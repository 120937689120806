import React from 'react';
import { Divider, Radio, message } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getTranslationCache } from '../.././../selectors/language';
// import { constant } from '../Constants';

import styles from '../../../commons/styles';
import { themes } from './mock';
import { StyledTheme } from './style';

const { StyledWrapper, StyledWrapperLeft, StyledWrapperRight, StyledHeading } = styles.layout;

const RadioGroup = Radio.Group;

class Preferences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      radioValue: this.props.selectedTheme,
      radioThemeValue: this.props.selectedThemeing,
      selectedLangauge: this.props.selectedLangauge,
      translationCache: props.translationCache || []
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedTheme) this.setState({ radioValue: nextProps.selectedTheme });
    if (nextProps.selectedThemeing) this.setState({ radioThemeValue: nextProps.selectedThemeing });
    if (nextProps.selectedLangauge) this.setState({ selectedLangauge: nextProps.selectedLangauge });
  }

  onNaviagionModeChange = (mode) => {
    this.props.actions.setNavigationMode(mode);
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    // const { translationCache } = this.state;
    return (
      <div>
        <StyledWrapper>
          <StyledWrapperLeft>
            <StyledHeading>
              {this.translation('Navigation theme')
                ? this.translation('Navigation theme')
                : `${'Navigation theme'}`}
            </StyledHeading>
            <span>
              {this.translation(
                'Customize the appearance of the application header and navigation sidebar.'
              )
                ? this.translation(
                    'Customize the appearance of the application header and navigation sidebar.'
                  )
                : `${'Customize the appearance of the application header and navigation sidebar.'}`}
            </span>
          </StyledWrapperLeft>
          <StyledWrapperRight>
            <RadioGroup onChange={this.onRadioChange} value={this.state.radioValue}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                {themes.map((key) => {
                  return (
                    <>
                      <div style={{ marginLeft: '30px' }}>
                        <StyledTheme
                          style={{ backgroundColor: `${key.backgroundColor}` }}
                        ></StyledTheme>
                        <Radio
                          style={{ margin: 20, alignContent: 'left' }}
                          margin
                          value={key.color}
                        >
                          {key.color}
                        </Radio>
                      </div>
                    </>
                  );
                })}
              </div>
            </RadioGroup>
          </StyledWrapperRight>
        </StyledWrapper>
        <Divider />

        {/* <StyledWrapper>
          <StyledWrapperLeft>
            <StyledHeading>{translationCache &&
                translationCache[`${constant.background_theme}`] &&
                translationCache[`${constant.background_theme}`] ?
                translationCache[`${constant.background_theme}`] :
                `${constant.background_theme}`}</StyledHeading>
            <p>
            {translationCache &&
                translationCache[`${constant.Theme_Desc}`] &&
                translationCache[`${constant.Theme_Desc}`] ?
                translationCache[`${constant.Theme_Desc}`] :
                `${constant.Theme_Desc}`}
            </p>
          </StyledWrapperLeft>
          <StyledWrapperRight>
            <RadioGroup
              onChange={this.onRadioThemeChange}
              value={this.state.radioThemeValue}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {theme.map((key) => {
                  return (
                   <>
                    <div style={{ marginLeft: "30px" }}>
                      <StyledTheme
                        style={{ backgroundColor: `${key.backgroundColor}` }}
                      ></StyledTheme>
                      <Radio
                        style={{ margin: 20, alignContent: "left" }}
                        margin
                        value={key.color}
                      >
                        {key.color}
                      </Radio>
                    </div>
                   </>
                  );
                })}
              </div>
            </RadioGroup>
          </StyledWrapperRight>
        </StyledWrapper>
         <Divider />
        <StyledWrapper>
          <StyledWrapperLeft>
            <StyledHeading>Navigation Mode</StyledHeading>
            <p>This settings allow you to customize the menu position</p>
          </StyledWrapperLeft>
          <StyledWrapperRight>
            <div style={{ display: "flex", flexDirection: "row",justifyContent:'flex-start' }}>
                <div style={{textAlign:"center"}}>
                <img
                  src="https://gw.alipayobjects.com/zos/antfincdn/XwFOFbLkSM/LCkqqYNmvBEbokSDscrm.svg"
                  style={{ cursor: "pointer", marginRight: "20px" }}
                  alt=""
                  onClick={() => this.onNaviagionModeChange("side")}
                />
             <h1> <span style={{fontSize:"15px"}}>Side Navigation</span></h1>
                </div>
                <div style={{marginLeft:"20px",textAlign:"center"}}>
                <img
                  src="https://gw.alipayobjects.com/zos/antfincdn/URETY8%24STp/KDNDBbriJhLwuqMoxcAr.svg"
                  style={{ cursor: "pointer" }}
                  alt=""
                  onClick={() => this.onNaviagionModeChange("top")}
                />
                <h1> <span style={{fontSize:"15px"}}>Top Navigation</span></h1>
                </div>
            </div>
          </StyledWrapperRight>
        </StyledWrapper>
        <Divider /> */}
        {/* <StyledWrapper>
          <StyledWrapperLeft>
            <StyledHeading>Behavior</StyledHeading>
            <p>
              This setting allows you to customize the behavior of the system
              layout and default views.
            </p>
          </StyledWrapperLeft>
          <StyledWrapperRight>
            <Form onSubmit={this.handleSubmit}>
              <b>Layout width</b>
              <FormItem>
                <StyledSelect option={data} defaultValue="Fixed">
                  {data.map((option) => (
                    <Option key={option.value}>{option.label}</Option>
                  ))}
                </StyledSelect>
                <p>
                  Choose between fixed (max. 1200px) and fluid (100%)
                  application layout.
                </p>
              </FormItem>
              <b>Default dashboard</b>
              <FormItem>
                <StyledSelect option={data} defaultValue="default">
                  {dashboard.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </StyledSelect>
              </FormItem>
              <b>Project overview content</b>
              <FormItem>
                <StyledSelect option={data} defaultValue="default">
                  {dashboard.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </StyledSelect>
              </FormItem>
              <FormItem>
                <PrimaryButton
                  style={{ width: "30%" }}
                  size="default"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Save Changes
                </PrimaryButton>
              </FormItem>
            </Form>
          </StyledWrapperRight>
        </StyledWrapper> */}
      </div>
    );
  }
  handleChange(value) {
    this.props.actions.changeLanguage(value);
  }
  onRadioChange = (e) => {
    this.props.actions.changeTheme(e.target.value);

    message.loading('Loading theme..', 1.0);
    // .then(() => message.success('Loading theme', 1.5))
    this.setState({
      radioValue: e.target.value
    });
  };

  onRadioThemeChange = (e) => {
    this.props.actions.changeThemeing(e.target.value);
    this.setState({
      radioThemeValue: e.target.value
    });
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(Preferences);
