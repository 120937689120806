import React, { Fragment } from 'react';
import { Form, message, Upload, Select } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import { StyledForm } from '../CSS/style';
import { constant } from '../Utils/constants';
import themeSettings from '../Utils/themeSettings.json';
import translation from '../Utils/translation';
import localStorage from '../../../utils/localStorage';
import { getDeviceFileDownloadName, getDeviceTypeList } from '../APICalls';
import { IPAddress } from '../../../commons/api';

class UploadDevices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      deviceTypeList: []
    };
    this.hRef = React.createRef();
  }
  componentDidMount() {
    this.setData(this.props.ShowForm);
  }
  componentDidUpdate(prevprops) {
    if (
      prevprops.ShowForm !== this.props.ShowForm ||
      prevprops.selectedRow !== this.props.selectedRow
    ) {
      this.setData(this.props.ShowForm);
    }
  }

  setData = async (ShowForm) => {
    if (ShowForm === 'UPLOAD_DEVICES') {
      let { selectedRow } = this.props;
      if (selectedRow && selectedRow.Type === 'DeviceType') {
        this.getFileDownloadName(selectedRow.Code);
        this.setState({
          formObject: {
            DeviceTypes: selectedRow.Code
          }
        });
      } else {
        this.GetDeviceType(this.props.Module);
      }
    }
  };
  GetDeviceType = async (Module) => {
    let deviceTypeList = await getDeviceTypeList(Module);
    this.setState({ deviceTypeList });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  getFileDownloadName = async (DeviceTypes) => {
    let { parent, selectedRow } = this.props;
    let AssetAlias = selectedRow && selectedRow.Type === 'Asset' ? selectedRow.Code : parent.Code;
    let fileDownloadName = await getDeviceFileDownloadName(DeviceTypes, AssetAlias);
    this.setState({
      fileDownloadName: fileDownloadName && fileDownloadName.file ? fileDownloadName.file : ''
    });
  };
  onFieldChange = (fieldValue, fieldName) => {
    let { formObject } = this.state;
    this.setState({
      formObject: {
        ...formObject,
        [fieldName]: fieldValue
      }
    });
    this.getFileDownloadName(fieldValue);
  };
  render() {
    let { fileDownloadName, deviceTypeList, formObject } = this.state;
    let { selectedRow, translationCache } = this.props;
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    return (
      <Fragment>
        <div
          style={{
            color: 'white',
            fontSize: '16px',
            // fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '24px'
          }}
        >
          {`System bulk upload for ${selectedRow.Name}`}
        </div>

        <StyledForm
          name="Item"
          ref={this.hRef}
          theme={themeSettings}
          layout="horizontal"
          labelCol={{ flex: '130px', marginTop: '20px' }}
          labelAlign="right"
          labelWrap
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {selectedRow.Type === 'DeviceType' ? null : (
              <Form.Item
                name="DeviceTypes"
                rules={[
                  {
                    required: true,
                    message: 'Please select DeviceType!'
                  }
                ]}
                label={translation(translationCache, constant.DeviceTypes)}
                style={{ width: '50%' }}
              >
                <Select
                  // mode="multiple"
                  value={formObject && formObject.DeviceTypes ? formObject.DeviceTypes : undefined}
                  onChange={(e) => this.onFieldChange(e, 'DeviceTypes')}
                >
                  {deviceTypeList &&
                    Array.isArray(deviceTypeList) &&
                    deviceTypeList.map((devicetype, index) => {
                      return (
                        <Select.Option key={index} value={devicetype.AliasCode}>
                          {devicetype.Name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            )}

            {formObject && formObject.DeviceTypes ? (
              <>
                <Form.Item
                  name="Download Devices"
                  style={{ width: '50%' }}
                  label={translation(translationCache, constant.DownloadDevices)}
                >
                  <a href={`${IPAddress.LOCAL}${fileDownloadName}`}>
                    <DownloadOutlined
                      style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                    />
                    <span style={{ color: 'white' }}>Download</span>
                  </a>
                </Form.Item>

                <Form.Item
                  name="Upload Devices"
                  style={{ width: '50%' }}
                  label={translation(translationCache, constant.UploadDevices)}
                >
                  <Upload
                    multiple={false}
                    style={{ marginRight: '10px', color: 'white' }}
                    onChange={(info) => {
                      if (info.file.status === 'done') {
                        message.success(info.file.response.message);
                        this.props.GetHierarchyLevelsUpdated();
                        this.getFileDownloadName(formObject.DeviceTypes);
                      } else if (info.file.status === 'error') {
                        message.error(info.file.response.message);
                      }
                    }}
                    action={`/api/client/upload/device?SiteId=${siteId}&AssetCode=${
                      selectedRow && selectedRow.Type === 'Asset'
                        ? selectedRow.Code
                        : this.props.parent.Code
                    }&DeviceType=${
                      selectedRow && selectedRow.Type === 'Asset'
                        ? formObject.DeviceTypes
                        : selectedRow.Code
                    }&EquipmentType=${'Parent'}&ParentEquipment=null`}
                    headers={{
                      Authorization: Authorization
                    }}
                    showUploadList={false}
                  >
                    <UploadOutlined
                      style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                    />
                    <span style={{ color: 'white', marginRight: '10px' }}> Upload </span>
                  </Upload>
                </Form.Item>
              </>
            ) : null}
          </div>
        </StyledForm>
      </Fragment>
    );
  }
}
export default UploadDevices;
