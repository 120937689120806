import axios from 'axios';
import localStorage from '../../../utils/localStorage';
import {
  GETUSERLIST,
  ANALYTICS,
  API_MASTERCONFIGURATION,
  API_SITE,
  API_RUBUSMASTERS
} from '../../../commons/api';
import { message } from 'antd';
export async function SubmitForm(payload, url) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${url}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };

  return axios(data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function FormUpdation(payload, url) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${url}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };

  return axios(data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
export async function FormUpdate(payload, url) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${url}?SiteId=${siteId}`,
    method: 'Patch',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };

  return axios(data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
export async function GetUseCase(url, Id, Type) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${url}?SiteId=${siteId}&AnalyticsId=${Id}&Type=${Type}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };

  return axios(data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function getUserList() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${GETUSERLIST.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getUsecaseList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${ANALYTICS.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getUserListData() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${GETUSERLIST.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getById(Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${ANALYTICS.GETBYID}?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return e;
    });
}

export const UpdateUsecase = async (payload) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const header = {
    method: 'POST',
    url: `${ANALYTICS.UPDATE}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
};

export async function deleteUsecase(id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${ANALYTICS.DELETE}?SiteId=${siteId}&Id=${id}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: { id }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function getRelatedTags() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_RUBUSMASTERS.ParametersGet}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
    // data: {
    //   TableName: 'rubusConfig."Parameters"',
    //   ColumnName: 'Code'
    // }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function deleteAttachment(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${ANALYTICS.DeleteAttachment}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: { Id: id }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function getStatusList() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'analytics."SM_TaskStatus"',
      ColumnName: 'Name'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export const getSitesList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_SITE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export async function getList(api) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
export async function getDeployHistory(api, Parameter, Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${api}?SiteId=${siteId}&${Parameter}=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
export async function FormSubmission(payload, url) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${url}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };

  return axios(data)
    .then((response) => {
      console.log(response, 'resrrrr');
      return response;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
