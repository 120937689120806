import React, { Component } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { Button, Form, Input, InputNumber, message, Collapse, Tooltip, DatePicker } from 'antd';

import SelectComponennt from '../Widgets/SelectComponent';
import 'antd/lib/date-picker/style/css';
import { dailyShift, morningShift, nightShift, defaultRange } from '../../Constants/index.js';
import { DeleteFilled } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const newLocal = true;
class EquipmentBodyCodeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: props.formObject || {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: '',
      equipmentName: '',
      BodyCount: '',
      batchArray: [],
      date: moment().format('YYYY-MM-DD'),
      equipmentList: [],
      shift: 'daily'
    };
  }

  componentDidMount() {
    this.setState({
      formObject: this.props.formObject,
      columns:
        this.props.formObject && this.props.formObject.columns ? this.props.formObject.columns : [],
      data:
        this.props.formObject && this.props.formObject.datasource
          ? this.props.formObject.datasource
          : []
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        formObject: this.props.formObject,
        columns:
          this.props.formObject && this.props.formObject.columns
            ? this.props.formObject.columns
            : [],
        data:
          this.props.formObject && this.props.formObject.datasource
            ? this.props.formObject.datasource
            : [],
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog
      });
    }
  }

  _getMandatoryStatus = () => {
    let { formObject } = this.props;
    let requiredStatus = false;
    if (formObject && formObject['equipmentBatch'] && formObject['equipmentBatch'].mainHeader) {
      formObject['equipmentBatch'].mainHeader.map((formHeader) => {
        let val = formObject['equipmentBatch'].mainHeaderValue;
        if (formHeader && formHeader.key && formHeader.required === true) {
          if (val && val[formHeader.key]) {
            return {};
          } else {
            requiredStatus = true;
          }
        }
        return {};
      });
    }
    return requiredStatus;
  };

  addEquipment = async () => {
    let { formObject } = this.props;
    let mandatoryStatus = await this._getMandatoryStatus();
    let equipmentExist = false;
    if (formObject.mainHeaderValue.EquipmentName) {
      let equipmentName = formObject.mainHeaderValue.EquipmentName;
      Object.keys(formObject['tabs']).map((eqpName) => {
        if (eqpName === equipmentName) {
          message.error('The equipment is already selected');
          equipmentExist = true;
        }
        return {};
      });
    }
    if (!mandatoryStatus && !equipmentExist) {
      if (formObject && formObject && formObject.mainHeaderValue) {
        formObject = {
          ...formObject,
          tabs: {
            ...(formObject && formObject['tabs']),
            [formObject.mainHeaderValue.EquipmentName]: {
              ...(formObject && formObject['tabs'] && formObject['tabs']['defaultData']),
              mainHeaderValue: formObject.mainHeaderValue,
              ReferenceAttributes: {
                JsonKey: ['tabs', formObject.mainHeaderValue.EquipmentName]
              }
            }
          }
        };

        this.props.downTimeData(formObject, false);
      }
    } else {
      if (mandatoryStatus) {
        message.error('Please select mandatory fields');
      }
    }
  };
  inputNumberChange = (minValue, maxValue, value, key, name, batchKey) => {
    if (
      (value >= minValue &&
        value <= maxValue &&
        minValue !== undefined &&
        maxValue !== undefined) ||
      value === ''
    ) {
      this.onInputChange(value, key, name, batchKey);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputChange(value, key, name, batchKey);
    } else {
      this.onInputChange('', key, name, batchKey);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  onInputChange = (columnValue, columnKey, name, batchKey) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...formObject[name].datasource,
          // [columnKey]: columnValue
          [batchKey]: {
            ...(formObject &&
            formObject[name] &&
            formObject[name].datasource &&
            formObject[name].datasource[batchKey]
              ? formObject[name].datasource[batchKey]
              : {}),
            [columnKey]: columnValue
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onChangeRemarks = (value, name, key) => {
    let { formObject } = this.state;
    if (
      formObject &&
      formObject[name] &&
      formObject[name].datasource &&
      formObject[name].datasource.cause
    ) {
      formObject[name].datasource.cause[key] = value;
    }
    this.props.downTimeData(formObject, false);
  };

  handleKeyPress = () => {};

  onDateTimeChange = (date, columnKey, batchKey) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      datasource: {
        ...formObject.datasource,
        [batchKey]: {
          ...(formObject && formObject && formObject.datasource && formObject.datasource[batchKey]
            ? formObject.datasource[batchKey]
            : {}),
          [columnKey]: {
            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onDateHeaderMainChange = (date, columnKey) => {
    let { formObject } = this.state;
    if (columnKey === 'Date' && formObject) {
      Object.keys(formObject).map((tabs) => {
        if (tabs !== 'mainHeader' && tabs !== 'mainHeaderValue') {
          Object.keys(formObject && formObject[tabs]).map((dynamicTabName) => {
            let datasourceNew = {};
            formObject &&
              formObject[tabs] &&
              formObject[tabs][dynamicTabName] &&
              formObject[tabs][dynamicTabName].subSection &&
              formObject[tabs][dynamicTabName].subSection &&
              Object.keys(
                formObject &&
                  formObject[tabs] &&
                  formObject[tabs][dynamicTabName] &&
                  formObject[tabs][dynamicTabName].subSection &&
                  formObject[tabs][dynamicTabName].subSection
              ).map((subSectionKey) => {
                let batchCodeNew = {};
                formObject[tabs][dynamicTabName].subSection[subSectionKey] &&
                  formObject[tabs][dynamicTabName].subSection[subSectionKey]['form'] &&
                  Object.keys(
                    formObject[tabs][dynamicTabName].subSection[subSectionKey]['form'] &&
                      formObject[tabs][dynamicTabName].subSection[subSectionKey]['form'].datasource
                  ).map((batchKey) => {
                    batchCodeNew[batchKey] = {
                      ...formObject[tabs][dynamicTabName].subSection[subSectionKey]['form']
                        .datasource[batchKey],
                      TimeSelection: {}
                    };
                    return {};
                  });
                datasourceNew[subSectionKey] = {
                  ...formObject[tabs][dynamicTabName].subSection[subSectionKey],
                  form: {
                    ...(formObject[tabs][dynamicTabName].subSection[subSectionKey] &&
                      formObject[tabs][dynamicTabName].subSection[subSectionKey]['form']),
                    datasource: {
                      ...batchCodeNew
                    }
                  }
                };
                return {};
              });
            formObject = {
              ...formObject,
              mainHeaderValue: {
                ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
              },
              [tabs]: {
                ...(formObject && formObject[tabs]),
                [dynamicTabName]: {
                  ...(formObject && formObject[tabs] && formObject[tabs][dynamicTabName]),

                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
                  },
                  subSection: datasourceNew
                }
              }
            };
            return {};
          });
        }
        return {};
      });
      this.props.downTimeData(formObject, false);
    } else {
      formObject = {
        ...formObject,
        mainHeaderValue: {
          ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
          [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
        }
      };
      this.props.downTimeData(formObject, false);
    }
  };

  inputHeaderMainNumberChange = (minValue, maxValue, value, key) => {
    if (
      (value >= minValue &&
        value <= maxValue &&
        minValue !== undefined &&
        maxValue !== undefined) ||
      value === ''
    ) {
      this.onInputHeaderMainChange(parseInt(value), key, minValue, maxValue);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputHeaderMainChange(parseInt(value), key, minValue, maxValue);
    } else {
      this.onInputHeaderMainChange('', key, minValue, maxValue);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  onInputHeaderMainChange = (columnValue, columnKey, min) => {
    let { formObject } = this.state;
    // if (columnValue < 0 && typeof (columnValue) === "number" && min>0) {
    //     message.error("Please Input Positive Values  Only")
    // }

    if (min !== undefined && min > 0 && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
    } else if (min === undefined && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
    } else {
      formObject = {
        ...formObject,

        mainHeaderValue: {
          ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
          [columnKey]: columnValue
        }
      };
      this.setState({ formObject });
      this.props.downTimeData(formObject, false);
    }
  };

  renderMainSwitch(formObject, columns, batchKey) {
    const { shift } = this.state;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputHeaderMainChange(e.target.value, columns.key)}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject &&
              formObject.mainHeaderValue &&
              (formObject.mainHeaderValue[columns.key] ||
                formObject.mainHeaderValue[columns.key] === 0)
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onBlur={(e) =>
              this.inputHeaderMainNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key
              )
            }
            onChange={(e) => this.onInputHeaderMainChange(e, columns.key, columns.min, columns.max)}

            // onChange={(e) => this.onInputHeaderMainChange(e, columns.key)}
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            value={
              formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Date']
                ? moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss')
                : undefined
            }
            allowClear={false}
            disabledDate={this.disabledDate}
            onChange={(e) => this.onDateHeaderMainChange(e, columns.key)}
            style={{ width: 300 }}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            handleFieldChange={(e) => this.onInputHeaderMainChange(e, columns.key)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            // ranges={{
            //     Today: [moment(), moment()],
            //     'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}

            showTime={{
              hideDisabledOptions: false,
              defaultValue: this.dateRanges(shift)
            }}
            allowClear={false}
            format="YYYY-MM-DD HH:mm"
            value={
              formObject &&
              formObject.datasource &&
              formObject.datasource[batchKey] &&
              formObject.datasource[batchKey][columns.key]
                ? [
                    moment(formObject.datasource[batchKey][columns.key]['startDate'], dateFormat),
                    moment(formObject.datasource[batchKey][columns.key]['endDate'], dateFormat)
                  ]
                : null
            }
            onChange={(e) => this.onDateTimeChange(e, columns.key)}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputHeaderMainChange(e.target.value, columns.key)}
          />
        );
    }
  }

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };

  onDateChange = (date, dateString) => {
    this.setState({
      date: dateString
    });
  };
  inputHeaderNumberChange = (minValue, maxValue, value, key, name, dynamicTabName) => {
    if (
      (value >= minValue &&
        value <= maxValue &&
        minValue !== undefined &&
        maxValue !== undefined) ||
      value === ''
    ) {
      this.onHeaderInputChange(parseInt(value), key, name, minValue, maxValue);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onHeaderInputChange(parseInt(value), key, name, dynamicTabName, minValue, maxValue);
    } else {
      this.onHeaderInputChange('', key, name, dynamicTabName, minValue, maxValue);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };

  onHeaderInputChange = (columnValue, columnKey, name, dynamicTabName, min) => {
    let formObject = cloneDeep(this.props.formObject);
    let headerValue = {};
    // if (columnValue < 0 && typeof (columnValue) === "number" && min > 0) {
    //     message.error("Please Input Positive Values  Only")
    //     formObject = {
    //         ...formObject,
    //         [name]: {
    //             ...formObject[name],
    //             [dynamicTabName]: {
    //                 ...formObject && formObject[name] && formObject[name][dynamicTabName],
    //                 headerValue: {
    //                     ...(formObject && formObject[name] && formObject[name][dynamicTabName] && formObject[name][dynamicTabName].headerValue ? formObject[name][dynamicTabName].headerValue : {}),
    //                     [columnKey]: ""
    //                 }
    //             }

    //         }
    //     }
    //     this.props.downTimeData(formObject, false)
    // }
    if (min !== undefined && min > 0 && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          [dynamicTabName]: {
            ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
            headerValue: {
              ...(formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName].headerValue
                ? formObject[name][dynamicTabName].headerValue
                : {}),
              [columnKey]: ''
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    } else if (min === undefined && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          [dynamicTabName]: {
            ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
            headerValue: {
              ...(formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName].headerValue
                ? formObject[name][dynamicTabName].headerValue
                : {}),
              [columnKey]: ''
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    } else {
      if (
        (columnKey === 'IsEquipmentActive' || columnKey === 'EquipmentActiveStatus') &&
        columnValue === 'No'
      ) {
        headerValue = {
          [columnKey]: columnValue,
          TotalProductionOutput: '0'
        };
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            [dynamicTabName]: {
              ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
              header: formObject[name][dynamicTabName].header.map((headerObject) => {
                if (
                  headerObject.key !== 'EquipmentActiveStatus' &&
                  headerObject.key !== 'TotalProductionOutput'
                ) {
                  return {
                    ...headerObject,
                    hide: true
                  };
                } else {
                  return headerObject;
                }
              }),
              headerValue: {
                ...(formObject &&
                formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].headerValue
                  ? formObject[name][dynamicTabName].headerValue
                  : {}),
                ...headerValue
              }
              // ["form"]: {

              //     ["ReferenceAttributes"]: {
              //         ["JsonKey"]: [
              //             name,
              //             "subSection",
              //             dynamicTabName,
              //             "form"
              //         ]
              //     }

              // }
            }
          }
        };
      } else if (columnKey === 'EquipmentActiveStatus' && columnValue === 'Yes') {
        headerValue = {
          [columnKey]: columnValue,
          TotalProductionOutput: '0'
        };
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            [dynamicTabName]: {
              ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
              header: formObject[name][dynamicTabName].header.map((headerObject) => {
                if (
                  headerObject.key !== 'EquipmentActiveStatus' &&
                  headerObject.key !== 'TotalProductionOutput'
                ) {
                  return {
                    ...headerObject,
                    hide: false
                  };
                } else {
                  return headerObject;
                }
              }),
              headerValue: {
                ...(formObject &&
                formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].headerValue
                  ? formObject[name][dynamicTabName].headerValue
                  : {}),
                [columnKey]: columnValue
              }
            }
          }
        };
      } else if (
        columnKey === 'BodyCount' &&
        formObject &&
        formObject[name] &&
        formObject[name][dynamicTabName] &&
        formObject[name][dynamicTabName]['headerValue'] &&
        formObject[name][dynamicTabName]['headerValue']['EquipmentActiveStatus'] === 'Yes'
      ) {
        let bodyArray = {};
        let bodyCodeName = '';
        for (let i = 1; i <= columnValue; i++) {
          bodyArray = {
            ...bodyArray,
            ['Body-' + i]: {
              ...(formObject &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName]['subSection'] &&
                formObject[name][dynamicTabName]['subSection']['bodycode'])
            }
          };
          bodyCodeName = 'Body-' + i;
        }

        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            [dynamicTabName]: {
              ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
              header: formObject[name][dynamicTabName].header.map((headerObject) => {
                if (
                  headerObject.key !== 'EquipmentActiveStatus' &&
                  headerObject.key !== 'TotalProductionOutput'
                ) {
                  return {
                    ...headerObject,
                    hide: false
                  };
                } else {
                  return headerObject;
                }
              }),
              headerValue: {
                ...(formObject &&
                formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].headerValue
                  ? formObject[name][dynamicTabName].headerValue
                  : {}),

                [columnKey]: columnValue
              },

              subSection: {
                // ...(formObject && formObject[name] && formObject[name].subSection ? formObject[name].subSection : {}),
                bodycode: formObject[name][dynamicTabName]['subSection']['bodycode'],
                ...bodyArray,
                ReferenceAttributes: {
                  JsonKey: [name, dynamicTabName, 'subSection', bodyCodeName]
                }
              }
            }
          }
        };
      } else {
        headerValue = {
          [columnKey]: columnValue
        };

        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            [dynamicTabName]: {
              ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
              headerValue: {
                ...(formObject &&
                formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].headerValue
                  ? formObject[name][dynamicTabName].headerValue
                  : {}),
                ...headerValue
              }
            }
          }
        };
      }
      this.props.downTimeData(formObject, false);
    }
  };
  inputSubNumberChange = (
    minValue,
    maxValue,
    value,
    key,
    name,
    dynamicTabName,
    subSectionKeyName
  ) => {
    if (
      (value >= minValue &&
        value <= maxValue &&
        minValue !== undefined &&
        maxValue !== undefined) ||
      value === ''
    ) {
      this.onHeaderSubInputChange(
        parseInt(value),
        key,
        name,
        subSectionKeyName,
        minValue,
        maxValue
      );
    } else if (minValue === undefined && maxValue === undefined) {
      this.onHeaderSubInputChange(
        parseInt(value),
        key,
        name,
        dynamicTabName,
        subSectionKeyName,
        minValue,
        maxValue
      );
    } else {
      this.onHeaderSubInputChange(
        '',
        key,
        name,
        dynamicTabName,
        subSectionKeyName,
        minValue,
        maxValue
      );
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  onHeaderSubInputChange = (
    columnValue,
    columnKey,
    name,
    dynamicTabName,
    subSectionKeyName,
    min
  ) => {
    let { formObject } = this.state;
    // if (columnValue < 0 && typeof (columnValue) === "number" && min > 0) {
    //     message.error("Please Input Positive Values  Only")
    //     formObject = {
    //         ...formObject,
    //         [name]: {
    //             ...formObject[name],
    //             [dynamicTabName]: {
    //                 ...formObject[name] && formObject[name][dynamicTabName],
    //                 "subSection": {
    //                     ...(formObject && formObject[name] && formObject[name].subSection ? formObject[name].subSection : {}),
    //                     ...(formObject && formObject[name] && formObject[name][dynamicTabName] && formObject[name][dynamicTabName].subSection ? formObject[name][dynamicTabName].subSection : {}),
    //                     [subSectionKeyName]: {
    //                         ...(
    //                             formObject &&
    //                                 formObject[name] && formObject[name][dynamicTabName] &&
    //                                 formObject[name][dynamicTabName].subSection &&
    //                                 formObject[name][dynamicTabName].subSection[subSectionKeyName]
    //                                 ?
    //                                 formObject[name][dynamicTabName].subSection[subSectionKeyName] : {}
    //                         ),
    //                         headerValue: {
    //                             ...(
    //                                 formObject &&
    //                                     formObject[name] && formObject[name][dynamicTabName] &&
    //                                     formObject[name][dynamicTabName].subSection &&
    //                                     formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
    //                                     formObject[name][dynamicTabName].subSection[subSectionKeyName]["headerValue"]
    //                                     ?
    //                                     formObject[name][dynamicTabName].subSection[subSectionKeyName]["headerValue"] : {}
    //                             ),
    //                             [columnKey]: ""
    //                         }
    //                     }
    //                 }

    //             }

    //         }
    //     }
    //     this.props.downTimeData(formObject, false)
    // }

    if (min !== undefined && min > 0 && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          [dynamicTabName]: {
            ...(formObject[name] && formObject[name][dynamicTabName]),
            subSection: {
              ...(formObject && formObject[name] && formObject[name].subSection
                ? formObject[name].subSection
                : {}),
              ...(formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName].subSection
                ? formObject[name][dynamicTabName].subSection
                : {}),
              [subSectionKeyName]: {
                ...(formObject &&
                formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].subSection &&
                formObject[name][dynamicTabName].subSection[subSectionKeyName]
                  ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                  : {}),
                headerValue: {
                  ...(formObject &&
                  formObject[name] &&
                  formObject[name][dynamicTabName] &&
                  formObject[name][dynamicTabName].subSection &&
                  formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                  formObject[name][dynamicTabName].subSection[subSectionKeyName]['headerValue']
                    ? formObject[name][dynamicTabName].subSection[subSectionKeyName]['headerValue']
                    : {}),
                  [columnKey]: ''
                }
              }
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    } else if (min === undefined && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          [dynamicTabName]: {
            ...(formObject[name] && formObject[name][dynamicTabName]),
            subSection: {
              ...(formObject && formObject[name] && formObject[name].subSection
                ? formObject[name].subSection
                : {}),
              ...(formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName].subSection
                ? formObject[name][dynamicTabName].subSection
                : {}),
              [subSectionKeyName]: {
                ...(formObject &&
                formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].subSection &&
                formObject[name][dynamicTabName].subSection[subSectionKeyName]
                  ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                  : {}),
                headerValue: {
                  ...(formObject &&
                  formObject[name] &&
                  formObject[name][dynamicTabName] &&
                  formObject[name][dynamicTabName].subSection &&
                  formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                  formObject[name][dynamicTabName].subSection[subSectionKeyName]['headerValue']
                    ? formObject[name][dynamicTabName].subSection[subSectionKeyName]['headerValue']
                    : {}),
                  [columnKey]: ''
                }
              }
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    } else {
      if (columnKey === 'PowderCount') {
        let bodyList = {};
        for (let i = 1; i <= columnValue; i++) {
          bodyList = {
            ...bodyList,
            [i]: {
              key: i
            }
          };
        }
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            [dynamicTabName]: {
              ...(formObject[name] && formObject[name][dynamicTabName]),
              subSection: {
                ...(formObject &&
                formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].subSection
                  ? formObject[name][dynamicTabName].subSection
                  : {}),
                [subSectionKeyName]: {
                  ...(formObject &&
                  formObject[name] &&
                  formObject[name][dynamicTabName] &&
                  formObject[name][dynamicTabName].subSection &&
                  formObject[name][dynamicTabName].subSection[subSectionKeyName]
                    ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                    : {}),
                  headerValue: {
                    ...(formObject &&
                    formObject[name] &&
                    formObject[name][dynamicTabName] &&
                    formObject[name][dynamicTabName].subSection &&
                    formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                    formObject[name][dynamicTabName].subSection[subSectionKeyName]['headerValue']
                      ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                          'headerValue'
                        ]
                      : {}),
                    [columnKey]: columnValue
                  },

                  form: {
                    ...(formObject &&
                      formObject[name] &&
                      formObject[name][dynamicTabName] &&
                      formObject[name][dynamicTabName]['subSection'] &&
                      formObject[name][dynamicTabName]['subSection'][subSectionKeyName] &&
                      formObject[name][dynamicTabName]['subSection'][subSectionKeyName]['form']),
                    datasource: bodyList
                  },
                  ReferenceAttributes: {
                    JsonKey: [name, dynamicTabName, 'subSection', subSectionKeyName, 'form']
                  }
                }
              }
            }
          }
        };
      } else {
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            [dynamicTabName]: {
              ...(formObject[name] && formObject[name][dynamicTabName]),
              subSection: {
                ...(formObject && formObject[name] && formObject[name].subSection
                  ? formObject[name].subSection
                  : {}),
                ...(formObject &&
                formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].subSection
                  ? formObject[name][dynamicTabName].subSection
                  : {}),
                [subSectionKeyName]: {
                  ...(formObject &&
                  formObject[name] &&
                  formObject[name][dynamicTabName] &&
                  formObject[name][dynamicTabName].subSection &&
                  formObject[name][dynamicTabName].subSection[subSectionKeyName]
                    ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                    : {}),
                  headerValue: {
                    ...(formObject &&
                    formObject[name] &&
                    formObject[name][dynamicTabName] &&
                    formObject[name][dynamicTabName].subSection &&
                    formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                    formObject[name][dynamicTabName].subSection[subSectionKeyName]['headerValue']
                      ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                          'headerValue'
                        ]
                      : {}),
                    [columnKey]: columnValue
                  }
                }
              }
            }
          }
        };
      }

      this.props.downTimeData(formObject, false);
    }
  };

  onSubsectiondatasourceChange = (
    columnValue,
    columnKey,
    name,
    subSectionKeyName,
    datasourceKey
  ) => {
    let { formObject } = this.state;
    let startDate2 = moment(columnValue[0]).format('YYYY-MM-DD HH:mm:ss');
    let endDate2 = moment(columnValue[1]).format('YYYY-MM-DD HH:mm:ss');

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        subSection: {
          ...(formObject && formObject[name] && formObject[name].subSection
            ? formObject[name].subSection
            : {}),
          [subSectionKeyName]: {
            ...(formObject &&
            formObject[name] &&
            formObject[name].subSection &&
            formObject[name].subSection[subSectionKeyName]
              ? formObject[name].subSection[subSectionKeyName]
              : {}),
            datasource: {
              ...(formObject &&
              formObject[name] &&
              formObject[name].subSection &&
              formObject[name].subSection[subSectionKeyName] &&
              formObject[name].subSection[subSectionKeyName]['datasource']
                ? formObject[name].subSection[subSectionKeyName]['datasource']
                : {}),
              [datasourceKey]: {
                ...(formObject &&
                formObject[name] &&
                formObject[name].subSection &&
                formObject[name].subSection[subSectionKeyName] &&
                formObject[name].subSection[subSectionKeyName]['datasource'] &&
                formObject[name].subSection[subSectionKeyName]['datasource'][datasourceKey]
                  ? formObject[name].subSection[subSectionKeyName]['datasource'][datasourceKey]
                  : {}),
                [columnKey]: {
                  startDate: moment(startDate2).format('YYYY-MM-DD HH:mm:ss'),
                  endDate: moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
                }
              }
            }
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };
  onOk = (columnValue, columnKey, name, dynamicTabName, subSectionKeyName, form, datasourceKey) => {
    let { formObject } = this.state;
    let startDate2 = '';
    let endDate2 = '';
    startDate2 = moment(columnValue[0]).format('YYYY-MM-DD 00:00:00');
    endDate2 = moment(columnValue[1]).format('YYYY-MM-DD 01:00:00');
    if (columnValue[0] !== null && columnValue[1] !== null) {
      if (startDate2 === endDate2) {
        message.error('StartDateTime and EndDateTime should not be same');
        // startDate2 = moment(columnValue[0]).format("YYYY-MM-DD 00:00:00")
        // endDate2 = moment(columnValue[1]).format("YYYY-MM-DD 01:00:00")
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            [dynamicTabName]: {
              ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
              subSection: {
                ...(formObject &&
                formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].subSection
                  ? formObject[name][dynamicTabName].subSection
                  : {}),
                [subSectionKeyName]: {
                  ...(formObject &&
                  formObject[name] &&
                  formObject[name][dynamicTabName] &&
                  formObject[name][dynamicTabName].subSection &&
                  formObject[name][dynamicTabName].subSection[subSectionKeyName]
                    ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                    : {}),
                  [form]: {
                    ...(formObject &&
                      formObject[name] &&
                      formObject[name][dynamicTabName] &&
                      formObject[name][dynamicTabName].subSection &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName][form] &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName][form]),
                    datasource: {
                      ...(formObject &&
                      formObject[name] &&
                      formObject[name][dynamicTabName] &&
                      formObject[name][dynamicTabName].subSection &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'] &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                        'datasource'
                      ]
                        ? formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                            'datasource'
                          ]
                        : {}),
                      [datasourceKey]: {
                        ...(formObject &&
                        formObject[name] &&
                        formObject[name][dynamicTabName] &&
                        formObject[name][dynamicTabName].subSection &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'] &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                          'datasource'
                        ] &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                          'datasource'
                        ][datasourceKey]
                          ? formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                              'datasource'
                            ][datasourceKey]
                          : {}),
                        [columnKey]: {}
                      }
                    }
                  }
                }
              }
            }
          }
        };
      } else if (startDate2 !== endDate2) {
        startDate2 = moment(columnValue[0]).format('YYYY-MM-DD HH:mm:ss');
        endDate2 = moment(columnValue[1]).format('YYYY-MM-DD HH:mm:ss');
        formObject = cloneDeep(this.props.formObject);
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            [dynamicTabName]: {
              ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
              subSection: {
                ...(formObject &&
                formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].subSection
                  ? formObject[name][dynamicTabName].subSection
                  : {}),
                [subSectionKeyName]: {
                  ...(formObject &&
                  formObject[name] &&
                  formObject[name][dynamicTabName] &&
                  formObject[name][dynamicTabName].subSection &&
                  formObject[name][dynamicTabName].subSection[subSectionKeyName]
                    ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                    : {}),
                  [form]: {
                    ...(formObject &&
                      formObject[name] &&
                      formObject[name][dynamicTabName] &&
                      formObject[name][dynamicTabName].subSection &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName][form] &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName][form]),
                    datasource: {
                      ...(formObject &&
                      formObject[name] &&
                      formObject[name][dynamicTabName] &&
                      formObject[name][dynamicTabName].subSection &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'] &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                        'datasource'
                      ]
                        ? formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                            'datasource'
                          ]
                        : {}),
                      [datasourceKey]: {
                        ...(formObject &&
                        formObject[name] &&
                        formObject[name][dynamicTabName] &&
                        formObject[name][dynamicTabName].subSection &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'] &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                          'datasource'
                        ] &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                          'datasource'
                        ][datasourceKey]
                          ? formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                              'datasource'
                            ][datasourceKey]
                          : {}),
                        [columnKey]: {
                          startDate: moment(startDate2).format('YYYY-MM-DD HH:mm:ss'),
                          endDate: moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        };

        let masterData = [
          {
            startHour1: '06:00',
            endHour1: '23:00',
            startHour2: '00:00',
            endHour2: '05:59'
          }
        ];
        if (newLocal) {
          let check =
            formObject &&
            formObject['equipmentBatch'] &&
            formObject['equipmentBatch']['mainHeaderValue'].Date;
          let startDate = moment(check).format('DD');
          let startHours = moment(columnValue[0]).format('HH:mm');
          let endHours = moment(columnValue[1]).format('HH:mm');
          let endDate = moment(columnValue[1]).format('DD');
          masterData &&
            masterData.map((item) => {
              if (
                item.startHour1 <= startHours &&
                endHours <= item.endHour2 &&
                startDate !== endDate
              ) {
                formObject = {
                  ...formObject,
                  [name]: {
                    ...formObject[name],
                    [dynamicTabName]: {
                      ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
                      subSection: {
                        ...(formObject &&
                        formObject[name] &&
                        formObject[name][dynamicTabName] &&
                        formObject[name][dynamicTabName].subSection
                          ? formObject[name][dynamicTabName].subSection
                          : {}),
                        [subSectionKeyName]: {
                          ...(formObject &&
                          formObject[name] &&
                          formObject[name][dynamicTabName] &&
                          formObject[name][dynamicTabName].subSection &&
                          formObject[name][dynamicTabName].subSection[subSectionKeyName]
                            ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                            : {}),
                          [form]: {
                            ...(formObject &&
                              formObject[name] &&
                              formObject[name][dynamicTabName] &&
                              formObject[name][dynamicTabName].subSection &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                form
                              ] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][form]),
                            datasource: {
                              ...(formObject &&
                              formObject[name] &&
                              formObject[name][dynamicTabName] &&
                              formObject[name][dynamicTabName].subSection &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                'form'
                              ] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                'form'
                              ]['datasource']
                                ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                    'form'
                                  ]['datasource']
                                : {}),
                              [datasourceKey]: {
                                ...(formObject &&
                                formObject[name] &&
                                formObject[name][dynamicTabName] &&
                                formObject[name][dynamicTabName].subSection &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ]['datasource'] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ]['datasource'][datasourceKey]
                                  ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                      'form'
                                    ]['datasource'][datasourceKey]
                                  : {}),
                                [columnKey]: {
                                  startDate: moment(startDate2).format('YYYY-MM-DD HH:mm:ss'),
                                  endDate: moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                };
              } else if (
                item.startHour2 <= startHours &&
                endHours <= item.endHour2 &&
                startDate < endDate &&
                startDate !== endDate
              ) {
                formObject = {
                  ...formObject,
                  [name]: {
                    ...formObject[name],
                    [dynamicTabName]: {
                      ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
                      subSection: {
                        ...(formObject &&
                        formObject[name] &&
                        formObject[name][dynamicTabName] &&
                        formObject[name][dynamicTabName].subSection
                          ? formObject[name][dynamicTabName].subSection
                          : {}),
                        [subSectionKeyName]: {
                          ...(formObject &&
                          formObject[name] &&
                          formObject[name][dynamicTabName] &&
                          formObject[name][dynamicTabName].subSection &&
                          formObject[name][dynamicTabName].subSection[subSectionKeyName]
                            ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                            : {}),
                          [form]: {
                            ...(formObject &&
                              formObject[name] &&
                              formObject[name][dynamicTabName] &&
                              formObject[name][dynamicTabName].subSection &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                form
                              ] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][form]),
                            datasource: {
                              ...(formObject &&
                              formObject[name] &&
                              formObject[name][dynamicTabName] &&
                              formObject[name][dynamicTabName].subSection &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                'form'
                              ] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                'form'
                              ]['datasource']
                                ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                    'form'
                                  ]['datasource']
                                : {}),
                              [datasourceKey]: {
                                ...(formObject &&
                                formObject[name] &&
                                formObject[name][dynamicTabName] &&
                                formObject[name][dynamicTabName].subSection &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ]['datasource'] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ]['datasource'][datasourceKey]
                                  ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                      'form'
                                    ]['datasource'][datasourceKey]
                                  : {}),
                                [columnKey]: {
                                  startDate: moment(startDate2).format('YYYY-MM-DD HH:mm:ss'),
                                  endDate: moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                };
              } else if (
                item.startHour1 <= startHours &&
                endHours <= item.endHour1 &&
                startDate === endDate
              ) {
                formObject = {
                  ...formObject,
                  [name]: {
                    ...formObject[name],
                    [dynamicTabName]: {
                      ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
                      subSection: {
                        ...(formObject &&
                        formObject[name] &&
                        formObject[name][dynamicTabName] &&
                        formObject[name][dynamicTabName].subSection
                          ? formObject[name][dynamicTabName].subSection
                          : {}),
                        [subSectionKeyName]: {
                          ...(formObject &&
                          formObject[name] &&
                          formObject[name][dynamicTabName] &&
                          formObject[name][dynamicTabName].subSection &&
                          formObject[name][dynamicTabName].subSection[subSectionKeyName]
                            ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                            : {}),
                          [form]: {
                            ...(formObject &&
                              formObject[name] &&
                              formObject[name][dynamicTabName] &&
                              formObject[name][dynamicTabName].subSection &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                form
                              ] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][form]),
                            datasource: {
                              ...(formObject &&
                              formObject[name] &&
                              formObject[name][dynamicTabName] &&
                              formObject[name][dynamicTabName].subSection &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                'form'
                              ] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                'form'
                              ]['datasource']
                                ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                    'form'
                                  ]['datasource']
                                : {}),
                              [datasourceKey]: {
                                ...(formObject &&
                                formObject[name] &&
                                formObject[name][dynamicTabName] &&
                                formObject[name][dynamicTabName].subSection &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ]['datasource'] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ]['datasource'][datasourceKey]
                                  ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                      'form'
                                    ]['datasource'][datasourceKey]
                                  : {}),
                                [columnKey]: {
                                  startDate: moment(startDate2).format('YYYY-MM-DD HH:mm:ss'),
                                  endDate: moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                };
              } else {
                message.error('Please choose correct time ranges');
                formObject = cloneDeep(this.props.formObject);
                formObject = {
                  ...formObject,
                  [name]: {
                    ...formObject[name],
                    [dynamicTabName]: {
                      ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
                      subSection: {
                        ...(formObject &&
                        formObject[name] &&
                        formObject[name][dynamicTabName] &&
                        formObject[name][dynamicTabName].subSection
                          ? formObject[name][dynamicTabName].subSection
                          : {}),
                        [subSectionKeyName]: {
                          ...(formObject &&
                          formObject[name] &&
                          formObject[name][dynamicTabName] &&
                          formObject[name][dynamicTabName].subSection &&
                          formObject[name][dynamicTabName].subSection[subSectionKeyName]
                            ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                            : {}),
                          [form]: {
                            ...(formObject &&
                              formObject[name] &&
                              formObject[name][dynamicTabName] &&
                              formObject[name][dynamicTabName].subSection &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                form
                              ] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][form]),
                            datasource: {
                              ...(formObject &&
                              formObject[name] &&
                              formObject[name][dynamicTabName] &&
                              formObject[name][dynamicTabName].subSection &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                'form'
                              ] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                'form'
                              ]['datasource']
                                ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                    'form'
                                  ]['datasource']
                                : {}),
                              [datasourceKey]: {
                                ...(formObject &&
                                formObject[name] &&
                                formObject[name][dynamicTabName] &&
                                formObject[name][dynamicTabName].subSection &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ]['datasource'] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ]['datasource'][datasourceKey]
                                  ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                      'form'
                                    ]['datasource'][datasourceKey]
                                  : {}),
                                [columnKey]: {}
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                };
              }
              return {};
            });
        }

        if (
          formObject &&
          formObject[name] &&
          formObject[name]['subSection'] &&
          formObject[name]['subSection'][subSectionKeyName] &&
          formObject[name]['subSection'][subSectionKeyName].datasource
        ) {
          let datasource = formObject[name]['subSection'][subSectionKeyName].datasource;
          Object.keys(datasource).map((dataSourceKey) => {
            if (
              datasource[dataSourceKey][columnKey] &&
              datasource[dataSourceKey][columnKey]['endDate'] &&
              datasourceKey !== parseInt(dataSourceKey)
            ) {
              let startDate1 = moment(datasource[dataSourceKey][columnKey]['startDate']).format(
                'YYYY-MM-DD HH:mm:ss'
              );
              let endDate1 = moment(datasource[dataSourceKey][columnKey]['endDate']).format(
                'YYYY-MM-DD HH:mm:ss'
              );
              if (startDate1 <= endDate2 && startDate2 <= endDate1) {
                message.error('Dates Overlapping');
                formObject = cloneDeep(this.props.formObject);
                formObject = {
                  ...formObject,
                  [name]: {
                    ...formObject[name],
                    [dynamicTabName]: {
                      ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
                      subSection: {
                        ...(formObject &&
                        formObject[name] &&
                        formObject[name][dynamicTabName] &&
                        formObject[name][dynamicTabName].subSection
                          ? formObject[name][dynamicTabName].subSection
                          : {}),
                        [subSectionKeyName]: {
                          ...(formObject &&
                          formObject[name] &&
                          formObject[name][dynamicTabName] &&
                          formObject[name][dynamicTabName].subSection &&
                          formObject[name][dynamicTabName].subSection[subSectionKeyName]
                            ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                            : {}),
                          [form]: {
                            ...(formObject &&
                              formObject[name] &&
                              formObject[name][dynamicTabName] &&
                              formObject[name][dynamicTabName].subSection &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                form
                              ] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][form]),
                            datasource: {
                              ...(formObject &&
                              formObject[name] &&
                              formObject[name][dynamicTabName] &&
                              formObject[name][dynamicTabName].subSection &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                'form'
                              ] &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                'form'
                              ]['datasource']
                                ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                    'form'
                                  ]['datasource']
                                : {}),
                              [datasourceKey]: {
                                ...(formObject &&
                                formObject[name] &&
                                formObject[name][dynamicTabName] &&
                                formObject[name][dynamicTabName].subSection &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ]['datasource'] &&
                                formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                  'form'
                                ]['datasource'][datasourceKey]
                                  ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                      'form'
                                    ]['datasource'][datasourceKey]
                                  : {}),
                                [columnKey]: {}
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                };
              }
            }
            return {};
          });
          Object.keys(formObject[name]['subSection']).map((subsection) => {
            if (subsection !== 'bodycode' && subsection !== subSectionKeyName) {
              let bodyDatasource =
                formObject &&
                formObject[name] &&
                formObject[name]['subSection'] &&
                formObject[name]['subSection'][subsection] &&
                formObject[name]['subSection'][subsection].datasource;
              Object.keys(bodyDatasource).map((Key) => {
                if (bodyDatasource[Key][columnKey] && bodyDatasource[Key][columnKey]['endDate']) {
                  let startDate = moment(bodyDatasource[Key][columnKey]['startDate']).format(
                    'YYYY-MM-DD HH:mm:ss'
                  );
                  let endDate = moment(bodyDatasource[Key][columnKey]['endDate']).format(
                    'YYYY-MM-DD HH:mm:ss'
                  );
                  if (startDate <= endDate2 && startDate2 <= endDate) {
                    message.error('Dates Overlapping');
                    formObject = cloneDeep(this.props.formObject);
                    formObject = {
                      ...formObject,
                      [name]: {
                        ...formObject[name],
                        [dynamicTabName]: {
                          ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
                          subSection: {
                            ...(formObject &&
                            formObject[name] &&
                            formObject[name][dynamicTabName] &&
                            formObject[name][dynamicTabName].subSection
                              ? formObject[name][dynamicTabName].subSection
                              : {}),
                            [subSectionKeyName]: {
                              ...(formObject &&
                              formObject[name] &&
                              formObject[name][dynamicTabName] &&
                              formObject[name][dynamicTabName].subSection &&
                              formObject[name][dynamicTabName].subSection[subSectionKeyName]
                                ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                                : {}),
                              [form]: {
                                ...(formObject &&
                                  formObject[name] &&
                                  formObject[name][dynamicTabName] &&
                                  formObject[name][dynamicTabName].subSection &&
                                  formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                    form
                                  ] &&
                                  formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                    form
                                  ]),
                                datasource: {
                                  ...(formObject &&
                                  formObject[name] &&
                                  formObject[name][dynamicTabName] &&
                                  formObject[name][dynamicTabName].subSection &&
                                  formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                                  formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                    'form'
                                  ] &&
                                  formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                    'form'
                                  ]['datasource']
                                    ? formObject[name][dynamicTabName].subSection[
                                        subSectionKeyName
                                      ]['form']['datasource']
                                    : {}),
                                  [datasourceKey]: {
                                    ...(formObject &&
                                    formObject[name] &&
                                    formObject[name][dynamicTabName] &&
                                    formObject[name][dynamicTabName].subSection &&
                                    formObject[name][dynamicTabName].subSection[
                                      subSectionKeyName
                                    ] &&
                                    formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                      'form'
                                    ] &&
                                    formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                      'form'
                                    ]['datasource'] &&
                                    formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                      'form'
                                    ]['datasource'][datasourceKey]
                                      ? formObject[name][dynamicTabName].subSection[
                                          subSectionKeyName
                                        ]['form']['datasource'][datasourceKey]
                                      : {}),
                                    [columnKey]: {}
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    };
                  }
                }
                return {};
              });
            }
            return {};
          });
        } else {
          formObject = {
            ...formObject,
            [name]: {
              ...formObject[name],
              [dynamicTabName]: {
                ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
                subSection: {
                  ...(formObject &&
                  formObject[name] &&
                  formObject[name][dynamicTabName] &&
                  formObject[name][dynamicTabName].subSection
                    ? formObject[name][dynamicTabName].subSection
                    : {}),
                  [subSectionKeyName]: {
                    ...(formObject &&
                    formObject[name] &&
                    formObject[name][dynamicTabName] &&
                    formObject[name][dynamicTabName].subSection &&
                    formObject[name][dynamicTabName].subSection[subSectionKeyName]
                      ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                      : {}),
                    [form]: {
                      ...(formObject &&
                        formObject[name] &&
                        formObject[name][dynamicTabName] &&
                        formObject[name][dynamicTabName].subSection &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName][form] &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName][form]),
                      datasource: {
                        ...(formObject &&
                        formObject[name] &&
                        formObject[name][dynamicTabName] &&
                        formObject[name][dynamicTabName].subSection &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'] &&
                        formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                          'datasource'
                        ]
                          ? formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                              'datasource'
                            ]
                          : {}),
                        [datasourceKey]: {
                          ...(formObject &&
                          formObject[name] &&
                          formObject[name][dynamicTabName] &&
                          formObject[name][dynamicTabName].subSection &&
                          formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                          formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'] &&
                          formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                            'datasource'
                          ] &&
                          formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                            'datasource'
                          ][datasourceKey]
                            ? formObject[name][dynamicTabName].subSection[subSectionKeyName][
                                'form'
                              ]['datasource'][datasourceKey]
                            : {}),
                          [columnKey]: {
                            startDate: moment(startDate2).format('YYYY-MM-DD HH:mm:ss'),
                            endDate: moment(endDate2).format('YYYY-MM-DD HH:mm:ss')
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          };
        }
      }
    } else {
      message.error('Please Input Start & End Times');
    }
    this.props.downTimeData(formObject, false);
  };
  disabledRangeTime = (current) => {
    let { shift, formObject } = this.state;
    if (
      current !== null &&
      formObject &&
      formObject['mainHeaderValue'] &&
      formObject['mainHeaderValue']['Date']
    ) {
      let selectedDate = moment(
        formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Date']
      ).format('YYYY-MM-DD');
      let currentDate = moment(current).format('YYYY-MM-DD');
      let range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
      };
      if (shift === 'Morning Shift' && selectedDate === currentDate) {
        return {
          // disabledHours: () => range(18, 18),
          disabledHours: () => [0, 1, 2, 3, 4, 5, 18, 19, 20, 21, 22, 23],
          disabledMinutes: () => range(60, 60)
          // disabledSeconds: () => [55, 56],
        };
      } else if (shift === 'Night Shift' && selectedDate === currentDate) {
        return {
          disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
          disabledMinutes: () => range(60, 60)
        };
      } else if (shift === 'Night Shift' && selectedDate !== currentDate) {
        return {
          disabledHours: () => [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
          disabledMinutes: () => range(60, 60)
        };
      } else if (selectedDate === currentDate) {
        return {
          disabledHours: () => [0, 1, 2, 3, 4, 5],
          disabledMinutes: () => range(60, 60)
        };
      } else if (selectedDate !== currentDate) {
        return {
          disabledHours: () => [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
        };
      }
    }

    return {
      disabledHours: () => [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
      ]
    };
  };

  disabledDateRange = (formObject, current) => {
    if (formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Date']) {
      let selectedDate = moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).add(1, 'day').endOf('day') ||
        current < moment(selectedDate).startOf('day')
      );
    }
    return (
      current || current > moment().add(1, 'day').endOf('day') || current < moment().startOf('day')
    );
  };

  inputHeaderSubDatasourceNumberChange = (
    minValue,
    maxValue,
    value,
    key,
    name,
    dynamicTabName,
    subkey,
    form,
    dataSourceKey
  ) => {
    if (
      (value >= minValue &&
        value <= maxValue &&
        minValue !== undefined &&
        maxValue !== undefined) ||
      value === ''
    ) {
      this.onHeaderSubDatasourceInputChange(
        parseInt(value),
        key,
        name,
        dynamicTabName,
        subkey,
        dataSourceKey,
        minValue,
        maxValue
      );
    } else if (minValue === undefined && maxValue === undefined) {
      this.onHeaderSubDatasourceInputChange(
        parseInt(value),
        key,
        name,
        dynamicTabName,
        subkey,
        form,
        dataSourceKey,
        minValue,
        maxValue
      );
    } else {
      this.onHeaderSubDatasourceInputChange(
        '',
        key,
        name,
        dynamicTabName,
        subkey,
        form,
        dataSourceKey,
        minValue,
        maxValue
      );

      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };

  onHeaderSubDatasourceInputChange = (
    columnValue,
    columnKey,
    name,
    dynamicTabName,
    subSectionKeyName,
    form,
    datasourceKey,
    min
  ) => {
    let { formObject } = this.state;
    // if (columnValue < 0 && typeof (columnValue) === "number" && min > 0) {
    //     message.error("Please Input Positive Values  Only")
    // }

    if (min !== undefined && min > 0 && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
    } else if (min === undefined && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
    } else {
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          [dynamicTabName]: {
            ...(formObject && formObject[name] && formObject[name][dynamicTabName]),
            subSection: {
              ...(formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName].subSection
                ? formObject[name][dynamicTabName].subSection
                : {}),
              [subSectionKeyName]: {
                ...(formObject &&
                formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].subSection &&
                formObject[name][dynamicTabName].subSection[subSectionKeyName]
                  ? formObject[name][dynamicTabName].subSection[subSectionKeyName]
                  : {}),
                [form]: {
                  ...(formObject &&
                    formObject[name] &&
                    formObject[name][dynamicTabName] &&
                    formObject[name][dynamicTabName].subSection &&
                    formObject[name][dynamicTabName].subSection[subSectionKeyName][form] &&
                    formObject[name][dynamicTabName].subSection[subSectionKeyName][form]),
                  datasource: {
                    ...(formObject &&
                    formObject[name] &&
                    formObject[name][dynamicTabName] &&
                    formObject[name][dynamicTabName].subSection &&
                    formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                    formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'] &&
                    formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                      'datasource'
                    ]
                      ? formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                          'datasource'
                        ]
                      : {}),
                    [datasourceKey]: {
                      ...(formObject &&
                      formObject[name] &&
                      formObject[name][dynamicTabName] &&
                      formObject[name][dynamicTabName].subSection &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'] &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                        'datasource'
                      ] &&
                      formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                        'datasource'
                      ][datasourceKey]
                        ? formObject[name][dynamicTabName].subSection[subSectionKeyName]['form'][
                            'datasource'
                          ][datasourceKey]
                        : {}),
                      [columnKey]: columnValue
                    }
                  }
                }
              }
            }
          }
        }
      };
      // if (columnKey === "ProductionOutput") {
      //     let sum = 0
      //     // sum = columnValue

      //     let headerProduction = formObject &&
      //         formObject[name] && formObject[name][dynamicTabName] &&
      //         formObject[name][dynamicTabName].subSection ?
      //         formObject[name][dynamicTabName].subSection : {}

      //     Object.keys(headerProduction).map((mainObjectKey) => {
      //         if (mainObjectKey !== 'bodycode') {

      //             let productionOutputSum = formObject &&
      //                 formObject[name] && formObject[name][dynamicTabName] &&
      //                 formObject[name][dynamicTabName].subSection
      //                 && formObject[name][dynamicTabName].subSection[mainObjectKey].datasource ?
      //                 formObject[name][dynamicTabName].subSection[mainObjectKey].datasource : {}

      //             productionOutputSum && Object.keys(productionOutputSum).map((datasource) => {
      //                 if (productionOutputSum[datasource] && productionOutputSum[datasource].ProductionOutput) {
      //                     sum = sum + (+productionOutputSum[datasource].ProductionOutput)
      //                 }
      //             })
      //         }

      //     })

      //     formObject = {
      //         ...formObject,
      //         [name]: {
      //             ...formObject[name],
      //             [dynamicTabName]: {
      //                 ...formObject && formObject[name] && formObject[name][dynamicTabName],
      //                 "subSection": {
      //                     ...(formObject && formObject[name] && formObject[name][dynamicTabName] && formObject[name][dynamicTabName].subSection ?
      //                         formObject[name][dynamicTabName].subSection : {}),
      //                     [subSectionKeyName]: {
      //                         ...(
      //                             formObject &&
      //                                 formObject[name] && formObject[name][dynamicTabName] &&
      //                                 formObject[name][dynamicTabName].subSection &&
      //                                 formObject[name][dynamicTabName].subSection[subSectionKeyName]
      //                                 ?
      //                                 formObject[name][dynamicTabName].subSection[subSectionKeyName] : {}
      //                         ),
      //                         [form]:{
      //                             ...formObject &&  formObject[name] && formObject[name][dynamicTabName] &&formObject[name][dynamicTabName].subSection &&formObject[name][dynamicTabName].subSection[subSectionKeyName][form] &&formObject[name][dynamicTabName].subSection[subSectionKeyName][form],
      //                             "datasource": {
      //                                 ...(
      //                                     formObject &&
      //                                         formObject[name] && formObject[name][dynamicTabName] &&
      //                                         formObject[name][dynamicTabName].subSection && formObject[name][dynamicTabName].subSection[subSectionKeyName] &&
      //                                         formObject[name][dynamicTabName].subSection[subSectionKeyName]["form"] &&
      //                                         formObject[name][dynamicTabName].subSection[subSectionKeyName]["form"] ["datasource"]
      //                                         ?
      //                                         formObject[name][dynamicTabName].subSection[subSectionKeyName]["form"] ["datasource"] : {}
      //                                 ),
      //                                 [datasourceKey]: {
      //                                     ...(
      //                                         formObject &&
      //                                             formObject[name] && formObject[name][dynamicTabName] &&
      //                                             formObject[name][dynamicTabName].subSection &&
      //                                             formObject[name][dynamicTabName].subSection[subSectionKeyName] && formObject[name][dynamicTabName].subSection[subSectionKeyName]["form"] &&
      //                                             formObject[name][dynamicTabName].subSection[subSectionKeyName]["form"]["datasource"] &&
      //                                             formObject[name][dynamicTabName].subSection[subSectionKeyName]["form"]["datasource"][datasourceKey] ?

      //                                             formObject[name][dynamicTabName].subSection[subSectionKeyName]["form"]["datasource"][datasourceKey]
      //                                             : {}
      //                                     ),
      //                                     [columnKey]: columnValue
      //                                 }
      //                             }
      //                         }
      //                     }
      //                 },
      //                 headerValue: {
      //                     ...(formObject[name][dynamicTabName].headerValue),
      //                     ['TotalProductionOutput']: sum
      //                 }
      //             }

      //         }
      //     }
      // }

      this.props.downTimeData(formObject, false);
    }
  };

  renderHeaderSwitch(formObject, columns, name, dynamicTabName) {
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName].headerValue &&
              formObject[name][dynamicTabName].headerValue[columns.key]
                ? formObject[name][dynamicTabName].headerValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onHeaderInputChange(e.target.value, columns.key, name, dynamicTabName)
            }
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName].headerValue &&
              (formObject[name][dynamicTabName].headerValue[columns.key] ||
                formObject[name][dynamicTabName].headerValue[columns.key] === 0)
                ? formObject[name][dynamicTabName].headerValue[columns.key]
                : ''
            }
            // min={columns.min ? columns.min : 0}
            // max={columns.max ? columns.max : 1000000}
            placeholder={columns.placeHolder}
            // onChange={(e) =>
            //     this.onHeaderInputChange(e, columns.key, name,dynamicTabName)}
            onBlur={(e) =>
              this.inputHeaderNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key,
                name,
                dynamicTabName
              )
            }
            onChange={(e) =>
              this.onHeaderInputChange(
                e,
                columns.key,
                name,
                dynamicTabName,
                columns.min,
                columns.max
              )
            }
            disabled={columns && columns.disabled ? columns.disabled : false}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName].headerValue &&
              formObject[name][dynamicTabName].headerValue[columns.key]
                ? formObject[name][dynamicTabName].headerValue[columns.key]
                : undefined
            }
            handleFieldChange={(e) =>
              this.onHeaderInputChange(e, columns.key, name, dynamicTabName)
            }
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'textArea':
        return (
          <div>
            <Input.TextArea
              rows={4}
              style={{ width: 300 }}
              value={
                formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].headerValue &&
                formObject[name][dynamicTabName].headerValue[columns.key]
                  ? formObject[name][dynamicTabName].headerValue[columns.key]
                  : ''
              }
              placeholder={columns.placeHolder}
              onChange={(e) =>
                this.onHeaderInputChange(e.target.value, columns.key, name, dynamicTabName)
              }
              autoSize={{ minRows: 2, maxRows: 4 }}
              // style={{ width: '100%' }}
              maxLength={250}
            />
            <div style={{ color: 'white' }}>
              Characters left :{' '}
              <span style={{ color: 'white', fontWeight: 'bold' }}>
                {formObject[name] &&
                formObject[name][dynamicTabName] &&
                formObject[name][dynamicTabName].headerValue &&
                formObject[name][dynamicTabName].headerValue[columns.key]
                  ? 250 - formObject[name][dynamicTabName].headerValue[columns.key].length
                  : 250}
                {/* {remarks && remarks !== null && remarks !== "null" ? 250 - (remarks.length) : 250} */}
              </span>
            </div>
          </div>
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName].headerValue &&
              formObject[name][dynamicTabName].headerValue[columns.key]
                ? formObject[name][dynamicTabName].headerValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onHeaderInputChange(e.target.value, columns.key, name, dynamicTabName)
            }
          />
        );
    }
  }

  renderSubSectionHeaderSwitch(formObject, columns, name, dynamicTabName, subSectionKeyName) {
    let subSection = 'subSection';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName][subSection] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue[
                columns.key
              ]
                ? formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue[
                    columns.key
                  ]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onHeaderSubInputChange(
                e.target.value,
                columns.key,
                name,
                dynamicTabName,
                subSectionKeyName
              )
            }
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName][subSection] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue &&
              (formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue[
                columns.key
              ] ||
                formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue[
                  columns.key
                ] === 0)
                ? formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue[
                    columns.key
                  ]
                : ''
            }
            // min={columns.min ? columns.min : 0}
            // max={columns.max ? columns.max : 10000000}
            placeholder={columns.placeHolder}
            // onChange={(e) =>
            //     this.onHeaderSubInputChange(e, columns.key, name, subSectionKeyName)}
            onBlur={(e) =>
              this.inputSubNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key,
                name,
                dynamicTabName,
                subSectionKeyName
              )
            }
            onChange={(e) =>
              this.onHeaderSubInputChange(
                e,
                columns.key,
                name,
                dynamicTabName,
                subSectionKeyName,
                columns.min,
                columns.max
              )
            }
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName][subSection] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue[
                columns.key
              ]
                ? formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue[
                    columns.key
                  ]
                : undefined
            }
            handleFieldChange={(e) =>
              this.onHeaderSubInputChange(e, columns.key, name, dynamicTabName, subSectionKeyName)
            }
            handleKeyPress={this.handleKeyPress}
            data={
              formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName][subSection]
                ? Object.keys(formObject[name][dynamicTabName][subSection]).map((subsectionVal) => {
                    return {
                      ...(formObject[name][dynamicTabName][subSection][subsectionVal] &&
                      formObject[name][dynamicTabName][subSection][subsectionVal].headerValue
                        ? formObject[name][dynamicTabName][subSection][subsectionVal].headerValue
                        : {})
                    };
                  })
                : {}
            }
          />
        );

      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName][subSection] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue[
                columns.key
              ]
                ? formObject[name][dynamicTabName][subSection][subSectionKeyName].headerValue[
                    columns.key
                  ]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onHeaderSubInputChange(
                e.target.value,
                columns.key,
                name,
                dynamicTabName,
                subSectionKeyName
              )
            }
          />
        );
    }
  }

  renderSubSectionDatasourceSwitch(
    formObject,
    columns,
    name,
    dynamicTabName,
    subSectionKeyName,
    form,
    datasourceKey
  ) {
    const { shift } = this.state;
    let subSection = 'subSection';
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName][subSection] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                datasourceKey
              ] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                datasourceKey
              ][columns.key]
                ? formObject[name][dynamicTabName][subSection][subSectionKeyName]['form']
                    .datasource[datasourceKey][columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onHeaderSubDatasourceInputChange(
                e.target.value,
                columns.key,
                name,
                dynamicTabName,
                subSectionKeyName,
                form,
                datasourceKey
              )
            }
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName][subSection] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                datasourceKey
              ] &&
              (formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                datasourceKey
              ][columns.key] ||
                formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                  datasourceKey
                ][columns.key] === 0)
                ? formObject[name][dynamicTabName][subSection][subSectionKeyName]['form']
                    .datasource[datasourceKey][columns.key]
                : ''
            }
            // min={columns.min ? columns.min : 0}
            // max={columns.max ? columns.max : 100}
            placeholder={columns.placeHolder}
            // onChange={(e) =>
            //     this.onHeaderSubDatasourceInputChange(e, columns.key, name, subSectionKeyName, datasourceKey)}

            onBlur={(e) =>
              this.inputHeaderSubDatasourceNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key,
                name,
                dynamicTabName,
                subSectionKeyName,
                form,
                datasourceKey
              )
            }
            onChange={(e) =>
              this.onHeaderSubDatasourceInputChange(
                e,
                columns.key,
                name,
                dynamicTabName,
                subSectionKeyName,
                form,
                datasourceKey,
                columns.min,
                columns.max
              )
            }
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName][subSection] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                datasourceKey
              ] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                datasourceKey
              ][columns.key]
                ? formObject[name][dynamicTabName][subSection][subSectionKeyName]['form']
                    .datasource[datasourceKey][columns.key]
                : undefined
            }
            handleFieldChange={(e) =>
              this.onHeaderSubDatasourceInputChange(
                e,
                columns.key,
                name,
                dynamicTabName,
                subSectionKeyName,
                form,
                datasourceKey
              )
            }
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            // ranges={{
            //     Today: [moment(), moment()],
            //     'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}
            allowClear={false}
            showTime={{
              hideDisabledOptions: false,
              defaultValue: this.dateRanges(shift)
            }}
            format="YYYY-MM-DD HH:mm"
            value={
              formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName][subSection] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                datasourceKey
              ] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                datasourceKey
              ][columns.key] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                datasourceKey
              ][columns.key]['startDate'] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                datasourceKey
              ][columns.key]['endDate']
                ? [
                    moment(
                      formObject[name][dynamicTabName][subSection][subSectionKeyName]['form']
                        .datasource[datasourceKey][columns.key]['startDate'],
                      dateFormat
                    ),
                    moment(
                      formObject[name][dynamicTabName][subSection][subSectionKeyName]['form']
                        .datasource[datasourceKey][columns.key]['endDate'],
                      dateFormat
                    )
                  ]
                : null
            }
            disabledTime={(e) => this.disabledRangeTime(e)}
            disabledDate={(e) =>
              this.disabledDateRange(
                {
                  ...(formObject && formObject ? formObject : {})
                },
                e
              )
            }
            // onChange={(e) => this.onSubsectiondatasourceChange(e, columns.key, name, subSectionKeyName, datasourceKey)}
            onOk={(e) =>
              this.onOk(
                e,
                columns.key,
                name,
                dynamicTabName,
                subSectionKeyName,
                form,
                datasourceKey
              )
            }
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject &&
              formObject[name] &&
              formObject[name][dynamicTabName] &&
              formObject[name][dynamicTabName][subSection] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'] &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource &&
              formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                datasourceKey
              ] &&
              (formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                datasourceKey
              ][columns.key] ||
                formObject[name][dynamicTabName][subSection][subSectionKeyName]['form'].datasource[
                  datasourceKey
                ][columns.key] === 0)
                ? formObject[name][dynamicTabName][subSection][subSectionKeyName]['form']
                    .datasource[datasourceKey][columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onHeaderSubDatasourceInputChange(
                e.target.value,
                columns.key,
                name,
                dynamicTabName,
                subSectionKeyName,
                form,
                datasourceKey
              )
            }
          />
        );
    }
  }
  deleteHandler = (name, dynamicTabName) => {
    let { formObject } = this.props;
    delete formObject[name][dynamicTabName];
    formObject = { ...formObject };
    this.props.downTimeData(formObject, false);
  };
  dateRanges = (shift) => {
    switch (shift) {
      case 'daily': {
        return dailyShift;
      }
      case 'Morning Shift': {
        return morningShift;
      }
      case 'Night Shift': {
        return nightShift;
      }
      default:
        return defaultRange;
    }
  };
  render() {
    let { formObject } = this.state;
    return (
      <div className="mainHeader">
        <Form layout="inline" className="formCustomLabel">
          <div
            style={{
              ...(this.props.status === 'Completed'
                ? {
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }
                : {})
            }}
          >
            <div className="formRowWrap">
              {formObject && formObject.mainHeader
                ? formObject.mainHeader.map((mainHeaderKey, index) => {
                    return (
                      <div key={index} className="subSectionFormBody">
                        <Form.Item
                          required={false}
                          label={
                            <span style={{ marginLeft: mainHeaderKey.marginLeft }}>
                              {mainHeaderKey.displayName}
                            </span>
                          }
                        >
                          <div>{this.renderMainSwitch(formObject, mainHeaderKey)}</div>
                        </Form.Item>
                      </div>
                    );
                  })
                : null}
            </div>
            <Button
              type=""
              className="saveButton"
              style={{ margin: '10px 0' }}
              onClick={this.addEquipment}
            >
              Add Equipment
            </Button>
          </div>
          {formObject && Object.keys(formObject).length > 1
            ? formObject &&
              Object.keys(formObject).map((name, index) => {
                if (name !== 'mainHeader' && name !== 'mainHeaderValue') {
                  return (
                    <Collapse key={index} bordered={false} className="customCollapse">
                      {formObject &&
                        formObject[name] &&
                        Object.keys(formObject[name]).map((dynamicTabName) => {
                          if (
                            dynamicTabName !== 'defaultData' &&
                            dynamicTabName !== 'ReferenceAttributes'
                          ) {
                            let batchArray = [];
                            let noOfBodyCount =
                              formObject &&
                              formObject[name] &&
                              formObject[name][dynamicTabName] &&
                              formObject[name][dynamicTabName].headerValue &&
                              formObject[name][dynamicTabName].headerValue.BodyCount
                                ? formObject[name][dynamicTabName].headerValue.BodyCount
                                : 0;
                            for (let i = 1; i <= noOfBodyCount; i++) {
                              batchArray.push(i);
                            }
                            return (
                              <Panel
                                header={[dynamicTabName]}
                                key={[dynamicTabName]}
                                extra={
                                  <Tooltip placement="top" title={<span>Delete</span>}>
                                    <DeleteFilled
                                      style={{ fontSize: '20px' }}
                                      onClick={() => this.deleteHandler(name, [dynamicTabName])}
                                    />
                                  </Tooltip>
                                }
                              >
                                <div
                                  className="customCollapse .ant-collapse-content-box"
                                  style={{
                                    margin: '20px 10px',
                                    ...(this.props.status === 'Completed'
                                      ? {
                                          opacity: newLocal ? 0.75 : 1,
                                          pointerEvents: newLocal ? 'none' : 'initial'
                                        }
                                      : {})
                                  }}
                                >
                                  {formObject &&
                                  formObject[name][dynamicTabName] &&
                                  formObject[name][dynamicTabName] &&
                                  formObject[name][dynamicTabName].header
                                    ? formObject[name][dynamicTabName].header.map(
                                        (columns, index) => {
                                          if (columns && !columns.hide) {
                                            return (
                                              <div key={index} className="subSectionFormBody">
                                                <Form.Item
                                                  required={false}
                                                  label={
                                                    <span
                                                      style={{ marginLeft: columns.marginLeft }}
                                                    >
                                                      {columns.displayName}
                                                    </span>
                                                  }
                                                >
                                                  <div>
                                                    {this.renderHeaderSwitch(
                                                      formObject,
                                                      columns,
                                                      name,
                                                      dynamicTabName
                                                    )}
                                                  </div>
                                                </Form.Item>
                                              </div>
                                            );
                                          }
                                          return {};
                                        }
                                      )
                                    : null}
                                </div>
                                <div
                                  style={{
                                    margin: '20px 10px',
                                    ...(this.props.status === 'Completed'
                                      ? {
                                          opacity: newLocal ? 0.75 : 1,
                                          pointerEvents: newLocal ? 'none' : 'initial'
                                        }
                                      : {})
                                  }}
                                >
                                  {formObject &&
                                  formObject[name][dynamicTabName] &&
                                  formObject[name][dynamicTabName] &&
                                  formObject[name][dynamicTabName].subSection
                                    ? Object.keys(
                                        formObject[name] &&
                                          formObject[name][dynamicTabName].subSection
                                      ).map((subSectionKeyName, index) => {
                                        if (
                                          subSectionKeyName !== 'bodycode' &&
                                          subSectionKeyName !== 'ReferenceAttributes'
                                        ) {
                                          return (
                                            <div key={index}>
                                              <div className="subSectionBody">
                                                <h3 className="subSectionTitle">
                                                  {subSectionKeyName}
                                                </h3>
                                                <div>
                                                  {formObject[name][dynamicTabName].subSection &&
                                                  formObject[name][dynamicTabName].subSection[
                                                    subSectionKeyName
                                                  ] &&
                                                  formObject[name][dynamicTabName].subSection[
                                                    subSectionKeyName
                                                  ]['header']
                                                    ? formObject[name][dynamicTabName].subSection[
                                                        subSectionKeyName
                                                      ]['header'].map((columns, subindex) => {
                                                        return (
                                                          <div
                                                            key={subindex}
                                                            className="subSectionFormBody"
                                                          >
                                                            <Form.Item
                                                              required={false}
                                                              label={
                                                                <span
                                                                  style={{
                                                                    marginLeft: columns.marginLeft
                                                                  }}
                                                                >
                                                                  {columns.displayName}
                                                                </span>
                                                              }
                                                            >
                                                              <div>
                                                                {this.renderSubSectionHeaderSwitch(
                                                                  formObject,
                                                                  columns,
                                                                  name,
                                                                  dynamicTabName,
                                                                  subSectionKeyName
                                                                )}
                                                              </div>
                                                            </Form.Item>
                                                          </div>
                                                        );
                                                      })
                                                    : null}
                                                </div>
                                                <div className="formRowWrap">
                                                  {formObject[name][dynamicTabName].subSection &&
                                                  formObject[name][dynamicTabName].subSection[
                                                    subSectionKeyName
                                                  ] &&
                                                  formObject[name][dynamicTabName].subSection[
                                                    subSectionKeyName
                                                  ]['form'] &&
                                                  formObject[name][dynamicTabName].subSection[
                                                    subSectionKeyName
                                                  ]['form']['datasource']
                                                    ? Object.keys(
                                                        formObject[name][dynamicTabName].subSection[
                                                          subSectionKeyName
                                                        ]['form']['datasource']
                                                      ).map((datasourceKey, subsubindex) => {
                                                        return (
                                                          <div
                                                            className="bodyCodeForm"
                                                            key={subsubindex}
                                                          >
                                                            {formObject &&
                                                              formObject[name] &&
                                                              formObject[name][dynamicTabName] &&
                                                              formObject[name][dynamicTabName]
                                                                .subSection[subSectionKeyName][
                                                                'form'
                                                              ] &&
                                                              formObject[name][
                                                                dynamicTabName
                                                              ].subSection[subSectionKeyName][
                                                                'form'
                                                              ]['columns'].map(
                                                                (columns, datakey) => {
                                                                  return (
                                                                    <div
                                                                      key={datakey}
                                                                      className="subSectionFormBody bodyForm"
                                                                    >
                                                                      <Form.Item
                                                                        required={false}
                                                                        label={
                                                                          <span
                                                                            style={{
                                                                              marginLeft:
                                                                                columns.marginLeft
                                                                            }}
                                                                          >
                                                                            {columns.displayName}
                                                                          </span>
                                                                        }
                                                                      >
                                                                        <div>
                                                                          {this.renderSubSectionDatasourceSwitch(
                                                                            formObject,
                                                                            columns,
                                                                            name,
                                                                            dynamicTabName,
                                                                            subSectionKeyName,
                                                                            'form',
                                                                            datasourceKey
                                                                          )}
                                                                        </div>
                                                                      </Form.Item>
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                          </div>
                                                        );
                                                      })
                                                    : null}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        return {};
                                      })
                                    : null}
                                </div>
                              </Panel>
                            );
                          }
                          return {};
                        })}
                    </Collapse>
                  );
                }
                return {};
              })
            : null}
        </Form>
      </div>
    );
  }
}

export default EquipmentBodyCodeForm;
