import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getCurrentTimezone, getUserProfile } from '../../selectors/layout';
import GraphComponent from './Widget/GraphComponent';
import AllGraphComponent from './Widget/AllGraphComponent';

class DataComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FilterData: this.props && this.props.filterData ? this.props.filterData : [],
      AllFilterData: this.props && this.props.allFilterData ? this.props.allFilterData : [],
      OperatorList: this.props && this.props.OperatorList ? this.props.OperatorList : []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.FilterData !== this.props.FilterData) {
      this.setState({
        FilterData: this.props.FilterData,
        AllFilterData: this.props.AllFilterData,
        OperatorList: this.props.OperatorList
      });
    }
  }
  getObservationList = (ObservationList) => {
    this.setState({ ObservationList });
    this.props.ObservationList(ObservationList);
  };
  render() {
    let { FilterData, AllFilterData } = this.state;
    return (
      <div>
        <GraphComponent FilterData={FilterData} OperatorList={this.state.OperatorList} />
        <div style={{ marginTop: '100px' }}>
          <AllGraphComponent
            AllFilterData={AllFilterData}
            OperatorList={this.state.OperatorList}
            ObservationList={this.getObservationList}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(DataComponent);
