import React from 'react';

import { getTranslationCache } from '../../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import themeSettings from '../../Utils/themeSettings.json';
import { StyledComponent, StyledTable } from '../../CSS/style';
import { getList, DeleteMasters } from '../../Utils/FormCalls';
import Highlighter from 'react-highlight-words';
import { MASTERALGORITHM } from '../../../../commons/api';
import { Button, Tooltip, Input, message, Popconfirm } from 'antd';
import moment from 'moment';
// import translation from '../Utils/translation';
import history from '../../../../commons/history';
import {
  EditOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons';

const APIList = {
  getList: `${MASTERALGORITHM.GET}`,
  delete: `${MASTERALGORITHM.DELETE}`
};
class AlgorithmMastersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      translationCache: props.translationCache || {}
    };
  }
  componentDidMount = async () => {
    let response = await getList(APIList.getList);
    this.setState({
      dataSource: response && Array.isArray(response) ? response : []
    });
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };
  viewRecord = (e, record) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `/rubus/RbsAnalytics/AlgorithmMasterDetails/${record.Id}`,
      routes: record.Id,
      state: { viewTag: true }
    });
  };

  editRecord = (record) => {
    history.push({
      pathname: `/rubus/RbsAnalytics/AlgorithmMasterDetails/${record.Id}`,
      state: { editTag: true }
    });
  };
  _deleteById = async (Id) => {
    let response = await DeleteMasters(APIList.delete, Id);
    response && response.message && message.success(response.message);
    let responseList = await getList(APIList.getList);
    this.setState({
      dataSource: responseList && Array.isArray(responseList) ? responseList : []
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: '' });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search') + this.translation(dataIndex)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  render() {
    const { dataSource } = this.state;
    const columns = [
      {
        title: this.translation('Algorithm Name'),
        dataIndex: 'AlgorithmName',
        key: 'AlgorithmName',
        ...this.getColumnSearchProps('AlgorithmName')
      },
      {
        title: this.translation('Model Type'),
        dataIndex: 'ModelType',
        key: 'ModelType',
        ...this.getColumnSearchProps('ModelType')
      },
      {
        title: this.translation('GPU Flag'),
        dataIndex: 'GPUFlag',
        key: 'GPUFlag'
      },
      {
        title: this.translation('Created By'),
        dataIndex: 'CreatedName',
        key: 'CreatedName',
        // filters: ,
        ...this.getColumnSearchProps('CreatedName'),
        sorter: (a, b) => a.CreatedName.length - b.CreatedName.length
        // onFilter: (value, record) => record.CreatedName === value,
      },
      {
        title: this.translation('Created Time'),
        dataIndex: 'CreatedTime',
        key: 'CreatedTime',
        ...this.getColumnSearchProps('CreatedTime'),
        sorter: (a, b) =>
          a.CreatedTime && b.CreatedTime && a.CreatedTime.localeCompare(b.CreatedTime),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Action'),
        width: '600',
        render: (text, record) => (
          <>
            <Tooltip title={this.translation('Edit')}>
              <Button
                style={{ marginRight: '5px' }}
                className="ant-btn"
                onClick={() => {
                  this.editRecord(record);
                }}
                size="middle"
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Popconfirm
              title={this.translation('Are you want to delete?')}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this._deleteById(record.Id)}
              okText={this.translation('Yes')}
              cancelText={this.translation('No')}
            >
              <Tooltip title={this.translation('Delete')}>
                <Button className="ant-btn" size="middle">
                  <span style={{ color: '#ff4d4f' }}>
                    {' '}
                    <DeleteOutlined />
                  </span>
                </Button>
              </Tooltip>
            </Popconfirm>
          </>
        )
      }
    ];
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '50px 30px', minHeight: window.innerHeight - 143 }}
        >
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Tooltip title={this.translation('Click here to add a new Algorithm')}>
              <Button
                onClick={() => history.push('/rubus/RbsAnalytics/AlgorithmMasterDetails')}
                type="primary"
                style={{
                  marginBottom: 8
                }}
                size="middle"
              >
                {this.translation('New')}
              </Button>
            </Tooltip>
          </div>
          <StyledTable
            theme={themeSettings}
            dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
            columns={columns}
          ></StyledTable>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(AlgorithmMastersList);
