import React, { Component } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { Form, Input, InputNumber, Collapse, message, TimePicker, DatePicker } from 'antd';
import TableView from '../Widgets/TableView';
import SelectComponennt from '../Widgets/SelectComponent';
import { dailyShift, morningShift, nightShift, defaultRange } from '../../Constants/index.js';

import 'antd/lib/date-picker/style/css';
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const newLocal = true;
class FormWithFormSubSectionFormTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: props.formObject || {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: '',
      equipmentName: '',
      BodyCount: '',
      batchArray: [],
      date: moment().format('YYYY-MM-DD'),
      equipmentList: [],
      shift: 'daily',
      InputNumberType: 'number'
    };
  }

  componentDidMount() {
    const { formObject } = this.state;
    this.setState({
      formObject: this.props.formObject
    });
    this.props.downTimeData(formObject, false);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        formObject: this.props.formObject,
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog,
        shift:
          this.props.formObject &&
          this.props.formObject['mainHeaderValue'] &&
          this.props.formObject['mainHeaderValue'] &&
          this.props.formObject['mainHeaderValue']['Shift']
            ? this.props.formObject['mainHeaderValue']['Shift']
            : ' '
      });
    }
  }

  onOk = (
    date,
    columnKey,
    tabs,
    name,
    form,
    subsection,
    subsectionName,
    subSubSection,
    subForm,
    datasourceKey
  ) => {
    let { formObject } = this.state;
    let startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
    let endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
    if (date[0] !== null && date[1] !== null) {
      if (moment(date[0]).isSame(date[1])) {
        message.error('StartDateTime and EndDateTime should not be same');

        if (subsectionName && subsectionName !== undefined) {
          formObject = {
            ...formObject,
            tabs: {
              ...(formObject && formObject['tabs'] ? formObject.tabs : {}),
              [name]: {
                ...(formObject && formObject['tabs'] && formObject['tabs'][name]
                  ? formObject['tabs'][name]
                  : {}),
                subsection: {
                  ...(formObject &&
                  formObject['tabs'] &&
                  formObject['tabs'][name] &&
                  formObject['tabs'][name]['subsection']
                    ? formObject['tabs'][name]['subsection']
                    : {}),
                  [subsectionName]: {
                    ...(formObject &&
                    formObject['tabs'] &&
                    formObject['tabs'][name] &&
                    formObject['tabs'][name]['subsection'] &&
                    formObject['tabs'][name]['subsection'][subsectionName]
                      ? formObject['tabs'][name]['subsection'][subsectionName]
                      : {}),
                    form: {
                      ...(formObject &&
                      formObject['tabs'] &&
                      formObject['tabs'][name] &&
                      formObject['tabs'][name]['subsection'] &&
                      formObject['tabs'][name]['subsection'][subsectionName] &&
                      formObject['tabs'][name]['subsection'][subsectionName]['form']
                        ? formObject['tabs'][name]['subsection'][subsectionName]['form']
                        : {}),
                      ReferenceAttributes: {
                        JsonKey: ['tabs', name, 'subsection', subsectionName, 'form']
                      },
                      dynamicSubForm: {
                        ...(formObject &&
                        formObject['tabs'] &&
                        formObject['tabs'][name] &&
                        formObject['tabs'][name]['subsection'] &&
                        formObject['tabs'][name]['subsection'][subsectionName] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ]
                          ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                              'dynamicSubForm'
                            ]
                          : {}),
                        [subSubSection]: {
                          ...(formObject &&
                          formObject['tabs'] &&
                          formObject['tabs'][name] &&
                          formObject['tabs'][name]['subsection'] &&
                          formObject['tabs'][name]['subsection'][subsectionName] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection]
                            ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ][subSubSection]
                            : {}),
                          form: {
                            ...(formObject &&
                            formObject['tabs'] &&
                            formObject['tabs'][name] &&
                            formObject['tabs'][name]['subsection'] &&
                            formObject['tabs'][name]['subsection'][subsectionName] &&
                            formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                            formObject['tabs'][name]['subsection'][subsectionName]['form'][
                              'dynamicSubForm'
                            ] &&
                            formObject['tabs'][name]['subsection'][subsectionName]['form'][
                              'dynamicSubForm'
                            ][subSubSection] &&
                            formObject['tabs'][name]['subsection'][subsectionName]['form'][
                              'dynamicSubForm'
                            ][subSubSection]['datasource']
                              ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                  'dynamicSubForm'
                                ][subSubSection]['datasource']
                              : {}),
                            datasource: {
                              ...(formObject &&
                              formObject['tabs'] &&
                              formObject['tabs'][name] &&
                              formObject['tabs'][name]['subsection'] &&
                              formObject['tabs'][name]['subsection'][subsectionName] &&
                              formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                              formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ] &&
                              formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ][subSubSection] &&
                              formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ][subSubSection]['form'] &&
                              formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ][subSubSection]['form']['datasource']
                                ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                    'dynamicSubForm'
                                  ][subSubSection]['form']['datasource']
                                : {}),
                              [datasourceKey]: {
                                ...(formObject &&
                                formObject['tabs'] &&
                                formObject['tabs'][name] &&
                                formObject['tabs'][name]['subsection'] &&
                                formObject['tabs'][name]['subsection'][subsectionName] &&
                                formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                                formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                  'dynamicSubForm'
                                ] &&
                                formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                  'dynamicSubForm'
                                ][subSubSection] &&
                                formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                  'dynamicSubForm'
                                ][subSubSection]['form'] &&
                                formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                  'dynamicSubForm'
                                ][subSubSection]['form']['datasource'] &&
                                formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                  'dynamicSubForm'
                                ][subSubSection]['form']['datasource'][datasourceKey]
                                  ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                      'dynamicSubForm'
                                    ][subSubSection]['form']['datasource'][datasourceKey]
                                  : {}),

                                [columnKey]: {}
                              }
                            },
                            ReferenceAttributes: {
                              JsonKey: [
                                'tabs',
                                name,
                                'subsection',
                                subsectionName,
                                'form',
                                'dynamicSubForm',
                                subSubSection,
                                'form'
                              ]
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          };
        } else if (!subsectionName && subsectionName === undefined) {
          formObject = {
            ...formObject,
            tabs: {
              ...(formObject && formObject.tabs ? formObject.tabs : {}),
              [name]: {
                ...(formObject && formObject.tabs && formObject.tabs[name]
                  ? formObject.tabs[name]
                  : {}),
                form: {
                  ...(formObject &&
                  formObject.tabs &&
                  formObject.tabs[name] &&
                  formObject.tabs[name]['form']
                    ? formObject.tabs[name]['form']
                    : {}),
                  datasource: {
                    ...(formObject &&
                    formObject.tabs &&
                    formObject.tabs[name] &&
                    formObject.tabs[name]['form'] &&
                    formObject.tabs[name]['form'].datasource
                      ? formObject.tabs[name]['form'].datasource
                      : {}),
                    [columnKey]: {}
                  },
                  ReferenceAttributes: {
                    JsonKey: ['tabs', name, 'form']
                  }
                }
              }
            }
          };
        }
        this.props.downTimeData(formObject, false);
      } else if (startDate2 !== endDate2) {
        startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
        endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
        if (subsectionName && subsectionName !== undefined) {
          formObject = {
            ...formObject,
            tabs: {
              ...(formObject && formObject['tabs'] ? formObject.tabs : {}),
              [name]: {
                ...(formObject && formObject['tabs'] && formObject['tabs'][name]
                  ? formObject['tabs'][name]
                  : {}),
                subsection: {
                  ...(formObject &&
                  formObject['tabs'] &&
                  formObject['tabs'][name] &&
                  formObject['tabs'][name]['subsection']
                    ? formObject['tabs'][name]['subsection']
                    : {}),
                  [subsectionName]: {
                    ...(formObject &&
                    formObject['tabs'] &&
                    formObject['tabs'][name] &&
                    formObject['tabs'][name]['subsection'] &&
                    formObject['tabs'][name]['subsection'][subsectionName]
                      ? formObject['tabs'][name]['subsection'][subsectionName]
                      : {}),
                    form: {
                      ...(formObject &&
                      formObject['tabs'] &&
                      formObject['tabs'][name] &&
                      formObject['tabs'][name]['subsection'] &&
                      formObject['tabs'][name]['subsection'][subsectionName] &&
                      formObject['tabs'][name]['subsection'][subsectionName]['form']
                        ? formObject['tabs'][name]['subsection'][subsectionName]['form']
                        : {}),
                      ReferenceAttributes: {
                        JsonKey: ['tabs', name, 'subsection', subsectionName, 'form']
                      },
                      dynamicSubForm: {
                        ...(formObject &&
                        formObject['tabs'] &&
                        formObject['tabs'][name] &&
                        formObject['tabs'][name]['subsection'] &&
                        formObject['tabs'][name]['subsection'][subsectionName] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ]
                          ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                              'dynamicSubForm'
                            ]
                          : {}),
                        [subSubSection]: {
                          ...(formObject &&
                          formObject['tabs'] &&
                          formObject['tabs'][name] &&
                          formObject['tabs'][name]['subsection'] &&
                          formObject['tabs'][name]['subsection'][subsectionName] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection]
                            ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ][subSubSection]
                            : {}),
                          [subForm]: {
                            ...(formObject &&
                            formObject['tabs'] &&
                            formObject['tabs'][name] &&
                            formObject['tabs'][name]['subsection'] &&
                            formObject['tabs'][name]['subsection'][subsectionName] &&
                            formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                            formObject['tabs'][name]['subsection'][subsectionName]['form'][
                              'dynamicSubForm'
                            ] &&
                            formObject['tabs'][name]['subsection'][subsectionName]['form'][
                              'dynamicSubForm'
                            ][subSubSection] &&
                            formObject['tabs'][name]['subsection'][subsectionName]['form'][
                              'dynamicSubForm'
                            ][subSubSection][form]
                              ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                  'dynamicSubForm'
                                ][subSubSection][form]
                              : {}),

                            datasource: {
                              ...(formObject &&
                              formObject['tabs'] &&
                              formObject['tabs'][name] &&
                              formObject['tabs'][name]['subsection'] &&
                              formObject['tabs'][name]['subsection'][subsectionName] &&
                              formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                              formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ] &&
                              formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ][subSubSection] &&
                              formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ][subSubSection][form] &&
                              formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ][subSubSection][form]['datasource']
                                ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                    'dynamicSubForm'
                                  ][subSubSection][form]['datasource']
                                : {}),
                              [datasourceKey]: {
                                ...(formObject &&
                                formObject['tabs'] &&
                                formObject['tabs'][name] &&
                                formObject['tabs'][name]['subsection'] &&
                                formObject['tabs'][name]['subsection'][subsectionName] &&
                                formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                                formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                  'dynamicSubForm'
                                ] &&
                                formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                  'dynamicSubForm'
                                ][subSubSection] &&
                                formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                  'dynamicSubForm'
                                ][subSubSection][form] &&
                                formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                  'dynamicSubForm'
                                ][subSubSection][form]['datasource'] &&
                                formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                  'dynamicSubForm'
                                ][subSubSection][form]['datasource'][datasourceKey]
                                  ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                      'dynamicSubForm'
                                    ][subSubSection][form]['datasource'][datasourceKey]
                                  : {}),

                                [columnKey]: {
                                  StartDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                                  EndDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                                }
                              }
                            },
                            ReferenceAttributes: {
                              JsonKey: [
                                'tabs',
                                name,
                                'subsection',
                                subsectionName,
                                'form',
                                'dynamicSubForm',
                                subSubSection,
                                'form'
                              ]
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          };
        } else if (!subsectionName && subsectionName === undefined) {
          formObject = {
            ...formObject,
            tabs: {
              ...(formObject && formObject.tabs ? formObject.tabs : {}),
              [name]: {
                ...(formObject && formObject.tabs && formObject.tabs[name]
                  ? formObject.tabs[name]
                  : {}),
                form: {
                  ...(formObject &&
                  formObject.tabs &&
                  formObject.tabs[name] &&
                  formObject.tabs[name][form]
                    ? formObject.tabs[name][form]
                    : {}),
                  datasource: {
                    ...(formObject &&
                    formObject.tabs &&
                    formObject.tabs[name] &&
                    formObject.tabs[name][form] &&
                    formObject.tabs[name][form].datasource
                      ? formObject.tabs[name][form].datasource
                      : {}),
                    [columnKey]: {
                      startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                      endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                    }
                  },
                  ReferenceAttributes: {
                    JsonKey: ['tabs', name, 'form']
                  }
                }
              }
            }
          };
          let masterData = [
            {
              shift: 'Morning Shift',
              startHour: '06',
              endHour: '17',
              startHour1: '06',
              endHour1: '23',
              startHour2: '00',
              endHour2: '05'
            },
            {
              shift: 'Night Shift',
              startHour: '18',
              endHour: '05',
              startHour1: '06',
              endHour1: '23',
              startHour2: '00',
              endHour2: '05'
            }
          ];
          if (newLocal) {
            let startTime = moment(date[0]).format('HH:mm:ss');
            let startHours = moment(date[0]).format('HH');
            let startDate = moment(date[0]).format('DD');
            let endDate = moment(date[1]).format('DD');
            let endHours = moment(date[1]).format('HH');

            let endTime = moment(date[1]).format('HH:mm:ss');
            let Shift =
              formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Shift'];
            let selectedDate =
              formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Date'];
            let currentDate = moment(selectedDate).format('DD');
            masterData &&
              masterData.map((item) => {
                if (item.shift === Shift && item.shift === 'Morning Shift') {
                  //  hours >= 6 && hours < 18)
                  if (item.startHour <= startHours && endHours <= item.endHour) {
                    if (subsectionName && subsectionName !== undefined) {
                      formObject = {
                        ...formObject,
                        [tabs]: {
                          ...(formObject && formObject[tabs] ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject[tabs] && formObject[tabs][name]
                              ? formObject[tabs][name]
                              : {}),
                            tabs: {
                              ...(formObject &&
                              formObject[tabs] &&
                              formObject[tabs][name] &&
                              formObject[tabs][name]['subsection']
                                ? formObject[tabs][name]['subsection']
                                : {}),
                              [subsectionName]: {
                                ...(formObject &&
                                formObject[tabs] &&
                                formObject[tabs][name] &&
                                formObject[tabs][name]['subsection'] &&
                                formObject[tabs][name]['subsection'][subsectionName]
                                  ? formObject[tabs][name]['subsection'][subsectionName]
                                  : {}),
                                form: {
                                  ...(formObject &&
                                  formObject[tabs] &&
                                  formObject[tabs][name] &&
                                  formObject[tabs][name]['subsection'] &&
                                  formObject[tabs][name]['subsection'][subsectionName] &&
                                  formObject[tabs][name]['subsection'][subsectionName]['form']
                                    ? formObject[tabs][name]['subsection'][subsectionName]['form']
                                    : {}),
                                  dynamicSubForm: {
                                    ...(formObject &&
                                    formObject['tabs'] &&
                                    formObject['tabs'][name] &&
                                    formObject['tabs'][name]['subsection'] &&
                                    formObject['tabs'][name]['subsection'][subsectionName] &&
                                    formObject['tabs'][name]['subsection'][subsectionName][
                                      'form'
                                    ] &&
                                    formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                      'dynamicSubForm'
                                    ]
                                      ? formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm']
                                      : {}),
                                    [subSubSection]: {
                                      ...(formObject &&
                                      formObject['tabs'] &&
                                      formObject['tabs'][name] &&
                                      formObject['tabs'][name]['subsection'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'][subSubSection]
                                        ? formObject['tabs'][name]['tabs'][subsectionName]['form'][
                                            'dynamicSubForm'
                                          ][subSubSection]
                                        : {}),
                                      [form]: {
                                        ...(formObject &&
                                        formObject['tabs'] &&
                                        formObject['tabs'][name] &&
                                        formObject['tabs'][name]['subsection'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection][form]
                                          ? formObject['tabs'][name]['subsection'][subsectionName][
                                              'form'
                                            ]['dynamicSubForm'][subSubSection][form]
                                          : {}),

                                        datasource: {
                                          ...(formObject &&
                                          formObject['tabs'] &&
                                          formObject['tabs'][name] &&
                                          formObject['tabs'][name]['tabs'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form]['datasource']
                                            ? formObject['tabs'][name]['subSubSection'][
                                                subsectionName
                                              ]['form']['dynamicSubForm'][subSubSection][form][
                                                'datasource'
                                              ]
                                            : {}),
                                          [datasourceKey]: {
                                            ...(formObject &&
                                            formObject['tabs'] &&
                                            formObject['tabs'][name] &&
                                            formObject['tabs'][name]['subSubSection'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form][
                                              'datasource'
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][
                                              'datasource'
                                            ][form][datasourceKey]
                                              ? formObject['tabs'][name]['subSubSection'][
                                                  subsectionName
                                                ]['form']['dynamicSubForm'][subSubSection][
                                                  'datasource'
                                                ][form][datasourceKey]
                                              : {}),

                                            [columnKey]: {
                                              StartDate: moment(date[0]).format(
                                                'YYYY-MM-DD HH:mm:ss'
                                              ),
                                              EndDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                                            }
                                          }
                                        },
                                        ReferenceAttributes: {
                                          JsonKey: [
                                            'tabs',
                                            name,
                                            'subsection',
                                            subsectionName,
                                            'form',
                                            'dynamicSubForm',
                                            subSubSection,
                                            'form'
                                          ]
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      };
                    } else if (!subsectionName && subsectionName === undefined) {
                      formObject = {
                        ...formObject,
                        tabs: {
                          ...(formObject && formObject.tabs ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject.tabs && formObject.tabs[name]
                              ? formObject.tabs[name]
                              : {}),
                            [form]: {
                              ...(formObject &&
                              formObject.tabs &&
                              formObject.tabs[name] &&
                              formObject.tabs[name][form]
                                ? formObject.tabs[name][form]
                                : {}),
                              datasource: {
                                ...(formObject &&
                                formObject.tabs &&
                                formObject.tabs[name] &&
                                formObject.tabs[name][form] &&
                                formObject.tabs[name][form].datasource
                                  ? formObject.tabs[name][form].datasource
                                  : {}),
                                [columnKey]: {
                                  startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                                  endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                                }
                              },
                              ReferenceAttributes: {
                                JsonKey: ['tabs', name, 'form']
                              }
                            }
                          }
                        }
                      };
                    }
                  } else {
                    message.error(
                      'Please Select Morning Shift From ' +
                        item.startHour +
                        ':00' +
                        '~' +
                        item.endHour +
                        ':59'
                    );
                    if (subsectionName && subsectionName !== undefined) {
                      formObject = {
                        ...formObject,
                        [tabs]: {
                          ...(formObject && formObject[tabs] ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject[tabs] && formObject[tabs][name]
                              ? formObject[tabs][name]
                              : {}),
                            tabs: {
                              ...(formObject &&
                              formObject[tabs] &&
                              formObject[tabs][name] &&
                              formObject[tabs][name]['subsection']
                                ? formObject[tabs][name]['subsection']
                                : {}),
                              [subsectionName]: {
                                ...(formObject &&
                                formObject[tabs] &&
                                formObject[tabs][name] &&
                                formObject[tabs][name]['subsection'] &&
                                formObject[tabs][name]['subsection'][subsectionName]
                                  ? formObject[tabs][name]['subsection'][subsectionName]
                                  : {}),
                                form: {
                                  ...(formObject &&
                                  formObject[tabs] &&
                                  formObject[tabs][name] &&
                                  formObject[tabs][name]['subsection'] &&
                                  formObject[tabs][name]['subsection'][subsectionName] &&
                                  formObject[tabs][name]['subsection'][subsectionName]['form']
                                    ? formObject[tabs][name]['subsection'][subsectionName]['form']
                                    : {}),
                                  dynamicSubForm: {
                                    ...(formObject &&
                                    formObject['tabs'] &&
                                    formObject['tabs'][name] &&
                                    formObject['tabs'][name]['subsection'] &&
                                    formObject['tabs'][name]['subsection'][subsectionName] &&
                                    formObject['tabs'][name]['subsection'][subsectionName][
                                      'form'
                                    ] &&
                                    formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                      'dynamicSubForm'
                                    ]
                                      ? formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm']
                                      : {}),
                                    [subSubSection]: {
                                      ...(formObject &&
                                      formObject['tabs'] &&
                                      formObject['tabs'][name] &&
                                      formObject['tabs'][name]['subsection'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'][subSubSection]
                                        ? formObject['tabs'][name]['tabs'][subsectionName]['form'][
                                            'dynamicSubForm'
                                          ][subSubSection]
                                        : {}),
                                      [form]: {
                                        ...(formObject &&
                                        formObject['tabs'] &&
                                        formObject['tabs'][name] &&
                                        formObject['tabs'][name]['subsection'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection][form]
                                          ? formObject['tabs'][name]['subsection'][subsectionName][
                                              'form'
                                            ]['dynamicSubForm'][subSubSection][form]
                                          : {}),

                                        datasource: {
                                          ...(formObject &&
                                          formObject['tabs'] &&
                                          formObject['tabs'][name] &&
                                          formObject['tabs'][name]['tabs'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form]['datasource']
                                            ? formObject['tabs'][name]['subSubSection'][
                                                subsectionName
                                              ]['form']['dynamicSubForm'][subSubSection][form][
                                                'datasource'
                                              ]
                                            : {}),
                                          [datasourceKey]: {
                                            ...(formObject &&
                                            formObject['tabs'] &&
                                            formObject['tabs'][name] &&
                                            formObject['tabs'][name]['subSubSection'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form][
                                              'datasource'
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][
                                              'datasource'
                                            ][form][datasourceKey]
                                              ? formObject['tabs'][name]['subSubSection'][
                                                  subsectionName
                                                ]['form']['dynamicSubForm'][subSubSection][
                                                  'datasource'
                                                ][form][datasourceKey]
                                              : {}),

                                            [columnKey]: {}
                                          }
                                        },
                                        ReferenceAttributes: {
                                          JsonKey: [
                                            'tabs',
                                            name,
                                            'subsection',
                                            subsectionName,
                                            'form',
                                            'dynamicSubForm',
                                            subSubSection,
                                            'form'
                                          ]
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      };
                    } else if (!subsectionName && subsectionName === undefined) {
                      formObject = {
                        ...formObject,
                        tabs: {
                          ...(formObject && formObject.tabs ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject.tabs && formObject.tabs[name]
                              ? formObject.tabs[name]
                              : {}),
                            [form]: {
                              ...(formObject &&
                              formObject.tabs &&
                              formObject.tabs[name] &&
                              formObject.tabs[name][form]
                                ? formObject.tabs[name][form]
                                : {}),
                              datasource: {
                                ...(formObject &&
                                formObject.tabs &&
                                formObject.tabs[name] &&
                                formObject.tabs[name][form] &&
                                formObject.tabs[name][form].datasource
                                  ? formObject.tabs[name][form].datasource
                                  : {}),
                                [columnKey]: {}
                              },
                              ReferenceAttributes: {
                                JsonKey: ['tabs', name, 'form']
                              }
                            }
                          }
                        }
                      };
                    }
                  }
                } else if (
                  item.shift === Shift &&
                  item.shift === 'Night Shift' &&
                  currentDate === startDate &&
                  startDate !== endDate &&
                  startTime !== endTime
                ) {
                  //  hours >= 6 && hours < 18)
                  if (item.startHour <= startHours && item.endHour >= endHours) {
                    if (subsectionName && subsectionName !== undefined) {
                      formObject = {
                        ...formObject,
                        [tabs]: {
                          ...(formObject && formObject[tabs] ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject[tabs] && formObject[tabs][name]
                              ? formObject[tabs][name]
                              : {}),
                            tabs: {
                              ...(formObject &&
                              formObject[tabs] &&
                              formObject[tabs][name] &&
                              formObject[tabs][name]['subsection']
                                ? formObject[tabs][name]['subsection']
                                : {}),
                              [subsectionName]: {
                                ...(formObject &&
                                formObject[tabs] &&
                                formObject[tabs][name] &&
                                formObject[tabs][name]['subsection'] &&
                                formObject[tabs][name]['subsection'][subsectionName]
                                  ? formObject[tabs][name]['subsection'][subsectionName]
                                  : {}),
                                form: {
                                  ...(formObject &&
                                  formObject[tabs] &&
                                  formObject[tabs][name] &&
                                  formObject[tabs][name]['subsection'] &&
                                  formObject[tabs][name]['subsection'][subsectionName] &&
                                  formObject[tabs][name]['subsection'][subsectionName]['form']
                                    ? formObject[tabs][name]['subsection'][subsectionName]['form']
                                    : {}),
                                  dynamicSubForm: {
                                    ...(formObject &&
                                    formObject['tabs'] &&
                                    formObject['tabs'][name] &&
                                    formObject['tabs'][name]['subsection'] &&
                                    formObject['tabs'][name]['subsection'][subsectionName] &&
                                    formObject['tabs'][name]['subsection'][subsectionName][
                                      'form'
                                    ] &&
                                    formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                      'dynamicSubForm'
                                    ]
                                      ? formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm']
                                      : {}),
                                    [subSubSection]: {
                                      ...(formObject &&
                                      formObject['tabs'] &&
                                      formObject['tabs'][name] &&
                                      formObject['tabs'][name]['subsection'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'][subSubSection]
                                        ? formObject['tabs'][name]['tabs'][subsectionName]['form'][
                                            'dynamicSubForm'
                                          ][subSubSection]
                                        : {}),
                                      [form]: {
                                        ...(formObject &&
                                        formObject['tabs'] &&
                                        formObject['tabs'][name] &&
                                        formObject['tabs'][name]['subsection'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection][form]
                                          ? formObject['tabs'][name]['subsection'][subsectionName][
                                              'form'
                                            ]['dynamicSubForm'][subSubSection][form]
                                          : {}),

                                        datasource: {
                                          ...(formObject &&
                                          formObject['tabs'] &&
                                          formObject['tabs'][name] &&
                                          formObject['tabs'][name]['tabs'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form]['datasource']
                                            ? formObject['tabs'][name]['subSubSection'][
                                                subsectionName
                                              ]['form']['dynamicSubForm'][subSubSection][form][
                                                'datasource'
                                              ]
                                            : {}),
                                          [datasourceKey]: {
                                            ...(formObject &&
                                            formObject['tabs'] &&
                                            formObject['tabs'][name] &&
                                            formObject['tabs'][name]['subSubSection'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form][
                                              'datasource'
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][
                                              'datasource'
                                            ][form][datasourceKey]
                                              ? formObject['tabs'][name]['subSubSection'][
                                                  subsectionName
                                                ]['form']['dynamicSubForm'][subSubSection][
                                                  'datasource'
                                                ][form][datasourceKey]
                                              : {}),

                                            [columnKey]: {
                                              StartDate: moment(date[0]).format(
                                                'YYYY-MM-DD HH:mm:ss'
                                              ),
                                              EndDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                                            }
                                          }
                                        },
                                        ReferenceAttributes: {
                                          JsonKey: [
                                            'tabs',
                                            name,
                                            'subsection',
                                            subsectionName,
                                            'form',
                                            'dynamicSubForm',
                                            subSubSection,
                                            'form'
                                          ]
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      };
                    } else if (!subsectionName && subsectionName === undefined) {
                      formObject = {
                        ...formObject,
                        tabs: {
                          ...(formObject && formObject.tabs ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject.tabs && formObject.tabs[name]
                              ? formObject.tabs[name]
                              : {}),
                            [form]: {
                              ...(formObject &&
                              formObject.tabs &&
                              formObject.tabs[name] &&
                              formObject.tabs[name]['form']
                                ? formObject.tabs[name]['form']
                                : {}),
                              datasource: {
                                ...(formObject &&
                                formObject.tabs &&
                                formObject.tabs[name] &&
                                formObject.tabs[name][form] &&
                                formObject.tabs[name][form].datasource
                                  ? formObject.tabs[name][form].datasource
                                  : {}),
                                [columnKey]: {
                                  startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                                  endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                                }
                              },
                              ReferenceAttributes: {
                                JsonKey: ['tabs', name, 'form']
                              }
                            }
                          }
                        }
                      };
                    }
                  } else {
                    message.error(
                      'Please Select Night Shift From' +
                        item.startHour +
                        ':00' +
                        '~' +
                        item.endHour +
                        ':59'
                    );
                    formObject = cloneDeep(this.props.formObject);
                    if (subsectionName && subsectionName !== undefined) {
                      formObject = {
                        ...formObject,
                        [tabs]: {
                          ...(formObject && formObject[tabs] ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject[tabs] && formObject[tabs][name]
                              ? formObject[tabs][name]
                              : {}),
                            tabs: {
                              ...(formObject &&
                              formObject[tabs] &&
                              formObject[tabs][name] &&
                              formObject[tabs][name]['subsection']
                                ? formObject[tabs][name]['subsection']
                                : {}),
                              [subsectionName]: {
                                ...(formObject &&
                                formObject[tabs] &&
                                formObject[tabs][name] &&
                                formObject[tabs][name]['subsection'] &&
                                formObject[tabs][name]['subsection'][subsectionName]
                                  ? formObject[tabs][name]['subsection'][subsectionName]
                                  : {}),
                                form: {
                                  ...(formObject &&
                                  formObject[tabs] &&
                                  formObject[tabs][name] &&
                                  formObject[tabs][name]['subsection'] &&
                                  formObject[tabs][name]['subsection'][subsectionName] &&
                                  formObject[tabs][name]['subsection'][subsectionName]['form']
                                    ? formObject[tabs][name]['subsection'][subsectionName]['form']
                                    : {}),
                                  dynamicSubForm: {
                                    ...(formObject &&
                                    formObject['tabs'] &&
                                    formObject['tabs'][name] &&
                                    formObject['tabs'][name]['subsection'] &&
                                    formObject['tabs'][name]['subsection'][subsectionName] &&
                                    formObject['tabs'][name]['subsection'][subsectionName][
                                      'form'
                                    ] &&
                                    formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                      'dynamicSubForm'
                                    ]
                                      ? formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm']
                                      : {}),
                                    [subSubSection]: {
                                      ...(formObject &&
                                      formObject['tabs'] &&
                                      formObject['tabs'][name] &&
                                      formObject['tabs'][name]['subsection'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'][subSubSection]
                                        ? formObject['tabs'][name]['tabs'][subsectionName]['form'][
                                            'dynamicSubForm'
                                          ][subSubSection]
                                        : {}),
                                      [form]: {
                                        ...(formObject &&
                                        formObject['tabs'] &&
                                        formObject['tabs'][name] &&
                                        formObject['tabs'][name]['subsection'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection][form]
                                          ? formObject['tabs'][name]['subsection'][subsectionName][
                                              'form'
                                            ]['dynamicSubForm'][subSubSection][form]
                                          : {}),

                                        datasource: {
                                          ...(formObject &&
                                          formObject['tabs'] &&
                                          formObject['tabs'][name] &&
                                          formObject['tabs'][name]['tabs'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form]['datasource']
                                            ? formObject['tabs'][name]['subSubSection'][
                                                subsectionName
                                              ]['form']['dynamicSubForm'][subSubSection][form][
                                                'datasource'
                                              ]
                                            : {}),
                                          [datasourceKey]: {
                                            ...(formObject &&
                                            formObject['tabs'] &&
                                            formObject['tabs'][name] &&
                                            formObject['tabs'][name]['subSubSection'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form][
                                              'datasource'
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][
                                              'datasource'
                                            ][form][datasourceKey]
                                              ? formObject['tabs'][name]['subSubSection'][
                                                  subsectionName
                                                ]['form']['dynamicSubForm'][subSubSection][
                                                  'datasource'
                                                ][form][datasourceKey]
                                              : {}),

                                            [columnKey]: {}
                                          }
                                        },
                                        ReferenceAttributes: {
                                          JsonKey: [
                                            'tabs',
                                            name,
                                            'subsection',
                                            subsectionName,
                                            'form',
                                            'dynamicSubForm',
                                            subSubSection,
                                            'form'
                                          ]
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      };
                    } else if (!subsectionName && subsectionName === undefined) {
                      formObject = {
                        ...formObject,
                        tabs: {
                          ...(formObject && formObject.tabs ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject.tabs && formObject.tabs[name]
                              ? formObject.tabs[name]
                              : {}),
                            [form]: {
                              ...(formObject &&
                              formObject.tabs &&
                              formObject.tabs[name] &&
                              formObject.tabs[name][form]
                                ? formObject.tabs[name][form]
                                : {}),

                              datasource: {
                                ...(formObject &&
                                formObject.tabs &&
                                formObject.tabs[name] &&
                                formObject.tabs[name][form] &&
                                formObject.tabs[name][form].datasource
                                  ? formObject.tabs[name][form].datasource
                                  : {}),
                                [columnKey]: {}
                              },
                              ReferenceAttributes: {
                                JsonKey: ['tabs', name, 'form']
                              }
                            }
                          }
                        }
                      };
                    }
                  }
                } else if (
                  item.shift === Shift &&
                  item.shift === 'Night Shift' &&
                  currentDate === startDate &&
                  startDate === endDate
                ) {
                  //  hours >= 6 && hours < 18)
                  if (item.startHour <= startHours && item.endHour1 >= endHours) {
                    if (subsectionName && subsectionName !== undefined) {
                      formObject = {
                        ...formObject,
                        [tabs]: {
                          ...(formObject && formObject[tabs] ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject[tabs] && formObject[tabs][name]
                              ? formObject[tabs][name]
                              : {}),
                            tabs: {
                              ...(formObject &&
                              formObject[tabs] &&
                              formObject[tabs][name] &&
                              formObject[tabs][name]['subsection']
                                ? formObject[tabs][name]['subsection']
                                : {}),
                              [subsectionName]: {
                                ...(formObject &&
                                formObject[tabs] &&
                                formObject[tabs][name] &&
                                formObject[tabs][name]['subsection'] &&
                                formObject[tabs][name]['subsection'][subsectionName]
                                  ? formObject[tabs][name]['subsection'][subsectionName]
                                  : {}),
                                form: {
                                  ...(formObject &&
                                  formObject[tabs] &&
                                  formObject[tabs][name] &&
                                  formObject[tabs][name]['subsection'] &&
                                  formObject[tabs][name]['subsection'][subsectionName] &&
                                  formObject[tabs][name]['subsection'][subsectionName]['form']
                                    ? formObject[tabs][name]['subsection'][subsectionName]['form']
                                    : {}),
                                  dynamicSubForm: {
                                    ...(formObject &&
                                    formObject['tabs'] &&
                                    formObject['tabs'][name] &&
                                    formObject['tabs'][name]['subsection'] &&
                                    formObject['tabs'][name]['subsection'][subsectionName] &&
                                    formObject['tabs'][name]['subsection'][subsectionName][
                                      'form'
                                    ] &&
                                    formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                      'dynamicSubForm'
                                    ]
                                      ? formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm']
                                      : {}),
                                    [subSubSection]: {
                                      ...(formObject &&
                                      formObject['tabs'] &&
                                      formObject['tabs'][name] &&
                                      formObject['tabs'][name]['subsection'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'][subSubSection]
                                        ? formObject['tabs'][name]['tabs'][subsectionName]['form'][
                                            'dynamicSubForm'
                                          ][subSubSection]
                                        : {}),
                                      [form]: {
                                        ...(formObject &&
                                        formObject['tabs'] &&
                                        formObject['tabs'][name] &&
                                        formObject['tabs'][name]['subsection'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection][form]
                                          ? formObject['tabs'][name]['subsection'][subsectionName][
                                              'form'
                                            ]['dynamicSubForm'][subSubSection][form]
                                          : {}),

                                        datasource: {
                                          ...(formObject &&
                                          formObject['tabs'] &&
                                          formObject['tabs'][name] &&
                                          formObject['tabs'][name]['tabs'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form]['datasource']
                                            ? formObject['tabs'][name]['subSubSection'][
                                                subsectionName
                                              ]['form']['dynamicSubForm'][subSubSection][form][
                                                'datasource'
                                              ]
                                            : {}),
                                          [datasourceKey]: {
                                            ...(formObject &&
                                            formObject['tabs'] &&
                                            formObject['tabs'][name] &&
                                            formObject['tabs'][name]['subSubSection'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form][
                                              'datasource'
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][
                                              'datasource'
                                            ][form][datasourceKey]
                                              ? formObject['tabs'][name]['subSubSection'][
                                                  subsectionName
                                                ]['form']['dynamicSubForm'][subSubSection][
                                                  'datasource'
                                                ][form][datasourceKey]
                                              : {}),

                                            [columnKey]: {
                                              StartDate: moment(date[0]).format(
                                                'YYYY-MM-DD HH:mm:ss'
                                              ),
                                              EndDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                                            }
                                          }
                                        },
                                        ReferenceAttributes: {
                                          JsonKey: [
                                            'tabs',
                                            name,
                                            'subsection',
                                            subsectionName,
                                            'form',
                                            'dynamicSubForm',
                                            subSubSection,
                                            'form'
                                          ]
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      };
                    } else if (!subsectionName && subsectionName === undefined) {
                      formObject = {
                        ...formObject,
                        tabs: {
                          ...(formObject && formObject.tabs ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject.tabs && formObject.tabs[name]
                              ? formObject.tabs[name]
                              : {}),
                            [form]: {
                              ...(formObject &&
                              formObject.tabs &&
                              formObject.tabs[name] &&
                              formObject.tabs[name]['form']
                                ? formObject.tabs[name]['form']
                                : {}),
                              datasource: {
                                ...(formObject &&
                                formObject.tabs &&
                                formObject.tabs[name] &&
                                formObject.tabs[name][form] &&
                                formObject.tabs[name][form].datasource
                                  ? formObject.tabs[name][form].datasource
                                  : {}),
                                [columnKey]: {
                                  startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                                  endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                                }
                              }
                            },
                            ReferenceAttributes: {
                              JsonKey: ['tabs', name, 'form']
                            }
                          }
                        }
                      };
                    }
                  } else {
                    message.error(
                      'Please Select Night Shift From' + item.startHour + '~' + item.endHour
                    );
                    formObject = cloneDeep(this.props.formObject);
                    if (subsectionName && subsectionName !== undefined) {
                      formObject = {
                        ...formObject,
                        [tabs]: {
                          ...(formObject && formObject[tabs] ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject[tabs] && formObject[tabs][name]
                              ? formObject[tabs][name]
                              : {}),
                            tabs: {
                              ...(formObject &&
                              formObject[tabs] &&
                              formObject[tabs][name] &&
                              formObject[tabs][name]['subsection']
                                ? formObject[tabs][name]['subsection']
                                : {}),
                              [subsectionName]: {
                                ...(formObject &&
                                formObject[tabs] &&
                                formObject[tabs][name] &&
                                formObject[tabs][name]['subsection'] &&
                                formObject[tabs][name]['subsection'][subsectionName]
                                  ? formObject[tabs][name]['subsection'][subsectionName]
                                  : {}),
                                form: {
                                  ...(formObject &&
                                  formObject[tabs] &&
                                  formObject[tabs][name] &&
                                  formObject[tabs][name]['subsection'] &&
                                  formObject[tabs][name]['subsection'][subsectionName] &&
                                  formObject[tabs][name]['subsection'][subsectionName]['form']
                                    ? formObject[tabs][name]['subsection'][subsectionName]['form']
                                    : {}),
                                  dynamicSubForm: {
                                    ...(formObject &&
                                    formObject['tabs'] &&
                                    formObject['tabs'][name] &&
                                    formObject['tabs'][name]['subsection'] &&
                                    formObject['tabs'][name]['subsection'][subsectionName] &&
                                    formObject['tabs'][name]['subsection'][subsectionName][
                                      'form'
                                    ] &&
                                    formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                      'dynamicSubForm'
                                    ]
                                      ? formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm']
                                      : {}),
                                    [subSubSection]: {
                                      ...(formObject &&
                                      formObject['tabs'] &&
                                      formObject['tabs'][name] &&
                                      formObject['tabs'][name]['subsection'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'][subSubSection]
                                        ? formObject['tabs'][name]['tabs'][subsectionName]['form'][
                                            'dynamicSubForm'
                                          ][subSubSection]
                                        : {}),
                                      [form]: {
                                        ...(formObject &&
                                        formObject['tabs'] &&
                                        formObject['tabs'][name] &&
                                        formObject['tabs'][name]['subsection'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection][form]
                                          ? formObject['tabs'][name]['subsection'][subsectionName][
                                              'form'
                                            ]['dynamicSubForm'][subSubSection][form]
                                          : {}),

                                        datasource: {
                                          ...(formObject &&
                                          formObject['tabs'] &&
                                          formObject['tabs'][name] &&
                                          formObject['tabs'][name]['tabs'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form]['datasource']
                                            ? formObject['tabs'][name]['subSubSection'][
                                                subsectionName
                                              ]['form']['dynamicSubForm'][subSubSection][form][
                                                'datasource'
                                              ]
                                            : {}),
                                          [datasourceKey]: {
                                            ...(formObject &&
                                            formObject['tabs'] &&
                                            formObject['tabs'][name] &&
                                            formObject['tabs'][name]['subSubSection'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form][
                                              'datasource'
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][
                                              'datasource'
                                            ][form][datasourceKey]
                                              ? formObject['tabs'][name]['subSubSection'][
                                                  subsectionName
                                                ]['form']['dynamicSubForm'][subSubSection][
                                                  'datasource'
                                                ][form][datasourceKey]
                                              : {}),

                                            [columnKey]: {}
                                          }
                                        },
                                        ReferenceAttributes: {
                                          JsonKey: [
                                            'tabs',
                                            name,
                                            'subsection',
                                            subsectionName,
                                            'form',
                                            'dynamicSubForm',
                                            subSubSection,
                                            'form'
                                          ]
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      };
                    } else if (!subsectionName && subsectionName === undefined) {
                      formObject = {
                        ...formObject,
                        tabs: {
                          ...(formObject && formObject.tabs ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject.tabs && formObject.tabs[name]
                              ? formObject.tabs[name]
                              : {}),
                            [form]: {
                              ...(formObject &&
                              formObject.tabs &&
                              formObject.tabs[name] &&
                              formObject.tabs[name]['form']
                                ? formObject.tabs[name]['form']
                                : {}),
                              datasource: {
                                ...(formObject &&
                                formObject.tabs &&
                                formObject.tabs[name] &&
                                formObject.tabs[name][form] &&
                                formObject.tabs[name][form].datasource
                                  ? formObject.tabs[name][form].datasource
                                  : {}),
                                [columnKey]: {}
                              },
                              ReferenceAttributes: {
                                JsonKey: ['tabs', name, 'form']
                              }
                            }
                          }
                        }
                      };
                    }
                  }
                } else if (
                  item.shift === Shift &&
                  item.shift === 'Night Shift' &&
                  currentDate !== startDate &&
                  startDate === endDate
                ) {
                  //  hours >= 6 && hours < 18)

                  if (item.startHour2 <= startHours && item.endHour2 >= endHours) {
                    if (subsectionName && subsectionName !== undefined) {
                      formObject = {
                        ...formObject,
                        [tabs]: {
                          ...(formObject && formObject[tabs] ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject[tabs] && formObject[tabs][name]
                              ? formObject[tabs][name]
                              : {}),
                            tabs: {
                              ...(formObject &&
                              formObject[tabs] &&
                              formObject[tabs][name] &&
                              formObject[tabs][name]['subsection']
                                ? formObject[tabs][name]['subsection']
                                : {}),
                              [subsectionName]: {
                                ...(formObject &&
                                formObject[tabs] &&
                                formObject[tabs][name] &&
                                formObject[tabs][name]['subsection'] &&
                                formObject[tabs][name]['subsection'][subsectionName]
                                  ? formObject[tabs][name]['subsection'][subsectionName]
                                  : {}),
                                form: {
                                  ...(formObject &&
                                  formObject[tabs] &&
                                  formObject[tabs][name] &&
                                  formObject[tabs][name]['subsection'] &&
                                  formObject[tabs][name]['subsection'][subsectionName] &&
                                  formObject[tabs][name]['subsection'][subsectionName]['form']
                                    ? formObject[tabs][name]['subsection'][subsectionName]['form']
                                    : {}),
                                  dynamicSubForm: {
                                    ...(formObject &&
                                    formObject['tabs'] &&
                                    formObject['tabs'][name] &&
                                    formObject['tabs'][name]['subsection'] &&
                                    formObject['tabs'][name]['subsection'][subsectionName] &&
                                    formObject['tabs'][name]['subsection'][subsectionName][
                                      'form'
                                    ] &&
                                    formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                      'dynamicSubForm'
                                    ]
                                      ? formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm']
                                      : {}),
                                    [subSubSection]: {
                                      ...(formObject &&
                                      formObject['tabs'] &&
                                      formObject['tabs'][name] &&
                                      formObject['tabs'][name]['subsection'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'][subSubSection]
                                        ? formObject['tabs'][name]['tabs'][subsectionName]['form'][
                                            'dynamicSubForm'
                                          ][subSubSection]
                                        : {}),
                                      [form]: {
                                        ...(formObject &&
                                        formObject['tabs'] &&
                                        formObject['tabs'][name] &&
                                        formObject['tabs'][name]['subsection'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection][form]
                                          ? formObject['tabs'][name]['subsection'][subsectionName][
                                              'form'
                                            ]['dynamicSubForm'][subSubSection][form]
                                          : {}),

                                        datasource: {
                                          ...(formObject &&
                                          formObject['tabs'] &&
                                          formObject['tabs'][name] &&
                                          formObject['tabs'][name]['tabs'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form]['datasource']
                                            ? formObject['tabs'][name]['subSubSection'][
                                                subsectionName
                                              ]['form']['dynamicSubForm'][subSubSection][form][
                                                'datasource'
                                              ]
                                            : {}),
                                          [datasourceKey]: {
                                            ...(formObject &&
                                            formObject['tabs'] &&
                                            formObject['tabs'][name] &&
                                            formObject['tabs'][name]['subSubSection'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form][
                                              'datasource'
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][
                                              'datasource'
                                            ][form][datasourceKey]
                                              ? formObject['tabs'][name]['subSubSection'][
                                                  subsectionName
                                                ]['form']['dynamicSubForm'][subSubSection][
                                                  'datasource'
                                                ][form][datasourceKey]
                                              : {}),

                                            [columnKey]: {
                                              StartDate: moment(date[0]).format(
                                                'YYYY-MM-DD HH:mm:ss'
                                              ),
                                              EndDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                                            }
                                          }
                                        },
                                        ReferenceAttributes: {
                                          JsonKey: [
                                            'tabs',
                                            name,
                                            'subsection',
                                            subsectionName,
                                            'form',
                                            'dynamicSubForm',
                                            subSubSection,
                                            'form'
                                          ]
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      };
                    } else if (!subsectionName && subsectionName === undefined) {
                      formObject = {
                        ...formObject,
                        tabs: {
                          ...(formObject && formObject.tabs ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject.tabs && formObject.tabs[name]
                              ? formObject.tabs[name]
                              : {}),
                            [form]: {
                              ...(formObject &&
                              formObject.tabs &&
                              formObject.tabs[name] &&
                              formObject.tabs[name]['form']
                                ? formObject.tabs[name]['form']
                                : {}),
                              datasource: {
                                ...(formObject &&
                                formObject.tabs &&
                                formObject.tabs[name] &&
                                formObject[tabs][name][form] &&
                                formObject.tabs[name][form].datasource
                                  ? formObject.tabs[name][form].datasource
                                  : {}),
                                [columnKey]: {
                                  startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                                  endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                                }
                              },
                              ReferenceAttributes: {
                                JsonKey: ['tabs', name, 'form']
                              }
                            }
                          }
                        }
                      };
                    }
                  } else {
                    message.error(
                      'Please Select Night Shift From' + item.startHour + '~' + item.endHour
                    );
                    formObject = cloneDeep(this.props.formObject);
                    if (subsectionName && subsectionName !== undefined) {
                      formObject = {
                        ...formObject,
                        [tabs]: {
                          ...(formObject && formObject[tabs] ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject[tabs] && formObject[tabs][name]
                              ? formObject[tabs][name]
                              : {}),
                            tabs: {
                              ...(formObject &&
                              formObject[tabs] &&
                              formObject[tabs][name] &&
                              formObject[tabs][name]['subsection']
                                ? formObject[tabs][name]['subsection']
                                : {}),
                              [subsectionName]: {
                                ...(formObject &&
                                formObject[tabs] &&
                                formObject[tabs][name] &&
                                formObject[tabs][name]['subsection'] &&
                                formObject[tabs][name]['subsection'][subsectionName]
                                  ? formObject[tabs][name]['subsection'][subsectionName]
                                  : {}),
                                form: {
                                  ...(formObject &&
                                  formObject[tabs] &&
                                  formObject[tabs][name] &&
                                  formObject[tabs][name]['subsection'] &&
                                  formObject[tabs][name]['subsection'][subsectionName] &&
                                  formObject[tabs][name]['subsection'][subsectionName]['form']
                                    ? formObject[tabs][name]['subsection'][subsectionName]['form']
                                    : {}),
                                  dynamicSubForm: {
                                    ...(formObject &&
                                    formObject['tabs'] &&
                                    formObject['tabs'][name] &&
                                    formObject['tabs'][name]['subsection'] &&
                                    formObject['tabs'][name]['subsection'][subsectionName] &&
                                    formObject['tabs'][name]['subsection'][subsectionName][
                                      'form'
                                    ] &&
                                    formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                      'dynamicSubForm'
                                    ]
                                      ? formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm']
                                      : {}),
                                    [subSubSection]: {
                                      ...(formObject &&
                                      formObject['tabs'] &&
                                      formObject['tabs'][name] &&
                                      formObject['tabs'][name]['subsection'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'] &&
                                      formObject['tabs'][name]['subsection'][subsectionName][
                                        'form'
                                      ]['dynamicSubForm'][subSubSection]
                                        ? formObject['tabs'][name]['tabs'][subsectionName]['form'][
                                            'dynamicSubForm'
                                          ][subSubSection]
                                        : {}),
                                      [form]: {
                                        ...(formObject &&
                                        formObject['tabs'] &&
                                        formObject['tabs'][name] &&
                                        formObject['tabs'][name]['subsection'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection] &&
                                        formObject['tabs'][name]['subsection'][subsectionName][
                                          'form'
                                        ]['dynamicSubForm'][subSubSection][form]
                                          ? formObject['tabs'][name]['subsection'][subsectionName][
                                              'form'
                                            ]['dynamicSubForm'][subSubSection][form]
                                          : {}),

                                        datasource: {
                                          ...(formObject &&
                                          formObject['tabs'] &&
                                          formObject['tabs'][name] &&
                                          formObject['tabs'][name]['tabs'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form] &&
                                          formObject['tabs'][name]['subsection'][subsectionName][
                                            'form'
                                          ]['dynamicSubForm'][subSubSection][form]['datasource']
                                            ? formObject['tabs'][name]['subSubSection'][
                                                subsectionName
                                              ]['form']['dynamicSubForm'][subSubSection][form][
                                                'datasource'
                                              ]
                                            : {}),
                                          [datasourceKey]: {
                                            ...(formObject &&
                                            formObject['tabs'] &&
                                            formObject['tabs'][name] &&
                                            formObject['tabs'][name]['subSubSection'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][form][
                                              'datasource'
                                            ] &&
                                            formObject['tabs'][name]['subSubSection'][
                                              subsectionName
                                            ]['form']['dynamicSubForm'][subSubSection][
                                              'datasource'
                                            ][form][datasourceKey]
                                              ? formObject['tabs'][name]['subSubSection'][
                                                  subsectionName
                                                ]['form']['dynamicSubForm'][subSubSection][
                                                  'datasource'
                                                ][form][datasourceKey]
                                              : {}),

                                            [columnKey]: {}
                                          }
                                        },
                                        ReferenceAttributes: {
                                          JsonKey: [
                                            'tabs',
                                            name,
                                            'subsection',
                                            subsectionName,
                                            'form',
                                            'dynamicSubForm',
                                            subSubSection,
                                            'form'
                                          ]
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      };
                    } else if (!subsectionName && subsectionName === undefined) {
                      formObject = {
                        ...formObject,
                        tabs: {
                          ...(formObject && formObject.tabs ? formObject.tabs : {}),
                          [name]: {
                            ...(formObject && formObject.tabs && formObject.tabs[name]
                              ? formObject.tabs[name]
                              : {}),
                            [form]: {
                              ...(formObject &&
                              formObject.tabs &&
                              formObject.tabs[name] &&
                              formObject.tabs[name]['form']
                                ? formObject.tabs[name]['form']
                                : {}),

                              datasource: {
                                ...(formObject &&
                                formObject.tabs &&
                                formObject.tabs[name] &&
                                formObject.tabs[name][form] &&
                                formObject.tabs[name][form].datasource
                                  ? formObject.tabs[name][form].datasource
                                  : {}),
                                [columnKey]: {}
                              },
                              ReferenceAttributes: {
                                JsonKey: ['tabs', name, 'form']
                              }
                            }
                          }
                        }
                      };
                    }
                  }
                }
                return {};
              });
          }
          if (formObject && formObject['tabs']) {
            Object.keys(formObject['tabs']).map((modelItem) => {
              if (modelItem !== 'defaultData' && modelItem !== name) {
                let datasource =
                  formObject['tabs'] &&
                  formObject['tabs'][modelItem] &&
                  formObject[tabs][modelItem][form] &&
                  formObject['tabs'][modelItem][form].datasource;
                if (datasource && datasource[columnKey] && datasource[columnKey]['endDate']) {
                  let startDate1 = datasource && datasource[columnKey]['startDate'];
                  let endDate1 = datasource && datasource[columnKey]['endDate'];
                  if (startDate1 <= endDate2 && startDate2 <= endDate1) {
                    message.error('Dates Overlapping with' + startDate1 + '~' + endDate1);
                    formObject = cloneDeep(this.props.formObject);
                    formObject = {
                      ...formObject,
                      tabs: {
                        ...(formObject && formObject.tabs ? formObject.tabs : {}),
                        [name]: {
                          ...(formObject && formObject.tabs && formObject.tabs[name]
                            ? formObject.tabs[name]
                            : {}),
                          [form]: {
                            ...(formObject &&
                            formObject.tabs &&
                            formObject.tabs[name] &&
                            formObject.tabs[name][form]
                              ? formObject.tabs[name][form]
                              : {}),
                            datasource: {
                              ...(formObject &&
                              formObject.tabs &&
                              formObject.tabs[name] &&
                              formObject.tabs[name][form] &&
                              formObject.tabs[name][form].datasource
                                ? formObject.tabs[name][form].datasource
                                : {}),
                              [columnKey]: {}
                            },
                            ReferenceAttributes: {
                              JsonKey: ['tabs', name, 'form']
                            }
                          }
                        }
                      }
                    };
                  } else {
                    formObject = {
                      ...formObject,
                      tabs: {
                        ...(formObject && formObject.tabs ? formObject.tabs : {}),
                        [name]: {
                          ...(formObject && formObject.tabs && formObject.tabs[name]
                            ? formObject.tabs[name]
                            : {}),
                          [form]: {
                            ...(formObject &&
                            formObject.tabs &&
                            formObject.tabs[name] &&
                            formObject.tabs[name][form]
                              ? formObject.tabs[name][form]
                              : {}),
                            datasource: {
                              ...(formObject &&
                              formObject.tabs &&
                              formObject.tabs[name] &&
                              formObject[tabs][name][form] &&
                              formObject.tabs[name][form].datasource
                                ? formObject.tabs[name][form].datasource
                                : {}),
                              [columnKey]: {
                                startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                                endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
                              }
                            },
                            ReferenceAttributes: {
                              JsonKey: ['tabs', name, 'form']
                            }
                          }
                        }
                      }
                    };
                  }
                }
              }
              //  else {
              //   formObject = {
              //     ...formObject,
              //     tabs: {
              //       ...(formObject && formObject.tabs ? formObject.tabs : {}),
              //       [name]: {
              //         ...(formObject && formObject.tabs && formObject.tabs[name]
              //           ? formObject.tabs[name]
              //           : {}),
              //         datasource: {
              //           ...(formObject &&
              //           formObject.tabs &&
              //           formObject.tabs[name] &&
              //           formObject.tabs[name].datasource
              //             ? formObject.tabs[name].datasource
              //             : {}),
              //           [columnKey]: {
              //             startDate: moment(date[0]).format("YYYY-MM-DD HH:mm:ss"),
              //             endDate: moment(date[1]).format("YYYY-MM-DD HH:mm:ss"),
              //           },
              //         },
              //       },
              //     },
              //   };
              // }
              return {};
            });
          }
        }
        this.props.downTimeData(formObject, false);
      }
    } else {
      message.error('Please Input Start & End Times');
    }
    this.props.downTimeData(formObject, false);
  };

  onCheckOK = (date, columnKey, columns) => {
    let { formObject } = this.state;
    let startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm');
    let endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm');
    if (date[0] !== null && date[1] !== null) {
      if (startDate2 === endDate2) {
        message.error('StartDateTime and EndDateTime should not be same');
        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: {}
          }
        };
        this.props.downTimeData(formObject, false);
      } else if (startDate2 !== endDate2) {
        startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm');
        endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm');
        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: {
              startDate: moment(date[0]).format('YYYY-MM-DD HH:mm'),
              endDate: moment(date[1]).format('YYYY-MM-DD HH:mm')
            }
          }
        };
        let masterData = [
          {
            startHour1: '06:00',
            endHour1: '23:00',
            startHour2: '00:00',
            endHour2: '05:59'
          }
        ];
        if (newLocal) {
          let check = formObject && formObject['mainHeaderValue'].Date;
          let startDate = moment(check).format('DD');
          let endDate = moment(date[1]).format('DD');
          let startHours = moment(date[0]).format('HH:mm');

          let endHours = moment(date[1]).format('HH:mm');

          masterData &&
            masterData.map((item) => {
              //item.startHour1 <= startHours && endHours <= item.endHour2 && startDate !== endDate
              // item.startHour2 >= startHours && endHours <= item.endHour2 && startDate < endDate && startDate !== endDate
              //(item.startHour1 <= startHours && endHours <= item.endHour1 && startDate === endDate
              if (
                item.startHour1 <= startHours &&
                endHours <= item.endHour2 &&
                startDate !== endDate
              ) {
                //  hours >= 6 && hours < 18)

                formObject = {
                  ...formObject,
                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: {
                      startDate: moment(date[0]).format('YYYY-MM-DD HH:mm'),
                      endDate: moment(date[1]).format('YYYY-MM-DD HH:mm')
                    }
                  }
                };
                this.props.downTimeData(formObject, false);
              } else if (
                item.startHour2 <= startHours &&
                endHours <= item.endHour2 &&
                startDate < endDate &&
                startDate !== endDate
              ) {
                formObject = {
                  ...formObject,
                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: {
                      startDate: moment(date[0]).format('YYYY-MM-DD HH:mm'),
                      endDate: moment(date[1]).format('YYYY-MM-DD HH:mm')
                    }
                  }
                };
                this.props.downTimeData(formObject, false);
              } else if (
                item.startHour1 <= startHours &&
                endHours <= item.endHour1 &&
                startDate === endDate
              ) {
                formObject = {
                  ...formObject,
                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: {
                      startDate: moment(date[0]).format('YYYY-MM-DD HH:mm'),
                      endDate: moment(date[1]).format('YYYY-MM-DD HH:mm')
                    }
                  }
                };
                this.props.downTimeData(formObject, false);
              } else {
                message.error('Please choose correct time ranges');
                formObject = {
                  ...formObject,
                  mainHeaderValue: {
                    ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                    [columnKey]: {}
                  }
                };
                this.props.downTimeData(formObject, false);
              }
              return {};
            });

          // let { formObject } = this.props
          let selectedStartDate = '';
          let selectedEndDate = '';
          selectedStartDate = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
          selectedEndDate = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');

          if (columns && columns.autoGeneratedField) {
            var duration = moment.duration(date[1].diff(date[0]));
            var hours = duration.asHours();
            formObject = {
              ...formObject,
              mainHeaderValue: {
                ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                [columnKey]: {
                  startDate: moment(selectedStartDate).format('YYYY-MM-DD HH:mm:ss'),
                  endDate: moment(selectedEndDate).format('YYYY-MM-DD HH:mm:ss')
                },
                [columns.autoGeneratedField]: hours
              }
            };
          } else {
            formObject = {
              ...formObject,
              mainHeaderValue: {
                ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                [columnKey]: {
                  startDate: moment(selectedStartDate).format('YYYY-MM-DD HH:mm:ss'),
                  endDate: moment(selectedEndDate).format('YYYY-MM-DD HH:mm:ss')
                }
              }
            };
          }
          this.props.downTimeData(formObject, false);
        }
        this.props.downTimeData(formObject, false);
      }
    } else {
      message.error('Please Input Start & End Times');
    }
  };

  onMainChange = (date, columnKey, columns) => {
    let { formObject } = this.props;
    let selectedStartDate = '';
    let selectedEndDate = '';
    // if (moment(date[0]).isSame(date[1])) {
    //     selectedStartDate = moment(date[0]).format("YYYY-MM-DD 00:00:00")
    //     selectedEndDate = moment(date[1]).format("YYYY-MM-DD 01:00:00")
    // } else {
    selectedStartDate = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
    selectedEndDate = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
    // }

    if (columns && columns.autoGeneratedField) {
      var duration = moment.duration(date[1].diff(date[0]));
      var hours = duration.asHours();
      formObject = {
        ...formObject,
        mainHeaderValue: {
          ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
          [columnKey]: {
            startDate: moment(selectedStartDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(selectedEndDate).format('YYYY-MM-DD HH:mm:ss')
          },
          [columns.autoGeneratedField]: hours
        }
      };
    } else {
      formObject = {
        ...formObject,
        mainHeaderValue: {
          ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
          [columnKey]: {
            startDate: moment(selectedStartDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(selectedEndDate).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      };
    }
    this.props.downTimeData(formObject, false);
  };

  onDateHeaderMainChange = (date, columnKey) => {
    let { formObject } = this.state;
    let formObjectNew = {};
    if (
      columnKey === 'Date' &&
      formObject &&
      formObject.mainHeader &&
      Array.isArray(formObject.mainHeader)
    ) {
      //Main Header Clear
      let allAvailableMainHeader = [];
      allAvailableMainHeader = formObject.mainHeader.map((formMain) => {
        if (formMain && formMain.key) {
          return formMain.key;
        }
        return {};
      });
      if (allAvailableMainHeader && allAvailableMainHeader.indexOf('TimeSelection') > -1) {
        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss'),
            TimeSelection: ''
          }
        };
      }
      let datasourceNew = {};
      datasourceNew = formObject.tabs;

      Object.keys(formObject.tabs).map((modelKey) => {
        let allAvailableColumns = [];
        if (formObject.tabs[modelKey].form && formObject.tabs[modelKey].form.columns) {
          allAvailableColumns = formObject.tabs[modelKey].form.columns.map((colu) => {
            if (colu && colu.key) {
              return colu.key;
            }

            return {};
          });
        }

        if (
          formObject.tabs[modelKey].form &&
          formObject.tabs[modelKey].form.datasource &&
          allAvailableColumns &&
          (allAvailableColumns.indexOf('TimeSelection') > -1 ||
            allAvailableColumns.indexOf('Time Selection') > -1)
        ) {
          let datasourceNews = {};
          if (
            allAvailableColumns.indexOf('Time Selection') > -1 ||
            allAvailableColumns.indexOf('TimeSelection') > -1
          ) {
            datasourceNews = {
              ...formObject.tabs[modelKey].form.datasource,
              TimeSelection: {}
            };
          } else {
            datasourceNews = {
              ...formObject.tabs[modelKey].form.datasource
            };
          }

          datasourceNew[modelKey] = {
            ...formObject.tabs[modelKey],
            form: {
              ...formObject.tabs[modelKey].form,
              datasource: datasourceNews
            }
          };
        } else {
          datasourceNew[modelKey] = formObject.tabs[modelKey];
        }
        return {};
      });
      formObjectNew = {
        ...formObject,
        mainHeaderValue: {
          ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
          [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
        tabs: datasourceNew
      };
      this.props.downTimeData(formObjectNew);
    } else {
      formObject = {
        ...formObject,
        mainHeaderValue: {
          ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
          [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
        }
      };
      this.props.downTimeData(formObject, false);
    }
  };

  inputHeaderNumberChange = (minValue, maxValue, value, key, columns) => {
    if (
      (value >= minValue &&
        value <= maxValue &&
        minValue !== undefined &&
        maxValue !== undefined) ||
      value === ''
    ) {
      this.onInputHeaderMainChange(parseInt(value), key, columns, minValue, maxValue);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputHeaderMainChange(parseInt(value), key, columns, minValue, maxValue);
    } else {
      this.onInputHeaderMainChange('', key, columns, minValue, maxValue);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  onInputHeaderMainChange = (columnValue, columnKey, columns, min) => {
    let { formObject } = this.state;
    // if (columnValue < 0 && typeof (columnValue) === "number" ) {
    if (min !== undefined && min > 0 && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      let mainHeaderValueNew = {
        ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
        [columnKey]: ''
      };
      formObject = {
        ...formObject,
        mainHeaderValue: mainHeaderValueNew,
        tabs: {
          ...(formObject && formObject.tabs ? formObject.tabs : {}),
          defaultData: {
            ...(formObject && formObject.tabs && formObject.tabs['defaultData']
              ? formObject.tabs['defaultData']
              : {})
          }
        }
      };
      this.props.downTimeData(formObject, false);
    } else if (min === undefined && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      let mainHeaderValueNew = {
        ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
        [columnKey]: ''
      };
      formObject = {
        ...formObject,
        mainHeaderValue: mainHeaderValueNew,
        tabs: {
          ...(formObject && formObject.tabs ? formObject.tabs : {}),
          defaultData: {
            ...(formObject && formObject.tabs && formObject.tabs['defaultData']
              ? formObject.tabs['defaultData']
              : {})
          }
        }
      };
      this.props.downTimeData(formObject, false);
    }

    // }
    else {
      if (
        columnKey === 'NumberOfModels' ||
        columnKey === 'TileSizeCount' ||
        columnKey === 'NoOfChangeovers'
      ) {
        let modelList = {};
        if (columnKey === 'TileSizeCount') {
          for (let i = 1; i <= columnValue; i++) {
            let count = i;
            modelList['TileSize-' + count] = {
              ...(formObject && formObject.tabs && formObject.tabs['defaultData']
                ? formObject.tabs['defaultData']
                : {})
            };
          }
          Object.keys(formObject.tabs)
            .filter((key) => {
              return key !== 'defaultData' && !key.startsWith('TileSize');
            })
            .map((key) => {
              modelList[key] = formObject.tabs[key];
              return {};
            });
        } else if (columnKey === 'NoOfChangeovers') {
          for (let i = 0; i <= columnValue; i++) {
            let count = i + 1;
            modelList['TileSize-' + count] = {
              ...(formObject && formObject.tabs && formObject.tabs['defaultData']
                ? formObject.tabs['defaultData']
                : {})
            };
          }
        } else if (columnKey === 'NoOfChangeovers') {
          for (let i = 0; i <= columnValue; i++) {
            let count = i + 1;
            modelList['Model' + count] = {
              ...(formObject && formObject.tabs && formObject.tabs['defaultData']
                ? formObject.tabs['defaultData']
                : {})
            };
          }
        }
        let mainHeaderValueNew = {};
        if (columns.autoGeneratedField) {
          mainHeaderValueNew = {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue,
            [columns.autoGeneratedField]: 0
          };
        } else {
          mainHeaderValueNew = {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue
          };
        }

        formObject = {
          ...formObject,
          mainHeaderValue: mainHeaderValueNew,
          tabs: {
            // ...(formObject && formObject.tabs ? formObject.tabs : {}),
            defaultData: {
              ...(formObject && formObject.tabs && formObject.tabs['defaultData']
                ? formObject.tabs['defaultData']
                : {})
            },
            ...modelList
          }
        };

        this.props.downTimeData(formObject, false);
      } else if (columnKey === 'ModelChange' && columnValue === 'No') {
        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue,
            NoOfChangeovers: 0
          }
          // tabs: {
          //   ["defaultData"]:
          //     formObject && formObject.tabs && formObject.tabs["defaultData"]
          //       ? formObject.tabs["defaultData"]
          //       : {},
          //   ["Model1"]: {
          //     ...(formObject && formObject.tabs && formObject.tabs["defaultData"]
          //       ? formObject.tabs["defaultData"]
          //       : {}),
          //   },
          // },
        };

        this.props.downTimeData(formObject, false);
      } else if (columnKey === 'MouldChange' && columnValue === 'No') {
        let mainHeaderNew = formObject.mainHeader.map((mainHead) => {
          if (columns.disableMouldYes && columns.autoGeneratedField === mainHead.key) {
            return {
              ...mainHead,
              disabled: true
            };
          } else {
            return mainHead;
          }
        });
        formObject = {
          ...formObject,
          mainHeader: mainHeaderNew,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue,
            [columns.autoGeneratedField]: columns.autoGeneratedValue
            // "NoOfChangeovers": columns.autoGeneratedValue
          },
          tabs: {
            defaultData:
              formObject && formObject.tabs && formObject.tabs['defaultData']
                ? formObject.tabs['defaultData']
                : {},
            'TileSize-1': {
              ...(formObject && formObject.tabs && formObject.tabs['defaultData']
                ? formObject.tabs['defaultData']
                : {})
            }
          }
        };
        this.props.downTimeData(formObject, false);
      } else if (
        columnKey === 'MouldChange' &&
        columnValue === 'Yes' &&
        columns.autoGeneratedValueOnYes
      ) {
        // Press
        let modelList = {};
        for (let i = 0; i <= columns.autoGeneratedValueOnYes; i++) {
          let count = i + 1;
          modelList['TileSize-' + count] = {
            ...(formObject && formObject.tabs && formObject.tabs['defaultData']
              ? formObject.tabs['defaultData']
              : {})
          };
        }
        let mainHeaderNew = formObject.mainHeader.map((mainHead) => {
          return {
            ...mainHead,
            disabled: false
          };
        });

        formObject = {
          ...formObject,
          mainHeader: mainHeaderNew,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columns.autoGeneratedField]: columns.autoGeneratedValueOnYes,
            [columnKey]: columnValue
          },
          tabs: {
            defaultData: {
              ...(formObject && formObject.tabs && formObject.tabs['defaultData']
                ? formObject.tabs['defaultData']
                : {})
            },
            ...modelList
          }
        };
        this.props.downTimeData(formObject, false);
      } else if (columnKey === 'MouldChange' && columnValue === 'Yes' && columns.disableMouldYes) {
        // H Drier

        let mainHeaderNew = formObject.mainHeader.map((mainHead) => {
          return {
            ...mainHead,
            disabled: false
          };
        });

        formObject = {
          ...formObject,
          mainHeader: mainHeaderNew,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue
          }
        };
        this.props.downTimeData(formObject, false);
      } else if (columnKey === 'Shift') {
        formObject &&
          formObject['tabs'] &&
          Object.keys(formObject['tabs']).map((key) => {
            if (
              key !== 'defaultData' &&
              formObject['tabs'] &&
              formObject['tabs'][key] &&
              formObject['tabs'][key].form &&
              formObject['tabs'][key].form['columns'] &&
              formObject['tabs'][key].form['datasource']
            ) {
              Object.keys(
                formObject['tabs'] &&
                  formObject['tabs'][key] &&
                  formObject['tabs'][key].form &&
                  formObject['tabs'][key].form['columns'] &&
                  formObject['tabs'][key].form['datasource']
              ).map((item) => {
                if (item === 'TimeSelection') {
                  formObject = {
                    ...formObject,
                    mainHeaderValue: {
                      ...(formObject && formObject.mainHeaderValue
                        ? formObject.mainHeaderValue
                        : {}),
                      [columnKey]: columnValue
                    },
                    tabs: {
                      ...(formObject && formObject.tabs ? formObject.tabs : {}),
                      [key]: {
                        ...(formObject && formObject.tabs && formObject.tabs[key]
                          ? formObject.tabs[key]
                          : {}),
                        form: {
                          ...(formObject && formObject.tabs[key].form),
                          datasource: {
                            ...(formObject &&
                            formObject.tabs &&
                            formObject.tabs[key] &&
                            formObject.tabs[key].form.datasource
                              ? formObject.tabs[key].form.datasource
                              : {}),
                            TimeSelection: ''
                          }
                        }
                      }
                    }
                  };
                }
                return {};
              });
              if (
                formObject &&
                formObject['tabs'] &&
                formObject['tabs'][key] &&
                formObject['tabs'][key].tabs &&
                formObject['tabs'][key].tabs['Process Changeover'] &&
                formObject['tabs'][key].tabs['Process Changeover'].form &&
                formObject['tabs'][key].tabs['Process Changeover'].form['dynamicSubForm']
              ) {
                Object.keys(
                  formObject &&
                    formObject['tabs'] &&
                    formObject['tabs'][key] &&
                    formObject['tabs'][key].tabs &&
                    formObject['tabs'][key].tabs['Process Changeover'] &&
                    formObject['tabs'][key].tabs['Process Changeover'].form &&
                    formObject['tabs'][key].tabs['Process Changeover'].form['dynamicSubForm']
                ).map((itemData) => {
                  Object.keys(
                    formObject['tabs'][key].tabs['Process Changeover'].form['dynamicSubForm'][
                      itemData
                    ]
                  ).map(() => {
                    Object.keys(
                      formObject['tabs'][key].tabs['Process Changeover'].form['dynamicSubForm'][
                        itemData
                      ] &&
                        formObject['tabs'][key].tabs['Process Changeover'].form['dynamicSubForm'][
                          itemData
                        ].columns &&
                        formObject['tabs'][key].tabs['Process Changeover'].form['dynamicSubForm'][
                          itemData
                        ].datasource
                    ).map((e) => {
                      if (
                        formObject['tabs'][key].tabs['Process Changeover'].form['dynamicSubForm'][
                          itemData
                        ] &&
                        formObject['tabs'][key].tabs['Process Changeover'].form['dynamicSubForm'][
                          itemData
                        ].columns &&
                        formObject['tabs'][key].tabs['Process Changeover'].form['dynamicSubForm'][
                          itemData
                        ].datasource[e]
                      ) {
                        formObject = {
                          ...formObject,
                          mainHeaderValue: {
                            ...(formObject && formObject.mainHeaderValue
                              ? formObject.mainHeaderValue
                              : {}),
                            [columnKey]: columnValue
                          },
                          tabs: {
                            ...(formObject && formObject.tabs ? formObject.tabs : {}),
                            [key]: {
                              ...(formObject && formObject.tabs && formObject.tabs[key]
                                ? formObject.tabs[key]
                                : {}),
                              datasource: {
                                ...(formObject &&
                                formObject.tabs &&
                                formObject.tabs[key] &&
                                formObject.tabs[key].datasource
                                  ? formObject.tabs[key].datasource
                                  : {}),
                                TimeSelection: ''
                              },
                              tabs: {
                                ...(formObject &&
                                formObject.tabs &&
                                formObject.tabs[key] &&
                                formObject.tabs[key].tabs
                                  ? formObject &&
                                    formObject.tabs &&
                                    formObject.tabs[key] &&
                                    formObject.tabs[key].tabs
                                  : {}),
                                'Process Changeover': {
                                  ...(formObject &&
                                  formObject.tabs &&
                                  formObject.tabs[key] &&
                                  formObject.tabs[key].tabs &&
                                  formObject.tabs[key].tabs['Process Changeover']
                                    ? formObject &&
                                      formObject.tabs &&
                                      formObject.tabs[key] &&
                                      formObject.tabs[key].tabs &&
                                      formObject.tabs[key].tabs['Process Changeover']
                                    : {}),
                                  form: {
                                    ...(formObject &&
                                    formObject.tabs &&
                                    formObject.tabs[key] &&
                                    formObject.tabs[key].tabs &&
                                    formObject.tabs[key].tabs['Process Changeover'] &&
                                    formObject.tabs[key].tabs['Process Changeover']['form']
                                      ? formObject.tabs[key].tabs['Process Changeover']['form']
                                      : {}),
                                    dynamicSubForm: {
                                      ...(formObject &&
                                      formObject.tabs &&
                                      formObject.tabs[key] &&
                                      formObject.tabs[key].tabs['Process Changeover'] &&
                                      formObject.tabs[key].tabs['Process Changeover']['form'] &&
                                      formObject.tabs[key].tabs['Process Changeover']['form'][
                                        'dynamicSubForm'
                                      ]
                                        ? formObject.tabs[key].tabs['Process Changeover']['form'][
                                            'dynamicSubForm'
                                          ]
                                        : {}),
                                      [itemData]: {
                                        ...(formObject &&
                                        formObject.tabs &&
                                        formObject.tabs[key] &&
                                        formObject.tabs[key].tabs['Process Changeover'] &&
                                        formObject.tabs[key].tabs['Process Changeover']['form'] &&
                                        formObject.tabs[key].tabs['Process Changeover']['form'][
                                          'dynamicSubForm'
                                        ] &&
                                        formObject.tabs[key].tabs['Process Changeover']['form'][
                                          'dynamicSubForm'
                                        ][itemData]
                                          ? formObject.tabs[key].tabs['Process Changeover']['form'][
                                              'dynamicSubForm'
                                            ][itemData]
                                          : {}),

                                        datasource: {
                                          [e]: {
                                            //  ...(formObject &&  formObject.tabs &&
                                            //    formObject.tabs[key] && formObject.tabs[key].tabs["Process Changeover"] && formObject.tabs[key].tabs["Process Changeover"]["form"] &&
                                            //    formObject.tabs[key].tabs["Process Changeover"]["form"]["dynamicSubForm" ]  &&formObject.tabs[key].tabs["Process Changeover"]["form"]["dynamicSubForm" ][itemData] &&
                                            //    formObject.tabs[key].tabs["Process Changeover"]["form"]["dynamicSubForm" ][itemData] .datasource ?
                                            //    formObject.tabs[key].tabs["Process Changeover"]["form"]["dynamicSubForm" ][itemData] .datasource : {}),
                                            TimeSelection: ''
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        };
                      }
                      return {};
                    });
                    return {};
                  });
                  return {};
                });
              }
            }
            //   if(key !== "defaultData" &&formObject["tabs"] && formObject["tabs"][key] && formObject["tabs"][key].tabs && formObject["tabs"][key].tabs["Process Changeover"] &&
            //   formObject["tabs"][key].tabs["Process Changeover"].dynamicSubForm  ){

            //     Object.keys(formObject["tabs"] && formObject["tabs"][key] && formObject["tabs"][key].tabs && formObject["tabs"][key].tabs["Process Changeover"]
            //     ).map((item)=>{
            //     })
            //   }
            else {
              formObject = {
                ...formObject,
                mainHeaderValue: {
                  ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
                  [columnKey]: columnValue
                }
              };
            }

            return {};
          });

        this.props.downTimeData(formObject, false);
      } else {
        formObject = {
          ...formObject,
          mainHeaderValue: {
            ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
            [columnKey]: columnValue
          }
        };
        this.props.downTimeData(formObject, false);
      }
    }
  };
  inputHeaderMainNumberChange = (
    minValue,
    maxValue,
    value,
    key,
    tabs,
    name,
    subsection,
    subSectionName,
    InputNumberType
  ) => {
    if (
      (value >= minValue &&
        value <= maxValue &&
        minValue !== undefined &&
        maxValue !== undefined) ||
      value === ''
    ) {
      this.onInputFormHeaderMainChange(
        value,
        key,
        tabs,
        name,
        subsection,
        subSectionName,
        minValue,
        maxValue,
        InputNumberType
      );
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputFormHeaderMainChange(
        value,
        key,
        tabs,
        name,
        subsection,
        subSectionName,
        minValue,
        maxValue,
        InputNumberType
      );
    } else {
      this.onInputFormHeaderMainChange(
        '',
        key,
        tabs,
        name,
        subsection,
        subSectionName,
        minValue,
        maxValue,
        InputNumberType
      );
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };

  onInputFormHeaderMainChange = (
    columnValue,
    columnKey,
    tabs,
    name,
    subsection,
    subSectionName,
    min,
    max,
    InputNumberType
  ) => {
    let { formObject } = this.state;
    // if (min !== undefined && min > 0 && InputNumberType === "number" && columnValue < 0) {
    //   message.error("Please Input Positive Values  Only")
    //   formObject = {
    //     ...formObject,
    //     tabs: {
    //       ...(formObject &&
    //         formObject.tabs ?
    //         formObject.tabs : {}),
    //       [name]: {
    //         ...(formObject &&
    //           formObject.tabs &&
    //           formObject.tabs[name] ?
    //           formObject.tabs[name] : {}),
    //         [subsection]: {
    //           ...(formObject &&
    //             formObject.tabs &&
    //             formObject.tabs[name] &&
    //             formObject.tabs[name]["subsection"] ?
    //             formObject.tabs[name]["subsection"] : {}),
    //           [subSectionName]: {
    //             ...(formObject &&
    //               formObject.tabs &&
    //               formObject.tabs[name] &&
    //               formObject.tabs[name]["subsection"] &&
    //               formObject.tabs[name]["subsection"][subSectionName] ?
    //               formObject.tabs[name]["subsection"][subSectionName] : {}),
    //             form: {
    //               ...(formObject &&
    //                 formObject.tabs &&
    //                 formObject.tabs[name] &&
    //                 formObject.tabs[name]["subsection"] &&
    //                 formObject.tabs[name]["subsection"][subSectionName]["form"] ?
    //                 formObject.tabs[name]["subsection"][subSectionName]["form"] : {}),
    //               datasource: {
    //                 ...(formObject &&
    //                   formObject.tabs &&
    //                   formObject.tabs[name] &&
    //                   formObject.tabs[name]["subsection"] &&
    //                   formObject.tabs[name]["subsection"][subSectionName]["form"] &&
    //                   formObject.tabs[name]["subsection"][subSectionName]["form"]["datasource"] ?
    //                   formObject.tabs[name]["subsection"][subSectionName]["form"]["datasource"] : {}),
    //                 [columnKey]: "",
    //                 // TimeSelection:{}
    //               },
    //               "ReferenceAttributes": {
    //                 "JsonKey": ["tabs", name, "subsection", subSectionName, "form"]
    //               },
    //             }

    //           },
    //         },
    //       },
    //     },
    //   }
    //   this.props.downTimeData(formObject, false);

    // }
    // else
    if (min === undefined && InputNumberType === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      formObject = {
        ...formObject,
        tabs: {
          ...(formObject && formObject.tabs ? formObject.tabs : {}),
          [name]: {
            ...(formObject && formObject.tabs && formObject.tabs[name]
              ? formObject.tabs[name]
              : {}),
            [subsection]: {
              ...(formObject &&
              formObject.tabs &&
              formObject.tabs[name] &&
              formObject.tabs[name]['subsection']
                ? formObject.tabs[name]['subsection']
                : {}),
              [subSectionName]: {
                ...(formObject &&
                formObject.tabs &&
                formObject.tabs[name] &&
                formObject.tabs[name]['subsection'] &&
                formObject.tabs[name]['subsection'][subSectionName]
                  ? formObject.tabs[name]['subsection'][subSectionName]
                  : {}),
                form: {
                  ...(formObject &&
                  formObject.tabs &&
                  formObject.tabs[name] &&
                  formObject.tabs[name]['subsection'] &&
                  formObject.tabs[name]['subsection'][subSectionName]['form']
                    ? formObject.tabs[name]['subsection'][subSectionName]['form']
                    : {}),
                  datasource: {
                    ...(formObject &&
                    formObject.tabs &&
                    formObject.tabs[name] &&
                    formObject.tabs[name]['subsection'] &&
                    formObject.tabs[name]['subsection'][subSectionName]['form'] &&
                    formObject.tabs[name]['subsection'][subSectionName]['form']['datasource']
                      ? formObject.tabs[name]['subsection'][subSectionName]['form']['datasource']
                      : {}),
                    [columnKey]: ''
                    // TimeSelection:{}
                  },
                  ReferenceAttributes: {
                    JsonKey: ['tabs', name, 'subsection', subSectionName, 'form']
                  }
                }
              }
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    } else {
      if (
        formObject &&
        formObject.tabs &&
        formObject.tabs[name] &&
        formObject.tabs[name]['subsection'] &&
        formObject.tabs[name]['subsection'][subSectionName]['form'] &&
        formObject.tabs[name]['subsection'][subSectionName]['form']['dynamicSubForm']
      ) {
        let dynamicSubFormKeys = Object.keys(
          formObject.tabs[name]['subsection'][subSectionName]['form']['dynamicSubForm']
        );

        if (dynamicSubFormKeys.includes(columnKey)) {
          let changeList = {};
          for (let i = 1; i <= columnValue; i++) {
            changeList[i] = {};
          }
          formObject = {
            ...formObject,
            [tabs]: {
              ...(formObject && formObject.tabs ? formObject.tabs : {}),
              [name]: {
                ...(formObject && formObject.tabs && formObject.tabs[name]
                  ? formObject.tabs[name]
                  : {}),
                [subsection]: {
                  ...(formObject &&
                  formObject.tabs &&
                  formObject.tabs[name] &&
                  formObject.tabs[name][subsection]
                    ? formObject.tabs[name][subsection]
                    : {}),
                  [subSectionName]: {
                    ...(formObject &&
                    formObject.tabs &&
                    formObject.tabs[name] &&
                    formObject.tabs[name]['subsection'] &&
                    formObject.tabs[name]['subsection'][subSectionName]
                      ? formObject.tabs[name]['subsection'][subSectionName]
                      : {}),
                    form: {
                      ...(formObject &&
                      formObject.tabs &&
                      formObject.tabs[name] &&
                      formObject.tabs[name]['subsection'] &&
                      formObject.tabs[name]['subsection'][subSectionName]['form']
                        ? formObject.tabs[name]['subsection'][subSectionName]['form']
                        : {}),
                      datasource: {
                        ...(formObject &&
                        formObject.tabs &&
                        formObject.tabs[name] &&
                        formObject.tabs[name]['subsection'] &&
                        formObject.tabs[name]['subsection'][subSectionName]['form'] &&
                        formObject.tabs[name]['subsection'][subSectionName]['form']['datasource']
                          ? formObject.tabs[name]['subsection'][subSectionName]['form'][
                              'datasource'
                            ]
                          : {}),
                        [columnKey]: columnValue
                      },
                      dynamicSubForm: {
                        ...(formObject &&
                        formObject.tabs &&
                        formObject.tabs[name] &&
                        formObject.tabs[name]['subsection'] &&
                        formObject.tabs[name]['subsection'][subSectionName]['form'] &&
                        formObject.tabs[name]['subsection'][subSectionName]['form'][
                          'dynamicSubForm'
                        ]
                          ? formObject.tabs[name]['subsection'][subSectionName]['form'][
                              'dynamicSubForm'
                            ]
                          : {}),

                        [columnKey]: {
                          ...(formObject &&
                          formObject.tabs &&
                          formObject.tabs[name] &&
                          formObject.tabs[name]['subsection'] &&
                          formObject.tabs[name]['subsection'][subSectionName]['form'] &&
                          formObject.tabs[name]['subsection'][subSectionName]['form'][
                            'dynamicSubForm'
                          ] &&
                          formObject.tabs[name]['subsection'][subSectionName]['form'][
                            'dynamicSubForm'
                          ][columnKey]
                            ? formObject.tabs[name]['subsection'][subSectionName]['form'][
                                'dynamicSubForm'
                              ][columnKey]
                            : {}),
                          form: {
                            ...(formObject &&
                            formObject.tabs &&
                            formObject.tabs[name] &&
                            formObject.tabs[name]['subsection'] &&
                            formObject.tabs[name]['subsection'][subSectionName]['form'] &&
                            formObject.tabs[name]['subsection'][subSectionName]['form'][
                              'dynamicSubForm'
                            ] &&
                            formObject.tabs[name]['subsection'][subSectionName]['form'][
                              'dynamicSubForm'
                            ][columnKey] &&
                            formObject.tabs[name]['subsection'][subSectionName]['form'][
                              'dynamicSubForm'
                            ][columnKey]['form']
                              ? formObject.tabs[name]['subsection'][subSectionName]['form'][
                                  'dynamicSubForm'
                                ][columnKey]['form']
                              : {}),
                            datasource: changeList,
                            ReferenceAttributes: {
                              JsonKey: [
                                'tabs',
                                name,
                                'subsection',
                                subSectionName,
                                'form',
                                'dynamicSubForm',
                                'form'
                              ]
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          };
        }
      } else if (columnKey === 'noOfBatches' || columnKey === 'GasReading_m3') {
        if (columnKey === 'noOfBatches') {
          let batchArray = [];
          for (let i = 1; i <= columnValue; i++) {
            let count = i + 1;
            let obj = {
              batch: `Batch-${i}`,
              key: count
            };
            batchArray.push(obj);
          }
          formObject = {
            ...formObject,
            tabs: {
              ...(formObject && formObject.tabs ? formObject.tabs : {}),
              [name]: {
                ...(formObject && formObject.tabs && formObject.tabs[name]
                  ? formObject.tabs[name]
                  : {}),
                [subsection]: {
                  ...(formObject &&
                  formObject.tabs &&
                  formObject.tabs[name] &&
                  formObject.tabs[name]['subsection']
                    ? formObject.tabs[name]['subsection']
                    : {}),
                  [subSectionName]: {
                    ...(formObject &&
                    formObject.tabs &&
                    formObject.tabs[name] &&
                    formObject.tabs[name]['subsection'] &&
                    formObject.tabs[name]['subsection'][subSectionName]
                      ? formObject.tabs[name]['subsection'][subSectionName]
                      : {}),
                    form: {
                      ...(formObject &&
                      formObject.tabs &&
                      formObject.tabs[name] &&
                      formObject.tabs[name]['subsection'] &&
                      formObject.tabs[name]['subsection'][subSectionName]['form']
                        ? formObject.tabs[name]['subsection'][subSectionName]['form']
                        : {}),
                      datasource: {
                        ...(formObject &&
                        formObject.tabs &&
                        formObject.tabs[name] &&
                        formObject.tabs[name]['subsection'] &&
                        formObject.tabs[name]['subsection'][subSectionName]['form'] &&
                        formObject.tabs[name]['subsection'][subSectionName]['form']['datasource']
                          ? formObject.tabs[name]['subsection'][subSectionName]['form'][
                              'datasource'
                            ]
                          : {}),
                        [columnKey]: columnValue
                      },
                      ReferenceAttributes: {
                        JsonKey: ['tabs', name, 'subsection', subSectionName, 'form']
                      }
                    },
                    table: {
                      ...(formObject &&
                      formObject.tabs &&
                      formObject.tabs[name] &&
                      formObject.tabs[name]['subsection'] &&
                      formObject.tabs[name]['subsection'][subSectionName]['table']
                        ? formObject.tabs[name]['subsection'][subSectionName]['table']
                        : {}),
                      datasource: [...batchArray],
                      ReferenceAttributes: {
                        JsonKey: ['tabs', name, 'subsection', subSectionName, 'table']
                      }
                    }
                  }
                }
              }
            }
          };
        } else {
          formObject = {
            ...formObject,
            tabs: {
              ...(formObject && formObject.tabs ? formObject.tabs : {}),
              [name]: {
                ...(formObject && formObject.tabs && formObject.tabs[name]
                  ? formObject.tabs[name]
                  : {}),
                [subsection]: {
                  ...(formObject &&
                  formObject.tabs &&
                  formObject.tabs[name] &&
                  formObject.tabs[name]['subsection']
                    ? formObject.tabs[name]['subsection']
                    : {}),
                  [subSectionName]: {
                    ...(formObject &&
                    formObject.tabs &&
                    formObject.tabs[name] &&
                    formObject.tabs[name]['subsection'] &&
                    formObject.tabs[name]['subsection'][subSectionName]
                      ? formObject.tabs[name]['subsection'][subSectionName]
                      : {}),
                    form: {
                      ...(formObject &&
                      formObject.tabs &&
                      formObject.tabs[name] &&
                      formObject.tabs[name]['subsection'] &&
                      formObject.tabs[name]['subsection'][subSectionName]['form']
                        ? formObject.tabs[name]['subsection'][subSectionName]['form']
                        : {}),
                      datasource: {
                        ...(formObject &&
                        formObject.tabs &&
                        formObject.tabs[name] &&
                        formObject.tabs[name]['subsection'] &&
                        formObject.tabs[name]['subsection'][subSectionName]['form'] &&
                        formObject.tabs[name]['subsection'][subSectionName]['form']['datasource']
                          ? formObject.tabs[name]['subsection'][subSectionName]['form'][
                              'datasource'
                            ]
                          : {}),
                        [columnKey]: columnValue
                      },
                      ReferenceAttributes: {
                        JsonKey: ['tabs', name, 'subsection', subSectionName, 'form']
                      }
                    }
                  }
                }
              }
            }
          };
        }
        // let testformObject = cloneDeep(formObject)
        // if(columnKey === "noOfBatches") {
        //   let batchArray = [];
        // for (let i = 0; i <= columnValue; i++) {
        //   let count = i + 1;
        //  let obj = {
        //     "batch": `Batch-${i}`,
        //     "key": count
        //   }
        //   batchArray.push(obj)
        // }
        // testformObject["tabs"][name]["subsection"][subSectionName]["table"]["datasource"]=batchArray;
        // }
        // testformObject["tabs"][name]["subsection"][subSectionName]["form"]["datasource"][columnKey] = columnValue
        // formObject = testformObject
      } else {
        formObject = {
          ...formObject,
          tabs: {
            ...(formObject && formObject.tabs ? formObject.tabs : {}),
            [name]: {
              ...(formObject && formObject.tabs && formObject.tabs[name]
                ? formObject.tabs[name]
                : {}),
              [subsection]: {
                ...(formObject &&
                formObject.tabs &&
                formObject.tabs[name] &&
                formObject.tabs[name]['subsection']
                  ? formObject.tabs[name]['subsection']
                  : {}),
                [subSectionName]: {
                  ...(formObject &&
                  formObject.tabs &&
                  formObject.tabs[name] &&
                  formObject.tabs[name]['subsection'] &&
                  formObject.tabs[name]['subsection'][subSectionName]
                    ? formObject.tabs[name]['subsection'][subSectionName]
                    : {}),
                  form: {
                    ...(formObject &&
                    formObject.tabs &&
                    formObject.tabs[name] &&
                    formObject.tabs[name]['subsection'] &&
                    formObject.tabs[name]['subsection'][subSectionName]['form']
                      ? formObject.tabs[name]['subsection'][subSectionName]['form']
                      : {}),
                    datasource: {
                      ...(formObject &&
                      formObject.tabs &&
                      formObject.tabs[name] &&
                      formObject.tabs[name]['subsection'] &&
                      formObject.tabs[name]['subsection'][subSectionName]['form'] &&
                      formObject.tabs[name]['subsection'][subSectionName]['form']['datasource']
                        ? formObject.tabs[name]['subsection'][subSectionName]['form']['datasource']
                        : {}),
                      [columnKey]: columnValue
                    },
                    ReferenceAttributes: {
                      JsonKey: ['tabs', name, 'subsection', subSectionName, 'form']
                    }
                  }
                }
              }
            }
          }
        };
      }
      this.props.downTimeData(formObject, false);
    }
  };

  onDateChange = (date, dateString) => {
    this.setState({
      date: dateString
    });
  };
  inputNumberChange = (minValue, maxValue, value, key, tabs, name, form) => {
    if (
      (value >= minValue &&
        value <= maxValue &&
        minValue !== undefined &&
        maxValue !== undefined) ||
      value === ''
    ) {
      this.onHeaderInputChange(parseInt(value), key, tabs, name, form, minValue, maxValue);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onHeaderInputChange(parseInt(value), key, tabs, name, form, minValue, maxValue);
    } else {
      this.onHeaderInputChange('', key, tabs, name, form, minValue, maxValue);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  onHeaderInputChange = (columnValue, columnKey, tabs, name, form, min) => {
    let { formObject } = this.props;
    // if (columnValue < 0 && typeof (columnValue) === "number" && min > 0) {
    //   message.error("Please Input Positive Values  Only")
    //   formObject = {
    //     ...formObject,
    //     tabs: {
    //       ...(formObject &&
    //         formObject[tabs] ?
    //         formObject[tabs] : {}),
    //       [name]: {
    //         ...(formObject &&
    //           formObject[tabs] &&
    //           formObject[tabs][name] ?
    //           formObject[tabs][name] : {}),
    //         [form]: {
    //           ...(formObject &&
    //             formObject[tabs] &&
    //             formObject[tabs][name] &&
    //             formObject[tabs][name][form] ?
    //             formObject[tabs][name][form] : {}),
    //           datasource: {
    //             ...(formObject &&
    //               formObject[tabs] &&
    //               formObject[tabs][name] &&
    //               formObject[tabs][name][form] &&
    //               formObject[tabs][name][form].datasource ?
    //               formObject[tabs][name][form].datasource : {}),
    //             [columnKey]: "",
    //           },
    //           "ReferenceAttributes": {
    //             "JsonKey": ["tabs", name, form]
    //           }
    //         },
    //       },
    //     },
    //   };
    //   this.props.downTimeData(formObject, false);
    // }
    if (min !== undefined && min > 0 && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      formObject = {
        ...formObject,
        tabs: {
          ...(formObject && formObject[tabs] ? formObject[tabs] : {}),
          [name]: {
            ...(formObject && formObject[tabs] && formObject[tabs][name]
              ? formObject[tabs][name]
              : {}),
            [form]: {
              ...(formObject &&
              formObject[tabs] &&
              formObject[tabs][name] &&
              formObject[tabs][name][form]
                ? formObject[tabs][name][form]
                : {}),
              datasource: {
                ...(formObject &&
                formObject[tabs] &&
                formObject[tabs][name] &&
                formObject[tabs][name][form] &&
                formObject[tabs][name][form].datasource
                  ? formObject[tabs][name][form].datasource
                  : {}),
                [columnKey]: ''
              },
              ReferenceAttributes: {
                JsonKey: ['tabs', name, form]
              }
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    } else if (min === undefined && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      formObject = {
        ...formObject,
        tabs: {
          ...(formObject && formObject[tabs] ? formObject[tabs] : {}),
          [name]: {
            ...(formObject && formObject[tabs] && formObject[tabs][name]
              ? formObject[tabs][name]
              : {}),
            [form]: {
              ...(formObject &&
              formObject[tabs] &&
              formObject[tabs][name] &&
              formObject[tabs][name][form]
                ? formObject[tabs][name][form]
                : {}),
              datasource: {
                ...(formObject &&
                formObject[tabs] &&
                formObject[tabs][name] &&
                formObject[tabs][name][form] &&
                formObject[tabs][name][form].datasource
                  ? formObject[tabs][name][form].datasource
                  : {}),
                [columnKey]: ''
              },
              ReferenceAttributes: {
                JsonKey: ['tabs', name, form]
              }
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    } else {
      if (columnKey === 'Model_BatchCount') {
        let batchArray = [];
        for (let i = 1; i <= columnValue; i++) {
          batchArray.push({
            BatchName: 'Batch-' + i,
            key: i
          });
        }
        formObject = {
          ...formObject,
          tabs: {
            ...(formObject && formObject[tabs] ? formObject[tabs] : {}),
            [name]: {
              ...(formObject && formObject[tabs] && formObject[tabs][name]
                ? formObject[tabs][name]
                : {}),
              [form]: {
                ...(formObject &&
                formObject[tabs] &&
                formObject[tabs][name] &&
                formObject[tabs][name][form]
                  ? formObject[tabs][name][form]
                  : {}),
                datasource: {
                  ...(formObject &&
                  formObject[tabs] &&
                  formObject[tabs][name] &&
                  formObject[tabs][name][form] &&
                  formObject[tabs][name][form].datasource
                    ? formObject[tabs][name][form].datasource
                    : {}),
                  [columnKey]: columnValue
                },
                ReferenceAttributes: {
                  JsonKey: ['tabs', name, form]
                }
              },

              table: {
                ...(formObject &&
                formObject[tabs] &&
                formObject[tabs][name] &&
                formObject[tabs][name].table &&
                formObject[tabs][name].table
                  ? formObject[tabs][name].table
                  : {}),
                datasource: [...batchArray],
                ReferenceAttributes: {
                  JsonKey: ['tabs', name, 'table']
                }
              }
            }
          }
        };
      } else if (columnKey === 'noOfModels') {
        let subSection = {};

        for (let i = 1; i <= columnValue; i++) {
          // Runs 5 times, with values of step 0 through 4.
          subSection[`Model-${i}`] =
            formObject &&
            formObject[tabs] &&
            formObject[tabs].defaultData &&
            formObject[tabs].defaultData.subsection &&
            formObject[tabs].defaultData.subsection.model;
        }
        formObject = {
          ...formObject,
          tabs: {
            ...(formObject && formObject[tabs] ? formObject[tabs] : {}),
            [name]: {
              ...(formObject && formObject[tabs] && formObject[tabs][name]
                ? formObject[tabs][name]
                : {}),
              form: {
                ...(formObject &&
                formObject[tabs] &&
                formObject[tabs][name] &&
                formObject[tabs][name][form]
                  ? formObject[tabs][name][form]
                  : {}),
                datasource: {
                  ...(formObject &&
                  formObject[tabs] &&
                  formObject[tabs][name] &&
                  formObject[tabs][name][form] &&
                  formObject[tabs][name][form].datasource
                    ? formObject[tabs][name][form].datasource
                    : {}),
                  [columnKey]: columnValue
                },
                ReferenceAttributes: {
                  JsonKey: ['tabs', name, form]
                }
              },
              subsection: subSection
            }
          }
        };
      } else {
        formObject = {
          ...formObject,
          tabs: {
            ...(formObject && formObject[tabs] ? formObject[tabs] : {}),
            [name]: {
              ...(formObject && formObject[tabs] && formObject[tabs][name]
                ? formObject[tabs][name]
                : {}),
              [form]: {
                ...(formObject &&
                formObject[tabs] &&
                formObject[tabs][name] &&
                formObject[tabs][name][form]
                  ? formObject[tabs][name][form]
                  : {}),
                datasource: {
                  ...(formObject &&
                  formObject[tabs] &&
                  formObject[tabs][name] &&
                  formObject[tabs][name][form] &&
                  formObject[tabs][name][form].datasource
                    ? formObject[tabs][name][form].datasource
                    : {}),
                  [columnKey]: columnValue
                },
                ReferenceAttributes: {
                  JsonKey: ['tabs', name, form]
                }
              }
            }
          }
        };
      }
      this.props.downTimeData(formObject, false);
    }
  };

  renderHeaderSwitch(formObject, columns, tabs, name, form) {
    const { shift } = this.state;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject &&
              formObject[tabs] &&
              formObject[tabs][name] &&
              formObject[tabs][name][form] &&
              formObject[tabs][name][form].datasource &&
              formObject[tabs][name][form].datasource[columns.key]
                ? formObject[tabs][name][form].datasource[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onHeaderInputChange(e.target.value, columns.key, tabs, name, form)
            }
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject &&
              formObject[tabs] &&
              formObject[tabs][name] &&
              formObject[tabs][name][form] &&
              formObject[tabs][name][form].datasource &&
              formObject[tabs][name][form].datasource[columns.key]
                ? formObject[tabs][name][form].datasource[columns.key]
                : ''
            }
            handleFieldChange={(e) => this.onHeaderInputChange(e, columns.key, tabs, name, form)}
            // handleKeyPress={this.handleKeyPress}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject &&
              formObject[tabs] &&
              formObject[tabs][name] &&
              formObject[tabs][name][form] &&
              formObject[tabs][name][form].datasource &&
              (formObject[tabs][name][form].datasource[columns.key] ||
                formObject[tabs][name][form].datasource[columns.key] === 0)
                ? formObject[tabs][name][form].datasource[columns.key]
                : ''
            }
            step={columns.step ? columns.step : 1}
            placeholder={columns.placeHolder}
            // min={columns.min ? columns.min : 0}
            // max={columns.max ? columns.max : 1000000}
            // onChange={(e) => {
            //   this.onHeaderInputChange(e, columns.key, name);
            // }}
            onBlur={(e) =>
              this.inputNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key,
                tabs,
                name,
                form
              )
            }
            onChange={(e) =>
              this.onHeaderInputChange(e, columns.key, tabs, name, form, columns.min, columns.max)
            }
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            allowClear={false}
            // ranges={{
            //     Today: [moment(), moment()],
            //     'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}
            showTime={{
              hideDisabledOptions: false,
              defaultValue: this.dateRanges(shift)
            }}
            placeholder={['Start Time', 'End Time']}
            value={
              formObject &&
              formObject[tabs] &&
              formObject[tabs][name] &&
              formObject[tabs][name][form] &&
              formObject[tabs][name][form].datasource &&
              formObject[tabs][name][form].datasource[columns.key] &&
              formObject[tabs][name][form].datasource[columns.key]['startDate'] &&
              formObject[tabs][name][form].datasource[columns.key]['endDate']
                ? [
                    moment(
                      formObject[tabs][name][form].datasource[columns.key]['startDate'],
                      dateFormat
                    ),
                    moment(
                      formObject[tabs][name][form].datasource[columns.key]['endDate'],
                      dateFormat
                    )
                  ]
                : null
            }
            format="YYYY-MM-DD HH:mm"
            // onChange={(e) => this.onChange(e, columns.key, name)}
            disabledTime={(e) => this.disabledRangeTime(e)}
            disabledDate={(e) =>
              this.disabledDateRange(
                {
                  ...(formObject && formObject ? formObject : {})
                },
                e
              )
            }
            onOk={(e) => this.onOk(e, columns.key, tabs, name, form)}
          />
        );
      case 'timePicker':
        return (
          <TimePicker
            use12Hours
            format="h:mm A"
            value={
              formObject &&
              formObject[tabs] &&
              formObject[tabs][name] &&
              formObject[tabs][name][form] &&
              formObject[tabs][name][form].datasource &&
              formObject[tabs][name][form].datasource[columns.key]
                ? formObject[tabs][name][form].datasource[columns.key]
                : ''
            }
            style={{ width: 300 }}
            onChange={(e) =>
              this.onHeaderInputChange(e.target.value, columns.key, tabs, name, form)
            }
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject &&
              formObject[tabs] &&
              formObject[tabs][name] &&
              formObject[tabs][name][form] &&
              formObject[tabs][name][form].datasource &&
              formObject[tabs][name][form].datasource[columns.key]
                ? formObject[tabs][name][form].datasource[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onHeaderInputChange(e.target.value, columns.key, tabs, name, form)
            }
          />
        );
    }
  }
  disabledDateRange = (formObject, current) => {
    if (
      formObject &&
      formObject['mainHeaderValue'] &&
      formObject['mainHeaderValue']['Date'] &&
      formObject['mainHeaderValue']['Shift'] === 'Morning Shift'
    ) {
      let selectedDate = moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).endOf('day') || current < moment(selectedDate).startOf('day')
      );
    } else if (
      formObject &&
      formObject['mainHeaderValue'] &&
      formObject['mainHeaderValue']['Date'] &&
      formObject['mainHeaderValue']['Shift'] === 'Night Shift'
    ) {
      let selectedDate = moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).add(1, 'day').endOf('day') ||
        current < moment(selectedDate).startOf('day')
      );
    } else if (
      formObject &&
      formObject['mainHeaderValue'] &&
      formObject['mainHeaderValue']['Date']
    ) {
      let selectedDate = moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).add(1, 'day').endOf('day') ||
        current < moment(selectedDate).startOf('day')
      );
    }
    //return current > moment().add(1, 'days').endOf("day") || current < moment().startOf("day");
    return current || current > moment().endOf('day') || current < moment().startOf('day');
  };

  renderMainSwitch(formObject, columns) {
    const { shift } = this.state;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputHeaderMainChange(e.target.value, columns.key, columns)}
            disabled={columns.disabled ? columns.disabled : false}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject &&
              formObject.mainHeaderValue &&
              (formObject.mainHeaderValue[columns.key] ||
                formObject.mainHeaderValue[columns.key] === 0)
                ? formObject.mainHeaderValue[columns.key]
                : null
            }
            step={columns.step ? columns.step : 1}
            disabled={columns.disabled ? columns.disabled : false}
            // min={columns.min ? columns.min : 0}
            // max={columns.max ? columns.max : 1000000}
            placeholder={columns.placeHolder}
            // onChange={(e) =>
            //   this.onInputHeaderMainChange(e, columns.key, columns)
            // }
            onBlur={(e) =>
              this.inputHeaderNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key,
                columns
              )
            }
            onChange={(e) =>
              this.onInputHeaderMainChange(e, columns.key, columns, columns.min, columns.max)
            }
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            format={columns.showTime ? 'YYYY-MM-DD HH:mm' : undefined}
            value={
              formObject &&
              formObject['mainHeaderValue'] &&
              formObject['mainHeaderValue'][columns.key]
                ? moment(formObject['mainHeaderValue'][columns.key], 'YYYY-MM-DD HH:mm')
                : null
            }
            showNow={false}
            showTime={columns.showTime ? columns.showTime : false}
            allowClear={false}
            disabledTime={(e) => this.disabledRangeTime(e)}
            disabledDate={(current) => this.disabledDate(current, columns)}
            disabled={this.disabled}
            onChange={(e) => this.onDateHeaderMainChange(e, columns.key)}
            style={{ width: 300 }}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            handleFieldChange={(e) => this.onInputHeaderMainChange(e, columns.key, columns)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            // ranges={{
            //     Today: [moment(), moment()],
            //     'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}
            allowClear={false}
            showTime={{
              hideDisabledOptions: false,
              defaultValue: this.dateRanges(shift)
            }}
            format="YYYY-MM-DD HH:mm"
            placeholder={['Start Time', 'End Time']}
            value={
              formObject &&
              formObject.mainHeaderValue &&
              formObject.mainHeaderValue[columns.key] &&
              formObject.mainHeaderValue[columns.key]['startDate'] &&
              formObject.mainHeaderValue[columns.key]['endDate']
                ? [
                    moment(formObject.mainHeaderValue[columns.key]['startDate'], dateFormat),
                    moment(formObject.mainHeaderValue[columns.key]['endDate'], dateFormat)
                  ]
                : null
            }
            disabledDate={(e) =>
              this.disabledDateRange(
                {
                  ...(formObject ? formObject : {})
                },
                e
              )
            }
            disabledTime={(e) => this.disabledRangeTime(e)}
            onOk={(e) => this.onCheckOK(e, columns.key, columns)}
            onChange={(e) => this.onMainChange(e, columns.key, columns)}
          />
        );
      case 'timePicker':
        return (
          <TimePicker
            use12Hours
            format="h:mm A"
            value={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? moment(formObject.mainHeaderValue[columns.key], 'h:mm:ss A')
                : ''
            }
            style={{ width: 300 }}
            onChange={(e, string) => this.onInputHeaderMainChange(string, columns.key, columns)}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputHeaderMainChange(e.target.value, columns.key, columns)}
            disabled={columns.disabled ? columns.disabled : false}
          />
        );
    }
  }
  inputSubNumberChange = (
    minValue,
    maxValue,
    value,
    key,
    tabs,
    name,
    subsection,
    subSectionName,
    subSubSection,
    form,
    datasourceKey
  ) => {
    if (
      (value >= minValue &&
        value <= maxValue &&
        minValue !== undefined &&
        maxValue !== undefined) ||
      value === ''
    ) {
      this.onInputSubSubSectionChange(
        parseInt(value),
        key,
        tabs,
        name,
        subsection,
        subSectionName,
        subSubSection,
        form,
        datasourceKey,
        minValue,
        maxValue
      );
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputSubSubSectionChange(
        parseInt(value),
        key,
        tabs,
        name,
        subsection,
        subSectionName,
        subSubSection,
        form,
        datasourceKey,
        minValue,
        maxValue
      );
    } else {
      this.onInputSubSubSectionChange(
        '',
        key,
        tabs,
        name,
        subsection,
        subSectionName,
        subSubSection,
        form,
        datasourceKey,
        minValue,
        maxValue
      );
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  onInputSubSubSectionChange = (
    columnValue,
    columnKey,
    tabs,
    name,
    subsection,
    subsectionName,
    subSubSection,
    form,
    datasourceKey,
    min
  ) => {
    let { formObject } = this.state;
    // if (columnValue < 0 && typeof (columnValue) === "number" && min > 0) {
    //   message.error("Please Input Positive Values  Only")
    //   formObject = {
    //     ...formObject,
    //     [tabs]: {
    //       ...(formObject &&
    //         formObject["tabs"] ?
    //         formObject.tabs : {}),
    //       [name]: {
    //         ...(formObject &&
    //           formObject["tabs"] &&
    //           formObject["tabs"][name] ?
    //           formObject["tabs"][name] : {}),
    //         [subsection]: {
    //           ...(formObject &&
    //             formObject["tabs"] &&
    //             formObject["tabs"][name] &&
    //             formObject["tabs"][name]["subsection"] ?
    //             formObject["tabs"][name]["subsection"] : {}),
    //           [subsectionName]: {
    //             ...(formObject &&
    //               formObject["tabs"] &&
    //               formObject["tabs"][name] &&
    //               formObject["tabs"][name]["subsection"] &&
    //               formObject["tabs"][name]["subsection"][subsectionName] ?
    //               formObject["tabs"][name]["subsection"][subsectionName] : {}),
    //             [form]: {
    //               ...(formObject &&
    //                 formObject["tabs"] &&
    //                 formObject["tabs"][name] &&
    //                 formObject["tabs"][name]["subsection"] &&
    //                 formObject["tabs"][name]["subsection"][subsectionName] &&
    //                 formObject["tabs"][name]["subsection"][subsectionName][form] ?
    //                 formObject["tabs"][name]["subsection"][subsectionName][form] : {}),
    //               dynamicSubForm: {
    //                 ...(formObject &&
    //                   formObject["tabs"] &&
    //                   formObject["tabs"][name] &&
    //                   formObject["tabs"][name]["subsection"] &&
    //                   formObject["tabs"][name]["subsection"][subsectionName] &&
    //                   formObject["tabs"][name]["subsection"][subsectionName][form] &&
    //                   formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"] ?
    //                   formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"] : {}),
    //                 [subSubSection]: {
    //                   ...(formObject &&
    //                     formObject["tabs"] &&
    //                     formObject["tabs"][name] &&
    //                     formObject["tabs"][name]["subsection"] &&
    //                     formObject["tabs"][name]["subsection"][subsectionName] &&
    //                     formObject["tabs"][name]["subsection"][subsectionName][form] &&
    //                     formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"] &&
    //                     formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection] ?
    //                     formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection] : {}),
    //                   [form]: {
    //                     ...(formObject &&
    //                       formObject["tabs"] &&
    //                       formObject["tabs"][name] &&
    //                       formObject["tabs"][name]["subsection"] &&
    //                       formObject["tabs"][name]["subsection"][subsectionName] &&
    //                       formObject["tabs"][name]["subsection"][subsectionName][form] &&
    //                       formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"] &&
    //                       formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection] &&
    //                       formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection][form] ?
    //                       formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection][form] : {}),
    //                     datasource: {
    //                       ...(formObject &&
    //                         formObject["tabs"] &&
    //                         formObject["tabs"][name] &&
    //                         formObject["tabs"][name]["subsection"] &&
    //                         formObject["tabs"][name]["subsection"][subsectionName] &&
    //                         formObject["tabs"][name]["subsection"][subsectionName][form] &&
    //                         formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"] &&
    //                         formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection] &&
    //                         formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection][form] &&
    //                         formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection][form]["datasource"] ?
    //                         formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection][form]["datasource"] : {}),
    //                       [datasourceKey]: {
    //                         ...(formObject &&
    //                           formObject["tabs"] &&
    //                           formObject["tabs"][name] &&
    //                           formObject["tabs"][name]["subsection"] &&
    //                           formObject["tabs"][name]["subsection"][subsectionName] &&
    //                           formObject["tabs"][name]["subsection"][subsectionName][form] &&
    //                           formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"] &&
    //                           formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection] &&
    //                           formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection][form] &&
    //                           formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection][form]["datasource"] &&
    //                           formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection][form]["datasource"][datasourceKey] ?
    //                           formObject["tabs"][name]["subsection"][subsectionName][form]["dynamicSubForm"][subSubSection][form]["datasource"][datasourceKey] : {}),
    //                         [columnKey]: "",
    //                       },
    //                     },
    //                     "ReferenceAttributes": {
    //                       "JsonKey": ["tabs", name, "subsection", subsectionName, "form", "dynamicSubForm", subSubSection, "form"]
    //                     },
    //                   }

    //                 },
    //               },
    //             },
    //           },
    //         },
    //       },
    //     },
    //   };
    //   this.props.downTimeData(formObject, false);
    // }

    if (min !== undefined && min > 0 && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      formObject = {
        ...formObject,
        [tabs]: {
          ...(formObject && formObject['tabs'] ? formObject.tabs : {}),
          [name]: {
            ...(formObject && formObject['tabs'] && formObject['tabs'][name]
              ? formObject['tabs'][name]
              : {}),
            [subsection]: {
              ...(formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name]['subsection']
                ? formObject['tabs'][name]['subsection']
                : {}),
              [subsectionName]: {
                ...(formObject &&
                formObject['tabs'] &&
                formObject['tabs'][name] &&
                formObject['tabs'][name]['subsection'] &&
                formObject['tabs'][name]['subsection'][subsectionName]
                  ? formObject['tabs'][name]['subsection'][subsectionName]
                  : {}),
                [form]: {
                  ...(formObject &&
                  formObject['tabs'] &&
                  formObject['tabs'][name] &&
                  formObject['tabs'][name]['subsection'] &&
                  formObject['tabs'][name]['subsection'][subsectionName] &&
                  formObject['tabs'][name]['subsection'][subsectionName][form]
                    ? formObject['tabs'][name]['subsection'][subsectionName][form]
                    : {}),
                  dynamicSubForm: {
                    ...(formObject &&
                    formObject['tabs'] &&
                    formObject['tabs'][name] &&
                    formObject['tabs'][name]['subsection'] &&
                    formObject['tabs'][name]['subsection'][subsectionName] &&
                    formObject['tabs'][name]['subsection'][subsectionName][form] &&
                    formObject['tabs'][name]['subsection'][subsectionName][form]['dynamicSubForm']
                      ? formObject['tabs'][name]['subsection'][subsectionName][form][
                          'dynamicSubForm'
                        ]
                      : {}),
                    [subSubSection]: {
                      ...(formObject &&
                      formObject['tabs'] &&
                      formObject['tabs'][name] &&
                      formObject['tabs'][name]['subsection'] &&
                      formObject['tabs'][name]['subsection'][subsectionName] &&
                      formObject['tabs'][name]['subsection'][subsectionName][form] &&
                      formObject['tabs'][name]['subsection'][subsectionName][form][
                        'dynamicSubForm'
                      ] &&
                      formObject['tabs'][name]['subsection'][subsectionName][form][
                        'dynamicSubForm'
                      ][subSubSection]
                        ? formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ][subSubSection]
                        : {}),
                      [form]: {
                        ...(formObject &&
                        formObject['tabs'] &&
                        formObject['tabs'][name] &&
                        formObject['tabs'][name]['subsection'] &&
                        formObject['tabs'][name]['subsection'][subsectionName] &&
                        formObject['tabs'][name]['subsection'][subsectionName][form] &&
                        formObject['tabs'][name]['subsection'][subsectionName][form][
                          'dynamicSubForm'
                        ] &&
                        formObject['tabs'][name]['subsection'][subsectionName][form][
                          'dynamicSubForm'
                        ][subSubSection] &&
                        formObject['tabs'][name]['subsection'][subsectionName][form][
                          'dynamicSubForm'
                        ][subSubSection][form]
                          ? formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection][form]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject['tabs'] &&
                          formObject['tabs'][name] &&
                          formObject['tabs'][name]['subsection'] &&
                          formObject['tabs'][name]['subsection'][subsectionName] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ][subSubSection] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ][subSubSection][form] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ][subSubSection][form]['datasource']
                            ? formObject['tabs'][name]['subsection'][subsectionName][form][
                                'dynamicSubForm'
                              ][subSubSection][form]['datasource']
                            : {}),
                          [datasourceKey]: {
                            ...(formObject &&
                            formObject['tabs'] &&
                            formObject['tabs'][name] &&
                            formObject['tabs'][name]['subsection'] &&
                            formObject['tabs'][name]['subsection'][subsectionName] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection][form] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection][form]['datasource'] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection][form]['datasource'][datasourceKey]
                              ? formObject['tabs'][name]['subsection'][subsectionName][form][
                                  'dynamicSubForm'
                                ][subSubSection][form]['datasource'][datasourceKey]
                              : {}),
                            [columnKey]: ''
                          }
                        },
                        ReferenceAttributes: {
                          JsonKey: [
                            'tabs',
                            name,
                            'subsection',
                            subsectionName,
                            'form',
                            'dynamicSubForm',
                            subSubSection,
                            'form'
                          ]
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    } else if (min === undefined && typeof columnValue === 'number' && columnValue < 0) {
      message.error('Please Input Positive Values  Only');
      formObject = {
        ...formObject,
        [tabs]: {
          ...(formObject && formObject['tabs'] ? formObject.tabs : {}),
          [name]: {
            ...(formObject && formObject['tabs'] && formObject['tabs'][name]
              ? formObject['tabs'][name]
              : {}),
            [subsection]: {
              ...(formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name]['subsection']
                ? formObject['tabs'][name]['subsection']
                : {}),
              [subsectionName]: {
                ...(formObject &&
                formObject['tabs'] &&
                formObject['tabs'][name] &&
                formObject['tabs'][name]['subsection'] &&
                formObject['tabs'][name]['subsection'][subsectionName]
                  ? formObject['tabs'][name]['subsection'][subsectionName]
                  : {}),
                [form]: {
                  ...(formObject &&
                  formObject['tabs'] &&
                  formObject['tabs'][name] &&
                  formObject['tabs'][name]['subsection'] &&
                  formObject['tabs'][name]['subsection'][subsectionName] &&
                  formObject['tabs'][name]['subsection'][subsectionName][form]
                    ? formObject['tabs'][name]['subsection'][subsectionName][form]
                    : {}),
                  dynamicSubForm: {
                    ...(formObject &&
                    formObject['tabs'] &&
                    formObject['tabs'][name] &&
                    formObject['tabs'][name]['subsection'] &&
                    formObject['tabs'][name]['subsection'][subsectionName] &&
                    formObject['tabs'][name]['subsection'][subsectionName][form] &&
                    formObject['tabs'][name]['subsection'][subsectionName][form]['dynamicSubForm']
                      ? formObject['tabs'][name]['subsection'][subsectionName][form][
                          'dynamicSubForm'
                        ]
                      : {}),
                    [subSubSection]: {
                      ...(formObject &&
                      formObject['tabs'] &&
                      formObject['tabs'][name] &&
                      formObject['tabs'][name]['subsection'] &&
                      formObject['tabs'][name]['subsection'][subsectionName] &&
                      formObject['tabs'][name]['subsection'][subsectionName][form] &&
                      formObject['tabs'][name]['subsection'][subsectionName][form][
                        'dynamicSubForm'
                      ] &&
                      formObject['tabs'][name]['subsection'][subsectionName][form][
                        'dynamicSubForm'
                      ][subSubSection]
                        ? formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ][subSubSection]
                        : {}),
                      [form]: {
                        ...(formObject &&
                        formObject['tabs'] &&
                        formObject['tabs'][name] &&
                        formObject['tabs'][name]['subsection'] &&
                        formObject['tabs'][name]['subsection'][subsectionName] &&
                        formObject['tabs'][name]['subsection'][subsectionName][form] &&
                        formObject['tabs'][name]['subsection'][subsectionName][form][
                          'dynamicSubForm'
                        ] &&
                        formObject['tabs'][name]['subsection'][subsectionName][form][
                          'dynamicSubForm'
                        ][subSubSection] &&
                        formObject['tabs'][name]['subsection'][subsectionName][form][
                          'dynamicSubForm'
                        ][subSubSection][form]
                          ? formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection][form]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject['tabs'] &&
                          formObject['tabs'][name] &&
                          formObject['tabs'][name]['subsection'] &&
                          formObject['tabs'][name]['subsection'][subsectionName] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ][subSubSection] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ][subSubSection][form] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ][subSubSection][form]['datasource']
                            ? formObject['tabs'][name]['subsection'][subsectionName][form][
                                'dynamicSubForm'
                              ][subSubSection][form]['datasource']
                            : {}),
                          [datasourceKey]: {
                            ...(formObject &&
                            formObject['tabs'] &&
                            formObject['tabs'][name] &&
                            formObject['tabs'][name]['subsection'] &&
                            formObject['tabs'][name]['subsection'][subsectionName] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection][form] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection][form]['datasource'] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection][form]['datasource'][datasourceKey]
                              ? formObject['tabs'][name]['subsection'][subsectionName][form][
                                  'dynamicSubForm'
                                ][subSubSection][form]['datasource'][datasourceKey]
                              : {}),
                            [columnKey]: ''
                          }
                        },
                        ReferenceAttributes: {
                          JsonKey: [
                            'tabs',
                            name,
                            'subsection',
                            subsectionName,
                            'form',
                            'dynamicSubForm',
                            subSubSection,
                            'form'
                          ]
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    } else {
      formObject = {
        ...formObject,
        [tabs]: {
          ...(formObject && formObject['tabs'] ? formObject.tabs : {}),
          [name]: {
            ...(formObject && formObject['tabs'] && formObject['tabs'][name]
              ? formObject['tabs'][name]
              : {}),
            [subsection]: {
              ...(formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name]['subsection']
                ? formObject['tabs'][name]['subsection']
                : {}),
              [subsectionName]: {
                ...(formObject &&
                formObject['tabs'] &&
                formObject['tabs'][name] &&
                formObject['tabs'][name]['subsection'] &&
                formObject['tabs'][name]['subsection'][subsectionName]
                  ? formObject['tabs'][name]['subsection'][subsectionName]
                  : {}),
                [form]: {
                  ...(formObject &&
                  formObject['tabs'] &&
                  formObject['tabs'][name] &&
                  formObject['tabs'][name]['subsection'] &&
                  formObject['tabs'][name]['subsection'][subsectionName] &&
                  formObject['tabs'][name]['subsection'][subsectionName][form]
                    ? formObject['tabs'][name]['subsection'][subsectionName][form]
                    : {}),
                  dynamicSubForm: {
                    ...(formObject &&
                    formObject['tabs'] &&
                    formObject['tabs'][name] &&
                    formObject['tabs'][name]['subsection'] &&
                    formObject['tabs'][name]['subsection'][subsectionName] &&
                    formObject['tabs'][name]['subsection'][subsectionName][form] &&
                    formObject['tabs'][name]['subsection'][subsectionName][form]['dynamicSubForm']
                      ? formObject['tabs'][name]['subsection'][subsectionName][form][
                          'dynamicSubForm'
                        ]
                      : {}),
                    [subSubSection]: {
                      ...(formObject &&
                      formObject['tabs'] &&
                      formObject['tabs'][name] &&
                      formObject['tabs'][name]['subsection'] &&
                      formObject['tabs'][name]['subsection'][subsectionName] &&
                      formObject['tabs'][name]['subsection'][subsectionName][form] &&
                      formObject['tabs'][name]['subsection'][subsectionName][form][
                        'dynamicSubForm'
                      ] &&
                      formObject['tabs'][name]['subsection'][subsectionName][form][
                        'dynamicSubForm'
                      ][subSubSection]
                        ? formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ][subSubSection]
                        : {}),
                      [form]: {
                        ...(formObject &&
                        formObject['tabs'] &&
                        formObject['tabs'][name] &&
                        formObject['tabs'][name]['subsection'] &&
                        formObject['tabs'][name]['subsection'][subsectionName] &&
                        formObject['tabs'][name]['subsection'][subsectionName][form] &&
                        formObject['tabs'][name]['subsection'][subsectionName][form][
                          'dynamicSubForm'
                        ] &&
                        formObject['tabs'][name]['subsection'][subsectionName][form][
                          'dynamicSubForm'
                        ][subSubSection] &&
                        formObject['tabs'][name]['subsection'][subsectionName][form][
                          'dynamicSubForm'
                        ][subSubSection][form]
                          ? formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection][form]
                          : {}),
                        datasource: {
                          ...(formObject &&
                          formObject['tabs'] &&
                          formObject['tabs'][name] &&
                          formObject['tabs'][name]['subsection'] &&
                          formObject['tabs'][name]['subsection'][subsectionName] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ][subSubSection] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ][subSubSection][form] &&
                          formObject['tabs'][name]['subsection'][subsectionName][form][
                            'dynamicSubForm'
                          ][subSubSection][form]['datasource']
                            ? formObject['tabs'][name]['subsection'][subsectionName][form][
                                'dynamicSubForm'
                              ][subSubSection][form]['datasource']
                            : {}),
                          [datasourceKey]: {
                            ...(formObject &&
                            formObject['tabs'] &&
                            formObject['tabs'][name] &&
                            formObject['tabs'][name]['subsection'] &&
                            formObject['tabs'][name]['subsection'][subsectionName] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection][form] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection][form]['datasource'] &&
                            formObject['tabs'][name]['subsection'][subsectionName][form][
                              'dynamicSubForm'
                            ][subSubSection][form]['datasource'][datasourceKey]
                              ? formObject['tabs'][name]['subsection'][subsectionName][form][
                                  'dynamicSubForm'
                                ][subSubSection][form]['datasource'][datasourceKey]
                              : {}),
                            [columnKey]: columnValue
                          }
                        },
                        ReferenceAttributes: {
                          JsonKey: [
                            'tabs',
                            name,
                            'subsection',
                            subsectionName,
                            'form',
                            'dynamicSubForm',
                            subSubSection,
                            'form'
                          ]
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    }
  };

  onMainSubSectionChange = (
    columnValue,
    columnKey,
    name,
    subsectionName,
    subSubSection,
    datasourceKey,
    index
  ) => {
    let { formObject } = this.state;
    if (subSubSection === 'NoOfModelChange') {
      formObject = {
        ...formObject,
        tabs: {
          ...(formObject && formObject['tabs'] ? formObject.tabs : {}),
          [name]: {
            ...(formObject && formObject['tabs'] && formObject['tabs'][name]
              ? formObject['tabs'][name]
              : {}),
            tabs: {
              ...(formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name]['subsection']
                ? formObject['tabs'][name]['subsection']
                : {}),
              [subsectionName]: {
                ...(formObject &&
                formObject['tabs'] &&
                formObject['tabs'][name] &&
                formObject['tabs'][name]['subsection'] &&
                formObject['tabs'][name]['subsection'][subsectionName]
                  ? formObject['tabs'][name]['subsection'][subsectionName]
                  : {}),
                form: {
                  ...(formObject &&
                  formObject['tabs'] &&
                  formObject['tabs'][name] &&
                  formObject['tabs'][name]['subsection'] &&
                  formObject['tabs'][name]['subsection'][subsectionName] &&
                  formObject['tabs'][name]['subsection'][subsectionName]['form']
                    ? formObject['tabs'][name]['subsection'][subsectionName]['form']
                    : {}),
                  dynamicSubForm: {
                    ...(formObject &&
                    formObject['tabs'] &&
                    formObject['tabs'][name] &&
                    formObject['tabs'][name]['subsection'] &&
                    formObject['tabs'][name]['subsection'][subsectionName] &&
                    formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                    formObject['tabs'][name]['subsection'][subsectionName]['form']['dynamicSubForm']
                      ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ]
                      : {}),
                    [subSubSection]: {
                      ...(formObject &&
                      formObject['tabs'] &&
                      formObject['tabs'][name] &&
                      formObject['tabs'][name]['subsection'] &&
                      formObject['tabs'][name]['subsection'][subsectionName] &&
                      formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                      formObject['tabs'][name]['subsection'][subsectionName]['form'][
                        'dynamicSubForm'
                      ] &&
                      formObject['tabs'][name]['subsection'][subsectionName]['form'][
                        'dynamicSubForm'
                      ][subSubSection]
                        ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection]
                        : {}),
                      datasource: {
                        ...(formObject &&
                        formObject['tabs'] &&
                        formObject['tabs'][name] &&
                        formObject['tabs'][name]['subsection'] &&
                        formObject['tabs'][name]['subsection'][subsectionName] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ][subSubSection] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ][subSubSection]['datasource']
                          ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                              'dynamicSubForm'
                            ][subSubSection]['datasource']
                          : {}),
                        [datasourceKey]: {
                          ...(columnValue[0] &&
                          columnValue[1] &&
                          formObject &&
                          formObject['tabs'] &&
                          formObject['tabs'][name] &&
                          formObject['tabs'][name]['subsection'] &&
                          formObject['tabs'][name]['subsection'][subsectionName] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection]['datasource'] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection]['datasource'][datasourceKey]
                            ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ][subSubSection]['datasource'][datasourceKey]
                            : {}),
                          ModelChange: index + 1,
                          [columnKey]: {
                            StartDate: moment(columnValue[0]).format('YYYY-MM-DD HH:mm:ss'),
                            EndDate: moment(columnValue[1]).format('YYYY-MM-DD HH:mm:ss')
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      };
    } else if (subSubSection === 'NoOfPunchChange') {
      formObject = {
        ...formObject,
        tabs: {
          ...(formObject && formObject['tabs'] ? formObject.tabs : {}),
          [name]: {
            ...(formObject && formObject['tabs'] && formObject['tabs'][name]
              ? formObject['tabs'][name]
              : {}),
            tabs: {
              ...(formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name]['subsection']
                ? formObject['tabs'][name]['subsection']
                : {}),
              [subsectionName]: {
                ...(formObject &&
                formObject['tabs'] &&
                formObject['tabs'][name] &&
                formObject['tabs'][name]['subsection'] &&
                formObject['tabs'][name]['subsection'][subsectionName]
                  ? formObject['tabs'][name]['subsection'][subsectionName]
                  : {}),
                form: {
                  ...(formObject &&
                  formObject['tabs'] &&
                  formObject['tabs'][name] &&
                  formObject['tabs'][name]['subsection'] &&
                  formObject['tabs'][name]['subsection'][subsectionName] &&
                  formObject['tabs'][name]['subsection'][subsectionName]['form']
                    ? formObject['tabs'][name]['subsection'][subsectionName]['form']
                    : {}),
                  dynamicSubForm: {
                    ...(formObject &&
                    formObject['tabs'] &&
                    formObject['tabs'][name] &&
                    formObject['tabs'][name]['subsection'] &&
                    formObject['tabs'][name]['subsection'][subsectionName] &&
                    formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                    formObject['tabs'][name]['subsection'][subsectionName]['form']['dynamicSubForm']
                      ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ]
                      : {}),
                    [subSubSection]: {
                      ...(formObject &&
                      formObject['tabs'] &&
                      formObject['tabs'][name] &&
                      formObject['tabs'][name]['subsection'] &&
                      formObject['tabs'][name]['subsection'][subsectionName] &&
                      formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                      formObject['tabs'][name]['subsection'][subsectionName]['form'][
                        'dynamicSubForm'
                      ] &&
                      formObject['tabs'][name]['subsection'][subsectionName]['form'][
                        'dynamicSubForm'
                      ][subSubSection]
                        ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection]
                        : {}),
                      datasource: {
                        ...(formObject &&
                        formObject['tabs'] &&
                        formObject['tabs'][name] &&
                        formObject['tabs'][name]['subsection'] &&
                        formObject['tabs'][name]['subsection'][subsectionName] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ][subSubSection] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ][subSubSection]['datasource']
                          ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                              'dynamicSubForm'
                            ][subSubSection]['datasource']
                          : {}),
                        [datasourceKey]: {
                          ...(columnValue[0] &&
                          columnValue[1] &&
                          formObject &&
                          formObject['tabs'] &&
                          formObject['tabs'][name] &&
                          formObject['tabs'][name]['subsection'] &&
                          formObject['tabs'][name]['subsection'][subsectionName] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection]['datasource'] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection]['datasource'][datasourceKey]
                            ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ][subSubSection]['datasource'][datasourceKey]
                            : {}),
                          PunchChange: index + 1,
                          [columnKey]: {
                            StartDate: moment(columnValue[0]).format('YYYY-MM-DD HH:mm:ss'),
                            EndDate: moment(columnValue[1]).format('YYYY-MM-DD HH:mm:ss')
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      };
    } else {
      formObject = {
        ...formObject,
        tabs: {
          ...(formObject && formObject['tabs'] ? formObject.tabs : {}),
          [name]: {
            ...(formObject && formObject['tabs'] && formObject['tabs'][name]
              ? formObject['tabs'][name]
              : {}),
            tabs: {
              ...(formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name]['subsection']
                ? formObject['tabs'][name]['subsection']
                : {}),
              [subsectionName]: {
                ...(formObject &&
                formObject['tabs'] &&
                formObject['tabs'][name] &&
                formObject['tabs'][name]['subsection'] &&
                formObject['tabs'][name]['subsection'][subsectionName]
                  ? formObject['tabs'][name]['subsection'][subsectionName]
                  : {}),
                form: {
                  ...(formObject &&
                  formObject['tabs'] &&
                  formObject['tabs'][name] &&
                  formObject['tabs'][name]['subsection'] &&
                  formObject['tabs'][name]['subsection'][subsectionName] &&
                  formObject['tabs'][name]['subsection'][subsectionName]['form']
                    ? formObject['tabs'][name]['subsection'][subsectionName]['form']
                    : {}),
                  dynamicSubForm: {
                    ...(formObject &&
                    formObject['tabs'] &&
                    formObject['tabs'][name] &&
                    formObject['tabs'][name]['subsection'] &&
                    formObject['tabs'][name]['subsection'][subsectionName] &&
                    formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                    formObject['tabs'][name]['subsection'][subsectionName]['form']['dynamicSubForm']
                      ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ]
                      : {}),
                    [subSubSection]: {
                      ...(formObject &&
                      formObject['tabs'] &&
                      formObject['tabs'][name] &&
                      formObject['tabs'][name]['subsection'] &&
                      formObject['tabs'][name]['subsection'][subsectionName] &&
                      formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                      formObject['tabs'][name]['subsection'][subsectionName]['form'][
                        'dynamicSubForm'
                      ] &&
                      formObject['tabs'][name]['subsection'][subsectionName]['form'][
                        'dynamicSubForm'
                      ][subSubSection]
                        ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection]
                        : {}),
                      datasource: {
                        ...(formObject &&
                        formObject['tabs'] &&
                        formObject['tabs'][name] &&
                        formObject['tabs'][name]['subsection'] &&
                        formObject['tabs'][name]['subsection'][subsectionName] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ][subSubSection] &&
                        formObject['tabs'][name]['subsection'][subsectionName]['form'][
                          'dynamicSubForm'
                        ][subSubSection]['datasource']
                          ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                              'dynamicSubForm'
                            ][subSubSection]['datasource']
                          : {}),
                        [datasourceKey]: {
                          ...(columnValue[0] &&
                          columnValue[1] &&
                          formObject &&
                          formObject['tabs'] &&
                          formObject['tabs'][name] &&
                          formObject['tabs'][name]['subsection'] &&
                          formObject['tabs'][name]['subsection'][subsectionName] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection]['datasource'] &&
                          formObject['tabs'][name]['subsection'][subsectionName]['form'][
                            'dynamicSubForm'
                          ][subSubSection]['datasource'][datasourceKey]
                            ? formObject['tabs'][name]['subsection'][subsectionName]['form'][
                                'dynamicSubForm'
                              ][subSubSection]['datasource'][datasourceKey]
                            : {}),
                          [columnKey]: {
                            StartDate: moment(columnValue[0]).format('YYYY-MM-DD HH:mm:ss'),
                            EndDate: moment(columnValue[1]).format('YYYY-MM-DD HH:mm:ss')
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      };
    }

    this.props.downTimeData(formObject, false);
  };

  renderSubSubFormSwitch = (
    formObject,
    columns,
    tabs,
    name,
    subsection,
    subSectionName,
    subSubSection,
    form,
    datasourceKey,
    index
  ) => {
    const { shift } = this.state;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name].tabs &&
              formObject['tabs'][name].tabs[subSectionName] &&
              formObject['tabs'][name].tabs[subSectionName].form &&
              formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm &&
              formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm[subSubSection] &&
              formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm[subSubSection]
                .datasource &&
              formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm[subSubSection]
                .datasource[datasourceKey] &&
              formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm[subSubSection][form]
                .datasource[datasourceKey][columns.key] &&
              formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm[subSubSection][form]
                .datasource[datasourceKey][columns.key]
                ? formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm[subSubSection][
                    form
                  ].datasource[datasourceKey][columns.key]
                : null
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onInputSubSubSectionChange(
                e.target.value,
                columns.key,
                tabs,
                name,
                subsection,
                subSectionName,
                subSubSection,
                form,
                datasourceKey
              )
            }
            disabled={columns.disabled ? columns.disabled : false}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            value={
              formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name][subsection] &&
              formObject['tabs'][name][subsection][subSectionName] &&
              formObject['tabs'][name][subsection][subSectionName].form &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource[datasourceKey] &&
              (formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource[datasourceKey][columns.key] ||
                formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                  subSubSection
                ][form].datasource[datasourceKey][columns.key] === 0)
                ? formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                    subSubSection
                  ][form].datasource[datasourceKey][columns.key]
                : ''
            }
            step={columns.step ? columns.step : 1}
            placeholder={columns.placeHolder}
            // onChange={(e) => {
            //   this.onInputSubSubSectionChange(
            //     e,
            //     columns.key,
            //     name,
            //     subSectionName,
            //     subSubSection,
            //     datasourceKey
            //   );
            // }}
            onBlur={(e) => {
              this.inputSubNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key,
                tabs,
                name,
                subsection,
                subSectionName,
                subSubSection,
                form,
                datasourceKey
              );
            }}
            onChange={(e) => {
              this.onInputSubSubSectionChange(
                e,
                columns.key,
                tabs,
                name,
                subsection,
                subSectionName,
                subSubSection,
                form,
                datasourceKey,
                columns.min,
                columns.max
              );
            }}
            disabled={columns.disabled ? columns.disabled : false}
            // min={columns.min ? columns.min : 0}
            // max={columns.max ? columns.max : 1000000}
            // placeholder={columns.placeHolder}
          />
        );
      // case 'date-picker':
      //     return <DatePicker

      // disabledDate={this.disabledDate}
      //         value={
      //             formObject &&
      //                 formObject['tabs'] &&
      //                 formObject['tabs'][name] &&
      //                 formObject['tabs'][name].tabs &&
      //                 formObject['tabs'][name].tabs[subSectionName] &&
      //                 formObject['tabs'][name].tabs[subSectionName].form &&
      //                 formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm &&
      //                 formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm[subSubSection] &&
      //                 formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm[subSubSection].datasource &&
      //                 formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm[subSubSection].datasource[datasourceKey] &&
      //                 formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm[subSubSection].datasource[datasourceKey][columns.key] ?
      //                 moment(formObject['tabs'][name].tabs[subSectionName].form.dynamicSubForm[subSubSection].datasource[datasourceKey][columns.key], 'YYYY-MM-DD HH:mm:ss')
      //                 : null
      //         }
      //         onChange={(e) => this.onDateHeaderMainChange(e, columns.key)}
      //         style={{ width: 300}} />
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            data={
              formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name][subsection] &&
              formObject['tabs'][name][subsection][subSectionName] &&
              formObject['tabs'][name][subsection][subSectionName].form &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource
                ? formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                    subSubSection
                  ][form].datasource
                : []
            }
            text={
              formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name][subsection] &&
              formObject['tabs'][name][subsection][subSectionName] &&
              formObject['tabs'][name][subsection][subSectionName].form &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource[datasourceKey] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource[datasourceKey][columns.key]
                ? formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                    subSubSection
                  ][form].datasource[datasourceKey][columns.key]
                : ''
            }
            handleFieldChange={(e) =>
              this.onInputSubSubSectionChange(
                e,
                columns.key,
                tabs,
                name,
                subsection,
                subSectionName,
                subSubSection,
                form,
                datasourceKey
              )
            }
            handleKeyPress={this.handleKeyPress}
            disabled={columns.disabled ? columns.disabled : false}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            // ranges={{
            //     Today: [moment(), moment()],
            //     'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}
            // showTime={{ format: "HH:mm" }}
            allowClear={false}
            showTime={{
              hideDisabledOptions: false,
              defaultValue: this.dateRanges(shift)
            }}
            placeholder={['Start Time', 'End Time']}
            format="YYYY-MM-DD HH:mm"
            value={
              formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name][subsection] &&
              formObject['tabs'][name][subsection][subSectionName] &&
              formObject['tabs'][name][subsection][subSectionName].form &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource[datasourceKey] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource[datasourceKey][columns.key] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource[datasourceKey][columns.key]['StartDate'] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource[datasourceKey][columns.key]['EndDate']
                ? [
                    moment(
                      formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                        subSubSection
                      ][form].datasource[datasourceKey][columns.key]['StartDate'],
                      dateFormat
                    ),
                    moment(
                      formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                        subSubSection
                      ][form].datasource[datasourceKey][columns.key]['EndDate'],
                      dateFormat
                    )
                  ]
                : null
            }
            // onChange={(e) => this.onMainSubSectionChange(e, columns.key, name, subSectionName, subSubSection, datasourceKey, index)}
            //checks
            onOk={(e) =>
              this.onOk(
                e,
                columns.key,
                tabs,
                name,
                form,
                subsection,
                subSectionName,
                subSubSection,
                form,
                datasourceKey
              )
            }
            disabledTime={(e) => this.disabledRangeTime(e)}
            disabledDate={(e) => this.disabledDateRange({ ...(formObject ? formObject : {}) }, e)}
          />
        );
      case 'text':
        return (
          <Input
            style={{ width: 200 }}
            value={
              formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name][subsection] &&
              formObject['tabs'][name][subsection][subSectionName] &&
              formObject['tabs'][name][subsection][subSectionName].form &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource[datasourceKey] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource[datasourceKey][columns.key]
                ? formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                    subSubSection
                  ][form].datasource[datasourceKey][columns.key]
                : index + 1
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onInputSubSubSectionChange(
                e.target.value,
                columns.key,
                tabs,
                name,
                subsection,
                subSectionName,
                subSubSection,
                form,
                datasourceKey
              )
            }
            disabled={columns.disabled ? columns.disabled : false}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name][subsection] &&
              formObject['tabs'][name][subsection][subSectionName] &&
              formObject['tabs'][name][subsection][subSectionName].form &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource[datasourceKey] &&
              formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                subSubSection
              ][form].datasource[datasourceKey][columns.key]
                ? formObject['tabs'][name][subsection][subSectionName].form.dynamicSubForm[
                    subSubSection
                  ][form].datasource[datasourceKey][columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onInputSubSubSectionChange(
                e.target.value,
                columns.key,
                tabs,
                name,
                subsection,
                subSectionName,
                subSubSection,
                form,
                datasourceKey
              )
            }
            disabled={columns.disabled ? columns.disabled : false}
          />
        );
    }
  };

  disabledDate = (current, columns) => {
    if (columns.showTime) {
      const { formObject } = this.props;
      if (formObject.mainHeaderValue.Date !== undefined) {
        let currentDate = moment(current).format('YYYY-MM-DD');
        let Date = moment(formObject.mainHeaderValue.Date).format('YYYY-MM-DD');
        let addedDate = moment(formObject.mainHeaderValue.Date).add(1, 'day').format('YYYY-MM-DD');
        if (moment(currentDate).isSame(Date)) {
          return false;
        } else if (moment(currentDate).isSame(addedDate)) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    }
    return current > moment().endOf('day');
  };

  renderFormSwitch(formObject, columns, tabs, name, subsection, subSectionName) {
    const { shift, InputNumberType } = this.state;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject &&
              formObject[tabs] &&
              formObject[tabs][name] &&
              formObject[tabs][name].subsection &&
              formObject[tabs][name].subsection[subSectionName] &&
              formObject[tabs][name].subsection[subSectionName]['form'] &&
              formObject[tabs][name].subsection[subSectionName]['form'].datasource &&
              formObject[tabs][name].subsection[subSectionName]['form'].datasource[columns.key]
                ? formObject[tabs][name].subsection[subSectionName]['form'].datasource[columns.key]
                : null
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onInputFormHeaderMainChange(
                e.target.value,
                columns.key,
                tabs,
                name,
                subsection,
                subSectionName
              )
            }
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject &&
              formObject[tabs] &&
              formObject[tabs][name] &&
              formObject[tabs][name].subsection &&
              formObject[tabs][name].subsection[subSectionName] &&
              formObject[tabs][name].subsection[subSectionName]['form'] &&
              formObject[tabs][name].subsection[subSectionName]['form'].datasource &&
              (formObject[tabs][name].subsection[subSectionName]['form'].datasource[columns.key] ||
                formObject[tabs][name].subsection[subSectionName]['form'].datasource[
                  columns.key
                ] === 0)
                ? formObject[tabs][name].subsection[subSectionName]['form'].datasource[columns.key]
                : ''
            }
            step={columns.step ? columns.step : 1}
            // min={columns.min ? columns.min : 0}
            // max={columns.max ? columns.max : 1000000}
            placeholder={columns.placeHolder}
            // onChange={(e) =>
            //   this.onInputFormHeaderMainChange(
            //     e,
            //     columns.key,
            //     name,
            //     subSectionName
            //   )
            // }
            onBlur={(e) =>
              this.inputHeaderMainNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key,
                tabs,
                name,
                subsection,
                subSectionName,
                InputNumberType
              )
            }
            onChange={(e) =>
              this.onInputFormHeaderMainChange(
                e,
                columns.key,
                tabs,
                name,
                subsection,
                subSectionName,
                columns.min,
                columns.max,
                InputNumberType
              )
            }
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            value={
              formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name].subsection &&
              formObject['tabs'][name].subsection[subSectionName] &&
              formObject['tabs'][name].subsection[subSectionName]['form'] &&
              formObject['tabs'][name].subsection[subSectionName]['form'].datasource &&
              formObject['tabs'][name].subsection[subSectionName]['form'].datasource[columns.key]
                ? moment(
                    formObject['tabs'][name].subsection[subSectionName]['form'].datasource[
                      columns.key
                    ],
                    'YYYY-MM-DD HH:mm:ss'
                  )
                : null
            }
            showNow={false}
            allowClear={false}
            disabledDate={this.disabledDate}
            onChange={(e) =>
              this.onInputFormHeaderMainChange(
                e,
                columns.key,
                tabs,
                name,
                subsection,
                subSectionName
              )
            }
            style={{ width: 300 }}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            datasource={
              formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name]['form'] &&
              formObject['tabs'][name]['form'].datasource
                ? formObject['tabs'][name]['form'].datasource
                : []
            }
            text={
              formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name].subsection &&
              formObject['tabs'][name].subsection[subSectionName] &&
              formObject['tabs'][name].subsection[subSectionName]['form'] &&
              formObject['tabs'][name].subsection[subSectionName]['form'].datasource &&
              formObject['tabs'][name].subsection[subSectionName]['form'].datasource[columns.key]
                ? formObject['tabs'][name].subsection[subSectionName]['form'].datasource[
                    columns.key
                  ]
                : ''
            }
            handleFieldChange={(e) =>
              this.onInputFormHeaderMainChange(
                e,
                columns.key,
                tabs,
                name,
                subsection,
                subSectionName
              )
            }
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            allowClear={false}
            // ranges={{
            //     Today: [moment(), moment()],
            //     'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}
            // showTime={{ format: "HH:mm" }}
            showTime={{
              hideDisabledOptions: false,
              defaultValue: this.dateRanges(shift)
            }}
            placeholder={['Start Time', 'End Time']}
            format="YYYY-MM-DD HH:mm"
            value={
              formObject &&
              formObject[tabs] &&
              formObject[tabs][name] &&
              formObject[tabs][name].subsection &&
              formObject[tabs][name].subsection[subSectionName] &&
              formObject[tabs][name].subsection[subSectionName]['form'] &&
              formObject[tabs][name].subsection[subSectionName]['form'].datasource &&
              formObject[tabs][name].subsection[subSectionName]['form'].datasource[columns.key]
                ? [
                    moment(
                      formObject[tabs][name].subsection[subSectionName]['form'].datasource[
                        columns.key
                      ]['startDate'],
                      dateFormat
                    ),
                    moment(
                      formObject[tabs][name].subsection[subSectionName]['form'].datasource[
                        columns.key
                      ]['endDate'],
                      dateFormat
                    )
                  ]
                : null
            }
            onChange={(e) => this.onInputFormHeaderMainChange(e, columns.key)}
          />
        );
      case 'timePicker':
        return (
          <TimePicker
            use12Hours
            format="h:mm A"
            value={
              formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name].subsection &&
              formObject['tabs'][name].subsection[subSectionName] &&
              formObject['tabs'][name].subsection[subSectionName].form &&
              formObject['tabs'][name].subsection[subSectionName].form.datasource &&
              formObject['tabs'][name].tabs[subSectionName].form.datasource[columns.key]
                ? formObject['tabs'][name].tabs[subSectionName].form.datasource[columns.key]
                : null
            }
            style={{ width: 300 }}
            onChange={(e) => this.onInputFormHeaderMainChange(e, columns.key, name, subSectionName)}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject &&
              formObject['tabs'] &&
              formObject['tabs'][name] &&
              formObject['tabs'][name].subsection &&
              formObject['tabs'][name].subsection[subSectionName] &&
              formObject['tabs'][name].subsection[subSectionName].form &&
              formObject['tabs'][name].subsection[subSectionName].form.datasource &&
              formObject['tabs'][name].subsection[subSectionName].form.datasource[columns.key]
                ? formObject['tabs'][name].subsection[subSectionName].form.datasource[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onInputFormHeaderMainChange(
                e,
                columns.key,
                tabs,
                name,
                subsection,
                subSectionName
              )
            }
          />
        );
    }
  }

  saveTableViewData = (name, e, subsection, subsectionName) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      tabs: {
        ...(formObject && formObject.tabs ? formObject.tabs : {}),
        [name]: {
          ...(formObject && formObject.tabs && formObject.tabs[name] ? formObject.tabs[name] : {}),
          [subsection]: {
            ...(formObject &&
            formObject.tabs &&
            formObject.tabs[name] &&
            formObject.tabs[name]['subsection']
              ? formObject.tabs[name]['subsection']
              : {}),
            [subsectionName]: {
              ...(formObject &&
              formObject.tabs &&
              formObject.tabs[name] &&
              formObject.tabs[name]['subsection'] &&
              formObject.tabs[name]['subsection'][subsectionName]
                ? formObject.tabs[name]['subsection'][subsectionName]
                : {}),
              table: {
                ...(formObject &&
                formObject.tabs &&
                formObject.tabs[name] &&
                formObject.tabs[name]['subsection'] &&
                formObject.tabs[name]['subsection'][subsectionName] &&
                formObject.tabs[name]['subsection'][subsectionName]['table']
                  ? formObject.tabs[name]['subsection'][subsectionName]['table']
                  : {}),
                datasource: e,
                ReferenceAttributes: {
                  JsonKey: ['tabs', name, subsection, subsectionName, 'table']
                }
              }
            }
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };
  dateRanges = (shift) => {
    switch (shift) {
      case 'daily': {
        return dailyShift;
      }
      case 'Morning Shift': {
        return morningShift;
      }
      case 'Night Shift': {
        return nightShift;
      }
      default:
        return defaultRange;
    }
  };
  disabledRangeTime = (current) => {
    let { shift, formObject } = this.state;
    if (
      current !== null &&
      formObject &&
      formObject['mainHeaderValue'] &&
      formObject['mainHeaderValue']['Date']
    ) {
      let selectedDate = moment(
        formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Date']
      ).format('YYYY-MM-DD');
      let currentDate = moment(current).format('YYYY-MM-DD');
      let range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
      };
      if (shift === 'Morning Shift' && selectedDate === currentDate) {
        return {
          // disabledHours: () => range(18, 18),
          disabledHours: () => [0, 1, 2, 3, 4, 5, 18, 19, 20, 21, 22, 23],
          disabledMinutes: () => range(60, 60)
          // disabledSeconds: () => [55, 56],
        };
      } else if (shift === 'Night Shift' && selectedDate === currentDate) {
        return {
          disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
          disabledMinutes: () => range(60, 60)
        };
      } else if (shift === 'Night Shift' && selectedDate !== currentDate) {
        return {
          disabledHours: () => [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
          disabledMinutes: () => range(60, 60)
        };
      } else if (selectedDate === currentDate) {
        return {
          disabledHours: () => [0, 1, 2, 3, 4, 5],
          disabledMinutes: () => range(60, 60)
        };
      } else if (selectedDate !== currentDate) {
        return {
          disabledHours: () => [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
        };
      }
    }

    return {
      disabledHours: () => [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
      ]
    };
  };

  render() {
    let { formObject, InputNumberType } = this.state;
    return (
      <div style={{ marginTop: '30px', marginBottom: '20px', color: 'black' }}>
        <Form className="formCustomLabel" layout="vertical">
          <div
            className="formRowWrap"
            style={{
              ...(this.props.status === 'Completed'
                ? {
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }
                : {})
            }}
          >
            {formObject && formObject.mainHeader
              ? formObject.mainHeader.map((mainHeaderKey, index) => {
                  if (
                    mainHeaderKey &&
                    mainHeaderKey.show &&
                    mainHeaderKey.show.key &&
                    mainHeaderKey.show.value &&
                    formObject &&
                    formObject.mainHeader &&
                    formObject.mainHeaderValue &&
                    (!formObject.mainHeaderValue[mainHeaderKey.show.key] ||
                      formObject.mainHeaderValue[mainHeaderKey.show.key] !==
                        mainHeaderKey.show.value)
                  ) {
                    return null;
                  } else {
                    return (
                      <div key={index} style={{ paddingRight: '2em' }}>
                        <Form.Item
                          required={false}
                          label={
                            <span style={{ marginLeft: mainHeaderKey.marginLeft }}>
                              {mainHeaderKey.displayName}
                            </span>
                          }
                        >
                          <div>{this.renderMainSwitch(formObject, mainHeaderKey)}</div>
                        </Form.Item>
                      </div>
                    );
                  }
                })
              : null}
          </div>
          {formObject && formObject.tabs && Object.keys(formObject.tabs).length >= 1 ? (
            <Collapse bordered={false} className="customCollapse">
              {formObject &&
                formObject.tabs &&
                Object.keys(formObject.tabs).map((name) => {
                  if (name !== 'defaultData') {
                    return (
                      <Panel header={name} key={name}>
                        <div
                          style={{
                            margin: '20px 10px',
                            ...(this.props.status === 'Completed'
                              ? {
                                  opacity: newLocal ? 0.75 : 1,
                                  pointerEvents: newLocal ? 'none' : 'initial'
                                }
                              : {})
                          }}
                        >
                          <div
                            style={{
                              margin: '0px 20px',
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              boxSizing: 'border-box'
                            }}
                          >
                            {formObject &&
                            formObject['tabs'] &&
                            formObject['tabs'][name] &&
                            formObject['tabs'][name]['form'] &&
                            formObject['tabs'][name]['form'].columns
                              ? formObject['tabs'][name]['form'].columns.map((columns, index) => {
                                  if (
                                    columns &&
                                    columns.show &&
                                    columns.show.key &&
                                    columns.show.value &&
                                    formObject &&
                                    formObject['tabs'] &&
                                    formObject['tabs'][name] &&
                                    formObject['tabs'][name]['form'] &&
                                    formObject['tabs'][name]['form'].datasource &&
                                    (!formObject.mainHeaderValue[columns.show.key] ||
                                      formObject.mainHeaderValue[columns.show.key] !==
                                        columns.show.value) &&
                                    (!formObject['tabs'][name]['form'].datasource[
                                      columns.show.key
                                    ] ||
                                      formObject['tabs'][name]['form'].datasource[
                                        columns.show.key
                                      ] !== columns.show.value)
                                  ) {
                                    return null;
                                  } else {
                                    if (columns && !columns.hide) {
                                      return (
                                        <div key={index} style={{ paddingRight: '2em' }}>
                                          <Form.Item
                                            required={false}
                                            label={
                                              <span style={{ marginLeft: columns.marginLeft }}>
                                                {' '}
                                                {columns.displayName}{' '}
                                              </span>
                                            }
                                          >
                                            <div>
                                              {this.renderHeaderSwitch(
                                                formObject,
                                                columns,
                                                'tabs',
                                                name,
                                                'form'
                                              )}
                                            </div>
                                          </Form.Item>
                                        </div>
                                      );
                                    }
                                  }
                                  return {};
                                })
                              : null}
                          </div>

                          <div>
                            {formObject &&
                            formObject['tabs'] &&
                            formObject['tabs'][name] &&
                            formObject['tabs'][name].subsection
                              ? Object.keys(formObject['tabs'][name].subsection).map(
                                  (subSectionName, index) => {
                                    return (
                                      <div key={index}>
                                        <h3 className="subSectionTitle"> {subSectionName}</h3>
                                        <div className="mainHeaderFormBody">
                                          {formObject &&
                                          formObject['tabs'] &&
                                          formObject['tabs'][name] &&
                                          formObject['tabs'][name].subsection &&
                                          formObject['tabs'][name].subsection[subSectionName] &&
                                          formObject['tabs'][name].subsection[subSectionName]
                                            .form &&
                                          formObject['tabs'][name].subsection[subSectionName].form
                                            .columns
                                            ? formObject['tabs'][name].subsection[
                                                subSectionName
                                              ].form.columns.map((columns) => {
                                                if (
                                                  columns &&
                                                  columns.show &&
                                                  columns.show.key &&
                                                  columns.show.value &&
                                                  formObject &&
                                                  formObject.tabs &&
                                                  formObject.tabs[name] &&
                                                  formObject.tabs[name]['form'] &&
                                                  formObject.tabs[name]['form'].datasource &&
                                                  (!formObject.mainHeaderValue[columns.show.key] ||
                                                    formObject.mainHeaderValue[columns.show.key] !==
                                                      columns.show.value) &&
                                                  (!formObject.tabs[name]['form'].datasource[
                                                    columns.show.key
                                                  ] ||
                                                    formObject.tabs[name]['form'].datasource[
                                                      columns.show.key
                                                    ] !== columns.show.value)
                                                ) {
                                                  return null;
                                                } else {
                                                  if (columns && !columns.hide) {
                                                    return (
                                                      <div
                                                        key={index}
                                                        style={{
                                                          paddingRight: '2em'
                                                        }}
                                                      >
                                                        <Form.Item
                                                          required={false}
                                                          label={
                                                            <span
                                                              style={{
                                                                marginLeft: columns.marginLeft
                                                              }}
                                                            >
                                                              {' '}
                                                              {columns.displayName}{' '}
                                                            </span>
                                                          }
                                                        >
                                                          <div>
                                                            {this.renderFormSwitch(
                                                              formObject,
                                                              columns,
                                                              'tabs',
                                                              name,
                                                              'subsection',
                                                              subSectionName,
                                                              InputNumberType
                                                            )}
                                                          </div>
                                                        </Form.Item>
                                                      </div>
                                                    );
                                                  }
                                                }
                                                return {};
                                              })
                                            : null}
                                        </div>

                                        <div>
                                          {formObject &&
                                          formObject['tabs'] &&
                                          formObject['tabs'][name] &&
                                          formObject['tabs'][name].subsection &&
                                          formObject['tabs'][name].subsection[subSectionName] &&
                                          formObject['tabs'][name].subsection[subSectionName]
                                            .form &&
                                          formObject['tabs'][name].subsection[subSectionName].form
                                            .dynamicSubForm
                                            ? Object.keys(
                                                formObject['tabs'][name].subsection[subSectionName]
                                                  .form.dynamicSubForm
                                              ).map((subsubName, index) => {
                                                return (
                                                  <div key={index}>
                                                    {formObject['tabs'][name].subsection[
                                                      subSectionName
                                                    ].form.dynamicSubForm &&
                                                    formObject['tabs'][name].subsection[
                                                      subSectionName
                                                    ].form.dynamicSubForm[subsubName] &&
                                                    formObject['tabs'][name].subsection[
                                                      subSectionName
                                                    ].form.dynamicSubForm[subsubName].form
                                                      .datasource ? (
                                                      <div>
                                                        {Object.keys(
                                                          formObject['tabs'][name].subsection[
                                                            subSectionName
                                                          ].form.dynamicSubForm[subsubName]['form']
                                                            .datasource
                                                        ).map((datasource, key) => {
                                                          return (
                                                            <div
                                                              key={key}
                                                              className="mainHeaderFormBody"
                                                            >
                                                              {formObject &&
                                                                formObject['tabs'] &&
                                                                formObject['tabs'][name] &&
                                                                formObject['tabs'][name]
                                                                  .subsection &&
                                                                formObject['tabs'][name].subsection[
                                                                  subSectionName
                                                                ] &&
                                                                formObject['tabs'][name].subsection[
                                                                  subSectionName
                                                                ].form &&
                                                                formObject['tabs'][name].subsection[
                                                                  subSectionName
                                                                ].form.dynamicSubForm &&
                                                                formObject['tabs'][name].subsection[
                                                                  subSectionName
                                                                ].form.dynamicSubForm[subsubName] &&
                                                                formObject['tabs'][name].subsection[
                                                                  subSectionName
                                                                ].form.dynamicSubForm[subsubName][
                                                                  'form'
                                                                ] &&
                                                                formObject['tabs'][name].subsection[
                                                                  subSectionName
                                                                ].form.dynamicSubForm[subsubName][
                                                                  'form'
                                                                ].columns.map((columns) => {
                                                                  if (
                                                                    columns &&
                                                                    columns.show &&
                                                                    columns.show.key &&
                                                                    columns.show.value &&
                                                                    formObject &&
                                                                    formObject &&
                                                                    formObject.tabs &&
                                                                    formObject.tabs[name] &&
                                                                    formObject.tabs[name]['form'] &&
                                                                    formObject.tabs[name]['form']
                                                                      .datasource &&
                                                                    (!formObject.tabs[name]['form']
                                                                      .datasource[
                                                                      columns.show.key
                                                                    ] ||
                                                                      formObject.tabs[name]['form']
                                                                        .datasource[
                                                                        columns.show.key
                                                                      ] !== columns.show.value)
                                                                  ) {
                                                                    return null;
                                                                  } else {
                                                                    return (
                                                                      <div
                                                                        key={key}
                                                                        style={{
                                                                          paddingRight: '2em'
                                                                        }}
                                                                      >
                                                                        <Form.Item
                                                                          required={false}
                                                                          label={
                                                                            <span
                                                                              style={{
                                                                                marginLeft:
                                                                                  columns.marginLeft
                                                                              }}
                                                                            >
                                                                              {' '}
                                                                              {
                                                                                columns.displayName
                                                                              }{' '}
                                                                            </span>
                                                                          }
                                                                        >
                                                                          <div>
                                                                            {this.renderSubSubFormSwitch(
                                                                              formObject,
                                                                              columns,
                                                                              'tabs',
                                                                              name,
                                                                              'subsection',
                                                                              subSectionName,
                                                                              subsubName,
                                                                              'form',
                                                                              datasource,
                                                                              key
                                                                            )}
                                                                          </div>
                                                                        </Form.Item>
                                                                      </div>
                                                                    );
                                                                  }
                                                                })}
                                                            </div>
                                                          );
                                                        })}
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                );
                                              })
                                            : null}
                                        </div>

                                        <div className="mainHeaderFormBody">
                                          {formObject &&
                                          formObject['tabs'] &&
                                          formObject['tabs'][name] &&
                                          formObject['tabs'][name].subsection &&
                                          formObject['tabs'][name].subsection[subSectionName] &&
                                          formObject['tabs'][name].subsection[subSectionName]
                                            .table &&
                                          formObject['tabs'][name].subsection[subSectionName].table
                                            .columns ? (
                                            <TableView
                                              form={
                                                formObject['tabs'][name].subsection[subSectionName]
                                                  .table
                                              }
                                              mainHeaderValue={
                                                formObject &&
                                                formObject['tabs'] &&
                                                formObject['tabs'][name] &&
                                                formObject['tabs'][name]['form'] &&
                                                formObject['tabs'][name]['form']['datasource']
                                                  ? formObject['tabs'][name]['form']['datasource']
                                                  : {}
                                              }
                                              recordType={'FormSubSectionTable'}
                                              showAddButton={
                                                formObject &&
                                                formObject['tabs'] &&
                                                formObject['tabs'][name] &&
                                                formObject['tabs'][name].subsection &&
                                                formObject['tabs'][name].subsection[
                                                  subSectionName
                                                ] &&
                                                formObject['tabs'][name].subsection[subSectionName]
                                                  .table &&
                                                formObject['tabs'][name].subsection[subSectionName]
                                                  .table.showAddButton
                                                  ? formObject['tabs'][name].subsection[
                                                      subSectionName
                                                    ].table.showAddButton
                                                  : false
                                              }
                                              saveTableViewData={(e) =>
                                                this.saveTableViewData(
                                                  name,
                                                  e,
                                                  'subsection',
                                                  subSectionName
                                                )
                                              }
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              : null}
                          </div>
                        </div>
                      </Panel>
                    );
                  }
                  return {};
                })}
            </Collapse>
          ) : null}
        </Form>
      </div>
    );
  }
}

export default FormWithFormSubSectionFormTable;
