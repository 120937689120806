import React, { Component } from 'react';
import { Button, Input } from 'antd';
import { createStructuredSelector } from 'reselect';
import Highlighter from 'react-highlight-words';
import QRCodePrint from 'react-qr-code';
import QRCodeImage from 'qrcode.react';
import axios from 'axios';
import { SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { exportComponentAsJPEG } from 'react-component-export-image';

import localStorage from '../../../../utils/localStorage';
import { getMasterList } from './Utils/FormCalls';
import { constant } from './Utils/constants';
import translation from './Utils/translation';
import { getTranslationCache } from '../../../../selectors/language';
import themeSettings from './Utils/themeSettings.json';
import ListView from './Utils/ListView';
import { StyledComponent, StyledButton, StyledModal } from './CSS/style';
import { API_DEVICE } from '../../../../commons/api';

const urlList = {
  list: `${API_DEVICE.GetScannersList}`
};

class QrList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      viewQRModal: false,
      SpecificationData: [],
      activeKey: '1',
      form: {},
      translationCache: props.translationCache || [],
      userProfile: props && props.userProfile ? props.userProfile : {},
      userList: [],
      config: {
        viewTypes: ['add'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['QRCode', 'Description'],

        firstTitle: 'QRCode',
        secondTitle: 'CreatedBy',
        thirdTitle: 'CreatedTime',

        actions: ['edit', 'delete'],
        edit: {
          byAttribute: 'ScannerId',
          redirectURL: '/rubus/QrDetails'
        }
        // deletee: {
        //   byAttribute: 'Id'
        // }
      }
    };
  }
  imageRef = React.createRef();
  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.match.params.Masters !== prevprops.match.params.Masters) {
      let mastername = this.props.match.params.Masters;
      this._getAdhocDataSource();
      this.setState({ mastername });
    }
    if (this.props.userProfile !== prevprops.userProfile) {
      this.setState({ userProfile: this.props.userProfile });
    }
  }
  componentDidMount = async () => {
    this._getAdhocDataSource();
  };

  // _getUserList = async () => {
  //   let userList = await getUsersList();
  //   this.setState({ userList });
  // };
  _getAdhocDataSource = async () => {
    let dataSource = await getMasterList(urlList.list);
    this.setState({
      dataSource,
      activeKey: '1',
      form: {}
    });
  };

  onModalOk = () => {
    this.setState({
      viewQRModal: false
    });
  };

  getQrList = async (url) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let Object = {};
    Object = {
      method: 'GET',
      url: `${url}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    return axios(Object)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };

  // export async function getMasterList(url) {
  //   const accessToken = localStorage.get('accessToken');
  //   const siteId = localStorage.get('currentSite');
  //   let Object = {};
  //   Object = {
  //     method: 'GET',
  //     url: `${url}?SiteId=${siteId}`,
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`
  //     }
  //   };

  //   return axios(Object)
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch(() => {
  //       return [];
  //     });
  // }
  // downTimeData = (form) => {
  //   this.setState({
  //     form
  //   });
  // };
  // _deleteById = async (id) => {
  //   // let payload = {
  //   //   url: `${MASTERTASK.DELETE}?Id=${id}`,
  //   //   method: 'POST'
  //   // };
  //   await deleteMaster(urlList.delete, id);
  //   //await deleteById(payload);
  //   let dataSource = await getMasterList(urlList.list);
  //   this.setState({
  //     dataSource
  //   });
  // };
  // deleteItemMaster = async (record) => {
  //   await deleteMaster(urlList.delete, record.Id);
  //   let dataSource = await getMasterList(urlList.list);
  //   this.setState({
  //     dataSource,
  //     activeKey: '1'
  //     // form: {}
  //   });
  // };
  // changeStatus = async (id) => {
  //     await changeWorkOrderStatus(id)
  //     let dataSource = await getEmergencyWorkOrdersList()
  //     this.setState({
  //         dataSource,
  //         activeKey: "1",
  //         // form: {}
  //     });

  // }

  editAssetMaster = async (record) => {
    this.setState({
      viewQRModal: true,
      value: record.ScannerId
    });
    // let { config } = this.state;
    // history.push({ pathname: `${config.edit.redirectURL}/${base64_encode(record.ScannerId)}` });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
        >
          {translation(this.props.translationCache, 'Search')}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small">
          {translation(this.props.translationCache, 'Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  print = () => {
    const printablecontent = document.getElementById('print').innerHTML;
    const printwindow = window.open('', '_blank', '', '');
    // printwindow.document.write(printablecontent.innerHTML)
    // printwindow.print()
    printwindow.document.write(printablecontent);
    // printwindow.document.close();
    printwindow.print();
    printwindow.close();
  };
  render() {
    const { dataSource, userList, value } = this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, constant.scannerID),
        dataIndex: 'ScannerId',
        key: 'ScannerId',
        filter: true,
        ...this.getColumnSearchProps('ScannerId')
      },
      {
        title: translation(translationCache, constant.equipment),
        dataIndex: 'DisplayCode',
        key: 'DisplayCode',
        ...this.getColumnSearchProps('DisplayCode')
      },
      {
        title: translation(translationCache, constant.equipmentDescription),
        dataIndex: 'Device_Description',
        key: 'Device_Description',
        filter: true
      },
      {
        title: translation(translationCache, constant.location),
        dataIndex: 'Location_DisplayCode',
        key: 'Location_DisplayCode',
        ...this.getColumnSearchProps('Location_DisplayCode')
      },
      {
        title: translation(translationCache, constant.locationDescription),
        dataIndex: 'Location_Description',
        key: 'Location_Description',
        filter: true
      },
      // {
      //     title: "Status",
      //     dataIndex: "Status",
      //     key: "Status",
      //     render: (text, record) => (
      //         <>
      //             <Tooltip title={`Click To ${text === 'Active' ? 'Inactive' : "Active"}`}>
      //                 <span onClick={() => this.changeStatus(record.Id)} >
      //                     <Tag color={text && text === 'Active' ? '#87d068' :
      //                         text === 'Inactive' ? "#ff4d4f" : "gold"} key={text}>
      //                         {text}
      //                     </Tag>
      //                 </span>
      //             </Tooltip>

      //         </>
      //     )
      // },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            <StyledButton
              theme={themeSettings}
              onClick={() => this.editAssetMaster(record)}
              size="middle"
            >
              {/* {' '} */}
              {/* <Tooltip title={translation(translationCache, 'Edit')}> */}
              {/* <EditOutlined /> */}
              {translation(translationCache, 'View QR')}
              {/* </Tooltip> */}
            </StyledButton>
          </>
        )
      }
    ];
    let config = {
      title: translation(translationCache, constant.QrCodeList),

      viewTypes: ['add'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['Item', 'Description'],

      firstTitle: 'QrList',
      secondTitle: 'CreatedBy',
      thirdTitle: 'CreatedTime',

      actions: ['edit', 'delete'],
      edit: {
        byAttribute: 'ScannerId',
        redirectURL: '/rubus/QrDetails'
      },
      deletee: {
        byAttribute: 'Id'
      }
    };
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 63 }}>
        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
        <StyledModal
          open={this.state.viewQRModal}
          // width={200}
          bodyStyle={{ height: 500 }}
          closable
          title={translation(translationCache, constant.viewQr)}
          okText={translation(this.props.translationCache, 'Ok')}
          cancelText={translation(this.props.translationCache, 'Cancel')}
          onOk={this.onModalOk}
          onCancel={this.onModalOk}
        >
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                ref={this.imageRef}
                style={{
                  height: 340,
                  width: 420,
                  backgroundColor: 'white'
                }}
              >
                <p style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  {'ScannerId'}:{this.state.value}
                </p>

                <QRCodeImage
                  id="QRIMAGE"
                  style={{ marginLeft: '110px' }}
                  value={`${JSON.stringify({ ScannerId: value })}`}
                  // delay={this.state.delay}
                  // style={previewStyle}
                  size={220}
                  onError={this.handleError}
                  onScan={this.handleScan}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
                marginBottom: '40px'
              }}
            >
              <StyledButton
                theme={themeSettings}
                style={{ marginRight: '50px' }}
                onClick={() => exportComponentAsJPEG(this.imageRef)}
              >
                {translation(translationCache, constant.SaveImage)}
              </StyledButton>

              <StyledButton
                theme={themeSettings}
                style={{ marginRight: '6px' }}
                onClick={this.print}
              >
                {translation(translationCache, constant.PrintImage)}
              </StyledButton>

              {/* <StyledButton theme={themeSettings} style={{ marginLeft: "30px" }}
                onClick={this.downloadQRCode}>
                {translation(translationCache, constant.SaveImage)}
              </StyledButton>
              <StyledButton theme={themeSettings} style={{ marginRight: "30px", height: "40px", width: "120px" }}
                //    onClick={() => exportComponentAsPDF(this.imageRef)}
                onClick={this.downloadQRCodeasPdf}
              >
                {translation(translationCache, constant.SavePdf)}
              </StyledButton>
              <StyledButton theme={themeSettings} htmlType="submit" style={{ marginRight: "30px", height: "40px", width: "120px" }}>
                {translation(translationCache, constant.saveList)}
              </StyledButton>
              <StyledButton theme={themeSettings} style={{ marginRight: "40px", height: "40px", width: "120px" }}
                onClick={() => { this.sendEmail('emailmodal') }}>
                {translation(translationCache, constant.SendEmail)}
              </StyledButton> */}
            </div>
            {
              <div
                id="print"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  opacity: 0,
                  marginLeft: '950px',
                  height: 240,
                  width: 320,
                  backgroundColor: 'white'
                }}
              >
                <p
                  size={400}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '30px',
                    marginTop: '180px'
                  }}
                >
                  {'ScannerId'}:{this.state.value}
                </p>
                <QRCodePrint
                  display={false}
                  id="QRPrint"
                  style={{ marginLeft: '120px' }}
                  value={`${JSON.stringify({ ScannerId: value })}`}
                  size={400}
                  onError={this.handleError}
                  onScan={this.handleScan}
                />
              </div>
            }
          </div>
        </StyledModal>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(QrList);
