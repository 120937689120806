import React from 'react';
// import { Map, TileLayer } from 'react-leaflet';
import './map.css';
import 'leaflet/dist/leaflet.css';
// import { Select } from 'antd';
import MapWithTable from './MapWithTable';

// const defaultCenter = [38.9072, -77.0369];
// const defaultZoom = 8;

function MapWithSidePanel() {
  return (
    <div>
      <div
        style={{
          color: 'black',
          fontWeight: 'bold',
          fontSize: '18px',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#aad3df'
        }}
      >
        ASSET REALTIME TRACKING SYSTEM
      </div>
      <MapWithTable />
    </div>
  );
}

export default MapWithSidePanel;
