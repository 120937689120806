/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Task
 */
import React, { Component } from 'react';
import moment from 'moment';
import {
  Input,
  Popconfirm,
  Tooltip,
  Divider,
  Space,
  Card,
  Pagination,
  Select,
  Empty,
  DatePicker,
  Form
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
  PlusOutlined,
  SolutionOutlined
} from '@ant-design/icons';

import { constant } from './constants';
import translation from './translation';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { encode as base64_encode } from 'base-64';
import { getTranslationCache } from '../../../selectors/language';
import history from '../../../commons/history';
import {
  StyledTable,
  CardTitle,
  StyledFormType,
  CardFooterActions,
  StyledComponent,
  StyledNoBorderSelect,
  StyledForm
} from '../CSS/style';
import themeSettings from './themeSettings.json';

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: props && props.userList ? props.userList : [],
      columns: props && props.columns ? props.columns : [],
      dataSource: props && props.dataSource ? props.dataSource : [],
      listData: props && props.dataSource ? props.dataSource : [],

      config: props && props.config ? props.config : {},
      viewType:
        props.config && props.config.defaultViewType ? props.config.defaultViewType : 'card',

      searchField: '',
      current: 1,
      pageSize: 10,
      filterKey: null,
      EquipmentKey: ''
    };
  }

  componentDidUpdate(prevprops) {
    if (
      this.props.userProfile !== prevprops.userProfile ||
      this.props.dataSource !== prevprops.dataSource ||
      this.props.translationCache !== prevprops.translationCache ||
      this.props.config !== prevprops.config
    ) {
      this.setState({
        userProfile: this.props.userProfile,
        listData: this.props.dataSource,
        config: this.props.config
      });
    }
  }

  filterByValue = (value) => {
    let { dataSource } = this.props;
    let { filterKey } = this.state;
    return (
      dataSource &&
      Array.isArray(dataSource) &&
      dataSource.filter((data) => {
        return (
          data[filterKey] &&
          data[filterKey].toString().toLowerCase().includes(value.toString().toLowerCase())
        );
      })
    );
  };

  onSearch = (value) => {
    let listData = this.filterByValue(value);
    this.setState({ listData });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  add3Dots = (text, limit) => {
    let dots = '...';
    if (text && text.length > limit) {
      text = text.substring(0, limit) + dots;
    }
    return text;
  };

  onPaginationChange = (current, pageSize) => {
    this.setState({ current, pageSize });
  };

  onViewTypeChange = (viewType) => {
    this.setState({ viewType });
  };

  _deleteRecord = async (id) => {
    this.props.delete(id);
  };

  _editRecord = async (id) => {
    let { config } = this.state;
    history.push({ pathname: `${config.edit.redirectURL}/${base64_encode(id)}` });
  };

  _editRecordDetails = async (id) => {
    let { config } = this.props;
    history.push({ pathname: `${config.edit.redirectDetailsURL}/${base64_encode(id)}` });
  };
  getUserName = (list, secondTitle) => {
    let { userList } = this.props;
    let userData =
      userList &&
      Array.isArray(userList) &&
      userList.filter((user) => user.Id === list[secondTitle]);

    return (userData && userData[0] && userData[0].FirstName) || list[secondTitle];
  };
  render() {
    const { current, pageSize, viewType, listData } = this.state;
    let { columns, dataSource, config, selection, userList } = this.props;
    let {
      title,
      viewTypes,
      filterType,
      attributeList,
      attributeListTitle,
      firstTitle,
      secondTitle,
      thirdTitle,
      actions,
      edit,
      deletee
    } = config;
    const IconText = ({ icon, text }) => (
      <Space>
        {React.createElement(icon)}
        {text}
      </Space>
    );
    const LabelValue = ({ labelText, valueText }) => (
      <Space>
        {labelText}
        {valueText}
      </Space>
    );
    console.log('config.secondTitle', config.secondTitle, userList);

    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 135 }}>
        <>
          <StyledFormType theme={themeSettings}> {title}</StyledFormType>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledNoBorderSelect>
              {filterType === 'ByAttributeValue' && viewType === 'card' ? (
                <div>
                  <Select
                    showSearch
                    placeholder={translation(this.props.translationCache, constant.selectAttribute)}
                    allowClear
                    value={this.state.filterKey}
                    style={{
                      background: '#1a3652',
                      borderRight: 'none',
                      borderTop: 'none',
                      borderLeft: 'none',
                      width: '40%',
                      color: 'white'
                    }}
                    onChange={(e) => {
                      this.setState({ filterKey: e });
                    }}
                  >
                    {attributeList && Array.isArray(attributeList)
                      ? attributeList.map((attribute, index) => {
                          return (
                            <Select.Option key={attribute}>
                              {attributeListTitle && attributeListTitle[index]
                                ? attributeListTitle[index]
                                : attribute}
                            </Select.Option>
                          );
                        })
                      : null}
                  </Select>
                  <Input
                    type="text"
                    style={{
                      background: '#1a3652',
                      borderRight: 'none',
                      borderTop: 'none',
                      color: 'white',
                      borderLeft: 'none',
                      width: '60%'
                    }}
                    id="searchText"
                    value={this.state.searchField}
                    onChange={(e) => {
                      this.setState({ searchField: e.target.value });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        this.onSearch(this.state.searchField);
                      }
                    }}
                    placeholder={translation(this.props.translationCache, constant.search)}
                  />
                  {/* <Button onClick={()=>this.props.reset()}>reset</Button> */}
                </div>
              ) : null}
            </StyledNoBorderSelect>

            <div style={{ marginTop: '9px' }}>
              {viewTypes && viewTypes.includes('add') ? (
                <Tooltip
                  placement="top"
                  key="Add"
                  title={translation(this.props.translationCache, constant.Add)}
                >
                  <PlusOutlined
                    onClick={() =>
                      history.push({
                        pathname: `${config.edit.redirectURL}/${base64_encode('new')}`
                      })
                    }
                    style={{
                      fontSize: '25px',
                      color: viewType === 'table' ? 'rgb(255, 108, 0)' : '#dfa230',
                      marginLeft: '10px'
                    }}
                  />
                </Tooltip>
              ) : (
                <div></div>
              )}
              <Divider type="vertical" />
              {viewTypes && viewTypes.includes('card') ? (
                <Tooltip
                  placement="top"
                  key="GridView"
                  title={translation(this.props.translationCache, constant.GridView)}
                >
                  <AppstoreOutlined
                    onClick={() => this.onViewTypeChange('card')}
                    style={{
                      fontSize: '25px',
                      color: viewType === 'card' ? 'rgb(255, 108, 0)' : '#dfa230',
                      marginLeft: '10px'
                    }}
                  />
                </Tooltip>
              ) : null}

              {viewTypes && viewTypes.includes('table') ? (
                <Tooltip
                  placement="top"
                  key="ListView"
                  title={translation(this.props.translationCache, constant.ListView)}
                >
                  <UnorderedListOutlined
                    onClick={() => this.onViewTypeChange('table')}
                    style={{
                      fontSize: '25px',
                      color: viewType === 'table' ? 'rgb(255, 108, 0)' : '#dfa230',
                      marginLeft: '10px'
                    }}
                  />
                </Tooltip>
              ) : null}
            </div>
          </div>
          {selection && selection.widget && selection.widget === 'date-picker' ? (
            <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 0px' }}>
              <StyledForm theme={themeSettings}>
                <Form.Item label="Select Date">
                  <DatePicker
                    placeholder="Select Date"
                    defaultValue={moment()}
                    format={'YYYY-MM-DD'}
                    onChange={(e) => this.props._getDataSource(moment(e).format('YYYY-MM-DD'))}
                  />
                </Form.Item>
              </StyledForm>
            </div>
          ) : null}

          <div style={{ marginTop: '10px' }}>
            {viewType === 'card' ? (
              <div>
                {listData && Array.isArray(listData) && listData.length !== 0 ? (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {listData
                      .filter(
                        (e, index) =>
                          index >= pageSize * current - pageSize && index < pageSize * current
                      )
                      .map((list) => {
                        return (
                          <Card
                            key={list.TaskName}
                            style={{ width: '410px', padding: '5px', margin: '5px' }}
                          >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <CardTitle theme={themeSettings}>
                                {this.add3Dots(list[firstTitle], 35)}
                              </CardTitle>

                              <div style={{ margin: '0px 0px 7px 0px' }}>
                                {config.TitleView === true ? (
                                  <LabelValue
                                    labelText={
                                      config.InspectionView === true
                                        ? 'Description :'
                                        : `${config.secondTitle}:`
                                    }
                                    valueText={list[secondTitle]}
                                    key="list-vertical-star-o"
                                  />
                                ) : (
                                  <IconText
                                    icon={UserOutlined}
                                    text={this.getUserName(list, secondTitle)}
                                    key="list-vertical-star-o"
                                  />
                                )}
                              </div>
                              {config && config.thirdTitle ? (
                                config && config.TitleView === true ? (
                                  <div style={{ margin: '0px 0px 7px 0px' }}>
                                    <LabelValue
                                      labelText={`${config.thirdTitle}:`}
                                      valueText={list[thirdTitle]}
                                      key="list-vertical-like-o"
                                    />
                                  </div>
                                ) : (
                                  <div style={{ margin: '0px 0px 7px 0px' }}>
                                    <IconText
                                      icon={CalendarOutlined}
                                      text={moment(list[thirdTitle])
                                        .utc()
                                        .local()
                                        .format('YYYY-MM-DD HH:mm:ss')}
                                      key="list-vertical-like-o"
                                    />
                                  </div>
                                )
                              ) : null}
                            </div>
                            <Divider
                              style={{
                                borderTop: '1px solid #000',
                                height: '0px',
                                margin: '15px 0px'
                              }}
                            />

                            <CardFooterActions>
                              {actions && actions.includes('edit') ? (
                                <Tooltip
                                  placement="top"
                                  key="Edit"
                                  title={translation(this.props.translationCache, constant.Edit)}
                                >
                                  <EditOutlined
                                    style={{
                                      color: themeSettings.list.card.icon.color,
                                      marginRight: '8px'
                                    }}
                                    onClick={() => this._editRecord(list[edit.byAttribute])}
                                  />
                                </Tooltip>
                              ) : config.InspectionView === true ? (
                                <Tooltip
                                  placement="right"
                                  title={translation(
                                    this.props.translationCache,
                                    constant.ViewInspection
                                  )}
                                >
                                  <EyeOutlined
                                    onClick={() => this._editRecord(list[edit.byAttribute])}
                                  />
                                </Tooltip>
                              ) : null}
                              <Divider type="vertical" />
                              {actions && actions.includes('delete') ? (
                                <Popconfirm
                                  key="1"
                                  title="Are you sure you want to delete the record ?"
                                  icon={<QuestionCircleOutlined style={{ color: '#d98e04' }} />}
                                  onConfirm={() => this._deleteRecord(list[deletee.byAttribute])}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Tooltip
                                    placement="top"
                                    key="Delete"
                                    title={translation(
                                      this.props.translationCache,
                                      constant.Delete
                                    )}
                                  >
                                    <DeleteOutlined
                                      style={{
                                        color: themeSettings.list.card.icon.color,
                                        marginRight: '8px'
                                      }}
                                    />
                                  </Tooltip>
                                </Popconfirm>
                              ) : null}
                              <Divider type="vertical" />
                              {actions && actions.includes('details') ? (
                                <Tooltip
                                  placement="top"
                                  key="Edit"
                                  title={translation(this.props.translationCache, constant.Edit)}
                                >
                                  <SolutionOutlined
                                    style={{
                                      color: themeSettings.list.card.icon.color,
                                      marginRight: '8px'
                                    }}
                                    onClick={() => this._editRecordDetails(list[edit.byAttribute])}
                                  />
                                </Tooltip>
                              ) : null}
                            </CardFooterActions>
                          </Card>
                        );
                      })}
                  </div>
                ) : (
                  <Empty style={{ color: 'white' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}

                {listData && Array.isArray(listData) && listData.length !== 0 ? (
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '14px' }}>
                    <Pagination
                      current={current}
                      onChange={this.onPaginationChange}
                      total={listData.length}
                      pageSize={10}
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              <StyledTable
                theme={themeSettings}
                dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
                columns={columns}
                scroll={{ x: 1300 }}
              ></StyledTable>
            )}
          </div>
        </>
      </StyledComponent>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ListView);
