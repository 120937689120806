import React from 'react';
import Icon from '@ant-design/icons';

const CustomMicrophoneSvg = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="audio"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M512 624c79.5 0 144-64.5 144-144V248c0-79.5-64.5-144-144-144s-144 64.5-144 144v232c0 79.5 64.5 144 144 144zm-48-376c0-26.5 21.5-48 48-48s48 21.5 48 48v232c0 26.5-21.5 48-48 48s-48-21.5-48-48V248zm-48 376h192v48H416v-48zm-48 0h-48v48h48v-48zm288 0h48v48h-48v-48z" />
  </svg>
);

const CustomMicrophoneIcon = (props) => <Icon component={CustomMicrophoneSvg} {...props} />;

export default CustomMicrophoneIcon;
