import React from 'react';

import list from './mock.json';
import './style.css';

class SiteOverview extends React.Component {
  getColor = (device) => {
    switch (device.status) {
      case 'Running': {
        return '#66d769';
      }
      case 'Idle': {
        return 'rgb(120, 187, 255)';
      }
      case 'Stop': {
        return '#ff795f';
      }
      case 'Manual Stop': {
        return '#ffb65b';
      }
      case 'Comm Fail': {
        return '#fff94d';
      }
      default:
        return '#444';
    }
  };
  getLightColor = (device) => {
    switch (device.status) {
      case 'Running': {
        return '#cdf9ce';
      }
      case 'Idle': {
        return 'rgb(120, 187, 255)';
      }
      case 'Stop': {
        return '#ff795f';
      }
      case 'Manual Stop': {
        return '#ffb65b';
      }
      case 'Comm Fail': {
        return '#fff94d';
      }
      default:
        return '#444';
    }
  };

  render() {
    return (
      <div style={{ backgroundColor: 'white', minHeight: window.innerHeight - 67 }}>
        {list &&
          Object.keys(list) &&
          Object.keys(list).map((siteName, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexFlow: 'wrap'
                }}
              >
                <div
                  style={{
                    color: 'white',
                    width: '90px',
                    marginBottom: '4px',
                    marginRight: '4px',
                    position: 'relative',
                    outline: 0,
                    overflow: 'hidden',
                    background: '#444',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {siteName}
                </div>

                {list[siteName] &&
                  Array.isArray(list[siteName]) &&
                  list[siteName].map((device, dindex) => {
                    return (
                      <div
                        key={dindex}
                        style={{
                          width: '90px',
                          background: this.getLightColor(device),
                          marginBottom: '4px',
                          marginRight: '4px',
                          position: 'relative',
                          outline: 0,
                          overflow: 'hidden',
                          display: 'flex'
                        }}
                      >
                        <div
                          style={{
                            width: `calc(100% - ${device.RunningValue || 0}px)`,
                            background: this.getColor(device),
                            justifyContent: 'center'
                          }}
                        >
                          {device.name}
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>
    );
  }
}

export default SiteOverview;
