import React, { Fragment, Component } from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ReactVideoPlayer } from 'video-player-for-react';
import { DownOutlined } from '@ant-design/icons';

import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../../ChartCalls';
import { getTranslationCache } from '../../../../../../selectors/language';
import { ErrorComponent, NoRecordComponent } from '../../../Utils';
import { makeSelectThemeing } from '../../../../../../selectors/theme';
import { defaultRefreshTime, excelDownloadUrl } from '../../../constant.json';
import { videoMock } from '../../../../Mock/MockJson';

import berth from './images/berth.jpg';
import berth1 from './images/berth1.jpg';
import berth2 from './images/berth2.jpg';
import berth11 from './images/berth11.jpg';

// import './style.css'
import 'video-player-for-react/dist/index.css';

class VideoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagesList: [],
      currentImage: berth,
      currentVideo: '',
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      translationCache: props.translationCache || []
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }

    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.deviceId !== this.props.deviceId ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  openImageViewer = (image, video) => {
    this.setState({
      currentImage: image,
      currentVideo: video
    });
  };

  closeImageViewer = () => {
    this.setState({
      CurrentImage: 0,
      isViewerOpen: false
    });
  };

  getData = async () => {
    this.requestCheck = false;
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = videoMock;
      this.requestCheck = true;
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection({
          graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          selectionDateLive: this.props.selectionDateLive,
          currentTimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      this.setState({
        imagesList: [
          {
            id: 1,
            imageUrl: berth,
            videoUrl: 'https://icedrive.net/s/NFvRu9597CVYx9uigNG2jTufyhwA'
          },
          {
            id: 2,
            imageUrl: berth1,
            videoUrl: 'https://icedrive.net/s/NFvRu9597CVYx9uigNG2jTufyhwA'
          },
          {
            id: 3,
            imageUrl: berth2,
            videoUrl: 'https://icedrive.net/s/NFvRu9597CVYx9uigNG2jTufyhwA'
          },
          {
            id: 4,
            imageUrl: berth11,
            videoUrl: 'https://icedrive.net/s/NFvRu9597CVYx9uigNG2jTufyhwA'
          }
        ],
        refreshDateTime: moment()
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  render() {
    let {
      imagesList,
      currentImage,
      reportName,
      excelDownload,
      refreshDateTime,
      errorInAPI,
      noDataInDB
    } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag color="blue" style={{ float: '', position: 'relative', zIndex: '2' }}>
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}>
            <div style={{ width: '40%', margin: '5px 10px' }}>
              {imagesList &&
                Array.isArray(imagesList) &&
                imagesList.length > 0 &&
                imagesList.map((src, index) => (
                  <img
                    src={src.imageUrl}
                    onClick={() => {
                      this.openImageViewer(src.imageUrl, src.videoUrl);
                    }}
                    width="50%"
                    height="150"
                    key={index}
                    style={{ margin: '5px 0px', padding: '2px' }}
                    alt=""
                  />
                ))}
            </div>

            <div style={{ marginTop: '10px', marginLeft: '30px' }}>
              <ReactVideoPlayer
                width="810px"
                height="350px"
                url="https://icedrive.net/s/NFvRu9597CVYx9uigNG2jTufyhwA"
                type="video/mp4"
                poster={currentImage}
              />
            </div>
          </div>
        )}

        {reportName && excelDownload === 'true' ? (
          <a
            href={`${excelDownloadUrl}/${reportName}`}
            style={{ fontSize: '20px', fontWeight: 'bold' }}
          >
            <DownOutlined
              style={{
                fontSize: '30px',
                fontWeight: 'bold',
                marginRight: '25px',
                marginTop: '6px'
              }}
            />
          </a>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(VideoComponent);
