import styled from 'styled-components';
import { Button, TreeSelect, Badge } from 'antd';

export const StyledApplication = styled.div`
  background: white;
  margin: 20px;
  padding: 30px;
  min-height: 750px;
`;
export const StyledAddButton = styled(Button)`
  margin: 24px;
`;
export const DrawerFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
`;
export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
`;
export const BadgeHeader = styled(Badge)`
  background-color: #fff;
  color: #999;
  margin-right: 1em;
`;
export const StyledTreeSelect = styled(TreeSelect)`
  width: 42em;
  margin-left: 680px;
`;
