/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from 'react';
import { Form, Select, Popconfirm, Tooltip, Collapse } from 'antd';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import {
  getToolsList,
  getLaborList,
  getMaterialsList,
  checkTask,
  getItemsList,
  getSkillTypeList
} from '../../../Utils/FormCalls';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { StyledButton, StyledCollapse } from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import { constant } from '../../../Utils/constants';
import translation from '../../../Utils/translation';
import InputComponent from '../../../Widgets/InputComponent';
import InputNumberComponent from '../../../Widgets/InputNumberComponent';
const { Option } = Select;
const { Panel } = Collapse;

//@ATT:this was created to make nested dynamic elements! This is hard!

const ToolsFormFunction = (props) => {
  const [toolsList, setTool] = useState([]);
  const [translationCache, settranslationCache] = useState(props.translationCache || {});
  useEffect(() => {
    (async () => {
      try {
        let response = await getToolsList();
        setTool(response.data);
      } catch (e) {
        return {};
      }
    })();
  }, []);
  return (
    <>
      <Form.List name={'Tools'}>
        {(Tools, { add, remove }) => {
          return (
            <Fragment>
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <StyledButton
                    theme={themeSettings}
                    type="dashed"
                    style={{ background: '#29415a', color: '#fff' }}
                    onClick={() => {
                      add();
                    }}
                  >
                    {translation(translationCache, 'Add Tools')}
                  </StyledButton>
                </div>
              </Form.Item>
              {Tools && Array.isArray(Tools) && Tools.length > 0 ? (
                <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                  {Tools.map((tool, index2) => (
                    <Panel
                      key={index2}
                      header={
                        <div style={{ fontWeight: 'bold' }}>
                          {`${translation(translationCache, constant.tools)} ${index2 + 1}`}
                        </div>
                      }
                      extra={
                        <Popconfirm
                          title={translation(translationCache, constant.deleteTool)}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={() => remove(tool.name)}
                          okText={translation(translationCache, constant.yes)}
                          cancelText={translation(translationCache, constant.no)}
                        >
                          {' '}
                          <Tooltip
                            title={translation(translationCache, 'Delete')}
                            placement="bottom"
                          >
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>
                      }
                    >
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          label={translation(translationCache, constant.tool)}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          {...tool}
                          name={[tool.name, 'ToolId']}
                          fieldKey={[tool.fieldKey, 'ToolId']}
                          key={index2}
                          rules={[
                            {
                              required: true,
                              message: 'Tool is Required'
                            }
                          ]}
                        >
                          <Select
                            style={{ width: '90%' }}
                            onChange={(value) =>
                              props.onToolChange(
                                value,
                                index2,
                                'Tools',
                                toolsList.filter((tool) => tool.Id === value)
                              )
                            }
                            placeholder={translation(translationCache, 'Select Tool')}
                          >
                            {toolsList.map((tool, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={tool.Id}
                                  disabled={
                                    props.formHeader &&
                                    props.formHeader.current &&
                                    props.formHeader.current.getFieldValue() &&
                                    props.formHeader.current.getFieldValue().Tools &&
                                    props.formHeader.current
                                      .getFieldValue()
                                      .Tools.filter((item) => item && item.ToolId === tool.Id)
                                      .length > 0
                                      ? true
                                      : false
                                  }
                                >
                                  {' '}
                                  {tool.Tool}{' '}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={translation(translationCache, constant.description)}
                          {...tool}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          name={[tool.name, 'Description']}
                          fieldKey={[tool.fieldKey, 'Description']}
                          key={index2}
                        >
                          <InputComponent
                            //maxLength={20}
                            placeholder={translation(translationCache, 'Auto Populate from Tools')}
                            style={{ width: '90%' }}
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          name={[tool.name, 'AvailableQuantity']}
                          // label={translation(translationCache, constant.availableQuantity)}
                          // disabled={true}
                          // {...tool}
                          // style={{ flex: '50%', display: 'none' }}
                          // labelCol={{ flex: '130px' }}
                          // name={[tool.name, 'AvailableQuantity']}
                          // fieldKey={[tool.fieldKey, 'AvailableQuantity']}
                          // key={index2}
                        >
                          {/* <InputComponent
                            disabled={true}
                            placeholder={translation(translationCache,"Provide Total Quantity")}
                            style={{ width: '90%' }}
                            //maxLength={10}
                          /> */}
                        </Form.Item>
                        <Form.Item
                          label={translation(translationCache, constant.Quantity)}
                          validateTrigger={['onChange', 'onBlur']}
                          {...tool}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          name={[tool.name, 'Quantity']}
                          fieldKey={[tool.fieldKey, 'Quantity']}
                          key={index2}
                          rules={[
                            // {
                            //   // pattern: new RegExp(/^[0-9]*$/),
                            //   message: 'field accept only numbers'
                            // },
                            {
                              validator: (_, value) =>
                                checkTask(_, value, 'Tools', index2, props.formObject, {
                                  validateDecimal: true
                                })
                            }
                          ]}
                        >
                          <InputNumberComponent
                            // min={0}
                            // max={1000}
                            disabled={
                              props &&
                              props.formObject &&
                              props.formObject.Tools &&
                              props.formObject.Tools[index2] === undefined
                                ? true
                                : false
                            }
                            placeholder={translation(translationCache, 'Input Quantity')}
                            style={{ width: '90%' }}
                            addonAfter={
                              <Tooltip
                                style={{ marginRight: '20px', flex: '50%' }}
                                labelCol={{ flex: '130px' }}
                                title={translation(
                                  translationCache,
                                  'AvailableQuantity  -  ' +
                                    (props &&
                                    props.formObject &&
                                    props.formObject.Tools &&
                                    props.formObject.Tools[index2] &&
                                    props.formObject.Tools[index2].AvailableQuantity
                                      ? props.formObject.Tools[index2].AvailableQuantity
                                      : 'Not Available')
                                )}
                              >
                                <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                              </Tooltip>
                            }
                            //maxLength={10}
                          />
                        </Form.Item>
                        <Form.Item style={{ flex: '50%' }} labelCol={{ flex: '130px' }} {...tool}>
                          {/* <Tooltip
                            style={{ marginRight: '20px', flex: '50%' }}
                            labelCol={{ flex: '130px' }}
                            title={translation(
                              translationCache,
                              'AvailableQuantity  -  ' +
                                (props &&
                                props.formObject &&
                                props.formObject.Tools &&
                                props.formObject.Tools[index2] &&
                                props.formObject.Tools[index2].AvailableQuantity
                                  ? props.formObject.Tools[index2].AvailableQuantity
                                  :'Not Available')
                            )}
                          >
                            <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                          </Tooltip> */}
                        </Form.Item>
                      </div>
                    </Panel>
                  ))}
                </StyledCollapse>
              ) : null}
            </Fragment>
          );
        }}
      </Form.List>
    </>
  );
};
const LaborFormFunction = (props) => {
  const [laborsList, setLabor] = useState([]);
  const [SkilTypeList, setSkill] = useState([]);
  const [translationCache, settranslationCache] = useState(props.translationCache || {});
  useEffect(() => {
    (async () => {
      try {
        let response = await getLaborList();
        setLabor(response.data);
      } catch (e) {
        return {};
      }
      try {
        let SkilTypeList = await getSkillTypeList();
        setSkill(SkilTypeList);
      } catch (e) {
        return {};
      }
    })();
  }, []);
  return (
    <>
      <Form.List name={'Labor'}>
        {(Labor, { add, remove }) => {
          return (
            <Fragment>
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <StyledButton
                    theme={themeSettings}
                    type="dashed"
                    style={{ background: '#29415a', color: '#fff' }}
                    onClick={() => {
                      add();
                    }}
                  >
                    {translation(translationCache, constant.addLabor)}
                  </StyledButton>
                </div>
              </Form.Item>
              {Labor && Array.isArray(Labor) && Labor.length > 0 ? (
                <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                  {Labor.map((labor, index2) => (
                    <Panel
                      key={index2}
                      header={
                        <div style={{ fontWeight: 'bold' }}>
                          {`${translation(translationCache, constant.labor)} ${index2 + 1}`}
                        </div>
                      }
                      extra={
                        <Popconfirm
                          title={translation(translationCache, constant.deleteLabor)}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={() => remove(labor.name)}
                          okText={translation(translationCache, constant.yes)}
                          cancelText={translation(translationCache, constant.no)}
                        >
                          <Tooltip
                            title={translation(translationCache, 'Delete')}
                            placement="bottom"
                          >
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>
                      }
                    >
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          label={translation(translationCache, constant.labor)}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          {...labor}
                          name={[labor.name, 'LaborId']}
                          fieldKey={[labor.fieldKey, 'LaborId']}
                          key={index2}
                          rules={[
                            {
                              required: true,
                              message: 'ManPower is Required'
                            }
                          ]}
                        >
                          <Select
                            style={{ width: '90%' }}
                            onChange={(value) =>
                              props.onLaborChange(
                                value,
                                index2,
                                'Labor',
                                laborsList.filter((Item) => Item.Id === value)
                              )
                            }
                            placeholder={translation(translationCache, 'Select Manpower')}
                          >
                            {laborsList.map((laborItem, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={laborItem.Id}
                                  disabled={
                                    props.formHeader &&
                                    props.formHeader.current &&
                                    props.formHeader.current.getFieldValue() &&
                                    props.formHeader.current.getFieldValue().Labor &&
                                    props.formHeader.current
                                      .getFieldValue()
                                      .Labor.filter((item) => item && item.LaborId === laborItem.Id)
                                      .length > 0
                                      ? true
                                      : false
                                    // props &&
                                    // props.formObject &&
                                    // props.formObject.Labor &&
                                    // props.formObject.Labor.filter(
                                    //   (item) => item && item.LaborId === laborItem.Id
                                    // ).length > 0
                                    //   ? true
                                    //   : false
                                  }
                                >
                                  {' '}
                                  {laborItem.FirstName}{' '}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          // label={translation(translationCache, constant.availableQuantity)}
                          // disabled={true}
                          // {...labor}
                          // style={{ flex: '50%' }}
                          // labelCol={{ flex: '130px' }}
                          name={[labor.name, 'AvailableQuantity']}
                          // fieldKey={[labor.fieldKey, 'AvailableQuantity']}
                          // key={index2}
                        >
                          {/* <InputComponent
                            disabled={true}
                            placeholder={translation(translationCache,"Provide Total Quantity"
                            style={{ width: '90%' }}
                            //maxLength={20}
                          /> */}
                        </Form.Item>
                        <Form.Item
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          label={translation(translationCache, constant.Skill)}
                          {...labor}
                          name={[labor.name, 'SkillTypeName']}
                          fieldKey={[labor.fieldKey, 'SkillTypeName']}
                          key={index2}
                        >
                          {/* <Select
                            style={{ width: '90%' }}
                            placeholder={translation(translationCache, 'Select Skill')}
                          >
                            {SkilTypeList &&
                              Array.isArray(SkilTypeList) &&
                              SkilTypeList.map((skill, index) => {
                                return (
                                  <Option key={index} value={skill.Id}>
                                    {' '}
                                    {skill.Type}{' '}
                                  </Option>
                                );
                              })}
                          </Select> */}
                          <InputComponent
                            placeholder={translation(
                              translationCache,
                              'AutoPopulate from Manpower'
                            )}
                            style={{ width: '90%' }}
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          label={translation(translationCache, constant.Quantity)}
                          validateTrigger={['onChange', 'onBlur']}
                          {...labor}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          name={[labor.name, 'Quantity']}
                          fieldKey={[labor.fieldKey, 'Quantity']}
                          key={index2}
                          rules={[
                            // {
                            //   // pattern: new RegExp(/^[0-9]*$/),
                            //   message: 'field accept only numbers'
                            // },
                            {
                              validator: (_, value) =>
                                checkTask(_, value, 'Labor', index2, props.formObject, {
                                  validateDecimal: true
                                })
                            }
                          ]}
                        >
                          <InputNumberComponent
                            // min={0}
                            // max={1000}
                            disabled={
                              props &&
                              props.formObject &&
                              props.formObject.Labor &&
                              props.formObject.Labor[index2] === undefined
                                ? true
                                : false
                            }
                            placeholder={translation(translationCache, 'Input Quantity')}
                            style={{ width: '90%' }}
                            addonAfter={
                              <Tooltip
                                style={{ flex: '50%' }}
                                labelCol={{ flex: '130px' }}
                                title={translation(
                                  translationCache,
                                  'AvailableQuantity  -  ' +
                                    (props &&
                                    props.formObject &&
                                    props.formObject.Labor &&
                                    props.formObject.Labor[index2] &&
                                    props.formObject.Labor[index2].AvailableQuantity
                                      ? props.formObject.Labor[index2].AvailableQuantity
                                      : 'Not Available')
                                )}
                              >
                                <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                              </Tooltip>
                            }
                            //maxLength={10}
                          />
                        </Form.Item>
                        <Form.Item style={{ flex: '50%' }} labelCol={{ flex: '130px' }} {...labor}>
                          {/* <Tooltip
                            style={{ flex: '50%' }}
                            labelCol={{ flex: '130px' }}
                            title={translation(
                              translationCache,
                              'AvailableQuantity  -  ' +
                                (props &&
                                props.formObject &&
                                props.formObject.Labor &&
                                props.formObject.Labor[index2] &&
                                props.formObject.Labor[index2].AvailableQuantity
                                  ? props.formObject.Labor[index2].AvailableQuantity
                                  :'Not Available')
                            )}
                          >
                            <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                          </Tooltip> */}
                        </Form.Item>
                      </div>
                    </Panel>
                  ))}
                </StyledCollapse>
              ) : null}
            </Fragment>
          );
        }}
      </Form.List>
    </>
  );
};
const MaterialFormFunction = (props) => {
  const [materialsList, setTool] = useState([]);
  const [translationCache, settranslationCache] = useState(props.translationCache || {});
  useEffect(() => {
    (async () => {
      try {
        let response = await getMaterialsList();
        setTool(response.data);
      } catch (e) {
        return {};
      }
    })();
  }, []);

  return (
    <>
      <Form.List name={'Materials'}>
        {(Material, { add, remove }) => {
          return (
            <Fragment>
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <StyledButton
                    theme={themeSettings}
                    type="dashed"
                    style={{ background: '#29415a', color: '#fff' }}
                    onClick={() => {
                      add();
                    }}
                  >
                    {translation(translationCache, constant.addMaterial)}
                  </StyledButton>
                </div>
              </Form.Item>
              {Material && Array.isArray(Material) && Material.length > 0 ? (
                <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                  {Material.map((material, index2) => (
                    <Panel
                      key={index2}
                      header={
                        <div style={{ fontWeight: 'bold' }}>
                          {`${translation(translationCache, constant.material)} ${index2 + 1}`}
                        </div>
                      }
                      extra={
                        <Popconfirm
                          title={translation(translationCache, constant.deleteMaterial)}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={() => remove(material.name)}
                          okText={translation(translationCache, constant.yes)}
                          cancelText={translation(translationCache, constant.no)}
                        >
                          <Tooltip
                            title={translation(translationCache, 'Delete')}
                            placement="bottom"
                          >
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>
                      }
                    >
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          label={translation(translationCache, constant.material)}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          {...material}
                          name={[material.name, 'MaterialId']}
                          fieldKey={[material.fieldKey, 'MaterialId']}
                          key={index2}
                          rules={[
                            {
                              required: true,
                              message: 'Material is Required'
                            }
                          ]}
                        >
                          <Select
                            style={{ width: '90%' }}
                            onChange={(value) =>
                              props.onMaterialChange(
                                value,
                                index2,
                                'Materials',
                                materialsList.filter((Item) => Item.Id === value)
                              )
                            }
                            placeholder={translation(translationCache, 'Select Material')}
                          >
                            {materialsList.map((material, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={material.Id}
                                  disabled={
                                    props.formHeader &&
                                    props.formHeader.current &&
                                    props.formHeader.current.getFieldValue() &&
                                    props.formHeader.current.getFieldValue().Materials &&
                                    props.formHeader.current
                                      .getFieldValue()
                                      .Materials.filter(
                                        (item) => item && item.MaterialId === material.Id
                                      ).length > 0
                                      ? true
                                      : false
                                  }
                                >
                                  {material.Material}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={translation(translationCache, 'Description')}
                          {...material}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          name={[material.name, 'Description']}
                          fieldKey={[material.fieldKey, 'Description']}
                          key={index2}
                        >
                          <InputComponent
                            //maxLength={20}
                            placeholder={translation(
                              translationCache,
                              'AutoPopulate from Material'
                            )}
                            style={{ width: '90%' }}
                            disabled
                          />
                        </Form.Item>

                        <Form.Item
                          // label={translation(translationCache, constant.availableQuantity)}
                          // disabled={true}
                          // {...material}
                          // style={{ flex: '50%' }}
                          // labelCol={{ flex: '130px' }}
                          name={[material.name, 'AvailableQuantity']}
                          // fieldKey={[material.fieldKey, 'AvailableQuantity']}
                          // key={index2}
                        >
                          {/* <InputComponent
                            disabled={true}
                            placeholder={translation(translationCache,"Provide Total Quantity")}
                            style={{ width: '90%' }}
                            //maxLength={10}
                          /> */}
                        </Form.Item>
                        <Form.Item
                          label={translation(translationCache, constant.Quantity)}
                          validateTrigger={['onChange', 'onBlur']}
                          {...material}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          name={[material.name, 'Quantity']}
                          fieldKey={[material.fieldKey, 'Quantity']}
                          key={index2}
                          rules={[
                            // {
                            //   // pattern: new RegExp(/^[0-9]*$/),
                            //   message: 'field accept only numbers'
                            // },
                            {
                              validator: (_, value) =>
                                checkTask(_, value, 'Materials', index2, props.formObject, {
                                  validateDecimal: true
                                })
                            }
                          ]}
                        >
                          <InputNumberComponent
                            // min={0}
                            // max={1000}
                            disabled={
                              props &&
                              props.formObject &&
                              props.formObject.Materials &&
                              props.formObject.Materials[index2] === undefined
                                ? true
                                : false
                            }
                            placeholder={translation(translationCache, 'Input Quantity')}
                            style={{ width: '90%' }}
                            addonAfter={
                              <Tooltip
                                style={{ flex: '50%' }}
                                labelCol={{ flex: '130px' }}
                                title={translation(
                                  translationCache,
                                  'AvailableQuantity  -  ' +
                                    (props &&
                                    props.formObject &&
                                    props.formObject.Materials &&
                                    props.formObject.Materials[index2] &&
                                    props.formObject.Materials[index2].AvailableQuantity
                                      ? props.formObject.Materials[index2].AvailableQuantity
                                      : 'Not Available')
                                )}
                              >
                                <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                              </Tooltip>
                            }
                            //maxLength={10}
                          />
                        </Form.Item>
                        <Form.Item
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          {...material}
                        >
                          {/* <Tooltip
                            style={{ flex: '50%' }}
                            labelCol={{ flex: '130px' }}
                            title={translation(
                              translationCache,
                              'AvailableQuantity  -  ' +
                              (props &&
                                props.formObject &&
                                props.formObject.Materials &&
                                props.formObject.Materials[index2] &&
                                props.formObject.Materials[index2].AvailableQuantity
                                ? props.formObject.Materials[index2].AvailableQuantity
                                : 'Not Available')
                            )}
                          >
                            <QuestionCircleOutlined style={{ fontSize: '25px'}} />
                          </Tooltip> */}
                        </Form.Item>
                      </div>
                    </Panel>
                  ))}
                </StyledCollapse>
              ) : null}
            </Fragment>
          );
        }}
      </Form.List>
    </>
  );
};
const ItemFormFunction = (props) => {
  const [itemsList, setItem] = useState([]);
  const [translationCache, settranslationCache] = useState(props.translationCache || {});
  useEffect(() => {
    (async () => {
      try {
        let response = await getItemsList();
        setItem(response.data);
      } catch (e) {
        return {};
      }
    })();
  }, []);
  return (
    <>
      <Form.List name={'Items'}>
        {(Items, { add, remove }) => {
          return (
            <Fragment>
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <StyledButton
                    theme={themeSettings}
                    type="dashed"
                    style={{ background: '#29415a', color: '#fff' }}
                    onClick={() => {
                      add();
                    }}
                  >
                    {translation(translationCache, constant.addItems)}
                  </StyledButton>
                </div>
              </Form.Item>
              {Items && Array.isArray(Items) && Items.length > 0 ? (
                <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                  {Items.map((item, index2) => (
                    <Panel
                      key={index2}
                      header={
                        <div style={{ fontWeight: 'bold' }}>
                          {`${translation(translationCache, constant.item)} ${index2 + 1}`}
                        </div>
                      }
                      extra={
                        <Popconfirm
                          title={translation(translationCache, constant.deleteItem)}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={() => remove(item.name)}
                          okText={translation(translationCache, constant.yes)}
                          cancelText={translation(translationCache, constant.yes)}
                        >
                          <Tooltip
                            title={translation(translationCache, 'Delete')}
                            placement="bottom"
                          >
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>
                      }
                    >
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          label={translation(translationCache, constant.item)}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          {...item}
                          name={[item.name, 'ItemId']}
                          fieldKey={[item.fieldKey, 'ItemId']}
                          key={index2}
                          rules={[
                            {
                              required: true,
                              message: 'Item is Required'
                            }
                          ]}
                        >
                          <Select
                            style={{ width: '90%' }}
                            onChange={(value) =>
                              props.onItemChange(
                                value,
                                index2,
                                'Items',
                                itemsList.filter((tool) => tool.Id === value)
                              )
                            }
                            placeholder={translation(translationCache, 'Select Item')}
                          >
                            {itemsList.map((item, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={item.Id}
                                  disabled={
                                    props.formHeader &&
                                    props.formHeader.current &&
                                    props.formHeader.current.getFieldValue() &&
                                    props.formHeader.current.getFieldValue().Items &&
                                    props.formHeader.current
                                      .getFieldValue()
                                      .Items.filter((text) => text && text.ItemId === item.Id)
                                      .length > 0
                                      ? true
                                      : false
                                  }
                                >
                                  {item.Item}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={translation(translationCache, 'Description')}
                          {...item}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          name={[item.name, 'Description']}
                          fieldKey={[item.fieldKey, 'Description']}
                          key={index2}
                        >
                          <InputComponent
                            placeholder={translation(translationCache, 'AutoPopulate from Item')}
                            style={{ width: '90%' }}
                            //maxLength={20}
                            disabled
                          />
                        </Form.Item>

                        <Form.Item
                          // label={translation(translationCache, constant.availableQuantity)}
                          // disabled={true}
                          // {...item}
                          // style={{ flex: '50%' }}
                          // labelCol={{ flex: '130px' }}
                          name={[item.name, 'AvailableQuantity']}
                          // fieldKey={[item.fieldKey, 'AvailableQuantity']}
                          // key={index2}
                        >
                          {/* <InputComponent
                            //maxLength={10}
                            disabled={true}
                            placeholder={translation(translationCache,"Provide Total Quantity"
                            style={{ width: '90%' }}
                          /> */}
                        </Form.Item>
                        <Form.Item
                          label={translation(translationCache, constant.Quantity)}
                          validateTrigger={['onChange', 'onBlur']}
                          {...item}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          name={[item.name, 'Quantity']}
                          fieldKey={[item.fieldKey, 'Quantity']}
                          key={index2}
                          rules={[
                            // {
                            //   // pattern: new RegExp(/^[0-9]*$/),
                            //   message: 'field accept only numbers'
                            // },
                            {
                              validator: (_, value) =>
                                checkTask(_, value, 'Items', index2, props.formObject, {
                                  validateDecimal: true
                                })
                            }
                          ]}
                        >
                          <InputNumberComponent
                            // min={0}
                            // max={1000}
                            disabled={
                              props &&
                              props.formObject &&
                              props.formObject.Items &&
                              props.formObject.Items[index2] === undefined
                                ? true
                                : false
                            }
                            placeholder={translation(translationCache, constant.inputQuantity)}
                            style={{ width: '90%' }}
                            addonAfter={
                              <Tooltip
                                style={{ marginRight: '20px', flex: '50%' }}
                                labelCol={{ flex: '130px' }}
                                title={translation(
                                  translationCache,
                                  'AvailableQuantity  -  ' +
                                    (props &&
                                    props.formObject &&
                                    props.formObject.Items &&
                                    props.formObject.Items[index2] &&
                                    props.formObject.Items[index2].AvailableQuantity
                                      ? props.formObject.Items[index2].AvailableQuantity
                                      : 'Not Available')
                                )}
                              >
                                <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                              </Tooltip>
                            }
                            //maxLength={10}
                          />
                        </Form.Item>
                        <Form.Item style={{ flex: '50%' }} labelCol={{ flex: '130px' }} {...item}>
                          {/* <Tooltip
                            style={{ marginRight: '20px', flex: '50%' }}
                            labelCol={{ flex: '130px' }}
                            title={translation(
                              translationCache,
                              'AvailableQuantity  -  ' +
                              (props &&
                                props.formObject &&
                                props.formObject.Items &&
                                props.formObject.Items[index2] &&
                                props.formObject.Items[index2].AvailableQuantity
                                ? props.formObject.Items[index2].AvailableQuantity
                                : 'Not Available')
                            )}
                          >
                            <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                          </Tooltip> */}
                        </Form.Item>
                      </div>
                    </Panel>
                  ))}
                </StyledCollapse>
              ) : null}
            </Fragment>
          );
        }}
      </Form.List>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

const ToolsForm = connect(mapStateToProps)(ToolsFormFunction);
const LaborForm = connect(mapStateToProps)(LaborFormFunction);
const MaterialForm = connect(mapStateToProps)(MaterialFormFunction);
const ItemForm = connect(mapStateToProps)(ItemFormFunction);

export { ToolsForm, LaborForm, MaterialForm, ItemForm };
