import React from 'react';
import { Card, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import history from '../../../../../commons/history';
import './landingpage.css';
import localStorage from '../../../../../utils/localStorage';
import { getCurrentLanguage, getTranslationCache } from '../../../../../selectors/language';
import { makeSelectThemeing, makeSelectTheme } from '../../../../../selectors/theme';
import { getUserProfile } from '../../../../../selectors/layout';
import { setMenuSelection } from '../../../../../modules/menu/menu.duck';
import EMS from './images/EMS.png';
import SLS from './images/Smart Lighting.png';
import BM from './images/Berth Monitoring.png';
import GATE from './images/Gate.png';
import WH from './images/E-House Monitoring.png';
import Marine from './images/Marine.png';
import EOC from './images/EOC.png';
import CBM from './images/conveyor1.png';
import SL from './images/Shiploader.png';
import QC from './images/QC.png';
import RTG from './images/RTG.png';
import WINDTURBINE from './images/Windturbine.png';
class landingPageDefault extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfile: props.userProfile || {}
    };
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.setState({
        userProfile: this.props.userProfile
      });
    }
  };
  onClick = (craneObject, moduleName) => {
    if (moduleName && moduleName === 'Energy Management System') {
      history.push(`${craneObject}`);
      let EMS = ['Modules', '1172', '1174'];
      this.props.actions.setMenuSelection(EMS);
    } else if (moduleName && moduleName === 'Gate Management System') {
      let GMS = ['Modules', '1156', '1158'];
      this.props.actions.setMenuSelection(GMS);
      history.push(`${craneObject}`);
    } else if (moduleName && moduleName === 'Smart Lightning System') {
      let SLS = ['Modules', '1198', '1200'];
      this.props.actions.setMenuSelection(SLS);
      history.push(`${craneObject}`);
    } else if (moduleName && moduleName === 'Marine Operations') {
      let MO = ['Modules', '182', '184'];
      this.props.actions.setMenuSelection(MO);
      history.push(`${craneObject}`);
    } else if (moduleName && moduleName === 'Mobile Harbour Cranes') {
      let MHC = ['Modules', '1218', '1220'];
      this.props.actions.setMenuSelection(MHC);
      history.push(`${craneObject}`);
    } else if (moduleName && moduleName === 'Rule Engine') {
      let RE = ['Modules', '11173'];
      this.props.actions.setMenuSelection(RE);
      history.push(`${craneObject}`);
    } else if (moduleName && moduleName === 'Berth Monitoring System') {
      let BMS = ['Modules', '198', '1100'];
      this.props.actions.setMenuSelection(BMS);
      history.push(`${craneObject}`);
    } else if (moduleName && moduleName === 'Conveyor Belt Monitoring') {
      let CBM = ['Modules', '190', '192'];
      this.props.actions.setMenuSelection(CBM);
      history.push(`${craneObject}`);
    } else if (moduleName && moduleName === 'Shiploader') {
      let SL = ['Modules', '176', '1188'];
      this.props.actions.setMenuSelection(SL);
      history.push(`${craneObject}`);
    } else if (moduleName && moduleName === 'QC Monitoring') {
      let QM = ['Modules', '1136', '1138'];
      this.props.actions.setMenuSelection(QM);
      history.push(`${craneObject}`);
    } else if (moduleName && moduleName === 'RTG Cranes Monitoring') {
      let RTG = ['Modules', '1144', '1146'];
      this.props.actions.setMenuSelection(RTG);
      history.push(`${craneObject}`);
    } else if (moduleName && moduleName === 'Wind Turbine') {
      let WT = ['Modules', '1246', '1250'];
      this.props.actions.setMenuSelection(WT);
      history.push(`${craneObject}`);
    } else if (moduleName && moduleName === 'AMM') {
      let AMM = ['Modules', '11219', '11221'];
      this.props.actions.setMenuSelection(AMM);
      history.push(`${craneObject}`);
    } else {
      history.push(`${craneObject}`);
    }
  };
  render() {
    let site = localStorage.get('currentSite');
    let menu =
      this.state.userProfile &&
      this.state.userProfile !== undefined &&
      this.state.userProfile.belongsTo &&
      this.state.userProfile.belongsTo.sites &&
      this.state.userProfile.belongsTo.sites[site] &&
      this.state.userProfile.belongsTo.sites[site].role.Modules.children;

    let shedString = (menuKey) => {
      switch (menuKey) {
        case 'Energy Management System':
          return EMS;
        case 'Gate Management System':
          return GATE;
        case 'Smart Lightning System':
          return SLS;
        case 'Equipment Monitoring':
          return WH;
        case 'Marine Operations':
          return Marine;
        case 'Mobile Harbour Cranes':
          return EOC;
        case 'Equipment Operations Center':
          return EOC;
        case 'Berth Monitoring System':
          return BM;
        case 'Conveyor Belt Monitoring':
          return CBM;
        case 'Shiploader':
          return SL;
        case 'QC Monitoring':
          return QC;
        case 'RTG Cranes Monitoring':
          return RTG;
        case 'Wind Turbine':
          return WINDTURBINE;
        default:
          return 'SLD';
      }
    };
    return (
      <div style={{ background: '#ECECEC', padding: '30px' }}>
        <Row gutter={16}>
          {menu &&
            Object.keys(menu) &&
            Object.keys(menu).map((menuKey) => {
              let imageUrl = shedString(menu[menuKey].name);
              return (
                <>
                  {menu[menuKey].name !== 'Equipment Overview' ? (
                    <Col span={5} className="columnStlye">
                      <Card
                        className="cardDesign"
                        title={menu[menuKey].name}
                        bordered={false}
                        onClick={() => this.onClick(menu[menuKey].path, menu[menuKey].name)}
                        style={{ borderRadius: '35px' }}
                      >
                        <img alt="Logo" src={imageUrl} width="100%" height="250px" />
                      </Card>
                    </Col>
                  ) : null}
                </>
              );
            })}
        </Row>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  selectedTheme: makeSelectTheme(),
  userProfile: getUserProfile()
});
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setMenuSelection
      },
      dispatch
    )
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(landingPageDefault);
