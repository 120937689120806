import axios from 'axios';
import { message } from 'antd';

import localStorage from '../../utils/localStorage';
import {
  API_ASSET,
  API_DEVICE,
  API_HIERARCHYMODULE,
  API_MODULES,
  API_RUBUSMASTERS
} from '../../commons/api';

export const getAssetDetailsById = (AssetCode) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_ASSET.CLIENT_GET}?SiteId=${siteId}&AliasCode=${AssetCode}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getDeviceDetailsById = (DeviceAliasCode, Name) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_DEVICE.GETDevices}?SiteId=${siteId}&${Name}=${DeviceAliasCode}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const GetDefaultHierarchy = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_HIERARCHYMODULE.GetDefaultHierarchy}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const saveAsset = async (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: data && data.Id ? 'PATCH' : 'POST',
    url: `${data && data.Id ? API_ASSET.CLIENT_UPDATE : API_ASSET.CLIENT_CREATE}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      ...data
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.error(err.response.data.message);
      }
    });
};

export const saveSubDevice = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: data && data.Id ? 'PATCH' : 'POST',
    url: `${data && data.Id ? API_DEVICE.UpdateDevice : API_DEVICE.CreateDevice}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.error(err.response.data.message);
      }
    });
};
export const saveDevice = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: data && data.Id ? 'PATCH' : 'POST',
    url: `${data && data.Id ? API_DEVICE.UpdateDevice : API_DEVICE.CreateDevice}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.error(err.response.data.message);
      }
    });
};

export const getSubDeviceListByDeviceType = (DeviceType) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_RUBUSMASTERS.GET}?SiteId=${siteId}&DeviceType=${DeviceType}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getSubEquipmentData = (EquipmentCode, SubDeviceType) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_DEVICE.GETDevices}?SiteId=${siteId}&ParentEquipment=${EquipmentCode}&SubDeviceType=${SubDeviceType}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getPriorityList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'Get',
    url: `/api/masterconfiguration/getPriority?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'master."Master_Priorities"',
      ColumnName: 'Name',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getDeviceTypeList = (ModuleCode) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_RUBUSMASTERS.DT_GET}?SiteId=${siteId}&Status=Active&ModuleCode=${ModuleCode}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getAttributeList = (DeviceType) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/attributes/get?SiteId=${siteId}&DeviceType=${DeviceType}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getAssetAttributeList = (Type, ModuleCode) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/assetAttributes/get?SiteId=${siteId}&Type=${Type}&ModuleCode=${ModuleCode}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getParameterData = (DeviceType, DeviceAlias) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/client/parameter/get?SiteId=${siteId}&DeviceType=${DeviceType}&DeviceAlias=${DeviceAlias}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getFileDownloadName = (DeviceType, DeviceAlias) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `api/download/parameters?SiteId=${siteId}&DeviceType=${DeviceType}&DeviceAlias=${DeviceAlias}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getAssetFileDownloadName = (ModuleCode, ParentAsset) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/client/download/assets?SiteId=${siteId}&ModuleCode=${ModuleCode}&ParentAsset=${ParentAsset}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getCarandDeviceFileDownloadName = (ModuleCode, ParentAsset) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/client/download/assetBulk?SiteId=${siteId}&AssetType=asset&ModuleCode=${ModuleCode}&ParentAsset=${ParentAsset}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getSubEquipmentListWithParent = (DeviceAlias) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/client/device/getSubDevicesList?SiteId=${siteId}&AliasCode=${DeviceAlias}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getDeviceFileDownloadName = (deviceCode, AssetAlias) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/client/download/devices?SiteId=${siteId}&DeviceType=${deviceCode}&AssetAlias=${AssetAlias}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getSubEquipmentFileDownloadName = (deviceCode, assetcode, SubDeviceType) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/client/download/devices?SiteId=${siteId}&DeviceType=${assetcode}&DeviceAlias=${deviceCode}&SubDeviceType=${SubDeviceType}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getParameterFileDownloadNameAPI = (DeviceType, DeviceAlias) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/client/download/parameters?SiteId=${siteId}&DeviceType=${DeviceType}&DeviceAlias=${DeviceAlias}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getAssetParameterFileDownloadNameAPI = (AssetAlias) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/client/download/assetparameters?SiteId=${siteId}&AssetAlias=${AssetAlias}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getAttributeFileDownloadName = (deviceType) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/download/attributes?SiteId=${siteId}&DeviceTypeAlias=${deviceType}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getModulesFileDownloadName = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/download/modules?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.status && err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getUnCommonFileDownloadName = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/download/assetAttributes?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.status && err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getDeviceTypeFileDownloadName = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/download/deviceTypes?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.status && err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getAssetParameterFileDownloadName = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/download/assetParameters?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.status && err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getDevicesTypesFileDownloadName = (ModuleCode) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/download/deviceTypes?SiteId=${siteId}${
      ModuleCode && ModuleCode !== null ? `&ModuleCode=${ModuleCode}` : ''
    }${ModuleCode === null ? `&Type=${'NoModule'}` : ''}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getAllSubEquipmentFileDownloadName = (ModuleCode) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/download/devices?SiteId=${siteId}&ModuleCode=${ModuleCode}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getAllAttributeFileDownloadName = (ModuleCode) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/download/attributes?SiteId=${siteId}&ModuleCode=${ModuleCode}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getAllParameterFileDownloadName = (ModuleCode) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/download/parameters?SiteId=${siteId}&ModuleCode=${ModuleCode}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const getSubEquipmentsFileDownloadName = (deviceType) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/download/devices?SiteId=${siteId}&DeviceTypeAlias=${deviceType}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getParametersFileDownloadName = async (deviceType) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/download/parameters?SiteId=${siteId}&DeviceTypeAlias=${deviceType}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const saveParameter = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'PATCH',
    url: `/api/client/parameter/update?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.error(err.response.data.message);
      }
    });
};
export const UpdateMasters = (url, payload, Id) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'PATCH',
    url: `${url}?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
};
export async function deleteModules(id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${API_MODULES.ModulesDelete}?SiteId=${siteId}&Id=${id}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((err) => {
      message.error(err.response.data.message);
    });
}
export async function deleteMasters(url, id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${url}?SiteId=${siteId}&Id=${id}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((err) => {
      message.error(err.response.data.message);
    });
}
export async function CreateMasters(url, payload) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${url}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { ...payload, SiteId: siteId }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}
export const getAssetTypeListDataAPI = (ModuleCode) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/assetType/get?SiteId=${siteId}&ModuleCode=${ModuleCode}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getManufacturerListDataAPI = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/rubusMaster/manufacturers/get?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const insertMasterFromSelect = (Name) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `/api/rubusMaster/manufacturers/create?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      SiteId: siteId,
      Name
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

// export const getAssetAttributeList = () => {
//   const siteId = localStorage.get('currentSite');
//   const accessToken = localStorage.get('accessToken');
//   const Object = {
//     method: 'GET',
//     url: `/api/rubusMaster/manufacturers/get?SiteId=${siteId}`,
//     headers: {
//       Authorization: `Bearer ${accessToken}`
//     }
//   };
//   return axios(Object)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       if (err.response.status === 400) {
//         message.info(err.response.data.message);
//       }
//     });
// };
export const getAttributesList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/asset/assetAttributes/get?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getAttributesListByAsset = (Type) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/asset/assetAttributes/get?SiteId=${siteId}&AssetAlias=${Type}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export async function deleteAssetAttribute(url, id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${url}?SiteId=${siteId}&Id=${id}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((err) => {
      message.error(err.response.data.message);
    });
}

export async function CreateAssetAttribute(url, payload) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${url}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { ...payload, SiteId: siteId }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}
export const getAssetParametersList = (Type) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `/api/client/assetParameter/get?SiteId=${siteId}&AssetAlias=${Type}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const saveAssetParameter = (data, type) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'PATCH',
    url: `/api/client/assetParameter/update?SiteId=${siteId}&AssetAlias=${type}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.error(err.response.data.message);
      }
    });
};
