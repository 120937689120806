import React, { Component } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { Form, Input, InputNumber, message, DatePicker } from 'antd';

import SelectComponennt from '../Widgets/SelectComponent';
import { _getEquipmentList } from '../eLogCalls';
import TableView from '../Widgets/TableView';

const { RangePicker } = DatePicker;

class AdhocFormTableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: props.formObject || {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: '',
      equipmentName: '',
      BodyCount: '',
      batchArray: [],
      date: moment().format('YYYY-MM-DD'),
      equipmentList: []
    };
  }

  componentDidMount() {
    let { formObject } = this.props;
    let batchArray = [];
    let noOfBodyCount =
      formObject &&
      formObject['equipmentBatch'] &&
      formObject['equipmentBatch'].mainHeaderValue &&
      (formObject['equipmentBatch'].mainHeaderValue.BodyCount ||
        formObject['equipmentBatch'].mainHeaderValue.BodyCount)
        ? formObject['equipmentBatch'].mainHeaderValue.BodyCount
          ? formObject['equipmentBatch'].mainHeaderValue.BodyCount
          : formObject['equipmentBatch'].mainHeaderValue.BodyCount
        : 0;

    for (let i = 1; i <= noOfBodyCount; i++) {
      batchArray.push(i);
    }

    this.equipment();
    this.setState({
      formObject: this.props.formObject,
      columns:
        this.props.formObject && this.props.formObject.columns ? this.props.formObject.columns : [],
      data:
        this.props.formObject && this.props.formObject.datasource
          ? this.props.formObject.datasource
          : []
    });
  }

  equipment = async () => {
    let equipmentList = await _getEquipmentList();
    this.setState({
      equipmentList
    });
  };

  componentDidUpdate(prevProps) {
    let { formObject } = this.props;
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      let batchArray = [];
      let noOfBodyCount =
        formObject &&
        formObject['equipmentBatch'] &&
        formObject['equipmentBatch'].mainHeaderValue &&
        (formObject['equipmentBatch'].mainHeaderValue.BodyCount ||
          formObject['equipmentBatch'].mainHeaderValue.BodyCount ||
          formObject['equipmentBatch'].mainHeaderValue.TileSizeCount)
          ? formObject['equipmentBatch'].mainHeaderValue.BodyCount ||
            formObject['equipmentBatch'].mainHeaderValue.TileSizeCount
            ? formObject['equipmentBatch'].mainHeaderValue.TileSizeCount
              ? formObject['equipmentBatch'].mainHeaderValue.TileSizeCount
              : formObject['equipmentBatch'].mainHeaderValue.BodyCount
            : formObject['equipmentBatch'].mainHeaderValue.BodyCount
          : 0;

      for (let i = 1; i <= noOfBodyCount; i++) {
        batchArray.push(i);
      }

      this.setState({
        formObject: this.props.formObject,
        columns:
          this.props.formObject && this.props.formObject.columns
            ? this.props.formObject.columns
            : [],
        data:
          this.props.formObject && this.props.formObject.datasource
            ? this.props.formObject.datasource
            : [],
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog
      });
    }
  }

  _getMandatoryStatus = () => {
    let { formObject } = this.props;
    let requiredStatus = false;
    if (formObject && formObject['equipmentBatch'] && formObject['equipmentBatch'].mainHeader) {
      formObject['equipmentBatch'].mainHeader.map((formHeader) => {
        let val = formObject['equipmentBatch'].mainHeaderValue;
        if (formHeader && formHeader.key && formHeader.required === true) {
          if (val && val[formHeader.key]) {
            return {};
          } else {
            requiredStatus = true;
          }
        }
        return {};
      });
    }
    return requiredStatus;
  };

  addEquipment = async () => {
    let { formObject } = this.props;
    let mandatoryStatus = await this._getMandatoryStatus();
    if (!mandatoryStatus) {
      if (
        formObject &&
        formObject['equipmentBatch'] &&
        formObject['equipmentBatch'].mainHeaderValue
      ) {
        formObject = {
          ...formObject,
          [formObject['equipmentBatch'].mainHeaderValue.EquipmentName]: {
            columns: formObject['equipmentBatch'].columns,
            header: formObject['equipmentBatch'].header,
            mainHeaderValue: formObject['equipmentBatch'].mainHeaderValue
          }
        };

        this.props.downTimeData(formObject, false);
      }
    } else {
      message.error('Please select mandatory fields');
    }
  };

  inputNumberChange = (minValue, maxValue, value, key, name, batchKey) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      (maxValue !== undefined || value === '')
    ) {
      this.onInputChange(value, key, name, batchKey);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputChange(value, key, name, batchKey);
    } else {
      this.onInputChange('', key, name, batchKey);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  onInputChange = (columnValue, columnKey, name, batchKey) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...formObject[name].datasource,
          // [columnKey]: columnValue
          [batchKey]: {
            ...(formObject &&
            formObject[name] &&
            formObject[name].datasource &&
            formObject[name].datasource[batchKey]
              ? formObject[name].datasource[batchKey]
              : {}),
            [columnKey]: columnValue
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onChangeRemarks = (value, name, key) => {
    let { formObject } = this.state;
    if (
      formObject &&
      formObject[name] &&
      formObject[name].datasource &&
      formObject[name].datasource.cause
    ) {
      formObject[name].datasource.cause[key] = value;
    }
    this.props.downTimeData(formObject, false);
  };

  handleKeyPress = () => {};
  onMainOk = (date, columnKey) => {
    let { formObject } = this.state;
    let duration = moment.duration(date[1].diff(date[0]));
    let hours = duration.asHours();
    let roundHours = Math.round(hours * 100) / 100;
    let startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm');
    let endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm');
    if (date[0] !== null && date[1] !== null) {
      if (startDate2 === endDate2) {
        message.error('StartDateTime and EndDateTime should not be same');
        formObject = {
          ...formObject,
          datasource: {
            ...(formObject && formObject.datasource ? formObject.datasource : {}),
            [columnKey]: {},
            'Milling Hrs': ''
          }
        };
        this.props.downTimeData(formObject, false);
      } else if (startDate2 !== endDate2) {
        formObject = {
          ...formObject,
          form: {
            ...formObject['form'],
            datasource: {
              ...(formObject && formObject['form'] && formObject['form'].datasource
                ? formObject['form'].datasource
                : {}),
              [columnKey]: {
                StartDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                EndDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
              },
              'Milling Hrs': roundHours
            }
          }
        };
        this.props.downTimeData(formObject, false);
      }
    }
  };
  onChange = (date, columnKey) => {
    let { formObject } = this.state;
    let duration = moment.duration(date[1].diff(date[0]));
    let hours = duration.asHours();
    let roundHours = Math.round(hours * 100) / 100;
    formObject = {
      ...formObject,
      form: {
        ...formObject['form'],
        datasource: {
          ...(formObject && formObject['form'] && formObject['form'].datasource
            ? formObject['form'].datasource
            : {}),
          [columnKey]: {
            StartDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
            EndDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
          },
          'Milling Hrs': roundHours
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onDateHeaderMainChange = (date, columnKey) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      datasource: {
        ...(formObject && formObject['form'] && formObject['form'].datasource
          ? formObject['form'].datasource
          : {}),
        [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
      }
    };
    this.props.downTimeData(formObject, false);
  };

  inputHeaderMainNumberChange = (minValue, maxValue, value, key) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      (maxValue !== undefined || value === '')
    ) {
      this.onInputHeaderMainChange(value, key);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputHeaderMainChange(value, key);
    } else {
      this.onInputHeaderMainChange('', key);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  onInputHeaderMainChange = (columnValue, columnKey) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      datasource: {
        ...(formObject && formObject && formObject.datasource ? formObject.datasource : {}),
        [columnKey]: columnValue
      }
    };
    this.props.downTimeData(formObject, false);
  };

  renderMainSwitch(formObject, columns) {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject && formObject.datasource && formObject.datasource[columns.key]
                ? formObject.datasource[columns.key]
                : ''
            }
            disabled={columns.disable ? columns.disable : false}
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputHeaderMainChange(e.target.value, columns.key)}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject && formObject.datasource && formObject.datasource[columns.key]
                ? formObject.datasource[columns.key]
                : ''
            }
            // min={columns.min ? columns.min : 0}
            // max={columns.max ? columns.max : 1000000000}
            placeholder={columns.placeHolder}
            onBlur={(e) =>
              this.inputHeaderMainNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key
              )
            }
            onChange={(e) => this.onInputHeaderMainChange(e, columns.key)}

            // onChange={(e) => this.onInputHeaderMainChange(e, columns.key)}
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            value={
              formObject && formObject['datasource'] && formObject['datasource']['Date']
                ? moment(formObject['datasource']['Date'], 'YYYY-MM-DD HH:mm:ss')
                : undefined
            }
            showNow={false}
            disabledDate={this.disabledDate}
            onChange={(e) => this.onDateHeaderMainChange(e, columns.key)}
            style={{ width: 300, fontWeight: 'bold' }}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject && formObject.datasource && formObject.datasource[columns.key]
                ? formObject.datasource[columns.key]
                : ''
            }
            handleFieldChange={(e) => this.onInputHeaderMainChange(e, columns.key)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300, fontWeight: 'bold' }}
            // ranges={{
            //     Today: [moment(), moment()],
            //     'This Month': [moment().startOf('month'), moment().endOf('month')],
            // }}
            showTime
            allowClear={false}
            format="YYYY-MM-DD HH:mm"
            disabledTime={(e) => this.disabledRangeTime(e)}
            disabledDate={(e) =>
              this.disabledDateRange(
                {
                  ...(formObject && formObject ? formObject : {})
                },
                e
              )
            }
            value={
              formObject &&
              formObject.datasource &&
              formObject.datasource[columns.key] &&
              formObject.datasource[columns.key]['StartDate'] &&
              formObject.datasource[columns.key]['EndDate']
                ? [
                    moment(formObject.datasource[columns.key]['StartDate'], dateFormat),
                    moment(formObject.datasource[columns.key]['EndDate'], dateFormat)
                  ]
                : null
            }
            onOk={(e) => this.onMainOk(e, columns.key)}
            //  onChange={(e) => this.onChange(e, columns.key)}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject && formObject.datasource && formObject.datasource[columns.key]
                ? formObject.datasource[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputHeaderMainChange(e.target.value, columns.key)}
          />
        );
    }
  }

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };
  disabledDateRange = (formObject, current) => {
    if (
      formObject &&
      formObject['mainHeaderValue'] &&
      formObject['mainHeaderValue']['Date'] &&
      formObject['mainHeaderValue']['Shift'] === 'Morning Shift'
    ) {
      let selectedDate = moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).endOf('day') || current < moment(selectedDate).startOf('day')
      );
    } else if (
      formObject &&
      formObject['mainHeaderValue'] &&
      formObject['mainHeaderValue']['Date'] &&
      formObject['mainHeaderValue']['Shift'] === 'Night Shift'
    ) {
      let selectedDate = moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).add(1, 'day').endOf('day') ||
        current < moment(selectedDate).startOf('day')
      );
    } else if (
      formObject &&
      formObject['mainHeaderValue'] &&
      formObject['mainHeaderValue']['Date']
    ) {
      let selectedDate = moment(formObject['mainHeaderValue']['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).add(1, 'day').endOf('day') ||
        current < moment(selectedDate).startOf('day')
      );
    }
    return current || current > moment().endOf('day') || current < moment().startOf('day');
  };

  disabledRangeTime = (current) => {
    let { shift, formObject } = this.state;
    if (
      current !== null &&
      formObject &&
      formObject['form'] &&
      formObject['form'].datasource &&
      formObject['form'].datasource['Date']
    ) {
      let selectedDate = moment(
        formObject &&
          formObject['form'] &&
          formObject['form'].datasource &&
          formObject['form'].datasource['Date']
      ).format('YYYY-MM-DD');
      let currentDate = moment(current).format('YYYY-MM-DD');
      let range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
      };
      if (shift === 'Morning Shift') {
        return {
          // disabledHours: () => range(18, 18),
          disabledHours: () => [0, 1, 2, 3, 4, 5, 18, 19, 20, 21, 22, 23],
          disabledMinutes: () => range(60, 60)
          // disabledSeconds: () => [55, 56],
        };
      } else if (shift === 'Night Shift') {
        return {
          disabledHours: () => range(0, 24).splice(6, 12),
          disabledMinutes: () => range(60, 60)
        };
      } else if (selectedDate === currentDate) {
        return {
          disabledHours: () => [0, 1, 2, 3, 4, 5],
          disabledMinutes: () => range(60, 60)
        };
      } else if (selectedDate !== currentDate) {
        return {
          disabledHours: () => [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
        };
      }
    }
  };

  onDateChange = (date, dateString) => {
    this.setState({
      date: dateString
    });
  };
  inputHeaderNumberChange = (minValue, maxValue, value, key, name) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      (maxValue !== undefined || value === '')
    ) {
      this.onHeaderInputChange(value, key, name);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onHeaderInputChange(value, key, name);
    } else {
      this.onHeaderInputChange('', key, name);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  onHeaderInputChange = (columnValue, columnKey, name) => {
    let formObject = cloneDeep(this.props.formObject);
    let headerValue = {};
    if (
      (columnKey === 'IsEquipmentActive' || columnKey === 'EquipmentActiveStatus') &&
      columnValue === 'no'
    ) {
      headerValue = {
        [columnKey]: columnValue,
        TotalProductionOutput: '0'
      };
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          header: formObject[name].header.map((headerObject) => {
            if (
              headerObject.key !== 'EquipmentActiveStatus' &&
              headerObject.key !== 'TotalProductionOutput'
            ) {
              return {
                ...headerObject,
                hide: true
              };
            } else {
              return headerObject;
            }
          })
        }
      };
    } else if (columnKey === 'EquipmentActiveStatus' && columnValue === 'yes') {
      headerValue = {
        [columnKey]: columnValue,
        TotalProduction: '0'
      };

      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          header: formObject[name].header.map((headerObject) => {
            if (
              headerObject.key !== 'EquipmentActiveStatus' &&
              headerObject.key !== 'TotalProductionOutput'
            ) {
              return {
                ...headerObject,
                hide: false
              };
            } else {
              return headerObject;
            }
          })
        }
      };
    } else if (
      columnKey === 'BodyCount' &&
      formObject &&
      formObject[name] &&
      formObject[name]['headerValue'] &&
      formObject[name]['headerValue']['EquipmentActiveStatus'] === 'yes'
    ) {
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          header: formObject[name].header.map((headerObject) => {
            if (
              headerObject.key !== 'EquipmentActiveStatus' &&
              headerObject.key !== 'TotalProductionOutput'
            ) {
              return {
                ...headerObject,
                hide: false
              };
            } else {
              return headerObject;
            }
          })
        }
      };
      headerValue = {
        [columnKey]: columnValue
      };
      let batchArray = [];
      for (let i = 1; i <= columnValue; i++) {
        batchArray.push(i);
      }
      this.setState({
        batchArray
      });
    } else {
      headerValue = {
        [columnKey]: columnValue
      };
    }

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        headerValue: {
          ...(formObject && formObject[name] && formObject[name].headerValue
            ? formObject[name].headerValue
            : {}),
          ...headerValue
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  renderHeaderSwitch(formObject, columns, name) {
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onHeaderInputChange(e.target.value, columns.key, name)}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : ''
            }
            // min={columns.min ? columns.min : 1}
            // max={columns.max ? columns.max : 100}
            placeholder={columns.placeHolder}
            // onChange={(e) =>
            //     this.onHeaderInputChange(e, columns.key, name)}
            onBlur={(e) =>
              this.inputHeaderNumberChange(
                columns.min,
                columns.max,
                e.target.value,
                columns.key,
                name
              )
            }
            onChange={(e) => this.onHeaderInputChange(e, columns.key, name)}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : undefined
            }
            handleFieldChange={(e) => this.onHeaderInputChange(e, columns.key, name)}
            handleKeyPress={this.handleKeyPress}
          />
        );

      default:
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name)}
          />
        );
    }
  }

  saveTableViewData = (list) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      table: {
        ...(formObject && formObject['table']),
        datasource: list
      }
    };
    this.props.downTimeData(formObject, false);
  };

  render() {
    let { formObject } = this.state;
    return (
      <div style={{ marginTop: '30px', marginBottom: '30px', color: 'black', fontWeight: 'bold' }}>
        <Form className="batchUpdateForm1">
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                boxSizing: 'border-box'
              }}
            >
              {formObject && formObject.columns
                ? formObject.columns.map((columns, subindex) => {
                    return (
                      <div key={subindex} style={{ paddingRight: '2em' }}>
                        <Form.Item
                          required={false}
                          label={
                            <span style={{ marginLeft: columns.marginLeft }}>
                              {columns.displayName}
                            </span>
                          }
                        >
                          <div>{this.renderMainSwitch(formObject, columns)}</div>
                        </Form.Item>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </Form>
        <div>
          {formObject && formObject['table'] && formObject['table'].columns ? (
            <TableView
              form={formObject['table']}
              recordType={'FormSubSectionTable'}
              saveTableViewData={(e) => this.saveTableViewData(e)}
              showAddButton={
                formObject['table'].showAddButton ? formObject['table'].showAddButton : false
              }
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default AdhocFormTableView;
