import React from 'react';

import { getTranslationCache } from '../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import themeSettings from '../../AMM/Utils/themeSettings.json';
import {
  StyledComponent,
  StyledForm,
  StyledTabs,
  StyledTable,
  StyledBox,
  StyledBoxLabel,
  StyledSubForm,
  StyledModal
} from './CSS/style';
// import { DrawerFooter } from '../../../commons/styles/layout';
import {
  getList,
  getDeployHistory,
  getStatusList,
  getUserList,
  SubmitForm,
  FormSubmission,
  FormUpdation
} from '../APICalls/FormCalls';
import Highlighter from 'react-highlight-words';
// import { constant } from '../Utils/Constants';
import {
  RBSLOOKUPMaster,
  MASTERDATASOURCE,
  ANALYTICS,
  DRIFT,
  MASTERALGORITHM,
  ServerRegistrationMasters,
  RBSPREDMODEL,
  IPAddress
} from '../../../commons/api';
import {
  Tabs,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Space,
  message,
  Collapse,
  Tooltip
  // InputNumber
} from 'antd';
import { getUserProfile } from '../../../selectors/layout';
// import translation from '../Utils/translation';
import history from '../../../commons/history';
import moment from 'moment';
import {
  PlusOutlined,
  UserOutlined,
  FieldTimeOutlined,
  MinusCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const APIList = {
  getMetrics: `${RBSLOOKUPMaster.LookUpItemsGetById}`,
  getDataSourceList: `${MASTERDATASOURCE.DatasourceList}`,
  CreateTrainingJob: `${ANALYTICS.TrainingJobCreate}`,
  Get: `${ANALYTICS.TrainingJobGet}`,
  GetById: `${ANALYTICS.TrainingJobGetById}`,
  getDeviceList: `${DRIFT.GetDevice}`,
  getParameterList: `${DRIFT.DriftParameters}`,
  getAlgorithmList: `${MASTERALGORITHM.GET}`,
  getTrainingServerList: `${ServerRegistrationMasters.GET}`,
  ModelWorkBenchListGet: `${RBSPREDMODEL.ModelWorkBenchList}`,
  Parameterslist: `${ANALYTICS.Parameterslist}`,
  UpdateTrainingJob: `${ANALYTICS.TrainningJobUpdate}`,
  GitPush: `${IPAddress.PREDICTIONIP}/training/gitpush`
};
const Operator = [
  {
    key: 'EqualTo (==)',
    value: '=='
  },
  {
    key: 'Not EqualTo (!=)',
    value: '!='
  },
  {
    key: 'Greater Than (>)',
    value: '>'
  },
  {
    key: 'Less Than (<)',
    value: '<'
  },
  {
    key: 'Greater Than Or EqualTo (>=)',
    value: '>='
  },
  {
    key: 'Less Than Or EqualTo (<=)',
    value: '<='
  }
];

const OPTIONS = ['0', '1'];
class TrainingJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      dataSource: [],
      ModelsDataSource: [],
      ModelTypeList: [],
      SelecetedModelType: '',
      StatusList: [],
      DataSourceList: [],
      deviceList: [],
      parameterList: [],
      FilterParameterList: [],
      AggregationParameterList: [],
      TransformationParameterList: [],
      ClassificationParameterList: [],
      ParameterModal: false,
      LabelParameterList: [],
      Classification: {},
      isActive: true,
      SelectedFilterValue: null,
      SelectedClassificationValue: null,
      SelectedLagValue: null,
      selectedItems: [],
      FilterDisable: true,
      value: '',
      translationCache: props.translationCache || {},
      userProfile: props && props.userProfile ? props.userProfile : {}
    };
  }
  mainFormRef = React.createRef();
  FiltersRef = React.createRef();
  AggregationsRef = React.createRef();
  TransformationsRef = React.createRef();
  ClassificationRef = React.createRef();
  dynamicFormRef = React.createRef();
  componentDidMount = async () => {
    // let response = await getList(APIList.getList);

    const pathSnippets = history.location.pathname
      .split(`/rubus/RubusAnalytics/TrainningJob/`)
      .filter((i) => i);
    if (pathSnippets && pathSnippets[0]) {
      let useCaseLevel = pathSnippets[0].split('/').filter((i) => i);
      if (useCaseLevel[0] && useCaseLevel[0] !== 'New') {
        let formObject =
          history && history.location && history.location.state && history.location.state.formObject
            ? history.location.state.formObject
            : null;
        if (formObject && formObject !== null) {
          let TagsList = [];
          {
            Object.values(
              formObject &&
                formObject.DataSet &&
                formObject.DataSet[0] &&
                formObject.DataSet[0].Tags
            ).map(
              (key) =>
                key &&
                Array.isArray(key) &&
                key.map((item) => {
                  TagsList.push(item);
                })
            );
          }
          this.mainFormRef.current &&
            this.mainFormRef.current.setFieldsValue({
              Name: formObject && formObject.Name,
              Description: formObject && formObject.Description,
              Status: formObject && formObject.Status,
              TrainingServerId: formObject && formObject.TrainingServerId,
              CreatedBy: formObject && formObject.CreatedBy,
              CreatedDate: moment(formObject.CreatedDate).format('YYYY-MM-DD HH:mm:ss'),
              Id: formObject && formObject.Id,
              DataSourceID:
                formObject &&
                formObject.DataSet &&
                formObject.DataSet[0] &&
                formObject.DataSet[0].DataSourceId,
              Devices:
                formObject &&
                formObject.DataSet &&
                formObject.DataSet[0] &&
                formObject.DataSet[0].Devices,
              Tags: TagsList,
              AlgorithmId:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].AlgorithmId,
              ModelType:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].ModelType,
              HyperParameters:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].HyperParameters,
              Metrics:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].Metrics,
              TargetDevices:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].Devices,
              TargetParameter:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].TargetTag,
              Aggregation:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].Aggregation,

              FuturePeriodFrom:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].FuturePeriodFrom,

              FuturePeriodTo:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].FuturePeriodTo,

              FuturePeriodUom: 'MINUTES',
              // formObject &&
              // formObject.LabellingData &&
              // formObject.LabellingData[0] &&
              // formObject.LabellingData[0].FuturePeriodUom,

              TestDate: [
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Test_FromTime
                ),
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Test_ToTime
                )
              ],

              TrainDate: [
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Training_FromTime
                ),
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Training_ToTime
                )
              ],

              ValidationDate: [
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Validation_FromTime
                ),
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Validation_ToTime
                )
              ]
              // Attachments:formObject.Attachments
            });
          let ModelType =
            formObject &&
            formObject.AlgorithmData &&
            formObject.AlgorithmData[0] &&
            formObject.AlgorithmData[0].ModelType;
          let ApplicableMetricsList = [];
          if (ModelType && ModelType === 'Regression') {
            ApplicableMetricsList = await getDeployHistory(
              APIList.getMetrics,
              'LookupName',
              'RBS_MODEL_REG_METRICS'
            );
          } else {
            ApplicableMetricsList = await getDeployHistory(
              APIList.getMetrics,
              'LookupName',
              'RBS_MODEL_CLF_METRICS'
            );
          }
          let FuturePeriodUomList = await getDeployHistory(APIList.getMetrics, 'LookupName', 'UOM');
          let LagUnitList = await getDeployHistory(APIList.getMetrics, 'LookupName', 'LAG_UNITS');
          let ModelsDataSource =
            formObject && formObject.ModelDetails ? formObject.ModelDetails : [];
          this.setState({
            ModelsDataSource,
            LagUnitList,
            FuturePeriodUomList,
            ApplicableMetricsList,
            SelecetedModelType: ModelType
          });

          this.setState({
            // TagsData: formObject.DataSet &&
            //   formObject.DataSet[0] &&
            //   formObject.DataSet[0].Tags,
            ParametersData: {
              users:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].HyperParameters
            },
            HyperParametersData:
              formObject &&
              formObject.AlgorithmData &&
              formObject.AlgorithmData[0] &&
              formObject.AlgorithmData[0].HyperParameters
            // SelecetedModelType: formObject.ModelType
          });
          this.dynamicFormRef.current &&
            this.dynamicFormRef.current.setFieldsValue({
              users:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].HyperParameters
            });
          this.setState({
            Filters: {
              Filters:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Filters
            },
            Aggregations: {
              Aggregations:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Aggregations
            },
            Transformations: {
              Transformations:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Transformations
            },
            Classification: {
              Classification:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].Classification
            }
          });
          this.FiltersRef.current &&
            this.FiltersRef.current.setFieldsValue({
              Filters:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Filters
            });
          this.AggregationsRef.current &&
            this.AggregationsRef.current.setFieldsValue({
              Aggregations:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Aggregations
            });
          this.TransformationsRef.current &&
            this.TransformationsRef.current.setFieldsValue({
              Transformations:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Transformations
            });
          this.ClassificationRef.current &&
            this.ClassificationRef.current.setFieldsValue({
              Classification:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].Classification
            });

          let parameterList = {
            DeviceCode:
              formObject &&
              formObject.DataSet &&
              formObject.DataSet[0] &&
              formObject.DataSet[0].Devices
          };
          let response = await SubmitForm(parameterList, APIList.Parameterslist);
          this.setState({
            parameterList: response ? response : []
          });

          let LabelParameterList = {
            DeviceCode: formObject &&
              formObject.LabellingData &&
              formObject.LabellingData[0] && [formObject.LabellingData[0].Devices]
          };
          let response4 = await SubmitForm(LabelParameterList, APIList.Parameterslist);
          this.setState({
            LabelParameterList: response4 ? response4 : []
          });

          formObject &&
            formObject.LabellingData &&
            Array.isArray(formObject.LabellingData) &&
            formObject.LabellingData.map((item) => {
              if (item && item.Classification) {
                item.Classification.map(async (i) => {
                  let ClassificationParameterList = {
                    DeviceCode: [i && i.Device]
                  };
                  let response5 = await SubmitForm(
                    ClassificationParameterList,
                    APIList.Parameterslist
                  );
                  this.setState({
                    ClassificationParameterList: response5 ? response5 : []
                  });
                });
              }
            });
          formObject &&
            formObject.Preprocess &&
            Array.isArray(formObject.Preprocess) &&
            formObject.Preprocess.map((item) => {
              if (item && item.Filters) {
                item.Filters.map(async (i) => {
                  let FilterParameterList = {
                    DeviceCode: [i && i.Device]
                  };
                  // openPanels.push('1')
                  let response1 = await SubmitForm(FilterParameterList, APIList.Parameterslist);
                  this.setState({
                    FilterParameterList: response1 ? response1 : []
                    // openPanels
                  });
                });
              }
              if (item && item.Aggregations) {
                item.Aggregations.map(async (i) => {
                  let AggregationParameterList = {
                    DeviceCode: [i && i.Device]
                  };
                  let response2 = await SubmitForm(
                    AggregationParameterList,
                    APIList.Parameterslist
                  );
                  this.setState({
                    AggregationParameterList: response2 ? response2 : []
                  });
                });
              }

              if (item && item.Transformations) {
                item.Transformations.map(async (i) => {
                  let TransformationParameterList = {
                    DeviceCode: [i && i.Device]
                  };
                  let response3 = await SubmitForm(
                    TransformationParameterList,
                    APIList.Parameterslist
                  );
                  this.setState({
                    TransformationParameterList: response3 ? response3 : []
                  });
                });
              }
            });
          this.setState({ formObject });
        } else {
          this.mainFormRef.current &&
            this.mainFormRef.current.setFieldsValue({
              Name: null,
              Description: null,
              Status: null,
              TrainingServerId: null,
              CreatedBy:
                this.props &&
                this.props.userProfile &&
                this.props.userProfile.user &&
                this.props.userProfile.user.Id,
              CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
              Id: null,
              DataSourceID: null,
              Devices: [],
              Tags: [],
              AlgorithmId: null,
              ModelType: null,
              HyperParameters: null,
              Metrics: [],
              TargetDevices: [],
              TargetParameter: [],
              Aggregation: null
            });
          this.setState({
            formObject: {},
            momentRange1: [],
            momentRange2: [],
            momentRange3: [],
            momentRange4: [],
            FuturePeriodFrom: [],
            FuturePeriodTo: [],
            FuturePeriodUom: 'MINUTES',
            TrainDate: [],
            TestDate: [],
            ValidationDate: [],
            Filters: {},
            Aggregations: {},
            Transformations: {},
            Classification: {},
            ModelsDataSource: [],
            TagsData: []
          });

          this.FiltersRef.current &&
            this.FiltersRef.current.setFieldsValue({
              Filters: []
            });
          this.AggregationsRef.current &&
            this.AggregationsRef.current.setFieldsValue({
              Aggregations: []
            });
          this.TransformationsRef.current &&
            this.TransformationsRef.current.setFieldsValue({
              Transformations: []
            });
          this.ClassificationRef.current &&
            this.ClassificationRef.current.setFieldsValue({
              Classification: []
            });
        }
      } else {
        this.mainFormRef.current &&
          this.mainFormRef.current.setFieldsValue({
            CreatedBy:
              this.props &&
              this.props.userProfile &&
              this.props.userProfile.user &&
              this.props.userProfile.user.Id,
            CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss')
          });
      }
    }

    let FuturePeriodUomList = await getDeployHistory(APIList.getMetrics, 'LookupName', 'UOM');

    let LagUnitList = await getDeployHistory(APIList.getMetrics, 'LookupName', 'LAG_UNITS');
    let editData =
      history && history.location && history.location.state && history.location.state.editData
        ? history.location.state.editData
        : null;
    let ModelTypeList = await getDeployHistory(
      APIList.getMetrics,
      'LookupName',
      'RBS_PRED_MODEL_TYPES'
    );
    let deviceList = await getList(APIList.getDeviceList);

    let TrainingServerList = await getList(APIList.getTrainingServerList);

    // let ModelsDataSource = await getList(APIList.ModelWorkBenchListGet);

    let AlgorithmList = await getList(APIList.getAlgorithmList);

    let StatusList = await getStatusList();

    let list = await getUserList();

    let DataSourceList = await getList(APIList.getDataSourceList);

    let AnalyticsId =
      history && history.location && history.location.state && history.location.state.AnalyticsId
        ? history.location.state.AnalyticsId
        : null;

    let TaskId =
      history && history.location && history.location.state && history.location.state.TaskId
        ? history.location.state.TaskId
        : null;
    // this.mainFormRef.current &&
    //   this.mainFormRef.current.setFieldsValue({
    //     CreatedBy:
    //       this.props &&
    //       this.props.userProfile &&
    //       this.props.userProfile.user &&
    //       this.props.userProfile.user.Id,
    //     CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss')
    //   });
    this.setState({
      ModelTypeList,
      FuturePeriodUomList,
      LagUnitList,
      StatusList: StatusList,
      DataSourceList,
      TrainingServerList,
      // ModelsDataSource,
      deviceList,
      users: list,
      AlgorithmList,
      TaskId,
      editData,
      AnalyticsId
    });
  };
  componentDidUpdate = async (prevProps) => {
    if (
      this.props.userProfile !== prevProps.userProfile ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.setState({
        userProfile: this.props.userProfile,
        translationCache: this.props.translationCache
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  FormSave = (e) => {
    e.preventDefault();
    let {
      // momentRange1,
      // momentRange2,
      // momentRange3,
      HyperParametersData,
      TaskId,
      formObject,
      TagsData,
      Filters,
      Aggregations,
      Transformations,
      Classification
    } = this.state;
    let payload = {};
    let FormData = this.mainFormRef.current && this.mainFormRef.current.getFieldsValue();
    let FiltersData = this.FiltersRef.current && this.FiltersRef.current.getFieldsValue();
    let AggregationData =
      this.AggregationsRef.current && this.AggregationsRef.current.getFieldsValue();
    let TransformationsData =
      this.TransformationsRef.current && this.TransformationsRef.current.getFieldsValue();
    let ClassificationData =
      this.ClassificationRef.current && this.ClassificationRef.current.getFieldsValue();

    let groupedTags =
      TagsData &&
      Array.isArray(TagsData) &&
      TagsData.reduce((result, obj) => {
        (result[obj.Device] = result[obj.Device] || []).push(obj.value);
        return result;
      }, {});

    this.mainFormRef.current &&
      this.mainFormRef.current
        .validateFields()
        .then(async () => {
          payload = {
            Name: FormData && FormData.Name,
            Description: FormData && FormData.Description,
            Status: FormData && FormData.Status,
            TrainingServerId: FormData && FormData.TrainingServerId,
            CreatedBy: FormData && FormData.CreatedBy,
            CreatedDate:
              FormData &&
              FormData.CreatedDate &&
              moment(FormData.CreatedDate).utc().format('YYYY-MM-DD HH:mm:ss'),
            TaskId: TaskId,
            DataSet: [
              {
                Id:
                  formObject &&
                  formObject.DataSet &&
                  formObject.DataSet[0] &&
                  formObject.DataSet[0].Id,
                DataSourceID: FormData && FormData.DataSourceID,
                Devices: FormData && FormData.Devices,
                Tags: groupedTags,
                Training_FromTime: moment(FormData && FormData.TrainDate && FormData.TrainDate[0]),
                Training_ToTime: moment(FormData && FormData.TrainDate && FormData.TrainDate[1]),
                Validation_FromTime: moment(
                  FormData && FormData.ValidationDate && FormData.ValidationDate[0]
                ),

                Validation_ToTime: moment(
                  FormData && FormData.ValidationDate && FormData.ValidationDate[1]
                ),
                Test_FromTime: moment(FormData && FormData.TestDate && FormData.TestDate[0]),
                Test_ToTime: moment(FormData && FormData.TestDate && FormData.TestDate[1])
              }
            ],
            Preprocess: [
              {
                Id:
                  formObject &&
                  formObject.Preprocess &&
                  formObject.Preprocess[0] &&
                  formObject.Preprocess[0].Id,
                Filters:
                  FiltersData && FiltersData.Filters
                    ? FiltersData.Filters
                    : Filters && Filters.Filters,
                Aggregations:
                  AggregationData && AggregationData.Aggregations
                    ? AggregationData.Aggregations
                    : Aggregations && Aggregations.Aggregations,
                Transformations:
                  TransformationsData && TransformationsData.Transformations
                    ? TransformationsData.Transformations
                    : Transformations && Transformations.Transformations
              }
            ],
            AlgorithmData: [
              {
                Id:
                  formObject &&
                  formObject.AlgorithmData &&
                  formObject.AlgorithmData[0] &&
                  formObject.AlgorithmData[0].Id,
                AlgorithmId: FormData && FormData.AlgorithmId,
                ModelType: FormData && FormData.ModelType,
                HyperParameters: HyperParametersData,
                Metrics: FormData && FormData.Metrics
              }
            ],
            LabellingData: [
              {
                Id:
                  formObject &&
                  formObject.LabellingData &&
                  formObject.LabellingData[0] &&
                  formObject.LabellingData[0].Id,
                TargetDevices: FormData && FormData.TargetDevices,
                TargetParameter: FormData && FormData.TargetParameter,
                Aggregation: FormData && FormData.Aggregation,
                FuturePeriodFrom: FormData && FormData.FuturePeriodFrom,
                FuturePeriodTo: FormData && FormData.FuturePeriodTo,
                FuturePeriodUom: 'MINUTES',
                // FormData && FormData.FuturePeriodUom,
                Classification:
                  ClassificationData && ClassificationData.Classification
                    ? ClassificationData.Classification
                    : Classification && Classification.Classification
              }
            ]
          };

          let FiltersArray = payload.Preprocess[0].Filters;
          let msg1 = '';
          let msg11 = '';

          FiltersArray &&
            Array.isArray(FiltersArray) &&
            FiltersArray.length !== 0 &&
            FiltersArray.filter((data) => {
              if (data === null || data === undefined) {
                this.FiltersRef.current && this.FiltersRef.current.validateFields();
                this.setState({ payload: {} });
                msg1 = 'Please add mandatory fields in Filters';
                message.error('Please add mandatory fields in Filters');
              } else {
                msg1 = '';
              }
            });
          FiltersArray &&
            Array.isArray(FiltersArray) &&
            FiltersArray.length !== 0 &&
            FiltersArray.map((item) => {
              item &&
                item !== undefined &&
                Object.values(item).filter((values) => {
                  if (
                    values === undefined ||
                    values === null ||
                    (values === '' && Object.values(item).length <= 4) ||
                    Object.values(item).length !== 4
                  ) {
                    this.FiltersRef.current && this.FiltersRef.current.validateFields();
                    this.setState({ payload: {} });
                    msg11 = 'Please add mandatory fields in Filters';
                    message.error('Please add mandatory fields in Filters');
                  } else {
                    msg11 = '';
                  }
                });
            });

          let AggregationArray = payload.Preprocess[0].Aggregations;
          let msg2 = '';
          let msg21 = '';

          AggregationArray &&
            Array.isArray(AggregationArray) &&
            AggregationArray.length !== 0 &&
            AggregationArray.map((item) => {
              item &&
                item !== undefined &&
                Object.values(item).filter((values) => {
                  if (
                    values === undefined ||
                    values === null ||
                    (values === '' && Object.values(item).length <= 5) ||
                    Object.values(item).length !== 5
                  ) {
                    this.AggregationsRef.current && this.AggregationsRef.current.validateFields();
                    this.setState({ payload: {} });
                    msg2 = 'Please add mandatory fields in Aggregations';
                    message.error('Please add mandatory fields in Aggregations');
                  } else {
                    msg2 = '';
                  }
                });
            });
          AggregationArray &&
            Array.isArray(AggregationArray) &&
            AggregationArray.length !== 0 &&
            AggregationArray.filter((data, index) => {
              if (
                AggregationArray[index] === null ||
                AggregationArray[index] === undefined ||
                data === null ||
                data === undefined
              ) {
                this.AggregationsRef.current && this.AggregationsRef.current.validateFields();
                this.setState({ payload: {} });
                msg21 = 'Please add mandatory fields in Aggregations===';
                message.error('Please add mandatory fields in Aggregations');
              } else {
                msg21 = '';
              }
            });

          let TransformationArray = payload.Preprocess[0].Transformations;
          let msg3 = '';
          let msg31 = '';

          TransformationArray &&
            Array.isArray(TransformationArray) &&
            TransformationArray.length !== 0 &&
            TransformationArray.filter((data) => {
              if (data === null || data === undefined) {
                this.TransformationsRef.current && this.TransformationsRef.current.validateFields();
                this.setState({ payload: {} });
                msg3 = 'Please add mandatory fields in Transformations';
                message.error('Please add mandatory fields in Transformations');
              } else {
                msg3 = '';
              }
            });
          TransformationArray &&
            Array.isArray(TransformationArray) &&
            TransformationArray.length !== 0 &&
            TransformationArray.map((item) => {
              item &&
                item !== undefined &&
                Object.values(item).filter((values) => {
                  if (
                    values === undefined ||
                    values === null ||
                    (values === '' && Object.values(item).length <= 3) ||
                    Object.values(item).length !== 3
                  ) {
                    this.TransformationsRef.current &&
                      this.TransformationsRef.current.validateFields();
                    this.setState({ payload: {} });
                    msg31 = 'Please add mandatory fields in Transformations';
                    message.error('Please add mandatory fields in Transformations');
                  } else {
                    msg31 = '';
                  }
                });
            });

          let msg4 = '';
          let msg41 = '';
          let msg42 = '';

          let ClassificationArray = [];

          if (this.state.SelecetedModelType && this.state.SelecetedModelType === 'Classification') {
            ClassificationArray = payload.LabellingData[0].Classification;
            if (
              ClassificationArray === undefined ||
              ClassificationArray === null ||
              (ClassificationArray && ClassificationArray.length === 0)
            ) {
              msg42 = 'Please Add Classification Target';
              message.error('Please Add Classification Target');
              this.setState({ payload: {} });
            } else {
              msg42 = '';
            }

            ClassificationArray &&
              Array.isArray(ClassificationArray) &&
              ClassificationArray.length !== 0 &&
              ClassificationArray.filter((data) => {
                if (data === null || data === undefined) {
                  this.ClassificationRef.current && this.ClassificationRef.current.validateFields();
                  this.setState({ payload: {} });
                  msg4 = 'Please add mandatory fields in Classification';
                  message.error('Please add mandatory fields in Classification');
                } else {
                  msg4 = '';
                }
              });
            ClassificationArray &&
              Array.isArray(ClassificationArray) &&
              ClassificationArray.length !== 0 &&
              ClassificationArray.map((item) => {
                item &&
                  item !== undefined &&
                  Object.values(item).filter((values) => {
                    if (
                      values === undefined ||
                      values === null ||
                      (values === '' && Object.values(item).length <= 4) ||
                      Object.values(item).length !== 4
                    ) {
                      this.ClassificationRef.current &&
                        this.ClassificationRef.current.validateFields();
                      this.setState({ payload: {} });
                      msg41 = 'Please add mandatory fields in Classification';
                      message.error('Please add mandatory fields in Classification');
                    } else {
                      msg41 = '';
                    }
                  });
              });
          }

          if (this.state.SelecetedModelType && this.state.SelecetedModelType === 'Classification') {
            if (
              msg1 === '' &&
              msg11 === '' &&
              msg2 === '' &&
              msg21 === '' &&
              msg3 === '' &&
              msg31 === '' &&
              msg4 === '' &&
              msg41 === '' &&
              msg42 === ''
            ) {
              this.setState({ payload });
              message.success('Successfully Saved');
            } else {
              this.setState({ payload: {} });
            }
          } else if (
            this.state.SelecetedModelType &&
            this.state.SelecetedModelType !== 'Classification'
          ) {
            if (
              msg1 === '' &&
              msg11 === '' &&
              msg2 === '' &&
              msg21 === '' &&
              msg3 === '' &&
              msg31 === ''
            ) {
              this.setState({ payload });
              message.success('Successfully Saved');
            } else {
              this.setState({ payload: {} });
            }
          } else {
            this.setState({ payload: {} });
            message.error('Please enter Required fields');
          }
        })
        .catch((error) => {
          this.setState({ payload: {} });
          message.error('Please Provide Required Fields');
          console.log(error);
        });
  };

  FormSubmit = async () => {
    let { payload, formObject, TaskId, HyperParametersData } = this.state;
    if (payload && payload !== undefined) {
      if (formObject && formObject.Id) {
        payload = {
          ...payload,
          Id: formObject.Id
        };
        let UpdateResponse = await FormUpdation(payload, APIList.UpdateTrainingJob);
        if (UpdateResponse && UpdateResponse.status === 200) {
          message.success(UpdateResponse && UpdateResponse.data && UpdateResponse.data.message);
          let PayloadForPrediction = {
            Id: UpdateResponse.data.Id
          };
          let PredictionResponse = await FormUpdation(PayloadForPrediction, APIList.GitPush);
          if (PredictionResponse && PredictionResponse.status === 200) {
            message.success(
              PredictionResponse && PredictionResponse.data && PredictionResponse.data.message
            );
          }

          let payload = {
            // AnalyticsId: AnalyticsId,
            TaskId: TaskId,
            Id: UpdateResponse.data.Id
          };
          let formObject = await SubmitForm(payload, APIList.GetById);
          let TagsList = [];
          {
            Object.values(
              formObject &&
                formObject.DataSet &&
                formObject.DataSet[0] &&
                formObject.DataSet[0].Tags
            ).map(
              (key) =>
                key &&
                Array.isArray(key) &&
                key.map((item) => {
                  TagsList.push(item);
                })
            );
          }
          this.mainFormRef.current &&
            this.mainFormRef.current.setFieldsValue({
              Name: formObject && formObject.Name,
              Description: formObject && formObject.Description,
              Status: formObject && formObject.Status,
              TrainingServerId: formObject && formObject.TrainingServerId,
              CreatedBy: formObject && formObject.CreatedBy,
              CreatedDate: moment(formObject.CreatedDate).format('YYYY-MM-DD HH:mm:ss'),
              Id: formObject && formObject.Id,
              DataSourceID:
                formObject &&
                formObject.DataSet &&
                formObject.DataSet[0] &&
                formObject.DataSet[0].DataSourceId,
              Devices:
                formObject &&
                formObject.DataSet &&
                formObject.DataSet[0] &&
                formObject.DataSet[0].Devices,
              Tags: TagsList,
              AlgorithmId:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].AlgorithmId,
              ModelType:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].ModelType,
              HyperParameters: HyperParametersData,
              Metrics:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].Metrics,
              TargetDevices:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].Devices,
              TargetParameter:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].TargetTag,
              Aggregation:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].Aggregation,

              FuturePeriodFrom:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].FuturePeriodFrom,

              FuturePeriodTo:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].FuturePeriodTo,

              FuturePeriodUom: 'MINUTES',
              // formObject &&
              // formObject.LabellingData &&
              // formObject.LabellingData[0] &&
              // formObject.LabellingData[0].FuturePeriodUom,

              TestDate: [
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Test_FromTime
                ),
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Test_ToTime
                )
              ],

              TrainDate: [
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Training_FromTime
                ),
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Training_ToTime
                )
              ],

              ValidationDate: [
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Validation_FromTime
                ),
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Validation_ToTime
                )
              ]
              // Attachments:formObject.Attachments
            });
          history.push({
            pathname: `/rubus/RubusAnalytics/TrainningJob/${UpdateResponse.data.Id}`
          });
          // this.setState({ history, editData: formObject });
          this.props.renderForm(formObject && formObject.Path, 'edit', 'type');
          let ModelType =
            formObject &&
            formObject.AlgorithmData &&
            formObject.AlgorithmData[0] &&
            formObject.AlgorithmData[0].ModelType;
          let ApplicableMetricsList = [];
          if (ModelType && ModelType === 'Regression') {
            ApplicableMetricsList = await getDeployHistory(
              APIList.getMetrics,
              'LookupName',
              'RBS_MODEL_REG_METRICS'
            );
          } else {
            ApplicableMetricsList = await getDeployHistory(
              APIList.getMetrics,
              'LookupName',
              'RBS_MODEL_CLF_METRICS'
            );
          }
          let FuturePeriodUomList = await getDeployHistory(APIList.getMetrics, 'LookupName', 'UOM');
          let LagUnitList = await getDeployHistory(APIList.getMetrics, 'LookupName', 'LAG_UNITS');
          this.setState({
            FuturePeriodUomList,
            LagUnitList,
            ApplicableMetricsList,
            SelecetedModelType: ModelType
          });
          // let listData = formObject.DataSet && formObject.DataSet[0] && formObject.DataSet[0].Tags;
          this.setState({
            // TagsData: formObject.DataSet &&
            //   formObject.DataSet[0] &&
            //   formObject.DataSet[0].Tags,
            ParametersData: {
              users:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].HyperParameters
            },
            HyperParametersData:
              formObject &&
              formObject.AlgorithmData &&
              formObject.AlgorithmData[0] &&
              formObject.AlgorithmData[0].HyperParameters
            // SelecetedModelType: formObject.ModelType
          });
          this.dynamicFormRef.current &&
            this.dynamicFormRef.current.setFieldsValue({
              users:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].HyperParameters
            });
          let ModelsDataSource =
            formObject && formObject.ModelDetails ? formObject.ModelDetails : [];
          this.setState({
            ModelsDataSource,
            path: formObject && formObject.Path,
            formObject: formObject,
            Filters: {
              Filters:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Filters
            },
            Aggregations: {
              Aggregations:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Aggregations
            },
            Transformations: {
              Transformations:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Transformations
            },
            Classification: {
              Classification:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].Classification
            }
          });
          this.FiltersRef.current &&
            this.FiltersRef.current.setFieldsValue({
              Filters:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Filters
            });
          this.AggregationsRef.current &&
            this.AggregationsRef.current.setFieldsValue({
              Aggregations:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Aggregations
            });
          this.TransformationsRef.current &&
            this.TransformationsRef.current.setFieldsValue({
              Transformations:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Transformations
            });
          this.ClassificationRef.current &&
            this.ClassificationRef.current.setFieldsValue({
              Classification:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].Classification
            });

          let parameterList = {
            DeviceCode:
              formObject &&
              formObject.DataSet &&
              formObject.DataSet[0] &&
              formObject.DataSet[0].Devices
          };
          let response = await SubmitForm(parameterList, APIList.Parameterslist);
          this.setState({
            parameterList: response ? response : []
          });

          let LabelParameterList = {
            DeviceCode: formObject &&
              formObject.LabellingData &&
              formObject.LabellingData[0] && [formObject.LabellingData[0].Devices]
          };
          let response4 = await SubmitForm(LabelParameterList, APIList.Parameterslist);
          this.setState({
            LabelParameterList: response4 ? response4 : []
          });

          formObject &&
            formObject.LabellingData &&
            Array.isArray(formObject.LabellingData) &&
            formObject.LabellingData.map((item) => {
              if (item && item.Classification) {
                item.Classification.map(async (i) => {
                  let ClassificationParameterList = {
                    DeviceCode: [i && i.Device]
                  };
                  let response5 = await SubmitForm(
                    ClassificationParameterList,
                    APIList.Parameterslist
                  );
                  this.setState({
                    ClassificationParameterList: response5 ? response5 : []
                  });
                });
              }
            });

          formObject &&
            formObject.Preprocess &&
            Array.isArray(formObject.Preprocess) &&
            formObject.Preprocess.map((item) => {
              if (item && item.Filters) {
                item.Filters.map(async (i) => {
                  let FilterParameterList = {
                    DeviceCode: [i && i.Device]
                  };
                  // openPanels.push('1')
                  let response1 = await SubmitForm(FilterParameterList, APIList.Parameterslist);
                  this.setState({
                    FilterParameterList: response1 ? response1 : []
                    // openPanels
                  });
                });
              }
              if (item && item.Aggregations) {
                item.Aggregations.map(async (i) => {
                  let AggregationParameterList = {
                    DeviceCode: [i && i.Device]
                  };
                  let response2 = await SubmitForm(
                    AggregationParameterList,
                    APIList.Parameterslist
                  );
                  this.setState({
                    AggregationParameterList: response2 ? response2 : []
                  });
                });
              }

              if (item && item.Transformations) {
                item.Transformations.map(async (i) => {
                  let TransformationParameterList = {
                    DeviceCode: [i && i.Device]
                  };
                  let response3 = await SubmitForm(
                    TransformationParameterList,
                    APIList.Parameterslist
                  );
                  this.setState({
                    TransformationParameterList: response3 ? response3 : []
                  });
                });
              }
            });
          this.setState({ formObject });
        }
      } else {
        let createResponse = await FormSubmission(payload, APIList.CreateTrainingJob);
        if (createResponse && createResponse.status === 200) {
          message.success(createResponse && createResponse.data && createResponse.data.message);
          let PayloadForPrediction = {
            Id: createResponse.data.Id
          };
          let PredictionResponse = await FormUpdation(PayloadForPrediction, APIList.GitPush);
          if (PredictionResponse && PredictionResponse.status === 200) {
            message.success(
              PredictionResponse && PredictionResponse.data && PredictionResponse.data.message
            );
          }

          let payload = {
            // AnalyticsId: AnalyticsId,
            TaskId: TaskId,
            Id: createResponse.data.Id
          };
          let formObject = await SubmitForm(payload, APIList.GetById);
          let TagsList = [];
          {
            formObject &&
              formObject.DataSet &&
              formObject.DataSet[0] &&
              formObject.DataSet[0].Tags &&
              Object.values(formObject.DataSet[0].Tags).map(
                (key) =>
                  key &&
                  Array.isArray(key) &&
                  key.map((item) => {
                    TagsList.push(item);
                  })
              );
          }
          // let response = await getList(APIList.Get);
          this.mainFormRef.current &&
            this.mainFormRef.current.setFieldsValue({
              Name: formObject && formObject.Name,
              Description: formObject && formObject.Description,
              Status: formObject && formObject.Status,
              TrainingServerId: formObject && formObject.TrainingServerId,
              CreatedBy: formObject && formObject.CreatedBy,
              CreatedDate:
                formObject &&
                formObject.CreatedDate &&
                moment(formObject.CreatedDate).format('YYYY-MM-DD HH:mm:ss'),
              Id: formObject && formObject.Id,
              DataSourceID:
                formObject &&
                formObject.DataSet &&
                formObject.DataSet[0] &&
                formObject.DataSet[0].DataSourceId,
              Devices:
                formObject &&
                formObject.DataSet &&
                formObject.DataSet[0] &&
                formObject.DataSet[0].Devices,
              Tags: TagsList,
              AlgorithmId:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].AlgorithmId,
              ModelType:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].ModelType,
              HyperParameters:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].HyperParameters,
              Metrics:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].Metrics,
              TargetDevices:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].Devices,
              TargetParameter:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].TargetTag,
              Aggregation:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].Aggregation,

              FuturePeriodFrom:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].FuturePeriodFrom,

              FuturePeriodTo:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].FuturePeriodTo,

              FuturePeriodUom: 'MINUTES',
              // formObject &&
              // formObject.LabellingData &&
              // formObject.LabellingData[0] &&
              // formObject.LabellingData[0].FuturePeriodUom,

              TestDate: [
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Test_FromTime
                ),
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Test_ToTime
                )
              ],

              TrainDate: [
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Training_FromTime
                ),
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Training_ToTime
                )
              ],

              ValidationDate: [
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Validation_FromTime
                ),
                moment(
                  formObject &&
                    formObject.DataSet &&
                    formObject.DataSet[0] &&
                    formObject.DataSet[0].Validation_ToTime
                )
              ]
              // Attachments:formObject.Attachments
            });
          history.push({
            pathname: `/rubus/RubusAnalytics/TrainningJob/${createResponse.data.Id}`
          });
          this.props.renderForm(formObject && formObject.Path, 'edit', 'type');
          let ModelType =
            formObject &&
            formObject.AlgorithmData &&
            formObject.AlgorithmData[0] &&
            formObject.AlgorithmData[0].ModelType;
          let ApplicableMetricsList = [];
          if (ModelType && ModelType === 'Regression') {
            ApplicableMetricsList = await getDeployHistory(
              APIList.getMetrics,
              'LookupName',
              'RBS_MODEL_REG_METRICS'
            );
          } else {
            ApplicableMetricsList = await getDeployHistory(
              APIList.getMetrics,
              'LookupName',
              'RBS_MODEL_CLF_METRICS'
            );
          }
          let FuturePeriodUomList = await getDeployHistory(APIList.getMetrics, 'LookupName', 'UOM');
          let LagUnitList = await getDeployHistory(APIList.getMetrics, 'LookupName', 'LAG_UNITS');
          let ModelsDataSource =
            formObject && formObject.ModelDetails ? formObject.ModelDetails : [];
          this.setState({
            FuturePeriodUomList,
            ModelsDataSource,
            LagUnitList,
            ApplicableMetricsList,
            SelecetedModelType: ModelType
          });
          this.setState({
            // TagsData: formObject.DataSet &&
            //   formObject.DataSet[0] &&
            //   formObject.DataSet[0].Tags,
            ParametersData: {
              users:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].HyperParameters
            },
            HyperParametersData:
              formObject &&
              formObject.AlgorithmData &&
              formObject.AlgorithmData[0] &&
              formObject.AlgorithmData[0].HyperParameters
            // SelecetedModelType: formObject.ModelType
          });
          this.dynamicFormRef.current &&
            this.dynamicFormRef.current.setFieldsValue({
              users:
                formObject &&
                formObject.AlgorithmData &&
                formObject.AlgorithmData[0] &&
                formObject.AlgorithmData[0].HyperParameters
            });
          this.setState({
            formObject: formObject,
            Id: formObject.Id,
            path: formObject && formObject.Path,
            Filters: {
              Filters:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Filters
            },
            Aggregations: {
              Aggregations:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Aggregations
            },
            Transformations: {
              Transformations:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Transformations
            },
            Classification: {
              Classification:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].Classification
            }
          });
          this.FiltersRef.current &&
            this.FiltersRef.current.setFieldsValue({
              Filters:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Filters
            });
          this.AggregationsRef.current &&
            this.AggregationsRef.current.setFieldsValue({
              Aggregations:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Aggregations
            });
          this.TransformationsRef.current &&
            this.TransformationsRef.current.setFieldsValue({
              Transformations:
                formObject &&
                formObject.Preprocess &&
                formObject.Preprocess[0] &&
                formObject.Preprocess[0].Transformations
            });
          this.ClassificationRef.current &&
            this.ClassificationRef.current.setFieldsValue({
              Classification:
                formObject &&
                formObject.LabellingData &&
                formObject.LabellingData[0] &&
                formObject.LabellingData[0].Classification
            });

          let parameterList = {
            DeviceCode:
              formObject &&
              formObject.DataSet &&
              formObject.DataSet[0] &&
              formObject.DataSet[0].Devices
          };
          let response = await SubmitForm(parameterList, APIList.Parameterslist);
          this.setState({
            parameterList: response ? response : []
          });

          let LabelParameterList = {
            DeviceCode: formObject &&
              formObject.LabellingData &&
              formObject.LabellingData[0] && [formObject.LabellingData[0].Devices]
          };
          let response4 = await SubmitForm(LabelParameterList, APIList.Parameterslist);
          this.setState({
            LabelParameterList: response4 ? response4 : []
          });

          formObject &&
            formObject.LabellingData &&
            Array.isArray(formObject.LabellingData) &&
            formObject.LabellingData.map((item) => {
              if (item && item.Classification) {
                item.Classification.map(async (i) => {
                  let ClassificationParameterList = {
                    DeviceCode: [i && i.Device]
                  };
                  let response5 = await SubmitForm(
                    ClassificationParameterList,
                    APIList.Parameterslist
                  );
                  this.setState({
                    ClassificationParameterList: response5 ? response5 : []
                  });
                });
              }
            });

          formObject &&
            formObject.Preprocess &&
            Array.isArray(formObject.Preprocess) &&
            formObject.Preprocess.map((item) => {
              if (item && item.Filters) {
                item.Filters.map(async (i) => {
                  let FilterParameterList = {
                    DeviceCode: [i && i.Device]
                  };
                  // openPanels.push('1')
                  let response1 = await SubmitForm(FilterParameterList, APIList.Parameterslist);
                  this.setState({
                    FilterParameterList: response1 ? response1 : []
                    // openPanels
                  });
                });
              }
              if (item && item.Aggregations) {
                item.Aggregations.map(async (i) => {
                  let AggregationParameterList = {
                    DeviceCode: [i && i.Device]
                  };
                  let response2 = await SubmitForm(
                    AggregationParameterList,
                    APIList.Parameterslist
                  );
                  this.setState({
                    AggregationParameterList: response2 ? response2 : []
                  });
                });
              }

              if (item && item.Transformations) {
                item.Transformations.map(async (i) => {
                  let TransformationParameterList = {
                    DeviceCode: [i && i.Device]
                  };
                  let response3 = await SubmitForm(
                    TransformationParameterList,
                    APIList.Parameterslist
                  );
                  this.setState({
                    TransformationParameterList: response3 ? response3 : []
                  });
                });
              }
            });
          this.setState({ formObject });
        }
      }
    } else {
      message.error('Please Save Before Submit');
    }
  };

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };
  onSubmit = (values) => {
    this.setState({
      HyperParametersData: values && values.users,
      ParameterModal: false,
      ParametersData: { users: values.users }
    });
  };
  onChangeTrainDate = (value, dateString) => {
    this.setState({
      range: [dateString[0], dateString[1]],
      momentRange1: [moment(dateString[0]), moment(dateString[1])],
      Training_FromTime: moment(dateString[0]),
      Training_ToTime: moment(dateString[1])
    });
  };
  onChangeValidationDate = (value, dateString) => {
    this.setState({
      range: [dateString[0], dateString[1]],
      momentRange2: [moment(dateString[0]), moment(dateString[1])],
      Validation_FromTime: moment(dateString[0]),
      Validation_ToTime: moment(dateString[1])
    });
  };
  onChangeTestDate = (value, dateString) => {
    this.setState({
      range: [dateString[0], dateString[1]],
      momentRange3: [moment(dateString[0]), moment(dateString[1])],
      Test_FromTime: moment(dateString[0]),
      Test_ToTime: moment(dateString[1])
    });
  };
  onChangeTargetDate = (value, dateString) => {
    this.setState({
      range: [dateString[0], dateString[1]],
      momentRange4: [moment(dateString[0]), moment(dateString[1])],
      TargetFrom: moment(dateString[0]),
      TargetTo: moment(dateString[1])
    });
  };
  onModelTypeChange = async (value) => {
    let ApplicableMetricsList = [];
    if (value && value === 'Regression') {
      ApplicableMetricsList = await getDeployHistory(
        APIList.getMetrics,
        'LookupName',
        'RBS_MODEL_REG_METRICS'
      );
    } else {
      ApplicableMetricsList = await getDeployHistory(
        APIList.getMetrics,
        'LookupName',
        'RBS_MODEL_CLF_METRICS'
      );
    }
    this.mainFormRef.current &&
      this.mainFormRef.current.setFieldsValue({
        Metrics: []
      });

    this.setState({ ApplicableMetricsList, SelecetedModelType: value, selectedItems: [] });
  };

  onCheck = async (key, value) => {
    let parameterList = {
      DeviceCode: value
    };
    this.mainFormRef.current &&
      this.mainFormRef.current.setFieldsValue({
        Tags: []
      });
    let response = await SubmitForm(parameterList, APIList.Parameterslist);
    this.setState({
      parameterList: response ? response : []
    });
  };
  tagChnage = (key, value) => {
    this.setState({ TagsData: value });
  };
  onDeviceCheck = async (key, value) => {
    let FilterParameterList = {
      DeviceCode: [value]
    };
    let response = await SubmitForm(FilterParameterList, APIList.Parameterslist);
    this.setState({
      FilterParameterList: response ? response : []
    });
  };
  onAggregationCheck = async (key, value) => {
    let AggregationParameterList = {
      DeviceCode: [value]
    };
    let response = await SubmitForm(AggregationParameterList, APIList.Parameterslist);
    this.setState({
      AggregationParameterList: response ? response : []
    });
  };
  onTransformationCheck = async (key, value) => {
    let TransformationParameterList = {
      DeviceCode: [value]
    };
    let response = await SubmitForm(TransformationParameterList, APIList.Parameterslist);
    this.setState({
      TransformationParameterList: response ? response : []
    });
  };
  // onClassificationCheck = async (key, value) => {
  //   let ClassificationParameterList = {
  //     DeviceCode: [value]
  //   };
  //   let response = await SubmitForm(ClassificationParameterList, APIList.Parameterslist);
  //   this.setState({
  //     ClassificationParameterList: response ? response : []
  //   });
  // };
  onCheckDevice = async (key, value) => {
    let LabelParameterList = {
      DeviceCode: [value]
    };
    this.mainFormRef.current &&
      this.mainFormRef.current.setFieldsValue({
        TargetParameter: ''
      });
    let response = await SubmitForm(LabelParameterList, APIList.Parameterslist);
    this.setState({
      LabelParameterList: response ? response : []
    });
  };
  algorithmChange = async (value) => {
    let HyperParameterList = await getDeployHistory(APIList.getAlgorithmList, 'Id', value);
    this.setState({
      ParametersData: {
        users: HyperParameterList && HyperParameterList[0] && HyperParameterList[0].HyperParameters
      },
      HyperParametersData:
        HyperParameterList && HyperParameterList[0] && HyperParameterList[0].HyperParameters
      // SelecetedModelType: formObject.ModelType
    });
    this.dynamicFormRef.current &&
      this.dynamicFormRef.current.setFieldsValue({
        users: HyperParameterList && HyperParameterList[0] && HyperParameterList[0].HyperParameters
      });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search') + this.translation(dataIndex)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  // onChangeValue = (value) => {
  //   if (typeof(value)) {
  //     this.setState({ SelectedFilterValue: value });
  //   } else {
  //     message.error('Please Input Numeric Values Only');
  //   }
  // };
  onChangeValue1 = (value) => {
    if (!isNaN(value)) {
      this.setState({ SelectedClassificationValue: value });
    } else {
      message.error('Please Input Numeric Values Only');
    }
  };
  lagOnChange = (value) => {
    if (!isNaN(value)) {
      this.setState({ SelectedLagValue: value });
    } else {
      message.error('Please Input Numeric Values Only');
    }
  };
  classOnChange = (selectedItems) => {
    this.setState({ selectedItems });
  };
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: '' });
  };
  onCollapseChange = () => {
    let { Filters, Aggregations, Transformations } = this.state;
    if (
      (Filters && Filters.Filters) ||
      (Aggregations && Aggregations.Aggregations) ||
      (Transformations && Transformations.Transformations)
    ) {
      this.setState({ openPanels: 1 });
    }
  };

  render() {
    const {
      // StatusList,
      TrainingServerList,
      users,
      DataSourceList,
      ApplicableMetricsList,
      deviceList,
      parameterList,
      LabelParameterList,
      AlgorithmList,
      ModelsDataSource,
      FilterParameterList,
      AggregationParameterList,
      TransformationParameterList,
      // ClassificationParameterList,
      ModelTypeList,
      formObject,
      payload,
      ParameterModal,
      SelecetedModelType,
      ParametersData,
      selectedItems,
      FuturePeriodUomList,
      LagUnitList
    } = this.state;
    const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
    const ModelsColumns = [
      {
        title: this.translation('Name'),
        dataIndex: 'ModelName',
        key: 'ModelName',
        ...this.getColumnSearchProps('ModelName'),
        sorter: (a, b) => a.ModelName.localeCompare(b.ModelName)
      },
      {
        title: this.translation('Type'),
        dataIndex: 'ModelType',
        key: 'ModelType',
        ...this.getColumnSearchProps('ModelType'),
        sorter: (a, b) => a.ModelType.localeCompare(b.ModelType)
      },
      {
        title: this.translation('Group'),
        dataIndex: 'ModelGroup',
        key: 'ModelGroup',
        ...this.getColumnSearchProps('ModelGroup'),
        sorter: (a, b) => a.ModelGroup.localeCompare(b.ModelGroup)
      },
      {
        title: this.translation('Created By'),
        key: 'CreatedBy',
        dataIndex: 'CreatedBy',
        ...this.getColumnSearchProps('CreatedBy'),
        sorter: (a, b) => a.CreatedBy && b.CreatedBy && a.CreatedBy.localeCompare(b.CreatedBy)
      },
      {
        title: this.translation('Created Date'),
        key: 'CreatedDate',
        dataIndex: 'CreatedDate',
        ...this.getColumnSearchProps('CreatedDate'),
        sorter: (a, b) => new Date(a.CreatedDate) - new Date(b.CreatedDate),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Enabled'),
        dataIndex: 'Enabled',
        key: 'Enabled',
        ...this.getColumnSearchProps('Enabled'),
        sorter: (a, b) => String(a.Enabled).localeCompare(String(b.Enabled)),
        render: (text) => String(text)
      },
      {
        title: this.translation('Status'),
        dataIndex: 'Status',
        key: 'Status',
        ...this.getColumnSearchProps('Status'),
        sorter: (a, b) => a.Status.localeCompare(b.Status)
      },
      {
        title: this.translation('Action'),
        render: (text, record) => (
          <>
            <Tooltip
              title={
                record.Status === 'Deployed' || record.Status === 'Registered'
                  ? this.translation('Open Details')
                  : this.translation('Click to Register')
              }
            >
              <a
                style={{ marginRight: '5px' }}
                onClick={() => history.push(`/rubus/RbsAnalytics/ModelDetails/${record.ModelId}`)}
              >
                {record.Status === 'Deployed' || record.Status === 'Registered'
                  ? this.translation('Details')
                  : this.translation('Register')}
              </a>
            </Tooltip>
          </>
        )
      }
    ];

    const formItemLayout = { width: '100%', flex: '50%' };

    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{
            padding: '0px 30px',
            minHeight: window.innerHeight - 143
          }}
        >
          <>
            <StyledForm
              name="Training Jobs"
              theme={themeSettings}
              ref={this.mainFormRef}
              onFinish={this.FormSubmit}
              onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
              initialValues={this.state.formObject || {}}
              layout="horizontal"
              labelCol={{ flex: '200px' }}
              labelAlign="right"
              labelWrap
              wrapperCol={{ flex: 1 }}
              // validateMessages={validateMessages}
            >
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <Form.Item>
                  <Button type="primary" onClick={this.FormSave} style={{ marginRight: '8px' }}>
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.FormSubmit}
                    disabled={
                      payload === undefined ||
                      (payload && payload !== undefined && Object.keys(payload).length === 0)
                        ? true
                        : false
                    }
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>

              <div style={{ marginTop: '24px', display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  label="Name"
                  name={'Name'}
                  style={formItemLayout}
                  rules={[
                    {
                      required: true,
                      message: 'Please Provide Name'
                    }
                  ]}
                >
                  <Input
                    maxLength={10000}
                    size="large"
                    style={{ width: '70%' }}
                    placeholder="Input Training Job Name"
                  />
                </Form.Item>
                <Form.Item
                  // labelCol={{ flex: '200px' }}
                  // {...restField}
                  name={'Description'}
                  label="Description"
                  style={formItemLayout}
                  rules={[
                    {
                      required: true,
                      message: 'Please Provide Description'
                    }
                  ]}
                >
                  <Input
                    maxLength={10000}
                    size="large"
                    style={{ width: '70%' }}
                    placeholder="Input Description"
                  />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name={'Status'}
                  placeholder="Select Status"
                  style={formItemLayout}
                >
                  <Select
                    placeholder="Search Status"
                    style={{ width: '70%' }}
                    disabled={formObject && formObject.Status ? false : true}
                  >
                    {/* {StatusList &&
                      Array.isArray(StatusList) &&
                      StatusList.map((item, index) => {
                        return ( */}
                    <Option value={'Cancelled'}>Cancelled</Option>
                    {/* );
                      })} */}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Training Server"
                  name={'TrainingServerId'}
                  style={formItemLayout}
                  rules={[
                    {
                      required: true,
                      message: 'Please Select Training Server'
                    }
                  ]}
                >
                  <Select placeholder="Please Select Training Server" style={{ width: '70%' }}>
                    {TrainingServerList &&
                      Array.isArray(TrainingServerList) &&
                      TrainingServerList.map((item, index) => {
                        return (
                          <Option key={index} value={item.Id}>
                            {item.Name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item style={formItemLayout} label="Created By" name={'CreatedBy'}>
                  <Select
                    disabled
                    value={
                      this.props &&
                      this.props.userProfile &&
                      this.props.userProfile.user &&
                      this.props.userProfile.user.Id
                    }
                    suffix={<UserOutlined />}
                    size="large"
                    placeholder="Select CreatedBy"
                    style={{ width: '70%' }}
                  >
                    {users &&
                      Array.isArray(users) &&
                      users.map((item, index) => {
                        return (
                          <option key={index} value={item.Id}>
                            {item.FirstName}
                          </option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item style={formItemLayout} label="Created Date" name={'CreatedDate'}>
                  <Input
                    disabled
                    size="large"
                    style={{ width: '70%' }}
                    placeholder="CreatedDate"
                    suffix={<FieldTimeOutlined />}
                  />
                </Form.Item>
              </div>

              <StyledSubForm theme={themeSettings}>
                <StyledTabs
                  theme={themeSettings}
                  type="card"
                  style={{ color: 'black' }}
                  className="TabsText"
                  onChange={() => {
                    const pager = { ...this.state.pagination };
                    pager.current = 1;
                    this.setState({ pagination: pager });
                  }}
                >
                  <TabPane tab={this.translation('Data Set')} key="DataSet">
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '40px' }}>
                      <Form.Item
                        label={this.translation('DataSource')}
                        name="DataSourceID"
                        style={formItemLayout}
                        rules={[
                          {
                            required: true,
                            message: 'Please Select DataSource'
                          }
                        ]}
                      >
                        <Select
                          style={{ width: '70%' }}
                          placeholder={this.translation('Please Select DataSource')}
                        >
                          {DataSourceList &&
                            Array.isArray(DataSourceList) &&
                            DataSourceList.map((item, index) => {
                              return (
                                <Option key={index} value={item.Id}>
                                  {item.Name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={this.translation('Device')}
                        name="Devices"
                        rules={[{ required: true }]}
                        style={formItemLayout}
                      >
                        <Select
                          mode="multiple"
                          maxTagCount={'responsive'}
                          placeholder={this.translation('Please Select Device')}
                          style={{ width: '70%' }}
                          onChange={(value) => this.onCheck('Devices', value)}
                        >
                          {deviceList &&
                            Array.isArray(deviceList) &&
                            deviceList.map((tag) => {
                              return (
                                <Option value={tag.key} key={tag.key}>
                                  {this.translation(tag.key)}
                                </Option>
                              );
                            })}{' '}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={this.translation('Tags')}
                        name="Tags"
                        rules={[{ required: true }]}
                        style={formItemLayout}
                      >
                        <Select
                          maxTagCount="responsive"
                          mode="multiple"
                          style={{ width: '70%' }}
                          placeholder={this.translation('Please Select Tags')}
                          options={parameterList}
                          onChange={(key, value) => this.tagChnage(key, value)}
                        />
                        {/* {parameterList &&
                            Array.isArray(parameterList) &&
                            parameterList.map((tag) => {
                              return (
                                <Option value={tag.value} key={tag.key}>
                                  {this.translation(tag.value)}-{this.translation(tag.Description)}
                                </Option>
                              );
                            })}{' '}
                        </Select> */}
                      </Form.Item>
                      <Form.Item
                        label=" Train From And To Date"
                        name={'TrainDate'}
                        style={formItemLayout}
                        rules={[
                          {
                            required: true,
                            message: 'Please Select From And To Date'
                          }
                        ]}
                      >
                        <RangePicker
                          style={{ width: '70%' }}
                          showTime={{ format: 'HH:mm' }}
                          format="YYYY-MM-DD HH:mm"
                          placeholder={['Start DateTime', 'End DateTime']}
                          value={this.state.momentRange1}
                          onChange={this.onChangeTrainDate}
                          onOk={this.onhandleRangepicker}
                          disabledDate={this.disabledDate}
                        />
                      </Form.Item>
                      <Form.Item
                        label=" Validation From And To Date"
                        name={'ValidationDate'}
                        style={formItemLayout}
                        rules={[
                          {
                            required: true,
                            message: 'Please Select From And To Date'
                          }
                        ]}
                      >
                        <RangePicker
                          style={{ width: '70%' }}
                          showTime={{ format: 'HH:mm' }}
                          format="YYYY-MM-DD HH:mm"
                          placeholder={['Start DateTime', 'End DateTime']}
                          value={this.state.momentRange2}
                          onChange={this.onChangeValidationDate}
                          onOk={this.onhandleRangepicker}
                          disabledDate={this.disabledDate}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Test From And To Date"
                        name={'TestDate'}
                        style={formItemLayout}
                        rules={[
                          {
                            required: true,
                            message: 'Please Select From And To Date'
                          }
                        ]}
                      >
                        <RangePicker
                          style={{ width: '70%' }}
                          showTime={{ format: 'HH:mm' }}
                          format="YYYY-MM-DD HH:mm"
                          placeholder={['Start DateTime', 'End DateTime']}
                          value={this.state.momentRange3}
                          onChange={this.onChangeTestDate}
                          onOk={this.onhandleRangepicker}
                          disabledDate={this.disabledDate}
                        />
                      </Form.Item>
                    </div>
                  </TabPane>

                  <TabPane tab={this.translation('Pre Processing')} key="Preprocessing">
                    <div
                      style={{ display: 'flex', flexWrap: 'wrap', marginTop: '40px' }}
                      theme={themeSettings}
                    >
                      <Collapse
                        // activeKey={this.state.openPanels}
                        defaultActiveKey={['1', '2', '3']}
                        style={{ width: '1500px' }}
                        expandIcon={({ isActive }) =>
                          isActive ? <MinusCircleOutlined /> : <PlusOutlined />
                        }
                        // onChange={this.onCollapseChange}
                      >
                        <Panel header="Filter Conditions" key="1">
                          <Form
                            name="dynamic_form_nest_item"
                            onFinish={this.onFiltersSubmit}
                            autoComplete="off"
                            ref={this.FiltersRef}
                            initialValues={this.state.Filters}
                            style={{ paddingLeft: '15%' }}
                          >
                            <Form.List name="Filters">
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                      key={key}
                                      style={{
                                        display: 'flex',
                                        marginBottom: 8
                                      }}
                                      align="baseline"
                                    >
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'Device']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing Device'
                                          }
                                        ]}
                                      >
                                        <Select
                                          placeholder=" Device"
                                          // maxTagCount={1}
                                          style={{ width: '200px' }}
                                          // mode="multiple"
                                          onChange={(value) => this.onDeviceCheck('Device', value)}
                                        >
                                          {deviceList &&
                                            Array.isArray(deviceList) &&
                                            deviceList.map((item, index) => {
                                              return (
                                                <option key={index} value={item.key}>
                                                  {item.key}
                                                </option>
                                              );
                                            })}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'Tag']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing Tag'
                                          }
                                        ]}
                                      >
                                        <Select
                                          placeholder=" Tag"
                                          style={{ width: '200px' }}
                                          // maxTagCount={'responsive'}
                                          // mode="multiple"
                                          options={FilterParameterList}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'Condition']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing Condition'
                                          }
                                        ]}
                                      >
                                        <Select placeholder=" Condition" style={{ width: '200px' }}>
                                          {Operator.map((item, index) => {
                                            return (
                                              <>
                                                <Option key={index} value={item.value}>
                                                  {item.value}
                                                </Option>
                                              </>
                                            );
                                          })}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'Value']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing Value'
                                          }
                                        ]}
                                      >
                                        <Input
                                          type="number"
                                          onKeyDown={(e) => {
                                            if ([8].includes(e.keyCode)) {
                                              return;
                                            }
                                            if (
                                              (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
                                              (e.keyCode < 96 || e.keyCode > 105)
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                          placeholder=" Value"
                                          style={{ width: '200px' }}
                                        />
                                      </Form.Item>
                                      <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      style={{ width: '500px' }}
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add Filter
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </Form>
                        </Panel>
                        <Panel header="Aggregations" key="2">
                          <Form
                            name="dynamic_form_nest_item"
                            onFinish={this.onAggregationsSubmit}
                            autoComplete="off"
                            ref={this.AggregationsRef}
                            initialValues={this.state.Aggregations}
                            style={{ paddingLeft: '15%' }}
                          >
                            <Form.Item name={'Aggregations'}>
                              <Form.List name="Aggregations">
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(({ key, name, ...restField }) => (
                                      <Space
                                        key={key}
                                        style={{
                                          display: 'flex',
                                          marginBottom: 8
                                        }}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'Device']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing Device'
                                            }
                                          ]}
                                        >
                                          <Select
                                            placeholder=" Device"
                                            // maxTagCount={1}
                                            style={{ width: '200px' }}
                                            // mode="multiple"
                                            onChange={(value) =>
                                              this.onAggregationCheck('Device', value)
                                            }
                                          >
                                            {deviceList &&
                                              Array.isArray(deviceList) &&
                                              deviceList.map((item, index) => {
                                                return (
                                                  <option key={index} value={item.key}>
                                                    {item.key}
                                                  </option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'Tag']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing Tag'
                                            }
                                          ]}
                                        >
                                          <Select
                                            placeholder=" Tag"
                                            style={{ width: '200px' }}
                                            // maxTagCount={'responsive'}
                                            // mode="multiple"
                                            options={AggregationParameterList}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'Method']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing Method'
                                            }
                                          ]}
                                        >
                                          <Select placeholder="Method" style={{ width: '200px' }}>
                                            <Option value="Min">Min</Option>
                                            <Option value="Max">Max</Option>
                                            <Option value="Sum"> Sum</Option>
                                            <Option value="Mean"> Mean</Option>
                                            <Option value="Avarage"> Average</Option>
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'lag']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing lag'
                                            }
                                          ]}
                                        >
                                          <Input
                                            type="number"
                                            onKeyDown={(e) => {
                                              if ([8].includes(e.keyCode)) {
                                                return;
                                              }
                                              if (
                                                (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
                                                (e.keyCode < 96 || e.keyCode > 105)
                                              ) {
                                                e.preventDefault();
                                              }
                                            }}
                                            style={{ width: '200px' }}
                                            placeholder="lag"
                                            // onChange={this.lagOnChange}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'lag unit']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing lag unit'
                                            }
                                          ]}
                                        >
                                          <Select placeholder="lag unit" style={{ width: '200px' }}>
                                            {LagUnitList &&
                                              Array.isArray(LagUnitList) &&
                                              LagUnitList.map((item) => {
                                                return (
                                                  <Option value={item.key} key={item.key}>
                                                    {item.key}
                                                  </Option>
                                                );
                                              })}{' '}
                                          </Select>
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                      </Space>
                                    ))}
                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        style={{ width: '500px' }}
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                      >
                                        Add Aggregations
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            </Form.Item>
                          </Form>
                        </Panel>
                        <Panel header="Transformations" key="3">
                          <Form
                            name="dynamic_form_nest_item"
                            onFinish={this.onTransformationsSubmit}
                            autoComplete="off"
                            ref={this.TransformationsRef}
                            initialValues={this.state.Transformations}
                            style={{ paddingLeft: '15%' }}
                          >
                            <Form.Item name={'Transformations'}>
                              <Form.List name="Transformations">
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(({ key, name, ...restField }) => (
                                      <Space
                                        key={key}
                                        style={{
                                          display: 'flex',
                                          marginBottom: 8
                                        }}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'Device']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing Device'
                                            }
                                          ]}
                                        >
                                          <Select
                                            placeholder=" Device"
                                            // maxTagCount={1}
                                            style={{ width: '200px' }}
                                            // mode="multiple"
                                            onChange={(value) =>
                                              this.onTransformationCheck('Device', value)
                                            }
                                          >
                                            {deviceList &&
                                              Array.isArray(deviceList) &&
                                              deviceList.map((item, index) => {
                                                return (
                                                  <option key={index} value={item.key}>
                                                    {item.key}
                                                  </option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'Tag']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing Tag'
                                            }
                                          ]}
                                        >
                                          <Select
                                            placeholder=" Tag"
                                            style={{ width: '200px' }}
                                            // maxTagCount="responsive"
                                            // mode="multiple"
                                            options={TransformationParameterList}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'Method']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing Method'
                                            }
                                          ]}
                                        >
                                          <Select placeholder="Method" style={{ width: '200px' }}>
                                            <Option value="Standard Scaler">Standard Scaler</Option>
                                            <Option value="MinMax Scaler">MinMax Scaler</Option>
                                            <Option value="Normalization"> Normalization</Option>
                                          </Select>
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                      </Space>
                                    ))}
                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        style={{ width: '500px' }}
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                      >
                                        Add Transformations
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            </Form.Item>
                          </Form>
                        </Panel>
                      </Collapse>
                    </div>
                  </TabPane>
                  <TabPane tab={this.translation('Algorithm')} key="Algorithm">
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '40px' }}>
                      <Form.Item
                        name="AlgorithmId"
                        label="Algorithm Name"
                        style={formItemLayout}
                        rules={[
                          {
                            required: true,
                            message: 'Please Select Algorithm'
                          }
                        ]}
                      >
                        <Select
                          style={{ width: '70%' }}
                          placeholder={this.translation('Please Select Algorithm Name')}
                          onChange={this.algorithmChange}
                        >
                          {AlgorithmList &&
                            Array.isArray(AlgorithmList) &&
                            AlgorithmList.map((Algorithm) => {
                              return (
                                <Option value={Algorithm.Id} key={Algorithm.Id}>
                                  {Algorithm.AlgorithmName}
                                </Option>
                              );
                            })}{' '}
                        </Select>
                      </Form.Item>
                      {/* <Form.Item
                        name="HyperParameters"
                        label="Hyper Parameters"
                        style={formItemLayout}
                      >
                        <Select
                          mode="multiple"
                          maxTagCount={'responsive'}
                          style={{ width: '70%' }}
                          placeholder={this.translation('Please Select Hyper Parameter')}
                        >
                          {HyperParameterList &&
                            Array.isArray(HyperParameterList) &&
                            HyperParameterList.map((Parameter) => {
                              return (
                                <Option value={Parameter.Name} key={Parameter.Name}>
                                  {Parameter.Name}
                                </Option>
                              );
                            })}{' '}
                        </Select>
                      </Form.Item> */}
                      <Form.Item
                        label={this.translation('Hyper Parameters')}
                        name="HyperParameters"
                        style={formItemLayout}
                      >
                        <Button
                          type="dashed"
                          onClick={() => {
                            // ParametersData: { users: values.users }}
                            this.setState({
                              ParametersData: { users: this.state.HyperParametersData }
                            });
                            this.dynamicFormRef.current &&
                              this.dynamicFormRef.current.setFieldsValue({
                                users: this.state.HyperParametersData
                              });
                            this.setState({ ParameterModal: true });
                          }}
                        >
                          {'...'}
                        </Button>
                      </Form.Item>
                      <StyledModal
                        open={ParameterModal}
                        theme={themeSettings}
                        width={800}
                        closable
                        title={
                          formObject &&
                          formObject.AlgorithmData &&
                          formObject.AlgorithmData[0] &&
                          formObject.AlgorithmData[0].HyperParameters !== null
                            ? 'Update Hyper Parameters'
                            : 'Add Hyper Parameters'
                        }
                        onCancel={() => this.setState({ ParameterModal: false })}
                        footer={null}
                      >
                        <Form
                          name="dynamic_form_nest_item"
                          onFinish={this.onSubmit}
                          autoComplete="off"
                          ref={this.dynamicFormRef}
                          initialValues={ParametersData ? ParametersData : {}}
                          style={{ paddingLeft: '10%' }}
                        >
                          <Form.List name="users">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }) => (
                                  <Space
                                    key={key}
                                    style={{
                                      display: 'flex',
                                      marginBottom: 8
                                    }}
                                    align="baseline"
                                  >
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'Name']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Missing Name'
                                        }
                                      ]}
                                    >
                                      <Input placeholder=" Name" />
                                    </Form.Item>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'DefaultValue']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Missing Default Value'
                                        }
                                      ]}
                                    >
                                      <Input placeholder="Default Value" />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                  </Space>
                                ))}
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    style={{ width: '70%' }}
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                  >
                                    Add Parameter
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'right',
                              paddingLeft: '10px'
                            }}
                          >
                            <Form.Item>
                              <Button type="primary" style={{ marginRight: 8 }} htmlType="submit">
                                Submit
                              </Button>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                key={'back'}
                                style={{ marginRight: 8 }}
                                onClick={() => this.setState({ ParameterModal: false })}
                              >
                                Cancel
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                      </StyledModal>
                      <Form.Item
                        label={this.translation('Model Type')}
                        name="ModelType"
                        rules={[{ required: true }]}
                        style={formItemLayout}
                      >
                        <Select
                          style={{ width: '70%' }}
                          onChange={this.onModelTypeChange}
                          placeholder={this.translation('Please Select Model Type')}
                        >
                          {ModelTypeList &&
                            Array.isArray(ModelTypeList) &&
                            ModelTypeList.map((Model) => {
                              return (
                                <Option value={Model.key} key={Model.key}>
                                  {Model.value}
                                </Option>
                              );
                            })}{' '}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label={this.translation('Applicable Metrics')}
                        name="Metrics"
                        rules={[{ required: true }]}
                        style={formItemLayout}
                      >
                        <Select
                          mode="multiple"
                          maxTagCount={'responsive'}
                          style={{ width: '70%' }}
                          disabled={this.state.SelecetedModelType ? false : true}
                          placeholder={this.translation('Please Select Applicable Metrics')}
                        >
                          {ApplicableMetricsList &&
                            Array.isArray(ApplicableMetricsList) &&
                            ApplicableMetricsList.map((metrics) => {
                              return (
                                <Option value={metrics.value} key={metrics.key}>
                                  {metrics.key}
                                </Option>
                              );
                            })}{' '}
                        </Select>
                      </Form.Item>
                    </div>
                  </TabPane>

                  <TabPane tab={this.translation('Labeling Critieria')} key="Labelling Critieria">
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '40px' }}>
                      <Form.Item
                        label={this.translation('Device')}
                        name="TargetDevices"
                        rules={[{ required: true }]}
                        style={formItemLayout}
                      >
                        <Select
                          // mode="multiple"
                          // maxTagCount={'responsive'}
                          placeholder={this.translation('Please Select Target Device')}
                          style={{ width: '70%' }}
                          onChange={(value) => this.onCheckDevice('TargetDevices', value)}
                        >
                          {deviceList &&
                            Array.isArray(deviceList) &&
                            deviceList.map((tag) => {
                              return (
                                <Option value={tag.key} key={tag.key}>
                                  {this.translation(tag.key)}
                                </Option>
                              );
                            })}{' '}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={this.translation('Target Parameter')}
                        name="TargetParameter"
                        rules={[{ required: true }]}
                        style={formItemLayout}
                      >
                        <Select
                          // maxTagCount={'responsive'}
                          // mode="multiple"
                          style={{ width: '70%' }}
                          placeholder={this.translation('Please Select Tags')}
                          options={LabelParameterList}
                        />
                      </Form.Item>
                      <Form.Item
                        name="Aggregation"
                        label="Aggregation"
                        style={formItemLayout}
                        rules={[
                          {
                            required: true,
                            message: 'Please Select Aggregation'
                          }
                        ]}
                      >
                        <Select style={{ width: '70%' }}>
                          <Option value={'Min'}>Min</Option>
                          <Option value={'Max'}>Max</Option>
                          <Option value={'Avg'}>Avg</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={this.translation('Target From And To')}
                        name="FuturePeriodFrom"
                        rules={[{ required: true }]}
                        style={formItemLayout}
                      >
                        <Input.Group compact>
                          <Form.Item name="FuturePeriodFrom">
                            <Input
                              type="number"
                              onKeyDown={(e) => {
                                if ([8].includes(e.keyCode)) {
                                  return;
                                }
                                if (
                                  (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
                                  (e.keyCode < 96 || e.keyCode > 105)
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              style={{ textAlign: 'center', width: 65 }}
                            />
                          </Form.Item>
                          <Form.Item rules={[{ required: true }]}>
                            <Input
                              style={{
                                width: 50,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none'
                              }}
                              placeholder={this.translation('To')}
                              disabled
                            />
                          </Form.Item>
                          <Form.Item name="FuturePeriodTo">
                            <Input
                              type="number"
                              onKeyDown={(e) => {
                                if ([8].includes(e.keyCode)) {
                                  return;
                                }
                                if (
                                  (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
                                  (e.keyCode < 96 || e.keyCode > 105)
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              style={{
                                textAlign: 'center',
                                borderLeft: 0,
                                width: 70
                              }}
                              // disabled
                            />
                          </Form.Item>
                          <Form.Item rules={[{ required: true }]}>
                            <Input
                              style={{
                                width: 90,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none'
                              }}
                              placeholder={this.translation('UOM')}
                              disabled
                            />
                          </Form.Item>
                          <Form.Item name="FuturePeriodUom">
                            <Select style={{ width: 110 }}>
                              {FuturePeriodUomList &&
                                Array.isArray(FuturePeriodUomList) &&
                                FuturePeriodUomList.map((item) => {
                                  return (
                                    <Option value={item.key} key={item.key}>
                                      {item.key}
                                    </Option>
                                  );
                                })}{' '}
                            </Select>
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                      {SelecetedModelType && SelecetedModelType === 'Classification' ? (
                        <StyledBox>
                          <StyledBoxLabel>
                            {this.translation('Classification Target')}
                          </StyledBoxLabel>
                          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '50px' }}>
                            <Form
                              name="dynamic_form_nest_item"
                              onFinish={this.onClassificationSubmit}
                              autoComplete="off"
                              ref={this.ClassificationRef}
                              initialValues={this.state.Classification}
                              style={{ paddingLeft: '5%' }}
                            >
                              <Form.List name="Classification">
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map(({ key, name, ...restField }) => (
                                      <Space
                                        key={key}
                                        style={{
                                          display: 'flex',
                                          marginBottom: 8
                                        }}
                                        align="baseline"
                                      >
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'Condition']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing Condition'
                                            }
                                          ]}
                                        >
                                          <Select
                                            placeholder=" Condition"
                                            style={{ width: '200px' }}
                                          >
                                            {Operator.map((item, index) => {
                                              return (
                                                <>
                                                  <Option key={index} value={item.value}>
                                                    {item.value}
                                                  </Option>
                                                </>
                                              );
                                            })}
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'Value']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing Value'
                                            }
                                          ]}
                                        >
                                          <Input
                                            type="number"
                                            onKeyDown={(e) => {
                                              if ([8].includes(e.keyCode)) {
                                                return;
                                              }
                                              if (
                                                (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
                                                (e.keyCode < 96 || e.keyCode > 105)
                                              ) {
                                                e.preventDefault();
                                              }
                                            }}
                                            style={{ width: '200px' }}
                                            placeholder=" Value"
                                            // onChange={this.onChangeValue1}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'Class']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing Class'
                                            }
                                          ]}
                                        >
                                          <Select
                                            value={selectedItems}
                                            onChange={this.classOnChange}
                                            placeholder=" Class"
                                            style={{ width: '200px' }}
                                          >
                                            {filteredOptions.map((item) => (
                                              <Select.Option key={item} value={item}>
                                                {item}
                                              </Select.Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'label']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing label'
                                            }
                                          ]}
                                        >
                                          <Input placeholder=" label" />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                      </Space>
                                    ))}
                                    <Form.Item style={{ paddingLeft: '20%' }}>
                                      <Button
                                        type="dashed"
                                        style={{ width: '200px' }}
                                        onClick={
                                          () => {
                                            if (fields && fields.length < 2) {
                                              add();
                                            } else {
                                              message.warning('You Can add Only Two conditions');
                                            }
                                          }
                                          //  add()
                                        }
                                        block
                                        icon={<PlusOutlined />}
                                      >
                                        Add Classification Target
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                            </Form>
                          </div>
                        </StyledBox>
                      ) : null}
                    </div>
                  </TabPane>

                  <TabPane tab={this.translation('Models')} key="Models">
                    <div style={{ marginTop: '40px' }}>
                      <StyledTable
                        theme={themeSettings}
                        columns={ModelsColumns}
                        dataSource={ModelsDataSource}
                      />
                    </div>
                  </TabPane>
                </StyledTabs>
              </StyledSubForm>
            </StyledForm>
          </>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  userProfile: getUserProfile()
});
export default connect(mapStateToProps)(TrainingJobs);
