import { fromJS } from 'immutable';

import { types } from './types';

export const getDeviceTypeList = () => {
  return {
    type: types.GET_DEVICETYPE
  };
};

export const getDeviceTypeListSuccess = (deviceTypeList) => {
  return {
    type: types.GET_DEVICETYPE_SUCCESS,
    deviceTypeList
  };
};

export const saveDeviceType = (payload) => {
  return {
    type: types.SAVE_DEVICETYPE,
    payload
  };
};

export const updateDeviceType = (payload) => {
  return {
    type: types.UPDATE_DEVICETYPE,
    payload
  };
};

export const saveDeviceParamters = (payload) => {
  return {
    type: types.SAVE_DEVICEPARAMETERS,
    payload
  };
};

export const deleteDeviceParameters = (payload) => {
  return {
    type: types.DELETE_DEVICEPARAMETERS,
    payload
  };
};

export const getDeviceTypeListCM = () => {
  return {
    type: types.GET_DEVICETYPE_CM
  };
};

export const getDeviceTypeCMListSuccess = (deviceTypeList) => {
  return {
    type: types.GET_DEVICETYPE_CM_SUCCESS,
    deviceTypeList
  };
};

export const deleteDeviceType = (deviceTypeID) => {
  return {
    type: types.DELETE_DEVICETYPE,
    deviceTypeID
  };
};

const initialState = fromJS({
  deviceTypeList: [],
  deviceTypeCMList: []
});

const deviceType = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEVICETYPE_SUCCESS: {
      return state.set('deviceTypeList', action.deviceTypeList);
    }
    case types.GET_DEVICETYPE_CM_SUCCESS: {
      return state.set('deviceTypeCMList', action.deviceTypeList);
    }
    default:
      return state;
  }
};

export default deviceType;
