import React, { Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Tag } from 'antd';

import ReactEcharts from '../../Echarts/Echarts';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { ArrayOfObjectsWithAction } from '../../../Mock/MockJson';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';

class PieBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(props),
      data: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
    this.pieName = '';
  }

  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  onChartClick = (cellData) => {
    if (cellData.componentSubType === 'pie') {
      this.pieName = cellData.name;
      this.getData(this.props);
    }
  };

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData)
    };

    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            option={this.state.option}
            notMerge={true}
            lazyUpdate={true}
            onEvents={onEvents}
            style={{ height: '90%', width: '100%' }}
            className="react_for_echarts"
          />
        )}
      </Fragment>
    );
  }

  getData = async () => {
    this.requestCheck = false;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    let json = [];
    let { graphprops } = this.props;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = ArrayOfObjectsWithAction;
      this.requestCheck = true;
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection({
          graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          selectionDateLive: this.props.selectionDateLive,
          currentTimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
        this.setState({ noDataInDB });
      }
    } else {
      if (json && Array.isArray(json)) {
        let line = [];
        let pie = json;
        if (this.pieName) {
          let lineData =
            json &&
            json.filter((jsonData) => {
              return jsonData.Description === this.pieName;
            });
          line = lineData && lineData[0] ? lineData[0].Action : [];
        } else {
          line = json && json[0] && json[0]['Action'] ? json[0]['Action'] : [];
        }
        let option = cloneDeep(this.getOption());
        option.yAxis = {
          ...option.yAxis,
          data:
            line &&
            Array.isArray(line) &&
            line.map((lineData) => {
              return {
                name: lineData.Name,
                value: lineData.Value
              };
            })
        };
        option.series[0] = {
          ...option.series[0],
          data:
            line &&
            Array.isArray(line) &&
            line.map((lineData) => {
              return {
                name: lineData.Name,
                value: lineData.Value
              };
            })
        };
        option.series[1] = {
          ...option.series[1],
          data:
            pie &&
            Array.isArray(pie) &&
            pie.map((pieData) => {
              return {
                name: pieData.Description,
                value: pieData.Value
              };
            })
        };

        this.setState({
          option,
          refreshDateTime: moment(),
          noDataInDB: false
        });
      }
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
      },
      label: {
        color: theme === 'lightTheme' ? 'black' : 'white',
        fontWeight: 'bold',
        fontSize: 14
      },
      textStyle: {
        color: theme === 'lightTheme' ? 'black' : 'white',
        fontWeight: 'bold',
        fontSize: 18
      },
      legend: {
        left: 'center',
        textStyle: {
          color: theme === 'lightTheme' ? 'black' : 'white'
        }
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            name: this.props.graphprops.title,
            backgroundColor: 'black',
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      grid: [
        {
          top: 50,
          width: '50%',
          bottom: '45%',
          left: 10,
          containLabel: true
        },
        {
          top: '55%',
          width: '50%',
          bottom: 0,
          left: 10,
          containLabel: true
        }
      ],
      xAxis: [
        {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisTick: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisLabel: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          },
          splitLine: {
            show: false
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          data: [],
          axisLine: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisTick: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          gridIndex: 0,
          axisLabel: {
            interval: 0,
            rotate: 30,
            color: theme === 'lightTheme' ? 'black' : 'white'
          },
          splitLine: {
            show: false
          }
        }
      ],
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true
          },
          data: []
        },
        {
          type: 'pie',
          radius: [0, '40%'],
          center: ['70%', '50%'],
          data: []
        }
        // {
        //   type: 'pie',
        //   radius: [0, '50%'],
        //   center: ['50%', '50%'],
        //   data: []
        // },
        // {
        //   type: 'bar',
        //   stack: 'chart',
        //   z: 3,
        //   label: {
        //     position: 'center',
        //     show: true
        //   },
        //   data: []
        // }
      ]
    };

    // let option1 = {
    //   tooltip: {
    //     trigger: "item",
    //     formatter: "{b} : {c} ({d}%)",
    //   },
    //   label: {
    //     color: theme === "lightTheme" ? "black" : "white",
    //     fontWeight: "bold",
    //     fontSize: 14,
    //   },
    //   textStyle: {
    //     color: theme === "lightTheme" ? "black" : "white",
    //     fontWeight: "bold",
    //     fontSize: 18,

    //   },
    //   legend: {
    //     left: "center",
    //     textStyle: {
    //       color: theme === "lightTheme" ? "black" : "white",
    //     }
    //   },
    //   toolbox: {
    //     showTitle: false,
    //     itemSize: 25,

    //     feature: {
    //       saveAsImage: {
    //         title: "download",
    //         name: this.props.graphprops.title,

    //         backgroundColor: "black",
    //         iconStyle: {
    //           // color: "none",
    //           borderColor: "#8c8c8c",
    //           borderWidth: 2,
    //         },
    //       },
    //     },
    //   },
    //   grid: [
    //     {
    //       top: "45%",
    //       width: "100%",
    //       //bottom: '45%',
    //       left: 10,
    //       height: "50%",
    //       containLabel: true,
    //     },
    //     {
    //       top: 0,
    //       width: "100%",
    //       bottom: 0,
    //       left: 0,
    //       height: "50%",
    //       containLabel: false,
    //     },
    //   ],
    //   xAxis: {
    //     axisLine: {
    //       lineStyle: {
    //         color: theme === "lightTheme" ? "black" : "white",
    //       },
    //     },
    //     axisTick: {
    //       lineStyle: {
    //         color: theme === "lightTheme" ? "black" : "white",
    //       },
    //     },
    //     axisLabel: {
    //       color: theme === "lightTheme" ? "black" : "white",
    //     },
    //     type: "category",
    //   },
    //   yAxis: {
    //     axisLine: {
    //       lineStyle: {
    //         color: theme === "lightTheme" ? "black" : "white",
    //       },
    //     },
    //     axisTick: {
    //       lineStyle: {
    //         color: theme === "lightTheme" ? "black" : "white",
    //       },
    //     },
    //     axisLabel: {
    //       color: theme === "lightTheme" ? "black" : "white",
    //     },
    //     gridIndex: 0,
    //   },
    // };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(PieBar);
