import React, { Component } from 'react';
import { Form, Button, Select } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../../../selectors/language';

import ReactEcharts from '../Echarts/Echarts';

class ContinuousMeasurement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {
        xAxis: {
          type: 'category',
          data: [0.0, 0.15, 0.3, 0.45, 0.6, 0.75, 1.0]
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [2000, 3000, 4000, 5000, 6000, 7000],
            type: 'line'
          }
        ]
      },
      translationCache: props.translationCache || []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    };
    const buttonItemLayout = {
      wrapperCol: {
        span: 14,
        offset: 4
      }
    };
    return (
      <div style={{}}>
        <Form
          {...formItemLayout}
          layout="horizontal"
          name={this.translation('HeaderNumber_current')}
          style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
        >
          <Form.Item label={this.translation('HD')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="01">01</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={this.translation('CH')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="01">01</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button type="primary" style={{ margin: '10px' }}>
              {this.translation('Cut Off')}
            </Button>
          </Form.Item>

          <Form.Item label={this.translation('Recording Cycle')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="10">10</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={this.translation('Automatic file')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="1">1</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={this.translation('Interval statistics')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="1">1</Select.Option>
            </Select>
          </Form.Item>
        </Form>

        <div>
          <Button style={{ margin: '19px' }} type="primary">
            {this.translation('Indication of the result of measurement file')}
          </Button>
          <Button style={{ margin: '19px' }} type="primary">
            {this.translation('Setting of the result of measurement file')}
          </Button>
        </div>

        <ReactEcharts
          option={this.state.option}
          notMerge={true}
          lazyUpdate={true}
          style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
          className="react_for_echarts"
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(ContinuousMeasurement);
