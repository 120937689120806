import React from 'react';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { message, Upload, Button, Steps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import localStorage from '../../utils/localStorage';
import constant from '../../constants/config.json';
import { getTranslationCache } from '../../selectors/language';
import { TIMESCALE } from '../../commons/api';

moment.locale('English');
const { Step } = Steps;

class TableConfigurationUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      current: 0,
      translationCache: props.translationCache || []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }
  handleChange = (info) => {
    const { status } = info.file;
    // if (status !== "uploading") {
    // }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  onChange = (current) => {
    this.setState({ current });
  };

  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isCSV = file.type === 'text/csv';
    if (!isCSV) {
      message.error('You can only upload CSV file!');
    }
    if (!isLt2M) {
      message.error('CSV file must be smaller than 10MB!');
    }
    return isLt2M && isCSV;
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { current } = this.state;
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const steps = [
      {
        title: this.translation(constant.Table),

        key: 'table',
        content: (
          <Upload.Dragger
            name="file"
            action={`${TIMESCALE.TableConfig}?SiteId=${siteId}`}
            accept="text/csv"
            multiple="false"
            headers={{
              Authorization: Authorization
            }}
            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{this.translation(constant.Upload_Text)}</p>

            <p className="ant-upload-hint">1.{this.translation(constant.Upload_Desp1)}</p>
            <p className="ant-upload-hint">2.{this.translation(constant.Upload_Desp2)}</p>
          </Upload.Dragger>
        )
      }
    ];

    return (
      <div style={{ minHeight: window.innerHeight - 300 }}>
        <div>
          <Steps current={current} type="navigation" onChange={this.onChange}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                marginTop: '20px'
              }}
            ></div>
            <div className="steps-content">{steps[current].content}</div>
          </div>

          <div className="steps-action">
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => this.next()}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={() => message.success('Processing complete!')}>
                {' '}
                {this.translation(constant.Done)}
              </Button>
            )}
            {current > 0 && (
              <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                Previous
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(TableConfigurationUpload);
