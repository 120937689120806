import axios from 'axios';
import localStorage from '../../../../utils/localStorage';
import { API_MASTERCONFIGURATION, API_MENU } from '../../../../commons/api';
export async function _getMenuList(menuType) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  let orgHeaders = {
    method: 'GET',
    url: `${API_MENU.Get}?SiteId=${siteId}&Type=Structured&menuType=${menuType}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(orgHeaders)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}
export async function getMobileTypeListData() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  let orgHeaders = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GetDropdownForCompositeKey}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: 'sitemaster."SM_MobileModules"',
      ColumnName: 'Name',
      ColumnValue: 'Code',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(orgHeaders)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}
