import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getUserProfile } from '../../selectors/layout';
import { InnerContainer, TypingContainer } from './CSS/styles';
import './CSS/TypingIndicator.css';

class TypingIndicator extends React.Component {
  timeTicket = null;
  requestCheck = true;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { isTyping } = this.props;
    return (
      <>
        <TypingContainer>
          <InnerContainer>
            <div className={`typing-indicator-container ${isTyping ? 'visible' : 'hidden'}`}>
              <div className="typing-indicator-dot" />
              <div className="typing-indicator-dot" />
              <div className="typing-indicator-dot" />
            </div>
          </InnerContainer>
        </TypingContainer>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(TypingIndicator);
