import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import SubmenuComponents from './SubmenuComponents';
import { StyledSider } from './styles';
import { setMenuCollapse } from '../../../../../../modules/layout/layout.duck';

const SomeComponent = withRouter((props) => <MenuPrepare {...props} />);

export class MenuPrepare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showmore: true,
      showMenu: true,
      collapsed: true,
      recentViewList:
        JSON.parse(localStorage.getItem('recentviewed')) !== null
          ? JSON.parse(localStorage.getItem('recentviewed'))
          : [],
      listPing: [],
      frequentviewed: []
    };

    this.toggleShowRecent = this.toggleShowRecent.bind(this);
    this.handleRoutingclick = this.handleRoutingclick.bind(this);
    this.getFrequentList = this.getFrequentList.bind(this);
    this.getpingList = this.getpingList.bind(this);
    this.showsMenu = this.showsMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }
  showsMenu() {
    this.setState({ showMenu: true });
  }

  hideMenu() {
    this.setState({ showMenu: false });
  }

  toggleShowRecent() {
    this.setState({
      showmore: !this.state.showmore
    });
  }

  handleRoutingclick(menuItem) {
    this.props.history.push(menuItem.path);
  }

  AddPingItem() {}

  getpingList() {}

  getFrequentList() {}
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
    this.props.actions.setMenuCollapse(!this.state.collapsed);
  };

  render() {
    const { handleRoutingclick, AddPingItem } = this;

    const { dataRouter } = this.props;

    const { showMenu } = this.state;
    return (
      <StyledSider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.toggleCollapsed}
        style={{
          top: '4.9vh'
        }}
      >
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
          {showMenu
            ? dataRouter !== null && dataRouter !== undefined && Array.isArray(dataRouter)
              ? dataRouter
                  .filter((data) => data && data.name !== 'Application Settings')
                  .map(function (data) {
                    return SubmenuComponents(data, handleRoutingclick, AddPingItem);
                  })
              : null
            : null}
        </Menu>
      </StyledSider>
    );
  }
}

MenuPrepare.propTypes = {
  // frequentviewed: PropTypes.array,
  //  listPing: PropTypes.array,
  // recentView: PropTypes.array,
  //   dataRouter: PropTypes.array.isRequired
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setMenuCollapse
      },
      dispatch
    )
  };
}
const mapStateToProps = createStructuredSelector({});

export default connect(mapStateToProps, mapDispatchToProps)(SomeComponent);
