import React from 'react';
import { StyledComponent, StyledTable } from '../../CSS/style';
import history from '../../../../commons/history';
import themeSettings from '../../Utils/themeSettings.json';
import { Button, Input, Tooltip } from 'antd';
import '../../CSS/index.css';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment-timezone';
import { getList } from '../../Utils/FormCalls';
// import { constant } from '../../Utils/Constants';
import { RBSPREDMODEL } from '../../../../commons/api';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../selectors/language';
import { connect } from 'react-redux';
// import translation from '../../Utils/translation';
const APIList = {
  getList: `${RBSPREDMODEL.ModelWorkBenchList}`
};
class ModelsWorkbench extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      // columns: [
      //   {
      //     title: this.translation('Name'),
      //     dataIndex: 'ModelName',
      //     key: 'ModelName',
      //     ...this.getColumnSearchProps('ModelName'),
      //     sorter: (a, b) => a.ModelName.localeCompare(b.ModelName)
      //   },
      //   {
      //     title: this.translation('Type'),
      //     dataIndex: 'ModelType',
      //     key: 'ModelType',
      //     ...this.getColumnSearchProps('ModelType'),
      //     sorter: (a, b) => a.ModelType.localeCompare(b.ModelType)
      //   },
      //   {
      //     title: this.translation('Group'),
      //     dataIndex: 'ModelGroup',
      //     key: 'ModelGroup',
      //     ...this.getColumnSearchProps('ModelGroup'),
      //     sorter: (a, b) => a.ModelGroup.localeCompare(b.ModelGroup)
      //   },
      //   {
      //     title: this.translation('Created By'),
      //     key: 'CreatedName',
      //     dataIndex: 'CreatedName',
      //     ...this.getColumnSearchProps('CreatedName'),
      //     sorter: (a, b) =>
      //       a.CreatedName && b.CreatedName && a.CreatedName.localeCompare(b.CreatedName)
      //   },
      //   {
      //     title: this.translation('Created Date'),
      //     key: 'CreatedTime',
      //     dataIndex: 'CreatedTime',
      //     ...this.getColumnSearchProps('CreatedTime'),
      //     sorter: (a, b) => new Date(a.CreatedTime) - new Date(b.CreatedTime),
      //     render: (text) => {
      //       return {
      //         children:
      //           text !== undefined && text !== null ? (
      //             <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //           ) : null
      //       };
      //     }
      //   },
      //   {
      //     title: this.translation('Enabled'),
      //     dataIndex: 'Enabled',
      //     key: 'Enabled',
      //     ...this.getColumnSearchProps('Enabled'),
      //     sorter: (a, b) => String(a.Enabled).localeCompare(String(b.Enabled)),
      //     render: (text) => String(text)
      //   },
      //   {
      //     title: this.translation('Status'),
      //     dataIndex: 'Status',
      //     key: 'Status',
      //     ...this.getColumnSearchProps('Status'),
      //     sorter: (a, b) => a.Status.localeCompare(b.Status)
      //   },
      //   {
      //     title: this.translation('Action'),
      //     render: (text, record) => (
      //       <>
      //         <Tooltip
      //           title={
      //             record.Status === 'Deployed' || record.Status === 'Registered'
      //               ? this.translation('Open Details')
      //               : this.translation('Click to Register')
      //           }
      //         >
      //           <a
      //             style={{ marginRight: '5px' }}
      //             onClick={() => history.push(`/rubus/RbsAnalytics/ModelDetails/${record.ModelId}`)}
      //           >
      //             {record.Status === 'Deployed' || record.Status === 'Registered'
      //               ? this.translation('Details')
      //               : this.translation('Register')}
      //           </a>
      //         </Tooltip>
      //       </>
      //     )
      //   }
      // ],
      translationCache: props.translationCache || {}
    };
  }
  componentDidMount = async () => {
    let response = await getList(APIList.getList);
    this.setState({
      dataSource: response && Array.isArray(response) ? [...response] : []
    });
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search') + this.translation(dataIndex)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
        {/* <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
        >
          Filter
        </Button> */}
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: '' });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    const { dataSource } = this.state;
    const columns = [
      {
        title: this.translation('Name'),
        dataIndex: 'ModelName',
        key: 'ModelName',
        ...this.getColumnSearchProps('ModelName'),
        sorter: (a, b) => a.ModelName.localeCompare(b.ModelName)
      },
      {
        title: this.translation('Type'),
        dataIndex: 'ModelType',
        key: 'ModelType',
        ...this.getColumnSearchProps('ModelType'),
        sorter: (a, b) => a.ModelType.localeCompare(b.ModelType)
      },
      {
        title: this.translation('Group'),
        dataIndex: 'ModelGroup',
        key: 'ModelGroup',
        ...this.getColumnSearchProps('ModelGroup'),
        sorter: (a, b) => a.ModelGroup.localeCompare(b.ModelGroup)
      },
      {
        title: this.translation('Created By'),
        key: 'CreatedName',
        dataIndex: 'CreatedName',
        ...this.getColumnSearchProps('CreatedName'),
        sorter: (a, b) =>
          a.CreatedName && b.CreatedName && a.CreatedName.localeCompare(b.CreatedName)
      },
      {
        title: this.translation('Created Date'),
        key: 'CreatedTime',
        dataIndex: 'CreatedTime',
        ...this.getColumnSearchProps('CreatedTime'),
        sorter: (a, b) => new Date(a.CreatedTime) - new Date(b.CreatedTime),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Enabled'),
        dataIndex: 'Enabled',
        key: 'Enabled',
        ...this.getColumnSearchProps('Enabled'),
        sorter: (a, b) => String(a.Enabled).localeCompare(String(b.Enabled)),
        render: (text) => String(text)
      },
      {
        title: this.translation('Status'),
        dataIndex: 'Status',
        key: 'Status',
        ...this.getColumnSearchProps('Status'),
        sorter: (a, b) => a.Status.localeCompare(b.Status)
      },
      {
        title: this.translation('Action'),
        render: (text, record) => (
          <>
            <Tooltip
              title={
                record.Status === 'Deployed' || record.Status === 'Registered'
                  ? this.translation('Open Details')
                  : this.translation('Click to Register')
              }
            >
              <a
                style={{ marginRight: '5px' }}
                onClick={() => history.push(`/rubus/RbsAnalytics/ModelDetails/${record.ModelId}`)}
              >
                {record.Status === 'Deployed' || record.Status === 'Registered'
                  ? this.translation('Details')
                  : this.translation('Register')}
              </a>
            </Tooltip>
          </>
        )
      }
    ];
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '50px 30px', minHeight: window.innerHeight - 143 }}
        >
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Tooltip title={this.translation('Click here to register a new model')}>
              <Button
                onClick={() => history.push('/rubus/RbsAnalytics/ModelRegistration')}
                type="primary"
                style={{
                  marginBottom: 8
                }}
                size="middle"
              >
                {this.translation('Register New')}
              </Button>
            </Tooltip>
          </div>
          <StyledTable
            data-testid="workbenchList"
            theme={themeSettings}
            dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
            columns={columns}
          ></StyledTable>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ModelsWorkbench);
