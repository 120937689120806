import React, { Component } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

const { RangePicker, MonthPicker } = DatePicker;

class SelectionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {},
      selectionWidget: props.selectionWidget || {},
      mode: ['month', 'month']
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectionWidget !== prevProps.selectionWidget) {
      this.setState({ selectionWidget: this.props.selectionWidget });
    }
  }

  onDataPickerChange = (value, dateString) => {
    let { selectionWidget } = this.state;
    if (selectionWidget.payload) {
      let payload = {};
      Object.keys(selectionWidget.payload).map((payloadParams) => {
        let payloadItem = selectionWidget.payload[payloadParams];
        if (selectionWidget.payload[payloadParams].add) {
          let { value, key } = selectionWidget.payload[payloadParams].add;
          payload[`${payloadParams}`] = moment(dateString)
            .add(value, key)
            .format(payloadItem.format);
        } else if (selectionWidget.payload[payloadParams].subtract) {
          let { value, key } = selectionWidget.payload[payloadParams].add;
          payload[`${payloadParams}`] = moment(dateString)
            .subtract(value, key)
            .format(payloadItem.format);
        } else {
          payload[`${payloadParams}`] = moment(dateString).format(payloadItem.format);
        }

        if (payloadParams === 'Date') {
          payload[`${payloadParams}`] = moment(dateString).format('YYYY-MM-DD');
        }
        return {};
      });
      this.setState({ payload });
      this.props.selectionpayload(payload);
    }
  };

  // onRangePickerChange = (dateString) =>{

  // }

  onOk = (dateString) => {
    let { selectionWidget } = this.state;
    if (selectionWidget.payload) {
      let payload = {};
      Object.keys(selectionWidget.payload).map((payloadParams, key) => {
        if (key <= 1) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment(dateString[key]).format(payloadItem.format);
        }
        return {};
      });
      this.setState({ payload });
    }
  };

  disabledDate = (current) => {
    return current > moment().startOf('day');
  };

  onMonthPickerChange = (dateString) => {
    let { selectionWidget } = this.state;
    let month = moment(dateString).month();
    let year = moment(dateString).year();

    if (selectionWidget.payload) {
      let payload = {};
      Object.keys(selectionWidget.payload).map((payloadParams, key) => {
        if (key <= 1 && key === 0) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([year, month])
            .startOf('month')
            .format(payloadItem.format);
        } else if (key <= 1 && key === 1) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([year, month])
            .endOf('months')
            .format(payloadItem.format);
        }
        if (payloadParams === 'month') {
          payload[`${payloadParams}`] = moment(dateString).format('MMMM');
        }
        if (payloadParams === 'year') {
          payload[`${payloadParams}`] = year;
        }
        return {};
      });
      this.setState({ payload });
      this.props.selectionpayload(payload);
    }
  };
  handlePanelChange = (dateString, mode) => {
    let { selectionWidget } = this.state;
    let startMonth = moment(dateString[0]).month();
    let startYear = moment(dateString[0]).year();
    let endMonth = moment(dateString[1]).month();
    let endYear = moment(dateString[1]).year();

    if (selectionWidget.payload) {
      let payload = {};
      Object.keys(selectionWidget.payload).map((payloadParams, key) => {
        if (key <= 1 && key === 0) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([startYear, startMonth])
            .startOf('month')
            .format(payloadItem.format);
        } else if (key <= 1 && key === 1) {
          let payloadItem = selectionWidget.payload[payloadParams];
          payload[`${payloadParams}`] = moment([endYear, endMonth])
            .add('months', 1)
            .format(payloadItem.format);
        }
        return {};
      });
      this.setState({
        payload,
        mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
      });
    } else {
      this.setState({
        mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
      });
    }
  };

  onRangePickerChange = (selectionDate) => {
    let { selectionWidget } = this.state;
    if (selectionWidget.payload) {
      let payload = {};
      Object.keys(selectionWidget.payload).map((payloadParams) => {
        if (payloadParams === 'startDate') {
          payload['startDate'] = selectionDate[1].format(
            selectionWidget.payload['startDate'].format
          );
        }

        if (payloadParams === 'endDate') {
          payload['endDate'] = selectionDate[1].format(selectionWidget.payload['endDate'].format);
        }
        return {};
      });
      if (payload['startDate'] && payload['endDate']) {
        payload['startDate'] = selectionDate[0].format('YYYY-MM-DD');
        payload['endDate'] = selectionDate[1].format('YYYY-MM-DD');
      }
      this.setState({ payload });
      this.props.selectionpayload(payload);
    }
  };

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };

  render() {
    let { selectionWidget, mode } = this.state;
    let { displayWidget, widget, displayDateFormat, displayTimeFormat, placeholder, format } =
      selectionWidget;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          marginBottom: '15px'
        }}
      >
        {displayWidget && widget === 'date-picker' ? (
          <DatePicker
            onChange={this.onDataPickerChange}
            defaultValue={moment()}
            placeholder={placeholder}
            disabledDate={this.disabledDate}
          />
        ) : null}
        {displayWidget && widget === 'dateRange-picker' ? (
          <RangePicker
            showTime={{ format: displayTimeFormat }}
            format={displayDateFormat}
            placeholder={placeholder}
            onChange={this.onRangePickerChange}
            onOk={this.onOk}
          />
        ) : null}
        {displayWidget && widget === 'month-picker' ? (
          <MonthPicker
            placeholder="Select month"
            defaultValue={moment()}
            format={format}
            disabledDate={this.disabledDate}
            onChange={this.onMonthPickerChange}
          />
        ) : null}
        {displayWidget && widget === 'monthRange-picker' ? (
          <RangePicker
            placeholder={placeholder}
            format={format}
            mode={mode}
            //   onChange={this.onMonthRangePickerChange}
            onPanelChange={this.handlePanelChange}
          />
        ) : null}
      </div>
    );
  }
}

export default SelectionComponent;
