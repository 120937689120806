import React from 'react';
import themeSettings from '../Utils/themeSettings.json';
// import translation from '../Utils/translation';
import { StyledComponent, StyledForm, StyledSubHeading } from '../CSS/style';

import { getMetaData, submitDataSource, getDeployHistory } from '../Utils/FormCalls';
import history from '../../../commons/history';
import { getTranslationCache } from '../../../selectors/language';
// import { constant } from '../Utils/Constants';
import { RBSLOOKUPMaster, MASTERDATASOURCE } from '../../../commons/api';
import { Button, Checkbox, Form, Input, Select, message } from 'antd';
// import { EditOutlined } from "@ant-design/icons";
import { createStructuredSelector } from 'reselect';
import { getUserProfile } from '../../../selectors/layout';
import { connect } from 'react-redux';
const { Option } = Select;
const APIList = {
  getById: `${MASTERDATASOURCE.GET}`,
  getMetrics: `${RBSLOOKUPMaster.LookUpItemsGetById}`,
  createData: `${MASTERDATASOURCE.CREATE}`
};
class DataSourceMastersDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      translationCache: props.translationCache || {},
      userProfile: props && props.userProfile ? props.userProfile : {},
      typeList: {},
      subTypeList: {},
      checkedVal: 'Active',
      selectedType: '',
      edit:
        this.props &&
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.editTag,
      view:
        this.props &&
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.viewTag
    };
    this.hRef = React.createRef();
  }
  componentDidMount = async () => {
    var DataSourceData = await getDeployHistory(APIList.getMetrics, 'LookupName', 'DATABASE TYPES');
    this.setState({ typeList: DataSourceData });

    let path = history.location.pathname.split('/rubus/RbsAnalytics/DataSourceMastersDetails/');
    let Id = path && path[1] ? path[1] : null;
    if (Id && Id !== null) {
      let data = await getMetaData(APIList.getById, 'Id', Id);

      let formObject = data && data[0] ? data[0] : {};

      var subType = await getDeployHistory(APIList.getMetrics, 'LookupName', formObject.Type);
      if (formObject.Status === 'Active') {
        this.setState({ checkedVal: 'Active' });
      } else {
        this.setState({ checkedVal: 'InActive' });
      }
      if (this.hRef.current) {
        this.hRef.current.setFieldsValue({ ...formObject });
      }

      this.setState({
        formObject,
        subTypeList: subType,
        selectedType: formObject.Type
      });
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.userProfile !== prevProps.userProfile ||
      this.props.translationCache !== prevProps.translationCache
    ) {
      this.setState({
        userProfile: this.props.userProfile,
        translationCache: this.props.translationCache
      });
    }
  };

  onFormSubmit = () => {
    let { checkedVal } = this.state;
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...this.hRef.current.getFieldsValue(),
          Status: checkedVal
        };
        let response = await submitDataSource(APIList.createData, values);
        //  const Id = response && response.Id ? response.Id : null;
        //   let metaData = await getMetaData(APIList.getById, "Id", Id);
        //   this.hRef.current &&
        //   this.hRef.current.setFieldsValue(metaData);
        // this.setState({ formObject: metaData });
        message.success(response && response.message ? this.translation(response.message) : '');
        history.push('/rubus/RbsAnalytics/DataSourceMastersList');
      })
      .catch(() => {});
  };

  resetFields = () => {
    if (this.hRef.current) this.hRef.current.resetFields();
    this.setState({
      formObject: {},
      checkedVal: 'Active'
    });
  };

  onTypeSelect = async (value) => {
    var subType = await getDeployHistory(APIList.getMetrics, 'LookupName', value);
    this.setState({ subTypeList: subType, selectedType: value });
  };

  onCheckboxChange = (e) => {
    if (e.target.checked === true) {
      this.setState({ checkedVal: 'Active' });
    } else if (e.target.checked === false) {
      this.setState({ checkedVal: 'InActive' });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { formObject, edit, view, checkedVal, typeList, subTypeList, selectedType } = this.state;

    const validateMessages = {
      required: this.translation('${label}') + this.translation('is required!')
    };
    const formItemLayout = { width: '100%', flex: '50%' };

    return (
      <StyledComponent
        theme={themeSettings}
        style={{
          padding: '50px 30px',
          minHeight: window.innerHeight - 143
        }}
      >
        <StyledSubHeading theme={themeSettings}>
          {this.translation('DataSource Master')}
        </StyledSubHeading>
        <StyledForm
          name="ModelDetails"
          ref={this.hRef}
          autoComplete="off"
          initialValues={formObject}
          theme={themeSettings}
          layout="horizontal"
          labelCol={{ flex: '200px' }}
          labelAlign="right"
          onFinish={this.onFormSubmit}
          onFinishFailed={this.onFinishFailed}
          disabled={view === true ? true : false}
          labelWrap
          validateMessages={validateMessages}
          wrapperCol={{ flex: 1 }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item name="Id"></Form.Item>
            <Form.Item
              label={this.translation('DataSource Name')}
              name="Name"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input
                disabled={formObject && formObject.Name ? true : false}
                style={{ width: '70%' }}
                placeholder={this.translation('Please Enter DataSource Name')}
              ></Input>
            </Form.Item>
            <Form.Item
              label={this.translation('DataSource Code')}
              name="Code"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input
                disabled={formObject && formObject.Code ? true : false}
                style={{ width: '70%' }}
                placeholder={this.translation('Please Enter DataSource Code')}
              ></Input>
            </Form.Item>
            <Form.Item
              label={this.translation('Type')}
              name="Type"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Select
                disabled={formObject && formObject.Type ? true : false}
                style={{ width: '70%' }}
                placeholder={this.translation('Please Select Type')}
                onChange={this.onTypeSelect}
              >
                {typeList &&
                  Array.isArray(typeList) &&
                  typeList.map((type) => {
                    return (
                      <Option value={type.value} key={type.key}>
                        {type.key}
                      </Option>
                    );
                  })}{' '}
              </Select>
            </Form.Item>
            <Form.Item
              label={this.translation('Sub Type')}
              name="SourceType"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Select
                disabled={formObject && formObject.SourceType ? true : false}
                style={{ width: '70%' }}
                placeholder={this.translation('Please Select Sub Type')}
              >
                {subTypeList &&
                  Array.isArray(subTypeList) &&
                  subTypeList.map((subType) => {
                    return (
                      <Option value={subType.value} key={subType.key}>
                        {subType.key}
                      </Option>
                    );
                  })}{' '}
              </Select>
            </Form.Item>
            {selectedType && selectedType !== undefined ? (
              <>
                {selectedType && selectedType === 'DATABASE' ? (
                  <>
                    <Form.Item
                      label={this.translation('DataBase')}
                      name="DataBase"
                      rules={[{ required: true }]}
                      style={formItemLayout}
                    >
                      <Input
                        disabled={formObject && formObject.DataBase ? true : false}
                        style={{ width: '70%' }}
                        placeholder={this.translation('Please Enter DataBase')}
                      ></Input>
                    </Form.Item>
                    <Form.Item
                      label={this.translation('Host')}
                      name="Host"
                      rules={[{ required: true }]}
                      style={formItemLayout}
                    >
                      <Input
                        disabled={formObject && formObject.Host ? true : false}
                        style={{ width: '70%' }}
                        placeholder={this.translation('Please Enter Host')}
                      ></Input>
                    </Form.Item>
                    <Form.Item
                      label={this.translation('User Name')}
                      name="UserName"
                      rules={[{ required: true }]}
                      style={formItemLayout}
                    >
                      <Input
                        disabled={formObject && formObject.UserName ? true : false}
                        style={{ width: '70%' }}
                        placeholder={this.translation('Please Enter User Name')}
                      ></Input>
                    </Form.Item>
                    <Form.Item
                      label={this.translation('Password')}
                      name="Password"
                      rules={[{ required: true }]}
                      style={formItemLayout}
                    >
                      <Input.Password
                        disabled={formObject && formObject.Password ? true : false}
                        style={{ width: '70%' }}
                        placeholder={this.translation('Please Enter Password')}
                      ></Input.Password>
                    </Form.Item>
                    <Form.Item
                      label={this.translation('Port')}
                      name="Port"
                      rules={[{ required: true }]}
                      style={formItemLayout}
                    >
                      <Input
                        disabled={formObject && formObject.Port ? true : false}
                        style={{ width: '70%' }}
                        placeholder={this.translation('Please Enter Port')}
                      ></Input>
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item
                    label={this.translation('File Path')}
                    name="FilePath"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Input
                      disabled={formObject && formObject.FilePath ? true : false}
                      style={{ width: '70%' }}
                      placeholder={this.translation('Please Enter File Path')}
                    ></Input>
                  </Form.Item>
                )}
              </>
            ) : null}

            <Form.Item label={this.translation('Active')} name="Status" style={formItemLayout}>
              <Checkbox
                checked={checkedVal === 'Active' ? true : false}
                onClick={(e) => this.onCheckboxChange(e)}
              />
            </Form.Item>
          </div>

          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              <Button
                style={{ marginRight: 8 }}
                theme={themeSettings}
                type="primary"
                htmlType="submit"
                data-testid="submitButton"
              >
                {edit ? this.translation('Update') : this.translation('Submit')}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ marginRight: 8 }}
                theme={themeSettings}
                type="primary"
                onClick={() => this.resetFields()}
              >
                {this.translation('Reset')}
              </Button>
            </Form.Item>
          </div>
        </StyledForm>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(DataSourceMastersDetails);
