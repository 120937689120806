import React from 'react';

import { getTranslationCache } from '../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import themeSettings from '../../AMM/Utils/themeSettings.json';
import { getUserProfile } from '../../../selectors/layout';
import localStorage from '../../../utils/localStorage';
import { StyledComponent, StyledTable, StyledForm } from './CSS/style';
import './CSS/style.css';
// import { DrawerFooter } from '../../../commons/styles/layout';
import {
  FormUpdate,
  FormSubmission,
  getUserList,
  getRelatedTags,
  getStatusList,
  getDeployHistory,
  SubmitForm
} from '../APICalls/FormCalls';
// import Index from './index';
// import { constant } from '../Utils/Constants';
import moment from 'moment';
import { ANALYTICS } from '../../../commons/api';
import {
  Button,
  Tabs,
  Form,
  Input,
  message,
  // DatePicker,
  Select,
  Upload,
  Drawer,
  // Table,
  Tooltip,
  Badge
  // Space,
} from 'antd';
// import translation from '../Utils/translation';
import history from '../../../commons/history';
import Highlighter from 'react-highlight-words';
import CommentsForm from '../Details/CommentsForm';
import {
  EditOutlined,
  PlusOutlined,
  FieldTimeOutlined,
  UserOutlined,
  UploadOutlined,
  DownloadOutlined,
  CommentOutlined,
  SearchOutlined
} from '@ant-design/icons';
// const { Option } = Select;
const { TextArea } = Input;
const { Option } = Select;

const APIList = {
  Create: `${ANALYTICS.PredictionCreate}`,
  Update: `${ANALYTICS.PredictionUpdate}`,
  Get: `${ANALYTICS.TrainingJobGet}`,
  GetById: `${ANALYTICS.TrainingJobGetById}`,
  getById: `${ANALYTICS.PredictionGetById}`
};
const { TabPane } = Tabs;
// const { RangePicker } = DatePicker;
class Tasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      dataSource: [],
      translationCache: props.translationCache || {},
      userProfile: props && props.userProfile ? props.userProfile : {},
      StatusList: [],
      PredictionfileModalVisible: false,
      PredictionResultsFileModalVisible: false,
      addResults: false,
      isPredictionResultVisible: false,
      editAddResult: false,
      useCaseModalVisible: false,
      remarksModalVisible: false,
      PredictionResultInd: '',
      usecaseName: ''
    };
  }
  hForm = React.createRef();
  predictionRef = React.createRef();
  predictionResultRef = React.createRef();
  componentDidMount = async () => {
    const pathSnippets = history.location.pathname
      .split(`/rubus/RubusAnalytics/Tasks/`)
      .filter((i) => i);
    if (pathSnippets && pathSnippets[0]) {
      let useCaseLevel = pathSnippets[0].split('/').filter((i) => i);
      if (useCaseLevel[0] && useCaseLevel[0] !== 'New') {
        let formObject =
          history && history.location && history.location.state && history.location.state.formObject
            ? history.location.state.formObject
            : null;
        if (formObject && formObject !== null) {
          // formObject = {
          //   ...formObject,
          //   Date: [moment(formObject.Date[0]), moment(formObject.Date[1])]
          // };
          this.hForm.current &&
            this.hForm.current.setFieldsValue({
              TaskName: formObject && formObject.TaskName,
              Id: formObject && formObject.Id,
              // RelatedTags: formObject.RelatedTags,
              // Date: formObject.Date,
              CreatedBy: formObject && formObject.CreatedBy,
              AssignedBy: formObject && formObject.AssignedBy,
              Status: formObject.Status,
              Approach: formObject.Approach,
              // Algorithms: formObject.Algorithms,
              CreatedTime:
                formObject &&
                formObject.CreatedTime &&
                moment(formObject.CreatedTime).format('YYYY-MM-DD HH:mm:ss'),
              Comments: formObject && formObject.Comments,
              UseCaseName: formObject && formObject.UseCaseName,
              Description: formObject && formObject.Description,
              Attachments: formObject && formObject.Attachments
              // Results: formObject.Results
            });
          let response = await getDeployHistory(APIList.Get, 'TaskId', formObject.Id);
          let TaskId =
            history && history.location && history.location.state && history.location.state.TaskId
              ? history.location.state.TaskId
              : null;
          let AnalyticsId =
            history &&
            history.location &&
            history.location.state &&
            history.location.state.AnalyticsId
              ? history.location.state.AnalyticsId
              : null;
          this.setState({
            TaskId,
            AnalyticsId,
            dataSource: response && Array.isArray(response) ? response : [],
            formObject,
            Type: formObject && formObject.Type
          });
        }
      } else {
        let obj = {
          TaskName: null,
          Id: null,
          // RelatedTags: [],
          CreatedBy:
            this.props &&
            this.props.userProfile &&
            this.props.userProfile.user &&
            this.props.userProfile.user.Id,
          CreatedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          AssignedBy: null,
          Status: '',
          Approach: '',
          // Algorithms: '',
          Comments: [],
          UseCaseName: null,
          Description: null,
          Attachments: []
          // Results: []
        };
        this.hForm.current &&
          this.hForm.current.setFieldsValue({
            CreatedBy:
              this.props &&
              this.props.userProfile &&
              this.props.userProfile.user &&
              this.props.userProfile.user.Id,
            CreatedTime: moment().format('YYYY-MM-DD HH:mm:ss')
          });
        this.setState({
          formObject: obj,
          Type:
            history && history.location && history.location.state && history.location.state.Type
              ? history.location.state.Type
              : null
        });
      }

      // let Type =  history && history.location && history.location.state && history.location.state.Type ? history.location.state.Type : null;
      let path =
        history && history.location && history.location.state && history.location.state.path
          ? history.location.state.path
          : null;

      let AnalyticsId =
        history && history.location && history.location.state && history.location.state.AnalyticsId
          ? history.location.state.AnalyticsId
          : null;
      let list = await getUserList();
      let relatedTags = await getRelatedTags();
      let StatusList = await getStatusList();
      this.setState({
        usecaseName: useCaseLevel && useCaseLevel[0],
        users: list,
        path,
        relatedTags,
        StatusList: StatusList,
        AnalyticsId
        // Type
      });
    }
  };
  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      this.props.userProfile !== prevProps.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  _editRecord = async (record) => {
    let { formObject } = this.state;
    let payload = {
      // AnalyticsId: AnalyticsId,
      TaskId: formObject && formObject.Id,
      Id: record.Id
    };
    let data = await SubmitForm(payload, APIList.GetById);
    history.push({
      pathname: `/rubus/RubusAnalytics/TrainningJob/${record.Id}`,
      state: {
        formObject: data,
        // AnalyticsId: AnalyticsId,
        Id: data && data.Id,
        TaskId: formObject && formObject.Id,
        path: data && data.Path
      }
    });
    this.setState({ history, editData: data });
    this.props.renderForm(data && data.Path, 'edit', 'type');
  };
  addTrainingJob = () => {
    let { formObject, editData, path } = this.state;
    history.push({
      pathname: '/rubus/RubusAnalytics/TrainningJob/New',
      state: {
        // UseCaseName: record.UseCaseName,
        editData: editData,
        TaskId: formObject && formObject.Id
        // AnalyticsId: AnalyticsId
      }
    });
    this.setState({ history });
    this.props.renderForm(path, 'New', 'TrainningJob');
  };
  onChangeAddResult = (value, resultKey, resultIndex, key) => {
    const { formObject } = this.state;
    formObject[resultKey][resultIndex][key] = value;
    this.setState({ formObject });
  };

  onFinish = async () => {
    // let formObject = this.hForm.current && this.hForm.current.getFieldsValue();
    let { formObject, dataObj, Type, AnalyticsId } = this.state;
    let data = this.hForm.current && this.hForm.current.getFieldsValue();
    let value = [];
    value.push(dataObj);
    this.hForm.current &&
      this.hForm.current
        .validateFields()
        .then(async () => {
          if (formObject && formObject.AnalyticsId) {
            let payload = {
              ...data,
              Comments: formObject && formObject.Comments,
              Attachments: formObject && formObject.Attachments,
              CreatedTime:
                data &&
                data.CreatedTime &&
                moment(data.CreatedTime).utc().format('YYYY-MM-DD HH:mm:ss'),
              // RelatedTags: data.RelatedTags,
              AssignedBy: data && data.AssignedBy,
              Status: data && data.Status,
              // Results: formObject.Results,
              Type: formObject && formObject.Type
            };
            let Final = {};
            if (formObject && formObject.Type && formObject.Type === 'Prediction') {
              Final = {
                PredictionTask: [payload],
                Id: formObject.Id
              };
            } else if (formObject && formObject.Type && formObject.Type === 'Analysis') {
              Final = {
                AnalyticsTask: [payload],
                Id: formObject.Id
              };
            }
            let UpdateResponse = await FormUpdate(Final, APIList.Update);
            if (UpdateResponse && UpdateResponse.status === 200) {
              message.success(UpdateResponse && UpdateResponse.data && UpdateResponse.data.message);

              let payload = {
                AnalyticsId: this.state.AnalyticsId,
                Id: formObject.Id,
                Type: formObject && formObject.Type
              };
              let TaskData = await SubmitForm(payload, APIList.getById);
              TaskData = {
                ...TaskData,
                CreatedTime: moment(TaskData.CreatedTime).format('YYYY-MM-DD HH:mm:ss')
              };

              this.hForm.current &&
                this.hForm.current.setFieldsValue({
                  TaskName: TaskData && TaskData.TaskName,
                  Id: TaskData && TaskData.Id,
                  // RelatedTags: TaskData.RelatedTags,
                  // Date: TaskData.Date,
                  CreatedBy: TaskData && TaskData.CreatedBy,
                  AssignedBy: TaskData && TaskData.AssignedBy,
                  Status: TaskData && TaskData.Status,
                  Approach: TaskData && TaskData.Approach,
                  // Algorithms: TaskData.Algorithms,
                  CreatedTime: TaskData && TaskData.CreatedTime,
                  Comments: TaskData && TaskData.Comments,
                  UseCaseName: TaskData && TaskData.UseCaseName,
                  Description: TaskData && TaskData.Description,
                  Attachments: TaskData && TaskData.Attachments
                  // Results: TaskData.Results
                });
              history.push({
                pathname: `/rubus/RubusAnalytics/Tasks/${TaskData.Id}`
              });
              this.props.renderForm(TaskData && TaskData.Path, 'edit', 'type');
              let response = await getDeployHistory(APIList.Get, 'TaskId', TaskData.Id);
              this.setState({
                path: TaskData && TaskData.Path,
                formObject: TaskData,
                TaskId: formObject && formObject.Id,
                AnalyticsId: AnalyticsId,
                dataSource: response && Array.isArray(response) ? response : []
              });
            }
          } else {
            let payload = {
              ...data,
              Comments: formObject && formObject.Comments,
              Attachments: formObject && formObject.Attachments,
              // RelatedTags: data.RelatedTags,
              AssignedBy: data && data.AssignedBy,
              Status: data && data.Status,
              CreatedTime:
                data &&
                data.CreatedTime &&
                moment(data.CreatedTime).utc().format('YYYY-MM-DD HH:mm:ss'),
              // Results: formObject.Results,
              Type: Type
            };
            let Final = {};
            if (Type && Type === 'Prediction') {
              Final = {
                PredictionTask: [payload],
                Id: AnalyticsId
              };
            } else if (Type && Type === 'Analysis') {
              Final = {
                AnalyticsTask: [payload],
                Id: AnalyticsId
              };
            }
            let createResponse = await FormSubmission(Final, APIList.Create);
            if (createResponse && createResponse.status === 200) {
              message.success(createResponse && createResponse.data && createResponse.data.message);

              let payload = {
                AnalyticsId: AnalyticsId,
                Id: createResponse.data.Id,
                Type: this.state.Type
              };
              let TaskData = await SubmitForm(payload, APIList.getById);
              TaskData = {
                ...TaskData,
                CreatedTime: moment(TaskData.CreatedTime).format('YYYY-MM-DD HH:mm:ss')
              };

              this.hForm.current &&
                this.hForm.current.setFieldsValue({
                  TaskName: TaskData && TaskData.TaskName,
                  Id: TaskData && TaskData.Id,
                  // RelatedTags: TaskData.RelatedTags,
                  // Date: TaskData.Date,
                  CreatedBy: TaskData && TaskData.CreatedBy,
                  AssignedBy: TaskData && TaskData.AssignedBy,
                  Status: TaskData && TaskData.Status,
                  Approach: TaskData && TaskData.Approach,
                  // Algorithms: TaskData.Algorithms,
                  CreatedTime: TaskData && TaskData.CreatedTime,
                  Comments: TaskData && TaskData.Comments,
                  UseCaseName: TaskData && TaskData.UseCaseName,
                  Description: TaskData && TaskData.Description,
                  Attachments: TaskData && TaskData.Attachments
                  // Results: TaskData.Results
                });
              history.push({
                pathname: `/rubus/RubusAnalytics/Tasks/${createResponse.data.Id}`
              });
              this.props.renderForm(TaskData && TaskData.Path, 'edit', 'type');
              let response = await getDeployHistory(APIList.Get, 'TaskId', TaskData.Id);
              this.setState({
                path: TaskData && TaskData.Path,
                formObject: TaskData,
                TaskId: createResponse.data.Id,
                AnalyticsId: AnalyticsId,
                dataSource: response && Array.isArray(response) ? response : []
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  onChange = (value, dateString) => {
    this.setState({
      range: [dateString[0], dateString[1]],
      momentRange: [moment(dateString[0]), moment(dateString[1])]
    });
  };
  disabledDate = (current) => {
    return current > moment().endOf('day');
  };

  fileUpload = (info, key, index) => {
    let { formObject, Type, AnalyticsId } = this.state;
    // let formObject = this.hForm.current.getFieldsValue();
    if (info.file.status === 'done') {
      let id =
        info && info.file && info.file.response && info.file.response && info.file.response.Id;
      let Name =
        info && info.file && info.file.response && info.file.response && info.file.response.Name;
      if (formObject && formObject[key] && formObject[key][index]) {
        if (formObject[key][index]['Attachments']) {
          formObject[key][index]['Attachments'] =
            this.state.formObject &&
            this.state.formObject[key] &&
            this.state.formObject[key][index] &&
            this.state.formObject[key][index]['Attachments']
              ? this.state.formObject[key][index]['Attachments']
              : [];

          formObject[key][index]['Attachments'].push({
            Id: id,
            file: Name,
            Type: Type,
            AnalyticsId: AnalyticsId
          });
        } else {
          formObject[key][index]['Attachments'] = [];
          formObject[key][index]['Attachments'].push({
            Id: id,
            file: Name,
            Type: Type,
            AnalyticsId: AnalyticsId
          });
        }
      } else {
        if (formObject['Attachments']) {
          formObject['Attachments'] =
            this.state.formObject && this.state.formObject['Attachments']
              ? this.state.formObject['Attachments']
              : [];
          formObject['Attachments'].push({
            Id: id,
            file: Name,
            Type: Type,
            AnalyticsId: AnalyticsId
          });
        } else {
          formObject['Attachments'] = [];
          formObject['Attachments'].push({
            Id: id,
            file: Name,
            Type: Type,
            AnalyticsId: AnalyticsId
          });
        }
      }
      this.setState({ formObject });
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  handlePredictionTasksClick = (e) => {
    // let { formObject } = this.state;
    if (e === 'Add New Attachments') {
      this.predictionRef.current && this.predictionRef.current.click();
    } else {
      this.setState({
        PredictionfileModalVisible: true
      });
    }
  };

  handleOkPrediction = () => {
    this.setState({
      isPredictionResultVisible: false,
      addResults: true,
      editAddResult: true
      // remarksModalVisible:true
    });
  };
  handleCancelPrediction = () => {
    this.setState({
      isPredictionResultVisible: false,
      addResults: true
    });
  };
  editResults = (item, itemId, resInd, key) => {
    if (key === 'EditAddResult') {
      this.setState({
        isPredictionResultVisible: true,
        PredictionResultInd: resInd
      });
    }
  };
  deleteResult = (val, key, index) => {
    const { formObject } = this.state;
    // if (key === 'EditAddResult') {
    let newArray = formObject.Results.filter((item, i) => i !== index);
    this.setState({
      formObject: {
        // ...formObject,
        formObject: [{ ...formObject, Results: newArray }]
      }
    });
    // }
  };
  add3Dots = (text, limit) => {
    let dots = '...';
    if (text.length > limit) {
      text = text.substring(0, limit) + dots;
    }
    return text;
  };
  addPredictionResults = () => {
    let { formObject } = this.state;
    if (formObject && !formObject.Results) {
      formObject['Results'] = [];
    }
    let useCaseobj1 = {
      // ...formObject,
      Description: '',
      CreatedBy:
        this.props &&
        this.props.userProfile &&
        this.props.userProfile.user &&
        this.props.userProfile.user.Id,
      CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      Comments: [],
      Attachments: []
    };
    formObject && formObject.Results && formObject.Results.push(useCaseobj1);
    this.setState({
      formObject: { ...formObject },
      addResults: true,
      editAddResult: false,
      isPredictionResultVisible: true
    });
  };
  useCaseModalClose = () => {
    this.setState({ useCaseModalVisible: false });
  };
  resultsModalClose = () => {
    this.setState({ remarksModalVisible: false });
  };
  SubmitComments = (value, key, index) => {
    // let formObject = this.hForm.current.getFieldValue();
    const { formObject } = this.state;
    if (formObject && formObject[key] && formObject[key][index]) {
      if (formObject[key][index]['Comments'] !== undefined) {
        formObject[key][index]['Comments'].push(
          ...value.filter((item) => {
            return item.Id === undefined;
          })
        );
        formObject[key][index]['Comments'] = formObject[key][index]['Comments'].filter(
          (obj, Index) => {
            return (
              Index ===
              formObject[key][index]['Comments'].findIndex((o) => obj.Comment === o.Comment)
            );
          }
        );
      } else {
        formObject[key][index]['Comments'] = value;
      }
    } else if (formObject && formObject[key] && formObject[key][index]) {
      if (
        formObject[key][index]['Comments'] !== undefined &&
        Array.isArray(formObject[key][index]['Comments'])
      ) {
        formObject[key][index]['Comments'].push(
          ...value.filter((item) => {
            return item.Id === undefined;
          })
        );
        formObject[key][index]['Comments'] = formObject[key][index]['Comments'].filter(
          (obj, Index) => {
            return (
              Index ===
              formObject[key][index]['Comments'].findIndex((o) => obj.Comment === o.Comment)
            );
          }
        );
      } else {
        formObject[key][index]['Comments'] = value;
      }
    } else {
      if (formObject['Comments'] !== undefined) {
        formObject['Comments'].push(
          ...value.filter((item) => {
            return item.Id === undefined;
          })
        );
        formObject['Comments'] = formObject['Comments'].filter((obj, Index) => {
          return Index === formObject['Comments'].findIndex((o) => obj.Comment === o.Comment);
        });
      } else {
        formObject['Comments'] = value;
      }
    }
    this.setState({
      formObject,
      useCaseModalVisible: false,
      remarksModalVisible: false
    });
    // this.hForm.current.setFieldsValue(formObject);
  };
  handlePredictionResultsClick = (e, key) => {
    let { formObject } = this.state;
    if (e === 'Add New Attachments') {
      this.predictionResultRef.current.click();
    } else {
      this.setState({
        PredictionResultsFileModalVisible: true,
        PredictionResultsdataSource:
          formObject &&
          formObject['Results'] &&
          formObject['Results'][key] &&
          formObject['Results'][key]['Attachments']
            ? [...formObject['Results'][key]['Attachments']]
            : []
      });
    }
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        {/* <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
        >
          Filter
        </Button> */}
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: '' });
  };
  render() {
    const {
      dataSource,
      formObject,
      users,
      StatusList,
      PredictionfileModalVisible,
      useCaseModalVisible,
      Type
      // resultsModalClose,
    } = this.state;
    let userName =
      this.props &&
      this.props.userProfile &&
      this.props.userProfile.user &&
      this.props.userProfile.user.Id;
    const columns = [
      {
        title: this.translation('Training Job Name'),
        dataIndex: 'Name',
        key: 'Name',
        ...this.getColumnSearchProps('Name')
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Description',
        key: 'Description'
      },
      {
        title: this.translation('Action'),
        width: '600',
        render: (text, record) => (
          <>
            <Button
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => {
                this._editRecord(record);
              }}
              size="middle"
            >
              <Tooltip title={this.translation('Edit')}>
                <EditOutlined />
              </Tooltip>
            </Button>
          </>
        )
      }
    ];

    const columnsPrediction = [
      {
        title: 'File',
        dataIndex: 'file',
        key: 'file',
        align: 'center'
      },
      {
        title: 'Action',
        width: '600',
        align: 'center',
        render: (text, record) => (
          <>
            <Tooltip placement="top" key="download" title="Download">
              <div style={{ marginLeft: '-25px' }}>
                <a href={`http://192.168.95.23:3001/api/IOTAAttachments/${record.file}`}>
                  {' '}
                  <DownloadOutlined style={{ color: '#1a91ff' }} />
                </a>
              </div>
            </Tooltip>
          </>
        )
      }
    ];
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const formItemLayout = { width: '100%', flex: '50%' };
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{
            padding: '0px 30px',
            minHeight: window.innerHeight - 143
          }}
        >
          <>
            <StyledForm
              name="Training Jobs"
              theme={themeSettings}
              ref={this.hForm}
              onFinish={this.onFinish}
              onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
              initialValues={formObject || {}}
              layout="horizontal"
              labelCol={{ flex: '200px' }}
              labelAlign="right"
              labelWrap
              wrapperCol={{ flex: 1 }}
              // validateMessages={validateMessages}
            >
              <div style={{ marginTop: '24px', display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  labelCol={{ flex: '200px' }}
                  label="Title"
                  name={'TaskName'}
                  style={formItemLayout}
                  rules={[
                    {
                      required: true,
                      message: 'Please Provide Title'
                    }
                  ]}
                >
                  <Input
                    maxLength={10000}
                    size="large"
                    style={{ width: '70%' }}
                    placeholder="Input Title"
                  />
                </Form.Item>
                <Form.Item
                  label="Assigne"
                  name={'AssignedBy'}
                  placeholder="Select 'Assigne'"
                  style={formItemLayout}
                >
                  <Select placeholder="Search Assigne" style={{ width: '70%' }}>
                    {users &&
                      Array.isArray(users) &&
                      users.map((item, index) => {
                        return (
                          <Option key={index} value={item.Id}>
                            {item.UserName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  labelCol={{ flex: '200px' }}
                  name={'Description'}
                  label="Functional Description"
                  style={formItemLayout}
                  rules={[
                    {
                      required: true,
                      message: 'Please Provide FunctionalDescription'
                    }
                  ]}
                >
                  <TextArea
                    maxLength={10000}
                    style={{
                      height: 100,
                      width: '70%'
                    }}
                    placeholder="Input FunctionalDescription"
                  />
                </Form.Item>
                <Form.Item
                  labelCol={{ flex: '200px' }}
                  label="Approach"
                  name={'Approach'}
                  style={formItemLayout}
                  rules={[
                    {
                      required: true,
                      message: 'Please Provide Approach'
                    }
                  ]}
                >
                  <TextArea
                    maxLength={10000}
                    style={{
                      height: 100,
                      width: '70%'
                    }}
                    placeholder="Input Approach"
                  />
                </Form.Item>
                {/* <Form.Item
                  label="Related Tags"
                  name={'RelatedTags'}
                  placeholder="Select Tags"
                  style={formItemLayout}
                >
                  <Select
                    maxTagCount="responsive"
                    mode="multiple"
                    placeholder="Search RelatedTags"
                    style={{ width: '70%' }}
                  >
                    {relatedTags &&
                      Array.isArray(relatedTags) &&
                      relatedTags.map((item, index) => {
                        return (
                          <Option key={index} value={item.Value}>
                            {item.Key}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item> */}

                <Form.Item
                  label="Status"
                  name={'Status'}
                  placeholder="Select Status"
                  style={formItemLayout}
                >
                  <Select
                    placeholder="Search Status"
                    style={{ width: '70%' }}
                    disabled={formObject && formObject.Status ? false : true}
                  >
                    {StatusList &&
                      Array.isArray(StatusList) &&
                      StatusList.map((item, index) => {
                        return (
                          <Option key={index} value={item.Key}>
                            {item.Key}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                {/* <Form.Item
                  label="From And To Date"
                  name={'Date'}
                  style={formItemLayout}
                  rules={[
                    {
                      required: true,
                      message: 'Please Select From And To Date'
                    }
                  ]}
                >
                  <RangePicker
                    style={{ width: '70%' }}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={['Start DateTime', 'End DateTime']}
                    value={this.state.momentRange}
                    onChange={this.onChange}
                    onOk={this.onhandleRangepicker}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item> */}

                {/* <Form.Item
                  // labelCol={{ flex: '200px' }}
                  label="Algorithms"
                  name={'Algorithms'}
                  style={formItemLayout}
                  rules={[
                    {
                      required: true,
                      message: 'Please Provide Algorithms'
                    }
                  ]}
                >
                  <Input
                    maxLength={10000}
                    size="large"
                    style={{ width: '70%' }}
                    placeholder="Input Algorithms"
                  />
                </Form.Item> */}
                <Form.Item
                  // labelCol={{ flex: '130px' }}
                  style={formItemLayout}
                  name={'Attachments'}
                  // name={['PredictionTasks', Index, 'Attachments']}
                  label="Attachments"
                >
                  <Select
                    size="large"
                    style={{ width: '70%' }}
                    placeholder={'Add/View Attachments'}
                    onChange={(e) => this.handlePredictionTasksClick(e)}
                  >
                    <Option key={'View Attachments'} value={'View Attachments'}>
                      View Attachments
                    </Option>
                    <Option key={'Add New Attachments'} value={'Add New Attachments'}>
                      Add New Attachments
                    </Option>
                  </Select>
                  <Upload
                    multiple={true}
                    onChange={(info) => this.fileUpload(info)}
                    action={`${ANALYTICS.FileUpload}?SiteId=${siteId}&Type=${'Task'}`}
                    headers={{
                      Authorization: Authorization
                    }}
                    showUploadList={false}
                  >
                    <Button
                      style={{ display: 'none' }}
                      ref={this.predictionRef}
                      className="uploadFile"
                    >
                      <UploadOutlined /> Upload
                    </Button>
                  </Upload>
                  <Drawer
                    title={
                      Type && Type === 'Prediction'
                        ? 'Prediction Task Uploaded Files'
                        : 'Analysis Task Uploaded Files'
                    }
                    className="PredictionTaskUploadedFiles"
                    open={PredictionfileModalVisible}
                    width={600}
                    onOk={() =>
                      this.setState({
                        PredictionfileModalVisible: false
                      })
                    }
                    onClose={() =>
                      this.setState({
                        PredictionfileModalVisible: false
                      })
                    }
                  >
                    <StyledTable
                      theme={themeSettings}
                      columns={columnsPrediction}
                      dataSource={
                        formObject &&
                        formObject.Attachments &&
                        Array.isArray(formObject.Attachments) &&
                        formObject.Attachments
                          ? [...formObject.Attachments]
                          : []
                      }
                    />
                  </Drawer>
                </Form.Item>
                <Form.Item style={formItemLayout} label="Comments" name={'Comments'}>
                  <Tooltip
                    placement="topLeft"
                    color={'red'}
                    title="Click Here To Add Your Comments"
                  >
                    <Badge
                      count={formObject && formObject['Comments'] && formObject['Comments'].length}
                    >
                      <Button
                        style={{ background: '#eabe6f', color: 'black' }}
                        onClick={() => this.setState({ useCaseModalVisible: true })}
                      >
                        <CommentOutlined />
                        Add/View Comments
                      </Button>
                    </Badge>
                  </Tooltip>
                  {useCaseModalVisible && useCaseModalVisible === true ? (
                    <CommentsForm
                      Comments={
                        formObject && formObject['Comments'] && formObject['Comments'] !== undefined
                          ? [...formObject['Comments']]
                          : []
                      }
                      Title={'UseCase Comments'}
                      visible={useCaseModalVisible}
                      modalClose={this.useCaseModalClose}
                      user={userName}
                      Type={Type}
                      AnalyticsId={this.state.AnalyticsId}
                      submitComments={this.SubmitComments}
                    />
                  ) : null}
                </Form.Item>
                <Form.Item style={formItemLayout} label="Created By" name={'CreatedBy'}>
                  <Select
                    disabled
                    value={
                      this.props &&
                      this.props.userProfile &&
                      this.props.userProfile.user &&
                      this.props.userProfile.user.Id
                    }
                    suffix={<UserOutlined />}
                    size="large"
                    placeholder="Select CreatedBy"
                    style={{ width: '70%' }}
                  >
                    {users &&
                      Array.isArray(users) &&
                      users.map((item, index) => {
                        return (
                          <option key={index} value={item.Id}>
                            {item.FirstName}
                          </option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item style={formItemLayout} label="Created Date" name={'CreatedTime'}>
                  <Input
                    disabled
                    size="large"
                    style={{ width: '30%' }}
                    placeholder="CreatedDate"
                    suffix={<FieldTimeOutlined />}
                  />
                </Form.Item>
                {/* <Form.Item style={{ paddingLeft: '200px' }}>
                  <Button
                    style={{
                      background: '#29415a',
                      color: '#fff'
                      // paddingLeft:'200px'
                      // width: '70%'
                    }}
                    onClick={() => this.addPredictionResults()}
                  >
                    <PlusOutlined /> Add Results
                  </Button>

                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}
                  >
                    {formObject && formObject.Results && this.state.editAddResult === true
                      ? formObject.Results.map((predicResultKey, ind) => {
                          return (
                            <Card
                              key={predicResultKey.title}
                              style={{
                                width: '410px',
                                padding: '5px',
                                margin: '15px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                <div
                                  style={{
                                    padding: '0px 0px 6px 0px',
                                    fontSize: '18px',
                                    fontWeight: 'bolder',
                                    color: '#d98e04'
                                  }}
                                >
                                  {this.add3Dots(predicResultKey.Description, 35)}
                                </div>

                                <div
                                  style={{
                                    margin: '0px 0px 7px 0px'
                                  }}
                                >
                                  <IconText
                                    icon={UserOutlined}
                                    text={
                                      this.props &&
                                      this.props.userProfile &&
                                      this.props.userProfile.user &&
                                      this.props.userProfile.user.FirstName
                                    }
                                    key="list-vertical-star-o"
                                  />
                                </div>
                                <div
                                  style={{
                                    margin: '0px 0px 7px 0px'
                                  }}
                                >
                                  <IconText
                                    icon={CalendarOutlined}
                                    text={moment().format('DD-MM-YYYY HH:mm:ss')}
                                    key="list-vertical-like-o"
                                  />
                                </div>
                              </div>
                              <Divider
                                className="customDividerHor"
                                style={{
                                  borderTop: '1px solid #000',
                                  height: '0px'
                                }}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: '0px 0px',
                                  fontSize: '18px'
                                }}
                              >
                                <Tooltip placement="top" key="Edit" title="Edit">
                                  <EditOutlined
                                    style={{
                                      color: '#d98e04',
                                      marginRight: '8px'
                                    }}
                                    onClick={() =>
                                      this.editResults(
                                        predicResultKey,
                                        predicResultKey.Id,
                                        ind,
                                        'EditAddResult'
                                      )
                                    }
                                  />
                                </Tooltip>
                                <Divider type="vertical" />
                                <Popconfirm
                                  key="1"
                                  title="Are you sure that you want to delete the Result"
                                  icon={
                                    <QuestionCircleOutlined
                                      style={{
                                        color: '#d98e04'
                                      }}
                                    />
                                  }
                                  onConfirm={() => this.deleteResult('', 'EditAddResult', ind)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Tooltip placement="top" key="Delete" title="Delete">
                                    <DeleteOutlined
                                      style={{
                                        color: '#d98e04',
                                        marginRight: '8px'
                                      }}
                                    />
                                  </Tooltip>
                                </Popconfirm>
                              </div>
                            </Card>
                          );
                        })
                      : null}
                  </div>

                  {formObject.Results !== 0 &&
                    formObject.Results !== undefined &&
                    formObject.Results.map((PrectictionKe, resultIndex1) => (
                      <>
                        {this.state.PredictionResultInd === resultIndex1 || addResults === true ? (
                          <StyledModal
                            title="Add Result"
                            className="AddResultDrawer"
                            open={isPredictionResultVisible}
                            onOk={this.handleOkPrediction}
                            onCancel={this.handleCancelPrediction}
                          >
                            <CommentsForm
                              visible={remarksModalVisible}
                              modalClose={this.resultsModalClose}
                              mainkey={'PredictionTasks'}
                              Title={'PredictionTaskResult Comments'}
                              index={resultIndex1}
                              keyName={'Results'}
                              user={userName}
                              Type={Type}
                              Comments={
                                formObject &&
                                formObject['Results'] &&
                                formObject['Results'][resultIndex1] &&
                                formObject['Results'][resultIndex1]['Comments'] !== undefined
                                  ? formObject['Results'][resultIndex1]['Comments']
                                  : []
                              }
                              submitComments={this.SubmitComments}
                            />

                            <Form.Item
                              labelCol={{ flex: '130px' }}
                              label="Description"
                              name={['Results', resultIndex1, 'Description']}
                              style={{ flex: '100%' }}
                              onChange={(e) =>
                                this.onChangeAddResult(
                                  e.target.value,
                                  'Results',
                                  resultIndex1,
                                  'Description'
                                )
                              }
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: 'Please Provide Description'
                              //   }
                              // ]}
                            >
                              <TextArea
                                maxLength={10000}
                                style={{
                                  width: '70%',
                                  height: 100
                                }}
                                placeholder="Input Description"
                              />
                            </Form.Item>
                            <Form.Item
                              labelCol={{ flex: '130px' }}
                              label="Observation"
                              name={['Results', resultIndex1, 'Observation']}
                              style={{ flex: '100%' }}
                              onChange={(e) =>
                                this.onChangeAddResult(
                                  e.target.value,
                                  'Results',
                                  resultIndex1,
                                  'Observation'
                                )
                              }
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: 'Please Provide Observation'
                              //   }
                              // ]}
                            >
                              <TextArea
                                maxLength={10000}
                                style={{
                                  width: '70%',
                                  height: 100
                                }}
                                placeholder="Input Observation"
                              />
                            </Form.Item>
                            <Form.Item
                              labelCol={{ flex: '130px' }}
                              style={{ flex: '70%' }}
                              label="CreatedBy"
                              // name={['Results', resultIndex1, 'CreatedBy']}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: 'Value Missing'
                              //   }
                              // ]}
                            >
                              <Select
                                disabled
                                size="large"
                                placeholder="Select CreatedBy"
                                value={
                                  this.props &&
                                  this.props.userProfile &&
                                  this.props.userProfile.user &&
                                  this.props.userProfile.user.Id
                                }
                                style={{ width: '70%' }}
                              >
                                {users &&
                                  Array.isArray(users) &&
                                  users.map((item) => {
                                    return (
                                      <option key={item.Id} value={item.Id}>
                                        {item.FirstName}
                                      </option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              labelCol={{ flex: '130px' }}
                              style={{ flex: '70%' }}
                              label="CreatedDate"
                              // name={'CreatedTime'}
                              // name={['Results', resultIndex1, 'CreatedTime']}
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: 'Value Missing'
                              //   }
                              // ]}
                            >
                              <Input
                                disabled
                                value={moment().format('YYYY-MM-DD HH:mm:ss')}
                                size="large"
                                style={{ width: '70%' }}
                                placeholder="CreatedDate"
                                suffix={<FieldTimeOutlined />}
                              />
                            </Form.Item>
                            <Form.Item
                              labelCol={{ flex: '130px' }}
                              style={{ flex: '70%' }}
                              label="Evaluation Metric"
                              name={['Results', resultIndex1, 'EvaluationMetric']}
                              onChange={(e) =>
                                this.onChangeAddResult(
                                  e.target.value,
                                  'Results',
                                  resultIndex1,
                                  'EvaluationMetric'
                                )
                              }
                              // rules={[
                              //   {
                              //     required: false,
                              //     message: 'Value Missing'
                              //   }
                              // ]}
                            >
                              <Input
                                style={{
                                  width: '70%'
                                }}
                                placeholder="Input Evaluation Metric"
                              />
                            </Form.Item>
                            <Form.Item
                              labelCol={{ flex: '130px' }}
                              style={{ flex: '70%' }}
                              name={['Results', resultIndex1, 'MetricValue']}
                              label="Metric Value"
                              onChange={(e) =>
                                this.onChangeAddResult(
                                  e.target.value,
                                  'Results',
                                  resultIndex1,
                                  'MetricValue'
                                )
                              }
                              // rules={[
                              //   {
                              //     required: false,
                              //     message: 'Value Missing'
                              //   }
                              // ]}
                            >
                              <Input
                                style={{
                                  width: '70%'
                                }}
                                placeholder="Input Metric Value"
                              />
                            </Form.Item>
                            <Form.Item
                              labelCol={{ flex: '130px' }}
                              style={{ flex: '70%' }}
                              name={['Results', resultIndex1, 'Attachments']}
                              label="Attachments"
                            >
                              <Select
                                size="large"
                                style={{ width: '70%' }}
                                placeholder={'Add/View Attachments'}
                                onChange={(e) =>
                                  this.handlePredictionResultsClick(
                                    e,
                                    // Index,
                                    resultIndex1
                                  )
                                }
                              >
                                <Option key={'View Attachments'} value={'View Attachments'}>
                                  View Attachments
                                </Option>
                                <Option key={'Add New Attachments'} value={'Add New Attachments'}>
                                  Add New Attachments
                                </Option>
                              </Select>
                              <Upload
                                style={{
                                  width: '90%',
                                  marginRight: '5px'
                                }}
                                multiple={true}
                                onChange={(info) =>
                                  this.fileUpload(
                                    info,
                                    'Results',
                                    resultIndex1
                                    // 'PredictionTasks'
                                    // Index
                                  )
                                }
                                action={`${
                                  ANALYTICS.FileUpload
                                }?SiteId=${siteId}&Type=${'Results'}`}
                                headers={{
                                  Authorization: Authorization
                                }}
                                showUploadList={false}
                              >
                                <Button
                                  style={{
                                    display: 'none'
                                  }}
                                  ref={this.predictionResultRef}
                                  className="uploadFile"
                                >
                                  <UploadOutlined /> Upload
                                </Button>
                              </Upload>
                              <Drawer
                                title="PredictionTask Results Uploaded Files"
                                className="PredictionTaskResultsUploadedFiles"
                                open={PredictionResultsFileModalVisible}
                                width={600}
                                onOk={() =>
                                  this.setState({
                                    PredictionResultsFileModalVisible: false
                                  })
                                }
                                onClose={() =>
                                  this.setState({
                                    PredictionResultsFileModalVisible: false
                                  })
                                }
                              >
                                <StyledTable
                                  theme={themeSettings}
                                  columns={columnsPrediction}
                                  dataSource={PredictionResultsdataSource}
                                />
                              </Drawer>
                            </Form.Item>
                            <Form.Item
                              labelCol={{ flex: '130px' }}
                              style={{ flex: '70%' }}
                              label="Comments"
                              name={'Comments'}
                            >
                              <Tooltip
                                placement="topLeft"
                                color={'red'}
                                title="Click Here To Add Your Comments"
                              >
                                <Badge
                                  count={
                                    formObject &&
                                    formObject['Results'][resultIndex1] &&
                                    formObject['Results'][resultIndex1]['Comments'] &&
                                    formObject['Results'][resultIndex1]['Comments'].length
                                  }
                                >
                                  <Button
                                    style={{
                                      background: '#eabe6f',
                                      color: 'black'
                                    }}
                                    onClick={() => {
                                      let resultsCommentsData = {
                                        Comments:
                                          formObject &&
                                          formObject['Results'] &&
                                          formObject['Results'][resultIndex1] &&
                                          formObject['Results'][resultIndex1]['Comments'] !==
                                            undefined
                                            ? [...formObject['Results'][resultIndex1]['Comments']]
                                            : [],
                                        index: resultIndex1
                                      };
                                      this.setState({
                                        remarksModalVisible: true,
                                        resultsCommentsData
                                      });
                                    }}
                                  >
                                    <CommentOutlined />
                                    Add/View Comments
                                  </Button>
                                </Badge>
                              </Tooltip>
                            </Form.Item>
                          </StyledModal>
                        ) : null}
                      </>
                    ))}
                </Form.Item> */}
              </div>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <Form.Item>
                  <Button type="primary" onClick={this.onFinish}>
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </StyledForm>
            {/* <div style={{ float: 'right' }}> */}
            <Tabs defaultActiveKey="1" type="card" style={{ color: 'black' }}>
              <TabPane tab="Training Jobs" key="1">
                {formObject && formObject.Id && formObject.Id !== undefined ? (
                  <div style={{ float: 'right' }}>
                    <Tooltip title={this.translation('Add Training Job')}>
                      <PlusOutlined
                        onClick={() => this.addTrainingJob()}
                        style={{
                          fontSize: '25px',
                          color: 'rgb(255, 108, 0)',
                          marginLeft: '10px',
                          paddingBottom: '20px',
                          paddingRight: '20px'
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : null}
                <div>
                  <StyledTable
                    theme={themeSettings}
                    dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
                    columns={columns}
                  />
                </div>
              </TabPane>
            </Tabs>
          </>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  userProfile: getUserProfile()
});
export default connect(mapStateToProps)(Tasks);
