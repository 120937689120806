import React from 'react';
import moment from 'moment';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { DatePicker, Drawer, Table } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import localStorage from '../../../utils/localStorage';
import ReactEcharts from '../Echarts/Echarts';
import NoRecordComponent from '../Utils/NoRecords';
import { getCurrentTimezone } from '../../../selectors/layout';
import { getTranslationCache } from '../../../selectors/language';
import { API_FILE, API_TRENDS, IPAddress, REPORTS } from '../../../commons/api';

const { RangePicker } = DatePicker;

class Line extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      range: [],
      celd: [],
      datasource: [],
      visible: false,
      language: props.language || 'English',
      reportName: '',
      reportData: '',
      errorInAPI: false,
      noDataInDB: false,
      graphType: props.graphType || 'line',
      viewType: props.viewType || 'Single'
    };
  }

  async componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate = (prevprops) => {
    if (
      prevprops.graphType !== this.props.graphType ||
      prevprops.panelData !== this.props.panelData ||
      prevprops.viewType !== this.props.viewType ||
      prevprops.translationCache !== this.props.translationCache ||
      prevprops.trendData !== this.props.trendData
    ) {
      this.prepareData();
    }
  };

  getReportNametoDownload(excelData, Type, viewType, SelectedDate, trendIndex, param) {
    let { translationCache } = this.props;
    let Consumption = this.translation('Trend');
    if (viewType === 'Multiple' && (Type === 'timeRange' || Type === 'timeWithSelectionType')) {
      //Consumption
      Consumption = param ? `${this.translation(param)}${trendIndex}` : this.translation('Trend');
    } else if (viewType === 'Multiple') {
      Consumption = param ? this.translation(param) : this.translation('Trend');
    }
    let payload = {
      PortName: 'Rubus',
      Name: Type && Type ? this.translation(Type) : this.translation('Trend'),
      Consumption,
      Data: excelData || [],
      ReportType: 'singleHierarchy',
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      SelectedDate:
        moment.utc(SelectedDate.From).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm:ss') +
        '~' +
        moment.utc(SelectedDate.To).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm:ss'),
      SelectionType: 'dateRange',
      IsTranslate: translationCache && translationCache['GeneratedDate'] ? true : false,
      translationList: {
        GeneratedDate: this.translation('GeneratedDate'),
        SelectedDate: this.translation('SelectedDate')
      }
    };
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    let options = {};
    options = {
      method: 'POST',
      url: `${API_FILE.GenerateExcel}?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: JSON.stringify(payload)
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        return '';
      });
  }
  prepareData = async () => {
    let { reportData, viewType } = this.state;
    const { panelData, graphType } = this.props;
    let option = cloneDeep(this.getOption(panelData));
    let xaxis = [];
    let series = [];
    let legendsdata = [];

    panelData &&
      panelData.trendData &&
      Array.isArray(panelData.trendData) &&
      panelData.trendData.map((data) => {
        data = {
          ...data,
          DateTime: moment
            .utc(data.DateTime, 'YYYY-MM-DD HH:mm:ss')
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm:ss')
        };
        legendsdata.push({
          name: data.Description,
          icon: 'roundRect',
          textStyle: {
            fontSize: 16
          }
        });
        if (!xaxis.includes(data.DateTime)) {
          xaxis.push(data.DateTime);
        }
        if (
          series
            .filter((e) => e.value !== 'null')
            .map((data) => data.name)
            .includes(data.Name)
        ) {
          let index = series.map((data) => data.name).indexOf(data.Name);
          series[index].data.push([data.DateTime, data.Value, data.ParameterCode, data.DeviceCode]);
        } else {
          let obj = {
            dataobj: data,
            name: data.Name,
            data: [[data.DateTime, data.Value, data.ParameterCode, data.DeviceCode]],
            type: graphType && graphType !== 'Area' ? graphType : 'line',
            ...(graphType && graphType === 'Area' ? { areaStyle: {} } : {})
          };
          series.push(obj);
        }
        return {};
      });
    xaxis.sort((a, b) => {
      return new Date(a) - new Date(b);
    });
    let seriesDataObject =
      series &&
      Array.isArray(series) &&
      series.map((seriesData) => {
        //Adding this for legend translations//
        let DataVal = { ...seriesData };
        delete DataVal.dataobj;
        return {
          ...DataVal,
          // name: this.translation(seriesData.name)
          name: `${this.translation(seriesData.dataobj.ParameterName)} (${this.translation(
            seriesData.dataobj.DeviceName
          )})`
        };
      });

    option.xAxis.data = xaxis;
    option.series = seriesDataObject;
    option.legend = [...legendsdata];
    this.setState({
      option
    });

    reportData = panelData.trendData.map((data) => {
      // delete data.Impact;
      // delete data.Colour;
      // delete data.DeviceCode;
      // delete data.ParameterCode;
      return {
        // ...data,
        [this.translation('DateTime')]: moment
          .utc(data.DateTime, 'YYYY-MM-DD HH:mm:ss')
          .tz(this.props.currentTimeZone)
          .format('YYYY-MM-DD HH:mm:ss'),
        [this.translation('Name')]: `${this.translation(data.ParameterName)} (${this.translation(
          data.DeviceName
        )})`,
        [this.translation('Value')]: this.translation(data.Value),
        [this.translation('Description')]: `${this.translation(
          data.ParameterName
        )} (${this.translation(data.DeviceName)})`,
        [this.translation('DeviceName')]: this.translation(data.DeviceName),
        [this.translation('ParameterName')]: this.translation(data.ParameterName),
        val: this.translation(data.ParameterName)
      };
    });
    let reportName = {};
    if (panelData && panelData.tunedData) {
      let { Type } = panelData;
      let From = moment().format('YYYY-MM-DD HH:mm:ss');
      let To = moment().format('YYYY-MM-DD HH:mm:ss');
      let { FromDate, ToDate, FromDate1, ToDate1, FromDate2, ToDate2 } = panelData.tunedData;
      if (Type === 'timeRange' && panelData.trendIndex === 0) {
        From = FromDate1;
        To = ToDate1;
      } else if (Type === 'timeRange' && panelData.trendIndex === 1) {
        From = FromDate2;
        To = ToDate2;
      } else {
        From = FromDate;
        To = ToDate;
      }
      let value = reportData[0].val;
      reportData = reportData.map((report) => {
        delete report.val;
        return {
          ...report
        };
      });

      reportName = await this.getReportNametoDownload(
        reportData,
        panelData.Type,
        viewType,
        { From, To },
        panelData.trendIndex,
        value
      );
    }
    this.setState({
      reportData,
      reportName: reportName && reportName.file ? reportName.file : ''
    });
  };

  downLoadExcel = () => {
    const { reportName } = this.state;
    const link = document.createElement('a');
    link.href = `${IPAddress.LOCAL}${REPORTS.Reports}/${reportName}`;
    link.setAttribute('download', reportName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  getOption = (panelData) => {
    const graph = this;
    let { graphType } = this.state;
    let option = {
      // backgroundColor: 'rgb(41, 65, 90)',
      title: {},
      legend: {},
      grid: {
        top: '20%'
      },
      color: panelData.colors,
      tooltip: {
        trigger: 'axis'
      },

      xAxis: {
        type: 'category',
        data: []
      },
      yAxis: {
        type: 'value'
      },
      toolbox: {
        showTitle: false,
        orient: 'horizontal',
        itemSize: 25,
        feature: {
          myTool1: {
            show: true,
            title: 'custom extension method 1',
            icon: 'path://M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM514.1 580.1l-61.8-102.4c-2.2-3.6-6.1-5.8-10.3-5.8h-38.4c-2.3 0-4.5.6-6.4 1.9-5.6 3.5-7.3 10.9-3.7 16.6l82.3 130.4-83.4 132.8a12.04 12.04 0 0 0 10.2 18.4h34.5c4.2 0 8-2.2 10.2-5.7L510 664.8l62.3 101.4c2.2 3.6 6.1 5.7 10.2 5.7H620c2.3 0 4.5-.7 6.5-1.9 5.6-3.6 7.2-11 3.6-16.6l-84-130.4 85.3-132.5a12.04 12.04 0 0 0-10.1-18.5h-35.7c-4.2 0-8.1 2.2-10.3 5.8l-61.2 102.3z',

            onclick: function () {
              graph.downLoadExcel();
            }
          },
          saveAsImage: {
            title: 'download',
            // backgroundColor: '#011931',
            name: this.props.graphprops ? this.props.graphprops.title : 'name'
            // iconStyle: {
            //   borderColor: '#8c8c8c',
            //   borderWidth: 2
            // }
          }
        }
      },
      dataZoom: [
        {
          type: 'slider',
          show: true,
          start: 0,
          end: 100,
          backgroundColor: '#9a9191'
        },
        {
          type: 'inside',
          start: 0,
          end: 100
        }
      ],

      series: {
        name: 'value',
        type: graphType,
        data: []
      }
    };
    return option;
  };

  onChartClick = (cellData) => {
    this.setState({
      celd: cellData
    });
    this.getModal(cellData);
  };

  getModal = async (cellData) => {
    let ToDate = cellData.data[0];
    let FromDate = moment(cellData.data[0]).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
    this.setState({ range: [FromDate, ToDate] });
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'POST',
      url: `${API_TRENDS.GetParametersData}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        ParameterList: [
          {
            ParameterCode: cellData.data[2],
            DeviceCode: cellData.data[3],
            ParameterValue: cellData.data[1]
          }
        ],
        ToDate,
        FromDate,
        SiteId: siteId
      }
    };
    axios(headers)
      .then((response) => {
        this.setState({ datasource: response.data });
      })
      .catch(() => {});
    this.setState({
      visible: true
    });
  };

  onChange = (value, dateString) => {
    this.setState({
      range: [dateString[0], dateString[1]],
      momentRange: [moment(dateString[0]), moment(dateString[1])]
    });
  };

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };

  onhandleRangepicker = () => {
    let cellData = this.state.celd;
    this.getModal(cellData);
  };

  handleOk = () => {
    this.setState({ visible: false });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let { datasource, option } = this.state;
    let { panelData } = this.props;
    const columns = [
      {
        title: this.translation('Device Name'),
        dataIndex: 'DeviceName',
        key: 'DeviceName'
      },
      {
        title: this.translation('Parameter Name'),
        dataIndex: 'ParameterName',
        key: 'ParameterName'
      },
      {
        title: this.translation('Parameter Value'),
        dataIndex: 'ParameterValue',
        key: 'ParameterValue'
      },
      {
        title: this.translation('TimeStamp'),
        dataIndex: 'DateTime',
        key: 'DateTime',
        render: (text) => {
          if (text) {
            return moment(text).local().format('YYYY-MM-DD HH:mm:ss');
          }
        }
      }
    ];
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData)
    };
    return (
      <React.Fragment>
        <Drawer
          visible={this.state.visible}
          title="Historic Trend"
          width={1200}
          onOk={this.handleOk}
          onClose={this.handleCancel}
          footer={null}
        >
          <RangePicker
            style={{ width: 350, float: 'right' }}
            showTime={{ format: 'HH:mm:ss' }}
            format="YYYY-MM-DD HH:mm:ss"
            placeholder={['Start DateTime', 'End DateTime']}
            value={this.state.momentRange}
            onChange={this.onChange}
            onOk={this.onhandleRangepicker}
            disabledDate={this.disabledDate}
          />

          <Table dataSource={datasource} columns={columns} />
        </Drawer>
        {option && option.series && Array.isArray(option.series) && option.series.length > 0 ? (
          <ReactEcharts
            option={this.state.option}
            onEvents={
              panelData.Type === 'historic' && panelData.parameterType === 'Operational'
                ? onEvents
                : null
            }
            notMerge={true}
            lazyUpdate={true}
            style={{ height: '50%', width: '100%' }}
            className="react_for_echarts"
          />
        ) : (
          <NoRecordComponent />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(Line);
