/* eslint-disable no-undef */
import localStorage from '../../../utils/localStorage';
import axios from 'axios';
import moment from 'moment';
import { message } from 'antd';
import { API_SITE } from '../../../commons/api';
import {
  API_ASSET,
  API_CONDITIONALMONITORING,
  API_DEVICE,
  API_DEVICETYPE,
  API_INSPECTIONCONFIG,
  API_INSPECTIONQUESTIONS,
  API_MASTERASSetDETAILS,
  API_MASTERCONFIGURATION,
  API_MASTERJOBPLAN,
  API_MASTERLABOUR,
  API_MASTERWORKORDERS,
  API_PM,
  API_HIERARCHYMODULE,
  MASTERTASK,
  MASTERWORKFLOW,
  API_SCHEDULER,
  API_ORGANISATION,
  LOCATION,
  GETUSERLIST,
  INSPECTION,
  API_PARAMETER,
  API_RULEENGINE,
  API_SERVICEREQ
} from '../../../commons/api';
import {
  Table_AMM,
  Table_Base,
  // Table_Master,
  Table_Maximo,
  Table_SiteMaster
} from '../../../commons/table';

export async function getList(payload) {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    ...payload,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function deleteById(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = {
    ...payload,
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getTaskList(type) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${MASTERTASK.LIST}?SiteId=${siteId}&Type=${type}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getTaskListByEquipment(type, equipmentId) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${MASTERTASK.LIST}?SiteId=${siteId}&Type=${type}&DeviceAlias=${equipmentId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getTaskListByEquipmentandTaskType(type, equipmentId, tasktype) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${MASTERTASK.LIST}?SiteId=${siteId}&Type=${type}&DeviceAlias=${equipmentId}&type=${tasktype}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function changeTaskStatus(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${MASTERTASK.ChangeStatus}?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getTaskById(jobplanId, type) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GEt',
    url: `${MASTERTASK.GETBYID}?Id=${jobplanId}&Type=${type}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function getTaskByIdFDM(jobplanId, type) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GEt',
    url: `${MASTERTASK.GETBYID}?Id=${jobplanId}&Type=${type}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function getTaskById1(jobplanId) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${MASTERTASK.LIST}?SiteId=${siteId}&Id=${jobplanId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getWorkFlow(workFlowId) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GEt',
    url: `${MASTERWORKFLOW.Json}?Id=${workFlowId}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  try {
    return axios(Object).then((response) => {
      return response.data;
    });
  } catch (e) {
    if (err.response.status === 400 || err.response.status === 500) {
      message.info(err.response.data.message);
    }
  }
}
export async function deleteTask(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${MASTERTASK.DELETE}?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function submitTask(payload, fileData) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const SiteId = localStorage.get('currentSite');
  let data = {
    url: `${MASTERTASK.INSERT}?SiteId=${SiteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: { ...payload, SiteId }
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${SiteId}&Id=${
            response.data.Id
          }&Type=${'Task'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            message.error(`${error.response.data.message}`);
          });
      } else {
        return response.data;
      }
    })
    .catch((err) => {
      message.error(err.response.data.message);
    });
}
export function filterTaskByReference(TaskList, recordData) {
  let res = [];
  res = TaskList.filter((el) => {
    return (
      recordData &&
      !recordData.find((element) => {
        return element.TaskName === el.TaskName;
      })
    );
  });
  return res;
}

// ===========================Jobplan Api's=============================
export async function getJobPlansList(type) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_MASTERJOBPLAN.LIST}?SiteId=${siteId}&Type=${type}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getJobPlansListByEquipment(type, Equipment) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_MASTERJOBPLAN.LIST}?SiteId=${siteId}&Type=${type}&Equipment=${Equipment}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getJobPlansListByEquipmentandType(type, Equipment, JobPlanType) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_MASTERJOBPLAN.LIST}?SiteId=${siteId}&Type=${type}&Equipment=${Equipment}&JobPlanType=${JobPlanType}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getJobPlanById(jobplanId, type) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GEt',
    url: `${API_MASTERJOBPLAN.GETBYID}?Id=${jobplanId}&Type=${type}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function submitJobPlan(payload, fileUploadList) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${API_MASTERJOBPLAN.INSERT}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };

  return axios(data)
    .then((response) => {
      if (
        fileUploadList &&
        fileUploadList.TaskData &&
        Array.isArray(fileUploadList.TaskData) &&
        fileUploadList.TaskData.length > 0
      ) {
        fileUploadList.TaskData.map((fileDataObject1) => {
          let fileData = new FormData();
          let { fileDataObject, TaskId } = fileDataObject1;
          fileData.append('files', fileDataObject);
          if (fileData && fileData !== undefined && fileData.length !== 0) {
            let files = {
              url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${
                response.data.Id
              }&Type=${'JobPlan'}&TaskId=${TaskId}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: Authorization
              },
              data: fileData
            };
            return axios(files)
              .then((response) => {
                return response.data;
              })
              .catch((error) => {
                message.error(`${error.response.data.message}`);
              });
          }
          return {};
        });
        return response.data;
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      if (
        (error && error.response && error.response.status && error.response.status === 400) ||
        (error && error.response && error.response.status && error.response.status === 500)
      ) {
        message.info(error && error.response && error.response.data && error.response.data.message);
      }
      return error;
    });
}
export async function deleteJob(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${API_MASTERJOBPLAN.DELETE}?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function changeJobPlanStatus(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${API_MASTERJOBPLAN.ChangeStatus}?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export function filterJobPlanByReference(TaskList, recordData) {
  let res = [];
  res = TaskList.filter((el) => {
    return !recordData.find((element) => {
      return element.TaskName === el.TaskName;
    });
  });
  return res;
}
// ===========================Tools,Materials,labor Api's===========================
export async function getToolsList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getList = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Tools',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: [
        {
          key: 'Status',
          value: 'Active'
        }
      ]
    }
  };
  return axios(getList);
}
export async function getLaborList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getList = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Labor',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: [
        {
          key: 'Status',
          value: 'Active'
        }
      ]
    }
  };
  return axios(getList);
}
export async function getMaterialsList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getList = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Materials',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: [
        {
          key: 'Status',
          value: 'Active'
        }
      ]
    }
  };
  return axios(getList);
}
export async function getItemsList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getList = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Items',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: [
        {
          key: 'Status',
          value: 'Active'
        }
      ]
    }
  };
  return axios(getList);
}
// export function checkAvailability(_, value, key, index, TaskData) {
//   if (typeof (TaskData[key][index]['AvailableQuantity']) === number) {
//     if (value !== undefined) {
//       if (value <= TaskData[key][index]['AvailableQuantity']) {
//         return Promise.resolve();
//       }
//       else {
//         return Promise.reject(new Error('Quantity should not be greater than AvailableQuantity!'));
//       }
//     }
//   }
//   else {
//     // return Promise.resolve();
//     return Promise.reject(new Error('accepts only zero'));
//   }
// }

export function checkAvailability(_, value, key, index, TaskData, validation) {
  if (TaskData[key] === undefined) {
    if (value !== undefined) {
      if (value === 0) {
        return Promise.resolve();
      } else if (
        validation &&
        validation['validateDecimal'] &&
        validation['validateDecimal'] === true &&
        value &&
        value % 1 !== 0
      ) {
        return Promise.reject(new Error('Quantity should not be decimal'));
      } else if (value > 0 || value < 0) {
        return Promise.reject(new Error('accepts only zero'));
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
      // return Promise.reject(new Error('accepts only zero'));
    }
  } else {
    if (value !== undefined) {
      if (
        validation &&
        validation['validateDecimal'] &&
        validation['validateDecimal'] === true &&
        value &&
        value % 1 !== 0
      ) {
        return Promise.reject(new Error('Available quantity should not be decimal'));
      } else {
        if (value <= TaskData[key][index]['AvailableQuantity'] && value > 0) {
          return Promise.resolve();
        }
        // else if (value < TaskData[key][index]['AvailableQuantity']) {
        //   return Promise.reject(new Error('Quantity should not be negative value!'));
        // }
        else if (value > TaskData[key][index]['AvailableQuantity'] && value > 0) {
          return Promise.reject(
            new Error('Quantity should not be greater than AvailableQuantity!')
          );
        }
      }
    } else {
      return Promise.resolve();
      // return Promise.reject(new Error('accepts only zero'));
    }
  }
}

export function checkDecimalValue(_, value, key, record, validation) {
  if (value !== undefined) {
    if (value === 0) {
      return Promise.resolve();
    } else if (
      validation &&
      validation['validateDecimal'] &&
      validation['validateDecimal'] === true &&
      value &&
      value % 1 !== 0
    ) {
      return Promise.reject(new Error('Value should not be decimal'));
    } else if (value < 0) {
      return Promise.reject(new Error('accepts only zero'));
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.resolve();
  }
}

export function checkLimit(value, key, record, validation) {
  if (value !== undefined) {
    if (
      value &&
      validation &&
      validation.validateField &&
      validation.operator &&
      validation.operator === 'less' &&
      record &&
      record[validation.validateField] &&
      parseInt(record[validation.validateField]) <= parseInt(value)
    ) {
      return Promise.reject(
        new Error(`${key} Should be ${validation.operator} than ${validation.validateField}`)
      );
    } else if (
      value &&
      validation &&
      validation.validateField &&
      validation.operator &&
      validation.operator === 'greater' &&
      record &&
      record[validation.validateField] &&
      parseInt(record[validation.validateField]) >= parseInt(value)
    ) {
      return Promise.reject(
        new Error(`${key} Should be ${validation.operator} than ${validation.validateField}`)
      );
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.resolve();
  }
}

export function check(value, key, validation) {
  if (value !== undefined) {
    if (
      validation &&
      validation['validateDecimal'] &&
      validation['validateDecimal'] === true &&
      value &&
      value % 1 !== 0
    ) {
      return Promise.reject(new Error('Available quantity should not be decimal'));
    } else {
      return Promise.resolve();
    }
  } else {
    return Promise.resolve();
  }
}
export function checkTask(_, value, key, index, form, validation) {
  if (form && form[key] === undefined) {
    if (value !== undefined) {
      if (value === 0) {
        return Promise.resolve();
      } else if (
        validation &&
        validation['validateDecimal'] &&
        validation['validateDecimal'] === true &&
        value &&
        value % 1 !== 0
      ) {
        return Promise.reject(new Error('Quantity should not be decimal'));
      } else if (value > 0 || value < 0) {
        return Promise.reject(new Error('accepts only zero'));
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
      // return Promise.reject(new Error('accepts only zero'));
    }
  } else {
    if (value !== undefined) {
      if (
        validation &&
        validation['validateDecimal'] &&
        validation['validateDecimal'] === true &&
        value &&
        value % 1 !== 0
      ) {
        return Promise.reject(new Error('Quantity should not be decimal'));
      } else {
        if (value <= form[key][index]['AvailableQuantity'] && value > 0) {
          return Promise.resolve();
        }
        // else if (value < TaskData[key][index]['AvailableQuantity']) {
        //   return Promise.reject(new Error('Quantity should not be negative value!'));
        // }
        else if (value > form[key][index]['AvailableQuantity'] && value > 0) {
          return Promise.reject(
            new Error('Quantity should not be greater than AvailableQuantity!')
          );
        } else {
          return Promise.resolve();
        }
      }
    } else {
      return Promise.resolve();
      // return Promise.reject(new Error('accepts only zero'));
    }
  }
}
// ===========================Workorder Api's===========================
export async function getWorkOrdersList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_MASTERWORKORDERS.List}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getWorkOrderById(workOrderId, type) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_MASTERWORKORDERS.GetById}?Id=${workOrderId}&Type=${type}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getConditionMonitorById(Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_CONDITIONALMONITORING.GETBYID}?Id=${Id}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function deleteWorkOrder(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_MASTERWORKORDERS.Delete}?Id=${id}&SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function deleteConditionMonitor(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${API_CONDITIONALMONITORING.DELETE}?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function changeWorkOrderStatus(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_MASTERWORKORDERS.ChangeStatus}?Id=${id}&SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getWorkOrderName() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_MASTERWORKORDERS.GetId}?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getInspectionName() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_INSPECTIONCONFIG.GetId}?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function submitWorkOrder(payload, fileUploadList) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_MASTERWORKORDERS.Insert}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (
        fileUploadList &&
        fileUploadList.WO &&
        Array.isArray(fileUploadList.WO) &&
        fileUploadList.WO.length > 0
      ) {
        fileUploadList.WO.map((fileDataObject1) => {
          let fileData = new FormData();
          let { fileDataObject } = fileDataObject1;
          fileData.append('files', fileDataObject);
          if (fileData && fileData !== undefined && fileData.length !== 0) {
            let files = {
              url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${
                response.data.Id
              }&Type=${'MainWorkOrder'}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: Authorization
              },
              data: fileData
            };
            return axios(files)
              .then((response) => {
                return response.data;
              })
              .catch((error) => {
                message.error(`${error.response.data.message}`);
              });
          }
          return {};
        });
      }
      if (
        fileUploadList &&
        fileUploadList.TaskData &&
        Array.isArray(fileUploadList.TaskData) &&
        fileUploadList.TaskData.length > 0
      ) {
        fileUploadList.TaskData.map((Tasks) => {
          let fileData = new FormData();
          let { fileDataObject, TaskId } = Tasks;
          fileData.append('files', fileDataObject);
          if (fileData && fileData !== undefined && fileData.length !== 0) {
            let files = {
              url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&TaskId=${TaskId}&Id=${
                response.data.Id
              }&Type=${'WorkOrder'}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: Authorization
              },
              data: fileData
            };
            return axios(files)
              .then((response) => {
                return response.data;
              })
              .catch((error) => {
                message.error(`${error.response.data.message}`);
              });
          }
          return {};
        });
      }
      if (
        fileUploadList &&
        fileUploadList.ActualTaskData &&
        Array.isArray(fileUploadList.ActualTaskData) &&
        fileUploadList.ActualTaskData.length > 0
      ) {
        fileUploadList.ActualTaskData.map((Tasks) => {
          let fileData = new FormData();
          let { fileDataObject, TaskId } = Tasks;
          fileData.append('files', fileDataObject);
          if (fileData && fileData !== undefined && fileData.length !== 0) {
            let files = {
              url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&TaskId=${TaskId}&Id=${
                response.data.Id
              }&Type=${'WorkOrder'}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: Authorization
              },
              data: fileData
            };
            return axios(files)
              .then((response) => {
                return response.data;
              })
              .catch((error) => {
                message.error(`${error.response.data.message}`);
              });
          }
          return {};
        });
      }
      if (
        fileUploadList &&
        fileUploadList.JobPlan &&
        Array.isArray(fileUploadList.JobPlan) &&
        fileUploadList.JobPlan.length > 0
      ) {
        fileUploadList.JobPlan.map((Tasks) => {
          let fileData = new FormData();
          let { fileDataObject, JobPlanId } = Tasks;
          fileData.append('files', fileDataObject);
          if (fileData && fileData !== undefined && fileData.length !== 0) {
            let files = {
              url: `${
                API_MASTERWORKORDERS.SaveFile
              }?SiteId=${siteId}&Id=${JobPlanId}&Type=${'JobPlan'}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: Authorization
              },
              data: fileData
            };
            return axios(files)
              .then((response) => {
                return response.data;
              })
              .catch((error) => {
                message.error(`${error.response.data.message}`);
              });
          }
          return {};
        });
      }
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function submitConditionMonitor(payload, fileData) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `${API_CONDITIONALMONITORING.INSERT}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${
            response.data.Id
          }&Type=${'CM'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch((err) => {
            if (err.response.status === 400 || err.response.status === 500) {
              message.info(err.response.data.message);
            }
          });
      } else {
        return response.data;
      }
    })

    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function getEmergencyWorkOrdersList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');

  const Object = {
    method: 'GET',
    url: `${API_MASTERWORKORDERS.List}?SiteId=${siteId}&Type=${'Emergency'}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getOverview(equipment) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');

  const Object = {
    method: 'GET',
    url: `${API_MASTERASSetDETAILS.GetEquipmentOverviewDetailsById}?SiteId=${siteId}&Equipment=${equipment}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getConditionMoitorList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');

  const Object = {
    method: 'GET',
    url: `${API_CONDITIONALMONITORING.LIST}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getFieldDataWorkOrdersList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_MASTERWORKORDERS.List}?SiteId=${siteId}&Type=${'ESchedule'}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
// here-cm feedback apis

export async function getRuleExecutionInfo(ruleId, value) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getRuleExecutionInfo = {
    method: 'POST',
    url: `${API_RULEENGINE.GetRuleEngineLogs}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SourceType: 'CM',
      RuleId: ruleId,
      StartDate: moment(value[0]).format('YYYY-MM-DD HH:mm'),
      EndDate: moment(value[1]).format('YYYY-MM-DD HH:mm'),
      SiteId: siteId
    }
  };
  return axios(getRuleExecutionInfo)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        message.info(error.response.data.message);
      }
    });
}
export async function saveFeedback(feedBackInfo) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const saveFeedback = {
    method: 'POST',
    url: `${API_RULEENGINE.SaveFeedbackData}?SiteId=${siteId}`,
    data: feedBackInfo,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(saveFeedback)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        message.info(error.response.data.message);
      }
    });
}
export async function getfeedBackInfo(ruleId) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');

  let Authorization = 'Bearer ' + token;
  const getRuleExecutionInfo = {
    method: 'GET',
    url: `${API_RULEENGINE.GetFeedbackList}/${ruleId}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getRuleExecutionInfo)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        message.info(error.response.data.message);
      }
    });
}
export async function updateFeedBack(feedBackInfo) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getRuleExecutionInfo = {
    method: 'POST',
    url: `${API_RULEENGINE.UpdateFeedback}?SiteId=${siteId}`,
    data: feedBackInfo,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getRuleExecutionInfo)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        message.info(error.response.data.message);
      }
    });
}
// ===========================PM Api's===========================
export async function getPMList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_SCHEDULER.LIST}?SiteId=${siteId}&Type=${'PM'}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getPMByID(workOrderId) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_PM.GETBYID}?Id=${workOrderId}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  try {
    return axios(Object).then((response) => {
      return response.data;
    });
  } catch (e) {
    return [];
  }
}
export async function deletePM(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${API_PM.DELETE}?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function submitPM(payload, fileData) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_PM.INSERT}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${
            response.data.message
          }&Type=${'WorkOrder'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            message.error(`${error.response.data.message}`);
          });
      } else {
        return response.data;
      }
    })

    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function changePMStatus(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${API_PM.ChangeStatus}?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getWorkTypeId() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_PM.GETID}?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getWorkTypes() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { MasterCode: 'Work_Types', ActionType: 'read', Type: 'WithSiteId' }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
// ===========================Workflow Api's ===========================
export async function getWorkFlows(WorkTypeId) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${MASTERWORKFLOW.GET}?SiteId=${siteId}&WorkTypeId=${WorkTypeId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getStatus() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterCode: 'AMM StatusMaster',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: [
        {
          key: 'Type',
          value: 'WorkOrder'
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getInspectionScheduleList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_SCHEDULER.LIST}?SiteId=${siteId}&Type=${'ESchedule'}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

// ===========================SR Api's===========================
export async function getSRList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_SERVICEREQ.LIST}?SiteId=${siteId}&Type=${'Active'}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function submitSR(payload, fileData) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_SERVICEREQ.INSERT}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${
            response.data.message
          }&Type=${'WorkOrder'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            message.error(`${error.response.data.message}`);
          });
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      if (error.response) {
        message.error(error.response.data.message);
      }
    });
}
export async function submitScheduleInspection(payload, fileData) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_SCHEDULER.INSERT}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${
            response.data.message
          }&Type=${'WorkOrder'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            message.error(`${error.response.data.message}`);
          });
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      if (error.response) {
        message.error(error.response.data.message);
      }
    });
}
export async function getServiceReqId() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_SERVICEREQ.GETID}?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function changeSRStatus(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${API_SERVICEREQ.ChangeStatus}?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getSRByID(workOrderId) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_SERVICEREQ.GETBYID}?Id=${workOrderId}&Type=withId&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  try {
    return axios(Object).then((response) => {
      return response.data;
    });
  } catch (e) {
    return {};
  }
}
export async function deleteSR(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${API_SERVICEREQ.DELETE}?Id=${id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

// ===========================Dropdown Api's===========================
export async function getDeviceList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getDeviceList = {
    method: 'GET',
    url: `${API_DEVICE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceList)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getParentDevicelist(device) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getDeviceList = {
    method: 'GET',
    url: `${API_DEVICE.GET}?SiteId=${siteId}&DeviceType=${device}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceList)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getAssetList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getAssetList = {
    method: 'GET',
    url: `${API_ASSET.GET}?SiteId=${siteId}&AssetType=${'location'}&ParentAsset=${null}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getAssetList)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function questionsListMaster() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const questionsListMaster = {
    method: 'GET',
    url: `${API_INSPECTIONQUESTIONS.GetQuestionsList}?action=read&SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(questionsListMaster)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getAnswersList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const Answers = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Master_Answers',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Answers)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getNotificationTypeListData() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const Answers = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: 'sitemaster."SM_NotificationTypes"',
      ColumnName: 'Name',
      ColumnValue: 'Code',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        },
        {
          key: 'Code',
          value: 'ServiceRequest'
        }
      ],
      condition: 'Type',
      conditionvalue: 'Table'
    }
  };
  return axios(Answers)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getWorkPlanningList1() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const Answers = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Notification_Types',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Answers)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getLocationByAsset(device) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getLocation = {
    method: 'GET',
    url: `${API_ASSET.GET}?SiteId=${siteId}&AssetCode=${device}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getLocation)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getOrganisationsList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getOrganisationsList = {
    method: 'GET',
    url: `${API_ORGANISATION.GET}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getOrganisationsList)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getSitesList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getSitesList = {
    method: 'GET',
    url: `${API_SITE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getSitesList)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getSkillTypeList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getequipmentList = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Skills',
      ActionType: 'read',
      Type: 'WithSiteId'
    }
  };
  return axios(getequipmentList)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getequipmentList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getequipmentList = {
    method: 'POST',
    url: `${API_DEVICE.CACHE}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      Module: '',
      SiteId: siteId
    }
  };
  return axios(getequipmentList)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getParameterList(DeviceAlias) {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getParameterList = {
    method: 'GET',
    url: `${API_PARAMETER.LIST}?ParameterCategory=Operational&SiteId=${siteId}&DeviceAlias=${DeviceAlias}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getParameterList)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function getCmpointList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getCmpointList = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: Table_AMM.CMTypes,
      ColumnName: 'Name',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(getCmpointList)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getwarningTypeList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getwarningTypeList = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: Table_AMM.WarningTypes,
      ColumnName: 'WarningType',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(getwarningTypeList)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getnotificationTypeList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getnotificationTypeList = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: Table_SiteMaster.NotificationTypes,
      ColumnName: 'Name',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        },
        {
          key: 'Module',
          value: 'Amm'
        },
        {
          key: 'Status',
          value: 'true'
        }
      ]
    }
  };
  return axios(getnotificationTypeList)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getLocationList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getSitesList = {
    method: 'GET',
    url: `${LOCATION.LIST}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getSitesList);
}

export async function getItemTypeList() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getItemTypeList = {
    method: 'GET',
    url: `${LOCATION.LIST}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getItemTypeList);
}
export async function getManufacturerList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Manufacturers',
      ActionType: 'read',
      Type: 'WithSiteId'
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getScannerId(EquipmentId) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  let headers = {
    method: 'POST',
    url: `${API_DEVICE.GENERATESCANNERID}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { DeviceCode: EquipmentId }
  };
  return axios(headers)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getManufacturerDescriptionList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `/api/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Manufacturers',
      ActionType: 'read',
      Type: 'WithSiteId'
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getUsersList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${GETUSERLIST.GET}?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function workOrderStatus(payload) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `/api/workOrders/updateWoFlowStatus?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getQuestionList(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${API_MASTERWORKORDERS.Status}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getStatusList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: Table_SiteMaster.SMStatus,
      ColumnName: 'Status',
      Conditions: [
        {
          key: 'Type',
          value: 'JobPlan'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getUOMList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { MasterCode: 'Site_ParametersList', ActionType: 'read', Type: 'WithoutSiteId' }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getFrequency() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: Table_SiteMaster.Frequency,
      ColumnValue: 'Id',
      ColumnName: 'Frequency',
      Conditions: [
        {
          key: 'Module',
          value: 'EAM'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getStatusListPM() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: Table_SiteMaster.SMStatus,
      ColumnName: 'Status',
      ColumnValue: 'Id',
      Conditions: [
        {
          key: 'Type',
          value: 'PM'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getStatusListInspection() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: Table_SiteMaster.SMStatus,
      ColumnName: 'Status',

      Conditions: [
        {
          key: 'Type',
          value: 'InspectionCron'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getStatusListTask() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: Table_SiteMaster.SMStatus,
      ColumnName: 'Status',

      Conditions: [
        {
          key: 'Type',
          value: 'Task'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getitemList() {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      TableName: Table_SiteMaster.SMStatus,
      ColumnName: 'Status',
      Conditions: [
        {
          key: 'Type',
          value: 'Task'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getHierarchyList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let headers = {
    method: 'GET',
    url: `${API_HIERARCHYMODULE.MainHierarchyList}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(headers)
    .then(({ data }) => {
      if (Array.isArray(data)) {
        //this.setState({ datasource: data });
        return data;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}
export async function getFailureCodeList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterCode: 'Failure_Codes',
      ActionType: 'read',
      Type: 'WithSiteId'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function insertModalData(url, ActionType, payload) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let Object = {
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      MasterCode: url,
      ActionType: ActionType,
      data: payload,
      Type: 'WithSiteId'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function insertCause(payload) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let Object = {
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Problem_Codes',
      ActionType: 'create',
      data: payload,
      Type: 'WithSiteId'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function submitMaster(url, payload, fileData, type) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${url}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${response.data.Id}&Type=${type}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            message.error(`${error.response.data.message}`);
          });
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      if (error.response) {
        message.error(error.response.data.message);
      }
    });
}
export async function submitMasterFailureCode(url, payload, fileData, type) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${url}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${response.data.Id}&Type=${type}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch((err) => {
            if (
              err &&
              err.response &&
              (err.response.status === 400 || err.response.status === 500)
            ) {
              message.info(err.response.data.message);
            }
          });
      } else {
        return response.data;
      }
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}
export async function _getUOMList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const uomObject = {
    method: 'GET',
    url: `${API_PARAMETER.GetUOM}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(uomObject)
    .then((response) => {
      return response.data;
    })
    .catch(function () {});
}

export async function getMasterList(url) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Object = {};
  Object = {
    method: 'GET',
    url: `${url}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function deleteMaster(url, id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${url}?Id=${id}&SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function deleteEquipmentMaster(url, id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${url}?SiteId=${siteId}&Id=${id}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((err) => {
      message.error(err.response.data.message);
    });
}
export async function getMasterById(url, id, method) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: `${method}`,
    url: `${url}?Id=${id}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getQRById(url, id, method) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: `${method}`,
    url: `${url}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { ScannerId: id }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getEquipmentMasterById(url, id, method) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: `${method}`,
    url: `${url}?SiteId=${siteId}&Id=${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getMatsterItemList(Equipment) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_MASTERITEMS.GET}?SiteId=${siteId}&Equipment=${Equipment}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getMasterItemById(url, id) {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${url}?Id=${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function getListQuestions() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Object = {};
  Object = {
    method: 'GET',
    url: `${API_INSPECTIONQUESTIONS.LIST}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getItemsMasterList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getDeviceList = {
    method: 'GET',
    url: `${API_DEVICE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceList);
}

// Labor Master Api's
export async function getLaborMasterList() {
  const accessToken = localStorage.get('accessToken');
  let Object = {};
  // if(master === "AssetMasterHierarchy")
  // {
  Object = {
    method: 'POST',
    url: `${API_MASTERLABOUR.LIST}?SiteId=1`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  // }

  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getProblemCodesList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterCode: 'Problem_Codes',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: null
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getCauseCodes() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterCode: 'Cause_Codes',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: null
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getRemedyCodes() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterCode: 'Remedy_Codes',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: null
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export const _getDeviceTypesList = () => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_DEVICETYPE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(function () {
      return [];
    });
};
export const _saveDeviceData = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `${API_DEVICE.CREATE}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      message.error('Equipment Already Exists');
    });
};
export const _SaveManufaturerData = (data) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      data,
      MasterCode: 'Manufacturers',
      ActionType: 'create',
      Type: 'WithSiteId'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      message.error('Manufacturer already exists');
    });
};
export const _saveAssetData = (payload, fileData) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `${API_ASSET.CREATE}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${
            response.data.message
          }&Type=${'Asset'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            message.error(`${error.response.data.message}`);
          });
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
};
export const saveQuestionData = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');

  const Object = {
    method: 'POST',
    url: `${API_INSPECTIONQUESTIONS.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.error(err.response.data.message);
    });
};

export const getinspectionItemList = () => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterCode: 'Inspection_Items',
      ActionType: 'read',
      Type: 'WithSiteId'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(function () {
      return [];
    });
};

export const saveAreaData = (data) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export async function getinspectionAreaList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterCode: 'Inspection_Areas',
      ActionType: 'read',
      Type: 'WithSiteId'
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
export const saveItemData = (data) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,

    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getEquipmentRecord = (data) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_INSPECTIONQUESTIONS.Master}?SiteId=${siteId}`,

    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

// InspectionForm Api's
export async function getQuestionDeviceList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_DEVICE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getInspectionQuestions(payload, Type) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_INSPECTIONCONFIG.GetInspectionQuestions}?Type=${Type}&SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getInspectionData(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${INSPECTION.GetByInspectionByEquipment}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export const InsertSeveiceReq = (data) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_SERVICEREQ.INSERT}?SiteId=${siteId}`,

    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export async function getWorkOrderId() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_MASTERWORKORDERS.GetId}?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export const InsertWorkOrder = (data) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERWORKORDERS.Insert}?SiteId=${siteId}`,

    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
export const submitInspection = (data, fileDataList, type) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_INSPECTIONCONFIG.InsertLogbookInspection}?SiteId=${siteId}`,

    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(Object)
    .then((response) => {
      fileDataList &&
        Array.isArray(fileDataList) &&
        fileDataList.map((fileDataObject) => {
          let fileData = new FormData();
          fileData.append('files', fileDataObject);
          if (fileData && fileData !== undefined && fileData.length !== 0) {
            let files = {
              url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${response.data.Id}&Type=${type}`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
              },
              data: fileData
            };
            return axios(files)
              .then((response) => {
                return response.data;
              })
              .catch((error) => {
                message.error(`${error.response.data.message}`);
              });
          }
          return {};
        });

      return response.data;
    })
    .catch((error) => {
      if (error.response && (error.response.status === 400 || error.response.status === 500)) {
        message.info(error.response.data.message);
      }
    });
};

export async function getEquipmentHierarchyForInspection(Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_INSPECTIONCONFIG.InspectionHierarchy}?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getDataSourceOfInspection(payload) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_INSPECTIONCONFIG.CronInspectionList}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getDataSourceOfInspectionById(Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_INSPECTIONCONFIG.GetByInspectionId}?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getEquipmentHierarchy(Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${INSPECTION.Hierarchy}?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getscheduleInspectByID(Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_SCHEDULER.GETBYID}?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getAreasList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterCode: 'Inspection_Areas',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: null
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getItemsListForInspection() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      MasterCode: 'Inspection_Items',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: null
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getWorkTypesEInspec(value) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getWorkTypes = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: Table_AMM.WorkTypes,
      ColumnName: 'WorkType',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        },
        {
          key: 'Type',
          value
        }
      ]
    }
  };
  return axios(getWorkTypes)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function getWorkTypesWF() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getWorkTypes = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: Table_AMM.WorkTypes,
      ColumnName: 'WorkType',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(getWorkTypes);
}
export async function getCommunication() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;

  const getRoles = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: `sitemaster."SM_NotificationTypes"`,
      ColumnName: 'Code',
      Conditions: [
        {
          key: 'Module',
          value: 'WorkFlow'
        },

        {
          key: 'Status',
          value: 'true'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(getRoles);
}
export async function getRoles() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getRoles = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: Table_Base.BaseRoles,
      ColumnName: 'Name',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(getRoles);
}
export async function insertWF(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getRoles = {
    method: 'POST',
    url: `${MASTERWORKFLOW.INSERT}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(getRoles)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function updateWF(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getRoles = {
    method: 'POST',
    url: `${MASTERWORKFLOW.UPDATE}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(getRoles)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function getWFList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;

  const getWFList = {
    method: 'GET',
    url: `${MASTERWORKFLOW.WorkFlowList}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getWFList);
}
export async function deleteWF(id) {
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const deleteWF = {
    method: 'POST',
    url: `${MASTERWORKFLOW.DELETE}?Id=${id}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(deleteWF);
}
export async function getStatusListWF() {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getStatusList = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: Table_SiteMaster.SMStatus,
      ColumnName: 'Status',
      Conditions: [
        {
          key: 'Type',
          value: 'WorkOrder'
        },
        {
          key: 'SiteId',
          value: siteId
        }
      ]
    }
  };
  return axios(getStatusList);
}

export async function getWorkFlowById(Id) {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getWorkFlowById = {
    method: 'GET',
    url: `${MASTERWORKFLOW.GETBYID}?Id=${Id}&SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getWorkFlowById)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function deleteDeviceType(deviceTypeId) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_DEVICETYPE.DELETE}?Id=${deviceTypeId}&SiteId=${siteId}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function deleteTags(id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${API_PARAMETER.DELETE}?Id=${id}&SiteId=${siteId}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      message.success('Successfully deleted');
      return response.data;
    })

    .catch((err) => {
      message.error(err.response.data.message);
    });
}
export async function getDeviceTypeList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Object = {};
  Object = {
    method: 'GET',
    url: `${API_DEVICETYPE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function insertSkillType(payload) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let Object = {
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Skills',
      ActionType: 'create',
      Type: 'WithSiteId',
      data: payload
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getTagsByEquipmentTypeId(id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_PARAMETER.GET}?DeviceType=${id}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getDeviceTypeData(deviceCode) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getDeviceTypeData = {
    method: 'GET',
    url: `${API_DEVICETYPE.GET}?Code=${deviceCode}&SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceTypeData)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function getDeviceTypeById(Id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getDeviceTypeData = {
    method: 'GET',
    url: `${API_DEVICETYPE.GET}?Id=${Id}&SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceTypeData)
    .then((response) => {
      return response.data;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}

export async function tagCreateAPI(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `${API_PARAMETER.CREATE}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      message.success('Tag Saved');
      return response.data;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}

export async function tagUpdateAPI(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `${API_PARAMETER.UPDATE}?Id=${payload.Id}`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      message.success('Tag Updated');
      return response.data;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}

export async function getLandingPageCount(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const roleId = localStorage.get('roleId');
  let Authorization = 'Bearer ' + token;

  const getLandingPageCount = {
    method: 'POST',
    url: `${API_MASTERWORKORDERS.LandingPageCount}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { RoleId: roleId, ...payload }
  };
  return axios(getLandingPageCount)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
export async function getPendingList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const roleId = localStorage.get('roleId');
  let Authorization = 'Bearer ' + token;

  const getLandingPageCount = {
    method: 'POST',
    url: ` ${API_MASTERWORKORDERS.RoleBasedList}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { RoleId: roleId, Status: 'Pending' }
  };
  return axios(getLandingPageCount);
}
export async function updateStatus(payload, WfStatus) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const roleId = localStorage.get('roleId');
  let Authorization = 'Bearer ' + token;

  const getLandingPageCount = {
    method: 'POST',
    url: `${API_MASTERWORKORDERS.UpdateWoFlowStatus}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { RoleId: roleId, ...payload, WfStatus: WfStatus }
  };
  return axios(getLandingPageCount);
}
export async function getMaximoList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getCmpointList = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      TableName: Table_Maximo.MasterKeyAliasNames,
      ColumnName: 'AliasName',
      Conditions: [
        {
          key: 'Type',
          value: 'Table'
        }
      ],
      condition: 'Type',
      conditionvalue: 'Table'
    }
  };
  return axios(getCmpointList)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
export async function UpdateWorkFlow(id, WorkFlowId) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${API_MASTERWORKORDERS.UpdateStatus}?SiteId=${siteId}&Id=${id}&WorkFlowId=${WorkFlowId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response;
    })

    .catch((error) => {
      message.error(`${error.response.data.message}`);
    });
}
