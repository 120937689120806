/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of WorkOrders
 */
import React, { Component } from 'react';
import { Button, Tag, Input, Popconfirm, Tooltip } from 'antd';
import {
  getEmergencyWorkOrdersList,
  deleteWorkOrder,
  changeWorkOrderStatus,
  getUsersList
} from '../../../Utils/FormCalls';
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import translation from '../../../Utils/translation';
import themeSettings from '../../../Utils/themeSettings.json';
import ListView from '../../../Utils/ListView';
import Highlighter from 'react-highlight-words';
import { StyledComponent } from '../../../CSS/style';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import { constant } from '../../../Utils/constants';

class EquipmentOverviewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: translation(props.translationCache, constant.workOrder),
          dataIndex: 'WorkOrder',
          key: 'WorkOrder',
          filter: true,
          ...this.getColumnSearchProps('WorkOrder')
        },
        {
          title: translation(props.translationCache, constant.description),
          dataIndex: 'Description',
          key: 'Description'
        },
        // {
        //     "title": "Organization",
        //     "dataIndex": "OrganizationId",
        //     "key": "OrganizationId",
        //     "filter": true

        // },
        {
          title: translation(props.translationCache, constant.site),
          dataIndex: 'SiteId',
          key: 'SiteId',
          filter: true
        },
        {
          title: translation(props.translationCache, constant.status),
          dataIndex: 'Status',
          key: 'Status',
          render: (text, record) => (
            <>
              <Tooltip title={`Click To ${text === 'Active' ? 'Inactive' : 'Active'}`}>
                <span onClick={() => this.changeStatus(record.Id)}>
                  <Tag
                    color={
                      text && text === 'Active'
                        ? '#87d068'
                        : text === 'Inactive'
                        ? '#ff4d4f'
                        : 'gold'
                    }
                    key={text}
                  >
                    {text}
                  </Tag>
                </span>
              </Tooltip>
            </>
          )
        },
        {
          title: translation(props.translationCache, constant.action),
          width: '600',
          render: (text, record) => (
            <>
              <Button
                disabled={record.Status === 'Inactive' ? true : false}
                style={{ marginRight: '5px' }}
                className="ant-btn"
                onClick={() => this.editWorkOrder(record)}
                size="middle"
              >
                <EditOutlined />
              </Button>
              <Popconfirm
                title={translation(
                  props.translationCache,
                  constant.areYouSureThatYouWantToDeleteTheEmergencyWorkOrder
                )}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={() => this.deleteJobplan(record.Id)}
                okText="Yes"
                cancelText="No"
              >
                <Button className="ant-btn" size="middle">
                  <span style={{ color: '#ff4d4f' }}>
                    {' '}
                    <DeleteOutlined />
                  </span>
                </Button>
              </Popconfirm>
            </>
          )
        }
      ],
      dataSource: [],
      config: {
        title: 'Equipment Overview',

        viewTypes: ['add', 'card', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['WorkOrder'],

        firstTitle: 'WorkOrder',
        secondTitle: 'CreatedBy',
        thirdTitle: 'CreatedTime',

        actions: ['edit', 'delete'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/AMM/EquipmentOverviewDetails'
        },
        deletee: {
          byAttribute: 'Id'
        }
      }
    };
  }
  componentDidMount = async () => {
    this._getAdhocDataSource();
  };
  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };
  _getAdhocDataSource = async () => {
    let dataSource = await getEmergencyWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1',
      form: {}
    });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          //   icon={<SearchOutlined/>}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          // type="link"
          size="small"
          style={{ width: 90 }}
          onClick={() => {
            clearFilters();
            confirm({
              closeDropdown: true
            });
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }}
      />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  downTimeData = (form) => {
    this.setState({
      form
    });
  };
  deleteJobplan = async (id) => {
    await deleteWorkOrder(id);
    let dataSource = await getEmergencyWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1'
      // form: {}
    });
  };
  changeStatus = async (id) => {
    await changeWorkOrderStatus(id);
    let dataSource = await getEmergencyWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1'
      // form: {}
    });
  };

  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };
  render() {
    const { dataSource, columns, config, userList } = this.state;
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 143 }}>
        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(EquipmentOverviewList);
