/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from 'react';
import { Form, Select, Popconfirm, Tooltip, Collapse } from 'antd';
import { PlusOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import {
  getToolsList,
  getLaborList,
  getMaterialsList,
  checkAvailability,
  getItemsList,
  getSkillTypeList,
  checkLimit
} from '../../../Utils/FormCalls';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { StyledButton, StyledCollapse } from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import { constant } from '../../../Utils/constants';
import translation from '../../../Utils/translation';
import InputComponent from '../../../Widgets/InputComponent';
import InputNumberComponent from '../../../Widgets/InputNumberComponent';
const { Option } = Select;
const { Panel } = Collapse;

//@ATT:this was created to make nested dynamic elements! This is hard!

const ToolsFormFunction = (props) => {
  const [toolsList, setTool] = useState([]);
  const [translationCache, settranslationCache] = useState(props.translationCache || {});
  useEffect(() => {
    (async () => {
      try {
        let response = await getToolsList();
        setTool(response.data);
      } catch (e) {
        return {};
      }
    })();
  }, []);

  const getDisableStatus = (index, notification) => {
    let selectedNotif =
      props.formObject &&
      props.formObject.Warnings &&
      props.formObject.Warnings[index] &&
      props.formObject.Warnings[index].NotificationTypeId
        ? props.formObject.Warnings[index].NotificationTypeId
        : [];
    let { WoId, SrId } = props;
    if (selectedNotif && Array.isArray(selectedNotif)) {
      if (
        notification &&
        notification.Key &&
        notification.Key === 'ServiceRequest' &&
        selectedNotif.includes(WoId)
      ) {
        //If SelectedNotification has WOId , disable SR
        return true;
      } else if (
        notification &&
        notification.Key &&
        notification.Key === 'WorkOrder' &&
        selectedNotif.includes(SrId)
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
  return (
    <>
      <Form.List name={'Warnings'}>
        {(Warnings, { add, remove }) => {
          return (
            <Fragment>
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <StyledButton
                    theme={themeSettings}
                    type="dashed"
                    Warnings
                    style={{ background: '#29415a', color: '#fff' }}
                    onClick={() => {
                      add();
                    }}
                  >
                    <PlusOutlined /> {translation(translationCache, constant.addWarning)}{' '}
                  </StyledButton>
                </div>
              </Form.Item>
              {Warnings && Array.isArray(Warnings) && Warnings.length > 0 ? (
                <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                  {Warnings.map((field, index2) => (
                    <Panel
                      key={index2}
                      header={
                        <div style={{ fontWeight: 'bold' }}>
                          {translation(translationCache, `Warning ${index2 + 1}`)}
                        </div>
                      }
                      extra={
                        <Popconfirm
                          title={translation(
                            translationCache,
                            'Are you sure that you want to  delete the Warning ?'
                          )}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={() => remove(field.name)}
                          okText="Yes"
                          cancelText="No"
                        >
                          {' '}
                          <Tooltip
                            title={translation(translationCache, 'Delete')}
                            placement="bottom"
                          >
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>
                      }
                    >
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          label={translation(translationCache, constant.warningType)}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          {...field}
                          name={[field.name, 'WarningTypeId']}
                          fieldKey={[field.fieldKey, 'WarningTypeId']}
                          key={index2}
                          rules={[{ required: true }]}
                        >
                          <Select
                            style={{ width: '70%' }}
                            placeholder={translation(translationCache, 'Select Warning Type')}
                          >
                            {props.warningTypeList &&
                              Array.isArray(props.warningTypeList) &&
                              props.warningTypeList.map((warning, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={warning.Value}
                                    disabled={
                                      props.conditionMonitorRef &&
                                      props.conditionMonitorRef.current &&
                                      props.conditionMonitorRef.current.getFieldValue() &&
                                      props.conditionMonitorRef.current.getFieldValue().Warnings &&
                                      props.conditionMonitorRef.current
                                        .getFieldValue()
                                        .Warnings.filter(
                                          (text) => text && text.WarningTypeId === warning.Value
                                        ).length > 0
                                        ? true
                                        : false
                                    }
                                  >
                                    {' '}
                                    {warning.Key}{' '}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          label={translation(translationCache, constant.notificationType)}
                          {...field}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          name={[field.name, 'NotificationTypeId']}
                          fieldKey={[field.fieldKey, 'NotificationTypeId']}
                          key={index2}
                          rules={[{ required: true }]}
                        >
                          <Select
                            mode="multiple"
                            showArrow={true}
                            style={{ width: '70%' }}
                            placeholder={translation(translationCache, 'Select Notification Type')}
                            // onChange={(e) => this.onNotificationChange(e)}
                            onChange={(value) =>
                              props.onNotificationChange(
                                value,
                                'NotificationTypeId',
                                index2,
                                'Warnings'
                              )
                            }
                          >
                            {props.notificationTypeList &&
                              Array.isArray(props.notificationTypeList) &&
                              props.notificationTypeList.map((notification, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={notification.Value}
                                    disabled={getDisableStatus(index2, notification)}
                                  >
                                    {notification.Key}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={translation(translationCache, constant.UpperLimit)}
                          // disabled={true}
                          {...field}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          name={[field.name, 'UpperLimit']}
                          fieldKey={[field.fieldKey, 'UpperLimit']}
                          key={index2}
                          rules={[
                            { required: true },
                            {
                              validator: (_, value) =>
                                checkLimit(
                                  value,
                                  'UpperLimit',
                                  props.conditionMonitorRef &&
                                    props.conditionMonitorRef.current &&
                                    props.conditionMonitorRef.current.getFieldValue() &&
                                    props.conditionMonitorRef.current.getFieldValue().Warnings &&
                                    props.conditionMonitorRef.current.getFieldValue().Warnings[
                                      index2
                                    ]
                                    ? props.conditionMonitorRef.current.getFieldValue().Warnings[
                                        index2
                                      ]
                                    : {},
                                  // props.formObject.Warnings
                                  //   ? props.formObject.Warnings[index2]
                                  //   : {},
                                  {
                                    validateField: 'LowerLimit',
                                    operator: 'greater'
                                  }
                                )
                            }
                          ]}
                        >
                          <InputComponent
                            placeholder={translation(translationCache, 'Input UpperLimit')}
                            style={{ width: '70%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          label={translation(translationCache, constant.LowerLimit)}
                          // disabled={true}
                          {...field}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          name={[field.name, 'LowerLimit']}
                          fieldKey={[field.fieldKey, 'LowerLimit']}
                          key={index2}
                          rules={[
                            { required: true },
                            {
                              validator: (_, value) =>
                                checkLimit(
                                  value,
                                  'LowerLimit',
                                  // props.formObject.Warnings
                                  //   ? props.formObject.Warnings[index2]
                                  //   : {},
                                  props.conditionMonitorRef &&
                                    props.conditionMonitorRef.current &&
                                    props.conditionMonitorRef.current.getFieldValue() &&
                                    props.conditionMonitorRef.current.getFieldValue().Warnings &&
                                    props.conditionMonitorRef.current.getFieldValue().Warnings[
                                      index2
                                    ]
                                    ? props.conditionMonitorRef.current.getFieldValue().Warnings[
                                        index2
                                      ]
                                    : {},
                                  {
                                    validateField: 'UpperLimit',
                                    operator: 'less'
                                  }
                                )
                            }
                          ]}
                        >
                          <InputComponent
                            placeholder={translation(translationCache, 'Input LowerLimit')}
                            style={{ width: '70%' }}
                          />
                        </Form.Item>

                        {(props.formObject &&
                          props.formObject.Warnings &&
                          props.formObject.Warnings[index2] &&
                          props.formObject.Warnings[index2].NotificationTypeId &&
                          props.formObject.Warnings[index2].NotificationTypeId.includes(
                            props.EmailId
                          )) ||
                        (props.formObject &&
                          props.formObject.Warnings &&
                          props.formObject.Warnings[index2] &&
                          props.formObject.Warnings[index2].NotificationTypeId &&
                          props.formObject.Warnings[index2].NotificationTypeId.includes(
                            props.MobileId
                          )) ? (
                          <>
                            <Form.Item
                              style={{ flex: '50%' }}
                              labelCol={{ flex: '130px' }}
                              label={translation(translationCache, constant.user)}
                              // {...restField}
                              name={[field.name, 'Emails']}
                              rules={[{ required: true }]}
                            >
                              <Select
                                showArrow={true}
                                mode="multiple"
                                placeholder={translation(translationCache, 'Select User')}
                                style={{ width: '70%' }}
                              >
                                {props.usersList &&
                                  Array.isArray(props.usersList) &&
                                  props.usersList.map((item, index) => {
                                    return (
                                      <Option key={index} value={item.Email}>
                                        {' '}
                                        {item.FirstName}{' '}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item style={{ width: '100%', flex: '50%' }}>
                              <InputComponent style={{ display: 'none' }} />
                            </Form.Item>
                          </>
                        ) : null}
                        {props.formObject &&
                        props.formObject.Warnings &&
                        props.formObject.Warnings[index2] &&
                        props.formObject.Warnings[index2].NotificationTypeId &&
                        props.formObject.Warnings[index2].NotificationTypeId.includes(
                          props.SlackId
                        ) ? (
                          <Form.Item
                            style={{ flex: '50%' }}
                            labelCol={{ flex: '130px' }}
                            label={translation(translationCache, constant.channel)}
                            name={[field.name, 'ChannelName']}
                            rules={[
                              { required: true, message: 'Please Input Channel!' }
                              // { pattern: new RegExp(/^[A-Za-z0-9]*$/),
                              //   message: 'field does not accept special characters' }
                            ]}
                          >
                            <InputComponent
                              //maxLength={30}
                              placeholder={translation(translationCache, 'Input Channel')}
                              style={{ width: '70%' }}
                            />
                          </Form.Item>
                        ) : null}
                        <Form.Item style={{ flex: '50%' }}>
                          <InputComponent style={{ width: '70%', display: 'none' }} />
                        </Form.Item>
                      </div>
                    </Panel>
                  ))}
                </StyledCollapse>
              ) : null}
            </Fragment>
          );
        }}
      </Form.List>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

const ToolsForm = connect(mapStateToProps)(ToolsFormFunction);

export { ToolsForm };
