import React, { Component } from 'react';
import { Form, message, Select, Button, Collapse, Popconfirm, Divider, Space, Tooltip } from 'antd';
import {
  getProblemCodesList,
  getSitesList,
  submitMasterFailureCode,
  insertModalData,
  getMasterById
} from '../../../Utils/FormCalls';
import localStorage from '../../../../../utils/localStorage';
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { PaperClipOutlined } from '@ant-design/icons';
import {
  StyledForm,
  StyledComponent,
  StyledButton,
  StyledCollapse,
  StyledSubHeading,
  StyledModal
} from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import history from '../../../../../commons/history';
import moment from 'moment';
import { decode as base64_decode } from 'base-64';
import CausesForm from './CausesForm';
import FileBase64 from '../../../Utils/FileUpload';
import { constant } from '../../../Utils/constants';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import translation from '../../../Utils/translation';
import InputComponent from '../../../Widgets/InputComponent';
import { API_MASTERFAILURECODES } from '../../../../../commons/api';
const { Option } = Select;
const { Panel } = Collapse;
const type = 'FailureCode';
const ActionType = 'create';
const urlList = {
  insert: API_MASTERFAILURECODES.INSERT,
  getbyId: API_MASTERFAILURECODES.GetById
};
const modalInsertUrl = {
  ProblemMaster: 'Problem_Codes',
  CauseMaster: 'Cause_Codes',
  RemedyMaster: 'Remedy_Codes'
};
const fileData = new FormData();
class FailureCodeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProblemCodes: [],
      CausesList: [],
      translationCache: props.translationCache || [],
      sitesList: [],
      visible: false,
      visibleRemedy: false,
      visibleCause: false,
      formObjectProblem: {},
      formObjectCause: {},
      formObjectRemedy: {},
      failureCodeAttachment: true,
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {}
    };
  }
  RemedyModalRef = React.createRef();
  CauseModalRef = React.createRef();
  ProblemModalRef = React.createRef();
  FailureCodeRef = React.createRef();

  componentDidMount = async () => {
    this.FailureCodeRef.current.resetFields();
    this._getDataById();
    try {
      let sitesList = await getSitesList();
      let ProblemCodes = await getProblemCodesList();
      let formObject = this.FailureCodeRef.current.getFieldsValue();
      formObject = {
        ...formObject,
        SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
      };
      this.FailureCodeRef.current.setFieldsValue(formObject);
      this.setState({
        sitesList: sitesList,
        formObject,
        ProblemCodes: ProblemCodes
      });
    } catch (error) {
      // message.warn(`${error.message}`);
    }
  };
  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      if (this.FailureCodeRef.current)
        this.FailureCodeRef.current.setFieldsValue(this.props.formObject);
    }
    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject
      });
    }
  };
  openModal = async () => {
    let formObject = this.FailureCodeRef.current.getFieldsValue();
    this.setState({
      visible: true,
      formObject
    });
  };
  _getDataById = async () => {
    let formObject = {};
    if (this.props.EWOModal === true) {
      if (this.FailureCodeRef.current) {
        this.FailureCodeRef.current.setFieldsValue(formObject);
      }
      this.setState({
        formObject
      });
    } else {
      let path = history.location.pathname.split('/rubus/AMM/FailureCodeDetails/');
      let formId = base64_decode(path[1]);
      if (formId !== 'new') {
        let data = await getMasterById(urlList.getbyId, base64_decode(path[1]), 'GET');
        formObject = data && data !== undefined ? data : {};
        _.cloneDeepWith(data, (value) => {
          return value && value !== null && value.StartTime && value.EndTime
            ? {
                ...value,
                ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
                ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
              }
            : _.noop();
        });
      }
      if (this.FailureCodeRef.current) {
        this.FailureCodeRef.current.setFieldsValue(formObject);
      }
      this.setState({
        formObject
      });
    }
    // let path = history.location.pathname.split('/rubus/AMM/FailureCodeDetails/');
    // let formId = base64_decode(path[1]);
    // if (formId !== 'new') {
    //   let data = await getMasterById(urlList.getbyId, base64_decode(path[1]), 'GET');
    //   formObject = data && data !== undefined ? data : {};
    //   _.cloneDeepWith(data, (value) => {
    //     return value && value !== null && value.StartTime && value.EndTime
    //       ? {
    //         ...value,
    //         ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
    //         ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
    //       }
    //       : _.noop();
    //   });
    // }
    // if (this.FailureCodeRef.current) {
    //   this.FailureCodeRef.current.setFieldsValue(formObject);
    // }
    // this.setState({
    //   formObject
    // });
  };
  // openCause = async () => {
  //   let formObject = this.FailureCodeRef.current.getFieldsValue();
  //   this.setState({
  //     visibleCause: true,
  //     formObject
  //   })
  // }
  // openRemedy = async () => {
  //   let formObject = this.FailureCodeRef.current.getFieldsValue();
  //   this.setState({
  //     visibleRemedy: true,
  //     formObject
  //   })
  // }

  onFormsubmit = async (values) => {
    let { formObject, fileData } = this.state;
    formObject = {
      ...this.state.formObject
    };

    // this.FailureCodeRef.current
    //   .validateFields()
    //   .then(async values => {
    let payload = {
      ...formObject,
      ...values
    };
    if (this.props.EWOModal === true) {
      let statusResponse = await submitMasterFailureCode(urlList.insert, payload, fileData, type);
      if (statusResponse && statusResponse.message) {
        this.props.onFCModalSubmit(payload);
        this._getDataById();
        message.success(statusResponse.message);
        this.onReset();
      } else if (statusResponse && statusResponse.Error) {
        message.error(statusResponse.Error);
      }
    } else {
      let statusResponse = await submitMasterFailureCode(urlList.insert, payload, fileData, type);
      if (statusResponse && statusResponse.message) {
        // history.goBack();
        this._getDataById();
        message.success(statusResponse.message);
        // this.onReset();
      } else if (statusResponse && statusResponse.Error) {
        message.error(statusResponse.Error);
      }
    }
    // let statusResponse = await submitMasterFailureCode(urlList.insert, payload, fileData, type);
    // if (statusResponse && statusResponse.message) {
    //   history.goBack();
    //   message.success('FailureCode Master Submitted Successfully');
    //   this.onReset();
    // } else if (statusResponse && statusResponse.Error) {
    //   message.error(statusResponse.Error);
    // } else {
    //   message.error('Error');
    // }
    // })
    // .catch(error => {
    // });
  };
  handleChange = async (value, key, ArrayKey) => {
    // this.setState({
    //   visible: true
    // })
    let { ProblemCodes } = this.state;

    let formObject =
      this.FailureCodeRef &&
      this.FailureCodeRef.current !== null &&
      this.FailureCodeRef.current.getFieldsValue();
    if (key === 'Problem') {
      let selectedDevice = ProblemCodes.filter((item) => item.Id === value);
      formObject[ArrayKey] &&
        formObject[ArrayKey].map((item) => {
          if (item !== undefined) {
            item.Description =
              selectedDevice && selectedDevice[0] && selectedDevice[0]['Description']
                ? selectedDevice[0]['Description']
                : null;
          }

          // }
          return {};
        });
    }
    if (this.FailureCodeRef.current) {
      this.FailureCodeRef.current.setFieldsValue(formObject);
    }

    this.setState({ formObject });
  };
  handleSelect = (value, mainkey, problemIndex, subkey, causeIndex, selectedObject) => {
    let formObject = this.FailureCodeRef.current.getFieldValue();
    let Causes = formObject && formObject[mainkey];
    Causes.map((item, index) => {
      if (index === problemIndex && item && item[subkey] && item[subkey][causeIndex]) {
        item[subkey][causeIndex]['Description'] = selectedObject[0]['Description'];
        item[subkey][causeIndex]['Remarks'] = selectedObject[0]['Remarks'];
      }
      return {};
    });
    this.FailureCodeRef.current.setFieldsValue(formObject);
    this.setState({ formObject });
  };
  RemediehandleSelect = (
    value,
    mainkey,
    problemIndex,
    subkey,
    causeIndex,
    subsubkey,
    remedyIndex,
    selectedObject
  ) => {
    let formObject = this.FailureCodeRef.current.getFieldValue();
    let TaskData = formObject && formObject[mainkey];
    // for autopoulated remedies
    TaskData.map((item, index) => {
      if (index === problemIndex) {
        item[subkey][causeIndex][subsubkey][remedyIndex]['Description'] =
          selectedObject[0]['Description'];
        item[subkey][causeIndex][subsubkey][remedyIndex]['Remarks'] = selectedObject[0]['Remarks'];
      }
      return {};
    });
    this.FailureCodeRef.current.setFieldsValue(formObject);
    this.setState({ formObject });
  };

  fileupload = async (values, key, fileDataObject) => {
    let formObject = this.FailureCodeRef.current.getFieldsValue();

    if (formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    if (this.FailureCodeRef.current) this.FailureCodeRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };
  onModalFormsubmit = async (e) => {
    e.preventDefault();
    this.ProblemModalRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          ...values
        };
        let response = await insertModalData(modalInsertUrl.ProblemMaster, ActionType, payload);
        if (response && response.message) {
          message.success(response.message);
          this.onReset();
          let ProblemCodes = await getProblemCodesList();
          this.setState({
            ProblemCodes: ProblemCodes,
            visible: false
          });
        } else if (response && response.Error) {
          message.error(response.Error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onReset = () => {
    // this.formRef.current.resetFields();
    if (this.FailureCodeRef.current) this.FailureCodeRef.current.resetFields();
    if (this.ProblemModalRef.current) this.ProblemModalRef.current.resetFields();
    if (this.CauseModalRef.current) this.CauseModalRef.current.resetFields();
    if (this.RemedyModalRef.current) this.RemedyModalRef.current.resetFields();
  };
  // onFinishFailed = () => {
  //   message.error('Please enter mandatory fields');
  // };
  onCancel = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      this.setState({
        visible: false
      });
    }
  };
  onModalOk = () => {
    // let formObject = this.FailureCodeRef.current.getFieldsValue()
    this.setState({
      visible: false
    });
  };
  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };
  removeCause = (problemIndex, causeIndex, key) => {
    let formObject = this.FailureCodeRef.current.getFieldsValue();
    let ToolsList =
      formObject &&
      formObject.Problems &&
      formObject.Problems[problemIndex] &&
      formObject.Problems[problemIndex];
    if (ToolsList && ToolsList[key]) {
      let Causes =
        ToolsList &&
        ToolsList[key] &&
        ToolsList[key].filter((tool, index) => {
          return causeIndex !== index;
        });
      formObject = {
        ...formObject,
        Problems:
          formObject.Problems &&
          Array.isArray(formObject.Problems) &&
          formObject.Problems.map((problem, index) => {
            if (index === problemIndex) {
              let CauseArray = problem && problem[key] ? problem[key] : [];
              Causes =
                CauseArray &&
                Array.isArray(CauseArray) &&
                CauseArray.filter((cause, cIndex) => {
                  return cIndex !== causeIndex;
                });
              return { ...problem, Causes };
            }
            return {};
          })
      };
      this.FailureCodeRef.current.setFieldsValue(formObject);
      this.setState({ formObject });
    }
  };
  render() {
    let {
      sitesList,
      formObject,
      ProblemCodes,
      formObjectProblem,
      translationCache,
      failureCodeAttachment
    } = this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      }
      // pattern: {
      //   mismatch: '${label} does not match pattern'
      // }
    };
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '0px 15px', minHeight: window.innerHeight - 143 }}
        >
          <StyledForm
            name="Item"
            theme={themeSettings}
            ref={this.FailureCodeRef}
            initialValues={formObject}
            onFinish={this.onFormsubmit}
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            //wrapperCol={{ flex: 1 }}
            validateMessages={validateMessages}
          >
            {/* {formObject && formObject.Id ? (
              <div
                style={{
                  color: 'white',
                  margin: '19px 37px',
                  fontSize: '17px',
                  fontWeight: 'bold',
                  marginBottom: '35px'
                }}
              >
                {formObject && formObject.SiteId ? (
                  <span>
                    <span style={{ color: '#ffb327' }}>
                      {this.getValueFromList(
                        sitesList,
                        'Id',
                        this.FailureCodeRef.current
                          ? this.FailureCodeRef.current.getFieldValue('SiteId')
                          : '',
                        'Name'
                      )}{' '}
                    </span>{' '}
                    (Site)
                  </span>
                ) : null}
              </div>
            ) : null} */}
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Form.Item>
                <StyledButton theme={themeSettings} htmlType="submit">
                  {translation(translationCache, constant.submit)}
                </StyledButton>
              </Form.Item>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item name="Id"></Form.Item>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                label={translation(translationCache, constant.failureClass)}
                name="FailureCode"
                rules={[
                  { required: true },
                  {
                    // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                    //  pattern: new RegExp(/^[一-龯ぁ ]*$/)
                    // /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+[々〆〤]+/u
                    // pattern:new RegExp(/^[[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+[々〆〤]]*$/)
                    // pattern: new RegExp(
                    //   /^[一-龯ぁ-んァ-ンぁ-んァ-ン\wぁ-んァ-ンｧ-ﾝﾞﾟぁ-ゞ0-9A-Za-zァ-ヶｦ-ﾟ-_&ー ]*$/
                    // )
                  }
                ]}
              >
                <InputComponent
                  // maxLength={50}
                  disabled={formObject && formObject.Id ? true : false}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Input FailureClass')}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                // label={translation(translationCache, constant.failureClass)}
                label={translation(translationCache, constant.failureClassDescription)}
                name="Description"
                rules={[{ required: true }]}
              >
                <InputComponent
                  // maxLength={150}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Input Failure Class Description')}
                />
              </Form.Item>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                  // display: formObject && formObject.Id ? 'none' : true
                }}
                label={translation(translationCache, constant.site)}
                name="SiteId"
                disabled={formObject && formObject.Id ? true : false}
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Select Site')}
                  disabled={formObject && formObject.Id ? true : false}
                  onChange={(e) => this.handleChange(e, 'SiteId')}
                >
                  {sitesList &&
                    Array.isArray(sitesList) &&
                    sitesList.map((site, index) => {
                      return (
                        <Option key={index} value={site.Id}>
                          {' '}
                          {site.Name}{' '}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                name="Attachments"
                // label={translation(translationCache, constant.attachments)}
                label={
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                      {translation(translationCache, constant.attachments)}
                      <PaperClipOutlined className="plusCircleOutlined" />
                    </span>
                  </div>
                }
              >
                <FileBase64
                  style={{ width: '70%' }}
                  failureCodeAttachment={failureCodeAttachment}
                  downloadClassName={'formdownloadInput'}
                  Attachments={formObject.Attachments}
                  fileUpload={this.fileupload}
                ></FileBase64>
              </Form.Item>
              <Form.Item name="key" />
            </div>
            <StyledSubHeading>{translation(translationCache, constant.problems)}</StyledSubHeading>
            <Form.List name="Problems">
              {(fields, { add, remove }) => (
                <>
                  {' '}
                  <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <Form.Item>
                      <StyledButton theme={themeSettings} onClick={() => add()}>
                        {translation(translationCache, constant.addProblem)}
                      </StyledButton>
                    </Form.Item>
                  </div>
                  {fields && Array.isArray(fields) && fields.length > 0 ? (
                    <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                      {fields.map((field, index) => (
                        <Panel
                          key={`${translation(translationCache, constant.problem)} ${index + 1}`}
                          header={`${translation(translationCache, constant.problem)} ${index + 1}`}
                          extra={
                            <Popconfirm
                              title={translation(
                                translationCache,
                                'Are you sure that you want to delete the Problem'
                              )}
                              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                              onConfirm={() => remove(field.name)}
                              okText={translation(translationCache, constant.yes)}
                              cancelText={translation(translationCache, constant.no)}
                            >
                              {/* <span className='deleteIcon'> <DeleteOutlined /></span> */}
                              <Tooltip
                                title={translation(translationCache, 'Delete')}
                                placement="bottom"
                              >
                                <DeleteOutlined />
                              </Tooltip>
                            </Popconfirm>
                          }
                        >
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Form.Item
                              style={{ width: '100%', flex: '50%' }}
                              // {...field}
                              label={translation(translationCache, constant.problemCode)}
                              name={[field.name, 'ProblemId']}
                              // fieldKey={[problem.fieldKey, 'Cause Code']}
                              // key={index2}
                              rules={[
                                {
                                  required: true
                                }
                                // {
                                //   // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                                //   pattern: new RegExp(/^[^%!^ ]*$/)
                                // }
                              ]}
                            >
                              <Select
                                style={{ width: '70%' }}
                                // mode={"tags"}
                                placeholder={translation(translationCache, 'Select Problem')}
                                dropdownRender={(menu) => (
                                  <>
                                    {menu}
                                    <Divider
                                      style={{
                                        margin: '8px 0'
                                      }}
                                    />
                                    <Space
                                      style={{
                                        padding: '0 8px 4px'
                                      }}
                                    >
                                      <Button
                                        // theme={themeSettings} htmlType="submit"
                                        // type="dashed"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.openModal();
                                          if (this.ProblemModalRef.current)
                                            this.ProblemModalRef.current.resetFields();
                                        }}
                                      >
                                        {translation(translationCache, constant.addProblem)}
                                      </Button>
                                    </Space>
                                  </>
                                )}
                                onChange={(value) =>
                                  this.handleChange(value, 'Problem', 'Problems', field.key)
                                }
                              >
                                {ProblemCodes &&
                                  Array.isArray(ProblemCodes) &&
                                  ProblemCodes.map((item, index) => {
                                    return (
                                      <Option
                                        key={index}
                                        value={item.Id}
                                        disabled={
                                          this.FailureCodeRef &&
                                          this.FailureCodeRef.current &&
                                          this.FailureCodeRef.current.getFieldsValue() &&
                                          this.FailureCodeRef.current.getFieldsValue().Problems &&
                                          this.FailureCodeRef.current
                                            .getFieldsValue()
                                            .Problems.filter(
                                              (failure) => failure && failure.ProblemId === item.Id
                                            ).length > 0
                                            ? true
                                            : false
                                        }
                                      >
                                        {' '}
                                        {item.ProblemCode}{' '}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              style={{ width: '100%', flex: '50%' }}
                              // {...field}
                              label={translation(translationCache, 'Description')}
                              name={[field.name, 'Description']}
                              rules={[
                                {
                                  required: false
                                }
                              ]}
                            >
                              <InputComponent
                                placeholder={translation(
                                  translationCache,
                                  'Autopopulate based on Problem'
                                )}
                                // maxLength={150}
                                style={{ width: '70%' }}
                                disabled
                              />
                              {/* <InputComponent></Input> */}
                            </Form.Item>
                            <Form.Item
                              style={{ width: '100%', flex: '50%' }}
                              {...field}
                              label={translation(translationCache, constant.remarks)}
                              name={[field.name, 'Remarks']}
                            >
                              <InputComponent
                                placeholder={translation(translationCache, 'Input Remarks')}
                                // maxLength={150}
                                style={{ width: '32%' }}
                              />
                              {/* <InputComponent></Input> */}
                            </Form.Item>
                          </div>
                          <Form.Item>
                            <CausesForm
                              onRemove={this.removeCause}
                              formObject={this.FailureCodeRef.current.getFieldsValue()}
                              fieldKey={field.name}
                              onCauseChange={this.handleSelect}
                              onRemedieSelect={this.RemediehandleSelect}
                              onCauseModalOpen={this.openCause}
                              onRemedyModalOpen={this.openRemedy}
                              CausesList={this.state.CausesList}
                            />
                          </Form.Item>
                        </Panel>
                      ))}
                    </StyledCollapse>
                  ) : null}
                </>
              )}
            </Form.List>
          </StyledForm>
          <StyledModal
            width={800}
            closable
            title={translation(translationCache, constant.newProblemCode)}
            open={this.state.visible}
            onCancel={this.onCancel}
            footer={[
              <Button key="2" onClick={this.onCancel}>
                Cancel
              </Button>,
              <Button key="1" type="primary" onClick={this.onModalFormsubmit}>
                Submit
              </Button>
            ]}
          >
            {/* <Form
                                ref={this.ProblemModalRef}
                                onFinish={this.onModalFormsubmit}
                                onFinishFailed={this.onModalFinishFailed}
                                initialValues={formObjectProblem}
                                layout="vertical"
                            > */}
            <Form
              theme={themeSettings}
              ref={this.ProblemModalRef}
              onFinish={(e) => {
                this.onModalFormsubmit(e);
              }}
              // onFinishFailed={this.onModalFinishFailed}
              initialValues={formObjectProblem}
              layout="vertical"
              // layout="horizontal"
              // labelCol={{ flex: '130px' }}
              labelAlign="right"
              labelWrap
              //wrapperCol={{ flex: 1 }}
            >
              {' '}
              {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}> */}
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.newProblemCode)}
                name="ProblemCode"
                rules={[
                  { required: true }
                  // {
                  //   // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                  //   // pattern: new RegExp(/^[^%!^ ]*$/)
                  // }
                ]}
              >
                <InputComponent
                  // maxLength={50}
                  placeholder={translation(translationCache, 'Input NewProblemCode')}
                  style={{ width: '70%' }}
                />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.description)}
                name="Description"
                rules={[{ required: true }]}
              >
                <InputComponent
                  // maxLength={150}
                  placeholder={translation(translationCache, 'Input Description')}
                  style={{ width: '70%' }}
                />
              </Form.Item>
              {/* </div> */}
            </Form>
          </StyledModal>
        </StyledComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(FailureCodeDetails);
