export default {
  Rule_Name: 'Rule Name',
  Rule_Description: 'Rule Description',
  deviceRules: 'Equipment Rule',
  deviceTypeRules: 'Equipment Type Rule',
  nodeviceRules: 'Nodevice Rules',
  restApiRules: 'RestApi Rules',
  tableRules: 'Table Rules',
  AssetName: 'Asset Name',
  type: 'Type',
  select_group: 'Select Group',
  replicate: 'Replicate',
  add: 'Add',
  preventivemeasure: 'Preventive Measures',
  rootcauseanalysis: 'Possible Cause',
  recomandations: 'Recommendations',
  remove: 'Remove',
  addelseif: 'Add Other Condition',
  addelse: 'Add Another Condition',
  addrule: 'Add Rule',
  Rule_Maintainence: 'View Maintainence',
  addAIRule: 'Add AI Rule',
  ProblemStatement: 'Problem Statement',
  CurrentStep: 'Current Step',
  bodymessage: 'Remarks',
  createdBy: 'Created By',
  createdTime: 'Created Time',
  text: '',
  edit: 'Edit',
  delete: 'Delete',
  status: 'Notifications',
  RuleHistory: 'Rule History',
  //ph Are Place Holders
  phName: 'Please Enter Rule Name',
  phDescription: 'Please Enter Rule Description',
  device: ' Select Equipment',
  selectdevicetype: 'Select EquipmentType',
  selectparameters: 'Select Parameter',
  Parameter: 'Parameter',
  ParameterName: 'Parameter Name',
  ParameterType: 'ParameterType',
  selectoperator: 'Select Operator',
  value: 'Value',
  action: 'Communication Type',
  NotificationType: 'Notification Type',
  email: 'EMAIL',
  slack: 'SLACK',
  update: 'Update',
  save: 'Save',
  cancel: 'Cancel',
  selectCondition: 'Select Condition',
  selectFrequency: 'Select Frequency',
  selectDelay: 'Select Delay',
  onchange: 'onChange',
  onContinous: 'onContinuous',
  selecturl: 'Select Url',
  priority: 'Priority',
  selecttable: 'Select Table',
  selectcolumn: 'Select Coulmn',
  Enable: 'Click to Enable',
  Disable: 'Click to Disable',
  equipmentType: 'Equipment Type',
  members: 'Members',
  body: 'Body',
  equipmentTagIDandname: 'Equipment Tag ID and name',
  conditions: 'Conditions',
  sno: 'S.No',
  tagName: 'Tag Name',
  tagValue: 'Tag Value',
  exeTime: 'Execution Time',
  communicationType: 'CommunicationType',
  fdBack: 'Feedback',
  rootCause: 'Root Cause',
  timeStamp: 'TimeStamp',
  comment: 'Comment',
  Go_Back: 'Go Back',
  GET_DATA: 'Get Data',
  search: 'Search',
  Reset: 'Reset',
  RealTime_Desc: 'Rules related to Single Device Can be Created',
  Batch_Desc: 'Rules Related to multiple Devices Can be Created',
  Hybrid_Desc: 'First Condition in rule is RealTime And Remaining Conditions are Batch',
  DownTime_Desc: 'First Condition in rule is RealTime And Remaining Conditions are Batch',
  RuleStatus: 'Rule Status'
};
