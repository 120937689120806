import styled from 'styled-components';
import { Select, Tabs, Card, Modal, Button, TreeSelect, Badge } from 'antd';

export const BlockedLabel = styled.label`
  display: block;
  background-color: initial;
  font-weight: 700;
`;

export const WorkCenterSelect = styled(Select)`
  width: 200px;
  display: inline-block;
  margin-bottom: 1%;
`;

export const StyledTab = styled(Tabs)`
  .ant-tabs-nav-container {
    height: 50px !important;
  }
  .ant-tabs-nav {
    border-radius: 10px;
  }
  .ant-tabs-tab {
    background: #1a3652 !important;
    border-radius: 8px;
    color: white !important;
    font-weight: 800;
    border-radius: 10px !important;
    border:1px solid #fff !important;
    margin-bottom: 12px !important;
  }

  .ant-tabs-extra-content {
    margin-right: 6%;
    margin-top: 0.6%;
  }
   .ant-tabs-tab-active{
    background: #fff !important;
    border-radius: 8px;
    color: black !important;
    font-weight: 800;
    border:1px solid #fff !important
    border-radius: 8px !important;
    
  }
 
`;

export const StyledCard = styled(Card)`
border: 1px solid #D3D3D3;
border-radius:20px;
margin-bottom: 10px;


box-shadow: 0 8px 6px -6px grey;

.ant-card-head {
    color:#000000 !important;
    border-bottom: 2px solid #d2caca;
    height: 65px;
}
.ant-card-body {
    color:#000000 !important;
    font-size:16px
    padding-left:0px
}`;

export const BlockedLabelBlack = styled.label`
  display: block;
  color: #000000 !important;
  font-weight: 700;
`;

export const StyledModel = styled(Modal)`
  width: 60% !important;

  .ant-modal-content {
    border-radius: 20px;
    background: #032a53;
  }

  .ant-modal-header {
    background: #032a53;
  }
`;

export const FormLabel = styled.label`
  font-weight: 700;
  color: black;
`;

export const lightTheme = {
  text: 'black',
  even: '#7a8793',
  odd: '#bdc0c2',
  hover: '#dedede'
};

export const darkTheme = {
  text: 'black',
  even: '#7a8793',
  odd: '#bdc0c2',
  hover: '#dedede'
};

export const StyledTable = styled.table`
 //background:#bfbfbf !important;
   caption-side: top;
 
 
   color: ${(props) => props.theme.text} !important;
   thead[class*="ant-table-thead"] th{
     background-color: #214972 !important;
     color: #fff !important;
     text-align: center;
   }
   width: 100%;
   /* border-collapse: separate; */
   /* border-spacing: 5px 10px; */
 
   caption-side: bottom;
   /* empty-cell: show | hide;  */
   /* empty-cell is a property of table or the cells themselves */
 
   /* vertical-align: baseline | sub | super | text-top | 
                 text-bottom | middle | top | bottom | 
                 <percentage> | <length> */
 
   /* tbody {
     vertical-align: top;
   }              */
   td,
   th {
     border: none;
   }
   /* td,
   th {
       color:${(props) => props.theme.text} !important;
     border: 1px solid red;
    
   } */
 
   td {
     padding: 11px 10px;
 
   }
   .ant-table-thead > tr > th{
     font-weight:bold !important
     font-size: 1.2em !important;
      // color:red !important
   }
   text[style] {
     font-weight:bold !important
     font-size: 1.15em !important;
     color: ${(props) => props.theme.text} !important;
  }
   tbody tr {
     :nth-of-type(odd) {
       background-color:${(props) => props.theme.odd} !important;
     }
     :hover {
       background-color:${(props) => props.theme.hover} !important; 
     }
   }
   tbody tr {
     :nth-of-type(even) {
       background-color:${(props) => props.theme.even} !important;
     }
     :hover {
       background-color:${(props) => props.theme.hover} !important; 
     }
     text-align: center;
   }
   thead > tr {
     color: ${(props) => props.theme.text} !important;
     background-color: #262626 !important;
   }
   caption {
     font-size: 0.9em;
     padding: 5px;
     font-weight: bold;
   }
 `;

export const StyledDowntime = styled.div`
  background: #1a3652;
  margin: 5px;
  padding: 30px;
  min-height: 750px;
  margin-top: 16px;
`;
export const StyledAddButton = styled(Button)`
  margin: 24px;
`;
export const DrawerFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
`;
export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
`;
export const BadgeHeader = styled(Badge)`
  background-color: #fff;
  color: #999;
  margin-right: 1em;
`;
export const StyledTreeSelect = styled(TreeSelect)`
  width: 42em;
  margin-left: 680px;
`;
