import React from 'react';
import { Menu } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Icon from '@ant-design/icons';

import history from '../../../../../commons/history';
import menuAdapter from '../Adapter/MenuAdapter';

import { getTranslationCache } from '../../../../../selectors/language';
const { SubMenu } = Menu;

class ThirdLevelComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      selectedMenu: props.selectedMenu,
      thirdLevelMenuItems: [],
      currentMenu: '',
      menuHierarchy: props.menuHierarchy || {},
      translationCache: props.translationCache || []
    };
  }

  componentDidMount() {
    let { selectedMenu, menuHierarchy } = this.props;

    if (selectedMenu && selectedMenu.length > 0 && this.props.menuHierarchy) {
      let thirdLevelMenu =
        menuHierarchy[selectedMenu[0]] &&
        menuHierarchy[selectedMenu[0]].children &&
        menuHierarchy[selectedMenu[0]].children[selectedMenu[1]]
          ? menuHierarchy[selectedMenu[0]].children[selectedMenu[1]].children
          : [];
      let thirdLevelMenuItems = menuAdapter.firstMenuAdapter(thirdLevelMenu);

      if (selectedMenu.length === 3) {
        this.setState({ currentMenu: selectedMenu[2] });
      }
      this.setState({ thirdLevelMenuItems });
    }
  }

  UNSAFE_componentWillReceiveProps({ selectedMenu, menuHierarchy }) {
    this.setState({ selectedMenu, menuHierarchy });
  }

  componentDidUpdate(prevProps) {
    let { selectedMenu, menuHierarchy } = this.state;
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (
      this.props.selectedMenu !== prevProps.selectedMenu &&
      (selectedMenu ? selectedMenu.length !== 0 : false) &&
      this.props.selectedMenu
    ) {
      let thirdLevelMenu =
        menuHierarchy[selectedMenu[0]] &&
        menuHierarchy[selectedMenu[0]].children &&
        menuHierarchy[selectedMenu[0]].children[selectedMenu[1]]
          ? menuHierarchy[selectedMenu[0]].children[selectedMenu[1]].children
          : [];

      let thirdLevelMenuItems = menuAdapter.firstMenuAdapter(thirdLevelMenu);
      if (selectedMenu.length >= 3) {
        this.setState({ currentMenu: selectedMenu[2] });
      }
      this.setState({ thirdLevelMenuItems });
    }
    if (prevProps.menuHierarchy !== this.props.menuHierarchy && this.props.selectedMenu) {
      if (selectedMenu && selectedMenu.length > 0 && this.props.menuHierarchy) {
        let thirdLevelMenu =
          menuHierarchy[selectedMenu[0]] &&
          menuHierarchy[selectedMenu[0]].children &&
          menuHierarchy[selectedMenu[0]].children[selectedMenu[1]]
            ? menuHierarchy[selectedMenu[0]].children[selectedMenu[1]].children
            : [];
        let thirdLevelMenuItems = menuAdapter.firstMenuAdapter(thirdLevelMenu);

        if (selectedMenu.length === 3) {
          this.setState({ currentMenu: selectedMenu[2] });
        }
        this.setState({ thirdLevelMenuItems });
      }
    }
  }
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  getSubMenuOrItem = (item) => {
    const { selectedMenu, translationCache } = this.state;
    if (item.children && Object.keys(item.children).length !== 0) {
      return (
        <SubMenu
          title={
            <span className="submenu-title-wrapper">
              <Icon type={item.icon} />
              {translationCache && translationCache[item.name]
                ? translationCache[item.name]
                : item.name}
            </span>
          }
          key={item.key}
        >
          {this.getNavMenuItems(item.children)}
        </SubMenu>
      );
    }

    return (
      <Menu.Item
        key={item.key}
        onClick={() => {
          selectedMenu.length = 2;
          selectedMenu.push(item.key);
          let menu = [...selectedMenu];
          this.props.actions.setMenuSelection(menu);
          history.push(item.path);
        }}
      >
        {item.icon ? <Icon type={item.icon} /> : null}
        {translationCache && translationCache[item.name] && translationCache[item.name]
          ? translationCache[item.name]
          : item.name}
      </Menu.Item>
    );
  };

  getNavMenuItems = (menusData, parent) => {
    let { translationCache } = this.state;
    if (menusData && !Array.isArray(menusData)) {
      return Object.keys(menusData)
        .map((item) => {
          return this.getSubMenuOrItem(menusData[item], parent);
        })
        .filter((item) => item);
    }

    if (!menusData || !Array.isArray(menusData)) {
      return [];
    }
    return menusData
      .filter((item) =>
        translationCache && translationCache[item.name]
          ? translationCache[item.name]
          : item.name && !item.hideInMenu
      )
      .map((item) => {
        return this.getSubMenuOrItem(item, parent);
      })
      .filter((item) => item);
  };

  render() {
    const { thirdLevelMenuItems, currentMenu } = this.state;
    return thirdLevelMenuItems ? (
      <div style={{ marginTop: '64px' }}>
        <Menu theme="light" mode="horizontal" selectedKeys={[currentMenu]}>
          {this.getNavMenuItems(thirdLevelMenuItems)}
        </Menu>
      </div>
    ) : null;
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ThirdLevelComponent);
