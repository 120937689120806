export default {
  device: 'Device Name',
  timestamp: 'Time Stamp',
  filename: 'File Name',
  fftgenerateddate: 'FFT Generated Date',
  status: 'Status',
  timestamprange: 'Timestamp range',
  showonlygenerated: 'Show Only Generated',
  submit: 'Submit',
  selectdevices: 'Select Device',
  startdatetime: 'Start Date Time',
  enddatetime: 'End Date Time',
  SELECT_HIERARCHY: 'Select Hierarchy'
};
