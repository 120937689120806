/**
 * @author Anvesh
 * @description This Component is mainly for Suppliers Address Book
 * @View Table with crud operations of Suppliers Address
 */
import React, { Component } from 'react';

import { Table, Modal, Button, Input, Form, Row, Col, Select, message, Popconfirm } from 'antd';
import { getTranslationCache } from '../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getSupplierList, createSupplier, updateSupplier, deleteSupplier } from './ApiCall';
//import { getHMICalls } from '../hmicalls'

import { getUserDetails } from '../../../selectors/layout';

import { StyledApplication, StyledTable, lightTheme } from '../style';
import { Divider } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

class SupplierDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierList: [],
      visible: false,
      loading: false,
      editData: false,
      editObj: {},
      translationCache: props.translationCache || []
    };
  }

  componentDidMount = async () => {
    try {
      //let processData = await this._getProcessOptionList();
      let supplierList = await getSupplierList();

      this.setState({
        supplierList: supplierList.data
      });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.UserDetails !== this.props.UserDetails) {
      this.setState({
        userId: this.props.UserDetails
      });
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  addNewAddress = () => {
    this.props.form.resetFields();
    this.setState({
      visible: true,
      editObj: {}
    });
  };
  handleCancel = () => {
    this.props.form.resetFields();
    this.setState({
      visible: false,
      editObj: {},
      editData: false,
      loading: false
    });
  };

  handleSupplierEdit = async (editObj) => {
    this.setState({ editObj, editData: true, visible: true });
  };
  handleSupplierDelete = async (data) => {
    this.setState({ visible: false });
    let payload = { Id: data.Id, Name: data.Name };
    try {
      let deleteStatus = await deleteSupplier(payload);
      message.success(deleteStatus.data.message);
      let supplierList = await getSupplierList();
      this.setState({ supplierList: supplierList.data });
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  editorDidMount = (editor) => {
    this.editor = editor;
  };
  handleOk = async () => {
    let { editData, editObj } = this.state;
    // this.setState({ loading: true });

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (!editData) {
          try {
            let createStatus = await createSupplier(values);
            message.success(createStatus.data.message);
            let supplierList = await getSupplierList();
            this.setState({
              editData: false,
              loading: false,
              visible: false,
              supplierList: supplierList.data
            });
            this.props.form.resetFields();
          } catch (error) {
            message.error(error.response.data.message);
          }
        } else {
          let payload = { ...values, Id: editObj.Id };
          try {
            let updateStatus = await updateSupplier(payload);

            message.success(updateStatus.data.message);
            this.props.form.resetFields();
            let supplierList = await getSupplierList();
            this.setState({
              editData: false,
              loading: false,
              visible: false,
              editObj: {},
              supplierList: supplierList.data
            });
          } catch (error) {
            message.error(error.response.data.message);
          }
        }
      }
    });
    // setTimeout(() => {
    //   this.setState({ loading: false, visible: false });
    // }, 3000);
  };

  render() {
    let { visible, loading } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { editObj, supplierList, editData } = this.state;
    const prefixSelector = (
      <Form.Item initialValue="91" rules={[{ required: true, message: 'Please Select Supplier' }]}>
        <Select>
          <Option value="91">+91</Option>
        </Select>
      </Form.Item>
    );

    const columns = [
      {
        title: 'Supplier Name',
        dataIndex: 'Name',
        key: 'Name'
      },
      {
        title: 'Contact Person',
        dataIndex: 'ContactPerson',
        key: 'ContactPerson'
      },
      {
        title: 'Contact Number',
        dataIndex: 'PhoneNumber',
        key: 'PhoneNumber'
      },
      {
        title: 'Edit',
        key: 'Edit',
        render: (text, record) => (
          <span>
            <EditOutlined
              onClick={() => {
                this.handleSupplierEdit(record);
              }}
            />
          </span>
        )
      },
      {
        title: 'Delete',
        key: 'Delete',
        render: (text, record) => (
          <span>
            <Popconfirm
              placement="topRight"
              title="Are you sure delete this Supplier?"
              onConfirm={(e) => {
                e.stopPropagation();
                this.handleSupplierDelete(record);
              }}
              onCancel={(e) => {
                e.stopPropagation();
              }}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 170 }}>
        <Modal
          title="Supplier Details"
          visible={visible}
          width={'800px'}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,

            <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
              {!editData ? 'Submit' : 'Update'}
            </Button>
          ]}
          onCancel={this.handleCancel}
        >
          <Form layout={'vertical'}>
            <Row gutter={16}>
              <Col span={8}>
                {' '}
                <Form.Item label="Supplier Name :">
                  {getFieldDecorator('Name', {
                    initialValue: editObj.Name,
                    rules: [{ required: true, message: 'Please Enter Supplier Name' }]
                  })(<Input placeholder="Name" />)}
                </Form.Item>
              </Col>

              <Col span={8}>
                {' '}
                <Form.Item label="Contact Person :">
                  {getFieldDecorator('ContactPerson', {
                    initialValue: editObj.ContactPerson,
                    rules: [{ required: true, message: 'Please Enter Contact Name' }]
                  })(<Input placeholder="Contact Name" />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                {' '}
                <Form.Item label="Supplier Code :">
                  {getFieldDecorator('Code', {
                    initialValue: editObj.Code,
                    rules: [{ required: true, message: 'Please Enter Supplier Code' }]
                  })(<Input placeholder="Supplier Code" />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                {' '}
                <Form.Item label="Phone Number">
                  {getFieldDecorator('PhoneNumber', {
                    initialValue: editObj.PhoneNumber
                    //  rules: [{ required: true, message: 'Please input your phone number!' }],
                  })(
                    <Input
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      addonBefore={prefixSelector}
                      maxlength="10"
                      style={{ width: '100%' }}
                      placeholder="Please Enter Phone number"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                {' '}
                <Form.Item label="LandLine Number">
                  {getFieldDecorator('LandLine', {
                    initialValue: editObj.LandLine
                    //  rules: [{ required: true, message: 'Please input your LandLine number!' }],
                  })(
                    <Input
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      style={{ width: '100%' }}
                      placeholder="Please Enter LandLine number"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                {' '}
                <Form.Item label="Mail ID :">
                  {getFieldDecorator('EmailId', {
                    initialValue: editObj.EmailId
                    //  rules: [{ type: 'email', message: "Please Enter Valid Mail ID" }]
                  })(<Input placeholder="Mail ID" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Divider>Supplier Address</Divider>
              {/* <h3>Supplier Address</h3> */}
              <Col span={8}>
                {' '}
                <Form.Item label="Address Line 1 :">
                  {getFieldDecorator('StreetName1', {
                    initialValue: editObj.StreetName1
                    //  rules: [{ required: true, message: "Please Enter Supplier Name" }]
                  })(<Input placeholder="Address Line 1" />)}
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                {' '}
                <Form.Item label="Address Line 2 :">
                  {getFieldDecorator('StreetName2', {
                    initialValue: editObj.StreetName2
                    //  rules: [{ required: true, message: "Please Enter Supplier Name" }]
                  })(<Input placeholder="Address Line 2" />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                {' '}
                <Form.Item label="City/Town:">
                  {getFieldDecorator('City', {
                    initialValue: editObj.City
                    //  rules: [{ required: true, message: "Please Enter City" }]
                  })(<Input placeholder="City" />)}
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                {' '}
                <Form.Item label="State :">
                  {getFieldDecorator('State', {
                    initialValue: editObj.State
                    //  rules: [{ required: true, message: "Please Enter State" }]
                  })(<Input placeholder="State" />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                {' '}
                <Form.Item label="Pin Code / Postal Code:">
                  {getFieldDecorator('PinCode', {
                    initialValue: editObj.PinCode
                    //  rules: [{ required: true, message: "Please Enter Pin Code" }]
                  })(<Input placeholder="Pin Code" />)}
                </Form.Item>
              </Col>
              <Col span={8} offset={1}>
                {' '}
                <Form.Item label="Country:">
                  {getFieldDecorator('Country', {
                    initialValue: editObj.Country
                    //  rules: [{ required: true, message: "Please enter HMI Type" }]
                  })(
                    <Select placeholder="Select Country" style={{ width: '200px' }}>
                      <Option value="India">India</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Button type="primary" onClick={this.addNewAddress} style={{ marginBottom: '10px' }}>
          {' '}
          <PlusOutlined /> Add Supplier Details
        </Button>
        <StyledTable theme={lightTheme} style={{ marginTop: '20px' }}>
          <Table columns={columns} dataSource={supplierList} />
        </StyledTable>
      </StyledApplication>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  UserDetails: getUserDetails(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(SupplierDetails);
