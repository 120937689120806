import React, { Fragment } from 'react';
import _ from 'lodash';
import { Button, Form, Input, message, Space, Tabs, Select, Divider, Table, Upload } from 'antd';

import InputComponent from '../Utils/InputComponent';
import themeSettings from '../Utils/themeSettings.json';
import localStorage from '../../../utils/localStorage';
import translation from '../Utils/translation';
import { StyledForm, StyledComponent, StyledTabs } from '../CSS/style';
import { constant } from '../Utils/constants';
import { IPAddress } from '../../../commons/api';
import Highlighter from 'react-highlight-words';
import '../CSS/index.css';
import { DownloadOutlined, UploadOutlined, SearchOutlined } from '@ant-design/icons';
import {
  getAssetDetailsById,
  getAssetAttributeList,
  saveAsset,
  saveAssetParameter,
  getAssetParameterFileDownloadNameAPI,
  getPriorityList,
  getAssetParametersList
} from '../APICalls';
import SelectWithAddComponent from '../Utils/SelectWithAddComponent';
import SelectComponent from '../Utils/SelectComponent';
const { TabPane } = Tabs;
class Asset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      attributeList: [],
      formType: '',
      parametersList: [],
      loading: false
    };
    this.hRef = React.createRef();
  }

  componentDidMount = async () => {
    this.setData(this.props.ShowForm);
    this.GetDeviceType();
  };
  componentDidUpdate(prevprops) {
    if (
      prevprops.ShowForm !== this.props.ShowForm ||
      prevprops.selectedRow !== this.props.selectedRow
    ) {
      this.setData(this.props.ShowForm);
    }
  }

  setData = async (ShowForm) => {
    let { Module } = this.props;
    let findADDMatch = JSON.stringify(ShowForm).match(`ADD`) !== null;
    let findEDITMatch = JSON.stringify(ShowForm).match(`EDIT`) !== null;

    if (findADDMatch === true) {
      this.onReset();
      let attributeList = await getAssetAttributeList(this.props.formTypeName, Module);
      this.setState({ formType: 'ADD', attributeList });
    } else if (findEDITMatch === true) {
      let { selectedRow, formTypeName } = this.props;
      if (selectedRow.Code) {
        let formObject = await getAssetDetailsById(selectedRow.Code);
        let attributeList = await getAssetAttributeList(formTypeName, Module);
        this.getParameterList(formObject && formObject[0] && formObject[0].AliasCode);
        this.getParameterFileDownloadName(selectedRow.Code);
        // let parametersList = await getAssetParametersList('3_Asset00002')
        this.hRef.current && this.hRef.current.setFieldsValue({ ...(formObject && formObject[0]) });

        this.setState({
          formObject: formObject && formObject[0] ? formObject[0] : {},
          formType: 'EDIT',
          attributeList
        });
      } else {
        message.error('Code missing in hierarchy structure');
      }
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onReset = () => {
    this.hRef.current.resetFields();
    this.hRef.current &&
      this.hRef.current.setFieldsValue({
        Name: null,
        Description: null,
        DisplayCode: null,
        Id: null,
        ManufacturerName: null,
        Section: null,
        ModuleAssetType: null
      });
    this.setState({ formObject: {} });
  };

  onAssetCRUD = async () => {
    let { selectedRow } = this.props;
    const siteId = localStorage.get('currentSite');
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...this.hRef.current.getFieldsValue()
        };
        let payload = {};
        if (values.Id) {
          payload = {
            ...values,
            ModuleCode: this.props.Module,
            ParentAsset:
              this.props.parent && this.props.parent.Type === 'Module'
                ? null
                : this.props.parent.Code
          };
        } else {
          delete values.Id;
          payload = {
            ...values,
            ModuleCode: this.props.Module,
            AssetType: selectedRow && selectedRow.LevelType ? selectedRow.LevelType : '',
            ParentAsset: selectedRow && selectedRow.Type === 'Module' ? null : selectedRow.Code,
            AssetGroup: '',
            SiteId: siteId
          };
        }

        let saveResponse = await saveAsset(payload);
        if (saveResponse && saveResponse.message) {
          message.success(saveResponse.message);
          this.props.GetHierarchyLevelsUpdated();
          this.onReset();
        }
      })
      .catch(() => {});
  };

  onFieldChange = async (fieldValue, fieldName) => {
    let { formObject } = this.state;
    this.setState({
      formObject: {
        ...formObject,
        [fieldName]: fieldValue
      }
    });
    this.hRef.current &&
      this.hRef.current.setFieldsValue({
        [fieldName]: fieldValue
      });
  };

  renderWidget = (props) => {
    let { widget, Code, isEditableOnUpdate, API } = props;
    let { formObject } = this.state;
    switch (widget) {
      case 'selectWithAdd':
        return (
          <SelectWithAddComponent
            value={formObject && formObject[Code] ? formObject[Code] : ''}
            Code={props && props.Code ? props.Code : ''}
            formObject={this.state && this.state.formObject ? this.state.formObject : ''}
            api={API}
            ModuleCode={this.props.Module}
            onSelectChange={(e) => this.onFieldChange(e, Code)}
          />
        );
      case 'select':
        return (
          <SelectComponent
            value={formObject && formObject[Code] ? formObject[Code] : ''}
            Code={props && props.Code ? props.Code : ''}
            formObject={this.state && this.state.formObject ? this.state.formObject : ''}
            api={API}
            ModuleCode={this.props.Module}
            onSelectChange={(e) => this.onFieldChange(e, Code)}
          />
        );
      default:
        return (
          <InputComponent
            value={
              formObject && formObject[Code]
                ? translation(this.props.translationCache, formObject[Code])
                : ''
            }
            disabled={formObject.Id && isEditableOnUpdate === 'true' ? true : false}
            onChange={(e) => this.onFieldChange(e.target.value, Code)}
          />
        );
    }
  };

  formSentence = (formTypeName, selectedRow, formType) => {
    if (formTypeName && formTypeName === 'CAR') {
      if (formType === 'ADD') {
        return `${_.startCase(formTypeName.toLowerCase())} creation for ${
          selectedRow.Name ? selectedRow.Name : ''
        }`;
      } else {
        return `Edit ${formTypeName.toLowerCase()} details for ${
          selectedRow.Name ? selectedRow.Name : ''
        }`;
      }
    } else {
      if (formType === 'ADD') {
        return `Creation of ${selectedRow.Name ? selectedRow.Name : ''}`;
      } else {
        return `Edit details for ${selectedRow.Name ? selectedRow.Name : ''}`;
      }
    }
  };
  getParameterFileDownloadName = async (AssetAlias) => {
    let parameterFileName = await getAssetParameterFileDownloadNameAPI(AssetAlias);
    this.setState({
      parameterFileName: parameterFileName && parameterFileName.file ? parameterFileName.file : ''
    });
  };
  GetDeviceType = async () => {
    // let deviceTypeList = await getDeviceTypeList(module);
    let priorityList = await getPriorityList();
    this.setState({ priorityList });
  };
  getParameterList = async (DeviceAlias) => {
    let parametersList = await getAssetParametersList(DeviceAlias);
    let DuplicateList = await getAssetParametersList(DeviceAlias);
    // let newObj={};
    parametersList &&
      Array.isArray(parametersList) &&
      parametersList.map((record) => {
        record['Editable'] = 'false';
        return {};
      });
    DuplicateList &&
      Array.isArray(DuplicateList) &&
      DuplicateList.map((record) => {
        record['Editable'] = 'false';
        return {};
      });
    this.setState({ parametersList, DuplicateList });
  };

  saveRow = async (e, record) => {
    let { selectedRow } = this.props;
    this.setState({ loading: true });
    let payload = {
      ...record,
      AssetAlias: selectedRow && selectedRow.Code
    };

    let t1 = await saveAssetParameter(payload, selectedRow && selectedRow.Code);
    message.success(t1 && t1.message);
    let uot = this.getParameterList(selectedRow && selectedRow.Code);
    this.getParameterFileDownloadName(selectedRow && selectedRow.Code);
    if (uot) {
      record['Editable'] = 'false';
      this.setState({ loading: false, parametersList: uot });
    }
  };
  getRowByKey(key, newData) {
    const { parametersList } = this.state;
    return (newData || parametersList).filter((item) => item.id === key)[0];
  }
  handleFieldChange(value, fieldName) {
    let { SlectedRow, parametersList } = this.state;
    let data = parametersList;
    let EditedRecord = data.filter((item) => {
      // if (item.Code === SlectedRow) {
      //   return item;
      // }
      return item.Code === SlectedRow;
    });
    let Record = EditedRecord && EditedRecord[0];
    Record[fieldName] = value;

    // let FinalData = parametersList.find((obj) => obj.Code === SlectedRow);
    // Object.assign(FinalData, Record);

    this.setState({ Record });
  }
  cancel(e, record) {
    let { DuplicateList } = this.state;
    record['Editable'] = 'false';
    this.setState({ parametersList: DuplicateList });
  }
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  editRow = (key, record) => {
    record['Editable'] = 'true';
    this.setState({
      record,
      SlectedRow: key
    });
  };
  handleKeyPress(e, key) {
    if (e.key === 'Enter') {
      this.saveRow(e, key);
    }
  }
  render() {
    let { ShowForm, translationCache, selectedRow, formTypeName } = this.props;
    let { formObject, attributeList, formType, parametersList, priorityList, parameterFileName } =
      this.state;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
        ...this.getColumnSearchProps('Name')
      },
      {
        title: 'Description',
        dataIndex: 'ParameterDescription',
        key: 'ParameterDescription',
        ...this.getColumnSearchProps('ParameterDescription'),
        render: (text, record) => {
          if (record.Editable === 'true') {
            return (
              <Input
                value={text}
                onChange={(e) =>
                  this.handleFieldChange(e.target.value, 'ParameterDescription', record.Code)
                }
                onKeyPress={(e) => this.handleKeyPress(e, record.id)}
                placeholder={'Description'}
              />
            );
          } else {
            return <div>{text}</div>;
          }
        }
      },
      {
        title: 'Edge Code',
        dataIndex: 'EdgeCode',
        key: 'EdgeCode',
        ...this.getColumnSearchProps('EdgeCode'),
        render: (text, record) => {
          if (record.Editable === 'true') {
            return (
              <Input
                value={record.EdgeCode}
                onChange={(e) => this.handleFieldChange(e.target.value, 'EdgeCode', record.Code)}
                onKeyPress={(e) => this.handleKeyPress(e, record.Code)}
                placeholder={'EdgeCode'}
              />
            );
          } else {
            return <div>{text}</div>;
          }
        }
      },
      {
        title: 'Priority',
        dataIndex: 'Priority',
        key: 'Priority',
        ...this.getColumnSearchProps('Priority'),
        render: (text, record) => {
          if (record.Editable === 'true') {
            return (
              <Select
                style={{ width: 150 }}
                value={record.Priority}
                onChange={(value) => this.handleFieldChange(value, 'Priority', record.Code)}
                placeholder={'Priority'}
              >
                {priorityList &&
                  Array.isArray(priorityList) &&
                  priorityList.map((priority, index) => {
                    return (
                      <Select.Option key={index} value={priority.Value}>
                        {priority.Key}
                      </Select.Option>
                    );
                  })}
              </Select>
            );
          } else {
            return <div>{text}</div>;
          }
        }
      },
      {
        title: 'Vendor Code',
        dataIndex: 'VendorCode',
        key: 'VendorCode',
        ...this.getColumnSearchProps('VendorCode'),
        render: (text, record) => {
          if (record.Editable === 'true') {
            return (
              <Input
                value={record.VendorCode}
                onChange={(e) => this.handleFieldChange(e.target.value, 'VendorCode', record.Code)}
                onKeyPress={(e) => this.handleKeyPress(e, record.Code)}
                placeholder={'Vendor Code'}
              />
            );
          } else {
            return <div>{text}</div>;
          }
        }
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => {
          if (record.Editable === 'false') {
            return (
              <span>
                <Button onClick={() => this.editRow(record.Code, record)}>{'Edit'}</Button>
              </span>
            );
          } else {
            if (record.Editable === 'true') {
              return (
                <span>
                  <Button onClick={(e) => this.saveRow(e, record)}>
                    {this.translation('Save')}{' '}
                  </Button>
                  <Divider type="vertical" />
                  <Button onClick={(e) => this.cancel(e, record)}>
                    {this.translation('Cancel')}
                  </Button>
                </span>
              );
            }
          }
        }
      }
    ];
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '0px 30px', minHeight: window.innerHeight - 114 }}
      >
        <StyledTabs
          tabPosition="top"
          type="card"
          style={{ marginBottom: '30px' }}
          theme={themeSettings}
          className="Modules"
        >
          <TabPane tab={this.formSentence(formTypeName, selectedRow, formType)} key="1">
            <Fragment>
              <StyledForm
                name="Item"
                ref={this.hRef}
                theme={themeSettings}
                initialValues={formObject}
                layout="horizontal"
                labelCol={{ flex: '130px', marginTop: '20px' }}
                labelAlign="right"
                labelWrap
              >
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '24px' }}>
                  <Form.Item name={'Id'}></Form.Item>
                  <Form.Item
                    name="DisplayCode"
                    style={{ width: '50%' }}
                    label={translation(translationCache, constant.Code)}
                    rules={[
                      {
                        required: true,
                        message: 'Please input Code!'
                      }
                    ]}
                  >
                    <InputComponent
                      placeholder="Input Code!"
                      value={
                        formObject && formObject.DisplayCode
                          ? translation(translationCache, formObject.DisplayCode)
                          : ''
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="Name"
                    style={{ width: '50%' }}
                    label={translation(translationCache, constant.Name)}
                    rules={[
                      {
                        required: true,
                        message: 'Please input Name!'
                      }
                    ]}
                  >
                    <InputComponent
                      placeholder="Input Name!"
                      value={
                        formObject && formObject.Name
                          ? translation(translationCache, formObject.Name)
                          : ''
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="Description"
                    style={{ width: '50%' }}
                    label={translation(translationCache, constant.Description)}
                    rules={[
                      {
                        required: true,
                        message: 'Please input Description!'
                      }
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Input Description!"
                      value={
                        formObject && formObject.Description
                          ? translation(translationCache, formObject.Description)
                          : ''
                      }
                    />
                  </Form.Item>
                  {attributeList &&
                    Array.isArray(attributeList) &&
                    attributeList.map((attr, key) => {
                      let requiredField = attr && attr.mandatory === 'Optional' ? false : true;
                      // if (attr && attr.showOnUpdate && attr.showOnUpdate !== false && formObject && formObject.Id) {
                      return (
                        <Form.Item
                          key={key}
                          name={attr.Code}
                          label={translation(translationCache, attr.Name)}
                          style={{ width: '50%' }}
                          rules={[
                            { required: requiredField, message: `Please enter  ${attr.Name}!` }
                          ]}
                        >
                          {this.renderWidget(attr, formObject)}
                        </Form.Item>
                      );
                      // }
                    })}
                </div>

                <Form.Item {...{ wrapperCol: { offset: 5, span: 16 } }}>
                  <Space>
                    {JSON.stringify(ShowForm).match(`ADD`) !== null ? (
                      <Button type="primary" onClick={this.onAssetCRUD}>
                        {`Create`}
                      </Button>
                    ) : (
                      <Button type="primary" onClick={this.onAssetCRUD}>
                        {`Update`}
                      </Button>
                    )}
                    <Button htmlType="button" onClick={this.onReset}>
                      Reset
                    </Button>
                  </Space>
                </Form.Item>
              </StyledForm>
            </Fragment>
          </TabPane>
          <TabPane tab={this.translation('Parameters')} key="2">
            {(parameterFileName && parameterFileName !== undefined) ||
            (parameterFileName && parameterFileName !== '') ? (
              <div style={{ display: 'flex', justifyContent: 'right', marginTop: '24px' }}>
                <a href={`${IPAddress.LOCAL}${parameterFileName}`}>
                  <DownloadOutlined
                    style={{ color: 'white', fontSize: '16px', marginRight: '6px' }}
                  />
                  <span style={{ color: 'white', marginRight: '30px' }}>Download </span>
                </a>
                <Upload
                  multiple={false}
                  style={{ marginRight: '10px', color: 'white' }}
                  onChange={(info) => {
                    if (info.file.status === 'done') {
                      message.success(info.file.response.message);

                      this.getParameterList(selectedRow && selectedRow.Code);
                      this.getParameterFileDownloadName(selectedRow && selectedRow.Code);
                    } else if (info.file.status === 'error') {
                      message.error(info.file.response.message);
                    }
                  }}
                  action={`/api/client/upload/assetParameter?SiteId=${siteId}&AssetAlias=${selectedRow.Code}`}
                  headers={{
                    Authorization: Authorization
                  }}
                  showUploadList={false}
                >
                  <UploadOutlined
                    style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                  />
                  <span style={{ color: 'white', marginRight: '10px' }}> Upload </span>
                </Upload>
              </div>
            ) : null}
            <Table
              style={{ marginTop: '10px' }}
              loading={this.state.loading}
              columns={columns}
              dataSource={parametersList && Array.isArray(parametersList) ? parametersList : null}
            />
          </TabPane>
        </StyledTabs>
      </StyledComponent>
    );
  }
}
export default Asset;
