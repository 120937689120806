/* eslint-disable no-undef */
import localStorage from '../../../../../utils/localStorage';
import axios from 'axios';
import { message } from 'antd';
import {
  API_MASTERCONFIGURATION,
  API_MASTERWORKORDERS,
  API_HIERARCHYMODULE,
  API_SITE,
  API_DEVICE
} from '../../../../../commons/api';
// ===========================Dropdown Api's===========================
export async function getDeviceList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const getDeviceList = {
    method: 'GET',
    url: `${API_DEVICE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceList)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function getHierarchyList() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let headers = {
    method: 'GET',
    url: `${API_HIERARCHYMODULE.MainHierarchyList}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(headers)
    .then(({ data }) => {
      if (Array.isArray(data)) {
        //this.setState({ datasource: data });
        return data;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}
export async function getManufacturerList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  var Authorization = 'Bearer ' + token;
  let data = {
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Manufacturers',
      ActionType: 'read'
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
export async function getMasterList(url) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Object = {};
  Object = {
    method: 'GET',
    url: `${url}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getQRById(url, id, method) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: `${method}`,
    url: `${url}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { ScannerId: id }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getScannerId(EquipmentId) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  let headers = {
    method: 'POST',
    url: `${API_DEVICE.GENERATESCANNERID}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { DeviceCode: EquipmentId }
  };
  return axios(headers)
    .then((response) => {
      return response.data;
    })
    .catch(function () {});
}
export async function getSitesList() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getSitesList = {
    method: 'GET',
    url: `${API_SITE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getSitesList)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function submitMaster(url, payload, fileData, type) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${url}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${response.data.Id}&Type=${type}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch(() => {});
      } else {
        return response.data;
      }
    })
    .catch((error) => {
      if (error.response) {
        message.error(error.response.data.message);
      }
    });
}
