import React from 'react';
import { Select, message, Input, TreeSelect, Button } from 'antd';
import axios from 'axios';
import localStorage from '../../../utils/localStorage';
import 'antd/lib/date-picker/style/css';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const { TreeNode } = TreeSelect;
// export default function DeviceRuleView(props) {
export default class Condition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getDeviceList(this.props.assetValue, this.props.assetType, this.props.HierarchyCode);
  }

  componentDidUpdate(prevprops) {
    if (prevprops.assetValue !== this.props.assetValue) {
      this.getDeviceList(this.props.assetValue, this.props.assetType, this.props.HierarchyCode);
    }
  }

  onDeviceChange = (deviceId) => {
    this.getParameterList(deviceId);
  };

  getDeviceList = (assetValue, assetType, HierarchyCode) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'GET',
      url: `/api/statusMapping/getDevicesList?SiteId=${siteId}&Code=${assetValue}&Type=${assetType}&HierarchyCode=${HierarchyCode}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(Object)
      .then((response) => {
        this.setState({
          deviceList: response.data
        });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.info(err.response.data.message);
        }
      });
  };

  getParameterList = (deviceAlias) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'GET',
      url: `/api/parameter/list?SiteId=${siteId}&DeviceAlias=${deviceAlias}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(Object)
      .then((response) => {
        let parameterList = [];
        parameterList =
          response.data &&
          Array.isArray(response.data) &&
          response.data.map((param) => {
            return {
              label: param.ParameterCode,
              value: param.ParameterAlias
            };
          });
        this.setState({
          parameterList
        });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.info(err.response.data.message);
        }
      });
  };

  onSelectChange = (value, rowKey, ifIndex, key) => {
    if (key === 'Device') {
      this.onDeviceChange(value);
    }
    this.props.onSelectChange(value, rowKey, ifIndex, key);
  };

  renderDeviceTreeNodes = (data) =>
    data &&
    Array.isArray(data) &&
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode value={item.Code} title={item.Name} key={item.Code} dataRef={item}>
            {this.renderDeviceTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode value={item.Code} key={item.Code} title={item.Name} {...item} />;
    });

  render() {
    let { index, rowKey, ifCond, ifIndex, Operator, ifCondition } = this.props;
    let { deviceList, parameterList } = this.state;
    return (
      <>
        <div style={{ margin: '10px' }} key={index}>
          {index === 0 ? <div style={{ color: 'white', marginBottom: '3px' }}>IF</div> : null}
          <TreeSelect
            showSearch
            style={{ width: 200, marginRight: '5px' }}
            value={ifCond.Device}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder={'Select Device'}
            allowClear={false}
            treeDefaultExpandAll
            onChange={(value) => {
              this.onSelectChange(value, rowKey, ifIndex, 'Device');
            }}
          >
            {this.renderDeviceTreeNodes(deviceList)}
          </TreeSelect>

          <Select
            style={{ width: 170, marginRight: '5px' }}
            placeholder={'Select parameter'}
            value={ifCond.Parameter}
            onChange={(value) => this.onSelectChange(value, rowKey, ifIndex, 'Parameter')}
            options={parameterList}
          />
          <Select
            style={{ width: 170, marginRight: '5px' }}
            placeholder={'Select operator'}
            value={ifCond.Operator}
            onChange={(value) => this.onSelectChange(value, rowKey, ifIndex, 'Operator')}
            options={Operator}
          />
          <Input
            style={{ width: 170, marginRight: '5px' }}
            value={ifCond.Value}
            placeholder="Select value"
            onChange={(e) => this.onSelectChange(e.target.value, rowKey, ifIndex, 'Value')}
          />
          {Object.keys(ifCondition)[Object.keys(ifCondition).length - 2] === rowKey ? (
            <>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => this.props.addNewCondition(ifIndex)}
              />
            </>
          ) : null}
          {Object.keys(ifCondition)[Object.keys(ifCondition).length - 2] === rowKey &&
          Object.keys(ifCondition).length !== 2 ? (
            <>
              <Button
                style={{ marginLeft: '5px' }}
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => this.props.deleteCondition(ifIndex, rowKey)}
              />
            </>
          ) : null}
        </div>
      </>
    );
  }
}
