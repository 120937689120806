import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { Form, Select, TimePicker } from 'antd';
import translation from '../../../Utils/translation';
import { constant } from '../../../Utils/constants';
import { getTranslationCache } from '../../../../../selectors/language';
import { StyledButton, StyledForm } from '../../../CSS/style';
import InputComponent from '../../../Widgets/InputComponent';
import InputNumberComponent from '../../../Widgets/InputNumberComponent';
import { checkDecimalValue } from '../../../Utils/FormCalls';
const { Option } = Select;

class WFHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: props.record
    };
  }

  form2 = React.createRef();
  componentDidUpdate = (prevprops) => {
    if (prevprops.record !== this.props.record) {
      let record = {
        ...this.props.record,
        'Min&Sec':
          this.props.record && this.props.record['Min&Sec'] !== undefined
            ? moment(this.props.record && this.props.record['Min&Sec'], 'mm:ss')
            : ''
      };
      this.setState({ record });
      this.form2.current.setFieldsValue(record);
    }
  };
  render() {
    let { record } = this.state;
    let { themeSettings, translationCache, workTypes } = this.props;
    return (
      <StyledForm
        name="WFHeader"
        theme={themeSettings}
        ref={this.form2}
        onFinish={this.props.onFinish}
        onKeyDown={(e) => {
          e.key === 'Enter' && e.preventDefault();
        }}
        initialValues={record || {}}
        layout="horizontal"
        labelCol={{ flex: '130px' }}
        labelAlign="right"
        labelWrap
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Form.Item
            label={translation(translationCache, constant.workFlowName)}
            name="WorkFlowName"
            style={{ width: '100%', flex: '50%' }}
            rules={[
              {
                required: true,
                message: translation(translationCache, 'Please Input Work Flow Name!')
              }
            ]}
          >
            <InputComponent
              placeholder={translation(translationCache, 'Input Work Flow Name')}
              style={{ width: '70%' }}
            />
          </Form.Item>

          <Form.Item
            label={translation(translationCache, constant.workFlowType)}
            name="WorkTypeId"
            style={{ width: '100%', flex: '50%' }}
            rules={[
              {
                required: true,
                message: translation(translationCache, 'Please Select Work Type!')
              }
            ]}
          >
            <Select
              placeholder={translation(translationCache, 'Select Work Type')}
              style={{ width: '70%' }}
            >
              {workTypes &&
                Array.isArray(workTypes) &&
                workTypes.map((param, index) => {
                  return (
                    <Option key={index} value={param.Value}>
                      {translation(translationCache, param.Key)}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            label={translation(translationCache, constant.hours)}
            name="Hours"
            style={{ width: '100%', flex: '50%' }}
            rules={[
              { required: false, message: 'Please Enter Hours!' },
              {
                validator: (_, value) =>
                  checkDecimalValue(_, value, 'Hours', record, { validateDecimal: true })
              }
            ]}
          >
            <InputNumberComponent
              placeholder={translation(translationCache, 'Input Hours')}
              min={0}
              max={24}
              style={{ width: '70%' }}
            />
          </Form.Item>

          <Form.Item
            name="Min&Sec"
            style={{ width: '100%', flex: '50%' }}
            label={translation(translationCache, constant.minSec)}
            rules={[
              {
                type: 'object',
                required: true,
                message: 'Please select time!'
              }
            ]}
          >
            <TimePicker
              placeholder={translation(translationCache, constant.selectTime)}
              format={'mm:ss'}
              style={{ width: '70%' }}
              value={null}
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Form.Item>
            <StyledButton theme={themeSettings} htmlType="submit">
              {record && record.Id
                ? translation(translationCache, constant.update)
                : translation(translationCache, constant.save)}
            </StyledButton>
          </Form.Item>
        </div>
      </StyledForm>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WFHeader);
