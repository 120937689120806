/**
 * @author Anvesh B
 * @description This Component is used for EAM WOrk Flow
 * @view flowchart and table view for list of workflow in tab view
 */
import React, { useState, useEffect } from 'react';
import { Button, Drawer, Form, Select, message } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { decode as base64_decode } from 'base-64';
import Flowchart from 'flowchart-react';

import {
  getWorkTypesWF,
  getRoles,
  insertWF,
  updateWF,
  getCommunication,
  getStatusListWF,
  getStatus,
  getWorkFlowById
} from '../../../Utils/FormCalls';
import '../../../CSS/workFlow.css';
import history from '../../../../../commons/history';
import translation from '../../../Utils/translation';
import themeSettings from '../../../Utils/themeSettings.json';
import { getTranslationCache } from '../../../../../selectors/language';
import { constant } from '../../../Utils/constants';
import { StyledComponent } from '../../../CSS/style';
import InputComponent from '../../../Widgets/InputComponent';
import { getUserProfile } from '../../../../../selectors/layout';
import WFHeader from './WFHeader';
const { Option } = Select;

const WorkFlow = (props) => {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  // const [form2] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [check, setCheck] = useState(false);
  const [nodeData, setNodeData] = useState({});
  const [nodes, setNodes] = useState([]);
  const [conns, setConns] = useState([]);
  const [connect, setConnect] = useState({});
  const [workTypes, setWorkTypes] = useState([]);
  const [roles, setRoles] = useState([]);
  // const [workFlowList, setWFList] = useState([]);
  const [record, setrecordData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [orderStatus, setWOStatus] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [communication, setCommunication] = useState([]);
  const [decisionId, setDecisionId] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [translationCache, setTranslationCache] = useState(props.translationCache || {});
  const onClose = () => {
    setVisible(false);
    setCheck(false);
    form1.resetFields();
    form.resetFields();
  };

  useEffect(() => {
    (async () => {
      _getDataById();
      try {
        let worktype = await getWorkTypesWF();
        setWorkTypes(worktype.data);
      } catch (error) {
        message.error(`${error.response.data.message}`);
      }
      try {
        let role = await getRoles();
        setRoles(role.data);
      } catch (error) {
        message.error(`${error.response.data.message}`);
      }
      try {
        let woStatus = await getStatus();
        setWOStatus(woStatus);
      } catch (error) {
        message.error(`${error.response.data.message}`);
      }
      try {
        let communication = await getCommunication();
        setCommunication(communication.data);
      } catch (error) {
        message.error(`${error.response.data.message}`);
      }
      try {
        let statusList = await getStatusListWF();
        setStatusList(statusList.data);
      } catch (error) {
        message.error(`${error.response.data.message}`);
      }
    })();
  }, []);

  useEffect(() => {
    form.resetFields();
    form1.resetFields();
    // form2.resetFields();
  }, [nodeData, connect, record]);

  const _getDataById = async () => {
    let path = history.location.pathname.split('/rubus/AMM/WorkFlowDetails/');
    let formId = base64_decode(path[1]);
    if (formId !== 'new') {
      let record = await getWorkFlowById(base64_decode(path[1]));

      if (record && record.Node) {
        setNodes(record.Node.nodes);
        setConns(record.Node.connections);
      }
      setrecordData(record);
    }
    //  else {
    //   // let record = await getWorkFlowById(Id);

    //   if (record && record.Node) {
    //     setNodes(record.Node.nodes);
    //     setConns(record.Node.connections);
    //   }

    //   setrecordData(record);
    // }
  };

  const handleNode = (node) => {
    setNodeData(node);
    setVisible(true);
  };
  const handleConnection = (connection) => {
    setCheck(true);
    setConnect(connection);
  };
  const getLastTwoDuplicates = (array) => {
    const countMap = new Map();
    const result = [];

    // Iterate through the array in reverse order
    for (let i = array.length - 1; i >= 0; i--) {
      const item = array[i];
      const keyValue = item.source.id;
      // If the key is already in the countMap, increment the count
      if (countMap.has(keyValue)) {
        countMap.set(keyValue, countMap.get(keyValue) + 1);
      } else {
        countMap.set(keyValue, 1);
      }
      // If the count is less than or equal to 2, push the item to the result
      if (countMap.get(keyValue) <= 2) {
        result.push(item);
      }
    }
    // Since we added items in reverse order, reverse the result to maintain original order
    return result.reverse();
  };
  const getLastDuplicateObjects = (array) => {
    const uniqueMap = new Map();
    for (let i = array.length - 1; i >= 0; i--) {
      const item = array[i];
      if (!decisionId.includes(item && item.source && item.source.id)) {
        const keyValue = item.source.id;
        if (!uniqueMap.has(keyValue)) {
          uniqueMap.set(keyValue, item);
        }
      }
    }
    return Array.from(uniqueMap.values());
  };

  const onFinishMAin = async (values) => {
    let userId =
      props.userProfile && props.userProfile.user && props.userProfile.user.Id
        ? props.userProfile.user.Id
        : '';
    let payload = {
      ...values,
      ...(values && !values.Id ? { CreatedBy: userId } : {}),
      ...(values && values.Id ? { UpdatedBy: userId } : {}),
      'Min&Sec': values && values['Min&Sec'] ? values['Min&Sec'].format('mm:ss') : '',
      nodesData: { nodes: nodes, connections: conns }
    };
    // try {
    if (record && record.Id) {
      let statusResponse = await updateWF({
        ...payload,
        Id: record && record.Id ? record.Id : undefined
      });
      if (statusResponse && statusResponse.message) {
        // _getDataById(statusResponse.Id);
        message.success(`WorkFlow Updated Successfully`);
      } else if (statusResponse && statusResponse.Error) {
        message.error(statusResponse.Error);
      }
    } else {
      let statusResponse = await insertWF(payload);
      if (statusResponse && statusResponse.message) {
        // _getDataById(statusResponse.Id);
        let record = await getWorkFlowById(statusResponse.Id);

        if (record && record.Node) {
          setNodes(record.Node.nodes);
          setConns(record.Node.connections);
        }

        setrecordData(record);
        message.success(`WorkFlow Created Successfully`);
      } else if (statusResponse && statusResponse.Error) {
        message.error(statusResponse.Error);
      }
    }
    // }
    //  catch (error) {
    // message.error(`${error.response.data.message}`);
    // }
  };
  const onFinish = (values) => {
    form.resetFields();
    const newState =
      nodes &&
      Array.isArray(nodes) &&
      nodes.map((obj) => {
        if (obj.id === nodeData.id) {
          return {
            ...obj,
            title: values.title,
            role: values.role,
            email: values.email,
            communicationType: values.communicationType
          };
        }
        return obj;
      });
    setNodes(newState);
    setNodeData({});
    setVisible(false);
  };
  const onFinishCon = (values) => {
    form1.resetFields();
    const newState =
      conns &&
      Array.isArray(conns) &&
      conns.map((obj) => {
        if (
          obj.source.id === connect.source.id &&
          obj.source.position === connect.source.position
        ) {
          return { ...obj, type: values.type, status: values.status };
        }
        return obj;
      });
    setConnect({});
    setConns(newState);

    setCheck(false);
  };
  return (
    <>
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 73 }}>
        <Drawer
          className="WorkFlowDrawer"
          style={{ color: 'black' }}
          title={translation(translationCache, constant.nodeData)}
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          <Form form={form} initialValues={nodeData} layout="vertical" onFinish={onFinish}>
            <Form.Item name="id" label={translation(translationCache, constant.Id)}>
              <InputComponent disabled />
            </Form.Item>
            {nodeData && nodeData.type && nodeData.type !== 'start' ? (
              <Form.Item
                name="title"
                label={translation(translationCache, constant.title)}
                rules={[{ required: true, message: 'Please Input your Title!' }]}
              >
                <InputComponent placeholder={translation(translationCache, 'Input Title ')} />
              </Form.Item>
            ) : null}
            {nodeData && nodeData.type && nodeData.type === 'start' ? (
              <Form.Item
                name="title"
                label={translation(translationCache, constant.title)}
                rules={[{ required: true, message: 'Please Input your Title!' }]}
              >
                <Select placeholder={translation(translationCache, 'Select Option ')}>
                  <Option value="Start">{translation(translationCache, constant.start)}</Option>
                  <Option value="End">{translation(translationCache, constant.end)}</Option>
                </Select>
              </Form.Item>
            ) : null}
            <Form.Item
              name="role"
              label={translation(translationCache, constant.role)}
              rules={[{ required: false, message: 'Please Select Role!' }]}
            >
              <Select placeholder={translation(translationCache, 'Select Role')}>
                {roles &&
                  Array.isArray(roles) &&
                  roles.map((param, index) => {
                    return (
                      <Option key={index} value={param.Value}>
                        {' '}
                        {param.Key}{' '}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              name="communicationType"
              label={translation(translationCache, constant.communicationType)}
            >
              <Select
                placeholder={translation(translationCache, constant.selectOption)}
                mode="multiple"
                rules={[{ required: false, message: 'Please Select Role!' }]}
                showArrow={true}
              >
                {communication &&
                  Array.isArray(communication) &&
                  communication.map((type, index) => {
                    return (
                      <Option key={index} value={type.Value}>
                        {translation(translationCache, type.Key)}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {' '}
                {translation(translationCache, constant.submit)}{' '}
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
        <Drawer
          className="WorkFlowDrawer"
          title={translation(translationCache, constant.connectionData)}
          placement="right"
          onClose={onClose}
          visible={check}
        >
          <Form form={form1} initialValues={connect} layout="vertical" onFinish={onFinishCon}>
            <Form.Item
              name="type"
              label={translation(translationCache, constant.type)}
              rules={[
                { required: true, message: translation(translationCache, 'Please Select Type!') }
              ]}
            >
              <Select placeholder={translation(translationCache, 'Select a option')}>
                <Option value="fail">{translation(translationCache, constant.fail)}</Option>
                <Option value="success">{translation(translationCache, constant.success)}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="status"
              label={translation(translationCache, constant.status)}
              rules={[
                { required: true, message: translation(translationCache, 'Please Select status!') }
              ]}
            >
              <Select placeholder={translation(translationCache, 'Select a option')}>
                {' '}
                {statusList &&
                  Array.isArray(statusList) &&
                  statusList.map((param, index) => {
                    return (
                      <Option key={index} value={param.Key}>
                        {translation(translationCache, param.Key)}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {' '}
                {translation(translationCache, constant.submit)}{' '}
              </Button>
            </Form.Item>
          </Form>
        </Drawer>

        <div>
          <>
            <WFHeader
              themeSettings={themeSettings}
              record={record}
              onFinish={onFinishMAin}
              translationCache={translationCache}
              workTypes={workTypes}
            />
            <Flowchart
              onChange={(nodes, connections) => {
                ////=======function to check duplicate objects are present in array or not======
                function hasDuplicates(array) {
                  const ids = new Set();
                  for (const item of array) {
                    if (ids.has(item.source.id)) {
                      return true; // Duplicate found
                    }
                    ids.add(item.source.id);
                  }
                  return false; // No duplicates found
                }
                const hasDuplicatesval = hasDuplicates(connections);

                if (hasDuplicatesval === true) {
                  //==== to get object id's in array which type is decision =====
                  let decisionId = [];
                  nodes &&
                    Array.isArray(nodes) &&
                    nodes.map((node) => {
                      if (node && node.type && node.type === 'decision') {
                        return decisionId.push(node.id);
                      }
                    });
                  setDecisionId(decisionId);
                  //====To get only unique objects in which type is 'decision'======
                  let decisionObj = connections.filter(function (item) {
                    return (
                      decisionId.find(function (item2) {
                        if (item.source.id !== item2) {
                          return item;
                        }
                      }) === undefined
                    );
                  });

                  //===== To get last two occurence objects of 'decision' type =======

                  const lastTwoDuplicates = getLastTwoDuplicates(decisionObj);

                  // To get the last duplicate objects in which type is not 'decision':

                  const lastDuplicateObjects = getLastDuplicateObjects(connections);
                  let finalArray = lastDuplicateObjects.concat(lastTwoDuplicates);

                  setNodes(nodes);
                  setConns(finalArray);
                } else {
                  setNodes(nodes);
                  setConns(connections);
                }
              }}
              showToolbar
              onNodeDoubleClick={handleNode}
              onConnectionDoubleClick={handleConnection}
              style={{ width: '100%', height: 500 }}
              nodes={nodes}
              connections={conns}
            />
          </>
        </div>
      </StyledComponent>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WorkFlow);
