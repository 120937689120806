import React, { Component } from 'react';
import { Tree, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';

// import localStorage from '../../../utils/localStorage';

// const { TreeNode } = Tree;

class HierarchyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      value: [],
      autoExpandParent: true.valueOf,
      expandedKeys: [],
      datasource: [],
      checkedKeys: [],
      selectedKeys: this.props && this.props.selectedKeys ? this.props.selectedKeys : [],
      deviceList: this.props && this.props.deviceList ? this.props.deviceList : [],
      treeData: this.getMenuHierarchyStructure(
        this.props && this.props.treeData ? this.props.treeData : []
      ),
      checkedArray: this.props && this.props.selectedDevice ? this.props.selectedDevice : []
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.selectedKeys !== this.props.selectedKeys) {
      this.setState({
        selectedKeys: this.props.selectedKeys
      });
    }
  }

  getMenuHierarchyStructure = (treeData) => {
    // const userData = JSON.parse(localStorage.get('modules'));
    if (treeData) {
      let final = treeData.map((node) => {
        // return this.renameProperty(node, 'Code', 'title');
        return this.renameProperty(node, 'Name', 'title', 'Code', 'key');
      });
      return final;
    }
  };
  renameProperty = (obj, oldName1, newName1, oldName2, newName2) => {
    if (obj && obj.children) {
      let children = obj.children.map((objChildren) => {
        return this.renameProperty(objChildren, oldName1, newName1, oldName2, newName2);
      });
      obj['children'] = children;
      obj[newName1] = `${obj[oldName2]}(${obj[oldName1]})`;
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    } else {
      obj[newName1] = `${obj[oldName2]}(${obj[oldName1]})`;
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    }
  };

  onSelect = (selectedKeys) => {
    let { deviceList } = this.state;
    let deviceObject = deviceList.filter((device) => {
      return device.AliasCode === selectedKeys[0];
    });
    if (deviceObject && deviceObject.length > 0) {
      this.props.handleDropdownChange(selectedKeys);
    } else {
      message.error('Please select valid sensor');
    }
    // this.setState({
    //   selectedKeys:[]
    // })
  };

  setTranslation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] && translationCache[keyword]
      ? translationCache[keyword]
      : keyword;
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  render() {
    let { treeData } = this.state;
    return (
      <div
        style={{
          //  height: '70%',
          //  marginTop: '10px'
          borderRight: '1px dashed white',
          width: '100%',
          flex: '35%',
          overflowY: 'auto',
          height: window.innerHeight - 250
        }}
      >
        <Tree
          style={{ color: 'black' }}
          showLine
          // checkedKeys={checkedArray}
          defaultExpandAll={true}
          switcherIcon={<DownOutlined />}
          treeData={treeData}
          selectedKeys={this.state.selectedKeys}
          onSelect={this.onSelect}
          value={this.state.selectedKeys}
        />
      </div>
    );
  }
}

export default HierarchyModal;
