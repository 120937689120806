import React from 'react';
import themeSettings from '../../Utils/themeSettings.json';
// import translation from '../Utils/translation';
import {
  StyledComponent,
  StyledForm,
  StyledSubHeading
  // StyledSubForm,
  // StyledTabs
} from '../../CSS/style';

import { getMetaData, submitDataSource, getDeployHistory } from '../../Utils/FormCalls';
import history from '../../../../commons/history';
import { getTranslationCache } from '../../../../selectors/language';
// import { constant } from '../Utils/Constants';
import { RBSLOOKUPMaster, ServerRegistrationMasters } from '../../../../commons/api';
import { Button, Checkbox, Form, Input, message, Radio, Modal } from 'antd';
import '../../CSS/index.css';
import { createStructuredSelector } from 'reselect';
import { getUserProfile } from '../../../../selectors/layout';
import { connect } from 'react-redux';
// import { QuestionCircleOutlined } from '@ant-design/icons';
const APIList = {
  getById: `${ServerRegistrationMasters.GET}`,
  getMetrics: `${RBSLOOKUPMaster.LookUpItemsGetById}`,
  createData: `${ServerRegistrationMasters.CREATE}`
};
class ServerRegistrationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      translationCache: props.translationCache || {},
      userProfile: props && props.userProfile ? props.userProfile : {},
      typeList: {},
      subTypeList: {},
      checkedVal: false,
      CheckedFlag: true,
      value: 'TrainingServerFlag',
      modalVisible: false,
      newOption: ''
      // checkedInference: 'true',
    };
    this.hRef = React.createRef();
  }
  componentDidMount = async () => {
    let path = history.location.pathname.split('/rubus/RbsAnalytics/ServerRegistrationDetails/');
    let Id = path && path[1] ? path[1] : null;
    if (Id && Id !== null) {
      let data = await getMetaData(APIList.getById, 'Id', Id);

      let formObject = data && data[0] ? data[0] : {};
      if (formObject && formObject.ServerType && formObject.ServerType === 'InferenceServerFlag') {
        this.setState({ value: 'InferenceServerFlag' });
      } else {
        this.setState({ value: formObject.ServerType });
      }
      var subType = await getDeployHistory(APIList.getMetrics, 'LookupName', formObject.Type);
      if (formObject.GMLFlowAvailableFlag === true) {
        this.setState({ checkedVal: true });
      } else {
        this.setState({ checkedVal: false });
      }
      if (formObject.GPUFlag === true) {
        this.setState({ CheckedFlag: true });
      } else {
        this.setState({ CheckedFlag: false });
      }

      if (this.hRef.current) {
        this.hRef.current.setFieldsValue({ ...formObject });
      }
      this.setState({
        formObject,
        subTypeList: subType,
        selectedType: formObject.Type
      });
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.userProfile !== prevProps.userProfile ||
      this.props.translationCache !== prevProps.translationCache
    ) {
      this.setState({
        userProfile: this.props.userProfile,
        translationCache: this.props.translationCache
      });
    }
  };

  onFormSubmit = () => {
    let { checkedVal, value, CheckedFlag } = this.state;
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...this.hRef.current.getFieldsValue(),
          // GMLFlowAvailableFlag: checkedVal,
          ServerType: value,
          GPUFlag: CheckedFlag
        };
        if (value && value === 'InferenceServerFlag') {
          values.GMLFlowAvailableFlag = checkedVal;
        }
        let response = await submitDataSource(APIList.createData, values);
        // const Id = response && response.Id ? response.Id : null;
        // let metaData = await getMetaData(APIList.getById, 'Id', Id);
        // this.setState({ formObject: metaData });
        message.success(response && response.message ? this.translation(response.message) : '');
        history.push('/rubus/RbsAnalytics/ServerRegistrationList');
      })
      .catch(() => {});
  };

  resetFields = () => {
    this.hRef.current &&
      this.hRef.current.setFieldsValue({
        Name: undefined,
        Description: undefined,
        IpAddress: undefined,
        GPUFlag: true,
        TrainingHome: undefined,
        InferenceHome: undefined,
        MLFlowBackboneDB: undefined,
        MLFlowArtifactsLocation: undefined,
        MLFlowIPAddress: undefined,
        Port: undefined,
        UserName: undefined,
        MLFlowPassword: undefined
      });
    this.setState({
      formObject: {},
      checkedVal: false,
      CheckedFlag: true,
      value: 'TrainingServerFlag'
      // checkedInference: 'true'
    });
  };

  onTypeSelect = async (value) => {
    var subType = await getDeployHistory(APIList.getMetrics, 'LookupName', value);
    this.setState({ subTypeList: subType, selectedType: value });
  };

  onCheckboxChange = (e) => {
    if (e.target.checked === true) {
      this.setState({ checkedVal: true });
    } else if (e.target.checked === false) {
      this.setState({ checkedVal: false });
    }
  };
  onCheckboxFlagChange = (e) => {
    if (e.target.checked === true) {
      this.setState({ CheckedFlag: true });
    } else if (e.target.checked === false) {
      this.setState({ CheckedFlag: false });
    }
  };
  handleModalConfirm = () => {
    let { newOption } = this.state;
    // if (e.target.checked === true) {
    this.hRef.current &&
      this.hRef.current.setFieldsValue({
        TrainingHome: undefined,
        InferenceHome: undefined,
        MLFlowBackboneDB: undefined,
        MLFlowArtifactsLocation: undefined,
        MLFlowIPAddress: undefined,
        Port: undefined,
        UserName: undefined,
        MLFlowPassword: undefined
      });
    // this.setState({ value: e.target.value });
    // }
    this.setState({ value: newOption, modalVisible: false, checkedVal: false });
  };
  handleModalCancel = () => {
    this.setState({ modalVisible: false });
  };
  onChange = (e) => {
    let { checkedVal } = this.state;
    let newOption = e.target.value;
    let data = this.hRef.current && this.hRef.current.getFieldValue('TrainingHome');
    let values = this.hRef.current && this.hRef.current.getFieldValue('InferenceHome');
    if ((data && data !== undefined) || values !== undefined || checkedVal === true) {
      this.setState({ newOption, modalVisible: true });
    } else {
      this.setState({ value: newOption });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { formObject, edit, value, checkedVal, CheckedFlag } = this.state;
    const validateMessages = {
      required: this.translation('${label}') + this.translation('is required!')
    };

    return (
      <StyledComponent
        theme={themeSettings}
        style={{
          padding: '50px 30px',
          minHeight: window.innerHeight - 143
        }}
      >
        <StyledSubHeading theme={themeSettings}>
          {this.translation('Server Registration')}
        </StyledSubHeading>
        <StyledForm
          name="ServerRegistration"
          ref={this.hRef}
          autoComplete="off"
          initialValues={formObject}
          theme={themeSettings}
          layout="horizontal"
          labelCol={{ flex: '200px' }}
          labelAlign="right"
          onFinish={this.onFormSubmit}
          onFinishFailed={this.onFinishFailed}
          // disabled={view === true ? true : false}
          labelWrap
          validateMessages={validateMessages}
          wrapperCol={{ flex: 1 }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap', paddingRight: '5%' }}>
            <Form.Item name="Id"></Form.Item>
            <Form.Item
              label={this.translation('Name')}
              name="Name"
              rules={[{ required: true }]}
              style={{ width: '50%' }}
            >
              <Input
                // disabled={formObject && formObject.Name ? true : false}
                placeholder={this.translation('Please Enter Name')}
              ></Input>
            </Form.Item>
            <Form.Item
              label={this.translation('Description')}
              name="Description"
              // rules={[{ required: true }]}
              style={{ width: '50%' }}
            >
              <Input
                // disabled={formObject && formObject.Description ? true : false}
                placeholder={this.translation('Please Enter Description')}
              ></Input>
            </Form.Item>
            <Form.Item
              label={this.translation('IP Address')}
              name="IpAddress"
              rules={[{ required: true }]}
              style={{ width: '50%' }}
            >
              <Input
                // disabled={formObject && formObject.IPAddress ? true : false}
                placeholder={this.translation('Please Enter IP Address')}
              ></Input>
            </Form.Item>
            <Form.Item
              label={this.translation('GPU Flag')}
              name="GPUFlag"
              // rules={[{ required: true }]}
              style={{ width: '50%' }}
            >
              <Checkbox
                checked={CheckedFlag === true ? true : false}
                onClick={(e) => this.onCheckboxFlagChange(e)}
              />
            </Form.Item>
            <Form.Item>
              <Modal
                className="ServerRegistrationModal"
                closable={false}
                title={null}
                visible={this.state.modalVisible}
                onOk={this.handleModalConfirm}
                cancelText={this.translation('No')}
                okText={this.translation('Yes')}
                onCancel={this.handleModalCancel}
              >
                Are you want to change the option. If you try to change the option, you will lose
                data
              </Modal>
              <Radio.Group
                onChange={this.onChange}
                value={this.state.value}
                style={{ width: '100%', paddingLeft: '600px' }}
              >
                <Radio value={'TrainingServerFlag'} style={{ color: 'rgb(255, 179, 39)' }}>
                  Training Server Flag
                </Radio>
                <Radio
                  value={'InferenceServerFlag'}
                  style={{ paddingLeft: '100px', color: 'rgb(255, 179, 39)' }}
                >
                  Inference Server Flag
                </Radio>
              </Radio.Group>
            </Form.Item>

            {value && value === 'TrainingServerFlag' ? (
              <Form.Item
                label={this.translation('Training Home')}
                name="TrainingHome"
                // rules={[{ required: true }]}
                style={{ width: '50%' }}
              >
                <Input
                  // disabled={formObject && formObject.TrainingHome ? true : false}
                  placeholder={this.translation('Please Enter Training Home')}
                ></Input>
              </Form.Item>
            ) : null}

            {value && value === 'InferenceServerFlag' ? (
              // <>
              <div className="ServerDiv">
                <Form.Item
                  label={this.translation('Inference Home')}
                  name="InferenceHome"
                  // rules={[{ required: true }]}
                  style={{ width: '50%' }}
                >
                  <Input
                    // disabled={formObject && formObject.InferenceHome ? true : false}
                    placeholder={this.translation('Please Enter Inference Home')}
                  ></Input>
                </Form.Item>
                <Form.Item
                  label={this.translation('Model Server Available')}
                  name="GMLFlowAvailableFlag"
                  style={{ width: '50%' }}
                >
                  <Checkbox
                    checked={checkedVal === true ? true : false}
                    onClick={(e) => this.onCheckboxChange(e)}
                  />
                </Form.Item>
                <Form.Item
                  label={this.translation('Model Server  Type')}
                  name="MLFlowBackboneDB"
                  // rules={[{ required: true }]}
                  style={{ width: '50%' }}
                >
                  <Input
                    disabled={checkedVal && checkedVal === true ? false : true}
                    placeholder={this.translation('Please Enter Model Server Type')}
                  ></Input>
                </Form.Item>
                <Form.Item
                  label={this.translation('Model Server Artifacts Location')}
                  name="MLFlowArtifactsLocation"
                  // rules={[{ required: true }]}
                  style={{ width: '50%' }}
                >
                  <Input
                    disabled={checkedVal && checkedVal === true ? false : true}
                    placeholder={this.translation('Please Enter Model Server Artifacts Location')}
                  ></Input>
                </Form.Item>

                <Form.Item
                  label={this.translation('IP Address')}
                  name="MLFlowIPAddress"
                  // rules={[{ required: true }]}
                  style={{ width: '50%' }}
                >
                  <Input
                    disabled={checkedVal && checkedVal === true ? false : true}
                    placeholder={this.translation('Please Enter Ip Address')}
                  ></Input>
                </Form.Item>
                <Form.Item
                  label={this.translation('Port')}
                  name="Port"
                  // rules={[{ required: true }]}
                  style={{ width: '50%' }}
                >
                  <Input
                    disabled={checkedVal && checkedVal === true ? false : true}
                    placeholder={this.translation('Please Enter Port')}
                  ></Input>
                </Form.Item>
                <Form.Item
                  label={this.translation('User Name')}
                  name="UserName"
                  // rules={[{ required: true }]}
                  style={{ width: '50%' }}
                >
                  <Input
                    disabled={checkedVal && checkedVal === true ? false : true}
                    placeholder={this.translation('Please Enter User Name')}
                  ></Input>
                </Form.Item>
                <Form.Item
                  label={this.translation('Password')}
                  name="MLFlowPassword"
                  // rules={[{ required: true }]}
                  style={{ width: '50%' }}
                >
                  <Input
                    type="password"
                    disabled={checkedVal && checkedVal === true ? false : true}
                    placeholder={this.translation('Please Enter Password')}
                  ></Input>
                </Form.Item>
              </div>
            ) : // </>
            null}
          </div>

          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              <Button
                style={{ marginRight: 8 }}
                theme={themeSettings}
                type="primary"
                htmlType="submit"
                data-testid="submitButton"
              >
                {edit ? this.translation('Update') : this.translation('Submit')}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ marginRight: 8 }}
                theme={themeSettings}
                type="primary"
                onClick={() => this.resetFields()}
              >
                {this.translation('Reset')}
              </Button>
            </Form.Item>
          </div>
        </StyledForm>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ServerRegistrationDetails);
