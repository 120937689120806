// import echarts from 'echarts';
import * as echarts from 'echarts';
import EchartsReactCore from './core';

// import data from './theme.json'

// export the Component the echarts Object.
export default class EchartsReact extends EchartsReactCore {
  constructor(props) {
    super(props);
    // echarts.registerTheme('customed', data)
    this.echartsLib = echarts;
  }
}
