export default {
  startValue: null,
  endValue: null,
  endOpen: false,
  splitDowntimeLog: [],
  openModel: false,
  ProcessArea: '',
  EquipmentCode: '',
  Shift: '',
  EquipmentName: '',
  WorkCentre: '',
  TypeOfDowntime: '',
  Source: '',
  UpdateSource: '',
  splitStartRecord: false,
  splitEndRecord: false,
  DeletedFailureDetails: [],
  workCenterlist: [],
  equipmentData: [],
  editLog: false,
  editAutoLog: false,
  totalDownTimearea: '',
  totalDownDescription: '',
  totalFromDateTime: '',
  totalToDateTime: '',
  splitView: false,
  totalDownDescriptionData: [],
  selectedAreas: [],
  modifyRecord: false,
  checkValidations: false,
  addArea: false,
  greyOutRecords: [],
  Remarks: [],
  RecordStatus: '',
  SelectedValue: [],
  selectedKeys: [],
  totalTime: '',
  hasSubEquipment: false,
  SubEquipmentCode: '',
  Remarksvisible: false,
  manualRemarks: [],
  manualRemarksChild: [],
  manualRemarksParent: []
};

export let constants = {
  maxTime: 5,
  warnings: {
    totalTimeValidation: 'Cannot enter More Than Total DownTime',
    notEquateTime: 'Total Downtime is Not equal to splitDowntime',
    totalTimeGreater: 'TotalTime Should Be Greater Than  Mins :',
    unEditable: 'DownTimeLog Cannot Be Edited Again'
  }
};
