import { createSelector } from 'reselect';

const loginList = (state) => {
  return state.get('login').get('login');
};

const getuserCurrentMenu = () =>
  createSelector(loginList, (state) => {
    return state.get('userCurrentMenu');
  });

const getUserToken = () =>
  createSelector(loginList, (state) => {
    return state.get('accessToken');
  });

export { getUserToken, getuserCurrentMenu };
