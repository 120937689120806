// /* eslint-disable array-callback-return */
import React, { Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import ReactEcharts from '../../Echarts/Echarts';
import {
  trendLabelTranslation,
  predictionsLegendsTranslation
} from '../../../Utils/Adapter/translationAdapter';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getCurrentTimezone } from '../../../../../selectors/layout';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { getTranslationCache } from '../../../../../selectors/language';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { arrayOfObjects } from '../../../Mock/MockJson';
import { defaultRefreshTime } from '../../constant.json';

class PolarGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      fromedit: this.props.graphprops.fromedit,
      data: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  getData = async (props, theme) => {
    this.requestCheck = false;
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = arrayOfObjects;
      this.requestCheck = true;
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection({
          graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          selectionDateLive: this.props.selectionDateLive,
          currentTimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      }
    }
    // json = [
    //   {
    //     DateTime: '2023-03-18 12:00:00',
    //     Name: 'Train_Speed',
    //     Value: 73,
    //     Description: 'Train Speed Description1',
    //     status: 'high',
    //     statusValue: '10215/45664'
    //   },
    //   {
    //     DateTime: '2023-03-18 13:00:00',
    //     Name: 'Train_Speed1',
    //     Value: 20,
    //     Description: 'Train Speed Description'
    //   },
    //   {
    //     DateTime: '2023-03-18 12:00:00',
    //     Name: 'Train_Speed',
    //     Value: 93,
    //     Description: 'Train Speed Description1'
    //   },
    //   {
    //     DateTime: '2023-03-18 13:00:00',
    //     Name: 'Train_Speed1',
    //     Value: 40,
    //     Description: 'Train Speed Description'
    //   }
    // ]
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      let Names = [];
      let legendsdata = [];
      let dates = [];
      let name = '';
      if (json && Array.isArray(json)) {
        json &&
          Array.isArray(json) &&
          json.map((val) => {
            if (val.Description && val.Description !== name) {
              if (Names.indexOf(val.Description) === -1) {
                Names.push(val.Description);
                name = val.Description;
                legendsdata.push({
                  name: val.Description,
                  textStyle: {
                    color: theme === 'lightTheme' ? 'black' : 'white',
                    fontSize: 16,
                    fontWeight: 'bold'
                  }
                });
              }
            }
            return {};
          });
        Names.map((tagval, tagindex) => {
          json.map((jsonval) => {
            if (Names[0] === jsonval.Description && tagindex === 0) {
              let date = {};
              if (
                this.props.graphprops.monthExists === 'true' ||
                this.props.graphprops.monthExists === true
              ) {
                date =
                  graphprops && graphprops.type === 'live'
                    ? moment
                        .utc(jsonval.DateTime)
                        .tz(this.props.currentTimeZone)
                        .format('DD/MM/YYY')
                    : moment(jsonval.DateTime).tz(this.props.currentTimeZone).format('DD/MM/YYYY');
              } else {
                if (
                  graphprops &&
                  graphprops.polar &&
                  graphprops.polar.XAxisType &&
                  graphprops.polar.XAxisType === 'string'
                ) {
                  date = jsonval.DateTime;
                } else {
                  let format =
                    graphprops.polar && graphprops.polar.xAxisDateFormat
                      ? graphprops.polar.xAxisDateFormat
                      : 'YYYY-MM-DD HH:mm:ss';

                  if (
                    this.props.graphprops &&
                    this.props.graphprops.selection &&
                    this.props.graphprops.selection === 'dateMonthYearPicker'
                  ) {
                    if (
                      this.props.dayMonthYear &&
                      this.props.dayMonthYear.selectionType === 'day'
                    ) {
                      date =
                        graphprops && graphprops.type === 'summary'
                          ? moment.utc(jsonval.DateTime).format('HH:mm')
                          : moment
                              .utc(jsonval.DateTime)
                              .tz(this.props.currentTimeZone)
                              .format('HH:mm');
                    } else if (
                      this.props.dayMonthYear &&
                      this.props.dayMonthYear.selectionType === 'month'
                    ) {
                      date =
                        graphprops && graphprops.type === 'summary'
                          ? moment.utc(jsonval.DateTime).format('DD/MM')
                          : moment
                              .utc(jsonval.DateTime)
                              .tz(this.props.currentTimeZone)
                              .format('DD/MM');
                    } else if (
                      this.props.dayMonthYear &&
                      this.props.dayMonthYear.selectionType === 'year'
                    ) {
                      date =
                        graphprops && graphprops.type === 'summary'
                          ? moment.utc(jsonval.DateTime).format('MMMM')
                          : moment
                              .utc(jsonval.DateTime)
                              .tz(this.props.currentTimeZone)
                              .format('MMMM');
                    }
                  } else {
                    date =
                      graphprops && graphprops.type === 'summary'
                        ? moment.utc(jsonval.DateTime).format(format)
                        : moment
                            .utc(jsonval.DateTime)
                            .tz(this.props.currentTimeZone)
                            .format(format);
                  }
                }
                // dates.push(date);
              }
              dates.push(date);
            }
          });
          return {};
        });
        let valueName = json && Array.isArray(json) && json.map((val) => val.Description);
        let seriesArray =
          json &&
          Array.isArray(json) &&
          json.map((desc, index) => {
            return {
              name: valueName[index],
              type: 'bar',
              coordinateSystem: 'polar',
              stack: 'a',
              data:
                json &&
                Array.isArray(json) &&
                json
                  .filter((e) => e.Description === desc.Description)
                  .map((barData) => {
                    return barData.Value;
                  }),
              emphasis: {
                focus: 'series'
              }
            };
          });
        let option = cloneDeep(this.state.option);
        let series = await trendLabelTranslation(seriesArray, this.props.translationCache);
        let legends = await predictionsLegendsTranslation(legendsdata, this.props.translationCache);
        if (
          this.props.graphprops &&
          this.props.graphprops.polar &&
          this.props.graphprops.polar.colors &&
          this.props.graphprops.polar.colors.length > 0
        ) {
          option.color = [...this.props.graphprops.polar.colors];
        }
        option.series = series;
        option.legend = [...legends];
        option.angleAxis.data = [...dates];
        this.setState({
          option,
          refreshDateTime: moment()
        });
      }
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  render() {
    const { errorInAPI, noDataInDB } = this.state;
    return (
      <Fragment>
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            option={this.state.option}
            style={{ height: '90%', width: '100%' }}
            className="react_for_echarts"
          />
        )}
      </Fragment>
    );
  }
  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      angleAxis: {
        type: 'category',
        data: []
      },
      radiusAxis: {
        axisLabel: {
          fontSize: 18
        },
        z: 2
      },
      polar: {},
      series: [
        {
          type: 'bar',
          data: [],
          coordinateSystem: 'polar',
          name: ''
        }
      ],
      legend: {
        show: true,
        textStyle: {
          color: theme === 'lightTheme' ? 'black' : 'white',
          fontSize: 12,
          fontWeight: 'bold'
        },
        data: []
      }
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(PolarGraph);
