import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Spin, Select, DatePicker, Drawer, Tree, Modal, Input, Button } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Panel from './Panel';
import NewDashboard from './NewDashboard';
import localStorage from '../../../utils/localStorage';
import station from '../Utils/Images/Stations_V1.png';
import DayMonthYearSelection from '../DashboardComponents/Widgets/Selection/DayMonthYearSelection';
import HierarchyDropdownSelection from './Widgets/Selection/HierarchyDropdownSelection';
import { makeSelectThemeing, makeSelectTheme } from '../../../selectors/theme';
import { getMenuCollapse } from '../../../selectors/layout';
import { getNavigationMode } from '../../../selectors/theme';
import { translation } from '../../Adapter/TranslationAdapter.js';

import { getTranslationCache } from '../../../selectors/language';
import { getDashboardByName } from './DashboardCalls';
import { DashboardDiv, DashboardHeaderDiv } from './CSS/styles.js';
import HierarchyModal from './Widgets/Selection/HierarchyModal';

import './CSS/Dashboard.css';
import { DownOutlined, UpSquareOutlined } from '@ant-design/icons';
import history from '../../../commons/history';
import { API_DEVICE } from '../../../commons/api.js';
import MultiLevelDashboard from '../Custom/MultiLevelDashboard';

const { MonthPicker, WeekPicker, RangePicker } = DatePicker;
const { Option } = Select;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardName: '',
      visible: false,
      panels: [],
      headerJSON: {},
      graphDataForEdit: '',
      translationCache: props.translationCache || {},

      selectionDate: moment().format('YYYY-MM-DD 00:00:00'),
      showDateRange: false,
      selectionDateRange: {
        startDate: moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().format('YYYY-MM-DD HH:mm:ss')
      },
      deviceList: [],
      selectionMonth: moment().format('YYYY-MM'),
      selectionWeek: moment().startOf('week'),
      shift: 'Morning Shift',
      loading: true,
      selectedThemeing: this.props.selectedThemeing,
      selectedTheme: this.props.selectedTheme,
      dropdownSelection: {},
      treeList: [],

      AssetName: '',
      AssetCode: '',
      AssetObject: {},
      DeviceCode: undefined,
      isModalVisible: false
    };
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.name) {
      let dashboardName = this.props.match.params.name;
      document.title = 'Dashboard-' + dashboardName;
      this.selectDashboard(dashboardName);
    }
  }

  componentDidUpdate(preprops) {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.name &&
      (this.props.match.params.name !== preprops.match.params.name ||
        this.props.menuCollapse !== preprops.menuCollapse ||
        preprops.navigationMode !== this.props.navigationMode)
    ) {
      if (document.getElementsByClassName('layout')[0]) {
        if (document.getElementsByClassName('ant-layout-sider-trigger')[0]) {
          this.divWidth = parseInt(
            document.getElementsByClassName('ant-layout-sider-trigger')[0].style.width,
            10
          );
        }
      }
      let dashboardName = this.props.match.params.name;
      document.title = 'Dashboard - ' + this.props.match.params.name;
      this.selectDashboard(dashboardName);
    }
  }

  _getDevicesList = (payload) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let url = '';
    if (payload && payload.method === 'GET' && payload.GETParams) {
      url = `${payload.url}?SiteId=${siteId}&${payload.GETParams}`;
    } else {
      url = `${payload.url}?SiteId=${siteId}`;
    }
    let payloadData = payload && payload.payload ? payload.payload : '';
    const ObjectHeader = {
      method: payload.method,
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payloadData || {}
    };
    return axios(ObjectHeader)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };

  disabledDate = (current) => {
    let { dashboards } = this.state;
    let selectionPoint = '0';
    if (dashboards.headerJSON.DashboardLevelSelection !== 'none') {
      selectionPoint =
        dashboards.json &&
        dashboards.json[0] &&
        dashboards.json[0].DashboardLevelSelectionPoint &&
        dashboards.json[0].DashboardLevelSelectionPoint === 'PreviousDay'
          ? '1'
          : '0';
    } else if (dashboards.json[0].GraphLevelSelection !== 'none') {
      selectionPoint =
        dashboards.json &&
        dashboards.json[0] &&
        dashboards.json[0].GraphLevelSelectionPoint &&
        dashboards.json[0].GraphLevelSelectionPoint === 'PreviousDay'
          ? '1'
          : '0';
    }

    return current > moment().startOf('day').subtract(selectionPoint, 'day');
  };

  onChangeDatePicker = (value, dateString) => {
    this.setState({
      selectionDate: moment(dateString).format('YYYY-MM-DD 00:00:00')
    });
  };

  onDateRangePickerChange = (value, currentDate) => {
    let selectionDateRange = {};
    if (currentDate && currentDate[0] && currentDate[1]) {
      selectionDateRange = {
        startDate: moment(currentDate[0]).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(currentDate[1]).format('YYYY-MM-DD HH:mm:ss')
      };
      this.setState({
        selectionDateRange
      });
    } else {
      selectionDateRange = {
        startDate: moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().format('YYYY-MM-DD HH:mm:ss')
      };
      this.setState({
        selectionDateRange
      });
    }
  };

  onChangeMonthPicker = (value, dateString) => {
    this.setState({
      selectionMonth: dateString
    });
  };

  onChangeWeekPicker = (value) => {
    this.setState({
      selectionWeek: value
    });
  };

  selectionpayload = (payload) => {
    this.setState({
      dayMonthYear: payload
    });
  };

  handleDropdownChange = (value) => {
    let { dropdownSelection } = this.state;
    dropdownSelection = {
      ...dropdownSelection,
      ...value
    };
    this.setState({
      dropdownSelection
    });
  };

  hierarchyDropdownChange = (value, hierarchydata, deviceList) => {
    let { dropdownSelection } = this.state;
    let selectedValue = value && Array.isArray(value) && value.length > 0 ? value[0] : undefined;
    if (selectedValue) {
      this.setState({
        isModalVisible: false,
        dropdownSelection: { ...dropdownSelection, DeviceCode: selectedValue },
        hierarchydata,
        deviceList
      });
    }
  };

  onTreeSelect = async (keys, nodeobject) => {
    const findParent = (key, tree) => {
      for (let node of tree) {
        if (node.children) {
          for (let child of node.children) {
            if (child.key === key) {
              return node;
            }
          }
          const result = findParent(key, node.children);
          if (result) return result;
        }
      }
      return null;
    };
    let { dashboards } = this.state;
    let payload = {
      ...(dashboards && dashboards.json && dashboards.json[0] && dashboards.json[0].apiPayload
        ? dashboards.json[0].apiPayload
        : {}),
      payload: {
        ...(dashboards &&
        dashboards.json &&
        dashboards.json[0] &&
        dashboards.json[0].apiPayload &&
        dashboards.json[0].apiPayload.payload
          ? dashboards.json[0].apiPayload.payload
          : {}),
        AssetCode: keys && keys[0] ? keys[0] : ''
      }
    };
    const parentNode = findParent(keys && keys[0] ? keys[0] : '', this.state.treeList[0].children);
    let deviceList = await this._getDevicesList(payload, 'dynamic');
    this.setState({
      toggleVisible: false,
      AssetCode: keys && keys[0] ? keys[0] : '',
      AssetName: nodeobject && nodeobject.node ? nodeobject.node.title : '',
      AssetObject: { Station: parentNode ? parentNode.title : '' },
      deviceList,
      DeviceCode:
        dashboards &&
        dashboards.json &&
        dashboards.json[0] &&
        dashboards.json[0]['checkedList'] === true
          ? 'All'
          : deviceList && deviceList[0] && deviceList[0].Value
          ? deviceList[0].Value
          : ''
    });
  };
  onOk = () => {};
  render() {
    let colorTheme = this.state.selectedTheme;
    const styles = {
      background: colorTheme
    };
    let {
      selectionDate,
      selectionMonth,
      selectionWeek,
      selectionDateRange,
      panels,
      deviceList,
      loading,
      DeviceCode,
      shift,
      dayMonthYear,
      dropdownSelection,
      dashboardName,
      toggleVisible,
      treeList,
      AssetCode,
      AssetName,
      isModalVisible,
      AssetObject,
      dashboards
    } = this.state;
    let { translationCache, triggerPoint } = this.props;
    let filteredDevice =
      dropdownSelection && dropdownSelection.DeviceCode
        ? deviceList.filter((device) => device.AliasCode === dropdownSelection.DeviceCode)
        : dropdownSelection.DeviceCode;

    if (this.state.dashboardName === 'newdashboard') {
      return (
        <NewDashboard
          DeviceData={this.props.DeviceData}
          AssetData={this.props.AssetData}
          panels={this.state.panels}
        />
      );
    } else if (
      dashboards &&
      dashboards.headerJSON &&
      dashboards.headerJSON.isMultiLevelDashboard &&
      dashboards.headerJSON.isMultiLevelDashboard === 'true'
    ) {
      return (
        <>
          <MultiLevelDashboard dashboards={dashboards} />
        </>
      );
    } else {
      return (
        <DashboardDiv className={'testDiv'} id="testdiv" style={styles}>
          <div
            style={{
              color: '#fff',
              display: 'flex',
              justifyContent: 'space-evenly',
              fontWeight: 'bold',
              fontSize: '18px'
            }}
          >
            {AssetName.length !== 0
              ? `Station Name : ${AssetName} 
            ${AssetObject && AssetObject.Station ? `(${AssetObject.Station})` : ''}`
              : null}
          </div>
          {dashboards &&
          dashboards.headerJSON &&
          dashboards.headerJSON.dashboardType === 'hierarchy' &&
          dashboards.headerJSON.hierarchyViewType === 'Tree' ? (
            <span
              style={{
                height: '40px',
                display: 'flex',
                justifyContent: 'flex-start',
                flexFlow: 'row wrap',
                marginTop: '10px'
              }}
            >
              <Input
                value={
                  filteredDevice &&
                  Array.isArray(filteredDevice) &&
                  filteredDevice[0] &&
                  filteredDevice[0].Name
                    ? `${
                        filteredDevice[0].Name
                          ? `${translation(filteredDevice[0].Name, translationCache)}`
                          : ''
                      }`
                    : ''
                }
                size="small"
                style={{ width: 300, opacity: 1, height: '32px' }}
              />
              <Button
                type="primary"
                style={{ marginRight: '5px' }}
                onClick={() => {
                  this.setState({ isModalVisible: !isModalVisible });
                }}
              >
                <UpSquareOutlined />
              </Button>
              <Modal
                title={translation('Select Hierarchy', translationCache)}
                className="hierarchyModal"
                open={this.state.isModalVisible}
                bodyStyle={{ overflowY: 'scroll', height: '900px' }}
                footer={null}
                onCancel={() => {
                  this.setState({
                    isModalVisible: false
                  });
                }}
              >
                <HierarchyModal
                  value={
                    dropdownSelection && dropdownSelection.DeviceCode
                      ? dropdownSelection.DeviceCode
                      : DeviceCode
                  }
                  translationCache={this.props.translationCache}
                  handleDropdownChange={this.hierarchyDropdownChange}
                  deviceList={this.state.deviceList}
                  hierarchydata={this.state.hierarchydata}
                  dashboards={this.state.dashboards}
                />
              </Modal>
              {this.state.dashboardName && this.state.dashboardName === 'Vibration Sensor' ? (
                <Button
                  type="primary"
                  style={{ marginRight: '5px' }}
                  onClick={() => {
                    history.push('/rubus/LogBook/Vibration Sensor Field Entry');
                  }}
                >
                  {translation('Redirect to logbook', translationCache)}
                </Button>
              ) : null}

              {this.state.dashboardName && this.state.dashboardName === 'Vibration Sensor' ? (
                <span>
                  <Button
                    type="primary"
                    style={{ marginRight: '5px' }}
                    onClick={() => {
                      history.push({
                        pathname: `/rubus/FFT`,
                        state:
                          dropdownSelection && dropdownSelection.DeviceCode
                            ? dropdownSelection.DeviceCode
                            : DeviceCode
                      });
                    }}
                  >
                    {translation('FFT', translationCache)}
                  </Button>
                </span>
              ) : null}
            </span>
          ) : null}
          {dashboards && dashboards.headerJSON && dashboards.headerJSON.treeExists === 'true' ? (
            <div className="drawerToggle">
              <div
                onClick={() => {
                  this.setState({ toggleVisible: true });
                }}
              >
                <img
                  src={station}
                  alt="logo"
                  height={'50'}
                  width={'50'}
                  style={{ marginTop: '8px', width: '200%' }}
                />
              </div>
              <Drawer
                className="drawerBlackTheme"
                title="Select Tree"
                placement={'left'}
                closable={false}
                onClose={() => this.setState({ toggleVisible: false })}
                open={toggleVisible}
                key={'left'}
              >
                <Tree
                  className="treeBlackTheme"
                  showLine
                  value={AssetCode}
                  defaultExpandAll={true}
                  switcherIcon={<DownOutlined />}
                  onSelect={(selectedValue, nodeObject) =>
                    this.onTreeSelect(selectedValue, nodeObject)
                  }
                  treeData={treeList && Array.isArray(treeList) ? treeList : []}
                />
              </Drawer>
            </div>
          ) : null}
          {(dashboards &&
            dashboards.headerJSON &&
            dashboards.headerJSON.dashboardType === 'dynamic') ||
          (dashboards &&
            dashboards.headerJSON &&
            dashboards.headerJSON.dashboardType === 'multi') ||
          (dashboards &&
            dashboards.headerJSON &&
            dashboards.headerJSON.dashboardType === 'hierarchy' &&
            dashboards.headerJSON.hierarchyViewType === 'Select') ||
          (dashboards &&
            dashboards.headerJSON &&
            dashboards.headerJSON.DashboardLevelSelection !== 'none') ? (
            <DashboardHeaderDiv>
              {dashboards &&
              dashboards.headerJSON &&
              dashboards.headerJSON.dashboardType === 'dynamic' ? (
                <Select
                  style={{ width: 200, marginTop: '10px', marginRight: '10px' }}
                  value={DeviceCode}
                  placeholder="Select Equipment"
                  onChange={(e) => {
                    this.setState({ DeviceCode: e });
                  }}
                >
                  {dashboards &&
                  dashboards.headerJSON &&
                  dashboards.headerJSON.checkedList === true ? (
                    <Option key={'All'} value={'All'}>
                      All
                    </Option>
                  ) : null}
                  {deviceList &&
                    Array.isArray(deviceList) &&
                    deviceList.map((device, index) => {
                      return (
                        <Option key={index} value={device.Value}>
                          {device.Key}
                        </Option>
                      );
                    })}
                </Select>
              ) : null}
              {dashboards &&
              dashboards.headerJSON &&
              dashboards.headerJSON.dashboardType === 'hierarchy' &&
              dashboards.headerJSON.hierarchyViewType === 'Select' ? (
                <div>
                  <HierarchyDropdownSelection handleDropdownChange={this.hierarchyDropdownChange} />
                </div>
              ) : null}

              {/* {panels &&
              panels[0] &&
              panels[0]['DashboardLevelSelection'] === 'dateMonthYearPicker' ? ( */}
              {dashboards &&
              dashboards.headerJSON &&
              dashboards.headerJSON.DashboardLevelSelection === 'dateMonthYearPicker' ? (
                <DayMonthYearSelection
                  dashboards={this.state.dashboards}
                  headerJSON={this.state.headerJSON}
                  selectionpayload={this.selectionpayload}
                  dashboardName={dashboardName}
                  graphprops={panels && panels[0] ? panels[0] : {}}
                />
              ) : null}
              {/* {panels && panels[0] && panels[0]['DashboardLevelSelection'] === 'datePicker' ? ( */}
              {dashboards &&
              dashboards.headerJSON &&
              dashboards.headerJSON.DashboardLevelSelection === 'datePicker' ? (
                <DatePicker
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  format="YYYY-MM-DD"
                  onChange={this.onChangeDatePicker}
                  value={moment(this.state.selectionDate)}
                  disabledDate={this.disabledDate}
                  allowClear={false}
                />
              ) : null}

              {/* {panels && panels[0] && panels[0]['DashboardLevelSelection'] === 'dateRangePicker' ? ( */}
              {dashboards &&
              dashboards.headerJSON &&
              dashboards.headerJSON.DashboardLevelSelection === 'dateRangePicker' ? (
                <RangePicker
                  showTime
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  placeholder={['Start Date', 'End Date']}
                  value={[
                    moment(this.state.selectionDateRange.startDate, 'YYYY-MM-DD HH:mm'),
                    moment(this.state.selectionDateRange.endDate, 'YYYY-MM-DD HH:mm')
                  ]}
                  disabledDate={this.disabledDate}
                  onChange={this.onDateRangePickerChange}
                />
              ) : null}

              {/* {panels && panels[0] && panels[0]['DashboardLevelSelection'] === 'monthPicker' ? ( */}
              {dashboards &&
              dashboards.headerJSON &&
              dashboards.headerJSON.DashboardLevelSelection &&
              dashboards.headerJSON.DashboardLevelSelection === 'monthPicker' ? (
                <MonthPicker
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  disabledDate={this.disabledDate}
                  placeholder="Select month"
                  onChange={this.onChangeMonthPicker}
                  format="YYYY-MM"
                  value={moment(this.state.selectionMonth, 'YYYY-MM')}
                />
              ) : null}

              {/* {panels && panels[0] && panels[0]['DashboardLevelSelection'] === 'weakPicker' ? ( */}
              {dashboards &&
              dashboards.headerJSON &&
              dashboards.headerJSON.DashboardLevelSelection &&
              dashboards.headerJSON.DashboardLevelSelection === 'weakPicker' ? (
                <WeekPicker
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  disabledDate={this.disabledDate}
                  placeholder="Select Week"
                  onChange={this.onChangeWeekPicker}
                  value={this.state.selectionWeek}
                />
              ) : null}

              {/* {panels && panels[0] && panels[0]['DashboardLevelSelection'] === 'shiftPicker' ? ( */}
              {dashboards &&
              dashboards.headerJSON &&
              dashboards.headerJSON.DashboardLevelSelection &&
              dashboards.headerJSON.DashboardLevelSelection === 'shiftPicker' ? (
                <Select
                  onChange={(e) => {
                    this.setState({ shift: e });
                  }}
                  style={{ marginTop: '10px', marginRight: '10px' }}
                  value={this.state.shift}
                >
                  <Option value="Morning Shift">Morning Shift</Option>
                  <Option value="Night Shift">Night Shift</Option>
                </Select>
              ) : null}
            </DashboardHeaderDiv>
          ) : null}

          <div>
            {panels && panels.length > 0 && loading === false ? (
              <Panel
                dashboardName={this.state.dashboardName}
                panels={this.state.panels}
                drilldownRedirect={this.drilldownRedirect}
                editGraph={this.editGraph}
                DeviceCode={
                  triggerPoint && triggerPoint === 'custom'
                    ? this.props.match.params.deviceCode
                    : DeviceCode
                }
                AssetCode={AssetCode}
                selectionDate={selectionDate}
                selectionMonth={selectionMonth}
                selectionWeek={selectionWeek}
                selectionDateRange={selectionDateRange}
                dayMonthYear={dayMonthYear}
                shift={shift}
                divWidth={this.divWidth}
                navigationMode={this.props.navigationMode}
                dropdownSelection={dropdownSelection}
                headerJSON={this.state.headerJSON}
              />
            ) : loading === true ? (
              <div
                style={{
                  textAlign: 'center',
                  color: 'white'
                }}
              >
                <h2 style={{ color: 'white' }}>
                  <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                </h2>
              </div>
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  color: 'white'
                }}
              >
                <h2 style={{ color: 'white' }}>No Panels Configured</h2>
              </div>
            )}
          </div>
        </DashboardDiv>
      );
    }
  }

  getFormattedTreeData = (treeData) => {
    let data =
      treeData &&
      Array.isArray(treeData) &&
      treeData.map((treeObject) => {
        if (
          treeObject &&
          treeObject.children &&
          Array.isArray(treeObject.children) &&
          treeObject.children.length > 0
        ) {
          let children = this.getFormattedTreeData(treeObject.children);
          return {
            title: treeObject.Name,
            key: treeObject.Code,
            children
          };
        } else {
          return {
            title: treeObject.Name,
            key: treeObject.Code
          };
        }
      });
    return data;
  };

  selectDashboard(dashboardName) {
    if (dashboardName === 'newdashboard') {
      this.setState({
        panels: [],
        dashboardName,
        selectionDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        selectionDateRange: {
          startDate: moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment().format('YYYY-MM-DD HH:mm:ss')
        },
        selectionMonth: moment().format('YYYY-MM'),
        selectionWeek: moment().startOf('week')
      });
    } else {
      this.getDashboard(dashboardName);
    }
  }

  getDashboard = async (dashboardName) => {
    let deviceList = [];
    let treeList = [];
    let AssetCode = '';
    let AssetName = '';
    let AssetObject = {};
    let DeviceCode = '';
    let dashboardObject = await getDashboardByName(dashboardName);
    let dashboards = dashboardObject && dashboardObject[0] ? dashboardObject[0] : {};
    if (dashboards) {
      // When TreeExists gets Enabled
      if (
        dashboards &&
        dashboards.headerJSON &&
        dashboards.headerJSON.dashboardType &&
        dashboards.headerJSON.treeExists === 'true'
      ) {
        if (
          dashboards &&
          dashboards.headerJSON &&
          dashboards.headerJSON.treePayload &&
          dashboards.headerJSON.treePayload.method &&
          dashboards.headerJSON.treePayload.url
        ) {
          let treeListData = await this._getDevicesList(dashboards.headerJSON.treePayload, 'tree');
          treeList = await this.getFormattedTreeData(treeListData);

          let defaultData = await this.ChildrenNames(treeList, []);
          AssetCode = defaultData && defaultData[0] && defaultData[0].key ? defaultData[0].key : '';
          AssetName =
            defaultData && defaultData[0] && defaultData[0].title ? defaultData[0].title : '';
          AssetObject = defaultData && defaultData[0] ? defaultData[0] : {};
        } else {
          treeList = [];
        }
      }

      // When TreeExists True and DashboardType is dynamic
      if (
        dashboards &&
        dashboards.headerJSON &&
        dashboards.headerJSON.apiPayload &&
        dashboards.headerJSON.apiPayload.method &&
        dashboards.headerJSON.apiPayload.url &&
        dashboards.headerJSON.dashboardType === 'dynamic'
      ) {
        if (
          dashboards &&
          dashboards.headerJSON.treeExists &&
          dashboards.headerJSON.treeExists === 'true' &&
          treeList &&
          treeList[0] &&
          treeList[0].key
        ) {
          let payload = {
            ...dashboards.headerJSON.apiPayload,
            payload: {
              ...dashboards.headerJSON.apiPayload.payload,
              AssetCode: AssetCode
            }
          };
          deviceList = await this._getDevicesList(payload, 'dynamic');
          DeviceCode =
            deviceList && deviceList[0] && deviceList[0].Value ? deviceList[0].Value : '';
        } else {
          deviceList = await this._getDevicesList(dashboards.headerJSON.apiPayload, 'dynamic');
          DeviceCode =
            deviceList && deviceList[0] && deviceList[0].Value ? deviceList[0].Value : '';
        }
      }

      if (
        dashboards &&
        dashboards.headerJSON &&
        dashboards.headerJSON.dashboardType &&
        dashboards.headerJSON.dashboardType === 'hierarchy'
      ) {
        let hierarchydata = await this._getDevicesList(
          dashboards && dashboards.headerJSON && dashboards.headerJSON.apiPayload,
          'hierachy'
        );

        let final = hierarchydata.map((userHierarchy) => {
          return this.renameProperty(userHierarchy, 'Name', 'title', 'Code', 'key');
        });
        let deviceCodeList = await this.ChildrenNames(final, []);
        if (deviceCodeList && deviceCodeList[0] && deviceCodeList[0].key) {
          DeviceCode = deviceCodeList[0].key;
        }
        let payload = {
          url: `${API_DEVICE.GET}`,
          method: 'GET'
        };
        deviceList = await this._getDevicesList(payload, 'hierarchy');

        this.hierarchyDropdownChange([DeviceCode], hierarchydata, deviceList);
      }

      let dayMonthYear = {};
      let defaultSelection = '0';
      if (
        dashboards &&
        dashboards.headerJSON &&
        dashboards.headerJSON &&
        dashboards.headerJSON.DashboardLevelSelection !== 'none'
      ) {
        defaultSelection =
          dashboards.headerJSON.DashboardLevelSelectionPoint &&
          dashboards.headerJSON.DashboardLevelSelectionPoint === 'PreviousDay'
            ? '1'
            : '0';
      } else if (
        dashboards &&
        dashboards.json &&
        dashboards.json[0] &&
        dashboards.json[0].GraphLevelSelection !== 'none'
      ) {
        defaultSelection =
          dashboards &&
          dashboards.json &&
          dashboards.json[0] &&
          dashboards.json[0].GraphLevelSelectionPoint &&
          dashboards.json[0].GraphLevelSelectionPoint === 'PreviousDay'
            ? '1'
            : '0';
      }
      if (
        dashboards &&
        dashboards.headerJSON &&
        dashboards.headerJSON &&
        dashboards.headerJSON.DashboardLevelSelection === 'dateMonthYearPicker' &&
        dashboards.headerJSON.TimeBucket &&
        Object.keys(dashboards.headerJSON.TimeBucket).length > 0
      ) {
        let tb = dashboards.headerJSON.TimeBucket;

        if (Object.keys(tb)[0] === 'Day') {
          dayMonthYear = {
            StartDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD 00:00:00'),
            EndDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss'),
            TimeBucket: tb['Day'],
            SelectionType: 'Day'
          };
        } else if (Object.keys(tb)[0] === 'Week') {
          dayMonthYear = {
            StartDate: moment().format('YYYY-MM-DD 00:00:00'),
            EndDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss'),
            TimeBucket: tb['Week'],
            SelectionType: 'Week'
          };
        } else if (Object.keys(tb)[0] === 'Month') {
          dayMonthYear = {
            StartDate: moment().format('YYYY-MM-DD 00:00:00'),
            EndDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss'),
            TimeBucket: tb['Month'],
            SelectionType: 'Month'
          };
        } else if (Object.keys(tb)[0] === 'Year') {
          dayMonthYear = {
            StartDate: moment().format('YYYY-MM-DD 00:00:00'),
            EndDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss'),
            TimeBucket: tb['Year'],
            SelectionType: 'Year'
          };
        }
      }

      this.setState({
        panels: dashboards.json,
        headerJSON: dashboards.headerJSON,
        dashboardName: dashboards.Name,
        loading: false,
        deviceList,
        treeList,
        dashboards,
        DeviceCode:
          dashboards &&
          dashboards.headerJSON &&
          dashboards.headerJSON &&
          dashboards.headerJSON['checkedList'] === true
            ? 'All'
            : deviceList && deviceList[0] && deviceList[0].Value
            ? deviceList[0].Value
            : '',
        AssetCode,
        AssetName,
        AssetObject,
        selectionDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD 00:00:00'),
        selectionMonth: moment().format('YYYY-MM'),
        selectionWeek: moment().startOf('week'),
        selectionDateRange: {
          startDate: moment()
            .subtract(defaultSelection, 'day')
            .subtract(1, 'hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss')
        },
        dayMonthYear
      });
    } else {
      this.setState({
        loading: false,
        panels: [],
        headerJSON: {},
        dashboardName: '',
        dashboards,
        selectionDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        selectionDateRange: {
          startDate: moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm'),
          endDate: moment().format('YYYY-MM-DD HH:mm')
        },
        selectionMonth: moment().format('YYYY-MM'),
        selectionWeek: moment().startOf('week')
      });
    }
  };

  renameProperty = (obj, oldName1, newName1, oldName2, newName2) => {
    if (obj && obj.children) {
      let children = obj.children.map((objChildren) => {
        return this.renameProperty(objChildren, oldName1, newName1, oldName2, newName2);
      });
      obj['children'] = children;
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    } else {
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    }
  };

  ChildrenNames = (obj) => {
    // Commneted code is used to get Hierarchies last children of first node
    // obj &&
    //   obj.forEach((subObj) => {
    //     if (
    //       Object.prototype.hasOwnProperty.call(subObj, 'children') &&
    //       // subObj.hasOwnProperty('children') &&
    //       subObj.children instanceof Array &&
    //       subObj.children.length > 0
    //     ) {
    //       this.ChildrenNames(subObj.children, data);
    //     } else {
    //       if (Object.prototype.hasOwnProperty.call(subObj, 'key')) {
    //         // if (subObj.hasOwnProperty('key')) {
    //         data.push({ key: subObj.key, title: subObj.title });
    //       }
    //     }
    //   });
    let objectData =
      obj && obj[0] && obj[0].children
        ? obj[0].children.map((subChildren) => {
            return { key: subChildren.key, title: subChildren.title, Station: '' };
          })
        : [];

    return objectData;
  };

  editGraph = (graphData) => {
    let {
      DeviceCode,
      selectionDate,
      selectionMonth,
      selectionWeek,
      selectionDateRange,
      shift,
      dayMonthYear
    } = this.state;

    localStorage.set('graphData', JSON.stringify(graphData));
    this.props.history.push({
      pathname: `/rubus/dashboard/editpanel/${graphData.dashboardName}/${graphData.id}?kiosk=false`,
      selection: {
        DeviceCode,
        selectionDate,
        selectionMonth,
        selectionWeek,
        selectionDateRange,
        dayMonthYear,
        shift
      }
    });
  };

  drilldownRedirect = (url) => {
    this.props.history.push(`/rubus/dashboard/${url}`);
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  selectedTheme: makeSelectTheme(),
  menuCollapse: getMenuCollapse(),
  navigationMode: getNavigationMode()
});

export default connect(mapStateToProps)(Dashboard);
