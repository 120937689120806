import React, { Component } from 'react';
import { Input, Form, Select, Button, message } from 'antd';
import './reportConfig.css';

let { Option } = Select;

class WidgetCustomization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editObj: {},
      activeKey: [],
      dragDisabled: false,
      widgetProperties: props.widgetProperties || [],
      tableMaster: [],
      inputFields: [],
      payloadFields: [],
      showWidget: 'false',
      widgetPicker: ''
    };
    this.columns = {};
  }
  userRef = React.createRef();
  componentDidUpdate(prevprops) {
    if (this.props.widgetProperties !== prevprops.widgetProperties) {
      this.setState({
        widgetProperties: this.props.widgetProperties
      });
    }
  }

  handleFieldChange(value, colName) {
    const { widgetProperties } = this.state;
    widgetProperties[colName] = value;

    this.setState({
      widgetProperties,
      showWidget: value,
      widgetPicker: value
    });
    this.props.jsonSaveObject(widgetProperties, 'selectionWidget');
  }

  handleChange(value, keyName, key) {
    const { widgetProperties } = this.state;
    widgetProperties[keyName][key] = value;

    this.setState({
      widgetProperties
    });
    this.props.jsonSaveObject(widgetProperties, 'selectionWidget');
  }

  widgetSave = () => {
    const { widgetProperties } = this.state;
    this.userRef.current.validateFields().then(async () => {
      this.props.jsonSaveObject(widgetProperties, 'selectionWidget');
      message.success('Widget Validated Successfully');
      this.setState({
        activeKey: []
      });
    });
  };

  callback = (key) => {
    if (key && key.length <= 1) {
      this.setState({
        activeKey: key
      });
    } else if (key.length > 1) {
      key = key[key.length - 1];
      this.setState({
        activeKey: [key]
      });
    }
  };
  handlePayloadChange = (key, event, objectkey) => {
    const { widgetProperties } = this.state;
    if (objectkey === 'key') {
      widgetProperties.payload[event.target.value] = widgetProperties.payload[key];
      delete widgetProperties.payload[key];

      this.setState({ widgetProperties });
    } else if (objectkey === 'value') {
      if (typeof widgetProperties.payload[key] === 'object') {
        widgetProperties.payload[key] = {
          format: event.target.value
        };
        this.setState({ widgetProperties });
      } else if (typeof widgetProperties.payload[key] !== 'object') {
        widgetProperties.payload[key] = event.target.value;
        this.setState({ widgetProperties });
      }
    }

    this.props.jsonSaveObject(widgetProperties, 'selectionWidget');
  };
  addPayload = () => {
    let inputFields = [
      {
        reportKey: '',
        reportValue: ''
      }
    ];

    this.setState({ inputFields });
  };
  handleAddFields = () => {
    const values = this.state.inputFields;
    values.push({ reportKey: '', reportValue: '' });
    this.setState(values);
  };
  handleRemoveFields = (index) => {
    const values = this.state.inputFields;
    values.splice(index, 1);
    this.setState(values);
  };
  handleMainPayloadRemoveFields = (index) => {
    let { widgetProperties } = this.state;
    let { payload } = widgetProperties;

    delete payload[index];

    this.setState({ widgetProperties });
  };
  handlePayloadRemoveFields = (index) => {
    const values = this.state.payloadFields;
    values.splice(index, 1);
    this.setState(values);
  };
  UpdatePayload = () => {
    let { payloadFields, widgetProperties } = this.state;
    payloadFields.map((item) => {
      widgetProperties.payload[item.reportKey] = item.reportValue;
      return {};
    });

    this.setState({ payloadFields: [] });
    this.setState({ widgetProperties });
    this.props.jsonSaveObject(widgetProperties, 'selectionWidget');
  };
  handlePayloadInputChange = (index, event) => {
    const { payloadFields } = this.state;

    payloadFields[index][event.target.name] = event.target.value;

    this.setState({ payloadFields });
    // payloadFields.map((item, index) => {
    //   widgetProperties.payload[item.reportKey] = item.reportValue;
    // });
    // this.setState({ widgetProperties });

    // this.props.jsonSaveObject(widgetProperties, "api");
  };
  handleAddPayloadFields = () => {
    let { payloadFields } = this.state;

    // apiProperties = {
    //   ...apiProperties,
    //   ["payload"]:{
    //   ...( apiProperties["payload"] ? apiProperties["payload"] : {}),
    //  "":""
    // },

    payloadFields.push({
      reportKey: '',
      reportValue: ''
    });

    this.setState({ payloadFields });
  };

  handleInputChange = (index, event) => {
    let inputFields = this.state.inputFields;
    inputFields[index][event.target.name] = event.target.value;
    this.setState({ inputFields });
    let { widgetProperties } = this.state;
    inputFields.map((item) => {
      if (widgetProperties && widgetProperties.payload)
        widgetProperties.payload[item.reportKey] = item.reportValue;
      return {};
    });
    this.setState({ widgetProperties });
    this.props.jsonSaveObject(widgetProperties, 'selectionWidget');
  };

  render() {
    const { widgetProperties, payloadFields, showWidget, widgetPicker } = this.state;
    let item = widgetProperties;
    let newLocal = showWidget === 'true' ? true : false;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <div style={{ margin: '50px' }}>
        {widgetProperties ? (
          <div>
            <Form
              {...formItemLayout}
              layout="vertical"
              ref={this.userRef}
              name="user_form"
              initialValues={item || {}}
            >
              <Form.Item label="DisplayWidget :">
                <Select
                  value={item.displayWidget}
                  onSelect={(value) => this.handleFieldChange(value, 'displayWidget')}
                >
                  <Option value="true">TRUE</Option>
                  <Option value="false">FALSE</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Widget :"
                name="widget"
                rules={[{ required: newLocal, message: 'Please Select Widget!' }]}
              >
                <Select onSelect={(value) => this.handleFieldChange(value, 'widget')}>
                  <Option value="date-picker">Date Picker</Option>
                  <Option value="dateRange-picker">DateRange Picker</Option>
                  <Option value="month-picker">Month Picker</Option>
                  <Option value="monthRange-picker">MonthRange Picker</Option>
                  <Option value="year-picker">Year Picker</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Placeholder :"
                // name="Placeholder"
                rules={[{ required: newLocal, message: 'Please Select Placeholder!' }]}
              >
                <Input
                  value={item.placeholder}
                  onChange={(e) => this.handleFieldChange(e.target.value, 'placeholder')}
                ></Input>
              </Form.Item>
              <Form.Item
                label="Format :"
                name="format"
                rules={[{ required: true, message: 'Please Provide Format!' }]}
              >
                <Select
                  value={item.format}
                  // mode="tags"
                  onSelect={(value) => this.handleFieldChange(value, 'format')}
                >
                  {widgetPicker === 'date-picker' ? (
                    <>
                      <Option value="MM-DD-YY">MM-DD-YY</Option>
                      <Option value="DD-MM-YY">DD-MM-YY</Option>
                      <Option value="YY-MM-DD">YY-MM-DD</Option>
                    </>
                  ) : widgetPicker === 'month-picker' ? (
                    <>
                      <Option value="YY-MM">YYYY-MM</Option>
                      <Option value="MM-YY">MM-YY</Option>
                    </>
                  ) : widgetPicker === 'year-picker' ? (
                    <Option value="YYYYY-MMMM">YYYYY-MMMM</Option>
                  ) : widgetPicker === 'dateRange-picker' ? (
                    <Option value="DD-MM-YY-DD-MM-YY">DD-MM-YY - DD-MM-YY</Option>
                  ) : widgetPicker === 'monthRange-picker' ? (
                    <Option value="MM-YY">MM-YY - MM-YY</Option>
                  ) : null}
                </Select>
              </Form.Item>

              {/* <Form.Item label="Payload :">
                {widgetProperties &&
                widgetProperties.payload &&
                Object.entries(widgetProperties.payload).length > 0 &&
                this.props.currentTab === 'Edit Report JSON' ? (
                  Object.entries(widgetProperties.payload).map(([key, value], index) => (
                    <div key={index}>
                      {' '}
                      <input
                        type="text"
                        className="form-control"
                        id="reportKey"
                        name="reportKey"
                        value={key}
                        onChange={(e) => this.handlePayloadChange(key, e, 'key')}
                      />
                      <input
                        type="text"
                        className="form-control"
                        id="reportValue"
                        name="reportValue"
                        value={typeof value === 'object' ? value.format : value}
                        onChange={(e) => this.handlePayloadChange(key, e, 'value')}
                      />
                      <Button
                        className="btn-link"
                        type="Button"
                        onClick={() => this.handleMainPayloadRemoveFields(key)}
                      >
                        -
                      </Button>
                      {Object.keys(widgetProperties.payload).length - 1 === index ? (
                        <Button
                          className="btn-link"
                          type="Button"
                          onClick={() => this.handleAddPayloadFields()}
                        >
                          +
                        </Button>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div>
                    <Button onClick={(e) => this.addPayload(e)}>Add Payload</Button>
                    {inputFields.length > 0 &&
                      inputFields.map((item, index) => (
                        <Fragment key={`${item}~${index}`}>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              id="reportKey"
                              name="reportKey"
                              value={item.reportKey}
                              onChange={(e) => this.handleInputChange(index, e)}
                            />
                            <input
                              type="text"
                              className="form-control"
                              id="reportValue"
                              name="reportValue"
                              value={item.reportValue}
                              onChange={(e) => this.handleInputChange(index, e)}
                            />

                            <Button
                              className="btn-link"
                              type="Button"
                              onClick={() => this.handleRemoveFields(index)}
                            >
                              -
                            </Button>
                            {inputFields.length - 1 === index ? (
                              <Button
                                className="btn-link"
                                type="Button"
                                onClick={() => this.handleAddFields()}
                              >
                                +
                              </Button>
                            ) : null}
                          </div>
                        </Fragment>
                      ))}
                  </div>
                )}
                {this.props.currentTab === 'Edit Report JSON' &&
                payloadFields &&
                payloadFields.length > 0
                  ? payloadFields.map((item, index) => (
                      <Fragment key={`${item}~${index}`}>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            id="reportKey"
                            name="reportKey"
                            value={item.reportKey}
                            onChange={(e) => this.handlePayloadInputChange(index, e)}
                          />

                         <input
                            type="text"
                            className="form-control"
                            id="reportValue"
                            name="reportValue"
                            value={item.reportValue}
                            onChange={(e) => this.handlePayloadInputChange(index, e)}
                          />

                          <Button
                            className="btn-link"
                            type="Button"
                            onClick={() => this.handlePayloadRemoveFields(index)}
                          >
                            -
                          </Button>
                          {payloadFields.length - 1 === index ? (
                            <Button
                              className="btn-link"
                              type="Button"
                              onClick={() => this.handleAddPayloadFields()}
                            >
                              +
                            </Button>
                          ) : null}
                        </div>

                      </Fragment>
                    ))
                  : null}
              </Form.Item> */}
            </Form>
            <div>
              <Button
                type="primary"
                // style={{ marginLeft: "60x" }}
                onClick={(e) => this.widgetSave(e)}
              >
                Validate Json
              </Button>{' '}
              {payloadFields.length > 0 ? (
                <Button type="primary" onClick={(e) => this.UpdatePayload(e)}>
                  Update Payload
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default WidgetCustomization;
