import React, { Component } from 'react';
import MonacoEditor from 'react-monaco-editor';
import { CaretRightOutlined } from '@ant-design/icons';

import { Button, Collapse } from 'antd';
import config from '../../../constants/config.json';
const { Panel } = Collapse;
const customPanel = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden'
};

class MobileConfigEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.hRef = React.createRef();
  }
  editorDidMount = (editor) => {
    setTimeout(function () {
      editor.getAction('editor.action.formatDocument').run();
    }, 300);
  };

  editorWillMount = (monaco) => {
    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({});
  };

  saveConfig = () => {
    const model = this.monaco ? this.monaco.editor.getModel() : null;
    let value = model.getValue();
    value = JSON.parse(value);
    if (typeof value !== 'string') {
      this.props.columnEditorObjectMobile(value);
    } else {
      alert('Error');
    }
  };

  render() {
    const options = {
      selectOnLineNumbers: false
    };
    return (
      <div style={{ marginTop: '15px' }}>
        .
        <Collapse
          bordered={false}
          style={{ marginTop: '20px' }}
          // defaultActiveKey={"1"}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0}></CaretRightOutlined>
          )}
        >
          <Panel key="1" header={'JSON'} style={customPanel}>
            <MonacoEditor
              width="1000"
              height="500"
              language="json"
              theme="vs-dark"
              options={options}
              ref={(el) => (this.monaco = el)}
              value={JSON.stringify(this.props.form)}
              // value={this.props.form}
              // onChange={this.onChange}
              editorWillMount={this.editorWillMount}
              editorDidMount={this.editorDidMount}
            />
            {config.production !== true ? (
              <Button type="primary" onClick={this.saveConfig} style={{ marginTop: '5px' }}>
                Save
              </Button>
            ) : null}
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default MobileConfigEditor;
