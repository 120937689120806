import styled from 'styled-components';
import { Layout } from 'antd';

const { Footer } = Layout;

export const StyledFooter = styled(Footer)`
  background: rgba(0, 0, 0, 0.5);
  color: '#817979';
  line-height: 1.25rem;
  padding: 3px;
  font-size: 14px;
  position: fixed;
  bottom: 0px;
  right: 0px;
`;
export const FooterMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: 25px;
  margin-bottom: 3rem !important ;
`;

export const FooterDiv = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
`;
export const FooterTitle = styled.h4`
  color: white;
  text-transform: uppercase !important;
`;
export const FooterList = styled.div`
  padding-left: 0;
  list-style: none;
`;
export const FooterIcon = styled.li`
  margin-right: 1.5rem;
  display: inline-block;
`;
export const FooterAnchor = styled.a`
  color: white;
  margin-left: 1rem;
`;
export const FooterAlignment = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-between;
`;
export const FooterCopyRight = styled.span`
  font-size: 0.75rem;
  opacity: 0.6;
  a {
    color: white;
  }
  padding: 0px;
`;
