import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import DeviceParameterComponent from './DeviceParameterComponent';
import {
  getDeviceTypeList,
  saveDeviceParamters,
  deleteDeviceParameters
} from '../../../../modules/deviceType/deviceType.duck';

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        saveDeviceParamters,
        getDeviceTypeList,
        deleteDeviceParameters
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceParameterComponent);
