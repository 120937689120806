/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  Form,
  message,
  Collapse,
  Popconfirm,
  Select,
  Divider,
  Space,
  Button,
  DatePicker,
  TimePicker,
  Input,
  Col,
  Tabs
} from 'antd';
import FileBase64 from '../../../Utils/FileUpload';
import { Toolsform, LaborForm, MaterialForm, ItemForm } from './FieldDataMeasurementJobplanes';
import { StyledCollapse, StyledButton, StyledTabs, StyledSubForm } from '../../../CSS/style';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import themeSettings from '../../../Utils/themeSettings.json';
import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  getTaskListByEquipmentandTaskType,
  getTaskByIdFDM,
  getSkillTypeList,
  getUsersList,
  filterTaskByReference,
  _getUOMList
} from '../../../Utils/FormCalls';
import translation from '../../../Utils/translation';
import { constant } from '../../../Utils/constants';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import { StyledForm } from '../../../CSS/style';
import InputComponent from '../../../Widgets/InputComponent';
import Tooltip from 'antd/es/tooltip';

// const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;
// const fileData = new FormData();
class WorkOrderJobPlanes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      SkillTypeList: [],
      recordData: props.formObject && props.formObject.TaskData ? props.formObject.TaskData : [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      workOrderStatus: props.workOrderStatus,
      fileData: this.props.fileData ? this.props.fileData : [],
      translationCache: props.translationCache || {},
      Type: props && props.formObject && props.formObject.Type ? props.formObject.Type : '',
      selectedTab: '1'
    };
  }
  workOrderJobPlanRef = React.createRef();
  componentDidMount = async () => {
    const { recordData } = this.state;
    this.workOrderJobPlanRef.current !== null &&
      this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject);
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let organisationsList = await getOrganisationsList();
      this.setState({ organisationsList: organisationsList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      this.setState({ sitesList: sitesList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let usersList = await getUsersList();
      this.setState({ usersList: usersList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    let SkillTypeList = await getSkillTypeList();
    this.setState({ SkillTypeList: SkillTypeList });
    try {
      let TaskList = await getTaskListByEquipmentandTaskType(
        'Active',
        this.props.formObject.Equipment,
        this.props.formObject.Type
      );
      TaskList = filterTaskByReference(TaskList, recordData);
      this.setState({ TaskList: TaskList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let uomList = await _getUOMList();
      this.setState({ uomList: uomList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (prevProps.fileUploadList !== this.props.fileUploadList) {
      this.setState({ fileUploadList: this.props.fileUploadList });
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.workOrderJobPlanRef.current !== null &&
        this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject);
    }

    if (prevProps.formObject !== this.props.formObject) {
      this.workOrderJobPlanRef.current !== null &&
        this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject);
      this.setState({
        formObject: this.props.formObject,
        recordData:
          this.props.formObject && this.props.formObject.TaskData
            ? this.props.formObject.TaskData
            : [],
        workOrderStatus: this.props.workOrderStatus ? this.props.workOrderStatus : null,
        fileData: this.props.fileData ? this.props.fileData : null
      });
    }
  }

  handleChange = (value, key) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [key]: value
    };
    this.setState({ [key]: value, formObject });
  };

  onReset = () => {
    this.workOrderJobPlanRef.current.resetFields();
  };

  TaskOnChange = async (value) => {
    let TaskData = await getTaskByIdFDM(value, 'withoutId');
    let data =
      this.workOrderJobPlanRef.current.getFieldsValue() &&
      this.workOrderJobPlanRef.current.getFieldsValue().TaskData !== undefined
        ? this.workOrderJobPlanRef.current.getFieldsValue().TaskData
        : [];
    _.cloneDeepWith(TaskData, (value) => {
      return value !== undefined && value !== null
        ? {
            ...value,
            ...(value['StartTime']
              ? (value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['EndTime']
              ? (value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['EstimatedDuration']
              ? (value['EstimatedDuration'] = moment.utc(
                  value.EstimatedDuration,
                  'YYYY-MM-DD HH:mm:ss'
                ))
              : null)
          }
        : _.noop();
    });
    data.push(TaskData);
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      TaskData: data
    };
    this.setState({ selectedTask: value, formObject, TaskType: TaskData.Type });
    //    this.setState({ formObject: { ...this.state.formObject, ["TaskData"]: data }, selectedTask: value })
    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
    // this.workOrderJobPlanRef.current.setFieldsValue({ "TaskData": data })
  };
  actualTaskOnChange = async (value) => {
    let TaskData = await getTaskByIdFDM(value, 'withoutId');
    let data =
      this.workOrderJobPlanRef &&
      this.workOrderJobPlanRef.current &&
      this.workOrderJobPlanRef.current.getFieldsValue() &&
      this.workOrderJobPlanRef.current.getFieldsValue().ActualTaskData !== undefined
        ? this.workOrderJobPlanRef.current.getFieldsValue().ActualTaskData
        : [];
    _.cloneDeepWith(TaskData, (value) => {
      return value !== undefined && value !== null
        ? {
            ...value,
            ...(value['StartTime']
              ? (value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['EndTime']
              ? (value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['EstimatedDuration']
              ? (value['EstimatedDuration'] = moment.utc(
                  value.EstimatedDuration,
                  'YYYY-MM-DD HH:mm:ss'
                ))
              : null)
          }
        : _.noop();
    });
    data.push(TaskData);
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      ActualTaskData: data
    };
    this.setState({ selectedTask: value, formObject });
    //    this.setState({ formObject: { ...this.state.formObject, ["TaskData"]: data }, selectedTask: value })
    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
    // this.workOrderJobPlanRef.current.setFieldsValue({ "TaskData": data })
  };
  // handleSelect = (value, mainIndex, subindex, keyName) => {
  //   let formObject = this.workOrderJobPlanRef.current.getFieldValue();
  //   let TaskData = formObject && formObject.TaskData;
  //   TaskData.map((item, index) => {
  //     if (index === mainIndex) {
  //       item[keyName][subindex]['AvailableQuantity'] = value;
  //     }
  //     return {};
  //   });
  //   formObject['TaskData'] = TaskData;
  //   this.workOrderJobPlanRef.current.setFieldsValue(TaskData);
  // };

  handleSelect = (value, mainIndex, subindex, keyName, selectedObject) => {
    let { SkillTypeList } = this.state;
    if (keyName === 'Labor') {
      let skill = SkillTypeList.filter((item) => item.Id === selectedObject[0]['SkillTypeId']);
      let list = this.workOrderJobPlanRef.current.getFieldValue('TaskData');
      let TaskData = list;
      TaskData.map((item, index) => {
        if (index === mainIndex) {
          item[keyName][subindex]['SkillTypeId'] =
            skill && skill[0] && Array.isArray(skill) && skill[0].Id ? skill[0].Id : null;
          // item[keyName][subindex]['Description'] = selectedObject[0]['Description'];
          item[keyName][subindex]['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
        }
        return {};
      });
      this.workOrderJobPlanRef.current.setFieldsValue({ TaskData: TaskData });
    } else {
      let formObject = this.workOrderJobPlanRef.current.getFieldValue();
      let TaskData = formObject && formObject.TaskData;
      TaskData.map((item, index) => {
        if (index === mainIndex) {
          item[keyName][subindex]['Description'] = selectedObject[0]['Description'];
          item[keyName][subindex]['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
        }
        return {};
      });
      formObject['TaskData'] = TaskData;
      this.setState({ formObject });
      this.workOrderJobPlanRef.current.setFieldsValue({ TaskData: TaskData });
    }
  };
  handleSelectActual = (value, mainIndex, subindex, keyName, selectedObject) => {
    console.log(value, mainIndex, subindex, keyName, selectedObject, 'actualTaskk');

    let { SkillTypeList } = this.state;
    if (keyName === 'Labor') {
      let skill = SkillTypeList.filter((item) => item.Id === selectedObject[0]['SkillTypeId']);
      let list = this.workOrderJobPlanRef.current.getFieldValue('ActualTaskData');
      let ActualTaskData = list;
      ActualTaskData.map((item, index) => {
        if (index === mainIndex) {
          item[keyName][subindex]['SkillTypeId'] =
            skill && skill[0] && Array.isArray(skill) && skill[0].Id ? skill[0].Id : null;
          // item[keyName][subindex]['Description'] = selectedObject[0]['Description'];
          item[keyName][subindex]['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
        }
        return {};
      });
      this.workOrderJobPlanRef.current.setFieldsValue({ ActualTaskData: ActualTaskData });
    } else {
      let formObject = this.workOrderJobPlanRef.current.getFieldValue();
      let ActualTaskData = formObject && formObject.ActualTaskData;
      ActualTaskData.map((item, index) => {
        if (index === mainIndex) {
          item[keyName][subindex]['Description'] = selectedObject[0]['Description'];
          item[keyName][subindex]['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
        }
        return {};
      });
      formObject['ActualTaskData'] = ActualTaskData;
      console.log(ActualTaskData, 'ActualTaskData');

      this.setState({ formObject });
      this.workOrderJobPlanRef.current.setFieldsValue({ ActualTaskData: ActualTaskData });
    }
  };
  remove = (taskIndex, toolIndex, key, taskType) => {
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    let ToolsList =
      formObject &&
      formObject[taskType] &&
      formObject[taskType][taskIndex] &&
      formObject[taskType][taskIndex][key];
    if (ToolsList) {
      let Tools =
        ToolsList &&
        ToolsList.filter((tool, index) => {
          return toolIndex !== index;
        });
      formObject = {
        ...formObject,
        [taskType]:
          formObject[taskType] &&
          Array.isArray(formObject[taskType]) &&
          formObject[taskType].map((tasks, index) => {
            if (index === taskIndex) {
              let ToolsArray = tasks && tasks[key] ? tasks[key] : [];
              Tools =
                ToolsArray &&
                Array.isArray(ToolsArray) &&
                ToolsArray.filter((tool, tlIndex) => {
                  return tlIndex !== toolIndex;
                });
              if (key === 'Tools') {
                return { ...tasks, Tools: Tools };
              } else if (key === 'Labor') {
                return { ...tasks, Labor: Tools };
              } else if (key === 'Materials') {
                return { ...tasks, Materials: Tools };
              } else if (key === 'Items') {
                return { ...tasks, Items: Tools };
              } else return tasks;
            } else {
              return tasks;
            }
          })
      };
      this.workOrderJobPlanRef.current.setFieldsValue(formObject);
      this.setState({ formObject });
    }
  };
  add = (taskIndex, key) => {
    this.props.noJobplan();
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      TaskData:
        formObject.TaskData &&
        Array.isArray(formObject.TaskData) &&
        formObject.TaskData.map((task, index) => {
          if (index === taskIndex) {
            let newObj = null;
            let reqArray = task && task[key] ? task[key] : [];
            reqArray.push(newObj);
            if (key === 'Tools') {
              return { ...task, Tools: reqArray };
            } else if (key === 'Labor') {
              return { ...task, Labor: reqArray };
            } else if (key === 'Materials') {
              return { ...task, Materials: reqArray };
            } else if (key === 'Items') {
              return { ...task, Items: reqArray };
            } else return task;
          } else {
            return task;
          }
        })
    };
    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
    this.setState({ formObject });
  };
  addActual = (taskIndex, key) => {
    this.props.noJobplan();
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();

    formObject = {
      ...formObject,
      ActualTaskData:
        formObject.ActualTaskData &&
        Array.isArray(formObject.ActualTaskData) &&
        formObject.ActualTaskData.map((task, index) => {
          if (index === taskIndex) {
            let newObj = null;
            let reqArray = task && task[key] ? task[key] : [];
            reqArray.push(newObj);
            if (key === 'Tools') {
              return { ...task, Tools: reqArray };
            } else if (key === 'Labor') {
              return { ...task, Labor: reqArray };
            } else if (key === 'Materials') {
              return { ...task, Materials: reqArray };
            } else if (key === 'Items') {
              return { ...task, Items: reqArray };
            } else return task;
          } else {
            return task;
          }
        })
    };
    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
    this.setState({ formObject });
    // }
  };
  datepickerchange = (date, dateString, index, key) => {
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    if (
      key &&
      key === 'PlannedDate' &&
      this.workOrderJobPlanRef.current.getFieldValue() &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]
    ) {
      formObject &&
      formObject['TaskData'] &&
      formObject['TaskData'][index] &&
      formObject['TaskData'][index]['PlannedDate']
        ? (formObject['TaskData'][index]['PlannedDate'] = moment(dateString)
            .utc()
            .format('YYYY-MM-DD HH:mm'))
        : (formObject['TaskData'][index]['PlannedDate'] = '');
      this.setState({ formObject });
    }
    if (
      key &&
      key === 'ActualDate' &&
      this.workOrderJobPlanRef.current.getFieldValue() &&
      this.workOrderJobPlanRef.current.getFieldValue().ActualTaskData &&
      this.workOrderJobPlanRef.current.getFieldValue().ActualTaskData[index]
    ) {
      formObject &&
      formObject['ActualTaskData'] &&
      formObject['ActualTaskData'][index] &&
      formObject['ActualTaskData'][index]['ActualDate']
        ? (formObject['ActualTaskData'][index]['ActualDate'] = moment(dateString)
            .utc()
            .format('YYYY-MM-DD HH:mm'))
        : (formObject['ActualTaskData'][index]['ActualDate'] = '');
      this.setState({ formObject });
    }
  };

  getDisabledHours = (e, index, key) => {
    let hours = [];
    let minutes = [];
    let StartDateTime = '';
    let EndDateTime = '';
    let StartDate = '';
    let EndDate = '';

    if (key === 'StartTime') {
      StartDateTime = moment(e).format('YYYY-MM-DD HH:mm');
      EndDateTime = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime'],
          'YYYY-MM-DD HH:mm'
        )
        .local();

      StartDate = moment(e).format('YYYY-MM-DD');
      EndDate = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime']
        )
        .local()
        .format('YYYY-MM-DD');
    } else if (key === 'EndTime') {
      StartDateTime = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime'],
          'YYYY-MM-DD HH:mm'
        )
        .local();
      EndDateTime = moment(e).format('YYYY-MM-DD HH:mm');

      StartDate = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime']
        )
        .local()
        .format('YYYY-MM-DD');
      EndDate = moment(e).format('YYYY-MM-DD');
    }
    if (
      key === 'StartTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (var i = 0; i <= 24; i++) {
        if (i > moment(EndDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var j = 0; j <= 60; j++) {
          if (j > moment(EndDateTime).minutes() - 1) {
            minutes.push(j);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    } else if (
      key === 'EndTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (var k = 0; k < moment(StartDateTime).hours(); k++) {
        if (k < 25) {
          hours.push(k);
        }
      }

      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var J = 0; J < moment(StartDateTime).minutes() + 1; J++) {
          if (J < 61) {
            minutes.push(J);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    }
  };
  onFinish = () => {};
  taskFileupload = async (values, key, fileDataObject, index, TaskId) => {
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    let { fileUploadList } = this.state;
    if (
      formObject &&
      formObject['TaskData'] &&
      formObject['TaskData'][index] &&
      formObject['TaskData'][index][key]
    ) {
      formObject['TaskData'][index][key].push(...values);
    } else if (formObject['TaskData'][index] !== undefined) {
      formObject['TaskData'][index][key] = values;
    } else {
      formObject['TaskData'].push({ [key]: values });
    }
    formObject &&
      formObject['TaskData'].map((item, index) => {
        formObject['TaskData'][index][key] =
          formObject['TaskData'][index][key] &&
          formObject['TaskData'][index][key].reduce((acc, current) => {
            const x = acc.find((item) => item.Name === current.Name);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
        return {};
      });
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    let TaskData = [...(fileUploadList && fileUploadList.TaskData ? fileUploadList.TaskData : [])];

    TaskData.push({ fileDataObject, TaskId });
    fileUploadList = {
      ...fileUploadList,
      TaskData
    };

    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileUploadList });
    this.props.fileUploadListBack(fileUploadList);
  };
  actualTaskFileupload = async (values, key, fileDataObject, index, TaskId) => {
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    let { fileUploadList } = this.state;
    if (
      formObject &&
      formObject['ActualTaskData'] &&
      formObject['ActualTaskData'][index] &&
      formObject['ActualTaskData'][index][key]
    ) {
      formObject['ActualTaskData'][index][key].push(...values);
    } else if (formObject['ActualTaskData'][index] !== undefined) {
      formObject['ActualTaskData'][index][key] = values;
    } else {
      formObject['ActualTaskData'].push({ [key]: values });
    }
    formObject &&
      formObject['ActualTaskData'].map((item, index) => {
        formObject['ActualTaskData'][index][key] =
          formObject['ActualTaskData'][index][key] &&
          formObject['ActualTaskData'][index][key].reduce((acc, current) => {
            const x = acc.find((item) => item.Name === current.Name);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
        return {};
      });
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    let ActualTaskData = [
      ...(fileUploadList && fileUploadList.ActualTaskData ? fileUploadList.ActualTaskData : [])
    ];

    ActualTaskData.push({ fileDataObject, TaskId });
    fileUploadList = {
      ...fileUploadList,
      ActualTaskData
    };

    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileUploadList });
    this.props.fileUploadListBack(fileUploadList);
  };
  // actualTaskFileupload = async (values, key, fileDataObject, index, TaskId) => {
  //   let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
  //   let { fileUploadList } = this.state;
  //   if (
  //     formObject &&
  //     formObject['ActualTaskData'] &&
  //     formObject['ActualTaskData'][index] &&
  //     formObject['ActualTaskData'][index][key]
  //   ) {
  //     formObject['ActualTaskData'][index][key].push(...values);
  //   } else if (formObject['ActualTaskData'][index] !== undefined) {
  //     formObject['ActualTaskData'][index][key] = values;
  //   } else {
  //     formObject['ActualTaskData'].push({ [key]: values });
  //   }
  //   formObject &&
  //   formObject['ActualTaskData'].map((item, index) => {
  //     formObject['ActualTaskData'][index][key] =
  //       formObject['ActualTaskData'][index][key] &&
  //       formObject['ActualTaskData'][index][key].reduce((acc, current) => {
  //         const x = acc.find((item) => item.Name === current.Name);
  //         if (!x) {
  //           return acc.concat([current]);
  //         } else {
  //           return acc;
  //         }
  //       }, []);
  //     return {};
  //   });
  // formObject = {
  //   ...formObject,
  //   [key]: formObject[key]
  // };
  //   let ActualTaskData = [
  //     ...(fileUploadList && fileUploadList.ActualTaskData ? fileUploadList.ActualTaskData : [])
  //   ];

  //   ActualTaskData.push({ fileDataObject, TaskId });
  //   fileUploadList = {
  //     ...fileUploadList,
  //     ActualTaskData
  //   };

  //   this.workOrderJobPlanRef.current.setFieldsValue(formObject);
  //   this.setState({ formObject, fileUploadList });
  //   this.props.fileUploadListBack(fileUploadList);
  // };
  removeTask = (key, tab) => {
    let formObject = this.workOrderJobPlanRef.current.getFieldValue();
    if (tab === 'ActualTaskData') {
      formObject = {
        ...formObject,
        ActualTaskData:
          formObject &&
          formObject.ActualTaskData &&
          formObject.ActualTaskData.filter((task, index) => index !== key)
      };
      this.workOrderJobPlanRef.current.setFieldsValue(formObject);
      this.setState({ formObject });
    } else {
      formObject = {
        ...formObject,
        TaskData:
          formObject &&
          formObject.TaskData &&
          formObject.TaskData.filter((task, index) => index !== key)
      };
      this.workOrderJobPlanRef.current.setFieldsValue(formObject);
      this.setState({ formObject });
    }
  };
  render() {
    let { formObject, TaskList, selectedTask, translationCache, usersList, uomList, fileData } =
      this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    const styledCollapse = (formObject, key, translationCache) => {
      return (
        <span>
          <span style={{ fontWeight: 'bold' }}>{translation(translationCache, 'Task Name')}</span>:
          <span style={{ color: 'white', margin: '0px 8px' }}>
            {formObject &&
            formObject['TaskData'] &&
            formObject['TaskData'][key] &&
            formObject['TaskData'][key]['TaskName']
              ? formObject['TaskData'][key]['TaskName']
              : ''}
          </span>
          <span style={{ fontWeight: 'bold' }}>
            {translation(translationCache, 'Task Description')}
          </span>{' '}
          :
          <span style={{ color: 'white', margin: '0px 8px' }}>
            {formObject &&
            formObject['TaskData'] &&
            formObject['TaskData'][key] &&
            formObject['TaskData'][key]['TaskDescription']
              ? formObject['TaskData'][key]['TaskDescription']
              : ''}
          </span>
        </span>
      );
    };
    const styledCollapseActual = (formObject, key, translationCache) => {
      return (
        <span>
          <span style={{ fontWeight: 'bold' }}>{translation(translationCache, 'Task Name')}</span>:
          <span style={{ color: 'white', margin: '0px 8px' }}>
            {formObject &&
            formObject['ActualTaskData'] &&
            formObject['ActualTaskData'][key] &&
            formObject['ActualTaskData'][key]['TaskName']
              ? formObject['ActualTaskData'][key]['TaskName']
              : ''}
          </span>
          <span style={{ fontWeight: 'bold' }}>
            {translation(translationCache, 'Task Description')}
          </span>{' '}
          :
          <span style={{ color: 'white', margin: '0px 8px' }}>
            {formObject &&
            formObject['ActualTaskData'] &&
            formObject['ActualTaskData'][key] &&
            formObject['ActualTaskData'][key]['TaskDescription']
              ? formObject['ActualTaskData'][key]['TaskDescription']
              : ''}
          </span>
        </span>
      );
    };
    return (
      <div style={{ marginTop: '5px', marginBottom: '30px', color: 'black', fontWeight: 'bold' }}>
        <>
          <StyledForm
            ref={this.workOrderJobPlanRef}
            name={'JobPlan'}
            onFinish={(values) => this.props.dataF(values, 'JobPlan', fileData)}
            // onFinishFailed={this.onFinishFailed}
            initialValues={formObject}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            theme={themeSettings}
            labelAlign="right"
            labelWrap
            //wrapperCol={{ flex: 1 }}
            validateMessages={validateMessages}
          >
            {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.addTask)}
               >
                <Select
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache,"Select Task")}
                  defaultValue={selectedTask}
                  onChange={(value) => this.TaskOnChange(value)}
                  // style={{ width: '10%' }}
                >
                  {TaskList &&
                    Array.isArray(TaskList) &&
                    TaskList.map((task, index) => {
                      return (
                        <Option key={index} value={task.Id}>
                          {task.TaskName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item style={{ width: '100%', flex: '50%' }}>
                <InputComponent style={{ display: 'none' }} />
              </Form.Item>
            </div> */}

            <StyledTabs
              theme={themeSettings}
              // className={'cardContainer'}
              defaultActiveKey="1"
              type="card"
              size={'middle'}
              onChange={(key) => {
                if (key === '1') {
                  this.setState({ selectedTab: key });
                } else if (key === '2') {
                  this.setState({ selectedTab: key });
                }
                console.log(key, 'keyyyy');
              }}
            >
              <TabPane
                // className={workOrderStatus && workOrderStatus === 3 ? 'checkTab' : 'testt'}
                tab={translation(translationCache, 'Planned Task')}
                key="1"
              >
                <Form.List
                  initialValue={formObject && formObject['TaskData'] ? formObject['TaskData'] : []}
                  name="TaskData"
                >
                  {(fields, { add }) => (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          style={{ width: '100%', flex: '50%' }}
                          label={translation(translationCache, constant.addTask)}
                          name="TaskName"
                        >
                          <Select
                            placeholder={translation(translationCache, 'Select Task')}
                            defaultValue={selectedTask}
                            onChange={(value) => this.TaskOnChange(value)}
                            style={{ width: '70%' }}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider
                                  style={{
                                    margin: '8px 0'
                                  }}
                                />
                                <Space
                                  style={{
                                    padding: '0 8px 4px'
                                  }}
                                >
                                  <Button
                                    type="dashed"
                                    // onClick={() => this.setState({ viewEquipmentModal: true })}
                                    onClick={() => add()}
                                  >
                                    {' '}
                                    {translation(translationCache, constant.addNewTask)}{' '}
                                  </Button>
                                </Space>
                              </>
                            )}
                          >
                            {TaskList &&
                              Array.isArray(TaskList) &&
                              TaskList.map((task, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={task.Id}
                                    disabled={
                                      this.workOrderJobPlanRef.current &&
                                      this.workOrderJobPlanRef.current.getFieldsValue() &&
                                      this.workOrderJobPlanRef.current.getFieldsValue().TaskData &&
                                      this.workOrderJobPlanRef.current
                                        .getFieldsValue()
                                        .TaskData.filter(
                                          (item) =>
                                            item &&
                                            item !== undefined &&
                                            item.TaskName === task.TaskName
                                        ).length > 0
                                        ? true
                                        : false
                                    }
                                  >
                                    {task.TaskName}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>

                        <Form.Item style={{ width: '100%', flex: '50%' }}>
                          <InputComponent style={{ display: 'none' }} />
                        </Form.Item>
                      </div>
                      {fields && Array.isArray(fields) && fields.length > 0 ? (
                        <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                          {fields.map(({ key, name, ...restField }) => (
                            <Panel
                              key={key}
                              header={styledCollapse(formObject, key, translationCache)}
                              extra={
                                <Popconfirm
                                  title={translation(
                                    translationCache,
                                    constant.areYouSureThatYouWantToDeleteTheTask
                                  )}
                                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                  onConfirm={() => this.removeTask(key, 'TaskData')}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Tooltip
                                    title={translation(translationCache, 'Delete')}
                                    placement="bottom"
                                  >
                                    <DeleteOutlined />
                                  </Tooltip>
                                </Popconfirm>
                              }
                            >
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.task)}
                                  {...restField}
                                  name={[name, 'TaskName']}
                                  rules={[{ required: true }]}
                                >
                                  <InputComponent
                                    placeholder={translation(translationCache, 'Enter Task')}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.taskDescription)}
                                  {...restField}
                                  name={[name, 'TaskDescription']}
                                  rules={[{ required: true }]}
                                >
                                  <InputComponent
                                    placeholder={translation(
                                      translationCache,
                                      'Enter Task Description'
                                    )}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.criterion)}
                                  {...restField}
                                  name={[name, 'Criteria']}
                                  rules={[
                                    {
                                      // pattern: new RegExp(/^[A-Za-z0-9 ]*$/)
                                      // pattern: new RegExp(
                                      //   /^[一-龯ぁ-んァ-ンぁ-んァ-ン\wぁ-んァ-ンｧ-ﾝﾞﾟぁ-ゞ0-9A-Za-zァ-ヶｦ-ﾟ-_&一 ]*$/
                                      // )
                                      // message: 'field does not accept special characters'
                                    }
                                  ]}
                                >
                                  <InputComponent
                                    //maxLength={20}
                                    placeholder={translation(translationCache, 'Enter criterion')}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.observation)}
                                  {...restField}
                                  name={[name, 'Observation']}
                                >
                                  <InputComponent
                                    //maxLength={20}
                                    placeholder={translation(translationCache, 'Enter Observation')}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  name="Attachments"
                                  label={translation(translationCache, 'Attachments')}
                                  // disabled={
                                  //   formObject &&
                                  //   formObject['TaskData'] &&
                                  //   formObject['TaskData'][key] &&
                                  //   formObject['TaskData'][key]['Id']
                                  //     ? false
                                  //     : true
                                  // }
                                >
                                  <FileBase64
                                    className={'formuploadInput'}
                                    name={key}
                                    downloadClassName={'formdownloadInput'}
                                    Attachments={
                                      formObject &&
                                      formObject['TaskData'] &&
                                      formObject['TaskData'][key] &&
                                      formObject['TaskData'][key]['Attachments']
                                        ? formObject['TaskData'][key]['Attachments']
                                        : []
                                    }
                                    // disableAdd={
                                    //   formObject &&
                                    //   formObject['TaskData'] &&
                                    //   formObject['TaskData'][key] &&
                                    //   formObject['TaskData'][key].Id
                                    //     ? false
                                    //     : true
                                    // }
                                    fileUpload={(values, key, fileDataObject, index) => {
                                      this.taskFileupload(
                                        values,
                                        key,
                                        fileDataObject,
                                        index,
                                        formObject &&
                                          formObject &&
                                          formObject.TaskData &&
                                          formObject.TaskData[index] &&
                                          formObject.TaskData[index].Id
                                          ? formObject.TaskData[index].Id
                                          : ''
                                      );
                                    }}
                                  ></FileBase64>
                                </Form.Item>

                                {this.props &&
                                this.props.formObject &&
                                this.props.formObject.Type &&
                                this.props.formObject.Type === 'observationType' ? (
                                  <>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(
                                        translationCache,
                                        constant.estimatedDuration
                                      )}
                                      {...restField}
                                      name={[name, 'EstimatedDuration']}
                                    >
                                      {/* <InputComponent
                                    placeholder={translation(translationCache, 'EstimatedDuration')}
                                    value={formObject.EstimatedDuration}
                                    style={{ width: '70%' }}
                                  /> */}
                                      <TimePicker
                                        style={{ width: '70%' }}
                                        // format={'HH:mm'}
                                        allowClear={false}
                                        onChange={(value) =>
                                          this.handleChange(value, 'EstimatedDuration')
                                        }
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.method)}
                                      {...restField}
                                      name={[name, 'Method']}
                                      rules={[{ required: false }]}
                                    >
                                      <InputComponent
                                        placeholder={translation(translationCache, 'Enter Method')}
                                        value={formObject.Method}
                                        style={{ width: '70%' }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      label={translation(translationCache, constant.plannedDate)}
                                      style={{
                                        width: '100%',
                                        flex: '50%'
                                        // opacity: newLocal ? 0.75 : 1,
                                        // pointerEvents: newLocal ? 'none' : 'initial'
                                      }}
                                      {...restField}
                                      name={[name, 'PlannedDate']}
                                    >
                                      <DatePicker
                                        style={{ width: '70%' }}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        // inputReadOnly={false}
                                        onChange={(date, dateString) => {
                                          this.datepickerchange(
                                            date,
                                            dateString,
                                            key,
                                            'PlannedDate'
                                          );
                                        }}
                                        showTime={{
                                          defaultValue: moment('00:00:00', 'HH:mm:ss')
                                        }}
                                        disabledDate={(d) =>
                                          !d ||
                                          d.isBefore(moment().add(0, 'day').format('YYYY-MM-DD'))
                                        }
                                      />
                                    </Form.Item>
                                  </>
                                ) : (
                                  ''
                                )}

                                {this.props &&
                                this.props.formObject &&
                                this.props.formObject.Type &&
                                this.props.formObject.Type === 'fieldDataType' ? (
                                  <>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(
                                        translationCache,
                                        constant.measurementValue
                                      )}
                                      {...restField}
                                      name={[name, 'MeasurementValue']}
                                    >
                                      <InputComponent
                                        placeholder={translation(
                                          translationCache,
                                          'Enter MeasurementValue'
                                        )}
                                        style={{ width: '70%' }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.UOM)}
                                      {...restField}
                                      name={[name, 'UOM']}
                                    >
                                      <Select
                                        style={{ width: '70%' }}
                                        placeholder={translation(
                                          translationCache,
                                          'Select Parameter'
                                        )}
                                      >
                                        {uomList &&
                                          Array.isArray(uomList) &&
                                          uomList.map((uom, index) => {
                                            return (
                                              <Option key={index} value={uom.UOM}>
                                                {uom.UOM}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.recordBy)}
                                      {...restField}
                                      name={[name, 'RecordBy']}
                                    >
                                      <Select
                                        placeholder={translation(
                                          translationCache,
                                          'Select RecordBy'
                                        )}
                                        style={{ width: '70%' }}
                                      >
                                        {usersList &&
                                          Array.isArray(usersList) &&
                                          usersList.map((item, index) => {
                                            return (
                                              <Option key={index} value={item.Id}>
                                                {' '}
                                                {item.FirstName}{' '}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(
                                        translationCache,
                                        constant.inspectionMethod
                                      )}
                                      {...restField}
                                      name={[name, 'Method']}
                                    >
                                      <InputComponent
                                        //maxLength={20}
                                        placeholder={translation(
                                          translationCache,
                                          'Enter Inspection Method'
                                        )}
                                        style={{ width: '70%' }}
                                      />
                                    </Form.Item>
                                  </>
                                ) : (
                                  ''
                                )}

                                <Form.Item style={{ width: '100%', flex: '50%' }}>
                                  <Input style={{ display: 'none' }} />
                                </Form.Item>
                              </div>

                              <div>
                                <StyledSubForm theme={themeSettings} style={{ padding: '0px' }}>
                                  <StyledTabs
                                    theme={themeSettings}
                                    // className={'cardContainer'}
                                    defaultActiveKey="1"
                                    type="card"
                                    size={'middle'}
                                  >
                                    <TabPane
                                      tab={translation(translationCache, constant.tool)}
                                      key="1"
                                    >
                                      <Col span={24}>
                                        <Form.Item>
                                          <Toolsform
                                            fieldKey={name}
                                            formObject={
                                              this.workOrderJobPlanRef.current !== null
                                                ? this.workOrderJobPlanRef.current.getFieldsValue()
                                                : {}
                                            }
                                            onToolChange={this.handleSelect}
                                            onRemove={this.remove}
                                            taskType={'TaskData'}
                                            onAdd={this.add}
                                            selectedTab={this.state.selectedTab}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </TabPane>
                                    <TabPane
                                      tab={translation(translationCache, constant.labor)}
                                      key="2"
                                    >
                                      <Col span={24}>
                                        <Form.Item>
                                          <LaborForm
                                            fieldKey={name}
                                            formObject={
                                              this.workOrderJobPlanRef.current !== null
                                                ? this.workOrderJobPlanRef.current.getFieldsValue()
                                                : {}
                                            }
                                            onAdd={this.add}
                                            onRemove={this.remove}
                                            taskType={'TaskData'}
                                            onLaborChange={this.handleSelect}
                                            selectedTab={this.state.selectedTab}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </TabPane>
                                    <TabPane
                                      tab={translation(translationCache, constant.material)}
                                      key="3"
                                    >
                                      <Col span={24}>
                                        <Form.Item>
                                          <MaterialForm
                                            fieldKey={name}
                                            formObject={
                                              this.workOrderJobPlanRef.current !== null
                                                ? this.workOrderJobPlanRef.current.getFieldsValue()
                                                : {}
                                            }
                                            onAdd={this.add}
                                            onMaterialChange={this.handleSelect}
                                            onRemove={this.remove}
                                            taskType={'TaskData'}
                                            selectedTab={this.state.selectedTab}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </TabPane>
                                    <TabPane
                                      tab={translation(translationCache, constant.items)}
                                      key="4"
                                    >
                                      <Col span={24}>
                                        <Form.Item>
                                          <ItemForm
                                            fieldKey={name}
                                            formObject={
                                              this.workOrderJobPlanRef.current !== null
                                                ? this.workOrderJobPlanRef.current.getFieldsValue()
                                                : {}
                                            }
                                            onAdd={this.add}
                                            onRemove={this.remove}
                                            taskType={'TaskData'}
                                            onItemChange={this.handleSelect}
                                            selectedTab={this.state.selectedTab}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </TabPane>
                                  </StyledTabs>
                                </StyledSubForm>
                              </div>
                            </Panel>
                          ))}
                        </StyledCollapse>
                      ) : null}
                    </>
                  )}
                </Form.List>
              </TabPane>

              <TabPane tab={translation(translationCache, 'Actual Task')} key="2">
                <Form.List
                  initialValue={
                    formObject && formObject['ActualTaskData'] ? formObject['ActualTaskData'] : []
                  }
                  name="ActualTaskData"
                >
                  {(fields, { add }) => (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          style={{ width: '100%', flex: '50%' }}
                          label={translation(translationCache, constant.addTask)}
                          name="TaskName1"
                        >
                          <Select
                            placeholder={translation(translationCache, 'Select Task')}
                            defaultValue={selectedTask}
                            onChange={(value) => this.actualTaskOnChange(value)}
                            style={{ width: '70%' }}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider
                                  style={{
                                    margin: '8px 0'
                                  }}
                                />
                                <Space
                                  style={{
                                    padding: '0 8px 4px'
                                  }}
                                >
                                  <Button
                                    type="dashed"
                                    // onClick={() => this.setState({ viewEquipmentModal: true })}
                                    onClick={() => add()}
                                  >
                                    {' '}
                                    {translation(translationCache, constant.addNewTask)}{' '}
                                  </Button>
                                </Space>
                              </>
                            )}
                          >
                            {TaskList &&
                              Array.isArray(TaskList) &&
                              TaskList.map((task, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={task.Id}
                                    disabled={
                                      this.workOrderJobPlanRef.current &&
                                      this.workOrderJobPlanRef.current.getFieldsValue() &&
                                      this.workOrderJobPlanRef.current.getFieldsValue()
                                        .ActualTaskData &&
                                      this.workOrderJobPlanRef.current
                                        .getFieldsValue()
                                        .ActualTaskData.filter(
                                          (item) =>
                                            item &&
                                            item !== undefined &&
                                            item.TaskName === task.TaskName
                                        ).length > 0
                                        ? true
                                        : false
                                    }
                                  >
                                    {task.TaskName}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>

                        <Form.Item style={{ width: '100%', flex: '50%' }}>
                          <InputComponent style={{ display: 'none' }} />
                        </Form.Item>
                      </div>
                      {fields && Array.isArray(fields) && fields.length > 0 ? (
                        <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                          {fields.map(({ key, name, ...restField }) => (
                            <Panel
                              key={key}
                              header={styledCollapseActual(formObject, key, translationCache)}
                              extra={
                                <Popconfirm
                                  title={translation(
                                    translationCache,
                                    constant.areYouSureThatYouWantToDeleteTheTask
                                  )}
                                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                  onConfirm={() => this.removeTask(key, 'ActualTaskData')}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Tooltip
                                    title={translation(translationCache, 'Delete')}
                                    placement="bottom"
                                  >
                                    <DeleteOutlined />
                                  </Tooltip>
                                </Popconfirm>
                              }
                            >
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.task)}
                                  {...restField}
                                  name={[name, 'TaskName']}
                                  rules={[{ required: true }]}
                                >
                                  <InputComponent
                                    placeholder={translation(translationCache, 'Enter Task')}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.taskDescription)}
                                  {...restField}
                                  name={[name, 'TaskDescription']}
                                  rules={[{ required: true }]}
                                >
                                  <InputComponent
                                    placeholder={translation(
                                      translationCache,
                                      'Enter Task Description'
                                    )}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.criterion)}
                                  {...restField}
                                  name={[name, 'Criteria']}
                                  rules={[
                                    {
                                      // pattern: new RegExp(/^[A-Za-z0-9 ]*$/)
                                      // pattern: new RegExp(
                                      //   /^[一-龯ぁ-んァ-ンぁ-んァ-ン\wぁ-んァ-ンｧ-ﾝﾞﾟぁ-ゞ0-9A-Za-zァ-ヶｦ-ﾟ-_&一 ]*$/
                                      // )
                                      // message: 'field does not accept special characters'
                                    }
                                  ]}
                                >
                                  <InputComponent
                                    //maxLength={20}
                                    placeholder={translation(translationCache, 'Enter criterion')}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  label={translation(translationCache, constant.observation)}
                                  {...restField}
                                  name={[name, 'Observation']}
                                >
                                  <InputComponent
                                    //maxLength={20}
                                    placeholder={translation(translationCache, 'Enter Observation')}
                                    style={{ width: '70%' }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  style={{ width: '100%', flex: '50%' }}
                                  name="Attachments"
                                  label={translation(translationCache, 'Attachments')}
                                >
                                  <FileBase64
                                    className={'formuploadInput'}
                                    name={key}
                                    downloadClassName={'formdownloadInput'}
                                    Attachments={
                                      formObject &&
                                      formObject['ActualTaskData'] &&
                                      formObject['ActualTaskData'][key] &&
                                      formObject['ActualTaskData'][key]['Attachments']
                                        ? formObject['ActualTaskData'][key]['Attachments']
                                        : []
                                    }
                                    // disableAdd={
                                    //   formObject &&
                                    //   formObject['TaskData'] &&
                                    //   formObject['TaskData'][key] &&
                                    //   formObject['TaskData'][key].Id
                                    //     ? false
                                    //     : true
                                    // }
                                    fileUpload={(values, key, fileDataObject, index) => {
                                      this.actualTaskFileupload(
                                        values,
                                        key,
                                        fileDataObject,
                                        index,
                                        formObject &&
                                          formObject &&
                                          formObject.ActualTaskData &&
                                          formObject.ActualTaskData[index] &&
                                          formObject.ActualTaskData[index].Id
                                          ? formObject.ActualTaskData[index].Id
                                          : ''
                                      );
                                    }}
                                  ></FileBase64>
                                </Form.Item>
                                <Form.Item
                                  label={translation(translationCache, constant.actualDate)}
                                  style={{
                                    width: '100%',
                                    flex: '50%'
                                    // opacity: newLocal ? 0.75 : 1,
                                    // pointerEvents: newLocal ? 'none' : 'initial'
                                  }}
                                  {...restField}
                                  name={[name, 'ActualDate']}
                                >
                                  <DatePicker
                                    style={{ width: '70%' }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    // inputReadOnly={false}
                                    onChange={(date, dateString) => {
                                      this.datepickerchange(date, dateString, key, 'ActualDate');
                                    }}
                                    showTime={{
                                      defaultValue: moment('00:00:00', 'HH:mm:ss')
                                    }}
                                    disabledDate={(d) =>
                                      !d || d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                                    }
                                  />
                                </Form.Item>

                                {this.props &&
                                this.props.formObject &&
                                this.props.formObject.Type &&
                                this.props.formObject.Type === 'observationType' ? (
                                  <>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(
                                        translationCache,
                                        constant.estimatedDuration
                                      )}
                                      {...restField}
                                      name={[name, 'EstimatedDuration']}
                                    >
                                      {/* <InputComponent
                                    placeholder={translation(translationCache, 'EstimatedDuration')}
                                    value={formObject.EstimatedDuration}
                                    style={{ width: '70%' }}
                                  /> */}
                                      <TimePicker
                                        style={{ width: '70%' }}
                                        // format={'HH:mm'}
                                        allowClear={false}
                                        onChange={(value) =>
                                          this.handleChange(value, 'EstimatedDuration')
                                        }
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.method)}
                                      {...restField}
                                      name={[name, 'Method']}
                                      rules={[{ required: false }]}
                                    >
                                      <InputComponent
                                        placeholder={translation(translationCache, 'Enter Method')}
                                        value={formObject.Method}
                                        style={{ width: '70%' }}
                                      />
                                    </Form.Item>
                                  </>
                                ) : (
                                  ''
                                )}

                                {this.props &&
                                this.props.formObject &&
                                this.props.formObject.Type &&
                                this.props.formObject.Type === 'fieldDataType' ? (
                                  <>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(
                                        translationCache,
                                        constant.measurementValue
                                      )}
                                      {...restField}
                                      name={[name, 'MeasurementValue']}
                                    >
                                      <InputComponent
                                        placeholder={translation(
                                          translationCache,
                                          'Enter MeasurementValue'
                                        )}
                                        style={{ width: '70%' }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.UOM)}
                                      {...restField}
                                      name={[name, 'UOM']}
                                    >
                                      <Select
                                        style={{ width: '70%' }}
                                        placeholder={translation(
                                          translationCache,
                                          'Select Parameter'
                                        )}
                                      >
                                        {uomList &&
                                          Array.isArray(uomList) &&
                                          uomList.map((uom, index) => {
                                            return (
                                              <Option key={index} value={uom.UOM}>
                                                {' '}
                                                {uom.UOM}{' '}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(translationCache, constant.recordBy)}
                                      {...restField}
                                      name={[name, 'RecordBy']}
                                    >
                                      <Select
                                        placeholder={translation(
                                          translationCache,
                                          'Select RecordBy'
                                        )}
                                        style={{ width: '70%' }}
                                      >
                                        {usersList &&
                                          Array.isArray(usersList) &&
                                          usersList.map((item, index) => {
                                            return (
                                              <Option key={index} value={item.Id}>
                                                {' '}
                                                {item.FirstName}{' '}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      style={{ width: '100%', flex: '50%' }}
                                      label={translation(
                                        translationCache,
                                        constant.inspectionMethod
                                      )}
                                      {...restField}
                                      name={[name, 'Method']}
                                    >
                                      <InputComponent
                                        //maxLength={20}
                                        placeholder={translation(
                                          translationCache,
                                          'Enter Inspection Method'
                                        )}
                                        style={{ width: '70%' }}
                                      />
                                    </Form.Item>
                                  </>
                                ) : (
                                  ''
                                )}

                                <Form.Item style={{ width: '100%', flex: '50%' }}>
                                  <Input style={{ display: 'none' }} />
                                </Form.Item>
                              </div>
                              <div>
                                <StyledSubForm theme={themeSettings} style={{ padding: '0px' }}>
                                  <StyledTabs
                                    theme={themeSettings}
                                    // className={'cardContainer'}
                                    defaultActiveKey="1"
                                    type="card"
                                    size={'middle'}
                                  >
                                    <TabPane
                                      tab={translation(translationCache, constant.tool)}
                                      key="1"
                                    >
                                      <Col span={24}>
                                        <Form.Item>
                                          <Toolsform
                                            fieldKey={name}
                                            formObject={
                                              this.workOrderJobPlanRef.current !== null
                                                ? this.workOrderJobPlanRef.current.getFieldsValue()
                                                : {}
                                            }
                                            onToolChange={this.handleSelectActual}
                                            onRemove={this.remove}
                                            taskType={'ActualTaskData'}
                                            onAdd={this.addActual}
                                            selectedTab={this.state.selectedTab}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </TabPane>
                                    <TabPane
                                      tab={translation(translationCache, constant.labor)}
                                      key="2"
                                    >
                                      <Col span={24}>
                                        <Form.Item>
                                          <LaborForm
                                            fieldKey={name}
                                            formObject={
                                              this.workOrderJobPlanRef.current !== null
                                                ? this.workOrderJobPlanRef.current.getFieldsValue()
                                                : {}
                                            }
                                            onLaborChange={this.handleSelectActual}
                                            onRemove={this.remove}
                                            taskType={'ActualTaskData'}
                                            onAdd={this.addActual}
                                            selectedTab={this.state.selectedTab}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </TabPane>
                                    <TabPane
                                      tab={translation(translationCache, constant.material)}
                                      key="3"
                                    >
                                      <Col span={24}>
                                        <Form.Item>
                                          <MaterialForm
                                            fieldKey={name}
                                            formObject={
                                              this.workOrderJobPlanRef.current !== null
                                                ? this.workOrderJobPlanRef.current.getFieldsValue()
                                                : {}
                                            }
                                            onMaterialChange={this.handleSelectActual}
                                            onRemove={this.remove}
                                            taskType={'ActualTaskData'}
                                            onAdd={this.addActual}
                                            selectedTab={this.state.selectedTab}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </TabPane>
                                    <TabPane
                                      tab={translation(translationCache, constant.items)}
                                      key="4"
                                    >
                                      <Col span={24}>
                                        <Form.Item>
                                          <ItemForm
                                            fieldKey={name}
                                            formObject={
                                              this.workOrderJobPlanRef.current !== null
                                                ? this.workOrderJobPlanRef.current.getFieldsValue()
                                                : {}
                                            }
                                            onItemChange={this.handleSelectActual}
                                            onRemove={this.remove}
                                            taskType={'ActualTaskData'}
                                            onAdd={this.addActual}
                                            selectedTab={this.state.selectedTab}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </TabPane>
                                  </StyledTabs>
                                </StyledSubForm>
                              </div>
                            </Panel>
                          ))}
                        </StyledCollapse>
                      ) : null}
                    </>
                  )}
                </Form.List>
              </TabPane>
            </StyledTabs>

            {/* {this.props.noJobplan === true ? */}
            <Form.Item style={{ display: 'flex', justifyContent: 'right' }}>
              <StyledButton
                theme={themeSettings}
                style={{ marginTop: '20px', marginBottom: '-7px' }}
                onClick={() =>
                  this.props.onFinish(
                    this.workOrderJobPlanRef.current.getFieldsValue(),
                    'JobPlan',
                    fileData
                  )
                }
              >
                {translation(translationCache, constant.save)}
              </StyledButton>
            </Form.Item>
            {/* :null} */}
          </StyledForm>{' '}
        </>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WorkOrderJobPlanes);
