import { API_LANGUAGE } from '../../commons/api';
import localStorage from '../../utils/localStorage';

export default class translation {
  static getTranslationCache(language) {
    let SiteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`${API_LANGUAGE.LanguageFilter}?SiteId=${SiteId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        Language: language || 'English'
      })
    }).then(responseToken);

    function responseToken(response) {
      return response.json();
    }
  }
}
