import React, { Fragment } from 'react';
import { Input, Select, Popover, Col, Row, TimePicker } from 'antd';
import moment from 'moment';

import {
  Boxdiv,
  StyledAppdiv,
  RuleTypeSelectionDiv,
  BlockedLabelBlack,
  ConditionSelect,
  ActionSelectionDiv,
  // AddParagraph,
  LogicAddButton,
  BlankDiv,
  DeviceSelect,
  ParameterSelect,
  TagNameSelect,
  OperaterSelect,
  ValueInput,
  ButtonBoxDiv,
  // PickyStyling,
  MarginRightButton,
  OnchangeCheckbox,
  ActionCheckbox,
  TextParagraph
  // BlockedParaBlack
} from '../style';
import { actionList } from '../utils/const';
import { getCommunicationList, getGroupList, getUserprofiles, recipientListAPI } from '../Apicalls';
import constants from '../constants';
import localStorage from '../../../utils/localStorage';
import 'antd/lib/date-picker/style/css';
import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import history from '../../../commons/history';

const { Option } = Select;
const { TextArea } = Input;
const Operator = [
  {
    key: 'EqualTo (==)',
    value: '=='
  },
  {
    key: 'Not EqualTo (!=)',
    value: '!='
  },
  {
    key: 'Greater Than (>)',
    value: '>'
  },
  {
    key: 'Less Than (<)',
    value: '<'
  },
  {
    key: 'Greater Than Or EqualTo (>=)',
    value: '>='
  },
  {
    key: 'Less Than Or EqualTo (<=)',
    value: '<='
  }
];

// export default function DeviceRuleView(props) {
export default class DeviceTypeRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sitesList: [],
      ItemdataSource: [],
      treeVisible: false,
      communicationType: [],
      value: [],
      data: [],
      translationAction: [],
      checkedValue: [],
      groupList: [],
      selectHierachyLevel: [],
      userProfile: props.userProfile || {},
      DeviceData: [],
      treeData: this.props && this.props.treeData ? this.props.treeData : [],
      selectedHierarchy: []
    };
  }

  async componentDidMount() {
    let { communicationType } = this.state;
    let commlist = await getCommunicationList();
    let groupList = await getGroupList();

    const { handleRuleType } = this.props.Methods;
    console.log('groupList', groupList);

    communicationType =
      commlist &&
      commlist.data &&
      Array.isArray(commlist.data) &&
      commlist.data.map((item) => {
        let val = item.Code;
        let t1 = communicationType.push(val);
        return t1;
      });
    let filteredActionList = this.getList();
    this.setState({ data: filteredActionList, communicationType, groupList });
    let userProfile = await getUserprofiles();
    let recipientData = await recipientListAPI();
    let HierachyLevels =
      userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['hierarchy'];

    let Group =
      groupList && Array.isArray(groupList) && groupList.length > 0 ? groupList[0].Code : '';
    this.setState({
      selectedHierarchy: HierachyLevels,
      recipientList: recipientData && recipientData.data ? recipientData.data : [],
      Group: Group
    });
    handleRuleType(Group, 'Group');
  }

  getList = () => {
    let { communicationType } = this.state;
    let filteredArray = [];
    filteredArray = communicationType.map((comm) => {
      return actionList.filter((action) => action.value === comm);
    });
    let data = [];
    filteredArray.map((item) => {
      return item.map((val) => {
        return data.push(val);
      });
    });
    return data;
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  hierarchyDropdownChange = (value) => {
    const { Methods } = this.props;
    const { handledeviceSelect } = Methods;
    let { indexDevice } = this.props;
    handledeviceSelect(value, 'inputList', indexDevice);
  };
  onModalOk = () => {
    this.setState({
      treeVisible: false
    });
  };
  onModalCancel = () => {
    this.setState({
      treeVisible: false
    });
  };
  hierarchyDropdownChanged = (value) => {
    this.setState({ checkedValue: value });
  };

  onModalsOk = (e) => {
    const { Methods } = this.props;
    const { handlePickyChange } = Methods;
    let { checkedValue } = this.state;
    e.preventDefault();
    handlePickyChange(checkedValue);
    this.setState({
      visibleTree: false
      // test: this.props.config.replicateDevices
    });
  };
  onModalsCancel = () => {
    this.setState({
      visibleTree: false
    });
  };

  onTree = (arr) => {
    if (arr && Array.isArray(arr)) {
      arr.map((obj) => {
        return obj && Array.isArray(obj.children)
          ? { ...obj, children: this.onTree(obj.children) }
          : { ...obj, checkable: true };
      });
    }
    return arr;
  };

  render() {
    const { config, Methods } = this.props;
    const {
      inputList,
      conif,
      conelseif,
      conelse,
      elsifLength,
      elseList,
      ruleType,
      mailRecipients,
      rulebody,
      url,
      // Group,
      deviceTypeList,
      onContinous,
      PMvisibleDT,
      rootCausevisibleDT,
      recommandationVisibleDT,
      PMElsevisibleDT,
      rootcauseElsevisibleDT,
      recommandationElsevisibleDT,
      parametersList,
      parameterChangeList
      // disabledParamTypeDevice
    } = config;
    const {
      handleParameterSelect,
      handleDelay,
      handleFrequency,
      checkonChange,
      handleTagSelect,
      handleInputChange,
      handleAddClick,
      handleRuleType,
      handleRecipientList,
      handleRulebody,
      handleurl,
      // handlePickyChange,
      // handleRadioButton,
      handleContinous,
      handleRemoveClick,
      handleVisibleChange,
      hanblePopoverOnclick,
      handleTextAreaChange,
      handleConditionchange
      // handleparameterTypeSelect
    } = Methods;
    let { groupList, recipientList, Group } = this.state;
    console.log('Group', Group);

    return (
      <StyledAppdiv>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <a
            style={{ marginTop: '5px' }}
            onClick={() => history.push('/rubus/DeviceTypeRuleExclusion')}
          >
            {this.translation('Redirect to devicetype settings')}
          </a>
        </div>
        <RuleTypeSelectionDiv>
          <div style={{ marginRight: '12%', marginLeft: '10px' }}>
            <BlockedLabelBlack>{this.translation(constants.select_group)}</BlockedLabelBlack>
            <Select
              placeholder="Select Group"
              style={{ width: '120px', display: 'inline-block' }}
              value={Group}
              onChange={(value) => handleRuleType(value, 'Group')}
            >
              {groupList &&
                Array.isArray(groupList) &&
                groupList.map((group, index) => {
                  return (
                    <Option key={index} value={group.Code}>
                      {this.translation(group.Name)}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </RuleTypeSelectionDiv>

        <>
          {conif
            ? inputList.map((x, i) => {
                return (
                  <Boxdiv key={`if${i}`}>
                    {i === 0 ? (
                      <div style={{ display: 'block' }}>
                        <BlockedLabelBlack>{`if`}</BlockedLabelBlack>
                      </div>
                    ) : null}
                    {i > 0 ? (
                      <div
                        style={{
                          marginRight: '10px 0px'
                        }}
                      >
                        <ConditionSelect
                          name="operater"
                          style={{
                            marginBottom: '25px'
                          }}
                          placeholder={this.translation(constants.selectCondition)}
                          size="default"
                          value={x['54765'] === '' ? undefined : x['54765']}
                          onChange={(e) => handleConditionchange(e, i, 'inputList')}
                        >
                          <Option value="&&">AND</Option>
                          <Option value="||">OR</Option>
                        </ConditionSelect>
                      </div>
                    ) : null}

                    <ParameterSelect
                      // disabled={disabledParamTypeDevice}
                      name="parameterName"
                      placeholder={this.translation(constants.ParameterType)}
                      size="default"
                      value={x.parameterName === '' ? undefined : x.parameterName}
                      onChange={(value) => handleParameterSelect(value, i, 'inputList')}
                    >
                      {parameterChangeList &&
                        Array.isArray(parameterChangeList) &&
                        parameterChangeList.map((parameter, i) => {
                          return (
                            <Option key={`ifdeviceList${i}`} value={parameter.ParameterType}>
                              {parameter.ParameterType}
                            </Option>
                          );
                        })}
                    </ParameterSelect>
                    <DeviceSelect
                      placeholder={this.translation(constants.selectdevicetype)}
                      size="default"
                      value={x.deviceType === '' ? undefined : x.deviceType}
                      onChange={this.hierarchyDropdownChange}
                    >
                      {deviceTypeList &&
                        Array.isArray(deviceTypeList) &&
                        deviceTypeList.map((deviceType, i) => {
                          return (
                            <Option key={`ifdeviceTypeList${i}`} value={deviceType.AliasCode}>
                              {deviceType.Name}
                            </Option>
                          );
                        })}
                    </DeviceSelect>
                    <TagNameSelect
                      showSearch
                      name="tagName"
                      size="default"
                      placeholder={this.translation(constants.selectparameters)}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={x && x.tagName ? `${x.tagName}` : undefined}
                      onChange={(e) => handleTagSelect(e, i, 'inputList')}
                    >
                      {parametersList &&
                        Array.isArray(parametersList) &&
                        parametersList.map((param, j) => {
                          return (
                            <Option key={`inputTagname${i}${j}`} value={param.Code}>
                              {param.Name}
                            </Option>
                          );
                        })}
                    </TagNameSelect>
                    <OperaterSelect
                      name="operater"
                      size="default"
                      placeholder={this.translation(constants.selectoperator)}
                      value={x.operater === '' ? undefined : x.operater}
                      onChange={(e) => handleInputChange(e, i, 'select', 'inputList')}
                    >
                      {Operator.map((item, index) => {
                        return (
                          <>
                            {config.parameter === 'Operational' ? (
                              <Option key={index} value={item.value}>
                                {item.key}
                              </Option>
                            ) : null}
                          </>
                        );
                      })}
                      {config.parameter !== 'Operational' ? (
                        <Option value="==" title="EqualTo">
                          {`EqualTo (==)`}
                        </Option>
                      ) : null}
                    </OperaterSelect>
                    <ValueInput
                      size="default"
                      name="value"
                      maxLength={6}
                      placeholder={this.translation(constants.value)}
                      value={x.value}
                      onChange={(e) => handleInputChange(e, i, 'input', 'inputList')}
                    />

                    {inputList.length !== 1 && (
                      <MarginRightButton
                        size="default"
                        onClick={() => handleRemoveClick(i, 'inputList')}
                      >
                        <DeleteOutlined />
                      </MarginRightButton>
                    )}
                    {inputList.length - 1 === i && (
                      <LogicAddButton
                        type={'primary'}
                        size="default"
                        onClick={() => handleAddClick('inputList', this.state.ruleType)}
                      >
                        {this.translation(constants.add)}
                      </LogicAddButton>
                    )}

                    <ButtonBoxDiv>
                      {inputList.length - 1 === i && (
                        <Fragment>
                          <Popover
                            content={
                              <div>
                                <TextArea
                                  value={
                                    inputList[i] && inputList[i]['preventive']
                                      ? inputList[i]['preventive']
                                      : ''
                                  }
                                  rows={5}
                                  maxLength="150"
                                  onChange={(e) => {
                                    handleTextAreaChange(e, i, 'inputList', 'preventive');
                                  }}
                                />

                                <span
                                  style={{ color: '#1890ff', cursor: 'pointer' }}
                                  onClick={() => {
                                    hanblePopoverOnclick(
                                      i,
                                      'inputList',
                                      'close',
                                      'preventive',
                                      'PMvisibleDT'
                                    );
                                  }}
                                >
                                  {this.translation('Cancel')}
                                </span>
                                <span
                                  style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                  onClick={() => {
                                    hanblePopoverOnclick(i, 'inputList', 'ok', 'PMvisibleDT');
                                  }}
                                >
                                  {this.translation('Ok')}
                                </span>
                              </div>
                            }
                            open={PMvisibleDT}
                            onOpenChange={(value) => handleVisibleChange(value, 'PMvisibleDT')}
                            title={this.translation(constants.preventivemeasure)}
                            trigger="click"
                          >
                            <TextParagraph>
                              {this.translation(constants.preventivemeasure)}
                            </TextParagraph>
                            {inputList[i]['preventive'] && inputList[i]['preventive'].length > 0 ? (
                              <span style={{ color: '#60c40e' }}>
                                <CheckCircleOutlined />
                              </span>
                            ) : null}
                          </Popover>
                          <Popover
                            content={
                              <div>
                                <TextArea
                                  value={inputList[i]['rootcause']}
                                  rows={5}
                                  maxLength="150"
                                  onChange={(e) =>
                                    handleTextAreaChange(e, i, 'inputList', 'rootcause')
                                  }
                                />
                                <span
                                  style={{ color: '#1890ff', cursor: 'pointer' }}
                                  onClick={() => {
                                    hanblePopoverOnclick(
                                      i,
                                      'inputList',
                                      'close',
                                      'rootcause',
                                      'rootCausevisibleDT'
                                    );
                                  }}
                                >
                                  {' '}
                                  {this.translation('Cancel')}{' '}
                                </span>
                                <span
                                  style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                  onClick={() => {
                                    hanblePopoverOnclick(
                                      i,
                                      'inputList',
                                      'ok',
                                      'rootCausevisibleDT'
                                    );
                                  }}
                                >
                                  {' '}
                                  {this.translation('Ok')}{' '}
                                </span>
                              </div>
                            }
                            open={rootCausevisibleDT}
                            onOpenChange={(value) =>
                              handleVisibleChange(value, 'rootCausevisibleDT')
                            }
                            title={this.translation(constants.rootcauseanalysis)}
                            trigger="click"
                          >
                            <TextParagraph>
                              {this.translation(constants.rootcauseanalysis)}
                            </TextParagraph>
                            {inputList[i]['rootcause'] && inputList[i]['rootcause'].length > 0 ? (
                              <span style={{ color: '#60c40e' }}>
                                <CheckCircleOutlined />
                              </span>
                            ) : null}
                          </Popover>
                          <Popover
                            content={
                              <div>
                                <TextArea
                                  value={inputList[i]['recommandation']}
                                  rows={5}
                                  maxLength="150"
                                  onChange={(e) =>
                                    handleTextAreaChange(e, i, 'inputList', 'recommandation')
                                  }
                                />
                                <span
                                  style={{ color: '#1890ff', cursor: 'pointer' }}
                                  onClick={() => {
                                    hanblePopoverOnclick(
                                      i,
                                      'inputList',
                                      'close',
                                      'recommandation',
                                      'recommandationVisibleDT'
                                    );
                                  }}
                                >
                                  {' '}
                                  {this.translation('Cancel')}{' '}
                                </span>
                                <span
                                  style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                  onClick={() => {
                                    hanblePopoverOnclick(
                                      i,
                                      'inputList',
                                      'ok',
                                      'recommandationVisibleDT'
                                    );
                                  }}
                                >
                                  {' '}
                                  {this.translation('Ok')}{' '}
                                </span>
                              </div>
                            }
                            open={recommandationVisibleDT}
                            onOpenChange={(value) =>
                              handleVisibleChange(value, 'recommandationVisibleDT')
                            }
                            title={this.translation(constants.recomandations)}
                            trigger="click"
                          >
                            <TextParagraph>
                              {this.translation(constants.recomandations)}
                              {inputList[i]['recommandation'] &&
                              inputList[i]['recommandation'].length > 0 ? (
                                <span style={{ color: '#60c40e' }}>
                                  <CheckCircleOutlined />
                                </span>
                              ) : null}
                            </TextParagraph>
                          </Popover>
                        </Fragment>
                      )}
                    </ButtonBoxDiv>

                    {i === inputList.length - 1 ? (
                      <div>
                        <Row>
                          <Col span={10}>
                            <ActionSelectionDiv>
                              <BlockedLabelBlack>
                                {this.translation(constants.action)}
                                <span style={{ color: 'red' }}>*</span>
                              </BlockedLabelBlack>
                              <ActionCheckbox
                                options={this.state.data}
                                value={config.inputList[i]['action']}
                                onChange={(checkedValues) => {
                                  checkonChange(checkedValues, 'inputList', i);
                                }}
                              />
                              <Popover placement="top" title={'Delay'}>
                                <TimePicker
                                  style={{ margin: '10px' }}
                                  defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                  value={
                                    config.inputList &&
                                    config.inputList[i] &&
                                    config.inputList[i]['delay'] === undefined
                                      ? moment('00:00:00', 'HH:mm:ss')
                                      : moment(
                                          config.inputList &&
                                            config.inputList[i] &&
                                            config.inputList[i]['delay'][1],
                                          'HH:mm:ss'
                                        )
                                  }
                                  onChange={(e) => handleDelay(e, 'inputList', i)}
                                  placeholder={this.translation(constants.selectDelay)}
                                  allowClear={false}
                                  size="small"
                                />
                              </Popover>

                              {config &&
                              config.inputList &&
                              config.inputList[config.inputList.length - 1] &&
                              config.inputList[config.inputList.length - 1].cation &&
                              config.inputList[config.inputList.length - 1].action.indexOf(
                                'RestAPI'
                              ) > -1 ? (
                                <Input
                                  style={{ width: '55%', marginTop: '2%' }}
                                  placeholder="Enter Url"
                                  name="value"
                                  value={url}
                                  onChange={(e) => handleurl(e, 'url')}
                                />
                              ) : null}
                            </ActionSelectionDiv>
                          </Col>
                        </Row>
                      </div>
                    ) : null}

                    {i === inputList.length - 1 ? (
                      <div>
                        <Row>
                          <Col span={10}>
                            <ActionSelectionDiv>
                              <BlockedLabelBlack>
                                {this.translation(constants.NotificationType)}
                                {/* <span style={{ color: 'red' }}>*</span> */}
                              </BlockedLabelBlack>
                              {ruleType !== 'downTime' ? (
                                <OnchangeCheckbox
                                  checked={onContinous}
                                  onChange={(value) => handleContinous(value)}
                                >
                                  {this.translation(constants.onContinous)}
                                </OnchangeCheckbox>
                              ) : null}
                              <Popover placement="top" title={'Frequency'}>
                                <TimePicker
                                  disabled={onContinous === true ? false : true}
                                  style={{ margin: '10px' }}
                                  defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                  value={
                                    config.inputList &&
                                    config.inputList[i] &&
                                    config.inputList[i]['frequency'] === undefined
                                      ? moment('00:00:00', 'HH:mm:ss')
                                      : moment(
                                          config.inputList &&
                                            config.inputList[i] &&
                                            config.inputList[i]['frequency'][1],
                                          'HH:mm:ss'
                                        )
                                  }
                                  onChange={(e) => handleFrequency(e, 'inputList', i)}
                                  placeholder={this.translation(constants.selectFrequency)}
                                  allowClear={false}
                                  size="small"
                                />
                              </Popover>

                              {config &&
                              config.inputList &&
                              config.inputList[config.inputList.length - 1] &&
                              config.inputList[config.inputList.length - 1].cation &&
                              config.inputList[config.inputList.length - 1].action.indexOf(
                                'RestAPI'
                              ) > -1 ? (
                                <Input
                                  style={{ width: '55%', marginTop: '2%' }}
                                  placeholder="Enter Url"
                                  name="value"
                                  value={url}
                                  onChange={(e) => handleurl(e, 'url')}
                                />
                              ) : null}
                            </ActionSelectionDiv>
                          </Col>
                        </Row>
                      </div>
                    ) : null}

                    {i === inputList.length - 1 ? (
                      <div>
                        <Row>
                          <Col span={7}>
                            <BlockedLabelBlack>
                              {this.translation(constants.members)}
                            </BlockedLabelBlack>
                            <Select
                              placeholder={this.translation('RecipientList')}
                              mode="multiple"
                              value={mailRecipients}
                              style={{ width: '100%', display: 'inline-block' }}
                              onChange={(value) => handleRecipientList(value, 'mailRecipients')}
                            >
                              {recipientList &&
                                Array.isArray(recipientList) &&
                                recipientList.map((item, index) => {
                                  return (
                                    <Option key={index} value={item.Email}>
                                      {item.FirstName} {item.LastName}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                  </Boxdiv>
                );
              })
            : null}
          {/* -------------------- Else if */}
          {conelseif
            ? elsifLength.map((elsepropName, index) => {
                return config[elsepropName].map((x, i) => {
                  return (
                    <Boxdiv key={`${index}${i}`}>
                      {i === 0 ? <BlockedLabelBlack>else if</BlockedLabelBlack> : null}
                      {i > 0 ? (
                        <ConditionSelect
                          size="default"
                          name="operater"
                          value={x['54765'] === '' ? undefined : x['54765']}
                          placeholder={this.translation(constants.selectCondition)}
                          onChange={(e) => handleConditionchange(e, i, elsepropName)}
                        >
                          <Option value="&&">{this.translation('AND')}</Option>
                          <Option value="||">{this.translation('OR')}</Option>
                        </ConditionSelect>
                      ) : null}

                      <ParameterSelect
                        // disabled={disabledParamTypeDevice}
                        name="parameterName"
                        placeholder={this.translation(constants.ParameterType)}
                        size="default"
                        value={x.parameterName === '' ? undefined : x.parameterName}
                        onChange={(value) => handleParameterSelect(value, i, elsepropName)}
                      >
                        {parameterChangeList &&
                          Array.isArray(parameterChangeList) &&
                          parameterChangeList.map((parameter, i) => {
                            return (
                              <Option key={`ifdeviceList${i}`} value={parameter.ParameterType}>
                                {parameter.ParameterType}
                              </Option>
                            );
                          })}
                      </ParameterSelect>
                      <DeviceSelect
                        placeholder={this.translation(constants.selectdevicetype)}
                        size="default"
                        value={x.deviceType === '' ? undefined : x.deviceType}
                        onChange={this.hierarchyDropdownChange}
                      >
                        {deviceTypeList &&
                          Array.isArray(deviceTypeList) &&
                          deviceTypeList.map((deviceType, i) => {
                            return (
                              <Option key={`ifdeviceTypeList${i}`} value={deviceType.AliasCode}>
                                {deviceType.Name}
                              </Option>
                            );
                          })}
                      </DeviceSelect>
                      <TagNameSelect
                        showSearch
                        name="tagName"
                        size="default"
                        placeholder={this.translation(constants.selectparameters)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={x && x.tagName ? `${x.tagName}` : undefined}
                        onChange={(e) => handleTagSelect(e, i, elsepropName)}
                      >
                        {parametersList &&
                          Array.isArray(parametersList) &&
                          parametersList.map((param, j) => {
                            return (
                              <Option key={`inputTagname${i}${j}`} value={param.Code}>
                                {param.Name}
                              </Option>
                            );
                          })}
                      </TagNameSelect>
                      <OperaterSelect
                        name="operater"
                        size="default"
                        placeholder={this.translation(constants.selectoperator)}
                        value={x.operater === '' ? undefined : x.operater}
                        onChange={(e) => handleInputChange(e, i, 'select', elsepropName)}
                      >
                        {Operator.map((item, index) => {
                          return (
                            <>
                              {config.parameter === 'Operational' ? (
                                <Option key={index} value={item.value}>
                                  {item.key}
                                </Option>
                              ) : null}
                            </>
                          );
                        })}
                        {config.parameter !== 'Operational' ? (
                          <Option value="==" title="EqualTo">
                            {`EqualTo (==)`}
                          </Option>
                        ) : null}
                      </OperaterSelect>
                      <ValueInput
                        size="default"
                        name="value"
                        maxLength={6}
                        placeholder={this.translation(constants.value)}
                        value={x.value}
                        onChange={(e) => handleInputChange(e, i, 'input', elsepropName)}
                      />
                      <ButtonBoxDiv>
                        {config[elsepropName].length !== 1 && (
                          <MarginRightButton
                            size="default"
                            onClick={() => handleRemoveClick(i, elsepropName)}
                          >
                            <DeleteOutlined />
                          </MarginRightButton>
                        )}
                        {config[elsepropName].length - 1 === i && (
                          <Fragment>
                            <LogicAddButton
                              type={'primary'}
                              size="default"
                              onClick={() => handleAddClick(elsepropName, this.state.ruleType)}
                            >
                              {this.translation(constants.add)}
                            </LogicAddButton>
                            <Popover
                              content={
                                <div>
                                  <TextArea
                                    value={config[elsepropName][i]['preventive']}
                                    rows={5}
                                    maxLength="150"
                                    onChange={(e) =>
                                      handleTextAreaChange(e, i, elsepropName, 'preventive')
                                    }
                                  />
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        elsepropName,
                                        'close',
                                        'preventive',
                                        `PMvisibleDT${elsepropName}`
                                      );
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Cancel')}{' '}
                                  </span>
                                  <span
                                    style={{
                                      color: '#1890ff',
                                      cursor: 'pointer',
                                      float: 'right'
                                    }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        elsepropName,
                                        'ok',
                                        `PMvisibleDT${elsepropName}`
                                      );
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Ok')}{' '}
                                  </span>
                                </div>
                              }
                              open={config[`PMvisibleDT${elsepropName}`]}
                              onOpenChange={(value) =>
                                handleVisibleChange(value, `PMvisibleDT${elsepropName}`)
                              }
                              title={this.translation(constants.preventivemeasure)}
                              trigger="click"
                            >
                              <TextParagraph>
                                {this.translation(constants.preventivemeasure)}
                              </TextParagraph>
                              {config[elsepropName][i]['preventive'] &&
                              config[elsepropName][i]['preventive'].length > 0 ? (
                                <span style={{ color: '#60c40e' }}>
                                  <CheckCircleOutlined />
                                </span>
                              ) : null}
                            </Popover>

                            {/* <Divider   style={{ background: "black" }} type="vertical" /> */}
                            <Popover
                              content={
                                <div>
                                  <TextArea
                                    value={config[elsepropName][i]['rootcause']}
                                    rows={5}
                                    maxLength="150"
                                    onChange={(e) =>
                                      handleTextAreaChange(e, i, elsepropName, 'rootcause')
                                    }
                                  />
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        elsepropName,
                                        'close',
                                        'rootcause',
                                        `rootCausevisibleDT${elsepropName}`
                                      );
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Cancel')}{' '}
                                  </span>
                                  <span
                                    style={{
                                      color: '#1890ff',
                                      cursor: 'pointer',
                                      float: 'right'
                                    }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        elsepropName,
                                        'ok',
                                        `rootCausevisibleDT${elsepropName}`
                                      );
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Ok')}{' '}
                                  </span>
                                </div>
                              }
                              open={config[`rootCausevisibleDT${elsepropName}`]}
                              onOpenChange={(value) =>
                                handleVisibleChange(value, `rootCausevisibleDT${elsepropName}`)
                              }
                              title={this.translation(constants.preventivemeasure)}
                              trigger="click"
                            >
                              <TextParagraph>
                                {this.translation(constants.rootcauseanalysis)}
                              </TextParagraph>
                              {config[elsepropName][i]['rootcause'] &&
                              config[elsepropName][i]['rootcause'].length > 0 ? (
                                <span style={{ color: '#60c40e' }}>
                                  <CheckCircleOutlined />
                                </span>
                              ) : null}
                            </Popover>
                            <Popover
                              content={
                                <div>
                                  <TextArea
                                    value={config[elsepropName][i]['recommandation']}
                                    rows={5}
                                    maxLength="150"
                                    onChange={(e) =>
                                      handleTextAreaChange(e, i, elsepropName, 'recommandation')
                                    }
                                  />
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        elsepropName,
                                        'close',
                                        'recommandation',
                                        `recomandationsvisibleDT${elsepropName}`
                                      );
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Cancel')}{' '}
                                  </span>
                                  <span
                                    style={{
                                      color: '#1890ff',
                                      cursor: 'pointer',
                                      float: 'right'
                                    }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        elsepropName,
                                        'ok',
                                        `recomandationsvisibleDT${elsepropName}`
                                      );
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Ok')}{' '}
                                  </span>
                                </div>
                              }
                              open={config[`recomandationsvisibleDT${elsepropName}`]}
                              onOpenChange={(value) =>
                                handleVisibleChange(value, `recomandationsvisibleDT${elsepropName}`)
                              }
                              title={this.translation(constants.recomandations)}
                              trigger="click"
                            >
                              <TextParagraph>
                                {this.translation(constants.recomandations)}
                              </TextParagraph>
                            </Popover>
                            {config[elsepropName][i]['recommandation'] &&
                            config[elsepropName][i]['recommandation'].length > 0 ? (
                              <span style={{ color: '#60c40e' }}>
                                <CheckCircleOutlined />
                              </span>
                            ) : null}
                          </Fragment>
                        )}
                      </ButtonBoxDiv>
                      {config[elsepropName].length - 1 === i && (
                        <ActionSelectionDiv>
                          <BlockedLabelBlack>
                            {this.translation(constants.action)}
                            <span style={{ color: 'red' }}>*</span>
                          </BlockedLabelBlack>
                          <ActionCheckbox
                            options={this.state.data}
                            value={config[elsepropName][i]['action']}
                            onChange={(checkedValues) => {
                              checkonChange(checkedValues, elsepropName, i);
                            }}
                          />
                          <Popover placement="top" title={'Delay'}>
                            <TimePicker
                              style={{ margin: '10px' }}
                              defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                              value={
                                config[elsepropName][i]['delay'] === undefined
                                  ? undefined
                                  : moment(config[elsepropName][i]['delay'][1], 'HH:mm:ss')
                              }
                              onChange={(e) => handleDelay(e, elsepropName, i)}
                              placeholder={this.translation(constants.selectDelay)}
                              size="small"
                              allowClear={false}
                            />
                          </Popover>
                          <Popover placement="top" title={'Frequency'}>
                            <TimePicker
                              disabled={onContinous === true ? false : true}
                              style={{ margin: '10px' }}
                              defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                              value={
                                config[elsepropName][i]['frequency'] === undefined
                                  ? undefined
                                  : moment(config[elsepropName][i]['frequency'][1], 'HH:mm:ss')
                              }
                              onChange={(e) => handleFrequency(e, elsepropName, i)}
                              placeholder={this.translation(constants.selectFrequency)}
                              size="small"
                              allowClear={false}
                            />
                          </Popover>
                          {config &&
                          config[elsepropName][i] &&
                          config[elsepropName][i].action.indexOf('RestAPI') > -1 ? (
                            <Input
                              style={{ width: '55%', marginTop: '2%' }}
                              placeholder={this.translation('Enter Url')}
                              name="value"
                              value={url}
                              onChange={(e) => handleurl(e, 'url')}
                            />
                          ) : null}
                        </ActionSelectionDiv>
                      )}
                      {i === config[elsepropName].length - 1 ? (
                        <div>
                          <Row>
                            <Col span={7}>
                              <BlockedLabelBlack>
                                {this.translation(constants.members)}
                              </BlockedLabelBlack>
                              <Select
                                placeholder={this.translation('RecipientList')}
                                mode="multiple"
                                value={mailRecipients}
                                style={{ width: '90%', display: 'inline-block' }}
                                onChange={(value) => handleRecipientList(value, 'mailRecipients')}
                              >
                                {recipientList &&
                                  Array.isArray(recipientList) &&
                                  recipientList.map((item, index) => {
                                    return (
                                      <Option key={index} value={item.Email}>
                                        {item.FirstName} {item.LastName}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Col>
                            <Col span={6}>
                              <BlockedLabelBlack>
                                {this.translation(constants.bodymessage)}
                              </BlockedLabelBlack>
                              <TextArea
                                size="default"
                                name="value"
                                rows={1}
                                value={rulebody}
                                placeholder={this.translation(constants.text)}
                                onChange={(e) => handleRulebody(e, 'rulebody')}
                              />
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                    </Boxdiv>
                  );
                });
              })
            : null}
          {/* -------------------- Else */}
          {conelse
            ? elseList.map((x, i) => {
                return (
                  <Boxdiv key={`else${i}`}>
                    {i === 0 ? <BlockedLabelBlack>else</BlockedLabelBlack> : null}
                    {i > 0 ? (
                      <ConditionSelect
                        name="operater"
                        size="default"
                        value={x['54765'] === '' ? undefined : x['54765']}
                        placeholder={this.translation(constants.selectCondition)}
                        onChange={(e) => handleConditionchange(e, i, 'elseList')}
                      >
                        <Option value="&&">{this.translation('AND')}</Option>
                        <Option value="||">{this.translation('OR')}</Option>
                      </ConditionSelect>
                    ) : null}

                    <ParameterSelect
                      // disabled={disabledParamTypeDevice}
                      name="parameterName"
                      placeholder={this.translation(constants.ParameterType)}
                      size="default"
                      value={x.parameterName === '' ? undefined : x.parameterName}
                      onChange={(value) => handleParameterSelect(value, i, 'elseList')}
                    >
                      {parameterChangeList &&
                        Array.isArray(parameterChangeList) &&
                        parameterChangeList.map((parameter, i) => {
                          return (
                            <Option key={`ifdeviceList${i}`} value={parameter.ParameterType}>
                              {parameter.ParameterType}
                            </Option>
                          );
                        })}
                    </ParameterSelect>
                    <DeviceSelect
                      placeholder={this.translation(constants.selectdevicetype)}
                      size="default"
                      value={x.deviceType === '' ? undefined : x.deviceType}
                      onChange={this.hierarchyDropdownChange}
                    >
                      {deviceTypeList &&
                        Array.isArray(deviceTypeList) &&
                        deviceTypeList.map((deviceType, i) => {
                          return (
                            <Option key={`ifdeviceTypeList${i}`} value={deviceType.AliasCode}>
                              {deviceType.Name}
                            </Option>
                          );
                        })}
                    </DeviceSelect>
                    <TagNameSelect
                      showSearch
                      name="tagName"
                      size="default"
                      placeholder={this.translation(constants.selectparameters)}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      value={x && x.tagName ? `${x.tagName}` : undefined}
                      onChange={(e) => handleTagSelect(e, i, 'elseList')}
                    >
                      {parametersList &&
                        Array.isArray(parametersList) &&
                        parametersList.map((param, j) => {
                          return (
                            <Option key={`inputTagname${i}${j}`} value={param.Code}>
                              {param.Name}
                            </Option>
                          );
                        })}
                    </TagNameSelect>
                    <OperaterSelect
                      name="operater"
                      size="default"
                      placeholder={this.translation(constants.selectoperator)}
                      value={x.operater === '' ? undefined : x.operater}
                      onChange={(e) => handleInputChange(e, i, 'select', 'elseList')}
                    >
                      {Operator.map((item, index) => {
                        return (
                          <>
                            {config.parameter === 'Operational' ? (
                              <Option key={index} value={item.value}>
                                {item.key}
                              </Option>
                            ) : null}
                          </>
                        );
                      })}
                      {config.parameter !== 'Operational' ? (
                        <Option value="==" title="EqualTo">
                          {`EqualTo (==)`}
                        </Option>
                      ) : null}
                    </OperaterSelect>
                    <ValueInput
                      size="default"
                      name="value"
                      maxLength={6}
                      placeholder={this.translation(constants.value)}
                      value={x.value}
                      onChange={(e) => handleInputChange(e, i, 'input', 'elseList')}
                    />
                    <ButtonBoxDiv>
                      {elseList.length !== 1 && (
                        <MarginRightButton
                          size="default"
                          onClick={() => handleRemoveClick(i, 'elseList')}
                          icon={<DeleteOutlined />}
                        >
                          <DeleteOutlined />
                        </MarginRightButton>
                      )}
                      {elseList.length - 1 === i && (
                        <Fragment>
                          <LogicAddButton
                            type={'primary'}
                            size="default"
                            onClick={() => handleAddClick('elseList', this.state.handleAddClick)}
                          >
                            {this.translation(constants.add)}
                          </LogicAddButton>
                          <Popover
                            content={
                              <div>
                                <TextArea
                                  value={elseList[i]['preventive']}
                                  rows={5}
                                  maxLength="150"
                                  onChange={(e) =>
                                    handleTextAreaChange(e, i, 'elseList', 'preventive')
                                  }
                                />
                                <span
                                  style={{ color: '#1890ff', cursor: 'pointer' }}
                                  onClick={() => {
                                    hanblePopoverOnclick(
                                      i,
                                      'elseList',
                                      'close',
                                      'preventive',
                                      `PMElsevisibleDT`
                                    );
                                  }}
                                >
                                  {this.translation('Cancel')}
                                </span>
                                <span
                                  style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                  onClick={() => {
                                    hanblePopoverOnclick(i, 'elseList', 'ok', `PMElsevisibleDT`);
                                  }}
                                >
                                  {this.translation('Ok')}
                                </span>
                              </div>
                            }
                            open={PMElsevisibleDT}
                            onOpenChange={(value) => handleVisibleChange(value, `PMElsevisibleDT`)}
                            title={this.translation(constants.preventivemeasure)}
                            trigger="click"
                          >
                            <TextParagraph>
                              {this.translation(constants.preventivemeasure)}
                            </TextParagraph>
                            {elseList[i]['preventive'] && elseList[i]['preventive'].length > 0 ? (
                              <span style={{ color: '#60c40e' }}>
                                <CheckCircleOutlined />
                              </span>
                            ) : null}
                          </Popover>
                          {/* <Divider   style={{ background: "black" }}type="vertical" /> */}
                          <Popover
                            content={
                              <div>
                                <TextArea
                                  value={elseList[i]['rootcause']}
                                  rows={4}
                                  maxLength="150"
                                  onChange={(e) =>
                                    handleTextAreaChange(e, i, 'elseList', 'rootcause')
                                  }
                                />
                                <span
                                  style={{ color: '#1890ff', cursor: 'pointer' }}
                                  onClick={() => {
                                    hanblePopoverOnclick(
                                      i,
                                      'elseList',
                                      'close',
                                      'rootcause',
                                      `rootcauseElsevisibleDT`
                                    );
                                  }}
                                >
                                  {' '}
                                  {this.translation('Cancel')}{' '}
                                </span>
                                <span
                                  style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                  onClick={() => {
                                    hanblePopoverOnclick(
                                      i,
                                      'elseList',
                                      'ok',
                                      `rootcauseElsevisibleDT`
                                    );
                                  }}
                                >
                                  {' '}
                                  {this.translation('Ok')}{' '}
                                </span>
                              </div>
                            }
                            open={rootcauseElsevisibleDT}
                            onOpenChange={(value) =>
                              handleVisibleChange(value, `rootcauseElsevisibleDT`)
                            }
                            title={this.translation(constants.rootcauseanalysis)}
                            trigger="click"
                          >
                            <TextParagraph>
                              {this.translation(constants.rootcauseanalysis)}
                            </TextParagraph>
                            {elseList[i]['rootcause'] && elseList[i]['rootcause'].length > 0 ? (
                              <span style={{ color: '#60c40e' }}>
                                <CheckCircleOutlined />
                              </span>
                            ) : null}
                          </Popover>
                          {/* <Divider  style={{ background: "black" }}type="vertical" /> */}
                          <Popover
                            content={
                              <div>
                                <TextArea
                                  value={elseList[i]['recommandation']}
                                  rows={4}
                                  maxLength="150"
                                  onChange={(e) =>
                                    handleTextAreaChange(e, i, 'elseList', 'recommandation')
                                  }
                                />
                                <span
                                  style={{ color: '#1890ff', cursor: 'pointer' }}
                                  onClick={() => {
                                    hanblePopoverOnclick(
                                      i,
                                      'elseList',
                                      'close',
                                      'recommandation',
                                      `recommandationElsevisibleDT`
                                    );
                                  }}
                                >
                                  {' '}
                                  {this.translation('Cancel')}{' '}
                                </span>
                                <span
                                  style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                  onClick={() => {
                                    hanblePopoverOnclick(
                                      i,
                                      'elseList',
                                      'ok',
                                      `recommandationElsevisibleDT`
                                    );
                                  }}
                                >
                                  {' '}
                                  {this.translation('Ok')}{' '}
                                </span>
                              </div>
                            }
                            open={recommandationElsevisibleDT}
                            onOpenChange={(value) =>
                              handleVisibleChange(value, `recommandationElsevisibleDT`)
                            }
                            title={this.translation(constants.recomandations)}
                            trigger="click"
                          >
                            <TextParagraph>
                              {this.translation(constants.recomandations)}
                            </TextParagraph>
                          </Popover>
                          {elseList[i]['recommandation'] &&
                          elseList[i]['recommandation'].length > 0 ? (
                            <span style={{ color: '#60c40e' }}>
                              <CheckCircleOutlined />
                            </span>
                          ) : null}
                        </Fragment>
                      )}
                    </ButtonBoxDiv>
                    {elseList.length - 1 === i && (
                      <ActionSelectionDiv>
                        <BlockedLabelBlack>
                          {this.translation(constants.action)}
                          <span style={{ color: 'red' }}>*</span>
                        </BlockedLabelBlack>
                        <ActionCheckbox
                          options={this.state.data}
                          value={config.elseList[i]['action'].map((covert) => {
                            return this.translation(covert);
                          })}
                          onChange={(checkedValues) => {
                            checkonChange(checkedValues, 'elseList', i);
                          }}
                        />
                        <Popover placement="top" title={'Delay'}>
                          <TimePicker
                            style={{ margin: '10px' }}
                            defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                            value={
                              config.elseList[i]['delay'] === undefined
                                ? undefined
                                : moment(config.elseList[i]['delay'][1], 'HH:mm:ss')
                            }
                            onChange={(e) => handleDelay(e, 'elseList', i)}
                            placeholder={this.translation(constants.selectDelay)}
                            size="small"
                            allowClear={false}
                          />
                        </Popover>
                        <Popover placement="top" title={'Frequency'}>
                          <TimePicker
                            disabled={onContinous === true ? false : true}
                            style={{ margin: '10px' }}
                            defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                            value={
                              config.elseList[i]['frequency'] === undefined
                                ? undefined
                                : moment(config.elseList[i]['frequency'][1], 'HH:mm:ss')
                            }
                            onChange={(e) => handleFrequency(e, 'elseList', i)}
                            placeholder={this.translation(constants.selectFrequency)}
                            size="small"
                            allowClear={false}
                          />
                        </Popover>
                        {config &&
                        config.elseList[i] &&
                        config.elseList[i].action.indexOf('RestAPI') > -1 ? (
                          <Input
                            style={{ width: '55%', marginTop: '2%' }}
                            placeholder="Enter Url"
                            name="value"
                            value={url}
                            onChange={(e) => handleurl(e, 'url')}
                          />
                        ) : null}
                      </ActionSelectionDiv>
                    )}
                    {i === elseList.length - 1 ? (
                      <div>
                        <Row>
                          <Col span={7}>
                            <BlockedLabelBlack>
                              {this.translation(constants.members)}
                            </BlockedLabelBlack>
                            <Select
                              placeholder={this.translation('RecipientList')}
                              value={mailRecipients}
                              mode="multiple"
                              style={{ width: '90%', display: 'inline-block' }}
                              onChange={(value) => handleRecipientList(value, 'mailRecipients')}
                            >
                              {recipientList &&
                                Array.isArray(recipientList) &&
                                recipientList.map((item, index) => {
                                  return (
                                    <Option key={index} value={item.Email}>
                                      {item.FirstName} {item.LastName}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Col>
                          <Col span={5}>
                            <BlockedLabelBlack>
                              {this.translation(constants.bodymessage)}
                            </BlockedLabelBlack>
                            <TextArea
                              size="default"
                              name="value"
                              rows={1}
                              value={rulebody}
                              placeholder={this.translation(constants.text)}
                              onChange={(e) => handleRulebody(e, 'rulebody')}
                            />
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                  </Boxdiv>
                );
              })
            : null}

          <BlankDiv></BlankDiv>
        </>
      </StyledAppdiv>
    );
  }
}
