import React, { Component } from 'react';
import { Input } from 'antd';

class InputComponent extends Component {
  render() {
    return (
      <Input
        maxLength={1500}
        // type={}
        {...{ ...this.props }}
        onChange={(e) =>
          this.props.onChange(typeof this.props.onChange === 'function' ? e : e.target.value)
        }
      />
    );
  }
}

export default InputComponent;
