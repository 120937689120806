import React from 'react';
import axios from 'axios';
import { Select, Form } from 'antd';

import localStorage from '../../../../utils/localStorage';
import { getTranslationCache } from '../../../../selectors/language';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { API_ROLE, API_SITE } from '../../../../commons/api';

const { Option } = Select;

class SiteRoleComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siteList: [],
      roleList: [],
      site: props.site,
      index: props.index,
      translationCache: props.translationCache || []
    };
  }

  componentDidMount() {
    this._getSiteList();
    this._getRolesList(this.props.site.SiteId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editObj !== this.props.editObj) {
      this.setState({
        site: this.props.site
      });
      this._getRolesList(this.props.site.SiteId);
    }
  }

  _getSiteList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${API_SITE.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ siteList: response.data });
      })
      .catch(function () {});
  };

  _getRolesList = () => {
    const accessToken = localStorage.get('accessToken');
    const SiteId = localStorage.get('currentSite');

    let orgHeaders = {
      method: 'GET',
      url: `${API_ROLE.GET}?SiteId=${SiteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ roleList: response.data });
      })
      .catch(function () {});
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { siteList, index, roleList } = this.state;
    let { site } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Form.Item label={this.translation('SiteName')}>
          <Select
            value={site.SiteId}
            openOnFocus
            style={{ width: 160 }}
            onChange={(e) => this.props.handleFieldChange(e, 'SiteId', index)}
            placeholder="Site"
          >
            {siteList &&
              Array.isArray(siteList) &&
              siteList.map((role, index) => {
                return (
                  <Option key={index} value={role.Id}>
                    {this.translation(role.Name)}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item label={this.translation('Role')}>
          <Select
            mode="multiple"
            value={site.RoleId}
            openOnFocus
            style={{ width: 200 }}
            onChange={(e) => this.props.handleFieldChange(e, 'RoleId', index)}
            placeholder={this.translation('Role')}
          >
            {roleList &&
              Array.isArray(roleList) &&
              roleList.map((role, index) => {
                return (
                  <Option key={index} value={role.Id}>
                    {role.Name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        {/* <MinusCircleOutlined
          style={{ margin: '3px',marginTop:"30px" }}
          className="dynamic-delete-button"
          type="minus-circle-o"
          onClick={() => this.props.remove(index, site)}
        /> */}
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(SiteRoleComponent);
