import React from 'react';
import { withRouter } from 'react-router';
import { Menu } from 'antd';
import EquipmentMasterRoute from '../../../routes/equipmentMasterRoute';

import styles from '../../../commons/styles';
const { StyledWrapper, StyledWrapperLeft, StyledWrapperRight } = styles.layout;

class EquipmentMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      tabPosition: 'left'
    };
  }
  render() {
    return (
      <StyledWrapper style={{ minHeight: window.innerHeight - 140 }}>
        <StyledWrapperLeft>
          <Menu onClick={this.handleClick} mode="inline">
            {/* <Menu.Item key='parts' onClick={() => { history.push('/rubus/EquipmentMaster/Parts') }}> <Icon type='database' /> Parts Master </Menu.Item>
            <Menu.Item key='supplier' onClick={() => { history.push('/rubus/EquipmentMaster/SupplierDetails') }}> <Icon type='database' /> Supplier Details </Menu.Item>
            <Menu.Item key='status' onClick={() => { history.push('/rubus/EquipmentMaster/StatusMaster') }}> <Icon type='database' /> Status Master </Menu.Item>
            <Menu.Item key='specs' onClick={() => { history.push('/rubus/EquipmentMaster/Specifications') }}> <Icon type='database' /> Specifications </Menu.Item>
            <Menu.Item key='upload' onClick={() => { history.push('/rubus/EquipmentMaster/FileUpload') }}> <Icon type='database' /> Attachments Upload </Menu.Item> */}
          </Menu>
        </StyledWrapperLeft>
        <StyledWrapperRight style={{ background: 'white', margin: '1px 15px 13px 15px' }}>
          <EquipmentMasterRoute />
        </StyledWrapperRight>
      </StyledWrapper>
    );
  }
}
export default withRouter(EquipmentMaster);
