import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col, Table, Tag } from 'antd';
import { createStructuredSelector } from 'reselect';

import '../../CSS/Table.css';
import ErrorComponent from '../../Utils/Error';
import NoRecordComponent from '../../Utils/NoRecords';
import Line from '../ArrayOfObjects/Line';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { LineTabCounMock } from '../../../Mock/MockJson';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class LineTableCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      loading: false,
      dataSource: [],
      translationCache: props.translationCache || [],
      language: props.language || 'English',
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      counter: '0',
      table: [],
      line: [],
      currentDateData: [],
      currentDate: moment(),
      endDate: moment(),
      graphprops:
        {
          ...props.graphprops,
          colors: [
            'orange',
            'tomato',
            'steelblue',
            'green',
            'pink',
            'voilet',
            'yellow',
            'red',
            'blue',
            'gray'
          ],
          linetype: 'line',
          // startDate: moment().format('YYYY-MM-DD 00:00:00'),
          // endDate: moment().format('YYYY-MM-DD 23:59:59'),

          startDate: moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          // currentDateData: [],
          disableNext: false,
          translationCache: props.translationCache || [],
          language: props.language || 'English'
        } || {}
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }
    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }
  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.refereshData !== this.props.refereshData
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }
  getColor = (status) => {
    if (status === 'Low') {
      return 'red';
    } else if (status === 'High') {
      return 'green';
    } else {
      return 'orange';
    }
  };
  getData = async () => {
    this.requestCheck = false;
    let json = [];
    let { graphprops } = this.props;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = LineTabCounMock;
      this.requestCheck = true;
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection({
          graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          selectionDateLive: this.props.selectionDateLive,
          currentTimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
        let graphpropsResult = {
          ...graphprops,
          currentDateData: json && json.query1 ? json.query1 : ''
        };
        this.setState({
          ...graphprops,
          loading: false,
          currentDateData: json && json.query1 ? json.query1 : '',
          counter:
            json && json.query && json.query[0] && json.query[0].Value ? json.query[0].Value : '',
          graphprops: graphpropsResult,
          dataSource:
            json &&
            json.query2 &&
            json.query2.map((jsonData) => {
              return {
                ...jsonData,
                intervalAlias: moment.utc(jsonData.DateTime).local().format('YYYY-MM-DD HH:mm')
              };
            })
        });
      } else {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      }
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  refereshDataReq = () => {
    this.getData(this.props, this.props.selectedThemeing);
  };

  render() {
    let { dataSource, errorInAPI, noDataInDB, refreshDateTime, counter } = this.state;
    const columns = [
      {
        title: this.translation('Date'),
        dataIndex: 'intervalAlias',
        key: 'intervalAlias'
      },
      {
        title: this.translation('Value'),
        dataIndex: 'Value',
        key: 'Value'
      },
      {
        title: this.translation('Feedback'),
        dataIndex: 'Feedback',
        key: 'Feedback'
      }
    ];

    let { graphprops } = this.props;
    return (
      <Fragment>
        <div style={{ display: 'inline', color: 'white' }}>
          <label style={{ marginLeft: '9%', fontSize: '23px' }}>
            {this.translation('Moisture Content :')}{' '}
          </label>
          <div
            style={{
              marginLeft: '6px',
              color: 'white',
              display: 'inline',
              fontSize: '27px'
            }}
          >
            {counter}
          </div>
        </div>
        {graphprops && graphprops.checked === true ? (
          <Tag color="blue" style={{ float: '', position: 'relative', zIndex: '2' }}>
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        <span>
          <div style={{ padding: '10px' }}>
            <Row>
              <Col span={24} style={{ height: '300px' }}>
                {noDataInDB === true || errorInAPI === true ? (
                  noDataInDB === true ? (
                    <NoRecordComponent />
                  ) : (
                    <ErrorComponent />
                  )
                ) : (
                  <Line
                    graphprops={this.state.graphprops}
                    deviceId={this.state.deviceId}
                    currentDateData={this.state.currentDateData}
                    initialRender={this.state.initialRender}
                    refereshDataReq={this.refereshDataReq}
                    tableDataSource={dataSource}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div style={{ padding: '20px' }}>
                  <Table
                    className="barTable"
                    dataSource={this.state.dataSource}
                    columns={columns}
                    pagination={false}
                    size={'small'}
                    style={{ padding: '0px 10px', marginTop: '12px' }}
                    scroll={{ y: 240 }}
                    bordered
                  />
                </div>
              </Col>
            </Row>
          </div>
        </span>
      </Fragment>
    );
  }
  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      color: ['tomato', 'steelblue', 'orange', 'yellow', 'green'],
      legend: {
        textStyle: { color: theme === 'lightTheme' ? 'black' : 'white' },
        data: [
          {
            name: 'test1',
            icon: 'roundRect',
            textStyle: {
              fontSize: 15
            }
          }
        ]
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            backgroundColor: 'black',
            title: 'download',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      xAxis: [
        {
          type: 'category',
          data: [],
          axisLine: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisTick: {
            show: false,
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisLabel: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisTick: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisLabel: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        }
      ],
      series: []
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(LineTableCounter);
