import axios from 'axios';
import { message } from 'antd';
import localStorage from '../../utils/localStorage';
import { API_DEVICE, API_HIERARCHYMODULE, DOWN_TIME, IPAddress, API_AUTH } from '../../commons/api';

export async function getProcessArea() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;

  const getProcessArea = {
    method: 'POST',
    url: `${API_HIERARCHYMODULE.Dropdown}?SiteId=${siteId} `,
    headers: {
      Authorization: Authorization
    },
    data: {
      Type: 'ProcessArea',
      ParentId: null
    }
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getShiftList() {
  const token = localStorage.get('accessToken');
  let siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;

  const getProcessArea = {
    method: 'GET',
    // url: `${LOGBOOK.DropDown}?SiteId=${siteId}`,
    url: `${DOWN_TIME.Shift}?SiteId=${siteId}&Name=Downtime`,
    headers: {
      Authorization: Authorization
    }
    // data: { tableName: 'master."Master_Shifts"', columnName: 'Shift', conditionList: [] }
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getFailureReasonList() {
  const token = localStorage.get('accessToken');
  let siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;

  const getProcessArea = {
    method: 'GET',
    // url: `${LOGBOOK.DropDown}?SiteId=${siteId}`,
    url: `${DOWN_TIME.FailureList}?SiteId=${siteId}&Name=Downtime`,
    headers: {
      Authorization: Authorization
    }
    // data: { tableName: 'master."Master_Shifts"', columnName: 'Shift', conditionList: [] }
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getWorkCenter(id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;

  const getWorkCenter = {
    method: 'POST',
    url: `${API_HIERARCHYMODULE.Dropdown}?SiteId=${siteId} `,
    headers: {
      Authorization: Authorization
    },
    data: {
      Type: 'WorkCenter',
      ParentId: id
    }
  };
  return axios(getWorkCenter)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getEquipment(id) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;

  const getEquipment = {
    method: 'POST',
    url: `${API_HIERARCHYMODULE.Dropdown}?SiteId=${siteId} `,
    headers: {
      Authorization: Authorization
    },
    data: {
      Type: 'Device',
      ParentId: id
    }
  };
  return axios(getEquipment)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getAreaType() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getAreaType = {
    method: 'GET',
    url: `${DOWN_TIME.Area}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
    // data: {
    //   Type: 'FailureArea',
    //   Module: 'DownTime'
    // }
  };
  return axios(getAreaType)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getTypeList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getAreaType = {
    method: 'GET',
    url: `${DOWN_TIME.Type}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
    // data: {
    //   Type: 'FailureArea',
    //   Module: 'DownTime'
    // }
  };
  return axios(getAreaType)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function getDescription(failureArea) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getDescription = {
    method: 'POST',
    url: `${DOWN_TIME.FailureDetails}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      Type: 'FailureReasons',
      FailureArea: failureArea,
      Module: 'DownTime'
    }
  };
  return axios(getDescription)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function saveDownTimelogs(param) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const saveDownTimelogs = {
    method: 'POST',
    url: `${DOWN_TIME.CREATE}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { ...param, SiteId: siteId, Status: 'Close' }
  };
  return axios(saveDownTimelogs)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getDownTimeRecords(Source) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getDownTimeRecords = {
    method: 'GET',
    url: `${DOWN_TIME.GET}?SiteId=${siteId}&Source=${Source}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDownTimeRecords)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
export async function editDownTimelogs(param) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const editDownTimelogs = {
    method: 'POST',
    url: `${DOWN_TIME.CREATE}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { ...param, SiteId: siteId, Status: 'Close' }
  };
  return axios(editDownTimelogs)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getShift() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getShift = {
    method: 'GET',
    url: `${DOWN_TIME.GetShiftMaster}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getShift)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getSubEquipments(id) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getSubequipment = {
    method: 'GET',
    url: `${IPAddress.LOCAL}${DOWN_TIME.GetSubEquipment}/${id}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getSubequipment)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function submitDowntimeAPI(data) {
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getAreaType = {
    method: 'POST',
    url: `${DOWN_TIME.Submit}`,
    headers: {
      Authorization: Authorization
    },
    data
  };
  return axios(getAreaType)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getDepartmentDetailsAPI(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getAreaType = {
    method: 'POST',
    url: `${DOWN_TIME.FailureDetails}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: payload
  };
  return axios(getAreaType)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getEquipmentList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;

  const getEquipmentListPayload = {
    method: 'GET',
    url: `${API_DEVICE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getEquipmentListPayload)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export async function getUserHeirarchyData() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const deciceListApi = {
    method: 'GET',
    url: `${API_AUTH.ME}?SiteId=${siteId}&menuType=Web`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(deciceListApi)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
