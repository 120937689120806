/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from 'react';
import { Form, Button, Select, Popconfirm, Collapse, Tooltip } from 'antd';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  getToolsList,
  getLaborList,
  getMaterialsList,
  checkTask,
  getItemsList,
  getSkillTypeList
} from '../../../Utils/FormCalls';
import translation from '../../../Utils/translation';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import themeSettings from '../../../Utils/themeSettings.json';
import { getTranslationCache } from '../../../../../selectors/language';
import { constant } from '../../../Utils/constants';
import { StyledCollapse, StyledButton } from '../../../CSS/style';
import InputComponent from '../../../Widgets/InputComponent';
import InputNumberComponent from '../../../Widgets/InputNumberComponent';

const { Option } = Select;
const { Panel } = Collapse;

//@ATT:this was created to make nested dynamic elements! This is hard!

const ToolsformFunction = (props) => {
  const [toolsList, setTool] = useState([]);
  const [translationCache, settranslationCache] = useState(props.translationCache || {});
  useEffect(() => {
    (async () => {
      try {
        let response = await getToolsList();
        setTool(response.data);
      } catch (e) {
        return {};
      }
    })();
  }, []);
  return (
    <Form.List name={[props.fieldKey, 'Tools']}>
      {(Tools, { add, remove }) => {
        return (
          <Fragment>
            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <StyledButton
                  theme={themeSettings}
                  type="dashed"
                  style={{ background: '#29415a', color: '#fff' }}
                  onClick={() => {
                    // add();
                    props.onAdd(props.fieldKey, 'Tools');
                  }}
                >
                  {' '}
                  {translation(translationCache, constant.addTools)}{' '}
                </StyledButton>
              </div>
            </Form.Item>
            {Tools && Array.isArray(Tools) && Tools.length > 0 ? (
              <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                {Tools.map((tool, index2) => (
                  <Panel
                    key={index2}
                    header={`${translation(translationCache, constant.tools)} ${index2 + 1}`}
                    extra={
                      <Popconfirm
                        title={translation(translationCache, constant.deleteTool)}
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        // onConfirm={() => remove(tool.name)}
                        onConfirm={() => props.onRemove(props.fieldKey, index2, 'Tools')}
                        okText={translation(translationCache, constant.yes)}
                        cancelText={translation(translationCache, constant.no)}
                      >
                        <Tooltip title={translation(translationCache, 'Delete')} placement="bottom">
                          <DeleteOutlined />
                        </Tooltip>
                      </Popconfirm>
                    }
                  >
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          label={translation(translationCache, constant.tool)}
                          {...tool}
                          name={[tool.name, 'ToolId']}
                          fieldKey={[tool.fieldKey, 'ToolId']}
                          key={index2}
                          rules={[{ required: true, message: 'tool Missing' }]}
                        >
                          <Select
                            style={{ width: '90%' }}
                            onChange={(value) =>
                              props.onToolChange(
                                value,
                                props.fieldKey,
                                tool.key,
                                'Tools',
                                toolsList.filter((tool) => tool.Id === value)
                              )
                            }
                            placeholder={translation(translationCache, 'Select Tool')}
                          >
                            {toolsList.map((tool, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={tool.Id}
                                  disabled={
                                    props &&
                                    props.formObject &&
                                    props.formObject.TaskData &&
                                    props.formObject.TaskData[props.fieldKey] &&
                                    props.formObject.TaskData[props.fieldKey].Tools &&
                                    props.formObject.TaskData[props.fieldKey].Tools.filter(
                                      (item) => item && item.ToolId === tool.Id
                                    ).length > 0
                                      ? true
                                      : false
                                  }
                                >
                                  {' '}
                                  {tool.Tool}{' '}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={translation(translationCache, constant.description)}
                          {...tool}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          name={[tool.name, 'Description']}
                          fieldKey={[tool.fieldKey, 'Description']}
                          key={index2}
                          rules={[{ required: false, message: 'Value Missing' }]}
                        >
                          <InputComponent
                            placeholder={translation(
                              translationCache,
                              'AutoPopulate Based on Selected Tool'
                            )}
                            style={{ width: '90%' }}
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          // label={translation(translationCache, constant.availableQuantity)}
                          // disabled={true}
                          // {...tool}
                          // style={{ flex: '50%' }}
                          // labelCol={{ flex: '130px' }}
                          name={[tool.name, 'AvailableQuantity']}
                          // fieldKey={[tool.fieldKey, 'AvailableQuantity']}
                          // key={index2}
                          // rules={[{ required: false, message: 'Value Missing' }]}
                        >
                          {/* <InputComponent
                            disabled={true}
                            placeholder={translation(translationCache, 'Total Quantity')}
                            style={{ width: '90%' }}
                          /> */}
                        </Form.Item>
                        <Form.Item
                          label={translation(translationCache, constant.Quantity)}
                          validateTrigger={['onChange', 'onBlur']}
                          {...tool}
                          name={[tool.name, 'Quantity']}
                          fieldKey={[tool.fieldKey, 'Quantity']}
                          key={index2}
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          rules={[
                            { required: false, message: 'Please Input Numbers Only' },

                            {
                              validator: (_, value) =>
                                checkTask(
                                  _,
                                  value,
                                  'Tools',
                                  tool.key,
                                  props.formObject.TaskData[props.fieldKey],
                                  { validateDecimal: true }
                                )
                            }
                          ]}
                        >
                          <InputNumberComponent
                            // min={0}
                            // max={1000}
                            disabled={
                              props &&
                              props.formObject &&
                              props.formObject.TaskData &&
                              props.formObject.TaskData[props.fieldKey] &&
                              props.formObject.TaskData[props.fieldKey].Tools &&
                              props.formObject.TaskData[props.fieldKey].Tools[index2] === null
                                ? true
                                : false
                            }
                            placeholder={translation(translationCache, 'Quantity')}
                            style={{ width: '90%' }}
                            addonAfter={
                              <Tooltip
                                style={{ marginRight: '20px', flex: '50%' }}
                                labelCol={{ flex: '130px' }}
                                title={translation(
                                  translationCache,
                                  'AvailableQuantity  -  ' +
                                    (props &&
                                    props.formObject &&
                                    props.formObject.TaskData &&
                                    props.formObject.TaskData[props.fieldKey] &&
                                    props.formObject.TaskData[props.fieldKey].Tools &&
                                    props.formObject.TaskData[props.fieldKey].Tools[index2] &&
                                    props.formObject.TaskData[props.fieldKey].Tools[index2]
                                      .AvailableQuantity
                                      ? props.formObject.TaskData[props.fieldKey].Tools[index2]
                                          .AvailableQuantity
                                      : 'Not Available')
                                )}
                              >
                                <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                              </Tooltip>
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          {...tool}
                        ></Form.Item>
                      </div>
                    </>
                  </Panel>
                ))}
              </StyledCollapse>
            ) : null}
          </Fragment>
        );
      }}
    </Form.List>
  );
};
const LaborFormFunction = (props) => {
  const [laborsList, setLabor] = useState([]);
  const [SkilTypeList, setSkill] = useState([]);
  const [translationCache, settranslationCache] = useState(props.translationCache || {});
  useEffect(() => {
    (async () => {
      try {
        let response = await getLaborList();
        setLabor(response.data);
      } catch (e) {
        return {};
      }
      try {
        let SkilTypeList = await getSkillTypeList();
        setSkill(SkilTypeList);
      } catch (e) {
        return {};
      }
    })();
  }, []);
  return (
    <div>
      <Form.List name={[props.fieldKey, 'Labor']}>
        {(Labor) => {
          return (
            <Fragment>
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <StyledButton
                    theme={themeSettings}
                    type="dashed"
                    style={{ background: '#29415a', color: '#fff' }}
                    onClick={() => {
                      props.onAdd(props.fieldKey, 'Labor');
                    }}
                  >
                    {translation(translationCache, constant.addLabor)}{' '}
                  </StyledButton>{' '}
                </div>
              </Form.Item>
              {Labor && Array.isArray(Labor) && Labor.length > 0 ? (
                <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                  {Labor.map((labor, index2) => (
                    <Panel
                      key={index2}
                      header={`${translation(translationCache, constant.labor)} ${index2 + 1}`}
                      extra={
                        <Popconfirm
                          title={translation(translation, constant.deleteLabor)}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          // onConfirm={() => remove(labor.name)}
                          onConfirm={() => props.onRemove(props.fieldKey, index2, 'Labor')}
                          okText={translation(translationCache, constant.yes)}
                          cancelText={translation(translationCache, constant.no)}
                        >
                          <Tooltip
                            title={translation(translationCache, 'Delete')}
                            placement="bottom"
                          >
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>
                      }
                    >
                      <>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          <Form.Item
                            style={{ flex: '50%' }}
                            labelCol={{ flex: '130px' }}
                            label={translation(translationCache, constant.labor)}
                            {...labor}
                            name={[labor.name, 'LaborId']}
                            fieldKey={[labor.fieldKey, 'LaborId']}
                            key={index2}
                            rules={[{ required: true, message: 'Labor required' }]}
                          >
                            <Select
                              style={{ width: '90%' }}
                              onChange={(value) =>
                                props.onLaborChange(
                                  value,
                                  props.fieldKey,
                                  labor.key,
                                  'Labor',
                                  laborsList.filter((laborItem) => laborItem.Id === value)
                                )
                              }
                              placeholder={translation(translationCache, 'Select Manpower')}
                            >
                              {laborsList &&
                                Array.isArray(laborsList) &&
                                laborsList.map((labor, index) => {
                                  return (
                                    <Option
                                      key={index}
                                      value={labor.Id}
                                      disabled={
                                        props &&
                                        props.formObject &&
                                        props.formObject.TaskData &&
                                        props.formObject.TaskData[props.fieldKey] &&
                                        props.formObject.TaskData[props.fieldKey]['Labor'] &&
                                        props.formObject.TaskData[props.fieldKey]['Labor'].filter(
                                          (item) => item && item.LaborId === labor.Id
                                        ).length > 0
                                          ? true
                                          : false
                                      }
                                    >
                                      {labor.FirstName}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            style={{ flex: '50%', color: 'black' }}
                            labelCol={{ flex: '130px' }}
                            label={translation(translationCache, constant.Skill)}
                            {...labor}
                            name={[labor.name, 'LaborId']}
                            fieldKey={[labor.fieldKey, 'LaborId']}
                            key={index2}
                            rules={[{ required: false, message: 'SkillType Missing' }]}
                          >
                            <Select
                              style={{
                                width: '90%'
                                // cursor: 'not-allowed',
                                // opacity: '0.8'
                              }}
                              placeholder={translation(
                                translationCache,
                                'Autopopulated based on Manpower'
                              )}
                              disabled
                            >
                              {SkilTypeList &&
                                Array.isArray(SkilTypeList) &&
                                SkilTypeList.map((skill, index) => {
                                  return (
                                    <Option key={index} value={skill.Id}>
                                      {' '}
                                      {skill.Type}{' '}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                          {/* <Form.Item
                                                        style={{ flex: '50%' }}
                                                        labelCol={{ flex: '130px' }}
                                                        label={translation(translationCache, constant.description)}
                                                        {...labor}
                                                        name={[labor.name, 'Description']}
                                                        fieldKey={[labor.fieldKey, 'Description']}
                                                        key={index2}
                                                        rules={[{ required: false, message: 'Value Missing' }]}
                                                    >
                                                        <InputComponent
                                                            placeholder={translation(translationCache, 'Description')}
                                                            style={{ width: '90%' }}
                                                        />
                                                    </Form.Item> */}
                          <Form.Item
                            // style={{ flex: '50%' }}
                            // labelCol={{ flex: '130px' }}
                            // label={translation(translationCache, constant.availableQuantity)}
                            // disabled={true}
                            // {...labor}
                            name={[labor.name, 'AvailableQuantity']}
                            // fieldKey={[labor.fieldKey, 'AvailableQuantity']}
                            // key={index2}
                            // rules={[{ required: false, message: 'Value Missing' }]}
                          >
                            {/* <InputComponent
                              disabled={true}
                              placeholder={translation(translationCache, 'AvailableQuantity')}
                              style={{ width: '90%' }}
                            /> */}
                          </Form.Item>

                          <Form.Item
                            style={{ flex: '50%' }}
                            labelCol={{ flex: '130px' }}
                            label={translation(translationCache, constant.Quantity)}
                            validateTrigger={['onChange', 'onBlur']}
                            {...labor}
                            name={[labor.name, 'Quantity']}
                            fieldKey={[labor.fieldKey, 'Quantity']}
                            key={index2}
                            rules={[
                              { required: false, message: 'Please Input Numbers Only' },
                              {
                                validator: (_, value) =>
                                  checkTask(
                                    _,
                                    value,
                                    'Labor',
                                    labor.key,
                                    props.formObject.TaskData[props.fieldKey],
                                    { validateDecimal: true }
                                  )
                              }
                            ]}
                          >
                            <InputNumberComponent
                              disabled={
                                props &&
                                props.formObject &&
                                props.formObject.TaskData &&
                                props.formObject.TaskData[props.fieldKey] &&
                                props.formObject.TaskData[props.fieldKey].Labor &&
                                props.formObject.TaskData[props.fieldKey].Labor[index2] === null
                                  ? true
                                  : false
                              }
                              placeholder={translation(translationCache, 'Quantity')}
                              style={{ width: '90%' }}
                              addonAfter={
                                <Tooltip
                                  style={{ marginRight: '20px', flex: '50%' }}
                                  labelCol={{ flex: '130px' }}
                                  title={translation(
                                    translationCache,
                                    'AvailableQuantity  -  ' +
                                      (props &&
                                      props.formObject &&
                                      props.formObject.TaskData &&
                                      props.formObject.TaskData[props.fieldKey] &&
                                      props.formObject.TaskData[props.fieldKey].Labor &&
                                      props.formObject.TaskData[props.fieldKey].Labor[index2] &&
                                      props.formObject.TaskData[props.fieldKey].Labor[index2]
                                        .AvailableQuantity
                                        ? props.formObject.TaskData[props.fieldKey].Labor[index2]
                                            .AvailableQuantity
                                        : 'Not Available')
                                  )}
                                >
                                  <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                                </Tooltip>
                              }
                            />
                          </Form.Item>

                          <Form.Item style={{ width: '100%', flex: '50%' }}>
                            <InputComponent style={{ display: 'none' }} />
                          </Form.Item>
                        </div>
                      </>
                    </Panel>
                  ))}
                </StyledCollapse>
              ) : null}
            </Fragment>
          );
        }}
      </Form.List>
    </div>
  );
};
const MaterialFormFunction = (props) => {
  const [materialsList, setTool] = useState([]);
  const [translationCache, settranslationCache] = useState(props.translationCache || {});
  useEffect(() => {
    (async () => {
      try {
        let response = await getMaterialsList();
        setTool(response.data);
      } catch (e) {
        return {};
      }
    })();
  }, []);
  return (
    <div>
      <Form.List name={[props.fieldKey, 'Materials']}>
        {(Material) => {
          return (
            <Fragment>
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <StyledButton
                    theme={themeSettings}
                    type="dashed"
                    style={{ background: '#29415a', color: '#fff' }}
                    onClick={() => {
                      props.onAdd(props.fieldKey, 'Materials');
                    }}
                  >
                    {translation(translationCache, constant.addMaterial)}{' '}
                  </StyledButton>
                </div>
              </Form.Item>
              {Material && Array.isArray(Material) && Material.length > 0 ? (
                <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                  {Material.map((material, index2) => (
                    <Panel
                      key={index2}
                      header={`${translation(translationCache, constant.material)} ${index2 + 1}`}
                      extra={
                        <Popconfirm
                          title={translation(translationCache, constant.deleteMaterial)}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          // onConfirm={() => remove(material.name)}
                          onConfirm={() => props.onRemove(props.fieldKey, index2, 'Materials')}
                          okText={translation(translationCache, constant.yes)}
                          cancelText={translation(translationCache, constant.no)}
                        >
                          <Tooltip
                            title={translation(translationCache, 'Delete')}
                            placement="bottom"
                          >
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>
                      }
                    >
                      <>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          <Form.Item
                            style={{ flex: '50%' }}
                            labelCol={{ flex: '130px' }}
                            label={translation(translationCache, constant.material)}
                            {...material}
                            name={[material.name, 'MaterialId']}
                            fieldKey={[material.fieldKey, 'MaterialId']}
                            key={index2}
                            rules={[{ required: true, message: 'Material is required' }]}
                          >
                            <Select
                              style={{ width: '90%' }}
                              onChange={(value) =>
                                props.onMaterialChange(
                                  value,
                                  props.fieldKey,
                                  material.key,
                                  'Materials',
                                  materialsList.filter((Item) => Item.Id === value)
                                )
                              }
                              placeholder={translation(translationCache, 'Select Material')}
                            >
                              {materialsList.map((material, index) => {
                                return (
                                  <Option
                                    key={index}
                                    value={material.Id}
                                    disabled={
                                      props &&
                                      props.formObject &&
                                      props.formObject.TaskData &&
                                      props.formObject.TaskData[props.fieldKey] &&
                                      props.formObject.TaskData[props.fieldKey]['Materials'] &&
                                      props.formObject.TaskData[props.fieldKey]['Materials'].filter(
                                        (item) => item && item.MaterialId === material.Id
                                      ).length > 0
                                        ? true
                                        : false
                                    }
                                  >
                                    {' '}
                                    {material.Material}{' '}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            style={{ flex: '50%' }}
                            labelCol={{ flex: '130px' }}
                            label={translation(translationCache, constant.description)}
                            {...material}
                            name={[material.name, 'Description']}
                            fieldKey={[material.fieldKey, 'Description']}
                            key={index2}
                            rules={[{ required: false, message: 'Value Missing' }]}
                          >
                            <InputComponent
                              placeholder={translation(
                                translationCache,
                                'AutoPopulate Based on Selected Material'
                              )}
                              style={{ width: '90%' }}
                              disabled
                            />
                          </Form.Item>
                          <Form.Item
                            // style={{ flex: '50%' }}
                            // labelCol={{ flex: '130px' }}
                            // label={translation(translationCache, constant.availableQuantity)}
                            // disabled={true}
                            // {...material}
                            name={[material.name, 'AvailableQuantity']}
                            // fieldKey={[material.fieldKey, 'AvailableQuantity']}
                            // key={index2}
                            // rules={[{ required: false, message: 'Value Missing' }]}
                          >
                            {/* <InputComponent
                              disabled={true}
                              placeholder={translation(translationCache, 'AvailableQuantity')}
                              style={{ width: '90%' }}
                            /> */}
                          </Form.Item>
                          <Form.Item
                            style={{ flex: '50%' }}
                            labelCol={{ flex: '130px' }}
                            label={translation(translationCache, constant.Quantity)}
                            validateTrigger={['onChange', 'onBlur']}
                            {...material}
                            name={[material.name, 'Quantity']}
                            fieldKey={[material.fieldKey, 'Quantity']}
                            key={index2}
                            rules={[
                              { required: false, message: 'Please Input Numbers Only' },
                              {
                                validator: (_, value) =>
                                  checkTask(
                                    _,
                                    value,
                                    'Materials',
                                    material.key,
                                    props.formObject.TaskData[props.fieldKey],
                                    { validateDecimal: true }
                                  )
                              }
                            ]}
                          >
                            <InputNumberComponent
                              disabled={
                                props &&
                                props.formObject &&
                                props.formObject.TaskData &&
                                props.formObject.TaskData[props.fieldKey] &&
                                props.formObject.TaskData[props.fieldKey].Materials &&
                                props.formObject.TaskData[props.fieldKey].Materials[index2] === null
                                  ? true
                                  : false
                              }
                              placeholder={translation(translationCache, 'Quantity')}
                              style={{ width: '90%' }}
                              addonAfter={
                                <Tooltip
                                  style={{ marginRight: '20px', flex: '50%' }}
                                  labelCol={{ flex: '130px' }}
                                  title={translation(
                                    translationCache,
                                    'AvailableQuantity  -  ' +
                                      (props &&
                                      props.formObject &&
                                      props.formObject.TaskData &&
                                      props.formObject.TaskData[props.fieldKey] &&
                                      props.formObject.TaskData[props.fieldKey].Materials &&
                                      props.formObject.TaskData[props.fieldKey].Materials[index2] &&
                                      props.formObject.TaskData[props.fieldKey].Materials[index2]
                                        .AvailableQuantity
                                        ? props.formObject.TaskData[props.fieldKey].Materials[
                                            index2
                                          ].AvailableQuantity
                                        : 'Not Available')
                                  )}
                                >
                                  <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                                </Tooltip>
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            style={{ flex: '50%' }}
                            labelCol={{ flex: '130px' }}
                          ></Form.Item>
                        </div>
                      </>
                    </Panel>
                  ))}
                </StyledCollapse>
              ) : null}
            </Fragment>
          );
        }}
      </Form.List>
    </div>
  );
};
const ItemFormFunction = (props) => {
  const [itemsList, setItem] = useState([]);
  const [translationCache, settranslationCache] = useState(props.translationCache || {});
  useEffect(() => {
    (async () => {
      try {
        let response = await getItemsList();
        setItem(response.data);
      } catch (e) {
        return {};
      }
    })();
  }, []);
  return (
    <Form.List name={[props.fieldKey, 'Items']}>
      {(Items, { add, remove }) => {
        return (
          <Fragment>
            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <StyledButton
                  theme={themeSettings}
                  type="dashed"
                  style={{ background: '#29415a', color: '#fff' }}
                  onClick={() => {
                    props.onAdd(props.fieldKey, 'Items');
                  }}
                >
                  {translation(translationCache, constant.addItem)}{' '}
                </StyledButton>
              </div>
            </Form.Item>
            {Items && Array.isArray(Items) && Items.length > 0 ? (
              <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                {Items.map((item, index2) => (
                  <Panel
                    key={index2}
                    header={`${translation(translationCache, constant.item)} ${index2 + 1}`}
                    extra={
                      <Popconfirm
                        title={translation(translationCache, constant.deleteItem)}
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        // onConfirm={() => remove(item.name)}
                        onConfirm={() => props.onRemove(props.fieldKey, index2, 'Items')}
                        okText={translation(translationCache, constant.yes)}
                        cancelText={translation(translationCache, constant.no)}
                      >
                        <Tooltip title={translation(translationCache, 'Delete')} placement="bottom">
                          <DeleteOutlined />
                        </Tooltip>
                      </Popconfirm>
                    }
                  >
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          label={translation(translationCache, constant.item)}
                          {...item}
                          name={[item.name, 'ItemId']}
                          fieldKey={[item.fieldKey, 'ItemId']}
                          key={index2}
                          rules={[{ required: true, message: 'item Missing' }]}
                        >
                          <Select
                            style={{ width: '90%' }}
                            onChange={(value) =>
                              props.onItemChange(
                                value,
                                props.fieldKey,
                                item.key,
                                'Items',
                                itemsList.filter((tool) => tool.Id === value)
                              )
                            }
                            placeholder={translation(translationCache, 'Select Item')}
                          >
                            {itemsList.map((item, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={item.Id}
                                  disabled={
                                    props &&
                                    props.formObject &&
                                    props.formObject.TaskData &&
                                    props.formObject.TaskData[props.fieldKey] &&
                                    props.formObject.TaskData[props.fieldKey]['Items'] &&
                                    props.formObject.TaskData[props.fieldKey]['Items'].filter(
                                      (text) => text && text.ItemId === item.Id
                                    ).length > 0
                                      ? true
                                      : false
                                  }
                                >
                                  {' '}
                                  {translation(translationCache, item.Item)}{' '}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          label={translation(translationCache, constant.description)}
                          {...item}
                          name={[item.name, 'Description']}
                          fieldKey={[item.fieldKey, 'Description']}
                          key={index2}
                          rules={[{ required: false, message: 'Value Missing' }]}
                        >
                          <InputComponent
                            placeholder={translation(
                              translationCache,
                              'AutoPopulate Based on Selected Item'
                            )}
                            style={{ width: '90%' }}
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          // style={{ flex: '50%' }}
                          // labelCol={{ flex: '130px' }}
                          // label={translation(translationCache, constant.availableQuantity)}
                          // {...item}
                          name={[item.name, 'AvailableQuantity']}
                          // fieldKey={[item.fieldKey, 'AvailableQuantity']}
                          // key={index2}
                          // rules={[{ required: false, message: 'Value Missing' }]}
                        >
                          {/* <InputComponent
                          //maxLength={10}
                          disabled={true}
                          placeholder{translation(translationCache,"Select Tool")}"Autopopulate Basis To The Item"
                          style={{ width: '90%' }}
                        /> */}
                        </Form.Item>
                        <Form.Item
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          label={translation(translationCache, constant.Quantity)}
                          {...item}
                          validateTrigger={['onChange', 'onBlur']}
                          name={[item.name, 'Quantity']}
                          fieldKey={[item.fieldKey, 'Quantity']}
                          key={index2}
                          rules={[
                            { required: false, message: 'Please Input Numbers Only' },
                            // {
                            //   // pattern: new RegExp(/^[0-9]*$/),
                            //   // message: 'field accept only numbers'
                            // },
                            {
                              validator: (_, value) =>
                                checkTask(
                                  _,
                                  value,
                                  'Items',
                                  item.key,
                                  props.formObject.TaskData[props.fieldKey],
                                  { validateDecimal: true }
                                )
                            }
                          ]}
                        >
                          <InputNumberComponent
                            //maxLength={10}
                            disabled={
                              props &&
                              props.formObject &&
                              props.formObject.TaskData &&
                              props.formObject.TaskData[props.fieldKey] &&
                              props.formObject.TaskData[props.fieldKey].Items &&
                              props.formObject.TaskData[props.fieldKey].Items[index2] === null
                                ? true
                                : false
                            }
                            placeholder={translation(translationCache, 'Enter Quantity')}
                            style={{ width: '90%' }}
                            addonAfter={
                              <Tooltip
                                // style={{ flex: '50%' }}
                                // labelCol={{ flex: '130px' }}
                                title={translation(
                                  translationCache,
                                  'AvailableQuantity  -  ' +
                                    (props &&
                                    props.formObject &&
                                    props.formObject.TaskData[props.fieldKey] &&
                                    props.formObject.TaskData[props.fieldKey].Items &&
                                    props.formObject.TaskData[props.fieldKey].Items[index2] &&
                                    props.formObject.TaskData[props.fieldKey].Items[index2]
                                      .AvailableQuantity
                                      ? props.formObject.TaskData[props.fieldKey].Items[index2]
                                          .AvailableQuantity
                                      : 'Not Available')
                                )}
                              >
                                <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                              </Tooltip>
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          {...item}
                        ></Form.Item>
                        {/* <Col span={5} >
                    <Form.Item label="Total Quantity" disabled={true} {...item} name={[item.name, "AvailableQuantity"]} fieldKey={[item.fieldKey, "AvailableQuantity"]} key={index2} rules={[{ required: false, message: "Value Missing" }]} >
                      <InputComponent disabled={true} placeholder={translation(translationCache,"Total Quantity")} style={{ width: "90%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={5} >
                    <Form.Item label="Quantity" validateTrigger={['onChange', 'onBlur']} {...item} name={[item.name, "Quantity"]} fieldKey={[item.fieldKey, "Quantity"]} key={index2}
                      rules={[
                        { required: false, message: "Please Input Numbers Only" }, {
                              //pattern: new RegExp(/^[0-9]*$/),
                              message: 'field accept only numbers'
                            },
                        {
                          validator: (_, value) => checkTask(_, value, "Items", item.key, props.formObject, props.fieldKey),
                        }
                      ]}
                    >
                      <InputNumberComponent min={0} max={1000} placeholder={translation(translationCache,"Quantity")} style={{ width: "90%" }} />

                    </Form.Item>
                  </Col> */}
                      </div>
                    </>
                  </Panel>
                ))}
              </StyledCollapse>
            ) : null}
          </Fragment>
        );
      }}
    </Form.List>
  );
};
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
const ToolsForm = connect(mapStateToProps)(ToolsformFunction);
const LaborForm = connect(mapStateToProps)(LaborFormFunction);
const MaterialForm = connect(mapStateToProps)(MaterialFormFunction);
const ItemForm = connect(mapStateToProps)(ItemFormFunction);

export { ToolsForm, LaborForm, MaterialForm, ItemForm };
