import styled from 'styled-components';
import { Button, Table, Form, Modal, Tabs } from 'antd';
import { Link } from 'react-router-dom';

export const StyledDashboard = styled.div`
  // background: rgb(26, 54, 82);
  padding: 30px;
`;
export const DrawerFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
`;
export const StyledAddButton = styled(Button)`
  margin-left: 30px;
`;
export const AddTableButton = styled(Button)`
  margin-bottom: 20px;
  background: '#214972';
  border-color: '#214972';
`;
export const StyledIFrame = styled.iframe`
  width: 402%;
  height: 400%;
  border: 0;
  position: absolute;
  top: 0;
  left: -0.5%;
  background: #191a1d;
  -webkit-transform: scale(0.5);
  transform: scale(0.25);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  margin: 2px auto;
`;

export const LinkOverlay = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  :hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
  }
`;

export const StyledTable = styled(Table)`
  caption-side: top;
  color: ${(props) => props.theme.list.table.column.color} !important;
  width: 100%;
  caption-side: bottom;
  td,
  th {
    border: none;
  }
  td {
    padding: 11px 10px;
  }
  .ant-table-thead > tr > th{
    font-weight:bold !important
    font-size: 1em !important;
    color:white !important;
    background: ${(props) => props.theme.list.table.column.backgroundColor} ;
  }
  text[style] {
    font-size: 1em !important;
    color: ${(props) => props.theme.list.table.column.color} !important;
 }
  tbody tr {
    :nth-of-type(odd) {
      background-color:${(props) => props.theme.list.table.column.odd} !important;
    }
    :hover {
      background-color:${(props) => props.theme.list.table.column.hover} !important; 
    }
  }
  tbody tr {
    :nth-of-type(even) {
      background-color:${(props) => props.theme.list.table.column.even} !important;
    }
    :hover {
      background-color:${(props) => props.theme.list.table.column.hover} !important; 
    }
  }
  thead > tr {
    color: ${(props) => props.theme.list.table.column.color} !important;
    background-color: #262626 !important;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

export const StyledForm = styled(Form)`
  .ant-divider-horizontal.ant-divider-with-text {
    display: flex;
    margin: 16px 0;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-top: 0;
  }

  .ant-form-item-label > label {
    color: ${(props) => props.theme.form.formLabel} !important;
    font-weight: revert;
    font-size: ${(props) => props.theme.form.formLabelSize};
    font-weight: bold;
  }

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.66);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }

  .ant-tabs-content-holder {
    border-radius: 0 5px 5px 5px;
  }
`;

// export const StyledButton = styled(Button)`
//   background: ${(props) => props.theme.form.buttonBackgroundColor} !important;
//   color: ${(props) => props.theme.form.buttonColor} !important;
//   display: flex;
//   justify-content: right;
// `;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    color: black;
  }

  .ant-tabs-tab-remove {
    flex: none;
    margin-right: -4px;
    margin-left: 8px;
    font-size: 12px;
    background: #1a3b5c;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    color: white !important;
  }

  .checkTab {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0.5;
    cursor: not-allowed !important;
    pointer-events: none !important;
    color: black;
  }
`;

export const StyledModal = styled(Modal)`
  width: 55% !important;

  .ant-modal-content {
    border-radius: 20px;
    background: #1a3652;
  }

  .ant-modal-header {
    background: #1a3652;
  }
  .ant-modal-title {
    color: #ffffff !important;
  }
  .ant-form-item-required {
    color: #ffffff !important;
  }

  .ant-modal-close {
    color: white !important;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
  }
`;
