import React, { Component } from 'react';

import {
  Button,
  // Popconfirm,
  Input,
  Tooltip,
  Table,
  Tabs,
  Form,
  Space,
  Select,
  Drawer,
  message,
  Upload
} from 'antd';
import Highlighter from 'react-highlight-words';
import {
  getAttributesList,
  // deleteAssetAttribute,
  CreateAssetAttribute,
  getAttributesListByAsset,
  getUnCommonFileDownloadName
} from '../APICalls';
import axios from 'axios';
import {
  // DeleteOutlined,
  EditOutlined,
  // QuestionCircleOutlined,
  SearchOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined
} from '@ant-design/icons';
// import history from '../../../commons/history';
import localStorage from '../../../utils/localStorage';
import { connect } from 'react-redux';
import { constant } from '../Utils/constants';
import { createStructuredSelector } from 'reselect';
import translation from '../Utils/translation';
import { getTranslationCache } from '../../../selectors/language';
import themeSettings from '../Utils/themeSettings.json';

// import ListView from '../../../Utils/ListView';
// import ListView from '../../AMM/Utils/ListView';
// import AssetAttributeDetails from './AssetAttributeDetails';
import { StyledComponent, StyledButton, DrawerFooter } from '../../../commons/styles/layout';
import { IPAddress } from '../../../commons/api';

const { Option } = Select;

const { TabPane } = Tabs;
const APIList = {
  createAsset: `/api/asset/assetAttributes/upsert`
};
class AssetAttributeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      activeKey: '1',
      form: {},
      AssetList: [],
      TypeList: [],
      formObject: {},
      dynamicEditObject: {},
      translationCache: props.translationCache || [],
      userProfile: props && props.userProfile ? props.userProfile : {}
    };
  }
  hForm = React.createRef();
  dynamicConfig = React.createRef();

  componentDidMount() {
    this._getAdhocDataSource();
    this.getFileDownloadName();
    try {
      let AssetList = this.getAssetList();
      this.setState({ AssetList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let TypeList = this.getTypeList();
      this.setState({ TypeList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    // if (this.props.match.params.Masters !== prevprops.match.params.Masters) {
    //     let mastername = this.props.match.params.Masters;
    //     this._getAdhocDataSource();
    //     this.setState({ mastername });
    // }
    if (this.props.userProfile !== prevprops.userProfile) {
      this.setState({ userProfile: this.props.userProfile });
    }
  }
  getAssetList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let url = '';
    url = `/api/asset/get?SiteId=${siteId}`;

    let orgHeaders = {
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ AssetList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getTypeList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let url = '';
    url = `/api/masterconfiguration/getDropdownForCompositeKey?SiteId=${siteId}`;

    let orgHeaders = {
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        TableName: 'rubusconfig."SM_AssetAttributes"',
        ColumnName: 'Name',
        ColumnValue: 'Code',
        Conditions: [
          {
            key: 'SiteId',
            value: siteId
          }
        ]
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ TypeList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  _getAdhocDataSource = async () => {
    // let dataSource = await getAssetAttributeList(urlList.list);
    let dataSource = await getAttributesList();
    this.setState({
      dataSource,
      activeKey: '1',
      form: {}
    });
  };

  // _deleteById = async (id) => {
  //   // await deleteAssetAttribute(urlList.delete, id);
  //   // let dataSource = await getAssetAttributeList(urlList.list);
  //   this.setState({
  //     // dataSource
  //   });
  // };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, 'Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, 'Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  onClose = () => {
    this.setState({
      visibleParent: false,
      formObject: {},
      dynamicEditObject: {}
    });
    if (this.hForm.current || this.dynamicConfig.current) {
      this.hForm.current.resetFields();
      this.dynamicConfig.current.resetFields();
    }
  };
  AddNew = () => {
    if (this.hForm.current || this.dynamicConfig.current) {
      this.hForm.current.resetFields();
      this.dynamicConfig.current.resetFields();
    }
    // this.hForm.current &&
    //     this.hForm.current.setFieldsValue({
    //         AssetAlias: "",
    //         Details: []
    //     });
    this.setState({ visibleParent: true, formObject: {}, dynamicEditObject: {} });
  };
  editAssetMaster = (edit) => {
    let formObject = this.onAssetAliasChange(edit.AssetAlias);
    // let formObject = edit ? edit : {};
    if (this.hForm.current) {
      this.hForm.current.setFieldsValue(formObject);
      this.dynamicConfig.current.setFieldsValue({ Details: formObject.Details });
    }
    this.setState({
      formObject,
      visibleParent: true,
      dynamicEditObject: { Details: formObject.Details }
    });
  };
  onFinish = async () => {
    // let { formObject, dynamicEditObject } = this.state;
    let Details = [];
    this.dynamicConfig.current
      .validateFields()
      .then((values) => {
        Details = values && values['Details'] ? values['Details'] : [];
      })
      .catch((error) => {
        console.log(error);
      });
    this.hForm.current &&
      this.hForm.current
        .validateFields()
        .then(async (values) => {
          let payload = {
            AssetAlias: values.AssetAlias,
            Details: Details
          };
          // if (formObject && formObject.Id) {
          //     let UpdateResponse = await CreateAssetAttribute(
          //         APIList.createAsset,
          //         payload
          //     );
          //     message.success(UpdateResponse && UpdateResponse.message);
          // } else {

          let CreateResponse = await CreateAssetAttribute(APIList.createAsset, payload);
          if (CreateResponse && CreateResponse.message) {
            message.success(CreateResponse && CreateResponse.message);
          }
          this._getAdhocDataSource();
          this.hForm.current &&
            this.hForm.current.setFieldsValue({
              AssetAlias: '',
              Details: {}
            });
          this.setState({
            visibleParent: false,
            formObject: {},
            dynamicEditObject: {}
          });
        })
        .catch((error) => {
          console.log(error);
        });
  };
  onAssetAliasChange = async (value) => {
    let list = await getAttributesListByAsset(value);
    let formObject = list ? list : {};
    if (this.hForm.current) {
      this.hForm.current.setFieldsValue(formObject);
      this.dynamicConfig.current.setFieldsValue({ Details: formObject.Details });
    }
    this.setState({
      formObject,
      // visibleParent: true,
      dynamicEditObject: { Details: formObject.Details }
    });
  };
  getFileDownloadName = async () => {
    let fileDownloadName = await getUnCommonFileDownloadName();
    this.setState({
      fileDownloadName: fileDownloadName && fileDownloadName.file ? fileDownloadName.file : ''
    });
  };
  render() {
    const {
      dataSource,
      TypeList,
      AssetList,
      visibleParent,
      formObject,
      dynamicEditObject,
      fileDownloadName
    } = this.state;

    let { translationCache } = this.props;
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    let columns = [
      {
        title: translation(translationCache, constant.AssetAlias),
        dataIndex: 'Name',
        key: 'Name',
        filter: true,
        ...this.getColumnSearchProps('Name')
      },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            <Button className="ant-btn" onClick={() => this.editAssetMaster(record)} size="middle">
              {' '}
              <Tooltip title={translation(translationCache, 'Edit')}>
                <EditOutlined />
              </Tooltip>
            </Button>
          </>
        )
      }
    ];
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 69 }}>
        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '10px' }}>
          <StyledButton>
            <a href={`${IPAddress.LOCAL}${fileDownloadName}`}>
              <DownloadOutlined style={{ color: 'white', fontSize: '16px', marginRight: '1px' }} />
              <span style={{ color: 'white', marginRight: '10px' }}>
                {translation(translationCache, 'Download')}
              </span>
            </a>
          </StyledButton>
          <StyledButton>
            <Upload
              multiple={false}
              style={{ marginRight: '10px', color: 'white' }}
              onChange={(info) => {
                if (info.file.status === 'done') {
                  message.success(info.file.response.message);
                  // this.getParameterList();
                  this.getFileDownloadName();
                  this._getAdhocDataSource();
                } else if (info.file.status === 'error') {
                  message.error(info.file.response.message);
                }
              }}
              action={`/api/upload/assetAttributes?SiteId=${siteId}`}
              headers={{
                Authorization: Authorization
              }}
              showUploadList={false}
            >
              <UploadOutlined style={{ color: 'white', fontSize: '16px', marginRight: '1px' }} />
              <span style={{ color: 'white', marginRight: '10px' }}>
                {' '}
                {translation(translationCache, 'Upload')}
              </span>
            </Upload>
          </StyledButton>
          <StyledButton onClick={this.AddNew}>
            <PlusOutlined /> {translation(translationCache, 'Add')}
          </StyledButton>
        </div>
        <Tabs
          // tabPosition="top"
          // type="card"
          // style={{ marginTop: '20px', marginBottom: '30px' }}
          theme={themeSettings}
        >
          <TabPane>
            <Table
              className="basictable"
              columns={columns}
              dataSource={dataSource}
              // dataSource={[]}
              pagination={
                dataSource.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : false
              }
            />
          </TabPane>
        </Tabs>
        <Drawer
          title={
            formObject && formObject.Id
              ? translation(translationCache, 'Update')
              : translation(translationCache, 'Add')
          }
          placement="right"
          width={600}
          closable
          onClose={this.onClose}
          visible={visibleParent}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            theme={themeSettings}
            layout="horizontal"
            ref={this.hForm}
            name="Site_form"
            initialValues={formObject || {}}
            onFinish={this.onFinish}
          >
            <Form.Item
              style={{ width: '100%', flex: '50%', marginTop: '20px' }}
              label={translation(translationCache, constant.asset)}
              name="AssetAlias"
            >
              <Select
                style={{ width: '50%' }}
                value={formObject.AssetAlias}
                placeholder={'Select Asset Alias'}
                onChange={(e) => this.onAssetAliasChange(e)}
              >
                {AssetList &&
                  Array.isArray(AssetList) &&
                  AssetList.map((Asset, index) => {
                    return (
                      <Option key={index} value={Asset.AliasCode}>
                        {' '}
                        {Asset.DisplayCode}{' '}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label={'Details'}
              name="Details"
              rules={[{ required: false, message: 'Please select Columns!!' }]}
            >
              <Form
                ref={this.dynamicConfig}
                name="dynamic_form_nest_item"
                autoComplete="off"
                initialValues={dynamicEditObject || {}}
              >
                <Form.List name="Details">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'Type']}
                            rules={[
                              {
                                required: true,
                                message: 'Select Type'
                              }
                            ]}
                          >
                            <Select
                              showArrow
                              // defaultValue={k.key}
                              openOnFocus
                              style={{ width: 200 }}
                              // onChange={(e) => this.onColumnKeyChange(e, 'key', index)}
                              placeholder={'Type'}
                              // filterOption={(input, option) =>
                              //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                              //   0
                              // }
                            >
                              {TypeList &&
                                Array.isArray(TypeList) &&
                                TypeList.map((type, index) => {
                                  return (
                                    <Option key={index} value={type.Value}>
                                      {' '}
                                      {type.Key}{' '}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'Value']}
                            rules={[
                              {
                                required: true,
                                message: 'Input Value'
                              }
                            ]}
                          >
                            <Input placeholder={'Value'} style={{ width: 200 }} />
                          </Form.Item>

                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          style={{ width: 150 }}
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          {'Add'}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form>
            </Form.Item>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {translation(translationCache, 'Cancel')}
            </Button>
            <Button type="primary" onClick={this.onFinish}>
              {translation(translationCache, 'Submit')}
            </Button>
          </DrawerFooter>
        </Drawer>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(AssetAttributeList);
