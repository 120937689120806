/* eslint-disable no-unused-vars */
import axios from 'axios';
import moment from 'moment';

import localStorage from '../../../utils/localStorage';
import { API_DASHBOARD, API_FILE, API_HIERARCHYMODULE, WARNINGLIMITS } from '../../../commons/api';

export async function getChartDataWithoutSelection(props) {
  let { graphprops, abortController, dropdownSelection, DeviceCode, TimeZone } = props;
  console.log(props, 'propsprops');
  let SiteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`${API_DASHBOARD.GetDashboard}?SiteId=${SiteId}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      Name: graphprops.dashboardName,
      PanelName: graphprops.title,
      Id: graphprops.id,
      SiteId,
      TimeZone,
      ...dropdownSelection,
      ...(DeviceCode ? { DeviceCode } : {})
    }),
    signal: abortController.signal
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}
export async function getChartDataWithSelection(props) {
  let {
    graphprops,
    DeviceCode,
    selectionDate,
    selectionMonth,
    selectionWeek,
    shift,
    selectionDateRange,
    dayMonthYear,
    dropdownSelection,
    AssetCode,
    extraKeys,
    currentTimeZone
  } = props;
  let SiteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let { dashboardName, title, type, id } = graphprops;
  let newdashboardPayload = {};
  let newDatePayload = {};
  let newDateRangePayload = {};
  let newMonthPayload = {};
  let newShiftPayload = {};
  let newWeekPayload = {};
  let newdayMonthYearPayload = {};
  let newmultiDropdownPayload = {};
  let treePayload = {};
  let TimeBucket =
    graphprops && graphprops.GraphLevelTimeBucket
      ? graphprops.GraphLevelTimeBucket
      : graphprops.TimeBucket;
  let payload = {
    Name: dashboardName,
    PanelName: title,
    Id: id,
    TimeBucket,
    TimeZone: currentTimeZone ? currentTimeZone : '',
    ...(extraKeys ? extraKeys : {}),
    SiteId
  };

  let selectionPoint = '0';
  if (graphprops.DashboardLevelSelection !== 'none') {
    selectionPoint =
      graphprops &&
      graphprops.DashboardLevelSelectionPoint &&
      graphprops.DashboardLevelSelectionPoint === 'PreviousDay'
        ? '1'
        : '0';
  } else if (graphprops.GraphLevelSelection !== 'none') {
    selectionPoint =
      graphprops &&
      graphprops.GraphLevelSelectionPoint &&
      graphprops.GraphLevelSelectionPoint === 'PreviousDay'
        ? '1'
        : '0';
  }

  // If SelectionType is DatePicker
  if (
    graphprops.DashboardLevelSelection === 'datePicker' ||
    graphprops.GraphLevelSelection === 'datePicker'
  ) {
    let startFormat = 'YYYY-MM-DD 00:00:00';
    let endFormat = 'YYYY-MM-DD 23:59:59';
    let start = moment(selectionDate).format(startFormat);
    let end = moment(selectionDate).format(endFormat);
    if (type !== 'summary') {
      newDatePayload = {
        StartDate: moment.tz(start, currentTimeZone).utc().format('YYYY-MM-DD HH:mm:ss'),
        EndDate: moment.tz(end, currentTimeZone).utc().format('YYYY-MM-DD HH:mm:ss')
      };
    } else {
      //if type is summary, UTC conversion is not required
      newDatePayload = {
        StartDate: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        EndDate: moment(end).format('YYYY-MM-DD HH:mm:ss')
      };
    }
  }
  // If SelectionType is MonthPicker
  if (
    graphprops.DashboardLevelSelection === 'monthPicker' ||
    graphprops.GraphLevelSelection === 'monthPicker'
  ) {
    let monthYearSplit = selectionMonth.split('-');
    let currentMonth = moment().format('MM');
    let currentYear = moment().format('YYYY');
    if (type !== 'summary') {
      newMonthPayload = {
        StartDate: moment
          .tz([monthYearSplit[0], monthYearSplit[1]], currentTimeZone)
          .startOf('month')
          .subtract(1, 'month')
          .utc()
          .format(`YYYY-MM-DD HH:mm:ss`),
        EndDate:
          monthYearSplit[1] === currentMonth && monthYearSplit[0] === currentYear
            ? moment()
                .tz(currentTimeZone)
                .subtract(selectionPoint, 'day')
                .utc()
                .format('YYYY-MM-DD HH:mm:ss')
            : moment([monthYearSplit[0], monthYearSplit[1] - 1])
                .tz(currentTimeZone)
                .endOf('month')
                .subtract(selectionPoint, 'day')
                .utc()
                .format('YYYY-MM-DD HH:mm:ss')
      };
    } else {
      newMonthPayload = {
        StartDate: moment
          .tz([monthYearSplit[0], monthYearSplit[1]], currentTimeZone)
          .startOf('month')
          .subtract(1, 'month')
          .format(`YYYY-MM-DD HH:mm:ss`),
        EndDate:
          monthYearSplit[1] === currentMonth && monthYearSplit[0] === currentYear
            ? moment()
                .tz(currentTimeZone)
                .subtract(selectionPoint, 'day')
                .format('YYYY-MM-DD HH:mm:ss')
            : moment([monthYearSplit[0], monthYearSplit[1] - 1])
                .endOf('month')
                .subtract(selectionPoint, 'day')
                .format('YYYY-MM-DD HH:mm:ss')
      };
    }
  }
  // If SelectionType is WeekPicker
  if (
    graphprops.DashboardLevelSelection === 'weakPicker' ||
    graphprops.GraphLevelSelection === 'weakPicker'
  ) {
    let currentWeek = moment().startOf('week');
    if (type !== 'summary') {
      newWeekPayload = {
        StartDate: moment
          .tz(selectionWeek, currentTimeZone)
          .startOf('week')
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        EndDate: selectionWeek.isSame(currentWeek)
          ? moment()
              .tz(currentTimeZone)
              .subtract(selectionPoint, 'day')
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
          : moment
              .tz(selectionWeek, currentTimeZone)
              .endOf('week')
              .subtract(selectionPoint, 'day')
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
      };
    } else {
      newWeekPayload = {
        StartDate: moment(selectionWeek).startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        EndDate: selectionWeek.isSame(currentWeek)
          ? moment().tz(currentTimeZone).format('YYYY-MM-DD HH:mm:ss')
          : moment
              .tz(selectionWeek, currentTimeZone)
              .endOf('week')
              .subtract(selectionPoint, 'day')
              .format('YYYY-MM-DD HH:mm:ss')
      };
    }
  }

  // If SelectionType is DateRangePicker
  if (
    graphprops.DashboardLevelSelection === 'dateRangePicker' ||
    graphprops.GraphLevelSelection === 'dateRangePicker'
  ) {
    if (type !== 'summary') {
      newDateRangePayload = {
        StartDate: moment(selectionDateRange && selectionDateRange.startDate)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        EndDate: moment(selectionDateRange && selectionDateRange.endDate)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      };
    } else {
      newDateRangePayload = {
        StartDate: moment(selectionDateRange && selectionDateRange.startDate).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        EndDate: moment(selectionDateRange && selectionDateRange.endDate).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      };
    }
  }
  // If SelectionType is DayMonthYearPicker
  if (
    graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
    graphprops.GraphLevelSelection === 'dateMonthYearPicker'
  ) {
    if (type !== 'summary') {
      newdayMonthYearPayload = {
        ...dayMonthYear,
        StartDate: moment
          .tz(dayMonthYear && dayMonthYear.StartDate, currentTimeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        EndDate: moment
          .tz(dayMonthYear && dayMonthYear.EndDate, currentTimeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      };
    } else {
      newdayMonthYearPayload = {
        ...dayMonthYear,
        StartDate: moment
          .tz(dayMonthYear && dayMonthYear.StartDate, currentTimeZone)
          .format('YYYY-MM-DD HH:mm:ss'),
        EndDate: moment
          .tz(dayMonthYear && dayMonthYear.EndDate, currentTimeZone)
          .format('YYYY-MM-DD 23:59:59')
      };
    }
  }
  // If SelectionType is ShiftPicker
  if (
    graphprops.DashboardLevelSelection === 'shiftPicker' ||
    graphprops.GraphLevelSelection === 'shiftPicker'
  ) {
    newShiftPayload = {
      Shift: shift
    };
  }

  if (graphprops.treeExists === 'true') {
    treePayload = {
      AssetCode: AssetCode
    };
  }

  if (graphprops.dashboardType === 'dynamic' || DeviceCode) {
    newdashboardPayload = {
      DeviceCode
    };
  }

  if (
    dropdownSelection &&
    (graphprops.dashboardType === 'multi' || graphprops.dashboardType === 'hierarchy')
  ) {
    newmultiDropdownPayload = dropdownSelection;
  }
  payload = {
    ...payload,
    ...newdashboardPayload,
    ...newDatePayload,
    ...newMonthPayload,
    ...newWeekPayload,
    ...newShiftPayload,
    ...newDateRangePayload,
    ...newdayMonthYearPayload,
    ...newmultiDropdownPayload,
    ...treePayload
  };
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`${API_DASHBOARD.GetDashboard}?SiteId=${SiteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload),
    signal: AbortController.signal
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}
export async function getMaximoHierarchyData(abortController) {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`${API_HIERARCHYMODULE.GetMaximoHierarchy}?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    signal: abortController.signal
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}
export function _getReportNametoDownload(excelData) {
  let payload = {
    date: moment().format('YYYY-MM-DD'),
    time: moment().format('HH:mm:ss'),
    data: excelData || []
  };

  let siteId = localStorage.get('currentSite');
  let accessToken = localStorage.get('accessToken');
  let options = {
    method: 'POST',
    url: `${API_FILE.GenerateExcel}?SiteId=${siteId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: JSON.stringify(payload)
  };
  return axios(options)
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      return '';
    });
}
export async function getChartWarningSelection(props) {
  let {
    graphprops,
    DeviceCode,
    selectionDate,
    selectionMonth,
    selectionWeek,
    shift,
    selectionDateRange,
    dayMonthYear,
    abortController,
    dropdownSelection,
    dashboardPayload,
    AssetCode,
    selectionDateLive,
    timeZone
  } = props;
  let siteId = localStorage.get('currentSite');

  const token = localStorage.get('accessToken');

  let newdashboardPayload = {};
  let newDatePayload = {};
  let newDateRangePayload = {};
  let newMonthPayload = {};
  let newShiftPayload = {};
  let newWeekPayload = {};
  let newdayMonthYearPayload = {};
  let newmultiDropdownPayload = {};
  let treePayload = {};
  let payload = {
    Name: graphprops && graphprops.dashboardName,
    PanelName: graphprops && graphprops.title,
    Id: graphprops && graphprops.id,
    type: (graphprops && graphprops.type) || 'historic',
    timeZone,
    date: timeZone
      ? moment
          .tz(moment().format('YYYY-MM-DD HH:mm:ss'), timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      : moment().format('YYYY-MM-DD HH:mm'),
    dashboardType: 'static',
    siteId,
    startDate: timeZone
      ? moment
          .tz(moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'), timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      : moment().subtract(1, 'hour').utc().format('YYYY-MM-DD HH:mm:ss'),
    endDate: timeZone
      ? moment
          .tz(moment().format('YYYY-MM-DD HH:mm:ss'), timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      : moment().utc().format('YYYY-MM-DD HH:mm:ss'),

    timeBucket: '1h'
  };
  if (graphprops.dashboardType === 'dynamic' || DeviceCode) {
    newdashboardPayload = {
      dashboardType: 'dynamic',
      DeviceCode
    };
  }

  if (
    dropdownSelection &&
    (graphprops.dashboardType === 'multi' || graphprops.dashboardType === 'hierarchy')
  ) {
    newmultiDropdownPayload = dropdownSelection;
  }
  if (graphprops.dateExists === 'true' || graphprops.selection === 'datePicker') {
    newDatePayload = {
      dateExists: true,
      date: moment.tz(selectionDate, timeZone).utc().format('YYYY-MM-DD HH:mm:ss')
    };
  }
  if (graphprops.dateExists === 'true') {
    newDatePayload = {
      dateExists: true,
      date: moment.tz(selectionDateLive, timeZone).utc().format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.monthExists === 'true') {
    let monthYear = selectionMonth.split('-');
    let monthslipt = selectionMonth;
    let currentYear = moment().format('YYYY-MM');
    let selectedYear = monthYear[0];
    let selectedmonth = monthYear[1];
    newMonthPayload = {
      monthExists: true,
      timeBucket: '1d',
      DeviceCode,
      selectionType: 'month',
      startDate: moment
        .tz([monthYear[0], monthYear[1]], timeZone)
        .startOf('month')
        .subtract('month', 1)
        .utc()
        .format(`YYYY-MM-DD HH:mm:ss`),
      endDate:
        monthslipt === currentYear
          ? moment().subtract(1, 'day').utc().format('YYYY-MM-DD HH:mm:ss')
          : moment
              .tz([selectedYear, selectedmonth - 1], timeZone)
              .endOf('month')
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.weekExists === 'true') {
    let currentWeek = moment().startOf('week');
    newWeekPayload = {
      weekExists: true,
      startDate: moment
        .tz(selectionWeek, timeZone)
        .startOf('week')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: selectionWeek.isSame(currentWeek)
        ? moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')
        : moment.tz(selectionWeek, timeZone).endOf('week').utc().format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.shiftExists === 'true') {
    newShiftPayload = {
      shiftExists: true,
      shift
    };
  }

  if (graphprops.dateRangeExists === 'true' || graphprops.selection === 'dateRangePicker') {
    newDateRangePayload = {
      dateRangeExists: true,
      ...selectionDateRange,
      startDate: moment
        .tz(
          selectionDateRange && selectionDateRange.startDate ? selectionDateRange.startDate : '',
          timeZone
        )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment
        .tz(
          selectionDateRange && selectionDateRange.endDate ? selectionDateRange.endDate : '',
          timeZone
        )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
    };
  }

  if (graphprops.dayMonthYearExists === 'true' || graphprops.selection === 'dateMonthYearPicker') {
    if (graphprops.type === 'summary') {
      // Customized for Enewood Power Generation (Summary Data)
      newdayMonthYearPayload = {
        dayMonthYearExists: true,
        ...dayMonthYear,
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        startDate: moment(dayMonthYear && dayMonthYear.startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(dayMonthYear && dayMonthYear.endDate).format('YYYY-MM-DD HH:mm:ss'),
        timeZone
      };
    } else {
      newdayMonthYearPayload = {
        dayMonthYearExists: true,
        ...dayMonthYear,
        startDate: moment
          .tz(dayMonthYear && dayMonthYear.startDate ? dayMonthYear.startDate : '', timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment
          .tz(dayMonthYear && dayMonthYear.endDate ? dayMonthYear.endDate : '', timeZone)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      };
    }
  }

  if (graphprops.treeExists === 'true' || graphprops.selection === 'dateMonthYearPicker') {
    treePayload = {
      AssetCode: AssetCode
    };
  }
  payload = {
    ...payload,
    ...newdashboardPayload,
    ...newDatePayload,
    ...newMonthPayload,
    ...newWeekPayload,
    ...newShiftPayload,
    ...newDateRangePayload,
    ...newdayMonthYearPayload,
    ...newmultiDropdownPayload,
    ...treePayload
  };

  var Authorization = 'Bearer ' + token;
  let data = await fetch(`${WARNINGLIMITS.GetWarningData}?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload),
    signal: AbortController.signal
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}
