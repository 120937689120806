import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
import {
  Drawer,
  Button,
  Input,
  Select,
  message,
  Upload,
  Table,
  Space,
  Form,
  Popconfirm,
  Tooltip,
  Radio
} from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
  SearchOutlined
} from '@ant-design/icons';

import localStorage from '../../../../utils/localStorage';
import { adapter } from '../utility/adapter';
import { getUserProfile } from '../../../../selectors/layout';
import { _getMenuList, getMobileTypeListData } from './ApiCalls';
import { StyledTable, lightTheme, StyledApplication, DrawerFooter } from './style';
import { getTranslationCache } from '../../../../selectors/language';

import './index.css';
import 'react-sortable-tree/style.css';
// import { constant } from '../Constants';
import { API_MENU } from '../../../../commons/api';
import Highlighter from 'react-highlight-words';

let accessToken = localStorage.get('accessToken');
const { Option } = Select;

class MenuBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      forms: [],
      name: '',
      path: '',
      fullName: '',
      mobileType: '',
      visible: false,
      TableVisible: false,
      userProfile: props && props.userProfile ? props.userProfile : {},
      siteId: '',
      menuId: '',
      translationCache: props.translationCache || [],
      updateMenuData: [],
      editObject: {},
      defValue: '',
      editObj: {},
      UnstructuredDataSource: [],
      menuType: 'Web',
      mobileTypeList: [],
      type: ''
    };
  }

  menuRef = React.createRef();

  componentDidMount() {
    this.getMenuListData(this.state.menuType);
    this.getMobileModules();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  getMobileModules = async () => {
    let mobileTypeList = await getMobileTypeListData();
    this.setState({ mobileTypeList });
  };

  getMenuById = async (editObject) => {
    let { menuType } = this.state;
    let response = await this.getMenuByType(editObject, 'Structured', menuType);
    if (response && response[0]) {
      this.setState({
        treeData: toggleExpandedForAll({
          treeData: adapter(response[0])
        }),
        menuKey: response[0].key,
        menuName: response[0].name,
        menuId: response[0].Id,
        siteId: response[0].SiteId,
        editObj: response[0]
      });
    }
    let UnstructuredDataSource = await this.getMenuByType(editObject, 'UnStructured', menuType);
    this.setState({ UnstructuredDataSource: UnstructuredDataSource });
  };

  getMenuByType = (editObject, Type, menuType) => {
    const accessToken = localStorage.get('accessToken');
    let authMenuObject = {
      method: 'GET',
      url: `${API_MENU.Get}?SiteId=${editObject.SiteId}&Type=${Type}&menuType=${menuType}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(authMenuObject)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  };

  saveMenu = () => {
    let { editObj, editObject, menuType } = this.state;
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    this.menuRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          method: 'POST',
          url: ` ${API_MENU.CREATE}?SiteId=${siteId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            name: values.name,
            path: values.path,
            fullName: values.fullName,
            mobileType: values.mobileType,
            type: values.type,
            parentId: editObj.parentId,
            SiteId: siteId,
            menuType: menuType
          }
        };

        axios(payload)
          .then((response) => {
            message.success(response.data.message);
            this.getMenuById(editObject);
            this.setState({
              editObj: {},
              visible: false
            });
            if (this.menuRef.current) {
              this.menuRef.current.resetFields();
            }
          })
          .catch(function (error) {
            console.log(error);
            return [];
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateMenu = () => {
    let { editObj, editObject, menuType } = this.state;
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    this.menuRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          method: 'PATCH',
          url: `${API_MENU.Update}?SiteId=${siteId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            name: values.name,
            path: values.path,
            fullName: values.fullName,
            mobileType: values.mobileType,
            type: values.type,
            parentId: editObj.parentId,
            Id: editObj.Id,
            SiteId: siteId,
            menuType: menuType
          }
        };

        axios(payload)
          .then((response) => {
            message.success(response.data.message);
            this.getMenuById(editObject);
            if (this.menuRef.current) {
              this.menuRef.current.resetFields();
            }
            this.setState({
              editObj: {},
              visible: false
            });
          })
          .catch(function (error) {
            console.log(error);
            return [];
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteMenuItem = (Id) => {
    let { editObject } = this.state;
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let payload = {
      method: 'DELETE',
      url: `${API_MENU.DELETE}?SiteId=${siteId}&Id=${Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    axios(payload)
      .then((response) => {
        message.success(response.data.message);
        this.getMenuById(editObject);
        if (this.menuRef.current) {
          this.menuRef.current.resetFields();
        }
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  UploadMenu = (e) => {
    let { editObject, menuType } = this.state;
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let payload = {
      method: 'POST',
      url: `${API_MENU.BULKUPLOAD}?SiteId=${siteId}&menuType=${menuType}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: e
    };

    axios(payload)
      .then((response) => {
        message.success(response.data.message);
        this.getMenuById(editObject);
        if (this.menuRef.current) {
          this.menuRef.current.resetFields();
        }
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  getMenuListData = async (menuType) => {
    let dataSource = await _getMenuList(menuType);
    this.setState({
      dataSource
    });
  };

  editMenuItem = (obj) => {
    let editObj = obj.node.maniObj;
    if (this.menuRef.current) {
      this.menuRef.current.setFieldsValue(editObj);
    }
    this.setState({
      visible: true,
      editObj
    });
  };

  onClose = () => {
    if (this.menuRef.current) {
      this.menuRef.current.resetFields();
    }
    this.setState({ visible: false, editObj: {} });
  };

  onTableClose = () => {
    this.setState({ TableVisible: false, treeData: {} });
  };

  OnAddModule = (data) => {
    let parentId =
      data && data.node && data.node.maniObj && data.node.maniObj.key ? data.node.maniObj.key : 0;
    if (this.menuRef.current) {
      this.menuRef.current.resetFields();
    }
    this.setState({ visible: true, editObj: { parentId } });
  };

  editMenu = (editObject) => {
    this.setState({
      editObject,
      TableVisible: true
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onFinishFailed = () => {
    message.error('please enter required fields');
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation(`Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  onMenuTypeChange = async (menuType) => {
    let { editObject } = this.state;
    let response = await this.getMenuByType(editObject, 'Structured', menuType);
    let UnstructuredDataSource = await this.getMenuByType(editObject, 'UnStructured', menuType);

    if (response && response[0]) {
      this.setState({
        treeData: toggleExpandedForAll({
          treeData: adapter(response[0])
        }),
        menuKey: response[0].key,
        menuName: response[0].name,
        menuId: response[0].Id,
        siteId: response[0].SiteId,
        editObject,
        menuType,
        UnstructuredDataSource
      });
    }
    // else{
    //   this.setState({menuType})
    // }
  };

  render() {
    let {
      mobileTypeList,
      treeData,
      type,
      editObj,
      dataSource,
      TableVisible,
      UnstructuredDataSource,
      menuType
    } = this.state;
    let columns = [
      {
        title: this.translation('SiteId'),
        dataIndex: 'SiteId',
        key: 'SiteId',
        ...this.getColumnSearchProps('SiteId')
      },
      {
        title: this.translation('Action'),
        render: (text, record) => (
          <div>
            <button
              type="button"
              onClick={() => this.editMenu(record)}
              style={{ marginRight: '10px' }}
              className="ant-btn"
            >
              <EditOutlined />
            </button>
          </div>
        )
      }
    ];
    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 170 }}>
        <StyledTable theme={lightTheme}>
          <Table
            className="basictable"
            columns={columns}
            dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
            onRow={(editObject) => {
              return {
                onClick: () => {
                  this.getMenuById(editObject);
                }
              };
            }}
            pagination={false}
          />
        </StyledTable>

        <Drawer
          onClose={() => this.onTableClose()}
          title={this.translation('Menu Configuration')}
          width={1300}
          closable
          visible={TableVisible}
          extra={
            <Space>
              <div style={{ float: 'right' }}>
                <Upload
                  name="file"
                  accept="application/json"
                  headers={{
                    Authorization: `Bearer ${accessToken}`
                  }}
                  beforeUpload={(file) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      this.UploadMenu(JSON.parse(e.target.result));
                    };
                    reader.readAsText(file);
                    return false;
                  }}
                >
                  <Button>
                    <UploadOutlined /> {this.translation('Upload')}
                  </Button>
                </Upload>
              </div>

              <div>
                <Button
                  type="primary"
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(UnstructuredDataSource)
                  )}`}
                  download="menuConfiguration.json"
                >
                  <DownloadOutlined /> {this.translation(`Download`)}
                </Button>
              </div>
            </Space>
          }
        >
          <div style={{ padding: '10px 40px' }}>
            <Radio.Group
              onChange={(e) => {
                this.onMenuTypeChange(e.target.value);
              }}
              value={menuType}
            >
              <Radio value={'Web'}>Web</Radio>
              <Radio value={'Mobile'}>Mobile</Radio>
            </Radio.Group>
            <div style={{ padding: 20, background: 'white', minHeight: '65vh' }}>
              <SortableTree
                treeData={treeData}
                onChange={(treeData) => {
                  this.setState({ treeData });
                }}
                onMoveNode={(treeData) => {
                  let { editObject } = this.state;
                  const siteId = localStorage.get('currentSite');
                  let data = {
                    ...treeData.node.maniObj,
                    parentId:
                      treeData &&
                      treeData.nextParentNode &&
                      treeData.nextParentNode.maniObj &&
                      treeData.nextParentNode.maniObj.key
                        ? treeData.nextParentNode.maniObj.key
                        : null
                  };
                  delete data.children;
                  let payloadData = {
                    method: 'PATCH',
                    url: `${API_MENU.Update}?SiteId=${siteId}`,
                    headers: {
                      Authorization: `Bearer ${accessToken}`
                    },
                    data
                  };

                  axios(payloadData)
                    .then((response) => {
                      message.success(response.data.message);
                      this.getMenuById(editObject);
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                }}
                isVirtualized={false}
                slideRegionSize={0}
                rowHeight={60}
                generateNodeProps={(rowInfo, index) => {
                  if (rowInfo.node.name === 'Modules' || rowInfo.node.name === 'Configuration') {
                    return {};
                  } else {
                    return {
                      buttons: [
                        <PlusOutlined
                          key={index}
                          style={{ margin: '7px' }}
                          onClick={() => this.OnAddModule(rowInfo)}
                        />,
                        <EditOutlined
                          key={index}
                          style={{ margin: '7px' }}
                          onClick={() => this.editMenuItem(rowInfo)}
                        />,
                        <Popconfirm
                          key="delete"
                          title={this.translation('Are you sure, do you want to delete?')}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={() => {
                            this.deleteMenuItem(rowInfo.node.maniObj.Id);
                          }}
                          okText={this.translation('Yes')}
                          cancelText={this.translation('No')}
                        >
                          <Tooltip title={this.translation('Delete')}>
                            <DeleteOutlined key={index} style={{ margin: '7px' }} />
                          </Tooltip>
                        </Popconfirm>
                      ]
                    };
                  }
                }}
              />
            </div>
          </div>
          <DrawerFooter>
            <Button onClick={this.onTableClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
          </DrawerFooter>
        </Drawer>

        <Drawer
          title={
            editObj && editObj.Id
              ? this.translation('Update MenuItem')
              : this.translation('Create MenuItem')
          }
          width={600}
          closable
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            layout="vertical"
            ref={this.menuRef}
            name="user_form"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={editObj || {}}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              label={this.translation('Type')}
              name="type"
              rules={[
                {
                  required: true,
                  message: this.translation('Select Type')
                }
              ]}
            >
              <Select value={type} placeholder={this.translation('Select Type')}>
                {treeData &&
                  Array.isArray(treeData) &&
                  treeData.map((item) => (
                    <Option key={item.key} value={item.key}>
                      {item.key}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            {menuType === 'Mobile' ? (
              <Form.Item
                label={this.translation('ModuleType')}
                name="mobileType"
                rules={[
                  {
                    required: true,
                    message: this.translation('Select Module Type')
                  }
                ]}
              >
                <Select value={type} placeholder={this.translation('Select Type')}>
                  {mobileTypeList &&
                    Array.isArray(mobileTypeList) &&
                    mobileTypeList.map((item) => {
                      return (
                        <Option key={item.Value} value={item.Key}>
                          {item.key}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            ) : null}

            <Form.Item
              label={this.translation('Name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: this.translation('Select Module name')
                }
              ]}
            >
              <Input placeholder={this.translation('Select Module name')} />
            </Form.Item>
            <Form.Item
              label={this.translation('Full Name')}
              name="fullName"
              rules={[
                {
                  required: false,
                  message: this.translation('Select Full name')
                }
              ]}
            >
              <Input placeholder={this.translation('Select Full name')} />
            </Form.Item>
            <Form.Item
              label={this.translation('Path')}
              name="path"
              rules={[{ required: true, message: this.translation('Enter path') }]}
            >
              <Input placeholder={this.translation('Enter Path')} />
            </Form.Item>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            {editObj.Id ? (
              <Button onClick={this.updateMenu} type="primary">
                {this.translation('Update')}
              </Button>
            ) : (
              <Button onClick={this.saveMenu} type="primary">
                {this.translation('Save')}
              </Button>
            )}
          </DrawerFooter>
        </Drawer>
      </StyledApplication>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(MenuBuilder);
