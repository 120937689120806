import React, { Fragment } from 'react';
import { flattenDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../selectors/language';
// import SinglePlot from '../SinglePlot';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { StyledTree } from '../../CustomerAssetDeviceConfiguration/CSS/style';
import axios from 'axios';
import localStorage from '../../../utils/localStorage';
import SinglePlot from '../SinglePlot';
import { GetDefaultHierarchy } from '../../CustomerAssetDeviceConfiguration/APICalls';

class ModuleWiseDashboard extends React.Component {
  timeTicket = null;
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      dynamicName: '',
      displayName: ''
    };
  }

  componentDidMount = () => {
    console.log('this.props.match.params.module', this.props.match.params.module);
    this.getHierarchyLevels(this.props.match.params.module);
  };

  componentDidUpdate(prevprops) {
    if (this.props.match.params.module !== prevprops.match.params.module) {
      this.getHierarchyLevels(this.props.match.params.module);
    }
  }
  translation = (keyword) => {
    return keyword;
  };
  getHierarchyLevels = async (Module) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let defaultHiearchyCode = await GetDefaultHierarchy();
    let HierarchyCode =
      defaultHiearchyCode && defaultHiearchyCode[0] && defaultHiearchyCode[0].HierarchyCode
        ? defaultHiearchyCode[0].HierarchyCode
        : '';
    let headers = {
      method: 'GET',
      url: `/api/hierarchymodule/getHierarchyByLevels?SiteId=${siteId}&HierarchyCode=${HierarchyCode}&Module=${Module}&Level=1`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          let dynamicName = '';
          let displayName = '';
          if (
            data &&
            data[0] &&
            data[0].children &&
            data[0].children[0] &&
            data[0].children[0].Code
          ) {
            dynamicName = data[0].children[0].Code;
            displayName = data[0].children[0].Name;
          }
          this.expandAll(data);
          this.setState({
            treeData: data,
            Module,
            dynamicName,
            displayName
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  expandAll = async (treeData) => {
    let expandedKeys = await this.getAllKeys(treeData);
    this.setState({
      expandedKeys
    });
  };

  getAllKeys = (data) => {
    // This function makes an array of keys, this is specific for this example, you would have to adopt for your case. If your list is dynamic, also make sure that you call this function everytime data changes.
    const nestedKeys = data.map((node) => {
      let childKeys = [];
      if (node.children) {
        childKeys = this.getAllKeys(node.children);
      }
      return [childKeys, node.Id];
    });
    return flattenDeep(nestedKeys);
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys
    });
  };

  renderTreeNodes = (data) => {
    return (
      data &&
      Array.isArray(data) &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <Tree.TreeNode title={<>{item.Name}</>} key={item.Id} dataRef={item}>
              {this.renderTreeNodes(item.children, item)}
            </Tree.TreeNode>
          );
        }
        return (
          <Tree.TreeNode
            key={item.key}
            title={
              <div
                onClick={() => {
                  this.setState({ dynamicName: item.Code, displayName: item.Name });
                }}
              >
                <>{item.Name}</>
              </div>
            }
            dataRef={item}
          />
        );
      })
    );
  };
  render() {
    let { expandedKeys, treeData, dynamicName, displayName, Module } = this.state;
    return (
      <Fragment>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            background: '#1a3652',
            minHeight: window.innerHeight - 67,
            padding: '20px'
          }}
        >
          <div
            style={{
              width: '100%',
              flex: '25%',
              overflowY: 'auto',
              height: window.innerHeight - 140
            }}
          >
            <StyledTree
              onExpand={this.onExpand}
              expandedKeys={expandedKeys}
              showLine
              defaultExpandAll={true}
              switcherIcon={<DownOutlined />}
            >
              {this.renderTreeNodes(treeData)}
            </StyledTree>
          </div>

          <div
            style={{
              flex: '75%',
              paddingLeft: '17px',
              borderLeft: '1px dashed white'
            }}
          >
            {displayName ? (
              <div
                style={{
                  color: '#f8a000',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '10px 0px'
                }}
              >
                {displayName}
              </div>
            ) : null}
            <SinglePlot
              triggerPoint="dashboard"
              match={{
                params: {
                  hmiName: Module,
                  type: 'distinctByParent',
                  dynamicName
                }
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ModuleWiseDashboard);
