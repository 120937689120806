import React, { Fragment } from 'react';
import moment from 'moment';
import { Tag, Form, Modal, Input, DatePicker } from 'antd';
import cloneDeep from 'lodash.clonedeep';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ErrorComponent, NoRecordComponent } from '../../Utils';
import ReactEcharts from '../../Echarts/Echarts';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { trendLabelTranslation } from '../../../Utils/Adapter/translationAdapter';
import { getTranslationCache } from '../../../../../selectors/language';
// import { defaultExcelAdapter } from "./ExcelAdapters/defaultAdapter";
import localStorage from '../../../../../utils/localStorage';
import { arrayOfObjects } from '../../../Mock/MockJson';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';
import { getCurrentTimezone } from '../../../../../selectors/layout';
import { FEEDBACK } from '../../../../../commons/api';

class Line extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      loading: false,
      colors: this.props.graphprops.colors,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      reportName: '',
      errorInAPI: false,
      noDataInDB: false,
      TagNAme: '',
      DeviceCOde: ''
    };
  }

  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  myRef = React.createRef();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }
    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }
  handleOk = (e) => {
    e.preventDefault();
    this.myRef.current
      .validateFields()
      .then(async () => {
        const siteId = localStorage.get('currentSite');
        const { TagNAme, DeviceCOde } = this.state;
        const { currentName, currentValue } = this.state;

        let payload = {
          Feedback: this.state.Feedback,
          CreatedTime: currentName,
          Value: currentValue,
          ParameterCode: TagNAme,
          DeviceCode: DeviceCOde,
          SiteId: siteId
        };

        const accessToken = localStorage.get('accessToken');
        const deviceTypeObject = {
          method: 'POST',
          url: `${FEEDBACK.INSERT}?SiteId=${siteId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: payload
        };
        return axios(deviceTypeObject)
          .then(() => {
            this.setState({
              visible: false,
              Feedback: ''
            });
            this.myRef.current.resetFields();
            this.props.refereshData('close');
          })
          .catch(function () {
            return 'error';
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      Feedback: ''
    });
  };
  onChartClick = (cellData) => {
    let { visible } = this.state;
    let data = this.props.graphprops.currentDateData;
    data.map((item) => {
      let testing = item.Name;
      let delimiter = '$$$';
      const result = testing.split(delimiter);
      this.setState({ TagNAme: result[0], DeviceCOde: result[1] });
      return {};
    });

    if (cellData.seriesType === 'line') {
      let newObject = this.state.data.filter((predict) => {
        return predict.DateTime && cellData.name && cellData.seriesName === predict.Description;
      });
      if (newObject && Array.isArray(newObject)) {
        this.setState({
          visible: !visible,
          currentName: moment(cellData.name, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
          currentValue: cellData.value,
          // TagNAme:result[0],
          // DeviceCOde:result[1],
          dataSource: newObject && newObject[0] ? newObject[0].Action : [],
          selectedDate: cellData.DateTime
        });
      }
    }
  };
  onFeedBack = (e) => {
    this.setState({ Feedback: e.target.value });
    this.myRef.current.setFieldsValue('Feedback');
  };

  render() {
    // let theme = this.props.selectedThemeing;
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 10 }
    };
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData)
    };
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}

        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <span>
            <ReactEcharts
              option={this.state.option}
              onEvents={onEvents}
              notMerge={true}
              lazyUpdate={true}
              style={{ height: '80%', width: '100%' }}
              className="react_for_echarts"
            />

            {/* <div
                style={{
                  textAlign: 'center',
                  color: theme === 'lightTheme' ? 'black' : 'white',
                  marginTop: '10%'
                }}
              >
                <h2 style={{ color: 'black' }}>
                  <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                </h2>
              </div> */}
          </span>
        )}
        <Modal
          open={this.state.visible}
          title="Feedback"
          width={1000}
          height={1000}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form {...formItemLayout} onFinish={this.handleOk} ref={this.myRef} name="form">
            <Form.Item label="Date">
              <DatePicker
                readOnly
                value={moment(this.state.currentName) || moment()}
                style={{ width: '400px' }}
                showTime
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>
            <Form.Item label="Value">
              <Input value={this.state.currentValue || ''} readOnly></Input>
            </Form.Item>
            <Form.Item label="TagName">
              <Input value={this.state.TagNAme || ''} readOnly></Input>
            </Form.Item>
            <Form.Item label="DeviceCode">
              <Input value={this.state.DeviceCOde || ''} readOnly></Input>
            </Form.Item>
            <Form.Item label="Feedback" onChange={(e) => this.onFeedBack(e)}>
              <Input.TextArea value={this.state.Feedback} />
            </Form.Item>
          </Form>
        </Modal>
      </Fragment>
    );
  }

  // getReportNametoDownload(excelData) {
  //   let payload = {
  //     date: moment().format('YYYY-MM-DD'),
  //     time: moment().format('HH:mm:ss'),
  //     data: excelData || [],
  //     ...this.props.graphprops.excelObj
  //   };

  //   let siteId = localStorage.get('currentSite');
  //   let accessToken = localStorage.get('accessToken');
  //   let options = {
  //     method: 'POST',
  //     url: `/api/file/generateExcel?SiteId=${siteId}`,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${accessToken}`
  //     },
  //     data: JSON.stringify(payload)
  //   };
  //   return axios(options)
  //     .then(({ data }) => {
  //       return data;
  //     })
  //     .catch(() => {
  //       return '';
  //     });
  // }

  // downLoadExcel = () => {
  //   const { reportName } = this.state;
  //   let url = `${excelDownloadUrl}/${reportName}`;
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", reportName); //or any other extension
  //   document.body.appendChild(link);
  //   link.click();
  // };

  getData = async (props) => {
    this.requestCheck = false;
    let theme = this.props.selectedThemeing;

    let json = [];
    let { graphprops } = this.props;
    this.setState({ loading: true });
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = arrayOfObjects;

      this.requestCheck = true;
      this.setState({ loading: false });
    } else {
      if (
        props.graphprops.graph === 'rangeLine' ||
        (props.graphprops.graph === 'LineTableCounter' &&
          props.graphprops.currentDateData !== undefined)
      ) {
        json = this.props.graphprops.currentDateData;
      } else {
        if (
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'datePicker' ||
              graphprops.GraphLevelSelection === 'datePicker') &&
            this.props.selectionDate) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'monthPicker' ||
              graphprops.GraphLevelSelection === 'monthPicker') &&
            this.props.selectionMonth) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'weakPicker' ||
              graphprops.GraphLevelSelection === 'weakPicker') &&
            this.props.selectionWeek) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'shiftPicker' ||
              graphprops.GraphLevelSelection === 'shiftPicker') &&
            this.props.shift) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
              graphprops.GraphLevelSelection === 'dateRangePicker') &&
            this.props.selectionDateRange) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
              graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
            this.props.dayMonthYear) ||
          (graphprops && graphprops.dashboardType === 'dynamic') ||
          (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
          (graphprops &&
            graphprops.dashboardType === 'hierarchy' &&
            this.props.dropdownSelection) ||
          (graphprops && this.props.dashboardPayload) ||
          (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
        ) {
          json = await getChartDataWithSelection({
            graphprops,
            DeviceCode: this.props.DeviceCode,
            selectionDate: this.props.selectionDate,
            selectionMonth: this.props.selectionMonth,
            selectionWeek: this.props.selectionWeek,
            shift: this.props.shift,
            selectionDateRange: this.props.selectionDateRange,
            dayMonthYear: this.props.dayMonthYear,
            abortController: this.abortController,
            dropdownSelection: this.props.dropdownSelection,
            dashboardPayload: this.props.dashboardPayload,
            AssetCode: this.props.AssetCode,
            selectionDateLive: this.props.selectionDateLive,
            currentTimeZone: this.props.currentTimeZone
          });
          this.requestCheck = true;
          this.setState({ loading: false, errorInAPI: false, noDataInDB: false });
        } else {
          json = await await getChartDataWithoutSelection({
            graphprops,
            abortController: this.abortController,
            dropdownSelection: this.props.dropdownSelection,
            DeviceCode: this.props.DeviceCode,
            TimeZone: this.props.currentTimeZone
          });
          this.requestCheck = true;
          this.setState({ loading: false });
        }
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0) ||
      (graphprops.enableMock && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      // let reportName = await this.getReportNametoDownload(
      //   // defaultExcelAdapter(json)
      // );
      if (json && Array.isArray(json)) {
        let dates = [];
        let values = [];
        let name = '';
        let tagnames = [];
        let seriesarray = [];
        let legendsdata = [];
        json.map((val) => {
          if (val.Name !== name) {
            if (tagnames.indexOf(val.Name) === -1) {
              tagnames.push(val.Name);
              name = val.Name;
              legendsdata.push({
                name: val.Description,
                icon: 'roundRect',
                textStyle: {
                  color: theme === 'lightTheme' ? 'black' : 'white',
                  fontSize: 14,
                  fontWeight: 'bold'
                }
              });
            }
          }
          return {};
        });
        let yAxisData = [];
        if (
          this.props.graphprops &&
          this.props.graphprops[this.props.graphprops.graph] &&
          this.props.graphprops[this.props.graphprops.graph].YAxis
        ) {
          yAxisData = JSON.parse(this.props.graphprops[this.props.graphprops.graph].YAxis);
        } else {
          yAxisData = [];
        }
        let yaxis = yAxisData.map((value, index) => {
          let axis = {};
          axis.splitLine = {
            show: true,
            lineStyle: {
              color: '#504c4c'
            }
            // axisLabel: { fontSize: 15 },
          };
          axis.triggerEvent = true;
          axis.axisLine = {
            show: true,
            lineStyle: {
              //  color: "white"
              color:
                this.props.graphprops &&
                this.props.graphprops[this.props.graphprops.graph] &&
                this.props.graphprops[this.props.graphprops.graph].colors &&
                this.props.graphprops[this.props.graphprops.graph].colors[index] !== undefined
                  ? value && value.tags && value.tags.length > 1
                    ? 'white'
                    : this.props.graphprops &&
                      this.props.graphprops[this.props.graphprops.graph] &&
                      this.props.graphprops[this.props.graphprops.graph].colors &&
                      this.props.graphprops[this.props.graphprops.graph].colors[index]
                  : 'white'
            }
            // axisLabel: { fontSize: 15 },
          };
          axis.max = value.max;
          axis.min = value.min;
          axis.offset = value.offset;
          axis.interval = value.interval;
          axis.position = value.position;
          axis.name = value.name;
          axis.tags = value.tags;
          return axis;
        });

        yaxis.unshift({
          splitLine: {
            show: true,
            lineStyle: {
              color: '#504c4c'
            }
          },
          triggerEvent: true,
          axisLine: {
            show: true,
            lineStyle: {
              color:
                this.props.graphprops &&
                this.props.graphprops.line &&
                this.props.graphprops.line.Linecolor &&
                this.props.graphprops.line.Linecolor.length > 0
                  ? this.props.graphprops.line.Linecolor[0]
                  : 'white'
            }
          },
          axisLabel: { fontSize: 15 },
          tags: []
        });

        tagnames.map((tagval, tagindex) => {
          values = [];
          json.map((jsonval) => {
            if (tagnames[0] === jsonval.Name && tagindex === 0) {
              //  let date =  (graphprops &&
              //     graphprops.type === 'live' &&
              //     props.graphprops.graph === 'LineTableCounter'
              //       ? moment.utc(jsonval.DateTime).tz(this.props.currentTimeZone).format('DD/MM/YYYY HH:mm:ss')
              //       : moment(jsonval.DateTime).format('DD/MM/YYYY HH:mm:ss')) ||
              //     (graphprops && graphprops.type === 'live' && props.graphprops.graph === 'line'
              //       ? moment
              //           .utc(jsonval.DateTime)
              //           .tz(this.props.currentTimeZone)
              //           .format('DD/MM/YYYY HH:mm:ss')
              //       : moment(jsonval.DateTime).format('DD/MM/YYYY HH:mm:ss'));

              // let date =
              //   props.graphprops &&
              //     props.graphprops.graph &&
              //     props.graphprops.graph === 'line' &&
              //     props.graphprops['line'] &&
              //     props.graphprops['line'].displayType === 'DD/MM/YYYY HH:mm'
              //     ? (graphprops && graphprops.type === 'live' && props.graphprops.graph === 'LineTableCounter'
              //       ? moment.utc(jsonval.DateTime).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm')
              //       : moment(jsonval.DateTime).format('YYYY-MM-DD HH:mm'))
              //       ||
              //     (graphprops && graphprops.type === 'live' && props.graphprops.graph === 'line'
              //       ? moment.utc(jsonval.DateTime).tz(this.props.currentTimeZone).format('DD/MM/YYYY HH:mm')
              //       : moment(jsonval.DateTime).format('DD/MM/YYYY HH:mm'))
              //     :
              //     (graphprops && graphprops.type === 'live' && props.graphprops.graph === 'LineTableCounter'
              //       ? moment.utc(jsonval.DateTime).tz(this.props.currentTimeZone).format('DD/MM/YYYY HH:mm:ss')
              //       : moment(jsonval.DateTime).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm:ss'))
              //       ||
              //     (graphprops && graphprops.type === 'live' && props.graphprops.graph === 'line'
              //       ? moment.utc(jsonval.DateTime).tz(this.props.currentTimeZone).format('DD/MM/YYYY HH:mm:ss')
              //       : moment(jsonval.DateTime).format('DD/MM/YYYY HH:mm:ss'));

              // var gmtDateTime = moment.utc(date, 'YYYY-MM-DD HH;mm:ss').tz(this.props.currentTimeZone);
              // let dateVal = moment(gmtDateTime._d).format('YYYY-MM-DD HH:mm:ss');
              let date = '';
              if (
                graphprops &&
                graphprops.line &&
                graphprops.line.XAxisType &&
                graphprops.line.XAxisType === 'string'
              ) {
                date = jsonval.DateTime;
              } else {
                let format =
                  graphprops.line && graphprops.line.xAxisDateFormat
                    ? graphprops.line.xAxisDateFormat
                    : 'YYYY-MM-DD HH:mm:ss';

                if (
                  this.props.graphprops &&
                  this.props.graphprops.selection &&
                  this.props.graphprops.selection === 'dateMonthYearPicker'
                ) {
                  if (this.props.dayMonthYear && this.props.dayMonthYear.selectionType === 'day') {
                    date =
                      graphprops && graphprops.type === 'summary'
                        ? moment.utc(jsonval.DateTime).format('HH:mm')
                        : moment
                            .utc(jsonval.DateTime)
                            .tz(this.props.currentTimeZone)
                            .format('HH:mm');
                  } else if (
                    this.props.dayMonthYear &&
                    this.props.dayMonthYear.selectionType === 'month'
                  ) {
                    date =
                      graphprops && graphprops.type === 'summary'
                        ? moment.utc(jsonval.DateTime).format('DD/MM')
                        : moment
                            .utc(jsonval.DateTime)
                            .tz(this.props.currentTimeZone)
                            .format('DD/MM');
                  } else if (
                    this.props.dayMonthYear &&
                    this.props.dayMonthYear.selectionType === 'year'
                  ) {
                    date =
                      graphprops && graphprops.type === 'summary'
                        ? moment.utc(jsonval.DateTime).format('MMMM')
                        : moment
                            .utc(jsonval.DateTime)
                            .tz(this.props.currentTimeZone)
                            .format('MMMM');
                  }
                } else {
                  if (graphprops && graphprops.type === 'summary') {
                    date = moment.utc(jsonval.DateTime).format(format);
                  } else if (graphprops && graphprops.type === 'live') {
                    date = jsonval.DateTime;
                  } else {
                    date = moment
                      .utc(jsonval.DateTime)
                      .tz(this.props.currentTimeZone)
                      .format(format);
                  }
                  // date =
                  //   graphprops && graphprops.type === 'summary'
                  //     ? moment.utc(jsonval.DateTime).format(format)
                  //     : moment.utc(jsonval.DateTime).tz(this.props.currentTimeZone).format(format);
                }
              }
              dates.push(date);
            }
            if (tagval === jsonval.Name) {
              values.push(jsonval.Value);
            }
            return {};
          });

          if (yaxis.length > 1) {
            yaxis.map((axis, axisindex) => {
              if (axis.tags.indexOf(tagval) !== -1) {
                seriesarray.push({
                  name: legendsdata[tagindex]['name'],
                  data: values,
                  yAxisIndex: axisindex,
                  type: 'line',
                  markPoint: {
                    data:
                      this.props.graphprops &&
                      this.props.graphprops[graphprops.graph] &&
                      this.props.graphprops[graphprops.graph].minMax
                        ? [
                            { type: 'max', name: 'Max' },
                            { type: 'min', name: 'Min' }
                          ]
                        : null
                  }
                });
              }
              return {};
            });
          } else {
            seriesarray.push({
              name: legendsdata[tagindex]['name'],
              data: values,
              type: 'line',
              markPoint: {
                data:
                  this.props.graphprops &&
                  this.props.graphprops[graphprops.graph] &&
                  this.props.graphprops[graphprops.graph].minMax
                    ? [
                        { type: 'max', name: 'Max' },
                        { type: 'min', name: 'Min' }
                      ]
                    : null
              }
            });
          }
          return {};
        });

        const option = cloneDeep(this.getOption());
        if (
          this.props.graphprops &&
          this.props.graphprops.line &&
          this.props.graphprops.line.colors &&
          this.props.graphprops.line.colors.length > 0
        ) {
          option.color = this.props.graphprops.line.colors;
        }
        let series = await trendLabelTranslation(seriesarray, this.props.translationCache);
        option.xAxis.data = [...dates];
        option.yAxis = [...yaxis];
        option.series = [...series];
        option.legend = [...legendsdata];
        this.setState({
          option,
          refreshDateTime: moment()
        });
      }
    }

    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      legend: {
        textStyle: { color: theme === 'lightTheme' ? 'black' : 'white' },
        data: [
          {
            name: 'test1',
            icon: 'roundRect',
            textStyle: {
              // fontSize: 15,
            }
          }
        ]
      },
      tooltip: {
        trigger: 'axis',
        textStyle: {
          // fontSize: 15,
        }
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            name: this.props.graphprops.title,
            backgroundColor: theme === 'lightTheme' ? 'white' : 'black'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: this.props.graphprops,
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
          // fontSize: 15,
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        }
        // min: min,
        // max: max,
      },
      dataZoom: [
        {
          type: 'slider',
          show: true,
          start: 0,
          end: 100,

          backgroundColor: '#9a9191',
          textStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        {
          type: 'inside',
          start: 0,
          end: 100
        }
      ]
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(Line);
