import styled from 'styled-components';
import { Button, Collapse, Form, Table, Tabs, Checkbox, Tree, Descriptions, Modal } from 'antd';

export const StyledComponent = styled.div`
  background: ${(props) => props.theme.backgroundColor} !important;
  padding: 10px;
`;

export const StyledFormType = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${(props) => props.theme.title.fontSize};
  color: ${(props) => props.theme.title.color};
  font-weight: ${(props) => props.theme.title.fontWeight};
`;

export const CardTitle = styled.div`
  padding: 0px 0px 6px;
  font-size: 18px;
  font-weight: bolder;
  color: ${(props) => props.theme.list.card.titleColor};
`;

export const CardFooterActions = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px;
  font-size: 18px;
`;

export const StyledTable = styled(Table)`
  caption-side: top;
  color: ${(props) => props.theme.list.table.column.color} !important;
  width: 100%;
  caption-side: bottom;
  td,
  th {
    border: none;
  }
  td {
    padding: 11px 10px;
  }
  .ant-table-thead > tr > th{
    font-weight:bold !important
    font-size: 1em !important;
    color:white !important;
    background: ${(props) => props.theme.list.table.column.backgroundColor} ;
  }
  text[style] {
    font-size: 1em !important;
    color: ${(props) => props.theme.list.table.column.color} !important;
 }
  tbody tr {
    :nth-of-type(odd) {
      background-color:${(props) => props.theme.list.table.column.odd} !important;
    }
    :hover {
      background-color:${(props) => props.theme.list.table.column.hover} !important; 
    }
  }
  tbody tr {
    :nth-of-type(even) {
      background-color:${(props) => props.theme.list.table.column.even} !important;
    }
    :hover {
      background-color:${(props) => props.theme.list.table.column.hover} !important; 
    }
  }
  thead > tr {
    color: ${(props) => props.theme.list.table.column.color} !important;
    background-color: #262626 !important;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

export const StyledForm = styled(Form)`
  .ant-divider-horizontal.ant-divider-with-text {
    display: flex;
    margin: 16px 0;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-top: 0;
  }

  .ant-form-item-label > label {
    color: ${(props) => props.theme.form.formLabel} !important;
    font-weight: revert;
    font-size: ${(props) => props.theme.form.formLabelSize};
    font-weight: bold;
  }

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.66);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }

  .ant-tabs-content-holder {
    border-radius: 0 5px 5px 5px;
  }
`;

export const StyledSubForm = styled.div`
  background: ${(props) => props.theme.form.subSectionBackgroundColor};
  padding: 20px;
  min-height: 300px;
`;

export const StyledButton = styled(Button)`
  background: ${(props) => props.theme.form.buttonBackgroundColor} !important;
  color: ${(props) => props.theme.form.buttonColor} !important;
  display: flex;
  justify-content: right;
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
  }

  .ant-tabs-tab-remove {
    flex: none;
    margin-right: -4px;
    margin-left: 8px;
    font-size: 12px;
    background: #1a3b5c;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    color: white !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.form.tabs.color};
    text-shadow: 0 0 0.25px currentcolor;
  }
  .checkTab {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0.5;
    cursor: not-allowed !important;
    pointer-events: none !important;
  }
`;

export const StyledCollapse = styled(Collapse)`
   {
    background-color: ${(props) => props.theme.form.collapse.backgroundColor} !important;
    color: orange !important;

    .ant-collapse-content-box {
      background-color: ${(props) => props.theme.form.collapse.backgroundColor} !important;
    }
    .ant-collapse-item > .ant-collapse-header {
      color: ${(props) => props.theme.form.collapse.color} !important;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      padding: 12px 16px;
      color: #fff;
      line-height: 1.5715;
      cursor: pointer;
      transition: all 0.3s, visibility 0s;
    }
  }
`;

export const StyledNoBorderSelect = styled.div`
  padding: 5px;
  .ant-list {
    color: rgba(224, 203, 203, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
  }

  .ant-list-item-meta-title > a {
    color: #fff !important;
    font-size: 17px;
  }

  .ant-list-item {
    color: #fff !important;
  }

  .ant-list-split .ant-list-item {
    border-bottom: 10px solid #1a3652;
    background: #1a4c7e !important;
    overflow: auto;
    max-height: 150px !important;
  }

  .ant-list-item-meta-description {
    color: rgba(236, 232, 232, 0.45) !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #1a3652;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: white;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 31px !important;
  }

  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none !important;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-select-selection-placeholder {
    color: #717e8b !important;
  }
`;

export const StyledSubHeading = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: orange;
  font-weight: bold;
  // padding: 20px;
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox .ant-checkbox-inner {
    border-color: rgb(217, 142, 4);
    background-color: transparent;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: rgb(217, 142, 4);
    background-color: white;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #d98e04 !important;
    border-color: #d98e04 !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #f55e00 !important;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: white !important;
  }
  .ant-checkbox-disabled + span {
    color: #d98e04 !important;
    cursor: not-allowed;
  }
`;

export const StyledTabsContent = styled.div`
  margin-top: 20px;
  color: black;
  fontweight: bold;
`;

export const StyledTree = styled(Tree)`
  background: #1a3652 !important;
  color: white !important;
`;

export const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-item-label {
    color: #d98e04;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5715;
    text-align: start;
  }

  .ant-descriptions-item-content {
    display: table-cell;
    flex: 1 1;
    color: white;
    font-size: 14px;
    line-height: 1.5715;
    word-break: break-word;
    overflow-wrap: break-word;
  }
`;

export const StyledModal = styled(Modal)`
  width: 55% !important;

  .ant-modal-content {
    border-radius: 20px;
    background: #1a3652;
  }

  .ant-modal-header {
    background: #1a3652;
  }
  .ant-modal-title {
    color: #ffffff !important;
  }
  .ant-form-item-required {
    color: #ffffff !important;
  }

  .ant-modal-close {
    color: white !important;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
  }
`;

export const StyledBox = styled.div`
  margin-top: 15px;
  height: 100%;
  width: 100%;
  border: 2px solid grey;
`;
export const StyledBoxLabel = styled.div`
  color: #d98e04
  margin-top: -14px;
  position: absolute;
  left: 60px;
  height: 4%;
  width: 180px;
  text-align: center;
  background-color: #1a3652;
  font-size: 16px;
  font-weight: bold;
`;

export const lightTheme = {
  text: 'black',
  even: '#7a8793',
  odd: '#bdc0c2',
  hover: '#dedede'
};

export const StyledDashboard = styled.div`
  background: rgb(26, 54, 82);
  padding: 30px;
`;
