//Required Module imports//
import React, { Component } from 'react';
import axios from 'axios';
import localStorage from '../../../../../utils/localStorage';
import {
  Form,
  message,
  Select,
  Button,
  Space,
  //  Popconfirm,
  Input
} from 'antd';
import {
  // QuestionCircleOutlined,
  // DownOutlined,
  SearchOutlined
} from '@ant-design/icons';
//Required Component imports//
import themeSettings from '../../../Utils/themeSettings.json';
import { decode as base64_decode } from 'base-64';
import history from '../../../../../commons/history';
import Highlighter from 'react-highlight-words';
import {
  // getDeviceList,
  getDeviceTypeData,
  _getUOMList,
  tagCreateAPI,
  deleteTags,
  getTagsByEquipmentTypeId,
  getDeviceTypeById,
  tagUpdateAPI
} from '../../../Utils/FormCalls';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { constant } from '../../../Utils/constants';
//css//
import TableSelect from '../../../Widgets/TableSelect';
import {
  StyledComponent,
  StyledForm,
  StyledButton,
  StyledSubHeading,
  StyledModal
} from '../../../CSS/style';
import { getTranslationCache } from '../../../../../selectors/language';
import translation from '../../../Utils/translation';
import InputComponent from '../../../Widgets/InputComponent';
import { API_DEVICE, API_HIERARCHYMODULE } from '../../../../../commons/api';

const { Option } = Select;
const fileData = new FormData();

class EquipmentTagDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitesList: [],
      deviceList: [],
      visible: false,
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      hierarchyList: [],
      datasource:
        props.formObject && props.formObject.Specifications
          ? props.formObject.Specifications
          : [{}],
      // type:"Tools"
      translationCache: props.translationCache || [],
      UOMList: []
    };
  }
  TagsRef = React.createRef();

  componentDidMount = async () => {
    this._getDataById();
    let { formObject } = this.state;
    try {
      // this.setState({ deviceList });
      // let selectedDevice = deviceList.filter((item) => {
      //   return item.Id === formObject.Id;
      // });
      formObject = {
        ...formObject
        // equipmentName: selectedDevice && selectedDevice[0] && selectedDevice[0]['DeviceCode']
      };
      this.setState({ formObject });
      if (this.TagsRef.current) {
        this.TagsRef.current.setFieldsValue(formObject);
      }
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let UOMList = await _getUOMList();
      this.setState({ UOMList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      if (this.TagsRef.current) this.TagsRef.current.setFieldsValue(this.props.formObject);
    }

    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject,
        datasource:
          this.props.formObject && this.props.formObject.Specifications
            ? this.props.formObject.Specifications
            : [{}]
      });
    }
  };
  onDrawerCancel = (e) => {
    let formObject = this.TagsRef.current.getFieldsValue();
    if (formObject.Equipment === undefined && e.target.tagName === 'DIV') {
      e.preventDefault();
      return message.error(`Please Click On Cancel`);
    } else {
      this.setState({
        visible: false
      });
    }
  };
  onModalOk = () => {
    this.setState({
      visible: false
    });
  };
  handleChange = async (value, key) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      [key]: value
    };
    if (this.TagsRef.current) this.TagsRef.current.setFieldsValue(formObject);

    this.setState({ [key]: value, formObject });
  };

  fileupload = async (values, key, fileDataObject) => {
    let formObject = this.TagsRef.current.getFieldsValue();

    if (formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    if (this.TagsRef.current) this.TagsRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };
  removeTags = async (id, remove) => {
    let formObject = this.TagsRef.current.getFieldsValue();
    let data = formObject && formObject['Tags'][id];
    await deleteTags(data.Id);
    remove(id);
  };
  onSubmit = async (i) => {
    let formObject = this.TagsRef.current.getFieldsValue();
    let payload = {
      ...(formObject && formObject['Tags'][i]),
      DeviceType: formObject['Code']
    };
    let statusResponse =
      payload && payload.Id ? await tagUpdateAPI(payload) : await tagCreateAPI(payload);
    if (statusResponse && statusResponse.message) {
      message.success(statusResponse.message);
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };

  onReset = () => {
    if (this.TagsRef.current) this.TagsRef.current.resetFields();
  };

  // onFinishFailed = () => {
  //   message.error('Please enter mandatory fields');
  // };
  _getDataById = async () => {
    let formObject = {};
    let path = history.location.pathname.split('/rubus/AMM/EquipmentTagDetails/');
    let formId = base64_decode(path[1]);
    if (formId !== 'new') {
      let deviceType = await getDeviceTypeById(formId);
      let Tags = await getTagsByEquipmentTypeId(deviceType[0].Code);

      // let tableRowData = await getDeviceTypeData(base64_decode(path[1]));
      formObject = formObject && formObject !== undefined ? formObject : [];

      formObject = {
        ...(deviceType && Array.isArray(deviceType) && deviceType[0] ? deviceType[0] : {}),
        Tags: Tags && Array.isArray(Tags) ? Tags : []
        //   equipmentName: base64_decode(path[1]),
        //   ...(tableRowData && tableRowData[0] ? tableRowData[0] : {}),
        //   Tags: [...(formObject && Array.isArray(formObject) ? formObject : [])]
      };
    }
    if (this.TagsRef.current) {
      this.TagsRef.current.setFieldsValue(formObject);
    }
    this.setState({
      formObject
    });
  };
  saveTableViewData = (list) => {
    let { formObject } = this.state;
    this.setState({
      datasource: list
    });
    formObject = {
      ...formObject,
      Specifications: list
    };
    this.props.downTimeData(formObject);
  };

  getHierarchyLevels = () => {
    let { hierarchyList } = this.state;
    let payload = {
      HierarchyCode:
        hierarchyList &&
        Array.isArray(hierarchyList) &&
        hierarchyList[0] &&
        hierarchyList[0].HierarchyCode
          ? hierarchyList[0].HierarchyCode
          : null,
      Type: 'Device'
    };
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${API_HIERARCHYMODULE.GetHierarchyLevels}?SiteId=${siteId}&HierarchyCode=${payload.HierarchyCode}&Type=${payload.Type}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({
            treeData: data,
            visible: true,
            formObject: this.TagsRef.current.getFieldsValue()
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  parentNode = (parent) => {
    let formObject = this.TagsRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Location: parent.Code
    };
    this.TagsRef.current.setFieldsValue(formObject);
    this.setState({
      visible: false,
      formObject
    });
  };

  hierarchyDropdownChange = (value) => {
    let formObject = this.TagsRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Equipment: value.Code,
      EquipmentDescription: value.Name
    };
    this.TagsRef.current.setFieldsValue(formObject);
    this.setState({
      visible: false,
      formObject
    });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  onChangeEquipment = async (value, key) => {
    let { deviceList } = this.state;
    let formObject = this.TagsRef.current.getFieldsValue();
    let selectedDevice = deviceList && deviceList.filter((item) => item.DeviceCode === value);
    try {
      let deviceTypeData = await getDeviceTypeData(selectedDevice[0]['DeviceType']);

      formObject = {
        ...formObject,
        [key]: value,
        Name: deviceTypeData[0].Name,
        Code: deviceTypeData[0].Code,
        Description: deviceTypeData[0].Description
      };
      this.setState({
        formObject,
        deviceTypeData
      });
      this.TagsRef.current.setFieldsValue(formObject);
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${API_DEVICE.LIST}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.TagsRef.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, constant.search)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, constant.search)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, constant.Reset)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  TableDropdownChange = async (value, modal) => {
    let formObject = this.TagsRef.current.getFieldsValue();
    try {
      let deviceTypeData = await getDeviceTypeData(value['DeviceType']);
      formObject = {
        ...formObject,
        equipmentName: value.Name,
        Name: deviceTypeData[0].Name,
        Code: deviceTypeData[0].Code,
        Description: deviceTypeData[0].Description
      };
      this.TagsRef.current.setFieldsValue(formObject);
      this.setState({
        formObject,
        [modal]: false,
        deviceTypeData
      });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  render() {
    let { formObject, UOMList, translationCache } = this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '20px 15px', minHeight: window.innerHeight - 135 }}
        >
          <StyledForm
            name="EquipmentTags"
            theme={themeSettings}
            ref={this.TagsRef}
            className="collapse"
            onFinish={this.onFormsubmit}
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            initialValues={formObject}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            validateMessages={validateMessages}
          >
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item name="Id"></Form.Item>
              <StyledModal
                open={this.state.visible}
                width={800}
                closable
                title={translation(translationCache, constant.selectEquipment)}
                onOk={this.onModalOk}
                onCancel={this.onDrawerCancel}
              >
                <TableSelect
                  data={this.state.tableData}
                  translationCache={this.props.translationCache}
                  key="Equipment"
                  columns={[
                    {
                      title: translation(translationCache, 'Equipment'),
                      dataIndex: 'DeviceCode',
                      key: 'DeviceCode',
                      ...this.getColumnSearchProps('DeviceCode')
                    },
                    {
                      title: translation(translationCache, 'Equipment Description'),
                      dataIndex: 'Name',
                      key: 'Name',
                      ...this.getColumnSearchProps('Name')
                    }
                  ]}
                  handleDropdownChange={(e) => this.TableDropdownChange(e, 'visible')}
                />
              </StyledModal>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                label={translation(translationCache, constant.equipmentTypeName)}
                name="Name"
              >
                <InputComponent
                  style={{ width: '70%' }}
                  disabled={formObject && formObject.Id ? true : false}
                  placeholder={translation(translationCache, 'Input equipmentTypeName')}
                />
              </Form.Item>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                label={translation(translationCache, constant.equipmentTypeCode)}
                name="Code"
              >
                <InputComponent
                  disabled={formObject && formObject.Id ? true : false}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Input equipmentTypeCode')}
                />
              </Form.Item>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                name="Description"
                label={translation(translationCache, constant.equipmentTypeDescription)}
              >
                <InputComponent
                  disabled={formObject && formObject.Id ? true : false}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'EquipmentType Description')}
                />
              </Form.Item>
              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                }}
              ></Form.Item>
            </div>

            <StyledSubHeading>
              {translation(translationCache, constant.EQUIPMENT_TAGS)}
            </StyledSubHeading>
            <div style={{ margin: '20px 0px' }}>
              <Form.Item>
                <Form.List name="Tags">
                  {/* {(fields, { add, remove }) => ( */}
                  {(fields, { add }) => (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '40px' }}>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: 'flex'
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, 'Name']}
                              rules={[
                                {
                                  required: true,
                                  message: translation(translationCache, 'Name is required!')
                                }
                              ]}
                            >
                              <InputComponent placeholder={translation(translationCache, 'Name')} />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'Code']}
                              rules={[
                                {
                                  required: true,
                                  message: translation(translationCache, 'Code is required!')
                                }
                              ]}
                            >
                              <InputComponent placeholder={translation(translationCache, 'Code')} />
                            </Form.Item>
                            {/* <Form.Item
                              {...restField}
                              name={[name, 'Description']}
                              rules={[
                                {
                                  required: true,
                                  message: translation(translationCache, 'Description is required!')
                                }
                              ]}
                            >
                              <InputComponent
                                placeholder={translation(translationCache, 'Description')}
                              />
                            </Form.Item> */}
                            <Form.Item
                              {...restField}
                              name={[name, 'UOM']}
                              rules={[
                                {
                                  required: true,
                                  message: translation(translationCache, 'UOM is required!')
                                }
                              ]}
                            >
                              <Select
                                showArrow
                                showSearch
                                openOnFocus
                                style={{ width: 160 }}
                                // onChange={e => this.handleFieldChange(e, "UOM", index)}
                                placeholder={translation(translationCache, 'UOM')}
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {UOMList &&
                                  Array.isArray(UOMList) &&
                                  UOMList.map((uom, index) => {
                                    return (
                                      <Option key={index} value={uom.UOM}>
                                        {uom.UOM}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'DataType']}
                              rules={[
                                {
                                  required: true,
                                  message: translation(translationCache, 'DataType is required!')
                                }
                              ]}
                            >
                              <Select
                                openOnFocus
                                style={{ width: 160 }}
                                // onChange={e => this.handleFieldChange(e, "DataType", index)}
                                placeholder={translation(translationCache, 'DataType')}
                              >
                                <Option value="float">
                                  {translation(translationCache, 'float')}
                                </Option>
                                <Option value="boolean">
                                  {translation(translationCache, 'boolean')}
                                </Option>
                              </Select>
                            </Form.Item>
                            <Form.Item>
                              <Button
                                type="primary"
                                // className="SubmitButton"
                                // htmlType="submit"
                                onClick={() => this.onSubmit(name)}
                              >
                                {translation(translationCache, constant.submit)}
                              </Button>
                            </Form.Item>
                          </Space>
                        ))}
                      </div>
                      <Form.Item>
                        <StyledButton
                          theme={themeSettings}
                          style={{ width: '150px', marginLeft: '40px' }}
                          onClick={() => add()}
                          block
                        >
                          {translation(this.props.translationCache, 'Add Parameters')}
                        </StyledButton>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </div>
          </StyledForm>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(EquipmentTagDetails);
