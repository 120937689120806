import React, { Component } from 'react';
import axios from 'axios';
import { Tree } from 'antd';

import localStorage from '../../../../../utils/localStorage';
import { DownloadOutlined } from '@ant-design/icons';

const { TreeNode } = Tree;

class HierarchyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      value: [],
      hierarchy: this.getMenuHierarchyStructure(),
      deviceList: [],
      autoExpandParent: true.valueOf,
      expandedKeys: []
    };
  }

  renameProperty = (obj, oldName1, newName1, oldName2, newName2) => {
    if (obj && obj.children) {
      let children = obj.children.map((objChildren) => {
        return this.renameProperty(objChildren, oldName1, newName1, oldName2, newName2);
      });
      obj['children'] = children;
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    } else {
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    }
  };

  _getDevicesList = async (payload) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let url = '';
    if (payload && payload.method === 'GET' && payload.GETParams) {
      url = `${payload.url}?SiteId=${siteId}${payload.GETParams}`;
    } else {
      url = `${payload.url}?SiteId=${siteId}`;
    }
    let payloadData = payload && payload.payload ? payload.payload : '';
    const ObjectHeader = {
      method: payload.method,
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payloadData || {}
    };
    return await axios(ObjectHeader)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };

  getMenuHierarchyStructure = async () => {
    let { hierarchydata } = this.props;
    // let payload = dashboards && dashboards.json &&
    //   dashboards.json[0] &&
    //   dashboards.json[0].apiPayload
    //   ? dashboards.json[0].apiPayload
    //   : {}
    // let hierarchydata1 = await this._getDevicesList(payload, 'hierachy');
    // let final = hierarchydata1.map((userHierarchy) => {
    //   return this.renameProperty(userHierarchy, 'label', 'title', 'value', 'key');
    // });
    return hierarchydata;
  };

  getData = (selectedKey) => {
    this.onSelect([selectedKey.key]);
  };

  renderTreeNodes = (data) => {
    return (
      data &&
      Array.isArray(data) &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <TreeNode
              title={`${this.setTranslation(item.title)}`}
              key={item.key}
              dataRef={item.key}
            >
              {this.renderTreeNodes(item.children)}
            </TreeNode>
          );
        }
        return (
          <TreeNode
            key={item.key}
            title={
              <div
                style={{ fontWeight: item.Type === 'Device' ? 'bold' : 'none' }}
                onClick={() => this.getData(item)}
              >
                {`${this.setTranslation(item.title)}`}
              </div>
            }
            dataRef={item.key}
          />
        );
      })
    );
  };

  setTranslation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onSelect = (selectedKeys) => {
    let { hierarchydata, deviceList } = this.props;
    this.props.handleDropdownChange(selectedKeys, hierarchydata, deviceList);
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  render() {
    let { value, hierarchydata } = this.props;
    return (
      <div style={{ height: '70%', marginTop: '10px' }}>
        <Tree
          showLine
          defaultExpandAll={true}
          switcherIcon={<DownloadOutlined />}
          onExpand={this.onExpand}
          selectedKeys={value ? [value] : ''}
        >
          {this.renderTreeNodes(hierarchydata)}
        </Tree>
      </div>
    );
  }
}

export default HierarchyModal;
