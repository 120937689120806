import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import DeviceType from './DeviceType';
import {
  getDeviceTypeList,
  saveDeviceParamters,
  saveDeviceType,
  updateDeviceType,
  deleteDeviceParameters,
  deleteDeviceType
} from '../../../modules/deviceType/deviceType.duck';
import { getDeviceTypeListSelector } from '../../../selectors/deviceType';

const mapStateToProps = createStructuredSelector({
  deviceTypeList: getDeviceTypeListSelector()
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getDeviceTypeList,
        saveDeviceType,
        updateDeviceType,
        deleteDeviceType,
        saveDeviceParamters,
        deleteDeviceParameters
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceType);
