import React from 'react';
// import axios from 'axios';
import moment from 'moment';
import { getTranslationCache } from '../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import themeSettings from '../../AMM/Utils/themeSettings.json';
import { StyledComponent, StyledTable } from './CSS/style';
import Highlighter from 'react-highlight-words';
import { getList, deleteById } from './Utils/FormCalls';
import localStorage from '../../../utils/localStorage';
import { API_BACKUP } from '../../../commons/api';
import { getUserProfile } from '../../../selectors/layout';
import {
  Button,
  Input,
  message,
  // Select,
  // Tabs,
  // Tooltip,
  Modal
} from 'antd';
// import history from '../../../commons/history';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
const APIList = {
  getList: `${API_BACKUP.PurgeGet}`,
  deleteData: `${API_BACKUP.PurgeDelete}`
};
class PurgeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      dataSource: [],
      deleteModal: false,
      translationCache: props.translationCache || {},
      userProfile: props && props.userProfile ? props.userProfile : {}
    };
  }
  componentDidMount = async () => {
    let response = await getList(APIList.getList);
    this.setState({
      dataSource: response && Array.isArray(response) ? response : []
    });
  };
  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      this.props.userProfile !== prevProps.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        {/* <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
        >
          Filter
        </Button> */}
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: '' });
  };

  _deleteById = async () => {
    let { selectedRecord } = this.state;
    console.log('selectedRecord', selectedRecord);
    const siteId = localStorage.get('currentSite');
    let payload = {
      url: `${APIList.deleteData}?Id=${selectedRecord.TaskId}&SiteId=${siteId}&TableName=${selectedRecord.TableName}`,
      method: 'DELETE'
    };
    let response = await deleteById(payload);
    message.success(response && response.message);
    this.setState({ deleteModal: false });

    let dataSource = await getList(APIList.getList);
    this.setState({
      dataSource
    });
  };
  render() {
    const {
      dataSource,
      deleteModal
      // formObject
    } = this.state;

    let columns = [
      {
        title: this.translation('Table Name'),
        dataIndex: 'TableName',
        key: 'TableName',
        ...this.getColumnSearchProps('TableName')
      },
      {
        title: this.translation('Task Name'),
        dataIndex: 'TaskName',
        key: 'TaskName',
        ...this.getColumnSearchProps('TaskName')
      },
      {
        title: this.translation('Start Time'),
        key: 'StartDate',
        dataIndex: 'StartDate',
        ...this.getColumnSearchProps('StartDate'),
        sorter: (a, b) => new Date(a.StartDate) - new Date(b.StartDate),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('End Time'),
        key: 'EndDate',
        dataIndex: 'EndDate',
        ...this.getColumnSearchProps('EndDate'),
        sorter: (a, b) => new Date(a.EndDate) - new Date(b.EndDate),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Purge Data'),
        width: '600',
        render: (text, record) => (
          <>
            {/* <Tooltip title={this.translation('Delete')}> */}
            <Button
              disabled={record && record.DeleteStatus === 'Yet to Start' ? false : true}
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => {
                this.setState({ deleteModal: true, selectedRecord: record });
                //   this.DeleteRecord(record);
              }}
              size="middle"
            >
              <DeleteOutlined />
            </Button>
            {/* </Tooltip> */}
          </>
        )
      }
    ];

    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{
            // padding: '0px 30px',
            minHeight: window.innerHeight - 143,
            margin: '5px'
          }}
        >
          <>
            <div style={{ color: 'white', margin: '20px 20px', fontSize: '30px' }}>
              {this.translation('Purge')}
            </div>
            <div style={{ marginTop: '40px' }}>
              <StyledTable
                theme={themeSettings}
                dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
                columns={columns}
              ></StyledTable>
            </div>
            <Modal
              width="50%"
              title={this.translation('Confirm the Acton')}
              open={deleteModal}
              onCancel={() => {
                this.setState({
                  deleteModal: false
                });
              }}
              closable
              theme={themeSettings}
              footer={[
                <>
                  <Button
                    key="back"
                    onClick={() => {
                      this.setState({
                        deleteModal: false
                      });
                    }}
                  >
                    {this.translation('Cancel')}
                  </Button>
                  {/* <ButtonContainer> */}
                  <Button
                    key="Submit"
                    type="primary"
                    className="confirmButton"
                    onClick={this._deleteById}
                  >
                    {' '}
                    {this.translation('Confirm')}{' '}
                  </Button>
                  {/* </ButtonContainer> */}
                </>
              ]}
            >
              <div style={{ display: 'flex', flexWrap: 'wrap', width: '1300px' }}>
                <p> Do you really want to purge the selected data from database</p>
              </div>
            </Modal>
          </>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  userProfile: getUserProfile()
});
export default connect(mapStateToProps)(PurgeList);
