import React from 'react';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Avatar, message as Amessage, Tooltip, Modal } from 'antd';

import { getTranslationCache } from '../../selectors/language';
import { getUserDetails } from '../../selectors/layout';
import ThumbsUp from './Utils/images/ThumbsUp.png';
import ThumbsDown from './Utils/images/ThumbsDown.png';
import ThumbsUpDark from './Utils/images/ThumbsUpDark.png';
import ThumbsDownDark from './Utils/images/ThumbsDownDark.png';
import CopySlip from './Utils/images/CopySlip.png';
import translation from './Utils/translation';

import { Content, FooterContainer, LeftContainer, RightContainer, Sender } from './CSS/styles';
import './CSS/style.css';
import { OnFeedbackChange } from './Utils/ApICalls';
import localStorage from '../../utils/localStorage';
import ChatWidgets from './ChatWidgets';
import { IPAddress } from '../../commons/api';

class ChatMessage extends React.Component {
  timeTicket = null;
  requestCheck = true;
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      linkObject: {}
    };
  }

  onFeedbackAction = async (Feedback, messageId) => {
    let feedback = await OnFeedbackChange(Feedback, messageId);
    if (feedback && feedback.message) {
      this.props.refreshList();
    }
  };

  viewGraph = (linkObject) => {
    this.setState({
      linkObject,
      isModalOpen: true
    });
  };

  renderChatMessage = (isUser, content, sender, generatedDate, message, Feedback, messageId) => {
    let { userDetails } = this.props;
    let userProfilePhoto = userDetails.ImageURL;
    let datasource =
      message && message.MetaData && message.MetaData.DataSource
        ? message.MetaData.DataSource
        : null;

    let datasourceDocs =
      message && message.MetaData && message.MetaData.DOCS ? message.MetaData.DOCS : [];

    let datasourceLinks =
      message && message.MetaData && message.MetaData.URLs ? message.MetaData.URLs : [];

    let datasourceGraphType =
      message && message.MetaData && message.MetaData.LINK_FORMAT
        ? message.MetaData.LINK_FORMAT
        : 'TABLE';

    let LINK_FORMAT =
      message && message.MetaData && message.MetaData.LINK_FORMAT
        ? message.MetaData.LINK_FORMAT
        : 'TEXT';
    let WebResults = [];
    if (datasource && datasource === 'Web search') {
      WebResults =
        message && message.MetaData && message.MetaData.WebResults
          ? message.MetaData.WebResults
          : [];
    }

    const siteId = localStorage.get('currentSite');
    let { translationCache } = this.props;
    switch (sender) {
      case 'User':
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
            <div>
              <Sender style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {moment(generatedDate).format('YYYY-MM-DD HH:mm:ss')}
              </Sender>
              <RightContainer isUser={isUser}>
                <div style={{ width: '3%' }}></div>
                <Content>
                  {content &&
                    content
                      .toString()
                      .split('\n')
                      .map((line, index) =>
                        line === '' ? <br key={index} /> : <div key={index}>{line}</div>
                      )}
                </Content>
              </RightContainer>
            </div>
            <div style={{ padding: '7px', marginTop: '16px' }}>
              <Avatar
                style={{ backgroundColor: '#e3e3e300', border: '1px solid #bbacac' }}
                src={`/api/${userProfilePhoto}`}
              />
            </div>
          </div>
        );
      case 'Bot':
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '5px',
              width: '97%'
            }}
          >
            <div style={{ padding: '7px', marginTop: '16px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: '#87d068',
                  borderRadius: '20px',
                  width: '30px',
                  height: '30px'
                }}
              >
                <div style={{ marginTop: '4px' }}>BM</div>
              </div>
            </div>
            <div>
              <Sender style={{ display: 'flex', justifyContent: 'flex-start' }}>
                {moment(generatedDate).format('YYYY-MM-DD HH:mm:ss')}
              </Sender>
              <LeftContainer isUser={isUser}>
                <Content>
                  {content &&
                    datasource !== 'Web search' &&
                    content
                      .toString()
                      .split('\n')
                      .map((line, index) => <div key={index}>{line}</div>)}
                  {datasource && datasource === 'Web search' ? (
                    <div>
                      {WebResults &&
                        Array.isArray(WebResults) &&
                        WebResults.map(({ title, description, link }, index) => {
                          return (
                            <div key={index} style={{ margin: '15px 10px' }}>
                              <div style={{ color: 'rgb(22, 48, 70)', fontWeight: 'bold' }}>
                                {title}
                              </div>
                              <div>
                                {description &&
                                  description
                                    .toString()
                                    .split('\n')
                                    .map((line, index) => <div key={index}>{line}</div>)}
                              </div>
                              <a
                                href={link}
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{
                                  color: '#163046',
                                  // fontWeight: 'bold',
                                  cursor: 'pointer'
                                }}
                              >
                                <span>{link}</span>
                              </a>
                            </div>
                          );
                        })}
                    </div>
                  ) : null}

                  {datasource && datasource !== 'Web search' ? (
                    <div style={{ marginTop: '10px', borderTop: '1px dashed' }}>
                      {datasource ? (
                        <div style={{ marginTop: '5px' }}>
                          <span style={{ fontSize: '17px', fontWeight: 'bold' }}>
                            {translation(translationCache, 'Source')}:{' '}
                          </span>{' '}
                          {datasource}
                        </div>
                      ) : (
                        ''
                      )}

                      {datasource && datasource === 'Trouble reports' ? (
                        <div>
                          <span style={{ fontWeight: 'bold' }}>
                            {translation(translationCache, 'Source Documents')}:
                          </span>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {datasourceDocs &&
                              Array.isArray(datasourceDocs) &&
                              datasourceDocs.map((formName, index) => {
                                return (
                                  <div key={index}>
                                    <span
                                      style={{ padding: '0px 10px 10px 0px', fontWeight: 'bold' }}
                                    >
                                      {index + 1}.
                                    </span>
                                    <span>
                                      {' '}
                                      <a
                                        href={`${IPAddress.LOCAL}/api/chatAttachments/${formName.file_name}?SiteId=${siteId}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        style={{
                                          color: '#163046',
                                          fontWeight: 'bold',
                                          cursor: 'pointer'
                                        }}
                                      >
                                        <span>{formName.file_name}</span>
                                      </a>
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      {datasource && datasource === 'BMecomo' && LINK_FORMAT !== 'TEXT' ? (
                        <div style={{ display: 'flex' }}>
                          <span style={{ fontWeight: 'bold' }}>
                            {translation(translationCache, 'Source Links')}:
                          </span>

                          <span>
                            <div
                              style={{
                                color: '#163046',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                marginLeft: '6px'
                              }}
                              onClick={() => this.viewGraph({ GraphType: datasourceGraphType })}
                            >
                              {translation(translationCache, 'View Data')}
                            </div>
                          </span>
                        </div>
                      ) : (
                        ''
                      )}

                      {datasource && datasource === 'Web search' ? (
                        <div>
                          <span style={{ fontWeight: 'bold' }}>
                            {translation(translationCache, 'Source Links')}:
                          </span>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {datasourceLinks &&
                              Array.isArray(datasourceLinks) &&
                              datasourceLinks.map((linkPath, index) => {
                                return (
                                  <div key={index}>
                                    <span
                                      style={{ padding: '0px 10px 10px 0px', fontWeight: 'bold' }}
                                    >
                                      {index + 1}.
                                    </span>
                                    <span>
                                      {' '}
                                      <a
                                        href={linkPath}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        style={{
                                          color: '#163046',
                                          fontWeight: 'bold',
                                          cursor: 'pointer'
                                        }}
                                      >
                                        <span>{linkPath}</span>
                                      </a>
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}

                  <FooterContainer>
                    <Tooltip
                      title={translation(
                        translationCache,
                        Feedback && Feedback === 'Like' ? 'Deselect Like' : 'Like'
                      )}
                    >
                      <img
                        src={Feedback && Feedback === 'Like' ? ThumbsUpDark : ThumbsUp}
                        onClick={async () => {
                          this.onFeedbackAction(
                            Feedback && Feedback === 'Like' ? null : 'Like',
                            messageId
                          );
                        }}
                        key={'thumnbsUp'}
                        style={{
                          margin: '12px 6px -11px 0px',
                          border: '1px solid gray',
                          padding: '4px',
                          borderRadius: '4px',
                          height: '32px',
                          width: '32px'
                        }}
                        alt=""
                      />
                    </Tooltip>
                    <Tooltip
                      title={translation(
                        translationCache,
                        Feedback && Feedback === 'DisLike' ? 'Deselect DisLike' : 'DisLike'
                      )}
                    >
                      <img
                        src={Feedback && Feedback === 'DisLike' ? ThumbsDownDark : ThumbsDown}
                        onClick={() => {
                          this.onFeedbackAction(
                            Feedback && Feedback === 'DisLike' ? null : 'DisLike',
                            messageId
                          );
                        }}
                        key={'ThumbsDown'}
                        style={{
                          margin: '12px 6px -11px 0px',
                          border: '1px solid gray',
                          padding: '4px',
                          borderRadius: '4px',
                          height: '32px',
                          width: '32px'
                        }}
                        alt=""
                      />
                    </Tooltip>
                    <Tooltip title={translation(translationCache, 'Copy to Clipboard')}>
                      <img
                        src={CopySlip}
                        onClick={() => {
                          copy(content);
                          Amessage.success(translation(translationCache, 'Copy to Clipboard'));
                        }}
                        key={'CopySlip'}
                        style={{
                          cursor: 'pointer',
                          margin: '12px 6px -11px 0px',
                          border: '1px solid gray',
                          padding: '4px',
                          borderRadius: '4px',
                          height: '32px',
                          width: '32px'
                        }}
                        alt=""
                      />
                    </Tooltip>
                  </FooterContainer>
                </Content>
              </LeftContainer>
            </div>
          </div>
        );
      case 'Debug':
        return (
          <div className="debugMode">
            <div
              style={{
                width: '10px',
                height: '10px',
                background:
                  message && message.DebugStatus && message.DebugStatus === 'Enable'
                    ? 'green'
                    : 'red',
                margin: '6px',
                fontWeight: 'bold'
              }}
            ></div>
            {content &&
              content
                .toString()
                .split('\n')
                .map((line, index) =>
                  line === '' ? <br key={index} /> : <div key={index}>{line}</div>
                )}
          </div>
        );
      case 'Welcome':
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '5px',
              width: '97%'
            }}
          >
            <div style={{ padding: '7px', marginTop: '-6px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: '#87d068',
                  borderRadius: '20px',
                  width: '30px',
                  height: '30px'
                }}
              >
                <div style={{ marginTop: '4px' }}>BM</div>
              </div>
            </div>
            <div>
              <LeftContainer isUser={isUser}>
                <Content>
                  {content &&
                    content
                      .toString()
                      .split('\n')
                      .map((line, index) => <div key={index}>{line}</div>)}
                </Content>
              </LeftContainer>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  handleOk = () => {};

  handleCancel = () => {
    this.setState({
      isModalOpen: false
    });
  };

  render() {
    let { isUser, content, sender, generatedDate, message, Feedback, Id, translationCache } =
      this.props;
    let { isModalOpen, linkObject } = this.state;
    return (
      <>
        {this.renderChatMessage(isUser, content, sender, generatedDate, message, Feedback, Id)}
        <Modal
          width={1000}
          style={{ height: '300px' }}
          title={translation(translationCache, 'Data Visualization')}
          open={isModalOpen}
          footer={null}
          onCancel={this.handleCancel}
        >
          <ChatWidgets linkObject={linkObject} Id={Id} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userDetails: getUserDetails(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ChatMessage);
