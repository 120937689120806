import React, { Fragment } from 'react';
import { Progress, Tag } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { progressBarMock } from '../../../Mock/MockJson';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import '../../CSS/style.css';
import { defaultRefreshTime } from '../../constant.json';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percent: 0,
      type: 'circle',
      title: '',
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  // UNSAFE_componentWillReceiveProps(props) {
  //     this.getData(props);
  // }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  getData = async () => {
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state;
    this.requestCheck = false;
    errorInAPI = false;
    noDataInDB = false;

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = progressBarMock;
      this.requestCheck = true;
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection(
          graphprops,
          this.props.DeviceCode,
          this.props.selectionDate,
          this.props.selectionMonth,
          this.props.selectionWeek,
          this.props.shift,
          this.props.selectionDateRange,
          this.props.dayMonthYear,
          this.abortController,
          this.props.dropdownSelection,
          this.props.dashboardPayload,
          this.props.AssetCode,
          this.props.selectionDateLive,
          this.props.currentTimeZone
        );
        this.requestCheck = true;
      } else if (graphprops) {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      }
    }

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      if (json && json[0] && json[0].Value) {
        this.setState({
          percent: json[0].Value,
          refreshDateTime: moment()
        });
      }

      this.setState({
        errorInAPI,
        noDataInDB
      });
    }
  };

  // componentWillUnmount() {
  //   clearInterval(this.timeTicket);
  // }

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime, percent } = this.state;
    let theme = this.props.selectedThemeing;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <div className="progressbar">
            {' '}
            {
              //Check if message failed
              graphprops[graphprops.graph] && graphprops[graphprops.graph].barType === 'line' ? (
                <div className="progressbarline">
                  <Progress
                    strokeWidth={20}
                    strokeColor={{
                      '0%': '#108ee9',
                      '50%': '#87d068'
                    }}
                    style={{
                      color: this.props.graphprops.titleColor
                        ? this.props.graphprops.titleColor
                        : theme === 'lightTheme'
                        ? 'black'
                        : 'white'
                    }}
                    type={
                      graphprops[graphprops.graph] && graphprops[graphprops.graph].barType
                        ? graphprops[graphprops.graph].barType
                        : ''
                    }
                    percent={percent}
                    status="active"
                  />
                </div>
              ) : (
                <div className="progressbarcircles">
                  <Progress
                    style={{ color: this.props.graphprops.titleColor }}
                    type={
                      graphprops[graphprops.graph] && graphprops[graphprops.graph].barType
                        ? graphprops[graphprops.graph].barType
                        : ''
                    }
                    percent={this.state.percent}
                    status="active"
                  />
                </div>
              )
            }
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(ProgressBar);
