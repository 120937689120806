import { Button } from 'antd';
import styled from 'styled-components';

export const StyledApplication = styled.div`
  background: white;
  margin: 20px;
  padding: 30px;
  min-height: 750px;
`;
export const StyledAddButton = styled(Button)`
  margin: 24px;
`;

export const StyledDashboard = styled.div`
  background: white;
  // margin: 20px;
  padding: 30px;
`;
