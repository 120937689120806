import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { Form, Select, DatePicker, Button, message, Drawer, Table, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import localStorage from '../../../utils/localStorage';
import { getUserProfile } from '../../../selectors/layout';
import { getTranslationCache } from '../../../selectors/language';
import { API_MASTERCONFIGURATION, REPORTFRAMEWORK } from '../../../commons/api';
import { Table_AppConfig } from '../../../commons/table';

const { Option } = Select;
const { RangePicker } = DatePicker;
class CronReRun extends Component {
  constructor(props) {
    super(props);
    this.state = {
      JobList: [],
      // JobList: [
      //   // { Id: 'Daily SEC Report', JobName: 'Daily SEC Report' },
      //   // { Id: 'Daily Report', JobName: 'Daily Report' },
      //   // { Id: 'Weekly Report', JobName: 'Weekly Report' },
      //   // { Id: 'PC Report', JobName: 'PC Report' }
      // ],
      historyJobList: [],
      jobId: '',
      jobName: '',
      range: '',
      listView: false,
      dataSource: [],
      momentRange: [],
      userProfile: props && props.userProfile ? props.userProfile : {},
      selectedJobId: 2,
      selectedJobName: 'KilnGasReport',
      selectedDate: moment().format('YYYY-MM-DD'),
      translationCache: props.translationCache || []
    };
  }
  componentDidMount() {
    this.getJobHistoryList();
    this.getJobList();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    // if (this.props.userProfile !== prevProps.userProfile) {
    //   this.setState({ userProfile: this.props.userProfile });
    // }
  }
  getJobHistoryList = () => {
    const siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    const healthheaders = {
      method: 'GET',
      url: `${REPORTFRAMEWORK.JobNames}?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(healthheaders)
      .then((res) => {
        let historyJobList = res.data;
        this.setState({ historyJobList });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getJobList = () => {
    const siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    const healthheaders = {
      method: 'POST',
      url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        TableName: Table_AppConfig.SiteMasterCronJobs,
        ColumnName: 'Name',
        Conditions: [
          {
            key: 'Rerun',
            value: true
          }
        ]
      }
    };
    axios(healthheaders)
      .then((res) => {
        let JobList = res.data;
        this.setState({ JobList });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onChange = (value, dateString) => {
    let dateString0 =
      dateString && dateString[0] ? moment(dateString[0]).format('YYYY-MM-DD 00:00:00') : null;
    let dateString1 =
      dateString && dateString[1] ? moment(dateString[1]).format('YYYY-MM-DD 23:59:59') : null;
    let startDate = dateString0 ? moment(dateString0).utc().format('YYYY-MM-DD HH:mm:ss') : '';
    let endDate =
      dateString && dateString[1] ? moment(dateString1).utc().format('YYYY-MM-DD HH:mm:ss') : '';

    this.setState({
      range: [startDate, endDate],
      momentRange: [moment(dateString[0]), moment(dateString[1])]
    });
  };
  ondateOk = (value, dateString) => {
    this.setState({
      range: [dateString[0], dateString[1]],
      momentRange: [moment(dateString[0]), moment(dateString[1])]
    });
  };
  onOk = (value, dateString) => {
    this.setState({
      range: [dateString[0], dateString[1]],
      momentRange: [moment(dateString[0]), moment(dateString[1])]
    });
  };
  jobChange = (value) => {
    const { JobList } = this.state;
    JobList.map((jobItem) => {
      if (jobItem.Key === value) {
        this.setState({ jobId: value, jobName: jobItem.Key });
      }
      return {};
    });
  };
  SelectedJobChange = (value) => {
    const { selectedDate } = this.state;
    this.setState({ selectedJobId: value, selectedJobName: value });
    this.historyShow(selectedDate, value);
  };
  cronSubmit = () => {
    const { range, jobName, userProfile } = this.state;
    let userName = userProfile && userProfile.user && userProfile.user.UserName;
    let role = '';
    let userId = userProfile && userProfile.user && userProfile.user.Id;
    let roleId = '';

    userProfile &&
      userProfile.belongsTo &&
      userProfile.belongsTo.sites &&
      Object.keys(userProfile.belongsTo.sites).map((item) => {
        role =
          userProfile.belongsTo.sites[item] &&
          userProfile.belongsTo.sites[item].role &&
          userProfile.belongsTo.sites[item].role.Name;
        roleId =
          userProfile.belongsTo.sites[item] &&
          userProfile.belongsTo.sites[item].role &&
          userProfile.belongsTo.sites[item].role.Id
            ? userProfile.belongsTo.sites[item].role.Id
            : '';
        return {};
      });
    const payload = {
      cronName: jobName,
      cronType: 'Rerun',
      startDate: range[0],
      endDate: range[1],
      userName: userName,
      userId: userId,
      roleName: role,
      roleId: roleId
    };
    const siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    const healthheaders = {
      method: 'POST',
      url: `${REPORTFRAMEWORK.ReRun}?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(healthheaders)
      .then(() => {
        message.success('JOB Is Going To Be Run In Few Minutes');
      })
      .catch((error) => {
        message.error(error);
      });
  };
  historyShow = async (date) => {
    let { jobName } = this.state;
    let start = moment(date).format('YYYY-MM-DD 00:00:00');
    let end = moment(date).format('YYYY-MM-DD 23:59:59');
    const siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    let payload = {
      cronName: jobName,
      startDate: moment(start).utc().format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(end).utc().format('YYYY-MM-DD HH:mm:ss'),
      CronType: 'Rerun'
    };
    const healthheaders = {
      method: 'POST',
      url: `${REPORTFRAMEWORK.ReRunHistory}?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(healthheaders)
      .then((respon) => {
        let dataSource = respon.data;
        this.setState({ listView: true });
        this.setState({ dataSource });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onCloseList = () => {
    this.setState({ listView: false });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {dataIndex === 'RunDate' ? (
          <DatePicker
            format="YYYY-MM-DD"
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
        ) : (
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
        )}

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record && record[dataIndex] && dataIndex === 'Equipment'
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : record['dataIndex'],
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  handleSearch = (selectedKeys, confirm, column) => {
    confirm();
    if (column === 'RunDate') {
      this.setState({
        searchText: moment(selectedKeys[0]).format('YYYY-MM-DD')
      });
    } else {
      this.setState({ searchText: selectedKeys[0] });
    }
  };

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };
  onDateChange = (date, dateString) => {
    const { selectedJobName } = this.state;
    this.setState({ selectedDate: dateString });
    this.historyShow(dateString, selectedJobName);
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  disabledDateRange = (current) => {
    return current < moment().subtract(7, 'day').endOf('day') || current > moment().endOf('day');
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let { JobList, listView, dataSource } = this.state;
    // const { getFieldDecorator } = this.props.form;
    const columns = [
      // {
      //   title: 'Id',
      //   key: 'Id',
      //   dataIndex: 'Id'
      // },
      // {
      //   title: 'Equipment',
      //   key: 'Equipment',
      //   dataIndex: 'Equipment',
      //   ...this.getColumnSearchProps('Equipment')
      // },
      {
        title: 'ReportName',
        key: 'Name',
        dataIndex: 'Name'
      },

      {
        title: 'RunType',
        key: 'CronType',
        dataIndex: 'CronType'
      },
      {
        title: 'ReportDate',
        key: 'StartTime',
        dataIndex: 'StartTime'
      },

      {
        title: 'JobStatus',
        key: 'Status',
        dataIndex: 'Status'
      },
      {
        title: 'Remarks',
        key: 'Remarks',
        dataIndex: 'Remarks'
      },
      {
        title: 'RunDate',
        key: 'CronRunDate',
        dataIndex: 'CronRunDate',
        ...this.getColumnSearchProps('RunDate')
      }
    ];
    return (
      <div style={{ minHeight: window.innerHeight - 300 }}>
        <Button
          size="large"
          type={'primary'}
          style={{
            display: 'flex',
            marginLeft: 'auto',
            padding: '5px',
            order: 2
          }}
          onClick={() =>
            this.historyShow(moment().format('YYYY-MM-DD'), this.state.selectedJobName)
          }
        >
          {this.translation('Report Run History')}
        </Button>
        <Form
          style={{ marginTop: '40px' }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={this.cronSubmit}
        >
          <Form.Item
            label={this.translation('Select Report')}
            name="Select Report"
            rules={[{ required: true, message: 'Please Select CronJob' }]}
          >
            <Select
              placeholder={this.translation('PleaseSelect CronJob')}
              onSelect={(value) => this.jobChange(value)}
              style={{ width: '350px' }}
            >
              {JobList.map((item) => {
                return (
                  <Option key={item.Value} value={item.Key}>
                    {item.Key}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label={this.translation('Date Range Selection')}
            name="Select Date"
            rules={[{ required: true, message: 'Please Select Date Selection' }]}
          >
            <RangePicker
              // showTime={{ format: 'HH:mm:ss' }}
              format="YYYY-MM-DD"
              placeholder={[this.translation('Start Date'), this.translation('End Date')]}
              // value={this.state.momentRange}
              onChange={this.onChange}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button size="large" type={'primary'} htmlType="submit">
              {this.translation('Generate Report')}
            </Button>
          </Form.Item>
        </Form>

        <Drawer
          title="Cron Job History"
          width={1500}
          placement="right"
          closable={true}
          onClose={this.onCloseList}
          visible={listView}
        >
          <div>
            <div
              style={{
                display: 'flex',
                fontWeight: 'bold',
                flexDirection: 'row-reverse'
              }}
            >
              <DatePicker
                style={{ width: 300 }}
                defaultValue={moment()}
                disabledDate={this.disabledDate}
                onChange={this.onDateChange}
              />
              <div style={{ marginRight: '15px' }}>Select Date :</div>
              <div
                style={{
                  display: 'flex',
                  fontWeight: 'bold',
                  flexDirection: 'row-reverse'
                }}
              >
                {/* <Select
               style={{ width: 300 }}
                    placeholder="Please Select CronJob"
                    onSelect={(value) => this.SelectedJobChange(value)}
                    defaultValue={this.state.selectedJobName}
                  >
                    {historyJobList.map((item) => {
                      return (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select> */}
                <div style={{ marginRight: '10px' }}>Select Job :</div>
              </div>
            </div>
            <div style={{ marginTop: '10px' }}>
              <Table columns={columns} dataSource={dataSource} />
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  userProfile: getUserProfile()
});
export default connect(mapStateToProps)(CronReRun);
