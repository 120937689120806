import React, { Fragment } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tag } from 'antd';
import ReactEcharts from '../../Echarts/Echarts';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { trendLabelTranslation } from '../../../Utils/Adapter/translationAdapter';
import { getTranslationCache } from '../../../../../selectors/language';
import { beijingChartMockJson } from '../../../Mock/MockJson';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class BeijingChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      colors: this.props.graphprops.colors,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }
  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            option={this.state.option}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: '80%', width: '100%' }}
            className="react_for_echarts"
          />
        )}
      </Fragment>
    );
  }

  getData = async (props, theme) => {
    let { graphprops } = this.props;
    this.requestCheck = false;
    let json = [];
    // let { errorInAPI, noDataInDB } = this.state
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = beijingChartMockJson;
      this.requestCheck = true;
    } else {
      if (props.graphprops.graph === '' && props.graphprops.currentDateData !== undefined) {
        json = props.graphprops.currentDateData;
      } else {
        if (
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'datePicker' ||
              graphprops.GraphLevelSelection === 'datePicker') &&
            this.props.selectionDate) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'monthPicker' ||
              graphprops.GraphLevelSelection === 'monthPicker') &&
            this.props.selectionMonth) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'weakPicker' ||
              graphprops.GraphLevelSelection === 'weakPicker') &&
            this.props.selectionWeek) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'shiftPicker' ||
              graphprops.GraphLevelSelection === 'shiftPicker') &&
            this.props.shift) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
              graphprops.GraphLevelSelection === 'dateRangePicker') &&
            this.props.selectionDateRange) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
              graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
            this.props.dayMonthYear) ||
          (graphprops && graphprops.dashboardType === 'dynamic') ||
          (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
          (graphprops &&
            graphprops.dashboardType === 'hierarchy' &&
            this.props.dropdownSelection) ||
          (graphprops && this.props.dashboardPayload) ||
          (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
        ) {
          json = await getChartDataWithSelection({
            graphprops,
            DeviceCode: this.props.DeviceCode,
            selectionDate: this.props.selectionDate,
            selectionMonth: this.props.selectionMonth,
            selectionWeek: this.props.selectionWeek,
            shift: this.props.shift,
            selectionDateRange: this.props.selectionDateRange,
            dayMonthYear: this.props.dayMonthYear,
            abortController: this.abortController,
            dropdownSelection: this.props.dropdownSelection,
            dashboardPayload: this.props.dashboardPayload,
            AssetCode: this.props.AssetCode,
            selectionDateLive: this.props.selectionDateLive,
            currentTimeZone: this.props.currentTimeZone
          });
          this.requestCheck = true;
        } else {
          json = await await getChartDataWithoutSelection({
            graphprops,
            abortController: this.abortController,
            dropdownSelection: this.props.dropdownSelection,
            DeviceCode: this.props.DeviceCode,
            TimeZone: this.props.currentTimeZone
          });
          this.requestCheck = true;
        }
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        this.setState({ errorInAPI: true });
      } else if (json.length === 0) {
        this.setState({ noDataInDB: true });
      }
    } else {
      if (json && Array.isArray(json)) {
        let dates = [];
        let values = [];
        let name = '';
        let tagnames = [];
        let seriesarray = [];
        let legendsdata = [];
        json.map((val) => {
          if (val.Name !== name) {
            if (tagnames.indexOf(val.Name) === -1) {
              tagnames.push(val.Name);
              name = val.Name;
              legendsdata.push({
                name: val.Description,
                icon: 'roundRect',
                textStyle: {
                  color: theme === 'lightTheme' ? 'black' : 'white',
                  fontSize: 18,
                  fontWeight: 'bold'
                }
              });
            }
          }
          return {};
        });

        let yAxisData = [];

        if (Array.isArray(this.props.graphprops.beijingLine.YAxis)) {
          yAxisData = [...this.props.graphprops.beijingLine.YAxis];
        }

        let yaxis = yAxisData.map((value, index) => {
          let axis = {};
          axis.splitLine = {
            show: true,
            lineStyle: {
              color: '#504c4c'
            }
          };
          axis.triggerEvent = true;
          axis.axisLine = {
            lineStyle: {
              // color: "white"
              color:
                this.props.graphprops.colors[index] !== undefined
                  ? value.tags.length > 1
                    ? 'white'
                    : this.props.graphprops.colors[index]
                  : 'white'
            }
          };
          axis.max = value.max;
          axis.min = value.min;
          axis.offset = value.offset;
          axis.interval = value.interval;
          axis.position = value.position;
          axis.name = value.name;
          //  axis.nameLocation = (value.nameLocation==undefined)?"top":"center";
          axis.tags = value.tags;
          return axis;
        });

        yaxis.unshift({
          splitLine: {
            show: true,
            lineStyle: {
              color: '#504c4c'
            }
          },
          triggerEvent: true,
          axisLine: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          tags: []
        });

        tagnames.map((tagval, tagindex) => {
          values = [];
          json.map((jsonval) => {
            if (tagnames[0] === jsonval.Name && tagindex === 0) {
              let date =
                graphprops && graphprops.type === 'live'
                  ? moment
                      .utc(jsonval.DateTime)
                      .tz(this.props.currentTimeZone)
                      .format('DD/MM/YYYY HH:mm:ss')
                  : moment(jsonval.DateTime).format('DD/MM/YYYY HH:mm:ss');
              dates.push(date);
              // dates.push(
              //   moment(jsonval.DateTime)
              //     .utc()
              //     .format("DD/MM/YYYY HH:mm:ss")
              // );
            }
            if (tagval === jsonval.Name) {
              values.push(jsonval.Value);
            }
            return {};
          });
          if (this.props.graphprops.linetype === 'area') {
            if (yaxis.length > 1) {
              yaxis.map((axis, axisindex) => {
                if (axis.tags.indexOf(tagval) !== -1) {
                  seriesarray.push({
                    name: legendsdata[tagindex]['name'],
                    data: values,
                    type:
                      this.props.graphprops &&
                      this.props.graphprops.beijingLine &&
                      this.props.graphprops.beijingLine.beijingType
                        ? this.props.graphprops.beijingLine.beijingType
                        : 'line',
                    yAxisIndex: axisindex,
                    areaStyle: {},
                    smooth: true
                  });
                }
                return {};
              });
            } else {
              seriesarray.push({
                name: legendsdata[tagindex]['name'],
                data: values,
                type:
                  this.props.graphprops &&
                  this.props.graphprops.beijingLine &&
                  this.props.graphprops.beijingLine.beijingType
                    ? this.props.graphprops.beijingLine.beijingType
                    : 'line',
                areaStyle: {},
                smooth: true
              });
            }
          } else {
            if (yaxis.length > 1) {
              yaxis.map((axis, axisindex) => {
                if (axis.tags.indexOf(tagval) !== -1) {
                  seriesarray.push({
                    name: legendsdata[tagindex]['name'],
                    data: values,
                    yAxisIndex: axisindex,
                    type:
                      this.props.graphprops &&
                      this.props.graphprops.beijingLine &&
                      this.props.graphprops.beijingLine.beijingType
                        ? this.props.graphprops.beijingLine.beijingType
                        : 'line'
                    // type: 'line'
                  });
                }
                return {};
              });
            } else {
              seriesarray.push({
                name: legendsdata[tagindex]['name'],
                data: values,
                type:
                  this.props.graphprops &&
                  this.props.graphprops.beijingLine &&
                  this.props.graphprops.beijingLine.beijingType
                    ? this.props.graphprops.beijingLine.beijingType
                    : 'line'
                // type: 'line'
              });
            }
          }
          return {};
        });

        const option = cloneDeep(this.getOption());
        let series = await trendLabelTranslation(seriesarray, this.props.translationCache);
        option.xAxis.data = [...dates];
        option.yAxis = [...yaxis];
        option.series = [...series];
        option.legend = [...legendsdata];
        this.setState({
          option: option,
          refreshDateTime: moment()
        });
      }
    }
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      title: {},
      color: this.props.graphprops.colors,
      tooltip: {
        trigger: 'axis'
      },
      visualMap: {
        textStyle: {
          fontSize: 15,
          color: theme === 'lightTheme' ? 'black' : 'white'
        },

        top: 20,
        //right: "auto",
        left: 'center',
        align: 'left',
        orient: 'horizontal',

        pieces:
          this.props.graphprops &&
          this.props.graphprops &&
          this.props.graphprops.beijingLine &&
          this.props.graphprops.beijingLine.VisualMap
            ? JSON.parse(this.props.graphprops.beijingLine.VisualMap)
            : [],

        outOfRange: {
          color: '#999'
        }
      },
      xAxis: {
        data: this.data.map(function (item) {
          return item.date;
        }),
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
        }
      },
      yAxis: [],

      toolbox: {
        showTitle: false,
        orient: 'vertical',
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            backgroundColor: theme === 'lightTheme' ? 'white' : 'black',
            name: this.props.graphprops.title
            // backgroundColor: "black",
          }
        }
      },
      // toolbox: {
      //   showTitle: false,
      //   orient: "vertical",
      //   feature: {
      //     saveAsImage: {
      //       title: "download",
      //       name: this.props.graphprops.title,
      //       backgroundColor: "black"
      //     }
      //   }
      // },
      dataZoom: [
        {
          type: 'slider',
          show: true,
          start: 0,
          end: 100,
          backgroundColor: '#9a9191',

          textStyle: {
            color: 'white'
          }
        },
        {
          type: 'inside',
          start: 0,
          end: 100
        }
      ],

      series: {
        name: 'value',
        type:
          this.props.graphprops &&
          this.props.graphprops.beijingLine &&
          this.props.graphprops.beijingLine.beijingType
            ? this.props.graphprops.beijingLine.beijingType
            : 'line',
        data: this.data.map(function (item) {
          return item.value;
        })

        // markLine: {
        //   silent: true,
        //   data: [
        //     {
        //       yAxis: 20
        //     },
        //     {
        //       yAxis: 40
        //     },
        //     {
        //       yAxis: 60
        //     },
        //     {
        //       yAxis: 80
        //     },
        //     {
        //       yAxis: 100
        //     }
        //   ]
        // }
      }
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(BeijingChart);
