import styled from 'styled-components';
import { Table, Tabs, Form, Modal } from 'antd';

export const StyledMain = styled.div`
  background: #e3e3e3;
`;

export const StyledComponent = styled.div`
  background: ${(props) => props.theme.backgroundColor} !important;
  padding: 10px;
`;
export const StyledTable = styled(Table)`
  caption-side: top;
  color: ${(props) => props.theme.list.table.column.color} !important;
  width: 100%;
  caption-side: bottom;
  td,
     th{
         border: none;
        text-align:center !important;
    }
    td
     { padding: 11px 10px;
        text-align:center !important;
    }
  .ant-table-thead > tr > th{
    font-weight:bold !important
    font-size: 1em !important;
    color:white !important;
    background: ${(props) => props.theme.list.table.column.backgroundColor} ;
  }
  text[style] {
    font-size: 1em !important;
    color: ${(props) => props.theme.list.table.column.color} !important;
 }
  tbody tr {
    :nth-of-type(odd) {
      background-color:${(props) => props.theme.list.table.column.odd} !important;
    }
    :hover {
      background-color:${(props) => props.theme.list.table.column.hover} !important; 
    }
  }
  tbody tr {
    :nth-of-type(even) {
      background-color:${(props) => props.theme.list.table.column.even} !important;
    }
    :hover {
      background-color:${(props) => props.theme.list.table.column.hover} !important; 
    }
  }
  thead > tr {
    color: ${(props) => props.theme.list.table.column.color} !important;
    background-color: #262626 !important;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;
export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
  }

  .ant-tabs-tab-remove {
    flex: none;
    margin-right: -4px;
    margin-left: 8px;
    font-size: 12px;
    background: #1a3b5c;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    color: white !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.form.tabs.color};
    text-shadow: 0 0 0.25px currentcolor;
  }
`;
export const StyledSubForm = styled.div`
  background: ${(props) => props.theme.form.subSectionBackgroundColor};
  padding: 20px;
  min-height: 300px;
`;
export const StyledForm = styled(Form)`
  .ant-divider-horizontal.ant-divider-with-text {
    display: flex;
    margin: 16px 0;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-top: 0;
  }

  .ant-form-item-label > label {
    color: ${(props) => props.theme.form.formLabel} !important;
    font-weight: revert;
    font-size: ${(props) => props.theme.form.formLabelSize};
    font-weight: bold;
  }

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.66);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }

  .ant-tabs-content-holder {
    border-radius: 0 5px 5px 5px;
  }
`;
export const StyledBox = styled.div`
  margin-top: 15px;
  height: 100%;
  width: 100%;
  border: 2px solid grey;,
  margin-left:15px;
`;
export const StyledBoxLabel = styled.div`
  color: #1a1919
  margin-top: -14px;
  position: absolute;
  left: 60px;
  width: 180px;
  text-align: center;
  background-color: #e5eaf0;
  font-size: 16px;
  font-weight: bold;
`;
export const StyledModal = styled(Modal)`
  width: 85% !important;

  .ant-modal-content {
    border-radius: 20px;
    background: #1a3652;
  }

  .ant-modal-header {
    background: #1a3652;
  }
  .ant-modal-title {
    color: #ffffff !important;
  }
  .ant-form-item-required {
    color: #ffffff !important;
  }
  .ant-form-item-label > label {
    color: #ffffff !important;
  }

  .ant-modal-close {
    color: white !important;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
  }
`;
