import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Popconfirm, Tooltip, Tag, Input } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { encode as base64_encode } from 'base-64';
import ListView from '../../../Utils/ListView';
import history from '../../../../../commons/history';
import { constant } from '../../../Utils/constants';
import { getUserProfile } from '../../../../../selectors/layout';
import { getJobPlansList, deleteById, getUsersList } from '../../../Utils/FormCalls';
import { StyledComponent } from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import { getTranslationCache } from '../../../../../selectors/language';
import localStorage from '../../../../../utils/localStorage';
import Highlighter from 'react-highlight-words';
import { API_MASTERJOBPLAN } from '../../../../../commons/api';

class JobPlanList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      userProfile: props && props.userProfile ? props.userProfile : {},
      userList: [],
      config: {
        viewTypes: ['add', 'card', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['JobPlanName'],

        firstTitle: 'JobPlanName',
        secondTitle: 'CreatedBy',
        thirdTitle: 'CreatedTime',

        actions: ['edit', 'delete'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/AMM/JobPlanDetails'
        },
        deletee: {
          byAttribute: 'Id'
        }
      }
    };
  }

  componentDidMount = async () => {
    this._getDataSource();
  };

  componentDidUpdate(prevprops) {
    if (this.props.userProfile !== prevprops.userProfile) {
      this.setState({ userProfile: this.props.userProfile });
    }
  }

  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };

  _getDataSource = async () => {
    let dataSource = await getJobPlansList('both');
    this.setState({
      dataSource
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          // maxLength={30}
        />
        <SearchOutlined
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8, fontSize: '130%' }}
        >
          {translation(this.props.translationCache, 'Search')}
        </SearchOutlined>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, 'Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  editJobPlan = async (record) => {
    let { config } = this.state;
    history.push({ pathname: `${config.edit.redirectURL}/${base64_encode(record.Id)}` });
  };

  _deleteById = async (id) => {
    const siteId = localStorage.get('currentSite');
    let payload = {
      url: `${API_MASTERJOBPLAN.DELETE}?Id=${id}&SiteId=${siteId}`,
      method: 'POST'
    };
    await deleteById(payload);
    let dataSource = await getJobPlansList('both');
    this.setState({
      dataSource
    });
  };

  render() {
    const { dataSource, userList } = this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, constant.jobPlan),
        dataIndex: 'JobPlanName',
        key: 'JobPlanName',
        filter: true,
        ...this.getColumnSearchProps('JobPlanName')
      },
      {
        title: translation(translationCache, constant.description),
        dataIndex: 'JobPlanDescription',
        key: 'JobPlanDescription',
        ...this.getColumnSearchProps('JobPlanDescription')
      },
      {
        title: translation(translationCache, constant.site),
        dataIndex: 'SiteName',
        key: 'SiteName',
        filter: true
      },
      {
        title: translation(translationCache, constant.status),
        dataIndex: 'JobPlanStatusId',
        key: 'JobPlanStatusId',
        render: (text) => (
          <>
            <Tag
              color={
                text && text === 'Active' ? '#87d068' : text === 'Inactive' ? '#ff4d4f' : 'gold'
              }
              key={text}
            >
              {text && text === 4
                ? translation(translationCache, 'Disable')
                : translation(translationCache, 'Enable')}
            </Tag>
          </>
        )
      },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            <Tooltip title={translation(translationCache, 'Edit')}>
              <Button
                disabled={record.Status === 'Inactive' ? true : false}
                style={{ marginRight: '5px' }}
                className="ant-btn"
                onClick={() => this.editJobPlan(record)}
                size="middle"
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Popconfirm
              title={translation(translationCache, constant.deleteJobPlan)}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this._deleteById(record.Id)}
              okText={translation(translationCache, constant.yes)}
              cancelText={translation(translationCache, constant.no)}
            >
              <Tooltip title={translation(translationCache, 'Delete')}>
                <Button className="ant-btn" size="middle">
                  <span style={{ color: '#ff4d4f' }}>
                    {' '}
                    <DeleteOutlined />
                  </span>
                </Button>
              </Tooltip>
            </Popconfirm>
          </>
        )
      }
    ];

    let config = {
      title: translation(translationCache, 'JobPlans'),

      viewTypes: ['add', 'card', 'table'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['JobPlanName'],

      firstTitle: 'JobPlanName',
      secondTitle: 'CreatedBy',
      thirdTitle: 'CreatedTime',

      actions: ['edit', 'delete'],
      edit: {
        byAttribute: 'Id',
        redirectURL: '/rubus/AMM/JobPlanDetails'
      },
      deletee: {
        byAttribute: 'Id'
      }
    };
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 143 }}>
        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(JobPlanList);
