import React from 'react';
import {
  Checkbox,
  Input,
  Select,
  Tabs,
  Radio,
  Button,
  Form,
  Drawer,
  InputNumber,
  Tooltip
} from 'antd';
import { DeleteOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { SelectDropDown } from './CSS/styles.js';
import { defaultGraphTypeProps, graphTypeList } from './DefaultGraphTypeProps';
import { DrawerFooter } from './CSS/styles.js';
import TagInputWidget from './Widgets/Selection/TagInputWidget';
import ColorInputWidget from './Widgets/Selection/ColorInputWidget';

const Option = Select.Option;
const TabPane = Tabs.TabPane;
// const { TextArea } = Input;

class ConfigurationModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      graph: '',
      type: 'historic',
      GraphLevelSelection: 'none',
      GraphLevelSelectionPoint: 'PreviousDay',
      GraphLevelTimeBucket: '',
      activeKey: '1',
      query: '',
      queryData: [],
      warningList: [],
      refreshTime: '',
      checked: false,
      enableExcel: false,
      num: '',
      inputGraphFields: {
        Day: '',
        Week: '',
        Month: '',
        Year: ''
      },
      TimeBucketKey: true,
      apiPayloadCount: {},
      apiPayloadData: {},
      deviceMultiSelect: false,
      apiPayloadDeviceSelection: {}
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.editData !== this.props.editData) {
      let editData = this.props.editData;
      if (editData.length > 0) {
        this.setState({
          ...editData[0],
          title: editData[0].title,
          graph: editData[0].graph,
          type: editData[0].type,
          GraphLevelSelection: editData[0].GraphLevelSelection,
          GraphLevelSelectionPoint: editData[0].GraphLevelSelectionPoint,
          GraphLevelTimeBucket: editData[0].GraphLevelTimeBucket,
          // inputGraphFields:
          //   editData[0].GraphLevelSelection === 'dateMonthYearPicker'
          //     ? editData[0].inputGraphFields
          //     : {},
          checked: editData[0].checked,
          query: editData[0].query,
          queryRowsToColumns: editData[0].queryRowsToColumns,
          enableMock: editData[0].enableMock,
          enableWarningsOnFS: editData[0].enableWarningsOnFS,
          warningList: editData[0].warningList,
          enableExcel: editData[0].enableExcel,
          refreshTime: editData[0].refreshTime,
          apiPayloadData: editData[0].apiPayloadData,
          apiPayloadCount: editData[0].apiPayloadCount,
          [editData[0].graph]: editData[0][editData[0].graph],
          deviceMultiSelect: editData[0].deviceMultiSelect
        });
      }
    }
  }

  renderWidget(graphTypeProps, index) {
    let { graph, num } = this.state;
    switch (graphTypeProps.Widget) {
      case 'Input':
        return num === 'string' && graphTypeProps.DisplayName === 'xAxisDateFormat' ? null : (
          <Form.Item key={index} label={graphTypeProps.DisplayName}>
            <Input
              value={
                this.state[graph] && this.state[graph][graphTypeProps.Key]
                  ? this.state[graph][graphTypeProps.Key]
                  : ''
              }
              onChange={(e) =>
                this.setState({
                  [graph]: {
                    ...this.state[graph],
                    [graphTypeProps.Key]: e.target.value
                  }
                })
              }
            />
          </Form.Item>
        );
      case 'Number':
        return num === 'string' && graphTypeProps.DisplayName === 'Interval' ? null : (
          <Form.Item key={index} label={graphTypeProps.DisplayName}>
            <InputNumber
              value={
                this.state[graph] && this.state[graph][graphTypeProps.Key]
                  ? this.state[graph][graphTypeProps.Key]
                  : ''
              }
              onChange={(e) =>
                this.setState({
                  [graph]: {
                    ...this.state[graph],
                    [graphTypeProps.Key]: e
                  }
                })
              }
            />
          </Form.Item>
        );
      case 'TextArea':
        return (
          <Form.Item key={index} label={graphTypeProps.DisplayName}>
            <Input.TextArea
              rows={3}
              value={
                this.state[graph] && this.state[graph][graphTypeProps.Key]
                  ? this.state[graph][graphTypeProps.Key]
                  : ''
              }
              onChange={(e) =>
                this.setState({
                  [graph]: {
                    ...this.state[graph],
                    [graphTypeProps.Key]: e.target.value
                  }
                })
              }
            />
          </Form.Item>
        );
      case 'Select':
        return (
          <Form.Item key={index} label={graphTypeProps.DisplayName}>
            <Select
              value={
                this.state[graph] && this.state[graph][graphTypeProps.Key]
                  ? this.state[graph][graphTypeProps.Key]
                  : ''
              }
              onChange={(value) => {
                this.setState({
                  [graph]: {
                    ...this.state[graph],
                    [graphTypeProps.Key]: value
                  },
                  num: value
                });
              }}
            >
              {graphTypeProps &&
                graphTypeProps.Options &&
                Array.isArray(graphTypeProps.Options) &&
                graphTypeProps.Options.map((optionObject, index) => {
                  return (
                    <Option key={index} value={optionObject}>
                      {optionObject}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        );
      case 'TagList':
        return (
          <Form.Item key={index} label={graphTypeProps.DisplayName}>
            <TagInputWidget
              graphTypeProps={graphTypeProps}
              value={
                this.state[graph] && this.state[graph][graphTypeProps.Key]
                  ? this.state[graph][graphTypeProps.Key]
                  : ''
              }
              addedTagList={(e) => this.addedTagList(e, graphTypeProps.Key)}
            />
          </Form.Item>
        );
      case 'ColorList':
        return (
          <Form.Item key={index} label={graphTypeProps.DisplayName}>
            <ColorInputWidget
              graphTypeProps={graphTypeProps}
              value={
                this.state[graph] && this.state[graph][graphTypeProps.Key]
                  ? this.state[graph][graphTypeProps.Key]
                  : ''
              }
              addedTagList={(e) => this.addedTagList(e, graphTypeProps.Key)}
            />
          </Form.Item>
        );
      default:
        return <div key={index}></div>;
    }
  }

  addedTagList = (valueList, key) => {
    let { graph } = this.state;
    this.setState({
      [graph]: {
        ...this.state[graph],
        [key]: valueList
      }
    });
  };

  addNextQuery = () => {
    let { queryData } = this.state;
    queryData.push(queryData.length + 1);
    this.setState({
      queryData
    });
  };

  handleRemoveFields = (keyName) => {
    const { inputGraphFields } = this.state;
    delete inputGraphFields[keyName];
    // values.splice(index, 1);
    this.setState(inputGraphFields);
  };

  handleInputChange = (event, item) => {
    const { inputGraphFields } = this.state;
    inputGraphFields[item] = event.target.value;
    this.setState({ inputGraphFields });
  };

  resetTimeBucket = () => {
    let { inputGraphFields } = this.state;
    let TimeBucket = {
      Day: '',
      Week: '',
      Month: '',
      Year: '',
      ...inputGraphFields
    };
    this.setState({ inputGraphFields: TimeBucket });
  };
  selectMethodAlertsData = (value) => {
    let { apiPayloadData } = this.state;
    this.setState({
      apiPayloadData: {
        ...apiPayloadData,
        method: value,
        ...(apiPayloadData && apiPayloadData.method === 'POST'
          ? { payload: apiPayloadData.payload }
          : {})
      }
    });
  };
  enterURLAlertsData = (e) => {
    let { apiPayloadData } = this.state;
    this.setState({
      apiPayloadData: {
        ...apiPayloadData,
        url: e.target.value,
        method: apiPayloadData && apiPayloadData.method ? apiPayloadData.method : '',
        ...(apiPayloadData && apiPayloadData.method === 'POST'
          ? { payload: apiPayloadData.payload }
          : {})
      }
    });
  };
  selectPayloadAlertsData = (e) => {
    // let value = JSON.parse(e.target.value);
    let { apiPayloadData } = this.state;
    this.setState({
      apiPayloadData: {
        ...apiPayloadData,
        payload: JSON.parse(e.target.value)
      }
    });
  };

  selectGetparamsAlertsData = (e) => {
    let { apiPayloadData } = this.state;
    this.setState({
      apiPayloadData: {
        ...apiPayloadData,
        GETParams: e.target.value
      }
    });
  };

  selectMethodDeviceSelection = (value) => {
    let { apiPayloadDeviceSelection } = this.state;
    this.setState({
      apiPayloadDeviceSelection: {
        ...apiPayloadDeviceSelection,
        method: value,
        ...(apiPayloadDeviceSelection && apiPayloadDeviceSelection.method === 'POST'
          ? { payload: apiPayloadDeviceSelection.payload }
          : {})
      }
    });
  };

  enterURLAlertsDeviceSelection = (e) => {
    let { apiPayloadDeviceSelection } = this.state;
    this.setState({
      apiPayloadDeviceSelection: {
        ...apiPayloadDeviceSelection,
        url: e.target.value,
        method:
          apiPayloadDeviceSelection && apiPayloadDeviceSelection.method
            ? apiPayloadDeviceSelection.method
            : '',
        ...(apiPayloadDeviceSelection && apiPayloadDeviceSelection.method === 'POST'
          ? { payload: apiPayloadDeviceSelection.payload }
          : {})
      }
    });
  };

  selectPayloadDeviceSelection = (e) => {
    // let value = JSON.parse(e.target.value);
    let { apiPayloadDeviceSelection } = this.state;
    this.setState({
      apiPayloadDeviceSelection: {
        ...apiPayloadDeviceSelection,
        payload: JSON.parse(e.target.value)
      }
    });
  };
  selectGetparamsDeviceSelection = (e) => {
    let { apiPayloadDeviceSelection } = this.state;
    this.setState({
      apiPayloadDeviceSelection: {
        ...apiPayloadDeviceSelection,
        GETParams: e.target.value
      }
    });
  };
  selectMethodAlertsCount = (value) => {
    let { apiPayloadCount } = this.state;
    this.setState({
      apiPayloadCount: {
        ...apiPayloadCount,
        method: value,
        ...(apiPayloadCount && apiPayloadCount.method === 'POST'
          ? { payload: apiPayloadCount.payload }
          : {})
      }
    });
  };
  enterURLAlertsCount = (e) => {
    let { apiPayloadCount } = this.state;
    this.setState({
      apiPayloadCount: {
        ...apiPayloadCount,
        url: e.target.value,
        method: apiPayloadCount && apiPayloadCount.method ? apiPayloadCount.method : '',
        ...(apiPayloadCount && apiPayloadCount.method === 'POST'
          ? { payload: apiPayloadCount.payload }
          : {})
      }
    });
  };
  selectPayloadAlertsCount = (e) => {
    let { apiPayloadCount } = this.state;
    this.setState({
      apiPayloadCount: {
        ...apiPayloadCount,
        payload: JSON.parse(e.target.value)
      }
    });
  };
  selectGetparamsAlertsCount = (e) => {
    let { apiPayloadCount } = this.state;
    this.setState({
      apiPayloadCount: {
        ...apiPayloadCount,
        GETParams: e.target.value
      }
    });
  };

  render() {
    let {
      activeKey,
      graph,
      title,
      refreshTime,
      checked,
      type,
      GraphLevelTimeBucket,
      GraphLevelSelectionPoint,
      GraphLevelSelection,
      queryData,
      inputGraphFields,
      TimeBucketKey
      // apiPayloadCount,
      // apiPayloadData
    } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };
    let defaultProps = {
      activeKey: '1',
      title: '',
      graph: '',
      type: '',
      GraphLevelSelection: '',
      GraphLevelSelectionPoint: 'PreviousDay',
      GraphLevelTimeBucket: '',
      query: '',
      queryData: [],
      queryRowsToColumns: false,
      enableMock: false,
      enableWarningsOnFS: false,
      warningList: [],
      refreshTime: '',
      checked: true,
      inputGraphFields: {
        Day: '',
        Week: '',
        Month: '',
        Year: ''
      },
      apiPayloadCount: {},
      apiPayloadData: {}
    };
    return (
      <Drawer
        width={1200}
        closable
        title="Panel Configuration"
        visible={this.props.visible}
        onClose={this.props.onCancel}
      >
        <Tabs activeKey={activeKey} onChange={(activeKey) => this.setState({ activeKey })}>
          <TabPane tab="Config" key="1">
            <Form {...formItemLayout}>
              <Form.Item label="Panel Name">
                <Input
                  type="text"
                  value={title}
                  onChange={(e) => this.setState({ title: e.target.value })}
                />
              </Form.Item>
              <Form.Item label="Graph Type">
                <SelectDropDown
                  onChange={(graph) => {
                    this.setState({ graph });
                  }}
                  value={graph}
                >
                  {graphTypeList &&
                    Array.isArray(graphTypeList) &&
                    graphTypeList.map((graphType, index) => {
                      return (
                        <Option key={index} value={graphType.value}>
                          {graphType.key}
                        </Option>
                      );
                    })}
                </SelectDropDown>
              </Form.Item>

              <Form.Item label="Type">
                <Radio.Group
                  value={type}
                  onChange={(e) => {
                    this.setState({
                      type: e.target.value
                    });
                  }}
                >
                  <Radio.Button value="live">Live</Radio.Button>
                  <Radio.Button value="historic">Historic</Radio.Button>
                  <Radio.Button value="summary">Summary</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="GraphLevel Selection Type">
                <Select
                  value={GraphLevelSelection}
                  onChange={(val) => {
                    if (val !== 'none') {
                      this.setState({
                        GraphLevelSelection: val,
                        TimeBucketKey: false,
                        GraphLevelTimeBucket: ''
                      });
                    } else {
                      this.setState({
                        GraphLevelSelection: val,
                        TimeBucketKey: true,
                        GraphLevelTimeBucket: ''
                      });
                    }
                  }}
                >
                  <Option value="datePicker">Date Selection</Option>
                  <Option value="dateRangePicker">DateRange Selection</Option>
                  <Option value="monthPicker">Month Selection</Option>
                  <Option value="dateMonthYearPicker">DayMonthYear Selection</Option>
                  <Option value="deviceSelection">Device Selection</Option>
                  <Option value="none">None</Option>
                </Select>
                {GraphLevelSelection && GraphLevelSelection === 'deviceSelection' ? (
                  <div>
                    <Form.Item>
                      <Select
                        value={
                          this.state &&
                          this.state.apiPayloadDeviceSelection &&
                          this.state.apiPayloadDeviceSelection
                            ? this.state.apiPayloadDeviceSelection.method
                            : ''
                        }
                        style={{ width: '200px' }}
                        placeholder={'Select Method'}
                        onChange={this.selectMethodDeviceSelection}
                      >
                        <Option value="GET">GET</Option>
                        <Option value="POST">POST</Option>
                      </Select>
                      <Input
                        style={{ width: '300px' }}
                        value={
                          this.state &&
                          this.state.apiPayloadDeviceSelection &&
                          this.state.apiPayloadDeviceSelection
                            ? this.state.apiPayloadDeviceSelection.url
                            : ''
                        }
                        placeholder="Enter URL"
                        onChange={this.enterURLAlertsDeviceSelection}
                      />
                      {this.state.apiPayloadDeviceSelection &&
                      this.state.apiPayloadDeviceSelection.method &&
                      this.state.apiPayloadDeviceSelection.method === 'POST' ? (
                        <Input.TextArea
                          style={{ width: '300px' }}
                          defaultValue={
                            this.state &&
                            this.state.apiPayloadDeviceSelection &&
                            this.state.apiPayloadDeviceSelection.payload &&
                            Object.keys(this.state.apiPayloadDeviceSelection.payload) &&
                            Object.keys(this.state.apiPayloadDeviceSelection.payload).length > 0 &&
                            JSON.stringify(this.state.apiPayloadDeviceSelection.payload)
                              ? JSON.stringify(this.state.apiPayloadDeviceSelection.payload)
                              : ''
                          }
                          placeholder="Enter payload"
                          onBlur={this.selectPayloadDeviceSelection}
                        />
                      ) : null}
                      {this.state &&
                      this.state.apiPayloadDeviceSelection &&
                      this.state.apiPayloadDeviceSelection.method &&
                      this.state.apiPayloadDeviceSelection.method === 'GET' ? (
                        <Input.TextArea
                          style={{ width: '300px' }}
                          defaultValue={
                            this.state &&
                            this.state.apiPayloadDeviceSelection &&
                            this.state.apiPayloadDeviceSelection &&
                            this.state.apiPayloadDeviceSelection.GETParams
                              ? this.state.apiPayloadDeviceSelection.GETParams
                              : ''
                          }
                          placeholder="Enter GETParams"
                          onBlur={this.selectGetparamsDeviceSelection}
                        />
                      ) : null}
                      {/* <TextArea
                              style={{ width: '300px' }}
                              defaultValue={
                                this.state &&
                                this.state.apiPayloadData &&
                                this.state.apiPayloadData.payload &&
                                Object.keys(this.state.apiPayloadData.payload) &&
                                Object.keys(this.state.apiPayloadData.payload).length > 0 &&
                                JSON.stringify(this.state.apiPayloadData.payload)
                                  ? JSON.stringify(this.state.apiPayloadData.payload)
                                  : ''
                              }
                              placeholder="Enter payload"
                              onBlur={this.selectPayloadAlertsData}
                            /> */}
                    </Form.Item>
                  </div>
                ) : null}
              </Form.Item>

              {GraphLevelSelection && GraphLevelSelection === 'deviceSelection' ? (
                <Form.Item label="Multi-Select">
                  <Checkbox
                    checked={this.state.deviceMultiSelect}
                    onChange={(e) => {
                      this.setState({ deviceMultiSelect: e.target.checked });
                    }}
                  ></Checkbox>
                </Form.Item>
              ) : null}

              {this.state && this.state.graph === 'DonutCounter' ? (
                <div>
                  <Form.Item label="Alerts Data">
                    <Select
                      value={
                        this.state && this.state.apiPayloadData && this.state.apiPayloadData
                          ? this.state.apiPayloadData.method
                          : ''
                      }
                      style={{ width: '200px' }}
                      placeholder={'Select Method'}
                      onChange={this.selectMethodAlertsData}
                    >
                      <Option value="GET">GET</Option>
                      <Option value="POST">POST</Option>
                    </Select>
                    <Input
                      style={{ width: '300px' }}
                      value={
                        this.state && this.state.apiPayloadData && this.state.apiPayloadData
                          ? this.state.apiPayloadData.url
                          : ''
                      }
                      placeholder="Enter URL"
                      onChange={this.enterURLAlertsData}
                    />
                    {this.state.apiPayloadData &&
                    this.state.apiPayloadData.method &&
                    this.state.apiPayloadData.method === 'POST' ? (
                      <Input.TextArea
                        style={{ width: '300px' }}
                        defaultValue={
                          this.state &&
                          this.state.apiPayloadData &&
                          this.state.apiPayloadData.payload &&
                          Object.keys(this.state.apiPayloadData.payload) &&
                          Object.keys(this.state.apiPayloadData.payload).length > 0 &&
                          JSON.stringify(this.state.apiPayloadData.payload)
                            ? JSON.stringify(this.state.apiPayloadData.payload)
                            : ''
                        }
                        placeholder="Enter payload"
                        onBlur={this.selectPayloadAlertsData}
                      />
                    ) : null}
                    {this.state &&
                    this.state.apiPayloadData &&
                    this.state.apiPayloadData.method &&
                    this.state.apiPayloadData.method === 'GET' ? (
                      <Input.TextArea
                        style={{ width: '300px' }}
                        defaultValue={
                          this.state &&
                          this.state.apiPayloadData &&
                          this.state.apiPayloadData &&
                          this.state.apiPayloadData.GETParams
                            ? this.state.apiPayloadData.GETParams
                            : ''
                        }
                        placeholder="Enter GETParams"
                        onBlur={this.selectGetparamsAlertsData}
                      />
                    ) : null}
                    {/* <TextArea
                      style={{ width: '300px' }}
                      defaultValue={
                        this.state &&
                        this.state.apiPayloadData &&
                        this.state.apiPayloadData.payload &&
                        Object.keys(this.state.apiPayloadData.payload) &&
                        Object.keys(this.state.apiPayloadData.payload).length > 0 &&
                        JSON.stringify(this.state.apiPayloadData.payload)
                          ? JSON.stringify(this.state.apiPayloadData.payload)
                          : ''
                      }
                      placeholder="Enter payload"
                      onBlur={this.selectPayloadAlertsData}
                    /> */}
                  </Form.Item>

                  <Form.Item label="Alerts Count">
                    <Select
                      value={
                        this.state && this.state.apiPayloadCount && this.state.apiPayloadCount
                          ? this.state.apiPayloadCount.method
                          : ''
                      }
                      style={{ width: '200px' }}
                      placeholder={'Select Method'}
                      onChange={this.selectMethodAlertsCount}
                    >
                      <Option value="GET">GET</Option>
                      <Option value="POST">POST</Option>
                    </Select>
                    <Input
                      style={{ width: '300px' }}
                      value={
                        this.state && this.state.apiPayloadCount && this.state.apiPayloadCount
                          ? this.state.apiPayloadCount.url
                          : ''
                      }
                      placeholder="Enter URL"
                      onChange={this.enterURLAlertsCount}
                    />
                    {this.state.apiPayloadCount &&
                    this.state.apiPayloadCount.method &&
                    this.state.apiPayloadCount.method === 'POST' ? (
                      <Input.TextArea
                        style={{ width: '300px' }}
                        defaultValue={
                          this.state &&
                          this.state.apiPayloadCount &&
                          this.state.apiPayloadCount.payload &&
                          Object.keys(this.state.apiPayloadCount.payload) &&
                          Object.keys(this.state.apiPayloadCount.payload).length > 0 &&
                          JSON.stringify(this.state.apiPayloadCount.payload)
                            ? JSON.stringify(this.state.apiPayloadCount.payload)
                            : ''
                        }
                        placeholder="Enter payload"
                        onBlur={this.selectPayloadAlertsCount}
                      />
                    ) : null}
                    {this.state &&
                    this.state.apiPayloadCount &&
                    this.state.apiPayloadCount.method &&
                    this.state.apiPayloadCount.method === 'GET' ? (
                      <Input.TextArea
                        style={{ width: '300px' }}
                        defaultValue={
                          this.state &&
                          this.state.apiPayloadCount &&
                          this.state.apiPayloadCount &&
                          this.state.apiPayloadCount.GETParams
                            ? this.state.apiPayloadCount.GETParams
                            : ''
                        }
                        placeholder="Enter GETParams"
                        onBlur={this.selectGetparamsAlertsCount}
                      />
                    ) : null}
                    {/* <TextArea
                      style={{ width: '300px' }}
                      defaultValue={
                        this.state &&
                        this.state.apiPayloadCount &&
                        this.state.apiPayloadCount.payload &&
                        Object.keys(this.state.apiPayloadCount.payload) &&
                        Object.keys(this.state.apiPayloadCount.payload).length > 0 &&
                        JSON.stringify(this.state.apiPayloadCount.payload)
                          ? JSON.stringify(this.state.apiPayloadCount.payload)
                          : ''
                      }
                      placeholder="Enter payload"
                      onBlur={this.selectPayloadAlertsCount}
                    /> */}
                  </Form.Item>
                </div>
              ) : null}

              {GraphLevelSelection &&
              GraphLevelSelection !== 'none' &&
              GraphLevelSelection !== 'deviceSelection' ? (
                <>
                  {GraphLevelSelection && GraphLevelSelection === 'dateMonthYearPicker' ? (
                    <div>
                      {inputGraphFields &&
                      Object.keys(inputGraphFields) &&
                      Object.keys(inputGraphFields).length > 0 ? (
                        Object.keys(inputGraphFields).map((item, index) => (
                          <div key={index}>
                            <Form.Item label={item}>
                              <input
                                style={{ width: '150px' }}
                                type="text"
                                id="Value"
                                value={inputGraphFields[item]}
                                onChange={(e) => this.handleInputChange(e, item)}
                              />
                              <MinusCircleOutlined
                                style={{ color: 'red', marginLeft: '20px' }}
                                onClick={() => this.handleRemoveFields(item)}
                              />
                              {index === 0 ? (
                                <Tooltip title={'Add Other Selections'}>
                                  <PlusOutlined
                                    style={{ color: 'green', marginLeft: '20px' }}
                                    onClick={this.resetTimeBucket}
                                  />
                                </Tooltip>
                              ) : null}
                            </Form.Item>
                          </div>
                        ))
                      ) : (
                        <Tooltip title={'Add Other Selections'}>
                          <PlusOutlined
                            style={{ color: 'green', marginLeft: '20px' }}
                            onClick={this.resetTimeBucket}
                          />
                        </Tooltip>
                      )}
                    </div>
                  ) : (
                    <Form.Item label="Time Bucket">
                      <Select
                        value={GraphLevelTimeBucket}
                        onChange={(value) => {
                          this.setState({
                            GraphLevelTimeBucket: value
                          });
                        }}
                        disabled={TimeBucketKey}
                      >
                        <option value="1m">1 Minute (1m)</option>
                        <option value="1h">1 Hour (1h)</option>
                        <option value="1d">1 Day (1d)</option>
                        <option value="1week">1 Week (1week)</option>
                        <option value="1month">1 Month (1month)</option>
                        <option value="1y">1 Year (1y)</option>
                        <option value="none">None</option>
                      </Select>
                    </Form.Item>
                  )}

                  <Form.Item label="GraphLevel Selection EndPoint">
                    <Select
                      value={GraphLevelSelectionPoint}
                      onChange={(e) => {
                        this.setState({
                          GraphLevelSelectionPoint: e
                        });
                      }}
                    >
                      <option value="PreviousDay">Previous Day</option>
                      <option value="CurrentDay">Current Day</option>
                    </Select>
                  </Form.Item>
                </>
              ) : null}

              {defaultGraphTypeProps &&
                defaultGraphTypeProps[graph] &&
                Array.isArray(defaultGraphTypeProps[graph]) &&
                defaultGraphTypeProps[graph].map((graphTypeProps, index) => {
                  return this.renderWidget(graphTypeProps, index);
                })}

              <Form.Item label="Refresh Time">
                <Input
                  type="text"
                  value={refreshTime}
                  onChange={(e) => {
                    this.setState({
                      refreshTime: e.target.value
                    });
                  }}
                />
              </Form.Item>

              <Form.Item label=" ">
                <Checkbox
                  checked={checked}
                  style={{ color: 'black' }}
                  onChange={(e) => {
                    this.setState({
                      checked: e.target.checked
                    });
                  }}
                >
                  Refresh TimeStamp
                </Checkbox>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Query" key="2">
            <div>
              <Checkbox
                checked={this.state.queryRowsToColumns}
                onChange={(value) => {
                  this.setState({
                    queryRowsToColumns: value.target.checked
                  });
                }}
              >
                Query RowsToColumns
              </Checkbox>
              <Checkbox
                checked={this.state.enableMock}
                onChange={(value) => {
                  this.setState({
                    enableMock: value.target.checked
                  });
                }}
              >
                Enable mock
              </Checkbox>
              <Checkbox
                checked={this.state.enableWarningsOnFS}
                onChange={(value) => {
                  this.setState({
                    enableWarningsOnFS: value.target.checked
                  });
                }}
              >
                Enable Warning On Fullscreen
              </Checkbox>

              <>
                {this.state.enableWarningsOnFS && this.state.enableWarningsOnFS === true ? (
                  <Input.TextArea
                    style={{ marginTop: '10px', width: '80%' }}
                    placeholder="Input DeviceTag Data (Ex:[{parameterName:pn1, device: d1,parameter:p1}] )"
                    autosize={{ minRows: 4 }}
                    onChange={(e) => {
                      this.setState({ warningList: e.target.value });
                    }}
                    value={this.state.warningList}
                  />
                ) : null}
              </>

              <Input.TextArea
                style={{ marginTop: '10px', width: '80%' }}
                placeholder="Query"
                autosize={{ minRows: 4 }}
                onChange={(e) => {
                  this.setState({ query: e.target.value });
                }}
                value={this.state.query}
              />

              {queryData && Array.isArray(queryData) && queryData.length < 2 ? (
                <Button type="primary" style={{ marginTop: '5px' }} onClick={this.addNextQuery}>
                  Add Query
                </Button>
              ) : null}

              {queryData &&
                Array.isArray(queryData) &&
                queryData.map((queryIndex, index) => {
                  return (
                    <>
                      <Input.TextArea
                        key={index}
                        style={{ marginTop: '10px', width: '80%' }}
                        placeholder={`Query ${queryIndex}`}
                        autosize={{ minRows: 4 }}
                        onChange={(e) => {
                          this.setState({ [`query${queryIndex}`]: e.target.value });
                        }}
                        value={this.state[`query${queryIndex}`]}
                      />
                      <DeleteOutlined
                        onClick={() => {
                          this.setState({
                            queryData: queryData.splice(queryIndex, 1)
                          });
                        }}
                      />
                    </>
                  );
                })}
            </div>
          </TabPane>
        </Tabs>
        <DrawerFooter>
          <Button
            style={{ marginRight: 8 }}
            onClick={() => {
              this.props.onCancel();
              this.setState({
                ...defaultProps
              });
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              this.props.onOk(this.state);
              this.setState({
                ...defaultProps
              });
            }}
            type="primary"
          >
            Save
          </Button>
        </DrawerFooter>
      </Drawer>
    );
  }
}

export default ConfigurationModalContent;
