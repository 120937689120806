import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { getChartDataWithSelection } from '../../../ChartCalls';
import { getTranslationCache } from '../../../../../../selectors/language';
import { defaultRefreshTime } from '../../../constant.json';
import { geoLocations } from '../../../../Mock/MockJson';
import { ErrorComponent, NoRecordComponent } from '../../../Utils';
import { getCurrentTimezone } from '../../../../../../selectors/layout';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
// import { iconData1, iconData2, iconData3, defaultIcon } from './custom/Icon';
// import { MASTERIMAGES } from '../../../../../../commons/api';

class GeoMapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      json: [],
      extraKeys: { Type: 'Running' }
    };
    this.timeTicket = null;
    this.abortController = new window.AbortController();
  }

  componentDidMount() {
    this.getData(this.state.extraKeys);

    const refreshTime = this.props.graphprops?.refreshTime
      ? this.props.graphprops.refreshTime * 60000
      : defaultRefreshTime;

    this.timeTicket = setInterval(() => {
      this.getData(this.state.extraKeys);
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.selectionDateLive !== this.props.selectionDateLive
    ) {
      this.getData(this.state.extraKeys);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeTicket);
    this.abortController.abort();
  }

  getData = async () => {
    let json = [];
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;

    this.setState({ loading: true });

    try {
      if (this.props.graphprops?.enableMock) {
        json = geoLocations;
      } else {
        json = await getChartDataWithSelection({
          graphprops: this.props.graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.props.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          selectionDateLive: this.props.selectionDateLive,
          currentTimeZone: this.props.currentTimeZone,
          extraKeys: this.state.extraKeys
        });
      }

      const uniqueJson = json.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => t.latitude === item.latitude && t.longitude === item.longitude)
      );

      this.setState({
        loading: false,
        errorInAPI,
        noDataInDB,
        json: uniqueJson
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      this.setState({
        loading: false,
        errorInAPI: true,
        noDataInDB: false
      });
    }
  };

  getIconByDataValue = (location) => {
    const { iconUrl } = location;
    if (iconUrl) {
      return L.icon({
        iconUrl: iconUrl,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34]
      });
    }
    return L.icon({
      iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34]
    });
  };

  render() {
    const defaultLatitude = 20.5937;
    const defaultLongitude = 78.9629;
    const { errorInAPI, noDataInDB, json, loading } = this.state;

    return (
      <Fragment>
        {loading ? (
          <div>Loading...</div>
        ) : noDataInDB || errorInAPI ? (
          noDataInDB ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <Map
            center={[defaultLatitude, defaultLongitude]}
            zoom={2}
            style={{ height: '600px', width: '100%' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {json.map((location, index) => (
              <Marker
                key={`${location.latitude}-${location.longitude}-${index}`}
                position={[
                  location.latitude || defaultLatitude,
                  location.longitude || defaultLongitude
                ]}
                // icon={this.getIconByDataValue(location.datavalue, location.iconUrl)}
                icon={this.getIconByDataValue(location)}
              >
                <Popup>
                  {location.name} <br />
                  Maximum Power: {location.datavalue !== undefined ? location.datavalue : 'NoData'}
                </Popup>
              </Marker>
            ))}
          </Map>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(GeoMapComponent);

// https://codeshare.io/Q8djYx
