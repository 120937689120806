import { Button, Select } from 'antd';
import styled from 'styled-components';

export const Title = styled.h3`
  color: white;
  font-family: Roboto-Medium;
  text-align: center;
  font-size: 0.9em;
  padding: 0.7em 0em;
  margin: 0px;
`;

export const PanelDiv = styled.div`
  background-color: #0d1115;
`;

export const EditDashboardDiv = styled.div`
  background-color: rgb(9, 26, 45);
  min-height: 100vh;
  margin-top: -58px;
`;

export const DashboardTheme = styled(Select)`
  float: right;
`;

export const AddPanelBtn = styled(Button)`
  margin-left: 20px;
  float: left;
`;

export const DeletePanelDiv = styled.div`
  text-align: center;
  margin-top: 15px;
`;

export const AddConfigurationDiv = styled.div`
  text-align: center;
`;
