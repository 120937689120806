import { API_MASTERCONFIGURATION, REPORTS } from '../../../commons/api';
import localStorage from '../../../utils/localStorage';

export async function getReportTypeList() {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      MasterCode: 'Report_Types',
      ActionType: 'read',
      Type: 'WithoutSiteId'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function _getReportList() {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`${REPORTS.Get}?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}
