import styled from 'styled-components';
import { Menu, Layout, Table, Button, Tabs, Form } from 'antd';

const { Sider } = Layout;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
export const StyledFloatLeft = styled.div`
  float: left;
`;
export const StyledFloatRight = styled.div`
  float: right;
`;
export const StyledWrapperRight = styled.div`
  margin-left: 2%;
  width: 100%;
  padding: 50px;
`;
export const StyledWrapperLeft = styled.div`
  color: #595959;
  width: 25%;
`;
export const StyledMenu = styled(Menu)`
  background-color: ${(props) => props.theme.primaryColor};
  border-right: 0;
  color: ${(props) => props.theme.color};
`;
export const StyledSider = styled(Sider)`
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.color};
`;

export const StyledHeading = styled.h2`
  font-size: 26;
`;

export const StyledComponent = styled.div`
  background: #1a3652 !important;
  padding: 25px;
`;

export const StyledTable = styled(Table)`
  caption-side: top;
  color: white !important;
  width: 100%;
  caption-side: bottom;
  td,
  th {
    border: none;
  }
  td {
    padding: 11px 10px;
  }
  .ant-table-thead > tr > th{
    font-weight:bold !important
    font-size: 1em !important;
    color:white !important;
    background: rgb(26, 59, 92) ;
  }
  text[style] {
    font-size: 1em !important;
    color: white !important;
 }
  tbody tr {
    :nth-of-type(odd) {
      background-color:#efefef !important;
    }
    :hover {
      background-color:rgb(26, 59, 92) !important; 
    }
  }
  tbody tr {
    :nth-of-type(even) {
      background-color: #d9d9d9 !important;
    }
    :hover {
      background-color: #f5f5f5 !important; 
    }
  }
  thead > tr {
    color: white !important;
    background-color: #262626 !important;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

export const DrawerFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
`;

export const StyledButton = styled(Button)`
  background: #1a3652 !important;
  color: white !important;
  display: flex;
  justify-content: right;
  margin-bottom: 10px;
`;

export const StyledContentRight = styled.div`
  display: flex !important;
  justify-content: end !important;
`;
export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
  }

  .ant-tabs-tab-remove {
    flex: none;
    margin-right: -4px;
    margin-left: 8px;
    font-size: 12px;
    background: #1a3b5c;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    color: white !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.form.tabs.color};
    text-shadow: 0 0 0.25px currentcolor;
  }
`;

export const StyledForm = styled(Form)`
  .ant-divider-horizontal.ant-divider-with-text {
    display: flex;
    margin: 16px 0;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-top: 0;
  }

  .ant-form-item-label > label {
    color: ${(props) => props.theme.form.formLabel} !important;
    font-weight: revert;
    font-size: ${(props) => props.theme.form.formLabelSize};
    font-weight: bold;
  }

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.66);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }

  .ant-tabs-content-holder {
    border-radius: 0 5px 5px 5px;
  }
`;
