import { API_PARAMETER, API_DEVICETYPE } from '../../commons/api';
import localStorage from '../../utils/localStorage';

export default class deviceType {
  static getDeviceTypeList() {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    return fetch(`${API_DEVICETYPE.GET}?SiteId=${siteId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static saveDeviceType(payload) {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    return fetch(`${API_DEVICETYPE.CREATE}?SiteId=${siteId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ ...payload, SiteId: siteId })
    }).then(responseToken);
    function responseToken(response) {
      response.json();
    }
  }

  static updateDeviceType(payload) {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    return fetch(`${API_DEVICETYPE.UPDATE}?Id=${payload.Id}&SiteId=${siteId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload)
    }).then(responseToken);
    function responseToken(response) {
      response.json();
    }
  }

  static deviceParameterCURD(payload) {
    if (payload) {
      Object.keys(payload).map((parameterKey) => {
        let parameter = payload[parameterKey];
        if (parameter.Id) {
          updateDeviceParameters(parameter);
        } else {
          saveDeviceParameters(parameter);
        }
        return {};
      });
    }

    function saveDeviceParameters(payload) {
      const siteId = localStorage.get('currentSite');
      const accessToken = localStorage.get('accessToken');
      return fetch(`${API_PARAMETER.CREATE}?SiteId=${siteId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...payload, SiteId: siteId })
      }).then(responseToken);
      function responseToken(response) {
        return response.json();
      }
    }

    function updateDeviceParameters(payload) {
      const siteId = localStorage.get('currentSite');
      const accessToken = localStorage.get('accessToken');
      return fetch(`${API_PARAMETER.UPDATE}?Id=${payload.Id}&SiteId=${siteId}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(responseToken);
      function responseToken(response) {
        return response.json();
      }
    }
  }

  static deleteDeviceParameter(payload) {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`${API_DEVICETYPE.DELETE}?Id=${payload}&SiteId=${siteId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      response.json();
    }
  }

  static getDeviceTypeCMList() {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`${API_DEVICETYPE.CACHE}?SiteId=${siteId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static deleteDeviceType(deviceTypeId) {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`${API_DEVICETYPE.DELETE}?Id=${deviceTypeId}&SiteId=${siteId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response;
    }
  }
}
