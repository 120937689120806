import axios from 'axios';
import { message } from 'antd';

import localStorage from '../../../../../utils/localStorage';
import {
  API_ASSET,
  API_DEVICE,
  API_PARAMETER,
  API_DEVICETYPE,
  API_MASTERCONFIGURATION,
  API_SITE,
  API_DEVICEPARAMETER,
  API_MASTERWORKORDERS
} from '../../../../../commons/api';

export const _getAssetsBasedOnAssetID = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_ASSET.GET}?SiteId=${siteId}&AssetType=${data.AssetType}&ParentAsset=${data.parentAssetId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};
export const getSitesList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_SITE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.status && err.response.status === 400) {
        message.info(err && err.response && err.response.data && err.response.data.message);
      }
    });
};
export const _saveAssetData = async (payload, fileData) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const Object = {
    method: 'POST',
    url: `${API_ASSET.CREATE}?SiteId=${siteId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };

  return axios(Object)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${
            response.data.Id
          }&Type=${'Asset'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: Authorization
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch(() => {});
      } else {
        return response.data;
      }
    })
    .catch((err) => {
      message.error(err.response.data.message);
    });
};

export const _getHierarchyJSONAPI = () => {
  // const siteId = localStorage.get('currentSite')
  // const accessToken = localStorage.get('accessToken')
  // const Object = {
  //   method: 'GET',
  //   url: `${API_HIERARCHY.LIST}?SiteId=${siteId}`,
  //   headers: {
  //     Authorization: `Bearer ${accessToken}`
  //   }
  // }
  // return axios(Object)
  //   .then((response) => {
  //     return response.data
  //   }).catch(function () {
  //     return []
  //   });
  return {
    asset: {
      location: {
        name: 'location',
        parent: null
      },
      subLocation: {
        name: 'Sub-Location',
        parent: ['location', 'subLocation']
      },
      asset: {
        name: 'asset',
        parent: ['location', 'subLocation', 'asset']
      }
    },
    device: {
      Parent: {
        name: 'Parent',
        asset: true,
        parent: ['location', 'subLocation', 'asset']
      },
      Child: {
        name: 'Child',
        parent: ['Parent', 'Child']
      },
      'Sub-Equipment': {
        name: 'Sub-Equipment',
        parent: ['Parent', 'Child', 'Sub-Equipment']
      }
    }
  };
};

export const _updateAssetData = (payload, fileData) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'PATCH',
    url: `${API_ASSET.UPDATE}?SiteId=${siteId}&Id=${payload.Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      if (fileData !== undefined && fileData.length !== 0) {
        let files = {
          url: `${API_MASTERWORKORDERS.SaveFile}?SiteId=${siteId}&Id=${
            response.data.Id
          }&Type=${'Asset'}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: fileData
        };
        return axios(files)
          .then((response) => {
            return response.data;
          })
          .catch(() => {});
      } else {
        return response.data;
      }
    })
    .catch(() => {});
};
export const _getDeviceTypesList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_DEVICETYPE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(function () {
      return [];
    });
};

export const _getDeviceParameterBasedOnType = (deviceTypeId) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const deviceTypeObject = {
    method: 'GET',
    url: `${API_PARAMETER.GET}?DeviceType=${deviceTypeId}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(deviceTypeObject)
    .then((response) => {
      return response.data;
    })
    .catch(function () {
      return [];
    });
};

export const _getDeviceBasedOnAssetID = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_DEVICE.LIST}?SiteId=${siteId}
    ${data.parentAssetId ? `&Asset=${data.parentAssetId}` : ``}
    ${data.parentDeviceId ? `&ParentEquipment=${data.parentDeviceId}` : ``}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400) {
        message.info(err.response.data.message);
      }
    });
};

export const _saveDeviceData = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `${API_DEVICE.CREATE}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
};
export const _updateDeviceData = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'PATCH',
    url: `${API_DEVICE.UPDATE}?SiteId=${siteId}&Id=${data.Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
};

export const _tagReferenceList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  try {
    const uomObject = {
      method: 'POST',
      url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        MasterCode: 'Parameter_References',
        ActionType: 'read',
        Type: 'WithoutSiteId'
      }
    };
    return axios(uomObject)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  } catch (err) {
    return err;
  }
};

export const _saveDeviceParamters = (payload) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const uomObject = {
    method: 'POST',
    url: `${API_PARAMETER.CREATE}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(uomObject)
    .then(() => {
      message.success('Condition & Tag references has been updated');
    })
    .catch((error) => {
      console.log(error);
    });
};

export const _deleteDeviceParameters = (deviceParameterId) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const header = {
    method: 'DELETE',
    url: `${API_DEVICEPARAMETER.DeleteAlias}?SiteId=${siteId}&Id=${deviceParameterId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const _deleteDevice = (deviceId) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const header = {
    method: 'DELETE',
    url: `${API_DEVICE.DELETE}?SiteId=${siteId}&Id=${deviceId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
};

export const _deleteAssetObject = (assetId) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const header = {
    method: 'DELETE',
    url: `${API_ASSET.DELETE}?SiteId=${siteId}&Id=${assetId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 400 || error.response.status === 500) {
        message.info(error.response.data.message);
      }
    });
};
export async function getMasterById(id) {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_ASSET.GET}?Id=${id}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
