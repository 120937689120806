import React from 'react';
import axios from 'axios';
import { Upload, message, Table, Row, Col, Typography } from 'antd';
import { DownOutlined, InboxOutlined } from '@ant-design/icons';

import localStorage from '../../../../utils/localStorage';
import { StyledAddButton } from './styles';
import './style.css';
import { StyledDashboard } from './styles';
import { StyledTable } from '../MaximoEdgeConfiguration/styles';
import { API_DOWNLOAD, IPAddress, MAXIMO } from '../../../../commons/api';
// const { Option } = Select;

class FileUploadAndDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      maximoDataSource: [],
      configType: 'deviceConfig'
    };
  }

  componentDidMount = () => {
    this.getFile('deviceConfig');
    this.getMaximoList('deviceConfig');
  };

  getFile = (value) => {
    let accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');
    const headers = {
      method: 'GET',
      url: `${MAXIMO.Download}?configtype=${value}&SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };

    axios(headers)
      .then((response) => {
        let fileList = [];
        fileList.push(response.data);
        this.setState({ fileName: fileList[0].file });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getMaximoList = (value) => {
    let accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');
    const headers = {
      method: 'GET',
      url: `${MAXIMO.List}?configtype=${value}&SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };

    axios(headers)
      .then((response) => {
        this.setState({ maximoDataSource: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isCSV =
      file.type === 'text/csv' ||
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
    if (!isCSV) {
      message.error('You can only upload CSV file!');
    }
    if (!isLt2M) {
      message.error('CSV file must be smaller than 10MB!');
    }
    return isLt2M && isCSV;
  };
  handleChange = (info) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
      this.getFile('deviceConfig');
      this.getMaximoList('deviceConfig');
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  DeviceConfig = () => {
    this.setState({
      deviceConfig: 'DeviceConfig'
    });
  };
  AssetConfig = () => {
    this.setState({
      deviceConfig: 'EdgeConfig'
    });
  };
  onHandleChanege = (value) => {
    this.setState({ configType: value });
    this.getFile(value);
    this.getMaximoList(value);
  };

  render() {
    const { maximoDataSource, fileName, configType } = this.state;

    const maximoCol = [
      {
        title: 'Device',
        dataIndex: 'Device',
        key: 'Device'
      },
      {
        title: 'Parameter Code',
        dataIndex: 'ParameterCode',
        key: 'ParameterCode'
      },
      {
        title: 'Site',
        dataIndex: 'Site',
        key: 'Site'
      },
      {
        title: 'Location',
        dataIndex: 'Location',
        key: 'Location'
      },
      {
        title: 'Asset',
        dataIndex: 'Asset',
        key: 'Asset'
      },
      {
        title: 'Meter',
        dataIndex: 'Meter',
        key: 'Meter'
      },
      {
        title: 'Meter Type',
        dataIndex: 'MeterType',
        key: 'MeterType'
      },
      {
        title: 'Lower Action',
        dataIndex: 'LowerAction',
        key: 'LowerAction'
      },
      {
        title: 'Lower Warning',
        dataIndex: 'LowerWarning',
        key: 'LowerWarning'
      },
      {
        title: 'Upper Warning',
        dataIndex: 'UpperWarning',
        key: 'UpperWarning'
      },
      {
        title: 'UpperAction',
        dataIndex: 'UpperAction',
        key: 'UpperAction'
      },
      {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status'
      }
    ];
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    var siteId = localStorage.get('currentSite');
    return (
      <StyledDashboard className="EAMForm" style={{ minHeight: window.innerHeight - 173 }}>
        {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ float: 'right' }}>
          <Form className="collapse">
            <Form.Item
              label={<span style={{ fontWeight: 'bold' }}>Select Configuration Type :</span>}
              rules={[{ required: true, message: 'Please enter PayloadType' }]}
            >
              <Select
                value={this.state.configType}
                style={{ width: '200px' }}
                placeholder="Please select Configuration Type "
                onChange={(e) => this.onHandleChanege(e)}
              >
                <Option value="deviceConfig">Device Configuration</Option>
                <Option value="edgeConfig">Edge Configuration</Option>
              </Select>
            </Form.Item>
          </Form>
        </Row> */}
        <Row>
          <Col span={7}>
            {' '}
            <div style={{ fontWeight: 'bold', fontSize: '18px', marginLeft: '5%' }}>
              File Download
            </div>
            <div className="ant-divider ant-divider-horizontal" role="separator"></div>{' '}
            {fileName !== '' || !undefined ? (
              <a
                href={`${IPAddress.LOCAL}${API_DOWNLOAD.DownloadAMS}/${fileName}?configtype=${configType}`}
              >
                {' '}
                <StyledAddButton type={'primary'}>
                  <DownOutlined /> {fileName}
                </StyledAddButton>{' '}
              </a>
            ) : (
              <StyledAddButton>No Files</StyledAddButton>
            )}
          </Col>
          <Col offset={1} span={14}>
            <div style={{ fontWeight: 'bold', fontSize: '18px' }}>File Upload</div>
            <div className="ant-divider ant-divider-horizontal" role="separator"></div>
            <div className="antUpload" style={{ fontSize: '30px' }}>
              <Upload.Dragger
                name="file"
                accept="text/csv"
                action={`${MAXIMO.UPLOAD}?configtype=${configType}&SiteId=${siteId}`}
                headers={{
                  Authorization: Authorization
                }}
                multiple="false"
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">1. Your can only upload .CSV file</p>
                <p className="ant-upload-hint">2. File should be less than 10MB</p>
              </Upload.Dragger>
            </div>
          </Col>
        </Row>

        <div style={{ marginTop: '2%' }}>
          <Typography.Title level={5} style={{ margin: 0, borderBottom: '1px solid #fff' }}>
            {' '}
            Maximo List{' '}
          </Typography.Title>

          <div className="ant-divider ant-divider-horizontal" role="separator"></div>
          <StyledTable>
            <Table
              className="table"
              columns={maximoCol}
              size="large"
              pagination={
                maximoDataSource.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : 1
              }
              dataSource={maximoDataSource}
            ></Table>
          </StyledTable>
        </div>
      </StyledDashboard>
    );
  }
}
export default FileUploadAndDownload;
