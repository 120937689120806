import axios from 'axios';
import { message } from 'antd';

import localStorage from '../../../utils/localStorage';
import {
  API_ASSET,
  API_DEVICE,
  API_PARAMETER,
  API_DEVICETYPE,
  API_MASTERCONFIGURATION,
  API_DEVICEPARAMETER
} from '../../../commons/api';

export const _getAssetsBasedOnAssetID = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_ASSET.GET}?SiteId=${siteId}&AssetType=${data.AssetType}&ParentAsset=${data.parentAssetId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
};

export const _saveAssetData = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `${API_ASSET.CREATE}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
};

export const _getHierarchyJSONAPI = () => {
  // const siteId = localStorage.get('currentSite');
  // const accessToken = localStorage.get('accessToken');
  // const Object = {
  //   method: 'GET',
  //   url: `${API_HIERARCHY.GET}?SiteId=${siteId}`,
  //   headers: {
  //     Authorization: `Bearer ${accessToken}`
  //   }
  // };
  // return axios(Object)
  //   .then((response) => {
  //     return response.data;
  //   }).catch(function () {
  //     return [];
  //   });
  return {
    asset: {
      location: {
        name: 'location',
        parent: null
      },
      subLocation: {
        name: 'Sub-Location',
        parent: ['location', 'subLocation']
      },
      asset: {
        name: 'asset',
        parent: ['location', 'subLocation', 'asset']
      }
    },
    device: {
      Parent: {
        name: 'Parent',
        asset: true,
        parent: ['location', 'subLocation', 'asset']
      },
      Child: {
        name: 'Child',
        parent: ['Parent', 'Child']
      },
      'Sub-Equipment': {
        name: 'Sub-Equipment',
        parent: ['Parent', 'Child', 'Sub-Equipment']
      }
    }
  };
};

export const _updateAssetData = (payload) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'PATCH',
    url: `${API_ASSET.UPDATE}?SiteId=${siteId}&Id=${payload.Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
};
export const _getDeviceTypesList = () => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${API_DEVICETYPE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(function () {
      return [];
    });
};

export const _getDeviceParameterBasedOnType = (deviceTypeId) => {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const deviceTypeObject = {
    method: 'GET',
    url: `${API_PARAMETER.GET}?DeviceType=${deviceTypeId}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(deviceTypeObject)
    .then((response) => {
      return response.data;
    })
    .catch(function () {
      return [];
    });
};

export const _getDeviceParameterType = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const ParameterTypeObject = {
    method: 'GET',
    url: `${API_DEVICEPARAMETER.GetParameterTypes}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(ParameterTypeObject)
    .then((response) => {
      return response.data;
    })
    .catch(function () {});
};

export const _getDeviceParameterBasedonDevice = (AliasCode) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const deviceTypeObject = {
    method: 'POST',
    url: `${API_DEVICE.GetDeviceParamsByCategory}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      DeviceCode: [AliasCode],
      Type: 'Device'
    }
  };
  return axios(deviceTypeObject)
    .then((response) => {
      return response.data;
    })
    .catch(function () {
      return [];
    });
};

export const _getDeviceBasedOnAssetID = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${API_DEVICE.GET}?SiteId=${siteId}${
      data.parentAssetId ? `&Asset=${data.parentAssetId}` : ``
    }${data.parentDeviceType ? `&EquipmentType=${data.parentDeviceType}` : ``}${
      data.parentDeviceId ? `&ParentEquipment=${data.parentDeviceId}` : ``
    }`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
};

export const _saveDeviceData = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'POST',
    url: `${API_DEVICE.CREATE}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
export const _updateDeviceData = (data) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'PATCH',
    url: `${API_DEVICE.UPDATE}?SiteId=${siteId}&Id=${data.Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: data
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const _CreateLinkDeviceParameter = (data, AliasCode) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  try {
    const uomObject = {
      method: 'POST',
      url: `${API_DEVICEPARAMETER.EnterAlias}?SiteId=${siteId}&DeviceAlias=${AliasCode}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(uomObject)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          message.info(error.response.data.message);
        }
      });
  } catch (err) {
    return err;
  }
};

export const _getParameterGroupList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  try {
    const parameterGroup = {
      method: 'GET',
      url: `${API_DEVICEPARAMETER.GetParameterGroups}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(parameterGroup)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  } catch (err) {
    return err;
  }
};

export const getTagReferenceList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  try {
    const parameterReferences = {
      method: 'POST',
      url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        MasterCode: 'Parameter_References',
        ActionType: 'read',
        Type: 'WithoutSiteId'
      }
    };
    return axios(parameterReferences)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  } catch (err) {
    return err;
  }
};

export const _getParameterCategoryList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  try {
    const uomObject = {
      method: 'GET',
      url: `${API_DEVICEPARAMETER.GetParameterCategory}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(uomObject)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  } catch (err) {
    return err;
  }
};

export const _tagReferenceList = () => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  try {
    const uomObject = {
      method: 'POST',
      url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        MasterCode: 'Parameter_References',
        ActionType: 'read',
        Type: 'WithoutSiteId'
      }
    };
    return axios(uomObject)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  } catch (err) {
    return err;
  }
};

export const _UpdateLinkDeviceParameter = (saveDeviceParameter, AliasCode) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const uomObject = {
    method: 'PATCH',
    url: `${API_DEVICEPARAMETER.UpdateAlias}?SiteId=${siteId}&DeviceAlias=${AliasCode}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: { ...saveDeviceParameter, Parameter: saveDeviceParameter.ParameterCode }
  };
  return axios(uomObject)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      if (error.response.status === 400 || error.response.status === 500) {
        message.info(error.response.data.message);
      }
    });
};

export const _saveDeviceParamters = (payload) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const uomObject = {
    method: 'POST',
    url: `${API_PARAMETER.CREATE}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(uomObject)
    .then((response) => {
      if (response && response.data && response.data.message)
        message.success(response.data.message);
    })
    .catch((error) => {
      if (error.response.status === 400 || error.response.status === 500) {
        message.info(error.response.data.message);
      }
    });
};

export const _deleteDeviceParameters = (deviceParameterId) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const header = {
    method: 'DELETE',
    url: `${API_DEVICEPARAMETER.DeleteAlias}?SiteId=${siteId}&Id=${deviceParameterId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const _deleteDevice = (deviceId) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const header = {
    method: 'DELETE',
    url: `${API_DEVICE.DELETE}?SiteId=${siteId}&Id=${deviceId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const _deleteAssetObject = (assetId) => {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const header = {
    method: 'DELETE',
    url: `${API_ASSET.DELETE}?SiteId=${siteId}&Id=${assetId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
