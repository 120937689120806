import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import localStorage from '../../../../../utils/localStorage';
import history from '../../../../../commons/history';
import { Form, message, Select } from 'antd';
import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  submitWorkOrder,
  getJobPlansList,
  getJobPlanById,
  // getWorkOrderName,
  getLocationByAsset,
  getWorkTypes,
  getStatus,
  getWorkOrderById
} from '../../../Utils/FormCalls';
import WorkOrderJobPlanesTask from './WorkOrderJobPlanesTask';
import { decode as base64_decode } from 'base-64';
import translation from '../../../Utils/translation';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { StyledComponent, StyledForm, StyledButton, StyledSubForm } from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import { getTranslationCache } from '../../../../../selectors/language';
import { constant } from '../../../Utils/constants';
const { Option } = Select;

class WorkOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      workTypesList: [],
      statusList: [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      activeKey: '1',
      selectedJobPlan:
        props.formObject && props.formObject.JobPlanName ? props.formObject.JobPlanName : '',
      jobplanActivekey: '1',
      noJobplan: false,
      translationCache: props.translationCache || {}
    };
  }
  // formRef = React.createRef();
  workOrderRef = React.createRef();

  componentDidMount = async () => {
    this._getDataById();
    // try {
    let deviceList = await getDeviceList();
    this.setState({ deviceList: deviceList });
    let organisationsList = await getOrganisationsList();
    this.setState({ organisationsList: organisationsList });
    let sitesList = await getSitesList();
    let formObject = this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
    };
    this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ sitesList: sitesList, formObject });
    let JobPlansList = await getJobPlansList('Active');
    //  JobPlansList = filterJobPlanByReference(JobPlansList, [formObject["JobPlan"]]);
    this.setState({ JobPlansList: JobPlansList });
    let workTypes = await getWorkTypes();
    this.setState({ workTypesList: workTypes });
    let statusList = await getStatus();
    this.setState({ statusList });
    if (formObject && formObject.Id) {
      // let workOrderName = await getWorkOrderName();
      // if (workOrderName && workOrderName['WorkOrder']) {
      let WAPPRStatus =
        statusList && Array.isArray(statusList)
          ? statusList.filter((status) => status.Status === 'WAPPR')
          : [];
      let StatusId = WAPPRStatus && WAPPRStatus[0] ? WAPPRStatus[0].Id : undefined;
      this.handleChange(formObject, 'workOrder');
      this.handleChange(StatusId, 'StatusId');
      // }
    }
    // this.

    // } catch (error) {
    //     message.warn(`${error.message}`);
    // }
  };
  _getDataById = async () => {
    let formObject = {};
    let path = history.location.pathname.split('/rubus/AMM/WorkOrderTaskDetails/');
    let formId = base64_decode(path[1]);
    if (formId !== 'new') {
      formObject = await getWorkOrderById(base64_decode(path[1]), 'withId');
      _.cloneDeepWith(formObject, (value) => {
        return value !== null && value.StartTime
          ? {
              ...value,
              ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
              ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
            }
          : _.noop();
      });
    }
    this.setState({
      formObject,
      selectedJobPlan: formObject && formObject.JobPlanName ? formObject.JobPlanName : ''
    });
    if (formObject && this.workOrderRef.current) {
      this.workOrderRef.current.setFieldsValue(formObject);
    }
  };
  componentDidUpdate = async (prevProps) => {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.workOrderRef.current.setFieldsValue(this.props.formObject);
      // this.formRef.current.setFieldsValue(this.props.formObject.TaskData)
    }

    if (prevProps.formObject !== this.props.formObject) {
      if (this.props.formObject && !this.props.formObject.WorkOrder) {
        // let workOrderName = await getWorkOrderName();
        this.handleChange(this.props.formObject.Id, 'workOrder');
      }
      this.setState({
        formObject: this.props.formObject
      });
    }
  };

  handleSelect = (value, mainIndex, subindex, keyName) => {
    // let list = this.formRef.current.getFieldValue("TaskData")
    let list = {};
    let TaskData = list;
    TaskData.map((item, index) => {
      if (index === mainIndex) {
        item[keyName][subindex]['TotalQuantity'] = value;
      }
      return {};
    });
    // this.formRef.current.setFieldsValue({ "TaskData": TaskData })
  };
  handleChange = async (value, key) => {
    let { deviceList } = this.state;
    let formObject = this.workOrderRef.current.setFieldsValue();
    formObject = {
      ...formObject,
      [key]: value
    };
    if (key === 'Device') {
      let selectedDevice = deviceList.filter((item) => item.AliasCode === value);
      let location = await getLocationByAsset(selectedDevice[0].Asset);
      formObject = {
        ...formObject,
        [key]: value,
        LocationId: location.Name
      };
      // this.workOrderRef.current.setFieldsValue({"LocationId":location["Name"]})
    }
    // if(key === 'StatusId')
    // {
    //   if(value===2)
    // }

    this.workOrderRef.current &&
      this.workOrderRef.current !== null &&
      this.workOrderRef.current.setFieldsValue(formObject);

    this.setState({ [key]: value, formObject });
  };

  onFinish = async (values, key, fileData) => {
    let formObject = this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      [key]: {
        ...formObject[key],
        ...values
      }
    };
    this.setState({ formObject, fileData, noJobplan: true });
    this.workOrderRef.current &&
      this.workOrderRef.current !== null &&
      this.workOrderRef.current.setFieldsValue(formObject);
  };
  onFormsubmit = async () => {
    let { formObject, noJobplan, fileData } = this.state;
    this.workOrderRef.current.getFieldsValue();
    formObject = {
      ...this.state.formObject
      // ...data
    };
    if (!formObject.JobPlan) {
      message.info('Please Provide JobPlan Or Task');
    } else if (noJobplan === true) {
      let statusResponse = await submitWorkOrder(formObject, fileData);
      if (statusResponse && statusResponse.message) {
        // history.goBack();
        // history.push(`/rubus/AMM/WorkOrderList`);
        this._getDataById();
        message.success('WorkOrder Submitted Successfully');
        // this.onReset()
      } else if (statusResponse && statusResponse.Error) {
        message.error(statusResponse.Error);
      }
    } else {
      message.error('Please save before you submit the Workorder');
    }
  };
  onReset = () => {
    // this.formRef.current.resetFields();
    this.workOrderRef.current.resetFields();
  };
  // onFinishFailed = () => {
  //   message.error('Please enter mandatory fields');
  // };
  jobPlanOnChange = async (value) => {
    let jobplanData = await getJobPlanById(value, 'withoutId');
    let formObject =
      this.workOrderRef.current !== null && this.workOrderRef.current.getFieldsValue()
        ? this.workOrderRef.current.getFieldsValue()
        : {};
    _.cloneDeepWith(jobplanData['TaskData'], (value) => {
      return value !== null && value.StartTime
        ? {
            ...value,
            ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
            ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
          }
        : _.noop();
    });
    formObject = { ...formObject, JobPlan: jobplanData };
    this.workOrderRef.current &&
      this.workOrderRef.current !== null &&
      this.workOrderRef.current.setFieldsValue(formObject);
    this.setState({ selectedJobPlan: value, formObject });
  };

  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };

  render() {
    let {
      deviceList,
      sitesList,
      formObject,

      selectedJobPlan,
      statusList,
      translationCache
    } = this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '0px 15px', minHeight: window.innerHeight - 143 }}
        >
          <StyledForm
            ref={this.workOrderRef}
            theme={themeSettings}
            onFinish={this.onFormsubmit}
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            // onFinishFailed={this.onFinishFailed}
            initialValues={formObject}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            //wrapperCol={{ flex: 1 }}
            validateMessages={validateMessages}
          >
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Form.Item>
                <StyledButton theme={themeSettings} htmlType="submit">
                  {translation(translationCache, constant.submit)}
                </StyledButton>
              </Form.Item>
            </div>

            <div
              style={{ color: 'white', margin: '19px 37px', fontSize: '17px', fontWeight: 'bold' }}
            >
              <span style={{ color: '#ffb327' }}>
                {this.getValueFromList(
                  sitesList,
                  'Id',
                  this.workOrderRef.current
                    ? this.workOrderRef.current.getFieldValue('SiteId')
                    : '',
                  'Name'
                )}
              </span>{' '}
              (Site)
              <span style={{ margin: '10px' }}> / </span>
              <span style={{ color: '#ffb327' }}>
                {' '}
                {this.workOrderRef.current
                  ? this.workOrderRef.current.getFieldValue('LocationId')
                  : null}
              </span>{' '}
              (Location)
              <span style={{ margin: '10px' }}> / </span>
              <span style={{ color: '#ffb327' }}>
                {' '}
                {this.getValueFromList(
                  deviceList,
                  'DeviceCode',
                  this.workOrderRef.current
                    ? this.workOrderRef.current.getFieldValue('Device')
                    : '',
                  'Name'
                )}
              </span>{' '}
              (Equipment)
            </div>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                borderBottom: '1px dashed white',
                marginBottom: '5px'
              }}
            >
              <Form.Item name="Id"></Form.Item>

              <Form.Item
                label={translation(translationCache, constant.workOrder)}
                name="WorkOrder"
                style={{ width: '100%', flex: '33%' }}
              >
                <div style={{ color: 'white' }}>
                  {this.workOrderRef.current
                    ? this.workOrderRef.current.getFieldValue('WorkOrder')
                    : null}
                </div>
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.description)}
                placeholder={translation(translationCache, 'Input workOrderDescription')}
                name="Description"
                style={{ width: '100%', flex: '33%' }}
                rules={[{ required: true }]}
              >
                <div style={{ color: 'white' }}>
                  {this.workOrderRef.current
                    ? this.workOrderRef.current.getFieldValue('Description')
                    : null}
                </div>
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.status)}
                name="StatusId"
                style={{ width: '100%', flex: '33%' }}
                rules={[{ required: true }]}
              >
                <Select
                  size={'small'}
                  placeholder={translation(translationCache, 'Select Status')}
                  onChange={(value) => this.handleChange(value, 'StatusId')}
                  style={{ width: '30%' }}
                >
                  {statusList.map((status, index) => {
                    return (
                      <Option key={index} value={status.Id}>
                        {status.Status}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                // label={translation(translationCache, constant.location)}
                style={{ width: '100%', flex: '33%', display: 'none' }}
                name="LocationId"
              ></Form.Item>
              <Form.Item
                label={translation(translationCache, constant.equipment)}
                name="Device"
                style={{ width: '100%', flex: '33%', display: 'none' }}
                rules={[{ required: true }]}
              >
                <div style={{ color: 'white' }}>
                  {this.getValueFromList(
                    deviceList,
                    'DeviceCode',
                    this.workOrderRef.current
                      ? this.workOrderRef.current.getFieldValue('Device')
                      : '',
                    'Name'
                  )}
                </div>
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.site)}
                name="SiteId"
                style={{ width: '100%', flex: '33%', display: 'none' }}
                rules={[{ required: true }]}
              >
                <div style={{ color: 'white' }}>
                  <div style={{ color: 'white' }}>
                    {this.getValueFromList(
                      sitesList,
                      'Id',
                      this.workOrderRef.current
                        ? this.workOrderRef.current.getFieldValue('SiteId')
                        : '',
                      'Name'
                    )}
                  </div>
                </div>
              </Form.Item>

              <Form.Item
                name="WorkTypeId"
                style={{ width: '100%', flex: '50%', display: 'none' }}
              ></Form.Item>
              <Form.Item
                name="WorkFlowId"
                style={{ width: '100%', flex: '50%', display: 'none' }}
              ></Form.Item>
              <Form.Item
                name="Attachments"
                style={{ width: '100%', flex: '50%', display: 'none' }}
              ></Form.Item>
            </div>

            <StyledSubForm theme={themeSettings} style={{ padding: '0px' }}>
              {/* <StyledTabs
                theme={themeSettings}
                defaultActiveKey={activeKey}
                onChange={(activeKey) => this.setState({ activeKey })}
                type="card"
                size={'small'}
              >
                <TabPane tab={translation(translationCache, constant.jobPlanTasks)} key="1">
                  {/* (Plans/Actuals) */}

              <Form.Item name="JobPlan" style={{ width: '100%', flex: '50%' }}>
                {(selectedJobPlan && selectedJobPlan !== undefined && selectedJobPlan !== '') ||
                (formObject['JobPlan'] !== undefined &&
                  formObject['JobPlan']['Id'] !== undefined) ? (
                  <WorkOrderJobPlanesTask
                    noJobplan={false}
                    // onFinishFailed={this.onFinishFailed}
                    onFinish={this.onFinish}
                    formObject={formObject['JobPlan']}
                    fileData={this.state.fileData}
                    workOrderStatus={formObject && formObject.StatusId ? 'CLOSE' : null}
                  ></WorkOrderJobPlanesTask>
                ) : null}
                {selectedJobPlan === '' ? (
                  <WorkOrderJobPlanesTask
                    noJobplan={true}
                    // onFinishFailed={this.onFinishFailed}
                    onFinish={this.onFinish}
                    formObject={formObject['JobPlan']}
                    fileData={this.state.fileData}
                  ></WorkOrderJobPlanesTask>
                ) : null}
              </Form.Item>
              {/* </TabPane>

              </StyledTabs> */}
            </StyledSubForm>
          </StyledForm>
        </StyledComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WorkOrderDetails);
