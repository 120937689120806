import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Input,
  DatePicker,
  Radio,
  Space,
  Button,
  Steps,
  Tooltip,
  Drawer,
  Tree,
  Tag,
  message,
  Typography,
  notification,
  Table,
  Modal,
  Select,
  Skeleton
} from 'antd';
import { FastForwardOutlined, FastBackwardOutlined, RightCircleFilled } from '@ant-design/icons';

import './AIScreen.css';
import history from '../../../commons/history';
import {
  // _getEquipmentType,
  _getHierarchyLevels,
  createStep,
  getBackTestResultsData,
  // getDeviceTypeByCode,
  getOptionsList,
  getStepsDetailsById,
  getStepsList,
  saveAIRule
} from './APICalls';
import { getTranslationCache } from '../../../selectors/language';
import { getDefaultHierarchy } from '../../../selectors/layout';
import { translation } from '../../../commons/translation';
import { IPAddress } from '../../../commons/api';
import localStorage from '../../../utils/localStorage';
import DynamicGrid from './DynamicGrid';
import DynamicGridTag from './DynamicGridTag';
// import rubusLogo from '../../../images/rubus_new/horizontal_logo.png';
import './markdownStyles.css'; // Import your CSS file
const { TextArea } = Input;
const { Title } = Typography;
const { RangePicker } = DatePicker;
const OperatorOptions = [
  {
    key: '>',
    value: '>'
  },
  {
    key: '<',
    value: '<'
  }
];
const AIScreen = (props) => {
  const [problemStatement, setProblemStatement] = useState('');
  const [scenario, setScenario] = useState('');
  const [customScenario, setCustomScenario] = useState('');
  const [equipment, setEquipment] = useState('');
  const [customEquipment, setCustomEquipment] = useState('');
  const [equipmentType, setEquipmentType] = useState('');
  const [customEquipmentType, setCustomEquipmentType] = useState('');
  const [thresholds, setThresholds] = useState({});
  const [tag, setTag] = useState('');
  const [customTag, setCustomTag] = useState('');
  const [backTest, setBackTest] = useState([]);
  const [response, setResponse] = useState('');
  const [currentStep, setCurrentStep] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  let searchParams = new URLSearchParams(history.location.search);
  const [editObject, setEditObject] = useState(Object.fromEntries([...searchParams]));
  const [treeData, setTreeData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [stepsList, setStepsList] = useState([]);
  const [backTestList, setBackTestList] = useState([]);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [deviceTypeList, setDeviceTypeList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const stepMapping = {
    ProblemStatement: problemStatement,
    Scenario: scenario,
    EquipmentType: equipmentType,
    Tag: tag,
    Thresholds: thresholds
  };
  const isStringifiedObject = (value) => {
    if (typeof value !== 'string') return false;
    try {
      return typeof JSON.parse(value) === 'object';
    } catch {
      return false;
    }
  };
  useEffect(() => {
    (async () => {
      try {
        let stepsList = await getStepsList();
        let deviceTypeList = await getOptionsList('EquipmentType');
        if (editObject && editObject.Id) {
          let editObjectById = await getStepsDetailsById(editObject.Id);
          let currentStep = editObjectById[editObjectById.length - 1].Step;
          editObjectById &&
            Array.isArray(editObjectById) &&
            editObjectById.map(async (stepObject) => {
              if (stepObject.Step === 'ProblemStatement') {
                setProblemStatement(stepObject.Value);
              } else if (stepObject.Step === 'Scenario') {
                if (stepObject.Type === 'custom') {
                  setCustomScenario(stepObject.Value);
                  setScenario(stepObject.Type);
                } else {
                  setScenario(stepObject.Value);
                }
              } else if (stepObject.Step === 'CustomScenario') {
                setCustomScenario(stepObject.Value);
              } else if (stepObject.Step === 'EquipmentType') {
                setEquipmentType(stepObject.Value);
                let tagListData = await getOptionsList('Tag', stepObject.Value);
                setTagList(tagListData);
              } else if (stepObject.Step === 'Equipment') {
                if (stepObject.Type === 'custom') {
                  setCustomEquipment(stepObject.Value);
                  setEquipment(stepObject.Type);
                } else {
                  setEquipment(stepObject.Value);
                }
              } else if (stepObject.Step === 'Tag') {
                setTag(stepObject.Value);
              } else if (stepObject.Step === 'Thresholds') {
                let thresholdsData = {};
                if (isStringifiedObject(stepObject.Value) === true) {
                  let parsedThresholdObject = JSON.parse(stepObject.Value);
                  console.log(parsedThresholdObject);

                  thresholdsData = parsedThresholdObject;
                } else {
                  thresholdsData = stepObject.Value;
                }
                setThresholds(thresholdsData);
              }
              return {};
            });
          setCurrentStep(currentStep);
          setResponse(editObjectById);
          setDeviceTypeList(deviceTypeList);
        }
        setStepsList(stepsList);
      } catch (e) {
        return {};
      }
    })();
  }, []);

  const handleChange = (e, Step, OptionIndex) => {
    if (Step === 'ProblemStatement') {
      setProblemStatement(e.target.value);
    } else if (Step === 'Scenario') {
      setScenario(e.target.value);
    } else if (Step === 'CustomScenario') {
      setCustomScenario(e.target.value);
    } else if (Step === 'EquipmentType') {
      setEquipmentType(e);
    } else if (Step === 'Equipment') {
      setEquipment(e.target.value);
    } else if (Step === 'CustomEquipment') {
      setCustomEquipment(e.target.value);
    } else if (Step === 'Tag') {
      setTag(e);
    } else if (Step === 'Thresholds_Value') {
      let updatedArray = thresholds.map((item, index) => {
        if (index === OptionIndex) {
          return { ...item, Value: e.target.value };
        }
        return item;
      });
      setThresholds(updatedArray);
    } else if (Step === 'Thresholds_Operator') {
      let updatedArray = thresholds.map((item, index) => {
        if (index === OptionIndex) {
          return { ...item, Operator: e };
        }
        return item;
      });
      setThresholds(updatedArray);
    } else if (Step === 'Thresholds_Condition') {
      let updatedArray = thresholds.map((item, index) => {
        if (index === OptionIndex) {
          return { ...item, Condition: e };
        }
        return item;
      });
      setThresholds(updatedArray);
    }
  };

  const handleSubmit = async (StepDirection) => {
    let currentStepData = response[response.length - 1] || {};
    let payload = {};
    payload = {
      Step: currentStepData.Step || 'ProblemStatement',
      RuleGenHeaderId: editObject.Id,
      RuleGenAuditId: currentStepData.Id
    };
    setLoading(true);
    setError('');
    try {
      if (payload.Step === 'ProblemStatement') {
        if (!problemStatement.trim()) {
          openNotificationWithIcon('info', `Please input ${payload.Step}`);
          return;
        }
        payload = {
          ...payload,
          Value: problemStatement
        };
      } else if (payload.Step === 'Scenario') {
        if (!scenario.trim() && StepDirection !== 'PreviousStep') {
          openNotificationWithIcon('info', `Please input ${payload.Step}`);
          return;
        }
        if (scenario && scenario !== 'custom') {
          let answer = currentStepData.Answer.filter((e) => e.Option === scenario);
          payload = {
            ...payload,
            ValueMetaData: answer && answer[0] ? answer[0] : {}
          };
        }
        payload = {
          ...payload,
          Value: scenario && scenario === 'custom' ? customScenario : scenario,
          ...(scenario && scenario === 'custom' ? { Type: 'custom' } : {})
        };
      } else if (payload.Step === 'EquipmentType') {
        if (!equipmentType.trim() && StepDirection !== 'PreviousStep') {
          openNotificationWithIcon('info', `Please input ${payload.Step}`);
          return;
        }
        if (equipmentType && equipmentType !== 'custom') {
          let answer = currentStepData.Answer.filter((e) => e.Value === equipmentType);
          payload = {
            ...payload,
            ValueMetaData: answer && answer[0] ? answer[0] : {}
          };
        }
        payload = {
          ...payload,
          Value: equipmentType && equipmentType === 'custom' ? customEquipmentType : equipmentType,
          ...(equipmentType && equipmentType === 'custom' ? { Type: 'custom' } : {})
        };
        setCustomTag('');
      } else if (payload.Step === 'Equipment') {
        payload = {
          Value: equipment && equipment === 'custom' ? customEquipment : equipment,
          ...(equipment && equipment === 'custom' ? { Type: 'custom' } : {})
        };
      } else if (payload.Step === 'Tag') {
        if (!tag.trim() && StepDirection !== 'PreviousStep') {
          openNotificationWithIcon('info', `Please input ${payload.Step}`);
          return;
        }
        if (tag && tag !== 'custom') {
          let answer = currentStepData.Answer.filter((e) => e.Value === tag);
          payload = {
            ...payload,
            ValueMetaData: answer && answer[0] ? answer[0] : {}
          };
        }
        payload = {
          ...payload,
          Value: tag && tag === 'custom' ? customTag : tag,
          ...(tag && tag === 'custom' ? { Type: 'custom' } : {})
        };
      } else if (payload.Step === 'Thresholds') {
        payload = {
          ...payload,
          Value: thresholds
        };
      } else if (payload.Step === 'BackTest') {
        payload = {
          ...payload,
          Value: ''
        };
      }
      let stepResponse = await createStep(payload, StepDirection);
      if (stepResponse && stepResponse.message) {
        if (payload.Step === 'ProblemStatement') {
          let editObjectById = await getStepsDetailsById(stepResponse.Id);
          editObjectById &&
            Array.isArray(editObjectById) &&
            editObjectById.map(async (stepObject) => {
              if (stepObject.Step === 'ProblemStatement') {
                setProblemStatement(stepObject.Value);
              } else if (stepObject.Step === 'Scenario') {
                if (stepObject.Type === 'custom') {
                  setCustomScenario(stepObject.Value);
                  setScenario(stepObject.Type);
                } else {
                  setScenario(stepObject.Value);
                }
              } else if (stepObject.Step === 'CustomScenario') {
                setCustomScenario(stepObject.Value);
              } else if (stepObject.Step === 'EquipmentType') {
                setEquipmentType(stepObject.Value);
                let tagListData = await getOptionsList('Tag', stepObject.Value);
                setTagList(tagListData);
              } else if (stepObject.Step === 'Equipment') {
                if (stepObject.Type === 'custom') {
                  setCustomEquipment(stepObject.Value);
                  setEquipment(stepObject.Type);
                } else {
                  setEquipment(stepObject.Value);
                }
              } else if (stepObject.Step === 'Tag') {
                setTag(stepObject.Value);
              } else if (stepObject.Step === 'Thresholds') {
                let thresholdsData = {};
                if (isStringifiedObject(stepObject.Value) === true) {
                  let parsedThresholdObject = JSON.parse(stepObject.Value);
                  console.log(parsedThresholdObject);

                  thresholdsData = parsedThresholdObject;
                } else {
                  thresholdsData = stepObject.Value;
                }
                setThresholds(thresholdsData);
              }
              return {};
            });
          setResponse(editObjectById);
          setEditObject({ Id: stepResponse.Id });
          setCurrentStep(editObjectById[editObjectById.length - 1].Step);
          history.push(`/rubus/AIRulesDetails?Id=${stepResponse.Id}`);
        } else {
          let editObjectById = await getStepsDetailsById(stepResponse.Id);
          editObjectById &&
            Array.isArray(editObjectById) &&
            editObjectById.map(async (stepObject) => {
              if (stepObject.Step === 'ProblemStatement') {
                setProblemStatement(stepObject.Value);
              } else if (stepObject.Step === 'Scenario') {
                if (stepObject.Type === 'custom') {
                  setCustomScenario(stepObject.Value);
                  setScenario(stepObject.Type);
                } else {
                  setScenario(stepObject.Value);
                }
              } else if (stepObject.Step === 'CustomScenario') {
                setCustomScenario(stepObject.Value);
              } else if (stepObject.Step === 'EquipmentType') {
                setEquipmentType(stepObject.Value);
                // let tagListData = await getOptionsList('Tag', stepObject.Value);
                // setTagList(tagListData);
              } else if (stepObject.Step === 'Equipment') {
                if (stepObject.Type === 'custom') {
                  setCustomEquipment(stepObject.Value);
                  setEquipment(stepObject.Type);
                } else {
                  setEquipment(stepObject.Value);
                }
              } else if (stepObject.Step === 'Tag') {
                setTag(stepObject.Value);
              } else if (stepObject.Step === 'Thresholds') {
                let thresholdsData = {};
                if (isStringifiedObject(stepObject.Value) === true) {
                  let parsedThresholdObject = JSON.parse(stepObject.Value);
                  console.log(parsedThresholdObject);

                  thresholdsData = parsedThresholdObject;
                } else {
                  thresholdsData = stepObject.Value;
                }
                setThresholds(thresholdsData);
              }
              return {};
            });
          setResponse(editObjectById);
          setEditObject({ Id: stepResponse.Id });
          setCurrentStep(editObjectById[editObjectById.length - 1].Step);
        }
      }
    } catch (err) {
      setError('An error occurred while fetching the response. Please try again.', err);
    } finally {
      setLoading(false);
    }
  };

  const onViewHierarchy = async (equipmentType) => {
    let treeData = await _getHierarchyLevels(equipmentType);
    setTreeData(treeData);
    setModalVisible(true);
  };

  const renderTreeNodes = (data) =>
    data &&
    Array.isArray(data) &&
    data.map((item) => {
      if (item.children) {
        return (
          <Tree.TreeNode
            title={
              <div
                onClick={() => {
                  setCustomEquipment(item.Code);
                  setModalVisible(false);
                }}
              >
                <>{item.Name}</>
              </div>
            }
            key={item.Id}
            autoExpandParent={true}
            dataRef={item}
          >
            {renderTreeNodes(item.children)}
          </Tree.TreeNode>
        );
      }
      return (
        <Tree.TreeNode
          key={item.Id}
          title={
            <div
              onClick={() => {
                setCustomEquipment(item.Code);
                setModalVisible(false);
              }}
            >
              <>{item.Name}</>
            </div>
          }
          {...item}
        />
      );
    });

  const renderSteps = (stepWise) => {
    let disableStyleProps = {
      color: '#1a3b5c',
      cursor: 'not-allowed',
      opacity: '0.8',
      pointerEvents: 'none'
    };
    switch (stepWise.Step) {
      case 'ProblemStatement':
        return (
          <div
            className="question-area"
            style={currentStep && currentStep === stepWise.Step ? {} : { ...disableStyleProps }}
          >
            <div className="question-text">{`Problem Statement`}</div>
            <div className="input-area">
              <TextArea
                style={{ borderRaduis: '10px' }}
                rows={4}
                value={problemStatement}
                onChange={(e) => handleChange(e, 'ProblemStatement')}
                placeholder="Type your statement here..."
              />
            </div>
          </div>
        );
      case 'Scenario':
        return (
          <div
            className="question-area"
            style={currentStep && currentStep === stepWise.Step ? {} : { ...disableStyleProps }}
          >
            <div className="question-text">{`Scenario`}</div>
            <div className="input-area">
              <Radio.Group value={scenario} onChange={(e) => handleChange(e, 'Scenario')}>
                <Space direction="vertical">
                  {stepWise.Answer &&
                    Array.isArray(stepWise.Answer) &&
                    stepWise.Answer.map((scen, index) => {
                      return (
                        <Radio key={index} style={{ color: 'white' }} value={scen.Option}>
                          <div className="customSelectOption">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: scen.Option
                              }}
                            ></div>
                            {scen.FileName ? (
                              <div style={{ marginTop: '8px', cursor: 'pointer' }}>
                                <Tag color="geekblue">
                                  <a
                                    href={`${IPAddress.LOCAL}/api/chatAttachments/chat/${scen.FileName}#Page=${scen.Page}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {scen.FileName}
                                  </a>
                                </Tag>
                              </div>
                            ) : null}
                          </div>
                        </Radio>
                      );
                    })}
                  <Radio value={'custom'} style={{ color: 'white' }}>
                    <div className="customSelectOption">
                      {' '}
                      Add Custom Scenario...
                      {scenario === 'custom' ? (
                        <Input.TextArea
                          value={customScenario}
                          placeholder="Input Scenario"
                          rows={2}
                          onChange={(e) => handleChange(e, 'CustomScenario')}
                          style={{ margin: '13px 0px' }}
                        />
                      ) : null}
                    </div>
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
          </div>
        );
      case 'EquipmentType':
        return (
          <div
            className="question-area"
            style={currentStep && currentStep === stepWise.Step ? {} : { ...disableStyleProps }}
          >
            <div className="question-text">{`Equipment Type`}</div>
            <div className="input-area" style={{ marginTop: '20px' }}>
              <DynamicGrid
                stepWise={stepWise}
                value={equipmentType}
                Step={'EquipmentType'}
                customValue={customEquipmentType}
                onSelectChange={(value) => handleChange(value, 'EquipmentType')}
                onCustomSelectChange={(value) => {
                  setCustomEquipmentType(value);
                  setEquipmentType('custom');
                }}
              />
            </div>
          </div>
        );
      case 'Equipment':
        return (
          <div
            className="question-area"
            style={currentStep && currentStep === stepWise.Step ? {} : { ...disableStyleProps }}
          >
            <div className="question-text">{`Equipment`}</div>
            <div className="input-area">
              <Radio.Group value={equipment} onChange={(e) => handleChange(e, 'Equipment')}>
                <Space direction="vertical">
                  {stepWise.Answer &&
                    Array.isArray(stepWise.Answer) &&
                    stepWise.Answer.map((equip, index) => {
                      return (
                        <Radio key={index} style={{ color: 'white' }} value={equip.Option}>
                          {equip.Option}
                        </Radio>
                      );
                    })}
                  <Radio value={'custom'} style={{ color: 'white' }}>
                    <div
                      onClick={() => {
                        onViewHierarchy(equipmentType);
                      }}
                    >
                      Custom{' '}
                      {customEquipment && customEquipment.length > 0 ? `(${customEquipment})` : ''}
                    </div>
                    <Drawer
                      title={'Select Equipment'}
                      visible={modalVisible}
                      closable
                      onClose={() => setModalVisible(false)}
                      width={600}
                    >
                      <Tree defaultExpandAll={true} showLine>
                        {treeData && Array.isArray(treeData) && treeData.length !== 0
                          ? renderTreeNodes(treeData)
                          : null}
                      </Tree>
                    </Drawer>
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
          </div>
        );
      case 'Tag':
        return (
          <div
            className="question-area"
            style={currentStep && currentStep === stepWise.Step ? {} : { ...disableStyleProps }}
          >
            <div className="question-text">{`Tag`}</div>
            <div className="input-area">
              <DynamicGridTag
                stepWise={stepWise}
                value={tag}
                Step={'Tag'}
                DeviceType={
                  equipmentType && equipmentType === 'custom' ? customEquipmentType : equipmentType
                }
                customValue={customTag}
                onSelectChange={(value) => handleChange(value, 'Tag')}
                onCustomSelectChange={(value) => {
                  setCustomTag(value);
                  setTag('custom');
                }}
              />
            </div>
          </div>
        );
      case 'Thresholds':
        return (
          <div
            className="question-area"
            style={currentStep && currentStep === stepWise.Step ? {} : { ...disableStyleProps }}
          >
            <div className="question-text">{`Thresholds`}</div>
            <div className="input-area">
              <Input.Group compact style={{ display: 'flex', flexDirection: 'column' }}>
                {thresholds &&
                  Array.isArray(thresholds) &&
                  thresholds.map((thresh, index) => {
                    if (thresh && thresh.Condition) {
                      return (
                        <div key={index} style={{ marginBottom: '20px' }}>
                          <Select
                            key={index}
                            style={{ marginRight: '5px', width: '20%' }}
                            value={thresh.Condition}
                            placeholder={'Operator'}
                            onChange={(e) => handleChange(e, 'Thresholds_Condition', index)}
                          >
                            <Select.Option key={index} value={'AND'}>
                              {'AND'}
                            </Select.Option>
                            <Select.Option key={index} value={'OR'}>
                              {'OR'}
                            </Select.Option>
                          </Select>
                        </div>
                      );
                    } else {
                      return (
                        <div key={index} style={{ marginBottom: '20px' }}>
                          <Input
                            value={thresh.Tag}
                            disabled={true}
                            style={{ marginRight: '5px', width: '20%' }}
                          ></Input>
                          <Select
                            style={{ marginRight: '5px', width: '20%' }}
                            value={thresh.Operator}
                            placeholder={'Operator'}
                            onChange={(e) => handleChange(e, 'Thresholds_Operator', index)}
                          >
                            {OperatorOptions &&
                              OperatorOptions.map((operatorOPT, index) => (
                                <Select.Option key={index} value={operatorOPT.value}>
                                  {translation(operatorOPT.key, props.translationCache)}
                                </Select.Option>
                              ))}
                          </Select>
                          <Input
                            style={{ width: '20%' }}
                            value={thresh.Value}
                            placeholder="Value"
                            onChange={(e) => handleChange(e, 'Thresholds_Value', index)}
                          />
                        </div>
                      );
                    }
                  })}
              </Input.Group>
            </div>
          </div>
        );
      case 'BackTest':
        return (
          <>
            <div
              className="question-area"
              style={currentStep && currentStep === stepWise.Step ? {} : { ...disableStyleProps }}
            >
              <div className="question-text">{`Backtest dates to get counts`}</div>
              <div style={{ width: '70%' }}>
                <div className="input-area">
                  <RangePicker
                    // value={backtest}
                    style={{ width: '47%' }}
                    showTime={{ format: 'HH:mm:ss' }}
                    format="YYYY-MM-DD HH:mm:ss"
                    onChange={(date, dateString) => {
                      setBackTest(dateString);
                    }}
                  />
                  <Button onClick={getBackTestResults}>Get Count</Button>
                </div>

                {backTestList && Array.isArray(backTestList) && backTestList[0] ? (
                  <Table
                    columns={Object.keys(backTestList[0]).map((col) => {
                      return { title: col, dataIndex: col, key: col };
                    })}
                    dataSource={backTestList}
                  />
                ) : null}
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Modal
                title="Review and Submit"
                centered
                open={openReviewModal}
                onOk={() => {
                  onRuleSubmit();
                }}
                onCancel={() => setOpenReviewModal(false)}
                width={800}
                okText="Submit"
              >
                <>
                  {response
                    .filter((e, i) => response.length - 1 !== i && e.Step !== 'BackTest')
                    .map((stepWise) => {
                      return (
                        <>
                          <div style={{ margin: '5px 0px' }}>
                            <span className="detailsTitle">{stepWise.Step} : </span>
                            {viewStep(stepWise.Step, stepWise.Value, stepWise)}
                          </div>
                        </>
                      );
                    })}
                </>
              </Modal>
              <Button type="primary" onClick={() => setOpenReviewModal(true)}>
                Review & Submit
              </Button>
            </div>
          </>
        );
      default:
        return <div></div>;
    }
  };

  const getBackTestResults = async () => {
    let payload = {
      RuleGenHeaderId: editObject.Id,
      StartDate: backTest[0],
      EndDate: backTest[1]
    };
    let backTestList = await getBackTestResultsData(payload);
    if (backTestList && Array.isArray(backTestList) && backTestList.length > 0) {
      setBackTestList(backTestList);
    } else {
      openNotificationWithIcon('info', `No data found`);
    }
  };

  const onRuleSubmit = async () => {
    const siteId = localStorage.get('currentSite');
    let payload = {
      AIRule: true,
      Step: 'BackTest',
      RuleGenHeaderId: editObject.Id,

      batchPriority: '',
      description: problemStatement,
      displayRuleName: problemStatement,
      Group: 'Group-1',
      mailRecipients: ['admin@rubusdigital.com'],
      name: problemStatement,
      onChange: false,
      ParameterCode: tag,
      payload: {
        batchPriority: '',
        checkedValues: [],
        conelse: false,
        conif: true,
        conlseif: false,
        deviceList: [],
        deviceTypeList: [],
        disabledParamTypeDevice: false,
        displayRuleName: problemStatement,
        downtimeinputList: {
          action: [],
          device: ''
        },
        edit: false,
        EditType: '',
        elseList: [],
        elsifLength: [],
        elsifList: [
          {
            operater: '',
            tagName: '',
            value: ''
          }
        ],
        elsifNumber: 0,
        enable: true,
        Group: 'Group-1',
        indexDevice: 0,
        inputList:
          thresholds &&
          Array.isArray(thresholds) &&
          thresholds
            .filter((e) => !e.Condition)
            .map((thres, index) => {
              return {
                // "54765": "&&",
                ...(index === 1
                  ? {
                      54765:
                        thresholds[1] && thresholds[1].Condition && thresholds[1].Condition === 'OR'
                          ? '||'
                          : '&&'
                    }
                  : {}),
                action: ['Email'],
                device: equipmentType,
                deviceType: equipmentType,
                operater: thres.Operator,
                parameterName: 'Operational',
                preventive: '',
                recommandation: '',
                tagName: thres.Tag,
                value: thres.Value
              };
            }),
        mailRecipients: ['admin@rubusdigital.com'],
        onChange: false,
        optionsSelected: [],
        parameter: 'Operational',
        parameterChangeList: [],
        ParameterCode: tag,
        parametersList: [],
        recipientList: [],
        replicateDevices: [],
        rulebody: '',
        ruleDesc: problemStatement,
        ruleMode: 'DeviceType',
        ruleName: '',
        ruleType: 'deviceType',
        script: '',
        selectedDevices: [],
        setOptionsSelected: [],
        tabActivationkey: 'DeviceTypeRules',
        tabType: '',
        tagNames: [],
        treeData: [],
        userProfile: {}
      },
      replicate: 'false',
      ruleType: 'deviceType',
      script: `if(data["@device_${thresholds[0].Tag}"]${thresholds[0].Operator}${
        thresholds[0].Value
      }${
        thresholds[2]
          ? `&&data["@device_${thresholds[2].Tag}"]${thresholds[2].Operator}${thresholds[2].Value}`
          : ''
      }){return [{"@device$$$${thresholds[0].Tag}":data["@device_${
        thresholds[0].Tag
      }"],"@device$$$${thresholds[0].Tag}":data["@device_${
        thresholds[0].Tag
      }"],},["Email"],"condition1",[0,"00:00:00"],[0,"00:00:00"]]}`,
      SiteId: siteId,
      tags:
        thresholds &&
        Array.isArray(thresholds) &&
        thresholds
          .filter((e) => !e.Condition)
          .map(() => {
            return {
              deviceType: equipmentType,
              tagName: tag
            };
          }),
      url: []
    };
    let data = await saveAIRule(payload);

    if (
      (data && data.status && data.status === 201) ||
      data.status === 200 ||
      data.status === 204
    ) {
      message.success(data.data.message);
      setOpenReviewModal(false);
      let nextResponse = await getStepsDetailsById(data.data.Id);
      setResponse(nextResponse);
      setEditObject({ Id: data.data.Id });
      setCurrentStep(nextResponse[nextResponse.length - 1].Step);
    }
  };

  const openNotificationWithIcon = (type, message) => {
    notification[type]({ message });
  };

  const getDeviceTypeName = (AliasCode) => {
    let currentDeviceTypeObject =
      deviceTypeList && Array.isArray(deviceTypeList)
        ? deviceTypeList.filter((e) => e.AliasCode === AliasCode)
        : [];
    return currentDeviceTypeObject && currentDeviceTypeObject[0]
      ? currentDeviceTypeObject[0].Name
      : AliasCode;
  };

  const getTagName = (Code) => {
    let currentParameterObject =
      tagList && Array.isArray(tagList) ? tagList.filter((e) => e.Code === Code) : [];
    return currentParameterObject && currentParameterObject[0]
      ? currentParameterObject[0].Name
      : Code;
  };

  const generateStatement = () => {
    if (thresholds && Array.isArray(thresholds) && thresholds.length > 0) {
      let thresholdData = thresholds.map((thresholds) => {
        if (thresholds && thresholds.Condition) {
          return thresholds.Condition;
        } else {
          return `${thresholds.Tag}${thresholds.Operator}${thresholds.Value}`;
        }
      });

      return thresholdData ? thresholdData.join('\n') : '';
    }
    return '';
  };

  const viewStep = (Step, Value, StepObject) => {
    let filterObjectValue = {};
    if (Step === 'EquipmentType' || Step === 'Tag') {
      let filterObject =
        StepObject && StepObject.Answer ? StepObject.Answer.filter((e) => e.Value === Value) : [];
      filterObjectValue =
        filterObject && filterObject[0] && filterObject[0].Option ? filterObject[0].Option : Value;
    }

    switch (Step) {
      case 'EquipmentType':
        return (
          <span>
            {getDeviceTypeName(filterObjectValue)} ({Value})
          </span>
        );
      case 'Tag':
        return (
          <span>
            {getTagName(filterObjectValue)} ({Value})
          </span>
        );
      case 'Thresholds':
        return <span>{generateStatement()}</span>;
      case 'Scenario':
        return <div dangerouslySetInnerHTML={{ __html: Value }}></div>;
      default:
        return <span>{Value}</span>;
    }
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="claude-question-container">
        <div className="page-header">
          <Title
            level={2}
            className="page-title"
            style={{ color: 'rgb(255, 171, 15)', fontFamily: 'initial' }}
          >
            AI Rule Generator
          </Title>
        </div>

        <Steps
          current={response && response.length > 0 ? response.length - 1 : 0}
          items={
            stepsList && Array.isArray(stepsList)
              ? stepsList.map((step) => {
                  return {
                    title: step.Step
                    // ...getStatus(step)
                  };
                })
              : []
          }
        />
        {response && Array.isArray(response) && response[response.length - 1].Step !== 'Submit' ? (
          <div style={{ marginTop: '20px' }}>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Button
                icon={<FastForwardOutlined />}
                style={{
                  background:
                    response &&
                    response.length > 0 &&
                    !stepMapping[response[response.length - 1].Step]
                      ? 'grey'
                      : 'rgb(255, 171, 15)',
                  border: 'rgb(255, 171, 15)'
                }}
                disabled={
                  response &&
                  response.length > 0 &&
                  stepMapping[response[response.length - 1].Step] === 'ProblemStatement'
                    ? true
                    : false
                }
                onClick={() => handleSubmit('NextStep')}
              >
                Next Step
              </Button>
              <Button
                disabled={false}
                icon={<FastBackwardOutlined />}
                style={{
                  marginRight: '10px',
                  background: 'rgb(255, 171, 15)',
                  border: 'rgb(255, 171, 15)'
                }}
                onClick={() => handleSubmit('PreviousStep')}
              >
                Previous Step
              </Button>
            </div>
          </div>
        ) : null}
        {!response && (
          <div style={{ marginTop: '20px' }}>
            <div className="question-text">Problem Statement</div>
            <div className="input-area">
              <TextArea
                style={{ borderRaduis: '10px' }}
                rows={4}
                value={problemStatement}
                onChange={(e) => handleChange(e, 'ProblemStatement')}
                placeholder="Type your statement here..."
              />
            </div>

            <Tooltip title={'Go to Next Step'}>
              <RightCircleFilled
                style={{
                  fontSize: '27px',
                  display: 'flex',
                  justifyContent: 'end',
                  color: 'rgb(255, 171, 15)'
                }}
                onClick={() => handleSubmit('NextStep')}
              />
            </Tooltip>
            {loading ? (
              <div style={{ marginTop: '40px' }}>
                {' '}
                <Skeleton active />
              </div>
            ) : null}
          </div>
        )}

        {response && (
          <div>
            {response && Array.isArray(response) && response.length > 1 && (
              <div className="detailsDiv">
                {response
                  .filter((e, i) => response.length - 1 !== i && e.Step !== 'BackTest')
                  .map((stepWise) => {
                    return (
                      <>
                        <div style={{ margin: '5px 0px' }}>
                          <span className="detailsTitle">{stepWise.Step} : </span>
                          {viewStep(stepWise.Step, stepWise.Value, stepWise)}
                        </div>
                      </>
                    );
                  })}

                {response &&
                  Array.isArray(response) &&
                  response
                    .filter((e) => e.Step === 'Submit')
                    .map((stepResponse, index) => {
                      return (
                        <div
                          key={index}
                          className="submitFooter"
                          onClick={() =>
                            history.push(`/rubus/RuleEngine/${stepResponse.GeneratedRuleId}`)
                          }
                        >
                          Click to view rule
                        </div>
                      );
                    })}
              </div>
            )}
            {response &&
              Array.isArray(response) &&
              [response[response.length - 1]].map((stepWise) => {
                return <>{renderSteps(stepWise)}</>;
              })}
          </div>
        )}
        {error && <div>{error}</div>}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  defaultHiearchy: getDefaultHierarchy()
});

export default connect(mapStateToProps)(AIScreen);
