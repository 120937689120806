import React from 'react';
import {
  Form,
  Input,
  message,
  Button,
  Table,
  Drawer,
  Select,
  Tooltip,
  Popconfirm
  // Option
} from 'antd';
import { StyledComponent } from '../../CSS/style';
import { StyledButton, DrawerFooter } from '../../../../commons/styles/layout';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import axios from 'axios';
import localStorage from '../../../../utils/localStorage';
import themeSettings from '../../Utils/themeSettings.json';
import { UpdateMasters, CreateMasters, deleteMasters } from '../../APICalls';
import { API_RUBUSMASTERS } from '../../../../commons/api';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../selectors/language';
import { connect } from 'react-redux';
import { API_MASTERCONFIGURATION } from '../../../../commons/api';
const ApiList = {
  CreateAsset: `${API_RUBUSMASTERS.AssetAttributesCREATE}`,
  UpdateAsset: `${API_RUBUSMASTERS.AssetAttributesUPDATE}`,
  DeleteAsset: `${API_RUBUSMASTERS.AssetAttributesDELETE}`
};
const { Option } = Select;

class AssetAttributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      list: [],
      typeList: [],
      moduleList: [],
      visibleTypes: false
      // translationCache
    };
  }
  hForm = React.createRef();

  componentDidMount() {
    this._getList();
    // this.getFileDownloadName();
    this.getTypeList();
    this.getModuleList();
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  _getList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let url = '';
    url = `/api/rubusMaster/assetAttributes/get?SiteId=${siteId}`;

    let orgHeaders = {
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ list: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getTypeList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    // let url = '';
    // url = `/api/rubusMaster/assetAttributes/get?SiteId=${siteId}`;
    const getTypes = {
      method: 'POST',
      url: `${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        TableName: 'sitemaster."SM_AssetTypes"',
        ColumnName: 'Name',
        ColumnValue: 'Code',
        Conditions: [
          {
            key: 'SiteId',
            value: siteId
          }
        ]
      }
    };
    axios(getTypes)
      .then((response) => {
        this.setState({ typeList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getModuleList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let url = '';
    url = `/api/rubusMaster/modules/get?Status=Active&SiteId=${siteId}`;

    let orgHeaders = {
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ moduleList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _editRecord = (record) => {
    let textValue = JSON.stringify(record.API);
    let formObject = {
      ...record,
      API: textValue
    };
    this.hForm.current && this.hForm.current.setFieldsValue(formObject);
    this.setState({
      visibleTypes: true,
      formObject,
      textValue,
      visible: true
    });
  };

  deleteRecord = async (id) => {
    let deleteResponse = await deleteMasters(ApiList.DeleteAsset, id);
    message.success(deleteResponse && deleteResponse.message);
    this._getList();
  };

  onFinish = async () => {
    let { formObject, textValue } = this.state;
    this.hForm.current &&
      this.hForm.current
        .validateFields()
        .then(async (values) => {
          let payload = {
            ...values,
            API: textValue
          };
          if (formObject && formObject.Id) {
            let UpdateResponse = await UpdateMasters(ApiList.UpdateAsset, payload, formObject.Id);
            message.success(UpdateResponse && UpdateResponse.message);
          } else {
            let createResponse = await CreateMasters(ApiList.CreateAsset, payload);
            message.success(createResponse && createResponse.message);
          }
          this._getList();
          this.hForm.current &&
            this.hForm.current.setFieldsValue({
              Code: null,
              Name: null,
              ModuleCode: null,
              Type: null,
              isEditableOnUpdate: null,
              mandatory: null,
              widget: null,
              showOnUpdate: null,
              API: null
            });
          this.setState({ formObject: {}, visibleTypes: false });
        })
        .catch((error) => {
          console.log(error);
        });
  };

  OpenDrawer = () => {
    let formObject = {
      Code: null,
      Name: null,
      ModuleCode: null,
      Type: null,
      isEditableOnUpdate: null,
      mandatory: null,
      widget: null,
      showOnUpdate: null,
      API: null
    };
    this.hForm.current &&
      this.hForm.current.setFieldsValue({
        Code: null,
        Name: null,
        ModuleCode: null,
        Type: null,
        isEditableOnUpdate: null,
        mandatory: null,
        widget: null,
        showOnUpdate: null,
        API: null
      });
    this.setState({ visibleTypes: true, formObject });
  };
  onClose = () => {
    this.hForm.current &&
      this.hForm.current.setFieldsValue({
        Code: null,
        Name: null,
        ModuleCode: null,
        Type: null,
        isEditableOnUpdate: null,
        mandatory: null,
        widget: null,
        showOnUpdate: null,
        API: null
      });
    this.setState({ visibleTypes: false, formObject: {} });
  };
  handleChange = (e, key) => {
    let { formObject } = this.state;
    if (key === 'API') {
      let values = JSON.parse(e.target.value);
      formObject = {
        ...formObject,
        API: values
      };
      this.setState({ formObject, textValue: values });
    }
  };

  render() {
    let { list, formObject, visibleTypes, textValue, typeList, moduleList } = this.state;
    const columns = [
      // {
      //   title: 'S.No',
      //   dataIndex: 'S No',
      //   key: 'S No'
      // },
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name'
      },
      {
        title: 'Module Code',
        dataIndex: 'ModuleCode',
        key: 'ModuleCode'
      },
      {
        title: 'Action',
        dataIndex: 'Action',
        render: (text, record) => (
          <>
            <Button className="ant-btn" onClick={() => this._editRecord(record)} size="middle">
              <Tooltip title="Edit">
                {' '}
                <EditOutlined />
              </Tooltip>
            </Button>
            <Popconfirm
              title={'Are You Sure That You Want To Delete'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this.deleteRecord(record.Id)}
              okText={'yes'}
              cancelText={'No'}
            >
              <Button className="ant-btn" size="middle">
                <span style={{ color: '#ff4d4f' }}>
                  {' '}
                  <Tooltip title={'Delete'}>
                    <DeleteOutlined />
                  </Tooltip>
                </span>
              </Button>
            </Popconfirm>
          </>
        )
      }
    ];
    console.log('type', typeList);

    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '0px 30px', minHeight: window.innerHeight - 143 }}
      >
        <div style={{ display: 'flex', justifyContent: 'right', padding: '20px' }}>
          <StyledButton onClick={this.OpenDrawer}>
            <PlusOutlined /> {this.translation('Add')}
          </StyledButton>
        </div>
        <Table
          className="basictable"
          columns={columns}
          dataSource={list}
          pagination={
            list.length > 10
              ? {
                  pageSize: '10'
                }
              : false
          }
        />
        <Drawer
          title={
            formObject && formObject.Id
              ? this.translation('Update Attribute')
              : this.translation('Add Attribute')
          }
          placement="right"
          width={600}
          closable
          onClose={this.onClose}
          visible={visibleTypes}
        >
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            // theme={themeSettings}
            layout="horizontal"
            ref={this.hForm}
            name="Site_form"
            initialValues={formObject}
            onFinish={this.onFinish}
          >
            <Form.Item
              label={this.translation('Code')}
              name="Code"
              rules={[{ required: true, message: this.translation('Please enter Code !') }]}
            >
              <Input
                // disabled={formObject && formObject.Id ? true : false}
                placeholder={this.translation('Please enter Code')}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Name')}
              name="Name"
              rules={[{ required: true, message: this.translation('Please enter Name !') }]}
            >
              <Input placeholder={this.translation('Please enter Name')} />
            </Form.Item>
            <Form.Item
              label={this.translation('ModuleCode')}
              name="ModuleCode"
              rules={[{ required: true, message: this.translation('Please select ModuleCode') }]}
            >
              <Select placeholder={this.translation('Pleaseselect Type')}>
                {moduleList &&
                  Array.isArray(moduleList) &&
                  moduleList.map((module, index) => {
                    return (
                      <Option key={index} value={module.Code}>
                        {module.Name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label={this.translation('Type')}
              name="Type"
              rules={[{ required: true, message: this.translation('Please enter Type !') }]}
            >
              <Select placeholder={this.translation('Pleaseselect Type')}>
                {typeList &&
                  Array.isArray(typeList) &&
                  typeList.map((type, index) => {
                    return (
                      <Option key={index} value={type.Value}>
                        {type.Key}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label={this.translation('isEditableOnUpdate')}
              name="isEditableOnUpdate"
              rules={[
                { required: true, message: this.translation('Please enter isEditableOnUpdate !') }
              ]}
            >
              <Input
                // disabled={formObject && formObject.Id ? true : false}
                placeholder={this.translation('Please enter isEditableOnUpdate')}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('mandatory')}
              name="mandatory"
              rules={[{ required: true, message: this.translation('Please enter mandatory !') }]}
            >
              <Input placeholder={this.translation('Please enter mandatory')} />
            </Form.Item>
            <Form.Item
              label={this.translation('widget')}
              name="widget"
              rules={[{ required: true, message: this.translation('Please select widget') }]}
            >
              <Input placeholder={this.translation('Please enter widget')} />
            </Form.Item>
            <Form.Item label={this.translation('showOnUpdate')} name="showOnUpdate">
              <Select placeholder={this.translation('Please enter showOnUpdate')}>
                <Option value={true}>true</Option>
                <Option value={false}>false</Option>
              </Select>
            </Form.Item>
            <Form.Item label={this.translation('API')} name="API">
              <Input.TextArea
                style={{ width: '100%' }}
                rows={3}
                value={textValue}
                placeholder={this.translation('Please enter API')}
                onChange={(e) => this.handleChange(e, 'API')}
              />
            </Form.Item>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            <Button type="primary" onClick={this.onFinish}>
              {this.translation('Submit')}
            </Button>
          </DrawerFooter>
        </Drawer>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(AssetAttributes);
