import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Button, Input, Radio, Table } from 'antd';

import { getTranslationCache } from '../../../selectors/language';
import translation from '../Utils/translation';
import { getCurrentTimezone, getUserProfile } from '../../../selectors/layout';
import ReactEcharts from '../Utils/Echarts/Echarts';
import { getVisualizationData } from '../Utils/ApICalls';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

class ChatWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      dataSource: []
    };
  }

  componentDidMount() {
    let { searchAttributes, Id } = this.props;
    this.getModalData(searchAttributes, Id);
  }

  getModalData = async (searchAttributes, Id) => {
    let { translationCache } = this.props;
    let dataSource = await getVisualizationData(Id);

    let legends = [];
    let xAxisData = [];
    let series = [];

    legends = [...new Set(dataSource.map((item) => item.Description))];
    xAxisData = [...new Set(dataSource.map((item) => item.DateTime))];
    series =
      legends &&
      legends.map((seriesName) => {
        return {
          name: seriesName,
          type: 'line',
          stack: 'Total',
          data: xAxisData.map((date) => {
            let processedData = dataSource.filter(
              (e) => e.DateTime === date && e.Description === seriesName
            );
            return processedData && processedData[0] ? processedData[0].Value : null;
          })
        };
      });
    let option = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: legends
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxisData
      },
      yAxis: {
        type: 'value'
      },
      series: series
    };
    let columns = [];
    if (dataSource && Array.isArray(dataSource) && dataSource && dataSource[0]) {
      columns = Object.keys(dataSource[0])
        .filter((e) => e !== 'key')
        .map((col) => {
          if (col === 'Date') {
            return {
              title: translation(translationCache, _.startCase(_.toLower(col))),
              dataIndex: col,
              key: col,
              render: (text) => (
                <>{moment(text).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm:ss')}</>
              )
            };
          }
          return {
            title: _.startCase(_.toLower(col)),
            dataIndex: col,
            key: col,
            ...this.getColumnSearchProps(col)
          };
        });
    }
    this.setState({ dataSource, columns, searchAttributes, option });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, 'Search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, 'Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, 'Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    let { dataSource, columns, searchAttributes, option } = this.state;
    let { translationCache } = this.props;
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Radio.Group
            value={
              searchAttributes && searchAttributes.GraphType && searchAttributes.GraphType
                ? searchAttributes.GraphType
                : 'TABLE'
            }
            onChange={(e) =>
              this.setState({
                searchAttributes: { ...searchAttributes, GraphType: e.target.value }
              })
            }
          >
            <Radio.Button value="TABLE">{translation(translationCache, 'Table')}</Radio.Button>
            <Radio.Button value="Line_Graph">{translation(translationCache, 'Graph')}</Radio.Button>
          </Radio.Group>
        </div>
        {searchAttributes &&
        searchAttributes.GraphType &&
        searchAttributes.GraphType === 'Line_Graph' ? (
          <ReactEcharts
            option={option}
            notMerge={true}
            lazyUpdate={true}
            className="react_for_echarts"
          />
        ) : (
          <Table
            className="TableWidget"
            style={{ width: '96%', margin: '20px' }}
            dataSource={dataSource}
            columns={columns}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(ChatWidget);
