import React, { Component } from 'react';
// import _ from 'lodash';
// import moment from 'moment';
import Highlighter from 'react-highlight-words';
import Flowchart from 'flowchart-react';
import { Button, Tag, Input, Popconfirm, Tooltip, Drawer, message, Form, Select } from 'antd';
import {
  NodeExpandOutlined,
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  ApartmentOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';

import ListView from '../../../Utils/ListView';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import history from '../../../../../commons/history';
import { encode as base64_encode } from 'base-64';
import {
  getWorkOrdersList,
  // getWorkOrderById,
  deleteWorkOrder,
  changeWorkOrderStatus,
  workOrderStatus,
  getWorkFlow,
  getUsersList,
  getWorkFlows,
  UpdateWorkFlow
} from '../../../Utils/FormCalls';
import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import { StyledComponent, StyledModal, StyledForm } from '../../../CSS/style';
import { constant } from '../../../Utils/constants';
const { Option } = Select;
class WorkOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      dataSource: [],
      activeKey: '1',
      editWork: false,
      initate: false,
      form: {},
      visible: false,
      modalVisible: false,
      workFlow: {},
      selectedId: '',
      config: {
        viewTypes: ['card', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['WorkOrder'],

        firstTitle: 'WorkOrder',
        secondTitle: 'CreatedBy',
        thirdTitle: 'newCreatedTime',

        actions: ['edit', 'delete'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/AMM/WorkOrderDetails',
          redirectDetailsURL: '/rubus/AMM/WorkOrderTaskDetails'
        },
        deletee: {
          byAttribute: 'Id'
        }
      }
    };
  }
  WorkFlowModalRef = React.createRef();

  componentDidMount = async () => {
    this._getAdhocDataSource();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    let searchParams = new URLSearchParams(this.props.history.location.search);
    let newParams = Object.fromEntries([...searchParams]);
    if (this.state.selectedId !== newParams.id && newParams.id) {
      this.editWorkOrder(newParams);
    }
  };

  _getAdhocDataSource = async () => {
    let dataSource = await getWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1',
      editWork: false,
      selectedId: '',
      form: {}
    });
  };
  editWorkOrder = async (record) => {
    // let { config } = this.state;
    let redirectPathURL = '';
    if (record.WorkType === 'Emergency Maintenance') {
      redirectPathURL = '/rubus/AMM/EWODetails';
    } else if (record.WorkType === 'Common Work') {
      redirectPathURL = '/rubus/AMM/FieldDataMeasurementDetails';
    }
    history.push({ pathname: `${redirectPathURL}/${base64_encode(record.Id)}` });
  };
  handleCancel = () => {
    this.setState({ visible: false });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          //  icon={<SearchOutlined/>}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          // type="link"
          size="small"
          style={{ width: 90 }}
          onClick={() => {
            clearFilters();
            confirm({
              closeDropdown: true
            });
          }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        type="search"
        style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }}
      />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  _deleteById = async (id) => {
    await deleteWorkOrder(id);
    let dataSource = await getWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1',
      initate: false
      // form: {}
    });
  };
  changeStatus = async (id) => {
    await changeWorkOrderStatus(id);
    let dataSource = await getWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1',
      initate: false
      // form: {}
    });
  };
  viewWorkFlow = async (record) => {
    this.setState({ visible: true });
    if (record && record.WorkFlowId && record.WorkFlowId !== null) {
      let NodeData = await getWorkFlow(record && record.WorkFlowId);
      this.setState({ workFlow: NodeData[0].Node });
    }
  };
  initateWorkOrder = async (record) => {
    let payload = {
      Id: record.Id,
      WorkFlowId: record.WorkFlowId,
      WfStatus: 'Submit',
      WorkTypeId: record.WorkTypeId,
      WorkOrderName: record.WorkOrder,
      RoleId: 1
    };
    let initateWO = await workOrderStatus(payload);
    if (initateWO && initateWO.message) {
      message.success(`${initateWO.message}`);
      this.setState({ initate: true });
      this._getAdhocDataSource();
    } else {
      this.setState({ initate: false });
    }
  };
  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };

  onModalCancel = () => {
    this.WorkFlowModalRef.current &&
      this.WorkFlowModalRef.current.setFieldsValue({
        WorkFlow: null
      });
    this.setState({ modalVisible: false });
  };
  onAddWorkFlow = async (record) => {
    let workFlowsList = await getWorkFlows(record.WorkTypeId);
    this.WorkFlowModalRef.current &&
      this.WorkFlowModalRef.current.setFieldsValue({
        WorkFlow: null
      });
    this.setState({ modalVisible: true, workFlowsList, selectedRecord: record });
  };
  onFinish = async (values) => {
    let { selectedRecord } = this.state;
    let UpdateWoFlow = await UpdateWorkFlow(selectedRecord.Id, values && values.WorkFlow);
    if (UpdateWoFlow && UpdateWoFlow.status && UpdateWoFlow.status === 200) {
      message.success(UpdateWoFlow && UpdateWoFlow.data && UpdateWoFlow.data.message);
      this.WorkFlowModalRef.current &&
        this.WorkFlowModalRef.current.setFieldsValue({
          WorkFlow: null
        });
      this.setState({ modalVisible: false });
      this._getAdhocDataSource();
    } else {
      message.error('Error');
    }
  };

  render() {
    const { dataSource, visible, workFlow, userList, workFlowsList } = this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, constant.workOrder),
        dataIndex: 'WorkOrder',
        key: 'WorkOrder',
        filter: true,
        ...this.getColumnSearchProps('WorkOrder')
      },
      {
        title: translation(translationCache, constant.description),
        dataIndex: 'Description',
        key: 'Description',
        ...this.getColumnSearchProps('Description', 'Description')
      },
      {
        title: translation(translationCache, constant.workType),
        dataIndex: 'WorkType',
        key: 'WorkType',
        ...this.getColumnSearchProps('WorkType', 'WorkType'),
        render: (text) => translation(translationCache, text)
      },
      {
        title: translation(translationCache, constant.site),
        dataIndex: 'SiteName',
        key: 'SiteName',
        filter: true
      },
      {
        title: translation(translationCache, constant.status),
        dataIndex: 'Status',
        key: 'Status',
        render: (text, record) => (
          <>
            <Tooltip title={`Click To ${text === 'Active' ? 'Inactive' : 'Active'}`}>
              <span onClick={() => this.changeStatus(record.Id)}>
                <Tag
                  color={
                    text && text === 'Active' ? '#87d068' : text === 'Inactive' ? '#ff4d4f' : 'gold'
                  }
                  key={text}
                >
                  {translation(translationCache, text)}
                </Tag>
              </span>
            </Tooltip>
          </>
        )
      },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            {record.WorkFlowId && record.WorkFlowId !== null ? (
              <>
                <Tooltip title="View WorkFlow">
                  <Button
                    style={{ marginRight: '5px' }}
                    className="ant-btn"
                    size="middle"
                    onClick={() => {
                      this.viewWorkFlow(record);
                    }}
                    icon={<ApartmentOutlined />}
                  />
                </Tooltip>
                <Tooltip title="Initiate WorkFlow">
                  <Button
                    style={{ marginRight: '5px' }}
                    // className="ant-btn"
                    size="middle"
                    disabled={record && record.RecordStatus === null ? false : true}
                    onClick={() => {
                      this.setState({ initate: true });
                      this.initateWorkOrder(record);
                    }}
                    icon={<NodeExpandOutlined />}
                  />
                </Tooltip>
              </>
            ) : (
              <Tooltip title="Update WorkFlow">
                <Button
                  style={{ marginRight: '5px' }}
                  className="ant-btn"
                  size="middle"
                  onClick={() => {
                    this.onAddWorkFlow(record);
                  }}
                  icon={<PlusOutlined />}
                />
              </Tooltip>
            )}

            {record.WorkType === 'Emergency Maintenance' || record.WorkType === 'Common Work' ? (
              <Button
                disabled={record.Status === 'Inactive' ? true : false}
                style={{ marginRight: '5px' }}
                className="ant-btn"
                onClick={() => {
                  this.editWorkOrder(record);
                }}
                size="middle"
              >
                <Tooltip title={'Edit'}>
                  <EditOutlined />
                </Tooltip>
              </Button>
            ) : null}

            <Popconfirm
              title={translation(
                translationCache,
                'Are you sure that you want to delete the WorkOrder'
              )}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this._deleteById(record.Id)}
              okText={translation(translationCache, constant.yes)}
              cancelText={translation(translationCache, constant.no)}
            >
              <Button className="ant-btn" size="middle">
                <span style={{ color: '#ff4d4f' }}>
                  {' '}
                  <Tooltip title={'Delete'}>
                    <DeleteOutlined />
                  </Tooltip>
                </span>
              </Button>
            </Popconfirm>
          </>
        )
      }
    ];
    let config = {
      title: translation(translationCache, 'WorkOrder'),

      viewTypes: ['card', 'table'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['WorkOrder'],

      firstTitle: 'WorkOrder',
      secondTitle: 'CreatedBy',
      thirdTitle: 'newCreatedTime',

      actions: ['edit', 'delete'],
      edit: {
        byAttribute: 'Id',
        redirectURL: '/rubus/AMM/WorkOrderDetails',
        redirectDetailsURL: '/rubus/AMM/WorkOrderTaskDetails'
      },
      deletee: {
        byAttribute: 'Id'
      }
    };
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '0px 30px', minHeight: window.innerHeight - 113 }}
      >
        <Drawer
          // width={'1400px'}
          width={window.innerWidth > 19000 ? 18000 : window.innerWidth - 100}
          visible={visible}
          onClose={this.handleCancel}
          title="WorkFlow Flow Chart"
        >
          <Flowchart
            showToolbar={false}
            style={{ width: '100%', height: 500 }}
            nodes={workFlow && workFlow.nodes}
            connections={workFlow && workFlow.connections}
          />
        </Drawer>
        <StyledModal
          open={this.state.modalVisible}
          width={800}
          closable={true}
          title={translation(translationCache, 'Update WorkFlow')}
          footer={null}
          onCancel={this.onModalCancel}
        >
          <StyledForm
            name="WorkFlow"
            theme={themeSettings}
            ref={this.WorkFlowModalRef}
            // initialValues={formObject}
            onFinish={this.onFinish}
            layout="horizontal"
            labelCol={{ flex: '200px' }}
            labelAlign="right"
            labelWrap
            wrapperCol={{ flex: 1 }}
          >
            <Form.Item
              label={translation(translationCache, 'Update WorkFlow')}
              name="WorkFlow"
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: '70%' }}
                placeholder={translation(this.props.translationCache, 'Please Select WorkFlow')}
              >
                {workFlowsList &&
                  workFlowsList !== undefined &&
                  workFlowsList.map((workFlow, index) => {
                    return (
                      <Option key={index} value={workFlow.Id}>
                        {workFlow.WorkFlowName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <div style={{ paddingLeft: '200px' }}>
              <Button type="primary" htmlType="submit">
                {translation(translationCache, constant.submit)}
              </Button>
            </div>
          </StyledForm>
        </StyledModal>

        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WorkOrderList);
