import React, { Component } from 'react';

import { StyledDashboard, StyledTable, lightTheme } from '../style.js';
import { Button, Drawer, Form, Select, Table, Tooltip, message } from 'antd';
import {
  getDeviceListByFilters,
  getDeviceTypeExclusionList,
  ruleListForDeviceType,
  updateRuleExclusion
} from '../Apicalls.js';
import { DrawerFooter } from '../../Modules/Hierarchy/style.js';
import { EditOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { getCurrentTimezone } from '../../../selectors/layout.js';
import { getTranslationCache } from '../../../selectors/language.js';
import { createStructuredSelector } from 'reselect';

const { Option } = Select;
class DeviceTypeRuleExclusion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editObj: {},
      ruleData: {}
    };
  }

  hRef = React.createRef();
  componentDidMount() {
    this.getDatasourceList();
    this.getRuleList();
  }

  getRuleList = async () => {
    let ruleData = await ruleListForDeviceType();
    this.setState({ ruleData });
  };

  getDatasourceList = async () => {
    let data = await getDeviceTypeExclusionList();
    this.setState({ data });
  };

  translation = (keyword) => {
    let { translationcache } = this.props;
    return translationcache && translationcache[keyword] ? translationcache[keyword] : keyword;
  };
  _update = () => {
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        let saveResponse = await updateRuleExclusion(values);
        if (saveResponse && saveResponse.data && saveResponse.data.message) {
          message.success(saveResponse.data.message);
          this.onClose();
          this.getDatasourceList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onClose = () => {
    this.setState({ showDrawer: false, editObj: {} });
    if (this.hRef.current) this.hRef.current.resetFields();
  };

  add = () => {
    this.setState({ showDrawer: true, editObj: {} });
    if (this.hRef.current) this.hRef.current.resetFields();
  };

  onEdit = (editObj) => {
    this.setState({
      editObj,
      showDrawer: true
    });

    if (this.hRef.current) this.hRef.current.setFieldsValue(editObj);
  };

  onRuleChange = async (value) => {
    let { ruleData } = this.state;
    let deviceObject = ruleData.find((rule) => rule.Id === value);
    let deviceList = await getDeviceListByFilters(deviceObject);
    this.setState({ deviceList });
  };

  render() {
    let { data, showDrawer, editObj, ruleData, deviceList } = this.state;
    let columns = [
      {
        title: 'Rule Name',
        dataIndex: 'Name',
        key: 'Name',
        render: (text) => {
          return {
            children: <div>{text}</div>
          };
        }
      },
      // {
      //   title: 'Created By',
      //   dataIndex: 'CreatedbyName',
      //   key: 'CreatedbyName'
      // },
      // {
      //   title: 'Created Date',
      //   dataIndex: 'CreatedDate',
      //   key: 'CreatedDate',
      //   render: (text) => {
      //     return {
      //       children: <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>
      //     };
      //   }
      // },
      {
        title: this.translation('Action'),
        dataIndex: 'Action',
        key: 'Action',
        render: (text, row) => {
          return {
            children: (
              <div>
                <Tooltip title={this.translation('Edit')}>
                  <Button
                    className="ant-btn"
                    size="middle"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.onEdit(row);
                    }}
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
              </div>
            )
          };
        }
      }
    ];
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 69 }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0px' }}>
          <Button type="primary" onClick={() => this.add()}>
            Add
          </Button>
        </div>

        <Drawer
          title={!editObj.Id ? this.translation('Create') : this.translation('Update')}
          visible={showDrawer}
          width={600}
          onClose={this.onClose}
        >
          <Form
            layout="vertical"
            name={'DeviceTypeRules'}
            ref={this.hRef}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={editObj || {}}
          >
            <Form.Item label={'Select Rule'} name="Id" rules={[{ required: true }]}>
              <Select placeholder={this.translation('Select Rule')} onChange={this.onRuleChange}>
                {ruleData &&
                  Array.isArray(ruleData) &&
                  ruleData.map((rule, index) => {
                    return (
                      <Option key={index} value={rule.Id}>
                        {rule.DisplayRuleName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label={'Select exclusion device'}
              name="Exclude"
              rules={[{ required: true }]}
            >
              <Select mode="tags" placeholder={this.translation('Select Devices')}>
                {deviceList &&
                  Array.isArray(deviceList) &&
                  deviceList.map((rule, index) => {
                    return (
                      <Option key={index} value={rule.AliasCode}>
                        {rule.Name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            <Button type="primary" onClick={() => this._update()}>
              {this.translation('Update')}
            </Button>
          </DrawerFooter>
        </Drawer>

        <StyledTable theme={lightTheme}>
          <Table columns={columns} dataSource={data} />
        </StyledTable>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(DeviceTypeRuleExclusion);
