import React, { Fragment } from 'react';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { Table, Button, message, Input, Checkbox, DatePicker, Form, Drawer } from 'antd';
import { EditOutlined, CaretLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import constants from './constants';
import history from '../../commons/history';
import localStorage from '../../utils/localStorage';
import { translation } from '../../commons/translation';
import { getTranslationCache } from '../../selectors/language';
import {
  getRuleExecutionInfo,
  saveFeedback,
  getfeedBackInfo,
  updateFeedBack,
  getCommunicationList
} from './Apicalls';
import { StyledDashboard, StyledTable, lightTheme } from './style';
import 'antd/lib/date-picker/style/css';
import { getCurrentTimezone } from '../../selectors/layout';
const { RangePicker } = DatePicker;

class RulesFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      openModel: false,
      feedBackInfo: {},
      currentPage: 1,
      editFlag: false,
      selectedDate: {
        startDate: moment().subtract(1, 'hour').format('YYYY-MM-DD 00:00'),
        endDate: moment().format('YYYY-MM-DD 23:59')
      },
      communicationTypes: []
    };
    this.hRef = React.createRef();
  }

  componentDidMount() {
    this.getRuleHistory([moment().format('YYYY-MM-DD 00:00'), moment().format('YYYY-MM-DD 23:59')]);
    this.communicationType();
  }
  communicationType = async () => {
    let commlist = await getCommunicationList();
    this.setState({ communicationTypes: commlist.data });
  };
  getRuleHistory = async (date) => {
    try {
      let queryString = this.props.location.search;
      let ruleId = queryString.split('=')[1];
      if (ruleId) {
        let ruleStatuInfo = await getRuleExecutionInfo(ruleId, date);
        this.setState({ tableData: ruleStatuInfo.data });
      }
    } catch (error) {
      message.error(`${error.response.data.message}`);
    }
  };

  handleFeedbackEvents = (e, type) => {
    let { feedBackInfo } = this.state;
    if (type === 'actions') {
      const { translationCache } = this.props;
      let actionOptions;

      let keys = Object.keys(translationCache);

      let arrConverter = e.map((action) => {
        let data = keys.filter((labels) => {
          return translationCache[labels] === action;
        });
        if (data[0] === undefined) {
          return [action];
        } else {
          return data;
        }
      });
      actionOptions = arrConverter.map((indexedArry) => {
        return indexedArry[0];
      });
      feedBackInfo['actions'] = actionOptions;
    } else {
      feedBackInfo['comments'] = e.target.value;
    }
    this.setState({ feedBackInfo });
  };

  handleModelCancel = () => {
    this.hRef.current.resetFields();
    this.setState({ openModel: false });
  };

  handleFeedbacksave = async () => {
    const { feedBackInfo, editFlag } = this.state;
    let siteId = localStorage.get('currentSite');
    let payload = {
      Action: feedBackInfo.actions,
      Comments: feedBackInfo.comments,
      StatusId: feedBackInfo.Id,
      TagId: feedBackInfo.TagId,
      TimeStamp: feedBackInfo.timeStamp,
      SiteId: siteId
    };
    try {
      if (!editFlag) {
        await saveFeedback(payload);
        this.setState({ openModel: false });
      } else {
        await updateFeedBack(payload);
        this.setState({ openModel: false, feedBackInfo: {} });
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  handlePagination = async (pageNumber) => {
    this.setState({ currentPage: pageNumber.current });
  };

  // onChangeDate = (date, dateString) => {
  //   this.getRuleHistory(dateString)
  //   this.setState({
  //     selectedDate:date
  //   })
  // }
  onChange = (value) => {
    this.getRuleHistory(value);
    this.setState({
      selectedDate: {
        startDate: moment(value[0]).format('YYYY-MM-DD HH:mm'),
        endDate: moment(value[1]).format('YYYY-MM-DD HH:mm')
      }
    });
  };

  onOk = () => {};

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(constants.search, this.props.translationCache)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(constants.search, this.props.translationCache)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(constants.Reset, this.props.translationCache)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    const { feedBackInfo, tableData, openModel, editFlag, selectedDate, communicationTypes } =
      this.state;
    const { translationCache } = this.props;
    const columns = [
      {
        title: translation(constants.sno, translationCache),
        dataIndex: 'RuleId',
        key: 'RuleId',
        render: (text, record, index) => {
          const { currentPage } = this.state;
          return (
            <span>
              {currentPage === 1 ? currentPage * (index + 1) : (currentPage - 1) * 10 + (index + 1)}
            </span>
          );
        }
      },
      {
        title: translation(constants.tagName, translationCache),
        dataIndex: 'ParameterCode',
        ...this.getColumnSearchProps('ParameterCode')
      },
      {
        title: translation(constants.tagValue, translationCache),
        dataIndex: 'ParameterValue',
        ...this.getColumnSearchProps('ParameterValue')
      },
      {
        title: translation(constants.communicationType, translationCache),
        dataIndex: 'Type',
        key: 'Type',
        ...this.getColumnSearchProps('Type')
      },
      {
        title: translation(constants.exeTime, translationCache),
        dataIndex: 'NotificationTime',
        key: 'NotificationTime',
        ...this.getColumnSearchProps('NotificationTime'),
        render: (text) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
      },

      {
        title: translation(constants.fdBack, translationCache),
        key: 'Feedback',
        render: (text, record) => (
          <span>
            <EditOutlined
              onClick={async () => {
                let { feedBackInfo } = this.state;
                feedBackInfo = {
                  ...feedBackInfo,
                  timeStamp: record.NotificationTime,
                  TagId: record.ParameterCode,
                  Id: record.Id,
                  Type: record.Type,
                  actions: [],
                  comments: record.comments ? record.comments : ''
                };

                let editFlag = false;
                try {
                  let feedBackData = await getfeedBackInfo(record.Id);
                  if (feedBackData && feedBackData.data && feedBackData.data.length > 0) {
                    feedBackInfo.actions = feedBackData.data[0].Action;
                    feedBackInfo['comments'] = feedBackData.data[0].Comments;
                    editFlag = true;
                  }
                } catch (error) {
                  console.log(error);
                }
                if (this.hRef.current) {
                  this.hRef.current.setFieldsValue(feedBackInfo);
                }
                this.setState({
                  openModel: true,
                  selectedRecord: record,
                  feedBackInfo,
                  editFlag
                });
              }}
            />
          </span>
        )
      }
      // {
      //   title: translation(constants.rootCause, translationCache),
      //   key: 'RootCause',
      //   render: (text, record) => (
      //     <span
      //       style={{ fontSize: '19px' }}
      //       onClick={async () => {
      //         const { feedBackInfo } = this.state;
      //         feedBackInfo.timeStamp = record.NotificationTime;
      //         feedBackInfo.TagId = record.ParameterCode;
      //         feedBackInfo.Id = record.Id;
      //         feedBackInfo.actions = [];
      //         feedBackInfo['comments'] = '';
      //         this.props.history.push(
      //           `/rubus/Rule/RootCause/?Device=${record.Id}&Parameter=${record.ParameterCode}&EndTime=${record.NotificationTime}`
      //         );
      //         try {
      //           let feedBackData = await getfeedBackInfo(record.RuleId);
      //           if (feedBackData.data.length > 0) {
      //             feedBackInfo.actions = feedBackData.data[0].Action;
      //             feedBackInfo['comments'] = feedBackData.data[0].Comments;
      //           }
      //         } catch (error) {
      //           message.warn("Can't Fetch Feedback Info");
      //         }
      //       }}
      //     >
      //       <ClusterOutlined />
      //     </span>
      //   )
      // }
    ];

    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 14 }
    };

    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 69 }}>
        <Fragment>
          <div>
            <Button
              type="primary"
              onClick={() => {
                history.goBack();
              }}
            >
              <CaretLeftOutlined />
              {translation(constants.Go_Back)}
            </Button>
            <div>
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                style={{ width: '300px', float: 'right' }}
                format="YYYY-MM-DD HH:mm"
                value={[moment(selectedDate.startDate), moment(selectedDate.endDate)]}
                onChange={this.onChange}
                disabledDate={this.disabledDate}
                onOk={this.onOk}
              />
            </div>

            <StyledTable theme={lightTheme}>
              <Table
                style={{ marginTop: '3%' }}
                dataSource={tableData}
                columns={columns}
                onChange={this.handlePagination}
              />
            </StyledTable>

            <Drawer
              title={editFlag ? 'Update Feedback' : 'Create Feedback'}
              closable
              centered
              width={800}
              visible={openModel}
              footer={[
                <Button key="back" onClick={this.handleModelCancel}>
                  {translation(constants.cancel)}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    this.handleFeedbacksave();
                  }}
                  style={{ fontWeight: 700, marginLeft: '5px' }}
                >
                  {editFlag ? translation(constants.update) : translation(constants.save)}
                </Button>
              ]}
              onClose={this.handleModelCancel}
            >
              <Form {...formItemLayout} ref={this.hRef} initialValues={feedBackInfo}>
                <Form.Item label={translation(constants.timeStamp)}>
                  <Input
                    readOnly
                    value={
                      feedBackInfo && feedBackInfo.timeStamp
                        ? moment
                            .utc(feedBackInfo.timeStamp, 'YYYY-MM-DD HH:mm:ss')
                            .tz(this.props.currentTimeZone)
                            .format('YYYY-MM-DD HH:mm:ss')
                        : null
                    }
                  />
                </Form.Item>
                <Form.Item label={translation(constants.tagName)}>
                  <Input readOnly value={feedBackInfo ? feedBackInfo.TagId : null} />
                </Form.Item>
                <Form.Item label={translation(constants.action)}>
                  <Checkbox.Group
                    options={
                      communicationTypes && Array.isArray(communicationTypes)
                        ? communicationTypes.map((comm) => {
                            return { label: comm.Name, value: comm.Name };
                          })
                        : []
                    }
                    value={
                      feedBackInfo !== undefined && feedBackInfo.actions
                        ? Array.isArray(feedBackInfo.actions)
                          ? feedBackInfo.actions
                          : [feedBackInfo.actions]
                        : []
                    }
                    onChange={(e) => {
                      this.handleFeedbackEvents(e, 'actions');
                    }}
                  />
                </Form.Item>
                <Form.Item label={translation(constants.comment)}>
                  <Input.TextArea
                    value={feedBackInfo.comments ? feedBackInfo.comments : ''}
                    onChange={(e) => this.handleFeedbackEvents(e)}
                  />
                </Form.Item>
              </Form>
            </Drawer>
          </div>
        </Fragment>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(RulesFeedback);
