import React, { Component } from 'react';
import axios from 'axios';
import { Select } from 'antd';

import localStorage from '../../../utils/localStorage';

const { Option } = Select;

class SelectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      formObject: this.props && this.props.formObject ? this.props.formObject : [],
      Code: this.props && this.props.Code ? this.props.Code : ''
    };
  }

  componentDidUpdate(prevprops) {
    if (
      prevprops.api !== this.props.api ||
      prevprops.formObject !== this.props.formObject ||
      prevprops.Code !== this.props.Code
    ) {
      this.setState({
        api: this.props.api,
        formObject: this.props.formObject,
        Code: this.props.Code
      });
    }
  }

  componentDidMount() {
    this._getOptionList(this.props.api);
  }

  _getOptionList = (api) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let objectHeader = {};
    let { ModuleCode } = this.props;
    if (api && api.url && api.method !== 'POST') {
      objectHeader = {
        method: 'GET',
        url: `${api.url}?SiteId=${siteId}&ModuleCode=${this.props.ModuleCode}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
    } else if (api && api.url && api.method && api.payload && api.method === 'POST') {
      objectHeader = {
        method: api.method,
        url: `${api.url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          SiteId: siteId,
          ModuleCode: ModuleCode
        }
      };
    }
    return axios(objectHeader)
      .then((response) => {
        if (Array.isArray(response.data)) {
          let optionList = response.data;
          this.setState({
            optionList
          });
        }
      })
      .catch(() => {
        return [];
      });
  };

  render() {
    let { optionList, Code, formObject } = this.state;
    return (
      <Select
        value={formObject && formObject[Code] ? formObject[Code] : []}
        onChange={(e) => this.props.onSelectChange(e)}
        placeholder={this.props.placeholder}
      >
        {optionList &&
          Array.isArray(optionList) &&
          optionList.map((data, index) => {
            return (
              <Option key={index} value={data.Code}>
                {data.Name}
              </Option>
            );
          })}
      </Select>
    );
  }
}

export default SelectComponent;
