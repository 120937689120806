import React, { Component } from 'react';
import axios from 'axios';
import {
  Input,
  Form,
  Col,
  Row,
  Select,
  Upload,
  Button,
  message,
  Tabs,
  Tooltip,
  Popconfirm
} from 'antd';
import {
  PlusOutlined,
  UploadOutlined,
  DoubleLeftOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import XMLViewer from 'react-xml-viewer';
import history from '../../../../../src/commons/history';
import { getMasterById, getMaximoList } from '../../../AMM/Utils/FormCalls';
import localStorage from '../../../../utils/localStorage';
import { StyledDashboard, StyledModal } from './style';
import './style.css';
import { SELECTEDFILE, XMLFORMAT } from '../../../../commons/api';
const minifyXML = require('minify-xml').minify;
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const urlList = {
  getbyId: `${XMLFORMAT.GetById}`
};

class XMLConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UniqueKeysList: [],
      uploadFileData: {},
      selectdeFileUploadData: {},
      filename: '',
      dataObject: '',
      childXMLdata: '',
      childDataObject: '',
      PayloadType: '',
      xmlView: false,
      XmlViewData: '',
      sitesList: [],
      isModalVisible: false,
      isChildModalVisible: false,
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},

      parentData: {},
      TypeList: [],
      UniqueKeysChildList: [],
      AllChildList: {},
      AllUniqueKeyList: [],
      ChildKeyList: {},
      selectedObj: {},
      ChildMappingKeysArray: [],
      AddParentDisable: true,
      AddChildDisable: true
    };
  }

  userRef = React.createRef();
  formRef = React.createRef();
  childRef = React.createRef();
  componentDidMount = () => {
    this._getDataById();
  };

  _getDataById = async (ItemId) => {
    let formObject = this.userRef.current.getFieldsValue();
    let path = history.location.pathname.split('/rubus/MaximoXMLConfiguration/');
    let formId = ItemId ? ItemId : path[1];
    if (formId !== '1') {
      let data = await getMasterById(urlList.getbyId, formId, 'GET');
      formObject = data && data !== undefined ? data : {};
    }
    if (this.userRef.current) {
      this.userRef.current.setFieldsValue(formObject);
    }

    this.setState({
      formObject
    });
    if (formObject) {
      this.userRef.current.setFieldsValue(formObject);
    }
    let TypeList = await getMaximoList();
    this.setState({ TypeList });
  };

  getUniqueKeysList = (payload) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const fileObject = {
      method: 'POST',
      url: `${XMLFORMAT.UniqueColumnsByXmlData}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(fileObject)
      .then((response) => {
        this.setState({ UniqueKeysList: response.data, AddParentDisable: false });
        return response;
      })
      .catch(function () {});
  };
  getUniqueKeysChildList = (payload, index) => {
    let { AllUniqueKeyList } = this.state;
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const fileObject = {
      method: 'POST',
      url: `${XMLFORMAT.UniqueColumnsByXmlData}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(fileObject)
      .then((response) => {
        let data = {
          ...AllUniqueKeyList,
          [index]: response.data
        };
        this.setState({
          UniqueKeysChildList: response.data,
          AllUniqueKeyList: data,
          AddChildDisable: false
        });
      })
      .catch(function () {});
  };
  ShowXmlView = () => {
    const { xmlView } = this.state;
    this.setState({ xmlView: !xmlView });
  };
  handleChange = (info) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  //  * @author sapna.m
  //  * @description XMLConfigUpload function is used to read file data
  //  * @return Get content of file
  XMLConfigUpload = (file) => {
    this.formRef.current && this.formRef.current.resetFields();
    this.userRef.current.resetFields(['DataObject']);
    this.userRef.current.resetFields(['UniqueKeys']);
    const reader = new FileReader();
    reader.onload = (e) => {
      const xml = e.target.result;
      this.setState({
        // uploadFileData: minifyXML(xml), // avoiding minnifyXMl to prevent missing of random xml file content//
        uploadFileData: xml,
        filename: file.name,
        XmlViewData: xml,
        parentData: {}
      });
    };
    reader.readAsText(file);
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isXML = file.type === 'text/xml';
    if (!isXML) {
      message.error('You can only upload xml file!');
    }
    if (!isLt2M) {
      message.error('xml file must be smaller than 10MB!');
    }
    return false;
  };

  //  * @author sapna.m
  // * @description This function is used to Save Form data
  SaveConfigurationUpload = () => {
    this.userRef.current
      .validateFields()
      .then(async (values) => {
        const { uploadFileData, parentData, ChildMappingKeysArray, selectedChildIndex } =
          this.state;
        if (parentData && Object.values(parentData).length !== 0 && values.child === undefined) {
          let fileObject = {
            Name: values.Name,
            type: 'Parent',
            DataObject: values.DataObject,
            XmlFormat: uploadFileData.replace(/"/g, "'"),
            PayloadType: values.PayloadType,
            UniqueKeys: values.UniqueKeys,
            MappingKeys: parentData,
            TableName: values.Type
          };
          if (
            fileObject &&
            fileObject.PayloadType === 'Response' &&
            fileObject.PayloadType !== 'Request'
          ) {
            fileObject['ResponseUrl'] = values.ResponseUrl;
            fileObject['Group'] = values.Group;
          }

          let statusResponse = this.saveConfigurationAPi(fileObject);

          if (statusResponse && statusResponse.message) {
            message.success('Record Submitted Successfully');
          } else if (statusResponse && statusResponse.Error) {
            message.error(statusResponse.Error);
          }
          if (this.userRef.current) {
            this.userRef.current.resetFields();
            this.setState({ formObject: {} });
          }
          if (this.formRef.current) {
            this.formRef.current.resetFields();
            this.setState({ parentData: {} });
          }

          this.setState({
            uploadFileData: {},
            filename: '',
            formObject: {},
            parentData: {},
            AddParentDisable: false,
            ChildMappingKeysArray: []
          });
        } else if (
          values.child &&
          Array.isArray(values.child) &&
          values.child !== undefined &&
          ChildMappingKeysArray &&
          ChildMappingKeysArray.length !== 0
        ) {
          let fileObject = {
            Name: values.Name,
            type: 'Parent',
            DataObject: values.DataObject,
            XmlFormat: uploadFileData.replace(/"/g, "'"),
            PayloadType: values.PayloadType,
            UniqueKeys: values.UniqueKeys,
            MappingKeys: parentData,
            TableName: values.Type,
            child:
              ChildMappingKeysArray &&
              ChildMappingKeysArray[selectedChildIndex] &&
              Object.values(ChildMappingKeysArray[selectedChildIndex]).length !== 0 &&
              values.child &&
              Array.isArray(values.child) &&
              values.child.map((data, index) => {
                return {
                  DataObject: data.DataObject,
                  type: 'Child',
                  XmlFormat:
                    data.XmlFormat && data.XmlFormat
                      ? minifyXML(data.XmlFormat).replace(/"/g, "'")
                      : data.XmlFormat,
                  UniqueKeys: data.UniqueKeys,
                  MappingKeys: ChildMappingKeysArray[index],
                  TableName: data.Type
                };
              })
          };
          if (
            fileObject &&
            fileObject.PayloadType === 'Response' &&
            fileObject.PayloadType !== 'Request'
          ) {
            fileObject['ResponseUrl'] = values.ResponseUrl;
            fileObject['Group'] = values.Group;
          }

          let statusResponse = this.saveConfigurationAPi(fileObject);

          if (statusResponse && statusResponse.message) {
            message.success('Record Submitted Successfully');
          } else if (statusResponse && statusResponse.Error) {
            message.error(statusResponse.Error);
          }
          if (this.userRef.current) {
            this.userRef.current.resetFields();
            this.setState({ formObject: {} });
          }
          if (this.formRef.current) {
            this.formRef.current.resetFields();
            this.setState({ parentData: {} });
          }

          this.setState({
            uploadFileData: {},
            filename: '',
            formObject: {},
            parentData: {},
            AddParentDisable: false,
            ChildMappingKeysArray: []
          });
        } else if (parentData && Object.values(parentData).length === 0) {
          message.error('Please select Parent Mapping Keys');
        } else if (
          values.child &&
          Array.isArray(values.child) &&
          values.child !== undefined &&
          ChildMappingKeysArray &&
          ChildMappingKeysArray.length === 0
        ) {
          message.error('Please Select Child Mapping Keys');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onFormItemSubmit = (e) => {
    e.preventDefault();
    this.formRef.current &&
      this.formRef.current
        .validateFields()
        .then(async (values) => {
          this.setState({ isModalVisible: false, parentData: values });
        })
        .catch((error) => {
          console.log(error);
        });
  };
  onChildModalSubmit = () => {
    let { selectedObj, selectedChildIndex, ChildMappingKeysArray } = this.state;
    //  this.setState({selectedObj:{},isChildModalVisible:false})
    let data = {};
    if (selectedObj && Object.keys(selectedObj).length !== 0) {
      data = {
        ...ChildMappingKeysArray,
        [selectedChildIndex]: selectedObj
      };
      this.setState({
        selectedObj: {},
        isChildModalVisible: false,
        ChildMappingKeysArray: Object.values(data)
      });
    } else {
      message.error('Please select atleast One Mapping Key');
    }
  };

  //APi for save
  saveConfigurationAPi = (payload) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const fileObject = {
      method: 'POST',
      url: `${XMLFORMAT.CREATE}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(fileObject)
      .then(() => {
        message.success('Successfully Submitted');
        if (this.userRef.current) {
          this.userRef.current.resetFields();
          this.setState({ formObject: {} });
        }
      })
      .catch(function () {
        message.error('Error');
      });
  };

  SelectedFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        selectdeFileUploadData: e.target.result
      });
    };
    reader.readAsText(file);

    const isLt2M = file.size / 1024 / 1024 < 10;
    const isXML = file.type === 'text/xml';
    if (!isXML) {
      message.error('You can only upload xml file!');
    }
    if (!isLt2M) {
      message.error('xml file must be smaller than 10MB!');
    }
    return isLt2M && isXML;
  };

  handleSelectChange = (info) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  _SaveDataEntryApi = (payload) => {
    const accessToken = localStorage.get('accessToken');
    const fileObject = {
      method: 'POST',
      url: `${SELECTEDFILE.DataEntryUpload}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(fileObject)
      .then(() => {
        if (this.userRef.current) {
          this.userRef.current.resetFields();
        }
      })
      .catch(function () {});
  };

  saveDataEntryUpload = (e) => {
    e.preventDefault();
    this.userRef.current.validateFields(['SelectedFile', 'xmlFormat']).then(async (values) => {
      const { selectdeFileUploadData } = this.state;
      let fileObject = {
        xmlFormat: selectdeFileUploadData,
        SelectedFile: values.SelectedFile
      };
      this._SaveDataEntryApi(fileObject);
    });
  };

  //  * @author sapna.m
  // * @description This Function is used for getting uniqueKeys for multi select option
  handleDataObject = (e) => {
    e.preventDefault();
    this.userRef.current.validateFields(['DataObject']).then(async (values) => {
      const { uploadFileData } = this.state;
      let fileObject = {
        DataObject: values.DataObject,
        XmlFormat: uploadFileData
      };
      this.getUniqueKeysList(fileObject); //uniqueKeys List will get from this function
    });
  };
  handleDataObjectChild = (e) => {
    this.setState({ childDataObject: e.target.value });
  };
  ChildXMLData = async (e, index) => {
    // e.preventDefault();
    const { childDataObject } = this.state;
    this.setState({ childXMLdata: e.target.value });
    let formObject = {
      DataObject: childDataObject,
      XmlFormat: e.target.value
    };
    this.getUniqueKeysChildList(formObject, index);
  };
  onHandleChanePayload = (value) => {
    this.setState({
      PayloadType: value
    });
  };
  onAddMapping = () => {
    this.setState({ isModalVisible: true });
  };
  onChildAdd = (selectedChildIndex) => {
    // check weather data exists are not
    let { AllChildList, AllUniqueKeyList } = this.state;

    let getMaximoKeysArray =
      AllUniqueKeyList &&
      AllUniqueKeyList[selectedChildIndex] &&
      AllUniqueKeyList[selectedChildIndex].dataKeys
        ? AllUniqueKeyList[selectedChildIndex].dataKeys
        : {};

    let keyList = {};
    getMaximoKeysArray &&
      Array.isArray(getMaximoKeysArray) &&
      getMaximoKeysArray.map((key) => {
        keyList[key] = '';
        return {};
      });

    AllChildList = {
      ...AllChildList,
      [selectedChildIndex]: keyList
    };
    this.setState({
      isChildModalVisible: true,
      selectedChildIndex,
      AllChildList,
      ChildKeyList: AllUniqueKeyList[selectedChildIndex]
    });
  };
  onKeySelect = (data, value) => {
    let { selectedObj } = this.state;
    let selectedObjData = {
      ...selectedObj,
      [data]: value
    };
    this.setState({ selectedObj: selectedObjData });
  };
  onModalClose = () => {
    this.setState({ isModalVisible: false });
  };
  onChildModalClose = () => {
    this.setState({ isChildModalVisible: false });
  };
  render() {
    let {
      UniqueKeysList,
      AllChildList,
      xmlView,
      XmlViewData,
      PayloadType,
      formObject,
      TypeList,
      AllUniqueKeyList,
      ChildMappingKeysArray,
      selectedChildIndex,
      AddParentDisable,
      AddChildDisable
    } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };
    const customTheme = {
      tagColor: '#41b341',
      attributeKeyColor: '#0074D9',
      attributeValueColor: '#b36b41',
      overflowBreak: true,
      textColor: '#FFF'
    };

    return (
      <StyledDashboard
        className="EAMForm"
        style={{ minHeight: window.innerHeight - 173, color: '#ffb327' }}
      >
        <div
          style={{ marginTop: '30px', marginBottom: '30px', color: 'black', fontWeight: 'bold' }}
        >
          <Button type="dashed" style={{ marginBottom: '20px' }} onClick={() => history.goBack()}>
            <DoubleLeftOutlined />
            Go Back
          </Button>
          <>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Configuration" key="1">
                <Row style={{ float: 'right', marginTop: '-40px' }}>
                  <Button type="danger" style={{ fontWeight: 'bold' }} onClick={this.ShowXmlView}>
                    {xmlView === false ? 'Show XmlView' : 'Hide XmlView'}
                  </Button>
                </Row>
                <Form
                  className="XMLForm"
                  ref={this.userRef}
                  onFinish={this.SaveConfigurationUpload}
                  onFinishFailed={this.onFinishFailed}
                  layout="vertical"
                  style={{ marginTop: '50px' }}
                  initialValues={formObject}
                >
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Name :"
                        name="Name"
                        rules={[{ required: true, message: 'Please enter Name' }]}
                      >
                        <Input style={{ width: '350px' }} placeholder="Please enter" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Upload File :"
                        name="XmlFormat"
                        rules={[{ required: true, message: 'Please enter XmlFormat' }]}
                      >
                        <Upload
                          name="file"
                          accept="text/xml"
                          multiple="false"
                          beforeUpload={this.XMLConfigUpload}
                          onChange={this.handleChange}
                        >
                          <Button style={{ width: '350px' }}>
                            <UploadOutlined /> Click to Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="DataObject :"
                        name="DataObject"
                        rules={[{ required: true, message: 'Please enter DataObject' }]}
                      >
                        <Input
                          style={{ width: '350px' }}
                          placeholder="Please enter"
                          onBlur={this.handleDataObject}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="PayloadType :"
                        name={'PayloadType'}
                        rules={[{ required: true, message: 'Please enter PayloadType' }]}
                      >
                        <Select
                          style={{ width: '350px' }}
                          placeholder="Please select PayloadType "
                          onChange={this.onHandleChanePayload}
                        >
                          <Option value="Request">Request</Option>
                          <Option value="Response">Response</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {PayloadType === 'Response' ? (
                      <>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            label="ResponseUrl :"
                            name={'ResponseUrl'}
                            rules={[{ required: true, message: 'Please enter ResponseUrl' }]}
                          >
                            <Input
                              placeholder=" Please Enter ResponseUrl"
                              style={{ width: '350px' }}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            label="Group :"
                            name={'Group'}
                            rules={[{ required: true, message: 'Please enter Group' }]}
                          >
                            <Input placeholder="Please Enter Group" style={{ width: '350px' }} />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null}
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="UniqueKeys :"
                        name="UniqueKeys"
                        rules={[{ required: true, message: 'Please select UniqueKeys' }]}
                      >
                        <Select
                          style={{ width: '350px' }}
                          mode="multiple"
                          placeholder="Please select"
                        >
                          {UniqueKeysList &&
                            UniqueKeysList.dataKeys &&
                            UniqueKeysList.dataKeys.map((uniquekey, index) => {
                              return (
                                <Option key={index} value={uniquekey}>
                                  {uniquekey}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Form.Item
                        label="Type:"
                        name="Type"
                        rules={[{ required: true, message: 'Please select Type' }]}
                      >
                        <Select
                          style={{ width: '350px' }}
                          // mode="multiple"
                          placeholder="Please select"
                        >
                          {TypeList &&
                            TypeList.map((Type, index) => {
                              return (
                                <Option key={index} value={Type.Key}>
                                  {Type.Key}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Form.Item style={{ paddingTop: '22px' }}>
                        <Button
                          type="primary"
                          onClick={this.onAddMapping}
                          disabled={AddParentDisable}
                        >
                          Add Mapping
                        </Button>
                      </Form.Item>
                    </Col>
                    <StyledModal
                      title={'Add Mapping'}
                      open={this.state.isModalVisible}
                      okText={'Save'}
                      onOk={(e) => this.onFormItemSubmit(e)}
                      onCancel={this.onModalClose}
                    >
                      <Form {...formItemLayout} ref={this.formRef} name="user_form">
                        {UniqueKeysList &&
                          UniqueKeysList.dataKeys &&
                          UniqueKeysList.dataKeys.map((data, index) => (
                            <div key={index}>
                              <Form.Item
                                label={data}
                                name={data}
                                rules={[
                                  {
                                    required: true
                                  }
                                ]}
                              >
                                <Select style={{ width: '350px' }}>
                                  {UniqueKeysList &&
                                    UniqueKeysList.rubusAliasKeys &&
                                    UniqueKeysList.rubusAliasKeys.map((uniquekey, index) => {
                                      return (
                                        <Option key={index} value={uniquekey}>
                                          {uniquekey}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                          ))}
                      </Form>
                    </StyledModal>
                  </Row>
                  <div>
                    <Form.List name="child">
                      {(child, { add, remove }) => (
                        <>
                          <Form.Item>
                            <Button
                              size="middle"
                              style={{ background: '#29415a', color: '#fff' }}
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                            >
                              Add Fields{' '}
                            </Button>
                          </Form.Item>
                          <div>
                            {child.map(({ key, name }, index) => (
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} key={key}>
                                <Col className="gutter-row" span={6}>
                                  <Form.Item
                                    label="DataObject"
                                    name={[name, 'DataObject']}
                                    rules={[{ required: true, message: 'DataObject Missing' }]}
                                  >
                                    <Input
                                      placeholder="DataObject"
                                      onBlur={(e) => this.handleDataObjectChild(e)}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                  <Form.Item
                                    label="XmlFormat"
                                    name={[name, 'XmlFormat']}
                                    rules={[{ required: true, message: 'XMLData Missing' }]}
                                    style={{ width: '43vw' }}
                                  >
                                    <TextArea
                                      placeholder="Input XmlFormat"
                                      maxLength={10000}
                                      style={{
                                        height: 100
                                      }}
                                      onChange={(e) => this.ChildXMLData(e, name)}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                  <Form.Item
                                    label="UniqueKeys:"
                                    name={[name, 'UniqueKeys']}
                                    rules={[
                                      { required: true, message: 'Please select UniqueKeys' }
                                    ]}
                                  >
                                    <Select
                                      style={{ width: '350px' }}
                                      mode="multiple"
                                      placeholder="Please select"
                                    >
                                      {AllUniqueKeyList &&
                                        AllUniqueKeyList[key] &&
                                        AllUniqueKeyList[key].dataKeys &&
                                        AllUniqueKeyList[key].dataKeys.map((uniquekey, index) => {
                                          return (
                                            <Option key={index} value={uniquekey}>
                                              {uniquekey}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                  <Form.Item
                                    label="Type:"
                                    name={[name, 'Type']}
                                    rules={[{ required: true, message: 'Please select Type' }]}
                                  >
                                    <Select
                                      style={{ width: '350px' }}
                                      // mode="multiple"
                                      placeholder="Please select"
                                    >
                                      {TypeList &&
                                        TypeList.map((Type, index) => {
                                          return (
                                            <Option key={index} value={Type.Key}>
                                              {Type.Key}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                  <Form.Item style={{ paddingTop: '22px' }}>
                                    <Button
                                      type="primary"
                                      key={key}
                                      disabled={AddChildDisable}
                                      onClick={() => {
                                        this.onChildAdd(index);
                                      }}
                                    >
                                      Add Mapping
                                    </Button>
                                  </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                  <Form.Item style={{ paddingTop: '24px' }}>
                                    <Popconfirm
                                      title="Are you sure that you want to delete the Child"
                                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                      onConfirm={() => remove(index)}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Button danger key={key}>
                                        <Tooltip title="Delete" placement="bottom">
                                          Remove Fields
                                        </Tooltip>
                                      </Button>
                                    </Popconfirm>
                                  </Form.Item>
                                </Col>
                                <StyledModal
                                  key={selectedChildIndex}
                                  title={'Add Mapping'}
                                  open={this.state.isChildModalVisible}
                                  okText={'Save'}
                                  onOk={(e) => this.onChildModalSubmit(e, key)}
                                  onCancel={this.onChildModalClose}
                                >
                                  <Form
                                    {...formItemLayout}
                                    name="Child-Form"
                                    initialValues={ChildMappingKeysArray[selectedChildIndex]}
                                  >
                                    {AllChildList &&
                                      AllChildList[selectedChildIndex] &&
                                      Object.keys(AllChildList[selectedChildIndex]) &&
                                      Object.keys(AllChildList[selectedChildIndex]).map(
                                        (data, index) => {
                                          return (
                                            <div key={index}>
                                              <Form.Item label={data} name={data}>
                                                <Select
                                                  style={{ width: '350px' }}
                                                  onChange={(value) =>
                                                    this.onKeySelect(data, value, index, key)
                                                  }
                                                >
                                                  {AllUniqueKeyList &&
                                                    AllUniqueKeyList[selectedChildIndex] &&
                                                    AllUniqueKeyList[selectedChildIndex]
                                                      .rubusAliasKeys &&
                                                    AllUniqueKeyList[
                                                      selectedChildIndex
                                                    ].rubusAliasKeys.map((uniquekey) => {
                                                      return (
                                                        <Option key={key} value={uniquekey}>
                                                          {uniquekey}
                                                        </Option>
                                                      );
                                                    })}
                                                </Select>
                                              </Form.Item>
                                            </div>
                                          );
                                        }
                                      )}
                                  </Form>
                                </StyledModal>
                              </Row>
                            ))}
                          </div>
                        </>
                      )}
                    </Form.List>
                  </div>

                  <Form.Item>
                    <Button type="primary" className="SubmitButton" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
                <div style={{ marginTop: '70px' }}>
                  {xmlView ? (
                    <XMLViewer
                      xml={XmlViewData}
                      theme={customTheme}
                      style={{ minWidth: '50vw', backgroundColor: '#000', padding: '15px' }}
                    />
                  ) : null}
                </div>
              </TabPane>

              {/* <TabPane tab="Data entry" key="2">
                <Form
                  ref={this.userRef}
                  name="user_form"
                  layout="horizontal"
                  hideRequiredMark
                  {...formItemLayout}
                >
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label="SelectedFile :"
                        name={'SelectedFile'}
                        rules={[{ required: true, message: 'Please enter SelectedFile' }]}
                      >
                        <Select style={{ width: '50%' }} placeholder="Please select">
                          <Option value="file1">file1</Option>
                          <Option value="file2">file2</Option>
                          <Option value="file3">file3</Option>
                          <Option value="file4">file4</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Upload File :"
                        name="xmlFormat"
                        rules={[{ required: true, message: 'please Upload file' }]}
                      >
                        <Upload
                          name="file"
                          accept="text/xml"
                          multiple="false"
                          beforeUpload={this.SelectedFileUpload}
                          onChange={this.handleSelectChange}
                        >
                          <Button style={{ width: '300px' }}>
                            <UploadOutlined /> Click to Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Button
                    type="primary"
                    style={{ marginLeft: '45%', marginTop: '30px', width: '100px' }}
                    onClick={this.saveDataEntryUpload}
                  >
                    Save
                  </Button>
                </Form>
              </TabPane> */}
            </Tabs>
          </>
        </div>
      </StyledDashboard>
    );
  }
}

export default XMLConfiguration;
