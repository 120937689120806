import styled from 'styled-components';
import { Table } from 'antd';
export const StyledTable = styled(Table)`
caption-side: top;
color: white !important;
width: 100%;
caption-side: bottom;
td,
th {
  border: none;
}
td {
  padding: 11px 10px;
}
.ant-table-thead > tr > th{
  font-weight:bold !important
  font-size: 1em !important;
  color:white !important;
  background: rgb(26, 59, 92) ;
}
text[style] {
  font-size: 1em !important;
  color: white !important;
}
tbody tr {
  :nth-of-type(odd) {
    background-color:#efefef !important;
  }
  :hover {
    background-color:rgb(26, 59, 92) !important; 
  }
}
tbody tr {
  :nth-of-type(even) {
    background-color: #d9d9d9 !important;
  }
  :hover {
    background-color: #f5f5f5 !important; 
  }
}
thead > tr {
  color: white !important;
  background-color: #262626 !important;
}
caption {
  font-size: 0.9em;
  padding: 5px;
  font-weight: bold;
}
`;

export const StyledComponent = styled.div`
  background: ${(props) => props.theme.backgroundColor} !important;
  padding: 10px;
`;
