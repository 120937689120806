import React from 'react';
import { Route } from 'react-router';

import Measurement from '../pages/Modules/Custom/MoistureSensor/form/Measurement';
import Calibration from '../pages/Modules/Custom/MoistureSensor/form/Calibration';
import Correction from '../pages/Modules/Custom/MoistureSensor/form/Correction';
import ChannelParameter from '../pages/Modules/Custom/MoistureSensor/form/ChannelParameter';
import SystemParameter from '../pages/Modules/Custom/MoistureSensor/form/SystemParameter';
import Memory from '../pages/Modules/Custom/MoistureSensor/form/Memory';
import ZeroAdjust from '../pages/Modules/Custom/MoistureSensor/form/ZeroAdjust';
import Settings from '../pages/Modules/Custom/MoistureSensor/form/Settings';

export default class configurationRouter extends React.Component {
  render() {
    return (
      <div>
        <Route path="/rubus/form/Moisture Sensor1/Measurement" component={Measurement} />
        <Route path="/rubus/form/Moisture Sensor1/Calibration" component={Calibration} />
        <Route path="/rubus/form/Moisture Sensor1/Correction" component={Correction} />
        <Route path="/rubus/form/Moisture Sensor1/ChannelParameter" component={ChannelParameter} />
        <Route path="/rubus/form/Moisture Sensor1/SystemParameter" component={SystemParameter} />
        <Route path="/rubus/form/Moisture Sensor1/Memory" component={Memory} />
        <Route path="/rubus/form/Moisture Sensor1/ZeroAdjust" component={ZeroAdjust} />
        <Route path="/rubus/form/Moisture Sensor1/Settings" component={Settings} />
      </div>
    );
  }
}
