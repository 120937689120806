import React, { Component } from 'react';
import { message, Input, Form, Switch, Button, DatePicker } from 'antd';
import themeSettings from '../../../Analytics_Predictions/Utils/themeSettings.json';
import ListView from './ListView';
import { v4 as uuidv4 } from 'uuid';
import { insert, getById, deleteItemByparentId } from './Utils/FormCalls';
import {
  StyledComponent,
  StyledForm,
  StyledFormType
} from '../../../Analytics_Predictions/CSS/style';
import _ from 'lodash';
import { getTranslationCache } from '../../../../selectors/language';
import history from '../../../../commons/history';
import InputComponent from '../../../AMM/Widgets/InputComponent';
import { RollbackOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment-timezone';
import translation from './Utils/translation';
import { constant } from './Utils/Constants';
import { RBSLOOKUPMaster, RBSLOOKUPITEMS } from '../../../../commons/api';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
const APIList = {
  getLookupItemMasterList: `${RBSLOOKUPITEMS.GET}`,
  getLookupMasterById: `${RBSLOOKUPMaster.GET}`,
  deleteLookupItem: `${RBSLOOKUPITEMS.Delete}`,
  insertLookupItem: `${RBSLOOKUPMaster.CREATE}`
};

class LookupMasters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: translation(props.translationCache, constant.lookupItem),
          key: 'LookupItem',
          dataIndex: 'LookupItem',
          editable: true,
          disable: true,
          ...this.getColumnSearchProps('LookupItem'),
          sorter: (a, b) => a.LookupItem.localeCompare(b.LookupItem)
        },
        {
          title: translation(props.translationCache, constant.lookupText),
          key: 'LookupText',
          dataIndex: 'LookupText',
          editable: true
        },
        {
          title: translation(props.translationCache, constant.enabled),
          key: 'Enabled',
          dataIndex: 'Enabled',
          editable: true,
          ...this.getColumnSearchProps('Enabled'),
          sorter: (a, b) => String(a.Enabled).localeCompare(String(b.Enabled)),
          render: (text) => String(text)
        },
        {
          title: translation(props.translationCache, constant.createdBy),
          key: 'CreatedName',
          dataIndex: 'CreatedName',
          ...this.getColumnSearchProps('CreatedName'),
          sorter: (a, b) =>
            a.CreatedName && b.CreatedName && a.CreatedName.localeCompare(b.CreatedName)
        },
        {
          title: translation(props.translationCache, constant.createdDate),
          key: 'CreatedTime',
          dataIndex: 'CreatedTime',
          ...this.getColumnSearchProps('CreatedTime'),
          sorter: (a, b) => new Date(a.CreatedTime) - new Date(b.CreatedTime),
          render: (text) => {
            return {
              children:
                text !== undefined ? (
                  <div> {moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
                ) : null
            };
          }
        }
      ],
      formObject: {},
      dataSource: [],
      disableSubmit: false,
      newRecord: false,
      translationCache: props.translationCache || []
    };
    this.lookupItemRef = React.createRef();
  }
  componentDidMount = () => {
    this.getLookupMasterDetails();
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };
  getLookupMasterDetails = async () => {
    let { formObject } = this.state;
    let path = history.location.pathname.split('/rubus/LookupMasterItemDetails/');
    if (path[1] !== 'new') {
      let response = await getById(APIList.getLookupMasterById, path[1], 'LookupId');
      _.cloneDeepWith(response, (value) => {
        return value !== undefined && value !== null && value.CreatedTime
          ? {
              ...value,
              ...(value['CreatedTime']
                ? (value['CreatedTime'] = moment
                    .utc(value.CreatedTime, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null)
              // ...value["Items"].map((item, i) => {
              //   return value["Items"][i]["CreatedTime"]
              //     ? (value["Items"][i]["CreatedTime"] = moment.utc(
              //         value["Items"][i]["CreatedTime"],
              //         "YYYY-MM-DD HH:mm:ss"
              //       ))
              //     : null;
              // }),
            }
          : _.noop();
      });
      if (response && response.status === 200) {
        if (this.lookupItemRef.current) {
          this.lookupItemRef.current.setFieldsValue(response.data ? response.data : {});
        }
        this.setState({
          formObject: response && response.data ? response.data : {},
          dataSource:
            response && response.data && response.data.Items && Array.isArray(response.data.Items)
              ? response.data.Items
              : []
        });
      }
    } else {
      formObject = {
        ...formObject,
        Enabled: 'true'
      };
      this.lookupItemRef.current.setFieldsValue(formObject);
      this.setState({ formObject });
    }
  };
  _deleteById = async (LookupItemId, LookupId) => {
    let response = await deleteItemByparentId(
      APIList.deleteLookupItem,
      LookupItemId,
      'LookupItemId',
      LookupId,
      'LookupId'
    );
    if (response && response.status === 200) {
      response &&
        response.message &&
        message.success(translation(this.state.translationCache, response.message));
      this.getLookupMasterDetails();
    }
  };
  handleSave = (dataSource) => {
    this.setState({ dataSource, disableSubmit: false, newRecord: false });
  };
  // handleSave = async (values) => {
  //     let path = history.location.pathname.split('/rubus/LookupMasterItemDetails/');
  //     let response = await insert(APIList.insertLookupItem, {
  //         ...values,
  //         Lookup_Id: path && Array.isArray(path) && path[1] ?
  //             path[1] : ""
  //     });
  //     response && response.message && message.success(response.message);
  //     this.getLookupMasterItemsList();
  // }
  onFormSubmit = () => {
    const { dataSource } = this.state;
    this.lookupItemRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...this.lookupItemRef.current.getFieldsValue(),
          LookupName: values['LookupName'].toUpperCase(),
          // Enabled: this.lookupItemRef.current.getFieldsValue().Enabled
          //   ? this.lookupItemRef.current.getFieldsValue().Enabled
          //   : false,
          Items: dataSource
        };
        let response = await insert(APIList.insertLookupItem, values);
        if (response && response.status === 200) {
          if (response && response.data && response.data.message) {
            message.success(translation(this.state.translationCache, response.data.message));
            history.goBack();
          }
        }
      })
      .catch(() => {});
  };
  handleAdd = () => {
    const { dataSource } = this.state;
    let newData = {
      key: uuidv4(),
      LookupItem: '',
      LookupText: '',
      Enabled: true
    };

    this.setState({ dataSource: [...dataSource, newData], newRecord: true });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: '' });
  };
  onFinishFailed = () => {
    message.error(translation(this.props.translationCache, 'Please Provide Required Fields'));
    // alert("hello");
  };
  onChange = (value, key) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      [key]: value
    };
    this.lookupItemRef.current.setFieldsValue(formObject);
    this.setState({ formObject });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={
            translation(this.props.translationCache, constant.search) +
            translation(this.props.translationCache, dataIndex)
          }
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          {translation(this.props.translationCache, constant.search)}
        </Button>
        <Button
          onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, constant.reset)}
        </Button>
        {/* <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
        >
          Filter
        </Button> */}
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  disableSubmit = (disableSubmit) => {
    this.setState({ disableSubmit, newRecord: false });
  };
  // onHandleChange = (e, key) => {
  //   let { formObject } = this.state;
  //   let data = {
  //     ...formObject,
  //     [key]: e.target.value.toUpperCase()
  //   };
  //   this.lookupItemRef.current.setFieldsValue({formObject});
  //   this.setState({ formObject:data });
  // };
  render() {
    const { dataSource, columns, formObject, disableSubmit, newRecord, translationCache } =
      this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required:
        translation(translationCache, '${label}') + translation(translationCache, 'is required!'),
      types: {
        email:
          translation(translationCache, '${label}') +
          translation(translationCache, 'is not a valid email!'),
        number:
          translation(translationCache, '${label}') +
          translation(translationCache, 'is not a valid number!')
      },
      number: {
        range:
          translation(translationCache, '${label}') +
          translation(translationCache, 'must be between') +
          translation(translationCache, '${min}') +
          translation(translationCache, 'and') +
          translation(translationCache, '${max}')
      },
      pattern: {
        mismatch:
          translation(translationCache, '${label}') +
          translation(translationCache, 'does not match pattern') +
          translation(translationCache, '${pattern}')
      }
    };
    return (
      <>
        <div
          style={{
            // marginTop: "30px",
            // marginBottom: "30px",
            color: 'black',
            fontWeight: 'bold'
          }}
        >
          <>
            <div style={{ padding: '10px 19px' }}>
              <div
                style={{
                  color: '#000',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
                  <RollbackOutlined />
                  {translation(translationCache, constant.goBack)}
                </span>
              </div>
            </div>
            {/* {  successMessage && successMessage !== undefined  && successMessage !== "" ?
               <span> <Alert banner={true} message={successMessage} type="success" showIcon closable  action={
                  <Space>
                    <Button size="small" type="ghost">
                      Done
                    </Button>
                  </Space>
                }/></span>
                 :null}  */}
            <StyledComponent
              theme={themeSettings}
              style={{
                padding: '50px 30px',
                minHeight: window.innerHeight - 143
              }}
            >
              <StyledForm
                name="LookupDetails"
                ref={this.lookupItemRef}
                autoComplete="off"
                initialValues={formObject}
                theme={themeSettings}
                layout="horizontal"
                labelCol={{ flex: '200px' }}
                labelAlign="right"
                onFinish={this.onFormSubmit}
                onFinishFailed={this.onFinishFailed}
                labelWrap
                validateMessages={validateMessages}
                wrapperCol={{ flex: 1 }}
              >
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <Form.Item>
                    <Button disabled={disableSubmit} htmlType="submit" type="primary">
                      {translation(translationCache, constant.submit)}
                    </Button>
                  </Form.Item>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Form.Item
                    label={translation(translationCache, constant.lookupCode)}
                    name="LookupName"
                    style={{ width: '100%', flex: '50%' }}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <InputComponent
                      style={{
                        width: '70%',
                        'text-transform': 'uppercase'
                      }}
                      disabled={formObject['LookupId'] ? true : false}
                    />
                  </Form.Item>
                  <Form.Item
                    label={translation(translationCache, constant.description)}
                    name="Description"
                    style={{ width: '100%', flex: '50%' }}
                    rules={[
                      {
                        required: true
                      }
                    ]}
                  >
                    <InputComponent style={{ width: '70%' }} />
                  </Form.Item>
                  <Form.Item
                    label={translation(translationCache, constant.createdBy)}
                    name="CreatedName"
                    style={{ width: '100%', flex: '50%' }}
                  >
                    <InputComponent style={{ width: '70%' }} disabled />
                  </Form.Item>
                  <Form.Item
                    label={translation(translationCache, constant.createdDate)}
                    name="CreatedTime"
                    style={{ width: '100%', flex: '50%' }}
                  >
                    <DatePicker style={{ width: '70%' }} disabled format={'YYYY-MM-DD HH:mm:ss'} />
                  </Form.Item>
                  <Form.Item
                    name="Enabled"
                    label={translation(translationCache, constant.enabled)}
                    valuePropName="checked"
                    style={{ width: '100%', flex: '50%' }}
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item style={{ width: '100%', flex: '50%' }} name="LookupId">
                    <InputComponent style={{ display: 'none' }} />
                  </Form.Item>
                </div>
              </StyledForm>
              <StyledFormType theme={themeSettings}>
                {' '}
                {translation(translationCache, constant.lookupItem)}
              </StyledFormType>
              <Form.Item name="Lookup_Items">
                <ListView
                  columns={columns}
                  dataSource={dataSource}
                  addRow={this.handleAdd}
                  saveRow={this.handleSave}
                  deleteRow={this._deleteById}
                  uniqueKey={'LookupItemId'}
                  disableSubmit={this.disableSubmit}
                  edit={newRecord}
                />
              </Form.Item>
            </StyledComponent>
          </>
        </div>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(LookupMasters);
