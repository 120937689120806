import React from 'react';
import { connect } from 'react-redux';
import { Form, Popover, Select, Tree, Button } from 'antd';
import { flattenDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';

import { StyledApplication, StyledTree } from './styles';
import { DownOutlined } from '@ant-design/icons';
import localStorage from '../../../../utils/localStorage';
import { getTranslationCache } from '../../../../selectors/language';
import axios from 'axios';
import { API_HIERARCHYMODULE } from '../../../../commons/api';

const { Option } = Select;

class ParameterHierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      hierarchyList: []
    };
  }

  componentDidMount = () => {
    this.getHierarchyList();
  };

  expandAll = async (treeData) => {
    let expandedKeys = await this.getAllKeys(treeData);
    this.setState({
      expandedKeys
    });
  };

  getAllKeys = (data) => {
    const nestedKeys = data.map((node) => {
      let childKeys = [];
      if (node.children) {
        childKeys = this.getAllKeys(node.children);
      }
      return [childKeys, node.Id];
    });
    return flattenDeep(nestedKeys);
  };

  getHierarchyList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${API_HIERARCHYMODULE.MainHierarchyList}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((response) => {
        this.setState({ hierarchyList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getTreeData = (data) => {
    const siteId = localStorage.get('currentSite');
    let payload = {
      ...data,
      SiteId: siteId
    };
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'POST',
      url: `${API_HIERARCHYMODULE.GetHierarchyLevelsForFaults}?SiteId=${siteId}`,
      data: payload,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.expandAll(data);
          this.setState({
            treeData: data
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  renderTreeNodes = (data) => {
    return (
      data &&
      Array.isArray(data) &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <Tree.TreeNode
              title={`${this.translation(item.Code)}${
                item.Name ? ` - (${this.translation(item.Name)})` : ''
              }`}
              key={item.Id}
              dataRef={item}
            >
              {this.renderTreeNodes(item.children, item)}
            </Tree.TreeNode>
          );
        }
        return (
          <Tree.TreeNode
            key={item.key}
            title={
              <Popover
                content={
                  <div>
                    <div>
                      {'Critical'} : {item.Critical}
                    </div>
                    <div>
                      {'Major'} : {item.Major}{' '}
                    </div>
                    <div>
                      {'Minor'} : {item.Minor}{' '}
                    </div>
                  </div>
                }
                title="Faults Count"
              >
                <div style={{ color: item.ColorCode ? item.ColorCode : 'black' }}>
                  {`
                    ${item.Code ? ` ${this.translation(item.Code)}` : ''}
                    ${item.Name ? ` - ${this.translation(item.Name)}` : ''}
                    ${item.Count ? ` - ${this.translation(item.Count)}` : ''}
                 `}
                </div>
              </Popover>
            }
            dataRef={item}
          />
        );
      })
    );
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys
    });
  };

  onFinish = (values) => {
    let { hierarchyList } = this.state;
    let filterList =
      hierarchyList && hierarchyList.filter((hierarchy) => hierarchy.Id === values.HierarchyId);
    let payload = {
      HierarchyId: values.HierarchyId,
      HierarchyName:
        filterList && filterList[0] && filterList[0].HierarchyName
          ? filterList[0].HierarchyName
          : '',
      ParameterType: values.ParameterType
    };
    this.getTreeData(payload);
  };
  render() {
    let { treeData, expandedKeys, hierarchyList } = this.state;
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 }
    };
    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 226 }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div
            style={{
              flex: '50%',
              paddingRight: '25px',
              borderRight: '1px dashed black'
            }}
          >
            <Form {...layout} name="control-hooks" onFinish={this.onFinish}>
              <Form.Item
                name="HierarchyId"
                label={this.translation('Hierarchy')}
                rules={[{ required: true }]}
              >
                <Select placeholder={this.translation('Select Hierarchy')} allowClear>
                  {hierarchyList &&
                    Array.isArray(hierarchyList) &&
                    hierarchyList.map((hierarchy) => {
                      return (
                        <Option key={hierarchy.Id} value={hierarchy.Id}>
                          {hierarchy.HierarchyName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name="ParameterType"
                label={this.translation('Parameter Type')}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={this.translation('Select Parameter Type')}
                  // onChange={this.onTypeChange}
                  allowClear
                >
                  <Option value={'Status'}>{'Status'}</Option>
                </Select>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  {this.translation('Show')}
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div
            style={{
              borderRight: '1px dashed white',
              width: '100%',
              flex: '50%',
              overflowY: 'auto',
              paddingLeft: '10px',
              height: window.innerHeight - 215
            }}
          >
            <StyledTree
              // onSelect={onSelect}
              onExpand={this.onExpand}
              expandedKeys={expandedKeys}
              showLine
              defaultExpandAll={true}
              switcherIcon={<DownOutlined />}
            >
              {this.renderTreeNodes(treeData)}
            </StyledTree>
          </div>
        </div>
      </StyledApplication>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(ParameterHierarchy);
