import React, { Component } from 'react';
import { Tree, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import localStorage from '../../../utils/localStorage';

const { TreeNode } = Tree;

class HierarchyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      value: [],
      autoExpandParent: true.valueOf,
      expandedKeys: [],
      datasource: [],
      treeData: this.props && this.props.treeData ? this.props.treeData : []
    };
  }

  renameProperty = (obj, oldName1, newName1, oldName2, newName2) => {
    if (obj && obj.children) {
      let children = obj.children.map((objChildren) => {
        return this.renameProperty(objChildren, oldName1, newName1, oldName2, newName2);
      });
      obj['children'] = children;
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    } else {
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    }
  };

  getMenuHierarchyStructure = () => {
    JSON.parse(localStorage.get('modules'));
    let { treeData } = this.state;
    if (treeData) {
      let final = treeData.map((userHierarchy) => {
        return this.renameProperty(userHierarchy, 'label', 'title', 'value', 'key');
      });
      return final;
    }
  };

  getData = (item) => {
    let { treeData } = this.state;
    let { Key } = this.props;
    if (Key === 'Asset') {
      if (item && item.Type === 'Site') {
        message.error('Cant select site');
      } else if (item.Type !== 'Device') {
        this.props.handleDropdownChange(item);
      } else {
        message.error('Please select valid input');
      }
    } else {
      this.props.handleDropdownChange(item);
      this.getParent(item.Name, item.ParentId, treeData);
    }
  };
  getParent = (Name, ParentId, treeData) => {
    let firstLevelParent = {};
    let children = [];
    let secondLevelParent = [];
    for (let i = 0; i < treeData.length; i++) {
      const node = treeData[i];
      if (node.children) {
        if (node.children.some((item) => item.Id === ParentId)) {
          firstLevelParent = node;
          children = node.children;
          if (children) {
            for (let i = 0; i < children.length; i++) {
              const childNode = children[i];
              if (childNode.children && childNode.children.length > 0) {
                if (childNode.Id === ParentId) {
                  secondLevelParent = childNode;
                }
              }
            }
          }
          if (this.props.handleParentNode && this.props.handleParentNode !== undefined) {
            this.props.handleParentNode(firstLevelParent, secondLevelParent);
          }
        } else if (this.getParent(Name, ParentId, node.children)) {
          this.getParent(Name, ParentId, node.children);
        }
      }
    }
    return firstLevelParent;
  };

  renderTreeNodes = (data) => {
    return data.map((item) => {
      if (item.children && item.children.length > 0) {
        return (
          <TreeNode
            // title={`${this.setTranslation(item.Code)} ${this.setTranslation(item.Name)}`}
            title={
              <div onClick={() => this.getData(item, item.key)}>
                {`${this.setTranslation(item.DisplayCode)} ${
                  item.Name ? `(-${this.setTranslation(item.Name)})` : ''
                }`}
              </div>
            }
            key={item.key}
            dataRef={item}
            onChange={this.onTreeChange}
          >
            {this.renderTreeNodes(item.children, item)}
          </TreeNode>
        );
      }

      return (
        <TreeNode
          key={item.key}
          title={
            <div onClick={() => this.getData(item, item.key)}>
              {`${this.setTranslation(item.DisplayCode)} ${
                item.Name ? `(-${this.setTranslation(item.Name)})` : ''
              }`}
            </div>
          }
          dataRef={item}
        />
      );
    });
  };

  setTranslation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] && translationCache[keyword]
      ? translationCache[keyword]
      : keyword;
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };
  onTreeChange = () => {};
  render() {
    let { treeData } = this.state;
    return (
      <div
        style={{
          //  height: '70%',
          //  marginTop: '10px'
          borderRight: '1px dashed white',
          width: '100%',
          flex: '35%',
          overflowY: 'auto',
          height: window.innerHeight - 250
        }}
      >
        <Tree
          className="treeTheme"
          style={{ color: 'white', backgroundColor: '#1a3652' }}
          showLine
          defaultExpandAll={true}
          switcherIcon={<DownOutlined />}
        >
          {this.renderTreeNodes(treeData)}
        </Tree>
      </div>
    );
  }
}

export default HierarchyModal;
