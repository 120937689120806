import React, { Component } from 'react';
import moment from 'moment';
import { Table, Input, Upload, Button, DatePicker, message, InputNumber, TimePicker } from 'antd';

import { SelectComponent, KeyComponent } from '.';
import localStorage from '../../../../utils/localStorage';
import { deleteFileUpload } from '../eLogCalls';

import styles from '../CSS/style.less';
import { lightTheme } from '../CSS/style.js';
import '../CSS/eLog.css';
import { UploadOutlined } from '@ant-design/icons';
import { MASTER } from '../../../../commons/api.js';

class TableViewInTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: props.form || {},
      columns: props.form && props.form.columns ? props.form.columns : [],
      modal: props.form && props.form.modal ? props.form.modal : [],
      data:
        props.form && props.form.datasource
          ? this.props.form.datasource.map((dataSource, index) => {
              return {
                ...dataSource,
                key: index + 1
              };
            })
          : [],
      open: false,
      mainHeaderValue: props.mainHeaderValue ? props.mainHeaderValue : {},
      subHeaderValue: props.subHeaderValue ? props.subHeaderValue : {}
    };
  }

  componentDidMount() {
    let columns = this.generateColumns(
      this.props.form && this.props.form.columns ? this.props.form.columns : [],
      this.props.mainHeaderValue ? this.props.mainHeaderValue : {},
      this.props.subHeaderValue ? this.props.subHeaderValue : {}
    );
    this.setState({
      form: this.props.form,
      columns,
      modal: this.props.form && this.props.form.modal ? this.props.form.modal : [],
      data:
        this.props.form && this.props.form.datasource
          ? this.props.form.datasource.map((dataSource, index) => {
              return {
                ...dataSource,
                key: index + 1
              };
            })
          : []
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.form !== this.props.form ||
      prevProps.mainHeaderValue !== this.props.mainHeaderValue ||
      prevProps.subHeaderValue !== this.props.subHeaderValue
    ) {
      let columns = this.generateColumns(
        this.props.form.columns,
        this.props.mainHeaderValue,
        this.props.subHeaderValue
      );
      this.setState({
        form: this.props.form,
        columns,
        data:
          this.props.form && this.props.form.datasource
            ? this.props.form.datasource.map((dataSource, index) => {
                return {
                  ...dataSource,
                  key: index + 1
                };
              })
            : []
      });
    }
  }

  generateColumns = (columns, mainHeaderValue, subHeaderValue) => {
    let { translationCache } = this.props;
    let finalColumns = [];

    if (columns && columns.length > 0) {
      finalColumns = columns.map((column) => {
        return {
          title:
            translationCache && translationCache[column.title]
              ? translationCache[column.title]
              : column.title,
          dataIndex: column.key || 0,
          key: column.key || 0,
          width: column.width,
          widget: column.widget,
          required: column.required,
          table: column.table || {},
          render: (text, record) => {
            return this.renderWidget(
              column,
              text,
              record,
              columns,
              mainHeaderValue,
              subHeaderValue
            );
          }
        };
      });
    }
    return finalColumns;
  };

  deleteFile = async (file) => {
    if (file && file.response && file.response.name) {
      await deleteFileUpload(file.response.name);
      message.success('File Deleted');
    } else {
      message.error('Error deleting file');
    }
  };

  onFileChange = (info, column, text, record) => {
    if (info.file.status === 'done') {
      let fieldValue = [];
      if (text && Array.isArray(text) && text !== undefined) {
        fieldValue = [...text, info.file.response];
      } else if (!text) {
        fieldValue = [info.file.response];
      }
      this.handleFieldChange(fieldValue, column.key, record.key, column);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status === 'removed') {
      this.deleteFile(info.file);
      this.handleFieldChange(info.fileList, column.key, record.key, column);
    }
  };

  renderWidget = (column, text, record, columns, mainHeaderValue, subHeaderValue) => {
    const token = localStorage.get('accessToken');
    let { translationCache } = this.props;
    let defaultFileList = text && Array.isArray(text) ? text : [];
    var Authorization = 'Bearer ' + token;
    const renderProps = {
      name: 'file',
      action: `${MASTER.FileUpload}`,
      headers: {
        Authorization: Authorization
      },
      defaultFileList
    };
    switch (column.widget) {
      case 'input':
        return (
          <Input
            autoFocus
            value={
              column
                ? translationCache && translationCache[text] && translationCache[text]
                  ? translationCache[text]
                  : text
                : text
            }
            style={{ fontWeight: 'bold' }}
            onChange={(e) => this.handleFieldChange(e.target.value, column.key, record.key, column)}
            maxLength={columns && columns.maxLength ? columns.maxLength : 100}
            onKeyPress={(e) => this.handleKeyPress(e.target.value, record.key)}
            placeholder={column.placeHolder || column.displayName}
            disabled={column.disable ? column.disable : false}
          />
        );
      case 'inputNumber':
        return column.widgetCondition &&
          column.widgetCondition === true &&
          record.widget !== undefined &&
          record.widget !== true ? (
          <InputNumber value="" disabled style={{ width: '90%', fontWeight: 'bold' }} />
        ) : (
          <InputNumber
            value={text}
            style={{ width: '90%', fontWeight: 'bold' }}
            min={column.min !== undefined ? column.min : 0}
            max={column.max !== undefined ? column.max : 1000000}
            // onChange={(e) => this.handleFieldChange(e, column.key, record.key)}
            onChange={(e) =>
              this.inputNumberChange(column.min, column.max, e, column.key, record.key, column)
            }
            onKeyPress={(e) => this.handleKeyPress(e.target.value, record.key)}
            placeholder={column.placeHolder || column.displayName}
          />
        );
      case 'textComponent':
        return (
          <KeyComponent
            data={this.state.data}
            column={column}
            record={record}
            text={text}
            datasource={mainHeaderValue ? mainHeaderValue : {}}
            subdatasource={subHeaderValue ? subHeaderValue : {}}
            handleDataChange={this.handleDataChange}
            // handleKeyPress={this.handleKeyPress}
          />
        );
      case 'select':
        return (
          <SelectComponent
            data={this.state.data}
            column={column}
            record={record}
            text={text}
            datasource={mainHeaderValue ? mainHeaderValue : {}}
            subdatasource={subHeaderValue ? subHeaderValue : {}}
            handleFieldChange={this.handleFieldChange}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'upload':
        return (
          <Upload
            {...renderProps}
            onChange={(info) => this.onFileChange(info, column, text, record)}
          >
            <Button>
              <UploadOutlined /> Upload
            </Button>
          </Upload>
        );
      case 'timePicker':
        return (
          <TimePicker
            format="HH:mm:ss"
            value={text && text !== 'Invalid date' ? moment(text, 'HH:mm:ss') : undefined}
            style={{ width: 500 }}
            disabled={columns.disable ? columns.disable : false}
            onChange={(e) => this.handleFieldChange(e, column.key, record.key, column)}
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            value={text ? moment(text, 'YYYY-MM-DD') : undefined}
            format={columns.showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
            allowClear={false}
            showNow={false}
            showTime={columns.showTime ? columns.showTime : false}
            disabled={columns.disable ? columns.disable : false}
            onChange={(e) => this.handleFieldChange(e, column.key, record.key)}
            style={{ width: 300 }}
          />
        );
      default:
        return text;
    }
  };

  saveRow(e, key, record) {
    let { columns } = this.state;
    e.persist();
    this.setState({
      loading: true
    });
    if (this.clickedCancel) {
      this.clickedCancel = false;
      return;
    }
    const target = this.getRowByKey(key) || {};
    let checkReqField = false;
    columns &&
      Array.isArray(columns) &&
      columns.map((columnsObject) => {
        if (
          columnsObject['required'] &&
          (columnsObject['required'] === 'true' || columnsObject['required'] === true)
        ) {
          if (
            target[columnsObject.key] ||
            (target[columnsObject.key] === 0 &&
              (target[columnsObject.key] !== '' || (record && record.widget === false)))
          ) {
            return {};
          } else {
            e.target.focus();
            checkReqField = true;
          }
        }
        return {};
      });
    if (checkReqField) {
      message.warn('Please fill in the complete information');
    } else {
      this.saveToggleEditable(e, key);
      this.setState({
        loading: false
      });
    }
  }

  editRow(key) {
    this.toggleEditable(key);
  }

  cancel(e, key) {
    this.clickedCancel = true;
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (this.cacheOriginData !== undefined) {
      if (this.cacheOriginData[key]) {
        Object.assign(target, this.cacheOriginData[key]);
        delete this.cacheOriginData[key];
      }
    }
    target.editable = false;
    this.setState({ data: newData });
    this.clickedCancel = false;
  }

  getRowByKey(key, newData) {
    const { data } = this.state;
    return (newData || data).filter((item) => item.key === key)[0];
  }

  saveToggleEditable = (e, key) => {
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      if (!target.editable && this.cacheOriginData) {
        this.cacheOriginData[key] = { ...target };
      }
      target.editable = !target.editable;
      this.setState({ data: newData });
      this.props.saveTableViewData(newData);
      //  Save to Backend
    }
  };

  toggleEditable = (key) => {
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      target.editable = !target.editable;
      this.setState({ data: newData });
      this.props.saveTableViewData(newData);
    }
  };
  remove(key) {
    const { data } = this.state;
    if (key.isNew) {
      const newData = data.filter((item) => item.key !== key.key);
      this.setState({ data: newData });
      //Delete BAckend Call
    } else {
      message.error('Cant delete this record..');
    }
  }

  handleKeyPress(e, key) {
    if (e.key === 'Enter') {
      this.saveRow(e, key);
    }
  }
  inputNumberChange = (minValue, maxValue, value, key, record, column) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      maxValue !== undefined
    ) {
      this.handleFieldChange(value, key, record, column);
    } else if (minValue === undefined && maxValue === undefined) {
      this.handleFieldChange(value, key, record, column);
    } else {
      this.handleFieldChange('', key, record, column);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };

  handleFieldChange = async (fieldValue, fieldName, keyValue, column) => {
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(keyValue, newData);
    if (target) {
      if (fieldName === 'StartTime' || (column && column.widget === 'timePicker')) {
        target[fieldName] = moment(fieldValue).format('HH:mm:ss');
      } else if (fieldName === 'Date') {
        target[fieldName] = moment(fieldValue).format('YYYY-MM-DD');
      } else {
        target[fieldName] = fieldValue;
      }

      let newObj = [];
      if (column && column.autopopulate && column.autoPopulateCondition) {
        newObj = [];
        column.autoPopulateCondition.map((dataObject) => {
          if (newData[keyValue - 1][dataObject]) {
            newObj.push(newData[keyValue - 1][dataObject]);
          }
          return {};
        });
        target['Max'] = Math.max.apply(
          Math,
          newObj.map(function (o) {
            return o;
          })
        );
      }

      this.setState({ data: newData });
      this.props.saveTableViewData(newData, column, fieldValue);
    }
  };

  handleDataChange = (fieldValue, fieldName) => {
    const { data, columns } = this.state;
    const newData = data.map((item) => ({ ...item }));
    if (columns) {
      return {};
    }
    if (newData) {
      newData.map((item, key) => {
        newData[key][fieldName] = fieldValue;
        return {};
      });
      this.setState({ data: newData });
    }
    this.props.saveTableViewData(newData);
  };

  addRow = () => {
    let { data, modal } = this.state;
    let dataLength = data.length + 1;
    let newdata = {
      key: dataLength,
      editable: true
    };
    modal &&
      Array.isArray(modal) &&
      modal.map((columnData) => {
        newdata[columnData.key] = '';
        return {};
      });

    data.push(newdata);
    this.setState({
      data
    });
    this.props.saveTableViewData(data);
  };

  render() {
    let { loading, form, translationCache } = this.props;
    let { columns, data } = this.state;
    return (
      <div className="tableView" style={{ marginTop: '10px' }}>
        <div>
          <h2 style={{ color: 'steelblue', fontWeight: 'bold' }}>
            {translationCache && translationCache[form.heading] && translationCache[form.heading]
              ? translationCache[form.heading]
              : form.heading}
          </h2>
          <Table
            theme={lightTheme}
            className="TableView"
            loading={loading}
            columns={columns}
            // size={"small"}
            dataSource={data}
            pagination={false}
            style={{ marginTop: '10px', border: '1px solid  red !important' }}
            rowClassName={(record) => (record.editable ? styles.editable : '')}
          />
          {form && form.showAddButton ? (
            <Button type="primary" style={{ marginTop: '10px' }} onClick={this.addRow}>
              {translationCache && translationCache['Add Row'] && translationCache['Add Row']
                ? translationCache['Add Row']
                : 'Add Row'}
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
}

export default TableViewInTable;
