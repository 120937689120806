import React from 'react';
import axios from 'axios';
import { getTranslationCache } from '../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import themeSettings from '../../AMM/Utils/themeSettings.json';
import { StyledComponent, StyledTable } from './CSS/style';
import Highlighter from 'react-highlight-words';
// import { DrawerFooter } from '../../../commons/styles/layout';
import { getList } from '../APICalls/FormCalls';
import localStorage from '../../../utils/localStorage';
// import Index from './index';
// import { constant } from '../Utils/Constants';
import { ANALYTICS } from '../../../commons/api';
// import './CSS/style.css';
// import moment from 'moment';
import { getUserProfile } from '../../../selectors/layout';
import {
  Button,
  Input,
  // Select,
  // Tabs,
  Tooltip
} from 'antd';
// import translation from '../Utils/translation';
import history from '../../../commons/history';
import { EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
const APIList = {
  getList: `${ANALYTICS.UseCaseGet}`
  // Edit: `${ANALYTICS.UseCaseGet}`
};
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      dataSource: [],
      translationCache: props.translationCache || {},
      userProfile: props && props.userProfile ? props.userProfile : {}
    };
  }
  componentDidMount = async () => {
    let response = await getList(APIList.getList);
    this.setState({
      dataSource: response && Array.isArray(response) ? response : []
    });
  };
  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      this.props.userProfile !== prevProps.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  };

  editList = async (Id, record) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const object = {
      method: 'GET',
      url: `${APIList.getList}?SiteId=${siteId}&Id=${Id}&Type=Usecase`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: record
    };
    return axios(object)
      .then((response) => {
        return response && response.data;
      })
      .catch(function () {});
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  _editRecord = async (record) => {
    let editData = [];
    editData = await this.editList(record.Id, record);

    let obj = {
      Title: record.Title,
      Id: record.Id,
      RelatedTags: record.RelatedTags,
      CreatedBy: editData && editData.CreatedBy,
      CreatedTime: editData && editData.CreatedTime,
      Comments: editData && editData && editData.Comments,
      UseCaseName: record.UseCaseName,
      Description: record.Description,
      Attachments: editData && editData.Attachments
    };
    history.push({
      pathname: `/rubus/RubusAnalytics/usecase/${record.Id}`,
      state: {
        formObject: obj,
        Description: editData && editData.Description,
        AnalyticsId: editData && editData.Id,
        path: editData && editData.Path
      }
    });
    this.setState({ redirect: true, history, editData });
    this.props.renderForm(editData && editData.Path, 'edit', 'type');
  };
  addUsecase = () => {
    let { editData } = this.state;
    history.push({
      pathname: '/rubus/RubusAnalytics/usecase/New',
      state: {
        editData: editData
      }
    });
    let path = [
      {
        title: 'Home',
        key: 'home',
        type: 'home' // usercase List,crete,update
      }
    ];
    this.setState({ redirect: true, history });
    this.props.renderForm(path, 'New', 'usecase');
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        {/* <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
        >
          Filter
        </Button> */}
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: '' });
  };
  render() {
    const {
      dataSource
      // formObject
    } = this.state;

    let columns = [
      {
        title: this.translation('UseCase Name'),
        dataIndex: 'UseCaseName',
        key: 'UseCaseName',
        ...this.getColumnSearchProps('UseCaseName')
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Description',
        key: 'Description'
      },
      {
        title: this.translation('Action'),
        width: '600',
        render: (text, record) => (
          <>
            <Button
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => this._editRecord(record)}
              size="middle"
            >
              <Tooltip title={this.translation('Edit')}>
                <EditOutlined />
              </Tooltip>
            </Button>
          </>
        )
      }
    ];

    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{
            padding: '0px 30px',
            minHeight: window.innerHeight - 143
          }}
        >
          <>
            <div style={{ float: 'right' }}>
              <Tooltip title={this.translation('Add Use Case')}>
                <PlusOutlined
                  onClick={() => this.addUsecase()}
                  style={{
                    fontSize: '25px',
                    color: 'rgb(255, 108, 0)',
                    marginLeft: '10px',
                    paddingBottom: '20px',
                    paddingRight: '20px'
                  }}
                />
              </Tooltip>
            </div>
            <div style={{ marginTop: '10px' }}>
              <StyledTable
                theme={themeSettings}
                dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
                columns={columns}
              ></StyledTable>
            </div>
          </>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  userProfile: getUserProfile()
});
export default connect(mapStateToProps)(Home);
