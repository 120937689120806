import React from 'react';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { Table, message, Input, Button } from 'antd';
import { FormOutlined, SearchOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';

import constants from './../constants';
import { getUserProfile } from '../../../selectors/layout';
import { getTranslationCache } from '../../../selectors/language';
import { StyledDashboard, StyledTable, lightTheme } from './../style';
import { getRules } from './APICalls';
import { translation } from '../../../commons/translation';

class AIList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AIList: [],
      userProfile: props.userProfile || {}
    };
  }
  async componentDidMount() {
    try {
      let AIList = await getRules();
      this.setState({ AIList });
    } catch (err) {
      message.warn('OOPS Something went Wrong');
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.setState({
        userProfile: this.props.userProfile
      });
    }
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(constants.search)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(constants.search)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(constants.Reset)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    let { translationCache } = this.props;
    let { AIList } = this.state;
    const aiColumns = [
      {
        title: translation(constants.ProblemStatement, translationCache),
        dataIndex: 'ProblemStatement',
        key: 'ProblemStatement',
        ...this.getColumnSearchProps('ProblemStatement')
      },
      {
        title: translation('Description', translationCache),
        dataIndex: 'Description',
        key: 'Description',
        ...this.getColumnSearchProps('Description')
      },
      {
        title: translation(constants.CurrentStep, translationCache),
        dataIndex: 'CurrentStep',
        key: 'CurrentStep',
        ...this.getColumnSearchProps('CurrentStep')
      },
      {
        title: translation('Status', translationCache),
        dataIndex: 'RuleStatus',
        key: 'RuleStatus',
        ...this.getColumnSearchProps('RuleStatus')
      },
      {
        title: translation(constants.createdTime, translationCache),
        dataIndex: 'CreatedDate',
        key: 'CreatedDate',
        ...this.getColumnSearchProps('CreatedDate'),
        render: (text) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
      },
      {
        title: translation(constants.edit, translationCache),
        key: 'Edit',
        render: (text, record) => (
          <span>
            <FormOutlined
              style={{ color: '#1a3652' }}
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(`/rubus/AIRulesDetails?Id=${record.Id}`);
              }}
            />
          </span>
        )
      }
    ];
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 69 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            color: '#fff !important',
            marginTop: '10px'
          }}
        >
          <Button
            type="primary"
            onClick={(e) => {
              e.stopPropagation();
              this.props.history.push(`/rubus/AIRulesDetails`);
            }}
          >
            {translation('Create AI Rule', translationCache)}
          </Button>
          <Button
            type="primary"
            style={{ marginRight: '5px' }}
            onClick={(e) => {
              e.stopPropagation();
              this.props.history.push(`/rubus/AI/fileupload`);
            }}
          >
            {translation('Redirect to Upload page', translationCache)}
          </Button>
        </div>
        <div style={{ marginTop: '25px' }}>
          <StyledTable theme={lightTheme}>
            <Table columns={aiColumns} dataSource={AIList} />
          </StyledTable>
        </div>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(AIList);
