import React, { Component } from 'react';
import { Input } from 'antd';

class TextAreaComponent extends Component {
  render() {
    return (
      <Input.TextArea
        maxLength={2650}
        {...{ ...this.props }}
        onChange={(e) =>
          this.props.onChange(typeof this.props.onChange === 'function' ? e : e.target.value)
        }
      />
    );
  }
}

export default TextAreaComponent;
