/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../selectors/language';
import { Link } from 'react-router-dom';
import Home from './Home';
import { StyledMain } from './CSS/style';
import { Breadcrumb } from 'antd';
import UseCase from './UseCase';
import TrainingJobs from './TrainingJobs';
import Tasks from './Tasks';
// import moment from 'moment';
import history from '../../../commons/history';
import axios from 'axios';
import { ANALYTICS } from '../../../commons/api';
import localStorage from '../../../utils/localStorage';
import { SubmitForm } from '../APICalls/FormCalls';
const APIList = {
  getList: `${ANALYTICS.UseCaseGet}`,
  PredictionGet: `${ANALYTICS.PredictionGetById}`,
  TrainingJobGet: `${ANALYTICS.TrainingJobGetById}`
  // Edit: `${ANALYTICS.UseCaseGet}`
};
// import { EllipsisOutlined } from '@ant-design/icons';

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBreadcrumb: [],
      redirect: this.props.redirect ? this.props.redirect : false,
      lastBreadcrumb: 'home',
      SelectedUsecaseName: '',
      SelectedUsecaseDesc: '',
      breadcrumbList: [
        {
          title: '',
          key: 'home',
          type: 'home' // usercase List,crete,update
        }
      ]
    };
  }
  componentDidMount = () => {
    let { breadcrumbList } = this.state;

    let lastBreadcrumb =
      breadcrumbList && breadcrumbList[breadcrumbList.length - 1]
        ? breadcrumbList[breadcrumbList.length - 1].type
        : {};
    this.setState({ lastBreadcrumb });
  };

  renderForm = (path, key, type) => {
    let { breadcrumbList } = this.state;

    let lastBreadcrumb = path && path.type;
    if (key && key === 'edit') {
      lastBreadcrumb = path && path.type;
      this.setState({
        breadcrumbList: path
      });
    }

    if (key && key === 'New') {
      let data = [];

      if (type && type === 'home') {
        data = [
          {
            title: 'Home',
            key: 'home',
            type: 'home' // usercase List,crete,update
          }
        ];
      } else if (type && type === 'usecase') {
        data = [
          {
            title: 'UseCase',
            key: 'usecase',
            type: 'usecase'
          }
        ];
      } else if (type && type === 'Prediction') {
        data = [
          {
            title: 'Prediction Task',
            key: 'Prediction',
            type: 'Prediction'
          }
        ];
      } else if (type && type === 'Analysis') {
        data = [
          {
            title: 'Analysis Task',
            key: 'Analysis',
            type: 'Analysis' //traing job by id => update option,models in tab
          }
        ];
      } else if (type && type === 'TrainningJob') {
        data = [
          {
            title: 'Training Job',
            key: 'TrainningJob',
            type: 'TrainningJob' //traing job by id => update option,models in tab
          }
        ];
      }
      let PathList = breadcrumbList.concat(data);
      lastBreadcrumb = PathList && PathList.type;
      this.setState({ breadcrumbList: PathList });
    }

    lastBreadcrumb =
      breadcrumbList && breadcrumbList[breadcrumbList.length - 1]
        ? breadcrumbList[breadcrumbList.length - 1].type
        : {};

    // this.setState({ lastBreadcrumb  });
    switch (lastBreadcrumb) {
      case 'home':
        return (
          <>
            {/* <div>{'Home'}</div> */}
            <Home renderForm={this.renderForm} {...'valll'} />
          </>
        );
      case 'usecase':
        return (
          <>
            {/* <div>{'Use Case'}</div> */}
            <UseCase renderForm={this.renderForm} {...'valll'} />
          </>
        );
      case 'Prediction':
        return (
          <>
            {/* <div>{'Prediction Task'}</div> */}
            <Tasks renderForm={this.renderForm} />
          </>
        );
      case 'Analysis':
        return (
          <>
            {/* <div>{'Analysis Task'}</div> */}
            <Tasks renderForm={this.renderForm} />
          </>
        );
      case 'TrainningJob':
        return (
          <>
            {/* <div>{'Training Job'}</div> */}
            <TrainingJobs renderForm={this.renderForm} />
          </>
        );
      default:
        return 'No form exists';
    }
  };
  changeOnBreadCrumb = async (breadCrumb, e, index) => {
    let { breadcrumbList } = this.state;
    let value = [...breadcrumbList];
    let data = value.splice(0, index + 1);

    let dataVal = data[data.length - 1];
    if (dataVal && dataVal.type === 'usecase') {
      let Id = dataVal && dataVal.Id;
      if (Id !== undefined) {
        let editData = [];
        editData = await this.editList(dataVal.Id);
        if (editData && editData !== undefined) {
          let obj = {
            Title: editData.Title,
            Id: editData.Id,
            RelatedTags: editData.RelatedTags,
            CreatedBy: editData.CreatedBy,
            CreatedTime: editData.CreatedTime,
            Comments: editData && editData.Comments,
            UseCaseName: editData.UseCaseName,
            Description: editData.Description,
            Attachments: editData && editData.Attachments
          };
          history.push({
            pathname: `/rubus/RubusAnalytics/usecase/${editData.Id}`,
            state: {
              formObject: obj,
              Description: editData.Description,
              Id: editData.Id,
              path: editData.Path
            }
          });
        }
      } else {
        history.push({
          pathname: '/rubus/RubusAnalytics/Tasks/New',
          state: {
            // UseCaseName: record.UseCaseName,
            // editData: editData,
            Id: data && data[0] && data[0].Id
          }
        });
      }
    }

    if ((dataVal && dataVal.type === 'Prediction') || (dataVal && dataVal.type === 'Analysis')) {
      let Id = dataVal && dataVal.Id;
      if (Id !== undefined) {
        let payload = {
          AnalyticsId: data && data[1] && data[1].Id,
          Id: dataVal && dataVal.Id
        };
        let FormData = await SubmitForm(payload, APIList.PredictionGet);
        history.push({
          pathname: `/rubus/RubusAnalytics/Tasks/${dataVal.Id}`,
          state: {
            formObject: FormData,
            Id: dataVal && dataVal.Id,
            AnalyticsId: data && data[1] && data[1].Id,
            path: data.Path
          }
        });
      } else {
        history.push({
          pathname: '/rubus/RubusAnalytics/Tasks/New',
          // state: {
          //   UseCaseName: record.UseCaseName,
          //   Description: record.Description,
          Id: data && data[1] && data[1].Id
          // }
        });
      }
    }

    if (dataVal && dataVal.type === 'TrainningJob') {
      let payload = {
        AnalyticsId: data && data[1] && data[1].Id,
        TaskId: data && data[2] && data[2].Id,
        Id: dataVal && dataVal.Id
      };
      let formObject = await SubmitForm(payload, APIList.TrainingJobGet);
      history.push({
        pathname: `/rubus/RubusAnalytics/TrainningJob/${dataVal.Id}`,
        state: {
          formObject: formObject,
          Id: dataVal.Id,
          TaskId: data && data[2] && data[2].Id,
          path: formObject && formObject.Path
        }
      });
    }

    this.setState({ lastBreadcrumb: data.type, breadcrumbList: data });
  };
  editList = async (Id) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const object = {
      method: 'GET',
      url: `${APIList.getList}?SiteId=${siteId}&Id=${Id}&Type=Usecase`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(object)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  };
  render() {
    let { breadcrumbList } = this.state;
    // if(redirect === false){
    let lastBreadcrumb =
      breadcrumbList && breadcrumbList[breadcrumbList.length - 1]
        ? breadcrumbList[breadcrumbList.length - 1]
        : {};
    return (
      <StyledMain style={{ minHeight: window.innerHeight - 73 }}>
        <div
          style={{
            margin: '10px',
            background: '#1a3652'
          }}
        >
          <Breadcrumb
            style={{
              padding: '20px 0px 10px 35px',
              color: 'white',
              fontWeight: '800',
              fontSize: '16px'
            }}
            separator=">"
            className="_Bredacrumb_"
          >
            {breadcrumbList &&
              Array.isArray(breadcrumbList) &&
              breadcrumbList.map((breadcrumb, index) => {
                if (
                  breadcrumb &&
                  breadcrumb.type &&
                  breadcrumb.type === 'home' &&
                  breadcrumbList.length === 1
                ) {
                  return (
                    <Breadcrumb.Item key={{ index }}>
                      <Link
                        to={`/rubus/RubusAnalytics/${breadcrumb.type}`}
                        onClick={(e) => this.changeOnBreadCrumb(breadcrumbList, e, index)}
                      >
                        {''}
                      </Link>
                    </Breadcrumb.Item>
                  );
                } else if (breadcrumb && breadcrumb.type && breadcrumb.type === 'home') {
                  return (
                    <Breadcrumb.Item key={{ index }}>
                      <Link
                        to={`/rubus/RubusAnalytics/${breadcrumb.type}`}
                        onClick={(e) => this.changeOnBreadCrumb(breadcrumbList, e, index)}
                      >
                        {breadcrumb.title}
                      </Link>
                    </Breadcrumb.Item>
                  );
                } else if (breadcrumb.type === 'Prediction' || breadcrumb.type === 'Analysis') {
                  return (
                    <Breadcrumb.Item key={{ index }}>
                      <Link
                        to={`/rubus/RubusAnalytics/Tasks/New`}
                        onClick={(e) => this.changeOnBreadCrumb(breadcrumbList, e, index)}
                      >
                        {breadcrumb.title}
                      </Link>
                    </Breadcrumb.Item>
                  );
                } else {
                  return (
                    <Breadcrumb.Item key={{ index }}>
                      <Link
                        to={`/rubus/RubusAnalytics/${breadcrumb.type}/New`}
                        onClick={(e) => this.changeOnBreadCrumb(breadcrumbList, e, index)}
                      >
                        {breadcrumb.title}
                      </Link>
                    </Breadcrumb.Item>
                  );
                }
                // <Breadcrumb.Item key={index}>{breadcrumb.title}</Breadcrumb.Item>;
              })}
          </Breadcrumb>

          <div>{this.renderForm(lastBreadcrumb)}</div>
        </div>
      </StyledMain>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(Analytics);
