import React, { Component } from 'react';
import { Form, Select } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../../../selectors/language';

class VersionInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: '',
      translationCache: props.translationCache || []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    };
    return (
      <div style={{}}>
        <Form
          {...formItemLayout}
          layout="horizontal"
          name={this.translation('HeaderNumber_current')}
          style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
        >
          <label style={{ fontSize: '18px', fontWeight: 'bolder' }}>
            {this.translation('Kb-30')}
          </label>
          <Form.Item label={this.translation('HD')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="01">01</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={this.translation('Version')}>
            <Select style={{ width: 220 }}>
              <div>008-V2.1</div>
            </Select>
          </Form.Item>
          <Form.Item label={this.translation('Update')}>
            <div>2022/05/25</div>
          </Form.Item>
        </Form>
        <Form
          {...formItemLayout}
          layout="horizontal"
          name={this.translation('KB-software')}
          style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
        >
          <label style={{ fontSize: '18px', fontWeight: 'bolder' }}>
            {this.translation('Kb30 Pc Software')}
          </label>
          <Form.Item label={this.translation('Drawing No')}>
            <div>OVS2004E</div>
          </Form.Item>
          <Form.Item label={this.translation('Version')}>
            <Select style={{ width: 220 }}>
              <div>1.1.1</div>
            </Select>
          </Form.Item>
          <Form.Item label={this.translation('Update')}>
            <div>2022/05/25 12:23:56</div>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(VersionInformation);
