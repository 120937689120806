import { createSelector } from 'reselect';

const deviceList = (state) => {
  return state.get('deviceType').get('deviceType');
};

const getDeviceTypeListSelector = () =>
  createSelector(deviceList, (state) => {
    return state && state.get('deviceTypeList');
  });

const getDeviceTypeCMListSelector = () =>
  createSelector(deviceList, (state) => {
    return state && state.get('deviceTypeCMList');
  });

export { getDeviceTypeListSelector, getDeviceTypeCMListSelector };
