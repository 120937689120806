/* eslint-disable array-callback-return */
import React from 'react';
import {
  DeployModel,
  getDeployHistory,
  getList,
  getMetaData,
  createPredictionRequests
} from '../Utils/FormCalls';
import _ from 'lodash';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Tree,
  message,
  Image,
  Tabs,
  Typography,
  Popconfirm,
  Layout,
  Result
} from 'antd';
import {
  StyledBox,
  StyledBoxLabel,
  StyledComponent,
  // StyledModal,
  StyledSubHeading,
  StyledTable,
  StyledTabs,
  StyleModal
} from '../CSS/style';
import { decode as base64_decode } from 'base-64';
import themeSettings from '../Utils/themeSettings.json';
// import translation from '../Utils/translation';
import { StyledForm } from '../CSS/style';
import '../CSS/index.css';
import { getTranslationCache } from '../../../selectors/language';
// import { constant } from '../Utils/Constants';
import { createStructuredSelector } from 'reselect';
import { getUserProfile } from '../../../selectors/layout';
import { connect } from 'react-redux';
import history from '../../../commons/history';
import moment from 'moment';
import { ApartmentOutlined, DownOutlined, SearchOutlined, SmileOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { IPAddress, MODELCOMPARSION, RBSLOOKUPMaster, RBSPREDMODEL } from '../../../commons/api';
const { Header, Content } = Layout;
const { TabPane } = Tabs;

const { TextArea } = Input;
const APIList = {
  getList: `${RBSPREDMODEL.ModelWorkBenchList}`,
  getPredictionHistory: `${RBSPREDMODEL.CompletedPredictionRequestList}`,
  getDeployHistory: `${RBSPREDMODEL.DeployemtReqlist}`,
  getData: `${RBSPREDMODEL.ReadMetadata}`,
  getMetrics: `${RBSLOOKUPMaster.LookUpItemsGetById}`,
  getMetricResults: `${RBSPREDMODEL.MetricpRedList}`,
  predictionRequest: `${IPAddress.PREDICTIONIP}/pred_reqs/create_request`,
  bookmarkCreate: `${MODELCOMPARSION.CREATE}`,
  getDataByBookmark: `${MODELCOMPARSION.GetById}`
};
const { Option } = Select;
const { TreeNode } = Tree;
const { Title } = Typography;

class Model_Comparision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      translationCache: props.translationCache || {},
      ModelsList: [],
      model_1PredictionRequestList: [],
      model_2PredictionRequestList: [],
      predictionModel_1: false,
      predictionModel_2: false,
      NewPredictionModel_1: false,
      NewPredictionModel_2: false,
      model_1Info: {},
      model_2Info: {},
      showResults: false,
      modelSelectionPopup: false,
      selectionType: 'radio',
      ModelDeployHistoryData_1: {},
      ModelDeployHistoryData_2: {},
      userProfile: props && props.userProfile ? props.userProfile : {},
      selectedRowKeys1: [],
      selectedRowKeys2: [],
      activekey_1: '1',
      BookmarkId: ''
    };
    this.dataRef = React.createRef();
    this.modelRef = React.createRef();
    this.model_1Ref = React.createRef();
    this.model_2Ref = React.createRef();
    this.model_1Button = React.createRef();
    this.model_2Button = React.createRef();
    this.model_1tab2Confirm = React.createRef();
    this.model_2tab2Confirm = React.createRef();
    this.model_1tab1Confirm = React.createRef();
    this.model_2tab1Confirm = React.createRef();
  }
  componentDidMount = async () => {
    let path = history.location.pathname.split('/rubus/RbsAnalytics/ModelComparision/');
    let Id = path && path[1] && base64_decode(path[1]) ? base64_decode(path[1]) : null;
    let response = await getList(APIList.getList);
    this.setState({
      ModelsList: response && Array.isArray(response) ? [...response] : [],
      filterdArrayOfModels_1: response && Array.isArray(response) ? [...response] : [],
      filterdArrayOfModels_2: response && Array.isArray(response) ? [...response] : []
    });
    if (
      this.props &&
      this.props.location &&
      this.props.location &&
      this.props.location.state === undefined
    ) {
      if (Id && Id !== null) {
        let model_1response = {};
        let model_2response = {};
        let data = await getMetaData(APIList.getDataByBookmark, 'Id', Id);

        let model_1Info = {
          ...(data && data !== undefined && data.model_1),
          FromTime: moment.utc(data.model_1.FromTime, 'YYYY-MM-DD HH:mm:ss'),
          ToTime: moment.utc(data.model_1.ToTime, 'YYYY-MM-DD HH:mm:ss')
        };
        let model_2Info = {
          ...(data && data !== undefined && data.model_2),
          FromTime: moment.utc(data.model_2.FromTime, 'YYYY-MM-DD HH:mm:ss'),
          ToTime: moment.utc(data.model_2.ToTime, 'YYYY-MM-DD HH:mm:ss')
        };
        let formObject = {
          ModelName_1:
            data && data.model_1 && data.model_1.ModelName_1 ? data.model_1.ModelName_1 : '',
          ModelName_2:
            data && data.model_2 && data.model_2.ModelName_2 ? data.model_2.ModelName_2 : '',
          comments: data.comments ? data.comments : ''
        };
        if (model_1Info && model_1Info.PredReqId_1) {
          model_1response = await getDeployHistory(
            APIList.getMetricResults,
            'PredReqId',
            model_1Info.PredReqId_1
          );
        }
        if (model_2Info && model_2Info.PredReqId_2) {
          model_2response = await getDeployHistory(
            APIList.getMetricResults,
            'PredReqId',
            model_2Info.PredReqId_2
          );
        }

        // this.model_1Ref.current.setFieldsValue({ model_1Info });
        //   this.model_2Ref.current.setFieldsValue({ model_2Info });
        this.dataRef.current && this.dataRef.current.setFieldsValue({ ...formObject });
        this.setState({
          model_1Info,
          model_2Info,
          formObject,
          selectedRowKeys1:
            data && data.model_1 && data.model_1.PredReqId_1 ? [data.model_1.PredReqId_1] : [],
          selectedRowKeys2:
            data && data.model_2 && data.model_2.PredReqId_2 ? [data.model_2.PredReqId_2] : [],
          Model_1MetricValuesData:
            model_1response && model_1response.TableList ? model_1response.TableList : null,
          Model_1MetricImagesData:
            model_1response.ImageList && model_1response.ImageList
              ? model_1response.ImageList
              : null,
          Model_2MetricValuesData:
            model_2response && model_2response.TableList ? model_2response.TableList : null,
          Model_2MetricImagesData:
            model_2response.ImageList && model_2response.ImageList
              ? model_2response.ImageList
              : null,
          showResults: true
        });
        this.onHandleChange(
          'ModelName_1',
          data && data.model_1 && data.model_1.ModelName_1,
          model_1Info
        );
        this.onHandleChange(
          'ModelName_2',
          data && data.model_2 && data.model_2.ModelName_2,
          model_2Info
        );
      } else {
        this.setState({
          ModelsList: response && Array.isArray(response) ? [...response] : []
        });
      }
    } else if (
      (this.props &&
        this.props.location &&
        this.props.location !== undefined &&
        this.props.location.state !== undefined &&
        this.props.location.state.ModelName_1,
      this.props.location.state.PredReqId_1)
    ) {
      let data =
        response &&
        response.filter((item) => item.ModelName === this.props.location.state.ModelName_1);
      let model_1Info = {
        ModelName_1: data && data[0] && data[0]['ModelId'] ? data[0]['ModelId'] : '',
        PredReqId_1: this.props.location.state.PredReqId_1,
        FromTime: this.props.location.state.FromTime ? this.props.location.state.FromTime : '',
        ToTime: this.props.location.state.ToTime ? this.props.location.state.ToTime : ''
      };
      let formObject = {
        ModelName_1: data && data[0] && data[0]['ModelId'] ? data[0]['ModelId'] : ''
      };
      this.onHandleChange('ModelName_1', data && data[0] && data[0]['ModelId'], model_1Info);
      this.dataRef.current.setFieldsValue({ ...formObject });
      this.setState({
        model_1Info,
        formObject,
        selectedRowKeys1: [this.props.location.state.PredReqId_1]
      });
    }
  };
  componentDidUpdate = async (prevProps) => {
    if (
      this.props.userProfile !== prevProps.userProfile ||
      this.props.translationCache !== prevProps.translationCache
    ) {
      this.setState({
        userProfile: this.props.userProfile,
        translationCache: this.props.translationCache
      });
    }
  };
  onHandleChange = async (key, value, model_Info) => {
    let { ModelsList } = this.state;
    let model_1Info = {};
    let model_2Info = {};
    if (key === 'ModelName_1') {
      var model_1PredictionRequestList = await getDeployHistory(
        APIList.getPredictionHistory,
        'ModelId',
        value
      );
      let arrayofdata = [];
      ModelsList &&
        ModelsList.map((item) => {
          if (item.ModelId === value) {
            arrayofdata = ModelsList.filter(
              (item1) => item1.TargetParameter === item.TargetParameter
            );
          }
        });
      let model_1Info = {
        ...model_Info,
        [key]: value
      };

      this.model_1Ref.current && this.model_1Ref.current.setFieldsValue({ ...model_1Info });
      this.setState({
        model_1Info,
        model_1PredictionRequestList,
        filterdArrayOfModels_2: arrayofdata
      });
    } else if (key === 'ModelName_2') {
      var model_2PredictionRequestList = await getDeployHistory(
        APIList.getPredictionHistory,
        'ModelId',
        value
      );
      let arrayofdata = [];
      ModelsList &&
        ModelsList.map((item) => {
          if (item.ModelId === value) {
            arrayofdata = ModelsList.filter(
              (item1) => item1.TargetParameter === item.TargetParameter
            );
          }
        });
      let model_2Info = {
        ...model_Info,
        [key]: value
      };
      this.model_1Ref.current && this.model_1Ref.current.setFieldsValue({ ...model_1Info });
      this.setState({
        model_2Info,
        model_2PredictionRequestList,
        filterdArrayOfModels_1: arrayofdata
      });
    }

    this.dataRef.current && this.dataRef.current.setFieldsValue({ ...model_1Info, ...model_2Info });

    this.setState({ formObject: { ...model_1Info, ...model_2Info } });
  };
  default_1DeployConfirm = (record) => {
    let { model_1Info, formObject } = this.state;
    formObject = {
      ...formObject,
      PredReqId_1: record.PredReqId
    };
    model_1Info = {
      ...model_1Info,
      PredReqId: record.PredReqId
    };
    this.model_1Ref.current && this.model_1Ref.current.setFieldsValue({ ...model_1Info });
    this.dataRef.current && this.dataRef.current.setFieldsValue({ ...formObject });
    this.setState({ model_1Info, formObject });
  };
  default_2DeployConfirm = (record) => {
    let { model_2Info, formObject } = this.state;
    formObject = {
      ...formObject,
      PredReqId_2: record.PredReqId
    };
    model_2Info = {
      ...model_2Info,
      PredReqId: record.PredReqId
    };
    this.model_2Ref.current && this.model_2Ref.current.setFieldsValue(model_2Info);
    this.dataRef.current && this.dataRef.current.setFieldsValue({ ...formObject });
    this.setState({ model_2Info, formObject });
  };
  renderTreeNodes = (data) => {
    return (
      data &&
      data !== undefined &&
      Array.isArray(data) &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <TreeNode title={item.key} key={item.key} dataRef={item.key}>
              {this.renderTreeNodes(item.children, item)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.value} title={item.key} dataRef={item.value} />;
      })
    );
  };
  getDeployHistory = async (Model_Id) => {
    let ModelDeployHistoryData = await getDeployHistory(
      APIList.getDeployHistory,
      'ModelId',
      Model_Id
    );
    return ModelDeployHistoryData;
  };
  getModel_1MetaData = async (Model_Id) => {
    let { model_1Info } = this.state;
    let ModelDetails = await getMetaData(APIList.getData, 'ModelId', Model_Id);
    let ModelDeployHistoryData_1 = await this.getDeployHistory(Model_Id);
    let modeltype =
      ModelDetails && ModelDetails.ModelType === 'Classification'
        ? 'RBS_MODEL_CLF_METRICS'
        : this.modelRef.current && this.modelRef.current.getFieldValue('ModelType') === 'Regression'
        ? 'RBS_MODEL_REG_METRICS'
        : null;
    var metricsData = await getDeployHistory(APIList.getMetrics, 'LookupName', modeltype);
    let deploymentData =
      ModelDeployHistoryData_1 &&
      ModelDeployHistoryData_1.filter((item) => item.Default === true) &&
      ModelDeployHistoryData_1.filter((item) => item.Default === true)[0];

    deploymentData = {
      CreatedTime: moment(deploymentData && deploymentData.CreatedTime)
        .utc()
        .local()
        .format('YYYY-MM-DD HH:mm:ss'),
      Virtualenv: deploymentData && deploymentData.Virtualenv
    };
    _.cloneDeepWith(ModelDetails, (value) => {
      return value !== null && value !== undefined
        ? {
            ...value,
            ...(value['TrainPeriodFrom']
              ? (value['TrainPeriodFrom'] = moment(value.TrainPeriodFrom)
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['TrainPeriodTo']
              ? (value['TrainPeriodTo'] = moment(value.TrainPeriodTo)
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['EvalPeriodFrom']
              ? (value['EvalPeriodFrom'] = moment(value.EvalPeriodFrom)
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['EvalPeriodTo']
              ? (value['EvalPeriodTo'] = moment(value.EvalPeriodTo)
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['CreatedTime']
              ? (value['CreatedTime'] = moment(value.CreatedTime)
                  .utc()
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss'))
              : null)
          }
        : _.noop();
    });
    ModelDetails = {
      ModelName_1: model_1Info.ModelName_1 ? model_1Info.ModelName_1 : '',
      ...ModelDetails,
      ...deploymentData,
      Metrics:
        metricsData &&
        Array.isArray(metricsData) &&
        metricsData.map((item) => {
          return item.value;
        })
    };
    this.model_1Ref.current && this.model_1Ref.current.setFieldsValue({ ...ModelDetails });
    this.setState({
      NewPredictionModel_1: true,
      model_1Info: ModelDetails,
      metricsData,
      ModelDeployHistoryData_1
    });
  };
  getModel_2MetaData = async (Model_Id) => {
    let { model_2Info } = this.state;
    let ModelDetails = await getMetaData(APIList.getData, 'ModelId', Model_Id);
    let ModelDeployHistoryData_2 = await this.getDeployHistory(Model_Id);
    let modeltype =
      ModelDetails && ModelDetails.ModelType === 'Classification'
        ? 'RBS_MODEL_CLF_METRICS'
        : this.modelRef.current && this.modelRef.current.getFieldValue('ModelType') === 'Regression'
        ? 'RBS_MODEL_REG_METRICS'
        : null;
    var metricsData = await getDeployHistory(APIList.getMetrics, 'LookupName', modeltype);
    let deploymentData =
      ModelDeployHistoryData_2 &&
      ModelDeployHistoryData_2.filter((item) => item.Default === true) &&
      ModelDeployHistoryData_2.filter((item) => item.Default === true)[0];

    deploymentData = {
      CreatedTime: moment(deploymentData && deploymentData.CreatedTime)
        .utc()
        .local()
        .format('YYYY-MM-DD HH:mm:ss'),
      Virtualenv: deploymentData && deploymentData.Virtualenv
    };
    _.cloneDeepWith(ModelDetails, (value) => {
      return value !== null && value !== undefined
        ? {
            ...value,
            ...(value['TrainPeriodFrom']
              ? (value['TrainPeriodFrom'] = moment(value.TrainPeriodFrom)
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['TrainPeriodTo']
              ? (value['TrainPeriodTo'] = moment(value.TrainPeriodTo)
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['EvalPeriodFrom']
              ? (value['EvalPeriodFrom'] = moment(value.EvalPeriodFrom)
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['EvalPeriodTo']
              ? (value['EvalPeriodTo'] = moment(value.EvalPeriodTo)
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss'))
              : null),
            ...(value['CreatedTime']
              ? (value['CreatedTime'] = moment(value.CreatedTime)
                  .utc()
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss'))
              : null)
          }
        : _.noop();
    });
    ModelDetails = {
      ModelName_2: model_2Info.ModelName_2 ? model_2Info.ModelName_2 : '',
      ...ModelDetails,
      ...deploymentData,
      Metrics: metricsData.map((item) => {
        return item.value;
      })
    };
    this.model_2Ref.current && this.model_2Ref.current.setFieldsValue({ ...ModelDetails });
    this.setState({
      NewPredictionModel_2: true,
      model_2Info: ModelDetails,
      metricsData,
      ModelDeployHistoryData_2
    });
  };
  getDisabledHoursTask = (e, key) => {
    let hours = [];
    let minutes = [];
    let StartDateTime = '';
    let EndDateTime = '';
    let StartDate = '';
    let EndDate = '';

    if (key === 'StartTime') {
      StartDateTime = moment(e).format('YYYY-MM-DD HH:mm');
      EndDateTime = moment
        .utc(
          this.TaskRef.current.getFieldValue() && this.TaskRef.current.getFieldValue('EndTime'),
          'YYYY-MM-DD HH:mm'
        )
        .local();

      StartDate = moment(e).format('YYYY-MM-DD');
      EndDate = moment
        .utc(this.TaskRef.current.getFieldValue() && this.TaskRef.current.getFieldValue('EndTime'))
        .local()
        .format('YYYY-MM-DD');
    } else if (key === 'EndTime') {
      StartDateTime = moment
        .utc(
          this.TaskRef.current.getFieldValue() && this.TaskRef.current.getFieldValue('StartTime'),
          'YYYY-MM-DD HH:mm'
        )
        .local();
      EndDateTime = moment(e).format('YYYY-MM-DD HH:mm');

      StartDate = moment
        .utc(
          this.TaskRef.current.getFieldValue() && this.TaskRef.current.getFieldValue('StartTime')
        )
        .local()
        .format('YYYY-MM-DD');
      EndDate = moment(e).format('YYYY-MM-DD');
    }
    if (
      key === 'StartTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (let i = 0; i <= 24; i++) {
        if (i > moment(EndDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var i = 0; i <= 60; i++) {
          if (i > moment(EndDateTime).minutes() - 1) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    } else if (
      key === 'EndTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (let i = 0; i < moment(StartDateTime).hours(); i++) {
        if (i < 25) {
          hours.push(i);
        }
      }

      if (moment(StartDate).isSame(moment(EndDate))) {
        for (let i = 0; i < moment(StartDateTime).minutes() + 1; i++) {
          if (i < 61) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    }
  };
  handleDateInputChange = (dateString) => {
    let data = this.model_1Ref.current.getFieldsValue();
    let TrianedFrom = data.TrainPeriodFrom;
    let TrianedTo = data.TrainPeriodTo;
    let date = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
    if (date >= TrianedFrom && date <= TrianedTo) {
      message.error(
        'This date range overlaps with model training period. The prediction results may not reflect the accuracy of the unseen data. Do you want to continue?'
      );
    } else {
      return null;
    }
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search') + this.translation(dataIndex)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
        {/* <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
        >
          Filter
        </Button> */}
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: '' });
  };
  onFormCompare = () => {
    let { model_1Info, model_2Info } = this.state;
    this.dataRef.current &&
      this.dataRef.current
        .validateFields()
        .then(async () => {
          let model_1response = {};
          let model_2response = {};
          if (model_1Info && model_1Info !== undefined) {
            model_1response = await getDeployHistory(
              APIList.getMetricResults,
              'PredReqId',
              model_1Info.PredReqId_1
            );
          }
          if (model_2Info && model_2Info.PredReqId_2) {
            model_2response = await getDeployHistory(
              APIList.getMetricResults,
              'PredReqId',
              model_2Info.PredReqId_2
            );
          }
          this.setState({
            Model_1MetricValuesData:
              model_1response && model_1response.TableList ? model_1response.TableList : null,
            Model_1MetricImagesData:
              model_1response.ImageList && model_1response.ImageList
                ? model_1response.ImageList
                : null,
            Model_2MetricValuesData:
              model_2response && model_2response.TableList ? model_2response.TableList : null,
            Model_2MetricImagesData:
              model_2response.ImageList && model_2response.ImageList
                ? model_2response.ImageList
                : null,
            showResults: true
          });
        })
        .catch(() => {});
  };
  onFormSaveComapare = () => {
    const formObject = this.dataRef.current.getFieldsValue();
    var { model_1Info, model_2Info, model_1PredictionRequestList, model_2PredictionRequestList } =
      this.state;
    this.dataRef.current &&
      this.dataRef.current
        .validateFields()
        .then(async () => {
          if (
            model_1Info &&
            model_1Info !== undefined &&
            model_1Info.PredReqId_1 === undefined &&
            model_2Info &&
            model_2Info !== undefined &&
            model_2Info.PredReqId_2 === undefined
          ) {
            this.modelsSave(model_1Info, model_2Info);
          } else {
            if (
              model_1Info &&
              model_1Info !== undefined &&
              model_1Info.PredReqId_1 === undefined &&
              model_2Info &&
              model_2Info.PredReqId_2
            ) {
              this.modelSave(model_1Info, 'model__1');
            } else if (
              model_2Info &&
              model_2Info !== undefined &&
              model_2Info.PredReqId_2 === undefined &&
              model_1Info &&
              model_1Info.PredReqId_1
            ) {
              this.modelSave(model_2Info, 'model__2');
            } else {
              if (
                model_2Info &&
                model_2Info.PredReqId_2 &&
                model_1Info &&
                model_1Info.PredReqId_1
              ) {
                let final = {
                  model_1: {
                    ...model_1Info,
                    FromTime:
                      model_1Info && model_1Info.FromTime && model_1Info.FromTime !== undefined
                        ? moment(model_1Info.FromTime).utc().format('YYYY-MM-DD HH:mm:ss')
                        : model_1PredictionRequestList &&
                          model_1PredictionRequestList.filter(
                            (item) => item.PredReqId === model_1Info.PredReqId_1
                          ) &&
                          model_1PredictionRequestList.filter(
                            (item) => item.PredReqId === model_1Info.PredReqId_1
                          )[0] &&
                          model_1PredictionRequestList.filter(
                            (item) => item.PredReqId === model_1Info.PredReqId_1
                          )[0]['FromTime']
                        ? moment(
                            model_1PredictionRequestList.filter(
                              (item) => item.PredReqId === model_1Info.PredReqId_1
                            )[0]['FromTime']
                          )
                            .utc()
                            .format('YYYY-MM-DD HH:mm:ss')
                        : null,
                    ToTime:
                      model_1Info && model_1Info.ToTime && model_1Info.ToTime !== undefined
                        ? moment(model_1Info.ToTime).utc().format('YYYY-MM-DD HH:mm:ss')
                        : model_1PredictionRequestList &&
                          model_1PredictionRequestList.filter(
                            (item) => item.PredReqId === model_1Info.PredReqId_1
                          ) &&
                          model_1PredictionRequestList.filter(
                            (item) => item.PredReqId === model_1Info.PredReqId_1
                          )[0] &&
                          model_1PredictionRequestList.filter(
                            (item) => item.PredReqId === model_1Info.PredReqId_1
                          )[0]['ToTime']
                        ? moment(
                            model_1PredictionRequestList.filter(
                              (item) => item.PredReqId === model_1Info.PredReqId_1
                            )[0]['ToTime']
                          )
                            .utc()
                            .format('YYYY-MM-DD HH:mm:ss')
                        : null
                  },
                  model_2: {
                    ...model_2Info,
                    FromTime:
                      model_2Info && model_2Info.FromTime && model_2Info.FromTime !== undefined
                        ? moment(model_2Info.FromTime).utc().format('YYYY-MM-DD HH:mm:ss')
                        : model_2PredictionRequestList &&
                          model_2PredictionRequestList.filter(
                            (item) => item.PredReqId === model_2Info.PredReqId_2
                          ) &&
                          model_2PredictionRequestList.filter(
                            (item) => item.PredReqId === model_2Info.PredReqId_2
                          )[0] &&
                          model_2PredictionRequestList.filter(
                            (item) => item.PredReqId === model_2Info.PredReqId_2
                          )[0]['FromTime']
                        ? moment(
                            model_2PredictionRequestList.filter(
                              (item) => item.PredReqId === model_2Info.PredReqId_2
                            )[0]['FromTime']
                          )
                            .utc()
                            .format('YYYY-MM-DD HH:mm:ss')
                        : null,
                    ToTime:
                      model_2Info && model_2Info.FromTime && model_2Info.FromTime !== undefined
                        ? moment(model_2Info.ToTime).utc().format('YYYY-MM-DD HH:mm:ss')
                        : model_2PredictionRequestList &&
                          model_2PredictionRequestList.filter(
                            (item) => item.PredReqId === model_2Info.PredReqId_2
                          ) &&
                          model_2PredictionRequestList.filter(
                            (item) => item.PredReqId === model_2Info.PredReqId_2
                          )[0] &&
                          model_2PredictionRequestList.filter(
                            (item) => item.PredReqId === model_2Info.PredReqId_2
                          )[0]['ToTime']
                        ? moment(
                            model_2PredictionRequestList.filter(
                              (item) => item.PredReqId === model_2Info.PredReqId_2
                            )[0]['ToTime']
                          )
                            .utc()
                            .format('YYYY-MM-DD HH:mm:ss')
                        : null
                  },
                  comments:
                    formObject.comments && formObject.comments !== undefined
                      ? formObject.comments
                      : ''
                };
                this.bookmarkCreate(final, model_1Info, model_2Info);
              }
            }
          }
        })
        .catch(() => {});
  };
  bookmarkCreate = async (final, model_1Info, model_2Info) => {
    let path = history.location.pathname.split('/rubus/RbsAnalytics/ModelComparision/');
    let Id = path && path[1] && base64_decode(path[1]) ? base64_decode(path[1]) : null;
    if (Id && Id !== undefined) {
      final = {
        ...final,
        Id: Id
      };
    }
    let response = await DeployModel(APIList.bookmarkCreate, final);
    message.success(response && response.message && this.translation(response.message));
    let model_1response = await getDeployHistory(
      APIList.getMetricResults,
      'PredReqId',
      model_1Info.PredReqId_1
    );
    let model_2response = await getDeployHistory(
      APIList.getMetricResults,
      'PredReqId',
      model_2Info.PredReqId_2
    );
    this.setState({
      Model_1MetricValuesData:
        model_1response && model_1response.TableList ? model_1response.TableList : null,
      Model_1MetricImagesData:
        model_1response.ImageList && model_1response.ImageList ? model_1response.ImageList : null,
      Model_2MetricValuesData:
        model_2response && model_2response.TableList ? model_2response.TableList : null,
      Model_2MetricImagesData:
        model_2response.ImageList && model_2response.ImageList ? model_2response.ImageList : null,
      showResults: true,
      BookmarkId: response && response.BookmarkId ? response.BookmarkId : ''
    });
  };

  modelsSave = async (model_1Info, model_2Info) => {
    let {
      ModelDeployHistoryData_1,
      userProfile,
      model_1PredictionRequestList,
      model_2PredictionRequestList,
      ModelDeployHistoryData_2
    } = this.state;
    const formObject = this.dataRef.current.getFieldsValue();
    let value1 = {
      // ...values,
      FromTime: moment(model_1Info.FromTime).utc().format('YYYY-MM-DD HH:mm:ss'),
      ToTime: moment(model_1Info.ToTime).utc().format('YYYY-MM-DD HH:mm:ss'),
      Metrics: model_1Info.Metrics,
      ModelId: formObject && formObject.ModelName_1 ? formObject.ModelName_1 : '',
      DepReqId:
        ModelDeployHistoryData_1 &&
        Array.isArray(ModelDeployHistoryData_1) &&
        ModelDeployHistoryData_1.filter((item) => item.Default === true) &&
        ModelDeployHistoryData_1.filter((item) => item.Default === true)[0] &&
        ModelDeployHistoryData_1.filter((item) => item.Default === true)[0]['ReqId']
          ? ModelDeployHistoryData_1.filter((item) => item.Default === true)[0]['ReqId']
          : null,
      UserId: userProfile && userProfile.user && userProfile.user.Id ? userProfile.user.Id : ''
    };
    let value2 = {
      // ...values,
      FromTime: moment(model_2Info.FromTime).utc().format('YYYY-MM-DD HH:mm:ss'),
      ToTime: moment(model_2Info.ToTime).utc().format('YYYY-MM-DD HH:mm:ss'),
      Metrics: model_2Info.Metrics,
      ModelId: formObject && formObject.ModelName_2 ? formObject.ModelName_2 : '',
      DepReqId:
        ModelDeployHistoryData_2 &&
        Array.isArray(ModelDeployHistoryData_2) &&
        ModelDeployHistoryData_2.filter((item) => item.Default === true) &&
        ModelDeployHistoryData_2.filter((item) => item.Default === true)[0] &&
        ModelDeployHistoryData_2.filter((item) => item.Default === true)[0]['ReqId']
          ? ModelDeployHistoryData_2.filter((item) => item.Default === true)[0]['ReqId']
          : null,
      UserId: userProfile && userProfile.user && userProfile.user.Id ? userProfile.user.Id : ''
    };
    let response = await createPredictionRequests(APIList.predictionRequest, value1, value2);
    model_1Info = {
      ...model_1Info,
      PredReqId_1:
        response && response.response1 && response.response1.PredReqId
          ? response.response1.PredReqId
          : ''
    };
    model_2Info = {
      ...model_2Info,
      PredReqId_2:
        response && response.response2 && response.response2.PredReqId
          ? response.response2.PredReqId
          : ''
    };
    if (
      model_1Info &&
      model_1Info.PredReqId_1 &&
      model_1Info.PredReqId_1 !== undefined &&
      model_2Info &&
      model_2Info.PredReqId_2 &&
      model_2Info.PredReqId_2 !== undefined
    ) {
      let final = {
        model_1: {
          ...model_1Info,
          FromTime:
            model_1Info && model_1Info.FromTime && model_1Info.FromTime !== undefined
              ? moment(model_1Info.FromTime).utc().format('YYYY-MM-DD HH:mm:ss')
              : model_1PredictionRequestList &&
                model_1PredictionRequestList.filter(
                  (item) => item.PredReqId === model_1Info.PredReqId_1
                ) &&
                model_1PredictionRequestList.filter(
                  (item) => item.PredReqId === model_1Info.PredReqId_1
                )[0] &&
                model_1PredictionRequestList.filter(
                  (item) => item.PredReqId === model_1Info.PredReqId_1
                )[0]['FromTime']
              ? moment(
                  model_1PredictionRequestList.filter(
                    (item) => item.PredReqId === model_1Info.PredReqId_1
                  )[0]['FromTime']
                )
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss')
              : null,
          ToTime:
            model_1Info && model_1Info.ToTime && model_1Info.ToTime !== undefined
              ? moment(model_1Info.ToTime).utc().format('YYYY-MM-DD HH:mm:ss')
              : model_1PredictionRequestList &&
                model_1PredictionRequestList.filter(
                  (item) => item.PredReqId === model_1Info.PredReqId_1
                ) &&
                model_1PredictionRequestList.filter(
                  (item) => item.PredReqId === model_1Info.PredReqId_1
                )[0] &&
                model_1PredictionRequestList.filter(
                  (item) => item.PredReqId === model_1Info.PredReqId_1
                )[0]['ToTime']
              ? moment(
                  model_1PredictionRequestList.filter(
                    (item) => item.PredReqId === model_1Info.PredReqId_1
                  )[0]['ToTime']
                )
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss')
              : null
        },
        model_2: {
          ...model_2Info,
          FromTime:
            model_2Info && model_2Info.FromTime && model_2Info.FromTime !== undefined
              ? moment(model_2Info.FromTime).utc().format('YYYY-MM-DD HH:mm:ss')
              : model_2PredictionRequestList &&
                model_2PredictionRequestList.filter(
                  (item) => item.PredReqId === model_2Info.PredReqId_2
                ) &&
                model_2PredictionRequestList.filter(
                  (item) => item.PredReqId === model_2Info.PredReqId_2
                )[0] &&
                model_2PredictionRequestList.filter(
                  (item) => item.PredReqId === model_2Info.PredReqId_2
                )[0]['FromTime']
              ? moment(
                  model_2PredictionRequestList.filter(
                    (item) => item.PredReqId === model_2Info.PredReqId_2
                  )[0]['FromTime']
                )
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss')
              : null,
          ToTime:
            model_2Info && model_2Info.FromTime && model_2Info.FromTime !== undefined
              ? moment(model_2Info.ToTime).utc().format('YYYY-MM-DD HH:mm:ss')
              : model_2PredictionRequestList &&
                model_2PredictionRequestList.filter(
                  (item) => item.PredReqId === model_2Info.PredReqId_2
                ) &&
                model_2PredictionRequestList.filter(
                  (item) => item.PredReqId === model_2Info.PredReqId_2
                )[0] &&
                model_2PredictionRequestList.filter(
                  (item) => item.PredReqId === model_2Info.PredReqId_2
                )[0]['ToTime']
              ? moment(
                  model_2PredictionRequestList.filter(
                    (item) => item.PredReqId === model_2Info.PredReqId_2
                  )[0]['ToTime']
                )
                  .utc()
                  .format('YYYY-MM-DD HH:mm:ss')
              : null
        },
        comments:
          formObject.comments && formObject.comments !== undefined ? formObject.comments : ''
      };
      this.bookmarkCreate(final, model_1Info, model_2Info);
      this.setState({ model_1Info, model_2Info });
    }
  };
  modelSave = async (model_Info, key) => {
    let {
      ModelDeployHistoryData_1,
      ModelDeployHistoryData_2,
      formObject,
      userProfile,
      model_1Info,
      model_2Info,
      model_1PredictionRequestList,
      model_2PredictionRequestList
    } = this.state;
    let values = {};
    if (key === 'model__1') {
      values = {
        // ...values,
        FromTime: moment(model_1Info.FromTime).utc().format('YYYY-MM-DD HH:mm:ss'),
        ToTime: moment(model_1Info.ToTime).utc().format('YYYY-MM-DD HH:mm:ss'),
        Metrics: model_1Info.Metrics,
        ModelId: formObject && formObject.ModelName_1 ? formObject.ModelName_1 : '',
        DepReqId:
          ModelDeployHistoryData_1 &&
          Array.isArray(ModelDeployHistoryData_1) &&
          ModelDeployHistoryData_1.filter((item) => item.Default === true) &&
          ModelDeployHistoryData_1.filter((item) => item.Default === true)[0] &&
          ModelDeployHistoryData_1.filter((item) => item.Default === true)[0]['ReqId']
            ? ModelDeployHistoryData_1.filter((item) => item.Default === true)[0]['ReqId']
            : null,
        UserId: userProfile && userProfile.user && userProfile.user.Id ? userProfile.user.Id : ''
      };
    } else {
      values = {
        // ...values,
        FromTime: moment(model_2Info.FromTime).utc().format('YYYY-MM-DD HH:mm:ss'),
        ToTime: moment(model_2Info.ToTime).utc().format('YYYY-MM-DD HH:mm:ss'),
        Metrics: model_2Info.Metrics,
        ModelId: formObject && formObject.ModelName_2 ? formObject.ModelName_2 : '',
        DepReqId:
          ModelDeployHistoryData_2 &&
          Array.isArray(ModelDeployHistoryData_2) &&
          ModelDeployHistoryData_2.filter((item) => item.Default === true) &&
          ModelDeployHistoryData_2.filter((item) => item.Default === true)[0] &&
          ModelDeployHistoryData_2.filter((item) => item.Default === true)[0]['ReqId']
            ? ModelDeployHistoryData_2.filter((item) => item.Default === true)[0]['ReqId']
            : null,
        UserId: userProfile && userProfile.user && userProfile.user.Id ? userProfile.user.Id : ''
      };
    }
    let response = await DeployModel(APIList.predictionRequest, values);
    if (key === 'model__1') {
      model_1Info = {
        ...model_1Info,
        PredReqId_1: response && response && response.PredReqId ? response.PredReqId : ''
      };
    } else {
      model_2Info = {
        ...model_2Info,
        PredReqId_2: response && response && response.PredReqId ? response.PredReqId : ''
      };
    }
    let final = {
      model_1: {
        ...model_1Info,
        FromTime:
          model_1Info && model_1Info.FromTime && model_1Info.FromTime !== undefined
            ? moment(model_1Info.FromTime).utc().format('YYYY-MM-DD HH:mm:ss')
            : model_1PredictionRequestList &&
              model_1PredictionRequestList.filter(
                (item) => item.PredReqId === model_1Info.PredReqId_1
              ) &&
              model_1PredictionRequestList.filter(
                (item) => item.PredReqId === model_1Info.PredReqId_1
              )[0] &&
              model_1PredictionRequestList.filter(
                (item) => item.PredReqId === model_1Info.PredReqId_1
              )[0]['FromTime']
            ? moment(
                model_1PredictionRequestList.filter(
                  (item) => item.PredReqId === model_1Info.PredReqId_1
                )[0]['FromTime']
              )
                .utc()
                .format('YYYY-MM-DD HH:mm:ss')
            : null,
        ToTime:
          model_1Info && model_1Info.ToTime && model_1Info.ToTime !== undefined
            ? moment(model_1Info.ToTime).utc().format('YYYY-MM-DD HH:mm:ss')
            : model_1PredictionRequestList &&
              model_1PredictionRequestList.filter(
                (item) => item.PredReqId === model_1Info.PredReqId_1
              ) &&
              model_1PredictionRequestList.filter(
                (item) => item.PredReqId === model_1Info.PredReqId_1
              )[0] &&
              model_1PredictionRequestList.filter(
                (item) => item.PredReqId === model_1Info.PredReqId_1
              )[0]['ToTime']
            ? moment(
                model_1PredictionRequestList.filter(
                  (item) => item.PredReqId === model_1Info.PredReqId_1
                )[0]['ToTime']
              )
                .utc()
                .format('YYYY-MM-DD HH:mm:ss')
            : null
      },
      model_2: {
        ...model_2Info,
        FromTime:
          model_2Info && model_2Info.FromTime && model_2Info.FromTime !== undefined
            ? moment(model_2Info.FromTime).utc().format('YYYY-MM-DD HH:mm:ss')
            : model_2PredictionRequestList &&
              model_2PredictionRequestList.filter(
                (item) => item.PredReqId === model_2Info.PredReqId_2
              ) &&
              model_2PredictionRequestList.filter(
                (item) => item.PredReqId === model_2Info.PredReqId_2
              )[0] &&
              model_2PredictionRequestList.filter(
                (item) => item.PredReqId === model_2Info.PredReqId_2
              )[0]['FromTime']
            ? moment(
                model_2PredictionRequestList.filter(
                  (item) => item.PredReqId === model_2Info.PredReqId_2
                )[0]['FromTime']
              )
                .utc()
                .format('YYYY-MM-DD HH:mm:ss')
            : null,
        ToTime:
          model_2Info && model_2Info.FromTime && model_2Info.FromTime !== undefined
            ? moment(model_2Info.ToTime).utc().format('YYYY-MM-DD HH:mm:ss')
            : model_2PredictionRequestList &&
              model_2PredictionRequestList.filter(
                (item) => item.PredReqId === model_2Info.PredReqId_2
              ) &&
              model_2PredictionRequestList.filter(
                (item) => item.PredReqId === model_2Info.PredReqId_2
              )[0] &&
              model_2PredictionRequestList.filter(
                (item) => item.PredReqId === model_2Info.PredReqId_2
              )[0]['ToTime']
            ? moment(
                model_2PredictionRequestList.filter(
                  (item) => item.PredReqId === model_2Info.PredReqId_2
                )[0]['ToTime']
              )
                .utc()
                .format('YYYY-MM-DD HH:mm:ss')
            : null
      },
      comments: formObject.comments && formObject.comments !== undefined ? formObject.comments : ''
    };
    this.bookmarkCreate(final, model_1Info, model_2Info);
  };

  onCheck1 = (key, value) => {
    let model_1Info = this.model_1Ref.current.getFieldsValue();
    model_1Info = {
      ...model_1Info,
      [key]: value
    };
    this.model_1Ref.current.setFieldsValue(...model_1Info);
    this.setState({ model_1Info });
  };
  onCheck2 = (key, value) => {
    let model_2Info = this.model_2Ref.current.getFieldsValue();
    model_2Info = {
      ...model_2Info,
      [key]: value
    };
    this.model_2Ref.current.setFieldsValue(...model_2Info);
    this.setState({ model_2Info });
  };
  onPrediction_1RequestSubmit = (values) => {
    if ((values && values.PredReqId_1) || values.FromTime || values.ToTime) {
      let formObject = this.dataRef.current.getFieldsValue();
      formObject = { ...formObject, ...values };
      this.dataRef.current && this.dataRef.current.setFieldsValue({ ...formObject });
      this.setState({ formObject, predictionModel_1: false });
    } else {
      message.error(this.translation('Please Input fileds'));
    }
  };
  onPrediction_2RequestSubmit = (values) => {
    if ((values && values.PredReqId_2) || values.FromTime || values.ToTime) {
      let formObject = this.dataRef.current.getFieldsValue();
      formObject = { ...formObject, ...values };
      this.dataRef.current && this.dataRef.current.setFieldsValue({ ...formObject });
      this.setState({ formObject, predictionModel_2: false });
    } else {
      message.error(this.translation('Please Input fileds'));
    }
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    const {
      formObject,
      ModelsList,
      model_1PredictionRequestList,
      model_2PredictionRequestList,
      predictionModel_1,
      predictionModel_2,
      model_1Info,
      metricsData,
      // MetricResultColumns,
      Model_1MetricValuesData,
      Model_1MetricImagesData,
      Model_2MetricValuesData,
      Model_2MetricImagesData,
      showResults,
      // Model_1PredHistoryColumns,
      // Model_2PredHistoryColumns,
      model_2Info,
      NewPredictionModel_2,
      NewPredictionModel_1,
      selectedRowKeys1,
      selectedRowKeys2,
      filterdArrayOfModels_2,
      filterdArrayOfModels_1,
      BookmarkId
      // ModelDeployHistoryData_1,
      // ModelDeployHistoryData_2
    } = this.state;
    const Model_1PredHistoryColumns = [
      {
        title: this.translation('Predicted Time From'),
        dataIndex: 'FromTime',
        key: 'FromTime',
        ...this.getColumnSearchProps('FromTime'),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Predicted Time To'),
        dataIndex: 'ToTime',
        key: 'ToTime',
        ...this.getColumnSearchProps('ToTime'),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Status'),
        dataIndex: 'Status',
        key: 'Status'
      }
    ];
    const Model_2PredHistoryColumns = [
      {
        title: this.translation('Predicted Time From'),
        dataIndex: 'FromTime',
        key: 'FromTime',
        ...this.getColumnSearchProps('FromTime'),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Predicted Time To'),
        dataIndex: 'ToTime',
        key: 'ToTime',
        ...this.getColumnSearchProps('ToTime'),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Status'),
        dataIndex: 'Status',
        key: 'Status'
      }
    ];
    const MetricResultColumns = [
      {
        title: this.translation('Metrics'),
        dataIndex: 'MetricCode',
        key: 'MetricCode'
      },
      {
        title: this.translation('Value'),
        dataIndex: 'MetricValue',
        key: 'MetricValue'
      }
    ];
    const validateMessages = {
      required: this.translation('${label}') + this.translation('is required!')
    };
    const rowSelection1 = {
      selectedRowKeys: selectedRowKeys1,
      onChange: (selectedRowKeys1) => {
        let model_1Info = this.model_1Ref.current && this.model_1Ref.current.getFieldsValue();
        model_1Info = {
          ...model_1Info,
          PredReqId_1: selectedRowKeys1 && selectedRowKeys1[0] ? selectedRowKeys1[0] : ''
        };
        this.model_1Ref.current &&
          this.model_1Ref.current.setFieldsValue({
            PredReqId_1: selectedRowKeys1 && selectedRowKeys1[0] ? selectedRowKeys1[0] : ''
          });
        this.setState({ model_1Info, selectedRowKeys1 });
      },
      getCheckboxProps: (record) => ({
        //   disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.PredReqId
      })
    };
    const rowSelection2 = {
      selectedRowKeys: selectedRowKeys2,
      onChange: (selectedRowKeys2) => {
        let model_2Info = this.model_2Ref.current && this.model_2Ref.current.getFieldsValue();
        model_2Info = {
          ...model_2Info,
          PredReqId_2: selectedRowKeys2 && selectedRowKeys2[0] ? selectedRowKeys2[0] : ''
        };
        this.model_2Ref.current &&
          this.model_2Ref.current.setFieldsValue({
            PredReqId_2: selectedRowKeys2 && selectedRowKeys2[0] ? selectedRowKeys2[0] : ''
          });
        this.setState({ model_2Info, selectedRowKeys2 });
      },
      getCheckboxProps: (record) => ({
        //   disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.PredReqId
      })
    };
    let path = history.location.pathname.split('/rubus/RbsAnalytics/ModelComparision/');
    let Id = path && path[1] && base64_decode(path[1]) ? base64_decode(path[1]) : null;
    const formItemLayout = { width: '100%', flex: '50%' };

    return (
      <StyledComponent
        theme={themeSettings}
        style={{
          padding: '50px 30px',
          minHeight: window.innerHeight - 143
        }}
      >
        <StyledSubHeading theme={themeSettings}>
          {this.translation('Model Details')}
        </StyledSubHeading>
        <StyledForm
          name="ModelDetails"
          ref={this.dataRef}
          autoComplete="off"
          initialValues={formObject}
          theme={themeSettings}
          layout="horizontal"
          labelCol={{ flex: '200px' }}
          labelAlign="right"
          onFinish={this.onFormSubmit}
          onFinishFailed={this.onFinishFailed}
          labelWrap
          validateMessages={validateMessages}
          wrapperCol={{ flex: 1 }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item
              label={this.translation('Model 1')}
              name="ModelName_1"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Select
                style={{ width: '70%' }}
                disabled
                suffixIcon={
                  <ApartmentOutlined
                    onClick={() => {
                      (Id && Id !== null) || (BookmarkId && BookmarkId !== '')
                        ? this.setState({ predictionModel_1: false })
                        : this.setState({ predictionModel_1: true });
                    }}
                  ></ApartmentOutlined>
                }
              >
                {ModelsList &&
                  ModelsList.map((item) => {
                    return (
                      <Option key={item.ModelId} value={item.ModelId}>
                        {item.ModelName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Form.Item
              label={this.translation('Model 2')}
              name="ModelName_2"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Select
                style={{ width: '70%' }}
                disabled
                suffixIcon={
                  <ApartmentOutlined
                    onClick={() => {
                      (Id && Id !== null) || (BookmarkId && BookmarkId !== '')
                        ? this.setState({ predictionModel_2: false })
                        : this.setState({ predictionModel_2: true });
                    }}
                  ></ApartmentOutlined>
                }
              >
                {ModelsList &&
                  Array.isArray(ModelsList) &&
                  ModelsList.map((item) => {
                    return (
                      <Option key={item.ModelId} value={item.ModelId}>
                        {item.ModelName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label={this.translation('Comments')} name="comments" style={formItemLayout}>
              <TextArea
                style={{ width: '70%' }}
                onChange={(e) => {
                  let formObject = this.dataRef.current.getFieldsValue();
                  formObject = {
                    ...formObject,
                    comments: e.target.value
                  };
                  this.dataRef.current.getFieldsValue({ ...formObject });
                  this.setState({ formObject });
                }}
              ></TextArea>
            </Form.Item>
            <Form.Item style={formItemLayout}></Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              {model_1Info && model_1Info.PredReqId_1 && model_2Info && model_2Info.PredReqId_2 ? (
                Id && Id !== null ? (
                  <Button
                    style={{ marginRight: 8 }}
                    theme={themeSettings}
                    type="primary"
                    onClick={() => this.onFormSaveComapare()}
                    data-testid="submitButton"
                  >
                    {this.translation('Save')}
                  </Button>
                ) : (
                  <Button
                    style={{ marginRight: 8 }}
                    theme={themeSettings}
                    type="primary"
                    onClick={() => this.onFormCompare()}
                    data-testid="submitButton"
                  >
                    {this.translation('Compare')}
                  </Button>
                )
              ) : null}
            </Form.Item>
            {history.location.pathname.split('/rubus/RbsAnalytics/ModelComparision/') &&
            history.location.pathname.split('/rubus/RbsAnalytics/ModelComparision/')[1] &&
            history.location.pathname.split('/rubus/RbsAnalytics/ModelComparision/')[1] !==
              undefined ? null : (
              <Form.Item>
                <Button
                  style={{ marginRight: 8 }}
                  theme={themeSettings}
                  type="primary"
                  disabled={BookmarkId && BookmarkId !== '' ? true : false}
                  onClick={() => this.onFormSaveComapare()}
                  data-testid="submitButton"
                >
                  {this.translation('Save & Compare')}
                </Button>
              </Form.Item>
            )}
            <Form.Item>
              <Button
                style={{ marginRight: 8 }}
                theme={themeSettings}
                type="primary"
                onClick={() => history.push('/rubus/RbsAnalytics/ModelComparisionList')}
                data-testid="submitButton"
              >
                {this.translation('Cancel')}
              </Button>
            </Form.Item>
            {/* <Form.Item>
              <Popconfirm
                placement="topLeft"
                title="Sure to cancel?"
                onConfirm={() => {
                  history.goBack();
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{ marginRight: 8 }}
                  theme={themeSettings}
                  size="middle"
                >
                  {this.translation('Cancel')}
                </Button>
              </Popconfirm>
            </Form.Item> */}
          </div>
        </StyledForm>
        {/* </div> */}
        {/* ============================================ Model_1 popup============================================ */}

        <StyleModal
          width="1500px"
          title=""
          open={predictionModel_1}
          onCancel={() => {
            this.setState({
              predictionModel_1: false
            });
          }}
          closable
          theme={themeSettings}
          footer={[
            <>
              <Button
                key="back"
                onClick={() => {
                  this.setState({
                    predictionModel_1: false
                  });
                  // let formObject = {
                  //   ...formObject,
                  //   ModelName_1: "",
                  // };
                  // let model_1Info = {
                  //   ModelName_1: "",
                  //   PredReqId_1: "",
                  //   FromTime: "",
                  //   ToTime: "",
                  // };
                  // this.setState({
                  //   model_1Info: { ...model_1Info },
                  //   predictionModel_1: false,
                  //   formObject: { ...formObject },
                  //   selectedRowKeys1: [],
                  // });
                  // this.model_1Ref.current &&
                  //   this.model_1Ref.current.setFieldsValue({ ...model_1Info });
                  // this.dataRef.current &&
                  //   this.dataRef.current.setFieldsValue({ ...formObject });
                  if ((Id && Id !== null) || (BookmarkId && BookmarkId !== '')) {
                    this.setState({
                      predictionModel_1: false
                    });
                  } else {
                    let formObject = {};
                    formObject = {
                      ...formObject,
                      ModelName_1: ''
                    };
                    let model_1Info = {
                      ModelName_1: '',
                      PredReqId_1: undefined,
                      FromTime: '',
                      ToTime: ''
                    };

                    this.setState({
                      model_1Info: model_1Info,
                      predictionModel_1: false,
                      formObject: { ...formObject },
                      selectedRowKeys1: [],
                      filterdArrayOfModels_2: ModelsList
                    });

                    this.model_1Ref.current &&
                      this.model_1Ref.current.setFieldsValue({
                        ...model_1Info
                      });
                    this.dataRef.current && this.dataRef.current.setFieldsValue({ ...formObject });
                  }
                }}
              >
                {model_1Info && model_1Info.PredReqId
                  ? this.translation('Close')
                  : this.translation('Cancel')}
              </Button>

              <Button
                key="submit"
                type="primary"
                // disabled={(model_1Info &&
                //   model_1Info.PredReqId_1) || (model_1Info &&
                //     model_1Info.FromTime && model_1Info.ToTime) ? false:true}
                onClick={() => this.model_1Button.current && this.model_1Button.current.click()}
              >
                {this.translation('Save')}
              </Button>
            </>
          ]}
        >
          <StyledForm
            disabled={(Id && Id !== null) || (BookmarkId && BookmarkId !== '') ? true : false}
            name="Prediction Request"
            ref={this.model_1Ref}
            autoComplete="off"
            initialValues={model_1Info || {}}
            theme={themeSettings}
            layout="horizontal"
            labelCol={{ flex: '200px' }}
            labelAlign="right"
            onFinish={this.onPrediction_1RequestSubmit}
            onFinishFailed={this.onFinishFailed}
            labelWrap
            validateMessages={validateMessages}
            wrapperCol={{ flex: 1 }}
          >
            <StyledBox>
              <StyledBoxLabel>{this.translation('Model Details')}</StyledBoxLabel>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginTop: '50px',
                  width: '1500px'
                }}
              >
                <Form.Item
                  label={this.translation('Model 1')}
                  name="ModelName_1"
                  rules={[{ required: true }]}
                  style={formItemLayout}
                >
                  <Select
                    style={{ width: '70%' }}
                    onChange={(value) => {
                      this.onHandleChange('ModelName_1', value, model_1Info);
                    }}
                  >
                    {filterdArrayOfModels_1 &&
                      filterdArrayOfModels_1.map((item) => {
                        return (
                          <Option key={item.ModelId} value={item.ModelId}>
                            {item.ModelName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item name="PredReqId_1" style={formItemLayout}></Form.Item>
              </div>

              {model_1Info && model_1Info.ModelName_1 && model_1Info.ModelName_1 !== undefined ? (
                <StyledTabs
                  theme={themeSettings}
                  type="card"
                  onTabClick={(value) => {
                    if (value === '2') {
                      if (model_1Info && model_1Info.PredReqId_1) {
                        this.model_1tab2Confirm.current && this.model_1tab2Confirm.current.click();
                      } else {
                        this.getModel_1MetaData(model_1Info && model_1Info.ModelName_1);
                        // this.setState({ activekey_1: value });
                      }
                    } else if (value === '1') {
                      if (model_1Info && (model_1Info.FromTime || model_1Info.ToTime)) {
                        this.model_1tab1Confirm.current && this.model_1tab1Confirm.current.click();
                        this.setState({ model_1PredictionRequestList: [] });
                      } else {
                        this.onHandleChange(
                          'ModelName_1',
                          model_1Info && model_1Info.ModelName_1,
                          model_1Info
                        );
                        // this.setState({ activekey_1: value });
                      }
                    }
                  }}
                  className="TabsText"
                  // activeKey={activekey_1}
                >
                  <TabPane tab={this.translation('Existing Prediction')} key="1">
                    <StyledTable
                      rowSelection={{
                        type: 'radio',
                        ...rowSelection1
                      }}
                      theme={themeSettings}
                      dataSource={
                        model_1PredictionRequestList && Array.isArray(model_1PredictionRequestList)
                          ? model_1PredictionRequestList
                          : []
                      }
                      columns={Model_1PredHistoryColumns}
                    />
                  </TabPane>

                  <TabPane
                    tab={this.translation('New Prediction')}
                    key="2"
                    disabled={
                      (Id && Id !== null) || (BookmarkId && BookmarkId !== '') ? true : false
                    }
                  >
                    {NewPredictionModel_1 && NewPredictionModel_1 === true ? (
                      <>
                        <StyledBox>
                          <StyledBoxLabel>{this.translation('Deployment Details')}</StyledBoxLabel>
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              marginTop: '50px'
                            }}
                          >
                            <Form.Item
                              label={this.translation('Deployed Date')}
                              name="CreatedTime"
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Input disabled style={{ width: '70%' }}></Input>
                            </Form.Item>
                            <Form.Item
                              label={this.translation('Virtual Environment')}
                              name="Virtualenv"
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Input disabled style={{ width: '70%' }}></Input>
                            </Form.Item>
                          </div>
                        </StyledBox>
                        <StyledBox>
                          <StyledBoxLabel>{this.translation('Prediction Details')}</StyledBoxLabel>
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              marginTop: '50px'
                            }}
                          >
                            <Form.Item
                              label={this.translation('Train Period')}
                              name="TrainPeriodFrom"
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Input.Group compact>
                                <Form.Item name="TrainPeriodFrom" rules={[{ required: true }]}>
                                  <Input style={{ textAlign: 'center', width: 190 }} disabled />
                                </Form.Item>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    style={{
                                      width: 50,
                                      borderLeft: 0,
                                      borderRight: 0,
                                      pointerEvents: 'none'
                                    }}
                                    placeholder={this.translation('To')}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item name="TrainPeriodTo" rules={[{ required: true }]}>
                                  <Input
                                    style={{
                                      textAlign: 'center',
                                      borderLeft: 0,
                                      width: 190
                                    }}
                                    disabled
                                  />
                                </Form.Item>
                              </Input.Group>
                            </Form.Item>
                            <Form.Item
                              label={this.translation('Evaluation Period')}
                              name="EvalPeriodFrom"
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Input.Group compact>
                                <Form.Item name="EvalPeriodFrom">
                                  <Input style={{ textAlign: 'center', width: 190 }} disabled />
                                </Form.Item>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    style={{
                                      width: 50,
                                      borderLeft: 0,
                                      borderRight: 0,
                                      pointerEvents: 'none'
                                    }}
                                    placeholder={this.translation('To')}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item name="EvalPeriodTo">
                                  <Input
                                    style={{
                                      textAlign: 'center',
                                      borderLeft: 0,
                                      width: 190
                                    }}
                                    disabled
                                  />
                                </Form.Item>
                              </Input.Group>
                            </Form.Item>
                            <Form.Item
                              label={this.translation('Future Prediction')}
                              name="FuturePredRangeFrom"
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Input.Group compact>
                                <Form.Item name="FuturePredRangeFrom">
                                  <Input style={{ textAlign: 'center', width: 85 }} disabled />
                                </Form.Item>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    style={{
                                      width: 50,
                                      borderLeft: 0,
                                      borderRight: 0,
                                      pointerEvents: 'none'
                                    }}
                                    placeholder={this.translation('To')}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item name="FuturePredRangeTo">
                                  <Input
                                    style={{
                                      textAlign: 'center',
                                      borderLeft: 0,
                                      width: 85
                                    }}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    style={{
                                      width: 100,
                                      borderLeft: 0,
                                      borderRight: 0,
                                      pointerEvents: 'none'
                                    }}
                                    placeholder={this.translation('UOM')}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item name="FuturePredRangeUom">
                                  <Input
                                    style={{
                                      textAlign: 'center',
                                      borderLeft: 0,
                                      width: 110
                                    }}
                                    disabled
                                  />
                                </Form.Item>
                              </Input.Group>
                            </Form.Item>
                            <Form.Item
                              label={this.translation('Look back period')}
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Input.Group compact>
                                <Form.Item name="LookBackPeriod">
                                  <Input style={{ textAlign: 'center', width: 200 }} disabled />
                                </Form.Item>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    style={{
                                      width: 100,
                                      borderLeft: 0,
                                      borderRight: 0,
                                      pointerEvents: 'none'
                                    }}
                                    placeholder={this.translation('UOM')}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item name="LookBackPeriodUom">
                                  <Input
                                    style={{
                                      textAlign: 'center',
                                      borderLeft: 0,
                                      width: 130
                                    }}
                                    disabled
                                  />
                                </Form.Item>
                              </Input.Group>
                            </Form.Item>
                            <Form.Item
                              name="FromTime"
                              label={this.translation('From Time')}
                              // rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <DatePicker
                                // disabled={
                                //   this.model_1Ref.current.getFieldValue(
                                //     "PredReqId_1"
                                //   ) &&
                                //     this.model_1Ref.current.getFieldValue(
                                //       "PredReqId_1"
                                //     ) !== undefined
                                //     ? true
                                //     : false
                                // }
                                style={{ width: '70%' }}
                                onChange={(date) => {
                                  let model_1Info =
                                    this.model_1Ref.current &&
                                    this.model_1Ref.current.getFieldsValue();
                                  model_1Info = {
                                    ...model_1Info,
                                    FromTime: date
                                  };
                                  this.model_1Ref.current &&
                                    this.model_1Ref.current.setFieldsValue({
                                      ...model_1Info
                                    });
                                  this.setState({ model_1Info });
                                }}
                                allowClear={false}
                                showTime={{
                                  defaultValue: moment('00:00:00', 'HH:mm:ss')
                                }}
                                format="YYYY-MM-DD HH:mm:ss"
                                onOk={(date) => this.handleDateInputChange(date)}
                                disabledDate={(d) =>
                                  //     this.formHeader.current.getFieldValue("EndTime")
                                  //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                                  //         ))
                                  !d ||
                                  d.isAfter(
                                    this.model_1Ref.current &&
                                      this.model_1Ref.current !== null &&
                                      this.model_1Ref.current.getFieldValue() &&
                                      this.model_1Ref.current.getFieldValue('ToTime')
                                      ? moment(
                                          this.model_1Ref.current.getFieldValue() &&
                                            this.model_1Ref.current.getFieldValue('ToTime')
                                        ).format('YYYY-MM-DD HH:mm:ss')
                                      : ''
                                  )
                                }
                                disabledTime={(e) =>
                                  this.getDisabledHoursTask(
                                    moment(e, 'YYYY-MM-DD HH:mm:ss'),
                                    'ToTime'
                                  )
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              name="ToTime"
                              label={this.translation('To Time')}
                              // rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <DatePicker
                                // disabled={
                                //   this.model_1Ref.current.getFieldValue(
                                //     "PredReqId_1"
                                //   ) &&
                                //     this.model_1Ref.current.getFieldValue(
                                //       "PredReqId_1"
                                //     ) !== undefined
                                //     ? true
                                //     : false
                                // }
                                style={{ width: '70%' }}
                                onChange={(date) => {
                                  let model_1Info =
                                    this.model_1Ref.current &&
                                    this.model_1Ref.current.getFieldsValue();
                                  model_1Info = {
                                    ...model_1Info,
                                    ToTime: date
                                  };
                                  this.model_1Ref.current &&
                                    this.model_1Ref.current.setFieldsValue({
                                      ...model_1Info
                                    });
                                  this.setState({ model_1Info });
                                }}
                                allowClear={false}
                                showTime={{
                                  defaultValue: moment('00:00:00', 'HH:mm:ss')
                                }}
                                format="YYYY-MM-DD HH:mm:ss"
                                onOk={(date) => this.handleDateInputChange(date)}
                                disabledDate={(d) =>
                                  //     this.formHeader.current.getFieldValue("EndTime")
                                  //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                                  //         ))
                                  !d ||
                                  d.isBefore(
                                    this.model_1Ref.current &&
                                      this.model_1Ref.current !== null &&
                                      this.model_1Ref.current.getFieldValue() &&
                                      this.model_1Ref.current.getFieldValue('FromTime')
                                      ? moment(
                                          this.model_1Ref.current.getFieldValue() &&
                                            this.model_1Ref.current.getFieldValue('FromTime')
                                        ).format('YYYY-MM-DD HH:mm:ss')
                                      : ''
                                  )
                                }
                                disabledTime={(e) =>
                                  this.getDisabledHoursTask(
                                    moment(e, 'YYYY-MM-DD HH:mm:ss'),
                                    'ToTime'
                                  )
                                }
                              />
                            </Form.Item>

                            <Form.Item
                              name="Metrics"
                              label={this.translation('Metrics')}
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Tree
                                // disabled={
                                //   this.model_1Ref.current.getFieldValue(
                                //     "PredReqId_1"
                                //   ) &&
                                //     this.model_1Ref.current.getFieldValue(
                                //       "PredReqId_1"
                                //     ) !== undefined
                                //     ? true
                                //     : false
                                // }
                                checkable
                                onCheck={(e) => this.onCheck1('Metrics', e)}
                                switcherIcon={<DownOutlined />}
                                checkedKeys={
                                  model_1Info && model_1Info['Metrics']
                                    ? model_1Info['Metrics']
                                    : ''
                                }
                              >
                                {this.renderTreeNodes(this.translation(metricsData))}
                              </Tree>
                            </Form.Item>
                            <Form.Item name="PredReqId_2" style={formItemLayout}></Form.Item>
                            <Form.Item>
                              <Button
                                ref={this.predictionButtonRef}
                                htmlType="submit"
                                style={{ display: 'none' }}
                              ></Button>
                            </Form.Item>
                          </div>
                        </StyledBox>
                      </>
                    ) : null}
                  </TabPane>
                </StyledTabs>
              ) : null}

              <Form.Item>
                <Button
                  ref={this.model_1Button}
                  htmlType="submit"
                  style={{ display: 'none' }}
                ></Button>
              </Form.Item>
            </StyledBox>
          </StyledForm>
          {/* </div> */}
          {model_1Info && model_1Info.ModelName_1 && model_1Info.ModelName_1 !== undefined ? (
            <div>
              <Popconfirm
                title={this.translation('Are you sure to clear the new prediction ?')}
                onConfirm={() => {
                  let model_1Info = this.model_1Ref.current.getFieldsValue();
                  model_1Info = {
                    ...model_1Info,
                    FromTime: '',
                    ToTime: ''
                  };
                  this.onHandleChange(
                    'ModelName_1',
                    model_1Info && model_1Info.ModelName_1,
                    model_1Info
                  );
                  this.model_1Ref.current &&
                    this.model_1Ref.current.setFieldsValue({ ...model_1Info });
                  this.setState({ model_1Info });
                }}
                onCancel={() => {}}
                okText={this.translation('Yes')}
                cancelText={this.translation('No')}
              >
                <Form.Item>
                  <Button ref={this.model_1tab1Confirm} style={{ display: 'none' }}></Button>
                </Form.Item>
              </Popconfirm>

              <Popconfirm
                title={this.translation('Are you sure to clear the existing prediction ?')}
                onConfirm={() => {
                  let model_1Info = this.model_1Ref.current.getFieldsValue();
                  model_1Info = {
                    ...model_1Info,
                    PredReqId_1: undefined
                  };
                  this.setState({ model_1Info, selectedRowKeys1: [] });
                  this.getModel_1MetaData(model_1Info && model_1Info.ModelName_1);
                  this.model_1Ref.current &&
                    this.model_1Ref.current.setFieldsValue({ ...model_1Info });
                }}
                onCancel={() => {}}
                okText={this.translation('Yes')}
                cancelText={this.translation('No')}
              >
                <Form.Item>
                  <Button ref={this.model_1tab2Confirm} style={{ display: 'none' }}></Button>
                </Form.Item>
              </Popconfirm>
            </div>
          ) : null}
        </StyleModal>

        {/* ============================================ Model_2 popup============================================ */}

        <StyleModal
          width="1500px"
          title=""
          open={predictionModel_2}
          onCancel={() => {
            this.setState({
              predictionModel_2: false
            });
          }}
          closable
          theme={themeSettings}
          footer={[
            <>
              <Button
                key="back"
                onClick={() => {
                  this.setState({
                    predictionModel_2: false
                  });
                  // let formObject = {
                  //   ...formObject,
                  //   ModelName_2: "",
                  // };
                  // let model_2Info = {
                  //   ModelName_2: "",
                  //   PredReqId_2: "",
                  //   FromTime: "",
                  //   ToTime: "",
                  // };
                  // this.setState({
                  //   model_2Info: { ...model_2Info },
                  //   predictionModel_2: false,
                  //   formObject: { ...formObject },
                  //   selectedRowKeys2: [],
                  // });
                  // this.model_2Ref.current &&
                  //   this.model_2Ref.current.setFieldsValue({ ...model_2Info });
                  // this.dataRef.current &&
                  //   this.dataRef.current.setFieldsValue({ ...formObject });
                  if ((Id && Id !== null) || (BookmarkId && BookmarkId !== '')) {
                    this.setState({
                      predictionModel_2: false
                    });
                  } else {
                    let formObject = {
                      // ...formObject,
                      ModelName_2: ''
                    };
                    let model_2Info = {
                      ModelName_2: '',
                      PredReqId_2: undefined,
                      FromTime: '',
                      ToTime: ''
                    };

                    this.setState({
                      model_2Info: model_1Info,
                      predictionModel_2: false,
                      formObject: { ...formObject },
                      selectedRowKeys2: [],
                      filterdArrayOfModels_1: ModelsList
                    });

                    this.model_2Ref.current &&
                      this.model_2Ref.current.setFieldsValue({
                        ...model_2Info
                      });
                    this.dataRef.current && this.dataRef.current.setFieldsValue({ ...formObject });
                  }
                }}
              >
                {' '}
                {model_1Info && model_1Info.PredReqId
                  ? this.translation('Close')
                  : this.translation('Cancel')}
              </Button>

              <Button
                key="submit"
                type="primary"
                // disabled={ (model_2Info &&
                //   model_2Info.PredReqId_2) || (model_2Info &&
                //     model_2Info.FromTime && model_2Info.ToTime) ? false : true }
                onClick={() => this.model_2Button.current && this.model_2Button.current.click()}
              >
                {this.translation('Save')}
              </Button>
            </>
          ]}
        >
          <StyledForm
            name="Prediction Request"
            disabled={(Id && Id !== null) || (BookmarkId && BookmarkId !== '') ? true : false}
            ref={this.model_2Ref}
            autoComplete="off"
            initialValues={model_2Info || {}}
            theme={themeSettings}
            layout="horizontal"
            labelCol={{ flex: '200px' }}
            labelAlign="right"
            onFinish={this.onPrediction_2RequestSubmit}
            onFinishFailed={this.onFinishFailed}
            labelWrap
            validateMessages={validateMessages}
            wrapperCol={{ flex: 1 }}
          >
            <StyledBox>
              <StyledBoxLabel>{this.translation('Model Details')}</StyledBoxLabel>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginTop: '50px',
                  width: '1500px'
                }}
              >
                <Form.Item
                  label={this.translation('Model 2')}
                  name="ModelName_2"
                  rules={[{ required: true }]}
                  style={formItemLayout}
                >
                  <Select
                    style={{ width: '70%' }}
                    onChange={(value) => {
                      this.onHandleChange('ModelName_2', value, model_2Info);
                    }}
                  >
                    {filterdArrayOfModels_2 &&
                      filterdArrayOfModels_2.map((item) => {
                        return (
                          <Option key={item.ModelId} value={item.ModelId}>
                            {item.ModelName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item name="PredReqId_2" style={formItemLayout}></Form.Item>
              </div>

              {model_2Info && model_2Info.ModelName_2 && model_2Info.ModelName_2 !== undefined ? (
                <StyledTabs
                  theme={themeSettings}
                  type="card"
                  className="TabsText"
                  // defaultActiveKey="1"
                  onTabClick={(value) => {
                    if (value === '2') {
                      if (model_2Info && model_2Info.PredReqId_2) {
                        this.model_2tab2Confirm.current && this.model_2tab2Confirm.current.click();
                      } else {
                        this.getModel_2MetaData(model_2Info && model_2Info.ModelName_2);
                        // this.setState({ activekey_1: value });
                      }
                    } else if (value === '1') {
                      if (model_2Info && (model_2Info.FromTime || model_2Info.ToTime)) {
                        this.model_2tab1Confirm.current && this.model_2tab1Confirm.current.click();
                        this.setState({ model_2PredictionRequestList: [] });
                      } else {
                        this.onHandleChange(
                          'ModelName_2',
                          model_2Info && model_2Info.ModelName_2,
                          model_1Info
                        );
                        // this.setState({ activekey_1: value });
                      }
                    }
                  }}
                >
                  <TabPane tab={this.translation('Existing Prediction')} key="1">
                    <StyledTable
                      //   pagination={pagination}
                      rowSelection={{
                        type: 'radio',
                        ...rowSelection2
                      }}
                      onChange={this.handleTableChange}
                      autoResetPage={false}
                      theme={themeSettings}
                      dataSource={
                        model_2PredictionRequestList && Array.isArray(model_2PredictionRequestList)
                          ? model_2PredictionRequestList
                          : []
                      }
                      columns={Model_2PredHistoryColumns}
                    ></StyledTable>
                  </TabPane>
                  <TabPane
                    tab={this.translation('New Prediction')}
                    key="2"
                    disabled={
                      (Id && Id !== null) || (BookmarkId && BookmarkId !== '') ? true : false
                    }
                  >
                    {NewPredictionModel_2 && NewPredictionModel_2 === true ? (
                      <>
                        <StyledBox>
                          <StyledBoxLabel>{this.translation('Deployment Details')}</StyledBoxLabel>
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              marginTop: '50px'
                            }}
                          >
                            <Form.Item
                              label={this.translation('Deployed Date')}
                              name="CreatedTime"
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Input disabled style={{ width: '70%' }}></Input>
                            </Form.Item>
                            <Form.Item
                              label={this.translation('Virtual Environment')}
                              name="Virtualenv"
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Input disabled style={{ width: '70%' }}></Input>
                            </Form.Item>
                          </div>
                        </StyledBox>
                        <StyledBox>
                          <StyledBoxLabel>{this.translation('Prediction Details')}</StyledBoxLabel>
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              marginTop: '50px'
                            }}
                          >
                            <Form.Item
                              label={this.translation('Train Period')}
                              name="TrainPeriodFrom"
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Input.Group compact>
                                <Form.Item name="TrainPeriodFrom" rules={[{ required: true }]}>
                                  <Input style={{ textAlign: 'center', width: 190 }} disabled />
                                </Form.Item>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    style={{
                                      width: 50,
                                      borderLeft: 0,
                                      borderRight: 0,
                                      pointerEvents: 'none'
                                    }}
                                    placeholder={this.translation('To')}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item name="TrainPeriodTo" rules={[{ required: true }]}>
                                  <Input
                                    style={{
                                      textAlign: 'center',
                                      borderLeft: 0,
                                      width: 190
                                    }}
                                    disabled
                                  />
                                </Form.Item>
                              </Input.Group>
                            </Form.Item>
                            <Form.Item
                              label={this.translation('Evaluation Period')}
                              name="EvalPeriodFrom"
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Input.Group compact>
                                <Form.Item name="EvalPeriodFrom">
                                  <Input style={{ textAlign: 'center', width: 190 }} disabled />
                                </Form.Item>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    style={{
                                      width: 50,
                                      borderLeft: 0,
                                      borderRight: 0,
                                      pointerEvents: 'none'
                                    }}
                                    placeholder={this.translation('To')}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item name="EvalPeriodTo">
                                  <Input
                                    style={{
                                      textAlign: 'center',
                                      borderLeft: 0,
                                      width: 190
                                    }}
                                    disabled
                                  />
                                </Form.Item>
                              </Input.Group>
                            </Form.Item>
                            <Form.Item
                              label={this.translation('Future Prediction')}
                              name="FuturePredRangeFrom"
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Input.Group compact>
                                <Form.Item name="FuturePredRangeFrom">
                                  <Input style={{ textAlign: 'center', width: 85 }} disabled />
                                </Form.Item>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    style={{
                                      width: 50,
                                      borderLeft: 0,
                                      borderRight: 0,
                                      pointerEvents: 'none'
                                    }}
                                    placeholder={this.translation('To')}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item name="FuturePredRangeTo">
                                  <Input
                                    style={{
                                      textAlign: 'center',
                                      borderLeft: 0,
                                      width: 85
                                    }}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    style={{
                                      width: 100,
                                      borderLeft: 0,
                                      borderRight: 0,
                                      pointerEvents: 'none'
                                    }}
                                    placeholder={this.translation('UOM')}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item name="FuturePredRangeUom">
                                  <Input
                                    style={{
                                      textAlign: 'center',
                                      borderLeft: 0,
                                      width: 110
                                    }}
                                    disabled
                                  />
                                </Form.Item>
                              </Input.Group>
                            </Form.Item>
                            <Form.Item
                              label={this.translation('Look back period')}
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Input.Group compact>
                                <Form.Item name="LookBackPeriod">
                                  <Input style={{ textAlign: 'center', width: 200 }} disabled />
                                </Form.Item>
                                <Form.Item rules={[{ required: true }]}>
                                  <Input
                                    style={{
                                      width: 100,
                                      borderLeft: 0,
                                      borderRight: 0,
                                      pointerEvents: 'none'
                                    }}
                                    placeholder={this.translation('UOM')}
                                    disabled
                                  />
                                </Form.Item>
                                <Form.Item name="LookBackPeriodUom">
                                  <Input
                                    style={{
                                      textAlign: 'center',
                                      borderLeft: 0,
                                      width: 130
                                    }}
                                    disabled
                                  />
                                </Form.Item>
                              </Input.Group>
                            </Form.Item>
                            <Form.Item
                              name="FromTime"
                              label={this.translation('From Time')}
                              // rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <DatePicker
                                // disabled={
                                //   this.model_2Ref.current.getFieldValue(
                                //     "PredReqId_2"
                                //   ) &&
                                //     this.model_2Ref.current.getFieldValue(
                                //       "PredReqId_2"
                                //     ) !== undefined
                                //     ? true
                                //     : false
                                // }
                                style={{ width: '70%' }}
                                allowClear={false}
                                showTime={{
                                  defaultValue: moment('00:00:00', 'HH:mm:ss')
                                }}
                                format="YYYY-MM-DD HH:mm:ss"
                                onOk={(date) => this.handleDateInputChange(date)}
                                onChange={(date) => {
                                  let model_2Info =
                                    this.model_2Ref.current &&
                                    this.model_2Ref.current.getFieldsValue();
                                  model_2Info = {
                                    ...model_2Info,
                                    FromTime: date
                                  };
                                  this.model_2Ref.current &&
                                    this.model_2Ref.current.setFieldsValue({
                                      ...model_2Info
                                    });
                                  this.setState({ model_2Info });
                                }}
                                disabledDate={(d) =>
                                  //     this.formHeader.current.getFieldValue("EndTime")
                                  //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                                  //         ))
                                  !d ||
                                  d.isAfter(
                                    this.model_2Ref.current &&
                                      this.model_2Ref.current !== null &&
                                      this.model_2Ref.current.getFieldValue() &&
                                      this.model_2Ref.current.getFieldValue('ToTime')
                                      ? moment(
                                          this.model_2Ref.current.getFieldValue() &&
                                            this.model_2Ref.current.getFieldValue('ToTime')
                                        ).format('YYYY-MM-DD HH:mm:ss')
                                      : ''
                                  )
                                }
                                disabledTime={(e) =>
                                  this.getDisabledHoursTask(
                                    moment(e, 'YYYY-MM-DD HH:mm:ss'),
                                    'ToTime'
                                  )
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              name="ToTime"
                              label={this.translation('To Time')}
                              // rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <DatePicker
                                // disabled={
                                //   this.model_2Ref.current.getFieldValue(
                                //     "PredReqId_2"
                                //   ) &&
                                //     this.model_2Ref.current.getFieldValue(
                                //       "PredReqId_2"
                                //     ) !== undefined
                                //     ? true
                                //     : false
                                // }
                                style={{ width: '70%' }}
                                allowClear={false}
                                showTime={{
                                  defaultValue: moment('00:00:00', 'HH:mm:ss')
                                }}
                                format="YYYY-MM-DD HH:mm:ss"
                                onOk={(date) => this.handleDateInputChange(date)}
                                onChange={(date) => {
                                  let model_2Info =
                                    this.model_2Ref.current &&
                                    this.model_2Ref.current.getFieldsValue();
                                  model_2Info = {
                                    ...model_2Info,
                                    ToTime: date
                                  };
                                  this.model_2Ref.current &&
                                    this.model_2Ref.current.setFieldsValue({
                                      ...model_2Info
                                    });
                                  this.setState({ model_2Info });
                                }}
                                disabledDate={(d) =>
                                  //     this.formHeader.current.getFieldValue("EndTime")
                                  //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                                  //         ))
                                  !d ||
                                  d.isBefore(
                                    this.model_2Ref.current &&
                                      this.model_2Ref.current !== null &&
                                      this.model_2Ref.current.getFieldValue() &&
                                      this.model_2Ref.current.getFieldValue('FromTime')
                                      ? moment(
                                          this.model_2Ref.current.getFieldValue() &&
                                            this.model_2Ref.current.getFieldValue('FromTime')
                                        ).format('YYYY-MM-DD HH:mm:ss')
                                      : ''
                                  )
                                }
                                disabledTime={(e) =>
                                  this.getDisabledHoursTask(
                                    moment(e, 'YYYY-MM-DD HH:mm:ss'),
                                    'ToTime'
                                  )
                                }
                              />
                            </Form.Item>

                            <Form.Item
                              name="Metrics"
                              label={this.translation('Metrics')}
                              rules={[{ required: true }]}
                              style={formItemLayout}
                            >
                              <Tree
                                // style={{ width: '30%' }}
                                // disabled={
                                //   this.model_2Ref.current.getFieldValue(
                                //     "PredReqId_2"
                                //   ) &&
                                //     this.model_2Ref.current.getFieldValue(
                                //       "PredReqId_2"
                                //     ) !== undefined
                                //     ? true
                                //     : false
                                // }
                                checkable
                                onCheck={(e) => this.onCheck2('Metrics', e)}
                                switcherIcon={<DownOutlined />}
                                checkedKeys={
                                  model_2Info && model_2Info['Metrics']
                                    ? model_2Info['Metrics']
                                    : ''
                                }
                              >
                                {this.renderTreeNodes(metricsData)}
                              </Tree>
                            </Form.Item>
                            <Form.Item name="PredReqId_2" style={formItemLayout}></Form.Item>
                            <Form.Item>
                              <Button
                                ref={this.predictionButtonRef}
                                htmlType="submit"
                                style={{ display: 'none' }}
                              ></Button>
                            </Form.Item>
                          </div>
                        </StyledBox>
                      </>
                    ) : null}
                  </TabPane>
                </StyledTabs>
              ) : null}
            </StyledBox>

            <Button ref={this.model_2Button} htmlType="submit" style={{ display: 'none' }}></Button>
          </StyledForm>
          {/* </div> */}
          {model_2Info && model_2Info.ModelName_2 && model_2Info.ModelName_2 !== undefined ? (
            <div>
              <Popconfirm
                title={this.translation('Are you sure to clear the new prediction ?')}
                onConfirm={() => {
                  let model_2Info = this.model_2Ref.current.getFieldsValue();
                  model_2Info = {
                    ...model_2Info,
                    FromTime: '',
                    ToTime: ''
                  };
                  this.onHandleChange(
                    'ModelName_2',
                    model_2Info && model_2Info.ModelName_2,
                    model_1Info
                  );
                  this.model_2Ref.current &&
                    this.model_2Ref.current.setFieldsValue({ ...model_2Info });
                  this.setState({ model_2Info });
                }}
                onCancel={() => {}}
                okText={this.translation('Yes')}
                cancelText={this.translation('No')}
              >
                <Form.Item>
                  <Button ref={this.model_2tab1Confirm} style={{ display: 'none' }}></Button>
                </Form.Item>
              </Popconfirm>
              <Popconfirm
                title={this.translation('Are you sure to clear the existing prediction ?')}
                onConfirm={() => {
                  let model_2Info = this.model_2Ref.current.getFieldsValue();
                  model_2Info = {
                    ...model_2Info,
                    PredReqId_2: undefined
                  };
                  this.setState({ model_2Info, selectedRowKeys2: [] });
                  this.getModel_2MetaData(model_2Info && model_2Info.ModelName_2);
                  this.model_2Ref.current &&
                    this.model_2Ref.current.setFieldsValue({ ...model_2Info });
                }}
                onCancel={() => {
                  this.getModel_2MetaData(model_2Info && model_2Info.ModelName_2);
                }}
                okText={this.translation('Yes')}
                cancelText={this.translation('No')}
              >
                <Form.Item>
                  <Button ref={this.model_2tab2Confirm} style={{ display: 'none' }}></Button>
                </Form.Item>
              </Popconfirm>
            </div>
          ) : null}
        </StyleModal>

        {/* ============================================  Results ============================================ */}

        {showResults && showResults === true ? (
          <>
            <StyledSubHeading theme={themeSettings}>
              {this.translation('Model Comparison Results')}
            </StyledSubHeading>
            <Row
              gutter={[16, 24]}
              style={{
                backgroundColor: '#f5f5f5',
                border: '1px solid gray',
                padding: '15px !important'
              }}
            >
              <Col className="gutter-row" span={12} style={{ marginTop: '10px' }}>
                <Layout className="results">
                  <Header>
                    {' '}
                    <Title level={5} className="HeaderPart">
                      {this.translation('Model 1') + ': '}
                      {model_1Info &&
                        ModelsList &&
                        ModelsList.filter((item) => item.ModelId === model_1Info.ModelName_1) &&
                        ModelsList &&
                        ModelsList.filter((item) => item.ModelId === model_1Info.ModelName_1)[0] &&
                        ModelsList &&
                        ModelsList.filter((item) => item.ModelId === model_1Info.ModelName_1)[0][
                          'ModelName'
                        ]}
                    </Title>
                    {model_1Info &&
                    model_1Info.FromTime &&
                    model_1Info.FromTime !== undefined &&
                    model_1Info.ToTime &&
                    model_1Info.ToTime !== undefined ? (
                      <>
                        {' '}
                        <Title level={5} className="HeaderPart">
                          {this.translation('From Time') + ': '}
                          {Id && Id !== null
                            ? moment(model_1Info.FromTime)
                                .utc()
                                .local()
                                .format('YYYY-MM-DD HH:mm:ss')
                            : moment(model_1Info.FromTime).format('YYYY-MM-DD HH:mm:ss')}
                        </Title>
                        <Title level={5} className="HeaderPart">
                          {this.translation('To Time') + ': '}
                          {Id && Id !== null
                            ? moment(model_1Info.ToTime).utc().local().format('YYYY-MM-DD HH:mm:ss')
                            : moment(model_1Info.ToTime).format('YYYY-MM-DD HH:mm:ss')}
                        </Title>
                      </>
                    ) : model_1PredictionRequestList &&
                      model_1PredictionRequestList.filter(
                        (item) => item.PredReqId === model_1Info.PredReqId_1
                      ) &&
                      model_1PredictionRequestList.filter(
                        (item) => item.PredReqId === model_1Info.PredReqId_1
                      )[0] &&
                      model_1PredictionRequestList.filter(
                        (item) => item.PredReqId === model_1Info.PredReqId_1
                      )[0]['FromTime'] &&
                      model_1PredictionRequestList.filter(
                        (item) => item.PredReqId === model_1Info.PredReqId_1
                      )[0]['ToTime'] ? (
                      <>
                        <Title level={5} className="HeaderPart">
                          {this.translation('From Time') + ' : '}
                          {moment(
                            model_1PredictionRequestList.filter(
                              (item) => item.PredReqId === model_1Info.PredReqId_1
                            )[0]['FromTime']
                          ).format('YYYY-MM-DD HH:mm:ss')}
                        </Title>
                        <Title level={5} className="HeaderPart">
                          {'To Time : '}
                          {moment(
                            model_1PredictionRequestList.filter(
                              (item) => item.PredReqId === model_1Info.PredReqId_1
                            )[0]['ToTime']
                          ).format('YYYY-MM-DD HH:mm:ss')}
                        </Title>
                      </>
                    ) : null}
                    <Title level={5} className="HeaderPart">
                      {this.translation('Status') + ': '}
                      {model_1Info &&
                        model_1PredictionRequestList &&
                        model_1PredictionRequestList.filter(
                          (item) => item.PredReqId === model_1Info.PredReqId_1
                        ) &&
                        model_1PredictionRequestList &&
                        model_1PredictionRequestList.filter(
                          (item) => item.PredReqId === model_1Info.PredReqId_1
                        )[0] &&
                        model_1PredictionRequestList &&
                        model_1PredictionRequestList.filter(
                          (item) => item.PredReqId === model_1Info.PredReqId_1
                        )[0]['Status']}
                    </Title>
                  </Header>
                  <Content>
                    {Model_1MetricValuesData &&
                    Array.isArray(Model_1MetricValuesData) &&
                    Model_1MetricValuesData.length !== 0 ? (
                      <StyledTable
                        pagination={{
                          defaultPageSize: 10,
                          hideOnSinglePage: true
                        }}
                        theme={themeSettings}
                        dataSource={
                          Model_1MetricValuesData && Array.isArray(Model_1MetricValuesData)
                            ? Model_1MetricValuesData
                            : []
                        }
                        columns={MetricResultColumns}
                      ></StyledTable>
                    ) : null}
                    {Model_1MetricImagesData && Array.isArray(Model_1MetricImagesData)
                      ? Model_1MetricImagesData.map((item, index) => {
                          return (
                            <Col key={index} span={8}>
                              <Card title={this.translation(item.MetricCode)} bordered={false}>
                                <Image
                                  width={500}
                                  src={`http://192.168.95.23:3001/api/analyticsAttachments/${
                                    item.MetricValue.split('/')[0]
                                  }/${item.MetricValue.split('/')[1]}`}
                                />
                              </Card>
                            </Col>
                          );
                        })
                      : null}
                  </Content>
                </Layout>
              </Col>
              {/* <Col
              className="gutter-row"
              span={2}
              style={{ marginTop: "10px" }}
            >  </Col> */}

              <Col className="gutter-row" span={12} style={{ marginTop: '10px' }}>
                <Layout className="results">
                  <Header className="results">
                    <Title level={5} className="HeaderPart">
                      {this.translation('Model 2') + ' : '}
                      {model_2Info &&
                        ModelsList &&
                        ModelsList.filter((item) => item.ModelId === model_2Info.ModelName_2) &&
                        ModelsList &&
                        ModelsList.filter((item) => item.ModelId === model_2Info.ModelName_2)[0] &&
                        ModelsList &&
                        ModelsList.filter((item) => item.ModelId === model_2Info.ModelName_2)[0][
                          'ModelName'
                        ]}
                    </Title>

                    {model_2Info &&
                    model_2Info.FromTime &&
                    model_2Info.FromTime !== undefined &&
                    model_2Info.ToTime &&
                    model_2Info.ToTime !== undefined ? (
                      <>
                        <Title level={5} className="HeaderPart">
                          {this.translation('From Time') + ' :'}
                          {Id && Id !== null
                            ? moment(model_2Info.FromTime)
                                .utc()
                                .local()
                                .format('YYYY-MM-DD HH:mm:ss')
                            : moment(model_2Info.FromTime).format('YYYY-MM-DD HH:mm:ss')}
                        </Title>
                        <Title level={5} className="HeaderPart">
                          {this.translation('To Time') + ' :'}
                          {Id && Id !== null
                            ? moment(model_2Info.ToTime).utc().local().format('YYYY-MM-DD HH:mm:ss')
                            : moment(model_2Info.ToTime).format('YYYY-MM-DD HH:mm:ss')}
                        </Title>
                      </>
                    ) : model_2PredictionRequestList &&
                      model_2PredictionRequestList.filter(
                        (item) => item.PredReqId === model_2Info.PredReqId_2
                      ) &&
                      model_2PredictionRequestList &&
                      model_2PredictionRequestList.filter(
                        (item) => item.PredReqId === model_2Info.PredReqId_2
                      )[0] &&
                      model_2PredictionRequestList &&
                      model_2PredictionRequestList.filter(
                        (item) => item.PredReqId === model_2Info.PredReqId_2
                      )[0]['FromTime'] &&
                      model_2PredictionRequestList &&
                      model_2PredictionRequestList.filter(
                        (item) => item.PredReqId === model_2Info.PredReqId_2
                      )[0]['ToTime'] ? (
                      <>
                        <Title level={5} className="HeaderPart">
                          {this.translation('From Time') + ' : '}
                          {moment(
                            model_2PredictionRequestList &&
                              model_2PredictionRequestList.filter(
                                (item) => item.PredReqId === model_2Info.PredReqId_2
                              )[0]['FromTime']
                          ).format('YYYY-MM-DD HH:mm:ss')}
                        </Title>
                        <Title level={5} className="HeaderPart">
                          {this.translation('To Time') + ' : '}
                          {moment(
                            model_2PredictionRequestList &&
                              model_2PredictionRequestList.filter(
                                (item) => item.PredReqId === model_2Info.PredReqId_2
                              )[0]['ToTime']
                          ).format('YYYY-MM-DD HH:mm:ss')}
                        </Title>
                      </>
                    ) : null}
                    <Title level={5} className="HeaderPart">
                      {this.translation('Status') + ' : '}
                      {model_2Info &&
                        model_2PredictionRequestList &&
                        model_2PredictionRequestList.filter(
                          (item) => item.PredReqId === model_2Info.PredReqId_2
                        ) &&
                        model_2PredictionRequestList &&
                        model_2PredictionRequestList.filter(
                          (item) => item.PredReqId === model_2Info.PredReqId_2
                        )[0] &&
                        model_2PredictionRequestList &&
                        model_2PredictionRequestList.filter(
                          (item) => item.PredReqId === model_2Info.PredReqId_2
                        )[0]['Status']}
                    </Title>
                  </Header>
                  <Content
                    style={{
                      marginTop: '10px !important',
                      border: '1px solid gray'
                    }}
                  >
                    {Model_2MetricValuesData &&
                    Array.isArray(Model_2MetricValuesData) &&
                    Model_2MetricValuesData.length !== 0 ? (
                      <StyledTable
                        pagination={{
                          defaultPageSize: 10,
                          hideOnSinglePage: true
                        }}
                        theme={themeSettings}
                        dataSource={
                          Model_2MetricValuesData && Array.isArray(Model_2MetricValuesData)
                            ? Model_2MetricValuesData
                            : []
                        }
                        columns={MetricResultColumns}
                      ></StyledTable>
                    ) : null}
                    {Model_2MetricImagesData && Array.isArray(Model_2MetricImagesData)
                      ? Model_2MetricImagesData.map((item, index) => {
                          return (
                            <Col key={index} span={8}>
                              <Card title={this.translation(item.MetricCode)} bordered={false}>
                                <Image
                                  width={500}
                                  src={`http://192.168.95.23:3001/api/analyticsAttachments/${
                                    item.MetricValue.split('/')[0]
                                  }/${item.MetricValue.split('/')[1]}`}
                                />
                              </Card>
                            </Col>
                          );
                        })
                      : null}
                  </Content>
                </Layout>
              </Col>
            </Row>
          </>
        ) : (
          <Result
            icon={<SmileOutlined />}
            style={{ color: 'rgb(255, 158, 89)' }}
            title={this.translation('Please Provide Model Details To Compare!')}
            // extra={<Button type="primary">Next</Button>
            // }
          />
        )}
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(Model_Comparision);
