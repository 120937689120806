import localStorage from '../../../../../utils/localStorage';
import axios from 'axios';
import { message } from 'antd';

// export const fetchUsers = async (api) => {
//   try {
//     return await axios.get(`${api}`);
//   } catch (e) {
//     return [];
//   }
// };
export async function getList(api) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };

  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}
export async function getById(api, id, key) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${api}?${key}=${id}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}
export async function insert(api, payload) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}
export async function deleteById(api, id, key) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'DELETE',
    url: `${api}?${key}=${id}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}
export async function deleteItemByparentId(api, parentId, parentKey, id, key) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'DELETE',
    url: `${api}?${key}=${id}&${parentKey}=${parentId}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}
