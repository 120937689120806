import axios from 'axios';
import moment from 'moment';

// import { API_DASHBOARD } from "../../../../../commons/api";
import localStorage from '../../../../../utils/localStorage';

export function getTableData(SelectedDate, DeviceCode) {
  let start = moment(SelectedDate).format('YYYY-MM-DD 00:00:00');
  let end = moment(SelectedDate).format('YYYY-MM-DD 23:59:59');
  // return [
  //   {
  //     Sno: '1',
  //     TimeChamp: 'Mike',
  //     FaultCode: 32,
  //     FaultDescription: '10 Downing Street',
  //     FaultValue: '10 Downing Street',
  //     TrainNo: '1 ',
  //     Location: 'Location1',
  //     Status: 'Open'
  //   },
  //   {
  //     Sno: '2',
  //     TimeChamp: 'John',
  //     FaultCode: 42,
  //     FaultDescription: '10 Downing Street',
  //     FaultValue: '10 Downing Street',
  //     TrainNo: '1 ',
  //     Location: 'Location1',
  //     Status: 'Closed'
  //   },
  //   {
  //     Sno: '2',
  //     TimeChamp: 'John',
  //     FaultCode: 42,
  //     FaultDescription: '10 Downing Street',
  //     FaultValue: '10 Downing Street',
  //     TrainNo: '1 ',
  //     Location: 'Location1',
  //     Status: 'Closed'
  //   },
  //   {
  //     Sno: '2',
  //     TimeChamp: 'John',
  //     FaultCode: 42,
  //     FaultDescription: '10 Downing Street',
  //     FaultValue: '10 Downing Street',
  //     TrainNo: '1 ',
  //     Location: 'Location1',
  //     Status: 'Closed'
  //   },
  //   {
  //     Sno: '2',
  //     TimeChamp: 'John',
  //     FaultCode: 42,
  //     FaultDescription: '10 Downing Street',
  //     FaultValue: '10 Downing Street',
  //     TrainNo: '1 ',
  //     Location: 'Location1',
  //     Status: 'Closed'
  //   },
  //   {
  //     Sno: '2',
  //     TimeChamp: 'John',
  //     FaultCode: 42,
  //     FaultDescription: '10 Downing Street',
  //     FaultValue: '10 Downing Street',
  //     TrainNo: '1 ',
  //     Location: 'Location1',
  //     Status: 'Closed'
  //   },
  //   {
  //     Sno: '2',
  //     TimeChamp: 'John',
  //     FaultCode: 42,
  //     FaultDescription: '10 Downing Street',
  //     FaultValue: '10 Downing Street',
  //     TrainNo: '1 ',
  //     Location: 'Location1',
  //     Status: 'Closed'
  //   }
  // ];
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getDashboardPayload = {
    method: 'POST',
    url: `/api/dashboard/getDashboard?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      StartDate: moment(start).utc().format('YYYY-MM-DD HH:mm:ss'),
      EndDate: moment(end).utc().format('YYYY-MM-DD HH:mm:ss'),
      Id: 2,
      Name: 'CMRL',
      PanelName: 'Table',
      SelectionType: 'Day',
      SiteId: siteId,
      TimeBucket: '',
      DeviceCode: DeviceCode
    }
  };
  return axios(getDashboardPayload)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export function getCounterData(Id, PanelName, SelectedDate) {
  let start = moment(SelectedDate).format('YYYY-MM-DD 00:00:00');
  let end = moment(SelectedDate).format('YYYY-MM-DD 23:59:59');
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getDashboardPayload = {
    method: 'POST',
    url: `/api/dashboard/getDashboard?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      StartDate: moment(start).utc().format('YYYY-MM-DD HH:mm:ss'),
      EndDate: moment(end).utc().format('YYYY-MM-DD HH:mm:ss'),
      Id,
      Name: 'CMRL',
      PanelName: PanelName,
      SelectionType: 'Day',
      SiteId: siteId,
      TimeBucket: ''
    }
  };
  return axios(getDashboardPayload)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export function getVerticalLineData(SelectedDate) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  let start = moment(SelectedDate).format('YYYY-MM-DD 00:00:00');
  let end = moment(SelectedDate).format('YYYY-MM-DD 23:59:59');
  const getDashboardPayload = {
    method: 'POST',
    url: `/api/dashboard/getDashboard?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      StartDate: moment(start).utc().format('YYYY-MM-DD HH:mm:ss'),
      EndDate: moment(end).utc().format('YYYY-MM-DD HH:mm:ss'),
      Id: 1,
      Name: 'CMRL',
      PanelName: 'Faults',
      SelectionType: 'Day',
      SiteId: siteId,
      TimeBucket: ''
    }
  };
  return axios(getDashboardPayload)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export function getMapsData(SelectedDate, DeviceCode) {
  // return  [
  //   {
  //     "Station": "Station-01",
  //     "ParentLatitude": 51.0,
  //     "ParentLongitude": 19.0,
  //     "Latitude": "20.27",
  //     "Longitude": "-157",
  //     "Total Faults": 120,
  //     "Priority-1 Faults": 20,
  //     "Priority-2 Faults": 100
  //   }
  // ]

  let start = moment(SelectedDate).format('YYYY-MM-DD 00:00:00');
  let end = moment(SelectedDate).format('YYYY-MM-DD 23:59:59');
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getDashboardPayload = {
    method: 'POST',
    url: `/api/dashboard/getDashboard?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      StartDate: moment(start).utc().format('YYYY-MM-DD HH:mm:ss'),
      EndDate: moment(end).utc().format('YYYY-MM-DD HH:mm:ss'),
      Id: 8,
      Name: 'CMRL',
      PanelName: 'Maps',
      SelectionType: 'Day',
      SiteId: siteId,
      TimeBucket: '',
      DeviceCode: DeviceCode
    }
  };
  return axios(getDashboardPayload)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export function getTrainList(hierarchyCode) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getDashboardPayload = {
    method: 'GET',
    url: `/api/hierarchymodule/getDataByLevels?SiteId=${siteId}&HierarchyCode=${hierarchyCode}&Module=RS&Level=1`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDashboardPayload)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
