import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { createStructuredSelector } from 'reselect';
import { Button, Input, Popconfirm, Tooltip } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';

import ListView from '../../../Utils/ListView';
import { constant } from '../../../Utils/constants';
import { getUserProfile } from '../../../../../selectors/layout';
import { deleteById, getUsersList, getConditionMoitorList } from '../../../Utils/FormCalls';
import { StyledComponent } from '../../../CSS/style';
import translation from '../../../Utils/translation';
import themeSettings from '../../../Utils/themeSettings.json';
import { getTranslationCache } from '../../../../../selectors/language';
import history from '../../../../../commons/history';
import { encode as base64_encode } from 'base-64';
import localStorage from '../../../../../utils/localStorage';
import { API_CONDITIONALMONITORING } from '../../../../../commons/api';

class CMList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      userProfile: props && props.userProfile ? props.userProfile : {},
      userList: [],
      config: {
        viewTypes: ['add', 'card', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['Name'],

        firstTitle: 'Name',
        secondTitle: 'CreatedBy',
        thirdTitle: 'CreatedTime',

        actions: ['edit', 'delete'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/AMM/CMDetails'
        },
        deletee: {
          byAttribute: 'Id'
        }
      }
    };
  }

  componentDidMount = async () => {
    this._getDataSource();
  };

  componentDidUpdate(prevprops) {
    if (this.props.userProfile !== prevprops.userProfile) {
      this.setState({ userProfile: this.props.userProfile });
    }
  }

  deleteCM = async (id) => {
    const siteId = localStorage.get('currentSite');
    let payload = {
      url: `${API_CONDITIONALMONITORING.DELETE}?Id=${id}&SiteId=${siteId}`,
      method: 'POST'
    };
    await deleteById(payload);
    await this._getDataSource();
  };
  edit = async (record) => {
    let { config } = this.state;
    history.push({ pathname: `${config.edit.redirectURL}/${base64_encode(record.Id)}` });
  };
  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };

  _getDataSource = async () => {
    let dataSource = await getConditionMoitorList();
    this.setState({
      dataSource
    });
  };

  _deleteById = async (id) => {
    const siteId = localStorage.get('currentSite');
    let payload = {
      url: `${API_CONDITIONALMONITORING.DELETE}?Id=${id}&SiteId=${siteId}`,
      method: 'POST'
    };
    await deleteById(payload);
    let dataSource = await getConditionMoitorList();
    this.setState({
      dataSource
    });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          //maxLength={30}
        />
        <SearchOutlined
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8, fontSize: '130%' }}
        >
          Search
        </SearchOutlined>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  handleRowClick = (ruleId) => {
    this.props.history.push(`/rubus/CmFeedback/?cmId=${ruleId}`);
  };
  render() {
    const { dataSource, userList } = this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, constant.name),
        dataIndex: 'Name',
        key: 'Name',
        ...this.getColumnSearchProps('Name')
      },
      {
        title: translation(translationCache, constant.description),
        dataIndex: 'Description',
        key: 'Description',
        ...this.getColumnSearchProps('Description')
      },
      {
        title: translation(translationCache, constant.site),
        dataIndex: 'SiteId',
        key: 'SiteId'
      },
      // {
      //   title: translation(translationCache, constant.status),
      //   dataIndex: 'Status',
      //   key: 'Status',
      //   render: (text) => (
      //     <>
      //       {/* <Tooltip title={`Click To ${text === 'Active' ? 'Inactive' : 'Active'}`}>
      //         <span onClick={() => this.changeStatus(record.Id)}> */}
      //       <Tag
      //         color={
      //           text && text === 'Active' ? '#87d068' : text === 'Inactive' ? '#ff4d4f' : 'gold'
      //         }
      //         key={text}
      //       >
      //         {text}
      //       </Tag>
      //       {/* </span>
      //       </Tooltip> */}
      //     </>
      //   )
      // },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            <Button
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => this.edit(record)}
              size="middle"
            >
              <Tooltip title={translation(translationCache, 'Edit')}>
                <EditOutlined />
              </Tooltip>
            </Button>
            <Popconfirm
              title={translation(
                this.props.translationCache,
                constant.areYouSureThatYouWantToDelete
              )}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this.deleteCM(record.Id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="ant-btn" size="middle">
                <span style={{ color: '#ff4d4f' }}>
                  <Tooltip title={translation(translationCache, 'Delete')}>
                    <DeleteOutlined />
                  </Tooltip>
                </span>
              </Button>
            </Popconfirm>
          </>
        )
      },
      {
        title: '',
        key: '',
        render: (text, record) => {
          return (
            <Button
              type="primary"
              onClick={() => {
                this.handleRowClick(record.Id);
              }}
            >
              {translationCache && translationCache[`Notification History`]
                ? translationCache[`Notification History`]
                : `Notification History`}
            </Button>
          );
        }
      }
    ];
    let config = {
      title: translation(translationCache, 'Conditional Monitoring'),

      viewTypes: ['add', 'card', 'table'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['Name'],

      firstTitle: 'Name',
      secondTitle: 'CreatedBy',
      thirdTitle: 'CreatedTime',

      actions: ['edit', 'delete'],
      edit: {
        byAttribute: 'Id',
        redirectURL: '/rubus/AMM/CMDetails'
      },
      deletee: {
        byAttribute: 'Id'
      }
    };
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 143 }}>
        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(CMList);
