import React from 'react';
import axios from 'axios';
import { Form, Input, message, Button } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getTranslationCache } from '../.././../selectors/language';
// import { constant } from '../Constants';
import localStorage from '../../../utils/localStorage';
import styles from '../../../commons/styles';
import { API_AUTH } from '../../../commons/api';

const { StyledWrapper, StyledWrapperLeft, StyledWrapperRight, StyledHeading } = styles.layout;

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      translationCache: props.translationCache || []
    };
  }
  editProfile = React.createRef();
  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  _changePasswordAPI = (value) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const changePassword = {
      method: 'POST',
      url: `${API_AUTH.ChangePassword}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { ...value, SiteId: siteId }
    };
    axios(changePassword)
      .then((response) => {
        message.success(response.data.message);
        localStorage.set('accessToken', 'null');
        this.props.history.push('/login');
      })
      .catch((error) => {
        if (error.response.status === 400) {
          message.error(error.response.data.message);
        }
      });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onFocus = (event) => {
    event.preventDefault();
    if (event.target.autocomplete) {
      event.target.autocomplete = 'whatever';
    }
  };

  render() {
    const onFinish = (values) => {
      this._changePasswordAPI(values);
    };

    const onFinishFailed = () => {};

    return (
      <div>
        <StyledWrapper>
          <StyledWrapperLeft>
            <StyledHeading>{this.translation('Change Password')}</StyledHeading>

            {this.translation(
              'After a successful password update,you will be redirected to the login page where you can log in with your new password.'
            )}
          </StyledWrapperLeft>
          <StyledWrapperRight style={{ width: '70%' }}>
            <Form
              ref={this.editProfile}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label={this.translation('Old Password')}
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: [this.translation('Please input your old password')]
                  }
                ]}
              >
                <Input.Password
                  autoComplete="off"
                  style={{ width: '300px' }}
                  placeholder={this.translation('Input your old password')}
                  autocomplete="new-password"
                />
              </Form.Item>

              <Form.Item
                label={this.translation('New Password')}
                name="password"
                rules={[
                  { required: true, message: [this.translation('Please input your new password')] }
                ]}
              >
                <Input.Password
                  style={{ width: '300px' }}
                  placeholder={'Input your new password'}
                  autocomplete="new-password"
                  // autoComplete="off"
                  onFocus={this.onFocus}
                />
              </Form.Item>
              <Form.Item
                label={this.translation('Confirm New Password')}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: [this.translation('Please input your confirm new password')]
                  }
                ]}
              >
                <Input.Password
                  style={{ width: '300px' }}
                  onFocus={this.onFocus}
                  placeholder={'Confirm your new password'}
                />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  {this.translation('Save')}
                </Button>
              </Form.Item>
            </Form>
          </StyledWrapperRight>
        </StyledWrapper>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(ChangePassword);
