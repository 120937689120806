import React, { Component } from 'react';
import { Form, Input, Select, Button, message } from 'antd';

const { Option } = Select;
const uuid = require('uuid');

class ChildrenColumnCustomization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childProperties: this.props.childProperties || [],
      columnIndex: this.props.index
    };
  }

  componentDidUpdate(prevprops) {
    if (this.props.childProperties !== prevprops.childProperties) {
      this.setState({
        childProperties: this.props.childProperties
      });
    }
  }
  childrenMapping = (childProperties, marginLeft, marginTop) => {
    const childrens = this.state.childProperties;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };

    return (
      <div>
        {childProperties.map((item, index) => (
          <Form
            key={index}
            style={{
              marginLeft,
              marginTop,
              border: '1px dashed #0b0a0a',
              padding: '18px'
            }}
            {...formItemLayout}
          >
            {item.title}
            <Form.Item
              label="Title :"
              // name="title"
              rules={[{ required: true, message: 'Please Input Title!' }]}
            >
              <Input
                value={item.title}
                onChange={(e) =>
                  this.handleFieldChange(childrens, e.target.value, index, item.id, 'title')
                }
              />
            </Form.Item>
            <Form.Item label="DataIndex :">
              <Input
                value={item.dataIndex}
                onChange={(e) =>
                  this.handleFieldChange(childrens, e.target.value, index, item.id, 'dataIndex')
                }
              />
            </Form.Item>
            <Form.Item label="Key :">
              <Input
                value={item.key}
                onChange={(e) =>
                  this.handleFieldChange(childrens, e.target.value, index, item.id, 'key')
                }
              />
            </Form.Item>
            <Form.Item label="Width :">
              <Input
                style={{ width: '100%' }}
                value={item.width}
                type="number"
                onChange={(e) =>
                  this.handleFieldChange(childrens, e.target.value, index, item.id, 'width')
                }
              ></Input>
            </Form.Item>
            <Form.Item label="ClassName :">
              <Select
                style={{ width: '100%' }}
                value={item.className}
                onSelect={(value) =>
                  this.handleFieldChange(childrens, value, index, item.id, 'className')
                }
              >
                <Option value="table-column"> TABLE-COLUMN </Option>
              </Select>
            </Form.Item>

            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '18px' }}>
              <Button
                id={item.id}
                type="primary"
                style={{ margin: '2px' }}
                onClick={(e) => this.subChildrenSave(childrens, e.target.id, item.key)}
              >
                Save
              </Button>
              <Button
                id={item.id}
                type="danger"
                style={{ margin: '2px' }}
                onClick={(e) => this.subChildDelete(childrens, index, e.target.id)}
              >
                Delete
              </Button>
              <Button
                id={item.id}
                type="primary"
                style={{ margin: '2px' }}
                onClick={(e) => this.subChildrenAddition(childrens, e.target.id, index, item.key)}
              >
                Add SubChildren
              </Button>
            </div>

            {item['children'] ? this.childrenMapping(item['children'], '100px') : null}
          </Form>
        ))}
      </div>
    );
  };
  subChildrenAddition = (childProperties, id, key) => {
    const { columnIndex } = this.state;
    if (id === '') {
      id = undefined;
    }

    childProperties &&
      Array.isArray(childProperties) &&
      childProperties.map((item, index) => {
        let array = [];

        if (item && item.children && item.children.length > 0) {
          if (item.id === id && item.key === key) {
            if (item.title === '' || item.dataIndex === '' || item.key === '') {
              message.error('Please Enter All Thes Fields');
            } else {
              let newObj = {
                id: uuid(),
                title: 'subChildren',
                dataIndex: '',
                key: '',
                width: '100',
                className: '',
                background: ''
              };
              item.children.push(newObj);
            }
          } else {
            item.children.map((childObj) => {
              this.addSubChildren(childObj, index, id, key);
              return {};
            });
          }
        } else {
          if (item.id === id && item.key === key) {
            if (item.title === '' || item.dataIndex === '' || item.key === '') {
              message.error('Please Enter All Thes Fields');
            } else {
              let newObj = {
                id: uuid(),
                title: 'subChildren',
                dataIndex: '',
                key: '',
                width: '100',
                className: '',
                background: ''
              };
              array.push(newObj);
              item['children'] = array;
            }
          }
        }

        this.props.submitChildren(childProperties, columnIndex);
        this.setState({ childProperties });
        return {};
      });
  };
  addSubChildren(childObj, index, id, key) {
    let array = [];
    if (childObj.children) {
      if (childObj.id === id && childObj.key === key) {
        if (childObj.title === '' || childObj.dataIndex === '' || childObj.key === '') {
          message.error('Please Enter All Thes Fields');
        } else {
          let newObj = {
            id: uuid(),
            title: 'subChildren',
            dataIndex: '',
            key: '',
            width: '100',
            className: '',
            background: ''
          };
          childObj.children.push(newObj);
        }
      } else {
        this.subChildrenAddition(childObj.children, id, index, key);
      }
    } else {
      if (childObj.id === id && childObj.key === key) {
        if (childObj.title === '' || childObj.dataIndex === '' || childObj.key === '') {
          message.error('Please Enter All Thes Fields');
        } else {
          let newObj = {
            id: uuid(),
            title: 'subChildren',
            dataIndex: '',
            key: '',
            width: '100',
            className: '',
            background: ''
          };
          array.push(newObj);
          childObj['children'] = array;
        }
      }
    }
  }
  subChildrenSave = (childProperties, id) => {
    const { columnIndex } = this.state;

    childProperties &&
      Array.isArray(childProperties) &&
      childProperties.map((item, index) => {
        if (item && item.children && item.children.length > 0) {
          if (item.id === id) {
            if (item.title === '' || item.dataIndex === '' || item.key === '') {
              message.error('Please Enter All These Fields');
            } else if (item.title !== '' && item.dataIndex !== '' && item.key !== '') {
              message.success('children Saved successfully');
            }
          } else {
            item.children.map((childObj) => {
              this.saveSubChildren(childObj, index, id);
              return {};
            });
          }
        } else {
          if (item.id === id) {
            if (item.title === '' || item.dataIndex === '' || item.key === '') {
              message.error('Please Enter All Thes Fields');
            } else if (item.title !== '' && item.dataIndex !== '' && item.key !== '') {
              message.success('children saved successfully');
            }
          }
        }

        this.props.submitChildren(childProperties, columnIndex);
        this.setState({ childProperties });
        return {};
      });
  };
  saveSubChildren(childObj, index, id) {
    if (childObj.children) {
      if (childObj.id === id) {
        if (childObj.title === '' || childObj.dataIndex === '' || childObj.key === '') {
          message.error('Please Enter All Thes Fields');
        } else if (childObj.title !== '' && childObj.dataIndex !== '' && childObj.key !== '') {
          message.success('children saved successfully');
        }
      } else {
        this.subChildrenSave(childObj.children, id);
      }
    } else {
      if (childObj.id === id) {
        if (childObj.title === '' || childObj.dataIndex === '' || childObj.key === '') {
          message.error('Please Enter All Thes Fields');
        } else if (childObj.title !== '' && childObj.dataIndex !== '' && childObj.key !== '') {
          message.success('children saved successfully');
        }
      }
    }
  }
  handleFieldChange = (childProperties, value, index, id, key) => {
    let { columnIndex } = this.state;
    childProperties &&
      Array.isArray(childProperties) &&
      childProperties.map((item, index) => {
        if (item && item.children && item.children.length > 0) {
          if (item.id === id) {
            item[key] = value;
          } else {
            item.children.map((childObj) => {
              this.dataChange(childObj, value, index, id, key);
              return {};
            });
          }
        } else {
          if (item.id === id) {
            item[key] = value;
          }
        }

        this.props.submitChildren(childProperties, columnIndex);
        this.setState({ childProperties });
        return {};
      });
  };
  dataChange(childObj, value, index, id, key) {
    if (childObj.children) {
      if (childObj.id === id) {
        childObj[key] = value;
      }
      this.handleFieldChange(childObj.children, value, index, id, key);
    } else {
      if (childObj.id === id) {
        childObj[key] = value;
      }
    }
  }
  subChildDelete(childProperties, index, id) {
    const { columnIndex } = this.state;
    childProperties &&
      Array.isArray(childProperties) &&
      childProperties.map((item, index) => {
        if (item && item.children && item.children.length > 0) {
          if (item.id === id) {
            childProperties.splice(item, 1);
          } else {
            item.children.map((childObj) => {
              if (childObj.id === id) {
                item.children.splice(childObj, 1);
              } else {
                this.deleteSubChild(childObj, index, id);
              }
              return {};
            });
          }
        } else {
          if (item.id === id) {
            childProperties.splice(item, 1);
          }
        }

        this.props.submitChildren(childProperties, columnIndex);
        this.setState({ childProperties });
        return {};
      });
  }
  deleteSubChild(childObj, index, id) {
    if (childObj.children) {
      if (childObj.id === id) {
        childObj.splice(childObj, 1);
        // childProperties.splice(childObj, 1);
      }
      this.subChildDelete(childObj.children, index, id);
    } else {
      if (childObj.id === id) {
        childObj.splice(childObj, 1);
        // childProperties.splice(childObj, 1);
      }
    }
  }

  render() {
    const { childProperties } = this.state;

    return this.childrenMapping(childProperties, '20px', '20px');
  }
}
export default ChildrenColumnCustomization;
