import React, { Component } from 'react';
import { message, Input, Button, Tooltip, Popconfirm, Alert } from 'antd';
import themeSettings from '../../../Analytics_Predictions/Utils/themeSettings.json';
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { getList, insert, deleteById } from './Utils/FormCalls';
import { StyledComponent, StyledTable } from '../../../Analytics_Predictions/CSS/style';
import Highlighter from 'react-highlight-words';
import history from '../../../../commons/history';
import moment from 'moment-timezone';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../selectors/language';
import { connect } from 'react-redux';
// import { constant } from './Utils/Constants';
import { RBSLOOKUPMaster } from '../../../../commons/api';
// import translation from './Utils/translation';

const APIList = {
  getLookupMasterList: `${RBSLOOKUPMaster.GET}`,
  getLookupMasterById: `${RBSLOOKUPMaster.GetById}`,
  insertLookupMaster: `${RBSLOOKUPMaster.CREATE}`,
  deleteLookupMaster: `${RBSLOOKUPMaster.Delete}`
};

class LookupMasters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      // columns: [
      //   {
      //     title: this.translation('Lookup Code'),
      //     key: 'LookupName',
      //     dataIndex: 'LookupName',
      //     editable: true,
      //     disable: true,
      //     filterMode: 'tree',
      //     filterSearch: true,
      //     ...this.getColumnSearchProps('LookupName'),
      //     sorter: (a, b) =>
      //       a.LookupName && b.LookupName ? a.LookupName.localeCompare(b.LookupName) : null
      //     // render: (text, record) => (
      //     //   <Link
      //     //     to={{ pathname: `/rubus/LookupMasterItemDetails/${record.Lookup_Id}` }}
      //     //   >
      //     //     {text}
      //     //   </Link>
      //     // ),
      //   },
      //   {
      //     title: this.translation('Description'),
      //     key: 'Description',
      //     dataIndex: 'Description',
      //     editable: true
      //   },
      //   {
      //     title: this.translation('Enabled'),
      //     key: 'Enabled',
      //     dataIndex: 'Enabled',
      //     editable: true,
      //     ...this.getColumnSearchProps('Enabled'),
      //     sorter: (a, b) => String(a.Enabled).localeCompare(String(b.Enabled)),
      //     render: (text) => String(text)
      //   },
      //   {
      //     title: this.translation('Created By'),
      //     key: 'CreatedName',
      //     dataIndex: 'CreatedName',
      //     ...this.getColumnSearchProps('CreatedName'),
      //     sorter: (a, b) =>
      //       a.CreatedName && b.CreatedName && a.CreatedName.localeCompare(b.CreatedName)
      //   },
      //   {
      //     title: this.translation('Created Date'),
      //     key: 'CreatedTime',
      //     dataIndex: 'CreatedTime',
      //     ...this.getColumnSearchProps('CreatedTime'),
      //     sorter: (a, b) =>
      //       new Date(moment(a.CreatedTime, 'YYYY-MM-DD HH:mm:ss').format('LLL')) -
      //       new Date(moment(b.CreatedTime, 'YYYY-MM-DD HH:mm:ss').format('LLL')),
      //     render: (text) => {
      //       return {
      //         children: <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //       };
      //     }
      //   },
      //   {
      //     title: this.translation('Action'),
      //     width: '600',
      //     render: (text, record) => (
      //       <>
      //         <Tooltip title={this.translation('Edit')}>
      //           <Button
      //             data-testid={'lift-hold-button'}
      //             disabled={record.Status === 'Inactive' ? true : false}
      //             style={{ marginRight: '5px' }}
      //             className="ant-btn"
      //             onClick={() => history.push(`/rubus/LookupMasterItemDetails/${record.LookupId}`)}
      //             size="middle"
      //           >
      //             <EditOutlined />
      //           </Button>
      //         </Tooltip>
      //         <Popconfirm
      //           title={this.translation('Are you sure ?,If you delete lookup code it will delete all the corresponding lookupitems')}
      //           icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
      //           onConfirm={() => this._deleteById(record.LookupId)}
      //           okText={this.translation('Yes')}
      //           cancelText={this.translation('No')}
      //         >
      //           <Tooltip title={this.translation('Delete')}>
      //             <Button className="ant-btn" size="middle">
      //               <span style={{ color: '#ff4d4f' }}>
      //                 {' '}
      //                 <DeleteOutlined />
      //               </span>
      //             </Button>
      //           </Tooltip>
      //         </Popconfirm>
      //       </>
      //     )
      //   }
      // ],
      translationCache: props.translationCache || []
    };
  }
  componentDidMount = () => {
    this.getLookupMasterList();
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search') + this.translation(dataIndex)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: '' });
  };
  getLookupMasterList = async () => {
    let lookupMasterList = await getList(APIList.getLookupMasterList);
    this.setState({
      dataSource: lookupMasterList && Array.isArray(lookupMasterList) ? [...lookupMasterList] : []
    });
  };
  _deleteById = async (Id) => {
    let response = await deleteById(APIList.deleteLookupMaster, Id, 'LookupId');
    response && response.message && message.success(response.message);
    this.getLookupMasterList();
  };
  handleSave = async (values) => {
    let response = await insert(APIList.insertLookupMaster, values);
    this.getLookupMasterList();
    return (
      <Alert
        message={response && response.message ? response.message : ''}
        type="success"
        showIcon
        closable
      />
    );
  };
  handleAdd = () => {
    const { dataSource } = this.state;
    let newData = {
      key: dataSource.length + 1,
      Lookup_Name: '',
      Description: '',
      enabled: ''
    };
    this.setState({ dataSource: [...dataSource, newData] });
  };
  addNew = () => {
    history.push('/rubus/LookupMasterItemDetails/new');
  };
  render() {
    const { dataSource } = this.state;
    const columns = [
      {
        title: this.translation('Lookup Code'),
        key: 'LookupName',
        dataIndex: 'LookupName',
        editable: true,
        disable: true,
        filterMode: 'tree',
        filterSearch: true,
        ...this.getColumnSearchProps('LookupName'),
        sorter: (a, b) =>
          a.LookupName && b.LookupName ? a.LookupName.localeCompare(b.LookupName) : null
        // render: (text, record) => (
        //   <Link
        //     to={{ pathname: `/rubus/LookupMasterItemDetails/${record.Lookup_Id}` }}
        //   >
        //     {text}
        //   </Link>
        // ),
      },
      {
        title: this.translation('Description'),
        key: 'Description',
        dataIndex: 'Description',
        editable: true
      },
      {
        title: this.translation('Enabled'),
        key: 'Enabled',
        dataIndex: 'Enabled',
        editable: true,
        ...this.getColumnSearchProps('Enabled'),
        sorter: (a, b) => String(a.Enabled).localeCompare(String(b.Enabled)),
        render: (text) => String(text)
      },
      {
        title: this.translation('Created By'),
        key: 'CreatedName',
        dataIndex: 'CreatedName',
        ...this.getColumnSearchProps('CreatedName'),
        sorter: (a, b) =>
          a.CreatedName && b.CreatedName && a.CreatedName.localeCompare(b.CreatedName)
      },
      {
        title: this.translation('Created Date'),
        key: 'CreatedTime',
        dataIndex: 'CreatedTime',
        ...this.getColumnSearchProps('CreatedTime'),
        sorter: (a, b) =>
          new Date(moment(a.CreatedTime, 'YYYY-MM-DD HH:mm:ss').format('LLL')) -
          new Date(moment(b.CreatedTime, 'YYYY-MM-DD HH:mm:ss').format('LLL')),
        render: (text) => {
          return {
            children: <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
          };
        }
      },
      {
        title: this.translation('Action'),
        width: '600',
        render: (text, record) => (
          <>
            <Tooltip title={this.translation('Edit')}>
              <Button
                data-testid={'lift-hold-button'}
                disabled={record.Status === 'Inactive' ? true : false}
                style={{ marginRight: '5px' }}
                className="ant-btn"
                onClick={() => history.push(`/rubus/LookupMasterItemDetails/${record.LookupId}`)}
                size="middle"
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Popconfirm
              title={this.translation(
                'Are you sure ?,If you delete lookup code it will delete all the corresponding lookupitems'
              )}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this._deleteById(record.LookupId)}
              okText={this.translation('Yes')}
              cancelText={this.translation('No')}
            >
              <Tooltip title={this.translation('Delete')}>
                <Button className="ant-btn" size="middle">
                  <span style={{ color: '#ff4d4f' }}>
                    {' '}
                    <DeleteOutlined />
                  </span>
                </Button>
              </Tooltip>
            </Popconfirm>
          </>
        )
      }
    ];
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '50px 30px', minHeight: window.innerHeight - 143 }}
      >
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Tooltip title={this.translation('Click here to add new Lookup')}>
            <Button
              onClick={() => this.addNew()}
              type="primary"
              style={{
                marginBottom: 16
              }}
            >
              {this.translation('Add')}
            </Button>
          </Tooltip>
        </div>
        <StyledTable
          theme={themeSettings}
          dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
          columns={columns}
        ></StyledTable>
      </StyledComponent>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(LookupMasters);
