import React, { Component } from 'react';
import { Button, Input, Popconfirm } from 'antd';
import {
  // getMasterById,
  getMasterList,
  deleteMaster,
  getUsersList
} from '../../../Utils/FormCalls';
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { constant } from '../../../Utils/constants';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getUserProfile } from '../../../../../selectors/layout';
import { getTranslationCache } from '../../../../../selectors/language';
import { StyledComponent } from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import ListView from '../../../Utils/ListView';
import { encode as base64_encode } from 'base-64';
import history from '../../../../../commons/history';
import Tooltip from 'antd/es/tooltip';
import { API_MASTERLABOUR } from '../../../../../commons/api';
const urlList = {
  list: API_MASTERLABOUR.LIST,
  getbyId: API_MASTERLABOUR.GetById,
  delete: API_MASTERLABOUR.DELETE
};
// const method = {
//   Method: 'GET'
// };

class ManPowerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      activeKey: '1',
      form: {},
      searchText: '',
      userProfile: props && props.userProfile ? props.userProfile : {},
      userList: [],
      translationCache: props.translationCache || [],
      config: {
        viewTypes: ['add', 'card', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['FirstName'],

        firstTitle: 'FirstName',
        secondTitle: 'CreatedBy',
        thirdTitle: 'CreatedTime',

        actions: ['edit', 'delete'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/AMM/ManPowerDetails'
        },
        deletee: {
          byAttribute: 'Id'
        }
      }
    };
  }
  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.match.params.Masters !== prevprops.match.params.Masters) {
      let mastername = this.props.match.params.Masters;
      this._getAdhocDataSource();
      this.setState({ mastername });
    }
  }
  componentDidMount = async () => {
    this._getAdhocDataSource();
  };

  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };
  _getAdhocDataSource = async () => {
    let url = API_MASTERLABOUR.LIST;
    let dataSource = await getMasterList(url);
    this.setState({
      dataSource,
      activeKey: '1',
      form: {}
    });
  };

  downTimeData = (form) => {
    this.setState({
      form
    });
  };

  // deleteMaster = async (id) => {
  //   await deleteMaster(urlList.delete, id);
  //   let dataSource = await getMasterList(urlList.list);
  //   this.setState({
  //     dataSource,
  //     activeKey: '1'
  //     // form: {}
  //   });
  // };
  _deleteById = async (id) => {
    await deleteMaster(urlList.delete, id);
    let dataSource = await getMasterList(urlList.list);
    this.setState({
      dataSource
    });
  };
  // editAssetMaster = async (tableRowData) => {
  //   let { form } = this.state;

  //   let data = await getMasterById(urlList.getbyId, tableRowData.Id, method.Method);
  //   let record = data && data !== undefined ? data : {};
  //   // const result = _.cloneDeepWith(data, (value) => {

  //   //     return (value !== null && value.StartTime && value.EndTime) ? {
  //   //         ...value, ...value["StartTime"] = moment.utc(value.StartTime, "YYYY-MM-DD HH:mm:ss"),
  //   //         ...value["EndTime"] = moment.utc(value.EndTime, "YYYY-MM-DD HH:mm:ss")
  //   //     }
  //   //         : _.noop();
  //   // });
  //   form = {
  //     ...data
  //   };
  //   this.setState({
  //     activeKey: '2',
  //     status: record && record.Status && record.Status === 'COMP' ? 'Completed' : this.state.status,
  //     form
  //   });
  // };
  _editRecord = async (record) => {
    let { config } = this.state;
    history.push({ pathname: `${config.edit.redirectURL}/${base64_encode(record.Id)}` });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, 'Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, 'Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  render() {
    const { dataSource, userList } = this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, constant.firstName),
        dataIndex: 'FirstName',
        key: 'FirstName',
        filter: true,
        ...this.getColumnSearchProps('FirstName')
      },
      {
        title: translation(translationCache, constant.lastName),
        dataIndex: 'LastName',
        key: 'LastName',
        filter: true
      },
      {
        title: translation(translationCache, constant.email),
        dataIndex: 'Email',
        key: 'Email',
        filter: true
        // ...this.getColumnSearchProps('Email')
      },
      {
        title: translation(translationCache, constant.address),
        dataIndex: 'Address',
        key: 'Address',
        filter: true
        // ...this.getColumnSearchProps('Address')
      },
      {
        title: translation(translationCache, constant.phoneNumber),
        dataIndex: 'PhoneNumber',
        key: 'PhoneNumber',
        filter: true
        // ...this.getColumnSearchProps('PhoneNumber')
      },
      {
        title: translation(translationCache, constant.zipCode),
        dataIndex: 'ZipCode',
        key: 'ZipCode',
        filter: true
        // ...this.getColumnSearchProps('ZipCode')
      },
      // {
      //     title: "Status",
      //     dataIndex: "Status",
      //     key: "Status",
      //     render: (text, record) => (
      //         <>
      //             <Tooltip title={`Click To ${text === 'Active' ? 'Inactive' : "Active"}`}>
      //                 <span onClick={() => this.changeStatus(record.Id)} >
      //                     <Tag color={text && text === 'Active' ? '#87d068' :
      //                         text === 'Inactive' ? "#ff4d4f" : "gold"} key={text}>
      //                         {text}
      //                     </Tag>
      //                 </span>
      //             </Tooltip>

      //         </>
      //     )
      // },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            <Button className="ant-btn" onClick={() => this._editRecord(record)} size="middle">
              <Tooltip placement="top" key="edit" title={translation(translationCache, 'Edit')}>
                <EditOutlined />
              </Tooltip>
            </Button>
            <Popconfirm
              title={translation(
                this.props.translationCache,
                'Are you sure that you want to delete the ManPower Master'
              )}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this._deleteById(record.Id)}
              okText={translation(translationCache, constant.yes)}
              cancelText={translation(translationCache, constant.no)}
            >
              <Button className="ant-btn" size="middle">
                <Tooltip
                  placement="top"
                  key="delete"
                  title={translation(translationCache, 'Delete')}
                >
                  <span style={{ color: '#ff4d4f' }}>
                    {' '}
                    <DeleteOutlined />
                  </span>
                </Tooltip>
              </Button>
            </Popconfirm>
          </>
        )
      }
    ];
    let config = {
      title: translation(translationCache, 'ManPower'),

      viewTypes: ['add', 'card', 'table'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['FirstName'],

      firstTitle: 'FirstName',
      secondTitle: 'CreatedBy',
      thirdTitle: 'CreatedTime',

      actions: ['edit', 'delete'],
      edit: {
        byAttribute: 'Id',
        redirectURL: '/rubus/AMM/ManPowerDetails'
      },
      deletee: {
        byAttribute: 'Id'
      }
    };

    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 143 }}>
        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ManPowerList);
