import React from 'react';
import moment from 'moment';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import localStorage from '../../../../utils/localStorage';
import ReactEcharts from '../../Utils/Echarts/Echarts';
import { API_TRENDS, API_FILE } from '../../../../commons/api';
import { DatePicker, Drawer, Table } from 'antd';

const { RangePicker } = DatePicker;
export default class FunctionalSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      range: [],
      celd: [],
      datasource: [],
      visible: false,
      language: props.language || 'English',
      reportName: '',
      errorInAPI: false,
      noDataInDB: false,
      graphType: props.panelData && props.panelData.type ? props.panelData.type : 'line'
    };
  }

  async componentDidMount() {
    this.prepareData(this.state.graphType);
  }

  componentDidUpdate = (prevprops) => {
    if (
      prevprops.graphType !== this.props.graphType ||
      prevprops.panelData !== this.props.panelData
    ) {
      this.prepareData(this.state.graphType);
    }
  };

  getReportNametoDownload(excelData, type, excelDate) {
    let payload = {
      portName: '',
      name: type && type ? type : '',
      consumption: 'Trend',
      date: moment().format('YYYY-MM-DD'),
      time: moment().format('HH:mm:SS'),
      data: excelData || [],
      reportType: 'singleHierarchy',
      excelDate:
        moment.utc(excelDate.fromDate).local().format('YYYY-MM-DD HH:mm') +
        '~' +
        moment.utc(excelDate.toDate).local().format('YYYY-MM-DD HH:mm'),
      selectionType: 'dateRange',
      CurrentTime: moment().format('HH:mm:SS')
    };
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    let options = {};
    options = {
      method: 'POST',
      url: `${API_FILE.GenerateExcel}?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: JSON.stringify(payload)
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        return '';
      });
  }

  prepareData = async (graphType) => {
    const { panelData } = this.props;
    let option = cloneDeep(this.getOption(panelData));
    let xaxis = [];
    let series = [];
    // let newList = [];
    // newList =
    //   panelData &&
    //   panelData.trendData &&
    //   Array.isArray(panelData.trendData) &&
    //   panelData.trendData.map((columns, index) => {
    //     return {
    //       "S.No": index + 1,
    //       Timestamp: columns.DateTime,
    //       "Device Name": columns.DeviceName,
    //       "Parameter Name": columns.ParameterName,
    //       "Parameter Value": columns.Value,
    //     };
    //   });
    // let { fromDate, toDate } =
    //   panelData && panelData.tunedData ? panelData.tunedData : {};
    // let reportName = await this.getReportNametoDownload(
    //   newList,
    //   panelData.type,
    //   {
    //     fromDate,
    //     toDate,
    //   }
    // );
    let current = moment(panelData.trendObj && panelData.trendObj.CurrentTime).format(
      'DD-MM-YYYY HH:mm:ss'
    );
    let from = moment(panelData.trendObj && panelData.trendObj.CurrentTime)
      .add(panelData.formObject && panelData.formObject.FuturePredRangeFrom, 'minutes')
      .format('DD-MM-YYYY HH:mm:ss');
    let to = moment(panelData.trendObj && panelData.trendObj.CurrentTime)
      .add(panelData.formObject && panelData.formObject.FuturePredRangeTo, 'minutes')
      .format('DD-MM-YYYY HH:mm:ss');

    panelData &&
      panelData.trendData &&
      Array.isArray(panelData.trendData) &&
      panelData.trendData.map((data) => {
        data = {
          ...data,
          DateTime: data.DateTime
        };
        if (!xaxis.includes(data.DateTime)) {
          xaxis.push(data.DateTime);
        }
        if (series.map((data) => data.name).includes(data.Name)) {
          let index = series.map((data) => data.name).indexOf(data.Name);
          series[index].data.push([data.DateTime, data.Value]);
        } else {
          let obj = {
            name: data.Name,
            data: [[data.DateTime, data.Value]],
            type: graphType && graphType !== 'area' ? graphType : 'line',
            // markLine: {
            //   symbol: ["none", "none"],
            //   label: { show: true },
            //   data: [{ name: "Prediction Target", xAxis: current }],
            // },
            // markArea: {
            //   silent: true,
            //   itemStyle: {
            //     type: "dashed",
            //     opacity: 5.0,
            //   },
            //   data: [
            //     [
            //       {
            //         name: "Prediction Target",
            //         xAxis: from,
            //       },
            //       {
            //         xAxis: to,
            //       },
            //     ],
            //   ],
            // },
            ...(graphType && graphType === 'area' ? { areaStyle: {} } : {})
          };
          if (panelData.trendObj && panelData.trendObj.CurrentTime) {
            obj.markArea = {
              silent: true,
              itemStyle: {
                type: 'dashed',
                opacity: 5.0
              },
              data: [
                [
                  {
                    name: 'Prediction Target',
                    xAxis: from
                  },
                  {
                    xAxis: to
                  }
                ]
              ]
            };
            obj.markLine = {
              symbol: ['none', 'none'],
              label: { show: true },
              data: [{ name: 'Prediction Target', xAxis: current }]
            };
          }
          series.push(obj);
        }
        return {};
      });
    xaxis.sort((a, b) => {
      return new Date(a) - new Date(b);
    });
    option.xAxis.data = xaxis;
    option.series = series;
    if (panelData.colors) {
      option.color = panelData.colors;
    }

    this.setState({
      option
      // reportName: reportName && reportName.file ? reportName.file : "",
    });
  };

  // downLoadExcel = () => {
  //   const { reportName } = this.state;
  //   const link = document.createElement("a");
  //   link.href = `${process.env.REACT_APP_Primary_IP}/api/reports/${reportName}`;
  //   link.setAttribute("download", reportName); //or any other extension
  //   document.body.appendChild(link);
  //   link.click();
  // };

  getOption = () => {
    let { graphType } = this.state;
    let option = {
      backgroundColor: 'rgb(41, 65, 90)',
      title: {},
      legend: {
        icon: 'roundRect',
        textStyle: {
          color: 'white',
          fontSize: 15
        }
      },
      tooltip: {
        trigger: 'axis'
      },

      xAxis: {
        type: 'category',
        data: [],
        axisLine: {
          lineStyle: {
            color: 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: 'white'
          }
        },
        axisLabel: {
          color: 'white'
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: 'white'
          }
        },
        axisLabel: {
          color: 'white'
        }
      },
      toolbox: {
        showTitle: false,
        orient: 'horizontal',
        itemSize: 25,
        feature: {
          // myTool1: {
          //   show: true,
          //   title: "custom extension method 1",
          //   icon: "path://M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM514.1 580.1l-61.8-102.4c-2.2-3.6-6.1-5.8-10.3-5.8h-38.4c-2.3 0-4.5.6-6.4 1.9-5.6 3.5-7.3 10.9-3.7 16.6l82.3 130.4-83.4 132.8a12.04 12.04 0 0 0 10.2 18.4h34.5c4.2 0 8-2.2 10.2-5.7L510 664.8l62.3 101.4c2.2 3.6 6.1 5.7 10.2 5.7H620c2.3 0 4.5-.7 6.5-1.9 5.6-3.6 7.2-11 3.6-16.6l-84-130.4 85.3-132.5a12.04 12.04 0 0 0-10.1-18.5h-35.7c-4.2 0-8.1 2.2-10.3 5.8l-61.2 102.3z",

          //   onclick: function () {
          //     graph.downLoadExcel();
          //   },
          // },
          saveAsImage: {
            title: 'download',
            backgroundColor: '#011931',
            name: this.props.graphprops ? this.props.graphprops.title : 'name',
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      // dataZoom: [
      //   {
      //     type: "slider",
      //     show: true,
      //     start: 0,
      //     end: 100,
      //     backgroundColor: "#9a9191",
      //     textStyle: {
      //       color: "white",
      //     },
      //   },
      //   {
      //     type: "inside",
      //     start: 0,
      //     end: 100,
      //   },
      // ],

      series: {
        name: 'value',
        type: graphType,
        data: []
      }
    };
    return option;
  };

  onChartClick = (cellData) => {
    this.setState({
      celd: cellData
    });
    this.getModal(cellData);
  };

  getModal = async (cellData) => {
    const { panelData } = this.props;
    let to = cellData.data[0];
    let from = moment(cellData.data[0]).subtract(1, 'days').format('YYYY-MM-DD HH:MM');
    this.setState({ range: [from, to] });
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const headers = {
      method: 'POST',
      url: `${API_TRENDS.GetParametersData}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        data: [
          {
            parameterCode: panelData.tunedData.data[0].parameterCode,
            deviceId: panelData.tunedData.data[0].deviceId,
            parameterValue: cellData.data[1]
          }
        ],
        toDate: this.state.range[1],
        fromDate: this.state.range[0],
        SiteId: siteId
      }
    };
    axios(headers)
      .then((response) => {
        this.setState({ datasource: response.data });
      })
      .catch(() => {
        return {};
      });
    this.setState({
      visible: true
    });
  };

  onChange = (value, dateString) => {
    this.setState({
      range: [dateString[0], dateString[1]],
      momentRange: [moment(dateString[0]), moment(dateString[1])]
    });
  };

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };

  onhandleRangepicker = () => {
    let cellData = this.state.celd;
    this.getModal(cellData);
  };

  handleOk = () => {
    this.setState({ visible: false });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    let { datasource, option } = this.state;
    let { panelData } = this.props;
    const columns = [
      {
        title: 'Device Name',
        dataIndex: 'DeviceName',
        key: 'DeviceName'
      },
      {
        title: 'Parameter Name',
        dataIndex: 'ParameterName',
        key: 'ParameterName'
      },
      {
        title: 'Parameter Value',
        dataIndex: 'Value',
        key: 'Value'
      },
      {
        title: 'TimeStamp',
        dataIndex: 'DateTime',
        key: 'DateTime'
      }
    ];
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData)
    };
    return (
      <React.Fragment>
        <Drawer
          visible={this.state.visible}
          title="Historic Trend"
          width={1200}
          onOk={this.handleOk}
          onClose={this.handleCancel}
          footer={null}
        >
          <RangePicker
            style={{ width: 350, float: 'right' }}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            placeholder={['Start DateTime', 'End DateTime']}
            value={this.state.momentRange}
            onChange={this.onChange}
            onOk={this.onhandleRangepicker}
            disabledDate={this.disabledDate}
          />

          <Table dataSource={datasource} columns={columns} />
        </Drawer>

        <ReactEcharts
          option={option}
          onEvents={panelData.type === 'historic' ? onEvents : null}
          notMerge={true}
          lazyUpdate={true}
          style={{ height: '90%', width: '200%' }}
          className="react_for_echarts"
        />
      </React.Fragment>
    );
  }
}
