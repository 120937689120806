import React from 'react';

import moment from 'moment';
import { getTranslationCache } from '../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import themeSettings from '../Utils/themeSettings.json';
import { StyledComponent, StyledTable } from '../CSS/style';
import { getList } from '../Utils/FormCalls';
// import { constant } from '../Utils/Constants';
import { Button, Tooltip } from 'antd';
import { encode as base64_encode } from 'base-64';
// import translation from '../Utils/translation';
import history from '../../../commons/history';
import { FormOutlined } from '@ant-design/icons';
import { DRIFT } from '../../../commons/api';

const APIList = {
  getList: `${DRIFT.GET}`
};
class DriftCalculationWorkbench extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // columns: [
      //   {
      //     title: this.translation('Reference From Date'),
      //     dataIndex: 'RefFromDate',
      //     key: 'RefFromDate',
      //     render: (text) => {
      //       return {
      //         children:
      //           text !== undefined && text !== null ? (
      //             <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //           ) : null
      //       };
      //     }
      //   },
      //   {
      //     title: this.translation('Reference To Date'),
      //     dataIndex: 'RefToDate',
      //     key: 'RefToDate',
      //     render: (text) => {
      //       return {
      //         children:
      //           text !== undefined && text !== null ? (
      //             <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //           ) : null
      //       };
      //     }
      //   },
      //   {
      //     title: this.translation('Current From Date'),
      //     dataIndex: 'CurrentFromDate',
      //     key: 'CurrentFromDate',
      //     render: (text) => {
      //       return {
      //         children:
      //           text !== undefined && text !== null ? (
      //             <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //           ) : null
      //       };
      //     }
      //   },
      //   {
      //     title: this.translation('Current To Date'),
      //     dataIndex: 'CurrentToDate',
      //     key: 'CurrentToDate',
      //     render: (text) => {
      //       return {
      //         children:
      //           text !== undefined && text !== null ? (
      //             <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //           ) : null
      //       };
      //     }
      //   },
      //   {
      //     title: this.translation('Calculation Date'),
      //     dataIndex: 'CreatedTime',
      //     key: 'CreatedTime',
      //     render: (text) => {
      //       return {
      //         children:
      //           text !== undefined && text !== null ? (
      //             <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //           ) : null
      //       };
      //     }
      //   },
      //   {
      //     title: this.translation('Calculation By'),
      //     dataIndex: 'CreatedName',
      //     key: 'CreatedName'
      //   },
      //   {
      //     title: this.translation('Model Name'),
      //     dataIndex: 'ModelName',
      //     key: 'ModelName'
      //   },
      //   {
      //     title: this.translation('Description'),
      //     dataIndex: 'Name',
      //     key: 'Name'
      //   },
      //   {
      //     title: this.translation('Type'),
      //     dataIndex: 'Type',
      //     key: 'Type'
      //   },
      //   {
      //     title: this.translation('Action'),
      //     render: (text, record) => (
      //       <>
      //         <Tooltip
      //           title={
      //             record.model_1_Status === 'Completed' && record.model_2_Status === 'Completed'
      //               ? this.translation('Click to Compare')
      //               : this.translation('Open Details')
      //           }
      //         >
      //           <a
      //             // style={{ marginRight: "5px" }}
      //             onClick={() =>
      //               history.push(`/rubus/RbsAnalytics/MonitorDrift/${base64_encode(record.Id)}`)
      //             }
      //           >
      //             <FormOutlined />
      //           </a>
      //         </Tooltip>
      //       </>
      //     )
      //   }
      // ],
      dataSource: [],
      translationCache: props.translationCache || {}
    };
  }
  componentDidMount = async () => {
    let response = await getList(APIList.getList);
    this.setState({
      dataSource: response && Array.isArray(response) ? response : []
    });
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { dataSource } = this.state;
    const columns = [
      {
        title: this.translation('Reference From Date'),
        dataIndex: 'RefFromDate',
        key: 'RefFromDate',
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Reference To Date'),
        dataIndex: 'RefToDate',
        key: 'RefToDate',
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Current From Date'),
        dataIndex: 'CurrentFromDate',
        key: 'CurrentFromDate',
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Current To Date'),
        dataIndex: 'CurrentToDate',
        key: 'CurrentToDate',
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Calculation Date'),
        dataIndex: 'CreatedTime',
        key: 'CreatedTime',
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Calculation By'),
        dataIndex: 'CreatedName',
        key: 'CreatedName'
      },
      {
        title: this.translation('Model Name'),
        dataIndex: 'ModelName',
        key: 'ModelName'
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Name',
        key: 'Name'
      },
      {
        title: this.translation('Type'),
        dataIndex: 'Type',
        key: 'Type'
      },
      {
        title: this.translation('Action'),
        render: (text, record) => (
          <>
            <Tooltip
              title={
                record.model_1_Status === 'Completed' && record.model_2_Status === 'Completed'
                  ? this.translation('Click to Compare')
                  : this.translation('Open Details')
              }
            >
              <a
                // style={{ marginRight: "5px" }}
                onClick={() =>
                  history.push(`/rubus/RbsAnalytics/MonitorDrift/${base64_encode(record.Id)}`)
                }
              >
                <FormOutlined />
              </a>
            </Tooltip>
          </>
        )
      }
    ];
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '50px 30px', minHeight: window.innerHeight - 143 }}
        >
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Tooltip title={this.translation('Click here to register a new model')}>
              <Button
                onClick={() => history.push('/rubus/RbsAnalytics/MonitorDrift')}
                type="primary"
                style={{
                  marginBottom: 8
                }}
                size="middle"
              >
                {this.translation('New')}
              </Button>
            </Tooltip>
          </div>
          <StyledTable
            data-testid="workbenchList"
            theme={themeSettings}
            dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
            columns={columns}
          ></StyledTable>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(DriftCalculationWorkbench);
