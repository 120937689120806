import React, { Component } from 'react';
import axios from 'axios';
import { TreeSelect } from 'antd';

import localStorage from '../../../../../utils/localStorage';

class HierarchyDropdownSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      value: ''
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.headerJSON !== this.props.headerJSON) {
      this._getOptionList(this.props.headerJSON);
    }
  }

  componentDidMount() {
    this._getOptionList(this.props.headerJSON, 'initial');
  }
  transformKeys = (arr) => {
    return arr.map((item) => {
      const newItem = {
        title: item.Name,
        value: item.Code,
        ...item // Spread the rest of the properties
      };

      // Remove the old keys
      delete newItem.Name;
      delete newItem.Code;

      // If there are children, recursively transform them
      if (item.children) {
        newItem.children = this.transformKeys(item.children);
      }

      return newItem;
    });
  };
  _getOptionList = (headerJSON, type) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let objectHeader = {};

    if (
      headerJSON &&
      headerJSON.apiPayload &&
      headerJSON.apiPayload.url &&
      headerJSON.apiPayload.method === 'GET'
    ) {
      let { url, GETParams } = headerJSON.apiPayload;
      objectHeader = {
        method: 'GET',
        url: `${url}?SiteId=${siteId}${GETParams ? `&${GETParams}` : ''}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
    } else if (
      headerJSON &&
      headerJSON.apiPayload &&
      headerJSON.apiPayload.url &&
      headerJSON.apiPayload.method &&
      headerJSON.apiPayload.payload &&
      headerJSON.apiPayload.method === 'POST'
    ) {
      objectHeader = {
        method: headerJSON.apiPayload.method,
        url: `${headerJSON.apiPayload.url}?SiteId=${siteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: headerJSON.apiPayload.payload
      };
    }
    return axios(objectHeader)
      .then((response) => {
        if (Array.isArray(response.data)) {
          let optionList = response.data;
          optionList = this.transformKeys(optionList);

          if (type === 'initial') {
            this.props.handleDropdownChange(optionList[0].value, optionList[0]);
          }
          this.setState({
            optionList
          });
        }
      })
      .catch(() => {
        return [];
      });
  };

  handleChange = (selectedValue, selectedObject) => {
    this.props.handleDropdownChange(selectedValue, selectedObject);
  };

  render() {
    let { optionList } = this.state;
    return (
      <>
        <TreeSelect
          style={{ width: 300 }}
          showSearch
          value={this.props.value}
          placeholder="Select"
          allowClear
          treeDefaultExpandAll
          onSelect={(selectedValue, selectedObject) =>
            this.handleChange(selectedValue, selectedObject)
          }
          treeData={optionList}
        />
      </>
    );
  }
}

export default HierarchyDropdownSelection;
