import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getUserProfile } from '../../../selectors/layout';
import { StyledDashboard } from '../style';
import { getTranslationCache } from '../../../selectors/language';
import AIScreen from './AIScreen';

class AIGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 69 }}>
        <AIScreen />
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(AIGenerator);
