import { createSelector } from 'reselect';

const selectTheme = (state) => {
  return state.get('theme').get('theme');
};
const makeSelectTheme = () =>
  createSelector(selectTheme, (state) => {
    return state.get('theme');
  });

const makeSelectThemeing = () =>
  createSelector(selectTheme, (state) => {
    return state.get('themeing');
  });

const getNavigationMode = () =>
  createSelector(selectTheme, (state) => {
    return state.get('navigationMode');
  });

export { makeSelectTheme, makeSelectThemeing, getNavigationMode };
