import React from 'react';
import axios from 'axios';
import { Table, Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import localStorage from '../../../../utils/localStorage';
import { getTranslationCache } from '../../../../selectors/language';
import { StyledTable, lightTheme } from '../../../../commons/styles/table';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { columns } from './mock';
import '../style.css';
import { API_ORGANISATION } from '../../../../commons/api';
class Organisation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      columns,
      datasource: [],
      translationCache: props.translationCache || []
    };
  }

  componentDidMount() {
    this._getOrganisationList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }
  _getOrganisationList = () => {
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${API_ORGANISATION.GET}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then(({ data }) => {
        this.setState({ datasource: data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] && translationCache[keyword]
      ? translationCache[keyword]
      : keyword;
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation(`Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    let { datasource } = this.state;
    const columns = [
      {
        title: this.translation('Id'),
        dataIndex: 'Id',
        ...this.getColumnSearchProps('Id')
      },
      {
        title: this.translation('Organisation Name'),
        dataIndex: 'Name',
        ...this.getColumnSearchProps('Name')
      },
      {
        title: this.translation('Short Name'),
        dataIndex: 'ShortName',
        ...this.getColumnSearchProps('ShortName')
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Description',
        ...this.getColumnSearchProps('Description')
      },
      {
        title: this.translation('Tag Line'),
        dataIndex: 'TagLine',
        ...this.getColumnSearchProps('TagLine')
      },
      {
        title: this.translation('URL'),
        dataIndex: 'URL',
        ...this.getColumnSearchProps('URL')
      }
    ];
    return (
      <StyledTable theme={lightTheme}>
        <Table
          columns={columns}
          dataSource={datasource}
          onRow={() => {
            return {
              onClick: () => {
                this.props.history.push({
                  pathname: `/rubus/settingsPage/UserManagement/Site`
                });
              }
            };
          }}
          pagination={false}
        />
      </StyledTable>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(Organisation);
