import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Table, Form, Input, Drawer, Button, Card, message, Popconfirm, Select } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import localStorage from '../../../../utils/localStorage';
import { DrawerFooter } from '../style';
import { StyledTable, lightTheme } from '../../../../commons/styles/table';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../selectors/language';

import '../style.css';
// import { constant } from '../../Constants';
import { LANDINGPAGE } from '../../../../commons/api';
const { Option } = Select;

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      datasource: [],
      editObj: {},
      translationCache: props.translationCache || []
    };
  }
  departmentRef = React.createRef();

  componentDidMount() {
    this._getLandingPageList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  _getLandingPageList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${LANDINGPAGE.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({ datasource: data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  openModal = () => {
    if (this.departmentRef.current) {
      this.departmentRef.current.resetFields();
    }
    this.setState({
      visible: true,
      editObj: {}
    });
  };

  _cancel = () => {
    if (this.departmentRef.current) {
      this.departmentRef.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {}
    });
  };

  editLandingPage = (editObj) => {
    if (this.departmentRef.current) {
      this.departmentRef.current.setFieldsValue(editObj);
    }
    this.setState({
      editObj,
      visible: true
    });
  };

  _createLandingPage = (e) => {
    e.preventDefault();
    this.departmentRef.current
      .validateFields()
      .then(async (values) => {
        this._createLandingPageAPI(values);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _updateLandingPage = (e) => {
    e.preventDefault();
    let { editObj } = this.state;
    this.departmentRef.current
      .validateFields()
      .then(async (values) => {
        let updateObject = {
          ...values,
          Id: editObj.Id
        };
        this._updateLandingPageAPI(updateObject);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _deleteLandingPage = (Id) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgDeleteHeaders = {
      method: 'DELETE',
      url: `${LANDINGPAGE.DELETE}?SiteId=${siteId}&Id=${Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgDeleteHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          message.success('LandingPage Deleted');
          this._getLandingPageList();
          this.setState({ visible: false });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _createLandingPageAPI = (createObject) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgCreateHeaders = {
      method: 'POST',
      url: `${LANDINGPAGE.CREATE}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { ...createObject, SiteId: siteId }
    };
    axios(orgCreateHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          message.success('LandingPage Saved');
          this._getLandingPageList();
          this.setState({ visible: false });
          if (this.departmentRef.current) {
            this.departmentRef.current.resetFields();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _updateLandingPageAPI = (updateObject) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgUpdateHeaders = {
      method: 'PATCH',
      url: `${LANDINGPAGE.UPDATE}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: updateObject
    };
    axios(orgUpdateHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          message.success('LandingPage Updated');
          this._getLandingPageList();
          this.setState({ visible: false, editObj: {} });
          if (this.departmentRef.current) {
            this.departmentRef.current.resetFields();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  renderFormSwitch(renderFormItems) {
    let { disableOnUpdate } = renderFormItems;
    switch (renderFormItems.Widget) {
      case 'Select':
        return (
          <Select
            style={{ width: 350 }}
            placeholder={`Select ${renderFormItems.Key}`}
            onChange={(e) =>
              this.departmentRef.current.setFieldsValue({ [renderFormItems.Key]: e })
            }
          >
            {renderFormItems.Options &&
              renderFormItems.Options.map((option, index) => {
                if (
                  renderFormItems.OptionsSettings &&
                  renderFormItems.OptionsSettings.key &&
                  renderFormItems.OptionsSettings.value
                ) {
                  return (
                    <Option key={index} value={option[renderFormItems.OptionsSettings.value]}>
                      {option[renderFormItems.OptionsSettings.key]}
                    </Option>
                  );
                }
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
          </Select>
        );

      default:
        return (
          <Input
            style={{ width: 350 }}
            placeholder={this.translation(`Enter ${renderFormItems.Key}`)}
            disabled={disableOnUpdate && disableOnUpdate === true ? true : false}
          />
        );
    }
  }
  onFinishFailed = () => {
    message.error('please enter required fields');
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation(`Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    const { datasource, visible, editObj } = this.state;

    const columns = [
      {
        title: this.translation('Landing Page Name'),
        dataIndex: 'Name',
        ...this.getColumnSearchProps('Name')
      },
      {
        title: this.translation('LandingPage Code'),
        dataIndex: 'Code',
        ...this.getColumnSearchProps('Code')
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Description',
        ...this.getColumnSearchProps('Description')
      },
      {
        title: this.translation('Path'),
        dataIndex: 'Path',
        ...this.getColumnSearchProps('Path')
      },
      {
        title: this.translation('Action'),
        render: (text, record) => (
          <div>
            <button
              type="button"
              onClick={() => this.editLandingPage(record)}
              style={{ marginRight: '10px' }}
              className="ant-btn"
            >
              <EditOutlined />
            </button>

            {record && record.Default && record.Default !== true ? (
              <Popconfirm
                title={this.translation('Are you sure delete LandingPage ?')}
                onConfirm={(e) => {
                  e.stopPropagation();
                  this._deleteLandingPage(record.Id);
                }}
                okText={this.translation('Yes')}
                cancelText={this.translation('No')}
              >
                <button type="button" className="ant-btn">
                  <DeleteOutlined />
                </button>
              </Popconfirm>
            ) : null}
          </div>
        )
      }
    ];

    let formStructure = [
      {
        DisplayName: 'Name',
        Key: 'Name',
        Widget: 'Input'
      },
      {
        DisplayName: 'Code',
        Key: 'Code',
        Widget: 'Input'
      },
      {
        DisplayName: 'Description',
        Key: 'Description',
        Widget: 'Input'
      },
      {
        DisplayName: 'Path',
        Key: 'Path',
        Widget: 'Input'
      }
    ];

    return (
      <Card bordered={false}>
        <Drawer
          title={
            !editObj.Id
              ? this.translation('Create LandingPage')
              : this.translation('Update LandingPage')
          }
          visible={visible}
          width={600}
          closable
          onClose={this._cancel}
        >
          <Form
            layout="vertical"
            ref={this.departmentRef}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            name="user_form"
            initialValues={editObj || {}}
            onFinishFailed={this.onFinishFailed}
          >
            {formStructure &&
              Array.isArray(formStructure) &&
              formStructure.map((formItem, index) => {
                return (
                  <Form.Item
                    key={index}
                    name={formItem.Key}
                    label={this.translation(formItem.DisplayName)}
                  >
                    {this.renderFormSwitch(formItem)}
                  </Form.Item>
                );
              })}
          </Form>

          <DrawerFooter>
            <Button onClick={this._cancel} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            {!editObj.Id ? (
              <Button type="primary" onClick={this._createLandingPage}>
                {this.translation('Create')}
              </Button>
            ) : (
              <Button type="primary" onClick={this._updateLandingPage}>
                {this.translation('Update')}
              </Button>
            )}
          </DrawerFooter>
        </Drawer>
        <Button
          type="primary"
          size={'large'}
          style={{
            marginBottom: '10px',
            backgroundColor: '#214972',
            border: 'white'
          }}
          onClick={this.openModal}
        >
          <PlusOutlined /> {this.translation('Add')}
        </Button>
        <StyledTable theme={lightTheme}>
          <Table
            columns={columns}
            dataSource={datasource}
            pagination={
              datasource.length > 10
                ? {
                    pageSize: '10'
                  }
                : false
            }
          />
        </StyledTable>
      </Card>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(LandingPage);
