import { types } from './types';
import { message } from 'antd';
import { takeLatest, call, put } from 'redux-saga/effects';

import { userLoginAuthSuccess } from './login.duck';
import history from '../../commons/history';
import loginAuth from './login.requests';

export function* userLoginAuth(payload) {
  const loginsuccess = yield call(loginAuth.userAuth, payload.credentials);
  if (
    loginsuccess.message === 'Please change password' ||
    loginsuccess.message === 'Password has been expired!!' ||
    loginsuccess.message === 'Please change your default password'
  ) {
    history.push(`/changeFirstPassword/${payload.credentials.identifier}`);
  } else if (loginsuccess.message) {
    message.error(loginsuccess.message);
  } else {
    yield put(userLoginAuthSuccess(loginsuccess));
  }
}

export function* userSignUpAuth(payload) {
  const signupSuccess = yield call(loginAuth.signUpAuth, payload.payload);
  if (signupSuccess.message) {
    message.error(signupSuccess.message);
  } else {
    yield put(userLoginAuthSuccess(signupSuccess));
    message.success('User created');
  }
}

export default function* loginAuthSaga() {
  yield [
    takeLatest(types.USERLOGIN_AUTH, userLoginAuth),
    takeLatest(types.USERSIGNUP_AUTH, userSignUpAuth)
  ];
}
