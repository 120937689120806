export const constant = {
  //***trends label start***//

  Select_Parameter: 'Parameter Name',
  Value: 'Value',
  UOM: 'UoM',
  Status: 'Status'

  //***trends label stop***//
};
