import React from 'react';
import { Form, Input, message, Button, Table, Drawer, Tooltip, Popconfirm } from 'antd';
import { StyledComponent } from '../../CSS/style';
import { StyledButton, DrawerFooter } from '../../../../commons/styles/layout';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import axios from 'axios';
import localStorage from '../../../../utils/localStorage';
import themeSettings from '../../Utils/themeSettings.json';
import { UpdateMasters, CreateMasters, deleteMasters } from '../../APICalls';
import { API_RUBUSMASTERS } from '../../../../commons/api';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../selectors/language';
import { connect } from 'react-redux';
// import { tableTranslation } from '../../Adapter/TranslationAdapter';
const ApiList = {
  CreateAssetType: `${API_RUBUSMASTERS.AssetTypeCREATE}`,
  UpdateAssetType: `${API_RUBUSMASTERS.AssetTypeUPDATE}`,
  DeleteAssetType: `${API_RUBUSMASTERS.AssetTypeDELETE}`
};

class AssetTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      list: [],
      visibleAttributes: false
      // translationCache
    };
  }
  hForm = React.createRef();

  componentDidMount() {
    this._getList();
    // this.getFileDownloadName();
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  _getList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let url = '';
    url = `/api/rubusMaster/assetType/get?SiteId=${siteId}`;

    let orgHeaders = {
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ list: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  _editRecord = (record) => {
    // let { presentActiveKey } = this.state;
    this.hForm.current && this.hForm.current.setFieldsValue(record);
    this.setState({
      visibleAttributes: true,
      formObject: record,
      visible: true
    });
  };

  deleteRecord = async (id) => {
    let deleteResponse = await deleteMasters(ApiList.DeleteAssetType, id);
    message.success(deleteResponse && deleteResponse.message);
    this._getList();
  };

  onFinish = async () => {
    let { formObject } = this.state;
    this.hForm.current &&
      this.hForm.current
        .validateFields()
        .then(async (values) => {
          let payload = {
            ...values
          };

          if (formObject && formObject.Id) {
            let UpdateResponse = await UpdateMasters(
              ApiList.UpdateAssetType,
              payload,
              formObject.Id
            );
            message.success(UpdateResponse && UpdateResponse.message);
          } else {
            let createResponse = await CreateMasters(ApiList.CreateAssetType, payload);
            message.success(createResponse && createResponse.message);
          }
          this._getList();
          this.hForm.current &&
            this.hForm.current.setFieldsValue({
              Code: null,
              Name: null,
              ModuleCode: null,
              MaxLimit: null
            });
          this.setState({ formObject: {}, visibleAttributes: false });
        })
        .catch((error) => {
          console.log(error);
        });
  };

  OpenDrawer = () => {
    this.hForm.current &&
      this.hForm.current.setFieldsValue({
        Code: null,
        Name: null,
        ModuleCode: null,
        MaxLimit: null
      });
    let formObject = {
      Code: null,
      Name: null,
      ModuleCode: null,
      MaxLimit: null
    };
    this.setState({ visibleAttributes: true, formObject });
  };
  onClose = () => {
    this.hForm.current &&
      this.hForm.current.setFieldsValue({
        Code: null,
        Name: null,
        ModuleCode: null,
        MaxLimit: null
      });
    this.setState({ visibleAttributes: false, formObject: {} });
  };

  render() {
    let { list, formObject, visibleAttributes } = this.state;
    const columns = [
      // {
      //   title: 'S.No',
      //   dataIndex: 'S No',
      //   key: 'S No'
      // },
      {
        title: 'Module Code',
        dataIndex: 'ModuleCode',
        key: 'ModuleCode'
      },
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name'
      },
      {
        title: 'Action',
        dataIndex: 'Action',
        render: (text, record) => (
          <>
            <Button className="ant-btn" onClick={() => this._editRecord(record)} size="middle">
              <Tooltip title="Edit">
                {' '}
                <EditOutlined />
              </Tooltip>
            </Button>
            <Popconfirm
              title={'Are You Sure That You Want To Delete'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this.deleteRecord(record.Id)}
              okText={'yes'}
              cancelText={'No'}
            >
              <Button className="ant-btn" size="middle">
                <span style={{ color: '#ff4d4f' }}>
                  {' '}
                  <Tooltip title={'Delete'}>
                    <DeleteOutlined />
                  </Tooltip>
                </span>
              </Button>
            </Popconfirm>
          </>
        )
      }
    ];
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '0px 30px', minHeight: window.innerHeight - 143 }}
      >
        <div style={{ display: 'flex', justifyContent: 'right', padding: '20px' }}>
          <StyledButton onClick={this.OpenDrawer}>
            <PlusOutlined /> {this.translation('Add AssetTypes')}
          </StyledButton>
        </div>
        <Table
          className="basictable"
          columns={columns}
          dataSource={list}
          pagination={
            list.length > 10
              ? {
                  pageSize: '10'
                }
              : false
          }
        />
        <Drawer
          title={
            formObject && formObject.Id
              ? this.translation('Update AssetTypes')
              : this.translation('Add AssetTypes')
          }
          placement="right"
          width={600}
          closable
          onClose={this.onClose}
          visible={visibleAttributes}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            // theme={themeSettings}
            layout="horizontal"
            ref={this.hForm}
            name="Site_form"
            initialValues={formObject}
            onFinish={this.onFinish}
          >
            <Form.Item
              label={this.translation('Code')}
              name="Code"
              rules={[{ required: true, message: this.translation('Please enter Code !') }]}
            >
              <Input
                // disabled={formObject && formObject.Id ? true : false}
                placeholder={this.translation('Please enter Code')}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Name')}
              name="Name"
              rules={[{ required: true, message: this.translation('Please enter Name !') }]}
            >
              <Input placeholder={this.translation('Please enter Name')} />
            </Form.Item>
            <Form.Item
              label={this.translation('ModuleCode')}
              name="ModuleCode"
              rules={[{ required: true, message: this.translation('Please select ModuleCode') }]}
            >
              <Input placeholder={this.translation('Please enter ModuleCode')} />
            </Form.Item>
            <Form.Item
              label={this.translation('MaxLimit')}
              name="MaxLimit"
              rules={[{ required: true, message: this.translation('Please enter MaxLimit !') }]}
            >
              <Input
                // disabled={formObject && formObject.Id ? true : false}
                placeholder={this.translation('Please enter MaxLimit')}
              />
            </Form.Item>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            <Button type="primary" onClick={this.onFinish}>
              {this.translation('Submit')}
            </Button>
          </DrawerFooter>
        </Drawer>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(AssetTypes);
