import React, { Component } from 'react';
import { Tree, message, Checkbox } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import localStorage from '../../../utils/localStorage';

const { TreeNode } = Tree;

class HierarchyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      value: [],
      autoExpandParent: true.valueOf,
      expandedKeys: [],
      datasource: [],
      checkedKeys: [],
      treeData: this.props && this.props.treeData ? this.props.treeData : [],
      checkedArray: this.props && this.props.selectedDevice ? this.props.selectedDevice : []
    };
  }
  // componentDidUpdate(prevProps) {
  //   if (prevProps.selectedDevice !== this.props.selectedDevice) {
  //     this.setState({
  //       checkedArray: this.props.selectedDevice
  //     });
  //   }
  // }

  renameProperty = (obj, oldName1, newName1, oldName2, newName2) => {
    if (obj && obj.children) {
      let children = obj.children.map((objChildren) => {
        return this.renameProperty(objChildren, oldName1, newName1, oldName2, newName2);
      });
      obj['children'] = children;
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    } else {
      obj[newName1] = obj[oldName1];
      delete obj[oldName1];
      obj[newName2] = obj[oldName2];
      delete obj[oldName2];
      return obj;
    }
  };

  getMenuHierarchyStructure = () => {
    JSON.parse(localStorage.get('modules'));
    let { treeData } = this.state;
    if (treeData) {
      let final = treeData.map((userHierarchy) => {
        return this.renameProperty(userHierarchy, 'label', 'title', 'value', 'key');
      });
      return final;
    }
  };

  getData = (item) => {
    let { treeData } = this.state;
    let { Key } = this.props;
    if (Key === 'Asset') {
      if (item && item.Type === 'Site') {
        message.error('Cant select site');
      } else if (item.Type !== 'Device') {
        this.props.handleDropdownChange(item);
      } else {
        message.error('Please select valid input');
      }
    } else {
      this.props.handleDropdownChange(item);
      this.getParent(item.Name, item.ParentId, treeData);
    }
  };
  getParent = (Name, ParentId, treeData) => {
    let firstLevelParent = {};
    let children = [];
    let secondLevelParent = [];
    for (let i = 0; i < treeData.length; i++) {
      const node = treeData[i];
      if (node.children) {
        if (node.children.some((item) => item.Id === ParentId)) {
          firstLevelParent = node;
          children = node.children;
          if (children) {
            for (let i = 0; i < children.length; i++) {
              const childNode = children[i];
              if (childNode.children && childNode.children.length > 0) {
                if (childNode.Id === ParentId) {
                  secondLevelParent = childNode;
                }
              }
            }
          }
          if (this.props.handleParentNode && this.props.handleParentNode !== undefined) {
            this.props.handleParentNode(firstLevelParent, secondLevelParent);
          }
        } else if (this.getParent(Name, ParentId, node.children)) {
          this.getParent(Name, ParentId, node.children);
        }
      }
    }
    return firstLevelParent;
  };
  checkboxOnChange = (value) => {
    let { checkedArray } = this.state;
    if (checkedArray.includes(value)) {
      checkedArray = checkedArray.filter((e) => e !== value);
    } else {
      checkedArray.push(value);
    }
    this.setState({ checkedArray });
    this.props.handleDropdownChange(checkedArray);
  };

  renderTreeNodes = (data) => {
    let { checkedArray } = this.state;
    return data.map((item) => {
      if (item.children && item.children.length > 0) {
        return (
          <TreeNode
            key={item.key}
            title={
              <div>
                {` ${item.Code ? `${this.setTranslation(item.Code)} ` : ''} ${
                  item.Name ? `(${this.setTranslation(item.Name)})` : ''
                }`}
              </div>
            }
            dataRef={item}
            onChange={this.onTreeChange}
          >
            {this.renderTreeNodes(item.children, item)}
          </TreeNode>
        );
      }

      return (
        <TreeNode
          key={item.key}
          title={
            <div>
              {item.Type === 'Device' ? (
                <>
                  <div onClick={() => this.checkboxOnChange(item.Code)}>
                    <Checkbox
                      key={item.key}
                      checked={
                        checkedArray &&
                        Array.isArray(checkedArray) &&
                        checkedArray.filter((item1) => item1 === item.Code) &&
                        checkedArray.filter((item1) => item1 === item.Code)[0]
                          ? true
                          : false
                      }
                      value={item.Code}
                    />
                    {` ${item.Code ? `${this.setTranslation(item.Code)} ` : ''} ${
                      item.Name ? `(${this.setTranslation(item.Name)})` : ''
                    }`}
                  </div>
                </>
              ) : (
                <>
                  {` ${item.Code ? `${this.setTranslation(item.Code)} ` : ''} ${
                    item.Name ? `(${this.setTranslation(item.Name)})` : ''
                  }`}
                </>
              )}
            </div>
          }
          dataRef={item}
        />
      );
      // return (
      //   <TreeNode
      //     key={item.key}
      //     title={
      //       <div
      //         style={{ fontWeight: item.Type === 'Device' ? 'bold' : 'none' }}
      //         onClick={() => this.getData(item, item.key)}
      //       >
      //         {`${this.setTranslation(item.Code)} ${
      //           item.Name ? `(-${this.setTranslation(item.Name)})` : ''
      //         }`}
      //       </div>
      //     }
      //     dataRef={item}
      //   />
      // );
    });
  };

  setTranslation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] && translationCache[keyword]
      ? translationCache[keyword]
      : keyword;
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  render() {
    let { treeData, checkedArray } = this.state;
    return (
      <div
        style={{
          //  height: '70%',
          //  marginTop: '10px'
          borderRight: '1px dashed white',
          width: '100%',
          flex: '35%',
          overflowY: 'auto',
          height: window.innerHeight - 250
        }}
      >
        <Tree
          style={{ color: 'black' }}
          showLine
          checkedKeys={checkedArray}
          defaultExpandAll={true}
          switcherIcon={<DownOutlined />}
        >
          {this.renderTreeNodes(treeData)}
        </Tree>
      </div>
    );
  }
}

export default HierarchyModal;
