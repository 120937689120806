import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { StyledDashboard, StyledTable, lightTheme } from '../style.js';
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Radio,
  Select,
  Table,
  Tooltip,
  message
} from 'antd';
import {
  deleteMaintainance,
  getMaintainenceList,
  ruleList,
  saveMaintainance,
  updateMaintainance
} from '../Apicalls.js';
import { DrawerFooter } from '../../Modules/Hierarchy/style.js';
import { DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { constant } from '../../AMM/Utils/constants.js';
import { connect } from 'react-redux';
import { getCurrentTimezone } from '../../../selectors/layout.js';
import translation from '../../../utils/translation.js';
import { getTranslationCache } from '../../../selectors/language.js';
import { createStructuredSelector } from 'reselect';

const { Option } = Select;
class RuleEngineMaintainance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editObj: {},
      ruleData: {},
      Reset: false
    };
  }

  hRef = React.createRef();
  componentDidMount() {
    this.getDatasourceList();
    this.getRuleList();
  }

  getRuleList = async () => {
    let ruleData = await ruleList();
    this.setState({ ruleData });
  };

  getDatasourceList = async () => {
    let data = await getMaintainenceList();
    this.setState({ data });
  };

  _update = () => {
    let { editObj } = this.state;
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...values,
          StartDate: moment.utc(values.StartDate).format('YYYY-MM-DD HH:mm:ss'),
          EndDate: moment.utc(values.EndDate).format('YYYY-MM-DD HH:mm:ss'),
          Id: editObj.Id
        };
        let saveResponse = await updateMaintainance(values);
        if (saveResponse && saveResponse.data && saveResponse.data.message) {
          message.success(translation(this.props.translationCache, saveResponse.data.message));
          this.onClose();
          this.getDatasourceList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  _create = () => {
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        console.log('values', values);
        if (values.Reset === true) {
          let saveResponse = await saveMaintainance(values);
          if (saveResponse && saveResponse.data && saveResponse.data.message) {
            message.success(translation(this.props.translationCache, saveResponse.data.message));
            this.onClose();
            this.getDatasourceList();
          }
        } else {
          message.warn(translation(this.props.translationCache, 'Please check Reset !'));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onClose = () => {
    this.setState({ showDrawer: false, editObj: {} });

    if (this.hRef.current) this.hRef.current.resetFields();
  };

  add = () => {
    let defaultData = { LogicType: 'DateInterval', Reset: null };
    this.setState({
      showDrawer: true,
      editObj: defaultData,
      RuleId: [],
      StartDate: {},
      EndDate: {},
      NumberOfTimes: '',
      Reset: null
    });
    if (this.hRef.current) {
      this.hRef.current.setFieldsValue({
        defaultData,
        RuleId: [],
        StartDate: '',
        EndDate: '',
        NumberOfTimes: ''
      });
    }
  };

  _deleteById = async (Id) => {
    let deleteResponse = await deleteMaintainance(Id);
    if (deleteResponse && deleteResponse.message) {
      message.success(translation(this.props.translationCache, deleteResponse.message));
      this.getDatasourceList();
    }
  };

  onEdit = (editObj) => {
    _.cloneDeepWith(editObj, (value) => {
      return value && value !== null
        ? {
            ...value,
            ...(value['StartDate'] = moment.utc(value.StartDate, 'YYYY-MM-DD HH:mm:ss').local()),
            ...(value['EndDate'] = moment.utc(value.EndDate, 'YYYY-MM-DD HH:mm:ss').local())
          }
        : _.noop();
    });
    this.setState({
      editObj,
      showDrawer: true
    });

    if (this.hRef.current) this.hRef.current.setFieldsValue(editObj);
  };
  render() {
    let { data, showDrawer, editObj, ruleData } = this.state;
    let columns = [
      {
        title: translation(this.props.translationCache, 'Rule Name'),
        dataIndex: 'RuleName',
        key: 'RuleName',
        render: (text) => {
          return {
            children: <div>{text}</div>
          };
        }
      },
      {
        title: translation(this.props.translationCache, 'Created By'),
        dataIndex: 'CreatedbyName',
        key: 'CreatedbyName'
      },
      {
        title: translation(this.props.translationCache, 'Created Date'),
        dataIndex: 'CreatedDate',
        key: 'CreatedDate',
        render: (text) => {
          return {
            children: <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>
          };
        }
      },
      {
        title: translation(this.props.translationCache, 'Action'),
        dataIndex: 'Action',
        key: 'Action',
        render: (text, row) => {
          return {
            children: (
              <div>
                <Tooltip title={translation(this.props.translationCache, 'Edit')}>
                  <Button
                    className="ant-btn"
                    size="middle"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.onEdit(row);
                    }}
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
                <Popconfirm
                  title={translation(this.props.translationCache, 'Do you want to delete?')}
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  onConfirm={() => this._deleteById(row.Id)}
                  okText={translation(this.props.translationCache, 'Yes')}
                  cancelText={translation(this.props.translationCache, 'No')}
                >
                  <Button className="ant-btn" size="middle">
                    <span style={{ color: '#ff4d4f' }}>
                      <Tooltip title={translation(this.props.translationCache, constant.Delete)}>
                        {' '}
                        <DeleteOutlined />
                      </Tooltip>
                    </span>
                  </Button>
                </Popconfirm>
              </div>
            )
          };
        }
      }
    ];
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 69 }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0px' }}>
          <Button type="primary" onClick={() => this.add()}>
            {translation(this.props.translationCache, 'Add Maintainance')}
          </Button>
        </div>

        <Drawer
          title={
            !editObj.Id
              ? translation(this.props.translationCache, 'Create Maintainance')
              : translation(this.props.translationCache, 'Update Maintainance')
          }
          visible={showDrawer}
          width={600}
          onClose={this.onClose}
        >
          <Form
            layout="vertical"
            name={'MaintainanceForm'}
            ref={this.hRef}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={editObj || {}}
          >
            <Form.Item
              label={translation(this.props.translationCache, 'Select Rule')}
              name="RuleId"
              rules={[{ required: true }]}
            >
              <Select placeholder={translation(this.props.translationCache, 'Select Rule')}>
                {ruleData &&
                  Array.isArray(ruleData) &&
                  ruleData.map((rule, index) => {
                    return (
                      <Option key={index} value={rule.Id}>
                        {rule.DisplayRuleName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item name="LogicType" rules={[{ required: true }]}>
              <Radio.Group
                options={[
                  {
                    label: translation(this.props.translationCache, 'Date Interval'),
                    value: 'DateInterval'
                  },
                  {
                    label: translation(this.props.translationCache, 'No of Days'),
                    value: 'NoOfDays'
                  }
                ]}
                onChange={(e) =>
                  this.setState({ editObj: { ...this.state.editObj, LogicType: e.target.value } })
                }
              />
            </Form.Item>
            {editObj && editObj['LogicType'] === 'DateInterval' ? (
              <>
                <Form.Item
                  label={translation(this.props.translationCache, 'Start Date')}
                  name="StartDate"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    style={{ width: 300 }}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder={translation(this.props.translationCache, 'Select Start Date')}
                    showTime
                    disabledDate={(d) =>
                      !d ||
                      d.isAfter(
                        this.hRef.current &&
                          this.hRef.current !== null &&
                          this.hRef.current.getFieldValue() &&
                          this.hRef.current.getFieldValue('EndDate')
                          ? moment(
                              this.hRef.current.getFieldValue() &&
                                this.hRef.current.getFieldValue('EndDate')
                            ).format('YYYY-MM-DD HH:mm:ss')
                          : ''
                      ) ||
                      d.isBefore(moment().format('YYYY-MM-DD HH:mm:ss'))
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={translation(this.props.translationCache, 'End Date')}
                  name="EndDate"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    style={{ width: 300 }}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder={translation(this.props.translationCache, 'Select End Date')}
                    showTime
                    disabledDate={(d) =>
                      !d ||
                      d.isBefore(
                        this.hRef.current &&
                          this.hRef.current !== null &&
                          this.hRef.current.getFieldValue() &&
                          this.hRef.current.getFieldValue('StartDate')
                          ? moment(
                              this.hRef.current.getFieldValue() &&
                                this.hRef.current.getFieldValue('StartDate')
                            ).format('YYYY-MM-DD HH:mm:ss')
                          : ''
                      ) ||
                      d.isBefore(moment().format('YYYY-MM-DD HH:mm:ss'))
                    }
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item
                label={translation(this.props.translationCache, 'Number Of Days')}
                name="NumberOfDays"
                rules={[{ required: true }]}
              >
                <Input placeholder={translation(this.props.translationCache, 'Number Of Days')} />
              </Form.Item>
            )}

            <Form.Item
              label={translation(this.props.translationCache, 'Number Of Times')}
              name="NumberOfTimes"
              rules={[{ required: true }]}
            >
              <Input placeholder={translation(this.props.translationCache, 'Number Of Times')} />
            </Form.Item>

            <Form.Item
              label={translation(this.props.translationCache, 'Reset')}
              name="Reset"
              valuePropName="checked"
              rules={[{ required: true }]}
            >
              <Checkbox
                placeholder={translation(this.props.translationCache, 'Please input Reset!')}
                checked={this.state.Reset}
                onChange={(e) => {
                  this.setState({ Reset: e.target.checked });
                }}
              />
            </Form.Item>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {translation(this.props.translationCache, 'Cancel')}
            </Button>
            {!editObj.Id ? (
              <Button type="primary" onClick={() => this._create()}>
                {translation(this.props.translationCache, 'Create')}
              </Button>
            ) : (
              <Button type="primary" onClick={() => this._update()}>
                {translation(this.props.translationCache, 'Update')}
              </Button>
            )}
          </DrawerFooter>
        </Drawer>

        <StyledTable theme={lightTheme}>
          <Table columns={columns} dataSource={data && Array.isArray(data) ? data : []} />
        </StyledTable>
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(RuleEngineMaintainance);
