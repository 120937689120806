export const constant = {
  Master_Name: 'Master Name',
  Table_Name: 'Table Name',
  Add: 'Add',
  Columns: 'Columns',
  Unquie_Column_Relation: 'Unquie Column Relation',
  Read_API: 'Read API',
  Create_API: 'Create API',
  Update_API: 'Update API',
  Cancel: 'Cancel',
  Delete: 'Delete',
  Create: 'Create',
  Update: 'Update',
  Add_New: 'Add',
  Title: 'Title',
  Key: 'Key',
  Widget: 'Widget',
  Is_Mandatory: 'Is Mandatory',
  Disable_On_Update: 'Disable On Update',
  Configuration: 'Configuration',
  Url: 'Url',
  Method: 'Method',
  Payload: 'Payload',
  lookupCode: 'Lookup Code',
  description: 'Description',
  register: 'Register',
  createdBy: 'Created By',
  createdDate: 'Created Date',
  enabled: 'Enabled',
  action: 'Action',
  edit: 'Edit',
  yes: 'Yes',
  no: 'No',
  search: 'Search',
  reset: 'Reset',
  lookupItem: 'Lookup Item',
  lookupText: 'Lookup Text',
  goBack: 'Go Back',
  submit: 'Submit',
  save: 'Save'
};
