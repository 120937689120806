import React, { Component, Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tag } from 'antd';
import moment from 'moment';

import ReactEcharts from '../../Echarts/Echarts';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { guageMockJson } from '../../../Mock/MockJson';
import { getCurrentTimezone } from '../../../../../selectors/layout';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';

class Guage extends Component {
  constructor(props) {
    super(props);
    let option = this.getInitialState();
    this.state = {
      option: option.option,
      fromedit: props.graphprops.fromedit,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  requestCheck = true;
  abortController = new window.AbortController();

  // UNSAFE_componentWillReceiveProps(props) {
  //   let option = this.getInitialState();
  //   this.setState({
  //     option: option.option,
  //     fromedit: this.props.graphprops.fromedit === undefined ? false : true,
  //   })
  // }

  getOption = () => {
    let theme = this.props.selectedThemeing;

    let { graphprops } = this.props;

    let stringRange = graphprops.guage && graphprops.guage.range ? graphprops.guage.range : '[]';
    let uom = graphprops.guage && graphprops.guage.uom ? graphprops.guage.uom : '';

    let range = JSON.parse(stringRange);

    let min = range[0] || '';
    let max = range[1] || '';

    let colorArray = [];
    let colors =
      graphprops && graphprops.guage && graphprops.guage.colors ? graphprops.guage.colors : [];
    if (colors && Array.isArray(colors) && colors.length > 0) {
      let colorLength = colors.length;
      let staticValue = 100 / colorLength;
      let incrementValue = 0;
      colorArray =
        colors &&
        Array.isArray(colors) &&
        colors.map((colorData) => {
          incrementValue = staticValue + incrementValue;
          return [incrementValue / 100, colorData];
        });
    }

    return {
      tooltip: {
        formatter: '{c}'
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            // backgroundColor: "black",
            title: 'download',
            backgroundColor: theme === 'lightTheme' ? 'black' : 'black',
            name: graphprops && graphprops.title ? graphprops.title : '',
            iconStyle: {
              color: 'none',
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      series: [
        {
          name: uom,
          title: {
            offsetCenter: [0, '30%'],
            fontSize: 15,
            fontStyle: 'italic',
            color:
              this.props.graphprops &&
              this.props.graphprops.guage &&
              this.props.graphprops.guage.titleColor
                ? this.props.graphprops.guage.titleColor
                : ''
          },
          type: 'gauge',
          axisLabel: {
            color: 'auto',
            distance: 20,
            // color: '#999',
            fontSize: 14
          },

          axisTick: {
            show: true
          },
          splitLine: {
            length: 15,
            lineStyle: {
              width: 5,
              color: 'auto'
            }
          },

          axisLine: {
            lineStyle: {
              color:
                this.props.graphprops &&
                this.props.graphprops.guage &&
                this.props.graphprops.guage.guageType === 'normalGuage'
                  ? colorArray && Array.isArray(colorArray) && colorArray.length > 0
                    ? colorArray
                    : [
                        [0.2, '#e34b04'],
                        [0.8, '#fec91b'],
                        [1, '#6daa11']
                      ]
                  : [
                      [0.2, '#e34b04'],
                      [0.8, '#fec91b'],
                      [1, '#6daa11']
                    ],
              show: true,
              width: 18
            }
          },
          pointer: {
            itemStyle: {
              color: 'auto'
            }
          },

          detail: {
            formatter: `{value} ${uom}`,
            fontSize: 25,
            color:
              this.props.graphprops &&
              this.props.graphprops.guage &&
              this.props.graphprops.guage.titleColor
                ? this.props.graphprops.guage.titleColor[0]
                : 'auto',
            valueAnimation: true,
            // fontSize: 60,
            offsetCenter: [0, '80%']
          },
          splitNumber:
            this.props.graphprops &&
            this.props.graphprops.guage &&
            this.props.graphprops.guage.SplitNumber
              ? Number(this.props.graphprops.guage.SplitNumber)
              : 10,

          min: min,
          max: max,
          // min:
          //   graphprops && graphprops.range &&
          //     graphprops.range[0] && graphprops.range[0] !== undefined &&
          //     graphprops.range[0] !== null
          //     ? graphprops.range[0]
          //     : 0,
          // max:
          //   graphprops &&
          //     graphprops.range[1] !== undefined &&
          //     graphprops.range[1] !== null
          //     ? graphprops.range[1]:500,

          data: [
            {
              name: '',
              value: 0
            }
          ]
        }
      ]
    };
  };

  timeTicket = null;
  getInitialState = () => ({ option: this.getOption() });

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }
  getData = async () => {
    this.requestCheck = false;
    let { graphprops } = this.props;
    let guageType = graphprops && graphprops.guageType;
    let json = [];
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = guageMockJson;
      this.requestCheck = true;
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection({
          graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          selectionDateLive: this.props.selectionDateLive,
          currentTimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      const option = cloneDeep(this.state.option);

      if (json && Array.isArray(json) && json.length > 0) {
        let tagValue = Number(json[0].Value).toFixed(2);
        let progress = {};
        let anchor = {
          show: true,
          showAbove: true,
          size: 25,
          itemStyle: {
            borderWidth: 10
          }
        };
        if (guageType && guageType === 'speedGuage') {
          progress = {
            show: true,
            width: 18
          };
          option.series[0].progress = progress;
          option.series[0].anchor = anchor;
        }

        option.series[0].data[0].value = tagValue;
      }

      this.setState({
        option,
        refreshDateTime: moment()
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            id={Math.ceil(Math.random(100, 100000) * 1000)}
            option={this.state.option}
            style={{
              height: '105%',
              width: '100%'
            }}
            className="react_for_echarts"
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(Guage);
