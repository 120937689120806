import React from 'react';
import axios from 'axios';
import {
  Table,
  Drawer,
  Button,
  Form,
  Input,
  Checkbox,
  Row,
  Tree,
  Popconfirm,
  message,
  Select,
  Card,
  Tooltip,
  Radio,
  Divider
} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import rolesPermissions from './Adapter';
import { connect } from 'react-redux';
// import { constant } from '../../Constants';
import localStorage from '../../../../utils/localStorage';
import { StyledTable, lightTheme } from '../../../../commons/styles/table';
import { getTranslationCache } from '../../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import '../style.css';
import { API_HIERARCHYMODULE, API_MENU, API_ROLE, LANDINGPAGE } from '../../../../commons/api';

const { TreeNode } = Tree;
const { Option } = Select;
class Role extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      editObj: {},
      siteId: '',
      moduleList: [],
      configurationList: [],
      landingPageList: [],
      HierarchyList: [],
      permissionsList: {},
      checkedConfiguartionKeys: [],
      checkedModuleKeys: [],
      checkedApplicationSettingsKeys: [],
      checkedPermissionsKeys: {
        asset_device: [],
        dashboard: [],
        timeScale: []
      },
      configurationActualList: {},
      modulesActualList: {},
      applicationActualList: {},
      datasource: [],
      selectedKeys: [],
      expandedKeys: [],
      autoExpandParent: true,
      translationCache: props.translationCache || [],
      menuType: 'Web'
    };
    this.RoleConfigRef = React.createRef();
  }

  componentDidMount() {
    let siteId = this.props.match.params.siteId;
    this.setState({ siteId });
    this._getRolesList(siteId);
    this._getPermissionsList();
    this._getLandingPageList();
    this.getHierarchyList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  _getPermissionsList = () => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${API_ROLE.Permissions}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ permissionsList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _getRolesList = () => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${API_ROLE.GET}?SiteId=${siteId}&menuType=${this.state.menuType}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ datasource: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _getRolesById = (menuType, Id) => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${API_ROLE.GET}?SiteId=${siteId}&menuType=${menuType}&Id=${Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(orgHeaders)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  };

  _getModuleList = (menuType) => {
    let siteId = this.props.match.params.siteId;
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${API_MENU.Get}?SiteId=${siteId}&Type=Structured&menuType=${menuType}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        if (response.data[0]) {
          this.setState({
            configurationActualList: response.data[0].Configuration,
            modulesActualList: response.data[0].Modules,
            applicationSettingsActualList: response.data[0].ApplicationSettings
          });
          let configurationList = rolesPermissions.menuListAdapter(response.data[0].Configuration);
          let moduleList = rolesPermissions.menuListAdapter(response.data[0].Modules);
          let applicationList = rolesPermissions.menuListAdapter(
            response.data[0].ApplicationSettings
          );
          this.setState({ moduleList, configurationList, applicationList });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getHierarchyList = () => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let headers = {
      method: 'GET',
      url: `${API_HIERARCHYMODULE.MainHierarchyList}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({ HierarchyList: data });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  _getLandingPageList = () => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${LANDINGPAGE.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ landingPageList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  showDrawer = () => {
    if (this.RoleConfigRef.current) {
      this.RoleConfigRef.current.resetFields();
    }
    this.setState({
      editObj: {},
      visible: true,
      checkedConfiguartionKeys: [],
      checkedModuleKeys: [],
      checkedApplicationSettingsKeys: [],
      checkedPermissionsKeys: {
        asset_device: [],
        dashboard: [],
        timeScale: []
      }
    });
  };

  onClose = () => {
    if (this.RoleConfigRef.current) {
      this.RoleConfigRef.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {}
    });
  };

  _updateRole = (updateObject) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let updateHeader = {
      method: 'PATCH',
      url: `${API_ROLE.UPDATE}?SiteId=${siteId}&menuType=${this.state.menuType}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: updateObject
    };
    axios(updateHeader)
      .then((response) => {
        if (response && response.data) {
          this._getRolesList(updateHeader.site);
          this.RoleConfigRef.current.resetFields();
          message.success(response.data.message);
          this.setState({ visible: false, editObj: {} });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _saveRole = (createObject) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgCreateHeaders = {
      method: 'POST',
      url: `${API_ROLE.CREATE}?SiteId=${siteId}&menuType=${this.state.menuType}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: createObject
    };
    axios(orgCreateHeaders)
      .then((response) => {
        if (response && response.data) {
          this._getRolesList(createObject.site);
          this.RoleConfigRef.current.resetFields();
          message.success(response.data.message);
          this.setState({ visible: false });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _deleteRole = (role) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgDeleteHeaders = {
      method: 'DELETE',
      url: `${API_ROLE.DELETE}?SiteId=${siteId}&Id=${role.Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgDeleteHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          message.success('Role Deleted');
          this._getRolesList(role.site);
          this.RoleConfigRef.current.resetFields();
          this.setState({ visible: false });
        } else {
          message.error('Error in delete');
        }
      })
      .catch(function () {});
  };

  openDrawer = (e, record, menuType) => {
    let checkedPermissionsKeys = rolesPermissions.permissionAdapter(record.Permission);
    this._getModuleList(menuType);
    if (this.RoleConfigRef.current) {
      this.RoleConfigRef.current.setFieldsValue(record);
    }
    this.setState({
      visible: true,
      editObj: record,
      checkedPermissionsKeys,
      checkedModuleKeys: record.Modules,
      checkedConfiguartionKeys: record.Configuration,
      checkedApplicationSettingsKeys: record.ApplicationSettings
    });
  };

  onCheck = (name, checkedKey) => {
    if (name === 'configuration') {
      this.setState({ checkedConfiguartionKeys: checkedKey });
    } else if (name === 'module') {
      this.setState({ checkedModuleKeys: checkedKey });
    } else if (name === 'applicationSettings') {
      this.setState({ checkedApplicationSettingsKeys: checkedKey });
    }
  };

  onSubmit = (operation) => {
    let {
      checkedConfiguartionKeys,
      checkedApplicationSettingsKeys,
      checkedPermissionsKeys,
      checkedModuleKeys,
      siteId,
      editObj
    } = this.state;
    this.RoleConfigRef.current &&
      this.RoleConfigRef.current
        .validateFields()
        .then(async (values) => {
          let Permission = [];
          if (checkedPermissionsKeys) {
            Permission = rolesPermissions.createPermissions(checkedPermissionsKeys);
          }
          let saveObject = {
            ...editObj,
            ...values,

            OrganizationId: 1,
            SiteId: siteId,
            Permission,
            Modules: checkedModuleKeys || [],
            Configuration: checkedConfiguartionKeys || [],
            ApplicationSettings: checkedApplicationSettingsKeys || []
          };

          if (operation === 'create') {
            this._saveRole(saveObject);
          } else if (operation === 'update') {
            this._updateRole(saveObject);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    // const values = this.RoleConfigRef.current.getFieldsValue('Role_form');
  };

  renderTreeNodes = (data) =>
    data &&
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });

  onChange = (checkedValues, title) => {
    let { checkedPermissionsKeys } = this.state;
    let newObj = {
      ...checkedPermissionsKeys,
      [title]: checkedValues
    };
    this.setState({ checkedPermissionsKeys: newObj });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] && translationCache[keyword]
      ? translationCache[keyword]
      : keyword;
  };
  onFinishFailed = () => {
    message.error('please enter required fields');
  };

  handleSelectAll = (e) => {
    let { permissionsList } = this.state;
    let checkedPermissionsKeys = {};
    if (e.target.checked === true) {
      let modules = Object.keys(permissionsList);
      modules &&
        Array.isArray(modules) &&
        modules.map(async (module) => {
          checkedPermissionsKeys = {
            ...checkedPermissionsKeys,
            [module]: permissionsList[module].permission.map((p) => p.value)
          };
        });
      this.setState({ checkedPermissionsKeys });
    } else if (e.target.checked === false) {
      this.setState({ checkedPermissionsKeys });
    }
  };

  handleSelectAllConfig = (e) => {
    let { configurationList } = this.state;
    let checkedConfiguartionKeys = [];
    if (e.target.checked === true) {
      configurationList.map((c) => {
        return checkedConfiguartionKeys.push(c.key);
      });
      this.setState({ checkedConfiguartionKeys });
    } else if (e.target.checked === false) {
      this.setState({ checkedConfiguartionKeys });
    }
  };

  handleSelectAllModule = (e) => {
    let { moduleList } = this.state;
    let checkedModuleKeys = [];

    if (e.target.checked === true) {
      moduleList.map((module) => {
        return checkedModuleKeys.push(module.key);
      });
      this.setState({ checkedModuleKeys });
    } else if (e.target.checked === false) {
      this.setState({ checkedModuleKeys });
    }
  };
  handleSelectAllSettings = (e) => {
    let { applicationList } = this.state;
    let checkedApplicationSettingsKeys = [];

    if (e.target.checked === true) {
      applicationList.map((app) => {
        return checkedApplicationSettingsKeys.push(app.key);
      });
      this.setState({ checkedApplicationSettingsKeys });
    } else if (e.target.checked === false) {
      this.setState({ checkedApplicationSettingsKeys });
    }
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation(`Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  onMenuTypeChange = async (menuType) => {
    let editObj = {};
    if (this.state.editObj && this.state.editObj.Id) {
      let editObj1 = await this._getRolesById(menuType, this.state.editObj.Id);
      editObj = editObj1 && editObj1[0] ? editObj1[0] : {};
    } else {
      editObj = this.state.editObj;
    }

    this.setState({ menuType });
    this.openDrawer('', editObj, menuType);
  };
  render() {
    const {
      menuType,
      moduleList,
      configurationList,
      applicationList,
      checkedModuleKeys,
      checkedApplicationSettingsKeys,
      checkedConfiguartionKeys,
      checkedPermissionsKeys,
      datasource,
      editObj,
      landingPageList,
      permissionsList,
      HierarchyList,
      visible
    } = this.state;
    const columns = [
      {
        title: this.translation('Role Name'),
        dataIndex: 'Name',
        key: 'Name',
        ...this.getColumnSearchProps('Name')
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Description',
        key: 'Description',
        ...this.getColumnSearchProps('Description')
      },
      {
        title: this.translation('Action'),
        dataIndex: '',
        key: '',
        render: (text, record) => (
          <div>
            <Tooltip title={this.translation('Edit')}>
              <button
                type="button"
                onClick={(e) => this.openDrawer(e, record, this.state.menuType)}
                style={{ marginRight: '10px' }}
                className="ant-btn"
              >
                <EditOutlined />
              </button>
            </Tooltip>
            {record && record.Default && record.Default !== true ? (
              <Popconfirm
                title={this.translation('Are you sure delete Role ?')}
                onConfirm={(e) => {
                  e.stopPropagation();
                  this._deleteRole(record);
                }}
                okText={this.translation('Yes')}
                cancelText={this.translation('No')}
              >
                {' '}
                <Tooltip title={this.translation('Delete')}>
                  <button type="button" className="ant-btn">
                    <DeleteOutlined />
                  </button>
                </Tooltip>
              </Popconfirm>
            ) : null}
          </div>
        )
      }
    ];
    return (
      <Card bordered={false}>
        <Drawer
          title={
            editObj && editObj.Id
              ? this.translation('Update Role')
              : this.translation('Create Role')
          }
          placement="right"
          width={600}
          closable
          onClose={this.onClose}
          visible={visible}
        >
          <Form
            layout="vertical"
            ref={this.RoleConfigRef}
            name="Role_form"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={editObj || {}}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              label={<div style={{ fontWeight: 'bold' }}>{this.translation('Role Name')}</div>}
              name="Name"
              rules={[{ required: true, message: 'Please input your rolename!' }]}
            >
              <Input
                placeholder={this.translation('Please enter Role Name')}
                disabled={editObj.Id ? true : false}
              />
            </Form.Item>

            <Form.Item
              label={<div style={{ fontWeight: 'bold' }}>{this.translation('Description')}</div>}
              name="Description"
              rules={[{ required: true, message: 'Please input your description!' }]}
            >
              <Input placeholder={this.translation('Please enter Description')} />
            </Form.Item>

            <Form.Item
              label={<div style={{ fontWeight: 'bold' }}>{this.translation('Landing Page')}</div>}
              name="LandingPageId"
              rules={[{ required: true, message: 'Please select Landing Page !' }]}
            >
              <Select>
                {landingPageList.map((value, index) => {
                  return (
                    <Option key={index} value={value.Id}>
                      {value.Name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label={<div style={{ fontWeight: 'bold' }}>{this.translation('Hierarchy')}</div>}
              name="Hierarchy"
              rules={[{ required: true, message: 'Please select Hierarchy!' }]}
            >
              <Select mode="multiple">
                {HierarchyList.map((value, index) => {
                  return (
                    <Option key={index} value={value.Id}>
                      {value.HierarchyName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Checkbox onChange={(e) => this.handleSelectAll(e, 'selectAll')}>SelectAll</Checkbox>

            {Object.keys(permissionsList).map((permissionName, index) => {
              let { name, key, permission } = permissionsList[permissionName];
              return (
                <>
                  <Form.Item
                    key={index}
                    ref={this.formRef}
                    label={<div style={{ fontWeight: 'bold' }}>{this.translation(name)}</div>}
                  >
                    <Checkbox.Group
                      value={
                        checkedPermissionsKeys && checkedPermissionsKeys[key]
                          ? checkedPermissionsKeys[key]
                          : []
                      }
                      onChange={(e) => this.onChange(e, key)}
                    >
                      <Row>
                        {permission &&
                          Array.isArray(permission) &&
                          permission.map(({ label, value }) => {
                            return (
                              <Checkbox key={value} value={value}>
                                {this.translation(label)}
                              </Checkbox>
                            );
                          })}
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </>
              );
            })}

            <Radio.Group
              style={{ float: 'right' }}
              onChange={(e) => {
                this.onMenuTypeChange(e.target.value);
              }}
              value={menuType}
            >
              <Radio value={'Web'}>Web</Radio>
              <Radio value={'Mobile'}>Mobile</Radio>
            </Radio.Group>

            <Form.Item
              label={<div style={{ fontWeight: 'bold' }}>{this.translation('Configuration')}</div>}
            >
              <Checkbox
                style={{ marginLeft: '23px' }}
                onChange={(e) => this.handleSelectAllConfig(e, 'selectAll')}
              >
                Select all
              </Checkbox>
              <Divider style={{ background: '#e6e5e5', margin: '5px 0px' }} />
              <Tree
                defaultExpandAll={true}
                checkable
                onCheck={(e) => this.onCheck('configuration', e)}
                checkedKeys={checkedConfiguartionKeys}
              >
                {this.renderTreeNodes(configurationList)}
              </Tree>
            </Form.Item>

            <Form.Item
              label={<div style={{ fontWeight: 'bold' }}>{this.translation('Modules')}</div>}
            >
              <Checkbox
                style={{ marginLeft: '23px' }}
                onChange={(e) => this.handleSelectAllModule(e, 'selectAll')}
              >
                Select all
              </Checkbox>
              <Divider style={{ background: '#e6e5e5', margin: '5px 0px' }} />
              <Tree
                defaultExpandAll={true}
                checkable
                onCheck={(e) => this.onCheck('module', e)}
                checkedKeys={checkedModuleKeys}
              >
                {this.renderTreeNodes(moduleList)}
              </Tree>
            </Form.Item>

            <Form.Item
              label={
                <div style={{ fontWeight: 'bold' }}>{this.translation('Application Settings')}</div>
              }
            >
              <Checkbox
                style={{ marginLeft: '23px' }}
                onChange={(e) => this.handleSelectAllSettings(e, 'selectAll')}
              >
                Select all
              </Checkbox>
              <Divider style={{ background: '#e6e5e5', margin: '5px 0px' }} />
              <Tree
                defaultExpandAll={true}
                style={{ marginBottom: '50px' }}
                checkable
                onCheck={(e) => this.onCheck('applicationSettings', e)}
                checkedKeys={checkedApplicationSettingsKeys}
              >
                {this.renderTreeNodes(applicationList)}
              </Tree>
            </Form.Item>
          </Form>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right'
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            {Object.keys(editObj).length === 0 ? (
              <Button onClick={() => this.onSubmit('create')} type="primary">
                {this.translation('Create')}
              </Button>
            ) : (
              <Button onClick={() => this.onSubmit('update')} type="primary">
                {this.translation('Update')}
              </Button>
            )}
          </div>
        </Drawer>

        <Button
          type="primary"
          onClick={this.showDrawer}
          size={'large'}
          style={{
            marginBottom: '10px',
            backgroundColor: '#214972',
            border: 'white'
          }}
        >
          <PlusOutlined />
          {this.translation('Add')}
        </Button>

        <StyledTable theme={lightTheme}>
          <Table dataSource={datasource} columns={columns} />
        </StyledTable>
      </Card>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(Role);
