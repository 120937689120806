import React, { useState, useEffect } from 'react';
// import 'antd/dist/antd.css';
import './CSS/index.css';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../selectors/language';
import { connect } from 'react-redux';
import translation from './Utils/translation';
import { constant } from './Utils/Constants';
import { Form, Input, Popconfirm, Table, Typography, Button, Switch, Tooltip, message } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  disabled,
  ...restProps
}) => {
  const inputNode =
    inputType === 'checkbox' ? (
      <Switch />
    ) : (
      <Input
        style={title === 'Lookup Item' ? { 'text-transform': 'uppercase' } : null}
        disabled={disabled}
      />
    );
  return (
    <td {...restProps}>
      {editing ? (
        inputType !== 'checkbox' ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0
            }}
            rules={[
              {
                required: inputType === 'checkbox' ? false : true,
                message: `Please Input ${title}!`
              }
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Form.Item
            name={dataIndex}
            valuePropName="checked"
            style={{
              margin: 0
            }}
            rules={[
              {
                required: inputType === 'checkbox' ? false : true,
                message: `Please Input ${title}!`
              }
            ]}
          >
            {inputNode}
          </Form.Item>
        )
      ) : (
        children
      )}
    </td>
  );
};

const App = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const translationCache = props.translationCache || [];
  const isEditing = (record) => {
    return record && record.key === editingKey;
  };
  const isDisable = (record, col) => {
    return record && record[props.uniqueKey] && col['disable'] ? true : false;
  };
  const edit = (record) => {
    form.setFieldsValue({
      ...record
    });
    setEditingKey(record.key);
    props.disableSubmit(true);
  };
  useEffect(() => {
    if (props) {
      setData(props.dataSource);
      if (props.edit === true) {
        let record = props.dataSource[props.dataSource.length - 1];
        form.setFieldsValue({
          ...record
        });
        setEditingKey(record.key);
        isEditing(record);
        props.disableSubmit(true);
      }
    }
  }, [props]);
  const cancel = () => {
    setEditingKey('');
    props.disableSubmit(false);
  };
  const deleteRow = (id) => {
    setEditingKey('');
    let newData = data.filter((item) => item.key !== id);
    setData(newData);
    props.saveRow(newData);
    // if(row && row !== null){
    //   props.deleteRow(row.Lookup_Item_Id,row.Lookup_Id);
    // }
  };
  const save = async (uniqueKey, key, id) => {
    try {
      let row = await form.validateFields();
      row = {
        ...row,
        LookupItem: row['LookupItem'].toUpperCase()
      };
      const newData = [...data];
      let duplicateCheck = newData.filter((item) => row['LookupItem'] === item['LookupItem']);
      if ((duplicateCheck && duplicateCheck.length === 0) || id !== undefined) {
        const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row
          });
          setData(newData);
          setEditingKey('');
          props.saveRow(newData);
        } else {
          setEditingKey('');
          newData.push(row);
          setData(newData);
        }
      } else {
        message.error(translation(translationCache, 'Lookup Item Already Exists'));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    ...props.columns,
    {
      title: translation(translationCache, constant.action),
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);

        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record[props.uniqueKey], record.key, record['LookupItemId'])}
              style={{
                marginRight: 8
              }}
            >
              {translation(translationCache, constant.save)}
            </Typography.Link>
            {/* <Popconfirm title="Sure to cancel?" onConfirm={cancel}> */}
            <Tooltip text={translation(translationCache, constant.Cancel)}>
              <a onClick={cancel}>{translation(translationCache, constant.Cancel)}</a>
            </Tooltip>
            {/* </Popconfirm> */}
          </span>
        ) : (
          <span>
            <Tooltip title={translation(translationCache, constant.edit)}>
              <Button
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
                style={{ marginRight: '5px' }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            {/* <Typography.Link  style={{
                marginRight: 8,
              }} disabled={editingKey !== ''} >
            <EditOutlined/> */}
            {/* </Typography.Link> */}
            <Popconfirm
              title={translation(translationCache, 'Are You Sure to delete?')}
              onConfirm={() => deleteRow(record.key ? record.key : null)}
            >
              <Tooltip title={translation(translationCache, constant.Delete)}>
                <Button className="ant-btn" size="middle">
                  <span style={{ color: '#ff4d4f' }}>
                    {' '}
                    <DeleteOutlined />
                  </span>
                </Button>
              </Tooltip>
            </Popconfirm>
          </span>
        );
      }
    }
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'Enabled' ? 'checkbox' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        disabled: isDisable(record, col),
        editing: isEditing(record),
        props
      }),
      onRow: (record) => ({
        record,
        inputType: col.dataIndex === 'Enabled' ? 'checkbox' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        disabled: isDisable(record, col),
        editing: isEditing(record),
        props
      })
    };
  });

  return (
    <div>
      <Tooltip title={translation(translationCache, 'Click here to add a New Lookup Item')}>
        <Button
          onClick={() => props.addRow()}
          type="primary"
          style={{
            marginBottom: 16
          }}
          data-testid="Addbutton"
          // disabled={editingKey !== ""}
        >
          <PlusOutlined /> {translation(translationCache, constant.Add)}
        </Button>
      </Tooltip>
      <Form form={form} component={false}>
        {/* <StyledTable theme={themeSettings}> */}
        <Table
          className="basictable"
          components={{
            body: {
              cell: EditableCell
            }
          }}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel
          }}
        />
        {/* </StyledTable> */}
      </Form>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(App);
