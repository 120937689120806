import React, { Component } from 'react';
import moment from 'moment';
import { Input, Upload, Button, Tooltip, message, TimePicker, DatePicker } from 'antd';
import { SaveOutlined, UploadOutlined, EditOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import SelectComponent from './SelectComponent';
import localStorage from '../../../utils/localStorage';
import styles from '../CSS/style.less';
import { getTranslationCache } from '../../../selectors/language';
import { constant } from '../Utils/constants';
import translation from '../Utils/translation';
import themeSettings from '../Utils/themeSettings.json';
import { createStructuredSelector } from 'reselect';
import { StyledButton, StyledTable } from '../CSS/style';
import InputNumberComponent from './InputNumberComponent';
import TextComponent from './TextComponent';
import { MASTER } from '../../../commons/api';

class TableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: props.form || {},
      columns: props.form && props.form.columns ? props.form.columns : [],
      modal: props.form && props.form.modal ? props.form.modal : [],
      data:
        props.form && props.form.datasource
          ? this.props.form.datasource.map((dataSource, index) => {
              return {
                ...dataSource,
                key: index + 1
              };
            })
          : [],
      autoPopulateData: props && props.autoPopulateData ? this.props.autoPopulateData : [],
      open: false,
      saved: true,
      translationCache: props.translationCache || []
    };
  }

  componentDidMount() {
    let columns = this.generateColumns(
      this.props.form && this.props.form.columns ? this.props.form.columns : []
    );
    this.setState({
      form: this.props.form,
      columns,
      modal: this.props.form && this.props.form.modal ? this.props.form.modal : [],
      data:
        this.props.form && this.props.form.datasource
          ? this.props.form.datasource.map((dataSource, index) => {
              return {
                ...dataSource,
                key: index + 1
              };
            })
          : []
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (prevProps.form !== this.props.form) {
      let columns = this.generateColumns(this.props.form.columns);
      this.setState({
        form: this.props.form,
        columns,
        data:
          this.props.form && this.props.form.datasource
            ? this.props.form.datasource.map((dataSource, index) => {
                return {
                  ...dataSource,
                  key: index + 1
                };
              })
            : []
      });
    }
  }

  generateColumns = (columns) => {
    let finalColumns = [];
    let { translationCache } = this.props;
    if (columns && columns.length > 0) {
      finalColumns = columns.map((column) => {
        return {
          title: translation(translationCache, column.title),
          dataIndex: column.key || 0,
          key: column.key || 0,
          width: column.width,
          widget: column.widget,
          required: column.required,
          table: column.table || {},
          render: (text, record) => {
            if (record.editable) {
              return this.renderWidget(column, text, record, columns);
            }

            if (Array.isArray(text)) {
              return text.map((file, index) => {
                return (
                  <div key={index}>
                    <a href={file.url}>{file.name}</a>
                  </div>
                );
              });
            } else if (column.widget === 'timePicker') {
              return text;
            } else if (column.widget === 'TimePicker') {
              return moment(text).format('HH:mm');
            } else if (column.widget === 'datePicker') {
              return moment(text).format('YYYY-MM-DD');
            } else if (column.key === 'name') {
              return (
                <div>
                  <a style={{ color: '#062d57' }} href={record.base64}>
                    {text}
                  </a>
                </div>
              );
            } else if (column.key === 'InspectionId') {
              return (
                <div>
                  {/* {text} */}
                  <TextComponent {...{ column, text, record, columns }} />
                </div>
              );
            } else {
              return text && text !== 'null' ? text : '';
            }
          }
        };
      });
    }
    return finalColumns;
  };

  //   deleteFile = async (file) => {
  //     if (file && file.response && file.response.name) {
  //       let deleteFile = await deleteFileUpload(file.response.name);
  //       message.success("File Deleted");
  //     } else {
  //       message.error("Error deleting file");
  //     }
  //   };

  onFileChange = (info, column, text, record) => {
    if (info.file.status === 'done') {
      let fieldValue = [];
      if (text && Array.isArray(text) && text !== undefined) {
        fieldValue = [...text, info.file.response];
      } else if (!text) {
        fieldValue = [info.file.response];
      }
      this.handleFieldChange(fieldValue, column.key, record.key);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status === 'removed') {
      //   this.deleteFile(info.file);
      this.handleFieldChange(info.fileList, column.key, record.key);
    }
  };

  renderWidget = (column, text, record, columns) => {
    const token = localStorage.get('accessToken');
    let defaultFileList = text && Array.isArray(text) ? text : [];
    var Authorization = 'Bearer ' + token;
    const renderProps = {
      name: 'file',
      action: `${MASTER.FileUpload}`,
      headers: {
        Authorization: Authorization
      },
      defaultFileList
    };
    switch (column.widget) {
      case 'input':
        return (
          <Input
            // dataType={column && column.dataType ? column.dataType : null}
            autoFocus
            value={text && text !== 'null' ? text : ''}
            style={{ fontWeight: 'bold' }}
            onChange={(e) => this.handleFieldChange(e.target.value, column.key, record.key)}
            maxLength={columns && columns.maxLength ? columns.maxLength : 100000}
            onKeyPress={(e) => this.handleKeyPress(e.target.value, record.key)}
            placeholder={column.placeHolder || column.displayName}
            disabled={column.disable ? column.disable : false}
          />
        );
      case 'inputNumber':
        return column.widgetCondition &&
          column.widgetCondition === true &&
          record.widget !== undefined &&
          record.widget !== true ? (
          <InputNumberComponent value="" disabled style={{ width: '90%', fontWeight: 'bold' }} />
        ) : (
          <InputNumberComponent
            value={text && text !== 'null' ? text : ''}
            style={{ width: '90%', fontWeight: 'bold' }}
            min={column.min !== undefined ? column.min : 0}
            max={column.max !== undefined ? column.max : 1000000}
            onChange={(e) =>
              this.inputNumberChange(column.min, column.max, e, column.key, record.key)
            }
            placeholder={column.placeHolder || column.displayName}
          />
        );
      case 'select':
        return (
          <SelectComponent
            data={this.state.data}
            column={column}
            record={record}
            text={text}
            handleFieldChange={this.handleFieldChange}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'upload':
        return (
          <Upload
            {...renderProps}
            onChange={(info) => this.onFileChange(info, column, text, record)}
          >
            <Button>
              <UploadOutlined /> Upload
            </Button>
          </Upload>
        );
      case 'timePicker':
        return (
          <TimePicker
            format="HH:mm:ss"
            value={text && text !== 'Invalid date' ? moment(text, 'HH:mm:ss') : undefined}
            style={{ width: 300 }}
            disabled={columns.disable ? columns.disable : false}
            onChange={(e) => this.handleFieldChange(e, column.key, record.key)}
            onOpenChange={() => this.setState({ open: true })}
            open={this.state.open}
            addon={() => (
              <Button size="small" type="primary" onClick={() => this.setState({ open: false })}>
                Ok
              </Button>
            )}
          />
        );
      case 'TimePicker':
        return (
          <TimePicker
            format="HH:mm"
            value={text && text !== 'Invalid date' ? moment(text, 'HH:mm') : undefined}
            style={{ width: 300 }}
            disabled={columns.disable ? columns.disable : false}
            onChange={(e) => this.handleFieldChange(e, column.key, record.key)}
            // onOpenChange={() => this.setState({ open: true })}
            // open={this.state.open}
            // addon={() => (
            //   <Button size="small" type="primary" onClick={() => this.setState({ open: false })}>
            //     Ok
            //   </Button>
            // )}
          />
        );
      case 'datePicker':
        return (
          <DatePicker
            format="YYYY-MM-DD"
            value={text && text !== 'Invalid date' ? moment(text, 'YYYY-MM-DD') : undefined}
            style={{ width: 300 }}
            disabled={
              column.dataIndex &&
              column.dataIndex === 'EffectiveEndDate' &&
              !record.EffectiveStartDate
                ? true
                : false
            }
            // disabled={true}
            onChange={(e) => this.handleFieldChange(e, column.key, record.key)}
            // onOpenChange={() => this.setState({ open: true })}
            // open={this.state.open}
            disabledDate={(current) => {
              if (column && column.dataIndex && column.dataIndex === 'EffectiveEndDate') {
                return (
                  !current ||
                  current.isBefore(
                    record.EffectiveStartDate
                      ? moment(record.EffectiveStartDate).format('YYYY-MM-DD')
                      : ''
                  )
                );
              } else if (column && column.dataIndex && column.dataIndex === 'EffectiveStartDate') {
                return (
                  moment() >= current ||
                  current.isAfter(
                    record.EffectiveEndDate
                      ? moment(record.EffectiveEndDate).format('YYYY-MM-DD')
                      : ''
                  )
                );
              } else if (
                column &&
                column.datePickerValidation &&
                column.datePickerValidation.disable &&
                column.datePickerValidation.disable === 'past'
              ) {
                return moment() >= current || moment().add(1, 'month') <= current;
              } else {
                return false;
              }
            }}
            addon={() => (
              <Button size="small" type="primary" onClick={() => this.setState({ open: false })}>
                Ok
              </Button>
            )}
          />
        );
      // case "checkbox":
      //   return <Checkbox
      //     onChange={this.checkboxOnChange}
      //   >select</Checkbox>

      default:
        return text;
    }
  };

  saveRow(e, key, record) {
    let { columns } = this.state;
    e.persist();
    this.setState({
      loading: true
    });

    const target = this.getRowByKey(key) || {};
    let checkReqField = false;
    columns &&
      Array.isArray(columns) &&
      columns.map((columnsObject) => {
        if (
          columnsObject['required'] &&
          (columnsObject['required'] === 'true' || columnsObject['required'] === true)
        ) {
          if (
            target[columnsObject.key] ||
            (target[columnsObject.key] === 0 && target[columnsObject.key] !== '') ||
            (record && record.widget === false)
          ) {
            return {};
          } else {
            e.target.focus();
            checkReqField = true;
          }
        }
        return {};
      });

    if (checkReqField) {
      this.setState({ saved: false });
      message.warn('Please fill in the complete information');
    } else {
      this.saveToggleEditable(e, key);
      this.setState({
        loading: false,
        saved: true
      });
    }
  }
  addRow = () => {
    let { data, modal } = this.state;
    let dataLength = data.length + 1;
    let newdata = {
      key: dataLength,
      editable: true
    };
    this.setState({
      saved: false
    });
    modal &&
      Array.isArray(modal) &&
      modal.map((columnData) => {
        if (columnData.key === 'Task') {
          let maxTask =
            data && Array.isArray(data) && data.length > 0
              ? Math.max.apply(
                  Math,
                  data.map(function (o) {
                    return o.Task;
                  })
                )
              : 0;
          newdata[columnData.key] = maxTask + 10;
        } else {
          newdata[columnData.key] = '';
        }
        return {};
      });
    data.push(newdata);
    this.setState({
      data
    });
    this.props.saveTableViewData(data);
  };
  editRow(key) {
    this.toggleEditable(key);
    this.setState({
      saved: false
    });
  }

  cancel(e, key) {
    // this.clickedCancel = true;
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (this.cacheOriginData !== undefined) {
      if (this.cacheOriginData[key]) {
        Object.assign(target, this.cacheOriginData[key]);
        delete this.cacheOriginData[key];
      }
    }
    target.editable = false;
    this.setState({ data: newData });
    // this.clickedCancel = false;
  }

  getRowByKey(key, newData) {
    const { data } = this.state;
    return (newData || data).filter((item) => item.key === key)[0];
  }

  saveToggleEditable = (e, key) => {
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      if (!target.editable && this.cacheOriginData) {
        this.cacheOriginData[key] = { ...target };
      }
      target.editable = !target.editable;
      this.setState({ data: newData });
      this.props.saveTableViewData(newData);
      //  Save to Backend
    }
  };

  toggleEditable = (key) => {
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      target.editable = !target.editable;
      this.setState({ data: newData });
      this.props.saveTableViewData(newData);
    }
  };
  remove(key) {
    const { data } = this.state;
    if (key.isNew) {
      const newData = data.filter((item) => item.key !== key.key);
      this.setState({ data: newData });
      //Delete BAckend Call
    } else {
      message.error('Cant delete this record..');
    }
  }

  handleKeyPress(e, key, type) {
    const isInteger = /^[0-9]+$/;
    if (type === 'number') {
      if (e === '' || isInteger.test(parseInt(e))) {
        return null;
      } else {
        message.error('Please Input Numbers Only');
      }
    } else {
      if (e.key === 'Enter') {
        this.saveRow(e, key);
      }
    }

    // if (e.key === 'Enter') {
    //   this.saveRow(e, key);
    // }
  }
  inputNumberChange = (minValue, maxValue, value, key, record) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      maxValue !== undefined
    ) {
      this.handleFieldChange(value, key, record);
    } else if (minValue === undefined && maxValue === undefined) {
      this.handleFieldChange(value, key, record);
    } else {
      this.handleFieldChange('', key, record);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };
  handleFieldChange = async (fieldValue, fieldName, keyValue) => {
    // const result = fieldValue.replace(/[^a-z-0-9]/gi, '');
    // const result = fieldValue.replace(/^[A-Za-z0-9]*$/, '');
    // p(/^[A-Za-z0-9]*$/)
    // const result = fieldValue(/[^a-z]/gi, '');
    const { data, autoPopulateData } = this.state;
    const newData = data.map((item) => ({ ...item }));
    let target = this.getRowByKey(keyValue, newData);
    if (target) {
      if (fieldName === 'InspectionId') {
        let selectedItem = autoPopulateData.filter((item) => {
          return item.Id === fieldValue;
        });

        target[fieldName] = fieldValue;
        target['Description'] =
          selectedItem && selectedItem[0] ? selectedItem[0]['Description'] : null;
      } else if (fieldName === 'StartTime') {
        target[fieldName] = moment(fieldValue).format('HH:mm:ss');
      } else if (fieldName === 'EffectiveStartDate' || fieldName === 'EffectiveEndDate') {
        if (fieldName === 'CreationTime') {
          target[fieldName] = moment(fieldValue, 'HH:mm');
        }
        target[fieldName] = moment(fieldValue).format('YYYY-MM-DD HH:mm:ss');
      } else if (
        fieldName === 'NumericValue' ||
        fieldName === 'TableValue' ||
        fieldName === 'UOM' ||
        fieldName === 'Value'
      ) {
        // const result = fieldValue.replace(/[^0-9]/gi, '');
        target[fieldName] = fieldValue;
      } else {
        target[fieldName] = fieldValue;
        // target[fieldName] = fieldValue.replace(/6[^%^! ]/gi, '');
      }
      this.setState({ data: newData });
      this.props.saveTableViewData(newData);
    }
  };

  handleDataChange = (fieldValue, fieldName) => {
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    if (newData) {
      newData.map((item, key) => {
        newData[key][fieldName] = fieldValue;
        return {};
      });
      this.setState({ data: newData });
    }
    this.props.saveTableViewData(newData);
  };
  // checkboxOnChange = (e) => {

  // }

  render() {
    let { loading, showAddButton, hideAction } = this.props;
    let { columns, data, translationCache, saved } = this.state;
    if (hideAction !== true) {
      columns = [
        ...columns,
        {
          title: translation(translationCache, 'Action'),
          key: 'action',
          render: (text, record) => {
            if (!!record.editable && loading) {
              return null;
            }
            if (record.editable) {
              if (record.isNew) {
                return (
                  <span>
                    <Tooltip placement="top" trigger="hover" title={'Save'}>
                      <button
                        type="button"
                        className="link-button"
                        onClick={(e) => this.saveRow(e, record.key, record)}
                      >
                        <SaveOutlined style={{ fontSize: '20px', color: '#193652' }} type="save" />
                      </button>
                    </Tooltip>
                  </span>
                );
              }
              return (
                <span>
                  {/* <Tooltip placement="top" trigger="hover" title={"Save"}> */}
                  <button
                    type="button"
                    className="link-button"
                    onClick={(e) => this.saveRow(e, record.key, record)}
                  >
                    <SaveOutlined style={{ fontSize: '20px', color: '#193652' }} type="save" />
                  </button>
                  {/* </Tooltip> */}
                </span>
              );
            } else {
              return (
                <span>
                  {/* <Tooltip placement="top" trigger="hover" title={"Edit"}> */}
                  <button
                    type="button"
                    className="link-button"
                    onClick={(e) => this.editRow(record.key, e)}
                  >
                    <EditOutlined style={{ fontSize: '20px', color: '#193652' }} type="save" />
                  </button>
                  {/* </Tooltip> */}
                </span>
              );
            }
          }
        }
      ];
    }

    return (
      <div className="tableView">
        <div>
          <StyledTable
            theme={themeSettings}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 5 }}
            style={{ marginTop: '10px', border: '1px solid  red !important' }}
            rowClassName={(record) => (record.editable ? styles.editable : '')}
          />
          {showAddButton && saved ? (
            <StyledButton
              theme={themeSettings}
              style={{ marginTop: '10px' }}
              onClick={this.addRow}
              // disabled={this.props.status && this.props.status === 'Completed' ? true : false}
            >
              {translation(translationCache, constant.addRow)}
            </StyledButton>
          ) : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(TableView);
