import React, { Component } from 'react';
import localStorage from '../../../../utils/localStorage';
import { Row, Col, Form, Radio, Slider, TimePicker, Button, InputNumber, message } from 'antd';
import moment from 'moment';
import axios from 'axios';
import './style.css';
import { API_DASHBOARD } from '../../../../commons/api';
class SmartLightControl extends Component {
  state = {
    HighMast3: {
      activateLights: moment().format('HH:mm'),
      deActivateLights: moment().format('HH:mm')
    },
    HighMast4: {
      activateLights: moment().format('HH:mm'),
      deActivateLights: moment().format('HH:mm')
    },
    mode: 'Mode 1',
    HighMast3IluminationSetPoint: 0,
    HighMast4IluminationSetPoint: 0
  };

  componentDidMount = () => {
    document.title = 'Rubus-Smart Control Form';
    this.getFormData();
  };

  getFormData = () => {
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const parameterGroup = {
      method: 'GET',
      url: `${API_DASHBOARD.GetSmartFormData}?SiteId=${siteId}`,
      headers: {
        Authorization: Authorization
      }
    };
    axios(parameterGroup)
      .then((res) => {
        // let formData =[
        //   {
        //     "CreatedTime": "2021-04-28 16:39:33",
        //     "Mode": "Mode 2",
        //     "AssetName": "HighMast-4",
        //     "Ilumination_Set_Point": null,
        //     "Activate_Lights": "03:05:00",
        //     "Deactivate_Lights": "02:02:00"
        //   },
        //   {
        //     "CreatedTime": "2021-04-28 16:39:33",
        //     "Mode": "Mode 2",
        //     "AssetName": "HighMast-3",
        //     "Ilumination_Set_Point": null,
        //     "Activate_Lights": "01:04:00",
        //     "Deactivate_Lights": "04:05:00"
        //   }
        // ];
        let formData = res.data;
        let HighMast3 = {};
        let HighMast4 = {};
        let HighMast3IluminationSetPoint = '';
        let HighMast4IluminationSetPoint = '';

        formData.map((data) => {
          if (data.AssetName && data.AssetName === 'HighMast-3') {
            HighMast3IluminationSetPoint = data.Ilumination_Set_Point;
            HighMast3 = {
              activateLights: data.Activate_Lights,
              deActivateLights: data.Deactivate_Lights
            };
          } else if (data.AssetName && data.AssetName === 'HighMast-4') {
            HighMast4IluminationSetPoint = data.Ilumination_Set_Point;
            HighMast4 = {
              activateLights: data.Activate_Lights,
              deActivateLights: data.Deactivate_Lights
            };
          }
          return {};
        });
        this.setState({
          HighMast3,
          HighMast4,
          HighMast3IluminationSetPoint,
          HighMast4IluminationSetPoint
        });
      })
      .catch(() => {});
  };
  handleSubmit = () => {
    const { mode, HighMast3IluminationSetPoint, HighMast4IluminationSetPoint } = this.state;

    let payload = {};
    if (mode === 'Mode 1') {
      if (HighMast3IluminationSetPoint === '' || HighMast4IluminationSetPoint === '') {
        message.error('Please Provide SetPoints');
      } else if (HighMast3IluminationSetPoint !== '' && HighMast4IluminationSetPoint !== '') {
        payload = [
          {
            modeType: this.state.mode,
            deviceId: 'HighMast-3',
            iluminationSetPoint: this.state.HighMast3IluminationSetPoint
          },
          {
            modeType: this.state.mode,
            deviceId: 'HighMast-4',
            iluminationSetPoint: this.state.HighMast4IluminationSetPoint
          }
        ];
        this.formSubmittion(payload);
      }
    }
    if (mode === 'Mode 2') {
      const { HighMast3, HighMast4 } = this.state;
      if (
        HighMast3.activateLights === '' ||
        HighMast3.deActivateLights === '' ||
        HighMast4.activateLights === '' ||
        HighMast4.deActivateLights === ''
      ) {
        message.error('Please Provide TimeStamp');
      } else if (
        HighMast3.activateLights !== '' &&
        HighMast3.deActivateLights !== '' &&
        HighMast4.activateLights !== '' &&
        HighMast4.deActivateLights !== ''
      ) {
        payload = [
          {
            modeType: this.state.mode,
            deviceId: 'HighMast-3',
            activateLights: HighMast3.activateLights,
            deActivateLights: HighMast3.deActivateLights
          },
          {
            modeType: this.state.mode,
            deviceId: 'HighMast-4',
            activateLights: HighMast4.activateLights,
            deActivateLights: HighMast4.deActivateLights
          }
        ];
        this.formSubmittion(payload);
      }
    }
  };
  formSubmittion = (payload) => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const formSubmittionObject = {
      method: 'POST',
      url: `${API_DASHBOARD.SmartControlForm}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(formSubmittionObject)
      .then((response) => {
        message.success('Submitted Successfully');
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // onActiveChange = (time, timeString) => {
  //   this.setState({ activateLights: timeString });
  // };
  // onDeactiveChange = (time, timeString) => {
  //   this.setState({ deActivateLights: timeString });
  // };
  getDisabledHours = (status) => {
    if (status === 'activate') {
      var hours = [];
      for (var i = 0; i < moment('12:00', 'HH:mm').hour(); i++) {
        hours.push(i);
      }
      return hours;
    } else if (status === 'deActivate') {
      hours = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
    }
    return hours;
  };

  // return e > moment("12:00").hour() ;
  // return current || current > moment().add(1, 'day').endOf('day') || current < moment().startOf('day');

  render() {
    const {
      HighMast3,
      HighMast4,
      HighMast3IluminationSetPoint,
      HighMast4IluminationSetPoint,
      mode
    } = this.state;
    // let HighMast = JSON.stringify(HighMast3.activateLights);
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const format = 'HH:mm';
    // const radioStyle = {
    //   display: "block",
    //   height: "30px",
    //   lineHeight: "30px",
    // };

    return (
      <div className="mode1" style={{ padding: '50px' }}>
        <Form {...formItemLayout}>
          <br />
          <Form.Item
            style={{ marginLeft: '45%', fontWeight: 'bold', fontSize: '25px' }}
            // label="Auto Control Mode :"
          >
            <div>Auto Control Mode</div>
            <Radio.Group onChange={(e) => this.setState({ mode: e.target.value })} value={mode}>
              <Radio value="Mode 1">Mode1</Radio>
              <Radio value="Mode 2">Mode2</Radio>
            </Radio.Group>
          </Form.Item>
          <br />
          {mode === 'Mode 1' ? (
            <div className="mode3">
              <Row>
                <Col sm={9} offset={2}>
                  <h4
                    style={{
                      marginLeft: '0px',
                      fontWeight: 'bold',
                      marginTop: '16px',
                      fontSize: '1em',
                      textAlign: 'center'
                    }}
                  >
                    HighMast-3 Ilumination Set Point (3 to 70,000) :
                  </h4>
                </Col>

                <Col sm={6}>
                  <div className="sliderBackground">
                    <Slider
                      //marks={marks}
                      min={3}
                      max={70000}
                      onChange={(value) => this.setState({ HighMast3IluminationSetPoint: value })}
                      value={HighMast3IluminationSetPoint}
                      tipFormatter={(value) => {
                        return `${value}`;
                      }}
                    />
                  </div>
                </Col>
                <Col sm={2}>
                  <InputNumber
                    min={3}
                    max={70000}
                    style={{
                      marginLeft: '3px',
                      width: '60px',
                      height: '50px',
                      marginTop: '5px'
                    }}
                    // step={0.01}
                    value={HighMast3IluminationSetPoint}
                    onChange={(value) => this.setState({ HighMast3IluminationSetPoint: value })}
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col sm={9} offset={2}>
                  <h4
                    style={{
                      marginLeft: '0px',
                      fontWeight: 'bold',
                      marginTop: '16px',
                      fontSize: '1em',
                      textAlign: 'center'
                    }}
                  >
                    HighMast-4 Ilumination Set Point (3 to 70,000) :
                  </h4>
                </Col>
                <Col xs={8} sm={6}>
                  <div className="sliderBackground">
                    <Slider
                      // marks={marks}
                      min={3}
                      max={70000}
                      onChange={(value) => this.setState({ HighMast4IluminationSetPoint: value })}
                      value={HighMast4IluminationSetPoint}
                      tipFormatter={(value) => {
                        return `${value}`;
                      }}
                    />
                  </div>
                </Col>
                <Col sm={2}>
                  <InputNumber
                    min={3}
                    max={70000}
                    style={{
                      marginLeft: '3px',
                      width: '60px',
                      height: '50px',
                      marginTop: '5px'
                    }}
                    // step={0.01}
                    value={HighMast4IluminationSetPoint}
                    onChange={(value) => this.setState({ HighMast4IluminationSetPoint: value })}
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <div style={{}}>
              <Row>
                <div className="mode2" style={{ width: '100%', display: 'inlineFlex' }}>
                  <div style={{ width: '50%', marginLeft: '10%' }}>
                    {/* <Col sm={1}> */}
                    <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>HighMast-3 :</h4>
                    <Form.Item
                      style={{ fontWeight: 'bold', fontSize: '15px' }}
                      label="Activate Lights :"
                    >
                      <span style={{ marginLeft: '5%' }}>
                        <TimePicker
                          style={{ width: '15%' }}
                          disabledHours={() => this.getDisabledHours('activate')}
                          //disabledMinutes={(e)=>this.getDisabledMinutes("activate")}
                          defaultValue={
                            HighMast3 &&
                            HighMast3.activateLights &&
                            HighMast3.activateLights !== null
                              ? moment(HighMast3.activateLights, format)
                              : moment()
                          }
                          format={'HH:mm'}
                          // defaultValue={moment('12:08', format)} format={format}
                          placeholder={'HH:mm'}
                          onChange={(time, timeString) => {
                            this.setState({
                              HighMast3: {
                                activateLights: timeString,
                                deActivateLights: HighMast3.deActivateLights
                              }
                            });
                          }}
                          // format={format}
                        />
                      </span>
                    </Form.Item>

                    <Form.Item
                      style={{ fontWeight: 'bold', fontSize: '15px' }}
                      label="De-activate Lights :"
                    >
                      {/* <h4>De-activateLights :</h4> */}
                      {/* </Col> */}
                      {/* <Col sm={2}> */}
                      <span style={{ marginLeft: '5%' }}>
                        <TimePicker
                          style={{ width: '15%' }}
                          defaultValue={
                            HighMast3 &&
                            HighMast3.deActivateLights &&
                            HighMast3.deActivateLights !== null
                              ? moment(HighMast3.deActivateLights, format)
                              : moment()
                          }
                          format="HH:mm"
                          // placeholder={"HH:mm:ss"}
                          onChange={(time, timeString) => {
                            this.setState({
                              HighMast3: {
                                deActivateLights: timeString,
                                activateLights: HighMast3.activateLights
                              }
                            });
                          }}
                          disabledHours={() => this.getDisabledHours('deActivate')}
                          //  disabledMinutes={(e) =>this.getDisabledMinutes("deActivate")}
                        />
                      </span>
                    </Form.Item>
                  </div>
                  {/* </Col> */}
                  {/* <Col sm={1} offset={1}> */}
                  <div style={{ width: '50%' }}>
                    <h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>HighMast-4 :</h4>
                    {/* </Col> */}
                    {/* <Col sm={2} offset={1}> */}
                    {/* <h4>activateLights :</h4> */}
                    {/* </Col> */}
                    {/* <Col sm={2}> */}
                    <Form.Item
                      style={{ fontWeight: 'bold', fontSize: '15px' }}
                      label="Activate Lights :"
                    >
                      {' '}
                      <span style={{ marginLeft: '5%' }}>
                        <TimePicker
                          style={{ width: '15%' }}
                          // defaultOpenValue={moment("00:00", "HH:mm")}
                          defaultValue={
                            HighMast4 &&
                            HighMast4.activateLights &&
                            HighMast4.activateLights !== null
                              ? moment(HighMast4.activateLights, format)
                              : moment()
                          }
                          format="HH:mm"
                          placeholder={'HH:mm'}
                          onChange={(time, timeString) => {
                            this.setState({
                              HighMast4: {
                                activateLights: timeString,
                                deActivateLights: HighMast4.deActivateLights
                              }
                            });
                          }}
                          disabledHours={() => this.getDisabledHours('activate')}
                          // disabledMinutes={(e) =>this.getDisabledMinutes("activate")}
                        />
                      </span>
                    </Form.Item>

                    {/* </Col> */}
                    {/* </Col> */}
                    {/* <Col sm={2} offset={1}> */}
                    <Form.Item
                      style={{ fontWeight: 'bold', fontSize: '15px' }}
                      label="De-activate Lights :"
                    >
                      {' '}
                      <span style={{ marginLeft: '5%' }}>
                        <TimePicker
                          style={{ width: '15%' }}
                          defaultValue={
                            HighMast4 &&
                            HighMast4.deActivateLights &&
                            HighMast4.deActivateLights !== null
                              ? moment(HighMast4.deActivateLights, format)
                              : moment()
                          }
                          format="HH:mm"
                          // placeholder={"HH:mm"}
                          onChange={(time, timeString) => {
                            this.setState({
                              HighMast4: {
                                deActivateLights: timeString,
                                activateLights: HighMast4.activateLights
                              }
                            });
                          }}
                          disabledHours={() => this.getDisabledHours('deActivate')}
                          //  disabledMinutes={(e) =>this.getDisabledMinutes("deActivate")}
                        />
                      </span>
                    </Form.Item>
                  </div>
                  {/* <h4>De-activateLights :</h4> */}
                  {/* </Col> */}
                  {/* <Col sm={2}> */}

                  {/* </Col> */}
                </div>
              </Row>
            </div>
          )}
          <Button
            style={{ marginLeft: '45%', marginTop: '60px' }}
            type="primary"
            shape="round"
            size="large"
            onClick={() => this.handleSubmit()}
          >
            Submit
          </Button>

          <br />
        </Form>
      </div>
    );
  }
}

export default SmartLightControl;
