import React from 'react';

import {
  AttributeBatch,
  FormSubSectionTableMain,
  DownTimeBatchMain,
  DownTimePowderFeddingMain,
  EquipmentBatchMain,
  EquipmentBodyCodeForm,
  SubSectionMain,
  GasLogForm,
  FormWithSubModelsMain,
  // FormWithSubModelsTable,
  FormWithFormSubSectionFormTable,
  // AdhocFormTableView,
  FormWithMultipleTabs,
  FormTableWithMultiSubHedearTable,
  AttributeDynamic
} from './FormTypes';

class FormTypes extends React.Component {
  render() {
    let { formType } = this.props;
    switch (formType) {
      case 'tableView':
        return <GasLogForm {...this.props} />;
      case 'GasLogForm':
        return <GasLogForm {...this.props} />;
      case 'EquipmentBodyCodeForm':
        return <EquipmentBodyCodeForm {...this.props} />;
      case 'FormWithSubModels':
        return <FormWithSubModelsMain {...this.props} />;
      case 'FormWithSubModelsTable':
        return <FormWithSubModelsMain {...this.props} />;
      case 'FormTable':
        return <FormWithFormSubSectionFormTable {...this.props} />;
      case 'FormWithFormSubSectionFormTable':
        return <FormWithFormSubSectionFormTable {...this.props} />;
      case 'EquipmentBatch':
        return <EquipmentBatchMain {...this.props} />;
      case 'Form With Multiple Tabs':
        return <FormWithMultipleTabs {...this.props} />;
      case 'Form Table With Multi Sub Header Table':
        return <FormTableWithMultiSubHedearTable {...this.props} />;
      case 'batchAttribute':
        return <AttributeBatch {...this.props} />;
      case 'batchAttributeForm':
        return <AttributeDynamic {...this.props} />;
      case 'DowntimeBatch':
        return <DownTimeBatchMain {...this.props} />;
      case 'DowntimePFBatch':
        return <DownTimePowderFeddingMain {...this.props} />;
      case 'SubSection':
        return <SubSectionMain {...this.props} />;
      case 'FormSubSectionTable':
        return <FormSubSectionTableMain {...this.props} />;
      default:
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '50px',
              color: 'white',
              fontSize: '16px'
            }}
          >
            {"Form Type doesn't exist"}
          </div>
        );
    }
  }
}

export default FormTypes;
