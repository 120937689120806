import axios from 'axios';
import localStorage from '../../../../../../utils/localStorage';
import { IHSR } from '../../../../../../commons/api';

export async function uploadJsonData(data) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${IHSR.Excel}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      JSON: data
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getTableListFromAPI() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${IHSR.Get}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
