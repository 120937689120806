export const treeMock = [
  {
    name: 'Actual',
    value: 3938,
    children: [
      {
        name: 'analytics',
        children: [
          {
            name: 'cluster',
            children: [
              {
                name: 'AgglomerativeCluster',
                value: 3938
              },
              {
                name: 'CommunityStructure',
                value: 3812
              },
              {
                name: 'HierarchicalCluster',
                value: 6714
              },
              {
                name: 'MergeEdge',
                value: 743
              }
            ]
          }
        ]
      },

      {
        name: 'data',
        children: [
          {
            name: 'converters',
            children: [
              {
                name: 'Converters',
                value: 721
              },
              {
                name: 'DelimitedTextConverter',
                value: 4294
              }
            ]
          },
          {
            name: 'DataField',
            value: 1759
          },
          {
            name: 'DataSchema',
            value: 2165
          },
          {
            name: 'DataSet',
            value: 586
          },
          {
            name: 'DataSource',
            value: 3331
          },
          {
            name: 'DataTable',
            value: 772
          },
          {
            name: 'DataUtil',
            value: 3322
          }
        ]
      }
    ]
  }
];
