import { Button } from 'antd';
import styled from 'styled-components';

export const StyledApplication = styled.div`
  background: white;
  margin: 20px;
  padding: 30px;
  min-height: 750px;
`;
export const StyledAddButton = styled(Button)`
  margin: 24px;
`;

export const StyledDashboard = styled.div`
  background: white;
  // margin: 20px;
  padding: 30px;
`;
export const StyledTable = styled.table`
  caption-side: top;
  color: ${(props) => props.theme.text} !important;
  width: 100%;
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */

  caption-side: bottom;
  /* empty-cell: show | hide;  */
  /* empty-cell is a property of table or the cells themselves */

  /* vertical-align: baseline | sub | super | text-top | 
                text-bottom | middle | top | bottom | 
                <percentage> | <length> */

  /* tbody {
    vertical-align: top;
  }              */
  td,
  th {
    border: none;
  }
  /* td,
  th {
      color:${(props) => props.theme.text} !important;
    border: 1px solid red;
   
  } */

  td {
    padding: 11px 10px;
  }
  .ant-table-thead > tr > th{
    font-weight:bold !important
    font-size: 1em !important;
    color:white !important;
    background: #214972 ;
  }
  text[style] {
    // font-weight:bold !important
    font-size: 1em !important;
    color: ${(props) => props.theme.text} !important;
 }
  tbody tr {
    :nth-of-type(odd) {
      background-color:${(props) => props.theme.odd} !important;
    }
    :hover {
      background-color:${(props) => props.theme.hover} !important; 
    }
  }
  tbody tr {
    :nth-of-type(even) {
      background-color:${(props) => props.theme.even} !important;
    }
    :hover {
      background-color:${(props) => props.theme.hover} !important; 
    }
  }
  thead > tr {
    color: ${(props) => props.theme.text} !important;
    background-color: #262626 !important;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;
