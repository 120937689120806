import axios from 'axios';
import localStorage from '../../utils/localStorage';
import { message } from 'antd';
import {
  API_DEVICE,
  API_DEVICEPARAMETER,
  API_DEVICETYPE,
  API_HIERARCHYMODULE,
  API_TRENDS,
  BOOKMARK,
  GETUSERLIST,
  OBSERVATION
} from '../../commons/api';

export async function getTrendData(config) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  const data = await fetch(`${API_TRENDS.LIST}?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      ...config,
      SiteId: siteId
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {});
  return data;
}

export async function getTrendConfig() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  const data = await fetch(`${API_DEVICETYPE.CACHE}?SiteId=${siteId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {});

  return data === undefined ? [] : data;
}
export async function getParameterList(DeviceId) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'POST',
    url: `${API_DEVICEPARAMETER.DeviceParameters}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      DeviceCode: DeviceId,
      SiteId: siteId
    }
  };
  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return {
        data: {}
      };
    });
}

export async function getOperationList(ParameterType, GraphType) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const operatorGroup = {
    method: 'POST',
    url: `${API_TRENDS.GetOperators}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ParameterType,
      GraphType
    }
  };

  return axios(operatorGroup)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function getBookmarkList(Module) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'GET',
    url: `${BOOKMARK.GET}?SiteId=${siteId}&Module=${[]}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      Module: Module || ''
    }
  };
  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
        return [];
      }
    });
}

export async function getObservationList(Module) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'GET',
    url: `${OBSERVATION.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      SiteId: siteId,
      Module: Module || ''
    }
  };
  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
        return [];
      }
    });
}

export async function CreateObservation(data) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'POST',
    url: `${OBSERVATION.CREATE}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data
  };

  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function getUsersList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${GETUSERLIST.GET}?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function DeleteObservation(Id) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'DELETE',
    url: `${OBSERVATION.DELETE}?SiteId=${siteId}&Name=${Id}`,
    headers: {
      Authorization: Authorization
    }
  };

  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return {
        data: {}
      };
    });
}

export async function CreateTrend(data) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'POST',
    url: `${BOOKMARK.CREATE}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data
  };

  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function UpdateTrends(params) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'PATCH',
    url: `${BOOKMARK.UPDATE}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...params,
      SiteId: siteId
    }
  };

  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function DeleteTrend(Id) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'DELETE',
    url: `${BOOKMARK.Delete}?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: Authorization
    }
  };

  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return {
        data: {}
      };
    });
}

// export async function getbookMarkByname(Module, Id) {
//   const siteId = localStorage.get('currentSite');
//   const token = localStorage.get('accessToken');
//   var Authorization = 'Bearer ' + token;
//   const parameterGroup = {
//     method: 'GET',
//     url: `${BOOKMARK.GET}?SiteId=${siteId}&Id=${Id}`,
//     headers: {
//       Authorization: Authorization
//     },
//     data: {
//       Id,
//       Module,
//       SiteId: siteId
//     }
//   };

//   return axios(parameterGroup)
//     .then((res) => {
//       return res.data;
//     })
//     .catch(() => {
//       return {
//         data: {}
//       };
//     });
// }
export async function getHierarchyLevels(HierarchyCode) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const headerObj = {
    method: 'GET',
    url: `${API_HIERARCHYMODULE.GetHierarchyLevels}?SiteId=${siteId}&HierarchyCode=${HierarchyCode}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(headerObj)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
      return [];
    });
}

export async function getEquipmentList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const headerObj = {
    method: 'GET',
    url: `${API_DEVICE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(headerObj)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function getDefaultSiteName() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const deciceListApi = {
    method: 'GET',
    url: `${API_HIERARCHYMODULE.GetHierarchyLevels}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(deciceListApi)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
