import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tag } from 'antd';
import moment from 'moment';

import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { defaultRefreshTime } from '../../constant.json';
import { arrayOfObjects } from '../../../Mock/MockJson';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class Counter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      imageData: {}
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  render() {
    const { counter, errorInAPI, noDataInDB, refreshDateTime, imageData } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : this.state.boolean === true ? (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <span
              style={{
                color: counter === 'on' ? 'green' : 'red',
                fontSize: this.props.graphprops.counter.fontSize
                  ? this.props.graphprops.counter.fontSize + 'em'
                  : '5em'
              }}
            >
              {this.state.counter}
            </span>
          </div>
        ) : (
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-around',
              flexWrap: `wrap`
            }}
          >
            {graphprops &&
            graphprops[graphprops.graph] &&
            graphprops[graphprops.graph].displayType &&
            graphprops[graphprops.graph].displayType === 'Image & Value' ? (
              <span
                style={{
                  justifyContent: 'center',
                  width: '50%',
                  alignItems: 'center'
                }}
              >
                <img
                  src={imageData && imageData.Description ? imageData.Description : ''}
                  type="image"
                  alt=""
                  style={{ height: '110px', width: '110px', marginTop: '15%' }}
                />
              </span>
            ) : null}

            <span
              style={{
                color:
                  this.props.graphprops[graphprops.graph] &&
                  this.props.graphprops[graphprops.graph].titleColor
                    ? this.props.graphprops[graphprops.graph].titleColor
                    : '',
                fontSize:
                  this.props.graphprops[graphprops.graph] &&
                  this.props.graphprops[graphprops.graph].fontSize
                    ? this.props.graphprops[graphprops.graph].fontSize + 'em'
                    : '5em',
                justifyContent: 'center',
                width: '50%',
                alignItems: 'center',
                marginTop: '5%'
              }}
            >
              {this.state.counter}
            </span>
          </div>
        )}
      </Fragment>
    );
  }

  getData = async () => {
    this.requestCheck = false;
    let { graphprops } = this.props;
    let json = [];
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = arrayOfObjects;
      this.requestCheck = true;
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection({
          graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          selectionDateLive: this.props.selectionDateLive,
          currentTimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        console.log(this.abortController, 'jsoncounter');
        this.requestCheck = true;
      }
    }

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      if (
        json &&
        Array.isArray(json) &&
        json.length > 0 &&
        this.props.graphprops[graphprops.graph] &&
        this.props.graphprops[graphprops.graph].counterType === 'number'
      ) {
        this.setState({ counter: Number(json[0].Value).toFixed(2) });
      } else if (
        json &&
        Array.isArray(json) &&
        json.length > 0 &&
        this.props.graphprops[graphprops.graph] &&
        this.props.graphprops[graphprops.graph].counterType === 'string'
      ) {
        this.setState({
          counter: json[0].Value,
          refreshDateTime: moment()
        });
      } else if (
        json &&
        Array.isArray(json) &&
        json.length > 0 &&
        this.props.graphprops &&
        this.props.graphprops[graphprops.graph] &&
        this.props.graphprops[graphprops.graph].counterType === 'both'
      ) {
        this.setState({
          counter: Number(json[0].Value).toFixed(2),
          uom: this.props.graphprops.uom
        });
      } else if (
        json &&
        Array.isArray(json) &&
        json.length > 0 &&
        this.props.graphprops[graphprops.graph] &&
        this.props.graphprops[graphprops.graph].counterType === 'boolean'
      ) {
        this.setState({ counter: Number(json[0].Value).toFixed(2), boolean: true });
      } else if (
        json &&
        Array.isArray(json) &&
        json.length > 0 &&
        this.props.graphprops[graphprops.graph] &&
        this.props.graphprops[graphprops.graph].counterType === 'date-picker'
      ) {
        this.setState({
          counter: moment
            .utc(json[0].DateTime)
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm')
        });
      }
      if (
        graphprops &&
        graphprops[graphprops.graph] &&
        graphprops[graphprops.graph].displayType &&
        graphprops[graphprops.graph].displayType === 'Image & Value'
      ) {
        this.setState({ imageData: json && json[0] ? json[0] : {} });
      }
      this.setState({
        errorInAPI,
        noDataInDB
      });
    }
    if (json && Array.isArray(json) && json[0] && json[0].Value === null) {
      this.setState({ errorInAPI, noDataInDB: !noDataInDB });
    } else {
      this.setState({ errorInAPI, noDataInDB });
    }
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(Counter);
