//Required Module imports//
import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { decode as base64_decode } from 'base-64';
import localStorage from '../../../../../utils/localStorage';
import { Form, message, Select, Input, Button } from 'antd';
import translation from '../../../Utils/translation';
import history from '../../../../../commons/history';
import Highlighter from 'react-highlight-words';
//Required Component imports//
// import HierarchyModal from '../../../Widgets/HierarchyModal';
import TableSelect from '../../../Widgets/TableSelect';
import {
  StyledComponent,
  StyledForm,
  StyledBox,
  StyledButton,
  StyledSubHeading,
  StyledModal
} from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import {
  getSitesList,
  submitMaster,
  getHierarchyList,
  getDeviceList,
  getMasterById
} from '../../../Utils/FormCalls';
import FileBase64 from '../../../Utils/FileUpload';
import TableView from '../../../Widgets/TableView';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { check } from '../../../Utils/FormCalls';
import { constant } from '../../../Utils/constants';
import { PaperClipOutlined, SearchOutlined, DownOutlined } from '@ant-design/icons';
//css//
import { getTranslationCache } from '../../../../../selectors/language';
import InputComponent from '../../../Widgets/InputComponent';
import InputNumberComponent from '../../../Widgets/InputNumberComponent';
import { getUserProfile } from '../../../../../selectors/layout';
import {
  API_DEVICE,
  API_HIERARCHYMODULE,
  // API_MASTERCONFIGURATION,
  API_MASTERTOOL,
  API_PARAMETER
} from '../../../../../commons/api';

//const variables//
const { Option } = Select;
const urlList = {
  insert: API_MASTERTOOL.INSERT,
  getbyId: API_MASTERTOOL.GetById
};
const type = 'Tools';
const fileData = new FormData();
class ToolsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sitesList: [],
      deviceList: [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      hierarchyList: [],
      tableData: [],
      datasource:
        props.formObject && props.formObject.Specifications
          ? props.formObject.Specifications
          : [{}],
      DeviceAlias: '',
      // type:"Tools"
      translationCache: props.translationCache || [],
      columns: [
        {
          title: translation(props.translationCache, constant.name),
          key: 'Name',
          dataIndex: 'Name',
          widget: 'input',
          required: true
          //maxLength: 50
        },
        {
          title: translation(props.translationCache, constant.value),
          key: 'Value',
          dataIndex: 'Value',
          widget: 'inputNumber',
          required: true
          //maxLength: 50
        },

        {
          title: translation(props.translationCache, constant.unitOfMeasure),
          key: 'UOM',
          dataIndex: 'UOM',
          widget: 'select',
          required: true,
          table: {
            url: `${API_PARAMETER.GetUOM}`,
            method: 'GET'
          }
        }
      ]
    };
  }
  ToolsRef = React.createRef();

  componentDidMount = async () => {
    this._getDataById();
    try {
      let sitesList = await getSitesList();
      let formObject = this.ToolsRef.current.getFieldsValue();
      formObject = {
        ...formObject,
        SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
      };
      this.ToolsRef.current.setFieldsValue(formObject);
      this.setState({ sitesList: sitesList, formObject });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let hierarchyList = await getHierarchyList();
      this.setState({ hierarchyList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      if (this.ToolsRef.current) this.ToolsRef.current.setFieldsValue(this.props.formObject);
    }

    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject,
        datasource:
          this.props.formObject && this.props.formObject.Specifications
            ? this.props.formObject.Specifications
            : [{}]
      });
    }
    if (this.props.history.location !== prevProps.history.location) {
      this.onReset();
      // this._getDataById();
    }
  };
  _getDataById = async (ToolId) => {
    let formObject = {};
    let path = history.location.pathname.split('/rubus/AMM/ToolsDetails/');
    let formId = ToolId ? ToolId : base64_decode(path[1]);
    // let formId = base64_decode(path[1]);
    if (formId !== 'new') {
      let data = await getMasterById(urlList.getbyId, formId, 'GET');

      formObject = data && data !== undefined ? data : {};
      _.cloneDeepWith(data, (value) => {
        return value && value !== null && value.StartTime && value.EndTime
          ? {
              ...value,
              ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
              ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
            }
          : _.noop();
      });
    }
    if (this.ToolsRef.current) {
      this.ToolsRef.current.setFieldsValue(formObject);
    }
    this.setState({
      formObject,
      datasource: formObject.Specifications,
      DeviceAlias: formObject.DeviceAlias
    });
  };
  handleChange = async (value, key) => {
    // let { formObject } = this.state;
    let formObject = this.ToolsRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      [key]: value
    };
    if (this.ToolsRef.current) {
      this.ToolsRef.current.setFieldsValue(formObject);
    }
    this.setState({ [key]: value, formObject });
  };

  fileupload = async (values, key, fileDataObject) => {
    let { datasource } = this.state;
    let formObject = this.ToolsRef.current.getFieldsValue();

    if (formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    formObject = {
      ...formObject,
      Specifications: datasource,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    if (this.ToolsRef.current) this.ToolsRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };
  onFormsubmit = async (values) => {
    let { formObject, fileData, DeviceAlias } = this.state;
    let Specifications = [];
    let userId =
      this.props.userProfile && this.props.userProfile.user && this.props.userProfile.user.Id
        ? this.props.userProfile.user.Id
        : '';
    let SpecificationsForm =
      formObject && formObject.Specifications ? formObject.Specifications : [];
    if (SpecificationsForm && Array.isArray(SpecificationsForm)) {
      let editableList = [];
      editableList = SpecificationsForm.filter((specifi) => {
        return specifi.editable === true;
      });
      if (editableList && Array.isArray(editableList) && editableList.length === 0) {
        Specifications = SpecificationsForm.map((spec) => {
          delete spec.editable;
          delete spec.key;
          return spec;
        });
        let payload = {
          ...formObject,
          ...values,
          ...(formObject && !formObject.Id ? { CreatedBy: userId } : {}),
          ...(formObject && formObject.Id ? { UpdatedBy: userId } : {}),
          Specifications,
          Equipment: DeviceAlias
        };
        let statusResponse = await submitMaster(urlList.insert, payload, fileData, type);
        if (statusResponse && statusResponse.message) {
          this._getDataById(statusResponse.Id);
          message.success(statusResponse.message);
        } else if (statusResponse && statusResponse.Error) {
          message.error(statusResponse.Error);
        }
      } else {
        message.warn('Please save specifications before submitting');
      }
    }
  };

  onReset = () => {
    if (this.ToolsRef.current) this.ToolsRef.current.resetFields();
  };

  saveTableViewData = (datasource) => {
    let formObject = this.ToolsRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Specifications: datasource
    };
    this.setState({ formObject, datasource });
  };

  getHierarchyLevels = () => {
    let { hierarchyList } = this.state;
    const siteId = localStorage.get('currentSite');
    let payload = {
      HierarchyCode:
        hierarchyList &&
        Array.isArray(hierarchyList) &&
        hierarchyList[0] &&
        hierarchyList[0].HierarchyCode
          ? hierarchyList[0].HierarchyCode
          : null,
      Type: 'Device'
    };
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${API_HIERARCHYMODULE.GetHierarchyLevels}?SiteId=${siteId}&HierarchyCode=${payload.HierarchyCode}&Type=${payload.Type}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({
            treeData: data,
            visible: true,
            formObject: this.ToolsRef.current.getFieldsValue()
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  parentNode = (parent) => {
    let formObject = this.ToolsRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Location: parent.Code
    };
    this.ToolsRef.current.setFieldsValue(formObject);
    this.setState({
      visible: false,
      formObject
    });
  };

  hierarchyDropdownChange = (value) => {
    let formObject = this.ToolsRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Equipment: value.Code,
      EquipmentDescription: value.Name
    };
    this.ToolsRef.current.setFieldsValue(formObject);
    this.setState({
      visible: false,
      formObject
    });
  };

  onModalCancel = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      this.setState({
        visible: false
      });
    }
  };
  onModalOk = () => {
    this.setState({
      visible: false
    });
    // let { formObject } = this.state;
    // formObject = {
    //     ...formObject,
    //     "FailureCodes": data
    // };
    // this.setState({
    //     selectedTableData: data,
    //     displayTable: true,
    //     Id: data.Id,
    //     formObject
    // });
  };
  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${API_DEVICE.LIST}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.ToolsRef.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  TableDropdownChange = (value, modal) => {
    let formObject = this.ToolsRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      Equipment: value.AliasCode,
      EquipmentDescription: value.Name,
      DeviceCode: value.DeviceCode,
      DisplayCode: value.DisplayCode
    };
    this.ToolsRef.current.setFieldsValue(formObject);
    this.setState({
      formObject,
      [modal]: false,
      DeviceAlias: value && value.AliasCode
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  render() {
    let {
      sitesList,
      formObject,
      // hierarchyList,
      // deviceList,
      datasource,
      columns,
      translationCache
    } = this.state;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      }
      // pattern: {
      //   mismatch: '${label} does not match pattern'
      // }
    };
    return (
      <>
        <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 143 }}>
          <StyledForm
            name="Item"
            theme={themeSettings}
            ref={this.ToolsRef}
            initialValues={formObject}
            onFinish={this.onFormsubmit}
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            //wrapperCol={{ flex: 1 }}
            validateMessages={validateMessages}
          >
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Form.Item>
                <StyledButton theme={themeSettings} htmlType="submit">
                  {translation(translationCache, constant.submit)}
                </StyledButton>
              </Form.Item>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item name="Id"></Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    marginTop: '30px'
                  }}
                  label={translation(translationCache, constant.tool)}
                  name="Tool"
                  rules={[
                    { required: true },
                    {
                      // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                      // pattern: new RegExp(/^[^%!^ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Tool')}
                    value={'WO-11'}
                    //maxLength={50}
                    disabled={formObject && formObject.Id ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    marginTop: '30px'
                  }}
                  label={translation(translationCache, constant.toolDescription)}
                  name="Description"
                  rules={[
                    { required: true },
                    {
                      // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                      // pattern: new RegExp(/^[^%!^ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={150}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Description')}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%'
                    // display: formObject && formObject.Id ? 'none' : true
                  }}
                  label={translation(translationCache, constant.equipment)}
                  name="DisplayCode"
                  rules={[
                    { required: true },
                    {
                      // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                      // pattern: new RegExp(/^[^%!^ ]*$/)
                    }
                  ]}
                >
                  <Input
                    style={{ width: '70%' }}
                    // value={formObject.Location}
                    placeholder={translation(translationCache, 'Select Equipment')}
                    disabled={true}
                    suffix={
                      <DownOutlined
                        style={{
                          // color: '#1a3b5c',
                          color: formObject && formObject.Id ? null : '#1a3b5c',
                          cursor: formObject && formObject.Id ? 'not-allowed' : null,
                          opacity: formObject && formObject.Id ? '0.8' : null
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (formObject && !formObject.Id) {
                            this.getDeviceData('visible');
                          }
                        }}
                      />
                    }
                  />
                </Form.Item>
                <StyledModal
                  open={this.state.visible}
                  width={800}
                  closable
                  title={translation(translationCache, constant.selectEquipment)}
                  onOk={this.onModalOk}
                  onCancel={this.onModalCancel}
                >
                  <TableSelect
                    data={this.state.tableData}
                    translationCache={this.props.translationCache}
                    key="Equipment"
                    columns={[
                      {
                        title: translation(translationCache, 'Equipment'),
                        dataIndex: 'DisplayCode',
                        key: 'DisplayCode',
                        ...this.getColumnSearchProps('DisplayCode')
                      },
                      {
                        title: translation(translationCache, 'Equipment Description'),
                        dataIndex: 'Name',
                        key: 'Name',
                        ...this.getColumnSearchProps('Name')
                      }
                    ]}
                    handleDropdownChange={(e) => this.TableDropdownChange(e, 'visible')}
                  />
                  {/* <HierarchyModal
                  value={this.state.deviceId}
                  treeData={this.state.treeData}
                  translationCache={this.props.translationCache}
                  handleDropdownChange={this.hierarchyDropdownChange}
                  handleParentNode={this.parentNode}
                /> */}
                </StyledModal>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%'
                    // display: formObject && formObject.Id ? 'none' : true
                  }}
                  label={translation(translationCache, constant.equipmentDescription)}
                  name="EquipmentDescription"
                  rules={[
                    { required: true },
                    {
                      // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                      // pattern: new RegExp(/^[^%!^ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={150}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Autopopulated based on Equipment!')}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%'
                    // display: formObject && formObject.Id ? 'none' : true
                  }}
                  label={translation(translationCache, constant.site)}
                  name="SiteId"
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: '70%' }}
                    disabled={formObject && formObject.Id ? true : false}
                    placeholder={translation(translationCache, 'Select Site')}
                    onChange={(e) => this.handleChange(e, 'SiteId')}
                  >
                    {sitesList &&
                      Array.isArray(sitesList) &&
                      sitesList.map((site, index) => {
                        return (
                          <Option key={index} value={site.Id}>
                            {' '}
                            {site.Name}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  name="Attachments"
                  // label={translation(translationCache, constant.attachments)}
                  label={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>
                        {translation(translationCache, constant.attachments)}
                        <PaperClipOutlined className="plusCircleOutlined" />
                      </span>
                    </div>
                  }
                >
                  <input style={{ width: '70%', display: 'none' }} />
                  <FileBase64
                    downloadClassName={'formdownloadInput'}
                    Attachments={formObject.Attachments}
                    fileUpload={this.fileupload}
                  ></FileBase64>
                </Form.Item>
              </StyledBox>
              <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                  label={translation(translationCache, constant.issueUnit)}
                  name="IssueUnit"
                  rules={[
                    {
                      // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                      // pattern: new RegExp(/^[^%!^ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={50}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Issue Unit')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                  label={translation(translationCache, constant.relatedEquipment)}
                  name="RelatedEquipment"
                  rules={[
                    {
                      // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                      // pattern: new RegExp(/^[^%!^ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={50}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Related Equipment')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.toolName)}
                  name="ToolName"
                  rules={[
                    {
                      // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                      // pattern: new RegExp(/^[^%!^ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={50}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input ToolName')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.drawings)}
                  name="Drawings"
                  rules={[
                    {
                      // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                      // pattern: new RegExp(/^[^%!^ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={50}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Drawings')}
                  />
                </Form.Item>
              </StyledBox>
              <StyledBox style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                  label={translation(translationCache, constant.storeLocation)}
                  name="StoreLocation"
                  rules={[
                    {
                      // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                      // pattern: new RegExp(/^[^%!^ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={50}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Store Location')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%', marginTop: '30px' }}
                  label={translation(translationCache, constant.Remarks)}
                  name="Remarks"
                  rules={[
                    {
                      // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                      // pattern: new RegExp(/^[^%!^ ]*$/)
                    }
                  ]}
                >
                  <InputComponent
                    //maxLength={150}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Remarks')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.Quantity)}
                  name="AvailableQuantity"
                  rules={[
                    { required: true },
                    {
                      validator: (_, value) => check(value, 'Item', { validateDecimal: true })
                    }
                  ]}
                >
                  <InputNumberComponent
                    //maxLength={20}
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Input Quantity')}
                    onChange={(e) => this.handleChange(e, 'Quantity')}
                  />
                </Form.Item>
                <Form.Item style={{ width: '100%', flex: '50%' }}>
                  <InputComponent style={{ display: 'none' }} />
                </Form.Item>
                <Form.Item name="Equipment"></Form.Item>
              </StyledBox>
            </div>

            <StyledSubHeading>
              {translation(translationCache, constant.specifications)}
            </StyledSubHeading>
            <Form.Item>
              <TableView
                form={{ columns: columns, datasource: datasource }}
                saveTableViewData={(e) => this.saveTableViewData(e)}
                showAddButton={true}
              />
            </Form.Item>
            {/* </Form> */}
          </StyledForm>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ToolsDetails);
