import axios from 'axios';

import localStorage from '../../../utils/localStorage';
import { API_DASHBOARD, LOGBOOK, REPORTS } from '../../../commons/api';

export function getHMICalls(payload) {
  const accessToken = localStorage.get('accessToken');
  const header = {
    ...payload,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        message: 'Error in API'
      };
    });
}
export function _getReportList() {
  const siteId = localStorage.get('currentSite');
  let accessToken = localStorage.get('accessToken');
  const healthHeaders = {
    method: 'GET',
    url: `${REPORTS.Get}?SiteId=${siteId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(healthHeaders)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        message: 'Error in API'
      };
    });
}
export function _getReportData() {
  const siteId = localStorage.get('currentSite');
  let accessToken = localStorage.get('accessToken');
  const healthHeaders = {
    method: 'GET',
    url: `${REPORTS.Get}?SiteId=${siteId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(healthHeaders)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        message: 'Error in API'
      };
    });
}
export async function selectDashboard() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`${API_DASHBOARD.GET}?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      if (Array.isArray(json)) {
        return json;
      }
    })
    .catch(() => {});
  return data === undefined ? [] : data;
}
export async function getDatasourceData() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`${LOGBOOK.GET}?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
  if (data && Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}
