import React, { Component } from 'react';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Table, Form, Input, Button, Row, Col, message, Upload, Tooltip } from 'antd';
import { Tabs } from 'antd';
import localStorage from '../../../utils/localStorage';
import { StyledDashboard, AddTableButton, StyledTabs } from './styles';
import SelectComponent from './SelectComponent';
import { getTranslationCache } from '../../../selectors/language';

import './style.css';
import { API_MASTERCONFIGURATION, IPAddress, MASTERUPLOAD } from '../../../commons/api';
import {
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
  UploadOutlined,
  SearchOutlined
} from '@ant-design/icons';
const { TabPane } = Tabs;
class MasterView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      masterCode: '',
      masterName: '',
      masterJSON: {},
      datasource: [],
      editObject: {},
      visible: false,
      activeKey: '1',
      translationCache: props.translationCache || []
    };
    this.hRef = React.createRef();
  }

  componentDidMount() {
    let masterCode = this.props.match.params.mastercode;
    this.getMasterData(masterCode);
    this.setState({
      masterJSON: this.props.match.params.mastercode
    });
  }

  componentDidUpdate(prevprops) {
    if (this.props.match.params.mastercode !== prevprops.match.params.mastercode) {
      this.getMasterData(this.props.match.params.mastercode);
      this.setState({
        masterJSON: this.props.match.params.mastercode
      });
    }
  }

  getDatasourceData = async (createAPIObject, type, Type) => {
    let { editObject, masterJSON } = this.state;
    const accessToken = localStorage.get('accessToken');
    const SiteId = localStorage.get('currentSite');
    let header = {};
    if (type === 'list') {
      header = {
        method: createAPIObject.method,
        url: `${createAPIObject.url}?SiteId=${SiteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          MasterCode: this.props.match.params.mastercode,
          ActionType: 'read',
          Type
        }
      };
    } else if (type === 'create') {
      header = {
        method: 'POST',
        url: `${createAPIObject.url}?SiteId=${SiteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          MasterCode: masterJSON.MasterCode,
          ActionType: 'create',
          Type,
          data: { ...editObject }
        }
      };
    } else if (type === 'update') {
      header = {
        method: createAPIObject.method,
        url: `${createAPIObject.url}?SiteId=${SiteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          MasterCode: masterJSON.MasterCode,
          ActionType: 'update',
          Type,
          data: { ...editObject, SiteId }
        }
      };
    } else if (type === 'delete') {
      header = {
        method: createAPIObject.method,
        url: `${createAPIObject.url}?SiteId=${SiteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          MasterCode: masterJSON.MasterCode,
          ActionType: 'delete',
          data: { ...editObject, SiteId },
          Type
        }
      };
    } else {
      header = {};
    }

    return axios(header)
      .then((response) => {
        return response.data;
      })
      .catch(function (err) {
        if (err.response.status === 400 || err.response.status === 500) {
          message.info(err.response.data.message);
        }
      });
  };

  getMasterData = async (masterCode) => {
    let masterJSON = await this._getMasterData(masterCode);
    let columns =
      masterJSON && masterJSON[0] && masterJSON[0].JSON && masterJSON[0].JSON.columns
        ? masterJSON[0].JSON.columns
        : [];
    columns = columns.filter((col) => col.key !== 'Id' && col.key !== 'SiteId');
    masterJSON = [
      {
        ...(masterJSON && masterJSON[0] ? masterJSON[0] : {}),
        JSON: {
          ...(masterJSON && masterJSON[0] && masterJSON[0].JSON ? masterJSON[0].JSON : {}),
          columns
        }
      }
    ];
    if (
      masterJSON &&
      masterJSON[0] &&
      masterJSON[0].JSON &&
      masterJSON[0].JSON.api &&
      masterJSON[0].JSON.api.read
    ) {
      let datasource = await this.getDatasourceData(
        masterJSON[0].JSON.api.read,
        'list',
        masterJSON[0].Type
      );

      this.DownloadCompleteData(masterJSON[0].JSON);
      this.setState({
        masterJSON: masterJSON[0].JSON,
        masterCode,
        Type: masterJSON[0].Type,
        datasource,
        activeKey: '1',
        masterObject: masterJSON
      });
    }
  };
  _getMasterData = (MasterCode) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'GET',
      url: `${API_MASTERCONFIGURATION.GET}?SiteId=${siteId}&MasterCode=${MasterCode}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(Object)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };

  openDrawer = (e, row) => {
    e.preventDefault();
    this.setState({
      editObject: row ? row : {},
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      activeKey: '1'
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
      editObject: {}
    });
  };

  _masterCreateObject = (e, apiObject) => {
    e.preventDefault();
    let { masterJSON, masterObject } = this.state;
    this.hRef.current
      .validateFields()
      .then(async () => {
        await this.getDatasourceData(apiObject, 'create', masterObject[0].Type);
        let datasource = await this.getDatasourceData(
          masterJSON.api.read,
          'list',
          masterObject[0].Type
        );
        this.setState({
          // visible: !visible,
          activeKey: '1',
          datasource
        });
        let masterCode = this.props.match.params.mastercode;
        this.getMasterData(masterCode);
      })
      .catch(() => {});
  };

  _masterUpdateObject = (e, apiObject) => {
    e.preventDefault();
    let { masterJSON, masterObject } = this.state;
    this.hRef.current
      .validateFields()
      .then(async () => {
        await this.getDatasourceData(apiObject, 'update', masterObject[0].Type);
        let datasource = await this.getDatasourceData(
          masterJSON.api.read,
          'list',
          masterObject[0].Type
        );
        this.setState({
          activeKey: '1',
          datasource
        });
      })
      .catch(() => {});
  };

  _masterDeleteObject = (e, apiObject) => {
    e.preventDefault();
    let { masterJSON, masterObject } = this.state;
    this.hRef.current
      .validateFields()
      .then(async () => {
        await this.getDatasourceData(apiObject, 'delete', masterObject[0].Type);
        let datasource = await this.getDatasourceData(
          masterJSON.api.read,
          'list',
          masterObject[0].Type
        );
        this.setState({
          activeKey: '1',
          datasource
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onInputChange = (e, key) => {
    let { editObject } = this.state;
    editObject = {
      ...editObject,
      [key]: e
    };
    this.setState({
      editObject
    });
  };

  renderFormItems(formObject, editObject) {
    switch (formObject.widget) {
      case 'input':
        return (
          <Input
            disabled={
              formObject &&
              formObject.disableOnUpdate &&
              editObject &&
              (editObject.Id || formObject.key === 'Id')
                ? formObject.disableOnUpdate
                : false
            }
            value={editObject && editObject[formObject.key] ? editObject[formObject.key] : ''}
            onChange={(e) => this.onInputChange(e.target.value, formObject.key)}
          />
        );

      case 'select':
        return (
          <SelectComponent
            value={editObject && editObject[formObject.key] ? editObject[formObject.key] : ''}
            column={formObject}
            handleFieldChange={this.onInputChange}
          />
        );
      default:
        return (
          <Input
            disabled={
              formObject &&
              formObject.disableOnUpdate &&
              editObject &&
              (editObject.Id || formObject.key === 'Id')
                ? formObject.disableOnUpdate
                : false
            }
            value={editObject && editObject[formObject.key] ? editObject[formObject.key] : ''}
            onChange={(e) => this.onInputChange(e.target.value, formObject.key)}
          />
        );
    }
  }

  DownloadCompleteData = (masterJSON) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let payload = {
      url: `${MASTERUPLOAD.DOWNLOAD}?SiteId=${siteId}&TableName=${masterJSON.TableName}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(payload)
      .then((response) => {
        this.setState({ fileName: response.data.file });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  DeleteCompleteData = () => {
    let { masterJSON } = this.state;
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let payload = {
      url: `${MASTERUPLOAD.Delete}?SiteId=${siteId}&TableName=${masterJSON.TableName}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(payload)
      .then((response) => {
        if (response.data && response.data.message) {
          message.success(response.data.message);
          this.getMasterData(masterJSON.MasterCode);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation(`Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    let { datasource, masterJSON, editObject, activeKey, fileName, masterObject } = this.state;
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    console.log(masterJSON.columns, 'masterColumns');

    return (
      <div>
        {masterJSON && masterJSON.MasterCode ? (
          <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <AddTableButton
                type="primary"
                style={{
                  // background: 'rgb(33, 73, 114)',
                  border: 'rgb(33, 73, 114)',
                  marginLeft: '10px'
                }}
                onClick={() => this.setState({ activeKey: '2', editObject: {} })}
              >
                <PlusOutlined />
                {this.translation('Add New')}
              </AddTableButton>
              {masterObject && masterObject[0] && masterObject[0].Upload ? (
                <Upload
                  {...{
                    name: 'file',
                    action: `${MASTERUPLOAD.UPLOAD}?SiteId=${siteId}&TableName=${masterJSON.TableName}`,
                    headers: {
                      Authorization: `Bearer ${accessToken}`
                    },
                    onChange: (info) => {
                      if (info.file.status === 'done') {
                        message.success(`${info.file.name} file uploaded successfully`);
                        this.getMasterData(masterJSON.MasterCode);
                      } else if (info.file.status === 'error') {
                        message.error(`${info.file.name} file upload failed.`);
                      }
                    }
                  }}
                >
                  <Button icon={<UploadOutlined />}>Bulk Data Upload</Button>
                </Upload>
              ) : null}
              <Tooltip title={fileName ? 'Download' : 'Error in Download API'}>
                <AddTableButton
                  disabled={fileName ? false : true}
                  type="primary"
                  style={{ marginRight: '10px' }}
                >
                  <a href={`${IPAddress.LOCAL}/api/downloads/${fileName}`}>
                    <DownloadOutlined />
                    {this.translation('Download Complete Data')}
                  </a>
                </AddTableButton>
              </Tooltip>

              <AddTableButton
                type="primary"
                style={{
                  background: 'rgb(33, 73, 114)',
                  border: 'rgb(33, 73, 114)',
                  marginRight: '10px'
                }}
                onClick={() => this.DeleteCompleteData()}
              >
                <DeleteOutlined />
                {this.translation('Delete Complete Data')}
              </AddTableButton>
            </div>
            <StyledTabs
              type="card"
              defaultActiveKey="1"
              activeKey={activeKey}
              onChange={async (activeKey) => {
                if (activeKey === '2') {
                  this.setState({ activeKey, editObject: {} });
                }
                if (activeKey === '1') {
                  let masterCode = this.props.match.params.mastercode;
                  this.getMasterData(masterCode);
                }
                this.setState({ activeKey });
              }}
            >
              <TabPane tab={this.translation('List')} key="1">
                <Table
                  className="masterTable"
                  columns={
                    masterJSON.columns &&
                    Array.isArray(masterJSON.columns) &&
                    masterJSON.columns.map((columnData) => {
                      return {
                        ...columnData,
                        dataIndex: columnData.key,
                        title: this.translation(columnData.title),
                        render: (text) => (
                          <div>{typeof text === 'boolean' ? text.toString() : text}</div>
                        ),
                        ...this.getColumnSearchProps(columnData.key)
                      };
                    })
                  }
                  dataSource={datasource && Array.isArray(datasource) ? datasource : []}
                  size="large"
                  pagination={
                    datasource && Array.isArray(datasource) && datasource.length > 10
                      ? {
                          pageSize: '10'
                        }
                      : false
                  }
                  locale={{ emptyText: this.translation('No Data') }}
                  onRow={(record) => ({
                    onClick: (e) => {
                      e.stopPropagation();
                      if (this.hRef.current) {
                        this.hRef.current.setFieldsValue(record);
                      }
                      this.setState({ activeKey: '2', editObject: record ? record : {} });
                    }
                  })}
                />
              </TabPane>
              <TabPane tab={this.translation('Details')} key="2">
                <div>
                  {activeKey && activeKey === '2' ? (
                    <>
                      <Form layout="vertical" ref={this.hRef} initialValues={editObject}>
                        <Row gutter={18}>
                          <Col span={16}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                boxSizing: 'border-box'
                              }}
                            >
                              {masterJSON &&
                                masterJSON.columns &&
                                Array.isArray(masterJSON.columns) &&
                                masterJSON.columns.map((masterColumns, index) => {
                                  return (
                                    <Col key={index} className="gutter-row" span={8}>
                                      <Form.Item
                                        key={index}
                                        required={false}
                                        className={'form'}
                                        label={masterColumns.title}
                                      >
                                        <div>{this.renderFormItems(masterColumns, editObject)}</div>
                                      </Form.Item>
                                    </Col>
                                  );
                                })}
                            </div>
                          </Col>
                        </Row>

                        <Button onClick={this.onClose}>{this.translation('Cancel')}</Button>
                        {editObject && editObject.Id !== undefined ? (
                          <Button
                            style={{ marginLeft: '9px' }}
                            onClick={(e) =>
                              this._masterUpdateObject(e, masterJSON.api.update, 'update')
                            }
                            type="primary"
                          >
                            {this.translation(`Update`)}
                          </Button>
                        ) : (
                          <Button
                            style={{ marginLeft: '9px' }}
                            onClick={(e) =>
                              this._masterCreateObject(e, masterJSON.api.create, 'create')
                            }
                            type="primary"
                          >
                            {this.translation(`Create`)}
                          </Button>
                        )}
                        {editObject && editObject.Id !== undefined ? (
                          <Button
                            style={{ marginLeft: '9px' }}
                            // className="SubmitButton"
                            onClick={(e) =>
                              this._masterDeleteObject(e, masterJSON.api.delete, 'delete')
                            }
                            type="primary"
                          >
                            {this.translation(`Delete`)}
                          </Button>
                        ) : null}
                      </Form>
                    </>
                  ) : null}
                </div>
              </TabPane>
            </StyledTabs>
          </StyledDashboard>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(MasterView);
