import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import axios from 'axios';
import { getTranslationCache } from '../../../selectors/language';
import { getUserProfile } from '../../../selectors/layout';
import { Button, Input, Upload, message, Modal } from 'antd';
import { IPAddress } from '../../../commons/api';
import '../CSS/style.css';
import { checkFileAlreadyExistsOrNot, getFileData } from '../Utils/ApICalls';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import localStorage from '../../../utils/localStorage';
import Highlighter from 'react-highlight-words';
import { StyledTable, StyledComponent } from '../Utils/style';
import themeSettings from '../Utils/themeSettings.json';
class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      image: [],
      viewModal: false
    };
  }
  componentDidMount() {
    this.getAttachmentList();
  }

  getAttachmentList = async () => {
    let files = await getFileData();
    this.setState({ files });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  handleCancel = () => {
    this.setState({ viewModal: false });
  };

  handleOk = () => {
    this.setState({
      viewModal: false
    });
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let url = '';
    url = `/api/chat/upload?SiteId=${siteId}`;
    let formData = new FormData();
    let files = this.state.newImages[0];
    let fileExists = this.state.fileExist;
    formData.append('file', files);
    formData.append('OverWrite', fileExists);
    let orgHeaders = {
      method: 'POST',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: formData
    };
    axios(orgHeaders)
      .then((response) => {
        message.success('File uploaded Successfully');
        this.setState({ list: response.data });
        this.getAttachmentList();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  render() {
    let { files, viewModal } = this.state;
    const columns = [
      {
        title: this.translation('File Name'),
        dataIndex: 'FileName',
        key: 'FileName',
        ...this.getColumnSearchProps('FileName'),
        render: (text, record) => (
          <a
            href={`${IPAddress.LOCAL}/api/${record.FilePath}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
        )
      },
      {
        title: this.translation('Attachment Type'),
        dataIndex: 'AttachmentType',
        key: 'AttachmentType'
      },
      {
        title: this.translation('Updated By'),
        dataIndex: 'UpdatedBy',
        key: 'UpdatedBy'
      },
      {
        title: this.translation('Updated Date'),
        dataIndex: 'UpdatedDate',
        key: 'UpdatedDate'
      }
    ];
    let siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 13 }}>
        <div style={{ margin: '20px' }}>
          <Upload
            name="file"
            showUploadList={false}
            action={`/api/chat/upload?SiteId=${siteId}`}
            multiple="false"
            headers={{
              Authorization: Authorization
            }}
            data={{
              OverWrite: false,
              file: this.state.FileName
            }}
            beforeUpload={async (uploadedFile) => {
              let payload = { FileName: uploadedFile.name };
              let fileExist = await checkFileAlreadyExistsOrNot(payload);

              if (fileExist.FileStatus !== 'false') {
                let newImages = [...this.state.image];
                newImages.push(uploadedFile);
                this.setState({
                  viewModal: true,
                  newImages,
                  fileExist: fileExist.FileStatus
                });
                return false;
              } else {
                return true;
              }
            }}
            onChange={(info) => {
              if (info.file.status === 'done') {
                message.success('File uploaded Successfully');
                this.setState({ fileList: info.fileList });
                this.getAttachmentList();
              } else if (info.file.status === 'error') {
                message.error('Failed');
              }
            }}
          >
            <Button icon={<UploadOutlined />}>Upload a file</Button>
          </Upload>
          <Modal open={viewModal} footer={null}>
            <div>
              <p style={{ fontSize: '20px' }}>Do you want to overwrite the existing file??</p>
              <div style={{ marginTop: '5%' }}>
                <Button onClick={this.handleOk}>{'Yes'}</Button>
                <Button style={{ marginLeft: '2%' }} onClick={this.handleCancel}>
                  {'No'}
                </Button>
              </div>
            </div>
          </Modal>
          <StyledTable
            style={{ marginTop: '20px' }}
            columns={columns ? columns : []}
            dataSource={files}
            scroll={{ x: 1000, y: 1000 }}
          />
        </div>
      </StyledComponent>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(FileUpload);
