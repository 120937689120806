import React, { Component } from 'react';
import { Form, Select, Row, Col } from 'antd';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../../../selectors/language';

class StatusCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: '',
      translationCache: props.translationCache || []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  renderWidget = (columns) => {
    let { widget, label, options, value } = columns;
    switch (widget) {
      case 'select':
        return (
          <Form.Item label={this.translation(label)}>
            <Select style={{ width: 220 }}>
              {options &&
                Array.isArray(options) &&
                options.map((value) => {
                  return (
                    <Select.Option key={value} value={value}>
                      {value}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        );
      default:
        return (
          <Form.Item label={this.translation(label)}>
            <div>{value}</div>
          </Form.Item>
        );
    }
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    };
    let columns1 = [
      {
        label: this.translation('HD'),
        widget: 'select',
        options: ['01']
      },
      {
        label: this.translation('CH'),
        widget: 'select',
        options: ['01']
      },
      {
        label: this.translation('Hold'),
        widget: 'select',
        options: ['None']
      },
      {
        label: this.translation('PGC Mode'),
        widget: 'select',
        options: ['Auto', 'Fixed']
      },
      {
        label: this.translation('Display Moisture'),
        widget: 'text',
        value: '21.3'
      },
      {
        label: this.translation('Raw Moisture'),
        value: '211.3'
      },
      {
        label: this.translation('Absorbance (X1)'),
        widget: 'text',
        value: '21.3'
      },
      {
        label: this.translation('Absorbance (X2)'),
        widget: 'text',
        value: '21.3'
      }
    ];
    let columns2 = [
      {
        label: this.translation('Alarm'),
        widget: 'text',
        value: 'None'
      },
      {
        label: this.translation('Hold'),
        widget: 'text',
        value: 'None'
      },
      {
        label: this.translation('PGC Mode'),
        widget: 'text',
        value: 'Auto'
      },
      {
        label: this.translation('Temperature(t)'),
        widget: 'text',
        value: '27.3'
      },
      {
        label: this.translation('Temperature Volt(vt)'),
        widget: 'text',
        value: '0.245'
      },
      {
        label: this.translation('Volt(v1)'),
        widget: 'text',
        value: '1.6589'
      },
      {
        label: this.translation('Volt(v2)'),
        widget: 'text',
        value: '1.6229'
      },
      {
        label: this.translation('Volt(v3)'),
        widget: 'text',
        value: '1.6589'
      },
      {
        label: this.translation('PGC'),
        widget: 'text',
        value: '40.0'
      }
    ];
    let columns3 = [
      {
        label: this.translation('E-01'),
        widget: 'text',
        value: 'Ro level error'
      },
      {
        label: this.translation('E-02'),
        widget: 'text',
        value: 'Temperature error- high'
      },
      {
        label: this.translation('E-03'),
        widget: 'text',
        value: 'Temperature error- low'
      },
      {
        label: this.translation('E-04'),
        widget: 'text',
        value: 'Absorbance calculation error'
      },
      {
        label: this.translation('E-05'),
        widget: 'text',
        value: 'Motor error'
      }
    ];
    let columns4 = [
      {
        label: this.translation('E-07'),
        widget: 'text',
        value: 'Channel unset'
      },
      {
        label: this.translation('E-08'),
        widget: 'text',
        value: 'System memory error'
      },
      {
        label: this.translation('E-09'),
        widget: 'text',
        value: 'Channel memory error'
      },
      {
        label: this.translation('E-12'),
        widget: 'text',
        value: 'Sensor data input error'
      }
    ];
    return (
      <div style={{}}>
        <Form
          {...formItemLayout}
          layout="horizontal"
          name="HeaderNumber_current"
          style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
        >
          <Row>
            <Col flex={3}>
              {columns1 &&
                columns1.map((columnData) => {
                  return this.renderWidget(columnData);
                })}
            </Col>
            <Col flex={3}>
              {columns2 &&
                columns2.map((columnData) => {
                  return this.renderWidget(columnData);
                })}
            </Col>
          </Row>

          <div style={{ border: '1px dashed' }}>
            <Row>
              <Col flex={3}>
                {columns3 &&
                  columns3.map((columnData) => {
                    return this.renderWidget(columnData);
                  })}
              </Col>
              <Col flex={3}>
                {columns4 &&
                  columns4.map((columnData) => {
                    return this.renderWidget(columnData);
                  })}
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(StatusCheck);
