import React, { Fragment } from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Space,
  Table,
  Tabs,
  Upload,
  Divider,
  TreeSelect
} from 'antd';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import { StyledForm } from '../CSS/style';
import { constant } from '../Utils/constants';
import InputComponent from '../Utils/InputComponent';
import themeSettings from '../Utils/themeSettings.json';

import translation from '../Utils/translation';
import {
  getDeviceDetailsById,
  getDeviceTypeList,
  saveDevice,
  saveParameter,
  getAttributeList,
  getParameterData,
  getSubEquipmentListWithParent,
  getParameterFileDownloadNameAPI,
  getPriorityList
} from '../APICalls';
import localStorage from '../../../utils/localStorage';
import moment from 'moment';
import SelectComponent from '../Utils/SelectComponent';
import { IPAddress } from '../../../commons/api';
import { DownloadOutlined, UploadOutlined, SearchOutlined } from '@ant-design/icons';
import SubEquipments from './SubEquipments';

class Device extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'Device',
      formObject: {},
      attributeList: [],
      parameterList: [],
      loading: false,
      subequipmentListWithParent: {}
    };
    this.hRef = React.createRef();
  }
  componentDidMount() {
    this.setData(this.props.ShowForm);
    this.GetDeviceType(this.props.Module);
  }

  componentDidUpdate(prevprops) {
    if (
      prevprops.ShowForm !== this.props.ShowForm ||
      prevprops.selectedRow !== this.props.selectedRow
    ) {
      this.setData(this.props.ShowForm);
    }
  }

  GetDeviceType = async (module) => {
    let deviceTypeList = await getDeviceTypeList(module);
    let priorityList = await getPriorityList();
    this.setState({ deviceTypeList, priorityList });
  };

  setData = async (ShowForm) => {
    if (ShowForm === 'ADD_DEVICE') {
      let { selectedRow } = this.props;
      if (selectedRow && selectedRow.Type === 'DeviceType') {
        let formData = {
          DeviceType: selectedRow.Code
        };
        let attributeList = await getAttributeList(selectedRow.Code);
        const attKeys = {};
        attributeList.forEach(({ Code }) => Object.assign(attKeys, { [Code]: null }));

        this.hRef.current &&
          this.hRef.current.setFieldsValue({
            DeviceType: null,
            Name: null,
            Description: null,
            DisplayCode: null,
            Id: null,
            ...attKeys
          });
        this.hRef.current && this.hRef.current.setFieldsValue({ ...formData });
        this.setState({ formObject: formData, attributeList, activeKey: 'Device' });
      } else {
        this.hRef.current &&
          this.hRef.current.setFieldsValue({
            DeviceType: null,
            Name: null,
            Description: null,
            DisplayCode: null,
            InstallationDate: null,
            ReplacementDate: null,
            SerialNo: null,
            UnitName: null,
            Id: null
          });
        this.setState({ formObject: {}, attributeList: [], activeKey: 'Device' });
      }
    } else if (ShowForm === 'EDIT_DEVICE') {
      let { selectedRow } = this.props;
      if (selectedRow.Code) {
        let formObject = await getDeviceDetailsById(selectedRow.Code, 'AliasCode');
        _.cloneDeepWith(formObject, (value) => {
          return value && value !== null && (value.ReplacementDate || value.InstallationDate)
            ? {
                ...value,
                ...(value['ReplacementDate'] = moment.utc(
                  value.ReplacementDate,
                  'YYYY-MM-DD HH:mm:ss'
                )),
                ...(value['InstallationDate'] = moment.utc(
                  value.InstallationDate,
                  'YYYY-MM-DD HH:mm:ss'
                ))
              }
            : _.noop();
        });

        let formData = formObject && formObject[0] ? formObject[0] : {};
        let attributeList = await getAttributeList(formData.DeviceType);
        this.hRef.current && this.hRef.current.setFieldsValue({ ...formData });
        let { parent } = this.props;
        this.getParameterFileDownloadName(parent.Code, selectedRow.Code);
        this.setState({
          formObject: formData,
          attributeList,
          activeKey: 'Device',
          treeValue: selectedRow.Code
        });

        this.getParameterList(selectedRow.Code);
        this.equipmentParentNChildEquipment(selectedRow.Code);
      } else {
        message.error('DeviceCode is missing in hierarchy structure');
      }
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onFieldChange = async (fieldValue, fieldName) => {
    let { formObject } = this.state;
    if (fieldName === 'DeviceType') {
      let attributeList = await getAttributeList(fieldValue);
      this.setState({ attributeList });
    }

    this.setState({
      formObject: {
        ...formObject,
        [fieldName]: fieldValue
      }
    });
  };

  onReset = () => {
    let { attributeList } = this.state;
    const attKeys = {};
    attributeList.forEach(({ Code }) => Object.assign(attKeys, { [Code]: null }));

    this.hRef.current &&
      this.hRef.current.setFieldsValue({
        DeviceType: null,
        Name: null,
        Description: null,
        DisplayCode: null,
        Id: null,
        ...attKeys
      });
    this.setState({ formObject: {}, attributeList: [] });
  };
  onCreate = async () => {
    let { selectedRow, parent } = this.props;
    const siteId = localStorage.get('currentSite');

    this.hRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...this.hRef.current.getFieldsValue()
        };
        let payload = {};
        let { formObject } = this.state;
        if (values.Id) {
          payload = {
            ...values,
            EquipmentType: 'Parent',
            ParentEquipment: null,
            AssetAlias: formObject && formObject.AssetAlias ? formObject.AssetAlias : '',
            SubDeviceType: null
          };
        } else {
          payload = {
            ...values,
            DeviceType: selectedRow.Type === 'DeviceType' ? selectedRow.Code : values.DeviceType,
            SiteId: siteId,
            EquipmentType: 'Parent',
            AssetAlias: selectedRow.Type === 'DeviceType' ? parent.Code : selectedRow.Code,
            ParentEquipment: null
          };
        }
        if (payload && (!payload.Id || payload.Id === null)) {
          delete payload.Id;
        }
        let saveResponse = await saveDevice(payload);
        if (saveResponse && saveResponse.message) {
          message.success(saveResponse.message);
          this.getParameterList(selectedRow.Code);
          let formObject = await getDeviceDetailsById(saveResponse.AliasCode, 'AliasCode');
          _.cloneDeepWith(formObject, (value) => {
            return value && value !== null && (value.ReplacementDate || value.InstallationDate)
              ? {
                  ...value,
                  ...(value['ReplacementDate'] = moment.utc(
                    value.ReplacementDate,
                    'YYYY-MM-DD HH:mm:ss'
                  )),
                  ...(value['InstallationDate'] = moment.utc(
                    value.InstallationDate,
                    'YYYY-MM-DD HH:mm:ss'
                  ))
                }
              : _.noop();
          });

          let formData = formObject && formObject[0] ? formObject[0] : {};
          this.props.GetHierarchyLevelsUpdated();
          this.hRef.current.setFieldsValue(formData);
          this.setState({ formObject: formData });
        }
      })
      .catch(() => {});
  };
  getParameterList = async (DeviceAlias) => {
    let { formObject } = this.state;
    let parameterList = await getParameterData(formObject.DeviceType, DeviceAlias);
    let DuplicateList = await getParameterData(formObject.DeviceType, DeviceAlias);
    // let newObj={};
    parameterList &&
      Array.isArray(parameterList) &&
      parameterList.map((record) => {
        record['Editable'] = 'false';
        return {};
      });
    DuplicateList &&
      Array.isArray(DuplicateList) &&
      DuplicateList.map((record) => {
        record['Editable'] = 'false';
        return {};
      });
    this.setState({ parameterList, DuplicateList });
  };
  updateParentDataFromSUbEqpChange = () => {
    let { selectedRow } = this.state;
    this.equipmentParentNChildEquipment(selectedRow.Code);
  };
  equipmentParentNChildEquipment = async (DeviceAlias) => {
    let subequipmentListWithParent = await getSubEquipmentListWithParent(DeviceAlias);
    this.setState({ subequipmentListWithParent });
  };
  getParameterFileDownloadName = async (DeviceType, DeviceAlias) => {
    let parameterFileName = await getParameterFileDownloadNameAPI(DeviceType, DeviceAlias);
    this.setState({
      parameterFileName: parameterFileName && parameterFileName.file ? parameterFileName.file : ''
    });
  };
  editRow = (key, record) => {
    record['Editable'] = 'true';
    this.setState({
      record,
      SlectedRow: key
    });
  };

  handleKeyPress(e, key) {
    if (e.key === 'Enter') {
      this.saveRow(e, key);
    }
  }
  saveRow = async (e, record) => {
    let { selectedRow, parent } = this.props;
    let { treeValue } = this.state;
    this.setState({ loading: true });
    let payload = {
      ...record,
      DeviceAlias: selectedRow && selectedRow.Code
    };

    let t1 = await saveParameter({ ...payload, DeviceAlias: treeValue });
    message.success(t1 && t1.message);
    let uot = this.getParameterList(treeValue);
    this.getParameterFileDownloadName(parent.Code, selectedRow.Code);
    if (uot) {
      record['Editable'] = 'false';
      this.setState({ loading: false, parameterList: uot });
    }
  };
  getRowByKey(key, newData) {
    const { parameterList } = this.state;
    return (newData || parameterList).filter((item) => item.id === key)[0];
  }
  handleFieldChange(value, fieldName) {
    let { SlectedRow, parameterList } = this.state;
    let data = parameterList;
    let EditedRecord = data.filter((item) => {
      // if (item.Code === SlectedRow) {
      //   return item;
      // }
      return item.Code === SlectedRow;
    });
    let Record = EditedRecord && EditedRecord[0];
    Record[fieldName] = value;

    // let FinalData = parameterList.find((obj) => obj.Code === SlectedRow);
    // Object.assign(FinalData, Record);

    this.setState({ Record });
  }

  renderWidget = (props) => {
    let { widget, Code, isEditableOnUpdate, JSON, Name } = props;
    let { formObject } = this.state;
    let deviceType =
      this.hRef && this.hRef.current && this.hRef.current.getFieldValue('DeviceType');
    switch (widget) {
      case 'text':
        return (
          <InputComponent
            value={
              formObject && formObject[Code]
                ? translation(this.props.translationCache, formObject[Code])
                : ''
            }
            placeholder={`Input ${Name}`}
            disabled={formObject.Id && isEditableOnUpdate === 'true' ? true : false}
            onChange={(e) => this.onFieldChange(e.target.value, Code)}
          />
        );
      case 'dateselection':
        return (
          <DatePicker
            value={
              formObject && formObject[Code]
                ? moment(formObject[Code]).format('YYYY-MM-DD HH:mm:ss')
                : undefined
            }
            placeholder={`Select ${Name}`}
            format={'YYYY-MM-DD HH:mm:ss'}
            allowClear={false}
            showNow={false}
            showTime={false}
            disabled={formObject.Id && isEditableOnUpdate === 'true' ? true : false}
            onChange={(e) => this.onFieldChange(e, Code)}
            style={{ width: '100%' }}
          />
        );
      case 'multiselect':
        return (
          <SelectComponent
            value={
              this.state.formObject && this.state.formObject[Code]
                ? this.state.formObject[Code]
                : []
            }
            placeholder={`Select ${Name}`}
            Code={props && props.Code ? props.Code : ''}
            formObject={this.state && this.state.formObject ? this.state.formObject : ''}
            api={JSON}
            DeviceType={deviceType}
            onSelectChange={(e) => this.onFieldChange(e, Code)}
          />
        );
      default:
        return (
          <InputComponent
            value={
              formObject && formObject[Code]
                ? translation(this.props.translationCache, formObject[Code])
                : ''
            }
            placeholder={`Input ${Name}`}
            disabled={formObject.Id && isEditableOnUpdate === 'true' ? true : false}
            onChange={(e) => this.onFieldChange(e.target.value, Code)}
          />
        );
    }
  };

  cancel(e, record) {
    let { DuplicateList } = this.state;
    record['Editable'] = 'false';
    this.setState({ parameterList: DuplicateList });
  }

  onTreeChange = (treeValue) => {
    let { parent } = this.props;
    this.getParameterFileDownloadName(parent && parent.Code ? parent.Code : '', treeValue);
    this.getParameterList(treeValue);
    this.setState({ treeValue });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    let { ShowForm, translationCache, selectedRow } = this.props;
    let {
      formObject,
      deviceTypeList,
      attributeList,
      subequipmentListWithParent,
      parameterList,
      parameterFileName,
      treeValue,
      priorityList
    } = this.state;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
        ...this.getColumnSearchProps('Name')
      },
      {
        title: 'Description',
        dataIndex: 'ParameterDescription',
        key: 'ParameterDescription',
        ...this.getColumnSearchProps('ParameterDescription'),
        render: (text, record) => {
          if (record.Editable === 'true') {
            return (
              <Input
                value={text}
                onChange={(e) =>
                  this.handleFieldChange(e.target.value, 'ParameterDescription', record.Code)
                }
                onKeyPress={(e) => this.handleKeyPress(e, record.id)}
                placeholder={'Description'}
              />
            );
          } else {
            return <div>{text}</div>;
          }
        }
      },
      {
        title: 'Edge Code',
        dataIndex: 'EdgeCode',
        key: 'EdgeCode',
        ...this.getColumnSearchProps('EdgeCode'),
        render: (text, record) => {
          if (record.Editable === 'true') {
            return (
              <Input
                value={record.EdgeCode}
                onChange={(e) => this.handleFieldChange(e.target.value, 'EdgeCode', record.Code)}
                onKeyPress={(e) => this.handleKeyPress(e, record.Code)}
                placeholder={'EdgeCode'}
              />
            );
          } else {
            return <div>{text}</div>;
          }
        }
      },
      {
        title: 'Priority',
        dataIndex: 'Priority',
        key: 'Priority',
        ...this.getColumnSearchProps('Priority'),
        render: (text, record) => {
          if (record.Editable === 'true') {
            return (
              <Select
                style={{ width: 150 }}
                value={record.Priority}
                onChange={(value) => this.handleFieldChange(value, 'Priority', record.Code)}
                placeholder={'Priority'}
              >
                {priorityList &&
                  Array.isArray(priorityList) &&
                  priorityList.map((priority, index) => {
                    return (
                      <Select.Option key={index} value={priority.Value}>
                        {priority.Key}
                      </Select.Option>
                    );
                  })}
              </Select>
            );
          } else {
            return <div>{text}</div>;
          }
        }
      },
      {
        title: 'Vendor Code',
        dataIndex: 'VendorCode',
        key: 'VendorCode',
        ...this.getColumnSearchProps('VendorCode'),
        render: (text, record) => {
          if (record.Editable === 'true') {
            return (
              <Input
                value={record.VendorCode}
                onChange={(e) => this.handleFieldChange(e.target.value, 'VendorCode', record.Code)}
                onKeyPress={(e) => this.handleKeyPress(e, record.Code)}
                placeholder={'Vendor Code'}
              />
            );
          } else {
            return <div>{text}</div>;
          }
        }
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => {
          if (record.Editable === 'false') {
            return (
              <span>
                <Button onClick={() => this.editRow(record.Code, record)}>{'Edit'}</Button>
              </span>
            );
          } else {
            if (record.Editable === 'true') {
              return (
                <span>
                  <Button onClick={(e) => this.saveRow(e, record)}>
                    {this.translation('Save')}{' '}
                  </Button>
                  <Divider type="vertical" />
                  <Button onClick={(e) => this.cancel(e, record)}>
                    {this.translation('Cancel')}
                  </Button>
                </span>
              );
            }
          }
        }
      }
    ];
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    return (
      <Fragment>
        <Tabs
          activeKey={this.state.activeKey}
          centered
          onChange={(activeKey) => {
            this.setState({ activeKey });
          }}
          style={{
            color: 'white'
          }}
        >
          <Tabs.TabPane tab={ShowForm === 'ADD_DEVICE' ? 'Add System' : 'Edit System'} key="Device">
            <StyledForm
              name="DeviceForm"
              ref={this.hRef}
              theme={themeSettings}
              initialValues={formObject}
              layout="horizontal"
              labelCol={{ flex: '130px', marginTop: '20px' }}
              labelAlign="right"
              labelWrap
            >
              <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '15px' }}>
                <Form.Item name={'Id'}></Form.Item>
                {selectedRow.Type === 'DeviceType' ? null : (
                  <Form.Item
                    name="DeviceType"
                    label={translation(translationCache, constant.DeviceTypes)}
                    style={{ width: '50%' }}
                    rules={[{ required: true, message: `Please enter DeviceType!` }]}
                  >
                    <Select
                      value={
                        formObject && formObject.DeviceType
                          ? translation(translationCache, formObject.DeviceType)
                          : []
                      }
                      placeholder={`Input ${constant.DeviceTypes}`}
                      disabled={formObject && formObject.Id ? true : false}
                      onChange={(e) => this.onFieldChange(e, 'DeviceType')}
                    >
                      {deviceTypeList &&
                        Array.isArray(deviceTypeList) &&
                        deviceTypeList.map((devicetype, index) => {
                          return (
                            <Select.Option key={index} value={devicetype.AliasCode}>
                              {devicetype.Name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item
                  name="Name"
                  label={translation(translationCache, constant.Name)}
                  style={{ width: '50%' }}
                  rules={[{ required: true, message: `Please enter name!` }]}
                >
                  <InputComponent
                    value={
                      formObject && formObject.Name
                        ? translation(translationCache, formObject.Name)
                        : ''
                    }
                    placeholder={`Input ${constant.Name}`}
                    // onChange={(e) => this.onFieldChange(e.target.value, 'Name')}
                  />
                </Form.Item>
                <Form.Item
                  name="Description"
                  label={translation(translationCache, constant.Description)}
                  style={{ width: '50%' }}
                  rules={[{ required: true, message: `Please enter Description!` }]}
                >
                  <Input.TextArea
                    placeholder={`Input ${constant.Description}`}
                    value={
                      formObject && formObject.Description
                        ? translation(translationCache, formObject.Description)
                        : ''
                    }
                    // onChange={(e) => this.onFieldChange(e.target.value, 'Description')}
                  />
                </Form.Item>
                <Form.Item
                  name="DisplayCode"
                  label={translation(translationCache, constant.Code)}
                  style={{ width: '50%' }}
                  rules={[{ required: true, message: `Please enter code!` }]}
                >
                  <InputComponent
                    value={
                      formObject && formObject.DisplayCode
                        ? translation(translationCache, formObject.DisplayCode)
                        : ''
                    }
                    placeholder={`Input ${constant.Code}`}
                    // onChange={(e) => this.onFieldChange(e.target.value, 'DisplayCode')}
                  />
                </Form.Item>

                {attributeList &&
                  Array.isArray(attributeList) &&
                  attributeList.map((attr, key) => {
                    let requiredField = attr && attr.mandatory === 'Optional' ? false : true;
                    return (
                      <Form.Item
                        key={key}
                        name={attr.Code}
                        label={translation(translationCache, attr.Name)}
                        style={{ width: '50%' }}
                        rules={[{ required: requiredField, message: `Please enter ${attr.Name}!` }]}
                      >
                        {this.renderWidget(attr, formObject)}
                      </Form.Item>
                    );
                  })}
              </div>

              <Form.Item {...{ wrapperCol: { offset: 5, span: 16 } }}>
                <Space>
                  {ShowForm === 'ADD_DEVICE' ? (
                    <Button type="primary" onClick={this.onCreate}>
                      Create
                    </Button>
                  ) : (
                    <Button type="primary" onClick={this.onCreate}>
                      Update
                    </Button>
                  )}
                  <Button htmlType="button" onClick={this.onReset}>
                    Reset
                  </Button>
                </Space>
              </Form.Item>
            </StyledForm>
          </Tabs.TabPane>

          {ShowForm === 'EDIT_DEVICE' && formObject && formObject.Id ? (
            <Tabs.TabPane tab="Sub Equipments" key="SubEquipments">
              <SubEquipments
                selectedRow={this.props.selectedRow}
                treeValue={this.state.treeValue}
                ShowForm={this.props.ShowForm}
                translationCache={this.props.translationCache}
                Module={this.props.Module}
                parent={this.props.parent}
                deviceDetails={this.state.formObject}
                updateParentDataFromSUbEqpChange={this.updateParentDataFromSUbEqpChange}
              />
            </Tabs.TabPane>
          ) : null}

          {ShowForm === 'EDIT_DEVICE' && formObject && formObject.Id ? (
            <Tabs.TabPane tab="Parameters" key="parameter">
              <>
                <div style={{ margin: '10px 0px' }}>
                  <StyledForm
                    name="DeviceForm"
                    ref={this.hRef}
                    theme={themeSettings}
                    initialValues={formObject}
                    layout="horizontal"
                    labelCol={{ flex: '250px', marginTop: '20px' }}
                    labelAlign="right"
                    labelWrap
                  >
                    <Form.Item
                      label={translation(translationCache, constant.SELECTPARENT_equipment)}
                      style={{ width: '50%' }}
                      rules={[{ required: false }]}
                    >
                      <TreeSelect
                        showSearch
                        style={{ width: '100%' }}
                        value={treeValue}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={constant.SELECTPARENT_equipment}
                        allowClear={false}
                        treeDefaultExpandAll
                        onChange={this.onTreeChange}
                        treeData={subequipmentListWithParent}
                      />
                    </Form.Item>
                  </StyledForm>
                  {(parameterFileName && parameterFileName !== undefined) ||
                  (parameterFileName && parameterFileName !== '') ? (
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                      <a href={`${IPAddress.LOCAL}${parameterFileName}`}>
                        <DownloadOutlined
                          style={{ color: 'white', fontSize: '16px', marginRight: '6px' }}
                        />
                        <span style={{ color: 'white' }} disabled={true}>
                          Download{' '}
                        </span>
                      </a>
                      <Upload
                        multiple={false}
                        style={{ marginRight: '10px', color: 'white' }}
                        onChange={(info) => {
                          if (info.file.status === 'done') {
                            message.success(info.file.response.message);

                            this.getParameterList(treeValue);
                            this.getParameterFileDownloadName(
                              this.props.parent && this.props.parent.Code
                                ? this.props.parent.Code
                                : '',
                              treeValue
                            );
                          } else if (info.file.status === 'error') {
                            message.error(info.file.response.message);
                          }
                        }}
                        action={`/api/client/upload/parameter?SiteId=${siteId}&DeviceType=${
                          this.props.parent && this.props.parent.Code ? this.props.parent.Code : ''
                        }&DeviceAlias=${this.state.treeValue}`}
                        headers={{
                          Authorization: Authorization
                        }}
                        showUploadList={false}
                      >
                        <UploadOutlined
                          style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                        />
                        <span style={{ color: 'white', marginRight: '10px' }}> Upload </span>
                      </Upload>
                    </div>
                  ) : null}
                </div>
                <Table
                  loading={this.state.loading}
                  columns={columns}
                  dataSource={parameterList && Array.isArray(parameterList) ? parameterList : null}
                />
              </>
            </Tabs.TabPane>
          ) : null}
        </Tabs>
      </Fragment>
    );
  }
}
export default Device;
