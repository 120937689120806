import { fromJS } from 'immutable';

import { types } from './types';

export const getTranslationsCache = (language) => {
  return {
    type: types.GET_TRANSLATION_CACHE,
    language
  };
};
export const setTranslationCacheSuccess = (response) => {
  return {
    type: types.GET_TRANSLATION_CACHE_SUCCESS,
    response
  };
};
const initialState = fromJS({
  translationCache: {}
});

const translation = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TRANSLATION_CACHE_SUCCESS: {
      return state.set('translationCache', action.response);
    }
    default:
      return state;
  }
};

export default translation;
