import React, { Component } from 'react';

import themeSettings from '../Utils/themeSettings.json';
import { StyledTable } from '../CSS/style';

class TableSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasource: [],
      data: this.props && this.props.data ? this.props.data : []
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ data: this.props.data });
    }
  }

  setTranslation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let { data, columns } = this.props;
    return (
      <div style={{ height: '70%', marginTop: '10px' }}>
        <StyledTable
          theme={themeSettings}
          columns={columns}
          dataSource={data}
          onRow={(record) => {
            return {
              onClick: () => {
                this.props.handleDropdownChange(record);
              }
            };
          }}
        />
      </div>
    );
  }
}

export default TableSelect;
