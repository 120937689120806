import React, { Fragment } from 'react';
import moment from 'moment';
import { Table, Spin, Tag } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { tableTranslation } from '../../../Utils/Adapter/translationAdapter';
import { getTranslationCache } from '../../../../../selectors/language';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { notificationtableMock } from '../../../Mock/MockJson';
import { defaultRefreshTime } from '../../constant.json';

import '../../CSS/Table.css';
import { BellFilled } from '@ant-design/icons';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class NotificationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      tabledata: [],
      loading: false,
      raisedEvents: 0,
      closedEvents: 0,
      dataSource: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  columns = [];

  tabledata = [];

  config = {
    redirectTo: 'counter'
  };

  timeTicket = null;
  abortController = new window.AbortController();
  rowUpdateInterval = null;
  requestCheck = true;

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  updateData = async (data) => {
    try {
      this.columns = [];
      this.tabledata = [];
      const columnNames = Object.keys(data[0]);

      columnNames.map((value) => {
        //   if (value === "tagname") {
        if (value.length > 0 && value !== 'color' && value !== 'noData') {
          this.columns.push({
            title: value,
            dataIndex: value,
            key: value,
            render(text, record) {
              return {
                props: {
                  //style: { background: Math.abs(key % 2) === 1 ? '#1a1a1a' : '#000' },
                  style: { color: record && record.color ? record.color : '#000' }
                },
                children: (
                  <text style={{ color: record && record.color ? record.color : '#8c8c8c' }}>
                    {text}
                  </text>
                )
              };
            }
          });
        }
        //  } else if (value === "tagvalue") {
        //  this.columns.push({
        //   title: "Tag Value",
        //   dataIndex: value,
        //  key: value,
        //   render: text => <text style={{ color: "orange" }}>{text}</text>
        //  });
        // }
        return {};
      });

      if (data && data[0] && data[0]['noData']) {
        return {};
      } else {
        data.map((dataval, dataindex) => {
          dataval.key = dataindex + 1;
          this.tabledata.push(dataval);
          return {};
        });
      }

      let translationTable = await tableTranslation(this.columns, this.props.translationCache);
      this.setState({
        columns: translationTable,
        tabledata: this.tabledata
      });
      // }, 3000);
    } catch (e) {
      this.setState({ columns: [], tabledata: [] });
    }
  };

  getData = async () => {
    let json = [];
    this.requestCheck = false;
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    this.setState({ loading: true });
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = notificationtableMock;
      this.requestCheck = true;
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection({
          graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          selectionDateLive: this.props.selectionDateLive,
          currentTimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      let queryData = json && json.query ? json.query : [];

      if (queryData && Array.isArray(queryData) && queryData[0] && queryData[1]) {
        this.setState({
          raisedEvents: queryData[0] && queryData[0].Value ? queryData[0].Value : ''
        });
        this.setState({
          closedEvents: queryData[1] && queryData[1].Value ? queryData[1].Value : ''
        });
      }
      let dataSource = json && json.query1 ? json.query1 : [];
      this.setState({ dataSource, refreshDateTime: moment() });
      this.updateData(dataSource);
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };
  filteringEvents = (events, tagName) => {
    let dataSource = this.state.dataSource;
    let tabledata = [];
    if (events === 'raisedEvents') {
      tabledata = dataSource.filter(function (item) {
        return item.Status === tagName;
      });
    } else if (events === 'closedEvents') {
      tabledata = dataSource.filter(function (item) {
        return item.Status === tagName;
      });
    }
    this.setState({ tabledata });
  };
  render() {
    const {
      loading,
      columns,
      raisedEvents,
      closedEvents,
      refreshDateTime,
      errorInAPI,
      noDataInDB
    } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}{' '}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <span>
            {columns && columns.length > 0 ? (
              <div>
                <div className="notificationMain">
                  <div
                    className="notificationDiv"
                    onClick={() =>
                      this.filteringEvents(
                        'raisedEvents',
                        this.props.graphprops[this.props.graphprops.graph].activateLights
                      )
                    }
                  >
                    <BellFilled
                      style={{
                        marginRight: '20px',
                        fontWeight: '800',
                        fontSize: '2.5em',
                        color: this.props.graphprops.iconColor
                          ? this.props.graphprops.iconColor
                          : '#389e0d'
                      }}
                    />
                    <span
                      style={{
                        color: this.props.graphprops.iconColor
                          ? this.props.graphprops.iconColor
                          : 'green'
                      }}
                    >
                      {raisedEvents}
                    </span>
                  </div>

                  <div
                    className="notificationDiv1"
                    onClick={() =>
                      this.filteringEvents(
                        'closedEvents',
                        this.props.graphprops[this.props.graphprops.graph].deActivateLights
                      )
                    }
                  >
                    <BellFilled
                      style={{
                        marginRight: '20px',
                        fontWeight: '800',
                        fontSize: '2.5em',
                        color: this.props.graphprops.titleColor
                          ? this.props.graphprops.titleColor
                          : 'red'
                      }}
                    />
                    <span
                      style={{
                        color: this.props.graphprops.titleColor
                          ? this.props.graphprops.titleColor
                          : 'red'
                      }}
                    >
                      {closedEvents}
                    </span>
                  </div>
                </div>
                <div>
                  <Table
                    scroll={{ x: this.tabledata.length > 5 ? true : false }}
                    className="TableWidget"
                    style={{ padding: '0px 10px', fontSize: '13px !important' }}
                    columns={this.state.columns}
                    dataSource={this.state.tabledata}
                    pagination={{ pageSize: '5' }}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  color: 'white',
                  marginTop: '10%'
                }}
              >
                <h2 style={{ color: 'white' }}>
                  <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                </h2>
              </div>
            )}
          </span>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(NotificationTable);
