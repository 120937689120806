import styled from 'styled-components';
import { Button, Modal } from 'antd';

export const StyledApplication = styled.div`
  background: white;
  margin: 20px;
  padding: 30px;
  min-height: 750px;
`;

export const StyledDashboard = styled.div`
  background: white;
  // margin: 20px;
  padding: 30px;
`;
export const StyledButton = styled(Button)`
  display: flex;
  justify-content: right;
`;
export const StyledComponent = styled.div`
  background: ${(props) => props.theme.backgroundColor} !important;
  padding: 10px;
`;
export const StyledModal = styled(Modal)`
  width: 55% !important;

  .ant-modal-content {
    border-radius: 20px;
    background: #1a3652;
  }

  .ant-modal-header {
    background: #1a3652;
  }
  .ant-modal-title {
    color: #ffffff !important;
  }
  .ant-form-item-required {
    color: #ffffff !important;
  }

  .ant-modal-close {
    color: white !important;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
  }
`;
