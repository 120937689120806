import { types } from './types';
import { message } from 'antd';
import { takeLatest, call, put } from 'redux-saga/effects';

import device from './device.requests';
import {
  getAllDeviceListSuccess,
  getAllDeviceListCMSuccess,
  getTagReferenceListSuccess
} from './device.duck';
import { getAssetDeviceCache } from '../asset/asset.duck';

export function* listDeviceSaga() {
  const deviceSagaSuccess = yield call(device.getDeviceList);
  if (deviceSagaSuccess.statusCode === 400) {
    message.error(deviceSagaSuccess.message);
  } else {
    yield put(getAllDeviceListSuccess(deviceSagaSuccess));
  }
}

export function* listDeviceCMSaga() {
  const deviceSagaSuccess = yield call(device.getDeviceCMList);
  if (deviceSagaSuccess.statusCode === 400) {
    message.error(deviceSagaSuccess.message);
  } else {
    yield put(getAllDeviceListCMSuccess(deviceSagaSuccess));
  }
}

export function* createDeviceSaga(payload) {
  const deviceSagaSuccess = yield call(device.createDevice, payload.payload);
  if (deviceSagaSuccess.statusCode === 400) {
    message.error(deviceSagaSuccess.message);
  } else {
    message.success('Device saved successfully');
  }
}

export function* updateDeviceSaga(payload) {
  const deviceSagaSuccess = yield call(device.updateDevice, payload.payload);
  if (deviceSagaSuccess.statusCode === 400) {
    message.error(deviceSagaSuccess.message);
  } else {
    message.success('Device updated successfully');
  }
}

export function* deleteDeviceSaga(payload) {
  const deviceSagaSuccess = yield call(device.deleteDeviceSaga, payload.deviceId);
  if (deviceSagaSuccess.status === 204) {
    message.success('Device Deleted successfully');
    yield put(getAssetDeviceCache());
  } else {
    message.error(deviceSagaSuccess.message);
  }
}

export function* listTagReferenceSaga() {
  const tagReferenceSagaSuccess = yield call(device.getList);
  yield put(getTagReferenceListSuccess(tagReferenceSagaSuccess));
}

export default function* deviceSaga() {
  yield [
    takeLatest(types.DEVICE_LIST, listDeviceSaga),
    takeLatest(types.CREATE_DEVICE, createDeviceSaga),
    takeLatest(types.UPDATE_DEVICE, updateDeviceSaga),
    takeLatest(types.DELETE_DEVICE, deleteDeviceSaga),
    takeLatest(types.DEVICE_LIST_CM, listDeviceCMSaga),
    takeLatest(types.GET_TAGREFERENCE_LIST, listTagReferenceSaga)
  ];
}
