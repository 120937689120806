import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import ReactEcharts from '../../Echarts/Echarts';

import { getTranslationCache } from '../../../../../selectors/language';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { SunBurstMock } from '../../../Mock/MockJson';

class SunBurst extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      fromedit: this.props.graphprops.fromedit,
      data: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      chartView: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    this.getData(this.props);
  }
  getData = async () => {
    this.requestCheck = false;
    let { option } = this.state;

    let json = [];
    let { graphprops } = this.props;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = SunBurstMock;
      this.requestCheck = true;
    }

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        this.setState({ errorInAPI: true });
      } else if (json.length === 0) {
        this.setState({ noDataInDB: true });
      }
    } else {
      option.series.data = json;
      this.setState({
        option,
        chartView: true
      });
    }
  };

  componentWillUnmount() {
    this.abortController.abort();
  }

  render() {
    return (
      <Fragment>
        {this.state.chartView && (
          <ReactEcharts
            option={this.state.option}
            style={{ height: '100%', width: '100%' }}
            className="react_for_echarts"
          />
        )}
      </Fragment>
    );
  }

  getOption = () => {
    let option = {
      series: {
        type: 'sunburst',
        emphasis: {
          focus: 'ancestor'
        },
        data: [],
        radius: [0, '90%'],
        label: {
          rotate: 'radial'
        }
      }
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(SunBurst);
