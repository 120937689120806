import React from 'react';
import axios from 'axios';

import history from '../../../../commons/history';
import localStorage from '../../../../utils/localStorage';
import TrainStatus from './TrainStatus';
import { LANDINGPAGE } from '../../../../commons/api';

class DefaultLandingPageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      landingPageList: []
    };
  }
  componentDidMount() {
    this._getLandingPageList();
  }
  _getLandingPageList = () => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${LANDINGPAGE.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ landingPageList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  userBasedLandingPage = (landingPageID) => {
    let landingList = this.state.landingPageList;
    if (landingPageID && landingList && landingList.length > 0) {
      landingList
        .filter((landing) => landing.Id === landingPageID && landingPageID !== undefined)
        .map((landingPath) => history.push(landingPath.Path));
    }
  };
  render() {
    let searchParams = new URLSearchParams(this.props.history.location.search);
    let landingParams = Object.fromEntries([...searchParams]);
    return (
      <div>
        {landingParams && landingParams.name && landingParams.name === 'TrainStatus' ? (
          <TrainStatus />
        ) : (
          this.userBasedLandingPage(this.props.landingPage)
        )}
      </div>
    );
  }
}

export default DefaultLandingPageComponent;
