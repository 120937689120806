/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of WorkOrders
 */
import React, { Component } from 'react';
import { Button, Tag, Popconfirm, Tooltip, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import { encode as base64_encode } from 'base-64';
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';

import ListView from '../../../Utils/ListView';
import { constant } from '../../../Utils/constants';
import { connect } from 'react-redux';
import { getFieldDataWorkOrdersList, deleteWorkOrder } from '../../../Utils/FormCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { StyledComponent } from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import history from '../../../../../commons/history';
class FieldDataMeasurementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translationCache: props.translationCache || {},
      dataSource: [],
      userList: [],
      config: {
        viewTypes: ['card', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['WorkOrder'],

        firstTitle: 'WorkOrder',
        secondTitle: 'CreatedBy',
        thirdTitle: 'RecordedTime',

        actions: ['edit', 'delete'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/AMM/FieldDataMeasurementDetails'
        },
        deletee: {
          byAttribute: 'Id'
        }
      }
    };
  }
  componentDidMount = async () => {
    this._getAdhocDataSource();
  };
  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          //maxLength={30}
        />
        <SearchOutlined
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8, fontSize: '130%' }}
        >
          {translation(this.props.translationCache, `Search`)}
        </SearchOutlined>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, `Reset`)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  edit = async (record) => {
    let { config } = this.state;
    history.push({ pathname: `${config.edit.redirectURL}/${base64_encode(record.Id)}` });
  };
  _getAdhocDataSource = async () => {
    let dataSource = await getFieldDataWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1',
      form: {}
    });
  };

  _deleteById = async (id) => {
    await deleteWorkOrder(id);
    let dataSource = await getFieldDataWorkOrdersList();
    this.setState({
      dataSource,
      activeKey: '1'
      // form: {}
    });
  };

  render() {
    const { dataSource, userList } = this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, constant.WorkOrder),
        dataIndex: 'WorkOrder',
        key: 'WorkOrder',
        ...this.getColumnSearchProps('WorkOrder')
      },
      {
        title: translation(translationCache, constant.description),
        dataIndex: 'Description',
        key: 'Description',
        ...this.getColumnSearchProps('Description')
      },
      {
        title: translation(translationCache, constant.site),
        dataIndex: 'SiteName',
        key: 'SiteName',
        filter: true
      },
      {
        title: translation(translationCache, constant.status),
        dataIndex: 'Status',
        key: 'Status',
        render: (text) => (
          <>
            {/* <Tooltip title={`Click To ${text === 'Active' ? 'Inactive' : 'Active'}`}> */}
            {/* <span onClick={() => this.changeStatus(record.Id)}> */}
            <Tag
              color={
                text && text === 'Active' ? '#87d068' : text === 'Inactive' ? '#ff4d4f' : 'gold'
              }
              key={text}
            >
              {translation(translationCache, text)}
            </Tag>
            {/* </span>
            </Tooltip> */}
          </>
        )
      },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            <Tooltip placement="top" key="edit" title="Edit">
              <Button
                disabled={record.Status === 'Inactive' ? true : false}
                style={{ marginRight: '5px' }}
                className="ant-btn"
                onClick={() => this.edit(record)}
                size="middle"
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Popconfirm
              title="Are you sure that you want to delete the FieldDataMeasurement"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this._deleteById(record.Id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip placement="top" key="delete" title="Delete">
                <Button className="ant-btn" size="middle">
                  <span style={{ color: '#ff4d4f' }}>
                    {' '}
                    <DeleteOutlined />
                  </span>
                </Button>
              </Tooltip>
            </Popconfirm>
          </>
        )
      }
    ];
    let config = {
      title: translation(translationCache, 'Field Data Measurement'),

      viewTypes: ['card', 'table'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['WorkOrder'],

      firstTitle: 'WorkOrder',
      secondTitle: 'CreatedBy',
      thirdTitle: 'RecordedTime',

      actions: ['edit', 'delete'],
      edit: {
        byAttribute: 'Id',
        redirectURL: '/rubus/AMM/FieldDataMeasurementDetails'
      },
      deletee: {
        byAttribute: 'Id'
      }
    };
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 67 }}>
        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(FieldDataMeasurementList);
