import React from 'react';
import moment from 'moment';
import axios from 'axios';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
// import cloneDeep from 'lodash.clonedeep';
import localStorage from '../../../utils/localStorage';
import { getCurrentTimezone } from '../../../selectors/layout';
import { getTranslationCache } from '../../../selectors/language';
import ReactEcharts from '../../Dashboard/Utils/Echarts/Echarts';
import { getGraphData } from '../apiCalls';
import { Radio, Select, DatePicker } from 'antd';
import { constants } from '../Utils/constants';
import Table from '../Widget/Table';
import { API_FILE, IPAddress, REPORTS } from '../../../commons/api';
// import TrendTable from '../Widget/Table';
const { RangePicker } = DatePicker;
const { Option } = Select;
const color = [
  'orange',
  'tomato',
  'steelblue',
  'green',
  'pink',
  'voilet',
  'yellow',
  'red',
  'blue',
  'gray'
];
class GraphView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedInterval:
        this.props && this.props.payload && this.props.payload.Interval
          ? this.props.payload.Interval
          : '',
      OperatorList: this.props && this.props.OperatorList ? this.props.OperatorList : [],

      frequencyList: constants && constants.frequencyList ? constants.frequencyList : [],
      selectedOperator:
        this.props && this.props.payload && this.props.payload.Operator
          ? this.props.payload.Operator
          : '',
      payload: this.props && this.props.payload ? this.props.payload : {},
      index: this.props && this.props.index ? this.props.index : '',
      translationCache: props.translationCache || [],
      reportData: '',
      FilterData: [],
      GraphType: 'line',
      Interval: '',
      option: {},
      reportName: ''
      // selectedFromDate_ToDate1:this.props&&this.props.defaultValue?this.props.defaultValue : []
    };
  }
  componentDidMount() {
    this.generateData(this.props.payload);
  }
  componentDidUpdate(prevprops) {
    if (prevprops.payload !== this.props.payload) {
      this.setState({ payload: this.props.payload });
      this.generateData(this.props.payload);
    }
    if (
      prevprops.translationCache !== this.props.translationCache ||
      prevprops.userProfile !== this.props.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  }
  getReportNametoDownload(excelData, Type, viewType, SelectedDate, trendIndex, param) {
    console.log(
      'getReportNametoDownload 99',
      excelData,
      Type,
      viewType,
      SelectedDate,
      trendIndex,
      param
    );
    let { translationCache } = this.props;
    let Consumption = this.translation('Trend');
    if (viewType === 'Single' && Type === 'historic') {
      Consumption = param ? this.translation(param) : this.translation('Trend');
    }
    let payload = {
      PortName: 'Rubus',
      Name: Type && Type ? this.translation(Type) : this.translation('Trend'),
      Consumption,
      Data: excelData || [],
      ReportType: 'singleHierarchy',
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      SelectedDate:
        moment.utc(SelectedDate.From).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm:ss') +
        '~' +
        moment.utc(SelectedDate.To).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm:ss'),
      SelectionType: 'dateRange',
      IsTranslate: translationCache && translationCache['GeneratedDate'] ? true : false,
      translationList: {
        GeneratedDate: this.translation('GeneratedDate'),
        SelectedDate: this.translation('SelectedDate')
      }
    };
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    let options = {};
    options = {
      method: 'POST',
      url: `${API_FILE.GenerateExcel}?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: JSON.stringify(payload)
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        return '';
      });
  }
  downLoadExcel = () => {
    const { reportName } = this.state;
    const link = document.createElement('a');
    link.href = `${IPAddress.LOCAL}${REPORTS.Reports}/${reportName}`;
    link.setAttribute('download', reportName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  prepareData = async (json, trendData) => {
    let { GraphType, index } = this.state;
    let option = this.getOption();
    let xaxis = [];
    let series = [];
    let legendsdata = [];

    json &&
      json &&
      Array.isArray(json) &&
      json.map((data) => {
        data = {
          ...data,
          DateTime: moment
            .utc(data.DateTime, 'YYYY-MM-DD HH:mm:ss')
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm')
        };
        legendsdata.push({
          name: data.Description,
          icon: 'roundRect',
          textStyle: {
            fontSize: 16
          }
        });
        if (!xaxis.includes(data.DateTime)) {
          xaxis.push(data.DateTime);
        }
        if (
          series
            .filter((e) => e.value !== 'null')
            .map((data) => data.name)
            .includes(data.Name)
        ) {
          let index = series.map((data) => data.name).indexOf(data.Name);
          series[index].data.push([data.DateTime, data.Value, data.ParameterCode, data.DeviceCode]);
        } else {
          let obj = {
            dataobj: data,
            name: data.Name,
            data: [[data.DateTime, data.Value, data.ParameterCode, data.DeviceCode]],
            type: GraphType && GraphType !== 'Area' ? GraphType : 'line',
            ...(GraphType && GraphType === 'Area' ? { areaStyle: {} } : {})
          };
          series.push(obj);
        }
        return {};
      });
    xaxis.sort((a, b) => {
      return new Date(a) - new Date(b);
    });
    let seriesDataObject =
      series &&
      Array.isArray(series) &&
      series.map((seriesData) => {
        //Adding this for legend translations//
        let DataVal = { ...seriesData };
        delete DataVal.dataobj;
        return {
          ...DataVal,
          // name: this.translation(seriesData.name)
          name: `${this.translation(seriesData.dataobj.ParameterName)} (${this.translation(
            seriesData.dataobj.DeviceName
          )})`
        };
      });
    option.xAxis.data = xaxis;
    option.series = seriesDataObject;
    option.legend = [...legendsdata];
    this.setState({
      option
    });
    let reportData =
      json &&
      Array.isArray(json) &&
      json.map((data) => {
        return {
          [this.translation('DateTime')]: moment
            .utc(data.DateTime, 'YYYY-MM-DD HH:mm:ss')
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm:ss'),
          [this.translation('Name')]: `${this.translation(data.ParameterName)} (${this.translation(
            data.DeviceName
          )})`,
          [this.translation('Value')]: this.translation(data.Value),
          [this.translation('Description')]: `${this.translation(
            data.ParameterName
          )} (${this.translation(data.DeviceName)})`,
          [this.translation('DeviceName')]: this.translation(data.DeviceName),
          [this.translation('ParameterName')]: this.translation(data.ParameterName),
          val: this.translation(data.ParameterName)
        };
      });
    let reportName = {};
    if (trendData) {
      // let { Type } = trendData.Type;
      let From = moment().format('YYYY-MM-DD HH:mm:ss');
      let To = moment().format('YYYY-MM-DD HH:mm:ss');
      let { FromDate, ToDate } = trendData;
      // if (Type === 'timeRange' && index === 0) {
      //   From = FromDate1;
      //   To = ToDate1;
      // } else if (Type === 'timeRange' && index === 1) {
      //   From = FromDate2;
      //   To = ToDate2;
      // } else {
      From = FromDate;
      To = ToDate;
      // }
      let value = reportData && Array.isArray(reportData) && reportData[0] ? reportData[0].val : '';
      reportData = reportData.map((report) => {
        delete report.val;
        return {
          ...report
        };
      });

      reportName = await this.getReportNametoDownload(
        reportData,
        trendData.Type,
        trendData.ViewType,
        { From, To },
        index,
        value
      );
    }

    this.setState({
      reportData,
      reportName: reportName && reportName.file ? reportName.file : '',
      // option: { ...this.state.option, option }
      option
    });
  };

  getOption = () => {
    const graph = this;
    // let { graphType } = this.state;
    let option = {
      legend: {
        icon: 'roundRect'
      },
      tooltip: {
        trigger: 'axis'
      },
      toolbox: {
        showTitle: false,
        orient: 'horizontal',
        itemSize: 15,
        feature: {
          myTool1: {
            show: true,
            title: 'custom extension method 1',
            icon: 'path://M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM514.1 580.1l-61.8-102.4c-2.2-3.6-6.1-5.8-10.3-5.8h-38.4c-2.3 0-4.5.6-6.4 1.9-5.6 3.5-7.3 10.9-3.7 16.6l82.3 130.4-83.4 132.8a12.04 12.04 0 0 0 10.2 18.4h34.5c4.2 0 8-2.2 10.2-5.7L510 664.8l62.3 101.4c2.2 3.6 6.1 5.7 10.2 5.7H620c2.3 0 4.5-.7 6.5-1.9 5.6-3.6 7.2-11 3.6-16.6l-84-130.4 85.3-132.5a12.04 12.04 0 0 0-10.1-18.5h-35.7c-4.2 0-8.1 2.2-10.3 5.8l-61.2 102.3z',

            onclick: function () {
              graph.downLoadExcel();
            }
          },
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        data: []
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: [],
          type: 'line'
        }
      ]
    };
    return option;
  };
  generateData = async (payload) => {
    const graph = this;
    let GraphType = payload.GraphType;
    let json = await getGraphData(payload);
    this.prepareData(json, payload);
    let option = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        name: [json && json[0] ? json[0].Name : ''],
        icon: 'roundRect',
        textStyle: {
          fontSize: 15
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        showTitle: false,
        orient: 'horizontal',
        itemSize: 15,
        feature: {
          myTool1: {
            show: true,
            title: 'custom extension method 1',
            icon: 'path://M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM514.1 580.1l-61.8-102.4c-2.2-3.6-6.1-5.8-10.3-5.8h-38.4c-2.3 0-4.5.6-6.4 1.9-5.6 3.5-7.3 10.9-3.7 16.6l82.3 130.4-83.4 132.8a12.04 12.04 0 0 0 10.2 18.4h34.5c4.2 0 8-2.2 10.2-5.7L510 664.8l62.3 101.4c2.2 3.6 6.1 5.7 10.2 5.7H620c2.3 0 4.5-.7 6.5-1.9 5.6-3.6 7.2-11 3.6-16.6l-84-130.4 85.3-132.5a12.04 12.04 0 0 0-10.1-18.5h-35.7c-4.2 0-8.1 2.2-10.3 5.8l-61.2 102.3z',

            onclick: function () {
              graph.downLoadExcel();
            }
          },
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data:
          json &&
          Array.isArray(json) &&
          json.map((data) => {
            return data.DateTime;
          })
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: json && json[0] ? json[0].Name : '',
          type: GraphType,
          data:
            json &&
            Array.isArray(json) &&
            json.map((data) => {
              return data.Value;
            })
        }
      ]
    };
    this.setState({
      option
    });
  };
  onIntervalChange = async (Interval) => {
    let { payload } = this.state;
    if (payload && payload.GraphType !== 'Table') {
      payload = {
        ...payload,
        Interval
      };
      this.generateData(payload);
      this.setState({ payload, SelectedInterval: Interval });
    } else {
      payload = {
        ...payload,
        Interval
      };
      let json = await getGraphData(payload);
      this.setState({ json: json, SelectedInterval: Interval, payload });
    }
    // let { payload } = this.state;
    // payload = {
    //   ...payload,
    //   Interval
    // };
    // this.setState({ payload, SelectedInterval: Interval });
    // this.generateData(payload);
  };
  onCheckboxChange = async (Operator) => {
    let { payload } = this.state;
    if (payload && payload.GraphType !== 'Table') {
      payload = {
        ...payload,
        Operator
      };
      this.generateData(payload);
      this.setState({ payload, selectedOperator: Operator });
    } else {
      payload = {
        ...payload,
        Operator
      };
      let json = await getGraphData(payload);
      this.setState({ payload, json, selectedOperator: Operator });
    }
  };
  radioOnChange = async (e) => {
    let GraphType = e.target.value;
    let { payload } = this.state;
    if (GraphType !== 'Table') {
      payload = {
        ...payload,
        GraphType
      };
      this.generateData(payload);
      this.setState({ payload, GraphType });
    } else {
      payload = {
        ...payload,
        GraphType
      };
      let json = await getGraphData(payload);
      this.setState({ json: json, GraphType, payload });
    }
  };
  onChangeFromDate_ToDate1 = (selectedFromDate_ToDate1) => {
    let { payload } = this.state;
    payload = {
      ...payload,
      FromDate: moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'), //conversion to utc
      ToDate: moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    // FromDate: moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'), //conversion to utc
    // ToDate: moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss')
    this.setState({
      selectedFromDate_ToDate1
    });
    this.generateData(payload);
    this.setState({ payload });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  disabledDate = (current) => {
    return current > moment().startOf('day').add(1, 'day');
  };
  render() {
    let { selectedOperator, GraphType, OperatorList, frequencyList, payload, json, option } =
      this.state;
    return (
      <>
        <div style={{ display: 'flex' }}>
          {/* <Form.Item
            name={'selectedFromDate_ToDate1'}
            label={this.translation(constant.Select_Range)}
            rules={[
              {
                required: false,
                message: this.translation('Please Select DateRanges!')
              }
            ]}
          > */}

          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            style={{ width: '330px' }}
            placeholder={[this.translation('Start DateTime'), this.translation('End DateTime')]}
            value={[moment.utc(payload.FromDate).local(), moment.utc(payload.ToDate).local()]}
            onChange={this.onChangeFromDate_ToDate1}
            onOk={this.onOk}
            disabledDate={this.disabledDate}
          />
          {/* </Form.Item> */}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px' }}>
          <Radio.Group onChange={(e) => this.radioOnChange(e)} value={GraphType}>
            <Radio.Button value={'line'}>Line</Radio.Button>
            <Radio.Button value={'bar'}>Bar</Radio.Button>
            <Radio.Button value={'Area'}>Area</Radio.Button>
            <Radio.Button value={'Table'}>Table</Radio.Button>
          </Radio.Group>
          {payload && payload.Type === 'historic' ? (
            <>
              <Select
                showSearch
                style={{ width: 100 }}
                value={payload.Interval}
                defaultValue={payload.Interval}
                placeholder="Select Interval"
                onChange={this.onIntervalChange}
                options={frequencyList}
              />
              <Select
                showSearch
                style={{ width: 100 }}
                value={selectedOperator}
                defaultValue={selectedOperator}
                placeholder="Select Operator"
                onChange={this.onCheckboxChange}
                // options={OperatorList}
              >
                {OperatorList &&
                  Array.isArray(OperatorList) &&
                  OperatorList.map((Operator, index) => {
                    return (
                      <Option key={index} value={Operator.Key}>
                        {Operator.Value}
                      </Option>
                    );
                  })}
              </Select>
            </>
          ) : (
            ''
          )}
        </div>
        {GraphType === 'Table' ? (
          <Table
            panelData={{
              graph: 'trends',
              trendData: json,
              colors: color,
              update: this.state.update,
              GraphType
            }}
            // index={index}
            currentTimeZone={this.props.currentTimeZone}
            className="trendTable"
            dataSource={this.state.json}
          />
        ) : (
          <ReactEcharts
            option={option}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: '90%', width: '100%' }}
            className="react_for_echarts"
            theme="dark"
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentTimeZone: getCurrentTimezone(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(GraphView);
