import React, { Component } from 'react';
import { Form, Select, Button, Table } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../../../../selectors/language';

class ChannelParameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translationCache: props.translationCache || []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;

    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    };
    const buttonItemLayout = {
      wrapperCol: {
        span: 14,
        offset: 4
      }
    };

    const columns = [
      {
        title: '',
        dataIndex: 'SampleName',
        key: 'SampleName'
      },
      {
        title: this.translation('CH-01'),
        dataIndex: 'CH-01',
        key: 'CH-01'
      },
      {
        title: this.translation('CH-02'),
        dataIndex: 'CH-02',
        key: 'CH-02'
      },
      {
        title: this.translation('CH-03'),
        dataIndex: 'CH-03',
        key: 'CH-03'
      },
      {
        title: this.translation('CH-04'),
        dataIndex: 'CH-04',
        key: 'CH-04'
      },
      {
        title: this.translation('CH-05'),
        dataIndex: 'CH-05',
        key: 'CH-05'
      },
      {
        title: this.translation('CH-06'),
        dataIndex: 'CH-06',
        key: 'CH-06'
      },
      {
        title: this.translation('CH-07'),
        dataIndex: 'CH-07',
        key: 'CH-07'
      },
      {
        title: this.translation('CH-08'),
        dataIndex: 'CH-08',
        key: 'CH-08'
      },
      {
        title: this.translation('CH-09'),
        dataIndex: 'CH-09',
        key: 'CH-09'
      },
      {
        title: this.translation('CH-10'),
        dataIndex: 'CH-10',
        key: 'CH-10'
      }
    ];
    const dataSource = [
      {
        key: '1',
        SampleName: this.translation('Channel Name'),
        age: 32,
        address: this.translation('10 Downing Street')
      },
      {
        key: '2',
        SampleName: this.translation('Digits'),
        age: 42,
        address: this.translation('10 Downing Street')
      }
    ];

    return (
      <div>
        <Form
          {...formItemLayout}
          layout="horizontal"
          name={this.translation('HeaderNumber_current')}
          style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
        >
          <Form.Item label={this.translation('HD')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="01">01</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <div>
              <Button type="primary" style={{ margin: '10px' }}>
                {this.translation('Ready By KB-30')}
              </Button>
              <Button type="primary" style={{ margin: '10px' }}>
                {this.translation('Write By KB-30')}
              </Button>
            </div>
          </Form.Item>

          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            // scroll={{ x: 2000, y: 500 }}
            // bordered
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(ChannelParameter);
