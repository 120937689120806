import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import rubusSLogo from '../../../../images/rubus_new/favicon.jpeg';

class PageWrapperComponenta extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { location } = this.props.history;
    let menuName = '';
    const pathList = location.pathname.replace('rubus/', '');
    const pathSnippets = pathList.split('/').filter((i) => i);

    const pathSnippetsFormatted = pathSnippets.map((snippet) => {
      menuName = snippet;
      snippet = _.startCase(snippet);
      snippet.replace(/-/g, ' ');
      return (
        <Breadcrumb.Item key={{ snippet }}>
          <span>{snippet}</span>
        </Breadcrumb.Item>
      );
    });

    const breadcrumbItems = [
      <Breadcrumb.Item key="home">
        <Link to="/rubus/LandingPage">
          {' '}
          <img
            src={rubusSLogo}
            alt="logo"
            height="12"
            style={{ marginTop: '-3px', height: '12px' }}
          />
          <span style={{ marginLeft: '3px' }}>Rubus</span>
        </Link>
      </Breadcrumb.Item>
    ].concat(pathSnippetsFormatted);

    return (
      <Fragment>
        {this.props.sideMenu ? (
          <div
            style={{
              marginTop: this.props.selectedMenu.length === 3 ? '125px' : '76px',
              background: 'white',
              fontWeight: '800px',
              fontSize: '1em'
            }}
          >
            <Breadcrumb style={{ padding: '5px', color: 'black', marginLeft: '48px' }}>
              {breadcrumbItems}
            </Breadcrumb>
            <div style={{ marginLeft: 50 }}>
              <h1>{_.startCase(menuName)}</h1>
            </div>
          </div>
        ) : (
          <div
            style={{
              marginTop: '73px',
              background: 'white',
              fontWeight: '800px',
              fontSize: '1em'
            }}
          >
            <Breadcrumb style={{ padding: '5px', color: 'black', marginLeft: '48px' }}>
              {breadcrumbItems}
            </Breadcrumb>
            <div style={{ marginLeft: 50 }}>
              <h1>{_.startCase(menuName)}</h1>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default PageWrapperComponenta;
