import { createSelector } from 'reselect';
import hierarchy from '../pages/Configurations/AssetDeviceList/hierarchy.json';
import _ from 'lodash';

const assetCache = (state) => {
  return state.get('asset').get('asset');
};

let childObj = [];
const getAllBreadcrumbList = () =>
  createSelector(assetCache, (state) => {
    childObj = [];
    const assetCache = state.get('assetCache');
    const assetParent = state.get('assetId');

    if (assetParent !== null && assetParent !== undefined) {
      if (assetCache[assetParent] !== undefined) {
        if (assetCache[assetParent].parent !== null) {
          childObj.push(assetCache[assetParent]);
          getParentChild(assetCache, assetCache[assetParent].parent);
        } else {
          childObj.push(assetCache[assetParent]);
        }
      }
    }

    childObj = _.sortBy(childObj, '_key');
    return childObj;
  });

const getParentChild = (assetCache, assetParent) => {
  if (assetCache[assetParent].parent !== null) {
    childObj.push(assetCache[assetParent]);
    return getParentChild(assetCache, assetCache[assetParent].parent);
  } else {
    childObj.push(assetCache[assetParent]);
  }
};

function getDeviceObject(type, deviceCache, assetName, assetId) {
  if (type === 'array') {
    const devices = hierarchy.device.parent.forEach((asset) => {
      // if device parents is array
      if (assetId !== null) {
        if (asset === hierarchy.asset[assetName]) {
          const filtered = [];

          Object.keys(deviceCache).forEach(function (key) {
            if (deviceCache[key] !== undefined) {
              if (deviceCache[key].asset === assetId) {
                filtered.push(deviceCache[key]);
              }
            }
          }, {});
          return filtered;
        }
      } else {
        return null;
      }
    });

    return devices[0];
  } else {
    const filtered = [];

    Object.keys(deviceCache).forEach((key) => {
      if (deviceCache[key] !== undefined) {
        const deviceCacheObject = deviceCache[key];
        if (deviceCacheObject.asset === assetId) {
          filtered.push(deviceCache[key]);
        }
      }
    }, {});
    return filtered;
  }
}

const getAllAssetList = () =>
  createSelector(assetCache, (state) => {
    const assetCache = state.get('assetCache');
    const assetId = state.get('assetId');
    let asset = state.get('asset');
    const siteDirection = state.get('siteDirection');
    let assetNameList = state.get('assetNameList');
    const checkObject = state.get('checkObject');
    const deviceCache = state.get('deviceCache');

    const mainList = [];
    var filteredDevices = [];
    let assetParent = '';
    let goBackSite = '';

    // asset
    if (assetNameList === null) {
      let assetName = '';
      const filtered = [];
      goBackSite = 'first';

      Object.keys(assetCache).forEach((key) => {
        if (assetCache[key] !== undefined) {
          if (assetCache[key].parent === null) {
            checkObject.push(assetCache[key].assetType);
            filtered.push(assetCache[key]);
            assetNameList = assetCache[key].assetType;
            assetName = assetCache[key].assetType;
          }
        }
      }, {});
      mainList.push({
        name: assetName,
        datasource: filtered
      });

      if (hierarchy) {
        // added devices
        if (typeof hierarchy.device.parent === 'string') {
          // if device parent is string
          filteredDevices = [];
          if (hierarchy.device.parent === assetName) {
            if (assetId !== null) {
              filteredDevices = getDeviceObject('string', deviceCache, assetName, assetId);
              mainList.push({
                name: 'device',
                datasource: filteredDevices
              });
            }
          }
        } else {
          // if device parent is array
          if (assetId !== null) {
            filteredDevices = getDeviceObject('array', deviceCache, assetName, assetId);
            if (filteredDevices !== null) {
              mainList.push({
                name: 'device',
                datasource: filteredDevices
              });
            }
          }
        }
      }
    } else {
      if (siteDirection === 'forward') {
        if (asset.parent !== null) {
          goBackSite = assetCache[asset.parent];
        } else {
          goBackSite = null;
        }
        assetNameList.forEach((assetName) => {
          const filtered = [];
          const assetCacheList = Object.keys(assetCache);
          assetCacheList.forEach((obj) => {
            if (assetCache[obj] !== undefined) {
              if (assetNameList !== undefined) {
                if (assetCache[obj].assetType === assetName && assetCache[obj].parent === assetId) {
                  assetParent = asset._key;
                  filtered.push(assetCache[obj]);
                }
              }
            }
          }, {});

          if (filtered.length <= 0 && asset.parent !== null) {
            assetParent = asset._key;
          } else if (asset.parent === null) {
            assetParent = asset._key;
          }
          mainList.push({
            name: assetName,
            datasource: filtered
          });
        });

        if (hierarchy) {
          filteredDevices = [];
          if (typeof hierarchy.device.parent === 'string') {
            if (hierarchy.device.parent === asset._key) {
              filteredDevices = getDeviceObject('string', deviceCache, asset._key, assetId);
              mainList.push({
                name: 'device',
                datasource: filteredDevices
              });
            }
          } else {
            filteredDevices = getDeviceObject('arraay', deviceCache, asset._key, assetId);
            mainList.push({
              name: 'device',
              datasource: filteredDevices
            });
          }
        }
      } else if (siteDirection === 'backward') {
        const list = [];
        const hyAssetKey = Object.keys(assetCache);
        hyAssetKey.forEach((keyValue) => {
          if (assetCache[keyValue].parent === asset.parent) {
            list.push(assetCache[keyValue].assetType);
          }
        });

        const uniqueArray = list.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });

        let asset1 = '';

        uniqueArray.forEach((assetName) => {
          const filtered = [];
          hyAssetKey.forEach((obj) => {
            if (assetCache[obj] !== undefined) {
              if (uniqueArray !== undefined) {
                if (
                  assetCache[obj].parent === asset.parent &&
                  assetCache[obj].assetType === assetName
                ) {
                  assetParent = asset.parent;
                  asset1 = assetCache[asset.parent];
                  if (asset1) {
                    if (asset1.parent === null) {
                      goBackSite = null;
                    } else if (asset1.parent) {
                      goBackSite = assetCache[asset1.parent];
                    } else {
                      goBackSite = 'first';
                    }
                    filtered.push(assetCache[obj]);
                  } else {
                    goBackSite = 'first';
                  }
                }
              }
            }
          }, {});
          mainList.push({
            name: assetName,
            datasource: filtered
          });
        });
        assetNameList = list;
        asset = asset1;
      }
    }

    return {
      assetList: mainList,
      assetParent: assetParent,
      goBackSite: goBackSite,
      asset: asset,
      checkObject: checkObject,
      assetChild: assetNameList
    };
  });

const getAssetList = () => {
  return createSelector(assetCache, (state) => {
    return state.get('assetCache');
  });
};

const getCurrentAsset = () => {
  return createSelector(assetCache, (state) => {
    return state.get('currentAsset');
  });
};

const getDeviceCache = () => {
  return createSelector(assetCache, (state) => {
    return state.get('deviceCache');
  });
};

const getAllNewBreadcrumbList = () => {
  return createSelector(assetCache, (state) => {
    return state.get('breadcrumbList');
  });
};

export {
  getAllAssetList,
  getAssetList,
  getAllBreadcrumbList,
  getDeviceCache,
  getCurrentAsset,
  getAllNewBreadcrumbList
};
