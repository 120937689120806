import React from 'react';
import axios from 'axios';
import { Form, message, Button, Select } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getTranslationCache } from '../.././../selectors/language';
import localStorage from '../../../utils/localStorage';
import { API_AUTH, API_SITE, GETUSERLIST } from '../../../commons/api';
const { Option } = Select;

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      siteList: [],
      userList: [],
      translationCache: props.translationCache || []
    };
  }
  editProfile = React.createRef();

  componentDidMount = () => {
    this.getSitesList();
  };
  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  onFinish = (data) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const changePassword = {
      method: 'POST',
      url: `${API_AUTH.UpdateTokenStatus}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    axios(changePassword)
      .then((response) => {
        message.success(response.data.message);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          message.error(error.response.data.message);
        }
      });
  };

  getSitesList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'GET',
      url: `${API_SITE.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(Object)
      .then((response) => {
        this.setState({
          siteList: response.data
        });
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 500) {
          message.info(err.response.data.message);
        }
      });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onFocus = (event) => {
    event.preventDefault();
    if (event.target.autocomplete) {
      event.target.autocomplete = 'whatever';
    }
  };
  onSiteIdChange = (SiteId) => {
    const Site = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${GETUSERLIST.UserList}?SiteId=${Site}&Site=${SiteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        SiteId
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ userList: response.data, SiteId });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    let { siteList, userList } = this.state;
    const onFinish = (values) => {
      this.onFinish(values);
    };

    const onFinishFailed = () => {};

    return (
      <div>
        <Form
          ref={this.editProfile}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={this.translation('Select Site')}
            name="SiteId"
            rules={[{ required: true, message: 'Select Site' }]}
          >
            <Select style={{ width: '300px' }} onChange={(e) => this.onSiteIdChange(e)}>
              {siteList &&
                Array.isArray(siteList) &&
                siteList.map((site) => {
                  return (
                    <Option key={site.Id} value={site.Id}>
                      {site.Name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            label={this.translation('Select Users')}
            name="UserIds"
            rules={[{ required: true, message: 'Select Users' }]}
          >
            <Select mode="multiple" style={{ width: '300px' }}>
              {userList &&
                Array.isArray(userList) &&
                userList.map((user) => {
                  return (
                    <Option key={user.Id} value={user.Id}>
                      {user.FirstName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label={this.translation('Select Status')}
            name="Status"
            rules={[{ required: true, message: 'Select Status' }]}
          >
            <Select style={{ width: '300px' }}>
              <Option value={true}>true</Option>
              <Option value={false}>false</Option>
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              {this.translation('Submit')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(ChangePassword);
