import React from 'react';
import moment from 'moment';
import { Popconfirm, Tooltip, message } from 'antd';
import { createStructuredSelector } from 'reselect';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { getTranslationCache } from '../../selectors/language';
import { getCurrentTimezone, getUserProfile } from '../../selectors/layout';
import { Button, ChatListContainer, ChatRow, SidebarContainer } from './CSS/styles';
import { createConversation, getChatList, deleteConversationId } from './Utils/ApICalls';
// import {constant } from '../Settings/Constants';
import { constant } from './constants';
import translation from '../AMM/Utils/translation';
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chatList: [],
      collapsed: true,
      translationCache: props.translationCache || {}
    };
  }

  componentDidMount() {
    this.FetchChatList();
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (prevprops.selectedChatId !== this.props.selectedChatId) {
      // this.FetchChatList();
    }
  }

  FetchChatList = async () => {
    let chatList = await getChatList();
    this.setState({ chatList });
  };

  onChatSelected = (chatId) => {
    this.props.onChatSelected(chatId);
  };

  onCreateChat = async () => {
    let saveResponse = await createConversation();
    if (saveResponse && saveResponse.Id) {
      this.FetchChatList();
      this.props.onChatSelected(saveResponse.Id);
    } else {
      message.error('');
    }
  };

  onDeleteChat = async (e, chatId) => {
    e.preventDefault();
    let deleteObject = await deleteConversationId(chatId);
    if (deleteObject && deleteObject.message) {
      message.success(deleteObject.message);
      this.FetchChatList();
    }
  };

  render() {
    let { chatList, collapsed } = this.state;
    let { selectedChatId } = this.props;
    return (
      <>
        <SidebarContainer collapseWidth={collapsed && collapsed === true ? '5%' : '15%'}>
          {collapsed === false ? (
            <ChatListContainer>
              <Button onClick={() => this.onCreateChat()}>
                + {translation(this.props.translationCache, constant.New_Chat)}
              </Button>
              {chatList &&
                Array.isArray(chatList) &&
                chatList.map((chat) => (
                  <ChatRow
                    key={chat.Id}
                    onClick={() => this.onChatSelected(chat.Id)}
                    isSelected={chat.Id === selectedChatId}
                  >
                    <span>
                      {chat.CreatedTime
                        ? moment(chat.CreatedTime)
                            .tz(this.props.currentTimeZone)
                            .format('YYYY-MM-DD HH:mm:ss')
                        : ''}
                    </span>
                    <Popconfirm
                      placement="topLeft"
                      title={'Are you sure to delete this conversation?'}
                      onConfirm={(e) => this.onDeleteChat(e, chat.Id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Popconfirm>
                  </ChatRow>
                ))}
            </ChatListContainer>
          ) : null}
          <div
            className="antdcollapsed-button"
            onClick={() => this.setState({ collapsed: !collapsed })}
          >
            {collapsed && collapsed === true ? (
              <Tooltip
                placement="top"
                title={translation(this.props.translationCache, constant.Open)}
              >
                <RightOutlined />
              </Tooltip>
            ) : (
              <Tooltip
                placement="top"
                title={translation(this.props.translationCache, constant.Close)}
              >
                <LeftOutlined />
              </Tooltip>
            )}
          </div>
        </SidebarContainer>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(Sidebar);
