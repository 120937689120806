// import echarts from 'echarts';
import * as echarts from 'echarts';
import EchartsReactCore from './core';

import vintage from './Theme/vintage.json';
import dark from './Theme/dark.json';
import shine from './Theme/shine.json';

// export the Component the echarts Object.
export default class EchartsReact extends EchartsReactCore {
  constructor(props) {
    super(props);
    echarts.registerTheme('vintage', vintage);
    echarts.registerTheme('dark', dark);
    echarts.registerTheme('shine', shine);
    this.echartsLib = echarts;
  }
}
