import React, { Component } from 'react';
import { Tree, Checkbox } from 'antd';
import { DownOutlined } from '@ant-design/icons';
const { TreeNode } = Tree;
class HierarchyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      value: [],
      autoExpandParent: true.valueOf,
      expandedKeys: [],
      datasource: [],
      checkedArray: this.props && this.props.checkedArray ? this.props.checkedArray : [],
      checkedKeys: [],
      treeData: this.props && this.props.treeData ? this.props.treeData : []
    };
  }
  componentDidMount() {
    let checkedArray = this.props.checkedArray;
    this.setState({ checkedArray });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.checkedArray !== this.props.checkedArray ||
      prevProps.treeData !== this.props.treeData
    ) {
      this.setState({
        checkedArray: this.props.checkedArray,
        treeData: this.props.treeData
      });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] && translationCache[keyword]
      ? translationCache[keyword]
      : keyword;
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  onCheck = (checkedKeys, info) => {
    this.setState({ checkedKeys });
    this.props.handleDropdownChange(checkedKeys, info.checkedNodes);
  };

  checkboxOnChange = (e) => {
    let { checkedArray } = this.state;
    if (e.target.checked === true) {
      checkedArray.push(e.target.value);
      this.setState({ checkedArray });
      this.props.handleDropdownChange(checkedArray);
    } else if (e.target.checked === false) {
      let data = checkedArray.filter((val) => val !== e.target.value);
      this.setState({ checkedArray: data });
      this.props.handleDropdownChange(data);
    }
  };

  renderTreeNodes = (data) => {
    let { checkedArray } = this.props;
    let { Key } = this.props;
    return (
      data &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <TreeNode
              title={
                <div>
                  {` ${item.key ? `${this.translation(item.key)} ` : ''} ${
                    item.title ? `(${this.translation(item.title)})` : ''
                  }`}
                </div>
              }
              key={item.key}
              dataRef={item}
              onChange={this.onTreeChange}
            >
              {this.renderTreeNodes(item.children, item)}
            </TreeNode>
          );
          // })
        }
        if (Key === 'Interval') {
          return (
            <TreeNode
              key={item.key}
              title={
                <div>
                  {item.children && item.children.length === 0 ? (
                    <>
                      <Checkbox
                        key={item.key}
                        // onClick={() => {
                        //   this.getTextOnChange(item);
                        // }}
                        onChange={(e) => this.checkboxOnChange(e)}
                        checked={
                          checkedArray &&
                          Array.isArray(checkedArray) &&
                          checkedArray.filter((item1) => item1 === item.AliasCode) &&
                          checkedArray.filter((item1) => item1 === item.AliasCode)[0]
                            ? true
                            : false
                        }
                        value={item.AliasCode}
                      />
                      {` ${item.AliasCode ? `${this.translation(item.AliasCode)} ` : ''} ${
                        item.Name ? `(${this.translation(item.Name)})` : ''
                      }`}
                    </>
                  ) : (
                    <>
                      {` ${item.AliasCode ? `${this.translation(item.AliasCode)} ` : ''} ${
                        item.Name ? `(${this.translation(item.Name)})` : ''
                      }`}
                    </>
                  )}
                </div>
              }
              dataRef={item}
            />
          );
        }
      })
    );
  };

  render() {
    let { treeData, checkedKeys } = this.state;
    let { Key, checkedArray } = this.props;
    return (
      <div
        style={{
          width: '100%',
          flex: '35%',
          overflowY: 'auto',
          height: window.innerHeight - 250
        }}
      >
        {Key === 'Interval' ? (
          <Tree
            style={{ color: 'white', backgroundColor: '#1a3652' }}
            showLine
            checkedKeys={checkedArray}
            defaultExpandAll={true}
            switcherIcon={<DownOutlined />}
          >
            {this.renderTreeNodes(treeData)}
          </Tree>
        ) : (
          <Tree
            checkable
            style={{ color: 'white', backgroundColor: '#1a3652' }}
            showLine
            // defaultExpandAll={true}
            switcherIcon={<DownOutlined />}
            onCheck={this.onCheck}
            checkedKeys={checkedKeys}
            treeData={treeData}
          ></Tree>
        )}
      </div>
    );
  }
}

export default HierarchyModal;
