import React, { Component } from 'react';
import { Table } from 'antd';

import '../CSS/eLog.css';
import styles from '../CSS/style.less';
import { lightTheme } from '../CSS/style.js';

class TableViewExpandView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: props.form || {},
      columns: props.form && props.form.columns ? props.form.columns : [],
      data:
        props.form && props.form.datasource
          ? this.props.form.datasource.map((dataSource, index) => {
              return {
                ...dataSource,
                key: index + 1
              };
            })
          : [],
      mainHeaderValue: props.mainHeaderValue ? props.mainHeaderValue : {},
      subHeaderValue: props.subHeaderValue ? props.subHeaderValue : {},
      selectedRowKeys: [],
      selectedRowKeys1: []
    };
  }

  componentDidMount() {
    this.setState({
      form: this.props.form,
      columns: this.props.form && this.props.form.columns ? this.props.form.columns : [],
      data: this.props.form && this.props.form.datasource ? this.props.form.datasource : []
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.form !== this.props.form) {
      if (this.props.form && this.props.form.problem && this.props.form.cause) {
        let selectedRowKeys =
          this.props.form && this.props.form.problem ? this.props.form.problem : [];
        let selectedRowKeys1 =
          this.props.form && this.props.form.cause ? this.props.form.cause : [];
        this.setState({
          selectedRowKeys,
          selectedRowKeys1
        });
      }
      this.setState({
        form: this.props.form,
        columns: this.props.form && this.props.form.columns ? this.props.form.columns : [],
        data: this.props.form && this.props.form.datasource ? this.props.form.datasource : []
      });
    }
  }
  onSelectChange = (selectedRowKeys) => {
    this.props.saveTableViewDataCustom(selectedRowKeys[0], 'problem');
    this.setState({ selectedRowKeys: [selectedRowKeys[0]] });
  };
  onSelectChange1 = (selectedRowKeys1) => {
    this.props.saveTableViewDataCustom(selectedRowKeys1[0], 'cause');
    this.setState({ selectedRowKeys1: [selectedRowKeys1[0]] });
  };
  render() {
    let { loading } = this.props;
    let { columns, data, selectedRowKeys, selectedRowKeys1 } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };
    const rowSelection1 = {
      selectedRowKeys1,
      onChange: this.onSelectChange1
    };
    return (
      <div className="tableView" style={{ marginTop: '80px' }}>
        <div>
          <Table
            theme={lightTheme}
            key="mainTable"
            className="TableView"
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={false}
            style={{ marginTop: '10px', border: '1px solid  red !important' }}
            rowClassName={(record) => (record.editable ? styles.editable : '')}
            rowSelection={rowSelection}
            expandedRowRender={(record) => (
              <p style={{ margin: 0, backgroundColor: '#b4cbdd' }}>
                <Table
                  key="childTable"
                  rowSelection={rowSelection1}
                  columns={columns}
                  dataSource={record.child}
                  bordered
                />
              </p>
            )}
          />
        </div>
      </div>
    );
  }
}

export default TableViewExpandView;
