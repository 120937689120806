export const Table_AMM = {
  CMTypes: 'amm."SM_CMTypes"',
  WarningTypes: 'amm."SM_WarningTypes"',
  WorkTypes: 'amm."SM_WorkTypes"',
  WorkFlows: 'amm."SM_WorkFlows"',
  MasterWorkOrderStatus: 'eam."Master_WorkOrder_Status"'
};

export const Table_Master = {
  MasterChannels: 'master."Master_Channels"',
  MasterRecurrence: 'master."Master_Recurrence"',
  MasterFormTypes: 'master."Master_FormTypes"',
  MasterBookTypes: 'master."Master_BookTypes"',
  MasterLogBookTypes: 'master."Master_LogBookTypes"'
};

export const Table_SiteMaster = {
  NotificationTypes: 'sitemaster."SM_NotificationTypes"',
  SMStatus: 'sitemaster."SM_Status"',
  Frequency: 'sitemaster."SM_Frequency"'
};

export const Table_Maximo = {
  MasterKeyAliasNames: 'ams."Master_KeyAliasNames"'
};

export const Table_AppConfig = {
  SiteMasterParametersList: 'appconfig."Site_Master_ParametersList"',
  SiteMasterCronJobs: 'sitemaster."SM_CronJobs"'
};
export const Table_Base = {
  BaseRoles: 'base."Base_Roles"'
};

export const Table_Analytics = {
  SiteMasterTaskStatus: 'analytics."Site_Master_TaskStatus"'
};
