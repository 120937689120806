import React, { Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tag } from 'antd';
import moment from 'moment';
import ReactEcharts from '../../Echarts/Echarts';
import { trendLabelTranslation } from '../../../Utils/Adapter/translationAdapter';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { getCurrentTimezone } from '../../../../../selectors/layout';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { arrayOfObjects } from '../../../Mock/MockJson';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';

class Donut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData();

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData();
      }
    }, refreshTime);
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }
  onChartClick = async (params) => {
    var activeLegends = params.selected;
    this.getData(activeLegends);
  };
  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    let onEvents = {
      legendselectchanged: (cellData) => this.onChartClick(cellData)
    };
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            option={this.state.option}
            style={{ height: '90%', width: '100%' }}
            className="react_for_echarts"
            onEvents={onEvents}
          />
        )}
      </Fragment>
    );
  }

  componentWillUnmount() {
    this.abortController.abort();
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
  }

  getData = async (activeLegends) => {
    this.requestCheck = false;
    // let graphData = [];
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = arrayOfObjects;
      this.requestCheck = true;
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection({
          graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          selectionDateLive: this.props.selectionDateLive,
          currentTimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      }
    }

    // json = [
    //   {
    //     interval_alias: "2020-04-08 15:31:33",
    //     tagname: "Bearing In Board",
    //     tagvalue: 81,
    //   },
    //   {
    //     interval_alias: "2020-04-08 15:31:33",
    //     tagname: "Bearing Out Board",
    //     tagvalue: 43,
    //   },
    // ];
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      let series = [];
      let total = 0;
      // let uom =
      // graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph].uom
      //   ? graphprops[graphprops.graph].uom
      //   : '';
      if (json && Array.isArray(json)) {
        let activeKeys = [];
        if (activeLegends) {
          activeKeys = Object.keys(activeLegends).filter((active) => {
            return activeLegends[active] === false;
          });
        }
        series = json.map((val) => {
          if (activeKeys && val.Description && activeKeys.includes(val.Description)) {
            if (val.Value !== null) total = parseInt(total) + 0;
          } else {
            if (val.Value !== null) total = parseInt(total) + parseInt(val.Value);
          }
          return { value: val.Value, name: val.Description };
        });
      }

      let title = {
        text: `Total: ${total}`,
        left: 'center',
        top: 'center',
        textStyle: {
          color: 'white'
        }
      };
      const option = cloneDeep(this.state.option);
      if (
        this.props.graphprops.donut &&
        this.props.graphprops.donut.colors &&
        this.props.graphprops.donut.colors !== null &&
        this.props.graphprops.donut.colors !== undefined &&
        this.props.graphprops.donut.colors.length !== 0
      ) {
        option.color = [...this.props.graphprops.donut.colors];
      }
      series = await trendLabelTranslation(series, this.props.translationCache);
      let ShowTotal =
        graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph].ShowTotal
          ? graphprops[graphprops.graph].ShowTotal
          : '';
      if (ShowTotal === 'Yes') {
        option.title = title;
      }
      option.legend = {
        ...option.legend,
        selected: activeLegends
      };
      option.series[0].data = [...series];
      this.setState({
        option,
        refreshDateTime: moment()
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} '
      },
      legend: {
        textStyle: {
          color: theme === 'lightTheme' ? 'black' : 'white',
          fontSize: 18,
          fontWeight: 'bold'
        }
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            backgroundColor: 'black',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      series: [
        {
          name: this.props.graphprops.title,
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: true,
          label: {
            // formatter: ' {b|{b}：}{c}  {per|{d}%}  ',
            formatter: ' {b|{c}}  {per|{d}%}  ',

            color: theme === 'lightTheme' ? 'black' : 'white',
            backgroundColor: '#eee',
            borderColor: '#aaa',
            borderWidth: 1,
            borderRadius: 4,
            // shadowBlur:3,
            // shadowOffsetX: 2,
            //     shadowOffsetY: 2,
            //     shadowColor: '#999',
            padding: [0, 4],
            rich: {
              b: {
                fontSize: 14,
                fontWeight: 'bold',
                color: theme === 'lightTheme' ? 'black' : 'white',
                lineHeight: 22,
                backgroundColor: '#c23531',
                padding: [3, 5],
                borderRadius: 2
              },
              per: {
                fontSize: 14,
                fontWeight: 'bold',
                color: theme === 'lightTheme' ? 'black' : 'white',
                backgroundColor: '#52c41a',
                padding: [3, 5],
                borderRadius: 2
              }
            }
          },
          data: []
        }
      ]
    };

    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(Donut);
