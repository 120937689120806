import React, { useState, useEffect, useCallback } from 'react';
import { DownloadOutlined } from '@ant-design/icons';

import { Row, Col, Card, Table, List, Avatar, Divider } from 'antd';
import ImageViewer from 'react-simple-image-viewer';
import { UPLOAD } from '../../../commons/api';
// import 'antd/dist/antd.css'

const EquipmentDetails = (props) => {
  const columns = [
    {
      title: 'Parameter',
      dataIndex: 'Name',
      key: 'Name'
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value'
    }
  ];

  return (
    <div>
      <div className="site-card-border-less-wrapper">
        <Row gutter={16}>
          <Col span={8}>
            <Card hoverable title="Specifications" bordered={false}>
              <Table columns={columns} dataSource={props.data.Specifications} pagination={false} />
            </Card>
          </Col>
          <Col span={8}>
            <Card hoverable title="Equipment Attachments" bordered={false}>
              <List
                itemLayout="horizontal"
                dataSource={props.data.AttachmentsList}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar icon={<DownloadOutlined />} />}
                      title={<a href="https://ant.design">{item.AttachmentURL}</a>}
                      description={
                        <>
                          <p>Description:{item.Description} </p>{' '}
                          <p>Equipment Name :{item.Equipment}</p>
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
              <div></div>
            </Card>
          </Col>
          <Col span={8}>
            <Card hoverable title="Equipment Images" bordered={false}>
              {props.data.ImagesList.length > 0 ? (
                <ImageList imageList={props.data.ImagesList} />
              ) : null}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const ImageList = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imageArray, setImageArray] = useState([]);

  useEffect(() => {
    let data = [];
    props.imageList.map((src) => data.push(`${UPLOAD.Images}/${src.ImageURL}`));
    setImageArray(data);
  }, []);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div>
      {props.imageList &&
        Array.isArray(props.imageList) &&
        props.imageList.length > 0 &&
        props.imageList.map((src, index) => (
          <div key={index}>
            <p style={{ fontWeight: 'bold', color: 'grey' }}>Equipment Name : {src.Equipment}</p>
            <p style={{ fontWeight: 'bold', color: 'grey' }}>Description : {src.Description}</p>
            <img
              src={`${UPLOAD.Images}/${src.ImageURL}`}
              onClick={() => openImageViewer(index)}
              width="100%"
              height={'300'}
              key={index}
              style={{ margin: '2px' }}
              alt=""
            />
            <Divider />
          </div>
        ))}

      {isViewerOpen && (
        <ImageViewer
          src={imageArray}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </div>
  );
};
export { EquipmentDetails };
