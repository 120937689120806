import React from 'react';

export function tableColumnAdapter(reportJSON) {
  if (reportJSON && Object.entries(reportJSON).length !== 0 && !reportJSON.stack) {
    reportJSON.map((reportItem, key) => {
      let { JSON } = reportItem;
      if (JSON && JSON.columns) {
        let children = [];
        JSON.columns.map((columnsItem, key1) => {
          if (
            JSON.tableConfiguration &&
            JSON.tableConfiguration.style &&
            JSON.tableConfiguration.style.colSpan &&
            JSON.tableConfiguration.style.colSpan.length > 0
          ) {
            //colspan
            let styleObj = {};
            if (!JSON.tableConfiguration || !JSON.tableConfiguration.style) {
              styleObj = {
                textAlign: 'center',
                fontWeight: 'normal',
                evenRowColor: 'rgb(251, 250, 250)',
                oldRowColor: '#fff'
              };
            } else {
              styleObj = {
                textAlign: JSON.tableConfiguration.style.textAlign
                  ? JSON.tableConfiguration.style.textAlign
                  : 'center',
                fontWeight: JSON.tableConfiguration.style.fontWeight
                  ? JSON.tableConfiguration.style.fontWeight
                  : 'normal',
                evenRowColor: JSON.tableConfiguration.style.evenRowColor
                  ? JSON.tableConfiguration.style.evenRowColor
                  : 'rgb(251, 250, 250)',
                oldRowColor: JSON.tableConfiguration.style.oldRowColor
                  ? JSON.tableConfiguration.style.oldRowColor
                  : '#fff',
                colSpan: JSON.tableConfiguration.style.colSpan
                  ? JSON.tableConfiguration.style.colSpan
                  : []
              };
            }

            let colSpanObject = JSON.tableConfiguration.style.colSpan;
            colSpanObject.map((colSpan) => {
              if (
                columnsItem.key === colSpan.columnKey ||
                (key1 !== 0 ? JSON.columns[key1 - 1].key === colSpan.columnKey : false)
              ) {
                let column = getGenerateColSpanJSON(columnsItem, styleObj, colSpan);
                reportJSON[key].JSON.columns[key1] = column;
              } else {
                let column = getGenerateJSON(columnsItem, styleObj);
                reportJSON[key].JSON.columns[key1] = column;
              }
              return {};
            });
          } else {
            let styleObj = {};
            if (!JSON.tableConfiguration || !JSON.tableConfiguration.style) {
              styleObj = {
                textAlign: 'center',
                fontWeight: 'normal',
                evenRowColor: 'rgb(251, 250, 250)',
                oldRowColor: '#fff'
              };
            } else {
              styleObj = {
                textAlign: JSON.tableConfiguration.style.textAlign
                  ? JSON.tableConfiguration.style.textAlign
                  : 'center',
                fontWeight: JSON.tableConfiguration.style.fontWeight
                  ? JSON.tableConfiguration.style.fontWeight
                  : 'normal',
                evenRowColor: JSON.tableConfiguration.style.evenRowColor
                  ? JSON.tableConfiguration.style.evenRowColor
                  : 'rgb(251, 250, 250)',
                oldRowColor: JSON.tableConfiguration.style.oldRowColor
                  ? JSON.tableConfiguration.style.oldRowColor
                  : '#fff',
                colSpan: JSON.tableConfiguration.style.colSpan
                  ? JSON.tableConfiguration.style.colSpan
                  : []
              };
            }
            if (columnsItem.children) {
              children = [];
              children = columnsItem.children.map((child) => {
                return getGenerateJSON(child, JSON.tableConfiguration.style);
              });
              reportJSON[key].JSON.columns[key1].children = children;
            } else {
              let column = getGenerateJSON(columnsItem, styleObj);
              reportJSON[key].JSON.columns[key1] = column;
            }
          }
          return {};
        });
      }
      return {};
    });
  }
  return reportJSON;
}

function getGenerateJSON(columns, tableConfig) {
  let { textAlign, fontWeight, evenRowColor, oldRowColor } = tableConfig;
  return {
    ...columns,
    render(text, record, key) {
      return {
        props: {
          style: {
            background: columns.background
              ? columns.background
              : Math.abs(key % 2) === 1
              ? evenRowColor
              : oldRowColor
          }
        },
        children: <div style={{ textAlign, fontWeight }}>{text}</div>
      };
    }
  };
}

function getGenerateColSpanJSON(columns, tableConfig, colSpan) {
  let { textAlign, fontWeight, evenRowColor, oldRowColor } = tableConfig;
  let { columnKey, noOfSpan, rowToColSpan } = colSpan;

  if (columns.key !== columnKey) {
    return {
      //columns for next Column Names colspan
      ...columns,
      render: (text, row, index) => {
        const obj = {
          props: {
            style: {
              background: columns.background
                ? columns.background
                : Math.abs(index % 2) === 1
                ? evenRowColor
                : oldRowColor
            }
          },
          children: <div style={{ textAlign, fontWeight }}>{text}</div>
        };
        if (index === rowToColSpan) {
          obj.props.colSpan = 0;
        }
        return obj;
      }
    };
  } else {
    //Column for colspan
    return {
      ...columns,
      render: (text, row, index) => {
        if (index !== rowToColSpan) {
          return {
            props: {
              style: {
                background: columns.background
                  ? columns.background
                  : Math.abs(index % 2) === 1
                  ? evenRowColor
                  : oldRowColor
              }
            },
            children: <div style={{ textAlign, fontWeight }}>{text}</div>
          };
        }
        return {
          props: {
            style: {
              background: columns.background
                ? columns.background
                : Math.abs(index % 2) === 1
                ? evenRowColor
                : oldRowColor
            },
            colSpan: noOfSpan
          },
          children: <div style={{ textAlign, fontWeight }}>{text}</div>
        };
      }
    };
  }
}
