import moment from 'moment';

export function localTimeConverter(logItems) {
  return logItems.map((area) => {
    if (area.StartTime !== null) {
      area.StartTime = moment.utc(area.StartTime).local().format('YYYY-MM-DD HH:mm:ss');
    } else if (area.StartTime === null || area.StartTime === '' || area.StartTime === undefined) {
      area.StartTime = null;
    }

    area.EndTime =
      area.EndTime !== null ? moment.utc(area.EndTime).local().format('YYYY-MM-DD HH:mm:ss') : null;
    if (
      area &&
      area.FailureDetails &&
      Array.isArray(area.FailureDetails) &&
      area.FailureDetails.length !== 0
    ) {
      area.FailureDetails.forEach((area) => {
        if (area.EndTime !== null) {
          area.EndTime = moment(area.EndTime).utc().local().format('YYYY-MM-DD HH:mm');
        } else if (area.EndTime === null || area.EndTime === '' || area.EndTime === undefined) {
          area.EndTime = null;
        }
        if (area.StartTime !== null) {
          area.StartTime = moment(area.StartTime).utc().local().format('YYYY-MM-DD HH:mm');
        } else if (
          area.StartTime === null ||
          area.StartTime === '' ||
          area.StartTime === undefined
        ) {
          area.StartTime = null;
        }
      });
    }
    return area;
  });
}

export function totalHoursCalculationLogic(spliTimeRecords) {
  let durations = spliTimeRecords.map((data) => {
    return data.totalTime;
  });

  return durations.slice(1).reduce((startTime, endTime) => {
    var times = [0, 0, 0];
    var max = times.length;

    var a = (startTime || '').split(':');
    var b = (endTime || '').split(':');

    // normalize time values
    for (var i = 0; i < max; i++) {
      a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i]);
      b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i]);
    }

    // store time values
    for (i = 0; i < max; i++) {
      times[i] = a[i] + b[i];
    }

    var hours = times[0];
    var minutes = times[1];
    var seconds = times[2];

    if (seconds >= 60) {
      var m = (seconds / 60) << 0;
      minutes += m;
      seconds -= 60 * m;
    }

    if (minutes >= 60) {
      var h = (minutes / 60) << 0;
      hours += h;
      minutes -= 60 * h;
    }

    return (
      ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)
    );
  }, durations[0]);
}
