import { types } from './types';
import { message } from 'antd';
import { takeLatest, call, put } from 'redux-saga/effects';

import assetCache from './asset.requests';
import { jwtToken } from '../../commons/jwtTokenExpiry';
import { getAssetCacheSuccess, getDeviceCacheSuccess } from './asset.duck';

export function* assetCacheSaga() {
  const assetCacheSagaSuccess = yield call(assetCache.asset);
  if (assetCacheSagaSuccess.message === 'User is not permitted to this resource') {
    jwtToken(assetCacheSagaSuccess.message);
  } else {
    yield put(getAssetCacheSuccess(assetCacheSagaSuccess));
  }
  const deviceCacheSagaSuccess = yield call(assetCache.device);
  yield put(getDeviceCacheSuccess(deviceCacheSagaSuccess));
}

export function* createAssetSaga(asset) {
  const createAssetResp = yield call(assetCache.createAsset, asset.asset);
  if (createAssetResp.statusCode === 400) {
    message.error(createAssetResp.message);
  } else {
    message.success('New asset saved successfully');
  }
}

export function* updateAssetSaga(asset) {
  yield call(assetCache.updateAsset, asset.asset);
  message.success('Asset Updated successfully');
}

export function* deleteAssetSaga(asset) {
  yield call(assetCache.deleteAsset, asset.asset);
  message.success('Asset Delete successfully');
}

export default function* assetSaga() {
  yield [
    takeLatest(types.ASSET_CACHE, assetCacheSaga),
    takeLatest(types.CREATE_ASSET, createAssetSaga),
    takeLatest(types.UPDATE_ASSET, updateAssetSaga),
    takeLatest(types.DELETE_ASSET, deleteAssetSaga)
  ];
}
