import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Spin, Col, Row, Table, Tag } from 'antd';
import { createStructuredSelector } from 'reselect';

import ReactEcharts from '../../Echarts/Echarts';
import { getTranslationCache } from '../../../../../selectors/language';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
// import { tableTranslation } from '../../../Utils/Adapter/translationAdapter';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { heatmapCartesionTableMock } from '../../../Mock/heatmapMock';
import { getCurrentTimezone } from '../../../../../selectors/layout';
// import { ArrayOfObjectsWithAction } from '../../../Mock/MockJson';

class HeatmapCartesion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      loading: false,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      columns: [],
      dataSource: []
    };
  }
  columns = [];
  dataSource = [];

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = 1 * 60000;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  updateData = async (data) => {
    try {
      this.columns = [];
      let { graphprops } = this.props;
      let dataSource = [];
      let columnConfig =
        graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph].columns
          ? JSON.parse(graphprops[graphprops.graph].columns)
          : [];

      let columnsList = [];

      if (columnConfig && Array.isArray(columnConfig)) {
        columnsList = columnConfig.map((column) => {
          if (column.isUTC && column.isUTC === true) {
            data = data.map((dataa) => {
              let date = moment.utc(dataa[column.key]).toDate();
              return {
                ...dataa,
                [column.key]: moment(date).local().format('YYYY-MM-DD HH:mm:ss')
              };
            });
          }
          return {
            title: column.title,
            dataIndex: column.key,
            key: column.key,
            render: (text, record) => (
              <span style={{ color: record.Color }}>
                {column &&
                column.showColor &&
                column.showColor === true &&
                record[`${column.key}_Color`] ? (
                  <Tag color={record[`${column.key}_Color`]} key={column.key}>
                    {text}
                  </Tag>
                ) : (
                  text
                )}
              </span>
            )
          };
        });
      }
      data.map((dataval) => {
        dataSource.push(dataval);
        return {};
      });
      // let columns = columnConfig || [];
      let columnsData = [];
      if (data && data.length > 0) {
        let datavalues = Object.keys(data[0]);
        // data &&
        //   Array.isArray(data) &&
        //   data.map((dataObject) => {
        //     console.log(dataObject,typeof(dataObject),'dataObject');

        //     console.log( Array.isArray(dataObject),col,'columnnnn');
        //     // if (dataObject && Array.isArray(dataObject) && dataObject.length !== 0 || Object.entries(dataObject).length === 0) {
        //     //  dataObject.map((dataVal,index)=>{
        //     //   console.log(dataVal,'datavaall');
        //     //  })

        //       let valuess = Object.keys(data[0]);
        //       console.log(valuess,dataObject,'sd');
        columnsData = datavalues.map((item) => {
          return {
            title: item,
            dataIndex: item,
            key: item
          };
        });
        this.setState({ columnsData });
        //     // }
        //   })
      }
      let col = [...columnsData, ...columnsList];
      let duplicateColumns = col.filter(
        (ele, ind) => ind === col.findIndex((elem) => elem.title === ele.title)
      );
      this.setState({ columns: duplicateColumns, dataSource });
    } catch (e) {
      this.setState({ columns: [], dataSource: [] });
    }
  };

  removeDuplicateObjectsByKey = (array, fieldToDuplicateCheck) => {
    const newArray = [];
    const arrayKeys = [];
    array.forEach((item) => {
      if (!arrayKeys.includes(item[fieldToDuplicateCheck])) {
        arrayKeys.push(item[fieldToDuplicateCheck]);
        newArray.push(item);
      }
    });
    return newArray;
  };
  getData = async () => {
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;

    const { graphprops } = this.props;
    let json = {};
    let option = this.getOption();
    this.setState({ loading: true });
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = heatmapCartesionTableMock;
      this.setState({ loading: false });
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection({
          graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          selectionDateLive: this.props.selectionDateLive,
          currentTimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
        this.setState({ loading: false });
      } else {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
        this.setState({ loading: false });
      }
    }
    let xAxisList = [];
    let yAxisList = [];
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      xAxisList = this.removeDuplicateObjectsByKey(json, 'DateTime');
      yAxisList = this.removeDuplicateObjectsByKey(json, 'Description');

      option.xAxis.data = xAxisList.map((xAx) => {
        return xAx.DateTime;
      });
      option.yAxis.data = yAxisList.map((yAx) => {
        return yAx.Description;
      });

      // option.series[0].data = [[0, 0, 123], [0, 1, 124], [1, 0, 456], [1, 1, 567]]
      let list = [];
      yAxisList &&
        Array.isArray(yAxisList) &&
        yAxisList.map((yAxis, yIndex) => {
          xAxisList.map((xAxis, xIndex) => {
            let filterObj = json.filter(
              (axis) => axis.Description === yAxis.Description && axis.DateTime === xAxis.DateTime
            );
            list.push([
              xIndex,
              yIndex,
              filterObj && filterObj[0] && filterObj[0].Value ? filterObj[0].Value : null || '-'
            ]);
            return {};
          });
          return {};
        });
      option.series[0].data = list;
      this.setState({
        option,
        refreshDateTime: moment(),
        json
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB,
      xAxisList,
      yAxisList
    });
  };
  onChartClick = (cellData) => {
    let { xAxisList, yAxisList, json } = this.state;
    let selectedName = xAxisList[cellData.data[0]].DateTime;
    let selectedDescription = yAxisList[cellData.data[1]].Description;
    let filterObj = json.filter(
      (axis) => axis.Description === selectedDescription && axis.DateTime === selectedName
    );
    if (filterObj && filterObj[0] && filterObj[0].Action) {
      this.updateData(filterObj[0].Action);
    } else {
      this.updateData([]);
    }
  };
  render() {
    let { errorInAPI, noDataInDB, loading, dataSource, columns } = this.state;

    let onEvents = {
      click: (cellData) => this.onChartClick(cellData)
    };
    return (
      <span>
        {loading === false ? (
          <Fragment>
            {/* <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
                         {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm') : ""}
                     </Tag> */}
            {noDataInDB === true || errorInAPI === true ? (
              noDataInDB === true ? (
                <NoRecordComponent />
              ) : (
                <ErrorComponent />
              )
            ) : (
              <>
                {loading === false ? (
                  <div style={{ padding: '0px' }}>
                    <Row>
                      <Col span={22}>
                        {noDataInDB === true || errorInAPI === true ? (
                          noDataInDB === true ? (
                            <NoRecordComponent />
                          ) : (
                            <ErrorComponent />
                          )
                        ) : (
                          <ReactEcharts
                            option={this.state.option}
                            className="react_for_echarts"
                            style={{ height: '360px', width: '100%' }}
                            onEvents={onEvents}
                          />
                        )}
                      </Col>
                      <Col span={24}>
                        <div>
                          {columns && Array.isArray(columns) && columns.length > 0 ? (
                            <Table
                              style={{ padding: '20px' }}
                              showSorterTooltip={false}
                              className="basictable"
                              columns={columns && Array.isArray(columns) ? columns : []}
                              dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
                              pagination={{ pageSize: '5' }}
                            />
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: 'center',
                      color: 'white',
                      marginTop: '15%'
                    }}
                  >
                    <h2 style={{ color: 'white' }}>
                      <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                    </h2>
                  </div>
                )}
              </>
            )}
          </Fragment>
        ) : null}
      </span>
    );
  }

  getOption = () => {
    let option = {
      tooltip: {
        position: 'top'
      },
      grid: {
        height: '50%',
        top: '10%'
      },
      xAxis: {
        type: 'category',
        data: [],
        splitArea: {
          show: true
        }
      },
      yAxis: {
        type: 'category',
        data: [],
        splitArea: {
          show: true
        }
      },
      visualMap: {
        // min: 0,
        // max: 10,
        // calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '15%'
      },
      series: [
        {
          name: '',
          type: 'heatmap',
          data: [],
          label: {
            show: true
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(HeatmapCartesion);
