import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import HomePageComponent from './HomePageComponent';
import { getUserProfile } from '../../../../selectors/layout';
import { getmenuSelected } from '../../../../selectors/menu';
import { getLoggedInProfile } from '../../../../modules/layout/layout.duck';
import { getNavigationMode } from '../../../../selectors/theme';

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  selectedMenu: getmenuSelected(),
  navigationMode: getNavigationMode()
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getLoggedInProfile
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageComponent);
