import axios from 'axios';
import localStorage from '../../../../../utils/localStorage';
import { API_DEVICE, API_HIERARCHYMODULE } from '../../../../../commons/api';

export function getTreeData() {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getProcessArea = {
    method: 'GET',
    url: `${API_HIERARCHYMODULE.GetHierarchyLevels}?HierarchyCode=10&SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export function _getMarkerList(payload) {
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getProcessArea = {
    method: 'POST',
    url: `${API_DEVICE.GetStatusDetails}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: payload
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export function getDataForPopup(payload) {
  // return {
  //   Power: 289,
  //   WindSpeed: '232',
  //   Production: 98789
  // };
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getProcessArea = {
    method: 'POST',
    url: `${API_DEVICE.GetPopupDetails}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: payload
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}

export function _getTableData(payload) {
  // return [
  //   {
  //     Site: 'Kanya Kumari',
  //     Code: 'KYKumari',
  //     Type: 'Asset',
  //     Status: 'running',
  //     Capacity: '30%',
  //     Power: '20%'
  //   },
  //   { Site: 'Srinagar', Code: 'SN', Type: 'Asset', Status: 'idle', Capacity: '100%', Power: '20%' }
  // ];
  const token = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let Authorization = 'Bearer ' + token;
  const getProcessArea = {
    method: 'POST',
    url: `${API_DEVICE.GetTableDetails}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: payload
  };
  return axios(getProcessArea)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return [];
    });
}
