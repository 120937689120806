import { types } from './types';
import { fromJS } from 'immutable';

export const changeTheme = (theme) => {
  return {
    type: types.THEME,
    theme
  };
};
export const changeThemeing = (themeing) => {
  return {
    type: types.THEMEING,
    themeing
  };
};
export const setNavigationMode = (mode) => {
  return {
    type: types.NAVIGATION_MODE,
    mode
  };
};

const initialState = fromJS({
  theme: '#1a3652',
  navigationMode: 'top',
  themeing: 'darkTheme'
});

const theme = (state = initialState, action) => {
  switch (action.type) {
    case types.THEME: {
      return state.set('theme', action.theme);
    }
    case types.THEMEING: {
      return state.set('themeing', action.themeing);
    }
    case types.NAVIGATION_MODE: {
      return state.set('navigationMode', action.mode);
    }
    default:
      return state;
  }
};

export default theme;
