import { Drawer, Table, Form, Input, Button, Select, Tooltip, Popconfirm, message } from 'antd';
import React from 'react';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { DrawerFooter, StyledDashboard } from './styles';
import axios from 'axios';
import localStorage from '../../../../utils/localStorage';
import { getTranslationCache } from '../../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { TOPIC } from '../../../../commons/api';

const { Option } = Select;
class TopicMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datasource: [],
      formObject: {},
      visible: false,
      Type: [],
      Redisbd: [],
      translationCache: props.translationCache || []
    };
  }
  hRef = React.createRef();

  componentDidMount() {
    let siteId = this.props.match.params.siteId;
    this.setState({ siteId });
    this.getMasterList(siteId);
    this._Redisbd();
    this._TypeOnSelect();
  }

  _curdOperation = (datas) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const payload = {
      method: 'POST',
      url: `${TOPIC.CREATE}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: datas
    };
    axios(payload)
      .then((response) => {
        if (response && response.data) {
          this.getMasterList(datas);
          this.hRef.current.resetFields();
          message.success(response.data.message);
          this.setState({ visible: false });
        }
      })
      .catch(() => {});
  };

  getMasterList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'GET',
      url: `${TOPIC.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(Object)
      .then((response) => {
        this.setState({
          datasource: response.data
        });
      })
      .catch(() => {});
  };

  _delete = (record) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgDeleteHeaders = {
      method: 'DELETE',
      url: `${TOPIC.DELETE}?SiteId=${siteId}&Id=${record.Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgDeleteHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          message.success('Role Deleted');
          this.getMasterList(record.site);
          this.hRef.current.resetFields();
          this.setState({ visible: false });
        } else {
          message.error('Error in delete');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _TypeOnSelect = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const type = {
      method: 'GET',
      url: `${TOPIC.GetTopicTypes}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(type)
      .then((response) => {
        this.setState({
          Type: response.data
        });
      })
      .catch(() => {});
  };

  _Redisbd = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const type = {
      method: 'GET',
      url: `${TOPIC.GetRedisDB}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(type)
      .then((response) => {
        this.setState({
          Redisbd: response.data
        });
      })
      .catch(() => {});
  };

  onSubmit = (operation) => {
    let values = this.hRef.current.getFieldsValue('Site_Form');
    let saveObject = {
      ...values
    };
    if (operation === 'create') {
      this._curdOperation(saveObject);
    } else if (operation === 'update') {
      this._updateSiteAPI(saveObject);
    }
  };

  _updateSiteAPI = (updateObject) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgUpdateHeaders = {
      method: 'PATCH',
      url: `${TOPIC.UPDATE}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: updateObject
    };
    axios(orgUpdateHeaders)
      .then((response) => {
        if (response && response.data && response.data.message) {
          message.success(response.data.message);
          this.setState({ visible: false, formObject: {} });
          this.getMasterList();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _editRecord = (record) => {
    if (this.hRef.current) {
      this.hRef.current.setFieldsValue(record);
    }
    this.setState({
      formObject: record,
      visible: true
    });
  };

  onClose = () => {
    if (this.hRef.current) {
      this.hRef.current.resetFields();
    }
    this.setState({
      visible: false,
      formObject: {}
    });
  };

  addNew = () => {
    if (this.hRef.current) {
      this.hRef.current.resetFields();
    }
    this.setState({
      visible: true,
      formObject: {}
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let columns = [
      {
        title: this.translation('Topic'),
        dataIndex: 'Topic',
        key: 'Topic'
      },
      {
        title: this.translation('Type'),
        dataIndex: 'Type',
        key: 'Type'
      },
      {
        title: this.translation('RedisDB'),
        dataIndex: 'RedisDB',
        key: 'RedisDB'
      },
      {
        title: this.translation('Action'),
        dataIndex: 'Action',
        key: 'Action',
        render: (text, record) => (
          <>
            <Button className="ant-btn" onClick={() => this._editRecord(record)} size="middle">
              <EditOutlined />
            </Button>
            <Popconfirm
              title={this.translation('Are you sure delete ?')}
              onConfirm={(e) => {
                e.stopPropagation();
                this._delete(record);
              }}
              okText={this.translation('yes')}
              cancelText={this.translation('no')}
            >
              {' '}
              <Tooltip title={this.translation('Delete')}>
                <button type="button" className="ant-btn">
                  <DeleteOutlined />
                </button>
              </Tooltip>
            </Popconfirm>
          </>
        )
      }
    ];

    let { datasource, formObject, Type, Redisbd } = this.state;
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <Button
          type="primary"
          onClick={this.addNew}
          style={{ background: '#214972', marginBottom: '10px' }}
        >
          {' '}
          <PlusOutlined /> Add
        </Button>
        <Table columns={columns} dataSource={datasource} pagination={false} />
        <Drawer
          title={
            formObject && formObject.Id
              ? this.translation('Update Topic')
              : this.translation('Create Topic')
          }
          width={720}
          closable
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Form
            layout="vertical"
            ref={this.hRef}
            name="Site_form"
            initialValues={formObject}
            onFinish={this.onFormSubmit}
          >
            <Form.Item
              label={this.translation('Topic')}
              name="Topic"
              rules={[{ required: true, message: 'Please select topic !' }]}
            >
              <Input
                placeholder={this.translation('Please enter Topic')}
                disabled={formObject.Topic ? true : false}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Type')}
              name="Type"
              rules={[{ required: true, message: 'Please select type !' }]}
            >
              <Select placeholder={this.translation('Please select type')}>
                {Type.map((value, index) => {
                  return (
                    <Option key={index} value={value.Type}>
                      {value.Type}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={this.translation('RedisDB')}
              name="RedisDB"
              rules={[{ required: true, message: 'Please enter redisDB' }]}
            >
              <Select placeholder={this.translation('Please select RedisDB')}>
                {Redisbd.map((value, index) => {
                  return (
                    <Option key={index} value={value.RedisDB}>
                      {value.RedisDB}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
          <DrawerFooter>
            {!formObject.Id ? (
              <Button
                style={{ marginRight: 8 }}
                onClick={() => this.onSubmit('create')}
                type="primary"
              >
                {this.translation('Create')}
              </Button>
            ) : (
              <Button
                style={{ marginRight: 8 }}
                onClick={() => this.onSubmit('update')}
                type="primary"
              >
                {this.translation('Update')}
              </Button>
            )}
            <Button onClick={this.onClose}>{this.translation('Cancel')}</Button>
          </DrawerFooter>
        </Drawer>
      </StyledDashboard>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(TopicMaster);
