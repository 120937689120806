/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react';
import { RollbackOutlined } from '@ant-design/icons';

import history from '../../../commons/history';
import Analytics_V1Router from '../../../routes/Anaytics_Router';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../selectors/language';
// import translation from '../Utils/translation';
// import { constant } from '../Utils/Constants';
import { connect } from 'react-redux';
class anaytics_V1 extends Component {
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    let pathname = history.location.pathname;
    return (
      <div>
        {!pathname.includes('ModelsWorkbench') ? (
          <div>
            <div style={{ padding: '10px 19px' }}>
              <div style={{ color: '#000', fontSize: '16px' }}>
                <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
                  <RollbackOutlined />
                  {this.translation('Go Back')}
                </span>
              </div>
            </div>

            <Analytics_V1Router />
          </div>
        ) : (
          <Analytics_V1Router />
        )}
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(anaytics_V1);
