/**
 * @author Marmini ALluri
 * @description This Component is used for Chennai Metro Faults
 */
import React, { Component } from 'react';
import moment from 'moment';
import L from 'leaflet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { Button, DatePicker, Input, message, Select, Table } from 'antd';

import { getTranslationCache } from '../../../../../selectors/language';
import { getDefaultHierarchy, getUserDetails } from '../../../../../selectors/layout';
import { StyledDashboard } from '../../../../Settings/Masters/styles';
import ReactEcharts from '../Utils/Echarts/Echarts';
import Location from './Location.png';
import './map.css';
import {
  getCounterData,
  getMapsData,
  getTableData,
  getTrainList,
  getVerticalLineData
} from './APIcalls';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

class CMRL_page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          textStyle: {
            color: 'white',
            fontSize: 16,
            fontWeight: 'bold'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: 'white'
            }
          },
          axisTick: {
            lineStyle: {
              color: 'white'
            },
            interval: 1
          },
          axisLabel: {
            color: 'white'
          }
        },
        yAxis: {
          type: 'category',
          data: [],
          axisLine: {
            lineStyle: {
              color: 'white'
            }
          },
          axisTick: {
            lineStyle: {
              color: 'white'
            },
            interval: 1
          },
          axisLabel: {
            color: 'white'
          }
        },
        series: []
      },
      selectedDate: moment().format('YYYY-MM-DD')
    };
  }

  componentDidMount() {
    this.getData(this.state.selectedDate);
  }

  getData = async (selectedDate) => {
    let { defaultHiearchy } = this.state;
    let CounterData = [];
    let TableData = [];
    let DeviceCode = '';
    let hierarchyCode =
      defaultHiearchy && defaultHiearchy.HierarchyCode
        ? defaultHiearchy.HierarchyCode
        : 'ChennaiMetro_DefalutConfiguration';
    let CounterData1 = await getCounterData(5, 'Total Faults', selectedDate);
    let CounterData2 = await getCounterData(3, 'Priority 1 Faults', selectedDate);
    let CounterData3 = await getCounterData(4, 'Priority 2 Faults', selectedDate);
    let CounterData4 = await getCounterData(7, 'Active Faults', selectedDate);
    let CounterData5 = await getCounterData(6, 'Closed Faults', selectedDate);
    CounterData = [
      CounterData1[0] || {},
      CounterData2[0] || {},
      CounterData3[0] || {},
      CounterData4[0] || {},
      CounterData5[0] || {}
    ];
    let TrainList = await getTrainList(hierarchyCode);
    if (TrainList && TrainList[0] && TrainList[0].Code) {
      DeviceCode = TrainList[0].Code;
      TableData = await getTableData(selectedDate, DeviceCode);
    } else {
      message.error('Error fetching trains');
    }

    let LineData = await getVerticalLineData(selectedDate);
    let MapsData = await getMapsData(selectedDate);
    this.UpdateLineOptions(LineData);
    this.setState({
      TableData,
      CounterData,
      LineData,
      MapsData,
      TrainList,
      DeviceCode,
      selectedDate
    });
  };

  removeDuplicateObjectsByKey = (array, fieldToDuplicateCheck) => {
    const newArray = [];
    const arrayKeys = [];
    array.forEach((item) => {
      if (!arrayKeys.includes(item[fieldToDuplicateCheck])) {
        arrayKeys.push(item[fieldToDuplicateCheck]);
        newArray.push(item);
      }
    });
    return newArray;
  };
  UpdateLineOptions = (LineData) => {
    let { option } = this.state;
    let trainList = this.removeDuplicateObjectsByKey(LineData, 'DateTime');
    let descriptionList = this.removeDuplicateObjectsByKey(LineData, 'Description');
    option = {
      ...option,
      yAxis: {
        type: 'category',
        data:
          trainList &&
          trainList.map((train) => {
            return train.DateTime;
          }),
        axisLine: {
          lineStyle: {
            color: 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: 'white'
          },
          interval: 1
        },
        axisLabel: {
          color: 'white'
        }
      },
      series:
        descriptionList &&
        Array.isArray(descriptionList) &&
        descriptionList.map((desc) => {
          return {
            name: desc.Description,
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data:
              LineData &&
              Array.isArray(LineData) &&
              LineData.filter((e) => e.Description === desc.Description).map((barData) => {
                return barData.Value;
              })
          };
        })
    };
    this.setState({ option });
  };
  onDateChange = (date, selectedDate) => {
    this.setState({ selectedDate });
    this.getData(selectedDate);
  };
  handleDeviceChange = async (DeviceCode) => {
    let { selectedDate } = this.state;
    let TableData = await getTableData(selectedDate, DeviceCode);
    let CounterData1 = await getCounterData(5, 'Total Faults', selectedDate);
    let CounterData2 = await getCounterData(3, 'Priority 1 Faults', selectedDate);
    let CounterData3 = await getCounterData(4, 'Priority 2 Faults', selectedDate);
    let CounterData4 = await getCounterData(7, 'Active Faults', selectedDate);
    let CounterData5 = await getCounterData(6, 'Closed Faults', selectedDate);
    let CounterData = [
      CounterData1[0] || {},
      CounterData2[0] || {},
      CounterData3[0] || {},
      CounterData4[0] || {},
      CounterData5[0] || {}
    ];
    this.setState({ DeviceCode, CounterData, TableData });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  translation = (translationCache, keyword) => {
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation(this.props.translationCache, 'Search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation(this.props.translationCache, 'Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation(this.props.translationCache, 'Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  render() {
    let { TableData, CounterData, MapsData, selectedDate, TrainList, DeviceCode } = this.state;

    let columns =
      TableData &&
      Array.isArray(TableData) &&
      TableData[0] &&
      Object.keys(TableData[0]).map((colKey) => {
        return {
          title: colKey,
          dataIndex: colKey,
          key: colKey,
          ...this.getColumnSearchProps(colKey)
        };
      });

    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 67 }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DatePicker
            style={{ marginRight: '10px' }}
            value={moment(selectedDate, 'YYYY-MM-DD')}
            onChange={this.onDateChange}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '25%', height: '100vh' }}>
            <ReactEcharts
              option={this.state.option}
              className="react_for_echarts"
              style={{ height: '80%', width: '100%' }}
            />
          </div>
          <div style={{ width: '75%', height: '100vh' }}>
            <div class="flex-container">
              {CounterData &&
                Array.isArray(CounterData) &&
                CounterData.map((counter) => {
                  return (
                    <>
                      <div
                        class="flex-item"
                        style={{
                          border: '1px solid white',
                          color: 'white',
                          fontSize: '16px',
                          fontWeight: '800',
                          borderRadius: '18px'
                        }}
                      >
                        <div
                          style={{
                            padding: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            borderBottom: '1px solid'
                          }}
                        >
                          {counter && counter.Name ? counter.Name : ''}
                        </div>
                        <div
                          style={{
                            padding: '14px'
                          }}
                        >
                          {counter && counter.Value ? counter.Value : ''}
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Select
                placeholder={'Select Device'}
                onChange={this.handleDeviceChange}
                value={DeviceCode}
                style={{ width: 200, marginRight: '10px' }}
              >
                {TrainList &&
                  Array.isArray(TrainList) &&
                  TrainList.map((train, index) => {
                    return (
                      <Select.Option key={index} value={train.Code}>
                        {' '}
                        {train.Name}{' '}
                      </Select.Option>
                    );
                  })}
              </Select>
            </div>
            <div style={{ padding: '10px' }}>
              <Map
                center={
                  MapsData &&
                  MapsData[0] &&
                  MapsData[0].ParentLatitude &&
                  MapsData[0].ParentLongitude
                    ? [MapsData[0].ParentLatitude, MapsData[0].ParentLongitude]
                    : [51.0, 19.0]
                }
                zoom={4}
                style={{ height: '400px', width: '100%' }}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                />
                {MapsData &&
                  Array.isArray(MapsData) &&
                  MapsData.map((mark, index) => {
                    return (
                      <Marker
                        key={index}
                        icon={
                          new L.Icon({
                            iconUrl: `${Location}`,
                            iconRetinaUrl: `${Location}`,
                            popupAnchor: [-0, -0],
                            iconSize: [15, 15]
                          })
                        }
                        onclick={() => this.setState({ mark })}
                        position={[mark.Latitude || 0, mark.Longitude || 0]}
                      >
                        <Popup>
                          <div style={{ fontWeight: 'bold' }}>
                            Station: {mark && mark.Station ? mark.Station : ''}
                            <br />
                            Total Faults: {mark && mark['Total Faults'] ? mark['Total Faults'] : ''}
                            <br />
                            Priority-1 Faults:{' '}
                            {mark && mark['Priority-1 Faults'] ? mark['Priority-1 Faults'] : ''}
                            <br />
                            Priority-2 Faults:{' '}
                            {mark && mark['Priority-2 Faults'] ? mark['Priority-2 Faults'] : ''}
                            <br />
                          </div>
                        </Popup>
                      </Marker>
                    );
                  })}
              </Map>
            </div>
            <div style={{ padding: '10px' }}>
              <Table
                dataSource={TableData && Array.isArray(TableData) ? TableData : []}
                columns={columns && Array.isArray(columns) ? columns : []}
                pagination={{ pageSize: 5 }}
              />
            </div>
          </div>
        </div>
      </StyledDashboard>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  UserDetails: getUserDetails(),
  translationCache: getTranslationCache(),
  defaultHiearchy: getDefaultHierarchy()
});
export default connect(mapStateToProps)(CMRL_page);
