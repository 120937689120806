import styled from 'styled-components';
import { Badge, Button } from 'antd';
import { Link } from 'react-router-dom';

export const StyledDashboard = styled.div`
  background: #173049;
  margin: 20px;
  padding: 30px;
  min-height: 750px;
`;
export const DrawerFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
`;
export const StyledAddButton = styled(Button)`
  margin-left: 30px;
`;
export const AddTableButton = styled(Button)`
  margin-bottom: 20px;
`;
export const StyledIFrame = styled.iframe`
  width: 402%;
  height: 400%;
  border: 0;
  position: absolute;
  top: 0;
  left: -0.5%;
  background: #191a1d;
  -webkit-transform: scale(0.5);
  transform: scale(0.25);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  margin: 2px auto;
`;

export const LinkOverlay = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  :hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
  }
`;

export const AddItemDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const BadgeStyle = styled(Badge)`
  .ant-badge-count {
    background-color: #ffffff !important;
    color: #999999 !important;
  }
`;

export const SpanItem = styled.span`
margin-right: 1em
margin-left: 1em
color:white
`;
