import React from 'react';
import { Button, Form, Table, Upload, message, Tag } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import localStorage from '../../../../utils/localStorage';
import './style.css';
import { StyledSubHeading } from '../style';
import { API_ASSET, API_DEVICE, API_PARAMETER } from '../../../../commons/api';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../selectors/language';

class ValidConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mockAsset: [],
      mockDevice: [],
      mockParameter: [],
      translationCache: props.translationCache || []
    };
  }
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let { mockAsset, mockDevice, mockParameter } = this.state;
    let assetColumns = [
      {
        title: this.translation('AssetCode'),
        dataIndex: 'AssetCode',
        key: 'AssetCode'
      },
      {
        title: this.translation('unmatchedcolumn'),
        key: 'unmatchedcolumn',
        dataIndex: 'unmatchedcolumn',
        render: (_, { unmatchedcolumn }) => (
          <>
            {unmatchedcolumn.map((tag) => {
              let color = tag.length > 0 ? 'geekblue' : 'green';
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </>
        )
      },
      {
        title: this.translation('Type'),
        dataIndex: 'Type',
        key: 'Type'
      }
    ];
    let columns = [
      {
        title: this.translation('DeviceCode'),
        dataIndex: 'DeviceCode',
        key: 'DeviceCode'
      },
      {
        title: this.translation('unmatchedcolumn'),
        key: 'unmatchedcolumn',
        dataIndex: 'unmatchedcolumn',
        render: (_, { unmatchedcolumn }) => (
          <>
            {unmatchedcolumn.map((tag) => {
              let color = tag.length > 14 ? 'geekblue' : 'green';
              if (tag === 'EquipmentType') {
                color = 'volcano';
              }
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </>
        )
      },
      {
        title: this.translation('Type'),
        dataIndex: 'Type',
        key: 'Type'
      }
    ];
    let parameterColumns = [
      {
        title: this.translation('DeviceCode'),
        dataIndex: 'DeviceCode',
        key: 'DeviceCode'
      },
      {
        title: this.translation('ParameterCode'),
        dataIndex: 'ParameterCode',
        key: 'ParameterCode'
      },
      {
        title: this.translation('unmatchedcolumn'),
        key: 'unmatchedcolumn',
        dataIndex: 'unmatchedcolumn',
        render: (_, { unmatchedcolumn }) => (
          <>
            {unmatchedcolumn.map((tag) => {
              let color = tag.length > 15 ? 'geekblue' : 'green';
              if (tag === 'EdgeCode') {
                color = 'volcano';
              }
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </>
        )
      },
      {
        title: this.translation('Type'),
        dataIndex: 'Type',
        key: 'Type'
      }
    ];

    let siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;

    const assetprops = {
      name: 'file',
      action: `${API_ASSET.CHECKDUPLICATE}?SiteId=${siteId}`,
      accept: 'text/csv',
      multiple: 'false',
      headers: {
        Authorization: Authorization
      },
      onChange: (info) => {
        if (info.file.status === 'done') {
          message.success(this.translation('File Validated Successfully'));
          mockAsset = info.file.response;
          this.setState({
            verifyAssetStatus: true,
            validatedFile: info.file.name,
            mockAsset
          });
        } else if (info.file.status === 'error') {
          message.error(this.translation('Validation Failed'));
        }
      }
    };

    const props = {
      name: 'file',
      action: `${API_DEVICE.CHECKDUPLICATE}?SiteId=${siteId}`,
      accept: 'text/csv',
      headers: {
        Authorization: Authorization
      },
      onChange: (info) => {
        if (info.file.response) {
          message.success('File Validated Successfully');
          mockDevice = info.file.response;
          this.setState({ verifyStatus: true, validatedFile: info.file.name, mockDevice });
        } else if (info.file.status === 'error') {
          message.error(this.translation('Validation Failed'));
        }
      }
    };

    const parameterProps = {
      name: 'file',
      action: `${API_PARAMETER.CHECKDUPLICATES}?SiteId=${siteId}`,
      accept: 'text/csv',
      headers: {
        Authorization: Authorization
      },
      onChange: (info) => {
        if (info.file.status === 'done') {
          message.success(this.translation('File Validated Successfully'));
          mockParameter = info.file.response;
          this.setState({
            verifyParameterstatus: true,
            validatedFile: info.file.name,
            mockParameter
          });
        } else if (info.file.status === 'error') {
          message.error(this.translation('Validation Failed'));
        }
      }
    };

    return (
      <Form>
        <div style={{ marginBottom: '15px' }}>
          <StyledSubHeading style={{ position: 'relative' }}>
            {this.translation('Asset Validation')}
          </StyledSubHeading>
          <Form.Item label={this.translation('Asset Configuration')}>
            <Upload {...assetprops}>
              <Button icon={<UploadOutlined />}>{this.translation('Upload a file')}</Button>
            </Upload>
          </Form.Item>
          <Table className="table" columns={assetColumns} dataSource={mockAsset} />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <StyledSubHeading style={{ position: 'relative' }}>
            {this.translation('Device Validation')}
          </StyledSubHeading>
          <Form.Item label="Device Configuration">
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>{this.translation('Upload a file')}</Button>
            </Upload>
          </Form.Item>
          <Table className="table" columns={columns} dataSource={mockDevice} />
          {/* <Divider style={{border: '1px dashed'}} /> */}
        </div>
        <div style={{ marginBottom: '15px' }}>
          <StyledSubHeading style={{ position: 'relative' }}>
            {this.translation('Parameter Validation')}
          </StyledSubHeading>
          <Form.Item label="Parameter Configuration">
            <Upload {...parameterProps}>
              <Button icon={<UploadOutlined />}>{this.translation('Upload a file')}</Button>
            </Upload>
          </Form.Item>
          <Table className="table" columns={parameterColumns} dataSource={mockParameter} />
        </div>
      </Form>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ValidConfiguration);
