import React from 'react';
import { withRouter } from 'react-router';
import { Menu } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import SettingsRouter from '../../routes/settingsRouter';
import styles from '../../commons/styles';
import history from '../../commons/history';
import { getTranslationCache } from '../../selectors/language';
import { getMenuHierarchy } from '../../selectors/layout';

import './style.css';
import { constant } from './Constants';
import { UserOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { StyledWrapper, StyledWrapperLeft, StyledWrapperRight } = styles.layout;

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      tabPosition: 'left',
      translationCache: props.translationCache || []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (
      prevProps.menuHierarchy !== this.props.menuHierarchy &&
      this.props.menuHierarchy['ApplicationSettings'] &&
      this.props.menuHierarchy['ApplicationSettings'].children
    ) {
      this.setState({
        menu: Object.keys(this.props.menuHierarchy['ApplicationSettings'].children).map(
          (i) => this.props.menuHierarchy['ApplicationSettings'].children[i]
        )
      });
    }
  }

  componentDidMount() {
    if (this.props.menuHierarchy && this.props.menuHierarchy['ApplicationSettings']) {
      this.setState({
        menu: Object.keys(this.props.menuHierarchy['ApplicationSettings'].children).map(
          (i) => this.props.menuHierarchy['ApplicationSettings'].children[i]
        )
      });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  getSubMenuOrItem = (item) => {
    if (item && item.children) {
      if (!item.children) {
        return [];
      }
      if (item.children && Object.keys(item.children).length > 0) {
        return (
          <SubMenu
            key="settings"
            title={
              <span>
                <UserOutlined />
                <span> {this.translation(constant.User_Management)}</span>
              </span>
            }
          >
            {item.children
              ? Object.keys(item.children)
                  .map((itemName) => item['children'][itemName])
                  .filter((item) => item && item.name && !item.hideInMenu)
                  .map((item) => {
                    return this.getSubMenuOrItem(item);
                  })
                  .filter((item) => item)
              : null}
          </SubMenu>
        );
      }
    }
    document.title = 'Application Settings';
    return (
      <Menu.Item
        key={item.key}
        className="customclass"
        onClick={() => {
          history.push(item.path);
        }}
      >
        {/* {item.icon ? <Icon type={item.icon} /> : null} */}
        <span className="nav-text">{this.translation(item.name)}</span>
      </Menu.Item>
    );
  };

  getNavMenuItems = (menusData) => {
    if (!menusData) {
      return [];
    }
    if (Array.isArray(menusData)) {
      return menusData
        .filter((item) => item && item.name && !item.hideInMenu)
        .map((item) => {
          return this.getSubMenuOrItem(item);
        })
        .filter((item) => item);
    }
  };

  render() {
    let { menu } = this.state;
    return (
      <StyledWrapper style={{ minHeight: window.innerHeight - 67 }}>
        <StyledWrapperLeft>
          <Menu mode="inline">
            {menu && Array.isArray(menu) ? this.getNavMenuItems(menu) : null}
          </Menu>
        </StyledWrapperLeft>
        <StyledWrapperRight style={{ background: 'white', margin: '5px' }}>
          <SettingsRouter />
        </StyledWrapperRight>
      </StyledWrapper>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  menuHierarchy: getMenuHierarchy()
});

export default connect(mapStateToProps)(withRouter(SettingsPage));
