import React from 'react';
import axios from 'axios';
import { Divider, Button, Row, Col, Select, Input, message, Form } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import localStorage from '../../../../utils/localStorage';
import { _getDeviceParameterBasedOnType } from '../../AssetDeviceList/APICalls';
import { API_DEVICE, API_PARAMETER } from '../../../../commons/api';
import { getTranslationCache } from '../../../../selectors/language';
const FormItem = Form.Item;
const { Option } = Select;

class DeviceParameterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UOMList: props.UOMList || [],
      deviceParametersBasedOnType: props.deviceParametersBasedOnType,
      deviceTypeObject: {}
    };
  }

  deviceTypePRef = React.createRef();

  UNSAFE_componentWillReceiveProps({ UOMList, deviceParametersBasedOnType, deviceType }) {
    this.setState({
      UOMList,
      deviceType,
      deviceParametersBasedOnType
    });
  }

  componentDidUpdate(prevprops) {
    if (prevprops.deviceParametersBasedOnType !== this.props.deviceParametersBasedOnType) {
      this.setState({
        deviceParametersBasedOnType: this.props.deviceParametersBasedOnType
      });
    }
  }

  handleFieldChange = (e, parameter, index) => {
    const { deviceParametersBasedOnType } = this.state;
    const arrayUpdated = this.updateArray(deviceParametersBasedOnType, index, (item) => ({
      ...item,
      [parameter]: e
    }));
    this.props.deviceParameter(arrayUpdated);
    this.setState({ deviceParametersBasedOnType: arrayUpdated });
  };

  parameterValChanged = (e, parameter, index) => {
    const { deviceParametersBasedOnType } = this.state;
    const arrayUpdated = this.updateArray(deviceParametersBasedOnType, index, (item) => ({
      ...item,
      [parameter]: e.target.value
    }));
    this.props.deviceParameter(arrayUpdated);
    this.setState({ deviceParametersBasedOnType: arrayUpdated });
  };

  remove = (k, object) => {
    let { deviceType } = this.props;
    const accessToken = localStorage.get('accessToken');
    const ParameterDeleteObject = {
      method: 'DELETE',
      url: `${API_PARAMETER.DELETE}?Id=${object.Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(ParameterDeleteObject)
      .then(async (response) => {
        if (response.status === 200) {
          message.success(response.data.message);
          let data = await _getDeviceParameterBasedOnType(deviceType);
          this.setState({ deviceParametersBasedOnType: data });
        } else {
          message.error(response.message);
        }
      })
      .catch(function () {});
  };

  updatedeviceParameter(deviceTypeId) {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'GET',
      url: `${API_DEVICE.GetParamsByDeviceType}/${deviceTypeId}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(deviceTypeObject)
      .then((response) => {
        this.setState({ deviceParametersBasedOnType: response.data });
      })
      .catch(function () {});
  }

  add = () => {
    let { deviceParametersBasedOnType } = this.state;
    let { deviceType } = this.props;
    const newObj = {
      Name: '',
      UOM: '',
      DataType: '',
      Description: '',
      DeviceType: deviceType
    };
    if (deviceParametersBasedOnType && Array.isArray(deviceParametersBasedOnType)) {
      deviceParametersBasedOnType.push(newObj);
    } else {
      deviceParametersBasedOnType = [];
      deviceParametersBasedOnType.push(newObj);
    }
    if (this.deviceTypePRef.current) {
      this.deviceTypePRef.current.setFieldsValue(deviceParametersBasedOnType);
    }
    this.props.deviceParameter(deviceParametersBasedOnType);
    this.setState({ deviceParametersBasedOnType });
  };

  updateArray = (array, index, updateFn) => {
    return [...array.slice(0, index), updateFn(array[index]), ...array.slice(index + 1)];
  };

  resetForm = () => {
    this.setState({ deviceTypeObject: {} });
    if (this.deviceTypePRef.current) {
      this.deviceTypePRef.current.resetFields();
    }
  };

  handleSubmit = () => {
    this.deviceTypePRef.current.validateFields().then(async (values) => {
      this.setState({ visible: false });
      this.props.actions.saveDeviceParamters(values);
    });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { UOMList, deviceType } = this.state;
    let { deviceParametersBasedOnType } = this.props;
    const formItems =
      deviceParametersBasedOnType &&
      Array.isArray(deviceParametersBasedOnType) &&
      deviceParametersBasedOnType.map((k, index) =>
        k !== null ? (
          <Row key={index}>
            <Col key={index} span={4}>
              <FormItem
                label={index === 0 ? this.translation('Parameter Name') : ''}
                key={k.Name}
                name={[index, 'Name']}
                // name={`Name[${index}]`}
                rules={[{ required: true, message: this.translation('Name is required') }]}
              >
                <Input
                  style={{ width: '90%' }}
                  placeholder={this.translation('Enter name')}
                  onBlur={(e) => {
                    this.parameterValChanged(e, 'Name', index);
                  }}
                />
              </FormItem>
            </Col>
            <Col span={4}>
              <FormItem
                label={index === 0 ? this.translation('Parameter Code') : ''}
                key={k.Code}
                name={[index, 'Code']}
                // name={`Code[${index}]`}
                rules={[{ required: true, message: this.translation('Code is required') }]}
              >
                <Input
                  style={{ width: '90%' }}
                  placeholder={this.translation('Enter code')}
                  onBlur={(e) => {
                    this.parameterValChanged(e, 'Code', index);
                  }}
                />
              </FormItem>
            </Col>
            {/* <Col span={4}>
              <FormItem
                label={index === 0 ? 'Parameter Description' : ''}
                key={k.Description}
                name={[index, 'Description']}
                // name={`Description[${index}]`}
                rules={[{ required: true, message: 'Description is required' }]}
              >
                <Input
                  style={{ width: '90%' }}
                  placeholder="Enter Description"
                  onBlur={(e) => {
                    this.parameterValChanged(e, 'Description', index);
                  }}
                />
              </FormItem>
            </Col> */}
            <Col span={4}>
              <FormItem
                label={index === 0 ? this.translation('UOM') : ''}
                key={k.UOM}
                name={[index, 'UOM']}
                rules={[{ required: false, message: this.translation('UOM is required') }]}
              >
                <Select
                  defaultValue={k.UOM}
                  style={{ width: 160 }}
                  onChange={(e) => this.handleFieldChange(e, 'UOM', index)}
                  placeholder={this.translation('Name')}
                >
                  {UOMList &&
                    UOMList.map((uom, index) => {
                      return (
                        <Option key={index} value={uom.UOM}>
                          {uom.UOM}
                        </Option>
                      );
                    })}
                </Select>
              </FormItem>
            </Col>

            <Col span={4}>
              <FormItem
                label={index === 0 ? this.translation('DataType') : ''}
                key={k.DataType}
                name={[index, 'DataType']}
                // name={`DataType[${index}]`}
                rules={[{ required: true, message: this.translation('DataType is required') }]}
              >
                <Select
                  defaultValue={k.DataType}
                  style={{ width: 160 }}
                  onChange={(e) => this.handleFieldChange(e, 'DataType', index)}
                  placeholder="DataType"
                >
                  <Option value="float">{this.translation('float')}</Option>
                  <Option value="boolean">{this.translation('boolean')}</Option>
                </Select>
              </FormItem>
            </Col>
            <Form.Item
              label={this.translation('Id')}
              name={[index, 'Id']}
              display="none"
              hidden={true}
            >
              <Input style={{ display: 'none' }} />
            </Form.Item>
            <Form.Item
              label={this.translation('DeviceType')}
              name={[index, 'DeviceType']}
              display="none"
              hidden={true}
            >
              <Input style={{ display: 'none' }} />
            </Form.Item>
            <Col span={4}>
              <FormItem label={index === 0 ? '' : ''}>
                {deviceParametersBasedOnType && deviceParametersBasedOnType.length > 0 ? (
                  <MinusCircleOutlined
                    style={{ marginLeft: '10px' }}
                    className="dynamic-delete-button"
                    disabled={deviceParametersBasedOnType.length === 1}
                    onClick={() => this.remove(index, k, deviceType)}
                  />
                ) : null}
              </FormItem>
            </Col>
          </Row>
        ) : null
      );

    return (
      <div>
        <Divider>{this.translation('Device Parameter')}</Divider>
        <Form
          ref={this.deviceTypePRef}
          name="dynamic_form_nest_item"
          autoComplete="off"
          initialValues={deviceParametersBasedOnType}
        >
          <Form.Item>
            <Button type="dashed" onClick={this.add}>
              <PlusOutlined /> {this.translation('Add')}
            </Button>
          </Form.Item>
          {formItems}
          <Form.Item>
            <Button type="primary" style={{ marginRight: '5px' }} onClick={this.handleSubmit}>
              {this.translation('Create/Update Device Parameters')}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                this.props.closeDrawer();
                if (this.deviceTypePRef.current) {
                  this.deviceTypePRef.current.resetFields();
                }
              }}
            >
              {this.translation('Close')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(DeviceParameterComponent);
