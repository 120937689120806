import { API_AUTH } from '../../commons/api';

export default class loginAuth {
  static userAuth(user) {
    return fetch(`${API_AUTH.LOGIN}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static signUpAuth(user) {
    return fetch(`${API_AUTH.SignUp}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }
}
