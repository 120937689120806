import React from 'react';
// import { Map, TileLayer } from 'react-leaflet';
import './css/map.css';
import 'leaflet/dist/leaflet.css';
// import { Select } from 'antd';
import MapWithTable from './MapWithTable';

// const defaultCenter = [38.9072, -77.0369];
// const defaultZoom = 8;

function MapWithSidePanel() {
  return (
    <div>
      <MapWithTable />
    </div>
  );
}

export default MapWithSidePanel;
