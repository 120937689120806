import React from 'react';
import moment from 'moment';
import { DatePicker, Radio, Menu, Select } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { DatePickerSlider } from '../../../ChartComponents/CSS/style';
import '../../CSS/style.css';
import { getTranslationCache } from '../../../../../selectors/language';
const { Option } = Select;
const { WeekPicker } = DatePicker;

class DayMonthYearSelection extends React.Component {
  constructor(props) {
    super(props);
    let { graphprops } = props;
    let selectionPoint =
      graphprops &&
      graphprops.DashboardLevelSelectionPoint &&
      graphprops.DashboardLevelSelectionPoint === 'PreviousDay'
        ? '1'
        : '0';
    this.state = {
      currentDate: moment().subtract(selectionPoint, 'day'),
      currentyear: null,
      EndDate: moment(),
      disableNext: false,
      week: moment().startOf('week'),
      month: moment().format('MMMM'),
      year: moment().year()
    };
  }

  componentDidMount() {
    let timeBucketObject =
      this.props && this.props.headerJSON && this.props.headerJSON.TimeBucket
        ? this.props.headerJSON.TimeBucket
        : {};

    let currentTab =
      timeBucketObject && Object.keys(timeBucketObject) && Object.keys(timeBucketObject)[0]
        ? Object.keys(timeBucketObject)[0]
        : '';
    this.setState({
      currentTab
    });
  }

  disabledDate = (current) => {
    return current > moment().startOf('day');
  };

  onChange = async (date) => {
    let timeBucketObject = this.props.dashboards.json[0].TimeBucket;
    // timeBucketObject = this.props.dashboards.json[0].GraphLevelTimeBucket;

    let startDateSelected = moment(date).format('YYYY-MM-DD 00:00:00');
    let endDateSelected = moment(date).format('YYYY-MM-DD 23:59:59');
    let payload = {
      StartDate: moment(startDateSelected).format('YYYY-MM-DD HH:mm:ss'),
      EndDate: moment(endDateSelected).format('YYYY-MM-DD HH:mm:ss'),
      TimeBucket: timeBucketObject && timeBucketObject['Day'] ? timeBucketObject['Day'] : '',
      SelectionType: 'Day'
    };
    this.setState({ currentDate: date, payload });
    this.props.selectionpayload(payload);
  };

  onRadioChange = (e) => {
    if (e.target.value === 'Month') {
      let currentMonth = moment().format('MMMM');
      let currentYear = moment().year();
      this.handleMenuClick(currentMonth, 'Month', 'default');
      this.setState({
        currentTab: e.target.value,
        month: currentMonth,
        year: currentYear
      });
    } else if (e.target.value === 'Year') {
      let currentYear = moment().year();
      this.handleMenuClick(currentYear, 'Year', 'default');
      this.setState({
        currentTab: e.target.value,
        year: currentYear
      });
    } else if (e.target.value === 'Week') {
      let currentWeek = moment().startOf('isoWeek');
      this.handleMenuClick(currentWeek, 'Week', 'default');
      this.setState({
        currentTab: e.target.value,
        week: currentWeek
      });
    } else if (e.target.value === 'Day') {
      this.onChange(moment().subtract(1, 'Day'), 'default');
      this.setState({
        currentTab: e.target.value
      });
    } else {
      this.onChange(moment());
      this.setState({
        currentTab: e.target.value
      });
    }
  };

  getNavMenuItems = (menusData) => {
    if (!menusData) {
      return [];
    }
    return menusData
      .map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
      })
      .filter((item) => item);
  };

  handleMenuClick = async (value, parent, callType) => {
    let { currentyear } = this.state;
    let { graphprops } = this.props;
    let DashboardLevelSelectionPoint =
      graphprops &&
      graphprops.DashboardLevelSelectionPoint &&
      graphprops.DashboardLevelSelectionPoint === 'PreviousDay' &&
      callType === 'default'
        ? '1'
        : '0';

    let timeBucketObject =
      this.props.dashboards.json &&
      this.props.dashboards.json[0] &&
      this.props.dashboards.json[0].TimeBucket
        ? this.props.dashboards.json[0].TimeBucket
        : {};
    if (parent === 'Month') {
      let currentYear = moment().year();
      let selectedMonth = moment(value, 'MMMM').month();
      let payload = {
        StartDate: moment([currentYear, selectedMonth])
          .startOf('month')
          .format('YYYY-MM-DD HH:mm:ss'),
        EndDate: currentyear
          ? currentyear === moment().year() && selectedMonth === parseInt(moment().format('M') - 1)
            ? moment().subtract(DashboardLevelSelectionPoint, 'day').format('YYYY-MM-DD HH:mm:ss')
            : moment([currentyear, selectedMonth]).endOf('month').format('YYYY-MM-DD HH:mm:ss')
          : currentYear === moment().year() && selectedMonth === parseInt(moment().format('M') - 1)
          ? moment().subtract(DashboardLevelSelectionPoint, 'day').format('YYYY-MM-DD HH:mm:ss')
          : moment([currentYear, selectedMonth]).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        TimeBucket: timeBucketObject['Month'],
        SelectionType: 'Month'
      };
      this.setState({ payload, month: value });
      this.props.selectionpayload(payload);
    } else if (parent === 'Year') {
      let payload = {
        StartDate: moment([value, 0]).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        EndDate:
          value === moment().year()
            ? moment().subtract(DashboardLevelSelectionPoint, 'day').format('YYYY-MM-DD HH:mm:ss')
            : moment([value, 11]).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        TimeBucket: timeBucketObject['Year'],
        SelectionType: 'Year'
      };
      this.setState({ currentyear: value, payload, year: value });
      this.props.selectionpayload(payload);
    } else if (parent === 'Week') {
      let currentWeek = moment().startOf('isoWeek');
      let payload = {
        StartDate: moment(value).startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        EndDate: value.isSame(currentWeek)
          ? moment().subtract(DashboardLevelSelectionPoint, 'day').format('YYYY-MM-DD HH:mm:ss')
          : moment(value).endOf('week').format('YYYY-MM-DD HH:mm:ss'),
        TimeBucket: timeBucketObject['Week'],
        SelectionType: 'Week'
      };
      this.setState({ currentDate: value, payload, week: moment(value).startOf('week') });
      this.props.selectionpayload(payload);
    }
  };

  componentWillUnmount() {
    clearInterval(this.timeTicket);
  }
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    let { currentTab } = this.state;
    if (moment().year() > 2023) {
      var yearList = [];
      for (var i = 2023; i <= moment().year(); i++) {
        yearList.push(i);
      }
      yearList.sort((a, b) => b - a);
    }

    // let timeBucketArray = this.props.dashboards.json[0].TimeBucket;
    let timeBucketArray =
      this.props && this.props.headerJSON && this.props.headerJSON.TimeBucket
        ? this.props.headerJSON.TimeBucket
        : '';
    // timeBucketArray =
    //   this.props &&
    //   this.props.dashboards &&
    //   this.props.dashboards.json[0] &&
    //   this.props.dashboards.json[0].GraphLevelTimeBucket &&
    //   this.props.dashboards.json[0].GraphLevelTimeBucket !== 'none'
    //     ? this.props.dashboards.json[0].GraphLevelTimeBucket
    //     : this.props.dashboards.json[0].TimeBucket;
    return (
      <div style={{ height: '70%', marginTop: '10px' }}>
        <DatePickerSlider>
          <Radio.Group
            value={this.state.currentTab}
            onChange={this.onRadioChange}
            style={{ marginLeft: '20px' }}
          >
            {timeBucketArray['Day'] ? (
              <Radio.Button value="Day">{this.translation('Day')}</Radio.Button>
            ) : null}
            {timeBucketArray['Week'] ? (
              <Radio.Button value="Week">{this.translation('Week')}</Radio.Button>
            ) : null}
            {timeBucketArray['Month'] ? (
              <Radio.Button value="Month">{this.translation('Month')}</Radio.Button>
            ) : null}
            {timeBucketArray['Year'] ? (
              <Radio.Button value="Year">{this.translation('Year')}</Radio.Button>
            ) : null}
          </Radio.Group>
          {currentTab === 'Day' ? (
            <DatePicker
              onChange={this.onChange}
              value={moment(this.state.currentDate)}
              disabledDate={this.disabledDate}
              allowClear={false}
            />
          ) : null}
          {currentTab === 'Week' ? (
            <WeekPicker
              value={this.state.week}
              style={{ width: '200px' }}
              disabledDate={this.disabledDate}
              placeholder="Select Week"
              allowClear={false}
              onChange={(e) => this.handleMenuClick(e, 'Week')}
            />
          ) : null}
          {currentTab === 'Month' ? (
            <Select
              value={this.state.month}
              onChange={(e) => this.handleMenuClick(e, 'Month')}
              style={{ width: '100px' }}
            >
              {moment.months().map((value, index) => {
                return (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                );
              })}
            </Select>
          ) : null}
          {currentTab === 'Year' || currentTab === 'Month' ? (
            <Select
              value={this.state.year}
              onChange={(e) => this.handleMenuClick(e, 'Year')}
              style={{ width: '100px' }}
            >
              {yearList.map((value, index) => {
                return (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                );
              })}
            </Select>
          ) : null}
        </DatePickerSlider>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(DayMonthYearSelection);
