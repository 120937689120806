import React, { Component, Fragment } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ReactEcharts from '../../Echarts/Echarts';
import { Tag } from 'antd';

import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { getCurrentTimezone } from '../../../../../selectors/layout';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';
import { arrayOfObjects } from '../../../Mock/MockJson';

class CompassGuage extends Component {
  constructor(props) {
    super(props);
    let option = this.getInitialState();
    this.state = {
      option: option.option,
      fromedit: props.graphprops.fromedit,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  UNSAFE_componentWillReceiveProps() {
    let option = this.getInitialState();
    this.setState({
      option: option.option,
      fromedit: this.props.graphprops.fromedit === undefined ? true : true
    });
  }
  getOption = () => {
    let theme = this.props.selectedThemeing;
    let { graphprops } = this.props;
    return {
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          borderWidth: 1,
          borderColor: '#464646'
        }
      },
      toolbox: {
        showTitle: true,
        itemSize: 25,
        feature: {
          saveAsImage: {
            // backgroundColor: "black",
            title: 'download',
            backgroundColor: theme === 'lightTheme' ? 'white' : 'black',
            name: graphprops && graphprops.title ? graphprops.title : '',
            iconStyle: {
              color: 'none',
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      }
    };
  };
  getInitialState = () => ({ option: this.getOption() });

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  getData = async () => {
    let { graphprops } = this.props;
    let json = [];
    let series = [];
    let { errorInAPI, noDataInDB } = this.state;
    this.requestCheck = false;
    errorInAPI = false;
    noDataInDB = false;

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = arrayOfObjects;
      this.requestCheck = true;
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection({
          graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          selectionDateLive: this.props.selectionDateLive,
          currentTimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      }
    }
    json =
      json &&
      Array.isArray(json) &&
      json.map((jsonData) => {
        // jsonData.tagvalue = (Math.random() * 100).toFixed(2) - 0;
        return {
          name: jsonData.Name,
          value: jsonData.Value
        };
      });

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      const option = cloneDeep(this.state.option);
      if (json && Array.isArray(json) && json[0]) {
        series.push({
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          center: ['50%', '50%'],
          radius: '80%',
          min: 0,
          max: 360,
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              width: 18,
              color: [
                [0.25, '#a2aeb6'],
                [0.5, '#a2aeb6'],
                [0.75, '#a2aeb6'],
                [1, '#a2aeb6']
              ]
            }
          },
          axisTick: {
            show: false
          },
          pointer: {
            offsetCenter: [0, 0],
            icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
            length: '80%',
            itemStyle: {
              color: '#f50505 '
            }
          },
          axisLabel: {
            color: '#ffffff',
            fontSize: 20,
            distance: -50,
            rotate: 'circular',
            formatter: function (value) {
              if (value === 360) {
                return 'N';
              } else if (value === 90) {
                return 'E';
              } else if (value === 270) {
                return 'W';
              } else if (value === 180) {
                return 'S';
              }
              return '';
            }
          },
          title: {
            offsetCenter: [0, '-10%'],
            fontSize: 25
          },
          detail: {
            fontSize: 30,
            fontWeight: 'smoothConstraint',
            offsetCenter: [0, '10%'],
            valueAnimation: true,
            formatter: function (value) {
              return Math.round(value * 1) + ' °';
            },
            unit: {
              fontSize: 50,
              color: '#ffffff',
              offsetCenter: [0, '0%']
            },
            color: '#ffffff'
          },
          data: [json[0] && json[0] ? json[0].value : {}]
        });
      }

      option.series = series;

      this.setState({ option, refreshDateTime: moment() });
    }

    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            id={Math.ceil(Math.random(100, 100000) * 1000)}
            option={this.state.option}
            style={{
              height: '90%',
              width: '100%'
            }}
            className="react_for_echarts"
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(CompassGuage);
