import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  Table,
  Input,
  Button,
  Tooltip,
  message,
  InputNumber,
  Modal,
  Form,
  Row,
  Anchor,
  DatePicker
} from 'antd';

import SelectComponent from './SelectComponent';
import localStorage from '../../../../utils/localStorage';
import FileBase64 from './FileBase64';
import { deleteFileUpload } from '../eLogCalls';
import { LOGBOOK } from '../../../../commons/api';
import styles from '../CSS/style.less';
import { lightTheme } from '../CSS/style.js';
import 'antd/lib/date-picker/style/css';
import '../CSS/eLog.css';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
const newLocal = true;
const { Link } = Anchor;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

class TableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: props.form || {},
      columns: props.form && props.form.columns ? props.form.columns : [],
      data: props.form && props.form.datasource ? props.form.datasource : [],
      modal: props.form && props.form.modal ? props.form.modal : [],
      mainHeaderValue: props.mainHeaderValue ? props.mainHeaderValue : {},
      subHeaderValue: props.subHeaderValue ? props.subHeaderValue : {},
      visible: false
    };
  }

  componentDidMount() {
    let columns = this.generateColumns(
      this.props.form && this.props.form.columns ? this.props.form.columns : [],
      this.props.mainHeaderValue ? this.props.mainHeaderValue : {},
      this.props.subHeaderValue ? this.props.subHeaderValue : {}
    );
    this.setState({
      form: this.props.form,
      columns,
      data: this.props.form && this.props.form.datasource ? this.props.form.datasource : [],
      modal: this.props.form && this.props.form.modal ? this.props.form.modal : []
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.form !== this.props.form ||
      prevProps.mainHeaderValue !== this.props.mainHeaderValue ||
      prevProps.subHeaderValue !== this.props.subHeaderValue
    ) {
      let columns = this.generateColumns(
        this.props.form.columns,
        this.props.mainHeaderValue,
        this.props.subHeaderValue
      );
      this.setState({
        form: this.props.form,
        columns,
        data: this.props.form && this.props.form.datasource ? this.props.form.datasource : [],
        modal: this.props.form && this.props.form.modal ? this.props.form.modal : []
      });
    }
  }

  generateColumns = (columns) => {
    let finalColumns = [];
    let { translationCache } = this.props;
    if (columns && columns.length > 0) {
      finalColumns = columns.map((column) => {
        return {
          title:
            translationCache && translationCache[column.title] && translationCache[column.title]
              ? translationCache[column.title]
              : column.title,
          dataIndex: column.key || 0,
          key: column.key || 0,
          width: column.width,
          widget: column.widget,
          required: column.required,
          table: column.table || {},
          render: (text) => {
            if (Array.isArray(text)) {
              return text.map((file, index) => {
                return (
                  <div key={index}>
                    <Link href={file.url} title={file.name} target="_blank" />
                  </div>
                );
              });
            } else {
              return text;
            }
          }
        };
      });
    }
    return finalColumns;
  };

  deleteFile = async (file) => {
    if (file && file.response && file.response.name) {
      await deleteFileUpload(file.response.name);
      message.success('File Deleted');
    } else {
      message.error('Error deleting file');
    }
  };

  onFileChange = (info, column, text, record) => {
    if (info.file.status === 'done') {
      let fieldValue = [];
      if (text && Array.isArray(text) && text !== undefined) {
        fieldValue = [...text, info.file.response];
      } else if (!text) {
        fieldValue = [info.file.response];
      }
      this.handleFieldChange(fieldValue, column.key, record.key);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    } else if (info.file.status === 'removed') {
      this.deleteFile(info.file);
      this.handleFieldChange(info.fileList, column.key, record.key);
    }
  };

  saveRow(e, key, record) {
    let { columns } = this.state;
    e.persist();
    this.setState({
      loading: true
    });
    if (this.clickedCancel) {
      this.clickedCancel = false;
      return;
    }
    const target = this.getRowByKey(key) || {};
    let checkReqField = false;
    columns &&
      Array.isArray(columns) &&
      columns.map((columnsObject) => {
        if (
          columnsObject['required'] &&
          (columnsObject['required'] === 'true' || columnsObject['required'] === true)
        ) {
          if (
            target[columnsObject.key] ||
            (target[columnsObject.key] === 0 &&
              (target[columnsObject.key] !== '' || (record && record.widget === false)))
          ) {
            return {};
          } else {
            e.target.focus();
            checkReqField = true;
          }
        }
        return {};
      });
    if (checkReqField) {
      message.warn('Please fill in the complete information');
    } else {
      this.saveToggleEditable(e, key);
      this.setState({
        loading: false
      });
    }
  }

  editRow(key) {
    this.toggleEditable(key);
  }

  cancel(e, key) {
    this.clickedCancel = true;
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (this.cacheOriginData !== undefined) {
      if (this.cacheOriginData[key]) {
        Object.assign(target, this.cacheOriginData[key]);
        delete this.cacheOriginData[key];
      }
    }
    target.editable = false;
    this.setState({ data: newData });
    this.clickedCancel = false;
  }

  getRowByKey(key, newData) {
    const { data } = this.state;
    return (newData || data).filter((item) => item.key === key)[0];
  }

  saveToggleEditable = (e, key) => {
    e.preventDefault();
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      if (!target.editable && this.cacheOriginData) {
        this.cacheOriginData[key] = { ...target };
      }
      target.editable = !target.editable;
      this.setState({ data: newData });
      this.props.saveTableViewData(newData);
      //  Save to Backend
    }
  };

  toggleEditable = (key) => {
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      target.editable = !target.editable;
      this.setState({ data: newData });
      this.props.saveTableViewData(newData);
    }
  };

  remove(key) {
    const { data } = this.state;
    if (key.isNew) {
      const newData = data.filter((item) => item.key !== key.key);
      this.setState({ data: newData });
      //Delete BAckend Call
    } else {
      message.error('Cant delete this record..');
    }
  }

  handleKeyPress(e, key) {
    if (e.key === 'Enter') {
      this.saveRow(e, key);
    }
  }

  inputNumberChange = (minValue, maxValue, value, key, record) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      maxValue !== undefined
    ) {
      this.handleFieldChange(value, key, record);
    } else if (minValue === undefined && maxValue === undefined) {
      this.handleFieldChange(value, key, record);
    } else {
      this.handleFieldChange('', key, record);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };

  handleFieldChange = async (fieldValue, fieldName, keyValue) => {
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(keyValue, newData);
    if (fieldName === 'ItemCode') {
      let found = newData.some((el) => el.ItemCode === fieldValue);
      if (found) {
        message.warn('ItemCode Must be Unique');
        return;
      }
    }
    if (fieldValue && Array.isArray(fieldValue)) {
      fieldValue = fieldValue[fieldValue.length - 1];
    }

    if (target) {
      target[fieldName] = fieldValue;
      if (fieldName === 'Quantity' || fieldName === 'M.C %') {
        if (
          newData[keyValue - 1]['Quantity'] !== undefined &&
          newData[keyValue - 1]['M.C %'] !== undefined
        ) {
          if (newData[keyValue - 1]['Quantity'] !== '' && newData[keyValue - 1]['M.C %'] !== '') {
            target['Dry Weight'] = parseFloat(
              newData[keyValue - 1]['Quantity'] -
                newData[keyValue - 1]['Quantity'] * newData[keyValue - 1]['M.C %']
            ).toFixed(3);
          }
        }
      }
      if (fieldName === 'ApplicationType') {
        const accessToken = localStorage.get('accessToken');
        let obj = {
          method: 'POST',
          url: `${LOGBOOK.DropDown}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            tableName: 'master."Master_ThermoCoupleNumber"',
            columnName: 'Temperature',
            condition: 'Number',
            conditionvalue: fieldValue
          }
        };

        let data = await axios(obj);

        target['Standard_Temp'] = data.data[0];
      }
      this.setState({ data: newData });
    }
  };

  handleDataChange = (fieldValue, fieldName) => {
    const { data } = this.state;
    const newData = data.map((item) => ({ ...item }));
    if (newData) {
      newData.map((item, key) => {
        newData[key][fieldName] = fieldValue;
        return {};
      });
      this.setState({ data: newData });
    }
    this.props.saveTableViewData(newData);
  };

  addRow = () => {
    let { data, modal } = this.state;
    let newdata = {};
    modal &&
      Array.isArray(modal) &&
      modal.map((columnData) => {
        if (columnData.key === 'Task') {
          let maxTask =
            data && Array.isArray(data) && data.length > 0
              ? Math.max.apply(
                  Math,
                  data.map(function (o) {
                    return o.Task;
                  })
                )
              : 0;
          newdata[columnData.key] = maxTask + 10;
        } else {
          newdata[columnData.key] = '';
        }
        return {};
      });
    // if(){
    newdata = {
      ...newdata,
      AttachmentName: '',
      AttachmentSize: '',
      AttachmentType: '',
      Base64: ''
    };
    // }

    data.push(newdata);
    this.setState({
      data
    });
    this.props.saveTableViewData(data);
  };

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };

  renderSwitch(formObject, columns) {
    const { editObject } = this.state;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{
              width: 300,
              ...(this.props.status === 'Completed'
                ? {
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }
                : {})
            }}
            value={editObject && editObject[columns.key] ? editObject[columns.key] : ''}
            maxLength={columns && columns.maxLength ? columns.maxLength : 100}
            disabled={columns.disabled ? columns.disabled : false}
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputChange(e.target.value, columns.key, columns)}
          />
        );
      case 'select':
        return (
          <SelectComponent
            column={columns}
            record={{ key: columns }}
            text={editObject && editObject[columns.key] ? editObject[columns.key] : undefined}
            datasource={editObject && editObject ? editObject : []}
            handleFieldChange={(e) => this.onInputChange(e, columns.key, columns)}
            handleKeyPress={this.handleKeyPress}
            status={this.props.status}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{
              width: 300,
              ...(this.props.status === 'Completed'
                ? {
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }
                : {})
            }}
            value={
              editObject && editObject && (editObject[columns.key] || editObject[columns.key] === 0)
                ? editObject[columns.key]
                : ''
            }
            disabled={columns.disabled ? columns.disabled : false}
            placeholder={columns.placeHolder}
            min={columns.min ? columns.min : 0}
            max={columns.max ? columns.max : 1000000}
            onChange={(e) => this.onInputChange(e, columns.key)}
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            disabledDate={this.disabledDate}
            value={
              editObject && editObject && editObject[columns.key]
                ? moment(editObject[columns.key], dateFormat)
                : this.props.elog &&
                  this.props.elog.logbookID &&
                  this.props.elog.logbookID === 'MC-08 Sorting-Packing Production Logbook'
                ? moment(editObject[columns.key], dateFormat)
                : null
            }
            format={columns.showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
            allowClear={false}
            showNow={false}
            showTime={columns.showTime ? columns.showTime : false}
            disabled={columns.disable ? columns.disable : false}
            onChange={(e) => this.onInputChange(e, columns.key, columns)}
            style={{
              width: 300,
              ...(this.props.status === 'Completed'
                ? {
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }
                : {})
            }}
          />
        );
      case 'date-time picker':
        return (
          <DatePicker
            style={{
              width: 300,
              ...(this.props.status === 'Completed'
                ? {
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }
                : {})
            }}
            disabled={
              columns.key &&
              columns.key === 'ActualFinish' &&
              editObject &&
              !editObject['ActualStart']
                ? true
                : false
            }
            allowClear={false}
            showTime={columns.showTime ? columns.showTime : false}
            onChange={(e) => this.onInputChange(e, columns.key, columns)}
            value={
              editObject && editObject && editObject[columns.key]
                ? columns.showTime && columns.showTime === true
                  ? moment.utc(editObject[columns.key], dateFormat).local()
                  : moment.utc(editObject[columns.key], 'YYYY-MM-DD').local()
                : this.props.elog &&
                  this.props.elog.logbookID &&
                  this.props.elog.logbookID === 'MC-08 Sorting-Packing Production Logbook'
                ? moment(editObject[columns.key], 'YYYY-MM-DD HH:mm:ss')
                : null
            }
            disabledTime={(e) => this.getDisabledHours(moment.utc(e, dateFormat), columns.key)}
            disabledDate={(d) =>
              !d ||
              d.isAfter(
                columns.key === 'ActualStart' && editObject['ActualFinish']
                  ? moment(editObject['ActualFinish']).add(1, 'day').format('YYYY-MM-DD')
                  : ''
              ) ||
              d.isBefore(
                columns.key === 'ActualFinish' && editObject['ActualStart']
                  ? moment(editObject['ActualStart']).format('YYYY-MM-DD')
                  : ''
              ) ||
              d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
            }
            format={columns.showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
            onOk={(e) => this.onMainOk(e, columns.key, columns)}
          />
        );
      case 'timePicker':
        return (
          <Input
            format="HH:mm:ss"
            value={
              editObject &&
              editObject[columns.key] !== '' &&
              (editObject[columns.key] || editObject[columns.key] === 0)
                ? editObject[columns.key]
                : ''
            }
            style={{
              width: 300,
              ...(this.props.status === 'Completed'
                ? {
                    opacity: newLocal ? 0.75 : 1,
                    pointerEvents: newLocal ? 'none' : 'initial'
                  }
                : {})
            }}
            disabled={columns.disable ? columns.disable : false}
            disabledHours={() => this.getDisabledHours(columns.key)}
            onChange={(e, string) => this.onInputChange(string, columns.key, columns)}
          />
        );

      case 'imageUploadBase64':
        return (
          <span>
            <span
              style={{
                ...(this.props.status === 'Completed'
                  ? {
                      opacity: newLocal ? 0.75 : 1,
                      pointerEvents: newLocal ? 'none' : 'initial'
                    }
                  : {})
              }}
            >
              <FileBase64
                id={editObject && editObject.Id ? editObject.Id : editObject}
                type="file"
                multiple={false}
                onDone={(file) => {
                  let { editObject } = this.state;
                  if (file && file.type !== 'image/svg+xml') {
                    this.setState({
                      editObject: {
                        ...editObject,
                        AttachmentName: file.name,
                        AttachmentSize: file.size,
                        AttachmentType: file.type,
                        Base64: file.base64
                      }
                    });
                  } else {
                    message.error('SVG File is not allowed');
                  }
                }}
              />
            </span>
            {editObject && editObject['AttachmentName'] && editObject.Base64 ? (
              <a
                style={{ color: '#062d57' }}
                href={editObject.Base64}
                target="_blank"
                rel="noopener noreferrer"
              >
                {editObject.AttachmentName}
              </a>
            ) : null}
          </span>
        );
      case 'text':
        return <div></div>;
      default:
        return <div></div>;
    }
  }

  onInputChange = (columnValue, columnKey, columns) => {
    let { editObject } = this.state;
    let EstimatedDuration = null;
    if (columns && columns.key === 'EndTime' && editObject['StartTime']) {
      let startTime = editObject['StartTime'];
      let endTime = columnValue;
      EstimatedDuration = moment
        .utc(moment(endTime, 'HH:mm:ss').diff(moment(startTime, 'HH:mm:ss')))
        .format('HH:mm:ss');
    } else if (columns && columns.key === 'StartTime' && editObject['EndTime']) {
      let endTime = editObject['EndTime'];
      let startTime = columnValue;
      EstimatedDuration = moment
        .utc(moment(endTime, 'HH:mm:ss').diff(moment(startTime, 'HH:mm:ss')))
        .format('HH:mm:ss');
    } else if (columnKey && (columnKey === 'ActualStart' || columnKey === 'ActualFinish')) {
      columnValue = moment(columnValue).utc().format('YYYY-MM-DD');
    }
    editObject = {
      ...editObject,
      EstimatedDuration: EstimatedDuration ? EstimatedDuration : editObject.EstimatedDuration,
      [`${columnKey}`]: columnValue
    };
    this.setState({
      editObject
    });
  };
  getDisabledHours = (e, key) => {
    let { editObject } = this.state;
    let hours = [];
    let minutes = [];
    let ActualStartDateTime = '';
    let ActualFinishDateTime = '';

    let ActualStartDate = '';
    let ActualFinishDate = '';

    if (key === 'ActualStart') {
      ActualStartDateTime = moment(e).format('YYYY-MM-DD HH:mm');
      ActualFinishDateTime = moment
        .utc(editObject && editObject.ActualFinish, 'YYYY-MM-DD HH:mm')
        .local();

      ActualStartDate = moment(e).format('YYYY-MM-DD');
      ActualFinishDate = moment
        .utc(editObject && editObject.ActualFinish)
        .local()
        .format('YYYY-MM-DD');
    } else if (key === 'ActualFinish') {
      ActualStartDateTime = moment
        .utc(editObject && editObject.ActualStart, 'YYYY-MM-DD HH:mm')
        .local();
      ActualFinishDateTime = moment(e).format('YYYY-MM-DD HH:mm');

      ActualStartDate = moment
        .utc(editObject && editObject.ActualStart)
        .local()
        .format('YYYY-MM-DD');
      ActualFinishDate = moment(e).format('YYYY-MM-DD');
    }
    if (
      key === 'ActualStart' &&
      moment(ActualStartDate).isSame(
        moment(ActualFinishDate) ||
          moment(ActualStartDateTime).hours() === moment(ActualFinishDateTime).hours()
      )
    ) {
      for (var i = 0; i <= 24; i++) {
        if (i > moment(ActualFinishDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(ActualStartDate).isSame(moment(ActualFinishDate))) {
        for (let i = 0; i <= 60; i++) {
          if (i > moment(ActualFinishDateTime).minutes() - 1) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    } else if (
      key === 'ActualFinish' &&
      moment(ActualStartDate).isSame(
        moment(ActualFinishDate) ||
          moment(ActualStartDateTime).hours() === moment(ActualFinishDateTime).hours()
      )
    ) {
      for (let i = 0; i < moment(ActualStartDateTime).hours(); i++) {
        if (i < 25) {
          hours.push(i);
        }
      }

      if (moment(ActualStartDate).isSame(moment(ActualFinishDate))) {
        for (let i = 0; i < moment(ActualStartDateTime).minutes(); i++) {
          if (i < 61) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    }
  };

  onOk = (columnValue, columnKey) => {
    let startDate = moment(columnValue[0]).format('YYYY-MM-DD HH:mm:ss');
    let endDate = moment(columnValue[1]).format('YYYY-MM-DD HH:mm:ss');
    let { editObject } = this.state;
    editObject = {
      ...editObject,
      [columnKey]: {
        startDate,
        endDate
      }
    };
    this.setState({
      editObject
    });
  };

  onMainOk = (columnValue, columnKey) => {
    let { editObject } = this.state;
    let EstimatedDuration = null;
    if (columnKey && columnKey === 'ActualFinish' && editObject['ActualStart']) {
      let startTime = moment
        .utc(editObject && editObject.ActualStart)
        .local()
        .format('YYYY-MM-DD HH:mm');
      let endTime = moment(columnValue).format('YYYY-MM-DD HH:mm');
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    } else if (columnKey && columnKey === 'ActualStart' && editObject['ActualFinish']) {
      let endTime = moment
        .utc(editObject && editObject.ActualFinish)
        .local()
        .format('YYYY-MM-DD HH:mm');
      let startTime = moment(columnValue).format('YYYY-MM-DD HH:mm');
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm');
    }
    editObject = {
      ...editObject,
      EstimatedDuration: EstimatedDuration ? EstimatedDuration : editObject.EstimatedDuration,
      [`${columnKey}`]: moment(columnValue).utc().format('YYYY-MM-DD HH:mm')
    };
    this.setState({
      editObject
    });
  };

  render() {
    let { recordType, loading, showAddButton, form, translationCache, status } = this.props;
    let { columns, data } = this.state;
    if (recordType !== 'Approver') {
      columns = [
        ...columns,
        {
          title:
            translationCache && translationCache['Action'] && translationCache['Action']
              ? translationCache['Action']
              : 'Action',
          key: 'action',
          render: (text, record, index) => {
            if (!!record.editable && loading) {
              return null;
            }
            if (record.editable) {
              if (record.isNew) {
                return (
                  <span>
                    <Tooltip placement="top" trigger="hover" title={'Save'}>
                      <Button onClick={(e) => this.saveRow(e, record.key, record)}>
                        <SaveOutlined style={{ fontSize: '20px', color: '#193652' }} type="save" />
                      </Button>
                    </Tooltip>
                  </span>
                );
              }
              return (
                <span>
                  <Tooltip placement="top" trigger="hover" title={'Save'}>
                    <Button onClick={(e) => this.saveRow(e, record.key, record)}>
                      <EditOutlined style={{ fontSize: '20px', color: '#193652' }} type="save" />
                    </Button>
                  </Tooltip>
                </span>
              );
            } else {
              return (
                <span>
                  <Tooltip placement="top" trigger="hover" title={'Edit'}>
                    <Button
                      onClick={() =>
                        this.setState({
                          visible: true,
                          editObject: record,
                          editIndex: index
                        })
                      }
                    >
                      <EditOutlined style={{ fontSize: '20px', color: '#193652' }} type="edit" />
                    </Button>
                  </Tooltip>
                </span>
              );
            }
          }
        }
      ];
    }
    return (
      <div className="tableView" style={{ marginTop: '15px' }}>
        <div>
          <Table
            theme={lightTheme}
            className="TableView"
            loading={loading}
            columns={columns}
            dataSource={[...(data ? data : [])]}
            pagination={true}
            style={{ marginTop: '10px', border: '1px solid red !important' }}
            rowClassName={(record) => (record.editable ? styles.editable : '')}
          />
          {showAddButton ? (
            <Button
              type="primary"
              style={{
                marginTop: '10px',
                ...(status === 'Completed'
                  ? {
                      opacity: newLocal ? 0.75 : 1,
                      pointerEvents: newLocal ? 'none' : 'initial'
                    }
                  : {})
              }}
              onClick={this.addRow}
            >
              {translationCache && translationCache['Add Row'] && translationCache['Add Row']
                ? translationCache['Add Row']
                : 'Add Row'}
            </Button>
          ) : null}
        </div>

        <Modal
          title="Update"
          visible={this.state.visible}
          width={1200}
          onOk={() => {
            let { form } = this.props;
            let { editObject, editIndex } = this.state;
            let saveObject = [...form.datasource];
            saveObject[editIndex] = editObject;
            this.props.saveTableViewData(saveObject);
            this.setState({
              visible: false,
              editObject: {}
            });
          }}
          onCancel={() => {
            this.setState({
              visible: false,
              editObject: {}
            });
          }}
        >
          <Form {...formItemLayout}>
            {form &&
              form.modal &&
              form.modal.map((modalForm, index) => {
                return (
                  <Form.Item
                    key={index}
                    required={false}
                    label={
                      translationCache && translationCache[modalForm.displayName]
                        ? translationCache[modalForm.displayName]
                        : modalForm.displayName
                    }
                  >
                    <Row>{this.renderSwitch(form, modalForm)}</Row>
                  </Form.Item>
                );
              })}
            {/* </Row> */}
          </Form>
        </Modal>
      </div>
    );
  }
}

export default TableView;
