import React, { Fragment, useState } from 'react';
import { message } from 'antd';

import ViewDashboard from '../../DashboardComponents/Dashboard';
import HierarchyDropdownSelection from '../../DashboardComponents/Widgets/Selection/HierarchyDropdownSelection';

const MultilevelDashboard = (props) => {
  const [value, setValue] = useState('Global');
  const [selectedObject, setSelectedobject] = useState('');
  const [currentDashboard, setCurrentDashboard] = useState('');
  let dashboards = props.dashboards || {};
  return (
    <div
      style={{
        minHeight: window.innerHeight - 143,
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        background: '#1a3652'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        {dashboards &&
        dashboards.headerJSON &&
        dashboards.headerJSON.dashboardType === 'hierarchy' &&
        dashboards.headerJSON.hierarchyViewType === 'Select' ? (
          <div>
            <HierarchyDropdownSelection
              value={value}
              headerJSON={dashboards.headerJSON}
              handleDropdownChange={(value, selectedObject) => {
                if (
                  dashboards &&
                  dashboards.headerJSON &&
                  dashboards.headerJSON.multiLevelSettings &&
                  Array.isArray(dashboards.headerJSON.multiLevelSettings)
                ) {
                  let filterObject = dashboards.headerJSON.multiLevelSettings.filter(
                    (e) => e.LevelType === selectedObject.LevelType
                  );
                  if (filterObject && filterObject[0]) {
                    setValue(value);
                    setSelectedobject(selectedObject.title);
                    setCurrentDashboard(filterObject[0].DashboardName);
                  } else {
                    message.info('Select type dashboard is not configured');
                  }
                }
              }}
            />
          </div>
        ) : null}
      </div>
      <Fragment>
        <div style={{ background: 'rgb(26, 54, 82)' }}>
          {value ? (
            <div
              style={{
                color: '#f8a000',
                fontSize: '16px',
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                padding: '10px 0px'
              }}
            >
              {selectedObject ? selectedObject : value}
            </div>
          ) : null}
          <ViewDashboard
            triggerPoint="custom"
            match={{
              params: {
                name: currentDashboard,
                deviceCode: value
              }
            }}
          />
        </div>
      </Fragment>
    </div>
  );
};
export default MultilevelDashboard;
