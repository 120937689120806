import React, { Component } from 'react';
import axios from 'axios';
import { Select, Cascader } from 'antd';

import localStorage from '../../../../utils/localStorage';
import { LOGBOOK } from '../../../../commons/api';
const { Option } = Select;

class TreeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      conditionList: []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.text !== this.props.text) {
      this.setState({
        text: this.props.text
      });
    }

    if (
      prevprops.datasource !== this.props.datasource ||
      prevprops.subdatasource !== this.props.subdatasource
    ) {
      let { column, datasource, subdatasource } = this.props;
      if (column && column.table && column.table.payload && column.table.payload.conditionList) {
        //Condition based filter option
        let conditionList = column.table.payload.conditionList;
        let dataSourceKeys = Object.keys(datasource);
        let subdataSourceKeys =
          subdatasource && subdatasource !== undefined && Object.keys(subdatasource);

        conditionList &&
          Array.isArray(conditionList) &&
          conditionList.map((condition, key) => {
            if (condition.referenceKey && dataSourceKeys.indexOf(condition.referenceKey) > -1) {
              conditionList[key].value = datasource[condition.referenceKey];
            } else if (
              condition.referenceKey &&
              subdataSourceKeys &&
              subdataSourceKeys.indexOf(condition.referenceKey) > -1
            ) {
              conditionList[key].value = subdatasource[condition.referenceKey];
            }
            return {};
          });
        this.setState({
          conditionList
        });
        this._getOptionList(this.props.column, conditionList);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextPrev) {
    let siteId = localStorage.get('currentSite');
    if (nextPrev.column.hierarchy && nextPrev.column.hierarchy === true) {
      this.setState({
        optionList:
          this.props.userProfile &&
          this.props.userProfile.belongsTo &&
          this.props.userProfile.belongsTo.sites &&
          this.props.userProfile.belongsTo.sites[siteId] &&
          this.props.userProfile.belongsTo.sites[siteId].hierarchy
      });
    } else if (Array.isArray(nextPrev.column.table)) {
      if (nextPrev.column.table !== this.state.optionList) {
        this.setState({ optionList: nextPrev.column.table });
      }
    }
  }

  componentDidMount() {
    let { column, datasource, subdatasource } = this.props;
    if (
      column &&
      datasource &&
      column.table &&
      column.table.payload &&
      column.table.payload.conditionList
    ) {
      //Condition based filter option
      let conditionList = column.table.payload.conditionList;
      let dataSourceKeys = Object.keys(datasource);
      let subdataSourceKeys =
        subdatasource && subdatasource !== undefined && Object.keys(subdatasource);
      conditionList &&
        Array.isArray(conditionList) &&
        conditionList.map((condition, key) => {
          if (condition.referenceKey && dataSourceKeys.indexOf(condition.referenceKey) > -1) {
            conditionList[key].value = datasource[condition.referenceKey];
          } else if (
            condition.referenceKey &&
            subdataSourceKeys &&
            subdataSourceKeys.indexOf(condition.referenceKey) > -1
          ) {
            conditionList[key].value = subdatasource[condition.referenceKey];
          }
          return {};
        });
      this.setState({
        conditionList
      });
      this._getOptionList(this.props.column, conditionList);
    } else {
      this._getOptionList(this.props.column);
    }
  }

  _getOptionList = (tableName, conditionList) => {
    // let { conditionList } = this.state
    let siteId = localStorage.get('currentSite');
    if (tableName.hierarchy && tableName.hierarchy === true) {
      this.setState({
        optionList:
          this.props.userProfile &&
          this.props.userProfile.belongsTo &&
          this.props.userProfile.belongsTo.sites &&
          this.props.userProfile.belongsTo.sites[siteId] &&
          this.props.userProfile.belongsTo.sites[siteId].hierarchy
      });
    } else if (!tableName.hierarchy) {
      const accessToken = localStorage.get('accessToken');
      let objectHeader = {};
      if (tableName && tableName.table && Array.isArray(tableName.table)) {
        //Direct OPtions Array
        this.setState({
          optionList: tableName.table
        });
        return tableName.table;
      }
      if (tableName && tableName.table && tableName.table.url && !tableName.table.method) {
        objectHeader = {
          //URL,GET
          method: 'GET',
          url: `${tableName.table.url}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        };
      } else if (
        tableName &&
        tableName.table &&
        tableName.table.url &&
        tableName.table.method &&
        tableName.table.payload &&
        tableName.table.method === 'POST'
      ) {
        objectHeader = {
          //URL,POST
          method: tableName.table.method,
          url: `${tableName.table.url}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            ...tableName.table.payload,
            conditionList: conditionList ? conditionList : []
          }
        };
      } else {
        //Table Name
        objectHeader = {
          method: 'POST',
          url: `${LOGBOOK.DropDown}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            tableName: `master."${tableName.table}"`
          }
        };
      }
      return axios(objectHeader)
        .then((response) => {
          if (Array.isArray(response.data)) {
            let optionList = response.data;
            this.setState({
              optionList
            });
          }
        })
        .catch(() => {
          return [];
        });
    }
  };

  render() {
    let { column, text, record, data } = this.props;
    let { optionList } = this.state;
    let filterListed = [];
    if (
      column &&
      column.validation &&
      column.validation.duplicate &&
      column.validation.duplicate === true &&
      !column.validation.conditionFilter
    ) {
      if (data && data !== undefined && Array.isArray(data)) {
        // If datasource is Array
        filterListed = data.map((da) => {
          return da[column.key];
        });
      } else if (data && data !== undefined && Object.is(data, data)) {
        // If datasource is object
        filterListed = Object.keys(data).map((da) => {
          return data[da][column.key];
        });
      }
    } else {
      if (column.validation && column.validation.conditionFilter && this.props.equipment) {
        // Based on colundition filter true or false
        // "validation": {   // format
        //     "duplicate": true,
        //     "conditionFilter": {
        //         "MTD": false,
        //         "MTC": true
        //     }
        // }
        let split = this.props.equipment.split(/[-]/);
        if (column.validation.conditionFilter[split[0]]) {
          //filter true

          if (data && data !== undefined && Array.isArray(data)) {
            // If datasource is Array when filter true
            filterListed = data.map((da) => {
              return da[column.key];
            });
          } else if (data && data !== undefined && Object.is(data, data)) {
            // If datasource is object  when filter true
            filterListed = Object.keys(data).map((da) => {
              return data[da][column.key];
            });
          }
        }
      }
    }
    if (column.hierarchy && column.hierarchy === true) {
      return (
        <Cascader
          options={optionList}
          showSearch
          value={text && text !== '' ? text : undefined}
          style={{ width: 300 }}
          onChange={(e) => {
            if (e && e.description) {
              this.props.handleFieldChange(e, column.key, record.key, e.description);
            } else if (e && e.children) {
              this.props.handleFieldChange(e, column.key, record.key, e.children);
            } else {
              this.props.handleFieldChange(e, column.key, record.key);
            }
          }}
          onKeyPress={(e) => this.props.handleKeyPress(e, record.key)}
          placeholder={column.placeHolder || column.displayName}
          disabled={column.disable ? column.disable : false}
        />
      );
    } else {
      return (
        <Select
          showSearch
          value={text && text !== '' ? text : undefined}
          style={{ width: 300 }}
          onChange={(e) => {
            if (e && e.description) {
              this.props.handleFieldChange(e, column.key, record.key, e.description);
            } else if (e && e.children) {
              this.props.handleFieldChange(e, column.key, record.key, e.children);
            } else {
              this.props.handleFieldChange(e, column.key, record.key);
            }
          }}
          onKeyPress={(e) => this.props.handleKeyPress(e, record.key)}
          placeholder={column.placeHolder || column.displayName}
          disabled={column.disable ? column.disable : false}
          // onSearch={this.onSearch}
          filterOption={(input, option) => {
            if (
              option.props &&
              option.props.children &&
              typeof option.props.children === 'string'
            ) {
              return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            } else {
              return true;
              // return option.props && option.props.children &&  option.props.children.indexOf(input) >= 0
            }
          }}
        >
          {optionList &&
            optionList
              .filter((col) => {
                if (
                  column &&
                  column.validation &&
                  column.validation.duplicate &&
                  column.validation.duplicate === true
                ) {
                  // optionsList is an Array of Objects
                  if (col && col.key && col.value) {
                    if (!(filterListed.indexOf(col.value) >= 0)) {
                      return col;
                    }
                  } else {
                    if (!(filterListed.indexOf(col) >= 0)) {
                      return col;
                    }
                  }
                } else {
                  // optionsList is an Array of strings
                  if (!(filterListed.indexOf(col) >= 0)) {
                    return col;
                  } else {
                    return col;
                  }
                }
                return {};
              })
              .map((data, index) => {
                if (typeof data === 'object' && data && (data.description || data.children)) {
                  return (
                    <Option key={index} value={data}>
                      {data.key}
                    </Option>
                  );
                } else if (typeof data === 'object' && data !== null) {
                  return (
                    <Option key={index} value={data.value}>
                      {data.key}
                    </Option>
                  );
                } else {
                  return (
                    <Option key={index} value={data}>
                      {data}
                    </Option>
                  );
                }
              })}
        </Select>
      );
    }
  }
}

export default TreeView;
