import React from 'react';

export default class FileBase64 extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      files: []
    };
  }

  handleChange = (e) => {
    let files = e.target.files;
    // Process each file
    var allFiles = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load somthing...

      reader.onload = () => {
        // Make a fileInfo Object

        let fileInfo = {
          name: file.name,

          type: file.type,

          size: Math.round(file.size / 1000) + ' kB',

          base64: reader.result,

          file: file
        };

        allFiles.push(fileInfo);

        if (allFiles.length === files.length) {
          this.props.onDone(allFiles[0]);
          this.myRef.current = '';
        }
      };
    }
  };

  render() {
    let { id } = this.props;
    return (
      <input
        key={id}
        type="file"
        // inputProps={{ accept: 'image/*' }}
        ref={this.myRef}
        onChange={this.handleChange}
      />
    );
  }
}

// FileBase64.defaultProps = {
//  multiple: false
// };
