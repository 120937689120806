export const heatmapCartesionMockJson = {
  '00:00:00': [
    {
      DateTime: '2022-09-25 00:00:00',
      Value: '3',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 00:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 00:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 00:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 00:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 00:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 00:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '01:00:00': [
    {
      DateTime: '2022-09-25 01:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 01:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 01:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 01:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 01:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 01:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 01:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '02:00:00': [
    {
      DateTime: '2022-09-25 02:00:00',
      Value: '2',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 02:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 02:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 02:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 02:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 02:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 02:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '03:00:00': [
    {
      DateTime: '2022-09-25 03:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 03:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 03:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 03:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 03:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 03:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 03:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '04:00:00': [
    {
      DateTime: '2022-09-25 04:00:00',
      Value: '2',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 04:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 04:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 04:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 04:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 04:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 04:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '05:00:00': [
    {
      DateTime: '2022-09-25 05:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 05:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 05:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 05:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 05:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 05:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 05:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '06:00:00': [
    {
      DateTime: '2022-09-25 06:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 06:00:00',
      Value: '2',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 06:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 06:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 06:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 06:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 06:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '07:00:00': [
    {
      DateTime: '2022-09-25 07:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 07:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 07:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 07:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 07:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 07:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 07:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '08:00:00': [
    {
      DateTime: '2022-09-25 08:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 08:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 08:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 08:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 08:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 08:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 08:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '09:00:00': [
    {
      DateTime: '2022-09-25 09:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 09:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 09:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 09:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 09:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 09:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 09:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '10:00:00': [
    {
      DateTime: '2022-09-25 10:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 10:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 10:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 10:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 10:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 10:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 10:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '11:00:00': [
    {
      DateTime: '2022-09-25 11:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 11:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 11:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 11:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 11:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 11:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 11:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '12:00:00': [
    {
      DateTime: '2022-09-25 12:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 12:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 12:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 12:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 12:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 12:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 12:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '13:00:00': [
    {
      DateTime: '2022-09-25 13:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 13:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 13:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 13:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 13:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 13:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 13:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '14:00:00': [
    {
      DateTime: '2022-09-25 14:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 14:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 14:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 14:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 14:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 14:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 14:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '15:00:00': [
    {
      DateTime: '2022-09-25 15:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 15:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 15:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 15:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 15:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 15:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 15:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '16:00:00': [
    {
      DateTime: '2022-09-25 16:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 16:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 16:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 16:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 16:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 16:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 16:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '17:00:00': [
    {
      DateTime: '2022-09-25 17:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 17:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 17:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 17:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 17:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 17:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 17:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '18:00:00': [
    {
      DateTime: '2022-09-25 18:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 18:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 18:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 18:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 18:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 18:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 18:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '19:00:00': [
    {
      DateTime: '2022-09-25 19:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 19:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 19:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 19:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 19:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 19:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 19:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '20:00:00': [
    {
      DateTime: '2022-09-25 20:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 20:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 20:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 20:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 20:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 20:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 20:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '21:00:00': [
    {
      DateTime: '2022-09-25 21:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 21:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 21:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 21:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 21:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 21:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 21:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '22:00:00': [
    {
      DateTime: '2022-09-25 22:00:00',
      Value: '1',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 22:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 22:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 22:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 22:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 22:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 22:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ],
  '23:00:00': [
    {
      DateTime: '2022-09-25 23:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-26 23:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-27 23:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-28 23:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-29 23:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-09-30 23:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    },
    {
      DateTime: '2022-10-01 23:00:00',
      Value: '0',
      Description: 'TIC301@@PV'
    }
  ]
};
export const heatmapCartesionMockJson1 = [
  {
    DateTime: '2022-09-25 00:00:00',
    Value: '3',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 00:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 00:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 00:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 00:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 00:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 00:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 01:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 01:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 01:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 01:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 01:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 01:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 01:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 02:00:00',
    Value: '2',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 02:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 02:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 02:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 02:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 02:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 02:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 03:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 03:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 03:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 03:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 03:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 03:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 03:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 04:00:00',
    Value: '2',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 04:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 04:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 04:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 04:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 04:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 04:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 05:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 05:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 05:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 05:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 05:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 05:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 05:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 06:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 06:00:00',
    Value: '2',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 06:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 06:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 06:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 06:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 06:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 07:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 07:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 07:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 07:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 07:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 07:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 07:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 08:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 08:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 08:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 08:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 08:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 08:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 08:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 09:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 09:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 09:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 09:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 09:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 09:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 09:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 10:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 10:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 10:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 10:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 10:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 10:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 10:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 11:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 11:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 11:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 11:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 11:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 11:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 11:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 12:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 12:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 12:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 12:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 12:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 12:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 12:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 13:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 13:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 13:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 13:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 13:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 13:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 13:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 14:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 14:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 14:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 14:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 14:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 14:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 14:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 15:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 15:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 15:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 15:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 15:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 15:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 15:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 16:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 16:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 16:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 16:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 16:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 16:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 16:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 17:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 17:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 17:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 17:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 17:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 17:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 17:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 18:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 18:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 18:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 18:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 18:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 18:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 18:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 19:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 19:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 19:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 19:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 19:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 19:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 19:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 20:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 20:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 20:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 20:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 20:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 20:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 20:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 21:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 21:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 21:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 21:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 21:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 21:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 21:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 22:00:00',
    Value: '1',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 22:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 22:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 22:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 22:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 22:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 22:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-25 23:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-26 23:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-27 23:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-28 23:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-29 23:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-09-30 23:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  },
  {
    DateTime: '2022-10-01 23:00:00',
    Value: '0',
    Description: 'TIC301@@PV'
  }
];
export const heatmapCartesionTableMock = [
  {
    Name: 'TS1',
    Description: 'TCMS',
    Value: 100,
    Action: [{ TimeStamp: '2023-10-20', Name: '123' }]
  },
  {
    Name: 'TS2',
    Description: 'TCMS',
    Value: 200
  },

  {
    Name: 'TS3',
    Description: 'TCMS',
    Value: 300
  },
  {
    Name: 'TS1',
    Description: 'HVAC',
    Value: 400,
    Action: [{ TimeStamp: '2023-10-20', Name: '12345' }]
  },
  {
    Name: 'TS2',
    Description: 'HVAC',
    Value: 500
  },

  {
    Name: 'TS3',
    Description: 'HVAC',
    Value: 600
  },

  {
    Name: 'TS4',
    Description: 'HVAC',
    Value: 700
  }
];
