import React, { Fragment } from 'react';
import moment from 'moment';
import { Form, Select, Button, Row, Col, message, DatePicker, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
  getSubDeviceList,
  getDeviceType,
  getPartsList,
  getEventsList,
  getPartLocation,
  eventCheck,
  postEventData
} from './ApiCalls';

import { StyledApplication1 } from '../style';
import './style.css';
const { Option } = Select;
const { confirm } = Modal;
const config = {
  rules: [
    {
      type: 'object',
      required: true,
      message: 'Please select time!'
    }
  ]
};
// moment(date[0]).format("YYYY-MM-DD HH:mm:ss")

class EquipmentEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceTypeList: [],
      deviceType: undefined,
      device: undefined,
      deviceList: [],
      subDeviceList: [],
      deviceCode: undefined,
      partsList: [],
      partId: undefined,
      locatedList: [],
      PartLocatedId: undefined,
      event: undefined,
      eventsList: [],
      PresentEvent: undefined,
      PresentStatus: undefined
    };
  }
  formRef = React.createRef();
  componentDidMount = async () => {
    try {
      let deviceType = await getDeviceType();
      this.setState({ deviceTypeList: deviceType.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  handelDeviceType = async (value) => {
    this.setState({ deviceType: value });
    try {
      let deviceList = await getSubDeviceList(value, 'Primary');
      this.setState({ deviceList: deviceList.data });
    } catch (err) {
      return {};
    }
  };
  handelDevice = async (value) => {
    this.setState({ device: value });
    try {
      let deviceList = await getSubDeviceList(this.state.deviceType, 'Secondary', value);
      this.setState({ subDeviceList: deviceList.data });
    } catch (err) {
      return {};
    }
  };
  handleSubDevice = async (value) => {
    this.setState({ deviceCode: value });
    try {
      let partsList = await getPartsList(value);
      this.setState({ partsList: partsList.data });
    } catch (err) {
      message.warn(`${err.message}`);
    }
  };
  handlePart = async (value) => {
    this.setState({ partId: value });
    try {
      let locatedList = await getPartLocation(value, this.state.deviceType, this.state.deviceCode);
      this.setState({ locatedList: locatedList.data });
    } catch (err) {
      message.warn(`${err.message}`);
    }
  };
  handleLocation = async (value) => {
    this.setState({ location: value });
    try {
      let locatedList = await getEventsList(value, this.state.partId);
      this.setState({
        eventsList: locatedList.data.Events,
        PresentStatus: locatedList.data.PresentStatus,
        PresentEvent: locatedList.data.PresentEvent
      });
    } catch (err) {
      message.warn(`${err.message}`);
    }
  };

  showConfirm = async () => {
    confirm({
      title: 'Are you sure to override the Event ? ',
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk: () => {},

      onCancel: () => {
        this.formRef.current.resetFields(['event']);
      }
    });
    // } else {
    //   message.error("Save all records before submiting e-log");
    // }
  };

  handleEvent = async (value) => {
    // this.setState({event:value})
    //  window.confirm("This is test")
    try {
      let eventValidation = await eventCheck(value, this.state.PresentStatus);
      if (eventValidation.data.message === 'Invalid') {
        this.showConfirm(value);
      } else {
        this.setState({ event: value });
      }
      //  this.setState({ eventsList:locatedList.data.Events});
    } catch (err) {
      message.warn(`${err.message}`);
    }
  };
  onReset = () => {
    this.formRef.current.resetFields();
  };

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };

  onFinish = async (values) => {
    try {
      await postEventData(values);
      this.setState({ deviceCode: undefined, deviceType: undefined, recordData: [] });
      this.onReset();
    } catch (err) {
      message.warn(`${err.message}`);
    }
  };
  handleChange = () => {};
  render() {
    let {
      deviceType,
      deviceTypeList,
      deviceList,
      deviceCode,
      subDeviceList,
      device,
      locatedList,
      partsList,
      eventsList,
      event
    } = this.state;
    //  let fields= recordData &&  Array.isArray(recordData)

    return (
      <StyledApplication1 style={{ minHeight: window.innerHeight - 170 }}>
        <Fragment>
          <Form ref={this.formRef} className="headerDiv" layout="vertical" onFinish={this.onFinish}>
            <Row>
              <Col span={5} offset={1}>
                <Form.Item label="Select Equipment Category">
                  <Select
                    placeholder="Select Equipment Category"
                    style={{ width: '100%' }}
                    value={deviceType}
                    onChange={this.handelDeviceType}
                  >
                    {deviceTypeList.map((param, index) => {
                      return (
                        <Option key={index} value={param.Id}>
                          {' '}
                          {param.Name}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5} offset={1}>
                <Form.Item label="Select Equipment ">
                  <Select
                    placeholder="Select Equipment "
                    style={{ width: '100%' }}
                    value={device}
                    onChange={this.handelDevice}
                  >
                    {deviceList.map((param, index) => {
                      return (
                        <Option key={index} value={param.Id}>
                          {' '}
                          {param.Name}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5} offset={1}>
                <Form.Item label="Select Sub Equipment">
                  <Select
                    placeholder="Select Equipment"
                    style={{ width: '100%' }}
                    value={deviceCode}
                    onChange={this.handleSubDevice}
                  >
                    {subDeviceList.map((param, index) => {
                      return (
                        <Option key={index} value={param.Id}>
                          {' '}
                          {param.Name}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5} offset={1}>
                <Form.Item label="Select Part">
                  <Select
                    placeholder="Select Equipment"
                    style={{ width: '100%' }}
                    onChange={this.handlePart}
                  >
                    {partsList.map((param, index) => {
                      return (
                        <Option key={index} value={param.Id}>
                          {' '}
                          {param.Name}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={5} offset={1}>
                <Form.Item label="Select Location" name="PartLocatedId">
                  <Select
                    placeholder="Select Location"
                    style={{ width: '100%' }}
                    onChange={this.handleLocation}
                  >
                    {locatedList.map((param, index) => {
                      return (
                        <Option key={index} value={param.Id}>
                          {' '}
                          {param.Located}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5} offset={1}>
                <Form.Item
                  label="Select Event"
                  name="EventId"
                  rules={[{ required: true, message: 'Please Select Event !' }]}
                >
                  <Select
                    placeholder="Event"
                    style={{ width: '100%' }}
                    value={event}
                    onChange={this.handleEvent}
                  >
                    {eventsList.map((param, index) => {
                      return (
                        <Option key={index} value={param.Id}>
                          {' '}
                          {param.Name}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5} offset={1}>
                <Form.Item label="Event Start Date & Time" name="StartTime" {...config}>
                  <DatePicker
                    style={{ width: '100%' }}
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
              </Col>
              <Col span={5} offset={1}>
                <Form.Item label="Event End Date & Time">
                  <DatePicker
                    style={{ width: '100%' }}
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
              </Col>
              <Col span={5} offset={1}>
                <Form.Item>
                  {' '}
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Fragment>
      </StyledApplication1>
    );
  }
}

export default EquipmentEvents;
