import { createSelector } from 'reselect';

const layoutList = (state) => {
  return state.get('layout').get('layout');
};

const getUserProfile = () =>
  createSelector(layoutList, (state) => {
    return state.get('loggedInProfile');
  });

const getMenuCollapse = () =>
  createSelector(layoutList, (state) => {
    return state.get('menuCollapse');
  });

const getCurrentSite = () =>
  createSelector(layoutList, (state) => {
    return state.get('currentSite');
  });

const getMenuHierarchy = () =>
  createSelector(layoutList, (state) => {
    return state.get('menuHierarchy');
  });

const getLandingPage = () =>
  createSelector(layoutList, (state) => {
    return state.get('landingPage');
  });

const getUserDetails = () =>
  createSelector(layoutList, (state) => {
    return state.get('UserDetails');
  });

const getCurrentTimezone = () =>
  createSelector(layoutList, (state) => {
    return state.get('currentTimeZone');
  });

const getDefaultHierarchy = () =>
  createSelector(layoutList, (state) => {
    return state.get('DefaultHierarchy');
  });

export {
  getUserProfile,
  getCurrentSite,
  getMenuHierarchy,
  getLandingPage,
  getUserDetails,
  getMenuCollapse,
  getCurrentTimezone,
  getDefaultHierarchy
};
