import React, { Component, Fragment } from 'react';
import MonacoEditor from 'react-monaco-editor';
import {
  Drawer,
  Input,
  Form,
  Row,
  Col,
  Select,
  message,
  Collapse,
  Upload,
  Button,
  Tabs
} from 'antd';
import {
  PlusOutlined,
  CaretRightOutlined,
  SearchOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import localStorage from '../../../utils/localStorage';
import { getTranslationCache } from '../../../selectors/language';
import { constant } from './constants';
import { getHMICalls } from '../hmicalls';
import { getUserDetails, getUserProfile } from '../../../selectors/layout';
import {
  StyledButton,
  StyledComponent,
  StyledContentRight,
  StyledTable
} from '../../../commons/styles/layout';
import Highlighter from 'react-highlight-words';
import { API_MASTERCONFIGURATION, API_HMI } from '../../../commons/api';
const customPanel = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden'
};
const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;

class HmiConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      theme: 'vs-dark',
      editObj: {},
      datasource: [],
      userId: props.userDeatils,
      userProfile: props.userProfile,
      translationCache: props.translationCache || [],
      editEditor: {},
      formObject1: {},
      imageDetails: {},
      imageUrl: '',
      imagebaseUrl: '',
      defaultFileList: [],
      progress: 0,
      loading: false,
      enable: false,
      HMITypeList: [],
      svgDimensions: { width: 0, height: 0 },
      lastClickedXY: { x: 0, y: 0 },
      activeDetails: '1'
    };
    this.hmiRef = React.createRef();
    this.editRef = React.createRef();
  }

  onClose = () => {
    if (this.hmiRef.current) {
      this.hmiRef.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {},
      imageUrl: '',
      imagebaseUrl: '',
      defaultFileList: []
    });
  };

  addNewHmi = () => {
    if (this.hmiRef.current) {
      this.hmiRef.current && this.hmiRef.current.resetFields();
    }
    this.setState({
      visible: true,
      editObj: {},
      listType: 'save',
      editEditor: {}
    });
  };

  componentDidMount() {
    this._getHmiList();
    this.getHMITypeAPIs();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.UserDetails !== this.props.UserDetails) {
      this.setState({
        userId: this.props.UserDetails
      });
    }
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  }

  _getHmiList = async () => {
    const siteId = localStorage.get('currentSite');
    let payload = {
      method: 'GET',
      url: `${API_HMI.GET}?SiteId=${siteId}`
    };
    let datasource = await getHMICalls(payload);
    if (datasource && datasource.message) {
      message.info(datasource.message);
    } else {
      this.setState({ datasource });
    }
  };

  getHMITypeAPIs = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const hmiMappingObject = {
      method: 'POST',
      url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { MasterCode: 'HMI_Types', ActionType: 'read', Type: 'WithoutSiteId' }
    };
    this.setState({ loading: true });
    axios(hmiMappingObject)
      .then((response) => {
        this.setState({
          HMITypeList: response.data || {}
        });
      })
      .catch(function () {});
  };

  editorDidMount = (editor) => {
    this.editor = editor;
  };

  hmiOperation = async (operation, userId) => {
    let { imageDetails, editObj } = this.state;

    let datasource = undefined;
    const siteId = localStorage.get('currentSite');

    if (operation === 'save') {
      this.hmiRef.current.validateFields().then(async (values) => {
        const model = this.monaco.editor.getModel();
        const value = model.getValue();
        if (typeof JSON.parse(value) !== 'string') {
          let payload = {
            method: 'POST',
            url: `${API_HMI.CREATE}?SiteId=${siteId}`,
            data: {
              Name: values.Name,
              Type: values.Type,
              ...imageDetails,
              JSON: JSON.parse(value),
              CreatedBy: userId,
              SiteId: siteId
            }
          };
          datasource = await getHMICalls(payload);
          if (datasource && datasource.message) {
            if (datasource.message === 'Error in API') {
              message.error(datasource.message);
            } else {
              message.success(datasource.message);
              this.setState({
                visible: false
              });
              this.hmiRef.current.resetFields();
              this._getHmiList('Update');
            }
          } else {
            this.setState({
              visible: false,
              imageUrl: '',
              defaultFileList: []
            });
            this.hmiRef.current.resetFields();
            this._getHmiList('Update');
          }
        } else {
          alert('Error');
        }
      });
    } else if (operation === 'update') {
      this.hmiRef.current
        .validateFields()
        .then(async (values) => {
          const model = this.monaco.editor.getModel();
          const value = model.getValue();
          if (typeof JSON.parse(value) !== 'string') {
            let payload = {
              method: 'PATCH',
              url: `${API_HMI.UPDATE}?SiteId=${siteId}`,
              data: {
                Name: values.Name,
                Type: values.Type,
                JSON: JSON.parse(value),
                ModifiedBy: userId,
                ...imageDetails,
                Id: editObj.Id,
                SiteId: siteId
              }
            };
            datasource = await getHMICalls(payload);
            if (datasource && datasource.message) {
              if (datasource.message === 'Error in API') {
                message.error(datasource.message);
              } else {
                message.success(datasource.message);

                if (this.hmiRef.current) {
                  this.hmiRef.current.resetFields();
                }
                this.setState({
                  visible: false,
                  editObj: {},
                  editEditor: {},
                  imageUrl: '',
                  defaultFileList: []
                });
                this._getHmiList('Update');
              }
            } else {
              this.setState({
                visible: false
              });
              this.hmiRef.current.resetFields();
              this._getHmiList('Update');
            }
          }
        })
        .catch(() => {});
    } else if (operation === 'delete') {
      this.hmiRef.current
        .validateFields()
        .then(async () => {
          let payload = {
            method: 'DELETE',
            url: `${API_HMI.DELETE}?SiteId=${siteId}&Id=${this.state.editObj.Id}`
          };
          datasource = await getHMICalls(payload);
          if (datasource && datasource.message) {
            message.success(datasource.message);
            this.setState({
              visible: false
            });
            this.hmiRef.current.resetFields();
            this._getHmiList('Uodate');
          } else {
            this.setState({
              visible: false
            });
            this.hmiRef.current.resetFields();
            this._getHmiList('Update');
          }
        })
        .catch(() => {});
    }
  };

  uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;

    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    const fmData = new FormData();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    fmData.append('file', file);
    try {
      const res = await axios.post(`${API_HMI.SVGUpload}?SiteId=${siteId}`, fmData, config);
      onSuccess('Ok');
      let imageDetails = res.data;
      this.setState({ imageDetails, enable: true });
    } catch (err) {
      message.error('Error occured while uploading');
      this.setState({ loading: false, enable: false });
      onError({ err });
    }
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  getBase64ImageDimensions = (base64String) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = function () {
        reject(new Error('Failed to load image'));
      };
      img.src = base64String; // Assign Base64 string
    });
  };
  onChangeAnnotations = (e, index, key) => {
    let { editEditor } = this.state;
    // let value = e.target.value;
    // let updateAnnotations = [...editEditor.annotations];
    const value = e.target.value;
    editEditor.annotations[index][key] = value;
    this.setState({
      editEditor: {
        ...editEditor
      }
    });

    //   if(updateAnnotations[index])  {
    //     updateAnnotations[index] = {
    //           ...updateAnnotations[index],
    //     [key]: value,
    //   };
    // }
    // editEditor.annotations[index][key] = value;
    // console.log(e, index, editEditor.updateAnnotations[index], value, 'onchangeeee');
    // updateAnnotations[index] =updateAnnotations;
    //     return{
    //       editEditor: {
    //         ...prevState.editEditor,
    //         annotations : updateAnnotations
    //       }
    //     };
    //   })
    console.log(value, index, key, 'onchange');
  };
  onChangeShapennotations = (e, index, key) => {
    let { editEditor } = this.state;
    const value = e.target.value;
    editEditor.shapesannotations[index][key] = value;
    this.setState({
      editEditor: {
        ...editEditor
      }
    });
  };

  handleOnChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, (url) => {
        this.getBase64ImageDimensions(url)
          .then((dimensions) => {
            let svgDimensions = { width: dimensions.width, height: dimensions.height };
            this.setState({
              svgDimensions,
              editEditor: {
                ...this.state.editEditor,
                width: dimensions.width,
                height: dimensions.height,
                viewbox: `0 0 ${dimensions.width} ${dimensions.height}`
              }
            });
          })
          .catch((error) => {
            console.error(error);
          });
        // editEditor
        this.setState({ loading: false, imagebaseUrl: url });
        // setImageUrl(url);
      });
    }
  };

  beforeUpload = (file) => {
    const isSvg = file.type === 'image/svg+xml';
    if (!isSvg) {
      message.error('You can only upload SVG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isSvg && isLt2M;
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation(`Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  getSvgDimensions = async (url) => {
    try {
      const response = await fetch(url);
      const svgText = await response.text();

      // Parse the SVG text into an XML document
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');

      // Get the <svg> element
      const svgElement = svgDoc.documentElement;

      // Extract width and height or viewBox
      let width = svgElement.getAttribute('width');
      let height = svgElement.getAttribute('height');
      const viewBox = svgElement.getAttribute('viewBox');

      if (!width || !height) {
        if (viewBox) {
          const viewBoxValues = viewBox.split(' ');
          width = viewBoxValues[2]; // width from viewBox
          height = viewBoxValues[3]; // height from viewBox
        }
      }

      return { width, height };
    } catch (error) {
      console.error('Error fetching SVG dimensions:', error);
    }
  };

  render() {
    let userDeatils = this.props.UserDetails;
    const {
      editObj,
      translationCache,
      datasource,
      userProfile,
      editEditor,
      defaultFileList,
      imageUrl,
      imagebaseUrl,
      svgDimensions,
      HMITypeList,
      lastClickedXY,
      activeDetails
    } = this.state;
    console.log(editEditor.shapesannotations, 'editEditor');
    const userId = userDeatils && userDeatils.Id;
    const options = {
      selectOnLineNumbers: true,
      roundedSelection: false,
      readOnly: false,
      cursorStyle: 'line',
      automaticLayout: false,
      lineDecorationsWidth: 0
    };
    let permissionsArray = [];
    const siteId = localStorage.get('currentSite');
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][siteId] &&
      userProfile['belongsTo']['sites'][siteId]['role'] &&
      userProfile['belongsTo']['sites'][siteId]['role']['Permission']
        .filter((item) => item.resource === 'hmi')
        .map((name) => permissionsArray.push(name.action));

    const columns = [
      {
        title:
          translationCache && translationCache[`${constant.HMI_Name}`]
            ? translationCache[`${constant.HMI_Name}`]
            : `${constant.HMI_Name}`,
        dataIndex: 'Name',
        key: 'Name',
        width: '50%',
        ...this.getColumnSearchProps('Name'),
        render: (text, record) => {
          return (
            <Link
              style={{ color: '#173049' }}
              to={{
                pathname: `/rubus/HMI/${record.Type}/${record.Name}${
                  record.Type === 'dynamic' || record.Type === 'distinct' ? '/deviceCode' : ''
                }`
              }}
            >
              {text}
            </Link>
          );
        }
      },
      {
        title:
          translationCache && translationCache[`${constant.HMI_Type}`]
            ? translationCache[`${constant.HMI_Type}`]
            : `${constant.HMI_Type}`,
        dataIndex: 'Type',
        key: 'Type',
        ...this.getColumnSearchProps('Type')
      },
      {
        title:
          translationCache && translationCache[`${constant.File_Name}`]
            ? translationCache[`${constant.File_Name}`]
            : `${constant.File_Name}`,
        dataIndex: 'FileName',
        key: 'FileName',
        ...this.getColumnSearchProps('FileName')
      }
    ];
    return (
      <StyledComponent style={{ minHeight: window.innerHeight - 69 }}>
        <StyledContentRight>
          <StyledButton
            // disabled={ permissionsArray && !permissionsArray.includes('create') ? true : false}
            onClick={this.addNewHmi}
          >
            {' '}
            <PlusOutlined />{' '}
            {translationCache && translationCache[`${constant.New_HMI}`]
              ? translationCache[`${constant.New_HMI}`]
              : `${constant.New_HMI}`}
          </StyledButton>
        </StyledContentRight>
        <Drawer
          title={this.translation('Create a new HMI configuration')}
          width={1700}
          closable
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            layout="vertical"
            ref={this.hmiRef}
            hideRequiredMark
            initialValues={editObj || {}}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={18}>
                <Form.Item
                  label={this.translation('HMI Name :')}
                  name="Name"
                  rules={[{ required: true, message: 'Enter HMI Name' }]}
                >
                  <Input
                    placeholder={this.translation('Please enter HMI Name')}
                    disabled={!editObj.Name ? false : true}
                  />
                </Form.Item>
                <Form.Item
                  label={this.translation('HMI Type :')}
                  name="Type"
                  rules={[{ required: true, message: 'Please enter HMI Type' }]}
                >
                  <Select
                    placeholder={this.translation('Select HMI Type')}
                    disabled={!editObj.Type ? false : true}
                  >
                    {HMITypeList &&
                      Array.isArray(HMITypeList) &&
                      HMITypeList.map((hmi, index) => {
                        return (
                          <Option key={index} value={hmi.HMIType}>
                            {hmi.HMIType}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item label={this.translation('Upload')} name="fileList">
                  <Upload
                    name="avatar"
                    showUploadList={false}
                    accept="image/svg+xml"
                    customRequest={this.uploadImage}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleOnChange}
                    defaultFileList={defaultFileList}
                  >
                    <Button icon={<UploadOutlined />}>Upload SVG file</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div>
            Last clicked coordinates:{' '}
            {lastClickedXY && lastClickedXY.x && lastClickedXY.y ? (
              <span>
                x :{lastClickedXY.x} , y :{lastClickedXY.y}
              </span>
            ) : (
              ''
            )}
          </div>
          <svg
            viewBox={`0 0 ${svgDimensions.width} ${svgDimensions.height}`}
            onClick={(event) => {
              const rect = event.target.getBoundingClientRect();
              const x = event.clientX - rect.left;
              const y = event.clientY - rect.top;
              this.setState({ lastClickedXY: { x, y } });
            }}
          >
            <image
              href={`${imagebaseUrl ? imagebaseUrl : imageUrl}`}
              width={svgDimensions.width}
              height={svgDimensions.height}
            />
          </svg>

          <div>{this.translation('Enter HMI JSON')}</div>
          <Tabs
            type="card"
            defaultActiveKey="1"
            activeKey={activeDetails}
            onChange={(activeDetails) => {
              if (activeDetails === '2') {
                this.setState({ activeDetails });
              }
              if (activeDetails === '3') {
                this.setState({ activeDetails });
              }
              if (activeDetails === '1') {
                this.setState({ activeDetails });
              }
            }}
          >
            <TabPane tab="Initial Tab" key="1">
              <Form
                labelCol={{
                  span: 4
                }}
                wrapperCol={{
                  span: 8
                }}
                ref={this.hForm}
                name="Site_form"
                initialValues={editEditor}
                onFinish={this.onFinishs}
              >
                <Form.Item label="Background">
                  <Input />
                </Form.Item>
                <Form.Item label="URL">
                  <Input />
                </Form.Item>
                <Form.Item label="Viewbox">
                  <Input />
                </Form.Item>
                <Form.Item label="Width">
                  <Input />
                </Form.Item>
                <Form.Item label="Height">
                  <Input />
                </Form.Item>
                <Form.Item label="Fill">
                  <Input />
                </Form.Item>
                <Form.Item label="FontWeight">
                  <Input />
                </Form.Item>
                <Form.Item label="FontFamily">
                  <Input />
                </Form.Item>
                <Form.Item label="IntervalOperation">
                  <Input />
                </Form.Item>
                <Form.Item label="IntervalOnchange">
                  <Input />
                </Form.Item>
                <Form.Item label="DataSource">
                  <Input />
                </Form.Item>
                <Form.Item label="Frequency">
                  <Input />
                </Form.Item>
                <Form.Item label="CodeType">
                  <Input />
                </Form.Item>
                <Form.Item label="GroupValue1">
                  <Input />
                </Form.Item>
                <Form.Item label="GroupValue2">
                  <Input />
                </Form.Item>
              </Form>
            </TabPane>
            <TabPane tab="Annotations" key="2">
              <Form layout="horizontal" name="horizontal">
                <Form.List name="annotations">
                  {(fields, { add }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <div
                          key={key}
                          style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}
                        >
                          {editEditor &&
                            Array.isArray(editEditor.annotations) &&
                            editEditor.annotations.map(
                              (field, index) => (
                                console.log(
                                  field,
                                  'fieldddddd',
                                  editEditor.annotations[index].fontWeight,
                                  index
                                ),
                                (
                                  <Form.Item
                                    key={field.parameter}
                                    // label={field.label}
                                    style={{ marginBottom: 0 }}
                                  >
                                    <Form.Item
                                      label="X"
                                      {...restField}
                                      // name={[name, field.x]}
                                      // fieldKey={[fieldKey, field.x]}
                                      name={[name, `x-${index}`]}
                                      // key={`annotation-${index}-x`}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.annotations[index].x}
                                        // value={editEditor.annotations[index].x}
                                        onChange={(e) => {
                                          this.onChangeAnnotations(e, index, 'x');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="Y"
                                      {...restField}
                                      name={[name, `y-${index}`]}
                                      fieldKey={[fieldKey, field.y]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.annotations[index].y}
                                        value={editEditor.annotations[index].y}
                                        onChange={(e) => {
                                          this.onChangeAnnotations(e, index, 'y');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="FontWeight"
                                      {...restField}
                                      name={[name, `fontWeight-${index}`]}
                                      fieldKey={[fieldKey, field.fontWeight]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.annotations[index].fontWeight}
                                        value={editEditor.annotations[index].fontWeight}
                                        onChange={(e) => {
                                          this.onChangeAnnotations(e, index, 'fontWeight');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="FontSize"
                                      {...restField}
                                      name={[name, `fontSize-${index}`]}
                                      fieldKey={[fieldKey, field.fontSize]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.annotations[index].fontSize}
                                        value={editEditor.annotations[index].fontSize}
                                        onChange={(e) => {
                                          this.onChangeAnnotations(e, index, 'fontSize');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="FontFamily"
                                      {...restField}
                                      name={[name, `fontFamily-${index}`]}
                                      fieldKey={[fieldKey, field.fontFamily]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.annotations[index].fontFamily}
                                        value={editEditor.annotations[index].fontFamily}
                                        onChange={(e) => {
                                          this.onChangeAnnotations(e, index, 'fontFamily');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="Parameter"
                                      {...restField}
                                      name={[name, `parameter-${index}`]}
                                      fieldKey={[fieldKey, field.parameter]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.annotations[index].parameter}
                                        value={editEditor.annotations[index].parameter}
                                        onChange={(e) => {
                                          this.onChangeAnnotations(e, index, 'parameter');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="Type"
                                      {...restField}
                                      name={[name, `type-${index}`]}
                                      fieldKey={[fieldKey, field.type]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.annotations[index].type}
                                        value={editEditor.annotations[index].type}
                                        onChange={(e) => {
                                          this.onChangeAnnotations(e, index, 'type');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="Fill"
                                      {...restField}
                                      name={[name, `fill-${index}`]}
                                      fieldKey={[fieldKey, field.fill]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.annotations[index].fill}
                                        value={editEditor.annotations[index].fill}
                                        onChange={(e) => {
                                          this.onChangeAnnotations(e, index, 'fill');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="ClassName"
                                      {...restField}
                                      name={[name, `className-${index}`]}
                                      fieldKey={[fieldKey, field.className]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.annotations[index].className}
                                        value={editEditor.annotations[index].className}
                                        onChange={(e) => {
                                          this.onChangeAnnotations(e, index, 'className');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="Group"
                                      {...restField}
                                      name={[name, `group-${index}`]}
                                      fieldKey={[fieldKey, field.group]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.annotations[index].group}
                                        value={editEditor.annotations[index].group}
                                        onChange={(e) => {
                                          this.onChangeAnnotations(e, index, 'group');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="GroupValue"
                                      {...restField}
                                      name={[name, `groupValue-${index}`]}
                                      fieldKey={[fieldKey, field.groupValue]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.annotations[index].groupValue}
                                        value={editEditor.annotations[index].groupValue}
                                        onChange={(e) => {
                                          this.onChangeAnnotations(e, index, 'groupValue');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="PayloadType"
                                      {...restField}
                                      name={[name, `payloadType-${index}`]}
                                      fieldKey={[fieldKey, field.payloadType]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.annotations[index].payloadType}
                                        value={editEditor.annotations[index].payloadType}
                                        onChange={(e) => {
                                          this.onChangeAnnotations(e, index, 'payloadType');
                                        }}
                                      />
                                    </Form.Item>
                                  </Form.Item>
                                )
                              )
                            )}
                        </div>
                      ))}
                      <Button type="dashed" onClick={() => add()} block>
                        Add
                      </Button>
                    </>
                  )}
                </Form.List>
              </Form>
            </TabPane>
            <TabPane tab="shapesannotations" key="3">
              <Form layout="horizontal" name="horizontal">
                <Form.List name="shapesannotations">
                  {(fields, { add }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <div
                          key={key}
                          style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}
                        >
                          {editEditor &&
                            Array.isArray(editEditor.shapesannotations) &&
                            editEditor.shapesannotations.map(
                              (field, index) => (
                                console.log(
                                  field,
                                  'fieldddddd',
                                  editEditor.shapesannotations[index].x,
                                  index
                                ),
                                (
                                  <Form.Item
                                    key={field.parameter}
                                    // label={field.label}
                                    style={{ marginBottom: 0 }}
                                  >
                                    <Form.Item
                                      label="Type"
                                      {...restField}
                                      name={[name, `type-${index}`]}
                                      // fieldKey={[fieldKey, field.type]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                    >
                                      <Input
                                        defaultValue={editEditor.shapesannotations[index].type}
                                        // value={editEditor.shapesannotations[index].type}
                                        onChange={(e) => {
                                          this.onChangeShapennotations(e, index, 'type');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="X"
                                      {...restField}
                                      name={[name, `x-${index}`]}
                                      fieldKey={[fieldKey, field.x]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.shapesannotations[index].x}
                                        value={editEditor.shapesannotations[index].x}
                                        onChange={(e) => {
                                          this.onChangeShapennotations(e, index, 'x');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="CY"
                                      {...restField}
                                      name={[name, `y-${index}`]}
                                      fieldKey={[fieldKey, field.y]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                    >
                                      <Input
                                        defaultValue={editEditor.shapesannotations[index].y}
                                        value={editEditor.shapesannotations[index].y}
                                        onChange={(e) => {
                                          this.onChangeShapennotations(e, index, 'y');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="RX"
                                      {...restField}
                                      name={[name, `rx-${index}`]}
                                      fieldKey={[fieldKey, field.rx]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                    >
                                      <Input
                                        defaultValue={editEditor.shapesannotations[index].rx}
                                        value={editEditor.shapesannotations[index].rx}
                                        onChange={(e) => {
                                          this.onChangeShapennotations(e, index, 'rx');
                                        }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      label="Width"
                                      {...restField}
                                      name={[name, `width-${index}`]}
                                      fieldKey={[fieldKey, field.width]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                    >
                                      <Input
                                        defaultValue={editEditor.shapesannotations[index].width}
                                        value={editEditor.shapesannotations[index].width}
                                        onChange={(e) => {
                                          this.onChangeShapennotations(e, index, 'width');
                                        }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      label="Height"
                                      {...restField}
                                      name={[name, `height-${index}`]}
                                      fieldKey={[fieldKey, field.height]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                    >
                                      <Input
                                        defaultValue={editEditor.shapesannotations[index].height}
                                        value={editEditor.shapesannotations[index].height}
                                        onChange={(e) => {
                                          this.onChangeShapennotations(e, index, 'height');
                                        }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      label="Device"
                                      {...restField}
                                      name={[name, `device-${index}`]}
                                      fieldKey={[fieldKey, field.device]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                    >
                                      <Input
                                        defaultValue={editEditor.shapesannotations[index].device}
                                        value={editEditor.shapesannotations[index].device}
                                        onChange={(e) => {
                                          this.onChangeShapennotations(e, index, 'device');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="Parameter"
                                      {...restField}
                                      name={[name, `parameter-${index}`]}
                                      fieldKey={[fieldKey, field.parameter]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                      //
                                    >
                                      <Input
                                        defaultValue={editEditor.shapesannotations[index].parameter}
                                        value={editEditor.shapesannotations[index].parameter}
                                        onChange={(e) => {
                                          this.onChangeShapennotations(e, index, 'parameter');
                                        }}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      label="Fill"
                                      {...restField}
                                      name={[name, `fill-${index}`]}
                                      fieldKey={[fieldKey, field.fill]}
                                      rules={[
                                        { required: true, message: `${field.label} is required.` }
                                      ]}
                                    >
                                      <Input
                                        defaultValue={editEditor.shapesannotations[index].fill}
                                        value={editEditor.shapesannotations[index].fill}
                                        onChange={(e) => {
                                          this.onChangeShapennotations(e, index, 'fill');
                                        }}
                                      />
                                    </Form.Item>
                                  </Form.Item>
                                )
                              )
                            )}
                        </div>
                      ))}
                      <Button type="dashed" onClick={() => add()} block>
                        Add
                      </Button>
                    </>
                  )}
                </Form.List>
              </Form>
            </TabPane>
          </Tabs>
          <Collapse
            bordered={false}
            style={{ marginTop: '20px' }}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0}></CaretRightOutlined>
            )}
          >
            <Panel key="1" header={'JSON'} style={customPanel}>
              <MonacoEditor
                width="1000"
                height="500"
                language="json"
                theme="vs-dark"
                defaultValue={JSON.stringify(editEditor)}
                options={options}
                ref={(el) => (this.monaco = el)}
                value={JSON.stringify(editEditor, null, '\t')}
                editorWillMount={this.editorWillMount}
                editorDidMount={this.editorDidMount}
              />

              {this.state.listType === 'save' ? (
                <StyledButton
                  type="primary"
                  // disabled={!enable ? true : false}
                  shape="round"
                  onClick={() => this.hmiOperation('save', userId)}
                  style={{ marginRight: '10px', marginTop: '10px', color: 'white !important' }}
                >
                  {this.translation('Save')}
                </StyledButton>
              ) : (
                <StyledButton
                  // disabled={!permissionsArray.includes('update') ? true : !enable ? true : false}
                  type="primary"
                  shape="round"
                  onClick={() => this.hmiOperation('update', userId)}
                  style={{ marginTop: '10px', color: 'white !important' }}
                >
                  {this.translation('Update')}
                </StyledButton>
              )}

              {
                <StyledButton
                  type="primary"
                  shape="round"
                  // disabled={!permissionsArray.includes('delete') ? true : !enable ? true : false}
                  onClick={() => this.hmiOperation('delete', userId)}
                  style={{
                    marginTop: '10px',
                    marginLeft: '5px',
                    color: 'white !important'
                  }}
                >
                  {this.translation('Delete')}
                </StyledButton>
              }
              {
                <StyledButton
                  type="primary"
                  shape="round"
                  disabled={!permissionsArray.includes('delete') ? true : false}
                  onClick={this.onClose}
                  style={{
                    marginTop: '10px',
                    marginLeft: '5px',
                    color: 'white !important'
                  }}
                >
                  {this.translation('Close')}
                </StyledButton>
              }
            </Panel>
          </Collapse>
        </Drawer>

        <StyledTable
          columns={columns}
          dataSource={datasource}
          onRow={(record) => {
            return {
              onClick: async () => {
                let svgDimensions = await this.getSvgDimensions(record.Url);
                this.setState({
                  visible: true,
                  editObj: record,
                  imageDetails: { FileName: record.FileName, path: '' },
                  imageUrl: record.Url,
                  listType: 'update',
                  editEditor: record['JSON'],
                  enable: true,
                  svgDimensions
                });
              }
            };
          }}
        />
      </StyledComponent>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  UserDetails: getUserDetails(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(HmiConfiguration);
