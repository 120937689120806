import React, { Component } from 'react';
import { Input, Button, Form, Select } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../../../selectors/language';

class SystemParameter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: '',
      translationCache: props.translationCache || []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;

    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    };
    const buttonItemLayout = {
      wrapperCol: {
        span: 14,
        offset: 4
      }
    };
    return (
      <div style={{}}>
        <Form
          {...formItemLayout}
          layout="horizontal"
          name={this.translation('HeaderNumber_current')}
          style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
        >
          <Form.Item label={this.translation('HD')}>
            <Select style={{ width: 440 }}>
              <Select.Option value="01">01</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={this.translation('Temperature Alarm Upper Limit')}>
            <Input style={{ width: '440px' }} />
          </Form.Item>
          <Form.Item label={this.translation('Temperature Alarm Lower Limit')}>
            <Input style={{ width: '440px' }} />
          </Form.Item>

          <Form.Item label={this.translation('Zero Coefficient')}>
            <Input style={{ width: '220px' }} />
            <Input style={{ width: '220px' }} />
          </Form.Item>
          <Form.Item label={this.translation('Temperature Coefficient-b0')}>
            <Input style={{ width: '220px' }} />
            <Input style={{ width: '220px' }} />
          </Form.Item>
          <Form.Item label={this.translation('Temperature Coefficient-b1')}>
            <Input style={{ width: '220px' }} />
            <Input style={{ width: '220px' }} />
          </Form.Item>
          <Form.Item label={this.translation('Temperature Coefficient-b2')}>
            <Input style={{ width: '220px' }} />
            <Input style={{ width: '220px' }} />
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button type="primary">{this.translation('Write to KB-30')}</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(SystemParameter);
