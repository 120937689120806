import React from 'react';
import { Form, Input, Select, message, Button, Drawer, Table, Tabs, Upload, Modal } from 'antd';
import { API_MODULES, IPAddress, API_RUBUSMASTERS } from '../../../commons/api';
import themeSettings from '../Utils/themeSettings.json';
import axios from 'axios';
import localStorage from '../../../utils/localStorage';
import history from '../../../commons/history';
import {
  UpdateMasters,
  CreateMasters,
  getDevicesTypesFileDownloadName,
  getAllSubEquipmentFileDownloadName,
  getAllAttributeFileDownloadName,
  getAllParameterFileDownloadName
} from '../APICalls';
import {
  StyledButton,
  StyledComponent,
  StyledTabs,
  DrawerFooter,
  StyledForm
} from '../../../commons/styles/layout';
import {
  SearchOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined,
  DownloadOutlined
  // DeleteOutlined,
  // QuestionCircleOutlined
} from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../selectors/language';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
// import SubEquipments from '../Forms/SubEquipments';
const { Option } = Select;
const { TabPane } = Tabs;
const APIList = {
  CreateDeviceTypes: `${API_MODULES.DeviceTypesCreate}`,
  UpdateDeviceTypes: `${API_MODULES.DeviceTypesUpdate}`
};

class DeviceTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      ModuleCodes: [],
      SelectedModulesCode: '',
      list: [],
      visible: false,
      modalVisible: false,
      module: null
    };
  }
  hForm = React.createRef();
  SubEquipmentFormRef = React.createRef();
  AttributeFormRef = React.createRef();
  ParameterFormRef = React.createRef();

  componentDidMount() {
    let path = history.location.pathname.split('/rubus/CustomerAssetDevice/DeviceTypes/');
    let module = path && path[1] ? path[1] : null;
    this.setState({ module });
    this._getList(module);
    this.ModulesCodeApi();
    this.getFileDownloadName(module);
    this.getSubEquipmentFileDownloadName(module);
    this.getParameterFileDownloadName(module);
    this.getAttributeFileDownloadName(module);
  }

  _getList = (module) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let url = '';
    url = `${API_RUBUSMASTERS.DT_GET}?SiteId=${siteId}&Status=Active${
      module && module !== null ? `&ModuleCode=${module}` : ''
    }${module === null ? `&Type=${'NoModule'}` : ''}`;
    let orgHeaders = {
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ list: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  _editRecord = (record) => {
    this.hForm.current && this.hForm.current.setFieldsValue(record);
    this.setState({
      formObject: record,
      visible: true
    });
  };

  // deleteRecord = async (id) => {
  //   // await deleteModules(id);
  //   this._getList(this.props && this.props.match && this.props.match.params.module);
  // };
  onFinish = async () => {
    let { formObject, module } = this.state;

    this.hForm.current &&
      this.hForm.current
        .validateFields()
        .then(async (values) => {
          let payload = {
            ...values,
            ModuleCode: module
          };

          if (formObject && formObject.Id) {
            let UpdateResponse = await UpdateMasters(
              APIList.UpdateDeviceTypes,
              payload,
              formObject.Id
            );
            message.success(UpdateResponse && UpdateResponse.message);
          } else {
            let CreateResponse = await CreateMasters(APIList.CreateDeviceTypes, payload);
            message.success(CreateResponse && CreateResponse.message);
          }
          this._getList(this.state.module);
          this.getFileDownloadName(this.state.module);
          this.hForm.current &&
            this.hForm.current.setFieldsValue({
              ModuleCode: null,
              Code: null,
              Name: null,
              Description: null,
              DisplayCode: null,
              Status: 'Active'
            });
          this.setState({ visible: false, formObject: {} });
        })
        .catch((error) => {
          console.log(error);
        });
  };
  ModulesCodeApi = () => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    const type = {
      method: '',
      url: `${API_MODULES.ModulesGet}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(type)
      .then((response) => {
        this.setState({
          ModuleCodes: response.data
        });
      })
      .catch(() => {});
  };

  onClose = () => {
    this.hForm.current &&
      this.hForm.current.setFieldsValue({
        ModuleCode: null,
        Code: null,
        Name: null,
        Description: null,
        DisplayCode: null,
        Status: 'Active'
      });
    this.setState({ visible: false, formObject: {} });
  };
  ModuleCodeSelection = (value) => {
    this.setState({ SelectedModulesCode: value });
  };
  openDrawer = () => {
    // e.preventDefault();
    this.hForm.current &&
      this.hForm.current.setFieldsValue({
        ModuleCode: null,
        Code: null,
        Name: null,
        Description: null,
        DisplayCode: null,
        Status: 'Active'
      });
    let formObject = {
      ModuleCode: null,
      Code: null,
      Name: null,
      Description: null,
      DisplayCode: null,
      Status: 'Active'
    };
    this.setState({
      formObject,
      visible: true
    });
  };
  openModal = () => {
    // e.preventDefault();
    // this.hForm.current &&
    //   this.hForm.current.setFieldsValue({
    //     ModuleCode: null,
    //     Code: null,
    //     Name: null,
    //     DisplayCode: null,
    //     Status: 'Active'
    //   });
    // let formObject = {
    //   ModuleCode: null,
    //   Code: null,
    //   Name: null,
    //   DisplayCode: null,
    //   Status: 'Active'
    // };
    this.setState({
      // formObject,
      modalVisible: true
    });
  };
  OnNextClick = (record) => {
    history.push(`/rubus/CustomerAssetDevice/DeviceTypesChildren/${record.AliasCode}`);
  };
  getFileDownloadName = async (module) => {
    let fileDownloadName = await getDevicesTypesFileDownloadName(module);
    this.setState({
      fileDownloadName: fileDownloadName && fileDownloadName.file ? fileDownloadName.file : ''
    });
  };
  getSubEquipmentFileDownloadName = async (module) => {
    let SubEquipmentFileName = await getAllSubEquipmentFileDownloadName(module);
    this.setState({
      SubEquipmentFileName:
        SubEquipmentFileName && SubEquipmentFileName.file ? SubEquipmentFileName.file : ''
    });
  };
  getAttributeFileDownloadName = async (module) => {
    let AttributeFileName = await getAllAttributeFileDownloadName(module);
    this.setState({
      AttributeFileName: AttributeFileName && AttributeFileName.file ? AttributeFileName.file : ''
    });
  };
  getParameterFileDownloadName = async (module) => {
    let ParameterFileName = await getAllParameterFileDownloadName(module);
    this.setState({
      ParameterFileName: ParameterFileName && ParameterFileName.file ? ParameterFileName.file : ''
    });
  };

  render() {
    let {
      formObject,
      visible,
      list,
      fileDownloadName,
      SubEquipmentFileName,
      AttributeFileName,
      ParameterFileName,
      module
    } = this.state;
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    let colum = [
      { key: 'S No', value: 'S No' },
      { key: 'Device Type Code', value: 'Code' },
      { key: 'Device Type Name', value: 'Name' },
      { key: 'Device Type Description', value: 'Description' },
      { key: 'Status', value: 'Status' }
    ];
    let columns =
      colum && Array.isArray(colum)
        ? colum
            // .filter((col) => col !== 'Id' && col !== 'SiteId' && col !== 'AliasCode')
            .map((field) => {
              return {
                title: field.key,
                dataIndex: field.value,
                key: field.value,
                ...this.getColumnSearchProps(field.value)
              };
            })
        : [];
    // let translationTable = tableTranslation(
    //   columns,
    //   this.props.translationCache,
    //   this.props.language
    // );
    let finalColumns = [
      ...columns,
      {
        title: this.translation('Actions'),
        dataIndex: 'Actions',
        key: 'Actions',
        render: (_text, record) => (
          <span>
            <Button
              className="ant-btn"
              icon={<EditOutlined />}
              onClick={() => this._editRecord(record)}
              size="middle"
            />
            {/* <Popconfirm
              title="Are you sure that you want to delete"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              // onConfirm={() => this.deleteRecord(record.Id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="ant-btn" size="middle" style={{ marginLeft: '20px' }}>
                <span>
                  {' '}
                  <DeleteOutlined />
                </span>
              </Button>
            </Popconfirm> */}
            <Button
              type="primary"
              onClick={() => this.OnNextClick(record)}
              style={{ marginLeft: '10px' }}
            >
              <span>{this.translation('Next')}</span>
            </Button>
          </span>
        )
      }
    ];
    console.log('module', module);

    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 114 }}>
        <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px' }}>
          <StyledButton>
            <a href={`${IPAddress.LOCAL}${fileDownloadName}`}>
              <DownloadOutlined style={{ color: 'white', fontSize: '16px', marginRight: '1px' }} />
              <span style={{ color: 'white', marginRight: '10px' }}>
                {this.translation('Download')}
              </span>
            </a>
          </StyledButton>
          <StyledButton>
            <Upload
              multiple={false}
              style={{ marginRight: '10px', color: 'white' }}
              onChange={(info) => {
                if (info.file.status === 'done') {
                  message.success(info.file.response.message);
                  this.getFileDownloadName(this.state.module);
                  this._getList(this.state.module);
                } else if (info.file.status === 'error') {
                  message.error(info.file.response.message);
                }
              }}
              action={`/api/rubusMaster/upload/deviceTypes?SiteId=${siteId}${
                module && module !== null ? `&ModuleCode=${module}` : ''
              }${module === null ? `&Type=${'NoModule'}` : ''}`}
              headers={{
                Authorization: Authorization
              }}
              showUploadList={false}
            >
              <UploadOutlined style={{ color: 'white', fontSize: '16px', marginRight: '1px' }} />
              <span style={{ color: 'white', marginRight: '10px' }}>
                {' '}
                {this.translation('Upload')}
              </span>
            </Upload>
          </StyledButton>
          <StyledButton onClick={(e) => this.openModal(e)}>
            {this.translation('Upload Data')}
          </StyledButton>
          <StyledButton onClick={(e) => this.openDrawer(e)}>
            <PlusOutlined /> {this.translation('Add DeviceTypes')}
          </StyledButton>
        </div>

        <StyledTabs type="card" theme={themeSettings}>
          <TabPane tab={this.translation('DeviceTypes')} key="List">
            <Table
              className="basictable"
              columns={finalColumns}
              dataSource={list}
              pagination={
                list.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : false
              }
              style={{ marginTop: '20px' }}
            />
          </TabPane>
        </StyledTabs>
        <Modal
          width={'900'}
          title={this.translation('Upload and Download Data')}
          open={this.state.modalVisible}
          // onOk={() => this.setState({ isModalViewBookmark: false })}
          onCancel={() => this.setState({ modalVisible: false })}
          footer={[
            // null
            <Button key="back" onClick={() => this.setState({ modalVisible: false })}>
              {this.translation('Cancel')}
            </Button>
          ]}
        >
          <StyledTabs
            type="card"
            style={{ marginTop: '20px', marginBottom: '30px', color: 'black' }}
            theme={themeSettings}
          >
            <TabPane tab={this.translation('SubEquipments')} key="SubEquipments">
              <StyledForm
                name="SubEquipmentForm"
                ref={this.hRef}
                theme={themeSettings}
                layout="horizontal"
                labelCol={{ flex: '130px' }}
                labelAlign="right"
                labelWrap
              >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <>
                    <Form.Item
                      name="Download SubEquipments"
                      style={{ width: '50%' }}
                      label={this.translation('DownloadSubEquipments')}
                    >
                      <a href={`${IPAddress.LOCAL}${SubEquipmentFileName}`}>
                        <DownloadOutlined
                          style={{ color: 'black', fontSize: '16px', marginRight: '1px' }}
                        />
                        <span style={{ color: 'black' }}>Download</span>
                      </a>
                    </Form.Item>

                    <Form.Item
                      name="Upload SubEquipments"
                      style={{ width: '50%' }}
                      label={this.translation('UploadSubEquipments')}
                    >
                      <Upload
                        multiple={false}
                        style={{ marginRight: '10px', color: 'white' }}
                        onChange={async (info) => {
                          if (info.file.status === 'done') {
                            message.success(info.file.response.message);
                            this.getSubEquipmentFileDownloadName(this.state.module);
                            this.setState({ modalVisible: false });
                          } else if (info.file.status === 'error') {
                            message.error(info.file.response.message);
                          }
                        }}
                        action={`/api/rubusMaster/upload/devices?SiteId=${siteId}${
                          module && module !== null ? `&ModuleCode=${module}` : ''
                        }${module === null ? `&Type=${'NoModule'}` : ''}`}
                        headers={{
                          Authorization: Authorization
                        }}
                        showUploadList={false}
                      >
                        <UploadOutlined
                          style={{ color: 'black', fontSize: '16px', marginRight: '1px' }}
                        />
                        <span style={{ color: 'black', marginRight: '10px' }}>
                          {' '}
                          {this.translation('Upload')}
                        </span>
                      </Upload>
                    </Form.Item>
                  </>
                </div>
              </StyledForm>
            </TabPane>

            <TabPane tab={this.translation('Attributes')} key="Attributes">
              <StyledForm
                name="AttributeForm"
                ref={this.AttributeFormRef}
                theme={themeSettings}
                layout="horizontal"
                labelCol={{ flex: '130px' }}
                labelAlign="right"
                labelWrap
              >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <>
                    <Form.Item
                      name="Download Attributes"
                      style={{ width: '50%' }}
                      label={this.translation('DownloadAttributes')}
                    >
                      <a href={`${IPAddress.LOCAL}${AttributeFileName}`}>
                        <DownloadOutlined
                          style={{ color: 'black', fontSize: '16px', marginRight: '1px' }}
                        />
                        <span style={{ color: 'black' }}>Download</span>
                      </a>
                    </Form.Item>

                    <Form.Item
                      name="Upload Attributes"
                      style={{ width: '50%' }}
                      label={this.translation('UploadAttributes')}
                    >
                      <Upload
                        multiple={false}
                        style={{ marginRight: '10px', color: 'white' }}
                        onChange={async (info) => {
                          if (info.file.status === 'done') {
                            message.success(info.file.response.message);
                            this.getAttributeFileDownloadName(this.state.module);
                            this.setState({ modalVisible: false });
                          } else if (info.file.status === 'error') {
                            message.error(info.file.response.message);
                          }
                        }}
                        action={`/api/rubusMaster/upload/attributes?SiteId=${siteId}${
                          module && module !== null ? `&ModuleCode=${module}` : ''
                        }${module === null ? `&Type=${'NoModule'}` : ''}`}
                        headers={{
                          Authorization: Authorization
                        }}
                        showUploadList={false}
                      >
                        <UploadOutlined
                          style={{ color: 'black', fontSize: '16px', marginRight: '1px' }}
                        />
                        <span style={{ color: 'black', marginRight: '10px' }}>
                          {' '}
                          {this.translation('Upload')}
                        </span>
                      </Upload>
                    </Form.Item>
                  </>
                </div>
              </StyledForm>
            </TabPane>

            <TabPane tab={this.translation('Parameters')} key="Parameters">
              <StyledForm
                name="ParameterForm"
                ref={this.ParameterFormRef}
                theme={themeSettings}
                layout="horizontal"
                labelCol={{ flex: '130px' }}
                labelAlign="right"
                labelWrap
              >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <>
                    <Form.Item
                      name="Download Parameters"
                      style={{ width: '50%' }}
                      label={this.translation('DownloadParameters')}
                    >
                      <a href={`${IPAddress.LOCAL}${ParameterFileName}`}>
                        <DownloadOutlined
                          style={{ color: 'black', fontSize: '16px', marginRight: '1px' }}
                        />
                        <span style={{ color: 'black' }}>Download</span>
                      </a>
                    </Form.Item>

                    <Form.Item
                      name="Upload Attributes"
                      style={{ width: '50%' }}
                      label={this.translation('UploadParameters')}
                    >
                      <Upload
                        multiple={false}
                        style={{ marginRight: '10px', color: 'white' }}
                        onChange={async (info) => {
                          if (info.file.status === 'done') {
                            message.success(info.file.response.message);
                            this.getParameterFileDownloadName(this.state.module);
                            this.setState({ modalVisible: false });
                          } else if (info.file.status === 'error') {
                            message.error(info.file.response.message);
                          }
                        }}
                        action={`/api/rubusMaster/upload/parameters?SiteId=${siteId}${
                          module && module !== null ? `&ModuleCode=${module}` : ''
                        }${module === null ? `&Type=${'NoModule'}` : ''}`}
                        headers={{
                          Authorization: Authorization
                        }}
                        showUploadList={false}
                      >
                        <UploadOutlined
                          style={{ color: 'black', fontSize: '16px', marginRight: '1px' }}
                        />
                        <span style={{ color: 'black', marginRight: '10px' }}>
                          {' '}
                          {this.translation('Upload')}
                        </span>
                      </Upload>
                    </Form.Item>
                  </>
                </div>
              </StyledForm>
            </TabPane>
          </StyledTabs>
        </Modal>
        <Drawer
          title={
            formObject && formObject.Id
              ? this.translation('Update Device Types')
              : this.translation('Add Device Types')
          }
          placement="right"
          width={600}
          closable
          onClose={this.onClose}
          visible={visible}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            layout="horizontal"
            ref={this.hForm}
            name="Site_form"
            initialValues={formObject}
            onFinish={this.onFinish}
          >
            {/* <Form.Item
              label="Module Code"
              name="ModuleCode"
              rules={[{ required: true, message: 'Please Select ModuleCode' }]}
            >
              <Select
                value={SelectedModulesCode}
                placeholder="Please select Modules Code"
                onChange={this.ModuleCodeSelection}
              >
                {ModuleCodes.map((value, index) => {
                  return (
                    <Option key={index} value={value.AliasCode}>
                      {value.Name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item> */}
            <Form.Item
              label={this.translation('Code')}
              name="Code"
              rules={[{ required: true, message: this.translation('Please enter Code !') }]}
            >
              <Input
                disabled={formObject && formObject.Id ? true : false}
                placeholder={this.translation('Please enter Code')}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Name')}
              name="Name"
              rules={[{ required: true, message: this.translation('Please enter Name !') }]}
            >
              <Input placeholder="Please enter Name" />
            </Form.Item>
            <Form.Item
              label={this.translation('Description')}
              name="Description"
              rules={[{ required: true, message: this.translation('Please enter Description !') }]}
            >
              <Input placeholder="Please enter Description" />
            </Form.Item>
            {/* <Form.Item
              label={this.translation('Display Code')}
              name="DisplayCode"
              rules={[{ required: true, message: this.translation('Please enter DisplayCode') }]}
            >
              <Input placeholder={this.translation('Please enter DisplayCode')} />
            </Form.Item> */}
            <Form.Item
              label={this.translation('Status')}
              name="Status"
              rules={[{ required: true, message: this.translation('Please select Status') }]}
            >
              <Select disabled={true} defaultValue={'Active'}>
                <Option value="Active">{'Active'}</Option>
              </Select>
            </Form.Item>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            <Button type="primary" onClick={this.onFinish}>
              {this.translation('Submit')}
            </Button>
          </DrawerFooter>
        </Drawer>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(DeviceTypes);
