import React from 'react';

import '../CSS/style.css';
import { WarningOutlined } from '@ant-design/icons';

class ErrorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mbdObject: {}
    };
  }

  getSelectedMBD = (selectedMDB) => {
    this.setState({
      selectedMDB
    });
  };

  render() {
    return (
      <div className="warning-placeholder">
        <div className="ant-empty ant-empty-normal">
          <div className="ant-empty-image">
            <WarningOutlined
              theme="filled"
              style={{ fontSize: '5em', marginBottom: '2px', color: 'grey' }}
            />
          </div>
        </div>
        <p
          style={{ color: 'grey', fontSize: '17px', fontWeight: 'bold' }}
          className="ant-empty-description"
        >
          ERROR IN API
        </p>
      </div>
    );
  }
}

export default ErrorComponent;
