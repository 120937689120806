import React, { Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import {
  Drawer,
  Input,
  Popconfirm,
  Select,
  Switch,
  Collapse,
  message,
  Divider,
  Form,
  Button
} from 'antd';
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import localStorage from '../../../utils/localStorage';
import { getUserProfile } from '../../../selectors/layout';
import DeviceTagReference from '../Device/DeviceTagReference';
import {
  _getDeviceTypesList,
  _getDeviceParameterBasedOnType,
  _getDeviceParameterType,
  _getDeviceParameterBasedonDevice,
  _getDeviceBasedOnAssetID,
  _saveDeviceData,
  _updateDeviceData,
  _getParameterGroupList,
  _tagReferenceList,
  _UpdateLinkDeviceParameter,
  _deleteDeviceParameters,
  _deleteDevice,
  _CreateLinkDeviceParameter,
  _getParameterCategoryList
} from './APICalls';
import { AddItemDiv, BadgeStyle, SpanItem, DrawerFooter } from '../AssetDeviceList/style';
import { currentDevice, setDeviceBreadcrumbs } from '../../../modules/device/device.duck';
import { getDeviceBreadcrumbList } from '../../../selectors/device';
import { StyledButton, StyledTable } from '../../../commons/styles/layout';
import { getTranslationCache } from '../../../selectors/language';
const { Option } = Select;
const { Panel } = Collapse;

const customPanel = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden'
};

class DeviceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      editObj: {},
      datasource: [],
      deviceTypeList: [],
      visibleConfiguration: false,
      deviceParameterBasedonDevice: [],
      tagReferenceList: [],
      selectedDeviceParameterId: '',
      parameterGroupList: [],
      parentDevice: '',
      equipmentType: '',
      userProfile: props.userProfile || {},
      ParameterCategoryList: []
    };
  }

  deviceRef = React.createRef();
  componentDidMount() {
    this._getDatasource(this.props.data, this.props.currentDevice);
    this._getDeviceType();
    this._getParameterGroupList();
    this._getTagReferenceList();
    this._getParameterCategoryList();
  }

  componentDidUpdate(preprops) {
    if (
      preprops.data !== this.props.data ||
      preprops.currentDevice !== this.props.currentDevice ||
      preprops.userProfile !== this.props.userProfile
    ) {
      this._getDatasource(this.props.data, this.props.currentDevice);
      this.setState({
        userProfile: this.props.userProfile
      });
    }
  }

  _getTagReferenceList = async () => {
    let tagReferenceList = await _tagReferenceList();
    this.setState({
      tagReferenceList
    });
  };
  _getParameterCategoryList = async () => {
    let parameterCategoryList = await _getParameterCategoryList();
    this.setState({
      parameterCategoryList
    });
  };

  _getParameterGroupList = async () => {
    let parameterGroupList = await _getParameterGroupList();
    this.setState({
      parameterGroupList
    });
  };

  _getDeviceType = async () => {
    let deviceTypeList = await _getDeviceTypesList();
    this.setState({
      deviceTypeList
    });
  };

  _getDatasource = async (data) => {
    let datasource = await _getDeviceBasedOnAssetID(data);
    this.setState({
      data,
      datasource
    });
  };

  editRowonRowEdit = (e, row) => {
    e.preventDefault();
    this.setState({
      deviceObject: row,
      visible: true
    });
  };

  onClose = () => {
    if (this.deviceRef.current) {
      this.deviceRef.current.resetFields();
    }
    this.setState({ visible: false, editObj: {} });
  };

  editConfigRow = async (deviceObject) => {
    let { DeviceType, Id, AliasCode } = deviceObject;
    let deviceParametersBasedOnType = await _getDeviceParameterBasedOnType(DeviceType);
    let deviceParameterBasedonDevice = await _getDeviceParameterBasedonDevice(AliasCode);
    let parameterType = await _getDeviceParameterType();
    this.setState({
      deviceParametersBasedOnType,
      deviceParameterBasedonDevice,
      deviceId: Id,
      parameterType,
      deviceObject,
      visibleConfiguration: true
    });
  };

  openAssetDrawer = (e) => {
    e.preventDefault();
    this.setState({
      deviceObject: {},
      visible: true
    });
  };

  onCloseVisibleConfiguration = () => {
    this.setState({
      visibleConfiguration: false
    });
  };

  createDevice = (e) => {
    let { data } = this.state;
    e.preventDefault();
    this.deviceRef.current
      .validateFields()
      .then(async (values) => {
        delete values.Id;
        let payload = {
          ...values,
          EquipmentType: data.parentDeviceType,
          ParentEquipment: data.parentDeviceId
        };
        let createDeviceResponse = await _saveDeviceData(payload);
        if (createDeviceResponse) {
          message.success(createDeviceResponse.message);
          this.setState({ visible: false });
          this._getDatasource(this.props.data);
          if (this.deviceRef.current) {
            this.deviceRef.current.resetFields();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateDevice = (e) => {
    let { data } = this.state;
    e.preventDefault();
    this.deviceRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          ...values,
          EquipmentType: data.parentDeviceType,
          ParentEquipment: data.parentDeviceId
        };
        let updateDeviceResponse = await _updateDeviceData(payload);
        if (updateDeviceResponse) {
          message.success(updateDeviceResponse.message);
          this.setState({ visible: false });
          this._getDatasource(this.props.data);
          if (this.deviceRef.current) {
            this.deviceRef.current.resetFields();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addDeviceParamter = () => {
    const {
      deviceParameterBasedonDevice,
      selectedDeviceParameterId,
      deviceParametersBasedOnType,
      deviceObject
    } = this.state;
    if (selectedDeviceParameterId) {
      let deviceStatus = false;
      deviceParameterBasedonDevice.forEach((usedDP) => {
        if (usedDP.ParameterId === selectedDeviceParameterId) {
          deviceStatus = true;
        }
      });
      if (!deviceStatus) {
        let newParameter = deviceParametersBasedOnType.filter(
          (parameter) => parameter.Id === selectedDeviceParameterId
        );

        const deviceParameter = {
          EdgeCode: '',
          ParameterGroup: '',
          ParameterCategory: '',
          // ParameterId: selectedDeviceParameterId,
          ParameterReferences: [],
          ParameterType: '',
          Parameter:
            newParameter && newParameter[0] && newParameter[0].Code ? newParameter[0].Code : '',
          ParameterCode:
            newParameter && newParameter[0] && newParameter[0].Code ? newParameter[0].Code : '',
          ParameterDescription:
            newParameter && newParameter[0] && newParameter[0].Name ? newParameter[0].Name : '',
          ParameterAlias:
            newParameter && newParameter[0] && newParameter[0].AliasCode
              ? newParameter[0].AliasCode
              : '',
          AssetAlias: deviceObject && deviceObject.AssetAlias ? deviceObject.AssetAlias : null,
          DeviceAlias: deviceObject && deviceObject.AliasCode ? deviceObject.AliasCode : null
        };
        deviceParameterBasedonDevice.push(deviceParameter);
        this.setState({
          deviceParameterBasedonDevice
        });
      } else {
        message.error('Equipment parameter already exists');
      }
    } else {
      message.error('Please select Equipment parameter');
    }
  };

  onChange = (value) => {
    this.setState({ selectedDeviceParameterId: value });
  };

  parameterTypeonChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].ParameterType = value;
      }
    });

    this.setState({ deviceParameterBasedonDevice });
  };

  parameterGrouponChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].ParameterGroup = value;
      }
    });

    this.setState({ deviceParameterBasedonDevice });
  };

  parameterCategoryonChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].ParameterCategory = value;
      }
    });

    this.setState({ deviceParameterBasedonDevice });
  };

  edgeCodeOnChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice &&
      deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
        if (deviceParameter.ParameterId === parameterId) {
          deviceParameterBasedonDevice[key].EdgeCode = value;
        }
      });

    this.setState({ deviceParameterBasedonDevice });
  };

  ParameterDescriptionOnChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice &&
      deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
        if (deviceParameter.ParameterId === parameterId) {
          deviceParameterBasedonDevice[key].ParameterDescription = value;
        }
      });

    this.setState({ deviceParameterBasedonDevice });
  };
  DisplayNameOnChange = (value, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;
    deviceParameterBasedonDevice &&
      deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
        if (deviceParameter.ParameterId === parameterId) {
          deviceParameterBasedonDevice[key].DisplayName = value;
        }
      });

    this.setState({ deviceParameterBasedonDevice });
  };
  saveTagReferenceList = (updatedTRList, parameterId) => {
    const { deviceParameterBasedonDevice } = this.state;

    deviceParameterBasedonDevice.forEach((deviceParameter, key) => {
      if (deviceParameter.ParameterId === parameterId) {
        deviceParameterBasedonDevice[key].ParameterReferences = updatedTRList;
      }
    });
    this.setState({ deviceParameterBasedonDevice });
  };

  saveDeviceParameter = async (payload, type) => {
    let { deviceObject } = this.state;
    if (payload) {
      payload &&
        payload.ParameterReferences &&
        payload.ParameterReferences.forEach((parameterAlias, index) => {
          if (parameterAlias.isNew) {
            delete parameterAlias.isNew;
            delete parameterAlias.editable;
            payload.ParameterReferences[index] = parameterAlias;
          }
        });

      let responseData = {};
      if (type && type === 'update') {
        responseData = await _UpdateLinkDeviceParameter(payload, deviceObject.AliasCode);
      } else if (type && type === 'create') {
        responseData = await _CreateLinkDeviceParameter(payload, deviceObject.AliasCode);
      }

      if (responseData && responseData.message) {
        message.success(responseData.message);
        let deviceParameterBasedonDevice = await _getDeviceParameterBasedonDevice(
          deviceObject.AliasCode
        );
        this.setState({ deviceParameterBasedonDevice });
      }
    }
  };

  _deleteDeviceParameter = async (parameterId) => {
    let deleteParameterStatus = await _deleteDeviceParameters(parameterId);
    if (deleteParameterStatus) {
      message.warn(deleteParameterStatus.message);
      this.editConfigRow(this.state.deviceObject);
    }
  };

  _deleteDevice = async (deviceObject) => {
    let deleteStatus = await _deleteDevice(deviceObject.Id);
    if (deleteStatus) {
      message.warn(deleteStatus.message);
      this._getDatasource(this.props.data);
    }
  };

  searchDeviceCode = async (value) => {
    let deviceDatasource = await _getDeviceBasedOnAssetID(this.props.data);
    const datasource =
      deviceDatasource &&
      Array.isArray(deviceDatasource) &&
      deviceDatasource.filter(function (device) {
        return (
          device.Name.toString().toLowerCase().includes(value.toLowerCase()) ||
          device.DeviceCode.toString().toLowerCase().includes(value.toLowerCase())
        );
      });
    this.setState({ datasource });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation(`Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <StyledButton
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          <SearchOutlined /> Search
        </StyledButton>
        <StyledButton
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </StyledButton>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text && text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  navigateDeviceNextPage = (e, row) => {
    let { deviceBreadcrumbList } = this.props;
    deviceBreadcrumbList.push(row);
    this.props.actions.setDeviceBreadcrumbs(deviceBreadcrumbList);
    this.props.actions.currentDevice(row);
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let {
      datasource,
      visible,
      deviceObject,
      deviceParameterBasedonDevice,
      deviceParametersBasedOnType,
      selectedDeviceParameterId,
      data,
      editObj,
      deviceTypeList,
      parameterType,
      parameterGroupList,
      parameterCategoryList,
      userProfile
    } = this.state;
    let permissionsArray = [];
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role']['Permission']
        .filter((item) => item.resource === 'asset_device')
        .map((name) => permissionsArray.push(name.action));

    return (
      <div style={{ margin: '20px 0px' }}>
        <AddItemDiv>
          <div></div>
          <div>
            <BadgeStyle count={datasource ? datasource.length : 0} />
            <SpanItem>{this.translation('Items')}</SpanItem>
            <StyledButton
              disabled={!permissionsArray.includes('create') ? true : false}
              onClick={(e) => this.openAssetDrawer(e, data.parentDeviceType)}
            >
              <PlusOutlined />
              {this.translation('Add')}
              <span style={{ padding: '0 5px' }}>
                {data && data.parentDeviceType
                  ? this.translation(`${_.startCase(_.toLower(data.parentDeviceType))}`)
                  : this.translation(`${_.startCase(_.toLower(''))}`)}
              </span>
            </StyledButton>
          </div>
        </AddItemDiv>

        <StyledTable
          columns={[
            {
              title:
                data && data.parentDeviceType
                  ? this.translation(`${_.startCase(_.toLower(data.parentDeviceType))} Code`)
                  : this.translation(`${_.startCase(_.toLower(''))} Code`),
              dataIndex: 'DeviceCode',
              key: 'DeviceCode',
              ...this.getColumnSearchProps('DeviceCode'),
              onCell: () => {
                return {
                  onClick: () => {}
                };
              }
            },
            {
              title:
                data && data.parentDeviceType
                  ? this.translation(`${_.startCase(_.toLower(data.parentDeviceType))} Name`)
                  : this.translation(`${_.startCase(_.toLower(''))} Name`),
              dataIndex: 'Name',
              key: 'Name',
              ...this.getColumnSearchProps('Name'),
              onCell: (record) => {
                return {
                  onClick: (e) => {
                    this.editRowonRowEdit(e, record);
                  }
                };
              },
              render: (text) => {
                return {
                  children: <span>{text}</span>
                };
              }
            },
            {
              title:
                data && data.parentDeviceType
                  ? this.translation(`${_.startCase(_.toLower(data.parentDeviceType))} Description`)
                  : this.translation(`${_.startCase(_.toLower(''))} Description`),
              dataIndex: 'Description',
              key: 'Description',
              ...this.getColumnSearchProps('Description'),
              onCell: (record) => {
                return {
                  onClick: (e) => {
                    this.editRowonRowEdit(e, record);
                  }
                };
              }
              // render: (text) => {
              //   let name =
              //     deviceTypeList &&
              //     Array.isArray(deviceTypeList) &&
              //     deviceTypeList.filter((deviceType) => {
              //       return deviceType.Id === text;
              //     });
              //   return {
              //     children: <span>{name && name[0] && name[0].Name ? name[0].Name : ''}</span>
              //   };
              // }
            },
            // {
            //   title:
            //     data && data.parentDeviceType
            //       ? this.translation(`${_.startCase(_.toLower(data.parentDeviceType))} TimeSeries`)
            //       : this.translation(`${_.startCase(_.toLower(''))} TimeSeries`),
            //   dataIndex: 'TimeSeries',
            //   key: 'TimeSeries',
            //   ...this.getColumnSearchProps('TimeSeries'),
            //   render: (text) => {
            //     return {
            //       children: <span>{text && text !== null ? text.toString() : text.toString()}</span>
            //     };
            //   }
            // },
            {
              title: this.translation('Actions'),
              dataIndex: '',
              key: '',
              render: (text, row) => {
                return {
                  props: {},
                  children: (
                    <StyledButton
                      type="StyledButton"
                      style={{ marginRight: '5px' }}
                      onClick={() => {
                        this.editConfigRow(row);
                      }}
                    >
                      <UnorderedListOutlined />
                    </StyledButton>
                  )
                };
              }
            },
            {
              title: this.translation('Action'),
              dataIndex: '_key',
              key: '_key',
              render: (text, row) => {
                return {
                  props: {},
                  children: (
                    <div>
                      <EditOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          this.editRowonRowEdit(e, row);
                        }}
                      />
                      <Divider type="vertical" />
                      <Popconfirm
                        title={this.translation('Are you sure delete Device?')}
                        onConfirm={(e) => {
                          e.stopPropagation();
                          this._deleteDevice(row);
                        }}
                        okText={this.translation('Yes')}
                        cancelText={this.translation('No')}
                      >
                        <DeleteOutlined
                          theme="twoTone"
                          twoToneColor="red"
                          type="delete"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </Popconfirm>
                      <Divider type="vertical" />
                      <StyledButton onClick={(e) => this.navigateDeviceNextPage(e, row)}>
                        {this.translation('Next')}
                      </StyledButton>
                    </div>
                  )
                };
              }
            }
          ]}
          dataSource={datasource}
          size="large"
          pagination={
            datasource && Array.isArray(datasource) && datasource.length > 10
              ? {
                  pageSize: '10'
                }
              : false
          }
        />

        <Drawer
          title={
            deviceObject && !deviceObject.Id
              ? data && data.parentDeviceType
                ? this.translation(`Create ${_.startCase(_.toLower(data.parentDeviceType))}`)
                : this.translation(`Create ${''}`)
              : data && data.parentDeviceType
              ? this.translation(`Update ${_.startCase(_.toLower(data.parentDeviceType))}`)
              : this.translation(`Update ${_.startCase(_.toLower(''))}`)
          }
          placement="right"
          width={400}
          closable
          onClose={this.onClose}
          visible={visible}
        >
          <Form
            layout="vertical"
            ref={this.deviceRef}
            name="user_form"
            initialValues={deviceObject || {}}
          >
            <Form.Item
              label={
                data && data.parentDeviceType
                  ? this.translation(`${_.startCase(_.toLower(data.parentDeviceType))} Code`)
                  : this.translation(`${_.startCase(_.toLower(''))} Code`)
              }
              name={'DeviceCode'}
              style={{ width: 300 }}
              placeholder={
                data && data.parentDeviceType
                  ? this.translation(
                      `Please enter ${_.startCase(_.toLower(data.parentDeviceType))} Code`
                    )
                  : this.translation(`Please enter ${_.startCase(_.toLower(''))} Code`)
              }
              rules={[
                {
                  required: true,
                  message:
                    data && data.parentDeviceType
                      ? this.translation(
                          `Please input ${_.startCase(_.toLower(data.parentDeviceType))} Code`
                        )
                      : this.translation(`Please enter ${_.startCase(_.toLower(''))} Code`)
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={
                data && data.parentDeviceType
                  ? this.translation(`${_.startCase(_.toLower(data.parentDeviceType))} Name`)
                  : this.translation(`${_.startCase(_.toLower(''))} Name`)
              }
              name={'Name'}
              style={{ width: 300 }}
              placeholder={
                data && data.parentDeviceType
                  ? this.translation(
                      `Please enter ${_.startCase(_.toLower(data.parentDeviceType))} Name`
                    )
                  : this.translation(`Please enter ${_.startCase(_.toLower(''))} Name`)
              }
              rules={[
                {
                  required: true,
                  message:
                    data && data.parentDeviceType
                      ? this.translation(
                          `Please input ${_.startCase(_.toLower(data.parentDeviceType))} Name`
                        )
                      : this.translation(`Please enter ${_.startCase(_.toLower(''))} Name`)
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                data && data.parentDeviceType
                  ? this.translation(`${_.startCase(_.toLower(data.parentDeviceType))} DisplayCode`)
                  : this.translation(`${_.startCase(_.toLower(''))} DisplayCode`)
              }
              name={'DisplayCode'}
              style={{ width: 300 }}
              placeholder={
                data && data.parentDeviceType
                  ? this.translation(
                      `Please enter ${_.startCase(_.toLower(data.parentDeviceType))} DisplayCode`
                    )
                  : this.translation(`Please enter ${_.startCase(_.toLower(''))} DisplayCode`)
              }
              rules={[
                {
                  required: true,
                  message:
                    data && data.parentDeviceType
                      ? this.translation(
                          `Please input ${_.startCase(
                            _.toLower(data.parentDeviceType)
                          )} DisplayCode`
                        )
                      : this.translation(`Please enter ${_.startCase(_.toLower(''))} DisplayCode`)
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={
                data && data.parentDeviceType
                  ? this.translation(`${_.startCase(_.toLower(data.parentDeviceType))} Description`)
                  : this.translation(`${_.startCase(_.toLower(''))} Description`)
              }
              name={'Description'}
              style={{ width: 300 }}
              placeholder={
                data && data.parentDeviceType
                  ? this.translation(
                      `Please enter ${_.startCase(_.toLower(data.parentDeviceType))} Description`
                    )
                  : this.translation(`Please enter ${_.startCase(_.toLower(''))} Description`)
              }
              rules={[
                {
                  required: true,
                  message:
                    data && data.parentDeviceType
                      ? this.translation(
                          `Please input ${_.startCase(
                            _.toLower(data.parentDeviceType)
                          )} Description`
                        )
                      : this.translation(`Please enter ${_.startCase(_.toLower(''))} Description`)
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                data && data.parentDeviceType
                  ? this.translation(`${_.startCase(_.toLower(data.parentDeviceType))} Type`)
                  : this.translation(`${_.startCase(_.toLower(''))} Type`)
              }
              name={'DeviceType'}
              style={{ width: 300 }}
              placeholder={
                data && data.parentDeviceType
                  ? this.translation(
                      `Please enter ${_.startCase(_.toLower(data.parentDeviceType))} Type`
                    )
                  : this.translation(`Please enter ${_.startCase(_.toLower(''))} Type`)
              }
              rules={[
                {
                  required: true,
                  message:
                    data && data.parentDeviceType
                      ? this.translation(
                          `Please input ${_.startCase(_.toLower(data.parentDeviceType))} Type`
                        )
                      : this.translation(`Please enter ${_.startCase(_.toLower(''))} Type`)
                }
              ]}
            >
              <Select
                placeholder={this.translation('Please enter Equipment Type')}
                defaultValue={deviceObject ? deviceObject.DeviceType : ''}
                style={{ width: 300 }}
                onChange={this.handleChange}
                disabled={deviceObject ? !!deviceObject.DeviceType : false}
              >
                {deviceTypeList
                  ? deviceTypeList.map((deviceType, index) => (
                      <Option key={index} value={deviceType.AliasCode}>
                        {deviceType.Name}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>

            <Form.Item
              label={
                data && data.parentDeviceType
                  ? this.translation(`${_.startCase(_.toLower(data.parentDeviceType))} TimeSeries`)
                  : this.translation(`${_.startCase(_.toLower(''))} TimeSeries`)
              }
              name={'TimeSeries'}
              style={{ width: 300 }}
              placeholder={
                data && data.parentDeviceType
                  ? this.translation(
                      `Please enter ${_.startCase(_.toLower(data.parentDeviceType))} TimeSeries`
                    )
                  : this.translation(`Please enter ${_.startCase(_.toLower(''))} TimeSeries`)
              }
              rules={[
                {
                  required: true,
                  message:
                    data && data.parentDeviceType
                      ? this.translation(
                          `Please input ${_.startCase(_.toLower(data.parentDeviceType))} TimeSeries`
                        )
                      : this.translation(`Please enter ${_.startCase(_.toLower(''))} TimeSeries`)
                }
              ]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label={this.translation('Asset')}
              display="none"
              name="Asset"
              hidden={true}
              initialValue={
                deviceObject && deviceObject.Asset
                  ? deviceObject.Asset
                  : this.props.data && this.props.data.parentAssetId
                  ? this.props.data.parentAssetId
                  : ''
              }
            >
              <Input style={{ display: 'none' }} />
            </Form.Item>
            <Form.Item
              label={this.translation('Id')}
              display="none"
              name="Id"
              hidden={true}
              initialValue={editObj.Id || ''}
            >
              <Input style={{ display: 'none' }} />
            </Form.Item>
          </Form>
          <DrawerFooter>
            <StyledButton onClick={this.onClose} style={{ backgroundColor: 'red', marginRight: 8 }}>
              {this.translation('Cancel')}
            </StyledButton>
            {deviceObject && deviceObject.Id === undefined ? (
              <StyledButton
                disabled={!permissionsArray.includes('create') ? true : false}
                onClick={this.createDevice}
              >
                {data && data.parentDeviceType
                  ? this.translation(`Create ${_.startCase(_.toLower(data.parentDeviceType))}`)
                  : this.translation(`Create ${_.startCase(_.toLower(''))}`)}
              </StyledButton>
            ) : (
              <StyledButton
                disabled={!permissionsArray.includes('update') ? true : false}
                onClick={this.updateDevice}
              >
                {data && data.parentDeviceType
                  ? this.translation(`Update ${_.startCase(_.toLower(data.parentDeviceType))}`)
                  : this.translation(`Update ${_.startCase(_.toLower(''))}`)}
              </StyledButton>
            )}
          </DrawerFooter>
        </Drawer>
        <Drawer
          title={this.translation('Configuration')}
          width={800}
          closable
          onClose={this.onCloseVisibleConfiguration}
          visible={this.state.visibleConfiguration}
          // style={{
          //   overflow: 'auto',
          //   height: 'calc(100% - 108px)'
          // }}
        >
          <div>
            <h4>{this.translation('Select Equipment Parameter :')}</h4>
            <div>
              <Select
                defaultValue={selectedDeviceParameterId || undefined}
                style={{ width: 300, marginRight: '15px' }}
                onChange={this.onChange}
                placeholder={this.translation('Select Equipment Parameters')}
              >
                {deviceParametersBasedOnType &&
                  Array.isArray(deviceParametersBasedOnType) &&
                  deviceParametersBasedOnType.map((deviceParameters, index) => {
                    return (
                      <Option key={index} value={deviceParameters.Id}>
                        {deviceParameters.Name}
                      </Option>
                    );
                  })}
              </Select>
              <Button type="primary" onClick={this.addDeviceParamter}>
                {this.translation('Add')}{' '}
              </Button>
            </div>
            {deviceParameterBasedonDevice.map((device, index) => {
              let abc =
                deviceParametersBasedOnType &&
                Array.isArray(deviceParametersBasedOnType) &&
                deviceParametersBasedOnType.filter((deviceParameter) => {
                  return deviceParameter.AliasCode === device.ParameterAlias;
                });
              return (
                <Collapse
                  key={index}
                  bordered={false}
                  style={{ marginTop: '20px' }}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0}></CaretRightOutlined>
                  )}
                >
                  <Panel
                    header={
                      <Fragment>
                        <h4 style={{ display: 'inline' }}>
                          {abc && abc[0] && abc[0].Parameter ? abc[0].Name : ''}-{' '}
                          {device.ParameterCode}
                        </h4>
                        <Popconfirm
                          title={this.translation(
                            'Are you sure delete link between parameter and device?'
                          )}
                          placement="top"
                          onConfirm={(e) => {
                            e.stopPropagation();
                            // this.deleteDeviceParameter(device.ParameterId);
                            this._deleteDeviceParameter(device.Id);
                          }}
                          onCancel={(e) => {
                            e.stopPropagation();
                          }}
                          okText={this.translation('Yes')}
                          cancelText={this.translation('No')}
                        >
                          <StyledButton
                            type="StyledButton"
                            className="ant-btn"
                            style={{
                              float: 'right',
                              marginRight: '5px',
                              marginTop: '-0.4vh'
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <DeleteOutlined
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </StyledButton>
                        </Popconfirm>
                      </Fragment>
                    }
                    key={device.Id}
                    style={customPanel}
                  >
                    <div style={{ padding: '0px 10px' }}>
                      <SaveOutlined
                        theme="twoTone"
                        style={{
                          fontSize: '20px',
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'end'
                        }}
                        onClick={() =>
                          this.saveDeviceParameter(
                            device,
                            device && device.Id ? 'update' : 'create'
                          )
                        }
                      ></SaveOutlined>

                      <div>
                        <label
                          style={{
                            display: 'block',
                            fontWeight: '500',
                            marginBottom: '4px'
                          }}
                        >
                          {this.translation('Parameter Type')}
                        </label>
                        <Select
                          disabled={device && device.Id ? true : false}
                          defaultValue={device.ParameterType || undefined}
                          style={{ width: 300, marginRight: '15px' }}
                          onChange={(e) => this.parameterTypeonChange(e, device.ParameterId)}
                          placeholder={this.translation('Select ParameterType')}
                        >
                          {parameterType &&
                            parameterType.map((deviceParamsType, index) => {
                              return (
                                <Option key={index} value={deviceParamsType.ParameterType}>
                                  {deviceParamsType.ParameterType}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div>
                        <label
                          style={{
                            display: 'block',
                            fontWeight: '500',
                            marginBottom: '4px'
                          }}
                        >
                          {this.translation('Parameter Category')}
                        </label>
                        <Select
                          disabled={device && device.Id ? true : false}
                          defaultValue={device.ParameterCategory || undefined}
                          style={{ width: 300, marginRight: '15px' }}
                          onChange={(e) => this.parameterCategoryonChange(e, device.ParameterId)}
                          placeholder={this.translation('Select Parameter Category')}
                        >
                          {parameterCategoryList &&
                            Array.isArray(parameterCategoryList) &&
                            parameterCategoryList.map((parameterCategory, index) => {
                              return (
                                <Option key={index} value={parameterCategory.Code}>
                                  {parameterCategory.Name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div>
                        <label
                          style={{
                            display: 'block',
                            fontWeight: '500',
                            marginBottom: '4px'
                          }}
                        >
                          {this.translation('Parameter Group')}
                        </label>
                        <Select
                          disabled={device && device.Id ? true : false}
                          defaultValue={device.ParameterGroup || undefined}
                          style={{ width: 300, marginRight: '15px' }}
                          onChange={(e) => this.parameterGrouponChange(e, device.ParameterId)}
                          placeholder={this.translation('Select Parameter Group')}
                        >
                          {parameterGroupList &&
                            Array.isArray(parameterGroupList) &&
                            parameterGroupList.map((parameterGroup, index) => {
                              return (
                                <Option key={index} value={parameterGroup.ParameterGroup}>
                                  {parameterGroup.ParameterGroup}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div>
                        <label
                          style={{
                            display: 'block',
                            fontWeight: '500',
                            marginBottom: '4px'
                          }}
                        >
                          {this.translation('Edge Code')}
                        </label>
                        <Input
                          disabled={device && device.Id ? true : false}
                          value={device.EdgeCode || undefined}
                          style={{ width: 300, marginRight: '15px' }}
                          placeholder={this.translation('Input Edge Code')}
                          onChange={(e) =>
                            this.edgeCodeOnChange(e.target.value, device.ParameterId)
                          }
                        />
                      </div>
                      <div>
                        <label
                          style={{
                            display: 'block',
                            fontWeight: '500',
                            marginBottom: '4px'
                          }}
                        >
                          Parameter Description
                        </label>
                        <Input
                          value={device.ParameterDescription || undefined}
                          style={{ width: 300, marginRight: '15px' }}
                          placeholder="Input Parameter Description"
                          onChange={(e) =>
                            this.ParameterDescriptionOnChange(e.target.value, device.ParameterId)
                          }
                        />
                      </div>
                      <div>
                        <label
                          style={{
                            display: 'block',
                            fontWeight: '500',
                            marginBottom: '4px'
                          }}
                        >
                          Display Name
                        </label>
                        <Input
                          value={device.DisplayName || undefined}
                          style={{ width: 300, marginRight: '15px' }}
                          placeholder="Input Display Name"
                          onChange={(e) =>
                            this.DisplayNameOnChange(e.target.value, device.ParameterId)
                          }
                        />
                      </div>
                      <DeviceTagReference
                        conditionReference={device}
                        tagReferenceList={this.state.tagReferenceList}
                        saveTagReferenceList={this.saveTagReferenceList}
                      />
                    </div>
                  </Panel>
                </Collapse>
              );
            })}
          </div>
        </Drawer>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        currentDevice,
        setDeviceBreadcrumbs
      },
      dispatch
    )
  };
}

const mapStateToProps = createStructuredSelector({
  deviceBreadcrumbList: getDeviceBreadcrumbList(),
  translationCache: getTranslationCache(),
  userProfile: getUserProfile()
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceComponent);
