import { Table, message } from 'antd';
import * as XLSX from 'xlsx';
import React, { Component } from 'react';

import { StyledApplication } from './CSS/style';
import './CSS/style.css';
import { getTableListFromAPI, uploadJsonData } from './Utils/apicalls';
import { DownloadOutlined } from '@ant-design/icons';
import localStorage from '../../../../../utils/localStorage';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getCurrentLanguage, getTranslationCache } from '../../../../../selectors/language';
import { IPAddress, SAMPLE } from '../../../../../commons/api';

class InHouseRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      translationList: {
        'Hole Number': '穴番号',
        'Gun Powder Quantity': '薬量',
        Design: '設計',
        Actual: '実績',
        'No Of Explosives with Detonators': '親ダイ',
        'No Of Explosives without Detonators': '増ダイ'
      }
    };
  }
  componentDidMount() {
    this.getTableList();
  }

  getTableList = async () => {
    let dataSource = await getTableListFromAPI();
    this.setState({ dataSource });
  };

  onFileUpload = (e) => {
    e.preventDefault();
    var files = e.target.files;
    var f = files[0];
    var reader = new FileReader();

    reader.onload = async (e) => {
      var dataa = e.target.result;
      let readedData = XLSX.read(dataa, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      let data =
        dataParse &&
        Array.isArray(dataParse) &&
        dataParse
          .filter((e, i) => i > 2)
          .map((data) => {
            return {
              HoleNumber: data[0],
              NoOfExplosivesWithDetonators_Design: data[1],
              NoOfExplosivesWithOutDetonators_Design: data[2],
              NoOfExplosivesWithDetonators_Actual: data[3],
              NoOfExplosivesWithOutDetonators_Actual: data[4]
            };
          });
      let saveExcel = await uploadJsonData(data);
      if (saveExcel && saveExcel.message) {
        message.success(saveExcel.message);
        this.getTableList();
      }
    };
    reader.readAsBinaryString(f);
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let { dataSource } = this.state;
    let columns = [
      {
        title: this.translation('Hole Number'),
        dataIndex: 'HoleNumber',
        key: 'HoleNumber'
      },
      {
        title: this.translation('Gun Powder Quantity'),
        dataIndex: 'GunPowderQuantity',
        key: 'GunPowderQuantity',
        children: [
          {
            title: this.translation('Design'),
            dataIndex: 'Design',
            key: 'Design',
            children: [
              {
                title: this.translation('No Of Explosives with Detonators'),
                dataIndex: 'NoOfExplosivesWithDetonators_Design',
                key: 'NoOfExplosivesWithDetonators_Design'
              },
              {
                title: this.translation('No Of Explosives without Detonators'),
                dataIndex: 'NoOfExplosivesWithOutDetonators_Design',
                key: 'NoOfExplosivesWithOutDetonators_Design'
              }
            ]
          },
          {
            title: this.translation('Actual'),
            dataIndex: 'Actual',
            key: 'Actual',
            children: [
              {
                title: this.translation('No Of Explosives with Detonators'),
                dataIndex: 'NoOfExplosivesWithDetonators_Actual',
                key: 'NoOfExplosivesWithDetonators_Actual'
              },
              {
                title: this.translation('No Of Explosives without Detonators'),
                dataIndex: 'NoOfExplosivesWithOutDetonators_Actual',
                key: 'NoOfExplosivesWithOutDetonators_Actual'
              }
            ]
          }
        ]
      }
    ];
    const siteId = localStorage.get('currentSite');
    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 113 }}>
        <div className="headerCSS">{this.translation('In-House Standard Registration')}</div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <input type="file" style={{ margin: '5px 0px' }} onChange={this.onFileUpload} />
          <div style={{ margin: '5px 0px', fontWeight: '18px' }}>
            <DownloadOutlined style={{ marginRight: '2px' }} />
            <a
              target={'_blank'}
              style={{ color: '#262222' }}
              href={`${IPAddress.LOCAL}${SAMPLE.Okumura}?SiteId=${siteId}`}
              rel="noopener noreferrer"
            >
              {this.translation('Download Default Template')}
            </a>
          </div>
        </div>
        <div>
          <Table
            className="downtimeTable"
            columns={columns}
            bordered={true}
            dataSource={dataSource}
          />
        </div>
      </StyledApplication>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(InHouseRegistration);
