import React, { Component } from 'react';
import { Select, message, Button, Form } from 'antd';
import axios from 'axios';

import localStorage from '../../../utils/localStorage';
import themeSettings from '../../Modules/DataBackup/Utils/themeSettings.json';
import { GETUSERLIST, CUSTOM_REPORT } from '../../../commons/api';
import { StyledForm } from './styles';
const { Option } = Select;
class DailyReportUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      userList: [],
      ModalVisible: false
    };
  }
  href = React.createRef();
  componentDidMount = async () => {
    let selectedUsers = [];
    let userList = await this.getUsersList();
    let response = await this.getTableData();
    if (response) {
      selectedUsers =
        response &&
        Array.isArray(response) &&
        response.map((resp) => {
          return resp.Id;
        });
    }
    this.href.current &&
      this.href.current.setFieldsValue({
        Users: selectedUsers
      });

    this.setState({ userList, selectedUsers });
  };
  getTableData = async () => {
    let siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    if (!siteId) {
      siteId = 'test';
    }
    let Headers = {
      method: 'GET',
      url: `${CUSTOM_REPORT.RecipientsList}?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(Headers)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getUsersList = async () => {
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const siteId = localStorage.get('currentSite');
    let data = {
      url: `${GETUSERLIST.GET}?SiteId=${siteId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization
      }
    };
    return axios(data)
      .then((response) => {
        return response.data;
      })

      .catch((error) => {
        message.error(
          `${error && error.response && error.response.data && error.response.data.message}`
        );
      });
  };
  CreateUser = async () => {
    let { selectedUsers } = this.state;
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    let Authorization = 'Bearer ' + token;
    const getReportData = {
      method: 'POST',
      url: `${CUSTOM_REPORT.CreateRecipients}?SiteId=${siteId}`,
      headers: {
        Authorization: Authorization
      },
      data: {
        SiteId: siteId,
        UserId: selectedUsers
      }
    };
    return axios(getReportData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error && error.response && error.response.status && error.response.status === 400) {
          message.info(
            error && error.response && error.response.data && error.response.data.message
          );
        }
      });
  };
  onModalok = async () => {
    let { selectedUsers } = this.state;
    let response = await this.CreateUser();
    if (response && response.status && response.status === 200) {
      message.success(response && response.data && response.data.message);
    }
    this.getTableData();
    this.href.current &&
      this.href.current.setFieldsValue({
        Users: selectedUsers
      });
    this.setState({ ModalVisible: false });
  };
  onModalCancel = () => {
    this.setState({ ModalVisible: false });
  };

  userOnchange = (value) => {
    this.setState({ selectedUsers: value });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { userList } = this.state;

    const formItemLayout = { width: '100%', flex: '50%' };

    return (
      <div>
        <StyledForm
          name="user_form"
          autoComplete="off"
          ref={this.href}
          onFinish={this.onModalok}
          // initialValues={formObject}
          theme={themeSettings}
          layout="horizontal"
          labelCol={{ flex: '200px' }}
          labelAlign="right"
          labelWrap
          // validateMessages={validateMessages}
          wrapperCol={{ flex: 1 }}
        >
          <Form.Item name={'Users'} style={formItemLayout} label={this.translation('Users')}>
            <Select
              style={{ width: '400px' }}
              mode="multiple"
              showArrow
              value={this.state.selectedUsers}
              placeholder={this.translation('Please Select Users')}
              onChange={this.userOnchange}
            >
              {userList &&
                Array.isArray(userList) &&
                userList.map((user, index) => {
                  return (
                    <Option key={index} value={user.Id}>
                      {user.Email}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <div style={{ marginLeft: '600px', marginTop: '40px' }}>
            <Button type="primary" htmlType="submit">
              {' '}
              {this.translation('Submit')}
            </Button>
          </div>
        </StyledForm>
      </div>
    );
  }
}

export default DailyReportUsers;
