import React, { Component } from 'react';
import { Form, Select, Button, Tabs, Row, Col, Table } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import ReactEcharts from '../Echarts/Echarts';
import { getTranslationCache } from '../../../../../selectors/language';
const { TabPane } = Tabs;

class Calibration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translationCache: props.translationCache || [],
      option: {
        xAxis: {
          type: 'category',
          data: [0.0, 0.15, 0.3, 0.45, 0.6, 0.75, 1.0]
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [2000, 3000, 4000, 5000, 6000, 7000],
            type: 'line'
          }
        ]
      }
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    };
    const buttonItemLayout = {
      wrapperCol: {
        span: 14,
        offset: 4
      }
    };
    const columns = [
      {
        title: this.translation('Name'),
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: this.translation('Sample Name'),
        dataIndex: 'age',
        key: 'age'
      },
      {
        title: this.translation('Date'),
        dataIndex: 'address',
        key: 'address'
      },
      {
        title: this.translation('STD Value'),
        dataIndex: 'address',
        key: 'address'
      }
    ];
    const dataSource = [{}, {}];

    const columns1 = [
      {
        title: this.translation('Item'),
        dataIndex: 'Item',
        key: 'Item'
      }
    ];

    const columns2 = [
      {
        title: this.translation('Standard Value'),
        dataIndex: 'No',
        key: 'No'
      }
    ];
    const dataSource1 = [{}, {}];

    return (
      <div>
        <Form
          {...formItemLayout}
          layout="horizontal"
          name={this.translation('HeaderNumber_current')}
          style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px 0px', padding: '30px' }}
        >
          <Form.Item label="HD">
            <Select style={{ width: 220 }}>
              <Select.Option value="01">01</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button type="primary">{this.translation('Connection')}</Button>
          </Form.Item>
        </Form>

        <Form
          {...formItemLayout}
          layout="horizontal"
          name="HeaderNumber_current"
          className="formComponent"
        >
          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab={this.translation('Calibration Data')} key={'CD'}>
              <div>
                <Row>
                  <Col flex="auto">
                    <Table
                      dataSource={dataSource}
                      columns={columns}
                      pagination={false}
                      // scroll={{ x: 2000, y: 500 }}
                      bordered
                    />
                  </Col>
                </Row>
              </div>
            </TabPane>
            <TabPane tab={this.translation('Calibration Result')} key={'CR'}>
              <Form.Item label="Number of Recommended Factor">
                <Select style={{ width: 220 }}>
                  <Select.Option value="01">01</Select.Option>
                </Select>
              </Form.Item>
              <div
                style={{ backgroundColor: 'rgb(230, 247, 255)', margin: '19px', padding: '30px' }}
              >
                <Table dataSource={dataSource1} columns={columns1} pagination={false} bordered />
              </div>
              <div
                style={{ backgroundColor: 'rgb(230, 247, 255)', margin: '19px', padding: '30px' }}
              >
                <Table dataSource={dataSource1} columns={columns2} pagination={false} bordered />
              </div>
            </TabPane>
            <TabPane tab={this.translation('Calibration Graph')} key={'CG'}>
              <Form.Item label="Factor Select Recommended">
                <Select style={{ width: 220 }}>
                  <Select.Option value="01">01</Select.Option>
                </Select>
              </Form.Item>

              <Row>
                <Col flex={3}>
                  <Form
                    {...formItemLayout}
                    layout="horizontal"
                    name={this.translation('form1')}
                    style={{
                      backgroundColor: 'rgb(230, 247, 255)',
                      margin: '19px',
                      padding: '30px'
                    }}
                    className="formComponent"
                  >
                    <Form.Item label={this.translation('Coefficient')}>
                      <div>R -</div>
                    </Form.Item>
                    <Form.Item label={this.translation('Standard Error')}>
                      <div>SEC -</div>
                    </Form.Item>
                    <Form.Item label={this.translation('Number Of Samples')}>
                      <div>N -</div>
                    </Form.Item>
                    <Form.Item label={this.translation('Sample County Count')}>
                      <div>GN -</div>
                    </Form.Item>
                  </Form>
                </Col>
                <Col flex={3}>
                  <Form
                    {...formItemLayout}
                    layout="horizontal"
                    name={this.translation('form2')}
                    style={{
                      backgroundColor: 'rgb(230, 247, 255)',
                      margin: '19px',
                      padding: '30px'
                    }}
                    className="formComponent"
                  >
                    <Form.Item label={this.translation('No')}>
                      <Select style={{ width: 220 }}>
                        <Select.Option value="01">01</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label={this.translation('Standard Value')}></Form.Item>
                    <Form.Item label={this.translation('Predicted Value')}></Form.Item>
                    <Form.Item label={this.translation('Residual Error')}></Form.Item>
                  </Form>
                </Col>
              </Row>
              <ReactEcharts
                option={this.state.option}
                notMerge={true}
                lazyUpdate={true}
                style={{ backgroundColor: 'rgb(230, 247, 255)', margin: '19px', padding: '30px' }}
                className="react_for_echarts"
              />
              <Button type="primary" style={{ margin: '10px' }}>
                {this.translation('Write By KB-30')}
              </Button>
            </TabPane>
          </Tabs>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(Calibration);
