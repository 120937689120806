export const data = [
  [
    '21-04-2020 12:01',
    2,
    4,
    [
      {
        predictionTime: '10 Minute',
        tagName: 'TRR_PIC301_PV',
        tagActualValue: '4',
        tagPredictedRange: '4.45 - 6.45',
        severity: 'critical'
      },
      {
        predictionTime: '10 Minute',
        tagName: 'TRR_TIC301_PV',
        tagActualValue: '5.55',
        tagPredictedRange: '5.45 - 7.45',
        severity: 'low'
      },
      {
        predictionTime: '9 Minute',
        tagName: 'TRR_TIC301_PV',
        tagActualValue: '7',
        tagPredictedRange: '6.45 - 9.45',
        severity: 'medium'
      },
      {
        predictionTime: '8 Minute',
        tagName: 'TRR_TIC301_PV',
        tagActualValue: '5',
        tagPredictedRange: '4.45 - 5.45',
        severity: 'low'
      }
    ]
  ],
  ['21-04-2020 12:02', 3, 4],
  ['21-04-2020 12:03', 6, null],
  ['21-04-2020 12:04', 3.4],
  ['21-04-2020 12:05', 2, null],
  ['21-04-2020 12:06', 2, null],
  ['21-04-2020 12:07', 1, 4],
  ['21-04-2020 12:08', 2, 4],
  ['21-04-2020 12:09', 1, 4],
  ['21-04-2020 12:10', 8, 4],
  ['21-04-2020 12:11', 2, 4]
];

export const data1 = [
  ['2020-04-08 12:01', 212],
  ['2020-04-08 12:02', 62],
  ['2020-04-08 12:03', 200],
  ['2020-04-08 12:04', 62],
  ['2020-04-08 12:05', 400],
  ['2020-04-08 12:06', 62],
  ['2020-04-08 12:07', 200],
  ['2020-04-08 12:08', 62],
  ['2020-04-08 12:09', 200],
  ['2020-04-08 12:10', 62],
  ['2020-04-08 12:11', 350],
  ['2020-04-08 12:12', 62],
  ['2020-04-08 12:13', 200],
  ['2020-04-08 12:14', 62],
  ['2020-04-08 12:15', 400],
  ['2020-04-08 12:16', 62],
  ['2020-04-08 12:17', 200],
  ['2020-04-08 12:18', 62],
  ['2020-04-08 12:19', 200],
  ['2020-04-08 12:20', 62],
  ['2020-04-08 12:21', 350],
  ['2020-04-08 12:12', 22],
  ['2020-04-08 12:23', 200],
  ['2020-04-08 12:24', 62],
  ['2020-04-08 12:25', 400],
  ['2020-04-08 12:26', 62],
  ['2020-04-08 12:27', 200],
  ['2020-04-08 12:28', 62],
  ['2020-04-08 12:29', 200],
  ['2020-04-08 12:30', 62],
  ['2020-04-08 12:31', 350],
  ['2020-04-08 12:32', 62],
  ['2020-04-08 12:33', 200],
  ['2020-04-08 12:34', 62],
  ['2020-04-08 12:35', 400],
  ['2020-04-08 12:36', 62],
  ['2020-04-08 12:37', 200],
  ['2020-04-08 12:38', 62],
  ['2020-04-08 12:39', 200],
  ['2020-04-08 12:40', 62],
  ['2020-04-08 12:41', 350],
  ['2020-04-08 12:42', 22]
];

export const arrayOfObjects = [
  {
    DataTime: '2022-03-01 06:24:00',
    count: 0,
    status: 'null'
  },
  {
    DataTime: '2022-03-01 06:25:00',
    count: 1,
    status: 'null'
  },
  {
    DataTime: '2022-03-01 06:26:00',
    count: 2,
    status: 'null'
  },
  {
    DataTime: '2022-03-01 06:27:00',
    count: 3,
    status: 'null'
  },
  {
    DataTime: '2022-03-01 06:28:00',
    count: 4,
    status: 'null'
  },
  {
    DataTime: '2022-03-01 06:29:00',
    count: 5,
    status: 'null'
  },
  {
    DataTime: '2022-03-01 06:30:00',
    count: 4,
    status: 4
  }
];
