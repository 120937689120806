import { Tabs, Table, Form, Button, Select, Tooltip, message } from 'antd';
import React from 'react';
import { EditOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { getTranslationCache } from '../../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import axios from 'axios';
import localStorage from '../../../../utils/localStorage';
import { StyledApplication } from '../style';
import {
  API_DEVICE,
  API_PARAMETER,
  API_DEVICETYPE,
  API_DOWNLOAD,
  TOPIC,
  IPAddress
} from '../../../../commons/api';

const { Option } = Select;
const { TabPane } = Tabs;
class TopicList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableDetails: true,
      disabledDropdown: false,
      datasource: [],
      formObject: {},
      formObjectBulk: {},
      initialForm: {},
      deviceType: [],
      device: [],
      ParameterList: [],
      activeKey: 'List',
      activeDetails: '1',
      translationCache: props.translationCache || [],
      topicName: '',
      submitData: false,
      resultFile: '',
      deviceTypes: ''
    };
  }
  hRef = React.createRef();
  hForm = React.createRef();
  hFile = React.createRef();

  componentDidMount() {
    this.getMasterList();
    this.DeviceTypeApi();
  }

  DeviceTypeApi = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const type = {
      method: 'GET',
      url: `${API_DEVICETYPE.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(type)
      .then((response) => {
        this.setState({
          deviceType: response.data
        });
      })
      .catch(() => {});
  };

  DeviceApi = (data) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const type = {
      method: 'Get',
      url: `${API_DEVICE.GET}?SiteId=${siteId}&DeviceType=${data}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(type)
      .then((response) => {
        this.setState({
          device: response.data
        });
      })
      .catch(() => {});
  };

  ParameterApi = (param) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const parameter = {
      method: 'GET',
      url: `${API_PARAMETER.LIST}?SiteId=${siteId}&Device=${param}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(parameter)
      .then((response) => {
        this.setState({
          ParameterList: response.data
        });
      })
      .catch(() => {});
  };

  downloadApi = (name) => {
    let accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');

    let options = {
      method: 'GET',
      url: `${TOPIC.DOWNLOAD}?SiteId=${siteId}&TopicName=${name}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(options)
      .then((response) => {
        window.open(`${IPAddress.LOCAL}${API_DOWNLOAD.DownLoads}/${response.data.file}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  uploadApi = (formData) => {
    let { topicId, formObjectBulk } = this.state;
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    let Authorization = 'Bearer ' + token;
    const getRootCauseInfo = {
      method: 'POST',
      url: `${TOPIC.UPLOAD}?SiteId=${siteId}&TopicName=${topicId}`,
      headers: {
        //  'Content-Type': 'application/json',
        Authorization: Authorization
      },
      data: formData,
      formObjectBulk
    };
    return axios(getRootCauseInfo)
      .then((response) => {
        if (response && response.data && response.data.message) {
          message.success(response.data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 500) {
          message.info(err.response.data.message);
        }
      });
  };

  submitApi = (values) => {
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    let Authorization = 'Bearer ' + token;
    const getRootCauseInfo = {
      method: 'POST',
      url: `${API_PARAMETER.UpdateTopics}?SiteId=${siteId}`,
      headers: {
        Authorization: Authorization
      },
      data: values
    };
    return axios(getRootCauseInfo)
      .then((response) => {
        if (response && response.data && response.data.message) {
          message.success(response.data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 500) {
          message.info(err.response.data.message);
        }
      });
  };

  _editRecord = (record) => {
    if (this.hRef.current) {
      this.hRef.current.setFieldsValue(record);
    }
    if (this.hForm.current) {
      this.hForm.current && this.hForm.current.setFieldsValue(record);
    }
    this.setState({
      formObject: record,
      formObjectBulk: record,
      activeKey: 'Details',
      activeDetails: '1',
      defaulttest: '2',
      disableDetails: false,
      disabledDropdown: true,
      topicId: record.Id
    });
  };
  getMasterList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'GET',
      url: `${TOPIC.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(Object)
      .then((response) => {
        this.setState({
          datasource: response.data
        });
      })
      .catch(() => {});
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  devicSelection = (value) => {
    this.setState({ deviceTypes: value });
    this.DeviceApi(value);
  };
  device = (value) => {
    this.setState({ deviceCode: value });
    this.ParameterApi(value);
  };

  onFinish = async () => {
    let { deviceCode, topicId, deviceTypes } = this.state;
    this.hRef.current &&
      this.hRef.current.validateFields().then(async (values) => {
        let saveObject = {
          Topic: topicId,
          Parameter: values.Parameter,
          deviceType: deviceTypes,
          DeviceCode: deviceCode
        };
        await this.submitApi(saveObject);
        this.setState({ saveObject });
      });
  };

  onFinishs = async () => {
    let { file } = this.state;
    const formData = new FormData();
    formData.append('file', file);
    await this.uploadApi(formData);
    this.hForm.current && this.hForm.current.resetFields();
    this.setState({ file: null });
    if (this.hFile.current) {
      this.hFile.current.value = '';
    }
    this.hRef.current &&
      this.hRef.current.setFieldsValue({
        Parameter: [],
        deviceType: null,
        DeviceCode: null
      });
    this.setState({
      activeKey: 'List',
      activeDetails: '1',
      formObjectBulk: {}
    });
  };

  // * @author harshitha.k
  // * @description beforeupload method function is used to read file data & without using api

  filebeforeUpload = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      this.setState({ resultFile: e.target.result });
    };
    reader.readAsText(file);

    return false;
  };

  filehandleChange = (info) => {
    let fileData = info.file.name;
    this.setState({ fileData });
  };
  TabChange = (activeKey) => {
    if (activeKey === 'Details') {
      this.setState({ activeKey });
    } else if (activeKey === 'List') {
      this.hRef.current &&
        this.hRef.current.setFieldsValue({
          Topic: null,
          deviceType: null,
          DeviceCode: null,
          Parameter: []
        });
      this.setState({
        activeKey,
        disableDetails: true,
        formObject: {},
        activeDetails: '1',
        formObjectBulk: {}
      });
    }
  };

  handleClick = () => {
    this.hFile.current && this.hFile.current.click();
  };

  render() {
    let {
      datasource,
      formObject,
      disableDetails,
      disabledDropdown,
      deviceType,
      device,
      ParameterList,
      activeKey,
      activeDetails,
      formObjectBulk
    } = this.state;
    let columns = [
      {
        title: this.translation('Topic'),
        dataIndex: 'Topic',
        key: 'Topic'
      },
      {
        title: this.translation('Action'),
        dataIndex: 'Action',
        key: 'Action',
        render: (text, record) => (
          <>
            <Tooltip placement="top" title={this.translation('edit')}>
              <Button className="ant-btn" onClick={() => this._editRecord(record)} size="middle">
                <EditOutlined />
              </Button>
            </Tooltip>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.downloadApi(record.Topic);
              }}
              style={{ marginLeft: '10px' }}
              className="ant-btn"
            >
              <DownloadOutlined />
            </button>
          </>
        )
      }
    ];
    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 226 }}>
        <Tabs
          type="card"
          defaultActiveKey="List"
          activeKey={activeKey}
          // onChange={(activeKey) => {
          //   if (activeKey === 'Details') {
          //     this.setState({ activeKey});
          //   }
          //   if (activeKey === 'List') {
          //     this.setState({ activeKey,
          //       disableDetails:true,
          //       formObject:{}
          //     });
          //   }
          //   this.setState({ activeKey });
          // }}
          onChange={(activeKey) => this.TabChange(activeKey)}
        >
          <TabPane tab={this.translation('List')} key="List">
            <Table columns={columns} dataSource={datasource} />
          </TabPane>

          <TabPane tab={this.translation('Details')} key="Details" disabled={disableDetails}>
            <Tabs
              tabPosition="left"
              defaultActiveKey="1"
              activeKey={activeDetails}
              onChange={(activeDetails) => {
                if (activeDetails === '2') {
                  this.hRef.current &&
                    this.hRef.current.setFieldsValue({
                      deviceType: null,
                      DeviceCode: null,
                      Parameter: []
                    });
                  this.setState({
                    activeDetails
                  });
                }
                if (activeDetails === '1') {
                  this.setState({
                    activeDetails,
                    formObjectBulk: {}
                  });
                }
              }}
            >
              <TabPane tab={this.translation('Manual')} key="1">
                <Form
                  labelCol={{
                    span: 4
                  }}
                  wrapperCol={{
                    span: 12
                  }}
                  layout="vertical"
                  ref={this.hRef}
                  name="Site_form"
                  initialValues={formObject}
                  onFinish={this.onFinish}
                >
                  <Form.Item
                    label={this.translation('Topic')}
                    name="Topic"
                    rules={[{ required: true, message: this.translation('Please select topic !') }]}
                  >
                    <Select disabled={disabledDropdown}>
                      <Option value="SP_MS_Operational">
                        {this.translation('SP_MS_Operational')}
                      </Option>
                      <Option value="SP_MS_Status">{this.translation('SP_MS_Status')}</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('DeviceType')}
                    name="deviceType"
                    rules={[
                      { required: true, message: this.translation('Please select deviceType !') }
                    ]}
                  >
                    <Select
                      placeholder={this.translation('Please select deviceType')}
                      onChange={this.devicSelection}
                    >
                      {deviceType.map((value, index) => {
                        return (
                          <Option key={index} value={value.AliasCode}>
                            {this.translation(value.Name)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Device')}
                    name="DeviceCode"
                    rules={[{ required: true, message: this.translation('Please enter device') }]}
                  >
                    <Select
                      onChange={this.device}
                      placeholder={this.translation('Please select device')}
                    >
                      {device.map((value, index) => {
                        return (
                          <Option key={index} value={value.DeviceCode}>
                            {this.translation(value.DeviceCode)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Parameter')}
                    name="Parameter"
                    rules={[{ required: true, message: this.translation('Please enter paramter') }]}
                  >
                    <Select mode="multiple" placeholder={this.translation('Please select pameter')}>
                      {ParameterList.map((value, index) => {
                        return (
                          <Option key={index} value={value.ParameterCode}>
                            {this.translation(value.ParameterDescription)}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {this.translation('Submit')}
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane>

              <TabPane tab={this.translation('Bulk')} key="2">
                <Form
                  labelCol={{
                    span: 4
                  }}
                  wrapperCol={{
                    span: 12
                  }}
                  layout="vertical"
                  ref={this.hForm}
                  name="Site_form"
                  initialValues={formObjectBulk}
                  onFinish={this.onFinishs}
                >
                  <Form.Item
                    label={this.translation('Topic')}
                    name="Topic"
                    rules={[{ required: true, message: this.translation('Please select topic !') }]}
                  >
                    <Select disabled={disabledDropdown}>
                      <Option value="SP_MS_Operational">
                        {this.translation('SP_MS_Operational')}
                      </Option>
                      <Option value="SP_MS_Status">{this.translation('SP_MS_Status')}</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="FileUpload"
                    rules={[{ required: false, message: this.translation('Please upload file !') }]}
                  >
                    <input
                      type="file"
                      ref={this.hFile}
                      onChange={(e) => {
                        let files = e.target.files;
                        for (const file of files) {
                          this.setState({ file });
                        }
                      }}
                      style={{ display: 'none' }}
                    />
                    <Button icon={<UploadOutlined />} onClick={this.handleClick}>
                      File Upload
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {this.translation('Submit')}
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          </TabPane>
        </Tabs>
      </StyledApplication>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(TopicList);
