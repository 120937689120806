import React from 'react';
import { Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  PicRightOutlined,
  RightSquareOutlined,
  UploadOutlined
} from '@ant-design/icons';

import ApplicationSettingsRouter from '../../../routes/applicationSettingsRoute';
import styles from '../../../commons/styles';
import { getTranslationCache } from '../../../selectors/language';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const { StyledWrapper, StyledWrapperLeft, StyledWrapperRight } = styles.layout;

class ApplicationSettings extends React.Component {
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    return (
      <StyledWrapper style={{ minHeight: window.innerHeight - 250 }}>
        <StyledWrapperLeft>
          <Menu mode="inline" defaultSelectedKeys={['configUpload']}>
            <Menu.Item
              key="configUpload"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/configurationUpload');
              }}
            >
              <UploadOutlined /> {this.translation('Configuration Upload')}
            </Menu.Item>
            <Menu.Item
              key="configUploadNew"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/configurationUploadNew');
              }}
            >
              <UploadOutlined /> {this.translation('Configuration Upload New')}
            </Menu.Item>
            <Menu.Item
              key="hierarchyUpload"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/hierarchyUpload');
              }}
            >
              <RightSquareOutlined />
              {this.translation('Default Hierarchy Upload')}
            </Menu.Item>
            <Menu.Item
              key="BridgeConfig"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/BridgeConfiguration');
              }}
            >
              <PicRightOutlined /> {this.translation('Bridge Configuration')}
            </Menu.Item>
            <Menu.Item
              key="EdgeConfig"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/EdgeConfiguration');
              }}
            >
              <MenuUnfoldOutlined /> {this.translation('Edge Configuration')}
            </Menu.Item>
            <Menu.Item
              key="ParameterHierarchy"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/ParameterHierarchy');
              }}
            >
              <MenuUnfoldOutlined /> {this.translation('Parameter Hierarchy')}
            </Menu.Item>

            <Menu.Item
              key="ValidationConfiguration"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/ValidationConfiguration');
              }}
            >
              <MenuUnfoldOutlined /> {this.translation('Validation Configuration')}
            </Menu.Item>

            <Menu.Item
              key="TopicMaster"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/TopicMaster');
              }}
            >
              <MenuUnfoldOutlined /> {this.translation('Topic Master')}
            </Menu.Item>
            <Menu.Item
              key="TopicList"
              onClick={() => {
                this.props.history.push('/rubus/configurationSettings/TopicList');
              }}
            >
              <MenuUnfoldOutlined /> {this.translation('Topic List')}
            </Menu.Item>
          </Menu>
        </StyledWrapperLeft>
        <StyledWrapperRight style={{ backgroundColor: 'white', margin: '5px 15px 13px 15px' }}>
          <ApplicationSettingsRouter />
        </StyledWrapperRight>
      </StyledWrapper>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(ApplicationSettings);
