export const types = {
  ASSET_CACHE: '@/ASSET_CACHE',
  ASSET_CACHE_SUCCESS: '@/ASSET_CACHE_SUCCESS',

  ASSET_DEVICE_LIST: '@/ASSET_DEVICE_LIST',
  DEVICE_CACHE_SUCCESS: '@/DEVICE_CACHE_SUCCESS',

  CREATE_ASSET: '@/CREATE_ASSET',
  UPDATE_ASSET: '@/UPDATE_ASSET',
  DELETE_ASSET: '@/DELETE_ASSET',

  SET_CURRENTLANGUAGE: '@/SET_CURRENTLANGUAGE',

  Current_ASSET: '@/Current_ASSET',
  SET_BREADCRUMB_LIST: '@/SET_BREADCRUMB_LIST',
  BREADCRUMB_LIST: '@/BREADCRUMB_LIST'
};
