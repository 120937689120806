/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getUserDetails, getUserProfile } from '../../selectors/layout';
import Sidebar from './Sidebar';
import { ChatContainer, MessageList } from './CSS/styles';
import ChatMessage from './ChatMessage';
import TypingIndicator from './TypingIndicator';
import ChatInput from './ChatInput';
import { getTranslationCache } from '../../selectors/language';
import { getLoggedInProfile } from '../../modules/layout/layout.duck';
import { constant } from './constants';
import translation from './Utils/translation';
import { getConversationList, setNewMessage } from './Utils/ApICalls';
import { bindActionCreators } from 'redux';

const Chat = (props) => {
  const bottomRef = useRef({});
  let [messages, setMessages] = useState([]);
  const [currentChatId, setCurrentChatId] = useState('');
  const [loading, setLoading] = useState(false);
  const [translationCache, setTranslationCache] = useState(props.translationCache || {});

  useEffect(() => {
    bottomRef.current.scrollIntoView({
      behavior: 'smooth'
    });
  }, [messages]);

  useEffect(() => {
    props.actions.getLoggedInProfile();
  }, []);

  const onNewChatCreated = async (ConversationId, Content) => {
    let getLastRecord = messages && Array.isArray(messages) ? messages[messages.length - 1] : [];
    let DebugMode =
      getLastRecord && getLastRecord.DebugStatus ? getLastRecord.DebugStatus : 'DISABLED';

    let payload = {
      ConversationId,
      Content,
      UserType: 'User',
      DebugMode
    };
    messages.push(payload);
    setMessages(messages);
    setLoading(true);
    let newMessageResp = await setNewMessage(payload);
    if (newMessageResp && newMessageResp.Id) {
      getConversationListData(ConversationId);
      setCurrentChatId(ConversationId);
    }
  };

  const getConversationListData = async (currentChatId) => {
    let messages = await getConversationList(currentChatId);
    setMessages(messages);
    setLoading(false);
  };

  const onChatSelected = (currentChatId) => {
    if (currentChatId) {
      getConversationListData(currentChatId);
      setCurrentChatId(currentChatId);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar onChatSelected={(chatId) => onChatSelected(chatId)} selectedChatId={currentChatId} />
      <>
        <ChatContainer>
          <>
            {messages && Array.isArray(messages) && messages.length > 0 ? (
              <>
                <MessageList>
                  <div style={{ margin: '20px' }}>
                    {messages.map((message, index) => (
                      <ChatMessage
                        key={index}
                        sender={message.UserType}
                        generatedDate={message.CreatedTime}
                        content={message.Content}
                        message={message}
                        Id={message.Id}
                        Feedback={message.Feedback}
                        isUser={message.UserType.toLowerCase() === 'user'}
                        refreshList={() => getConversationListData(currentChatId)}
                      />
                    ))}
                    <TypingIndicator isTyping={loading} />
                  </div>
                  <div ref={bottomRef} />
                </MessageList>
              </>
            ) : (
              <>
                <MessageList>
                  <div
                    style={{
                      margin: '20px',
                      fontWeight: 'bold',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <span>
                      {translation(
                        translationCache,
                        constant.Hi_there_Welcome_to_BM_GPT_How_can_i_help_you
                      )}
                    </span>
                  </div>
                  <div ref={bottomRef} />
                </MessageList>
              </>
            )}
          </>
          <ChatInput
            onNewUserMessage={(ConversationId, Content) =>
              onNewChatCreated(ConversationId, Content)
            }
            onNewChatCreated={(ConversationId, Content) =>
              onNewChatCreated(ConversationId, Content)
            }
            chatId={currentChatId}
            getConversationList={(ChatId) => getConversationListData(ChatId)}
            messages={messages}
          />
        </ChatContainer>
      </>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getLoggedInProfile
      },
      dispatch
    )
  };
};
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

const ChatApplication = connect(mapStateToProps, mapDispatchToProps)(Chat);

export default ChatApplication;
