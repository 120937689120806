import React from 'react';

import { getTranslationCache } from '../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import themeSettings from '../../AMM/Utils/themeSettings.json';
import { getUserProfile } from '../../../selectors/layout';
import localStorage from '../../../utils/localStorage';
import { StyledComponent, StyledTable, StyledForm } from './CSS/style';
import './CSS/style.css';
// import { DrawerFooter } from '../../../commons/styles/layout';
import {
  FormUpdate,
  FormSubmission,
  getUserList,
  getRelatedTags,
  GetUseCase,
  SubmitForm
} from '../APICalls/FormCalls';
import Highlighter from 'react-highlight-words';
// import { constant } from '../Utils/Constants';
import moment from 'moment';
import { ANALYTICS } from '../../../commons/api';
import {
  Button,
  Tabs,
  Form,
  Input,
  message,
  Select,
  Upload,
  Drawer,
  Tooltip,
  Badge
  // Space,
} from 'antd';
// import translation from '../Utils/translation';
import history from '../../../commons/history';
import CommentsForm from '../Details/CommentsForm';
import axios from 'axios';
import {
  EditOutlined,
  PlusOutlined,
  FieldTimeOutlined,
  UserOutlined,
  UploadOutlined,
  DownloadOutlined,
  CommentOutlined,
  SearchOutlined
} from '@ant-design/icons';
// const { Option } = Select;
const { TextArea } = Input;
const { Option } = Select;
// const IconText = ({ icon, text }) => (
//   <Space>
//     {React.createElement(icon)}
//     {text}
//   </Space>
// );
const APIList = {
  getList: `${ANALYTICS.PredictionGet}`,
  getListUsecase: `${ANALYTICS.UseCaseGet}`,
  getById: `${ANALYTICS.PredictionGetById}`,
  Create: `${ANALYTICS.UsecaseCreate}`,
  Update: `${ANALYTICS.UseCaseUpdate}`
};
const { TabPane } = Tabs;
// const { RangePicker } = DatePicker;
class UseCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      PredictionDatasource: [],
      AnalysisDatasource: [],
      translationCache: props.translationCache || {},
      userProfile: props && props.userProfile ? props.userProfile : {},
      useCaseModalVisible: false,
      usecaseName: '',
      showusecasedownloadModal: false
    };
  }
  hForm = React.createRef();
  myRef = React.createRef();
  componentDidMount = async () => {
    const pathSnippets = history.location.pathname
      .split(`/rubus/RubusAnalytics/usecase/`)
      .filter((i) => i);
    if (pathSnippets && pathSnippets[0]) {
      let useCaseLevel = pathSnippets[0].split('/').filter((i) => i);
      if (useCaseLevel[0] && useCaseLevel[0] !== 'New') {
        let formObject =
          history && history.location && history.location.state && history.location.state.formObject
            ? history.location.state.formObject
            : null;
        if (formObject && formObject !== null) {
          this.hForm.current &&
            this.hForm.current.setFieldsValue({
              Title: formObject && formObject.Title,
              Id: formObject && formObject.Id,
              RelatedTags: formObject && formObject.RelatedTags,
              CreatedBy: formObject && formObject.CreatedBy,
              CreatedTime:
                formObject &&
                formObject.CreatedTime &&
                moment(formObject.CreatedTime).format('YYYY-MM-DD HH:mm:ss'),
              Comments: formObject && formObject.Comments,
              UseCaseName: formObject && formObject.UseCaseName,
              Description: formObject && formObject.Description,
              Attachments: formObject && formObject.Attachments
            });
          let response = await GetUseCase(APIList.getList, useCaseLevel[0], 'Prediction');
          let response1 = await GetUseCase(APIList.getList, useCaseLevel[0], 'Analysis');
          let AnalyticsId =
            history &&
            history.location &&
            history.location.state &&
            history.location.state.AnalyticsId
              ? history.location.state.AnalyticsId
              : null;
          this.setState({
            AnalyticsId,
            PredictionDatasource: response && Array.isArray(response) ? response : [],
            AnalysisDatasource: response1 && Array.isArray(response1) ? response1 : [],
            formObject
          });
        }
      } else {
        let obj = {
          Title: null,
          Id: null,
          RelatedTags: [],
          CreatedBy:
            this.props &&
            this.props.userProfile &&
            this.props.userProfile.user &&
            this.props.userProfile.user.Id,
          CreatedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          Comments: [],
          UseCaseName: null,
          Description: null,
          Attachments: []
        };
        this.hForm.current &&
          this.hForm.current.setFieldsValue({
            CreatedBy:
              this.props &&
              this.props.userProfile &&
              this.props.userProfile.user &&
              this.props.userProfile.user.Id,
            CreatedTime: moment().format('YYYY-MM-DD HH:mm:ss')
          });
        this.setState({ formObject: obj });
      }
      let path =
        history && history.location && history.location.state && history.location.state.path
          ? history.location.state.path
          : null;
      let list = await getUserList();
      let relatedTags = await getRelatedTags();
      this.setState({
        usecaseName: useCaseLevel && useCaseLevel[0],
        users: list,
        relatedTags,
        path
      });
    }
  };
  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      this.props.userProfile !== prevProps.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  _editRecordPrediction = async (record) => {
    let { formObject } = this.state;

    let payload = {
      AnalyticsId: formObject && formObject.Id,
      Id: record.Id,
      Type: 'Prediction'
    };
    let data = await SubmitForm(payload, APIList.getById);
    history.push({
      pathname: `/rubus/RubusAnalytics/Tasks/${record.Id}`,
      state: {
        // Type:data
        formObject: data,
        TaskId: record.Id,
        AnalyticsId: formObject && formObject.Id,
        path: data && data.Path
      }
    });
    this.setState({ history, editData: data });
    this.props.renderForm(data && data.Path, 'edit', 'type');
  };
  _editRecordAnalysis = async (record) => {
    let { formObject } = this.state;

    let payload = {
      AnalyticsId: formObject && formObject.Id,
      Id: record.Id,
      Type: 'Analysis'
    };
    let data = await SubmitForm(payload, APIList.getById);
    history.push({
      pathname: `/rubus/RubusAnalytics/Tasks/${record.Id}`,
      state: {
        // Type:data
        formObject: data,
        TaskId: record.Id,
        AnalyticsId: formObject && formObject.Id,
        path: data && data.Path
      }
    });
    this.setState({ history, editData: data });
    this.props.renderForm(data && data.Path, 'edit', 'type');
  };

  addAnalysisTask = () => {
    let { formObject, editData, path } = this.state;
    history.push({
      pathname: '/rubus/RubusAnalytics/Tasks/New',
      state: {
        Type: 'Analysis',
        editData: editData,
        Id: formObject && formObject.Id,
        AnalyticsId: formObject && formObject.Id
      }
    });
    this.setState({ history });
    this.props.renderForm(path, 'New', 'Analysis');
  };
  addPredictionTask = () => {
    let { formObject, editData, path } = this.state;
    history.push({
      pathname: '/rubus/RubusAnalytics/Tasks/New',
      state: {
        Type: 'Prediction',
        editData: editData,
        Id: formObject && formObject.Id,
        AnalyticsId: formObject && formObject.Id
      }
    });
    this.setState({ history });
    this.props.renderForm(path, 'New', 'Prediction');
  };

  onFinish = async () => {
    let { formObject } = this.state;
    let data = this.hForm.current && this.hForm.current.getFieldsValue();
    this.hForm.current &&
      this.hForm.current
        .validateFields()
        .then(async () => {
          let payload = {
            // ...values,
            ...data,
            Comments: formObject && formObject.Comments,
            Attachments: formObject && formObject.Attachments,
            CreatedTime:
              data &&
              data.CreatedTime &&
              moment(data.CreatedTime).utc().format('YYYY-MM-DD HH:mm:ss')
          };

          if (formObject && formObject.Id) {
            // payload.Attachments = formObject.Attachments;
            let UpdateResponse = await FormUpdate(payload, APIList.Update);

            if (UpdateResponse && UpdateResponse.status === 200) {
              message.success(UpdateResponse && UpdateResponse.data && UpdateResponse.data.message);
              let editData = await this.editList(UpdateResponse.data.Id);
              this.hForm.current.setFieldsValue({
                Title: editData && editData.Title,
                Id: editData && editData.Id,
                RelatedTags: editData && editData.RelatedTags,
                CreatedBy: editData && editData.CreatedBy,
                CreatedTime:
                  editData &&
                  editData.CreatedTime &&
                  moment(editData.CreatedTime).format('YYYY-MM-DD HH:mm:ss'),
                Comments: editData && editData.Comments,
                UseCaseName: editData && editData.UseCaseName,
                Description: editData && editData.Description,
                Attachments: editData && editData.Attachments
              });
              history.push({
                pathname: `/rubus/RubusAnalytics/usecase/${editData.Id}`,
                state: {
                  formObject: editData,
                  Description: editData && editData.Description,
                  Id: editData && editData.Id,
                  path: editData && editData.Path
                }
              });
              this.props.renderForm(editData && editData.Path, 'edit', 'type');

              let response = await GetUseCase(
                APIList.getList,
                UpdateResponse.data.Id,
                'Prediction'
              );
              let response1 = await GetUseCase(APIList.getList, UpdateResponse.data.Id, 'Analysis');

              this.setState({
                formObject: editData,
                AnalyticsId: editData && editData.Id,
                PredictionDatasource: response && Array.isArray(response) ? response : [],
                AnalysisDatasource: response1 && Array.isArray(response1) ? response1 : []
              });
            }
          } else {
            let createResponse = await FormSubmission(payload, APIList.Create);
            if (createResponse && createResponse.status === 200) {
              message.success(createResponse && createResponse.data && createResponse.data.message);
              let editData = await this.editList(createResponse.data.Id);
              this.hForm.current &&
                this.hForm.current.setFieldsValue({
                  Title: editData && editData.Title,
                  Id: editData && editData.Id,
                  RelatedTags: editData && editData.RelatedTags,
                  CreatedBy: editData && editData.CreatedBy,
                  CreatedTime:
                    editData &&
                    editData.CreatedTime &&
                    moment(editData.CreatedTime).format('YYYY-MM-DD HH:mm:ss'),
                  Comments: editData && editData.Comments,
                  UseCaseName: editData && editData.UseCaseName,
                  Description: editData && editData.Description,
                  Attachments: editData && editData.Attachments
                });
              history.push({
                pathname: `/rubus/RubusAnalytics/usecase/${editData.Id}`,
                state: {
                  formObject: editData,
                  Description: editData && editData.Description,
                  Id: editData && editData.Id,
                  path: editData && editData.Path
                }
              });
              this.props.renderForm(editData && editData.Path, 'edit', 'type');

              let response = await GetUseCase(
                APIList.getList,
                createResponse.data.Id,
                'Prediction'
              );
              let response1 = await GetUseCase(APIList.getList, createResponse.data.Id, 'Analysis');

              this.setState({
                AnalyticsId: editData && editData.Id,
                formObject: editData,
                PredictionDatasource: response && Array.isArray(response) ? response : [],
                AnalysisDatasource: response1 && Array.isArray(response1) ? response1 : []
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };
  editList = async (Id) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const object = {
      method: 'GET',
      url: `${APIList.getListUsecase}?SiteId=${siteId}&Id=${Id}&Type=Usecase`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(object)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  };

  fileUpload = (info, key, index) => {
    let { formObject } = this.state;
    // let formObject = this.hForm.current.getFieldsValue();
    if (info.file.status === 'done') {
      let id =
        info && info.file && info.file.response && info.file.response && info.file.response.Id;
      let Name =
        info && info.file && info.file.response && info.file.response && info.file.response.Name;
      if (formObject && formObject[key] && formObject[key][index]) {
        if (formObject[key][index]['Attachments']) {
          formObject[key][index]['Attachments'] =
            this.state.formObject &&
            this.state.formObject[key] &&
            this.state.formObject[key][index] &&
            this.state.formObject[key][index]['Attachments']
              ? this.state.formObject[key][index]['Attachments']
              : [];

          formObject[key][index]['Attachments'].push({ Id: id, file: Name });
        } else {
          formObject[key][index]['Attachments'] = [];
          formObject[key][index]['Attachments'].push({ Id: id, file: Name });
        }
      } else {
        if (formObject && formObject['Attachments'] && formObject['Attachments'].length !== 0) {
          formObject['Attachments'] =
            this.state.formObject && this.state.formObject['Attachments']
              ? this.state.formObject['Attachments']
              : [];
          formObject['Attachments'].push({ Id: id, file: Name });
        } else {
          formObject['Attachments'].push({ Id: id, file: Name });
        }
      }
      this.setState({ formObject });
      // this.hForm.current.setFieldsValue(formObject);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  useCaseModalClose = () => {
    this.setState({ useCaseModalVisible: false });
  };

  SubmitComments = (value, key, index) => {
    // let formObject = this.hForm.current.getFieldValue();
    const { formObject } = this.state;
    if (formObject && formObject[key] && formObject[key][index]) {
      if (formObject[key][index]['Comments'] !== undefined) {
        formObject[key][index]['Comments'].push(
          ...value.filter((item) => {
            return item.Id === undefined;
          })
        );
        formObject[key][index]['Comments'] = formObject[key][index]['Comments'].filter(
          (obj, Index) => {
            return (
              Index ===
              formObject[key][index]['Comments'].findIndex((o) => obj.Comment === o.Comment)
            );
          }
        );
      } else {
        formObject[key][index]['Comments'] = value;
      }
    } else if (formObject && formObject[key] && formObject[key][index]) {
      if (
        formObject[key][index]['Comments'] !== undefined &&
        Array.isArray(formObject[key][index]['Comments'])
      ) {
        formObject[key][index]['Comments'].push(
          ...value.filter((item) => {
            return item.Id === undefined;
          })
        );
        formObject[key][index]['Comments'] = formObject[key][index]['Comments'].filter(
          (obj, Index) => {
            return (
              Index ===
              formObject[key][index]['Comments'].findIndex((o) => obj.Comment === o.Comment)
            );
          }
        );
      } else {
        formObject[key][index]['Comments'] = value;
      }
    } else {
      if (formObject && formObject['Comments'] && formObject['Comments'] !== undefined) {
        formObject['Comments'].push(
          ...value.filter((item) => {
            return item.Id === undefined;
          })
        );
        formObject['Comments'] = formObject['Comments'].filter((obj, Index) => {
          return Index === formObject['Comments'].findIndex((o) => obj.Comment === o.Comment);
        });
      } else {
        formObject['Comments'] = value;
      }
    }
    this.setState({
      formObject,
      useCaseModalVisible: false
    });
  };

  handleClick = (e) => {
    if (e === 'Add New Attachments') {
      this.myRef.current && this.myRef.current.click();
    } else {
      this.setState({ showusecasedownloadModal: true });
    }
  };
  beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        filename: file.name
      });
    };
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        {/* <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
        >
          Filter
        </Button> */}
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: '' });
  };
  render() {
    const {
      PredictionDatasource,
      AnalysisDatasource,
      users,
      showusecasedownloadModal,
      formObject,
      activeKey,
      relatedTags,
      useCaseModalVisible
      // resultsModalClose,
    } = this.state;
    let userName =
      this.props &&
      this.props.userProfile &&
      this.props.userProfile.user &&
      this.props.userProfile.user.Id;
    let UseCaseColumns = [
      {
        title: 'File',
        dataIndex: 'file',
        key: 'file',
        align: 'center'
      },
      {
        title: 'Action',
        width: '600',
        align: 'center',
        render: (text, record) => (
          <>
            <Tooltip placement="top" key="download" title="Download">
              <div style={{ marginLeft: '-25px' }}>
                <a href={`http://192.168.95.23:3001/api/IOTAAttachments/${record.file}`}>
                  {' '}
                  <DownloadOutlined style={{ color: '#1a91ff' }} />
                </a>
              </div>
            </Tooltip>
          </>
        )
      }
    ];
    const PredictionColumns = [
      {
        title: this.translation('Prediction Task'),
        dataIndex: 'TaskName',
        key: 'TaskName',
        ...this.getColumnSearchProps('TaskName')
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Description',
        key: 'Description'
      },
      {
        title: this.translation('Created By'),
        dataIndex: 'CreatedName',
        key: 'CreatedName'
      },
      {
        title: this.translation('Created Time'),
        dataIndex: 'CreatedTime',
        key: 'CreatedTime',
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Action'),
        width: '600',
        render: (text, record) => (
          <>
            <Button
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => {
                this._editRecordPrediction(record);
              }}
              size="middle"
            >
              <Tooltip title={this.translation('Edit')}>
                <EditOutlined />
              </Tooltip>
            </Button>
          </>
        )
      }
    ];

    const AnalysisColumns = [
      {
        title: this.translation('Analysis Task'),
        dataIndex: 'TaskName',
        key: 'TaskName',
        ...this.getColumnSearchProps('TaskName')
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Description',
        key: 'Description'
      },
      {
        title: this.translation('Created By'),
        dataIndex: 'CreatedName',
        key: 'CreatedName'
      },
      {
        title: this.translation('Created Time'),
        dataIndex: 'CreatedTime',
        key: 'CreatedTime',
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Action'),
        width: '600',
        render: (text, record) => (
          <>
            <Button
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => {
                this._editRecordAnalysis(record);
              }}
              size="middle"
            >
              <Tooltip title={this.translation('Edit')}>
                <EditOutlined />
              </Tooltip>
            </Button>
          </>
        )
      }
    ];

    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const formItemLayout = { width: '100%', flex: '50%' };
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{
            padding: '0px 30px',
            minHeight: window.innerHeight - 143
          }}
        >
          <>
            <StyledForm
              name="Training Jobs"
              theme={themeSettings}
              ref={this.hForm}
              onFinish={this.onFinish}
              onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
              initialValues={formObject || {}}
              layout="horizontal"
              labelCol={{ flex: '200px' }}
              labelAlign="right"
              labelWrap
              wrapperCol={{ flex: 1 }}
              // validateMessages={validateMessages}
            >
              <div style={{ marginTop: '24px', display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item name="Id"></Form.Item>

                <Form.Item
                  label="Title"
                  // labelCol={{ flex: '200px' }}
                  name={'Title'}
                  style={formItemLayout}
                  rules={[{ required: true, message: 'Please Provide Title' }]}
                >
                  <Input
                    style={{ width: '70%' }}
                    maxLength={10000}
                    size="large"
                    placeholder="Input Title"
                  />
                </Form.Item>

                <Form.Item
                  label="Related Tags"
                  name={'RelatedTags'}
                  placeholder="Select Tags"
                  style={formItemLayout}
                >
                  <Select
                    maxTagCount="responsive"
                    mode="multiple"
                    placeholder="Search RelatedTags"
                    style={{ width: '70%' }}
                  >
                    {relatedTags &&
                      Array.isArray(relatedTags) &&
                      relatedTags.map((item, index) => {
                        return (
                          <Option key={index} value={item.Code}>
                            {item.Code}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="UseCase Name"
                  name={'UseCaseName'}
                  rules={[{ required: true, message: 'Please Provide UseCase' }]}
                  style={formItemLayout}
                >
                  <TextArea
                    style={{
                      height: 100,
                      width: '70%'
                    }}
                    maxLength={10000}
                    placeholder="Input UseCaseName"
                  />
                </Form.Item>
                <Form.Item
                  label="Functional Description"
                  name={'Description'}
                  style={formItemLayout}
                  rules={[
                    {
                      required: true,
                      message: 'Please Provide FunctionalDescription'
                    }
                  ]}
                >
                  <TextArea
                    maxLength={10000}
                    style={{
                      height: 100,
                      width: '70%'
                    }}
                    placeholder="Input FunctionalDescription"
                  />
                </Form.Item>
                <Form.Item style={formItemLayout} name="Attachments" label="Attachments">
                  <Select
                    size="large"
                    style={{ width: '70%' }}
                    placeholder={'Add/View Attachments'}
                    onChange={(e) => this.handleClick(e)}
                  >
                    <Option key={'View Attachments'} value={'View Attachments'}>
                      View Attachments
                    </Option>
                    <Option key={'Add New Attachments'} value={'Add New Attachments'}>
                      Add New Attachments
                    </Option>
                  </Select>
                  <Upload
                    // accept="text/csv"
                    multiple={true}
                    beforeUpload={this.beforeUpload}
                    // beforeUpload={this.filebeforeUpload}
                    // ref={this.myRef}
                    onChange={(info) => this.fileUpload(info)}
                    action={`${ANALYTICS.FileUpload}?SiteId=${siteId}&Type=${'UseCase'}`}
                    headers={{
                      Authorization: Authorization
                    }}
                    showUploadList={false}
                  >
                    <Button style={{ display: 'none' }} ref={this.myRef} className="uploadFile">
                      <UploadOutlined /> Upload
                    </Button>
                  </Upload>
                  <Drawer
                    title="UseCase Uploaded Files"
                    open={showusecasedownloadModal}
                    width={600}
                    onOk={() => this.setState({ showusecasedownloadModal: false })}
                    onClose={() => this.setState({ showusecasedownloadModal: false })}
                  >
                    <StyledTable
                      theme={themeSettings}
                      className="UseCaseUploadFilesTables"
                      columns={UseCaseColumns}
                      dataSource={
                        formObject &&
                        formObject.Attachments &&
                        Array.isArray(formObject.Attachments) &&
                        formObject.Attachments
                          ? [...formObject.Attachments]
                          : []
                      }
                    />
                    {/* </StyledTable> */}
                  </Drawer>
                </Form.Item>

                <Form.Item style={formItemLayout} label="Comments" name={'Comments'}>
                  <Tooltip
                    placement="topLeft"
                    color={'red'}
                    title="Click Here To Add Your Comments"
                  >
                    <Badge
                      count={formObject && formObject['Comments'] && formObject['Comments'].length}
                    >
                      <Button
                        style={{ background: '#eabe6f', color: 'black' }}
                        onClick={() => this.setState({ useCaseModalVisible: true })}
                      >
                        <CommentOutlined />
                        Add/View Comments
                      </Button>
                    </Badge>
                  </Tooltip>
                  {useCaseModalVisible && useCaseModalVisible === true ? (
                    <CommentsForm
                      Comments={
                        formObject && formObject['Comments'] && formObject['Comments'] !== undefined
                          ? [...formObject['Comments']]
                          : []
                      }
                      Title={'UseCase Comments'}
                      visible={useCaseModalVisible}
                      modalClose={this.useCaseModalClose}
                      user={userName}
                      submitComments={this.SubmitComments}
                    />
                  ) : null}
                </Form.Item>
                <Form.Item style={formItemLayout} label="Created By" name={'CreatedBy'}>
                  <Select
                    disabled
                    value={
                      this.props &&
                      this.props.userProfile &&
                      this.props.userProfile.user &&
                      this.props.userProfile.user.Id
                    }
                    suffix={<UserOutlined />}
                    size="large"
                    placeholder="Select CreatedBy"
                    style={{ width: '70%' }}
                  >
                    {users &&
                      Array.isArray(users) &&
                      users.map((item, index) => {
                        return (
                          <option key={index} value={item.Id}>
                            {item.FirstName}
                          </option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item style={formItemLayout} label="Created Date" name={'CreatedTime'}>
                  <Input
                    disabled
                    size="large"
                    style={{ width: '70%' }}
                    placeholder="CreatedDate"
                    suffix={<FieldTimeOutlined />}
                  />
                </Form.Item>
              </div>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <Form.Item>
                  <Button type="primary" onClick={this.onFinish}>
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </StyledForm>
          </>

          <div>
            <Tabs
              defaultActiveKey="1"
              type="card"
              activeKey={activeKey}
              style={{ color: 'black' }}
              onChange={async (activeKey) => {
                if (activeKey === '2') {
                  this.setState({ activeKey });
                }
                if (activeKey === '1') {
                  this.setState({ activeKey });
                }
                this.setState({ activeKey });
              }}
              className="TabsText"
            >
              <TabPane tab="Prediction Tasks" key="1">
                {formObject && formObject.Id && formObject.Id !== undefined ? (
                  <div style={{ float: 'right' }}>
                    <Tooltip title={this.translation('Add Prediction Task')}>
                      <PlusOutlined
                        onClick={() => this.addPredictionTask()}
                        style={{
                          fontSize: '25px',
                          color: 'rgb(255, 108, 0)',
                          marginLeft: '10px',
                          paddingBottom: '20px',
                          paddingRight: '20px'
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : null}
                <div>
                  <StyledTable
                    theme={themeSettings}
                    dataSource={PredictionDatasource ? PredictionDatasource : []}
                    columns={PredictionColumns}
                  />
                </div>
              </TabPane>
              <TabPane tab="Analysis Tasks" key="2">
                {formObject && formObject.Id && formObject.Id !== undefined ? (
                  <div style={{ float: 'right' }}>
                    <Tooltip title={this.translation('Add Analysis Task')}>
                      <PlusOutlined
                        onClick={() => this.addAnalysisTask()}
                        style={{
                          fontSize: '25px',
                          color: 'rgb(255, 108, 0)',
                          marginLeft: '10px',
                          paddingBottom: '20px',
                          paddingRight: '20px'
                        }}
                      />
                    </Tooltip>
                  </div>
                ) : null}
                <div>
                  <StyledTable
                    theme={themeSettings}
                    dataSource={AnalysisDatasource ? AnalysisDatasource : []}
                    columns={AnalysisColumns}
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  userProfile: getUserProfile()
});
export default connect(mapStateToProps)(UseCase);
