/* eslint-disable array-callback-return */
import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import ReactEcharts from '../Dashboard/ChartComponents/Echarts/Echarts';
export default class GraphView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(this.props.panelData),
      data: [],
      graphType: 'bar',
      language: props.language || 'English'
    };
  }

  async componentDidMount() {
    this.prepareData('bar');
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { graphType } = this.state;
    if (prevState.graphType !== graphType) {
      this.prepareData('bar');
    }
    if (prevProps.panelData !== this.props.panelData) {
      this.prepareData('bar');
    }
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps() {
    this.prepareData();
  }

  prepareData = async (type) => {
    const { panelData } = this.props;
    // const {graphType}=this.state
    let option = cloneDeep(this.getOption(panelData));
    let xaxis = [],
      series = [],
      finalArray = panelData && panelData.trendData.FFTOutPut;
    let reportName = '';
    let yAxis = [];
    yAxis.push(panelData && panelData.trendData.xRanges);
    yAxis.push(panelData && panelData.trendData.yRanges);
    yAxis.push(panelData && panelData.trendData.zRanges);
    // yAxis.push(panelData && panelData.trendData.)
    finalArray &&
      Object.keys(finalArray).map((data, index) => {
        let obj = {
          name: index === 0 ? 'X' : index === 1 ? 'Y' : 'Z',
          type: type,
          xAxisIndex: index,
          yAxisIndex: index,
          data: finalArray[data] && Array.isArray(finalArray[data]) ? finalArray[data] : [],
          // animationEasing: easingName,
          animationDuration: 0
        };
        series.push(obj);
      });
    option.xAxis.data = xaxis;
    //  option.yAxis.data=yAxis
    option.series = series;
    this.setState({
      option,
      reportName: reportName && reportName.file ? reportName.file : ''
    });
  };

  getOption = (panelData) => {
    // const graph = this;
    let option = {
      animation: false,
      title: {},
      legend: {
        icon: 'roundRect',
        textStyle: {
          color: 'black',
          fontSize: 16
        }
      },
      // color: panelData.colors,
      tooltip: {
        trigger: 'axis'
      },
      xAxis: [
        {
          gridIndex: 0,
          min: -1,
          max: 1600,
          name: 'Frequency[Hz]',
          nameLocation: 'center',
          nameGap: '25',
          lineStyle: {
            color: 'white'
          },
          axisLabel: {
            show: true,
            inside: false,
            rotate: 0,
            showMinLabel: null,
            showMaxLabel: null,
            margin: 8,
            fontSize: 15
          }

          // fontSize:"10px"
        },
        {
          gridIndex: 1,
          min: -1,
          max: 1600,
          name: 'Frequency[Hz]',
          nameLocation: 'center',
          nameGap: '25',
          lineStyle: {
            color: 'white'
          },
          axisLabel: {
            show: true,
            inside: false,
            rotate: 0,
            showMinLabel: null,
            showMaxLabel: null,
            margin: 8,
            fontSize: 15
          }
        },
        {
          gridIndex: 2,
          min: -1,
          max: 1600,
          name: 'Frequency[Hz]',
          nameLocation: 'center',
          nameGap: '25',
          lineStyle: {
            color: 'white'
          },
          axisLabel: {
            show: true,
            inside: false,
            rotate: 0,
            showMinLabel: null,
            showMaxLabel: null,
            margin: 8,
            fontSize: 15
          }
        }
      ],
      yAxis: [
        {
          ...(panelData && panelData.trendData.xRanges),
          name: `X – (m/s^2)`,
          nameLocation: 'center',
          nameGap: '45',
          lineStyle: {
            color: 'white'
          },
          axisLabel: {
            show: true,
            inside: false,
            rotate: 0,
            showMinLabel: null,
            showMaxLabel: null,
            margin: 8,
            fontSize: 15
          }
        },
        {
          ...(panelData && panelData.trendData.yRanges),
          name: `Y – (m/s^2)`,
          nameLocation: 'center',
          nameGap: '45',
          lineStyle: {
            color: 'white'
          },
          axisLabel: {
            show: true,
            inside: false,
            rotate: 0,
            showMinLabel: null,
            showMaxLabel: null,
            margin: 8,
            fontSize: 15
          }
        },
        {
          ...(panelData && panelData.trendData.zRanges),
          name: `Z – (m/s^2)`,
          nameLocation: 'center',
          nameGap: '45',
          lineStyle: {
            color: 'white'
          },
          axisLabel: {
            show: true,
            inside: false,
            rotate: 0,
            showMinLabel: null,
            showMaxLabel: null,
            margin: 8,
            fontSize: 15
          }
        }
      ],
      grid: [
        { left: '7%', top: '7%', width: '90%', height: '20%' },
        { left: '7%', bottom: '40%', width: '90%', height: '20%' },
        { left: '7%', bottom: '7%', width: '90%', height: '20%' }
        // { right: '7%', bottom: '7%', width: '38%', height: '38%' }
      ],
      toolbox: {
        showTitle: false,
        orient: 'vertical',
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            backgroundColor: 'black',
            name:
              this.props.graphprops && this.props.graphprops.title
                ? this.props.graphprops.title
                : '',
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      }
    };
    return option;
  };

  render() {
    return (
      <React.Fragment>
        <ReactEcharts
          option={this.state.option}
          // notMerge={true}
          lazyUpdate={false}
          style={{ height: '600px', width: '100%', marginTop: '20px' }}
          className="react_for_echarts"
        />
      </React.Fragment>
    );
  }
}
