import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getTranslationCache } from '../../../selectors/language';
import localStorage from '../../../utils/localStorage';
import Dashboard from './Dashboard';
import './CSS/Dashboard.css';
import { API_DASHBOARD } from '../../../commons/api';

export const StyledDashboard = styled.div`
  background: rgb(26, 54, 82);
  margin: 5px;
  padding: 30px;
`;
class Dashboards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardList: []
    };
  }

  UNSAFE_componentWillMount() {
    let groupName = this.props.match.params.name;
    document.title = 'Dashboard-' + groupName;
    this._getDashboardListFromGroup(groupName);
  }

  componentDidUpdate(preprops) {
    if (this.props.match.params.name !== preprops.match.params.name) {
      document.title = 'Dashboard - ' + this.props.match.params.name;
      let groupName = this.props.match.params.name;
      this._getDashboardListFromGroup(groupName);
    }
  }

  _getDashboardListFromGroup = (groupName) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const ParameterTypeObject = {
      method: 'POST',
      url: `${API_DASHBOARD.GetNameByGroup}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        GroupName: groupName
      }
    };
    axios(ParameterTypeObject)
      .then((response) => {
        this.setState({
          dashboardList: response.data
        });
      })
      .catch(function () {});
  };

  render() {
    let { dashboardList } = this.state;
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        {dashboardList && Array.isArray(dashboardList) ? (
          <Tabs style={{ color: 'white' }}>
            {dashboardList.map((dashboardName) => {
              return (
                <Tabs.TabPane tab={dashboardName} key={dashboardName}>
                  <Dashboard match={{ params: { name: dashboardName } }} />
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        ) : null}
      </StyledDashboard>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(Dashboards);
