import React, { Fragment } from 'react';
import cloneDeep from 'lodash.clonedeep';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Row, Col, Tag, Spin, Card, List, Table } from 'antd';

import ReactEcharts from '../../Echarts/Echarts';
import localStorage from '../../../../../utils/localStorage';
import { getChartDataWithSelection, getChartDataWithoutSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { getCurrentTimezone } from '../../../../../selectors/layout';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { DonutCounterMock } from '../../../Mock/MockJson';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';
import {
  trendLabelTranslation,
  predictionsLegendsTranslation
} from '../../../Utils/Adapter/translationAdapter';

import '../../CSS/Table.css';
// import { API_DASHBOARD } from '../../../../../commons/api';

class DonutCounter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],

      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      loading: false,
      linksList: [],
      listDataSource: [],
      columns: [],
      dataSource: [],
      selectedDevice: '',
      AlertType: '',
      selectedDeviceName: ''
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);
    this._getGenerateData(this.state.selectedDevice, this.state.AlertType, true, '', 'dropdown');
    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
      this._getGenerateData(this.state.selectedDevice, this.state.AlertType, true, '', 'dropdown');
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.apiPayloadCount !== this.props.apiPayloadCount ||
      prevProps.apiPayloadData !== this.props.apiPayloadData
    ) {
      this.getData(this.props, this.props.selectedThemeing);
      this._getGenerateData(this.state.selectedDevice, this.state.AlertType, true, '', 'dropdown');
    }
  }
  // _getAlertsData = (DeviceCode) => {
  //   console.log(DeviceCode, 'DeviceCode');
  //   let { AssetCode, graphprops, dashboardName } = this.props;
  //   let searchParams = new URLSearchParams(graphprops.treePayload.GETParams);
  //   let paramObject = Object.fromEntries([...searchParams]);
  //   const accessToken = localStorage.get('accessToken');
  //   const siteId = localStorage.get('currentSite');
  //   const ParameterTypeObject = {
  //     method: 'POST',
  //     url: `${API_DASHBOARD.GetAlertsCount}`,
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`
  //     },
  //     data: {
  //       AssetCode: AssetCode,
  //       DeviceCode: DeviceCode,
  //       // DeviceType: this.props.DeviceCode,
  //       SiteId: siteId,
  //       StartDate: moment().subtract(7, 'day').utc().format('YYYY-MM-DD HH:mm:ss'),
  //       EndDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
  //       GroupName: dashboardName,
  //       HierarchyCode:
  //         graphprops &&
  //         graphprops.treePayload &&
  //         graphprops.treePayload.method &&
  //         graphprops.treePayload.method === 'GET' &&
  //         paramObject &&
  //         paramObject.HierarchyCode
  //           ? paramObject.HierarchyCode
  //           : graphprops.treePayload.payload && graphprops.treePayload.payload.HierarchyCode
  //           ? graphprops.treePayload.payload.HierarchyCode
  //           : ''
  //     }
  //   };
  //   return axios(ParameterTypeObject)
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch(function () {});
  // };
  // _getAlertsDetails = (AlertType, DeviceCode) => {
  //   let { AssetCode, graphprops, dashboardName } = this.props;
  //   let searchParams = new URLSearchParams(graphprops.treePayload.GETParams);
  //   let paramObject = Object.fromEntries([...searchParams]);
  //   const accessToken = localStorage.get('accessToken');
  //   const siteId = localStorage.get('currentSite');
  //   const ParameterTypeObject = {
  //     method: 'POST',
  //     url: `${API_DASHBOARD.GetAlertsData}`,
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`
  //     },
  //     data: {
  //       AssetCode: AssetCode,
  //       DeviceCode: DeviceCode,
  //       // DeviceType: this.props.DeviceCode,
  //       SiteId: siteId,
  //       StartDate: moment().subtract(7, 'day').utc().format('YYYY-MM-DD HH:mm:ss'),
  //       EndDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
  //       Type: AlertType || 'All',
  //       GroupName: dashboardName,
  //       HierarchyCode:
  //         graphprops &&
  //         graphprops.treePayload &&
  //         graphprops.treePayload.method &&
  //         graphprops.treePayload.method === 'GET' &&
  //         paramObject &&
  //         paramObject.HierarchyCode
  //           ? paramObject.HierarchyCode
  //           : graphprops.treePayload.payload && graphprops.treePayload.payload.HierarchyCode
  //           ? graphprops.treePayload.payload.HierarchyCode
  //           : '',
  //     }
  //   };
  //   return axios(ParameterTypeObject)
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch(function () {});
  // };
  _getDevicesList = async (payload) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let type = typeof payload.method;
    let url = '';
    if (payload && payload.method === 'GET' && payload.GETParams) {
      url = `${payload.url}?SiteId=${siteId}&${payload.GETParams}`;
    } else {
      url = `${payload.url}?SiteId=${siteId}`;
      console.log(url, 'posttttt', payload.method, type);
    }
    let payloadData = payload && payload.payload ? payload.payload : '';

    const ObjectHeader = {
      method: payload && payload.method && type === 'string' ? payload.method : '',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payloadData || {}
    };
    console.log(ObjectHeader, 'ObjectHeader');

    return axios(ObjectHeader)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };

  _getGenerateData = async (selectedDevice, AlertType, status, selectedDeviceName, Type) => {
    let { graphprops, AssetCode, dashboardName } = this.props;
    let siteId = localStorage.get('currentSite');
    selectedDevice =
      selectedDevice && selectedDevice !== '' ? selectedDevice : this.props.DeviceCode;
    let searchParams = new URLSearchParams(graphprops.treePayload.GETParams);
    let paramObject = Object.fromEntries([...searchParams]);

    let noOfBackDays =
      graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph].NoOfBackDays
        ? JSON.parse(graphprops[graphprops.graph].NoOfBackDays)
        : 7;
    if (status) {
      let payload = {
        ...(graphprops && graphprops.apiPayloadCount ? graphprops.apiPayloadCount : {}),
        payload: {
          ...(graphprops && graphprops.apiPayloadCount ? graphprops.apiPayloadCount.payload : {}),
          ...(Type && Type === 'pie' ? { Type: 'DeviceCode' } : {}),
          AssetCode: AssetCode,
          DeviceCode: selectedDevice,
          SiteId: siteId,
          StartDate: moment().subtract(noOfBackDays, 'day').utc().format('YYYY-MM-DD HH:mm:ss'),
          EndDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
          GroupName: dashboardName,
          HierarchyCode:
            graphprops &&
            graphprops.treePayload &&
            graphprops.treePayload.method &&
            graphprops.treePayload.method === 'GET' &&
            paramObject &&
            paramObject.HierarchyCode
              ? paramObject.HierarchyCode
              : graphprops.treePayload.payload && graphprops.treePayload.payload.HierarchyCode
              ? graphprops.treePayload.payload.HierarchyCode
              : ''
        }
      };
      let listDataSource = await this._getDevicesList(payload, selectedDevice);
      this.setState({
        listDataSource
      });
    }
    let payloadData = {
      ...(graphprops && graphprops.apiPayloadData ? graphprops.apiPayloadData : {}),
      payload: {
        ...(graphprops && graphprops.apiPayloadData ? graphprops.apiPayloadData.payload : {}),
        ...(Type && Type === 'pie' ? { Type: 'DeviceCode' } : {}),
        AssetCode: AssetCode,
        DeviceCode: selectedDevice,
        SiteId: siteId,
        StartDate: moment().subtract(noOfBackDays, 'day').utc().format('YYYY-MM-DD HH:mm:ss'),
        EndDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
        GroupName: dashboardName,
        HierarchyCode:
          graphprops &&
          graphprops.treePayload &&
          graphprops.treePayload.method &&
          graphprops.treePayload.method === 'GET' &&
          paramObject &&
          paramObject.HierarchyCode
            ? paramObject.HierarchyCode
            : graphprops.treePayload.payload && graphprops.treePayload.payload.HierarchyCode
            ? graphprops.treePayload.payload.HierarchyCode
            : ''
      }
    };
    let dataSource = await this._getDevicesList(payloadData, AlertType, selectedDevice);
    let columnsData =
      graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph].columns
        ? JSON.parse(graphprops[graphprops.graph].columns)
        : [];
    let columns =
      columnsData &&
      columnsData.map((data) => {
        return {
          ...data,
          ...(data.sort && data.sort === false
            ? { sorter: (a, b) => a[data].length - b[data].length }
            : {}),
          ...(data.isUTC && data.isUTC === false
            ? {
                render: (text) => {
                  return {
                    children: <div>{moment(text).utc().format('YYYY-MM-DD HH:mm:ss')}</div>
                  };
                }
              }
            : data.isUTC && data.isUTC === true
            ? {
                render: (text) => {
                  return {
                    children: <div>{moment.utc(text).local().format('YYYY-MM-DD HH:mm:ss')}</div>
                  };
                }
              }
            : {})
        };
      });
    let linksList =
      graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph].links
        ? JSON.parse(graphprops[graphprops.graph].links)
        : [];

    this.setState({
      columns,
      dataSource,
      selectedDevice,
      AlertType,
      selectedAlert: status === false ? AlertType : '',
      selectedDeviceName,
      linksList
    });
  };

  onChartClick = async (cellData) => {
    if (cellData.componentSubType === 'pie') {
      await this._getGenerateData(
        cellData.data.description,
        this.state.AlertType,
        true,
        cellData.name,
        'pie'
      );
      this.setState({ selectedAlert: '' });
    } else if (cellData.componentType === 'title') {
      await this._getGenerateData('', this.state.AlertType, true, cellData.name, 'pie');
      this.setState({ selectedAlert: '' });

      let listDataSource = await this._getAlertsData(this.state.selectedDevice);
      this.setState({
        listDataSource
      });
    }
  };

  render() {
    const {
      errorInAPI,
      noDataInDB,
      refreshDateTime,
      loading,
      listDataSource,
      option,
      dataSource,
      columns,
      selectedDeviceName,
      selectedAlert,
      selectedDevice
      // linksList
    } = this.state;

    let { graphprops } = this.props;
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData)
    };
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <>
            {loading === false ? (
              <div style={{ padding: '0px' }}>
                <Row>
                  <Col span={22}>
                    {noDataInDB === true || errorInAPI === true ? (
                      noDataInDB === true ? (
                        <NoRecordComponent />
                      ) : (
                        <ErrorComponent />
                      )
                    ) : (
                      <ReactEcharts
                        option={option}
                        className="react_for_echarts"
                        style={{ height: '360px', width: '100%' }}
                        onEvents={onEvents}
                      />
                    )}
                  </Col>
                  <Col span={2}>
                    {listDataSource &&
                    Array.isArray(listDataSource) &&
                    listDataSource.length > 0 ? (
                      <List
                        grid={{
                          gutter: 5,
                          column: 1
                        }}
                        dataSource={listDataSource}
                        style={{
                          textAlign: 'center',
                          textColor: '#fff !important',
                          fontSize: '1em'
                        }}
                        header={
                          <div style={{ color: 'white', fontSize: '17px', fontWeight: 'bold' }}>
                            {selectedDeviceName ? selectedDeviceName : 'All Devices'}
                          </div>
                        }
                        renderItem={(item) => (
                          <List.Item>
                            <Card
                              className="counterCard"
                              title={item.Name}
                              style={{
                                backgroundColor:
                                  selectedAlert === item.Name ? '#7bbcf2' : 'steelblue',
                                fontSize: '1.5em',
                                cursor: 'pointer'
                              }}
                            >
                              <div
                                onClick={() =>
                                  this._getGenerateData(
                                    selectedDevice,
                                    item.Name,
                                    false,
                                    this.state.selectedDeviceName,
                                    'counter'
                                  )
                                }
                              >
                                {item.Value}
                              </div>
                            </Card>
                          </List.Item>
                        )}
                      />
                    ) : null}
                  </Col>
                  <Col span={24}>
                    {/* <Space wrap className="linkList">
                      {linksList && Array.isArray(linksList) && linksList.length > 0
                        ? linksList.map((data) => {
                            return (
                              <>
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    history.push(
                                      `${data.path}?AssetCode=${this.props.AssetCode}&report=true`
                                    );
                                  }}
                                >
                                  {' '}
                                  {data.name}{' '}
                                </Button>
                              </>
                            );
                          })
                        : null}
                    </Space> */}

                    <div style={{ marginTop: '50px' }}>
                      {columns && Array.isArray(columns) && columns.length > 0 ? (
                        <Table
                          showSorterTooltip={false}
                          className="basictable"
                          columns={columns && Array.isArray(columns) ? columns : []}
                          dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
                          pagination={{ pageSize: '5' }}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  color: 'white',
                  marginTop: '15%'
                }}
              >
                <h2 style={{ color: 'white' }}>
                  <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                </h2>
              </div>
            )}
          </>
        )}
      </Fragment>
    );
  }

  componentWillUnmount() {
    this.abortController.abort();
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
  }

  getData = async (props, theme) => {
    this.requestCheck = false;
    let json = [];
    let { graphprops } = this.props;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = DonutCounterMock;
      this.requestCheck = true;
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection({
          graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          currentTimeZone: this.props.currentTimeZone,
          extraKeys: {}
          // extraKeys: { DeviceType: this.props.DeviceCode }
        });
        this.requestCheck = true;
      } else {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      const option = cloneDeep(this.state.option);
      let legend = {
        left: '55%',
        top: '10%',
        orient: 'vertical',
        textStyle: {
          color: theme === 'lightTheme' ? 'black' : 'white',
          fontSize: 18,
          fontWeight: 'bold',
          top: '61%'
        },
        data:
          json && Array.isArray(json)
            ? json.map((data) => {
                return data.Name;
              })
            : []
      };
      let series = [];
      let total = 0;
      if (json && Array.isArray(json)) {
        series = json.map((val) => {
          total = total + val.Value;
          return { value: val.Value, name: val.Name, description: val.Description };
        });
      }

      let title = {
        text: `Total`,
        triggerEvent: true,
        subtext: `${total}`,
        left: '30%',
        top: 'center',
        textStyle: {
          color: 'white'
        },
        subtextStyle: {
          fontSize: 20,
          color: 'white',
          left: '20%'
        },
        textAlign: 'center'
      };
      if (
        this.props.graphprops.colors !== null &&
        this.props.graphprops.colors !== undefined &&
        this.props.graphprops.colors.length !== 0
      ) {
        option.color = [...this.props.graphprops.colors];
      }
      series = await trendLabelTranslation(series, this.props.translationCache);
      let legends = await predictionsLegendsTranslation(legend, this.props.translationCache);
      option.title = title;
      option.legend = legends;
      option.series[0].data = [...series];
      this.setState({
        option,
        refreshDateTime: moment()
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c}'
      },
      legend: {
        textStyle: {
          color: theme === 'lightTheme' ? 'black' : 'white',
          fontSize: 18,
          fontWeight: 'bold'
        },
        type: 'scroll',
        orient: 'vertical',
        right: '50%',
        top: 0,
        bottom: 20,
        data: []
      },

      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            backgroundColor: theme === 'lightTheme' ? 'black' : '#000',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      series: [
        {
          name: this.props.graphprops.title,
          type: 'pie',
          radius: ['40%', '68%'],
          center: ['30%', '35%'],
          height: '500px',
          color: [
            '#f70505',
            '#e0f70f',
            '#e09722',
            '#22c7ab',
            '#1E5128',
            '#52057B',
            '#082296',
            '#c0a0f2',
            '#cc16f5',
            '#5f85db',
            '#C62A88',
            '#00A8CC'
          ],
          avoidLabelOverlap: false,
          data: [],
          label: {
            show: true,
            position: 'inner',
            formatter: '{c}'
          },
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };

    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(DonutCounter);
