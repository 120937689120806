import { Checkbox, Form } from 'antd';
import styled from 'styled-components';
export const StyledForm = styled(Form)`
  .ant-divider-horizontal.ant-divider-with-text {
    display: flex;
    margin: 16px 0;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-top: 0;
  }

  .ant-form-item-label > label {
    color: ${(props) => props.theme.form.formLabel} !important;
    font-weight: revert;
    font-size: ${(props) => props.theme.form.formLabelSize};
    font-weight: bold;
  }

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.66);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }

  .ant-tabs-content-holder {
    border-radius: 0 5px 5px 5px;
  }
`;
export const StyledComponent = styled.div`
  background: ${(props) => props.theme.backgroundColor} !important;
  padding: 10px;
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox .ant-checkbox-inner {
    border-color: rgb(217, 142, 4);
    background-color: transparent;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: rgb(217, 142, 4);
    background-color: white;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #d98e04 !important;
    border-color: #d98e04 !important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #f55e00 !important;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: white !important;
  }
  .ant-checkbox-disabled + span {
    color: #d98e04 !important;
    cursor: not-allowed;
  }
`;
