import React, { Fragment } from 'react';
import moment from 'moment';
import { Modal, Spin, Tag } from 'antd';
import cloneDeep from 'lodash.clonedeep';
// import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ErrorComponent, NoRecordComponent } from '../../Utils';
import ReactEcharts from '../../Echarts/Echarts';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { trendLabelTranslation } from '../../../Utils/Adapter/translationAdapter';
import { getTranslationCache } from '../../../../../selectors/language';
// import { defaultExcelAdapter } from "./ExcelAdapters/defaultAdapter";
// import localStorage from '../../../../../utils/localStorage';
import { objectOfObject } from '../../../Mock/MockJson';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';
import { LineChartOutlined } from '@ant-design/icons';
import '../../CSS/style.css';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class CounterLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      loading: false,
      colors: this.props.graphprops.colors,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      reportName: '',
      errorInAPI: false,
      noDataInDB: false,
      counter: {},
      showGraph: false
    };
  }

  data = [];
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }
    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props);
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  render() {
    let theme = this.props.selectedThemeing;
    let { graphprops } = this.props;
    let { loading, errorInAPI, noDataInDB, refreshDateTime, counter } = this.state;
    let isModal =
      graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph].isModal === 'true'
        ? 'true'
        : 'false';
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}

        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <span>
            {loading === false ? (
              <div>
                {isModal === 'true' ? (
                  <div className="counterIcon">
                    <span>
                      {' '}
                      {counter && counter[0] && counter[0].Value ? counter[0].Value : ''}
                    </span>
                    <LineChartOutlined
                      style={{ marginTop: '10px' }}
                      onClick={() => this.setState({ showGraph: true })}
                    />
                  </div>
                ) : (
                  <div className="counterGraph">
                    <span>
                      {' '}
                      {counter && counter[0] && counter[0].Value ? counter[0].Value : ''}
                    </span>
                  </div>
                )}

                <div>
                  {isModal === 'true' ? (
                    <Modal
                      width={800}
                      title="Historical Graph"
                      open={this.state.showGraph}
                      footer={null}
                      onCancel={() => this.setState({ showGraph: false })}
                    >
                      <ReactEcharts
                        option={this.state.option}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{ height: '400%', width: '100%', background: 'black' }}
                        className="react_for_echarts"
                      />
                    </Modal>
                  ) : (
                    <ReactEcharts
                      option={this.state.option}
                      notMerge={true}
                      lazyUpdate={true}
                      style={{ height: '400%', width: '100%' }}
                      className="react_for_echarts"
                    />
                  )}
                </div>
              </div>
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  color: theme === 'lightTheme' ? '#000' : '#fff',
                  marginTop: '10%'
                }}
              >
                <h2 style={{ color: '#000' }}>
                  <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                </h2>
              </div>
            )}
          </span>
        )}
      </Fragment>
    );
  }

  // getReportNametoDownload(excelData) {
  //   let payload = {
  //     date: moment().format('YYYY-MM-DD'),
  //     time: moment().format('HH:mm:ss'),
  //     data: excelData || [],
  //     ...this.props.graphprops.excelObj
  //   };

  //   let siteId = localStorage.get('currentSite');
  //   let accessToken = localStorage.get('accessToken');
  //   let options = {
  //     method: 'POST',
  //     url: `/api/file/generateExcel?SiteId=${siteId}`,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${accessToken}`
  //     },
  //     data: JSON.stringify(payload)
  //   };
  //   return axios(options)
  //     .then(({ data }) => {
  //       return data;
  //     })
  //     .catch(() => {
  //       return '';
  //     });
  // }

  // downLoadExcel = () => {
  //   const { reportName } = this.state;
  //   let url = `${excelDownloadUrl}/${reportName}`;
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", reportName); //or any other extension
  //   document.body.appendChild(link);
  //   link.click();
  // };

  getData = async (props) => {
    this.requestCheck = false;
    let theme = this.props.selectedThemeing;

    let json = [];
    let { graphprops } = this.props;
    this.setState({ loading: true });
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = objectOfObject;

      this.requestCheck = true;
      this.setState({ loading: false });
    } else {
      if (
        props.graphprops.graph === 'rangeLine' &&
        props.graphprops.currentDateData !== undefined
      ) {
        json = props.graphprops.currentDateData;
      } else {
        if (
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'datePicker' ||
              graphprops.GraphLevelSelection === 'datePicker') &&
            this.props.selectionDate) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'monthPicker' ||
              graphprops.GraphLevelSelection === 'monthPicker') &&
            this.props.selectionMonth) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'weakPicker' ||
              graphprops.GraphLevelSelection === 'weakPicker') &&
            this.props.selectionWeek) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'shiftPicker' ||
              graphprops.GraphLevelSelection === 'shiftPicker') &&
            this.props.shift) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
              graphprops.GraphLevelSelection === 'dateRangePicker') &&
            this.props.selectionDateRange) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
              graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
            this.props.dayMonthYear) ||
          (graphprops && graphprops.dashboardType === 'dynamic') ||
          (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
          (graphprops &&
            graphprops.dashboardType === 'hierarchy' &&
            this.props.dropdownSelection) ||
          (graphprops && this.props.dashboardPayload) ||
          (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
        ) {
          json = await getChartDataWithSelection({
            graphprops,
            DeviceCode: this.props.DeviceCode,
            selectionDate: this.props.selectionDate,
            selectionMonth: this.props.selectionMonth,
            selectionWeek: this.props.selectionWeek,
            shift: this.props.shift,
            selectionDateRange: this.props.selectionDateRange,
            dayMonthYear: this.props.dayMonthYear,
            abortController: this.abortController,
            dropdownSelection: this.props.dropdownSelection,
            dashboardPayload: this.props.dashboardPayload,
            AssetCode: this.props.AssetCode,
            selectionDateLive: this.props.selectionDateLive,
            currentTimeZone: this.props.currentTimeZone
          });
          this.requestCheck = true;
          this.setState({ loading: false });
        } else {
          json = await await getChartDataWithoutSelection({
            graphprops,
            abortController: this.abortController,
            dropdownSelection: this.props.dropdownSelection,
            DeviceCode: this.props.DeviceCode,
            TimeZone: this.props.currentTimeZone
          });
          this.requestCheck = true;
          this.setState({ loading: false });
        }
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0) ||
      (graphprops.enableMock && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      var counter = json && json.query ? json.query : '';
      let line = json && json.query1 ? json.query1 : [];

      if (line && Array.isArray(line)) {
        let dates = [];
        let values = [];
        let name = '';
        let tagnames = [];
        let seriesarray = [];
        let legendsdata = [];
        line.map((val) => {
          if (val.Name !== name) {
            if (tagnames.indexOf(val.Name) === -1) {
              tagnames.push(val.Name);
              name = val.Name;
              legendsdata.push({
                name: val.Description,
                icon: 'roundRect',
                textStyle: {
                  color: theme === 'lightTheme' ? 'black' : 'white',
                  fontSize: 14,
                  fontWeight: 'bold'
                }
              });
            }
          }
          return {};
        });
        let yAxisData = [];
        if (
          this.props.graphprops &&
          this.props.graphprops[this.props.graphprops.graph] &&
          this.props.graphprops[this.props.graphprops.graph].YAxis
        ) {
          yAxisData = JSON.parse(this.props.graphprops[this.props.graphprops.graph].YAxis);
        } else {
          yAxisData = [];
        }
        let yaxis = yAxisData.map((value) => {
          let axis = {};
          axis.splitLine = {
            show: true,
            lineStyle: {
              color: '#504c4c'
            }
            // axisLabel: { fontSize: 15 },
          };
          axis.triggerEvent = true;
          axis.axisLine = {
            show: true,
            lineStyle: {
              color: 'white'
              // color:
              //   this.props.graphprops.dateRangeLine.colors[index] !== undefined
              //     ? value && value.tags && value.tags.length > 1
              //       ? 'white'
              //       : this.props.graphprops.dateRangeLine.colors[index]
              //     : 'white'
            }
            // axisLabel: { fontSize: 15 },
          };
          axis.max = value.max;
          axis.min = value.min;
          axis.offset = value.offset;
          axis.interval = value.interval;
          axis.position = value.position;
          axis.name = value.name;
          axis.tags = value.tags;
          return axis;
        });

        yaxis.unshift({
          splitLine: {
            show: true,
            lineStyle: {
              color: '#504c4c'
            }
          },
          triggerEvent: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisLabel: { fontSize: 15 },
          tags: []
        });

        tagnames.map((tagval, tagindex) => {
          values = [];
          line.map((jsonval) => {
            if (tagnames[0] === jsonval.Name && tagindex === 0) {
              let date =
                graphprops && graphprops.type === 'live'
                  ? moment
                      .utc(jsonval.DateTime)
                      .tz(this.props.currentTimeZone)
                      .format('DD/MM/YYYY HH:mm:ss')
                  : moment
                      .utc(jsonval.DateTime)
                      .tz(this.props.currentTimeZone)
                      .format('DD/MM/YYYY HH:mm:ss');
              dates.push(date);
            }

            if (tagval === jsonval.Name) {
              values.push(jsonval.Value);
            }
            return {};
          });

          if (yaxis.length > 1) {
            yaxis.map((axis, axisindex) => {
              if (axis.tags.indexOf(tagval) === -1) {
                seriesarray.push({
                  name: legendsdata[tagindex]['name'],
                  data: values,
                  yAxisIndex: axisindex,
                  type: 'line'
                });
              }
              return {};
            });
          } else {
            seriesarray.push({
              name: legendsdata[tagindex]['name'],
              data: values,
              type: 'line'
            });
          }
          return {};
        });

        const option = cloneDeep(this.getOption());
        if (
          this.props.graphprops &&
          this.props.graphprops.line &&
          this.props.graphprops.colors &&
          this.props.graphprops.line.colors.length > 0
        ) {
          option.color = this.props.graphprops.line.colors;
        }

        let series = await trendLabelTranslation(seriesarray, this.props.translationCache);
        option.xAxis.data = [...dates];
        option.yAxis = [...yaxis];
        option.series = [...series];
        option.legend = [...legendsdata];
        this.setState({
          option: option,
          counter,
          refreshDateTime: moment()
        });
      }
    }

    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      legend: {
        textStyle: { color: theme === 'lightTheme' ? 'black' : 'white' },
        data: [
          {
            name: 'test1',
            icon: 'roundRect'
          }
        ]
      },
      tooltip: {
        trigger: 'axis'
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            name: this.props.graphprops.title,
            backgroundColor: theme === 'lightTheme' ? 'white' : 'black'
          }
        }
      },
      xAxis: {
        type: 'category',
        data: this.props.graphprops,
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        }
      },
      dataZoom: [
        {
          type: 'slider',
          show: true,
          start: 0,
          end: 100,

          backgroundColor: '#9a9191',
          textStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        {
          type: 'inside',
          start: 0,
          end: 100
        }
      ]
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(CounterLine);
