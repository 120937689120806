import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import CentralDashboardComponent from './CentralDashboardComponent'
// import { setMenuSelection } from '../../modules/layout/layout.duck'

const mapStateToProps = createStructuredSelector({
  
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(
      {
        // setMenuSelection
      },
      dispatch
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CentralDashboardComponent)
