import React, { Component } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
// import NoRecordComponent from '../../../Trends/Utils/NoRecords';
import ReactEcharts from '../../../Dashboard/ChartComponents/Echarts/Echarts';

class PredictedGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      disableGraph: this.props && this.props.disableGraph,
      difMin: this.props && this.props.difMin,
      differenceHours: this.props && this.props.differenceHours,
      json: [],
      data: []
    };
  }

  componentDidMount() {
    this.getData(this.props.panelData.GraphData);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.disableGraph !== this.props.disableGraph ||
      prevProps.difMin !== this.props.difMin ||
      prevProps.differenceHours !== this.props.differenceHours ||
      prevProps.panelData !== this.props.panelData ||
      prevProps.panelData.GraphData !== this.props.panelData.GraphData
    ) {
      this.setState({
        disableGraph: this.props.disableGraph,
        difMin: this.props.difMin,
        differenceHours: this.props.differenceHours
      });
      this.getData(this.props.panelData.GraphData);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeTicket);
  }

  getData = async () => {
    // let json = [];
    let series = [];
    let { json } = this.state;
    json =
      this.props && this.props.panelData && this.props.panelData.GraphData
        ? this.props.panelData.GraphData
        : [];
    // json =[
    //   {
    //     "time": "2024-05-18 03:45:00+00",
    //     "count": 4,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2024-05-18 03:45:00+00",
    //       "NotificationId": null,
    //       "TagName": "TRR_TIA204_PV",
    //       "TagDescription": null,
    //       "Action": null
    //     },
    //     "status": 4          },
    //   {
    //     "time": "2024-05-18 03:46:00+00",
    //     "count":4 ,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2024-05-18 03:46:00+00",
    //       "NotificationId": null,
    //       "TagName": "TRR_TIA204_PV",
    //       "TagDescription": null,
    //       "Action": null
    //     },
    //     "status": 4
    //   },
    //   {
    //     "time": "2024-05-18 03:47:00+00",
    //     "count": 5,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2024-05-18 03:47:00+00",
    //       "NotificationId": null,
    //       "TagName": "TRR_TIA204_PV",
    //       "TagDescription": null,
    //       "Action": null
    //     },
    //     "status": 4
    //   },
    //   {
    //     "time": "2024-05-18 03:48:00+00",
    //     "count": 3,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2024-05-18 03:48:00+00",
    //       "NotificationId": null,
    //       "TagName": "TRR_TIA204_PV",
    //       "TagDescription": null,
    //       "Action": null
    //     },
    //     "status": 3
    //   },
    //   {
    //     "time": "2024-05-18 03:49:00+00",
    //     "count": 1,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2024-05-18 03:49:00+00",
    //       "NotificationId": null,
    //       "TagName": "TRR_TIA204_PV",
    //       "TagDescription": null,
    //       "Action": null
    //     },
    //     "status": 3
    //   },
    //   {
    //     "time": "2024-05-18 03:50:00+00",
    //     "count": 2,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2024-05-18 03:50:00+00",
    //       "NotificationId": null,
    //       "TagName": "TRR_TIA204_PV",
    //       "TagDescription": null,
    //       "Action": null
    //     },
    //     "status": 4
    //   },
    //   {
    //     "time": "2024-05-18 03:51:00+00",
    //     "count": 3,
    //     "tagsList": [],
    //     "notification": {
    //       "Timestamp": "2024-05-18 03:51:00+00",
    //       "NotificationId": null,
    //       "TagName": "TRR_TIA204_PV",
    //       "TagDescription": null,
    //       "Action": null
    //     },
    //     "status": 4
    //   }
    // ]
    const option = cloneDeep(this.state.option);
    if (json && Array.isArray(json)) {
      this.setState({
        data: json
      });
      option.xAxis.data = json.map(function (item) {
        return moment.utc(item.time, 'YYYY-MM-DD HH:mm').local().format('YYYY-MM-DD HH:mm');
      });
      series = [
        {
          name: 'Predicted Abnormal',
          barWidth: '60%',
          data: json.map(function (item) {
            // if (item && item.PredictedStatus && item.PredictedStatus === 'Abnormal') {
            if (item && item.count) {
              // Change
              return item.count;
            } else {
              return 'null';
            }
            // }
          }),
          type: 'bar',
          color: 'steelblue'
        },
        {
          name: 'Actual Abnormal',
          symbolSize: 9,
          data: json.map(function (item) {
            // if (item && item.ActualStatus && item.ActualStatus === 'Abnormal') {
            if (item && item.count && item.count > 0 && item.status === 4) {
              // Change
              return item.count + 1;
            } else if (item.status === 4 && item.count === 0) {
              return 5;
            } else {
              return 'null';
            }
            // }
          }),
          type: 'scatter',
          color: 'yellow'
        },
        {
          name: 'Future Abnormal',
          // symbolSize: 6,
          barWidth: '60%',
          data: json.map(function (item) {
            if (item && item.Future && item.Future === true) {
              // Change
              return item.count;
            } else {
              return 'null';
            }
          }),
          type: 'bar',
          color: 'red'
        }
      ];
      // }
    }
    option.series = series;
    this.setState({ option });
    return option;
  };

  getOption = () => {
    let option = {
      backgroundColor: 'rgb(41, 65, 90)',
      title: {
        left: 'center',
        textStyle: {
          color: 'white',
          align: 'center'
        }
      },
      tooltip: {
        trigger: 'axis'
      },

      xAxis: {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: 'white'
          }
        },
        axisLabel: {
          color: 'white',
          formatter: function (value) {
            return moment(value).format('HH:mm');
          }
        }
      },
      toolbox: {
        showTitle: false,
        orient: 'horizontal',
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            backgroundColor: '#011931',
            name: this.props.graphprops ? this.props.graphprops.title : 'name',
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      yAxis: {
        splitLine: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: 'white'
          }
        },
        axisLabel: {
          color: 'white'
        }
      },
      dataZoom: [
        {
          // startValue: '2020-04-20'
        },
        {
          type: 'inside'
        }
      ]
      // legend: {
      //   right: 60,
      //   data: this.props.graphprops && this.props.graphprops.predictionsType === "arrayOfObject"
      //     ? ["Predicted Abnormal", "Actual Abnormal", "Future Abnormal"] : ["Anomaly", "Actual"],
      //   textStyle: {
      //     color: "white",
      //   },
      // }
    };
    return option;
  };

  render() {
    return (
      <React.Fragment>
        {/* {this.props.differenceHours === 0 && this.props.difMin > 0 ? ( */}
        {/* // || */}
        {/* // (this.props.differenceHours === undefined && this.props.difMin === undefined) */}
        <ReactEcharts
          //   onEvents={onEvents}
          style={{ height: '90%', width: '200%' }}
          option={this.state.option}
          className="react_for_echarts"
        />
        {/* ) : (
          <NoRecordComponent />
        )} */}
      </React.Fragment>
    );
  }
}

export default PredictedGraph;
