import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DownloadOutlined } from '@ant-design/icons';
import { message, Button, Tabs, Checkbox, Row, Col, Card, Collapse, Upload } from 'antd';

import { getHMICalls, _getReportData, selectDashboard, getDatasourceData } from './ApiCalls';
import { getTranslationCache } from '../.././../selectors/language';
import localStorage from '../../../utils/localStorage';
import './style.css';
import { InboxOutlined } from '@ant-design/icons';
import { API_HMI, UPLOAD } from '../../../commons/api';

const { TabPane } = Tabs;
const { Panel } = Collapse;

class BulkUploadComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datasource: [],
      dashboardData: [],
      selectedData: [],
      selectDashboard: [],
      elogData: [],
      selectElogData: [],
      reportData: [],
      selectReport: [],
      translationCache: props.translationCache || [],
      uploading: false
    };
  }
  componentDidMount() {
    this._getHmiList();
  }

  onChange = (checkedValues) => {
    let { datasource } = this.state;
    let selectedData = [];
    checkedValues.map((checkItem) => {
      let select = datasource.filter((data) => data.Name === checkItem);
      selectedData.push(...select);
      this.setState({ selectedData });
      return {};
    });
  };
  onChangeDashboard = (checkedValues) => {
    let { dashboardData } = this.state;
    let selectDashboard = [];
    checkedValues.map((checkItem) => {
      let select = dashboardData.filter((data) => data.Name === checkItem);
      selectDashboard.push(...select);
      this.setState({ selectDashboard });
      return {};
    });
  };

  onChangeElog = (checkedValues) => {
    let { elogData } = this.state;
    let selectElogData = [];
    checkedValues.map((checkItem) => {
      let select = elogData.filter((data) => data.ShortDescription === checkItem);
      selectElogData.push(...select);
      this.setState({ selectElogData });
      return {};
    });
  };

  onChangeReport = (checkedValues) => {
    let { reportData } = this.state;
    let selectReport = [];
    checkedValues.map((checkItem) => {
      let select = reportData.filter((data) => data.ReportName === checkItem);
      selectReport.push(...select);
      this.setState({ selectReport });
      return {};
    });
  };

  _getHmiList = async () => {
    const siteId = localStorage.get('currentSite');
    let payload = {
      method: 'GET',
      url: `${API_HMI.GET}?SiteId=${siteId}`
    };
    let datasource = await getHMICalls(payload);
    let reportData = await _getReportData();
    let dashboardData = await selectDashboard();
    let elogData = await getDatasourceData();
    this.setState({ datasource, reportData, dashboardData, elogData });
  };

  dashboardUpload = (file) => {
    const dashString = file.name.includes('dash');
    if (dashString) {
      message.success('success');
    } else {
      message.error('Error in File Upload!');
    }
  };
  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    const isCSV = file.type === 'application/json';
    if (!isCSV) {
      message.error('You can only upload json file!');
    }
    if (!isLt2M) {
      message.error('json file must be smaller than 10MB!');
    }
    return isLt2M && isCSV;
  };
  ElogUpload = (file) => {
    const ElogString = file.name.includes('Elog');
    if (ElogString) {
      message.success('success');
    } else {
      message.error('Error in File Upload!');
    }
  };
  HmiUpload = (file) => {
    const hmiString = file.name.includes('hmi');
    if (hmiString) {
      message.success('success');
    } else {
      message.error('Error in File Upload!');
    }
  };
  reportUpload = (file) => {
    const reportString = file.name.includes('report');
    if (reportString) {
      message.success('success');
    } else {
      message.error('Error in File Upload!');
    }
  };
  handleChange = (info) => {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    const {
      datasource,
      selectedData,
      selectDashboard,
      dashboardData,
      elogData,
      selectElogData,
      reportData,
      selectReport
    } = this.state;
    let accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    return (
      <Tabs style={{ minHeight: window.innerHeight - 170 }} defaultActiveKey="1">
        <TabPane tab={this.translation('Export')} key="1">
          <div style={{ padding: '30px' }}>
            <Collapse>
              <Panel header={this.translation('HMI Configuration')} key="1">
                <div style={{ padding: '30px' }}>
                  <Checkbox.Group onChange={this.onChange} style={{ display: 'flex' }}>
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32
                      }}
                    >
                      {datasource &&
                        Array.isArray(datasource) &&
                        datasource.map((name, index) => {
                          let list = name.Name;
                          return (
                            <Col key={index} className="gutter-row" span={6}>
                              <Card
                                style={{
                                  margin: '5px',
                                  minHeight: '80px',
                                  height: '100px',
                                  textAlign: 'center',
                                  width: '100px'
                                }}
                                bordered={false}
                              >
                                <Checkbox style={{ width: '100%' }} value={list}>
                                  {this.translation(list)}
                                </Checkbox>
                              </Card>
                            </Col>
                          );
                        })}
                    </Row>
                  </Checkbox.Group>
                  {selectedData.length >= 1 ? (
                    <Button
                      icon={<DownloadOutlined />}
                      type="primary"
                      href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        JSON.stringify(selectedData)
                      )}`}
                      download="hmiConfiguration.json"
                    >
                      {`Download `}
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </Panel>
              <Panel header={this.translation('Dashboard Configuration')} key="2">
                <div style={{ padding: '30px' }}>
                  <Checkbox.Group onChange={this.onChangeDashboard} style={{ display: 'flex' }}>
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32
                      }}
                    >
                      {dashboardData &&
                        Array.isArray(dashboardData) &&
                        dashboardData.map((name, index) => {
                          let list = name.Name;
                          return (
                            <Col key={index} className="gutter-row" span={6}>
                              <Card
                                style={{
                                  margin: '5px',
                                  minHeight: '80px',
                                  height: '100px',
                                  textAlign: 'center',
                                  width: '100px'
                                }}
                                bordered={false}
                              >
                                <Checkbox style={{ width: '100%' }} value={list}>
                                  {this.translation(list)}
                                </Checkbox>
                              </Card>
                            </Col>
                          );
                        })}
                    </Row>
                  </Checkbox.Group>
                  {selectDashboard.length >= 1 ? (
                    <Button
                      icon={<DownloadOutlined />}
                      type="primary"
                      href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        JSON.stringify(selectDashboard)
                      )}`}
                      download="dashboardConfiguration.json"
                    >
                      {`Download`}
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </Panel>

              <Panel header={this.translation('Elog Configuration')} key="3">
                <Row>
                  <div style={{ padding: '30px' }}>
                    <Checkbox.Group onChange={this.onChangeElog} style={{ display: 'flex' }}>
                      <Row
                        gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 32
                        }}
                      >
                        {/* <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              boxSizing: 'border-box'
                            }}
                          > */}
                        {elogData &&
                          Array.isArray(elogData) &&
                          elogData.map((name, index) => {
                            let list = name.ShortDescription;

                            return (
                              <Col key={index} className="gutter-row" span={4}>
                                <Card
                                  style={{
                                    margin: '5px',
                                    minHeight: '80px',
                                    height: '100px',
                                    textAlign: 'center',
                                    width: '100px'
                                  }}
                                  bordered={false}
                                >
                                  <Checkbox style={{ width: '100%' }} value={list}>
                                    {this.translation(list)}
                                  </Checkbox>
                                </Card>{' '}
                              </Col>
                            );
                          })}
                        {/* </div> */}
                      </Row>
                    </Checkbox.Group>

                    {selectElogData.length >= 1 ? (
                      <Button
                        icon={<DownloadOutlined />}
                        type="primary"
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                          JSON.stringify(selectElogData)
                        )}`}
                        download="ElogConfiguration.json"
                      >
                        {`Download `}
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                </Row>
              </Panel>
              <Panel header={this.translation('Report Configuration')} key="4">
                <div style={{ padding: '30px' }}>
                  <Checkbox.Group onChange={this.onChangeReport} style={{ display: 'flex' }}>
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32
                      }}
                    >
                      {/* <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            boxSizing: 'border-box'
                          }}
                        > */}
                      {reportData &&
                        Array.isArray(reportData) &&
                        reportData.map((name, index) => {
                          let list = name.ReportName;
                          return (
                            <Col key={index} className="gutter-row" span={6}>
                              <Card
                                style={{
                                  margin: '5px',
                                  minHeight: '80px',
                                  height: '100px',
                                  textAlign: 'center',
                                  width: '100px'
                                }}
                                bordered={false}
                              >
                                <Checkbox style={{ width: '100%' }} value={list}>
                                  {this.translation(list)}
                                </Checkbox>
                              </Card>
                            </Col>
                          );
                        })}
                      {/* </div> */}
                    </Row>
                  </Checkbox.Group>
                  {selectReport.length >= 1 ? (
                    <Button
                      icon={<DownloadOutlined />}
                      type="primary"
                      href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        JSON.stringify(selectReport)
                      )}`}
                      download="reportConfiguration.json"
                    >
                      {`Download`}
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </Panel>
            </Collapse>
          </div>
        </TabPane>
        <TabPane tab={this.translation('Import')} key="2">
          <div style={{ background: '#ECECEC', padding: '30px' }}>
            <Row gutter={16}>
              <Col span={6} className="importCard">
                <Card title={this.translation('Report Configuration')} bordered={false}>
                  <Upload.Dragger
                    name="file"
                    action={`${UPLOAD.BULKUPLOAD}?ModuleType=${'Report'}&SiteId=${siteId}`}
                    accept="application/json"
                    multiple="false"
                    headers={{
                      Authorization: `Bearer ${accessToken}`
                    }}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {this.translation('Click or drag file to this area to upload')}
                    </p>
                    <p className="ant-upload-hint">
                      {this.translation('1. You can only upload .json file')}
                    </p>
                    <p className="ant-upload-hint">
                      {this.translation('2. File should be less than 10MB')}
                    </p>
                  </Upload.Dragger>
                </Card>
              </Col>
              <Col span={6} className="importCard">
                <Card title={this.translation('Dashboard Configuration')} bordered={false}>
                  <Upload.Dragger
                    name="file"
                    action={`${UPLOAD.BULKUPLOAD}?ModuleType=${'Dashboard'}&SiteId=${siteId}`}
                    accept="application/json"
                    multiple="false"
                    headers={{
                      Authorization: `Bearer ${accessToken}`
                    }}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {this.translation('Click or drag file to this area to upload')}
                    </p>
                    <p className="ant-upload-hint">
                      {this.translation('1. You can only upload .json file')}
                    </p>
                    <p className="ant-upload-hint">
                      {this.translation('2. File should be less than 10MB')}
                    </p>
                  </Upload.Dragger>
                </Card>
              </Col>
              <Col span={6} className="importCard">
                <Card title={this.translation('HMI Configuration')} bordered={false}>
                  <Upload.Dragger
                    name="file"
                    action={`${UPLOAD.BULKUPLOAD}?ModuleType=${'Hmi'}&SiteId=${siteId}`}
                    accept="application/json"
                    multiple="false"
                    headers={{
                      Authorization: `Bearer ${accessToken}`
                    }}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {this.translation('Click or drag file to this area to upload')}
                    </p>
                    <p className="ant-upload-hint">
                      {this.translation('1. You can only upload .json file')}
                    </p>
                    <p className="ant-upload-hint">
                      {this.translation('2. File should be less than 10MB')}
                    </p>
                  </Upload.Dragger>
                </Card>
              </Col>
              <Col span={6} className="importCard">
                <Card title={this.translation('ELOG Configuration')} bordered={false}>
                  <Upload.Dragger
                    name="file"
                    action={`${UPLOAD.BULKUPLOAD}?ModuleType=${'Elogbook'}&SiteId=${siteId}`}
                    accept="application/json"
                    multiple="false"
                    headers={{
                      Authorization: `Bearer ${accessToken}`
                    }}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleChange}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {this.translation('Click or drag file to this area to upload')}
                    </p>
                    <p className="ant-upload-hint">
                      {this.translation('1. You can only upload .json file')}
                    </p>
                    <p className="ant-upload-hint">
                      {this.translation('2. File should be less than 10MB')}
                    </p>
                  </Upload.Dragger>
                </Card>
              </Col>
            </Row>
          </div>
        </TabPane>
      </Tabs>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(BulkUploadComponent);
