import React from 'react';
import { Form, Input, Select, message, Button, Table, Tabs, Drawer, Upload } from 'antd';
import {
  // StyledTabs,
  // StyledForm,
  StyledComponent
} from '../../CustomerAssetDeviceConfiguration/CSS/style';
import {
  SearchOutlined,
  EditOutlined,
  UploadOutlined,
  DownloadOutlined,
  // DeleteOutlined,
  // QuestionCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { StyledButton, DrawerFooter } from '../../../commons/styles/layout';
import axios from 'axios';
import history from '../../../commons/history';
import themeSettings from '../Utils/themeSettings.json';
import localStorage from '../../../utils/localStorage';
import { API_MODULES, IPAddress, API_RUBUSMASTERS } from '../../../commons/api';
import Highlighter from 'react-highlight-words';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../selectors/language';
// import { tableTranslation } from '../../Adapter/TranslationAdapter';
import { UpdateMasters, CreateMasters, getParametersFileDownloadName } from '../APICalls';
const { TabPane } = Tabs;
const { Option } = Select;
const APIList = {
  CreateParameters: `${API_MODULES.ParametersCreate}`,
  UpdateParameters: `${API_MODULES.ParametersUpdate}`
};
class Parameter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      ModuleCodes: [],
      SelectedDeviceTypes: '',
      list: [],
      form: 'modules',
      presentActiveKey: 'List',
      visibleParent: false
    };
  }
  hForm = React.createRef();
  componentDidMount() {
    this.DeviceTypeApi();
    this._getList();
    this.getFileDownloadName();
  }

  _getList = () => {
    let path = history.location.pathname.split('/rubus/CustomerAssetDevice/DeviceTypesChildren/');
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let url = '';
    url = `/api/rubusMaster/parameters/get?SiteId=${siteId}&DeviceType=${path[1]}`;

    let orgHeaders = {
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ list: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  DeviceTypesSelection = (value) => {
    this.setState({ SelectedDeviceTypes: value });
  };

  DeviceTypeApi = () => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    const type = {
      method: '',
      url: `${API_RUBUSMASTERS.DT_GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(type)
      .then((response) => {
        this.setState({
          ModuleCodes: response.data
        });
      })
      .catch(() => {});
  };
  _editRecord = (record) => {
    // let { presentActiveKey } = this.state;
    this.hForm.current && this.hForm.current.setFieldsValue(record);
    this.setState({
      visibleParent: true,
      formObject: record,
      visible: true
    });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          //maxLength={30}
        />

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  OpenDrawer = () => {
    this.hForm.current &&
      this.hForm.current.setFieldsValue({
        DeviceType: null,
        Code: null,
        Name: null,
        Type: null,
        AliasCode: null,
        Category: null,
        DataType: null,
        UOM: null
      });
    let formObject = {
      DeviceType: null,
      Code: null,
      Name: null,
      Type: null,
      AliasCode: null,
      Category: null,
      DataType: null,
      UOM: null
    };
    this.setState({ visibleParent: true, formObject });
  };
  onClose = () => {
    this.hForm.current &&
      this.hForm.current.setFieldsValue({
        DeviceType: null,
        Code: null,
        Name: null,
        AliasCode: null,
        Type: null,
        Category: null,
        DataType: null,
        UOM: null
      });
    this.setState({ visibleParent: false, formObject: {} });
  };

  onFinish = async () => {
    let path = history.location.pathname.split('/rubus/CustomerAssetDevice/DeviceTypesChildren/');
    let { formObject } = this.state;
    this.hForm.current &&
      this.hForm.current
        .validateFields()
        .then(async (values) => {
          let payload = {
            ...values,
            DeviceType: path && Array.isArray(path) && path[1] ? path[1] : ''
          };

          if (formObject && formObject.Id) {
            let UpdateResponse = await UpdateMasters(
              APIList.UpdateParameters,
              payload,
              formObject.Id
            );
            message.success(UpdateResponse && UpdateResponse.message);
          } else {
            let CreateResponse = await CreateMasters(APIList.CreateParameters, payload);
            message.success(CreateResponse && CreateResponse.message);
          }
          this._getList();
          this.getFileDownloadName();
          this.hForm.current &&
            this.hForm.current.setFieldsValue({
              DeviceType: null,
              Code: null,
              Name: null,
              AliasCode: null,
              Type: null,
              Category: null,
              DataType: null,
              UOM: null
            });
          this.setState({ formObject: {}, visibleParent: false });
        })
        .catch((error) => {
          console.log(error);
        });
  };
  getFileDownloadName = async () => {
    let path = history.location.pathname.split('/rubus/CustomerAssetDevice/DeviceTypesChildren/');
    let fileDownloadName = await getParametersFileDownloadName(path[1]);
    this.setState({
      fileDownloadName: fileDownloadName && fileDownloadName.file ? fileDownloadName.file : ''
    });
  };

  render() {
    let {
      formObject,
      list,
      visibleParent,
      fileDownloadName
      // SelectedDeviceTypes, ModuleCodes,
    } = this.state;
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    let path = history.location.pathname.split('/rubus/CustomerAssetDevice/DeviceTypesChildren/');
    let colum = [
      { key: 'S No', value: 'S No' },
      { key: 'Parameter Code', value: 'Code' },
      { key: 'Parameter Name', value: 'Name' },
      { key: 'Type', value: 'Type' },
      { key: 'Category', value: 'Category' },
      { key: 'Data Type', value: 'DataType' },
      { key: 'UOM', value: 'UOM' }
    ];
    let columns =
      colum && Array.isArray(colum)
        ? colum
            // .filter((col) => col !== 'Id' && col !== 'SiteId' && col !== 'AliasCode')
            .map((field) => {
              return {
                title: field.key,
                dataIndex: field.value,
                key: field.value,
                ...this.getColumnSearchProps(field.value)
              };
            })
        : [];

    // let translationTable = tableTranslation(
    //   columns,
    //   this.props.translationCache,
    //   this.props.language
    // );

    let finalColumns = [
      ...columns,
      {
        title: this.translation('Actions'),
        dataIndex: 'Actions',
        key: 'Actions',
        render: (_text, record) => (
          <span>
            <Button
              className="ant-btn"
              icon={<EditOutlined />}
              onClick={() => this._editRecord(record)}
              size="middle"
            />
            {/* <Popconfirm
              title="Are you sure that you want to delete"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this.deleteRecord(record.Id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="ant-btn" size="middle" style={{ marginLeft: '20px' }}>
                <span>
                  {' '}
                  <DeleteOutlined />
                </span>
              </Button>
            </Popconfirm> */}
            {/* <Button
              className="ant-btn"
              // onClick={() => this._editRecord(record)}
              size="middle"
              type="primary"
            >
              Next
            </Button> */}
          </span>
        )
      }
    ];
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '0px 30px', minHeight: window.innerHeight - 143 }}
      >
        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '10px' }}>
          <a href={`${IPAddress.LOCAL}${fileDownloadName}`}>
            <DownloadOutlined style={{ color: 'white', fontSize: '16px', marginRight: '1px' }} />
            <span style={{ color: 'white', marginRight: '10px' }}>Download </span>
          </a>
          <Upload
            multiple={false}
            style={{ marginRight: '10px', color: 'white' }}
            onChange={(info) => {
              if (info.file.status === 'done') {
                message.success(info.file.response.message);
                this.getFileDownloadName();
                this._getList();
              } else if (info.file.status === 'error') {
                message.error(info.file.response.message);
              }
            }}
            action={`/api/rubusMaster/upload/parameters?SiteId=${siteId}&DeviceTypeAlias=${path[1]}`}
            headers={{
              Authorization: Authorization
            }}
            showUploadList={false}
          >
            <UploadOutlined style={{ color: 'white', fontSize: '16px', marginRight: '1px' }} />
            <span style={{ color: 'white', marginRight: '10px' }}> Upload </span>
          </Upload>
          <StyledButton onClick={this.OpenDrawer}>
            <PlusOutlined /> {this.translation('Add Parameter')}
          </StyledButton>
        </div>

        <Tabs
          // tabPosition="top"
          // type="card"
          // style={{ marginTop: '20px', marginBottom: '30px' }}
          theme={themeSettings}
        >
          <TabPane>
            <Table
              className="basictable"
              columns={finalColumns}
              dataSource={list}
              pagination={
                list.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : false
              }
            />
          </TabPane>
        </Tabs>

        <Drawer
          title={
            formObject && formObject.Id
              ? this.translation('Update Parameter')
              : this.translation('Add Parameter')
          }
          placement="right"
          width={600}
          closable
          onClose={this.onClose}
          visible={visibleParent}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            theme={themeSettings}
            layout="horizontal"
            ref={this.hForm}
            name="Site_form"
            initialValues={formObject}
            onFinish={this.onFinish}
          >
            {/* <Form.Item
              label="Device Type"
              name="DeviceType"
              rules={[{ required: true, message: 'Please enter DeviceType' }]}
            >
              <Select
                value={SelectedDeviceTypes}
                placeholder="Please select Modules Code"
                onChange={this.DeviceTypesSelection}
              >
                {ModuleCodes.map((value, index) => {
                  return (
                    <Option key={index} value={value.Code}>
                      {value.DisplayCode}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item> */}
            <Form.Item
              label={this.translation('Code')}
              name="Code"
              rules={[{ required: true, message: this.translation('Please enter Code !') }]}
            >
              <Input
                disabled={formObject && formObject.Id ? true : false}
                placeholder={this.translation('Please enter Code ')}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Name')}
              name="Name"
              rules={[{ required: true, message: this.translation('Please enter Name !') }]}
            >
              <Input placeholder={this.translation('Please enter Name')} />
            </Form.Item>
            {/* {formObject && formObject.AliasCode ? null : (
              <Form.Item
                label={this.translation('Alias Code')}
                name="AliasCode"
                rules={[{ required: true, message: this.translation('Please enter AliasCode') }]}
              >
                <Input placeholder={this.translation("Please enter AliasCode'")} />
              </Form.Item>
            )} */}
            <Form.Item
              label={this.translation('Type')}
              name="Type"
              rules={[{ required: true, message: this.translation('Please enter Type') }]}
            >
              <Input placeholder={this.translation('Please enter Type')} />
            </Form.Item>
            <Form.Item
              label={this.translation('Category')}
              name="Category"
              rules={[{ required: true, message: this.translation('Please select Category') }]}
            >
              <Select placeholder={this.translation('Select Category')}>
                <Option value="Operational">{'Operational'}</Option>
                <Option value="OnChange">{'OnChange'}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={this.translation('DataType')}
              name="DataType"
              rules={[{ required: true, message: this.translation('Please enter DataType') }]}
            >
              <Input placeholder={this.translation('Please enter DataType')} />
            </Form.Item>
            <Form.Item
              label={this.translation('UOM')}
              name="UOM"
              rules={[{ required: true, message: this.translation('Please enter UOM') }]}
            >
              <Input placeholder={this.translation('Please enter UOM')} />
            </Form.Item>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            <Button type="primary" onClick={this.onFinish}>
              {this.translation('Submit')}
            </Button>
          </DrawerFooter>
        </Drawer>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(Parameter);
