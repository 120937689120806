import React, { Component } from 'react';
import { Row, Col, Tabs } from 'antd';

import { Header, TableView } from '.';

import '../CSS/eLog.css';

const { TabPane } = Tabs;

class SubSectionTableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: props.form || {},
      columns: props.form && props.form.columns ? props.form.columns : [],
      data: props.form && props.form.datasource ? props.form.datasource : [],
      activeKey: []
    };
  }

  componentDidMount() {
    this.setState({
      form: this.props.form,
      columns: this.props.form && this.props.form.columns ? this.props.form.columns : [],
      data: this.props.form && this.props.form.datasource ? this.props.form.datasource : []
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.form !== this.props.form) {
      this.setState({
        form: this.props.form,
        columns: this.props.form && this.props.form.columns ? this.props.form.columns : [],
        data: this.props.form && this.props.form.datasource ? this.props.form.datasource : []
      });
    }
  }

  setValue = (name, e) => {
    let { form } = this.state;
    let headerValue = {
      ...(form && form[name] && form[name].headerValue),
      [e.key]: e.value
    };
    form = {
      ...form,
      [name]: {
        ...(form && form[name] ? form[name] : {}),
        headerValue: {
          ...headerValue
        }
      }
    };
    this.setState({
      form
    });
    this.props.saveSubSectionTableViewData(form);
  };
  saveTableViewData = (name, data) => {
    let { form } = this.state;
    // data && data.map((dataObject) => {
    //     // delete dataObject["key"];
    //     // delete dataObject["isNew"];
    //     // delete dataObject["editable"];
    // });
    form = {
      ...form,
      [name]: {
        ...(form[name] ? form[name] : {}),
        datasource: data
      }
    };
    this.setState({
      form
    });
    this.props.saveSubSectionTableViewData(form);
  };

  callback = (key) => {
    this.setState({
      activeKey: key
    });
  };
  render() {
    let { loading, recordType, form } = this.state;
    return (
      <div className="card-container" style={{ marginTop: '30px', marginBottom: '30px' }}>
        <Tabs
          type="card"
          bordered={true}
          // activeKey={activeKey}
          onChange={this.callback}
        >
          {form &&
            Object.keys(form).map((name) => {
              if (name !== 'batch') {
                return (
                  <TabPane tab={name} key={name}>
                    <Row>
                      <Col span={16}>
                        <Header
                          header={form[name].header}
                          headerValue={form[name].headerValue}
                          recordType={recordType}
                          setValue={(e) => this.setValue(name, e)}
                          form={form}
                          sectioName={name}
                        />
                      </Col>
                    </Row>
                    <TableView
                      form={form[name]}
                      loading={loading}
                      recordType={recordType}
                      saveTableViewData={(e) => this.saveTableViewData(name, e)}
                    />
                  </TabPane>
                );
              }
              return {};
            })}
        </Tabs>
      </div>
    );
  }
}

export default SubSectionTableView;
