import React, { Component } from 'react';
import { Alert, Button, Form, Select } from 'antd';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../../../selectors/language';

class ZeroAdjust extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: '',
      translationCache: props.translationCache || []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    };
    const buttonItemLayout = {
      wrapperCol: {
        span: 14,
        offset: 4
      }
    };
    return (
      <div>
        <Form
          {...formItemLayout}
          layout="horizontal"
          name="HeaderNumber_current"
          className="formComponent"
        >
          <Form.Item label={this.translation('HD')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="01">01</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={this.translation('X1')}>
            <div>0.6656</div>
          </Form.Item>
          <Form.Item label={this.translation('X2')}>
            <div>0.3944</div>
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button type="primary">{this.translation('Zero Adjust')}</Button>
          </Form.Item>

          <Alert
            message={this.translation('Note:')}
            description={this.translation('Click The Zero Adjust After Attaching The Zero')}
            type="warning"
          />
        </Form>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(ZeroAdjust);
