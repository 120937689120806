/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import { Col, Divider, Row, message } from 'antd';

import { StyledComponent } from '../../../CSS/style';
import {
  getDeviceList,
  submitWorkOrder,
  // getWorkOrderName,
  getLocationByAsset,
  getOverview
  // getWorkOrderById
} from '../../../Utils/FormCalls';
import ReactEcharts from '../../../../Dashboard/ChartComponents/Echarts/Echarts';
import themeSettings from '../../../Utils/themeSettings.json';
import history from '../../../../../commons/history';
import { connect } from 'react-redux';
// import { decode as base64_decode } from 'base-64';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import { StyledDescriptions } from '../../../CSS/style';
const fileData = new FormData();

class EquipmentOverviewDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      sitesList: [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      activeKey: '1',
      datasource: [],
      data: {},
      translationCache: props.translationCache || {},
      option: {
        label: {
          color: 'white',
          fontWeight: 'bold',
          fontSize: 14
        },
        textStyle: {
          color: 'white',
          fontWeight: 'bold',
          fontSize: 18
        },
        legend: {
          left: 'center',
          textStyle: {
            color: 'white'
          },
          data: []
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'No Of Materials Used' },
              { value: 735, name: 'No Of Parts Replaced' },
              { value: 580, name: 'No Of Downtimes' },
              { value: 484, name: 'No Of Inspections' }
            ]
          }
        ]
      },
      optionBar: {
        legend: {
          textStyle: { color: 'white' }
        },
        tooltip: {},
        dataset: {
          dimensions: [
            'product',
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ],
          source: [
            {
              product: 'No Of Inspections',
              January: 43.3,
              February: 85.8,
              March: 93.7,
              April: 21.7,
              May: 76.4,
              June: 32.9,
              July: 54,
              August: 92,
              September: 39,
              October: 84,
              November: 72,
              December: 53
            },
            {
              product: 'No Of Downtimes',
              January: 83.1,
              February: 73.4,
              March: 55.1,
              April: 21.7,
              May: 76.4,
              June: 32.9,
              July: 54,
              August: 92,
              September: 39,
              October: 84,
              November: 72,
              December: 53
            },
            {
              product: 'No Of Repairs',
              January: 86.4,
              February: 65.2,
              March: 82.5,
              April: 21.7,
              May: 76.4,
              June: 32.9,
              July: 54,
              August: 92,
              September: 39,
              October: 84,
              November: 72,
              December: 53
            },
            {
              product: 'No Of Materials Used',
              January: 72.4,
              February: 53.9,
              March: 39.1,
              April: 21.7,
              May: 76.4,
              June: 32.9,
              July: 54,
              August: 92,
              September: 39,
              October: 84,
              November: 72,
              December: 53
            }
          ]
        },
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: {
              color: 'white'
            }
          },
          axisTick: {
            lineStyle: {
              color: 'white'
            }
          },
          axisLabel: {
            color: 'white'
          }
        },
        yAxis: {
          axisLine: {
            lineStyle: {
              color: 'white'
            }
          },
          axisTick: {
            lineStyle: {
              color: 'white'
            }
          },
          axisLabel: {
            color: 'white'
          }
        },
        series: [
          { type: 'bar' },
          { type: 'bar' },
          { type: 'bar' },
          { type: 'bar' },
          { type: 'bar' },
          { type: 'bar' },
          { type: 'bar' },
          { type: 'bar' },
          { type: 'bar' },
          { type: 'bar' },
          { type: 'bar' },
          { type: 'bar' }
        ]
      }
    };
  }
  EquipmentOverViewRef = React.createRef();

  componentDidMount = async () => {
    this._getDataById();
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    this.getOverviewDetails();
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.EquipmentOverViewRef.current.setFieldsValue(this.props.formObject);
      // this.formRef.current.setFieldsValue(this.props.formObject.TaskData)
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (prevProps.formObject !== this.props.formObject) {
      // if (this.props.formObject && !this.props.formObject.WorkOrder) {
      //   let workOrderName = await getWorkOrderName();
      //   this.handleChange(workOrderName['WorkOrder'], Object.keys(workOrderName)[0]);
      // }
      this.setState({
        formObject: this.props.formObject
      });
    }
  };
  getOverviewDetails = async () => {
    let pathname = history.location.pathname;
    let tmp = pathname.slice(pathname.lastIndexOf('/') + 1);
    let data = await getOverview(tmp);
    this.setState({
      data
    });
  };
  _getDataById = async () => {
    let formObject = {};
    // let path = history.location.pathname.split('/rubus/AMM/EquipmentOverviewDetails/');
    // let formId = base64_decode(path[1]);
    // if (formId !== 'new') {
    //   formObject = await getMasterById(urlList.getbyId, base64_decode(path[1]), 'GET');
    //   _.cloneDeepWith(formObject, (value) => {
    //     return value && value !== null && value.InstallationDate
    //       ? {
    //           ...value,
    //           ...(value['InstallationDate'] = moment.utc(
    //             value.InstallationDate,
    //             'YYYY-MM-DD HH:mm:ss'
    //           )),
    //           ...(value['RefurbishedDate'] = moment.utc(value.RefurbishedDate, 'YYYY-MM-DD HH:mm:ss'))
    //         }
    //       : _.noop();
    //   });
    // }

    this.setState({
      formObject
    });
    if (this.EquipmentOverViewRef.current) {
      this.EquipmentOverViewRef.current.setFieldsValue(formObject);
    }
  };
  handleSelect = (value, mainIndex, subindex, keyName) => {
    // let list = this.formRef.current.getFieldValue("TaskData")
    let list = {};
    let TaskData = list;
    TaskData.map((item, index) => {
      if (index === mainIndex) {
        item[keyName][subindex]['TotalQuantity'] = value;
      }
      return {};
    });
    // this.formRef.current.setFieldsValue({ "TaskData": TaskData })
  };
  handleChange = async (value, key) => {
    let { formObject, deviceList } = this.state;

    formObject = {
      ...formObject,
      [key]: value
    };
    if (key === 'DeviceId') {
      let selectedDevice = deviceList.filter((item) => item.Id === value);
      let location = await getLocationByAsset(selectedDevice[0]);
      formObject = {
        ...formObject,
        [key]: value,
        LocationId: location.data.Name
      };
      // this.EquipmentOverViewRef.current.setFieldsValue({"LocationId":location["Name"]})
    }

    this.EquipmentOverViewRef.current.setFieldsValue(formObject);

    this.setState({ [key]: value, formObject });
  };
  fileupload = async (values, key, fileDataObject) => {
    let formObject = this.EquipmentOverViewRef.current.getFieldsValue();

    if (formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    formObject[key] =
      formObject[key] &&
      formObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    this.EquipmentOverViewRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };
  onChangeEquipment = async (data) => {
    let { deviceList } = this.state;
    let formObject = this.EquipmentOverViewRef.current.getFieldsValue();
    let selectedDevice = deviceList.filter((test) => test.AliasCode === data);
    formObject = {
      ...formObject,
      EquipmentDescription: selectedDevice[0].Name
    };
    this.setState({
      formObject
    });
    this.EquipmentOverViewRef.current.setFieldsValue(formObject);
  };
  onFormsubmit = async () => {
    let { formObject, fileData } = this.state;
    formObject = {
      ...formObject
    };
    let statusResponse = await submitWorkOrder(formObject, fileData);
    if (statusResponse && statusResponse.message) {
      // history.goBack();
      this._getDataById();
      message.success('WorkOrder Submitted Successfully');
      this.onReset();
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  onReset = () => {
    this.EquipmentOverViewRef.current.resetFields();
  };
  // onFinishFailed = () => {
  //   message.error('Please enter mandatory fields');
  // };
  render() {
    let { data } = this.state;
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '0px 15px', minHeight: window.innerHeight - 143 }}
        >
          <div>
            <StyledDescriptions style={{ color: 'white' }} layout="vertical">
              <StyledDescriptions.Item label="Equipment">{data.Equipment}</StyledDescriptions.Item>
              <StyledDescriptions.Item label="Equipment Description">
                {data.EquipmentDescription}
              </StyledDescriptions.Item>
              <StyledDescriptions.Item label="Installation Date">
                {data.InstallationDate}
              </StyledDescriptions.Item>
              <StyledDescriptions.Item label="Equipment Capacity">
                {data.Capacity}
              </StyledDescriptions.Item>

              <StyledDescriptions.Item label="No Of WOs Created">
                {data.WorkOrdersCount}
              </StyledDescriptions.Item>
              <StyledDescriptions.Item label="Run Hours">{data.RunHours}</StyledDescriptions.Item>
              <StyledDescriptions.Item label="OEE">{data.OEECount}</StyledDescriptions.Item>

              <StyledDescriptions.Item label="No Of Parts Replaced">
                {data.PartsCount}
              </StyledDescriptions.Item>
              <StyledDescriptions.Item label="No Of Inspections">
                {data.InspectionCount}
              </StyledDescriptions.Item>
              <StyledDescriptions.Item label="No Of Maintainece">
                {data.MaintaineceCount}
              </StyledDescriptions.Item>
              <StyledDescriptions.Item label="No Of Materials Used">
                {data.MaterialsCount}
              </StyledDescriptions.Item>

              <StyledDescriptions.Item label="Total No of Downtimes">
                {data.TotalDownTime}
              </StyledDescriptions.Item>

              <StyledDescriptions.Item label="Most Recent Inspection">
                {data.InspectionDate}
              </StyledDescriptions.Item>
              <StyledDescriptions.Item label="Most Recent Downtime">
                {data.DownTimeDate}
              </StyledDescriptions.Item>
              <StyledDescriptions.Item label="Most Recent Repair">
                {data.RepairDate}
              </StyledDescriptions.Item>
            </StyledDescriptions>
          </div>
          <Divider></Divider>
          <Row gutter={[16, 16]}>
            <Col span={7}>
              <ReactEcharts option={this.state.option} className="react_for_echarts" />
            </Col>
            <Col span={11}>
              <ReactEcharts option={this.state.optionBar} className="react_for_echarts" />
            </Col>
          </Row>
        </StyledComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(EquipmentOverviewDetails);
