import { fromJS } from 'immutable';
import { types } from './types';

export const setMenuSelection = (menuHierarchy) => {
  return {
    type: types.SET_MENU_SELECTION,
    menuHierarchy
  };
};

const initialState = fromJS({
  menuSelected: []
});

const menu = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_MENU_SELECTION: {
      return state.set('menuSelected', action.menuHierarchy);
    }
    default:
      return state;
  }
};

export default menu;
