import React from 'react';
import moment from 'moment';
import axios from 'axios';
import history from '../../../commons/history';
import { DatePicker, Radio, Select, Menu } from 'antd';
import { getTranslationCache } from '../../../selectors/language';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Area,
  Bar,
  Table,
  Guage,
  GridGuage,
  Pie,
  PolarGraph,
  // PieRose,
  SingleLineDiagram,
  Donut,
  Counter,
  CompassGuage,
  // Funnel,
  CandleStick,
  SingleBar,
  Line,
  ProgressBar,
  // Regression,
  PieBar,
  BubbleChart,
  // CandleStickBrush,
  TreeGraph,
  // BoxPlot,
  BeijingLine,
  // LiquidGuage,
  SingleCandleStick,
  BarTable,
  StatusCounter,
  Scatter,
  HeatmapCartesion,
  HeatmapCalendar,
  ConfusionMatrix,
  DottedBar,
  PieSingleLine,
  PieLine,
  CardsList,
  WindBarb,
  StackedBar,
  StackedLine,
  FeedbackTable,
  ImageGalleryWithPreview,
  LiveStreaming,
  GuageRing,
  NotificationTable,
  SelectImageDetails,
  // PieNestComponent,
  LineTable,
  SliderStatus,
  GuageLine,
  NotificationScatter,
  LineBarArea,
  CounterLine,
  GeoMap,
  SunBurst,
  DonutCounter,
  HeatmapCartesionTable,
  CountersPie,
  Video,
  LineTableCounter,
  Report,
  Image,
  LineWithSelection,
  Predictions
} from '../ChartComponents';
import localStorage from '../../../utils/localStorage';
import { DateSelection } from './CSS/styles';

const { MonthPicker, WeekPicker, RangePicker } = DatePicker;
const { Option } = Select;

class Graph extends React.Component {
  constructor(props) {
    super(props);
    let { graphprops } = props;
    let selectionPoint =
      graphprops &&
      graphprops.GraphLevelSelectionPoint &&
      graphprops.GraphLevelSelectionPoint === 'PreviousDay'
        ? '1'
        : '0';
    // if (
    //   graphprops &&
    //   graphprops.GraphLevelSelection === 'dateMonthYearPicker' &&
    //   graphprops.GraphLevelTimeBucket &&
    //   Object.keys(graphprops.GraphLevelTimeBucket).length > 0
    // ) {
    //   let tb = graphprops.GraphLevelTimeBucket;
    //   if (Object.keys(tb)[0] === 'Day') {
    //     dayMonthYear = {
    //       StartDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD 00:00:00'),
    //       EndDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss'),
    //       TimeBucket: tb['Day'],
    //       SelectionType: 'Day'
    //     };
    //   } else if (Object.keys(tb)[0] === 'Week') {
    //     dayMonthYear = {
    //       StartDate: moment().format('YYYY-MM-DD 00:00:00'),
    //       EndDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss'),
    //       TimeBucket: tb['Week'],
    //       SelectionType: 'Week'
    //     };
    //   } else if (Object.keys(tb)[0] === 'Month') {
    //     dayMonthYear = {
    //       StartDate: moment().format('YYYY-MM-DD 00:00:00'),
    //       EndDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss'),
    //       TimeBucket: tb['Month'],
    //       SelectionType: 'Month'
    //     };
    //   } else if (Object.keys(tb)[0] === 'Year') {
    //     dayMonthYear = {
    //       StartDate: moment().format('YYYY-MM-DD 00:00:00'),
    //       EndDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss'),
    //       TimeBucket: tb['Year'],
    //       SelectionType: 'Year'
    //     };
    //   }
    // }

    this.state = {
      graphprops,
      GraphLevelSelection: {
        ...(graphprops &&
        graphprops.GraphLevelSelection &&
        graphprops.DashboardLevelSelection === 'none'
          ? {
              selectionDate: moment().subtract(selectionPoint, 'day').format('YYYY-MM-DD HH:mm:ss'),
              selectionDateRange: {
                startDate: moment()
                  .subtract(selectionPoint, 'day')
                  .subtract(1, 'hour')
                  .format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment().subtract(selectionPoint, 'day').format('YYYY-MM-DD HH:mm:ss')
              },
              selectionMonth: moment().format('YYYY-MM')
            }
          : {
              selectionDate: moment(this.props.selectionDate).format('YYYY-MM-DD HH:mm:ss'),
              selectionDateRange: {
                startDate: moment(this.props.selectionDateRange.startDate).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
                endDate: moment(this.props.selectionDateRange.endDate).format('YYYY-MM-DD HH:mm:ss')
              },
              selectionMonth: moment(this.props.selectionMonth).format('YYYY-MM')
            })
      },

      currentDate: moment(),
      currentTab: 'Day',
      week: moment().startOf('week'),
      month: moment().format('MMMM'),
      year: moment().year()
    };
  }

  componentDidMount() {
    let { graphprops } = this.props;
    let { dayMonthYear, defaultSelection } = this.state;
    if (
      graphprops &&
      graphprops.GraphLevelSelection &&
      graphprops.GraphLevelSelection === 'deviceSelection'
    ) {
      this.getOptionList();
    }
    if (graphprops.DashboardLevelSelection !== 'none') {
      defaultSelection =
        graphprops &&
        graphprops.DashboardLevelSelectionPoint &&
        graphprops.DashboardLevelSelectionPoint === 'PreviousDay'
          ? '1'
          : '0';
    } else if (graphprops.GraphLevelSelection !== 'none') {
      defaultSelection =
        graphprops &&
        graphprops.GraphLevelSelectionPoint &&
        graphprops.GraphLevelSelectionPoint === 'PreviousDay'
          ? '1'
          : '0';
    }
    if (
      graphprops &&
      graphprops.GraphLevelSelection === 'dateMonthYearPicker' &&
      graphprops.GraphLevelTimeBucket &&
      Object.keys(graphprops.GraphLevelTimeBucket).length > 0
    ) {
      let tb = graphprops.GraphLevelTimeBucket;
      if (Object.keys(tb)[0] === 'Day') {
        dayMonthYear = {
          StartDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD 00:00:00'),
          EndDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss'),
          TimeBucket: tb['Day'],
          SelectionType: 'Day'
        };
      } else if (Object.keys(tb)[0] === 'Week') {
        dayMonthYear = {
          StartDate: moment().format('YYYY-MM-DD 00:00:00'),
          EndDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss'),
          TimeBucket: tb['Week'],
          SelectionType: 'Week'
        };
      } else if (Object.keys(tb)[0] === 'Month') {
        dayMonthYear = {
          StartDate: moment().format('YYYY-MM-DD 00:00:00'),
          EndDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss'),
          TimeBucket: tb['Month'],
          SelectionType: 'Month'
        };
      } else if (Object.keys(tb)[0] === 'Year') {
        dayMonthYear = {
          StartDate: moment().format('YYYY-MM-DD 00:00:00'),
          EndDate: moment().subtract(defaultSelection, 'day').format('YYYY-MM-DD HH:mm:ss'),
          TimeBucket: tb['Year'],
          SelectionType: 'Year'
        };
      }
    }
    this.setState({ dayMonthYear });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode
    ) {
      this.setState({
        GraphLevelSelection: {
          selectionDate: moment(this.props.selectionDate).format('YYYY-MM-DD HH:mm:ss'),
          selectionDateRange: {
            startDate: moment(this.props.selectionDateRange.startDate).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            endDate: moment(this.props.selectionDateRange.endDate).format('YYYY-MM-DD HH:mm:ss')
          },
          selectionMonth: moment(this.props.selectionMonth).format('YYYY-MM'),
          dayMonthYear: this.state.dayMonthYear
        },
        currentTab:
          this.props && this.props.dayMonthYear && this.props.dayMonthYear.SelectionType
            ? this.props.dayMonthYear.SelectionType
            : this.state.currentTab
      });
    }
  }

  onChangeDatePicker = (value, dateString) => {
    this.setState({
      GraphLevelSelection: {
        ...this.state.GraphLevelSelection,
        selectionDate: moment(dateString).format('YYYY-MM-DD 00:00:00')
      }
    });
  };

  onDateRangePickerChange = (currentDate) => {
    let selectionDateRange = {};
    if (currentDate && currentDate[0] && currentDate[1]) {
      selectionDateRange = {
        startDate: currentDate[0].format('YYYY-MM-DD HH:mm:ss'),
        endDate: currentDate[1].format('YYYY-MM-DD HH:mm:ss')
      };
    }
    this.setState({
      GraphLevelSelection: {
        ...this.state.GraphLevelSelection,
        selectionDateRange
      }
    });
  };

  onChangeMonthPicker = (value, dateString) => {
    this.setState({
      GraphLevelSelection: {
        ...this.state.GraphLevelSelection,
        selectionMonth: dateString
      }
    });
  };

  disabledDate = (current) => {
    let { graphprops } = this.props;
    let selectionPoint = '0';
    if (graphprops && graphprops.DashboardLevelSelection !== 'none') {
      selectionPoint =
        graphprops &&
        graphprops.DashboardLevelSelectionPoint &&
        graphprops.DashboardLevelSelectionPoint === 'PreviousDay'
          ? '1'
          : '0';
    } else if (graphprops && graphprops.GraphLevelSelection !== 'none') {
      selectionPoint =
        graphprops &&
        graphprops.GraphLevelSelectionPoint &&
        graphprops.GraphLevelSelectionPoint === 'PreviousDay'
          ? '1'
          : '0';
    }
    return current > moment().startOf('day').add(1, 'day').subtract(selectionPoint, 'day');
  };

  disabledDateRangePicker = (current) => {
    let { graphprops } = this.props;
    let selectionPoint =
      graphprops &&
      graphprops.DashboardLevelSelectionPoint &&
      graphprops.DashboardLevelSelectionPoint === 'PreviousDay'
        ? '1'
        : '0';

    selectionPoint =
      graphprops &&
      graphprops.GraphLevelSelectionPoint &&
      graphprops.GraphLevelSelectionPoint === 'PreviousDay'
        ? '1'
        : '0';
    return current > moment().startOf('day').subtract(selectionPoint, 'day');
  };

  onChange = async (date) => {
    let { graphprops } = this.props;
    let selectionPoint =
      graphprops &&
      graphprops.GraphLevelSelectionPoint &&
      graphprops.GraphLevelSelectionPoint === 'PreviousDay'
        ? '1'
        : '0';

    let startDateSelected = moment(date).format('YYYY-MM-DD 00:00:00');
    let endDateSelected = moment(date).format('YYYY-MM-DD 23:59:59');
    console.log(startDateSelected, endDateSelected);

    let timeBucketObject =
      this.props && this.props.graphprops && this.props.graphprops.GraphLevelTimeBucket
        ? this.props.graphprops.GraphLevelTimeBucket
        : {};
    let payload = {
      StartDate: moment(startDateSelected)
        .subtract(selectionPoint, 'day')
        .format('YYYY-MM-DD HH:mm:ss'),
      EndDate: moment(endDateSelected)
        .subtract(selectionPoint, 'day')
        .format('YYYY-MM-DD HH:mm:ss'),
      SelectionType: 'Day',
      TimeBucket: timeBucketObject['Day']
    };

    this.setState({
      currentDate: date,
      GraphLevelSelection: {
        ...this.state.GraphLevelSelection,
        dayMonthYear: payload
      }
    });
  };

  onRadioChange = (e) => {
    let panelId =
      this.props && this.props.graphprops && this.props.graphprops.id
        ? this.props.graphprops.id
        : null;
    if (e && e.target && e.target.value === 'Month') {
      let currentMonth = moment().format('MMMM');
      let currentYear = moment().year();
      this.handleMenuClick(currentMonth, 'Month', panelId);
      this.setState({
        currentTab: e.target.value,
        month: currentMonth,
        year: currentYear
      });
    } else if (e && e.target && e.target.value === 'Year') {
      let currentYear = moment().year();
      this.handleMenuClick(currentYear, 'Year', panelId);
      this.setState({
        currentTab: e.target.value,
        year: currentYear
      });
    } else if (e && e.target && e.target.value === 'Week') {
      let currentWeek = moment().startOf('isoWeek');
      this.handleMenuClick(currentWeek, 'Week', panelId);
      this.setState({
        currentTab: e.target.value,
        week: currentWeek
      });
    } else if (e && e.target && e.target.value === 'Day') {
      this.onChange(moment(), panelId);
      this.setState({
        currentTab: e.target.value
      });
    } else {
      this.onChange(moment(), panelId);
      this.setState({
        currentTab: e.target.value
      });
    }
  };

  getNavMenuItems = (menusData) => {
    if (!menusData) {
      return [];
    }
    return menusData
      .map((item) => {
        return <Menu.Item key={item}>{item}</Menu.Item>;
      })
      .filter((item) => item);
  };

  handleMenuClick = async (value, tabType) => {
    let { currentyear } = this.state;
    let { graphprops } = this.props;
    let payload = {};
    let GraphLevelSelectionPoint =
      graphprops &&
      graphprops.GraphLevelSelectionPoint &&
      graphprops.GraphLevelSelectionPoint === 'PreviousDay'
        ? '1'
        : '0';

    let timeBucketObject =
      graphprops && graphprops.GraphLevelTimeBucket ? graphprops.GraphLevelTimeBucket : {};
    if (tabType === 'Month') {
      let currentYear = moment().year();
      let selectedMonth = moment(value, 'MMMM').month();
      payload = {
        StartDate: moment([currentYear, selectedMonth])
          .startOf('month')
          .format('YYYY-MM-DD HH:mm:ss'),
        EndDate: currentyear
          ? currentyear === moment().year() && selectedMonth === parseInt(moment().format('M') - 1)
            ? moment().subtract(GraphLevelSelectionPoint, 'day').format('YYYY-MM-DD HH:mm:ss')
            : moment([currentyear, selectedMonth]).endOf('month').format('YYYY-MM-DD HH:mm:ss')
          : currentYear === moment().year() && selectedMonth === parseInt(moment().format('M') - 1)
          ? moment().subtract(GraphLevelSelectionPoint, 'day').format('YYYY-MM-DD HH:mm:ss')
          : moment([currentYear, selectedMonth]).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        TimeBucket: timeBucketObject['Month'],
        SelectionType: 'Month'
      };
    } else if (tabType === 'Year') {
      payload = {
        StartDate: moment([value, 0]).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        EndDate:
          value === moment().year()
            ? moment().subtract(GraphLevelSelectionPoint, 'day').format('YYYY-MM-DD HH:mm:ss')
            : moment([value, 11]).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        TimeBucket: timeBucketObject['Year'],
        SelectionType: 'Year'
      };
    } else if (tabType === 'Week') {
      let currentWeek = moment().startOf('isoWeek');
      payload = {
        StartDate: moment(value).startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        EndDate: value.isSame(currentWeek)
          ? moment().subtract(GraphLevelSelectionPoint, 'day').format('YYYY-MM-DD HH:mm:ss')
          : moment(value).endOf('week').format('YYYY-MM-DD HH:mm:ss'),
        TimeBucket: timeBucketObject['Week'],
        SelectionType: 'Week'
      };
    }

    this.setState({
      GraphLevelSelection: {
        ...this.state.GraphLevelSelection,
        dayMonthYear: payload
      }
    });
  };

  onButtonClick = () => {
    history.push(`/rubus/LogBook/Power Sales`);
  };

  renderGraph = (graphprops) => {
    let { GraphLevelSelection } = this.state;
    let graphNewProps = {
      ...this.props,
      ...GraphLevelSelection
    };
    switch (graphprops.graph) {
      case 'area':
        return <Area {...graphNewProps} />;
      case 'bar':
        return <Bar {...graphNewProps} />;
      case 'guage':
        return <Guage {...graphNewProps} />;
      case 'GridGuage':
        return <GridGuage {...graphNewProps} />;
      case 'singleBar':
        return <SingleBar {...graphNewProps} />;
      case 'image':
        return <SingleLineDiagram {...graphNewProps} />;
      case 'counter':
        return <Counter {...graphNewProps} />;
      case 'counterLine':
        return <CounterLine {...graphNewProps} />;
      case 'compassGuage':
        return <CompassGuage {...graphNewProps} />;
      case 'table':
        return <Table {...graphNewProps} />;
      case 'candleStick':
        return <CandleStick {...graphNewProps} />;
      case 'line':
        return <Line {...graphNewProps} />;
      case 'progressBar':
        return <ProgressBar {...graphNewProps} />;
      // case 'funnel':
      //   return <Funnel {...graphNewProps} />;
      case 'donut':
        return <Donut {...graphNewProps} />;
      case 'pie':
        return <Pie {...graphNewProps} />;
      case 'polar':
        return <PolarGraph {...graphNewProps} />;
      // case 'pieRose':
      //   return <PieRose {...graphNewProps} />;
      // case 'regression':
      //   return <Regression {...graphNewProps} />;
      case 'pieBar':
        return <PieBar {...graphNewProps} />;
      case 'bubbleChart':
        return <BubbleChart {...graphNewProps} />;
      // case 'candleStickBrush':
      //   return <CandleStickBrush {...graphNewProps} />;
      case 'treeGraph':
        return <TreeGraph {...graphNewProps} />;
      // case 'boxPlot':
      //   return <BoxPlot {...graphNewProps} />;
      // case 'liquidGuage':
      //   return <LiquidGuage {...graphNewProps} />;
      case 'beijingLine':
        return <BeijingLine {...graphNewProps} />;
      case 'singleCandleStick':
        return <SingleCandleStick {...graphNewProps} />;
      case 'barTable':
        return <BarTable {...graphNewProps} />;
      case 'lineTable':
        return <LineTable {...graphNewProps} />;
      case 'statusCounter':
        return <StatusCounter {...graphNewProps} />;
      case 'scatter':
        return <Scatter {...graphNewProps} />;
      case 'HeatmapCartesion':
        return <HeatmapCartesion {...graphNewProps} />;
      case 'heatmapCalendar':
        return <HeatmapCalendar {...graphNewProps} />;
      case 'confusionMatrix':
        return <ConfusionMatrix {...graphNewProps} />;
      case 'dottedBar':
        return <DottedBar {...graphNewProps} />;
      case 'guageLine':
        return <GuageLine {...graphNewProps} />;
      case 'pieSingleLine':
        return <PieSingleLine {...graphNewProps} />;
      case 'pieLine':
        return <PieLine {...graphNewProps} />;
      case 'cardsList':
        return <CardsList {...graphNewProps} />;
      case 'windBarb':
        return <WindBarb {...graphNewProps} />;
      case 'stackedBar':
        return <StackedBar {...graphNewProps} />;
      case 'stackedLine':
        return <StackedLine {...graphNewProps} />;
      case 'HeatmapCartesionTable':
        return <HeatmapCartesionTable {...graphNewProps} />;
      case 'feedbackTable':
        return <FeedbackTable {...graphNewProps} />;
      case 'ImageGalleryWithPreview':
        return <ImageGalleryWithPreview {...graphNewProps} />;
      case 'liveStreaming':
        return <LiveStreaming {...graphNewProps} />;
      case 'GuageRing':
        return <GuageRing {...graphNewProps} />;
      case 'notificationTable':
        return <NotificationTable {...graphNewProps} />;
      case 'selectImageDetails':
        return <SelectImageDetails {...graphNewProps} />;
      // case 'pieNest':
      //   return <PieNestComponent {...graphNewProps} />;
      case 'sliderStatus':
        return <SliderStatus {...graphNewProps} />;
      case 'notificationScatter':
        return <NotificationScatter {...graphNewProps} />;
      case 'LineBarArea':
        return <LineBarArea {...graphNewProps} />;
      case 'GeoMap':
        return <GeoMap {...graphNewProps} />;
      case 'SunBurst':
        return <SunBurst {...graphNewProps} />;
      case 'DonutCounter':
        return <DonutCounter {...graphNewProps} />;
      case 'CountersPie':
        return <CountersPie {...graphNewProps} />;
      case 'Video':
        return <Video {...graphNewProps} />;
      case 'LineTableCounter':
        return <LineTableCounter {...graphNewProps} />;
      case 'Report':
        return <Report {...graphNewProps} />;
      case 'Image':
        return <Image {...graphNewProps} />;
      case 'LineWithSelection':
        return <LineWithSelection {...graphNewProps} />;
      case 'Predictions':
        return <Predictions {...graphNewProps} />;

      default:
        return <div style={{ color: 'white' }}>{`Graph Doesn't exists`}</div>;
    }
  };

  getOptionList = () => {
    let { graphprops } = this.props;
    let { GraphLevelSelection } = this.state;
    let accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');

    let url = '';
    let payload =
      graphprops && graphprops.apiPayloadDeviceSelection
        ? graphprops.apiPayloadDeviceSelection
        : {};
    if (payload && payload.method === 'GET' && payload.GETParams) {
      url = `${payload.url}?SiteId=${siteId}&${payload.GETParams}`;
    } else {
      url = `${payload.url}?SiteId=${siteId}`;
    }
    let payloadSelectedDevice = payload && payload ? payload : '';
    const ObjectHeader = {
      method: payload.method,
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: payloadSelectedDevice || {}
    };
    axios(ObjectHeader).then((response) => {
      if (response.data) {
        let optionList = response.data;
        let deviceCode =
          optionList && Array.isArray(optionList) && optionList[0] ? optionList[0].Code : '';
        this.setState({
          optionList,
          GraphLevelSelection: {
            ...GraphLevelSelection,
            DeviceCode:
              graphprops && graphprops.deviceMultiSelect === true ? [deviceCode] : deviceCode
          },
          DeviceCode: graphprops.deviceMultiSelect === true ? [deviceCode] : deviceCode
        });
      }
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let { graphprops } = this.props;
    let { GraphLevelSelection, currentTab, optionList, DeviceCode } = this.state;
    if (moment().year() > 2020) {
      var yearList = [];
      for (var i = 2020; i <= moment().year(); i++) {
        yearList.push(i);
      }
      yearList.sort((a, b) => b - a);
    }

    return (
      <span>
        {graphprops && graphprops.GraphLevelSelection === 'datePicker' ? (
          <DateSelection>
            <DatePicker
              format="YYYY-MM-DD"
              value={moment(GraphLevelSelection.selectionDate)}
              onChange={this.onChangeDatePicker}
              disabledDate={this.disabledDate}
              allowClear={false}
            />
          </DateSelection>
        ) : null}
        {graphprops && graphprops.GraphLevelSelection === 'dateRangePicker' ? (
          <DateSelection>
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm:ss"
              placeholder={['Start Date', 'End Date']}
              onChange={this.onDateRangePickerChange}
              value={[
                moment(
                  GraphLevelSelection.selectionDateRange
                    ? GraphLevelSelection.selectionDateRange.startDate
                    : undefined
                ),
                moment(
                  GraphLevelSelection.selectionDateRange
                    ? GraphLevelSelection.selectionDateRange.endDate
                    : undefined
                )
              ]}
              allowClear={false}
              style={{
                width: '400px',
                cursor: 'pointer'
              }}
              disabledDate={this.disabledDateRangePicker}
            />
          </DateSelection>
        ) : null}
        {graphprops && graphprops.GraphLevelSelection === 'dateMonthYearPicker' ? (
          <DateSelection>
            <Radio.Group
              value={this.state.currentTab}
              onChange={this.onRadioChange}
              style={{ marginLeft: '20px' }}
            >
              {Object.keys(graphprops.GraphLevelTimeBucket) ? (
                <>
                  {Object.keys(graphprops.GraphLevelTimeBucket).map((GraphType, index) => {
                    return (
                      <Radio.Button key={index} value={GraphType}>
                        {this.translation(GraphType)}
                      </Radio.Button>
                    );
                  })}
                </>
              ) : null}
            </Radio.Group>

            {currentTab === 'Day' ? (
              <DatePicker
                onChange={this.onChange}
                value={moment(
                  GraphLevelSelection &&
                    GraphLevelSelection.dayMonthYear &&
                    GraphLevelSelection.dayMonthYear.EndDate
                    ? GraphLevelSelection.dayMonthYear.EndDate
                    : undefined
                )}
                disabledDate={this.disabledDate}
                allowClear={false}
              />
            ) : null}
            {currentTab === 'Week' ? (
              <WeekPicker
                value={moment(
                  GraphLevelSelection &&
                    GraphLevelSelection.dayMonthYear &&
                    GraphLevelSelection.dayMonthYear.StartDate
                    ? GraphLevelSelection.dayMonthYear.StartDate
                    : undefined
                )}
                style={{ width: '200px' }}
                disabledDate={this.disabledDate}
                placeholder="Select Week"
                onChange={(e) => this.handleMenuClick(e, 'Week')}
                allowClear={false}
              />
            ) : null}
            {currentTab === 'Month' ? (
              <Select
                value={
                  GraphLevelSelection &&
                  GraphLevelSelection.dayMonthYear &&
                  GraphLevelSelection.dayMonthYear.EndDate
                    ? moment(GraphLevelSelection.dayMonthYear.EndDate).format('MMMM')
                    : undefined
                }
                onChange={(e) => this.handleMenuClick(e, 'Month')}
                style={{ width: '100px' }}
              >
                {moment.months().map((value, index) => {
                  return (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            ) : null}
            {currentTab === 'Year' || currentTab === 'Month' ? (
              <Select
                value={
                  GraphLevelSelection &&
                  GraphLevelSelection.dayMonthYear &&
                  GraphLevelSelection.dayMonthYear.EndDate
                    ? moment(GraphLevelSelection.dayMonthYear.EndDate).format('YYYY')
                    : undefined
                }
                onChange={(e) => this.handleMenuClick(e, 'Year')}
                style={{ width: '100px' }}
              >
                {yearList.map((value, index) => {
                  return (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            ) : null}
          </DateSelection>
        ) : null}
        {graphprops && graphprops.GraphLevelSelection === 'monthPicker' ? (
          <DateSelection>
            <MonthPicker
              style={{ marginTop: '10px', marginRight: '10px' }}
              disabledDate={this.disabledDate}
              placeholder="Select Month"
              onChange={this.onChangeMonthPicker}
              format="YYYY-MM"
              allowClear={false}
              value={moment(GraphLevelSelection.selectionMonth)}
            />
          </DateSelection>
        ) : null}
        {graphprops && graphprops.GraphLevelSelection === 'deviceSelection' ? (
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Select
              onChange={(DeviceCode) =>
                this.setState({
                  DeviceCode,
                  GraphLevelSelection: { ...GraphLevelSelection, DeviceCode }
                })
              }
              mode={graphprops.deviceMultiSelect === true ? 'multiple' : ''}
              value={DeviceCode}
              style={{ minWidth: 300, padding: '10px 8px' }}
            >
              {optionList &&
                Array.isArray(optionList) &&
                optionList.map((device, index) => {
                  return (
                    <Select.Option key={index} value={device.Code}>
                      {device.Name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
        ) : null}

        {this.renderGraph(graphprops, GraphLevelSelection)}
      </span>
    );
  }
}

// export default Graph;

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(Graph);
