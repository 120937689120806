import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Form, Space, Button, Tabs, Select, Input, Radio, message } from 'antd';
import axios from 'axios';

import { StyledDashboard } from './styles';
import './index.css';
import localStorage from '../../../../utils/localStorage';
import { getSchemaList, getTableNames, getList, createQuery } from './Apicalls';
import { API_QUERYBUILDER } from '../../../../commons/api';

const ButtonGroup = Button.Group;
const { Option } = Select;
const { TabPane } = Tabs;

class QueryBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condition: {
        ruleType: 'group',
        // operator: 'And',
        remove: false,
        identity: [['group']],
        group: [
          {
            ruleType: 'single',
            identity: [['group', 0]],
            rule: [
              {
                identity: [
                  ['group', 0],
                  ['rule', 0]
                ],
                tableName: '',
                operator: '=',
                name: 'column1',
                value: '1'
              }
            ]
          }
          // {
          //   ruleType: 'single',
          //   identity: [['group', 1]],
          //   rule: [
          //     {
          //       identity: [
          //         ['group', 1],
          //         ['rule', 0]
          //       ],
          //       operator: '==',
          //       name: 'column2',
          //       value: '2'
          //     }
          //   ]
          // },
          // {
          //   ruleType: 'single',
          //   identity: [['group', 2]],
          //   rule: [
          //     {
          //       identity: [
          //         ['group', 2],
          //         ['rule', 0]
          //       ],
          //       operator: '!=',
          //       name: 'column3',
          //       value: '3'
          //     }
          //   ]
          // },
          // {
          //   ruleType: 'group',
          //   operator: 'And',
          //   remove: true,
          //   identity: [['group', 3]],
          //   group: [
          //     {
          //       ruleType: 'single',
          //       identity: [
          //         ['group', 3],
          //         ['group', 1]
          //       ],
          //       rule: [
          //         {
          //           identity: [
          //             ['group', 3],
          //             ['group', 1],
          //             ['rule', 0]
          //           ],
          //           operator: '!=',
          //           name: 'column4',
          //           value: '4'
          //         }
          //       ]
          //     },
          //     // {
          //     //   ruleType: 'single',
          //     //   identity: [
          //     //     ['group', 3],
          //     //     ['group', 2]
          //     //   ],
          //     //   rule: [
          //     //     {
          //     //       identity: [
          //     //         ['group', 3],
          //     //         ['group', 2],
          //     //         ['rule', 1]
          //     //       ],
          //     //       operator: '!=',
          //     //       name: 'column5',
          //     //       value: '5'
          //     //     }
          //     //   ]
          //     // }
          //   ]
          // }
        ]
      },
      main_table_name: '',
      TableColumns: [],
      operatorList: [],
      tablesList: [],
      aggregateValuesList: [],
      modalVisible: false,
      selectedTab: '1',
      queryArray: {},
      dataObj: {},
      joinsObj: {}
    };
  }
  QRef = React.createRef();
  joinsRef = React.createRef();
  componentDidMount = async () => {
    let schemaList = await getSchemaList();
    let aggregateValuesList = await getList({ Type: 'Aggregate' });
    let clauseNameList = await getList({ Type: 'Clasue' });
    let joinsList = await getList({ Type: 'Joins' });
    let logicalOperatorList = await getList({ Type: 'logical operators' });
    let comparisionOperators = await getList({ Type: 'Comparison Operators' });
    let arithmeticOperators = await getList({ Type: 'Arithmetic Operators' });

    this.setState({
      schemaList,
      aggregateValuesList,
      clauseNameList,
      joinsList,
      logicalOperatorList,
      comparisionOperators,
      arithmeticOperators
    });
  };
  renderGroup = (ruleObject) => {
    let { ruleType, operator, group, rule, identity, remove } = ruleObject;

    if (ruleType === 'single') {
      return (
        <div style={{ padding: '8px' }}>
          {rule && rule[0] !== null ? this.renderRule(rule) : null}
        </div>
      );
    } else if (ruleType === 'group') {
      return (
        <div
          style={{
            border: '0.5px solid gray',
            background: '#d4dff9',
            padding: '7px',
            marginTop: '1%'
          }}
        >
          <div style={{}}>
            <Radio.Group
              value={operator}
              onChange={(e) => this.operatorChange(e.target.value, 'operator', identity)}
              buttonStyle="solid"
            >
              <Radio.Button value="And">And</Radio.Button>
              <Radio.Button value="Or">Or</Radio.Button>
            </Radio.Group>
            <ButtonGroup style={{ float: 'right' }}>
              <Button onClick={(e) => this.addNewRule(e, ruleObject)}>
                <PlusOutlined />
                Add Rule
              </Button>
              <Button onClick={(e) => this.addNewGroup(e, ruleObject)}>
                <PlusCircleOutlined />
                Add Group
              </Button>
              {remove === true ? (
                <Button
                  style={{ color: 'red' }}
                  onClick={() => this.deleteGroup(ruleObject, identity)}
                >
                  <DeleteOutlined />
                  DELETE
                </Button>
              ) : null}
            </ButtonGroup>
          </div>
          {this.ruleGroup(group)}
        </div>
      );
    }
  };
  ruleGroup = (groupList) => {
    return (
      groupList &&
      Array.isArray(groupList) &&
      groupList.map((rule) => {
        if (rule && rule !== null) {
          return this.renderGroup(rule);
        }
        return {};
      })
    );
  };
  renderRule = (rule) => {
    let { tableName, name, value, operator, identity } = rule && rule[0];
    let { condition, TableColumns, tablesList, queryArray, comparisionOperators } = this.state;
    return (
      <div style={{ padding: '4px', background: 'white' }}>
        {queryArray && queryArray['JoinsConditions'] ? (
          <Select
            value={tableName}
            placeholder="Table Name"
            onChange={(e) => this.nameOnChange(e, 'tableName', identity)}
            style={{ width: '180px', marginBottom: '1%' }}
          >
            {tablesList &&
              Array.isArray(tablesList) &&
              tablesList.map((tableName, index) => {
                return (
                  <Option key={index} value={tableName}>
                    {tableName}
                  </Option>
                );
              })}
          </Select>
        ) : null}
        <Select
          value={name}
          style={{ width: 120, margin: '2px' }}
          onChange={(e) => this.nameOnChange(e, 'name', identity)}
        >
          {TableColumns &&
            Array.isArray(TableColumns) &&
            TableColumns.map((columnName, index) => {
              return (
                <Option key={index} value={columnName}>
                  {columnName}
                </Option>
              );
            })}
        </Select>
        <Select
          value={operator}
          style={{ width: 120, margin: '2px' }}
          onChange={(e) => this.nameOnChange(e, 'operator', identity)}
        >
          {comparisionOperators &&
            Array.isArray(comparisionOperators) &&
            comparisionOperators.map((operatorName, index) => {
              return (
                <Option key={index} value={operatorName}>
                  {operatorName}
                </Option>
              );
            })}
        </Select>
        <Input
          value={value}
          style={{ width: 120, margin: '2px' }}
          onChange={(e) => this.nameOnChange(e.target.value, 'value', identity)}
        />
        <ButtonGroup style={{ float: 'right' }}>
          <Button style={{ color: 'red' }} onClick={() => this.deleteRule(condition, identity)}>
            <DeleteOutlined />
          </Button>
        </ButtonGroup>
        {/* <div>{identity}</div> */}
      </div>
    );
  };
  nameOnChange = async (valueData, key, identity) => {
    let { condition } = this.state;
    const result = _.cloneDeepWith(condition, (value, value1, value2) => {
      return value && value.identity && value.identity === identity
        ? { ...(value2 && value2[value1] ? value2[value1] : {}), [key]: valueData }
        : _.noop();
    });

    this.setState({
      condition: result
    });
  };
  operatorChange = async (valueData, key, identity) => {
    let { condition } = this.state;
    const result = _.cloneDeepWith(condition, (value, value1, value2) => {
      return value && value.identity && value.identity === identity
        ? { ...(value2 && value2[value1] ? value2[value1] : value ? value : {}), [key]: valueData }
        : _.noop();
    });

    this.setState({ condition: result });
  };

  addNewRule = (e, ruleObject) => {
    let { condition } = this.state;
    const result = _.cloneDeepWith(condition, (value) => {
      return ruleObject && ruleObject['group'] && ruleObject['group'] === value
        ? [
            ...ruleObject['group'],
            {
              ruleType: 'single',
              identity: [['group', 1]],
              rule: [
                {
                  identity: [
                    ['group', 1],
                    ['rule', 0]
                  ],
                  operator: '',
                  name: '',
                  value: ''
                }
              ]
            }
          ]
        : _.noop();
    });

    this.setState({
      condition: result
    });
  };
  addNewGroup = (e, ruleObject) => {
    let { condition } = this.state;
    const result = _.cloneDeepWith(condition, (value) => {
      return ruleObject && ruleObject['group'] && ruleObject['group'] === value
        ? [
            ...ruleObject['group'],
            {
              ruleType: 'group',
              operator: 'And',
              identity: [['group', 3]],
              group: [
                {
                  ruleType: 'single',
                  identity: [
                    ['group', 3],
                    ['group', 1]
                  ],
                  rule: [
                    {
                      identity: [],
                      operator: '',
                      name: '',
                      value: ''
                    }
                  ]
                }
              ]
            }
          ]
        : _.noop();
    });

    this.setState({
      condition: result
    });
  };
  deleteGroup = (ruleObject, identity) => {
    let { condition } = this.state;
    const result = _.cloneDeepWith(condition, (value) => {
      return value && value.identity && value.identity === identity ? (value = null) : _.noop();
      //                var colors = value2;
      // var greens = _.remove(value2, function(c) {
      //     return (c === value2[value1]); //remove if color is green
      // });
    });
    // var result =_.remove(condition,function(currentObject) {
    //    message.alert("hi")
    //     return currentObject.identity === identity;

    //  });
    //  ruleObject = _.filter(ruleObject, function(currentObject) {
    //     return currentObject.subTopicId !== stToDelete;
    // });
    this.setState({
      condition: result
    });
  };
  deleteRule = (ruleObject, identity) => {
    let { condition } = this.state;

    const result = _.cloneDeepWith(condition, (value) => {
      return value && value.identity && value.identity === identity ? null : _.noop();
    });
    this.setState({
      condition: result
    });
  };
  dropdownChange = async (selectedValue, key, tableKey) => {
    let siteId = localStorage.get('currentSite');
    if (key === 'mainSchema' || key === 'joinsMainSchema' || key === 'joinsChildSchema') {
      let tablesList = await getTableNames(selectedValue);
      this.setState({ [tableKey]: tablesList, main_schema: selectedValue });
    } else {
      const accessToken = localStorage.get('accessToken');
      let headers = {
        method: 'get',
        url: `${API_QUERYBUILDER.GetColumns}?SiteId=${siteId}&tableName=${selectedValue}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          tableName: selectedValue
        }
      };
      axios(headers)
        .then((response) => {
          if (response && Array.isArray(response.data) && response.data.length !== 0) {
            if (key === 'mainTable') {
              this.setState({ TableColumns: response.data, main_table_name: selectedValue });
            } else if (key === 'joinsMainTable') {
              this.setState({
                joinsMainTableColumns: response.data,
                main_table_name: selectedValue
              });
            } else if (key === 'joinsChildTable') {
              this.setState({
                joinsChildTableColumns: response.data,
                child_table_name: selectedValue
              });
            }
          }

          // let TableColumns = ["column-1", "column-2", "column-3"]
        })
        .catch(() => {
          const TableColumns = ['column-1', 'column-2', 'column-3'];
          this.setState({ main_table_name: selectedValue, TableColumns });
        });
    }
  };
  onFinish = async (values) => {
    const { queryArray, main_table_name, main_schema } = this.state;
    let obj = {
      select: 'SELECT',
      main_table_name: main_table_name,
      main_schema: main_schema,
      ...queryArray,
      ...values
    };
    let query = await createQuery(obj);
    message.success(query && query.message ? query.message : null);
    this.setState({ queryArray: obj });
  };
  onChange = () => {};
  handleOk = () => {
    this.setState({ modalVisible: true });
  };

  handleCancel = () => {
    this.setState({ modalVisible: false });
  };
  onHandleChange = (value, key) => {
    let { dataObj } = this.state;
    dataObj = {
      ...dataObj,
      [key]: value
    };
    this.setState({ dataObj });
    // if (this.QRef.current){ this.QRef.current.setFieldvalue({"Constraint_Clause":[dataObj]});}
  };
  selectCondition = () => {
    const {
      schemaList,
      mainTablesList,
      TableColumns,
      aggregateValuesList,
      clauseNameList,
      dataObj
    } = this.state;
    // const tablesList = ["Table-1", "Table-2", "Table-3"];
    // const aggregateValuesList = ["SUM", "null"];
    // const clauseNameList = ["LIMIT", "ASC"]
    return (
      <Fragment>
        <Form layout="vertical">
          <Form.Item label="Schema" rules={[{ required: true }]}>
            <Select
              placeholder="Select Schema"
              onSelect={(value) => this.dropdownChange(value, 'mainSchema', 'mainTablesList')}
              style={{ width: '180px', marginBottom: '1%' }}
            >
              {schemaList &&
                Array.isArray(schemaList) &&
                schemaList.map((schema, index) => {
                  return (
                    <Option key={index} value={schema}>
                      {schema}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item label="Table Name" rules={[{ required: true }]}>
            <Select
              placeholder="Table Name"
              onSelect={(value) => this.dropdownChange(value, 'mainTable')}
              style={{ width: '180px', marginBottom: '1%' }}
            >
              {mainTablesList &&
                Array.isArray(mainTablesList) &&
                mainTablesList.map((tableName, index) => {
                  return (
                    <Option key={index} value={tableName}>
                      {tableName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
        <div>
          {TableColumns && Array.isArray(TableColumns) && TableColumns.length !== 0 ? (
            <Fragment>
              <Form
                style={{ border: '0.5px solid gray', background: '#d4dff9', padding: '7px' }}
                name="dynamic_form_nest_item"
                onFinish={this.onFinish}
                autoComplete="off"
              >
                <Form.Item label="Columns" rules={[{ required: true }]}>
                  <Form.List name="selectedColumns">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: 'flex',
                              marginBottom: 8
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, 'AggregateValue']}
                              rules={[
                                {
                                  required: false,
                                  message: 'Missing AggregateValue'
                                }
                              ]}
                            >
                              <Select
                                placeholder="Aggregate Value"
                                style={{ width: '180px', marginBottom: '1%' }}
                              >
                                {aggregateValuesList &&
                                  Array.isArray(aggregateValuesList) &&
                                  aggregateValuesList.map((aggregateValue, index) => {
                                    return (
                                      <Option key={index} value={aggregateValue}>
                                        {aggregateValue}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'columnName']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Missing column name'
                                }
                              ]}
                            >
                              {/* <Input placeholder="First Name" /> */}
                              <Select
                                placeholder="Column Name"
                                style={{ width: '180px', marginBottom: '1%' }}
                              >
                                {TableColumns &&
                                  Array.isArray(TableColumns) &&
                                  TableColumns.map((columnName, index) => {
                                    return (
                                      <Option key={index} value={columnName}>
                                        {columnName}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'AlliasName']}
                              rules={[
                                {
                                  required: false,
                                  message: 'Missing alias name'
                                }
                              ]}
                            >
                              <Input placeholder="Alias Name" />
                            </Form.Item>

                            <MinusCircleOutlined
                              style={{ color: 'steelblue' }}
                              onClick={() => remove(name)}
                            />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            style={{ color: 'steelblue' }}
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Columns
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Apply
                  </Button>
                </Form.Item>
              </Form>

              <Form
                style={{
                  border: '0.5px solid gray',
                  background: '#d4dff9',
                  padding: '7px',
                  marginTop: '1%'
                }}
                ref={this.QRef}
                initialValues={dataObj || {}}
                name="dynamic_form_nest_item"
                onFinish={this.onFinish}
                autoComplete="off"
              >
                <Form.Item label="Constraint_Clause" rules={[{ required: true }]}>
                  <Form.List name="Constraint_Clause">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: 'flex',
                              marginBottom: 8
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, 'ClauseName']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Missing ClauseName'
                                }
                              ]}
                            >
                              <Select
                                placeholder="Clause Name"
                                style={{ width: '180px', marginBottom: '1%' }}
                                onChange={(value) => this.onHandleChange(value, 'ClauseName')}
                              >
                                {clauseNameList &&
                                  Array.isArray(clauseNameList) &&
                                  clauseNameList.map((clauseName, index) => {
                                    return (
                                      <Option key={index} value={clauseName}>
                                        {clauseName}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            {this.QRef.current &&
                              this.QRef.current.getFieldValue() &&
                              this.QRef.current.getFieldValue().Constraint_Clause &&
                              this.QRef.current.getFieldValue().Constraint_Clause[key] &&
                              this.QRef.current.getFieldValue().Constraint_Clause[key][
                                'ClauseName'
                              ] &&
                              this.QRef.current.getFieldValue().Constraint_Clause[key][
                                'ClauseName'
                              ] === 'LIMIT'}
                            <Form.Item
                              {...restField}
                              name={[name, 'ClauseValue']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Missing clause value'
                                }
                              ]}
                            >
                              {/* <Input placeholder="First Name" /> */}
                              {this.QRef.current &&
                              this.QRef.current.getFieldValue() &&
                              this.QRef.current.getFieldValue().Constraint_Clause &&
                              this.QRef.current.getFieldValue().Constraint_Clause[key] &&
                              this.QRef.current.getFieldValue().Constraint_Clause[key][
                                'ClauseName'
                              ] &&
                              this.QRef.current.getFieldValue().Constraint_Clause[key][
                                'ClauseName'
                              ] === 'LIMIT' ? (
                                <Input />
                              ) : (
                                <Select
                                  placeholder="ClauseValue"
                                  style={{ width: '180px', marginBottom: '1%' }}
                                >
                                  {' '}
                                  {TableColumns &&
                                    Array.isArray(TableColumns) &&
                                    TableColumns.map(
                                      (columnName, index) => (
                                        // return (
                                        <Option key={index} value={columnName}>
                                          {columnName}
                                        </Option>
                                      )
                                      // );
                                    )}
                                </Select>
                              )}
                            </Form.Item>

                            <MinusCircleOutlined
                              style={{ color: 'steelblue' }}
                              onClick={() => remove(name)}
                            />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            style={{ color: 'steelblue' }}
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Clause
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Apply
                  </Button>
                </Form.Item>
              </Form>
            </Fragment>
          ) : null}
        </div>
      </Fragment>
    );
  };
  Conditions = () => {
    const {
      joinsList,
      tablesList,
      joinsObj,
      operatorList,
      joinsMainTableColumns,
      joinsChildTableColumns
    } = this.state;
    // const tablesList = ["Table-1", "Table-2", "Table-3"];
    // const aggregateValuesList = ["SUM", "null"];
    // const clauseNameList = ["LIMIT", "ASC"]

    return (
      <Form
        style={{ border: '0.5px solid gray', background: '#d4dff9', padding: '7px' }}
        name="dynamic_form_nest_item"
        onFinish={() => this.onFinish()}
        ref={this.joinsRef}
        initialValues={joinsObj}
      >
        <Form.Item label="Joins">
          <Form.List name="JoinsConditions">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: 'flex',
                        marginBottom: 8
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        label="Joins"
                        rules={[
                          {
                            required: true,
                            message: 'Missing JoinsName'
                          }
                        ]}
                      >
                        <Select placeholder="Joins" style={{ width: '180px', marginBottom: '1%' }}>
                          {joinsList &&
                            Array.isArray(joinsList) &&
                            joinsList.map((joins, index) => {
                              return (
                                <Option key={index} value={joins}>
                                  {joins}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="MainTablename"
                        name={[name, 'mainTablename']}
                        rules={[{ required: true, message: 'Missing table name' }]}
                      >
                        <Select
                          placeholder="Table Name"
                          onSelect={(value) => this.dropdownChange(value, 'joinsMainTable')}
                          style={{ width: '180px', marginBottom: '1%' }}
                        >
                          {tablesList &&
                            Array.isArray(tablesList) &&
                            tablesList.map((tableName, index) => {
                              return (
                                <Option key={index} value={tableName}>
                                  {tableName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'childTablename']}
                        label="ChildTablename"
                        rules={[
                          {
                            required: true,
                            message: 'Missing child table name'
                          }
                        ]}
                      >
                        {/* <Input placeholder="First Name" /> */}
                        <Select
                          placeholder="Table Name"
                          onSelect={(value) => this.dropdownChange(value, 'joinsChildTable')}
                          style={{ width: '180px', marginBottom: '1%' }}
                        >
                          {tablesList &&
                            Array.isArray(tablesList) &&
                            tablesList.map((tableName, index) => {
                              return (
                                <Option key={index} value={tableName}>
                                  {tableName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'mainColumn']}
                        label="mainColumn"
                        rules={[
                          {
                            required: true,
                            message: 'Missing column name'
                          }
                        ]}
                      >
                        {/* <Input placeholder="First Name" /> */}
                        <Select
                          placeholder="MainColumn"
                          style={{ width: '180px', marginBottom: '1%' }}
                        >
                          {joinsMainTableColumns &&
                            Array.isArray(joinsMainTableColumns) &&
                            joinsMainTableColumns.map((columnName, index) => {
                              return (
                                <Option key={index} value={columnName}>
                                  {columnName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'operator']}
                        label="Operator"
                        rules={[
                          {
                            required: true,
                            message: 'Missing Operator'
                          }
                        ]}
                      >
                        {/* <Input placeholder="First Name" /> */}
                        <Select
                          placeholder="Operator"
                          style={{ width: '180px', marginBottom: '1%' }}
                        >
                          {operatorList &&
                            Array.isArray(operatorList) &&
                            operatorList.map((operatorName, index) => {
                              return (
                                <Option key={index} value={operatorName}>
                                  {operatorName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'childColumn']}
                        label="childColumn"
                        rules={[
                          {
                            required: true,
                            message: 'Missing childcolumn name'
                          }
                        ]}
                      >
                        {/* <Input placeholder="First Name" /> */}
                        <Select
                          placeholder="childColumn"
                          style={{ width: '180px', marginBottom: '1%' }}
                        >
                          {joinsChildTableColumns &&
                            Array.isArray(joinsChildTableColumns) &&
                            joinsChildTableColumns.map((childColumnName, index) => {
                              return (
                                <Option key={index} value={childColumnName}>
                                  {childColumnName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>

                      <Form.Item label="ChildColumns" rules={[{ required: true }]}>
                        <Form.List name="childColumns">{this.addChildcolumns(restField)}</Form.List>
                      </Form.Item>

                      <MinusCircleOutlined
                        style={{ color: 'steelblue' }}
                        onClick={() => remove(name)}
                      />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      style={{ color: 'steelblue' }}
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Joins
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
      </Form>
    );
  };

  joinsCondition = () => {
    const {
      joinsList,
      joinsMainTableList,
      joinsChildTableList,
      joinsMainTableColumns,
      joinsChildTableColumns,
      comparisionOperators,
      schemaList
    } = this.state;
    return (
      <Form
        style={{ border: '0.5px solid gray', background: '#d4dff9', padding: '7px' }}
        onFinish={(e) => this.onFinish(e)}
      >
        {/* This is the Dynamic room Adder */}

        <Form.Item label="Joins">
          <Form.List name="JoinsConditions">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => (
                    <Space
                      key={field.key}
                      style={{ display: 'flex', marginBottom: 8 }}
                      align="start"
                    >
                      <Form.Item
                        {...field}
                        name={[field.name, 'name']}
                        fieldKey={[field.fieldKey, " 'name'"]}
                        rules={[{ required: true, message: 'Missing joins name' }]}
                      >
                        <Select placeholder="Joins" style={{ width: '180px', marginBottom: '1%' }}>
                          {joinsList &&
                            Array.isArray(joinsList) &&
                            joinsList.map((joins, index) => {
                              return (
                                <Option key={index} value={joins}>
                                  {joins}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'joinsMainSchema']}
                        fieldKey={[field.fieldKey, 'joinsMainSchema']}
                        rules={[{ required: true, message: 'Missing schema name' }]}
                      >
                        <Select
                          placeholder="Select MainSchema"
                          onSelect={(value) =>
                            this.dropdownChange(value, 'joinsMainSchema', 'joinsMainTableList')
                          }
                          style={{ width: '180px', marginBottom: '1%' }}
                        >
                          {schemaList &&
                            Array.isArray(schemaList) &&
                            schemaList.map((schema, index) => {
                              // if (main_table_name === tableName) {
                              return (
                                <Option key={index} value={schema}>
                                  {schema}
                                </Option>
                              );
                              // }
                              // return {};
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'mainTablename']}
                        fieldKey={[field.fieldKey, 'mainTablename']}
                        rules={[{ required: true, message: 'Missing table name' }]}
                      >
                        <Select
                          placeholder="Table Name"
                          onSelect={(value) => this.dropdownChange(value, 'joinsMainTable')}
                          style={{ width: '180px', marginBottom: '1%' }}
                        >
                          {joinsMainTableList &&
                            Array.isArray(joinsMainTableList) &&
                            joinsMainTableList.map((tableName, index) => {
                              // if (main_table_name === tableName) {
                              return (
                                <Option key={index} value={tableName}>
                                  {tableName}
                                </Option>
                              );
                              // }
                              // return {};
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'joinsChildSchema']}
                        fieldKey={[field.fieldKey, 'joinsChildSchema']}
                        rules={[{ required: true, message: 'Missing childschema name' }]}
                      >
                        <Select
                          placeholder="Select ChildSchema"
                          onSelect={(value) =>
                            this.dropdownChange(value, 'joinsChildSchema', 'joinsChildTableList')
                          }
                          style={{ width: '180px', marginBottom: '1%' }}
                        >
                          {schemaList &&
                            Array.isArray(schemaList) &&
                            schemaList.map((schema, index) => {
                              // if (main_table_name === tableName) {
                              return (
                                <Option key={index} value={schema}>
                                  {schema}
                                </Option>
                              );
                              // }
                              // return {};
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'childTablename']}
                        fieldKey={[field.fieldKey, 'childTablename']}
                        rules={[{ required: true, message: 'Missing childTablename' }]}
                      >
                        <Select
                          placeholder="ChildTable Name"
                          onSelect={(value) => this.dropdownChange(value, 'joinsChildTable')}
                          style={{ width: '180px', marginBottom: '1%' }}
                        >
                          {joinsChildTableList &&
                            Array.isArray(joinsChildTableList) &&
                            joinsChildTableList.map((tableName, index) => {
                              return (
                                <Option key={index} value={tableName}>
                                  {tableName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'mainColumn']}
                        fieldKey={[field.fieldKey, 'mainColumn']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing column name'
                          }
                        ]}
                      >
                        {/* <Input placeholder="First Name" /> */}
                        <Select
                          placeholder="MainColumn"
                          style={{ width: '180px', marginBottom: '1%' }}
                        >
                          {joinsMainTableColumns &&
                            Array.isArray(joinsMainTableColumns) &&
                            joinsMainTableColumns.map((columnName, index) => {
                              return (
                                <Option key={index} value={columnName}>
                                  {columnName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'operator']}
                        fieldKey={[field.fieldKey, 'operator']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing Operator'
                          }
                        ]}
                      >
                        {/* <Input placeholder="First Name" /> */}
                        <Select
                          placeholder="Operator"
                          style={{ width: '180px', marginBottom: '1%' }}
                        >
                          {comparisionOperators &&
                            Array.isArray(comparisionOperators) &&
                            comparisionOperators.map((operatorName, index) => {
                              return (
                                <Option key={index} value={operatorName}>
                                  {operatorName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'childColumn']}
                        fieldKey={[field.fieldKey, 'childColumn']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing childcolumn name'
                          }
                        ]}
                      >
                        {/* <Input placeholder="First Name" /> */}
                        <Select
                          placeholder="childColumn"
                          style={{ width: '180px', marginBottom: '1%' }}
                        >
                          {joinsChildTableColumns &&
                            Array.isArray(joinsChildTableColumns) &&
                            joinsChildTableColumns.map((childColumnName, index) => {
                              return (
                                <Option key={index} value={childColumnName}>
                                  {childColumnName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>

                      {/* This is the Dynamic bed Adder */}

                      <Form.Item>{this.addChildcolumns(field.name)}</Form.Item>

                      <MinusCircleOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Space>
                  ))}

                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                  >
                    <PlusOutlined /> Add joins
                  </Button>
                </div>
              );
            }}
          </Form.List>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Apply
        </Button>
      </Form>
    );
  };

  addChildcolumns = (props) => {
    const { aggregateValuesList, childTableColumns } = this.state;
    return (
      <>
        <Form.List name={[props, 'childColumns']}>
          {(childColumns, { add, remove }) => {
            return (
              <div>
                {childColumns.map((item, index2) => (
                  <Space key={item.key} style={{ display: 'flex', marginBottom: 8 }} align="start">
                    <Form.Item
                      // name={"aar"}
                      {...item}
                      name={[item.name, 'AggregateValue']}
                      fieldKey={[item.fieldKey, 'AggregateValue']}
                      key={index2}
                      // noStyle
                      rules={[
                        {
                          required: true,
                          message: 'Missing AggregateValue'
                        }
                      ]}
                    >
                      <Select
                        placeholder="Aggregate Value"
                        style={{ width: '180px', marginBottom: '1%' }}
                      >
                        {aggregateValuesList &&
                          Array.isArray(aggregateValuesList) &&
                          aggregateValuesList.map((aggregateValue, index) => {
                            return (
                              <Option key={index} value={aggregateValue}>
                                {aggregateValue}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...item}
                      name={[item.name, 'columnName']}
                      fieldKey={[item.fieldKey, 'columnName']}
                      rules={[{ required: true, message: 'Missing column name' }]}
                      key={index2}
                    >
                      <Select
                        placeholder="columnName"
                        style={{ width: '180px', marginBottom: '1%' }}
                      >
                        {childTableColumns &&
                          Array.isArray(childTableColumns) &&
                          childTableColumns.map((childColumnName, index) => {
                            return (
                              <Option key={index} value={childColumnName}>
                                {childColumnName}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...item}
                      name={[item.name, 'AlliasName']}
                      fieldKey={[item.fieldKey, 'AlliasName']}
                      rules={[
                        {
                          required: true,
                          message: 'Missing alias name'
                        }
                      ]}
                      key={index2}
                    >
                      <Input placeholder="Alias Name" />
                    </Form.Item>

                    <MinusCircleOutlined
                      onClick={() => {
                        remove(item.name);
                      }}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                  >
                    <PlusOutlined /> Add Columns
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </>
    );
  };

  render() {
    let { condition, selectedTab } = this.state;
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <Tabs
          defaultActiveKey={selectedTab}
          onChange={(value) => this.setState({ selectedTab: value })}
          type="card"
        >
          <TabPane tab="Select" key={'1'}>
            {this.selectCondition()}
          </TabPane>
          <TabPane tab="Condition" key={'2'}>
            {condition && condition !== null ? this.renderGroup(condition) : null}
            <Form.Item>
              <Button
                type="primary"
                onClick={() => this.onFinish({ condition: this.state.condition })}
              >
                Apply
              </Button>
            </Form.Item>
          </TabPane>
          <TabPane tab="Joins" key={'3'}>
            {this.joinsCondition()}
          </TabPane>
        </Tabs>
      </StyledDashboard>
    );
  }
}

export default QueryBuilder;
