import React, { Fragment } from 'react';
import { flattenDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import axios from 'axios';
import { Button, message, Popconfirm, Tooltip, Tree } from 'antd';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  UploadOutlined
} from '@ant-design/icons';

import localStorage from '../../utils/localStorage';
import { StyledComponent } from './CSS/style';
import themeSettings from './Utils/themeSettings.json';
import { StyledTree } from './CSS/style';
import { getTranslationCache } from '../../selectors/language';
import { API_HIERARCHYMODULE } from '../../commons/api';
import Asset from './Forms/Asset';
import Device from './Forms/Device';
import { GetDefaultHierarchy } from './APICalls';
import UploadAssets from './Forms/UploadAssets';
import UploadDevices from './Forms/UploadDevices';

class CustomerAssetDeviceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: {},
      expandedKeys: [],
      selectedRow: {},
      ShowForm: '',
      Module: ''
    };
  }

  componentDidMount = () => {
    this.GetHierarchyLevels('initial');
  };

  componentDidUpdate(prevprops) {
    if (this.props.match.params.module !== prevprops.match.params.module) {
      this.GetHierarchyLevels('initial');
    }
  }

  getHierarchyLevels = async (Module, refereshStatus) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let defaultHiearchyCode = await GetDefaultHierarchy();
    let HierarchyCode =
      defaultHiearchyCode && defaultHiearchyCode[0] && defaultHiearchyCode[0].HierarchyCode
        ? defaultHiearchyCode[0].HierarchyCode
        : '';
    let headers = {
      method: 'GET',
      url: `${API_HIERARCHYMODULE.GetModuleHierarchy}?SiteId=${siteId}&HierarchyCode=${HierarchyCode}&Module=${Module}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          if (refereshStatus === 'initial') this.expandAll(data);
          this.setState({
            treeData: data,
            Module
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetHierarchyLevels = (refereshStatus) => {
    this.getHierarchyLevels(this.props.match.params.module, refereshStatus);
  };

  expandAll = async (treeData) => {
    // let expandedKeys = await this.getAllKeys(treeData);
    const expandedKeys = treeData.map((node) => {
      return node.Id;
    });

    this.setState({
      expandedKeys
    });
  };

  getAllKeys = (data) => {
    // This function makes an array of keys, this is specific for this example, you would have to adopt for your case. If your list is dynamic, also make sure that you call this function everytime data changes.
    const nestedKeys = data.map((node) => {
      let childKeys = [];
      if (node.children) {
        childKeys = this.getAllKeys(node.children);
      }
      return [childKeys, node.Id];
    });
    return flattenDeep(nestedKeys);
  };

  deleteAssetDevice = (Type, Item) => {
    const token = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    var Authorization = 'Bearer ' + token;

    let data = {
      url: `/api/${Type}/delete?SiteId=${siteId}&AliasCode=${Item.Code}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization
      }
    };
    return axios(data)
      .then((response) => {
        return response.data;
      })

      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  getIcon = (ShowForm, item) => {
    let findAssetMatch = JSON.stringify(ShowForm).match(`DELETE_ASSET`) !== null;
    let findDeviceMatch = JSON.stringify(ShowForm).match(`DELETE_DEVICE`) !== null;
    let findADDMatch = JSON.stringify(ShowForm).match(`ADD`) !== null;
    let findEDITMatch = JSON.stringify(ShowForm).match(`EDIT`) !== null;
    let findUPLOADMatch = JSON.stringify(ShowForm).match(`UPLOAD`) !== null;
    let findDELETEMatch = JSON.stringify(ShowForm).match(`DELETE`) !== null;
    if (findADDMatch === true) {
      return <PlusOutlined />;
    } else if (findEDITMatch === true) {
      return <EditOutlined />;
    } else if (findUPLOADMatch === true) {
      return <UploadOutlined />;
    } else if (findDELETEMatch === true) {
      return (
        <Popconfirm
          title="Are you sure to delete？"
          onConfirm={async () => {
            let deleteStatus = {};
            if (findAssetMatch === true) {
              deleteStatus = await this.deleteAssetDevice('asset', item);
            } else if (findDeviceMatch === true) {
              deleteStatus = await this.deleteAssetDevice('device', item);
            }
            if (deleteStatus && deleteStatus.message) {
              message.success(deleteStatus.message);
              this.GetHierarchyLevels();
            }
          }}
          okText="Yes"
          cancelText="No"
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
          <DeleteOutlined />
        </Popconfirm>
      );
    } else {
      return null;
    }
  };

  getFormTypeName = (ShowForm) => {
    let checkAdd = ShowForm.split('ADD_');
    let checkEdit = ShowForm.split('EDIT_');
    let ftName = '';
    if (checkAdd && Array.isArray(checkAdd) && checkAdd.length === 2) {
      ftName = checkAdd[1];
    } else if (checkEdit && Array.isArray(checkEdit) && checkEdit.length === 2) {
      ftName = checkEdit[1];
    }
    return ftName;
  };

  onAction = (ShowForm, item, parent) => {
    let findADDMatch = JSON.stringify(ShowForm).match(`ADD`) !== null;
    let findEDITMatch = JSON.stringify(ShowForm).match(`EDIT`) !== null;
    let findUPLOADMatch = JSON.stringify(ShowForm).match(`UPLOAD`) !== null;
    let stateValues = {
      ShowForm
    };
    let formTypeName = this.getFormTypeName(ShowForm);
    if (findADDMatch === true) {
      stateValues = {
        ...stateValues,
        formTypeName,
        selectedRow: item
      };
    } else if (findEDITMatch === true) {
      stateValues = {
        ...stateValues,
        formTypeName,
        selectedRow: item
      };
    } else if (findUPLOADMatch === true) {
      stateValues = {
        ...stateValues,
        selectedRow: { ...item, DeviceType: this.state.DeviceType, Module: this.state.Module }
      };
    }
    this.setState({ ...stateValues, parent });
  };

  renderTreeNodes = (data, parent) => {
    return (
      data &&
      Array.isArray(data) &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <Tree.TreeNode
              title={
                <>
                  {item.Name}
                  {/* ({item.Code}) */}
                  <>
                    {item.Actions && Object.keys(item.Actions)
                      ? Object.keys(item.Actions).map((ShowForm, index) => {
                          return (
                            <Tooltip
                              key={index}
                              title={`
                            ${this.translation(item.Actions[ShowForm])}`}
                            >
                              <Button
                                size="small"
                                onClick={() => {
                                  this.onAction(ShowForm, item, parent);
                                }}
                                style={{
                                  marginLeft: '5px',
                                  background: '#285a8c',
                                  borderColor: '#1a3652'
                                }}
                              >
                                {this.getIcon(ShowForm, item)}
                              </Button>
                            </Tooltip>
                          );
                        })
                      : null}
                  </>
                </>
              }
              key={item.Id}
              dataRef={item}
            >
              {this.renderTreeNodes(item.children, item)}
            </Tree.TreeNode>
          );
        }
        return (
          <Tree.TreeNode
            key={item.key}
            title={
              <div>
                <>
                  {item.Name}
                  {/* ({item.Code}) */}
                  <>
                    {item.Actions &&
                    Object.keys(item.Actions) &&
                    Object.keys(item.Actions).length > 0
                      ? Object.keys(item.Actions).map((ShowForm, index) => {
                          return (
                            <Tooltip key={index} title={this.translation(item.Actions[ShowForm])}>
                              <Button
                                size="small"
                                onClick={() => {
                                  this.onAction(ShowForm, item, parent);
                                }}
                                style={{
                                  marginLeft: '5px',
                                  background: '#285a8c',
                                  borderColor: '#1a3652'
                                }}
                              >
                                {this.getIcon(ShowForm, item)}
                              </Button>
                            </Tooltip>
                          );
                        })
                      : null}
                  </>
                </>
              </div>
            }
            dataRef={item}
          />
        );
      })
    );
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys
    });
  };

  renderForm = (ShowForm, selectedRow, Module, translationCache, parent) => {
    switch (ShowForm) {
      case 'ADD_DEVICE':
        return (
          <Device
            selectedRow={selectedRow}
            ShowForm={ShowForm}
            translationCache={translationCache}
            Module={Module}
            parent={parent}
            GetHierarchyLevelsUpdated={this.GetHierarchyLevels}
          />
        );
      case 'EDIT_DEVICE':
        return (
          <Device
            selectedRow={selectedRow}
            ShowForm={ShowForm}
            translationCache={translationCache}
            Module={Module}
            parent={parent}
            GetHierarchyLevelsUpdated={this.GetHierarchyLevels}
          />
        );
      case 'UPLOAD_DEVICES':
        return (
          <UploadDevices
            selectedRow={selectedRow}
            ShowForm={ShowForm}
            Module={Module}
            parent={parent}
            translationCache={translationCache}
            GetHierarchyLevelsUpdated={this.GetHierarchyLevels}
          />
        );
      case 'UPLOAD_ASSETS':
        return (
          <UploadAssets
            selectedRow={selectedRow}
            ShowForm={ShowForm}
            Module={Module}
            parent={parent}
            translationCache={translationCache}
            GetHierarchyLevelsUpdated={this.GetHierarchyLevels}
          />
        );
      case 'UPLOAD':
        return (
          <UploadAssets
            selectedRow={selectedRow}
            ShowForm={ShowForm}
            Module={Module}
            parent={parent}
            translationCache={translationCache}
            GetHierarchyLevelsUpdated={this.GetHierarchyLevels}
          />
        );
      default:
        return (
          <Asset
            selectedRow={selectedRow}
            ShowForm={ShowForm}
            formTypeName={this.state.formTypeName}
            Module={Module}
            parent={parent}
            translationCache={translationCache}
            GetHierarchyLevelsUpdated={this.GetHierarchyLevels}
          />
        );
    }
  };
  render() {
    let { treeData, expandedKeys, ShowForm, selectedRow, Module, parent } = this.state;
    let { translationCache } = this.props;
    return (
      <Fragment>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '20px', minHeight: window.innerHeight - 67 }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div
              style={{
                width: '100%',
                flex: '25%',
                overflowY: 'auto',
                height: window.innerHeight - 140
              }}
            >
              <StyledTree
                className="treeTheme"
                onExpand={this.onExpand}
                expandedKeys={expandedKeys}
                showLine
                defaultExpandAll={true}
                switcherIcon={<DownOutlined />}
              >
                {this.renderTreeNodes(treeData)}
              </StyledTree>
            </div>

            <div
              style={{
                flex: '75%',
                paddingLeft: '17px',
                borderLeft: '1px dashed white'
              }}
            >
              {selectedRow && Object.keys(selectedRow).length > 0
                ? this.renderForm(ShowForm, selectedRow, Module, translationCache, parent)
                : null}
            </div>
          </div>
        </StyledComponent>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(CustomerAssetDeviceComponent);
