import loginAuthSaga from './login/login.saga';
import assetSaga from './asset/asset.saga';
import deviceSaga from './device/device.saga';
import deviceTypeSaga from './deviceType/deviceType.saga';
import layoutSaga from './layout/layout.saga';
import translationSaga from './translation/translation.saga';

export default function* root() {
  yield [
    loginAuthSaga(),
    assetSaga(),
    deviceSaga(),
    deviceTypeSaga(),
    layoutSaga(),
    translationSaga()
  ];
}
