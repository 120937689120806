import {
  API_ASSET,
  API_MASTERCONFIGURATION,
  API_ROLE,
  LOGBOOK,
  MASTER
} from '../../../commons/api';
import { Table_Master } from '../../../commons/table';
import localStorage from '../../../utils/localStorage';

export async function getDatasourceData() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`${LOGBOOK.GET}?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
  if (data && Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function _getRolesBySite() {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  let data = await fetch(`${API_ROLE.GET}?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getRecurrencePatternData() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;

  const siteId = localStorage.get('currentSite');
  let data = await fetch(`${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      TableName: Table_Master.MasterRecurrence,
      ColumnName: 'Type'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      return [];
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function _deleteSubmitBatch(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let data = await fetch(`${LOGBOOK.Submit} `, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function deleteFileUpload(fileName) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`${MASTER.DeleteFile}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      fileName
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function getFormTypeData() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');

  let data = await fetch(`${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      TableName: Table_Master.MasterLogBookTypes,
      ColumnName: 'Type'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getLocationData() {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;

  let data = await fetch(`${API_ASSET.GET}?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getELogTypeData() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');

  let data = await fetch(`${API_MASTERCONFIGURATION.GETDROPDOWN}?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      TableName: Table_Master.MasterBookTypes,
      ColumnName: 'Type',
      conditionList: [{ key: 'Type', value: 'AdHoc' }],
      condition: 'Type',
      conditionvalue: 'AdHoc'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getTableMasters() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');

  let data = await fetch(`${LOGBOOK.TableList}?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}
