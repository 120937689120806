/**
 * @author Anvesh
 * @description This Component is mainly for Creating Equipment  Status Master
 * @View Table with crud operations of Status
 */
import React, { Component } from 'react';

import { Table, Modal, Button, Input, Form, Row, Col, Select, message, Popconfirm } from 'antd';
import { getTranslationCache } from '../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getStatusList, createStatus, updateStatus, deleteStatus, getDeviceType } from './ApiCall';

import { getUserDetails } from '../../../selectors/layout';

import { StyledApplication, StyledTable, lightTheme } from '../style';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

class StatusMAster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partsList: [],
      visible: false,
      loading: false,
      editData: false,
      deviceType: [],
      editObj: {},
      translationCache: props.translationCache || []
    };
  }

  componentDidMount = async () => {
    try {
      //let processData = await this._getProcessOptionList();
      let partsList = await getStatusList();
      let deviceType = await getDeviceType();
      this.setState({
        partsList: partsList.data,
        deviceType: deviceType.data
      });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.UserDetails !== this.props.UserDetails) {
      this.setState({
        userId: this.props.UserDetails
      });
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  addNewAddress = () => {
    this.props.form.resetFields();
    this.setState({
      visible: true,
      editObj: {}
    });
  };
  handleCancel = () => {
    this.props.form.resetFields();
    this.setState({
      visible: false,
      editObj: {},
      editData: false,
      loading: false
    });
  };

  handleSupplierEdit = async (editObj) => {
    this.setState({ editObj, editData: true, visible: true });
  };
  handleSupplierDelete = async (data) => {
    this.setState({ visible: false });
    let payload = { Id: data.Id, Name: data.Name };
    try {
      let deleteStatu = await deleteStatus(payload);
      message.success(deleteStatu.data.message);
      let partsList = await getStatusList();
      this.setState({ partsList: partsList.data });
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  editorDidMount = (editor) => {
    this.editor = editor;
  };
  handleOk = async () => {
    let { editData, editObj } = this.state;

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (!editData) {
          try {
            let createStatu = await createStatus(values);
            message.success(createStatu.data.message);
            let partsList = await getStatusList();
            this.setState({
              editData: false,
              loading: false,
              visible: false,
              partsList: partsList.data
            });
            this.props.form.resetFields();
          } catch (error) {
            //  message.error(error.response.data.message);
          }
        } else {
          let payload = { ...values, Id: parseFloat(editObj.Id) };
          try {
            let updateStatu = await updateStatus(payload);

            message.success(updateStatu.data.message);
            this.props.form.resetFields();
            let partsList = await getStatusList();
            this.setState({
              editData: false,
              loading: false,
              visible: false,
              editObj: {},
              partsList: partsList.data
            });
          } catch (error) {
            message.error(error.response.data.message);
          }
        }
      }
    });
    // setTimeout(() => {
    //   this.setState({ loading: false, visible: false });
    // }, 3000);
  };

  render() {
    let { visible, loading } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { editObj, partsList, editData, deviceType } = this.state;

    const columns = [
      {
        title: 'Status Code',
        dataIndex: 'StatusCode',
        key: 'StatusCode'
      },
      {
        title: 'Status Name',
        dataIndex: 'StatusName',
        key: 'StatusName'
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'Description'
      },
      {
        title: 'Edit',
        key: 'Edit',
        render: (text, record) => (
          <span>
            <EditOutlined
              onClick={() => {
                this.handleSupplierEdit(record);
              }}
            />
          </span>
        )
      },
      {
        title: 'Delete',
        key: 'Delete',
        render: (text, record) => (
          <span>
            <Popconfirm
              placement="topRight"
              title="Are you sure delete this Supplier?"
              onConfirm={(e) => {
                e.stopPropagation();
                this.handleSupplierDelete(record);
              }}
              onCancel={(e) => {
                e.stopPropagation();
              }}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </Popconfirm>
          </span>
        )
      }
    ];
    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 170 }}>
        <Modal
          title="Status Details"
          visible={visible}
          width={'800px'}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,

            <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
              {!editData ? 'Submit' : 'Update'}
            </Button>
          ]}
          onCancel={this.handleCancel}
        >
          <Form layout={'vertical'}>
            <Row gutter={16}>
              <Col span={8}>
                {' '}
                <Form.Item label="Equipment Type :">
                  {getFieldDecorator('DeviceTypeId', {
                    initialValue: editObj.DeviceTypeId,
                    rules: [{ required: true, message: 'Please Select Equipment Category' }]
                  })(
                    <Select placeholder="Select Equipment Category">
                      {/* <Option value="Warning-1">Warning-1</Option>
      <Option value="Warning-2">Warning-2</Option>
      <Option value="Warning-3">Warning-3</Option> */}
                      {deviceType.map((param, index) => {
                        return (
                          <Option key={index} value={param.Id}>
                            {param.Name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col span={8}>
                {' '}
                <Form.Item label="Status Code :">
                  {getFieldDecorator('StatusCode', {
                    initialValue: editObj.StatusCode,
                    rules: [{ required: true, message: 'Please Enter  Name' }]
                  })(<Input placeholder="Status Code" />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                {' '}
                <Form.Item label="Status Name">
                  {getFieldDecorator('StatusName', {
                    initialValue: editObj.StatusName,
                    rules: [{ required: true, message: 'Please input Status Name' }]
                  })(<Input placeholder="Status Name" />)}
                </Form.Item>
              </Col>
              <Col span={11}>
                {' '}
                <Form.Item label="Description:">
                  {getFieldDecorator('Description', {
                    initialValue: editObj.Description,
                    rules: [{ required: true, message: 'Please Enter Description' }]
                  })(<Input placeholder="Description" />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Button type="primary" onClick={this.addNewAddress} style={{ marginBottom: '10px' }}>
          {' '}
          <PlusOutlined /> Add Status{' '}
        </Button>
        <StyledTable theme={lightTheme} style={{ marginTop: '20px' }}>
          <Table columns={columns} dataSource={partsList} />
        </StyledTable>
      </StyledApplication>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  UserDetails: getUserDetails(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(StatusMAster);
