import React, { Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { createStructuredSelector } from 'reselect';

import ReactEcharts from '../../Echarts/Echarts';
import { getTranslationCache } from '../../../../../selectors/language';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';

import { makeSelectThemeing } from '../../../../../selectors/theme';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { heatmapCartesionMockJson1 } from '../../../Mock/heatmapMock';
import { getCurrentTimezone } from '../../../../../selectors/layout';

class HeatmapCartesion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      loading: false,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = 1 * 60000;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  getData = async () => {
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    let series = [];
    let theme = this.props.selectedThemeing;

    const { graphprops, translationCache } = this.props;
    let displayType =
      this.props &&
      this.props.graphprops &&
      this.props.graphprops[graphprops.graph] &&
      this.props.graphprops[graphprops.graph].cartesionDisplayType;
    let json = {};
    let option = this.getOption();
    this.setState({ loading: true });
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = heatmapCartesionMockJson1;
      this.setState({ loading: false });
    } else {
      if (
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'datePicker' ||
            graphprops.GraphLevelSelection === 'datePicker') &&
          this.props.selectionDate) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'monthPicker' ||
            graphprops.GraphLevelSelection === 'monthPicker') &&
          this.props.selectionMonth) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'weakPicker' ||
            graphprops.GraphLevelSelection === 'weakPicker') &&
          this.props.selectionWeek) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'shiftPicker' ||
            graphprops.GraphLevelSelection === 'shiftPicker') &&
          this.props.shift) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
            graphprops.GraphLevelSelection === 'dateRangePicker') &&
          this.props.selectionDateRange) ||
        (graphprops &&
          (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
            graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
          this.props.dayMonthYear) ||
        (graphprops && graphprops.dashboardType === 'dynamic') ||
        (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
        (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
        (graphprops && this.props.dashboardPayload) ||
        (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
      ) {
        json = await getChartDataWithSelection({
          graphprops,
          DeviceCode: this.props.DeviceCode,
          selectionDate: this.props.selectionDate,
          selectionMonth: this.props.selectionMonth,
          selectionWeek: this.props.selectionWeek,
          shift: this.props.shift,
          selectionDateRange: this.props.selectionDateRange,
          dayMonthYear: this.props.dayMonthYear,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          dashboardPayload: this.props.dashboardPayload,
          AssetCode: this.props.AssetCode,
          selectionDateLive: this.props.selectionDateLive,
          currentTimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
        this.setState({ loading: false });
      } else {
        json = await getChartDataWithoutSelection({
          graphprops,
          abortController: this.abortController,
          dropdownSelection: this.props.dropdownSelection,
          DeviceCode: this.props.DeviceCode,
          TimeZone: this.props.currentTimeZone
        });
        this.requestCheck = true;
        this.setState({ loading: false });
      }
    }
    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      let time = [];
      let data = [];
      let mainDataObject = [];

      let jsonTimeData =
        json &&
        Array.isArray(json) &&
        json.map((jsonTime) => {
          return moment.utc(jsonTime.DateTime).tz(this.props.currentTimeZone).format('HH:mm:ss');
        });
      jsonTimeData = _.uniq(jsonTimeData);
      let jsonNew = {};
      jsonTimeData.map((jsonObject) => {
        let JSONData = json.filter(
          (e) =>
            moment.utc(e.DateTime).tz(this.props.currentTimeZone).format('HH:mm:ss') === jsonObject
        );
        let newJSON = JSONData.map((data) => {
          return {
            ...data,
            DateTime: moment
              .utc(data.DateTime)
              .tz(this.props.currentTimeZone)
              .format('YYYY-MM-DD HH:mm:ss')
          };
        });

        jsonNew[jsonObject] = newJSON;
        return {};
      });
      json = jsonNew;
      if (json && Object.keys(json).length > 0) {
        time = Object.keys(json);
        let timeData = Object.keys(json).map((timeFormat) => {
          return moment(timeFormat, 'HH:mm:ss').format('HH:mm:ss');
        });
        if (time && time.length > 0 && json[time[0]]) {
          data =
            Array.isArray(json[time[0]]) &&
            json[time[0]].map((yAxis) => {
              return moment(yAxis.DateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
            });
        }
        let yAxis = [];

        for (let i = 6; i >= 0; i--) {
          yAxis.push(moment(this.props.selectionDateLive).subtract(i, 'days').format('YYYY-MM-DD'));
        }
        time &&
          time.map((mainObject) => {
            let newObject = [];
            if (json[mainObject] && Array.isArray(json[time[0]])) {
              // let mainObject1 = moment(mainObject, 'HH:mm:ss').format('HH:mm:ss')
              newObject = data.map((yAxisData, childKey) => {
                let splitedData = mainObject.split(':');
                let xAxis = '';
                if (displayType && displayType === 'percentage') {
                  xAxis = mainObject;
                } else {
                  xAxis =
                    splitedData.length === 3 ? splitedData[0] + ':' + splitedData[1] : mainObject;
                }
                let allIntervals = {};

                json[mainObject].map((jsonData) => {
                  allIntervals = {
                    ...allIntervals,
                    [moment(jsonData.DateTime).format('YYYY-MM-DD')]: jsonData
                  };
                  return {};
                });
                if (Object.keys(allIntervals).includes(yAxisData)) {
                  return [
                    childKey,
                    xAxis,
                    allIntervals[yAxisData].Value,
                    allIntervals[yAxisData].actualValue,
                    allIntervals[yAxisData].predictedValue,
                    allIntervals[yAxisData].Description
                  ];
                }
                return {};
              });
            }
            newObject = newObject.filter(function (element) {
              return element !== undefined;
            });
            mainDataObject = [
              ...mainDataObject,
              ...(newObject && Array.isArray(newObject) ? newObject : [])
            ];
            return {};
          });
        if (mainDataObject && Array.isArray(mainDataObject)) {
          this.setState({
            data: mainDataObject
          });
        }
        if (timeData && Array.isArray(timeData) && displayType !== 'percentage') {
          option.xAxis.data = timeData.map((item) => {
            let splitedData = item.split(':');
            let xAxisData = splitedData.length === 3 ? splitedData[0] + ':' + splitedData[1] : item;
            let xAxis =
              translationCache && translationCache[xAxisData]
                ? translationCache[xAxisData]
                : xAxisData;
            return xAxis;
          });
        } else if (displayType === 'percentage') {
          option.xAxis.data = time;
        }
        if (data && Array.isArray(data)) {
          option.yAxis.data =
            this.props.graphprops &&
            this.props.graphprops[graphprops.graph] &&
            this.props.graphprops[graphprops.graph].cartesionDisplayType === 'none' // custom logic ,to get last 7 days dates from selected date
              ? yAxis
              : data.map(function (item) {
                  return item;
                });
        }
        series = [
          {
            name: '',
            type: displayType === 'scatter' ? 'scatter' : 'heatmap',
            symbolSize: 15,
            itemStyle: {
              borderWidth: 2,
              borderType: 'dotted',
              borderColor: 'rgba(187, 177, 177, 1)'
            },
            data:
              mainDataObject &&
              Array.isArray(mainDataObject) &&
              mainDataObject.map(function (item) {
                if (item) {
                  return [
                    item[1],
                    item[0],
                    item[3] || 0,
                    item[4] || 0,
                    item[5] ? item[5] : '',
                    item[2] || item[2] === 0 ? item[2] : '-'
                  ];
                }
                return {};
              }),
            label: {
              show: displayType === 'none' ? false : true,
              textStyle: {
                color: theme === 'lightTheme' ? 'black' : 'black',
                fontSize: 12
              },
              formatter: function (params) {
                if (displayType === 'percentage') {
                  return params.value[5] + '%';
                }
                return params.value[5];
              },
              color: '#000'
            }
          }
        ];
      }

      option.series = series;
      this.setState({ option, refreshDateTime: moment() });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  render() {
    let { errorInAPI, noDataInDB, loading } = this.state;
    return (
      <span>
        {loading === false ? (
          <Fragment>
            {/* <Tag color="blue" style={{ float: "", position: "relative", zIndex: "2" }} >
                         {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm') : ""}
                     </Tag> */}
            {noDataInDB === true || errorInAPI === true ? (
              noDataInDB === true ? (
                <NoRecordComponent />
              ) : (
                <ErrorComponent />
              )
            ) : (
              <ReactEcharts
                option={this.state.option}
                style={{ height: '90%', width: '100%' }}
                className="react_for_echarts"
              />
            )}
          </Fragment>
        ) : (
          <div
            style={{
              textAlign: 'center',
              color: 'white',
              marginTop: '10%'
            }}
          >
            <h2 style={{ color: 'white' }}>
              <Spin spinning={loading} tip="Loading..." size="large"></Spin>
            </h2>
          </div>
        )}
      </span>
    );
  }

  getOption = () => {
    let { translationCache, graphprops } = this.props;
    let theme = this.props.selectedThemeing;
    let rangeValue = [];
    if (
      this.props.graphprops &&
      this.props.graphprops[graphprops.graph] &&
      this.props.graphprops[graphprops.graph].range
    ) {
      rangeValue = JSON.parse(this.props.graphprops[graphprops.graph].range);
    }

    let option = {
      grid: {
        left: '10%',
        width: '87%'
      },
      tooltip: {
        position: 'top',
        textStyle: {
          color: theme === 'lightTheme' ? 'black' : 'black',
          fontSize: 16
        },
        formatter: function (param) {
          let xAxisLabel =
            translationCache && translationCache[param.name] && translationCache[param.name]
              ? translationCache[param.name]
              : param.name;
          let actualValue =
            translationCache && translationCache['Actual Value'] && translationCache['Actual Value']
              ? translationCache['Actual Value']
              : 'Actual Value';
          let predictedValue =
            translationCache &&
            translationCache['Predicted Value'] &&
            translationCache['Predicted Value']
              ? translationCache['Predicted Value']
              : 'Predicted Value';
          if (
            graphprops &&
            graphprops[graphprops.graph] &&
            graphprops[graphprops.graph].cartesionDisplayType &&
            graphprops[graphprops.graph].cartesionDisplayType === 'percentage'
          ) {
            return [
              param.marker,
              ' <bold>' + xAxisLabel + '</bold>',
              ': ' + param.data[5] + '%',
              '\n\n',
              ' <bold>' + actualValue + ': ' + param.value[2],
              '</bold>' + predictedValue + ': ' + param.data[3]
            ].join(' <br/> ');
          } else if (param.data[5] === 1) {
            return [
              ' <bold>' +
                param.marker +
                ' <bold>' +
                xAxisLabel +
                '</br>' +
                param.data[4] +
                '</bold>'
            ];
          }
        }
      },
      animation: true,
      xAxis: {
        type: 'category',
        splitArea: {
          show: true
        },
        axisLabel: {
          textStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white',
            fontSize: 13
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        }
      },
      yAxis: {
        type: 'category',
        splitArea: {
          show: true
        },
        axisLabel: {
          textStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white',
            fontSize: 13
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        }
      },
      visualMap: [
        {
          calculable: true,
          orient: 'horizontal',
          show:
            this.props &&
            this.props.graphprops &&
            this.props.graphprops[graphprops.graph] &&
            this.props.graphprops[graphprops.graph].rangeBar &&
            (this.props.graphprops[graphprops.graph].rangeBar === 'true' ||
              this.props.graphprops[graphprops.graph].rangeBar === true)
              ? true
              : false,

          top: '1%',
          left: '65%',
          min: rangeValue && rangeValue[0] ? rangeValue[0] : 0,
          max: rangeValue && rangeValue[1] ? rangeValue[1] : 1,
          textStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white',
            fontWeight: 'bold',
            fontSize: 16
          },
          pieces:
            this.props &&
            this.props.graphprops &&
            this.props.graphprops[graphprops.graph] &&
            this.props.graphprops[graphprops.graph].VisualMap
              ? this.props.graphprops[graphprops.graph].VisualMap
              : [],
          outOfRange: {
            color: '#0d1115'
          },
          inRange: {
            color: ['#f5d191', 'red']
          }
        }
      ],
      series: [],
      toolbox: {
        showTitle: false,
        orient: 'vertical',
        itemSize: 25,
        feature: {
          saveAsImage: {
            title: 'download',
            backgroundColor: theme === 'lightTheme' ? 'white' : 'black',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      }
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(HeatmapCartesion);
