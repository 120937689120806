import React from 'react';
import { Button, Table } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../selectors/language';
import { StyledApplication } from './styles';
import './style.css';

class EdgeConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: []
    };
  }

  handleFileChange = (e) => {
    if (e.target.files.length) {
      this.setState({ file: e.target.files[0] });
    }
  };
  handleParse = () => {
    let { file } = this.state;
    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;
      this.processCSV(text);
    };

    reader.readAsText(file);
  };

  processCSV = (str, delim = ',') => {
    const headers = str.slice(0, str.indexOf('\n')).split(delim);
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');

    const newArray = rows.map((row) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });
    this.setState({ dataSource: newArray });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    let { dataSource } = this.state;
    const columns = [
      {
        title: this.translation('Equipment Name'),
        dataIndex: 'DeviceName',
        key: 'DeviceName'
      },
      {
        title: this.translation('Equipment Type'),
        dataIndex: 'DeviceTypeName',
        key: 'DeviceTypeName'
      },
      {
        title: this.translation('Module'),
        dataIndex: 'Module',
        key: 'Module'
      },
      {
        title: this.translation('Parameter Code'),
        dataIndex: 'ParameterCode',
        key: 'ParameterCode'
      },
      {
        title: this.translation('Parameter Group'),
        dataIndex: 'ParameterGroup',
        key: 'ParameterGroup'
      }
    ];
    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 226 }}>
        <input onChange={this.handleFileChange} id="csvInput" name="file" type="File" />
        <Button type="primary" onClick={this.handleParse}>
          {this.translation('Show .CSV Data')}
        </Button>

        <Table
          className="basictable"
          style={{ marginTop: '33px' }}
          size="large"
          columns={columns}
          dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
          locale="No Data"
        />
      </StyledApplication>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(EdgeConfiguration);
