/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import moment from 'moment';
import { Form, message, DatePicker } from 'antd';
import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  submitJobPlan,
  getJobPlanById
} from '../../../Utils/FormCalls';
import translation from '../../../Utils/translation';
import themeSettings from '../../../Utils/themeSettings.json';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import { constant } from '../../../Utils/constants';
import InputComponent from '../../../Widgets/InputComponent';
import { StyledButton } from '../../../CSS/style';
class CommunicationLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      recordData: props.formObject && props.formObject.TaskData ? props.formObject.TaskData : [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      translationCache: props.translationCache || {}
    };
  }
  taskRef = React.createRef();
  schedulingWorkOrderRef = React.createRef();

  componentDidMount = async () => {
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList.data });
      let organisationsList = await getOrganisationsList();
      this.setState({ organisationsList: organisationsList.data });
      let sitesList = await getSitesList();
      this.setState({ sitesList: sitesList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.schedulingWorkOrderRef.current.setFieldsValue(this.props.formObject);
      this.taskRef.current.setFieldsValue(this.props.formObject.TaskData);
    }

    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject,
        recordData:
          this.props.formObject && this.props.formObject.TaskData
            ? this.props.formObject.TaskData
            : []
      });
    }
  }

  handleChange = (value, key) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [key]: value
    };
    this.schedulingWorkOrderRef.current.setFieldsValue(formObject);
    this.setState({ [key]: value, formObject });
  };

  onFinish = async (values) => {
    let { formObject } = this.state;
    let payload = {
      ...formObject,
      ...values
    };
    let statusResponse = await submitJobPlan(payload);

    if (statusResponse && statusResponse.message) {
      // history.goBack();
      message.success(statusResponse.message);
      this.onReset();
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  onReset = () => {
    this.taskRef.current.resetFields();
    this.schedulingWorkOrderRef.current.resetFields();
  };
  jobPlanOnChange = async (value) => {
    let formObject = await getJobPlanById(value);
    let recordData = formObject.TaskData;
    // this.schedulingWorkOrderRef.current.setFieldsValue(formObject)
    // this.taskRef.current.setFieldsValue(formObject)
    this.taskRef.current.getFieldValue('TaskData');
    this.setState({ formObject, recordData, selectedJobPaln: value });
  };
  render() {
    let { formObject, translationCache } = this.state;
    return (
      <>
        <Form
          onFinish={this.props.onFinish}
          name={'CommunicationLog'}
          ref={this.schedulingWorkOrderRef}
          className="jobplan"
          initialValues={formObject}
          layout="horizontal"
          labelCol={{ flex: '130px' }}
          labelAlign="right"
          labelWrap
          //wrapperCol={{ flex: 1 }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item
              label={translation(translationCache, constant.iD)}
              name="ID"
              onChange={(value) => this.handleChange(value, 'ID')}
              rules={[{ required: false, message: 'Please Input ID!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <InputComponent
                placeholder={translation(translationCache, constant.inputId)}
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.creatdBy)}
              name="Created By"
              onChange={(value) => this.handleChange(value, 'Creatd By')}
              rules={[{ required: false, message: 'Please Input Creatd By!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <InputComponent
                placeholder={translation(translationCache, constant.inputId)}
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.notificationTo)}
              name="NotificationTo"
              onChange={(value) => this.handleChange(value, 'NotificationTo')}
              rules={[{ required: false, message: 'Please Input NotificationTo!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <InputComponent
                placeholder={translation(translationCache, constant.inputNotificationTo)}
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.triggeredDateTime)}
              name="TriggeredDateTime"
              onChange={(e) => this.handleChange(e.target.value, 'TriggeredDateTime')}
              rules={[{ required: false, message: 'Please Provide TriggeredDateTime' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <DatePicker
                style={{ width: '70%' }}
                showTime={{
                  defaultValue: moment('00:00:00', 'HH:mm:ss')
                }}
                placeholder={translation(translationCache, 'TriggeredDateTime')}
              />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              <StyledButton theme={themeSettings} htmlType="submit">
                {translation(translationCache, constant.save)}
              </StyledButton>
            </Form.Item>
          </div>
        </Form>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(CommunicationLog);
