import React from 'react';
import { flattenDeep } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Tree } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
// import history from '../../../../../commons/history';
import { getTreeData } from './apicalls';
import { setMenuSelection } from '../../../../../modules/menu/menu.duck';
import history from '../../../../../commons/history';
import localStorage from '../../../../../utils/localStorage';

const { TreeNode } = Tree;

class LeftSidePanel extends React.Component {
  constructor() {
    super();
    this.state = {
      treeData: [],
      expandKey: [],
      autoExpandParent: false,
      searchValue: ''
    };
  }
  componentDidMount() {
    this.assetHierarchy();
  }
  assetHierarchy = async () => {
    let treeData = await getTreeData();
    let expandKey = await this.getAllKeys(treeData);
    this.setState({ treeData, expandKey });
  };
  getAllKeys = (data) => {
    const nestedKeys = data.map((node) => {
      let childKeys = [];
      if (node.children) {
        childKeys = this.getAllKeys(node.children);
      }
      return [childKeys, node.key];
    });
    return flattenDeep(nestedKeys);
  };

  getData = (item) => {
    let { Type, Name, Code } = item;
    const siteId = localStorage.get('currentSite');
    if (Type === 'Device') {
      history.push({
        pathname: `/rubus/dashboard/Renewable Energy`,
        state: { DeviceCode: `${siteId}_${Code}`, DeviceName: Name }
      });
    } else {
      this.props.onTreeSelect(Type, Code, Name);
    }
  };

  renderTreeNodes = (data) => {
    return data.map((item) => {
      if (item.children && item.children.length > 0) {
        return (
          <TreeNode
            title={<div onClick={() => this.getData(item)}>{item.Name}</div>}
            key={item.key}
            dataRef={item}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.key}
          title={<div onClick={() => this.getData(item)}>{item.Name}</div>}
          dataRef={item}
        />
      );
    });
  };

  render() {
    let { expandKey, treeData } = this.state;
    return (
      <>
        <div style={{ minHeight: window.innerHeight - 73, borderRight: '1px dashed black' }}>
          <Tree
            style={{ background: '#aad3df' }}
            showLine
            defaultExpandAll={true}
            switcherIcon={<DownloadOutlined />}
            onSelect={this.onTreeSelect}
            expandedKeys={expandKey}
          >
            {this.renderTreeNodes(treeData)}
          </Tree>
        </div>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({});
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setMenuSelection
      },
      dispatch
    )
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LeftSidePanel);
