/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from 'react';
import { Form, Select, Popconfirm, InputNumber, Collapse, Tooltip } from 'antd';
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  getToolsList,
  getLaborList,
  getMaterialsList,
  getItemsList,
  checkAvailability,
  getSkillTypeList
} from '../../../Utils/FormCalls';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import { constant } from '../../../Utils/constants';
import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import { StyledButton, StyledCollapse } from '../../../CSS/style';
import InputComponent from '../../../Widgets/InputComponent';
import InputNumberComponent from '../../../Widgets/InputNumberComponent';
const { Option } = Select;
const { Panel } = Collapse;

//@ATT:this was created to make nested dynamic elements! This is hard!

const ToolsformFunction = (props) => {
  const [toolsList, setTool] = useState([]);
  const [translationCache, setTranslationCache] = useState(props.translationCache);
  useEffect(() => {
    (async () => {
      try {
        let response = await getToolsList();
        setTool(response.data);
      } catch (e) {
        return {};
      }
    })();
  }, []);
  return (
    <Form.List name={'Tools'}>
      {(Tools, { add, remove }) => {
        return (
          <Fragment>
            <Form.Item>
              {' '}
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <StyledButton
                  theme={themeSettings}
                  type="dashed"
                  style={{ background: '#29415a', color: '#fff' }}
                  onClick={() => {
                    add();
                  }}
                >
                  {' '}
                  {translation(translationCache, constant.addTools)}{' '}
                </StyledButton>
              </div>
            </Form.Item>
            {Tools && Array.isArray(Tools) && Tools.length > 0 ? (
              <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                {Tools.map((tool, index2) => (
                  <Panel
                    key={index2}
                    header={`${translation(translationCache, constant.tools)}${index2 + 1}`}
                    extra={
                      <Popconfirm
                        title={translation(translationCache, constant.deleteTool2)}
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        // onConfirm={() => remove(tool.name)}
                        onConfirm={() => props.onRemove(index2, 'Tools')}
                        okText={translation(translationCache, constant.yes)}
                        cancelText={translation(translationCache, constant.no)}
                      >
                        <Tooltip title={translation(translationCache, 'Delete')} placement="bottom">
                          <DeleteOutlined />
                        </Tooltip>
                      </Popconfirm>
                    }
                  >
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      <Form.Item
                        style={{ flex: '50%' }}
                        labelCol={{ flex: '130px' }}
                        label={translation(translationCache, constant.tool)}
                        {...tool}
                        rules={[{ required: true }]}
                        name={[tool.name, 'ToolId']}
                        fieldKey={[tool.fieldKey, 'ToolId']}
                        key={index2}
                      >
                        <Select
                          style={{ width: '90%' }}
                          onChange={(value) =>
                            props.onToolChange(
                              value,
                              index2,
                              'Tools',
                              toolsList.filter((tool) => tool.Id === value)
                            )
                          }
                          placeholder={translation(translationCache, 'Select Tool')}
                        >
                          {toolsList.map((tool, index) => {
                            return (
                              <Option
                                key={index}
                                value={tool.Id}
                                disabled={
                                  props &&
                                  props.formObject &&
                                  props.formObject.Tools &&
                                  props.formObject.Tools.filter(
                                    (item) => item && item.ToolId === tool.Id
                                  ).length > 0
                                    ? true
                                    : false
                                }
                              >
                                {' '}
                                {tool.Tool}{' '}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        // style={{ flex: '50%' }}
                        // labelCol={{ flex: '130px' }}
                        // label={translation(translationCache, constant.availableQuantity)}
                        // disabled={true}
                        // {...tool}
                        name={[tool.name, 'AvailableQuantity']}
                        // fieldKey={[tool.fieldKey, 'AvailableQuantity']}
                        // key={index2}
                      >
                        {/* <InputComponent
                          disabled={true}
                          placeholder={translation(translationCache,"Total Quantity"
                          style={{ width: '90%' }}
                        /> */}
                      </Form.Item>
                      <Form.Item
                        style={{ flex: '50%' }}
                        labelCol={{ flex: '130px' }}
                        label={translation(translationCache, constant.description)}
                        // disabled={true}
                        // {...material}
                        name={[tool.name, 'Description']}
                        fieldKey={[tool.fieldKey, 'Description']}
                        key={index2}
                      >
                        <InputComponent
                          disabled={true}
                          placeholder={translation(translationCache, 'Auto Populate from Tool')}
                          style={{ width: '90%' }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ flex: '50%' }}
                        labelCol={{ flex: '130px' }}
                        label={translation(translationCache, constant.Quantity)}
                        // validateTrigger="onChange"
                        {...tool}
                        name={[tool.name, 'Quantity']}
                        fieldKey={[tool.fieldKey, 'Quantity']}
                        key={index2}
                        rules={[
                          // {
                          // //  fieldKey[key][index2]['AvailableQuantity'] ==='Not Available' ?
                          // //     {
                          //       pattern: new RegExp(/^[0]*$/),
                          //       message: 'field accept only zero'
                          //     // }
                          //     //  : ''

                          // },
                          {
                            validator: (_, value) =>
                              checkAvailability(_, value, 'Tools', index2, props.formObject, {
                                validateDecimal: true
                              })
                          }
                        ]}
                      >
                        <InputNumberComponent
                          // min={0}
                          // max={1000}
                          // disabled={this.state.view}
                          placeholder={translation(translationCache, 'Quantity')}
                          style={{ width: '90%' }}
                          addonAfter={
                            <Tooltip
                              style={{ marginRight: '20px', flex: '50%' }}
                              labelCol={{ flex: '130px' }}
                              title={
                                'AvailableQuantity  -  ' +
                                (props &&
                                props.formObject &&
                                props.formObject.Tools &&
                                props.formObject.Tools[index2] &&
                                props.formObject.Tools[index2].AvailableQuantity
                                  ? props.formObject.Tools[index2].AvailableQuantity
                                  : 'Not Available')
                              }
                            >
                              <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                            </Tooltip>
                          }
                        />
                      </Form.Item>
                      <Form.Item style={{ flex: '50%' }} labelCol={{ flex: '130px' }} {...tool}>
                        {/* <Tooltip
                          style={{ marginRight: '20px', flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          title={
                            'AvailableQuantity  -  ' +
                            (props &&
                            props.formObject &&
                            props.formObject.Tools &&
                            props.formObject.Tools[index2] &&
                            props.formObject.Tools[index2].AvailableQuantity
                              ? props.formObject.Tools[index2].AvailableQuantity
                              :'Not Available')
                          }
                        >
                          <QuestionCircleOutlined style={{ fontSize: '25px', color: 'white' }} />
                        </Tooltip> */}
                      </Form.Item>
                      <Form.Item style={{ flex: '50%' }}>
                        <InputComponent style={{ display: 'none' }} />
                      </Form.Item>
                      {/* <Col span={1}>
                    <Popconfirm
                      title="Are you sure that you want to  delete the Tool ?"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={() => remove(tool.name)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <MinusCircleOutlined className="deleteIcon" />
                    </Popconfirm>
                  </Col> */}
                    </div>
                  </Panel>
                ))}
              </StyledCollapse>
            ) : null}
          </Fragment>
        );
      }}
    </Form.List>
  );
};
const MaterialFormFunction = (props) => {
  const [materialsList, setTool] = useState([]);
  const [translationCache, setTranslationCache] = useState(props.translationCache);
  useEffect(() => {
    (async () => {
      try {
        let response = await getMaterialsList();
        setTool(response.data);
      } catch (e) {
        return {};
      }
    })();
  }, []);

  return (
    <div>
      <Form.List name={'Materials'}>
        {(Material, { add, remove }) => {
          return (
            <Fragment>
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <StyledButton
                    theme={themeSettings}
                    type="dashed"
                    style={{ background: '#29415a', color: '#fff' }}
                    onClick={() => {
                      add();
                    }}
                  >
                    {translation(translationCache, constant.addMaterial)}{' '}
                  </StyledButton>
                </div>
              </Form.Item>
              {Material && Array.isArray(Material) && Material.length > 0 ? (
                <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                  {Material.map((material, index2) => (
                    <Panel
                      key={index2}
                      header={`${translation(translationCache, constant.material)}${index2 + 1}`}
                      extra={
                        <Popconfirm
                          title={translation(translationCache, constant.deleteMaterial1)}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          // onConfirm={() => remove(material.name)}
                          onConfirm={() => props.onRemove(index2, 'Materials')}
                          okText={translation(translationCache, constant.yes)}
                          cancelText={translation(translationCache, constant.no)}
                        >
                          <Tooltip
                            title={translation(translationCache, 'Delete')}
                            placement="bottom"
                          >
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>
                      }
                    >
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          label={translation(translationCache, constant.material)}
                          rules={[{ required: true }]}
                          {...material}
                          name={[material.name, 'MaterialId']}
                          fieldKey={[material.fieldKey, 'MaterialId']}
                          key={index2}
                        >
                          <Select
                            style={{ width: '90%' }}
                            onChange={(value) =>
                              props.onMaterialChange(
                                value,
                                index2,
                                'Materials',
                                materialsList.filter((Item) => Item.Id === value)
                              )
                            }
                            placeholder={translation(translationCache, 'Select Material')}
                          >
                            {materialsList.map((material, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={material.Id}
                                  disabled={
                                    props &&
                                    props.formObject &&
                                    props.formObject['Materials'] &&
                                    props.formObject['Materials'].filter(
                                      (item) => item && item.MaterialId === material.Id
                                    ).length > 0
                                      ? true
                                      : false
                                  }
                                >
                                  {' '}
                                  {material.Material}{' '}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          // style={{ flex: '50%' }}
                          // labelCol={{ flex: '130px' }}
                          // label={translation(translationCache, constant.availableQuantity)}
                          // disabled={true}
                          // {...material}
                          name={[material.name, 'AvailableQuantity']}
                          // fieldKey={[material.fieldKey, 'AvailableQuantity']}
                          // key={index2}
                        >
                          {/* <InputComponent
                            disabled={true}
                            placeholder={translation(translationCache,"Autopopulate Basis To The Material"
                            style={{ width: '90%' }}
                          /> */}
                        </Form.Item>
                        <Form.Item
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          label={translation(translationCache, constant.description)}
                          // disabled={true}
                          // {...material}
                          name={[material.name, 'Description']}
                          fieldKey={[material.fieldKey, 'Description']}
                          key={index2}
                        >
                          <InputComponent
                            disabled={true}
                            placeholder={translation(
                              translationCache,
                              'Auto Populate from Material'
                            )}
                            style={{ width: '90%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          label={translation(translationCache, constant.Quantity)}
                          validateTrigger="onChange"
                          {...material}
                          name={[material.name, 'Quantity']}
                          fieldKey={[material.fieldKey, 'Quantity']}
                          key={index2}
                          rules={[
                            // {
                            //   // pattern: new RegExp(/^[0-9]*$/),
                            //   message: 'field accept only numbers'
                            // },
                            {
                              validator: (_, value) =>
                                checkAvailability(_, value, 'Materials', index2, props.formObject, {
                                  validateDecimal: true
                                })
                            }
                          ]}
                        >
                          <InputNumberComponent
                            placeholder={translation(translationCache, 'Quantity')}
                            // min={0}
                            style={{ width: '90%' }}
                            addonAfter={
                              <Tooltip
                                style={{ marginRight: '20px', flex: '50%' }}
                                labelCol={{ flex: '130px' }}
                                title={
                                  'AvailableQuantity  -  ' +
                                  (props &&
                                  props.formObject &&
                                  props.formObject.Materials &&
                                  props.formObject.Materials[index2] &&
                                  props.formObject.Materials[index2].AvailableQuantity
                                    ? props.formObject.Materials[index2].AvailableQuantity
                                    : 'Not Available')
                                }
                              >
                                <QuestionCircleOutlined style={{ fontSize: '25px' }} />
                              </Tooltip>
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          style={{ flex: '50%' }}
                          labelCol={{ flex: '130px' }}
                          {...material}
                        >
                          {/* <Tooltip
                            style={{ marginRight: '20px', flex: '50%' }}
                            labelCol={{ flex: '130px' }}
                            title={
                              'AvailableQuantity  -  ' +
                              (props &&
                                props.formObject &&
                                props.formObject.Materials &&
                                props.formObject.Materials[index2] &&
                                props.formObject.Materials[index2].AvailableQuantity
                                ? props.formObject.Materials[index2].AvailableQuantity
                                : 'Not Available')
                            }
                          >
                            <QuestionCircleOutlined style={{ fontSize: '25px', color: 'white' }} />
                          </Tooltip> */}
                        </Form.Item>
                        <Form.Item style={{ flex: '50%' }}>
                          <InputComponent style={{ display: 'none' }} />
                        </Form.Item>
                      </div>
                    </Panel>
                  ))}
                </StyledCollapse>
              ) : null}
            </Fragment>
          );
        }}
      </Form.List>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
const Toolsform = connect(mapStateToProps)(ToolsformFunction);
const MaterialForm = connect(mapStateToProps)(MaterialFormFunction);

export { Toolsform, MaterialForm };
