import React, { Fragment } from 'react';
import {
  Space,
  Form,
  Select,
  Input,
  Button,
  Row,
  Col,
  Divider,
  message,
  Popconfirm,
  Tree
} from 'antd';
import axios from 'axios';
import { DeleteOutlined, PlusOutlined, CarryOutOutlined, FormOutlined } from '@ant-design/icons';
import { createPart, getPartRecord, getPartsSupilerList } from './ApiCalls';
import localStorage from '../../../utils/localStorage';
import { StyledApplication1 } from '../style';
import { Tabs, Card } from 'antd';
import './style.css';
import CreateSpecifications from './CreateSpecifications';
import { API_HIERARCHYMODULE, PARTSDATA } from '../../../commons/api';

const { TabPane } = Tabs;
const { Option } = Select;
const { TreeNode } = Tree;

class EquipmentParts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DeviceId: undefined,
      recordData: [],
      autoExpandParent: true.valueOf,
      expandedKeys: [],
      partHeirarchyData: [],
      selectedId: undefined,
      supplierList: [],
      MainId: undefined,
      typeOfDevice: '',
      maintainedPart: false,
      partId: '',
      EquipementName: '',
      visible: false,
      showLine: true
    };
  }
  formRef = React.createRef();
  componentDidMount = async () => {
    try {
      let supplierList = await getPartsSupilerList();
      this.setState({
        supplierList: supplierList.data
      });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    this._getHierarchy();
  };

  _getHierarchy = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');

    const deviceTypeObject = {
      method: 'POST',
      url: `${API_HIERARCHYMODULE.HierarchyLevels}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { Module: 'EMM' }
    };
    axios(deviceTypeObject)
      .then((response) => {
        this.setState({ partHeirarchyData: response.data });
      })
      .catch(function () {});
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  getData = async (selectedKey, name, type, mainId) => {
    this.onSelect1([selectedKey]);
    this.setState({
      DeviceId: selectedKey,
      EquipementName: name,
      visible: true,
      typeOfDevice: type,
      MainId: mainId
    });
    try {
      if (selectedKey !== null && type !== 'DeviceType') {
        let record = await getPartRecord(selectedKey, type);
        let recordDat = record.data && record.data;

        let partId =
          recordDat &&
          recordDat.Parts.map((item) => {
            return item.Id;
          });
        this.setState({ partId: partId });
        this.setState({ recordData: recordDat, visible: true });
      }
    } catch (err) {
      message.warn(`${err.message}`);
    }
  };

  selectDevice = async (code, TitleName, MainId, type) => {
    const { SubEquipementName } = this.state;
    this.setState({
      DeviceId: code,
      EquipementName: TitleName,
      SubEquipementName,
      visible: true,
      typeOfDevice: type
    });
  };
  onSelect1 = (selectedKey) => {
    this.setState({
      selectedId: selectedKey
    });
  };
  renderTreeNodes = (data) => {
    return (
      data &&
      Array.isArray(data) &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <TreeNode
              title={
                <div
                  onClick={() => this.selectDevice(item.Code, item.Name, item.MainId, item.Type)}
                  style={{}}
                >
                  {`${item.Name ? item.Name : ''}`}{' '}
                </div>
              }
              key={item.Id}
              dataRef={item}
              icon={<CarryOutOutlined />}
              disabled={item.DeviceId === null ? 'disabled' : null}
            >
              {this.renderTreeNodes(item.children)}
            </TreeNode>
          );
        }
        return (
          <TreeNode
            key={item.Id}
            icon={<FormOutlined />}
            title={
              <div
                onClick={() => this.getData(item.Code, item.Name, item.Type, item.MainId, item.Id)}
                style={{}}
              >
                {`${item.Name ? item.Name : ''}`}
              </div>
            }
            dataRef={item}
            disabled={item.DeviceId === null ? 'disabled' : null}
          />
        );
      })
    );
  };
  onReset = () => {
    this.formRef.current.resetFields();
  };

  onFinish = async (values, DeviceId, MainId) => {
    const { recordData } = this.state;
    if (values.Parts.length > 0) {
      let MaintainedPart = values.Parts.map((item) => {
        return item.MaintainedPart;
      });
      let partId =
        recordData &&
        recordData.Parts &&
        Array.isArray(recordData.Parts) &&
        recordData.Parts.map((item) => {
          return item.Id;
        });
      this.setState({ partId: partId });
      try {
        await createPart(values, DeviceId, MainId, 'Part');
        this.setState({ maintainedPart: MaintainedPart });
        message.success('Record Submitted Succesfully');
        this._getHierarchy();
        this.onReset();
      } catch (err) {
        message.warn(`${err.message}`);
      }
    } else {
      message.warn('Please Add Atleast one Specification');
    }
  };

  onMaintainedPart = (e, name) => {
    const fields = this.formRef.current.getFieldsValue();
    let maintainedPart = fields.Parts.map((item) => {
      return item.MaintainedPart;
    });

    this.setState({
      maintainedPart: maintainedPart,
      formId: name
    });
  };

  deleteParts = async (i) => {
    const { recordData } = this.state;
    let partId = recordData.Parts.map((item) => {
      return item.Id;
    });
    const accessToken = localStorage.get('accessToken');
    const partsObject = {
      method: 'POST',
      url: `${PARTSDATA.Delete}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { Id: partId[i] }
    };
    axios(partsObject)
      .then(() => {
        this._getHierarchy();
      })
      .catch(function () {});
  };
  callback = () => {};

  render() {
    let {
      typeOfDevice,
      recordData,
      supplierList,
      partHeirarchyData,
      DeviceId,
      MainId,
      EquipementName,
      visible,
      showLine
    } = this.state;

    return (
      <StyledApplication1 style={{ minHeight: window.innerHeight - 170 }}>
        <Fragment>
          <div style={{ minHeight: '100vh' }}>
            <Row>
              <Col span={5}>
                <Tree
                  showLine={showLine}
                  className={'emmTreelist'}
                  defaultExpandAll={true}
                  onExpand={this.onExpand}
                  onSelect={this.onSelect}
                  // expandedKeys={this.state.expandedKeys}
                  style={{ fontSize: '18px', marginTop: '30px', flex: '1', overflowY: 'scroll' }}
                >
                  {this.renderTreeNodes(partHeirarchyData)}
                </Tree>
              </Col>

              <Col span={19} style={{ backgroundColor: '#214972' }}>
                {typeOfDevice === 'Part' || typeOfDevice === 'SubDevice' ? (
                  <Tabs
                    type="card"
                    className="EMMTAB"
                    defaultActiveKey="1"
                    onChange={this.callback}
                    style={{ paddingLeft: '10px' }}
                  >
                    <TabPane tab="Parts" key="1">
                      <Form
                        ref={this.formRef}
                        name="dynamic_form_nest_item"
                        layout="vertical"
                        onFinish={(values) => this.onFinish(values, DeviceId, MainId)}
                        style={{
                          backgroundColor: '#214972',
                          height: '114vh',
                          flex: '1',
                          overflowY: 'scroll'
                        }}
                      >
                        <Form.Item
                          label={<label style={{ color: '#fff', fontWeight: 'bold' }}></label>}
                          style={{ marginLeft: '70px' }}
                        >
                          <Input
                            value={EquipementName ? EquipementName : ''}
                            style={{ width: '300px', fontStyle: 'bold' }}
                            disabled
                          />
                        </Form.Item>
                        <Divider style={{ color: '#fff', fontWeight: 'bold' }}>
                          Parts Details
                        </Divider>
                        <Card style={{ width: '69vw', backgroundColor: '#214972' }}>
                          <Form.List name="Parts" initialValue={recordData.Parts}>
                            {(fields, { add }) => (
                              <>
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    className="submitBtn"
                                    onClick={() => {
                                      add();
                                    }}
                                    icon={<PlusOutlined />}
                                  >
                                    {' '}
                                    Add Parts{' '}
                                  </Button>
                                </Form.Item>

                                {fields.map(({ key, name, ...restField }) => (
                                  <>
                                    <Space
                                      key={key}
                                      style={{
                                        marginBottom: '0px',
                                        marginRight: '4%',
                                        marginLeft: '35px'
                                      }}
                                    >
                                      <Form.Item
                                        label={
                                          <label style={{ color: '#fff', fontWeight: 'bold' }}>
                                            OEM Part number
                                          </label>
                                        }
                                        {...restField}
                                        name={[name, 'OEMPartNumber']}
                                      >
                                        <Input
                                          placeholder="OEM part number"
                                          style={{ width: '150px' }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label={
                                          <label style={{ color: '#fff', fontWeight: 'bold' }}>
                                            Description
                                          </label>
                                        }
                                        {...restField}
                                        name={[name, 'Description']}
                                      >
                                        <Input
                                          placeholder="Description"
                                          style={{ width: '150px' }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label={
                                          <label style={{ color: '#fff', fontWeight: 'bold' }}>
                                            Quantity
                                          </label>
                                        }
                                        {...restField}
                                        name={[name, 'Quantity']}
                                      >
                                        <Input placeholder="Quantity" style={{ width: '120px' }} />
                                      </Form.Item>
                                      <Form.Item
                                        label={
                                          <label style={{ color: '#fff', fontWeight: 'bold' }}>
                                            UOM
                                          </label>
                                        }
                                        {...restField}
                                        name={[name, 'UOM']}
                                        style={{ width: '120px' }}
                                      >
                                        <Input placeholder="UOM" />
                                      </Form.Item>
                                      <Form.Item
                                        label={
                                          <label style={{ color: '#fff', fontWeight: 'bold' }}>
                                            Part Status
                                          </label>
                                        }
                                        {...restField}
                                        name={[name, 'Status']}
                                      >
                                        <Select style={{ width: '120px' }} placeholder="Status">
                                          <option value={'1'}>IN USE</option>
                                          <option value={'2'}>FREE</option>
                                          <option value={'3'}>HOLD</option>
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        label={
                                          <label style={{ color: '#fff', fontWeight: 'bold' }}>
                                            Select Supplier
                                          </label>
                                        }
                                        {...restField}
                                        name={[name, 'Supplier']}
                                      >
                                        <Select
                                          style={{ width: '120px' }}
                                          placeholder="Select Supplier"
                                          onChange={this.handleChange}
                                        >
                                          {supplierList &&
                                            Array.isArray(supplierList) &&
                                            supplierList.map((param, index) => {
                                              return (
                                                <Option key={index} value={param.Code}>
                                                  {' '}
                                                  {param.Name}{' '}
                                                </Option>
                                              );
                                            })}
                                        </Select>
                                      </Form.Item>

                                      <Form.Item
                                        label={
                                          <label style={{ color: '#fff', fontWeight: 'bold' }}>
                                            Material Code
                                          </label>
                                        }
                                        {...restField}
                                        name={[name, 'MaterialCode']}
                                      >
                                        <Input
                                          placeholder="MaterialCode"
                                          style={{ width: '120px' }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label={
                                          <label style={{ color: '#fff', fontWeight: 'bold' }}>
                                            Maintained Part
                                          </label>
                                        }
                                        {...restField}
                                        name={[name, 'MaintainedPart']}
                                      >
                                        <Select
                                          style={{ width: '120px' }}
                                          placeholder="Maintained part"
                                          onSelect={(e) => this.onMaintainedPart(e)}
                                        >
                                          <Option value={true}>True</Option>
                                          <Option value={false}>False</Option>
                                        </Select>
                                      </Form.Item>
                                      <Popconfirm
                                        title="Are you sure delete parts ?"
                                        onConfirm={(e) => {
                                          e.stopPropagation();
                                          this.deleteParts(name);
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Button type="danger" className="ant-btn">
                                          <DeleteOutlined
                                            className="deleteIcon"
                                            style={{
                                              fontSize: '22px',
                                              color: '#fff',
                                              fontWeight: 'bold',
                                              marginTop: '-3px'
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          />
                                        </Button>
                                      </Popconfirm>
                                    </Space>

                                    {/* <Form.Item>
                               
                                {maintainedPart[name] === true || maintainedPart[name] === "true" ?
                                  <Card style={{ marginLeft: '70px', width: '53vw' }} >
                                    <PartSpecification fieldKey={name} DeviceId={DeviceId} partId={partId[name]} />
                                  </Card>
                                  : null}
                              </Form.Item> */}
                                  </>
                                ))}
                              </>
                            )}
                          </Form.List>

                          <Form.Item>
                            <Button
                              type="primary"
                              className="submitBtn"
                              style={{ marginTop: '10px', marginBottom: '10px' }}
                              htmlType="submit"
                            >
                              Submit
                            </Button>
                          </Form.Item>
                        </Card>
                      </Form>
                    </TabPane>
                    <TabPane tab="Specifications" key="2">
                      <CreateSpecifications
                        DeviceId={DeviceId}
                        EquipementName={EquipementName}
                        visible={visible}
                        _getHierarchy={this._getHierarchy}
                      />
                    </TabPane>
                  </Tabs>
                ) : null}
                {typeOfDevice === 'Device' ? (
                  <Tabs
                    type="card"
                    defaultActiveKey="0"
                    onChange={this.callback}
                    style={{ paddingLeft: '10px' }}
                  >
                    <TabPane tab="Specifications" key="1">
                      <CreateSpecifications
                        DeviceId={DeviceId}
                        EquipementName={EquipementName}
                        visible={visible}
                        _getHierarchy={this._getHierarchy}
                      />
                    </TabPane>
                  </Tabs>
                ) : null}
              </Col>
            </Row>
          </div>
        </Fragment>
      </StyledApplication1>
    );
  }
}

export default EquipmentParts;
