import React, { Component } from 'react';
import _ from 'lodash';
import { Form, message, Collapse, Popconfirm, Select, DatePicker, Tooltip, Input } from 'antd';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import themeSettings from '../../../Utils/themeSettings.json';
import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  getTaskList,
  getTaskById1,
  filterTaskByReference,
  submitTask
} from '../../../Utils/FormCalls';
import { StyledForm, StyledCollapse, StyledModal, StyledButton } from '../../../CSS/style';
import translation from '../../../Utils/translation';
import moment from 'moment';
import { constant } from '../../../Utils/constants';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import InputComponent from '../../../Widgets/InputComponent';
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;
class WorkOrderJobPlanes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      Equipment: props.Equipment && props.Equipment.DeviceAlias ? props.Equipment.DeviceAlias : '',
      // ttd: {},
      recordData: props.formObject && props.formObject.TaskData ? props.formObject.TaskData : [],
      formObject: props.formObject && props.formObject ? props.formObject : {},
      workOrderStatus: props.workOrderStatus,
      fileData: this.props.fileData ? this.props.fileData : {},
      addTask: props.addTask
    };
  }
  workOrderJobPlanRef = React.createRef();
  TaskRef = React.createRef();

  componentDidMount = async () => {
    const { recordData } = this.state;
    this.workOrderJobPlanRef.current !== null &&
      this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject);
    this.generateEstimate(this.props.formObject);
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let organisationsList = await getOrganisationsList();
      this.setState({ organisationsList: organisationsList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      this.setState({ sitesList: sitesList.data });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let TaskList = await getTaskList('Active');
      TaskList = filterTaskByReference(TaskList, recordData);
      this.setState({ TaskList: TaskList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.workOrderJobPlanRef.current !== null &&
        this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject);
      // this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject.TaskData)
    }

    if (prevProps.formObject !== this.props.formObject) {
      this.workOrderJobPlanRef.current !== null &&
        this.workOrderJobPlanRef.current.setFieldsValue(this.props.formObject);
      //
      this.setState({
        formObject: this.props.formObject,
        recordData:
          this.props.formObject && this.props.formObject.TaskData
            ? this.props.formObject.TaskData
            : [],
        workOrderStatus: this.props.workOrderStatus ? this.props.workOrderStatus : null,
        fileData: this.props.fileData ? this.props.fileData : null,
        Equipment:
          this.props.Equipment && this.props.Equipment.DeviceAlias
            ? this.props.Equipment.DeviceAlias
            : ''
      });
    }
  }

  handleChange = (value, key) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [key]: value
    };

    // this.workOrderJobPlanRef.current.setFieldsValue(formObject)
    this.setState({ [key]: value, formObject });
  };

  onReset = () => {
    this.workOrderJobPlanRef.current.resetFields();
    this.workOrderJobPlanRef.current.resetFields();
  };

  handleSelect = (value, mainIndex, subindex, keyName, selectedObject) => {
    let formObject = this.workOrderJobPlanRef.current.getFieldValue();
    let TaskData = formObject && formObject.TaskData;
    TaskData.map((item, index) => {
      if (index === mainIndex) {
        item[keyName][subindex]['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
      }
      return {};
    });
    formObject['TaskData'] = TaskData;
    this.workOrderJobPlanRef.current.setFieldsValue(TaskData);
  };

  generateEstimate(formObject) {
    if (formObject && formObject.TaskData) {
      let TaskData1 = formObject && formObject.TaskData ? formObject.TaskData : [];
      let taskData = [];
      let totalDurationHr = 0;
      let total = 0;
      if (TaskData1 && Array.isArray(TaskData1)) {
        taskData = TaskData1.map((item) => {
          let EstimatedDuration = null;
          let d = 0;
          if (item.StartTime && item.EndTime) {
            let start = item.StartTime;
            let end = item.EndTime;
            let ms = moment(end, 'YYYY-MM-DD HH:mm').diff(moment(start, 'YYYY-MM-DD HH:mm'));
            d = moment.duration(ms);
            EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm');
            totalDurationHr = d.asHours() + totalDurationHr;
            total = ms + total;
          }

          return {
            ...item,
            EstimatedDuration: EstimatedDuration ? EstimatedDuration : '00:00'
          };
        });
      }
      formObject = {
        ...formObject,
        TotalDowntime:
          Math.floor(moment.duration(total).asHours()) + moment.utc(total).format(':mm'),
        // TotalDowntime: Math.floor(totalDurationHr) + ':00:00',
        JobPlan: {
          ...(formObject.JobPlan ? formObject.JobPlan : {}),
          TaskData:
            taskData && Array.isArray(taskData) && taskData.length > 0 ? taskData : TaskData1
        }
      };
      this.workOrderJobPlanRef.current.setFieldsValue(formObject);
      this.props.handleTotalDownTime(formObject);
    }
  }

  datepickerchangeTask = (date, dateString, key) => {
    // this.workOrderJobPlanRef.current.setFieldsValue(key, moment(dateString).format("YYYY-MM-DD HH:mm:ss"))
    let formObject = this.TaskRef.current.getFieldsValue();
    let EstimatedDuration = null;
    if (
      key &&
      key === 'EndTime' &&
      this.TaskRef &&
      this.TaskRef.current &&
      this.TaskRef.current.getFieldValue() &&
      this.TaskRef.current.getFieldValue('StartTime')
    ) {
      let startTime =
        this.TaskRef &&
        this.TaskRef.current &&
        this.TaskRef.current.getFieldValue() &&
        this.TaskRef.current.getFieldValue('StartTime');
      let endTime = date;
      // EstimatedDuration = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm:ss")
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm:ss').diff(
        moment(startTime, 'YYYY-MM-DD HH:mm:ss')
      );
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm');
    } else if (
      key &&
      key === 'StartTime' &&
      this.TaskRef &&
      this.TaskRef.current &&
      this.TaskRef.current.getFieldValue() &&
      this.TaskRef.current.getFieldValue('EndTime')
    ) {
      let endTime =
        this.TaskRef &&
        this.TaskRef.current &&
        this.TaskRef.current.getFieldValue() &&
        this.TaskRef.current.getFieldValue('EndTime');
      let startTime = date;
      // EstimatedDuration = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm:ss")
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm');
    }
    formObject['EstimatedDuration'] = EstimatedDuration;
    this.setState({ formObject });
    this.TaskRef.current.setFieldsValue(formObject);
    // this.generateEstimate(formObject);
  };
  getDisabledHoursTask = (e, key) => {
    let hours = [];
    let minutes = [];
    let StartDateTime = '';
    let EndDateTime = '';
    let StartDate = '';
    let EndDate = '';

    if (key === 'StartTime') {
      StartDateTime = moment(e).format('YYYY-MM-DD HH:mm');
      EndDateTime = moment
        .utc(
          this.TaskRef.current.getFieldValue() && this.TaskRef.current.getFieldValue('EndTime'),
          'YYYY-MM-DD HH:mm'
        )
        .local();

      StartDate = moment(e).format('YYYY-MM-DD');
      EndDate = moment
        .utc(this.TaskRef.current.getFieldValue() && this.TaskRef.current.getFieldValue('EndTime'))
        .local()
        .format('YYYY-MM-DD');
    } else if (key === 'EndTime') {
      StartDateTime = moment
        .utc(
          this.TaskRef.current.getFieldValue() && this.TaskRef.current.getFieldValue('StartTime'),
          'YYYY-MM-DD HH:mm'
        )
        .local();
      EndDateTime = moment(e).format('YYYY-MM-DD HH:mm');

      StartDate = moment
        .utc(
          this.TaskRef.current.getFieldValue() && this.TaskRef.current.getFieldValue('StartTime')
        )
        .local()
        .format('YYYY-MM-DD');
      EndDate = moment(e).format('YYYY-MM-DD');
    }
    if (
      key === 'StartTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (let i = 0; i <= 24; i++) {
        if (i > moment(EndDateTime).hours()) {
          hours.push(i);
        }
      }

      if (moment(StartDateTime).hours() === moment(EndDateTime).hours()) {
        for (var i = 0; i <= 60; i++) {
          if (i > moment(EndDateTime).minutes() - 1) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    } else if (
      key === 'EndTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      // if()
      for (let i = 0; i < moment(StartDateTime).hours(); i++) {
        if (i < 25) {
          hours.push(i);
        }
      }

      if (moment(StartDateTime).hours() === moment(EndDateTime).hours()) {
        for (let i = 0; i < moment(StartDateTime).minutes() + 1; i++) {
          if (i < 61) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    }
  };
  getDisabledHours = (e, index, key) => {
    let hours = [];
    let minutes = [];
    let StartDateTime = '';
    let EndDateTime = '';
    let StartDate = '';
    let EndDate = '';

    if (key === 'StartTime') {
      StartDateTime = moment(e).format('YYYY-MM-DD HH:mm');
      EndDateTime = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime'],
          'YYYY-MM-DD HH:mm'
        )
        .local();

      StartDate = moment(e).format('YYYY-MM-DD');
      EndDate = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime']
        )
        .local()
        .format('YYYY-MM-DD');
    } else if (key === 'EndTime') {
      StartDateTime = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime'],
          'YYYY-MM-DD HH:mm'
        )
        .local();
      EndDateTime = moment(e).format('YYYY-MM-DD HH:mm');

      StartDate = moment
        .utc(
          this.workOrderJobPlanRef.current.getFieldValue() &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
            this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime']
        )
        .local()
        .format('YYYY-MM-DD');
      EndDate = moment(e).format('YYYY-MM-DD');
    }
    if (
      key === 'StartTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (let i = 0; i <= 24; i++) {
        if (i > moment(EndDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var i = 0; i <= 60; i++) {
          if (i > moment(EndDateTime).minutes() - 1) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    } else if (
      key === 'EndTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (let i = 0; i < moment(StartDateTime).hours(); i++) {
        if (i < 25) {
          hours.push(i);
        }
      }

      if (moment(StartDateTime).hours() === moment(EndDateTime).hours()) {
        for (let i = 0; i < moment(StartDateTime).minutes() + 1; i++) {
          if (i < 61) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    }
  };
  taskFileupload = async (values, key, fileDataObject, index) => {
    const { fileData } = this.state;
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    if (
      formObject &&
      formObject['TaskData'] &&
      formObject['TaskData'][index] &&
      formObject['TaskData'][index][key]
    ) {
      formObject['TaskData'][index][key].push(...values);
    } else if (formObject['TaskData'][index] !== undefined) {
      formObject['TaskData'][index][key] = values;
    } else {
      formObject['TaskData'].push({ [key]: values });
    }
    formObject['TaskData'][index][key] =
      formObject['TaskData'][index][key] &&
      formObject['TaskData'][index][key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    this.props.fileAppend(fileDataObject);
    formObject = {
      ...formObject
    };
    this.workOrderJobPlanRef.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };
  datepickerchange = (date, dateString, index, key) => {
    // this.workOrderJobPlanRef.current.setFieldsValue(key, moment(dateString).format("YYYY-MM-DD HH:mm:ss"))
    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    let EstimatedDuration = null;
    if (
      key &&
      key === 'EndTime' &&
      this.workOrderJobPlanRef.current.getFieldValue() &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime']
    ) {
      let startTime =
        this.workOrderJobPlanRef.current.getFieldValue() &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['StartTime'];
      let endTime = date;
      // EstimatedDuration = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm:ss")
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm');
    } else if (
      key &&
      key === 'StartTime' &&
      this.workOrderJobPlanRef.current.getFieldValue() &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
      this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime']
    ) {
      let endTime =
        this.workOrderJobPlanRef.current.getFieldValue() &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index] &&
        this.workOrderJobPlanRef.current.getFieldValue().TaskData[index]['EndTime'];
      let startTime = date;
      // EstimatedDuration = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("HH:mm:ss")
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    }

    formObject &&
    formObject['TaskData'] &&
    formObject['TaskData'][index] &&
    formObject['TaskData'][index]['EstimatedDuration']
      ? (formObject['TaskData'][index]['EstimatedDuration'] = EstimatedDuration)
      : (formObject['TaskData'][index]['EstimatedDuration'] = '');
    this.setState({ formObject });
    // this.workOrderJobPlanRef.current.setFieldsValue(formObject);
    this.generateEstimate(formObject);
  };
  onTaskModalOk = () => {
    // e.preventDefault();
    this.TaskRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...values,
          siteId: this.state.siteId,
          Equipment:
            this.props && this.props.Equipment && this.props.Equipment.DeviceAlias
              ? this.props.Equipment.DeviceAlias
              : ''
        };
        let TaskResponse = await submitTask(values);
        if (TaskResponse) {
          message.success(TaskResponse.message);
          let TaskList = await getTaskList();
          // let testdata = TaskList.filter(
          //   (test) =>
          //     test.Description === values.Description && test.Manufacturer === values.Manufacturer
          // );
          let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
          formObject = {
            ...formObject
            // Manufacturer: testdata[0].Id,
            // ManufacturerDescription: testdata[0].Description
          };
          // this.hRef.current.setFieldsValue(formObject);
          this.setState({ viewEquipmentModal: false, TaskList, formObject });

          if (this.TaskRef.current) {
            this.TaskRef.current.resetFields();
          }
        }
      })
      .catch(() => {});

    // let formObject = this.TaskRef.current.getFieldsValue();
    // this.setState({
    //   viewEquipmentModal: false
    // });
    // this.TaskRef.current.resetFields();
    // this.TaskOnChange(formObject, 'key');
    // this.generateEstimate(formObject);

    // onClick = {() =>
    // this.props.onFinish(
    //   this.workOrdthis.props.Equipment && this.props.Equipment.Device ? this.props.Equipment.Device :"" rJobPlanRef.current.getFieldsValue(),
    //   'JobPlan',
    //   this.state.fileData
    // );
    // }
    // workOrderJobPlanRef
  };

  onTaskModalCancel = () => {
    this.TaskRef.current.resetFields();
    this.setState({
      viewEquipmentModal: false
    });
  };
  timeToMins = (time) => {
    var b = time.split(':');
    return b[0] * 60 + +b[1];
  };

  // Convert minutes to a time in format hh:mm
  // Returned value is in range 00  to 24 hrs
  timeFromMins = (mins) => {
    function z(n) {
      return (n < 10 ? '0' : '') + n;
    }
    var h = ((mins / 60) | 0) % 24;
    var m = mins % 60;
    return z(h) + ':' + z(m);
  };
  addTimes = (t0, t1) => {
    return this.timeFromMins(this.timeToMins(t0) + this.timeToMins(t1));
  };
  TaskOnChange = async (value, val) => {
    let TaskData = {};
    let TaskArray = val && val === 'key' ? value : await getTaskById1(value, 'withoutid');
    if (TaskArray && !Array.isArray(TaskArray)) {
      TaskData = TaskArray;
    } else if (TaskArray && Array.isArray(TaskArray) && TaskArray[0]) {
      TaskData = TaskArray[0];
      // TaskData = {
      //   ...TaskData,
      //   EstimatedDuration: TaskData.EstimatedDuration
      //     ? moment(TaskData.EstimatedDuration).format('HH:mm')
      //     : '00:00'
      // };
      // let selected = ["Name", "TaskDescription", "EstimatedDuration", "EndTime", "StartTime"]
      // if (selected.every(key => Object.keys(TaskData).includes(key)) === true) {
      //   TaskData = {
      //     "Name": TaskData.Name,
      //     "TaskDescription": TaskData.TaskDescription,
      //     "EstimatedDuration": TaskData.EstimatedDuration,
      //     "EndTime": TaskData.Name.EndTime,
      //     "StartTime": TaskData.Name.StartTime,sitesList
      //   }
      // }
      // let names = Object.keys(TaskData)

      //   .filter((key) => key.includes(selected)
      //     //  && key.includes("TaskDescription") &&
      //     // key.includes("EstimatedDuration") && key.includes("EndTime") && key.includes("StartTime")
      //   )
      //   .reduce((obj, key) => {
      //     return Object.assign(obj, {
      //       [key]: TaskData[key]
      //     });
      //   }, {});
    }
    let data =
      this.workOrderJobPlanRef.current.getFieldsValue() &&
      this.workOrderJobPlanRef.current.getFieldsValue().TaskData !== undefined
        ? this.workOrderJobPlanRef.current.getFieldsValue().TaskData
        : [];
    if (TaskData)
      _.cloneDeepWith(TaskData, (value) => {
        return value !== undefined || value !== null || value.StartTime || value.EndTime
          ? {
              ...value,
              ...(value['StartTime']
                ? (value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss').local())
                : null),
              ...(value['EndTime']
                ? (value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss').local())
                : null)
            }
          : _.noop();
      });
    // data.push(TaskData);

    let formObject = this.workOrderJobPlanRef.current.getFieldsValue();
    if (formObject && formObject.TaskData) {
      let TaskData1 = formObject && formObject.TaskData ? formObject.TaskData : [];
      TaskData1.push(TaskData);
      let taskData = [];
      let totalDuration = '00:00';
      var result = moment.duration();
      if (TaskData1 && Array.isArray(TaskData1)) {
        taskData = TaskData1.map((item) => {
          let EstimatedDuration = null;
          let d = 0;
          if (
            item.StartTime &&
            item.EndTime &&
            (item.StartTime !== null) & (item.EndTime !== null)
          ) {
            let start = item.StartTime;
            let end = item.EndTime;
            let ms = moment(end, 'YYYY-MM-DD HH:mm').diff(moment(start, 'YYYY-MM-DD HH:mm'));
            d = moment.duration(ms);
            EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm');
            result = moment.duration(EstimatedDuration);
            result.add(moment.duration(totalDuration));
            totalDuration = `${Math.floor(result.asHours())}:${Math.floor(result.minutes())}`;
          }
          return {
            ...item,
            EstimatedDuration: EstimatedDuration ? EstimatedDuration : '00:00'
          };
        });
      }
      formObject = {
        // ...formObject,
        TotalDowntime: totalDuration,
        JobPlan: {
          ...(formObject.JobPlan ? formObject.JobPlan : {}),
          TaskData: taskData && Array.isArray(taskData) && taskData.length > 0 ? taskData : data
        }
      };
      this.setState({ selectedTask: value });
      this.props.handleTotalDownTime(formObject);
      this.workOrderJobPlanRef.current.setFieldsValue(formObject);
      this.props.onFinish(
        this.workOrderJobPlanRef.current.getFieldsValue(),
        'JobPlan',
        this.state.fileData
      );
    }
    return {};
  };
  // onFinishFailed = () => {
  //   message.error('Please enter mandatory fields');
  // };
  render() {
    let { formObject, TaskList, selectedTask, fileData } = this.state;
    let { translationCache } = this.props;
    /* eslint-disable no-template-curly-in-string */

    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      },
      pattern: {
        mismatch: '${label} does not match pattern'
      }
    };
    const styledCollapse = (index, translationCache) => (
      <span>
        <span style={{ fontWeight: 'bold' }}>{translation(translationCache, 'Task Name')}</span>:
        <span style={{ color: 'white', margin: '0px 8px' }}>
          {this.workOrderJobPlanRef.current &&
          this.workOrderJobPlanRef.current.getFieldsValue() &&
          this.workOrderJobPlanRef.current.getFieldsValue()['TaskData'] &&
          this.workOrderJobPlanRef.current.getFieldsValue()['TaskData'][index] &&
          this.workOrderJobPlanRef.current.getFieldsValue()['TaskData'][index]['TaskName']
            ? this.workOrderJobPlanRef.current.getFieldsValue()['TaskData'][index]['TaskName']
            : ''}
        </span>
        <span style={{ fontWeight: 'bold' }}>
          {translation(translationCache, 'Task Description')}
        </span>{' '}
        :
        <span style={{ color: 'white', margin: '0px 8px' }}>
          {this.workOrderJobPlanRef.current &&
          this.workOrderJobPlanRef.current.getFieldsValue() &&
          this.workOrderJobPlanRef.current.getFieldsValue()['TaskData'] &&
          this.workOrderJobPlanRef.current.getFieldsValue()['TaskData'][index] &&
          this.workOrderJobPlanRef.current.getFieldsValue()['TaskData'][index]['TaskDescription']
            ? this.workOrderJobPlanRef.current.getFieldsValue()['TaskData'][index][
                'TaskDescription'
              ]
            : ''}
        </span>
      </span>
    );

    return (
      <div style={{ marginTop: '5px', marginBottom: '30px', color: 'black', fontWeight: 'bold' }}>
        <StyledForm
          ref={this.workOrderJobPlanRef}
          name={'jobplan'}
          onFinish={(values) => this.props.onFinish(values, 'JobPlan', fileData)}
          // onFinishFailed={this.onFinishFailed}
          theme={themeSettings}
          initialValues={formObject}
          layout="horizontal"
          labelCol={{ flex: '130px' }}
          labelAlign="right"
          labelWrap
          //wrapperCol={{ flex: 1 }}
          validateMessages={validateMessages}
        >
          {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item
              label={translation(translationCache, constant.addTask)}
              name="TaskName"
              style={{ width: '100%', flex: '50%' }}
              >
              <Select
                placeholder={translation(translationCache,"Select Task")}
                defaultValue={selectedTask}
                onChange={(value) => this.TaskOnChange(value)}
                style={{ width: '70%' }}
              >
                {TaskList &&
                  Array.isArray(TaskList) &&
                  TaskList.map((task, index) => {
                    return (
                      <Option key={index} value={task.Id}>
                        {task.TaskName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item style={{ width: '100%', flex: '50%' }}>
              <InputComponent style={{ display: 'none' }} />
            </Form.Item>
          </div> */}
          <Form.List
            initialValue={formObject && formObject['TaskData'] ? formObject['TaskData'] : []}
            name="TaskData"
          >
            {(fields, { remove }) => (
              <>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <Form.Item
                    label={translation(translationCache, constant.addTask)}
                    name="TaskName"
                    style={{ width: '70%', flex: '50%' }}
                  >
                    <Select
                      showSearch={true}
                      placeholder={translation(translationCache, constant.selectTask)}
                      defaultValue={selectedTask}
                      onChange={(value) => this.TaskOnChange(value)}
                      // style={{ width: '70%' }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      // dropdownRender={(menu) => (
                      //   <>q
                      //     {menu}
                      //     <Divider
                      //       style={{
                      //         margin: '8px 0'
                      //       }}
                      //     />
                      //     <Space
                      //       style={{
                      //         padding: '0 8px 4px'
                      //       }}
                      //     >
                      //       <Button
                      //         type="dashed"
                      //         // onClick={() => this.setState({ viewEquipmentModal: true })}
                      //         onClick={() => add()}
                      //       >
                      //         {' '}
                      //         {translation(translationCache, constant.addNewTask)}{' '}
                      //       </Button>
                      //     </Space>
                      //   </>
                      // )}
                    >
                      {TaskList &&
                        Array.isArray(TaskList) &&
                        TaskList.map((task, index) => {
                          return (
                            <Option
                              key={index}
                              value={task.Id}
                              disabled={
                                this.workOrderJobPlanRef.current &&
                                this.workOrderJobPlanRef.current.getFieldsValue() &&
                                this.workOrderJobPlanRef.current.getFieldsValue().TaskData &&
                                this.workOrderJobPlanRef.current
                                  .getFieldsValue()
                                  .TaskData.filter(
                                    (item) =>
                                      item && item !== undefined && item.TaskName === task.TaskName
                                  ).length > 0
                                  ? true
                                  : false
                              }
                            >
                              {task.TaskName}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <StyledButton
                    theme={themeSettings}
                    style={{ marginLeft: '5px' }}
                    type="dashed"
                    disabled={this.props.addTask ? this.props.addTask : false}
                    onClick={() => this.setState({ viewEquipmentModal: true })}
                    //  onClick={() => add()}
                  >
                    {' '}
                    {translation(translationCache, constant.add)}{' '}
                  </StyledButton>
                  <Form.Item style={{ width: '100%', flex: '50%' }}>
                    <InputComponent style={{ display: 'none' }} />
                  </Form.Item>
                </div>
                {fields && Array.isArray(fields) && fields.length > 0 ? (
                  <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                    {fields.map(({ key, name }, index) => (
                      <Panel
                        key={key}
                        header={styledCollapse(index, translationCache)}
                        extra={
                          <Popconfirm
                            title={translation(
                              translationCache,
                              'Are you sure that you want to delete the task？'
                            )}
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => remove(name)}
                            okText={translation(translationCache, constant.yes)}
                            cancelText={translation(translationCache, constant.no)}
                          >
                            <Tooltip
                              title={translation(translationCache, 'Delete')}
                              placement="bottom"
                            >
                              <DeleteOutlined />
                            </Tooltip>
                          </Popconfirm>
                        }
                      >
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          <Form.Item
                            label={translation(translationCache, constant.TaskName)}
                            // {...restField}
                            style={{ width: '100%', flex: '50%' }}
                            name={[name, 'TaskName']}
                            rules={[
                              {
                                required: true,
                                message: 'TaskName is required'
                              }
                            ]}
                          >
                            <InputComponent
                              placeholder={translation(translationCache, 'Enter TaskName')}
                              style={{ width: '70%' }}
                              disabled={true}
                            />
                          </Form.Item>
                          <Form.Item
                            label={translation(translationCache, constant.description)}
                            // {...restField}
                            name={[name, 'TaskDescription']}
                            style={{ width: '100%', flex: '50%' }}
                          >
                            <TextArea
                              disabled={true}
                              rows={3}
                              style={{ width: '70%' }}
                              placeholder={translation(translationCache, 'Enter Description')}
                            />
                            {/* <InputComponent
                              //maxLength={150}
                              placeholder={translation(translationCache,"Enter Description"
                              style={{ width: '70%' }}
                            /> */}
                          </Form.Item>
                          <Form.Item
                            label={translation(translationCache, constant.startTime)}
                            // {...restField}
                            style={{ width: '100%', flex: '50%' }}
                            name={[name, 'StartTime']}
                          >
                            <DatePicker
                              style={{ width: '70%' }}
                              format="YYYY-MM-DD HH:mm:ss"
                              inputReadOnly={true}
                              placeholder={translation(translationCache, constant.startDate)}
                              onChange={(date, dateString) => {
                                this.datepickerchange(date, dateString, key, 'StartTime');
                              }}
                              showTime={true}
                              showNow={false}
                              disabledDate={(d) =>
                                !d ||
                                d.isAfter(
                                  this.workOrderJobPlanRef.current.getFieldValue() &&
                                    this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
                                    this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                      key
                                    ] &&
                                    this.workOrderJobPlanRef.current.getFieldValue().TaskData[key][
                                      'EndTime'
                                    ]
                                    ? moment(
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                          key
                                        ]['EndTime']
                                      )
                                        .add(1, 'day')
                                        .format('YYYY-MM-DD')
                                    : ''
                                ) ||
                                d.isBefore(
                                  this.workOrderJobPlanRef.current.getFieldValue() &&
                                    this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
                                    this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                      key
                                    ] &&
                                    this.workOrderJobPlanRef.current.getFieldValue().TaskData[key][
                                      'StartTime'
                                    ]
                                    ? moment(
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                          key
                                        ]['StartTime']
                                      ).format('YYYY-MM-DD')
                                    : ''
                                ) ||
                                d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                              }
                              disabledTime={(e) =>
                                this.getDisabledHours(
                                  moment(e, 'YYYY-MM-DD HH:mm:ss'),
                                  key,
                                  'StartTime'
                                )
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            label={translation(translationCache, constant.endTime)}
                            // {...restField}
                            style={{ width: '100%', flex: '50%' }}
                            name={[name, 'EndTime']}
                          >
                            <DatePicker
                              style={{ width: '70%' }}
                              format="YYYY-MM-DD HH:mm:ss"
                              inputReadOnly={true}
                              disabled={
                                this.workOrderJobPlanRef.current &&
                                this.workOrderJobPlanRef.current.getFieldValue() &&
                                this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
                                this.workOrderJobPlanRef.current.getFieldValue().TaskData[key] &&
                                this.workOrderJobPlanRef.current.getFieldValue().TaskData[key][
                                  'StartTime'
                                ]
                                  ? false
                                  : true
                              }
                              // disabled={
                              //   this.workOrderJobPlanRef.current &&
                              //   this.workOrderJobPlanRef.current !== null &&
                              //   this.workOrderJobPlanRef.current.getFieldValue() &&
                              //   this.workOrderJobPlanRef.current.getFieldValue()
                              //     .ActualTaskData &&
                              //   this.workOrderJobPlanRef.current.getFieldValue()
                              //     .ActualTaskData[key] &&
                              //   this.workOrderJobPlanRef.current.getFieldValue()
                              //     .ActualTaskData[key]['StartTime']
                              //     ? false
                              //     : true
                              // }

                              placeholder={translation(translationCache, constant.selectEndDate)}
                              onChange={(date, dateString) => {
                                this.datepickerchange(date, dateString, key, 'EndTime');
                              }}
                              showNow={false}
                              showTime={{
                                hideDisabledOptions: true,
                                defaultValue: moment('23:59:59', 'HH:mm:ss')
                              }}
                              disabledDate={(d) =>
                                !d ||
                                d.isAfter(
                                  this.workOrderJobPlanRef.current.getFieldValue() &&
                                    this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
                                    this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                      key
                                    ] &&
                                    this.workOrderJobPlanRef.current.getFieldValue().TaskData[key][
                                      'EndTime'
                                    ]
                                    ? moment(
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                          key
                                        ]['EndTime']
                                      )
                                        .add(1, 'day')
                                        .format('YYYY-MM-DD')
                                    : ''
                                ) ||
                                d.isBefore(
                                  this.workOrderJobPlanRef.current.getFieldValue() &&
                                    this.workOrderJobPlanRef.current.getFieldValue().TaskData &&
                                    this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                      key
                                    ] &&
                                    this.workOrderJobPlanRef.current.getFieldValue().TaskData[key][
                                      'StartTime'
                                    ]
                                    ? moment(
                                        this.workOrderJobPlanRef.current.getFieldValue().TaskData[
                                          key
                                        ]['StartTime']
                                      ).format('YYYY-MM-DD')
                                    : ''
                                ) ||
                                d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                              }
                              disabledTime={(e) =>
                                this.getDisabledHours(
                                  moment(e, 'YYYY-MM-DD HH:mm:ss'),
                                  key,
                                  'EndTime'
                                )
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            label={translation(translationCache, constant.duration)}
                            name={[name, 'EstimatedDuration']}
                            rules={[
                              {
                                required: false,
                                message: 'Autopopulte based on Start and End time!'
                              }
                            ]}
                            style={{ width: '100%', flex: '50%' }}
                          >
                            <InputComponent
                              disabled={true}
                              style={{ width: '70%' }}
                              placeholder="Autopopulte based on Start and End time!"
                              // format={'HH:mm:ss'}
                              // defaultOpenValue={moment('00:00', 'HH:mm:ss')}
                              // onChange={(value) => this.handleChange(value, 'WorkOrderCreationTime')}
                            />
                          </Form.Item>
                          {/* <Form.Item label="Attachments" style={{ width: '100%', flex: '50%' }}>
                            <FileBase64
                              Attachments={
                                formObject &&
                                formObject['TaskData'] &&
                                formObject['TaskData'][key] &&
                                formObject['TaskData'][key]['Attachments']
                                  ? formObject['TaskData'][key]['Attachments']
                                  : []
                              }
                              name={key}
                              fileUpload={this.taskFileupload}
                            ></FileBase64>
                          </Form.Item> */}
                          <Form.Item style={{ width: '100%', flex: '50%' }}>
                            <InputComponent style={{ display: 'none' }} />
                          </Form.Item>
                        </div>
                      </Panel>
                    ))}
                  </StyledCollapse>
                ) : null}
                <StyledModal
                  visible={this.state.viewEquipmentModal}
                  width={800}
                  closable
                  title={translation(translationCache, constant.addTasks)}
                  footer={null}
                  onOk={(e) => this.onTaskModalOk(e)}
                  onCancel={() => this.onTaskModalCancel()}
                >
                  <StyledForm
                    theme={themeSettings}
                    layout="vertical"
                    ref={this.TaskRef}
                    name="user_form"
                    onFinish={(e) => this.onTaskModalOk(e)}
                    //  onFinishFailed={this.onFinishFailed}
                  >
                    <div style={{ marginLeft: '85%' }}>
                      <Form.Item>
                        <StyledButton theme={themeSettings} htmlType="submit">
                          {translation(translationCache, constant.submit)}
                        </StyledButton>
                      </Form.Item>
                    </div>
                    <Form.Item
                      label={translation(translationCache, constant.TaskName)}
                      // {...restField}
                      style={{ width: '100%', flex: '50%' }}
                      name={'TaskName'}
                      rules={[
                        {
                          required: true,
                          message: translation(translationCache, 'TaskName is required')
                        }
                      ]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache, 'Enter TaskName')}
                        style={{ width: '70%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={translation(translationCache, constant.description)}
                      // {...restField}
                      rules={[
                        {
                          required: true,
                          message: translation(translationCache, 'TaskDescription is required')
                        }
                      ]}
                      name={'TaskDescription'}
                      style={{ width: '100%', flex: '50%' }}
                    >
                      <TextArea
                        rows={3}
                        style={{ width: '70%' }}
                        placeholder={translation(translationCache, 'Enter Description')}
                      />
                      {/* <InputComponent
                              //maxLength={150}
                              placeholder="Enter Description"
                              style={{ width: '70%' }}
                            /> */}
                    </Form.Item>
                    <Form.Item
                      label={translation(translationCache, constant.startTime)}
                      // {...restField}
                      style={{ width: '100%', flex: '50%' }}
                      name={'StartTime'}
                    >
                      <DatePicker
                        style={{ width: '70%' }}
                        format="YYYY-MM-DD HH:mm:ss"
                        inputReadOnly={true}
                        placeholder={translation(translationCache, constant.startDate)}
                        onChange={(date, dateString) => {
                          this.datepickerchangeTask(date, dateString, 'StartTime');
                        }}
                        showTime={true}
                        disabledDate={(d) =>
                          !d ||
                          d.isAfter(
                            this.TaskRef.current.getFieldValue() &&
                              this.TaskRef.current.getFieldValue().TaskData &&
                              this.TaskRef.current.getFieldValue('EndTime')
                              ? moment(
                                  this.TaskRef.current
                                    .getFieldValue('EndTime')
                                    .add(1, 'day')
                                    .format('YYYY-MM-DD')
                                )
                              : ''
                          ) ||
                          d.isBefore(
                            this.TaskRef.current.getFieldValue() &&
                              this.TaskRef.current.getFieldValue('StartTime')
                              ? moment(
                                  this.TaskRef.current
                                    .getFieldValue('StartTime')
                                    .format('YYYY-MM-DD')
                                )
                              : ''
                          ) ||
                          d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                        }
                        disabledTime={(e) =>
                          this.getDisabledHoursTask(moment(e, 'YYYY-MM-DD HH:mm:ss'), 'StartTime')
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label={translation(translationCache, constant.endTime)}
                      // {...restField}
                      style={{ width: '100%', flex: '50%', color: 'white' }}
                      name={'EndTime'}
                      rules={[
                        {
                          required: false,
                          message: 'Autopopulte based on Start and End time!'
                        }
                      ]}
                    >
                      <DatePicker
                        showNow={false}
                        style={{ width: '70%' }}
                        format="YYYY-MM-DD HH:mm:ss"
                        inputReadOnly={true}
                        placeholder={translation(translationCache, constant.selectDate)}
                        onChange={(date, dateString) => {
                          this.datepickerchangeTask(date, dateString, 'EndTime');
                        }}
                        showTime={{
                          hideDisabledOptions: true,
                          defaultValue: moment('23:59:59', 'HH:mm:ss')
                        }}
                        disabledDate={(d) =>
                          !d ||
                          d.isAfter(
                            this.TaskRef.current.getFieldValue() &&
                              this.TaskRef.current.getFieldValue('EndTime')
                              ? moment(this.TaskRef.current.getFieldValue('EndTime'))
                                  .add(1, 'day')
                                  .format('YYYY-MM-DD')
                              : ''
                          ) ||
                          d.isBefore(
                            this.TaskRef.current.getFieldValue() &&
                              this.TaskRef.current.getFieldValue('StartTime')
                              ? moment(this.TaskRef.current.getFieldValue('StartTime')).format(
                                  'YYYY-MM-DD'
                                )
                              : ''
                          ) ||
                          d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                        }
                        disabledTime={(e) =>
                          this.getDisabledHoursTask(moment(e, 'YYYY-MM-DD HH:mm:ss'), 'EndTime')
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label={translation(translationCache, constant.duration)}
                      name={'EstimatedDuration'}
                      rules={[
                        {
                          required: false,
                          message: 'Autopopulte based on Start and End time!'
                        }
                      ]}
                      style={{ width: '100%', flex: '50%' }}
                    >
                      <InputComponent
                        disabled={true}
                        style={{ width: '70%' }}
                        placeholder={translation(
                          translationCache,
                          'Autopopulte based on Start and End time!'
                        )}
                        // format={'HH:mm:ss'}
                        // defaultOpenValue={moment('00:00', 'HH:mm:ss')}
                        // onChange={(value) => this.handleChange(value, 'WorkOrderCreationTime')}
                      />
                    </Form.Item>
                  </StyledForm>
                </StyledModal>
              </>
            )}
          </Form.List>
          {/* <Form.Item style={{ display: 'flex', justifyContent: 'right' }}>
            <StyledButton
              theme={themeSettings}
              style={{ marginTop: '20px', marginBottom: '-7px' }}
              onClick={() =>
                this.props.onFinish(
                  this.workOrderJobPlanRef.current.getFieldsValue(),
                  'JobPlan',
                  fileData
                )
              }
            >
              {translation(translationCache, constant.save)}
            </StyledButton>
          </Form.Item> */}
        </StyledForm>{' '}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(WorkOrderJobPlanes);
