import React, { Fragment } from 'react';
import _ from 'lodash';
import Highlighter from 'react-highlight-words';
import { Button, DatePicker, Form, Input, message, Select, Table, Modal, Upload } from 'antd';
import { StyledForm } from '../CSS/style';
import { constant } from '../Utils/constants';
import InputComponent from '../Utils/InputComponent';
import theme from '../Utils/theme.json';
import translation from '../Utils/translation';
import { IPAddress } from '../../../commons/api';
import { DownloadOutlined, UploadOutlined, SearchOutlined } from '@ant-design/icons';
import {
  saveSubDevice,
  getAttributeList,
  getSubEquipmentFileDownloadName,
  getSubEquipmentData,
  getSubDeviceListByDeviceType
} from '../APICalls';
import localStorage from '../../../utils/localStorage';
import themeSettings from '../Utils/themeSettings.json';
import moment from 'moment';
import SelectComponent from '../Utils/SelectComponent';

class SubEquipments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      attributeList: [],
      parameterList: [],
      loading: false
    };
    this.hRef = React.createRef();
  }
  componentDidMount() {
    this.getSubEquipmentList(this.props.deviceDetails);
  }

  componentDidUpdate(prevprops) {
    if (
      prevprops.deviceDetails !== this.props.deviceDetails ||
      prevprops.selectedRow !== this.props.selectedRow
    ) {
      this.getSubEquipmentList(this.props.deviceDetails);
    }
  }

  getSubEquipmentList = async (deviceDetails) => {
    let subdeviceTypeList = await getSubDeviceListByDeviceType(deviceDetails.DeviceType);
    let SubDeviceType =
      subdeviceTypeList && subdeviceTypeList[0] ? subdeviceTypeList[0].AliasCode : '';
    let subEquipmentsList = await getSubEquipmentData(deviceDetails.DeviceCode, SubDeviceType);
    let attributeList = await getAttributeList(deviceDetails.DeviceType);

    this.getFileDownloadName(SubDeviceType);
    this.setState({
      subEquipmentsList,
      subdeviceTypeList,
      attributeList,
      SubDeviceType
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onFieldChange = async (fieldValue, fieldName) => {
    let { formObject } = this.state;
    this.setState({
      formObject: {
        ...formObject,
        [fieldName]: fieldValue
      }
    });
  };

  onSubEqptypeChange = async (fieldValue, fieldName) => {
    let { deviceDetails } = this.props;
    let subEquipmentsList = await getSubEquipmentData(deviceDetails.DeviceCode, fieldValue);
    this.getFileDownloadName(fieldValue);
    this.setState({
      [fieldName]: fieldValue,
      subEquipmentsList
    });
  };

  onReset = () => {
    let { attributeList } = this.state;
    const attKeys = {};
    attributeList.forEach(({ Code }) => Object.assign(attKeys, { [Code]: null }));

    this.hRef.current &&
      this.hRef.current.setFieldsValue({
        SubDeviceType: null,
        Name: null,
        Description: null,
        DisplayCode: null,
        Id: null,
        ...attKeys
      });
    this.setState({ formObject: {} });
  };

  getFileDownloadName = async (SubDeviceType) => {
    let fileDownloadName = await getSubEquipmentFileDownloadName(
      this.props.treeValue,
      this.props.parent.Code,
      SubDeviceType
    );
    this.setState({
      fileDownloadName: fileDownloadName && fileDownloadName.file ? fileDownloadName.file : ''
    });
  };

  editRow = async (formobject) => {
    _.cloneDeepWith(formobject, (value) => {
      return value && value !== null && (value.ReplacementDate || value.InstallationDate)
        ? {
            ...value,
            ...(value['ReplacementDate'] = moment.utc(
              value.ReplacementDate,
              'YYYY-MM-DD HH:mm:ss'
            )),
            ...(value['InstallationDate'] = moment.utc(
              value.InstallationDate,
              'YYYY-MM-DD HH:mm:ss'
            ))
          }
        : _.noop();
    });
    this.hRef.current &&
      this.hRef.current.setFieldsValue({
        ...formobject
      });
    this.setState({
      isModalOpen: true,
      formobject
    });
  };

  renderWidget = (props) => {
    let { widget, Code, isEditableOnUpdate, JSON, Name } = props;
    let { formObject } = this.state;
    let deviceType =
      this.hRef && this.hRef.current && this.hRef.current.getFieldValue('DeviceType');
    switch (widget) {
      case 'text':
        return (
          <InputComponent
            value={
              formObject && formObject[Code]
                ? translation(this.props.translationCache, formObject[Code])
                : ''
            }
            placeholder={`Input ${Name}`}
            disabled={formObject.Id && isEditableOnUpdate === 'true' ? true : false}
            onChange={(e) => this.onFieldChange(e.target.value, Code)}
          />
        );
      case 'dateselection':
        return (
          <DatePicker
            value={
              formObject && formObject[Code]
                ? moment(formObject[Code]).format('YYYY-MM-DD HH:mm:ss')
                : undefined
            }
            format={'YYYY-MM-DD HH:mm:ss'}
            allowClear={false}
            showNow={false}
            showTime={false}
            placeholder={`Select ${Name}`}
            disabled={formObject.Id && isEditableOnUpdate === 'true' ? true : false}
            onChange={(e) => this.onFieldChange(e, Code)}
            style={{ width: '100%' }}
          />
        );
      case 'multiselect':
        return (
          <SelectComponent
            value={
              this.state.formObject && this.state.formObject[Code]
                ? this.state.formObject[Code]
                : []
            }
            placeholder={`Select ${Name}`}
            Code={props && props.Code ? props.Code : ''}
            formObject={this.state && this.state.formObject ? this.state.formObject : ''}
            api={JSON}
            DeviceType={deviceType}
            onSelectChange={(e) => this.onFieldChange(e, Code)}
          />
        );
      default:
        return (
          <InputComponent
            value={
              formObject && formObject[Code]
                ? translation(this.props.translationCache, formObject[Code])
                : ''
            }
            placeholder={`Input ${Name}`}
            disabled={formObject.Id && isEditableOnUpdate === 'true' ? true : false}
            onChange={(e) => this.onFieldChange(e.target.value, Code)}
          />
        );
    }
  };

  handleOk = () => {
    const siteId = localStorage.get('currentSite');
    let { SubDeviceType } = this.state;
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        let { deviceDetails } = this.props;
        values = {
          ...this.hRef.current.getFieldsValue()
        };
        let payload = {};
        if (values && values.Id) {
          // let { formObject } = this.state;
          payload = {
            ...this.hRef.current.getFieldsValue(),
            EquipmentType: 'Child',
            DeviceType: deviceDetails && deviceDetails.DeviceType ? deviceDetails.DeviceType : '',
            ParentEquipment:
              deviceDetails && deviceDetails.DeviceCode ? deviceDetails.DeviceCode : '',
            AssetAlias: deviceDetails && deviceDetails.AssetAlias ? deviceDetails.AssetAlias : '',
            SubDeviceType
          };
        } else {
          payload = {
            ...this.hRef.current.getFieldsValue(),
            EquipmentType: 'Child',
            ParentEquipment:
              deviceDetails && deviceDetails.DeviceCode ? deviceDetails.DeviceCode : '',
            DeviceType: deviceDetails && deviceDetails.DeviceType ? deviceDetails.DeviceType : '',
            AssetAlias: deviceDetails && deviceDetails.AssetAlias ? deviceDetails.AssetAlias : '',
            SiteId: siteId,
            SubDeviceType
          };
        }
        if (payload && (!payload.Id || payload.Id === null)) {
          delete payload.Id;
        }
        let saveResponse = await saveSubDevice(payload);
        if (saveResponse && saveResponse.message) {
          message.success(saveResponse.message);

          let subEquipmentsList = await getSubEquipmentData(
            this.props.deviceDetails.DeviceCode,
            this.state.SubDeviceType
          );

          this.getFileDownloadName(SubDeviceType);
          // this.props.updateParentDataFromSUbEqpChange()
          this.setState({ isModalOpen: false, subEquipmentsList });
        }
      })
      .catch(() => {});
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
    this.onReset();
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    let { translationCache } = this.props;
    let {
      formObject,
      subEquipmentsList,
      attributeList,
      subdeviceTypeList,
      fileDownloadName,
      SubDeviceType
    } = this.state;
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name',
        ...this.getColumnSearchProps('Name')
      },
      {
        title: 'Code',
        dataIndex: 'DisplayCode',
        key: 'DisplayCode',
        ...this.getColumnSearchProps('DisplayCode')
      },
      {
        title: 'Description',
        dataIndex: 'Description',
        key: 'Description',
        ...this.getColumnSearchProps('Description')
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => {
          return (
            <span>
              <Button onClick={() => this.editRow(record)}>{'Edit'}</Button>
            </span>
          );
        }
      }
    ];
    return (
      <Fragment>
        <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 0px' }}>
          <a href={`${IPAddress.LOCAL}${fileDownloadName}`}>
            <DownloadOutlined style={{ color: 'white', fontSize: '16px', marginRight: '1px' }} />
            <span style={{ color: 'white', marginRight: '10px' }}>
              {this.translation('Download')}{' '}
            </span>
          </a>
          <Upload
            multiple={false}
            style={{ marginRight: '10px', color: 'white' }}
            onChange={async (info) => {
              if (info.file.status === 'done') {
                message.success(info.file.response.message);

                let subEquipmentsList = await getSubEquipmentData(
                  this.props.deviceDetails.DeviceCode,
                  this.state.SubDeviceType
                );
                this.setState({ subEquipmentsList });
                this.getFileDownloadName(this.state.SubDeviceType);
              } else if (info.file.status === 'error') {
                message.error(info.file.response.message);
              }
            }}
            action={`/api/client/upload/device?SiteId=${siteId}&DeviceType=${
              this.props.parent.Code
            }&EquipmentType=${'Child'}&ParentEquipment=${
              this.props.selectedRow.Code
            }&SubDeviceType=${this.state.SubDeviceType}`}
            headers={{
              Authorization: Authorization
            }}
            showUploadList={false}
          >
            <UploadOutlined style={{ color: 'white', fontSize: '16px', marginRight: '1px' }} />
            <span style={{ color: 'white', marginRight: '10px' }}>
              {' '}
              {this.translation('Upload')}
            </span>
          </Upload>
          <Button type="primary" onClick={() => this.setState({ isModalOpen: true })}>
            Add SubEquipments
          </Button>
        </div>
        <StyledForm
          theme={themeSettings}
          layout="horizontal"
          labelCol={{ flex: '130px', marginTop: '20px' }}
          labelAlign="right"
          labelWrap
        >
          <Form.Item
            label={translation(translationCache, constant.SubEquipmentsType)}
            style={{ width: '50%' }}
            rules={[{ required: true, message: `Please select SubDeviceType!` }]}
          >
            <Select
              value={SubDeviceType ? SubDeviceType : null}
              onChange={(e) => this.onSubEqptypeChange(e, 'SubDeviceType')}
            >
              {subdeviceTypeList &&
                Array.isArray(subdeviceTypeList) &&
                subdeviceTypeList.map((devicetype, index) => {
                  return (
                    <Select.Option key={index} value={devicetype.AliasCode}>
                      {devicetype.Name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </StyledForm>
        <Table
          columns={columns}
          dataSource={
            subEquipmentsList && Array.isArray(subEquipmentsList) ? subEquipmentsList : []
          }
        />
        <Modal
          width={'90%'}
          title="Add/Edit Sub equiments"
          open={this.state.isModalOpen}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <StyledForm
            name="DeviceForm"
            ref={this.hRef}
            theme={theme}
            initialValues={formObject}
            layout="horizontal"
            labelCol={{ flex: '130px', marginTop: '20px' }}
            labelAlign="right"
            labelWrap
          >
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '15px' }}>
              <Form.Item name={'Id'}></Form.Item>
              <Form.Item
                label={translation(translationCache, constant.SubEquipmentsType)}
                style={{ width: '50%' }}
                rules={[{ required: false, message: `Please select SubDeviceType!` }]}
              >
                <Select
                  value={SubDeviceType}
                  disabled={true}
                  placeholder={constant.SubEquipmentsType}
                >
                  {subdeviceTypeList &&
                    Array.isArray(subdeviceTypeList) &&
                    subdeviceTypeList.map((devicetype, index) => {
                      return (
                        <Select.Option key={index} value={devicetype.Code}>
                          {devicetype.Name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name="Name"
                label={translation(translationCache, constant.Name)}
                style={{ width: '50%' }}
                rules={[{ required: true, message: `Please enter name!` }]}
              >
                <InputComponent
                  placeholder={`Input ${constant.Name}`}
                  value={
                    formObject && formObject.Name
                      ? translation(translationCache, formObject.Name)
                      : ''
                  }
                />
              </Form.Item>
              <Form.Item
                name="Description"
                label={translation(translationCache, constant.Description)}
                style={{ width: '50%' }}
                rules={[{ required: true, message: `Please enter Description!` }]}
              >
                <Input.TextArea
                  placeholder={`Input ${constant.Description}`}
                  value={
                    formObject && formObject.Description
                      ? translation(translationCache, formObject.Description)
                      : ''
                  }
                />
              </Form.Item>
              <Form.Item
                name="DisplayCode"
                label={translation(translationCache, constant.Code)}
                style={{ width: '50%' }}
                rules={[{ required: true, message: `Please enter code!` }]}
              >
                <InputComponent
                  placeholder={`Input ${constant.Code}`}
                  value={
                    formObject && formObject.DisplayCode
                      ? translation(translationCache, formObject.DisplayCode)
                      : ''
                  }
                />
              </Form.Item>

              {attributeList &&
                Array.isArray(attributeList) &&
                attributeList.map((attr, index) => {
                  let requiredField = attr && attr.mandatory === 'Optional' ? false : true;
                  return (
                    <Form.Item
                      key={index}
                      name={attr.Code}
                      label={translation(translationCache, attr.Name)}
                      style={{ width: '50%' }}
                      rules={[{ required: requiredField, message: `Please enter ${attr.Name}!` }]}
                    >
                      {this.renderWidget(attr, formObject)}
                    </Form.Item>
                  );
                })}
            </div>
          </StyledForm>
        </Modal>
      </Fragment>
    );
  }
}
export default SubEquipments;
