import React, { Fragment } from 'react';
import { Input, Select, Popover, Divider, TimePicker } from 'antd';

import {
  Boxdiv,
  StyledAppdiv,
  RuleTypeSelectionDiv,
  BlockedLabelBlack,
  RestConditionSelect,
  ActionSelectionDiv,
  AddParagraph,
  BlankDiv,
  TagNameSelect,
  OperaterSelect,
  ValueInput,
  ButtonBoxDiv,
  MarginRightButton,
  OnchangeCheckbox,
  LogicAddButton,
  ActionCheckbox,
  TextParagraph
} from '../style';
import { actionList } from '../utils/const';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

export default function DeviceRuleView(props) {
  const {
    inputList,
    conif,
    conelseif,
    conelse,
    elsifLength,
    elseList,
    urlList,
    ruleType,
    batchPriority,
    onChange,
    restApielseprevisible,
    restApielseroot,
    predicPreventiveVisible,
    predicRootCauseVis
  } = props.config;
  const {
    handleelseif,
    handleelse,
    handleDelay,
    checkonChange,
    handleTagSelect,
    handleInputChange,
    handleAddClick,
    handleRuleType,
    handleRadioButton,
    handleRemoveClick,
    handleVisibleChange,
    hanblePopoverOnclick,
    handleTextAreaChange,
    handleConditionchange
  } = props.Methods;

  return (
    <StyledAppdiv>
      <RuleTypeSelectionDiv>
        <div style={{ marginRight: '12%' }}>
          <BlockedLabelBlack>Type</BlockedLabelBlack>
          <Select
            placeholder="RuleType"
            style={{ width: '120px', display: 'inline-block' }}
            value={ruleType}
            onChange={(value) => handleRuleType(value, 'ruleType')}
            disabled
          ></Select>
        </div>
        {ruleType === 'batch' ? (
          <div style={{ marginRight: '12%' }}>
            <label style={{ display: 'block' }}>Priority</label>
            <Select
              placeholder="Priority"
              style={{ width: '120px', display: 'inline-block' }}
              value={batchPriority === '' ? undefined : batchPriority}
              onChange={(value) => handleRuleType(value, 'batchPriority')}
            >
              {props.config.priorityTypes.map((type, index) => (
                <Option key={index} value={type.PriorityType}>
                  {type.PriorityType}
                </Option>
              ))}
            </Select>
          </div>
        ) : null}
        <OnchangeCheckbox checked={onChange} onChange={(value) => handleRadioButton(value)}>
          OnChange
        </OnchangeCheckbox>
      </RuleTypeSelectionDiv>
      {conif
        ? inputList.map((x, i) => {
            return (
              <Boxdiv key={`if${i}`}>
                {i === 0 ? <BlockedLabelBlack>if</BlockedLabelBlack> : null}
                {i > 0 ? (
                  <RestConditionSelect
                    name="operater"
                    placeholder="Select Condition"
                    size="default"
                    value={x[['54765']] === '' ? undefined : x[['54765']]}
                    onChange={(e) => handleConditionchange(e, i, 'inputList')}
                  >
                    <Option value="&&">AND</Option>
                    <Option value="||">OR</Option>
                  </RestConditionSelect>
                ) : null}

                <TagNameSelect
                  showSearch
                  name="tagName"
                  size="default"
                  placeholder="Select Url"
                  value={x.tagName === '' ? undefined : x.tagName}
                  onChange={(e) => handleTagSelect(e, i, 'inputList')}
                >
                  {urlList.map((url, j) => {
                    return (
                      <Option key={`ifurl${i}${j}`} value={url.Url}>
                        {url.Url}
                      </Option>
                    );
                  })}
                </TagNameSelect>
                <OperaterSelect
                  name="operater"
                  size="default"
                  placeholder="Select operator"
                  value={x.operater === '' ? undefined : x.operater}
                  onChange={(e) => handleInputChange(e, i, 'select', 'inputList')}
                >
                  <Option value="==" title="EqualTo">
                    ==
                  </Option>
                  <Option value="!=" title="Not EqualTo">
                    !=
                  </Option>
                  <Option value=">" title="Greater Than">
                    {'>'}
                  </Option>
                  <Option value="<" title="Less Than">
                    {'<'}
                  </Option>
                  <Option value=">=" title="Greater Than Or EqualTo">
                    {'>='}
                  </Option>
                  <Option value="<=" title="Less Than Or Equal To">
                    {'<='}
                  </Option>
                </OperaterSelect>
                <ValueInput
                  size="default"
                  name="value"
                  placeholder="value"
                  value={x.value}
                  onChange={(e) => handleInputChange(e, i, 'input', 'inputList')}
                />
                <ButtonBoxDiv>
                  {inputList.length !== 1 && (
                    <MarginRightButton
                      size="default"
                      onClick={() => handleRemoveClick(i, 'inputList')}
                    >
                      Remove
                    </MarginRightButton>
                  )}
                  {inputList.length - 1 === i && (
                    <Fragment>
                      <LogicAddButton
                        type={'primary'}
                        size="default"
                        onClick={() => handleAddClick('inputList')}
                      >
                        Add
                      </LogicAddButton>
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={inputList[i]['preventive']}
                              rows={5}
                              maxLength="150"
                              onChange={(e) =>
                                handleTextAreaChange(e, i, 'inputList', 'preventive')
                              }
                            />
                            <span
                              style={{ color: '#1890ff', cursor: 'pointer' }}
                              onClick={() => {
                                hanblePopoverOnclick(
                                  i,
                                  'inputList',
                                  'close',
                                  'preventive',
                                  'predicPreventiveVisible'
                                );
                              }}
                            >
                              Cancel
                            </span>

                            <span
                              style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                              onClick={() => {
                                hanblePopoverOnclick(
                                  i,
                                  'inputList',
                                  'ok',
                                  'predicPreventiveVisible'
                                );
                              }}
                            >
                              Ok
                            </span>
                          </div>
                        }
                        visible={predicPreventiveVisible}
                        onVisibleChange={(value) =>
                          handleVisibleChange(value, 'predicPreventiveVisible')
                        }
                        title="Preventive Measures"
                        trigger="click"
                      >
                        <TextParagraph>Preventive Measures</TextParagraph>
                      </Popover>
                      <Divider type="vertical" />
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={inputList[i]['rootcause']}
                              rows={4}
                              onChange={(e) => handleTextAreaChange(e, i, 'inputList', 'rootcause')}
                            />
                            <span
                              style={{ color: '#1890ff', cursor: 'pointer' }}
                              onClick={() => {
                                hanblePopoverOnclick(
                                  i,
                                  'inputList',
                                  'close',
                                  'rootcause',
                                  'predicRootCauseVis'
                                );
                              }}
                            >
                              Cancel
                            </span>
                            <span
                              style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                              onClick={() => {
                                hanblePopoverOnclick(i, 'inputList', 'ok', 'predicRootCauseVis');
                              }}
                            >
                              Ok
                            </span>
                          </div>
                        }
                        visible={predicRootCauseVis}
                        onVisibleChange={(value) =>
                          handleVisibleChange(value, 'predicRootCauseVis')
                        }
                        title=" RootCause Analysis"
                        trigger="click"
                      >
                        <TextParagraph>RootCause Analysis</TextParagraph>
                      </Popover>
                    </Fragment>
                  )}
                </ButtonBoxDiv>
                {i === inputList.length - 1 ? (
                  <ActionSelectionDiv>
                    <BlockedLabelBlack>
                      Action<span style={{ color: 'red' }}>*</span>
                    </BlockedLabelBlack>
                    <ActionCheckbox
                      options={actionList}
                      value={props.config.inputList[i]['action']}
                      onChange={(checkedValues) => {
                        checkonChange(checkedValues, 'inputList', i);
                      }}
                    />
                    <TimePicker
                      defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                      value={
                        props.config.inputList[i]['delay'] === undefined
                          ? undefined
                          : moment(props.config.inputList[i]['delay'][1], 'HH:mm:ss')
                      }
                      onChange={(e) => handleDelay(e, 'inputList', i)}
                      placeholder="Select Delay"
                      allowClear={false}
                      size="default"
                    />
                  </ActionSelectionDiv>
                ) : null}
              </Boxdiv>
            );
          })
        : null}
      {conelseif
        ? elsifLength.map((elsepropName, index) => {
            return props.config[elsepropName].map((x, i) => {
              return (
                <Boxdiv key={`${index}${i}`}>
                  {i === 0 ? <BlockedLabelBlack>else if</BlockedLabelBlack> : null}
                  {i > 0 ? (
                    <RestConditionSelect
                      size="default"
                      name="operater"
                      value={x[['54765']] === '' ? undefined : x[['54765']]}
                      placeholder="Select condition"
                      onChange={(e) => handleConditionchange(e, i, elsepropName)}
                    >
                      <Option value="&&">AND</Option>
                      <Option value="||">OR</Option>
                    </RestConditionSelect>
                  ) : null}

                  <TagNameSelect
                    showSearch
                    name="tagName"
                    size="default"
                    placeholder="Select Url"
                    value={x.tagName === '' ? undefined : x.tagName}
                    onChange={(e) => handleTagSelect(e, i, elsepropName)}
                  >
                    {urlList.map((url, j) => {
                      return (
                        <Option key={`elseifurl${i}${j}`} value={url.Url}>
                          {url.Url}
                        </Option>
                      );
                    })}
                  </TagNameSelect>
                  <OperaterSelect
                    size="default"
                    value={x.operater === '' ? undefined : x.operater}
                    name="operater"
                    placeholder="Select operator"
                    onChange={(e) => handleInputChange(e, i, 'select', elsepropName)}
                  >
                    <Option value="==" title="EqualTo">
                      ==
                    </Option>
                    <Option value="!=" title="Not EqualTo">
                      !=
                    </Option>
                    <Option value=">" title="Greater Than">
                      {'>'}
                    </Option>
                    <Option value="<" title="Less Than">
                      {'<'}
                    </Option>
                    <Option value=">=" title="Greater Than Or EqualTo">
                      {'>='}
                    </Option>
                    <Option value="<=" title="Less Than Or Equal To">
                      {'<='}
                    </Option>
                  </OperaterSelect>
                  <ValueInput
                    name="value"
                    size="default"
                    placeholder="value"
                    value={x.value}
                    onChange={(e) => handleInputChange(e, i, 'input', elsepropName)}
                  />
                  <ButtonBoxDiv>
                    {props.config[elsepropName].length !== 1 && (
                      <MarginRightButton
                        size="default"
                        onClick={() => handleRemoveClick(i, elsepropName)}
                      >
                        Remove
                      </MarginRightButton>
                    )}
                    {props.config[elsepropName].length - 1 === i && (
                      <Fragment>
                        <LogicAddButton
                          type={'primary'}
                          size="default"
                          onClick={() => handleAddClick(elsepropName)}
                        >
                          Add
                        </LogicAddButton>
                        <Popover
                          content={
                            <div>
                              <TextArea
                                value={props.config[elsepropName][i]['preventive']}
                                rows={5}
                                maxLength="150"
                                onChange={(e) =>
                                  handleTextAreaChange(e, i, elsepropName, 'preventive')
                                }
                              />

                              <span
                                style={{ color: '#1890ff', cursor: 'pointer' }}
                                onClick={() => {
                                  hanblePopoverOnclick(
                                    i,
                                    elsepropName,
                                    'close',
                                    'preventive',
                                    `restApipreDvisible${elsepropName}`
                                  );
                                }}
                              >
                                Cancel
                              </span>

                              <span
                                style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                onClick={() => {
                                  hanblePopoverOnclick(
                                    i,
                                    elsepropName,
                                    'ok',
                                    `restApipreDvisible${elsepropName}`
                                  );
                                }}
                              >
                                Ok
                              </span>
                            </div>
                          }
                          visible={props.config[`restApipreDvisible${elsepropName}`]}
                          onVisibleChange={(value) =>
                            handleVisibleChange(value, `restApipreDvisible${elsepropName}`)
                          }
                          title="Preventive Measures"
                          trigger="click"
                        >
                          <TextParagraph>Preventive Measures</TextParagraph>
                        </Popover>
                        <Divider type="vertical" />
                        <Popover
                          content={
                            <div>
                              <TextArea
                                value={props.config[elsepropName][i]['rootcause']}
                                rows={5}
                                maxLength="150"
                                onChange={(e) =>
                                  handleTextAreaChange(e, i, elsepropName, 'rootcause')
                                }
                              />
                              <span
                                style={{ color: '#1890ff', cursor: 'pointer' }}
                                onClick={() => {
                                  hanblePopoverOnclick(
                                    i,
                                    elsepropName,
                                    'close',
                                    'rootcause',
                                    `restApielseifroot${elsepropName}`
                                  );
                                }}
                              >
                                Cancel
                              </span>
                              <span
                                style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                onClick={() => {
                                  hanblePopoverOnclick(
                                    i,
                                    elsepropName,
                                    'ok',
                                    `restApielseifroot${elsepropName}`
                                  );
                                }}
                              >
                                Ok
                              </span>
                            </div>
                          }
                          visible={props.config[`restApielseifroot${elsepropName}`]}
                          onVisibleChange={(value) =>
                            handleVisibleChange(value, `restApielseifroot${elsepropName}`)
                          }
                          title=" RootCause Analysis"
                          trigger="click"
                        >
                          <TextParagraph>RootCause Analysis</TextParagraph>
                        </Popover>
                      </Fragment>
                    )}
                  </ButtonBoxDiv>
                  {i === props.config[elsepropName].length - 1 ? (
                    <ActionSelectionDiv>
                      <BlockedLabelBlack>
                        Action<span style={{ color: 'red' }}>*</span>
                      </BlockedLabelBlack>
                      <ActionCheckbox
                        options={actionList}
                        value={props.config[elsepropName][i]['action']}
                        onChange={(checkedValues) => {
                          checkonChange(checkedValues, elsepropName, i);
                        }}
                      />
                      <TimePicker
                        defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                        value={
                          props.config[elsepropName][i]['delay'] === undefined
                            ? undefined
                            : moment(props.config[elsepropName][i]['delay'][1], 'HH:mm:ss')
                        }
                        onChange={(e) => handleDelay(e, elsepropName, i)}
                        placeholder="Select Delay"
                        allowClear={false}
                        size="default"
                      />
                    </ActionSelectionDiv>
                  ) : null}
                </Boxdiv>
              );
            });
          })
        : null}

      {conelse
        ? elseList.map((x, i) => {
            return (
              <Boxdiv key={`else${i}`}>
                {i === 0 ? <BlockedLabelBlack>else</BlockedLabelBlack> : null}
                {i > 0 ? (
                  <RestConditionSelect
                    name="operater"
                    size="default"
                    value={x[['54765']] === '' ? undefined : x[['54765']]}
                    placeholder="Select condition"
                    onChange={(e) => handleConditionchange(e, i, 'elseList')}
                  >
                    <Option value="&&">AND</Option>
                    <Option value="||">OR</Option>
                  </RestConditionSelect>
                ) : null}
                <TagNameSelect
                  showSearch
                  name="tagName"
                  size="default"
                  placeholder="Select Url"
                  value={x.tagName === '' ? undefined : x.tagName}
                  onChange={(e) => handleTagSelect(e, i, 'elseList')}
                >
                  {urlList.map((url, j) => {
                    return (
                      <Option key={`elseurl${i}${j}`} value={url.Url}>
                        {url.Url}
                      </Option>
                    );
                  })}
                </TagNameSelect>
                <OperaterSelect
                  name="operater"
                  size="default"
                  placeholder="Select operator"
                  value={x.operater === '' ? undefined : x.operater}
                  onChange={(e) => handleInputChange(e, i, 'select', 'elseList')}
                >
                  <Option value="==" title="EqualTo">
                    ==
                  </Option>
                  <Option value="!=" title="Not EqualTo">
                    !=
                  </Option>
                  <Option value=">" title="Greater Than">
                    {'>'}
                  </Option>
                  <Option value="<" title="Less Than">
                    {'<'}
                  </Option>
                  <Option value=">=" title="Greater Than Or EqualTo">
                    {'>='}
                  </Option>
                  <Option value="<=" title="Less Than Or Equal To">
                    {'<='}
                  </Option>
                </OperaterSelect>
                <ValueInput
                  size="default"
                  name="value"
                  placeholder="value"
                  value={x.value}
                  onChange={(e) => handleInputChange(e, i, 'input', 'elseList')}
                />
                <ButtonBoxDiv>
                  {elseList.length !== 1 && (
                    <MarginRightButton
                      size="default"
                      onClick={() => handleRemoveClick(i, 'elseList')}
                    >
                      Remove
                    </MarginRightButton>
                  )}
                  {elseList.length - 1 === i && (
                    <Fragment>
                      <LogicAddButton
                        type={'primary'}
                        size="default"
                        onClick={() => handleAddClick('elseList')}
                      >
                        Add
                      </LogicAddButton>
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={elseList[i]['preventive']}
                              rows={5}
                              maxLength="150"
                              onChange={(e) => handleTextAreaChange(e, i, 'elseList', 'preventive')}
                            />

                            <span
                              style={{ color: '#1890ff', cursor: 'pointer' }}
                              onClick={() => {
                                hanblePopoverOnclick(
                                  i,
                                  'elseList',
                                  'close',
                                  'preventive',
                                  `restApielseprevisible`
                                );
                              }}
                            >
                              Cancel
                            </span>

                            <span
                              style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                              onClick={() => {
                                hanblePopoverOnclick(i, 'elseList', 'ok', `restApielseprevisible`);
                              }}
                            >
                              Ok
                            </span>
                          </div>
                        }
                        visible={restApielseprevisible}
                        onVisibleChange={(value) =>
                          handleVisibleChange(value, `restApielseprevisible`)
                        }
                        title="Preventive Measures"
                        trigger="click"
                      >
                        <TextParagraph>Preventive Measures</TextParagraph>
                      </Popover>
                      <Divider type="vertical" />
                      <Popover
                        content={
                          <div>
                            <TextArea
                              value={elseList[i]['rootcause']}
                              rows={5}
                              maxLength="150"
                              onChange={(e) => handleTextAreaChange(e, i, 'elseList', 'rootcause')}
                            />

                            <span
                              style={{ color: '#1890ff', cursor: 'pointer' }}
                              onClick={() => {
                                hanblePopoverOnclick(
                                  i,
                                  'elseList',
                                  'close',
                                  'rootcause',
                                  `restApielseroot`
                                );
                              }}
                            >
                              Cancel
                            </span>
                            <span
                              style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                              onClick={() => {
                                hanblePopoverOnclick(i, 'elseList', 'ok', `restApielseroot`);
                              }}
                            >
                              Ok
                            </span>
                          </div>
                        }
                        visible={restApielseroot}
                        onVisibleChange={(value) => handleVisibleChange(value, `restApielseroot`)}
                        title=" RootCause Analysis"
                        trigger="click"
                      >
                        <TextParagraph>RootCause Analysis</TextParagraph>
                      </Popover>
                    </Fragment>
                  )}
                </ButtonBoxDiv>
                {i === elseList.length - 1 ? (
                  <ActionSelectionDiv>
                    <BlockedLabelBlack>
                      Action<span style={{ color: 'red' }}>*</span>
                    </BlockedLabelBlack>
                    <ActionCheckbox
                      options={actionList}
                      value={props.config.elseList[i]['action']}
                      onChange={(checkedValues) => {
                        checkonChange(checkedValues, 'elseList', i);
                      }}
                    />
                    <TimePicker
                      defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                      value={
                        props.config.elseList[i]['delay'] === undefined
                          ? undefined
                          : moment(props.config.elseList[i]['delay'][1], 'HH:mm:ss')
                      }
                      onChange={(e) => handleDelay(e, 'elseList', i)}
                      placeholder="Select Delay"
                      allowClear={false}
                      size="default"
                    />
                  </ActionSelectionDiv>
                ) : null}
              </Boxdiv>
            );
          })
        : null}
      <AddParagraph onClick={handleelse}>Add else</AddParagraph>
      <AddParagraph onClick={handleelseif}>Add else if</AddParagraph>
      <BlankDiv></BlankDiv>
    </StyledAppdiv>
  );
}
