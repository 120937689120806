import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { StyledHomeRouter } from './styles';
import {
  Tasks,
  TasksDetails,
  JobPlanList,
  JobPlanDetails,
  WorkOrderList,
  WorkOrderDetails,
  WorkOrderTaskDetails,
  PMList,
  PMform,
  CMList,
  CMDetails,
  EWOList,
  EWODetails,
  FieldDataMeasurementList,
  FieldDataMeasurementDetails,
  ItemDetails,
  ItemList,
  // InspectionList,
  InspectionDetails,
  WorkFlowList,
  WorkFlowDetails,
  MaterialList,
  MaterialDetails,
  ToolsDetails,
  ToolsList,
  ManPowerList,
  ManPowerDetails,
  FailureCodeList,
  FailureCodeDetails,
  EquipmentDetails,
  EquipmentList,
  SRList,
  SRDetails,
  InspectionMasterList,
  InspectionMasterDetails,
  InspectionMaster,
  EquipmentTagList,
  EquipmentTagDetails,
  InventoryList,
  InventoryDetails,
  EquipmentOverviewList,
  EquipmentOverviewDetails,
  EAMLandingPage,
  Location,
  ELogScheduleList,
  ELogScheduleDetails,
  InspectionList
} from '../pages';

export default class AMMRouter extends React.Component {
  dynamicRouteRender = (routes, index) => {
    return (
      routes &&
      Array.isArray(routes) &&
      routes.map((route) => {
        return <Route key={index} {...route} />;
      })
    );
  };

  render() {
    let routes = [
      { path: '/rubus/AMM/Tasks', component: Tasks },
      { path: '/rubus/AMM/TasksDetails/:id', component: TasksDetails },

      { path: '/rubus/AMM/JobPlanList', component: JobPlanList },
      { path: '/rubus/AMM/JobPlanDetails/:id', component: JobPlanDetails },

      { path: '/rubus/AMM/WorkOrderList', component: WorkOrderList },
      { path: '/rubus/AMM/WorkOrderDetails/:id', component: WorkOrderDetails },
      { path: '/rubus/AMM/WorkOrderTaskDetails/:id', component: WorkOrderTaskDetails },

      { path: '/rubus/AMM/PMList', component: PMList },
      { path: '/rubus/AMM/PreventiveMaintenanceDetails/:id', component: PMform },

      { path: '/rubus/AMM/CMList', component: CMList },
      { path: '/rubus/AMM/CMDetails/:id', component: CMDetails },

      { path: '/rubus/AMM/EWOList', component: EWOList },
      { path: '/rubus/AMM/EWODetails/:id', component: EWODetails },

      { path: '/rubus/AMM/FieldDataMeasurementList', component: FieldDataMeasurementList },
      {
        path: '/rubus/AMM/FieldDataMeasurementDetails/:id',
        component: FieldDataMeasurementDetails
      },

      { path: '/rubus/AMM/ItemList', component: ItemList },
      { path: '/rubus/AMM/ItemDetails/:id', component: ItemDetails },

      { path: '/rubus/AMM/MaterialList', component: MaterialList },
      { path: '/rubus/AMM/MaterialDetails/:id', component: MaterialDetails },

      { path: '/rubus/AMM/ToolsList', component: ToolsList },
      { path: '/rubus/AMM/ToolsDetails/:id', component: ToolsDetails },

      { path: '/rubus/AMM/ManPowerList', component: ManPowerList },
      { path: '/rubus/AMM/ManPowerDetails/:id', component: ManPowerDetails },

      { path: '/rubus/AMM/InspectionList', component: InspectionList },
      { path: '/rubus/AMM/InspectionDetails/:id', component: InspectionDetails },

      { path: '/rubus/AMM/FailureCodeList', component: FailureCodeList },
      { path: '/rubus/AMM/FailureCodeDetails/:id', component: FailureCodeDetails },

      { path: '/rubus/AMM/EquipmentList', component: EquipmentList },
      { path: '/rubus/AMM/EquipmentDetails/:id', component: EquipmentDetails },

      { path: '/rubus/AMM/WorkFlowList', component: WorkFlowList },
      { path: '/rubus/AMM/WorkFlowDetails/:id', component: WorkFlowDetails },

      { path: '/rubus/AMM/SRList', component: SRList },
      { path: '/rubus/AMM/SRDetails/:id', component: SRDetails },

      { path: '/rubus/AMM/InspectionMasterList', component: InspectionMasterList },
      { path: '/rubus/AMM/InspectionMasterDetails/:id', component: InspectionMasterDetails },
      { path: '/rubus/AMM/InspectionMaster', component: InspectionMaster },

      { path: '/rubus/AMM/EquipmentTagList', component: EquipmentTagList },
      { path: '/rubus/AMM/EquipmentTagDetails/:id', component: EquipmentTagDetails },

      { path: '/rubus/AMM/EquipmentList', component: EquipmentList },
      { path: '/rubus/AMM/EquipmentDetails/:id', component: EquipmentDetails },

      { path: '/rubus/AMM/InventoryList', component: InventoryList },
      { path: '/rubus/AMM/InventoryDetails/:id', component: InventoryDetails },

      { path: '/rubus/AMM/EquipmentOverviewList', component: EquipmentOverviewList },
      { path: '/rubus/AMM/EquipmentOverviewDetails/:id', component: EquipmentOverviewDetails },

      { path: '/rubus/AMM/LandingPage', component: EAMLandingPage },

      { path: '/rubus/AMM/ELogScheduleList', component: ELogScheduleList },
      { path: '/rubus/AMM/ELogScheduleDetails', component: ELogScheduleDetails },

      { path: '/rubus/AMM/Locations', component: Location }
    ];
    return (
      <StyledHomeRouter>
        <Switch>{this.dynamicRouteRender(routes)}</Switch>
      </StyledHomeRouter>
    );
  }
}
