import React, { Fragment } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tag } from 'antd';

import ReactEcharts from '../../Echarts/Echarts';
import { getTranslationCache } from '../../../../../selectors/language';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { getCurrentTimezone } from '../../../../../selectors/layout';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { trendLabelTranslation } from '../../../Utils/Adapter/translationAdapter';
import { arrayOfObjects } from '../../../Mock/MockJson';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';

class Area extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: this.getOption(),
      data: [],
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false,
      checked: false
    };
  }
  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }
    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props);
    }
  }

  getData = async (props) => {
    let theme = this.props.selectedThemeing;
    this.requestCheck = false;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    let json = [];
    let { graphprops } = this.props;
    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = arrayOfObjects;
      this.requestCheck = true;
    } else {
      if (props.graphprops.graph === 'rangeBar' && props.graphprops.currentDateData !== undefined) {
        json = props.graphprops.currentDateData;
      } else {
        if (
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'datePicker' ||
              graphprops.GraphLevelSelection === 'datePicker') &&
            this.props.selectionDate) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'monthPicker' ||
              graphprops.GraphLevelSelection === 'monthPicker') &&
            this.props.selectionMonth) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'weakPicker' ||
              graphprops.GraphLevelSelection === 'weakPicker') &&
            this.props.selectionWeek) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'shiftPicker' ||
              graphprops.GraphLevelSelection === 'shiftPicker') &&
            this.props.shift) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
              graphprops.GraphLevelSelection === 'dateRangePicker') &&
            this.props.selectionDateRange) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
              graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
            this.props.dayMonthYear) ||
          (graphprops && graphprops.dashboardType === 'dynamic') ||
          (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
          (graphprops &&
            graphprops.dashboardType === 'hierarchy' &&
            this.props.dropdownSelection) ||
          (graphprops && this.props.dashboardPayload) ||
          (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
        ) {
          json = await getChartDataWithSelection({
            graphprops,
            DeviceCode: this.props.DeviceCode,
            selectionDate: this.props.selectionDate,
            selectionMonth: this.props.selectionMonth,
            selectionWeek: this.props.selectionWeek,
            shift: this.props.shift,
            selectionDateRange: this.props.selectionDateRange,
            dayMonthYear: this.props.dayMonthYear,
            abortController: this.abortController,
            dropdownSelection: this.props.dropdownSelection,
            dashboardPayload: this.props.dashboardPayload,
            AssetCode: this.props.AssetCode,
            currentTimeZone: this.props.currentTimeZone
          });
          this.requestCheck = true;
        } else {
          json = await getChartDataWithoutSelection({
            graphprops,
            abortController: this.abortController,
            dropdownSelection: this.props.dropdownSelection,
            DeviceCode: this.props.DeviceCode,
            TimeZone: this.props.currentTimeZone
          });
          this.requestCheck = true;
        }
      }
    }

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      let dates = [];
      let values = [];
      let name = '';
      let tagnames = [];
      let seriesarray = [];
      let legendsdata = [];
      json &&
        Array.isArray(json) &&
        json.map((val) => {
          if (val.Description !== name) {
            if (tagnames.indexOf(val.Description) === -1) {
              tagnames.push(val.Description);
              name = val.Description;
              legendsdata.push({
                name: val.Description,
                icon: 'roundRect',
                textStyle: {
                  color: theme === 'lightTheme' ? 'black' : 'white',
                  fontSize: 16,
                  fontWeight: 'bold'
                }
              });
            }
          }
          return {};
        });

      let yAxisData = [];

      if (Array.isArray(this.props.graphprops.YAxis)) {
        yAxisData = [...this.props.graphprops.YAxis];
      }

      let yaxis = yAxisData.map((value, index) => {
        let axis = {};
        axis.splitLine = {
          show: true,
          lineStyle: {
            color: '#504c4c'
          },
          axisLabel: { fontSize: 15 }
        };
        axis.triggerEvent = true;
        axis.axisLine = {
          lineStyle: {
            color:
              this.props.graphprops.colors[index] !== undefined
                ? value && value.tags && value.tags.length > 1
                  ? 'white'
                  : this.props.graphprops.colors[index]
                : 'white'
          },
          axisLabel: { fontSize: 15 }
        };
        axis.max = value.max;
        axis.min = value.min;
        axis.offset = value.offset;
        axis.interval = value.interval;
        axis.position = value.position;
        axis.name = value.name;
        axis.tags = value.tags;
        return axis;
      });

      yaxis.unshift({
        splitLine: {
          show: true,
          lineStyle: {
            color: '#504c4c'
          }
        },
        triggerEvent: true,
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: { fontSize: 15 },
        tags: []
      });

      tagnames.map((tagval, tagindex) => {
        values = [];
        json.map((jsonval) => {
          if (tagnames[0] === jsonval.Description && tagindex === 0) {
            let date = {};

            date =
              graphprops && graphprops.type !== 'summary'
                ? moment
                    .utc(jsonval.DateTime)
                    .tz(this.props.currentTimeZone)
                    .format('YYYY-MM-DD HH:mm:ss')
                : moment.tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm:ss');

            dates.push(date);
          }
          if (tagval === jsonval.Description) {
            values.push(jsonval.Value);
          }
          return {};
        });
        if (this.props.graphprops.linetype === 'area') {
          if (yaxis.length > 1) {
            yaxis.map((axis, axisindex) => {
              if (axis.tags.indexOf(tagval) !== -1) {
                let linetags = props.graphprops ? props.graphprops.tags : [];
                if (linetags && linetags.length > 0) {
                  linetags &&
                    linetags.map(() => {
                      seriesarray.push({
                        name: legendsdata[tagindex]['name'],
                        data: values,
                        type: 'line',
                        axisLabel: { fontSize: 15 },
                        yAxisIndex: axisindex,
                        areaStyle: {},
                        smooth: true
                      });
                      return {};
                    });
                } else {
                  seriesarray.push({
                    type: 'line',
                    name: legendsdata[tagindex]['name'],
                    data: values,
                    axisLabel: { fontSize: 15 },
                    yAxisIndex: axisindex,
                    areaStyle: {},
                    smooth: true
                  });
                }
              }
              return {};
            });
          } else {
            let linetags = props.graphprops ? props.graphprops.tags : [];
            if (linetags && linetags.length > 0) {
              linetags &&
                linetags.map((tag) => {
                  seriesarray.push({
                    name: legendsdata[tagindex]['name'],
                    data: values,
                    type: legendsdata[tagindex]['name'] !== tag ? 'line' : 'bar',
                    axisLabel: { fontSize: 15 },
                    areaStyle: {},
                    smooth: true
                  });
                  return {};
                });
            } else {
              seriesarray.push({
                name: legendsdata[tagindex]['name'],
                data: values,
                type: 'line',
                axisLabel: { fontSize: 15 },
                areaStyle: {},
                smooth: true
              });
            }
          }
        } else {
          if (yaxis.length > 1) {
            yaxis.map((axis, axisindex) => {
              if (axis && axis.tags && axis.tags.indexOf(tagval) !== -1) {
                let linetags = props.graphprops ? props.graphprops.tags : [];
                if (linetags && linetags.length > 0) {
                  linetags &&
                    linetags.map((tag) => {
                      seriesarray.push({
                        name: legendsdata[tagindex]['name'],
                        data: values,
                        yAxisIndex: axisindex,
                        type: legendsdata[tagindex]['name'] !== tag ? 'line' : 'bar',
                        areaStyle: {},
                        axisLabel: { fontSize: 15 }
                      });
                      return {};
                    });
                } else {
                  seriesarray.push({
                    name: legendsdata[tagindex]['name'],
                    data: values,
                    yAxisIndex: axisindex,
                    type: 'line',
                    areaStyle: {},
                    axisLabel: { fontSize: 15 }
                  });
                }
              }
              return {};
            });
          } else {
            let linetags = props.graphprops ? props.graphprops.tags : [];
            if (linetags && linetags.length > 0) {
              linetags &&
                linetags.map((tag) => {
                  seriesarray.push({
                    name: legendsdata[tagindex]['name'],
                    data: values,
                    type: legendsdata[tagindex]['name'] !== tag ? 'line' : 'bar',
                    areaStyle: {},
                    axisLabel: { fontSize: 15 }
                  });
                  return {};
                });
            } else {
              seriesarray.push({
                name: legendsdata[tagindex]['name'],
                data: values,
                type: 'line',
                axisLabel: { fontSize: 15 },
                areaStyle: {}
              });
            }
          }
        }
        return {};
      });
      const option = cloneDeep(this.getOption());
      let series = await trendLabelTranslation(seriesarray, this.props.translationCache);
      if (
        this.props.graphprops &&
        this.props.graphprops.area &&
        this.props.graphprops.area.colors &&
        this.props.graphprops.area.colors.length > 0
      ) {
        option.color = [...this.props.graphprops.area.colors];
      }
      option.xAxis.data = [...dates];
      option.yAxis = [...yaxis];
      option.series = [...series];
      option.legend = [...legendsdata];
      this.setState({
        option: option,
        refreshDateTime: moment()
      });
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            option={this.state.option}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: '80%', width: '100%' }}
            className="react_for_echarts"
            theme="dark"
          />
        )}
      </Fragment>
    );
  }

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let option = {
      legend: {
        textStyle: { color: theme === 'lightTheme' ? 'black' : 'white' },
        data: [
          {
            name: 'test1',
            icon: 'roundRect'
          }
        ]
      },
      tooltip: {
        trigger: 'axis'
      },
      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            backgroundColor: theme === 'lightTheme' ? 'black' : 'black',
            title: 'download',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      xAxis: {
        type: 'category',
        data: this.props.graphprops,
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisTick: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },
        axisLabel: {
          color: theme === 'lightTheme' ? 'black' : 'white'
        }
      },
      yAxis: {
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        }
      },
      dataZoom: [
        {
          type: 'slider',
          show: true,
          start: 0,
          end: 100,

          backgroundColor: '#9a9191',
          textStyle: {
            color: 'white'
          }
        },
        {
          type: 'inside',
          start: 0,
          end: 100
        }
      ]
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(Area);
