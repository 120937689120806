import axios from 'axios';
import localStorage from '../../../utils/localStorage';

export async function saveLandingPageConfiguration(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${`/api/statusMapping/create`}?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };

  return axios(data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getStatusList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${`/api/statusMapping/get`}?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };

  return axios(data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function deleteStatusRecord(Code) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = {
    url: `${`/api/statusMapping/delete`}?SiteId=${siteId}&Code=${Code}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };

  return axios(data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
