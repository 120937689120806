import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { StyledHomeRouter } from './styles';
import {
  ModelRegistrations,
  ModelsWorkbench,
  ModelDetails,
  Model_Comparision,
  BookmarksList,
  MonitorDrift,
  DriftCalculationWorkbench,
  DataSourceMastersList,
  DataSourceMastersDetails,
  AlgorithmMasterList,
  AlgorithmMasterDetails,
  ServerRegistrationList,
  ServerRegistrationDetails
} from '../pages';
import Home from '../pages/Analytics/Analytics_V2/Home';
import UseCase from '../pages/Analytics/Analytics_V2/UseCase';
import TrainingJobs from '../pages/Analytics/Analytics_V2/TrainingJobs';
import Tasks from '../pages/Analytics/Analytics_V2/Tasks';

export default class AMMRouter extends React.Component {
  dynamicRouteRender = (routes, index) => {
    return (
      routes &&
      Array.isArray(routes) &&
      routes.map((route) => {
        return <Route key={index} {...route} />;
      })
    );
  };
  render() {
    let routes = [
      {
        path: '/rubus/RbsAnalytics/ModelRegistration',
        component: ModelRegistrations
      },
      {
        path: '/rubus/RbsAnalytics/ModelsWorkbench',
        component: ModelsWorkbench
      },
      { path: '/rubus/RbsAnalytics/RegisterModel', component: ModelDetails },
      { path: '/rubus/RbsAnalytics/ModelDetails', component: ModelDetails },
      {
        path: '/rubus/RbsAnalytics/ModelComparisionList',
        component: BookmarksList
      },
      {
        path: '/rubus/RbsAnalytics/ModelComparision/:Id',
        component: Model_Comparision
      },
      {
        path: '/rubus/RbsAnalytics/ModelComparision',
        component: Model_Comparision
      },
      {
        path: '/rubus/RbsAnalytics/DriftCalculationWorkbench',
        component: DriftCalculationWorkbench
      },
      {
        path: '/rubus/RbsAnalytics/MonitorDrift/:Id',
        component: MonitorDrift
      },
      {
        path: '/rubus/RbsAnalytics/MonitorDrift',
        component: MonitorDrift
      },
      {
        path: '/rubus/RbsAnalytics/DataSourceMastersList',
        component: DataSourceMastersList
      },
      {
        path: '/rubus/RbsAnalytics/DataSourceMastersDetails',
        component: DataSourceMastersDetails
      },
      {
        path: '/rubus/RbsAnalytics/DataSourceMastersDetails/:Id',
        component: DataSourceMastersDetails
      },
      {
        path: '/rubus/RbsAnalytics/AlgorithmMasterList',
        component: AlgorithmMasterList
      },
      {
        path: '/rubus/RbsAnalytics/AlgorithmMasterDetails',
        component: AlgorithmMasterDetails
      },
      {
        path: '/rubus/RbsAnalytics/AlgorithmMasterDetails/:Id',
        component: AlgorithmMasterDetails
      },
      {
        path: '/rubus/RbsAnalytics/ServerRegistrationList',
        component: ServerRegistrationList
      },
      {
        path: '/rubus/RbsAnalytics/ServerRegistrationDetails',
        component: ServerRegistrationDetails
      },
      {
        path: '/rubus/RbsAnalytics/ServerRegistrationDetails/:Id',
        component: ServerRegistrationDetails
      },
      {
        path: '/rubus/RubusAnalytics/home',
        component: Home
      },
      {
        path: '/rubus/RubusAnalytics/usecase',
        component: UseCase
      },
      {
        path: '/rubus/RubusAnalytics/usecase/:Id',
        component: UseCase
      },
      {
        path: '/rubus/RubusAnalytics/Tasks',
        component: Tasks
      },
      {
        path: '/rubus/RubusAnalytics/Tasks/:Id',
        component: Tasks
      },
      {
        path: '/rubus/RubusAnalytics/TrainningJob',
        component: TrainingJobs
      },
      {
        path: '/rubus/RubusAnalytics/TrainningJob/:Id',
        component: TrainingJobs
      }
    ];
    return (
      <StyledHomeRouter>
        <Switch>{this.dynamicRouteRender(routes)}</Switch>
      </StyledHomeRouter>
    );
  }
}
