import React, { Component } from 'react';
import { Input, Form, Select, Button, message } from 'antd';
import './reportConfig.css';
let { Option } = Select;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class TableConfigCustomization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editObj: {},
      activeKey: [],
      dragDisabled: false,
      tableProperties: props.tableProperties || [],
      tableMaster: []
    };
    this.columns = {};
  }
  userRef = React.createRef();
  componentDidMount() {
    // this._getTableMasters()
  }

  componentDidUpdate(prevprops) {
    if (this.props.tableProperties !== prevprops.tableProperties) {
      this.setState({
        tableProperties: this.props.tableProperties
      });
    }
  }

  onDragEnd = (result) => {
    const { tableProperties } = this.state;
    if (!result.destination) {
      return;
    }
    const columnsPropertiess = reorder(tableProperties, result.source, result.destination);
    this.setState({
      tableProperties: columnsPropertiess
    });
    this.props.jsonSaveObject(tableProperties, 'tableConfiguration');
  };

  handleFieldChange(value, colName) {
    const { tableProperties } = this.state;
    tableProperties[colName] = value;

    this.setState({
      tableProperties
    });

    this.props.jsonSaveObject(tableProperties, 'tableConfiguration');
  }

  handleChange(value, keyName, key) {
    const { tableProperties } = this.state;
    tableProperties[keyName][key] = value;

    this.setState({
      tableProperties
    });
    this.props.jsonSaveObject(tableProperties, 'tableConfiguration');
  }

  tableSave = (e) => {
    e.preventDefault();
    const { tableProperties, downloadExcel } = this.state;

    this.userRef.current.validateFields().then(async () => {
      let item = tableProperties.downloadExcelPayload;
      if (
        downloadExcel === true &&
        (item.PortName === '' || item.Name === '' || item.Consumption === '')
      ) {
        message.error('please fill downloadExcelPayload fields');
      } else {
        this.props.jsonSaveObject(tableProperties, 'tableConfiguration');
        message.success('TableConfiguration Validated Successfully');
        this.setState({
          activeKey: []
        });
      }
    });
  };

  callback = (key) => {
    if (key && key.length <= 1) {
      this.setState({
        activeKey: key
      });
    } else if (key.length > 1) {
      key = key[key.length - 1];
      this.setState({
        activeKey: [key]
      });
    }
  };

  render() {
    const { tableProperties } = this.state;
    let item = tableProperties;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      }
    };
    const evenRowColor = [
      '#f5222d',
      '#bfbfbf',
      '#1890ff',
      '#fadb14',
      '#a0d911',
      '#fa541c',
      '#eb2f96',
      '#13c2c2',
      '#000000',
      '#ffd8bf'
    ];
    const oddRowColor = [
      '#ff7875',
      '#f0f0f0',
      '##69c0ff',
      '#fff566',
      '#d3f261',
      '#ff9c6e',
      '#ffadd2',
      '##5cdbd3',
      '#f5f5f5',
      '#ffffb8'
    ];

    return (
      <div style={{ margin: '50px' }}>
        {tableProperties ? (
          <div>
            <Form
              {...formItemLayout}
              layout="vertical"
              ref={this.userRef}
              name="user_form"
              initialValues={item || {}}
              autoComplete="off"
            >
              <Form.Item
                label="Bordered"
                name="bordered"
                rules={[
                  {
                    required: false,
                    message: 'Please Provide bordered!'
                  }
                ]}
              >
                <Select
                  style={{ width: '100%', showArrow: true }}
                  onSelect={(value) => this.handleFieldChange(value, 'bordered')}
                >
                  <Option value="true">TRUE</Option>
                  <Option value="false">FALSE</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="HorizontalScroll :"
                name="horizontalScroll"
                rules={[{ required: true, message: 'Please Select Option!' }]}
              >
                <Select
                  value={item.horizontalScroll}
                  style={{ width: '100%' }}
                  onSelect={(value) => this.handleFieldChange(value, 'horizontalScroll')}
                >
                  <Option value="true">TRUE</Option>
                  <Option value="false">FALSE</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="TableSize :"
                name="tableSize"
                rules={[{ required: true, message: 'Please Provide TableSize!' }]}
              >
                <Input
                  value={item.tableSize}
                  style={{ width: '100%' }}
                  type="number"
                  onChange={(e) => this.handleFieldChange(e.target.value, 'tableSize')}
                />
              </Form.Item>
              <Form.Item
                label="NoResultMessage"
                name="noResultMessage"
                rules={[
                  {
                    required: true,
                    message: 'Please Provide noResultMessage!'
                  }
                ]}
              >
                <Select
                  style={{ width: '100%', showArrow: true }}
                  onSelect={(value) => this.handleFieldChange(value, 'noResultMessage')}
                >
                  <Option value="No Records">No Records</Option>
                  <Option value="No Data">No Data</Option>
                  <Option value="Data Not Found">Data Not Found</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="IsCursorPointer :"
                name="isCursorPointer"
                rules={[
                  {
                    required: true,
                    message: 'Please Provide noResultMessage!'
                  }
                ]}
              >
                <Select
                  style={{ width: '100%' }}
                  onSelect={(value) => this.handleFieldChange(value, 'isCursorPointer')}
                >
                  <Option value="true">TRUE</Option>
                  <Option value="false">FALSE</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="DownloadXLXS :"
                name="downloadXLXS"
                rules={[{ required: true, message: 'Please Select Option!' }]}
              >
                <Select
                  style={{ width: '100%' }}
                  onSelect={(value) => this.handleFieldChange(value, 'downloadXLXS')}
                >
                  <Option value="true">TRUE</Option>
                  <Option value="false">FALSE</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <div style={{ fontSize: '15px', fontWeight: 'bold' }}>{'Download Payload :'}</div>
                }
              >
                <Form.Item
                  label="PortName :"
                  //  name="portName"
                  rules={[
                    //item.downloadExcelPayload.portName
                    { required: true, message: 'Please Provide PortName!' }
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      this.handleChange(e.target.value, 'downloadExcelPayload', 'portName')
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Name :"
                  name="name"
                  rules={[{ required: true, message: 'Please Provide Name!' }]}
                >
                  <Input
                    onChange={(e) =>
                      this.handleChange(e.target.value, 'downloadExcelPayload', 'name')
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Consumption :"
                  name="consumption"
                  rules={[
                    {
                      required: true,
                      message: 'Please Provide Consumption!'
                    }
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      this.handleChange(e.target.value, 'downloadExcelPayload', 'consumption')
                    }
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={<div style={{ fontSize: '15px', fontWeight: 'bold' }}>{'Styles:'}</div>}
              >
                <Form.Item label="TextAlign :">
                  <Select
                    style={{ width: '100%', showArrow: true }}
                    defaultValue={item.style.textAlign}
                    onSelect={(value) => this.handleChange(value, 'style', 'textAlign')}
                  >
                    <Option value="left">Left</Option>
                    <Option value="center">Center</Option>
                    <Option value="right">Right</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="FontWeight :">
                  <Select
                    style={{ width: '100%', showArrow: true }}
                    defaultValue={item.style.fontWeight}
                    onSelect={(value) => this.handleChange(value, 'style', 'fontWeight')}
                  >
                    <Option value="normal">Normal</Option>
                    <Option value="bold">Bold</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="EvenRowColor :">
                  <Select
                    style={{ width: '100%', showArrow: true }}
                    placeholder="select or enter color"
                    onSelect={(value) => this.handleChange(value, 'style', 'evenRowColor')}
                  >
                    {evenRowColor.map((colorItem, index) => (
                      <Option
                        key={index}
                        style={{ color: evenRowColor[index], fontSize: '20px' }}
                        value={colorItem}
                      >
                        {colorItem}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="OddRowColor :">
                  <Select
                    style={{ width: '100%', showArrow: true }}
                    placeholder="select or enter color"
                    onSelect={(value) => this.handleChange(value, 'style', 'oddRowColor')}
                  >
                    {oddRowColor.map((colorItem, index) => (
                      <Option
                        key={index}
                        style={{ color: oddRowColor[index], fontSize: '20px' }}
                        value={colorItem}
                      >
                        {' '}
                        {colorItem}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form.Item>
            </Form>
            {/* <div className="configButton"> */}
            <Button
              type="primary"
              style={{ marginLeft: '60px' }}
              onClick={(e) => this.tableSave(e)}
            >
              ValidateJson
            </Button>
            {/* </div> */}
          </div>
        ) : null}
      </div>
    );
  }
}
export default TableConfigCustomization;
