import React from 'react';

import { getTranslationCache } from '../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import themeSettings from '../Utils/themeSettings.json';
import { StyledComponent, StyledTable } from '../CSS/style';
import { getList } from '../Utils/FormCalls';
// import { constant } from '../Utils/Constants';
import { MASTERDATASOURCE } from '../../../commons/api';
import { Button, Tooltip, Input } from 'antd';
// import translation from '../Utils/translation';
import history from '../../../commons/history';
import Highlighter from 'react-highlight-words';
import { EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';

const APIList = {
  getList: `${MASTERDATASOURCE.GET}`
};
class DataSourceMastersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      translationCache: props.translationCache || {}
    };
  }
  componentDidMount = async () => {
    let response = await getList(APIList.getList);
    this.setState({
      dataSource: response && Array.isArray(response) ? response : []
    });
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };
  viewRecord = (e, record) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `/rubus/RbsAnalytics/DataSourceMastersDetails/${record.Id}`,
      routes: record.Id,
      state: { viewTag: true }
    });
  };

  editRecord = (record) => {
    history.push({
      pathname: `/rubus/RbsAnalytics/DataSourceMastersDetails/${record.Id}`,
      state: { editTag: true }
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search') + this.translation(dataIndex)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: '' });
  };
  render() {
    const { dataSource } = this.state;
    const columns = [
      {
        title: this.translation('DataSource Name'),
        dataIndex: 'Name',
        key: 'Name',
        ...this.getColumnSearchProps('Name')
      },
      {
        title: this.translation('DataSource Code'),
        dataIndex: 'Code',
        key: 'Code',
        ...this.getColumnSearchProps('Code')
      },
      {
        title: this.translation('Type'),
        dataIndex: 'Type',
        key: 'Type',
        ...this.getColumnSearchProps('Type')
      },
      {
        title: this.translation('Sub Type'),
        dataIndex: 'SourceType',
        key: 'SourceType',
        ...this.getColumnSearchProps('SourceType')
      },
      {
        title: this.translation('Status'),
        dataIndex: 'Status',
        key: 'Status',
        ...this.getColumnSearchProps('Status')
      },
      {
        title: this.translation('Action'),
        width: '600',
        render: (text, record) => (
          <>
            <Tooltip title={this.translation('View')}>
              <Button
                type="button"
                onClick={(e) => {
                  this.viewRecord(e, record);
                }}
                style={{ marginRight: '10px' }}
                className="ant-btn"
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={this.translation('Edit')}>
              <Button
                style={{ marginRight: '5px' }}
                className="ant-btn"
                onClick={() => {
                  this.editRecord(record);
                }}
                size="middle"
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          </>
        )
      }
    ];
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '50px 30px', minHeight: window.innerHeight - 143 }}
        >
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Tooltip title={this.translation('Click here to add a new DataSource')}>
              <Button
                onClick={() => history.push('/rubus/RbsAnalytics/DataSourceMastersDetails')}
                type="primary"
                style={{
                  marginBottom: 8
                }}
                size="middle"
              >
                {this.translation('New')}
              </Button>
            </Tooltip>
          </div>
          <StyledTable
            theme={themeSettings}
            dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
            columns={columns}
          ></StyledTable>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(DataSourceMastersList);
