import React, { Fragment } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tag } from 'antd';

import ReactEcharts from '../../Echarts/Echarts';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { getTranslationCache } from '../../../../../selectors/language';
import { ErrorComponent, NoRecordComponent } from '../../Utils';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { windBarbMock } from '../../../Mock/MockJson';
import { getCurrentTimezone } from '../../../../../selectors/layout';
class WindBarb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {},
      data: [],
      colors: this.props.graphprops.colors,
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      errorInAPI: false,
      noDataInDB: false
    };
  }

  data = [];
  timeTicket = null;
  abortController = new window.AbortController();
  requestCheck = true;

  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props, this.props.selectedThemeing);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = 1 * 60000;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData(this.props, this.props.selectedThemeing);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.getData(props);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }
  render() {
    const { errorInAPI, noDataInDB, refreshDateTime } = this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <ReactEcharts
            option={this.state.option}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: '90%', width: '100%' }}
            className="react_for_echarts"
          />
        )}
      </Fragment>
    );
  }

  getData = async (props, theme) => {
    let { graphprops } = this.props;
    this.requestCheck = false;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    let json = [];

    if (graphprops && graphprops.enableMock && graphprops.enableMock === true) {
      json = windBarbMock;

      this.requestCheck = true;
    } else {
      if (props.graphprops.graph === '' && props.graphprops.currentDateData !== undefined) {
        json = props.graphprops.currentDateData;
        this.requestCheck = true;
        // this.setState({loading:false});
      } else {
        if (
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'datePicker' ||
              graphprops.GraphLevelSelection === 'datePicker') &&
            this.props.selectionDate) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'monthPicker' ||
              graphprops.GraphLevelSelection === 'monthPicker') &&
            this.props.selectionMonth) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'weakPicker' ||
              graphprops.GraphLevelSelection === 'weakPicker') &&
            this.props.selectionWeek) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'shiftPicker' ||
              graphprops.GraphLevelSelection === 'shiftPicker') &&
            this.props.shift) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
              graphprops.GraphLevelSelection === 'dateRangePicker') &&
            this.props.selectionDateRange) ||
          (graphprops &&
            (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
              graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
            this.props.dayMonthYear) ||
          (graphprops && graphprops.dashboardType === 'dynamic') ||
          (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
          (graphprops &&
            graphprops.dashboardType === 'hierarchy' &&
            this.props.dropdownSelection) ||
          (graphprops && this.props.dashboardPayload) ||
          (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
        ) {
          json = await getChartDataWithSelection({
            graphprops,
            DeviceCode: this.props.DeviceCode,
            selectionDate: this.props.selectionDate,
            selectionMonth: this.props.selectionMonth,
            selectionWeek: this.props.selectionWeek,
            shift: this.props.shift,
            selectionDateRange: this.props.selectionDateRange,
            dayMonthYear: this.props.dayMonthYear,
            abortController: this.abortController,
            dropdownSelection: this.props.dropdownSelection,
            dashboardPayload: this.props.dashboardPayload,
            AssetCode: this.props.AssetCode,
            selectionDateLive: this.props.selectionDateLive,
            currentTimeZone: this.props.currentTimeZone
          });
          this.requestCheck = true;
        } else {
          json = await await getChartDataWithoutSelection({
            graphprops,
            abortController: this.abortController,
            dropdownSelection: this.props.dropdownSelection,
            DeviceCode: this.props.DeviceCode,
            TimeZone: this.props.currentTimeZone
          });
          this.requestCheck = true;
        }
      }
    }
    // [{"gte":17,"color":"#18BF12"},{"gte":11,"lt":17,"color":"#f4e9a3"},{"lt":11,"color":"#D33C3E"}]

    if (
      (json && json.message) ||
      (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
    ) {
      if (json.message) {
        errorInAPI = true;
      } else if (json.length === 0) {
        noDataInDB = true;
      }
    } else {
      if (json && Array.isArray(json)) {
        let graphObject = this.props.graphprops[this.props.graphprops.graph]
          ? this.props.graphprops[this.props.graphprops.graph]
          : '';
        let { YAxis } = graphObject;
        let YAxisList = YAxis ? JSON.parse(YAxis) : [];

        // let dates = [];
        let values = [];
        let name = '';
        let tagnames = [];
        //   let seriesarray = [];
        var directionMap = {};

        let legendsdata = [];
        var arrowSize = 18;
        var dims = {
          time: 0,
          windSpeed: 1,
          R: 2,
          waveHeight: 3,
          weatherIcon: 2,
          minTemp: 3,
          maxTemp: 4
        };
        // eslint-disable-next-line no-inner-declarations
        function renderArrow(param, api) {
          var point = api.coord([api.value(dims.time), api.value(dims.windSpeed)]);

          return {
            type: 'path',
            shape: {
              pathData: 'M31 16l-15-15v9h-26v12h26v9z',
              x: -arrowSize / 2,
              y: -arrowSize / 2,
              width: arrowSize,
              height: arrowSize
            },
            rotation: directionMap[api.value(dims.R)],
            position: point,
            style: api.style({
              stroke: '#555',
              lineWidth: 1
            })
          };
        }

        let data = json.map((entry) => {
          return [entry.time, entry.windSpeed, entry.R, entry.waveHeight];
        });

        let wind = [
          'W',
          'WSW',
          'SW',
          'SSW',
          'S',
          'SSE',
          'SE',
          'ESE',
          'E',
          'ENE',
          'NE',
          'NNE',
          'N',
          'NNW',
          'NW',
          'WNW'
        ];
        wind.map((name, index) => {
          directionMap[name] = (Math.PI / 8) * index;
          return {};
        });

        json.map((val) => {
          if (val.tagname !== name) {
            if (tagnames.indexOf(val.tagname) === -1) {
              tagnames.push(val.tagname);
              name = val.tagname;
              legendsdata.push({
                name: val.tag_description,
                icon: 'roundRect',
                textStyle: {
                  color: theme === 'lightTheme' ? 'black' : 'white',
                  fontSize: 16,
                  fontWeight: 'bold'
                }
              });
            }
          }
          return {};
        });

        let yAxisData = [];

        if (Array.isArray(YAxisList)) {
          yAxisData = [...YAxisList];
        }

        let yaxis = yAxisData;

        tagnames.map((tagval, tagindex) => {
          values = [];

          json.map((jsonval) => {
            if (tagnames[0] === jsonval.tagname && tagindex === 0) {
              // dates.push(
              //   moment(jsonval.interval_alias)
              //     .utc()
              //     .format("DD/MM/YYYY ")
              // );
            }

            values.push(jsonval.windSpeed);
            return {};
          });
          return {};
        });
        let xAxis = {
          type: 'time',
          maxInterval: 3600 * 1000 * 24,
          splitLine: {
            lineStyle: {
              color: '#ddd'
            }
          },
          axisLine: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisTick: {
            lineStyle: {
              color: theme === 'lightTheme' ? 'black' : 'white'
            }
          },
          axisLabel: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        };
        let series = [
          {
            type: 'line',
            yAxisIndex: 1,
            showSymbol: false,
            hoverAnimation: false,
            symbolSize: 10,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(88,160,253,1)'
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(88,160,253,0.7)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(88,160,253,0)'
                  }
                ]
              }
            },
            lineStyle: {
              normal: {
                color: 'rgba(88,160,253,1)'
              }
            },
            itemStyle: {
              normal: {
                color: 'rgba(88,160,253,1)'
              }
            },
            encode: {
              x: dims.time,
              y: dims.waveHeight
            },
            data: data,
            z: 2
          },
          {
            type: 'custom',
            renderItem: renderArrow,
            encode: {
              x: dims.time,
              y: dims.windSpeed
            },
            data: data,
            z: 10
          },
          {
            type: 'line',
            symbol: 'none',
            encode: {
              x: dims.time,
              y: dims.windSpeed
            },
            lineStyle: {
              normal: {
                color: '#aaa',
                type: 'dotted'
              }
            },
            data: data,
            z: 1
          }
        ];

        const option = cloneDeep(this.getOption());

        option.xAxis = xAxis;
        option.yAxis = [...yaxis];
        option.series = series;
        this.setState({
          option: option,
          refreshDateTime: moment()
        });
      }
    }
    this.setState({
      errorInAPI,
      noDataInDB
    });
  };

  getOption = () => {
    let theme = this.props.selectedThemeing;
    let graphObject = this.props.graphprops[this.props.graphprops.graph]
      ? this.props.graphprops[this.props.graphprops.graph]
      : '';
    let { colors, VisualMap } = graphObject;
    let visualMapList = VisualMap ? JSON.parse(VisualMap) : [];
    let option = {
      title: {},
      color: colors,
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          return ['Wind Speed,Wind Direction,Tide Level' + params[0].value.join('</br>')];
        }
      },

      visualMap: {
        type: 'piecewise',
        orient: 'horizontal',
        left: 'center',
        top: 10,
        pieces: visualMapList,
        seriesIndex: 1,
        dimension: 1,

        textStyle: {
          fontWeight: 'bold',
          fontSize: 15,
          color: theme === 'lightTheme' ? 'black' : 'white'
        }
      },
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: 0,
          minSpan: 5
        },
        {
          type: 'slider',
          xAxisIndex: 0,
          minSpan: 5,
          height: 20,
          bottom: 50,
          handleIcon:
            'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '120%'
        }
      ],
      xAxis: {
        axisLine: {
          lineStyle: {
            color: theme === 'lightTheme' ? 'black' : 'white'
          }
        },

        splitLine: {
          lineStyle: {
            color: '#ddd'
          }
        }
      },
      yAxis: [],

      toolbox: {
        showTitle: false,
        itemSize: 25,
        feature: {
          saveAsImage: {
            backgroundColor: 'black',
            title: 'download',
            name: this.props.graphprops.title,
            iconStyle: {
              borderColor: '#8c8c8c',
              borderWidth: 2
            }
          }
        }
      },
      series: [
        {
          name: 'value',
          type: 'line',
          data: this.data.map(function (item) {
            return item.value;
          })
        }
      ]
    };
    return option;
  };
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(WindBarb);
