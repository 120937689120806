import React, { Fragment, Component } from 'react';

import { Empty } from 'antd';
import Icon from '@ant-design/icons';

class VideoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVideo: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.imageClick !== this.props.imageClick
    ) {
      this.getData();
    }
  }

  getData = async () => {
    let { graphprops } = this.props;
    this.setState({
      camera:
        graphprops && graphprops.liveStreaming && graphprops.liveStreaming.path
          ? graphprops.liveStreaming.path
          : ''
    });
  };

  changeVideoSettings = () => {
    let { showVideo } = this.state;
    this.setState({
      showVideo: !showVideo
    });
  };

  // HeartSvg = () => (
  //   <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
  //     <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
  //   </svg>
  // );
  HeartSvg = () => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="15em"
      height="15em"
      viewBox="0 0 1024 1024"
      fill="#434343"
    >
      <path d="M815.8,264.8h-612c-15,0-27.2,12-27.2,26.9V332h68l0.6,70h-68.6v68.6h68.6v68.6h-68.6v68.6h68.6v58.8h-68.6v41.7c0,14.9,12.2,26.9,27.2,26.9h612c15,0,27.2-12,27.2-26.9v-41.7h-68.6v-58.8H843v-68.6h-68.6v-68.6H843V402h-68.6l0.6-70h68v-40.3C843,276.8,830.8,264.8,815.8,264.8z M715.6,676.4H304v-343h411.6L715.6,676.4L715.6,676.4z M826.7,10H173.3C83.1,10,10,83.1,10,173.3v653.3C10,916.9,83.1,990,173.3,990h653.3c90.2,0,163.3-73.1,163.3-163.3V173.3C990,83.1,916.9,10,826.7,10z M911.6,790.9c0,61.3-59.5,120.7-120.7,120.7H199.3c-61.3,0-110.9-59.5-110.9-120.7V199.3c0-61.3,49.7-110.9,110.9-110.9h591.6c61.3,0,120.7,49.7,120.7,110.9V790.9z M435,399.2v201.6L605,500L435,399.2z" />
    </svg>
  );

  render() {
    let { camera, showVideo } = this.state;

    const HeartIcon = (props) => (
      <Icon
        component={this.HeartSvg}
        onClick={() => this.changeVideoSettings()}
        style={{ cursor: 'pointer' }}
        {...props}
      />
    );

    return (
      <Fragment>
        {showVideo ? (
          <iframe
            title="Camera"
            style={{ width: '100%', height: '90%', border: 'none' }}
            src={`${camera}`}
          ></iframe>
        ) : (
          <Empty
            image={false}
            imageStyle={{
              height: 120
            }}
            description={false}
          >
            <HeartIcon style={{ color: 'hotpink' }} />
          </Empty>
        )}
      </Fragment>
    );
  }
}

export default VideoComponent;
