import React, { Component } from 'react';
import { Button, Divider, Input, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { getManufacturerListDataAPI, insertMasterFromSelect } from '../APICalls';

class SelectWithAddComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.value !== this.props.value) {
      this.setState({
        value: this.props.value
      });
    }
  }

  componentDidMount() {
    this._getOptionList();
  }

  _getOptionList = async () => {
    let list = await getManufacturerListDataAPI();
    this.setState({ list });
  };

  render() {
    let { list } = this.state;
    return (
      <Select
        placeholder="Add/Select Manufacturer"
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: '8px 0'
              }}
            />
            <Space
              style={{
                padding: '0 8px 4px'
              }}
            >
              <Input
                placeholder="Add new Manufacturer"
                value={this.state.newvalue}
                onChange={(e) => this.setState({ newvalue: e.target.value })}
              />
              <Button
                type="text"
                icon={<PlusOutlined />}
                onClick={async () => {
                  await insertMasterFromSelect(this.state.newvalue);
                  this._getOptionList();
                  this.setState({ newvalue: '' });
                }}
              >
                Add
              </Button>
            </Space>
          </>
        )}
        onChange={(e) => this.props.onSelectChange(e)}
        value={
          this.props.formObject && this.props.Code ? this.props.formObject[this.props.Code] : ''
        }
        options={
          list &&
          Array.isArray(list) &&
          list.map((item) => ({
            label: item.Name,
            value: item.Name
          }))
        }
      />
    );
  }
}

export default SelectWithAddComponent;
