import React from 'react';
import { Select, message, Input, TreeSelect, Button, Form, Tabs, Tooltip, Table } from 'antd';
import axios from 'axios';

import { StyledDashboard } from './style';
import localStorage from '../../../utils/localStorage';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { GetDefaultHierarchy } from '../APICalls';
import Condition from './Condition';
import { deleteStatusRecord, getStatusList, saveLandingPageConfiguration } from './apicalls';

const { TreeNode } = TreeSelect;

const Operator = [
  {
    label: 'EqualTo (==)',
    value: '=='
  },
  {
    label: 'Not EqualTo (!=)',
    value: '!='
  },
  {
    label: 'Greater Than (>)',
    value: '>'
  },
  {
    label: 'Less Than (<)',
    value: '<'
  },
  {
    label: 'Greater Than Or EqualTo (>=)',
    value: '>='
  },
  {
    label: 'Less Than Or EqualTo (<=)',
    value: '<='
  }
];

export default class StatusConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'LIST',
      ifConditionList: [
        {
          L1: {
            Device: undefined,
            Parameter: undefined,
            Operator: undefined,
            Value: undefined,
            RowType: 'CONDITION'
          },
          L2: {
            RowType: 'RETURN',
            Value: undefined
          }
        }
      ],
      scripts: [
        {
          Script: '',
          Priority: 1
        }
      ],
      assetValue: undefined,
      assetType: '',
      datasource: []
    };
  }

  componentDidMount() {
    this.getAssetList();
    this.getList();
  }

  getAssetList = async () => {
    let module = 'RS';
    let defaultHiearchyCode = await GetDefaultHierarchy();
    let HierarchyCode =
      defaultHiearchyCode && defaultHiearchyCode[0] && defaultHiearchyCode[0].HierarchyCode
        ? defaultHiearchyCode[0].HierarchyCode
        : '';
    let assetList = await this.getAssetListForStatusConfiguration(HierarchyCode, module);
    this.setState({ assetList, HierarchyCode });
  };

  getAssetListForStatusConfiguration = (HierarchyCode, module) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'GET',
      url: `/api/hierarchymodule/getHierarchyLevels?SiteId=${siteId}&HierarchyCode=${HierarchyCode}&LevelType=Device&Type=LevelWiseHierarchy&Module=${module}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(Object)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err.response.status === 400) {
          message.info(err.response.data.message);
        }
      });
  };

  generateScript = (ifCondition) => {
    let condition =
      ifCondition &&
      Object.keys(ifCondition) &&
      Object.keys(ifCondition).reduce((total, rowKey) => {
        let row = ifCondition[rowKey];
        if (row['RowType'] === 'CONDITION') {
          return total.concat(`(data["${row.Parameter}"]${row.Operator}${row.Value})`);
        } else if (row['RowType'] === 'OPERATOR') {
          return total.concat(row.Value);
        } else {
          return total;
        }
      }, '');

    let returnCondition = ifCondition ? ifCondition[Object.keys(ifCondition).pop()] : {};
    let script = `if(` + condition + `){ return "` + returnCondition.Value + `"}`;
    return script;
  };

  getList = async () => {
    let datasource = await getStatusList();
    this.setState({ datasource });
  };

  renderAssetTreeNodes = (data) =>
    data &&
    Array.isArray(data) &&
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            value={item.Code}
            title={item.Name}
            key={item.Code}
            dataRef={item}
            type={item.Type}
          >
            {this.renderAssetTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode value={item.Code} key={item.Code} title={item.Name} type={item.Type} {...item} />
      );
    });

  onSelectChange = (value, rowKey, ifIndex, key) => {
    let { ifConditionList } = this.state;
    ifConditionList[ifIndex] = {
      ...ifConditionList[ifIndex],
      [rowKey]: {
        ...ifConditionList[ifIndex][rowKey],
        [key]: value
      }
    };
    this.setState({ ifConditionList });
  };
  deleteCondition = (ifIndex, index) => {
    let { ifConditionList } = this.state;
    let ifCondition = ifConditionList[ifIndex];
    // let final = ifCondition[`L${index + 1}`];
    delete ifCondition[`L${index}`];
    delete ifCondition[`L${index + 1}`];
    ifConditionList[ifIndex] = ifCondition;
    this.setState({ ifConditionList });
  };
  addNewCondition = (ifIndex) => {
    let { ifConditionList } = this.state;
    let ifCondition = ifConditionList[ifIndex];
    let lastCondition = ifCondition ? ifCondition[Object.keys(ifCondition).pop()] : {};
    let rowKeysList = Object.keys(ifCondition);
    delete ifCondition[rowKeysList[rowKeysList.length - 1]];
    ifCondition = {
      ...ifCondition,
      [`L${Object.keys(ifCondition).length + 1}`]: {
        RowType: 'OPERATOR',
        Value: '&&'
      },
      [`L${Object.keys(ifCondition).length + 2}`]: {
        Device: undefined,
        Parameter: undefined,
        Operator: undefined,
        Value: undefined,
        RowType: 'CONDITION'
      },
      [`L${Object.keys(ifCondition).length + 3}`]: lastCondition
    };
    ifConditionList[ifIndex] = ifCondition;

    this.setState({ ifConditionList });
  };
  addNewIfCodition = () => {
    let { ifConditionList, scripts } = this.state;
    ifConditionList = [
      ...ifConditionList,
      {
        L1: {
          Device: undefined,
          Parameter: undefined,
          Operator: undefined,
          Value: undefined,
          RowType: 'CONDITION'
        },
        L2: {
          RowType: 'RETURN',
          Value: undefined
        }
      }
    ];

    scripts = [
      ...scripts,
      {
        Script: '',
        Priority: scripts.length + 1
      }
    ];
    this.setState({ ifConditionList, scripts });
  };

  deleteIfCondition = (ifIndex) => {
    let { ifConditionList, scripts } = this.state;
    ifConditionList.splice(ifIndex, 1);
    scripts.splice(ifIndex, 1);
    this.setState({ ifConditionList, scripts });
  };

  findCondition = (ifConditionList) => {
    let status = '';
    ifConditionList &&
      ifConditionList.map((ifCondition) => {
        let t3 = Object.keys(ifCondition).map((cond) => {
          let test = ifCondition[cond];
          if (
            test &&
            test.RowType === 'CONDITION' &&
            test.Value === undefined &&
            test.Device === undefined &&
            test.Parameter === undefined
          ) {
            return false;
          } else if (test && test.RowType === 'RETURN' && !test.Value) {
            return false;
          } else if (
            (test && test.RowType === 'RETURN' && test.Value === undefined) ||
            !test.Value
          ) {
            return false;
          } else {
            return true;
          }
        });
        status = t3.includes(false);
      });
    if (status === true) return false;
    else return true;
  };
  saveCondition = async () => {
    let { assetValue, assetType, ifConditionList, scripts } = this.state;
    let result = this.findCondition(ifConditionList);
    if (result === true) {
      let ifScriptList =
        ifConditionList &&
        ifConditionList.map((ifCondition, index) => {
          return {
            Script: this.generateScript(ifCondition),
            Priority: scripts[index].Priority
          };
        });
      const siteId = localStorage.get('currentSite');
      let payload = {
        Code: assetValue,
        Type: assetType,
        JSON: ifConditionList,
        Scripts: ifScriptList,
        SiteId: siteId
      };
      this.setState({ scripts: ifScriptList });
      let saveResp = await saveLandingPageConfiguration(payload);
      if (saveResp && saveResp.message) {
        message.success(saveResp.message);
        this.setState({ activeKey: 'LIST' });
        this.getList();
      }
    } else if (result === false) {
      message.error('please enter data');
    }
  };

  editRecord = (record) => {
    let { JSON, Code, Type, Scripts } = record;
    this.setState({
      ifConditionList: JSON,
      assetValue: Code,
      assetType: Type,
      scripts: Scripts,
      activeKey: 'DETAILS',
      editObject: record
    });
  };

  deleteRecord = async (record) => {
    let deleteResponse = await deleteStatusRecord(record.Code);
    if (deleteResponse && deleteResponse.message) {
      message.success(deleteResponse.message);
      this.getList();
    }
  };

  onPriorityChange = (value, ifIndex) => {
    let { scripts } = this.state;
    scripts[ifIndex].Priority = value;
    this.setState({ scripts });
  };
  render() {
    let {
      ifConditionList,
      assetList,
      assetValue,
      assetType,
      activeKey,
      datasource,
      scripts,
      HierarchyCode
    } = this.state;
    let columns = [
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name'
      },
      {
        title: 'Code',
        dataIndex: 'Code',
        key: 'Code'
      },
      {
        title: 'Actions',
        render: (text, record) => (
          <>
            <Button
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => this.editRecord(record)}
              size="middle"
            >
              {' '}
              <Tooltip title={'Edit'}>
                <EditOutlined />
              </Tooltip>
            </Button>
            <Button
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => this.deleteRecord(record)}
              size="middle"
            >
              {' '}
              <Tooltip title={'Delete'}>
                <DeleteOutlined />
              </Tooltip>
            </Button>
          </>
        )
      }
    ];
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 69 }}>
        <Tabs activeKey={activeKey} onChange={(activeKey) => this.setState({ activeKey })}>
          <Tabs.TabPane tab="List View" key="LIST">
            <Table
              style={{ marginTop: '10px' }}
              columns={columns}
              dataSource={datasource}
              pagination={
                datasource.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : false
              }
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Details View" key="DETAILS">
            <div style={{ marginTop: '10px' }}>
              <Form.Item>
                <TreeSelect
                  showSearch
                  style={{ width: 350 }}
                  value={assetValue}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder={'Select Asset'}
                  allowClear={false}
                  treeDefaultExpandAll
                  onSelect={(assetValue, node) => {
                    this.setState({ assetValue, assetType: node.type });
                  }}
                >
                  {this.renderAssetTreeNodes(assetList)}
                </TreeSelect>
              </Form.Item>

              {ifConditionList &&
                ifConditionList.map((ifCondition, ifIndex) => {
                  return (
                    <div
                      key={ifIndex}
                      style={{ padding: '10px', background: '#2d4f71', marginBottom: '10px' }}
                    >
                      {
                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                          <Button
                            type="danger"
                            icon={<DeleteOutlined />}
                            onClick={() => this.deleteIfCondition(ifIndex)}
                          />
                          <Input
                            style={{ width: '5%', marginRight: '5px' }}
                            value={scripts[ifIndex].Priority}
                            onChange={(e) => this.onPriorityChange(e.target.value, ifIndex)}
                          ></Input>
                        </div>
                      }

                      {ifCondition &&
                        Object.keys(ifCondition) &&
                        Object.keys(ifCondition).map((rowKey, index) => {
                          let ifCond = ifCondition[rowKey];
                          if (ifCond.RowType === 'CONDITION') {
                            return (
                              <>
                                <Condition
                                  index={index}
                                  rowKey={rowKey}
                                  ifCond={ifCond}
                                  ifIndex={ifIndex}
                                  Operator={Operator}
                                  assetValue={assetValue}
                                  assetType={assetType}
                                  HierarchyCode={HierarchyCode}
                                  ifConditionList={ifConditionList}
                                  ifCondition={ifCondition}
                                  onSelectChange={(value, rowKey, ifIndex, key) =>
                                    this.onSelectChange(value, rowKey, ifIndex, key)
                                  }
                                  deleteCondition={(ifIndex) =>
                                    this.deleteCondition(ifIndex, index)
                                  }
                                  addNewCondition={(ifIndex) => this.addNewCondition(ifIndex)}
                                />
                              </>
                            );
                          } else if (ifCond.RowType === 'OPERATOR') {
                            return (
                              <div style={{ margin: '10px' }} key={index}>
                                <Select
                                  defaultValue="AND"
                                  placeholder={'Select operator'}
                                  style={{ width: 170 }}
                                  value={ifCond.Value}
                                  onChange={(value) =>
                                    this.onSelectChange(value, rowKey, ifIndex, 'Value')
                                  }
                                  options={[
                                    {
                                      value: '&&',
                                      label: 'AND'
                                    },
                                    {
                                      value: '||',
                                      label: 'OR'
                                    }
                                  ]}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div style={{ margin: '-7px 10px 10px 10px' }} key={index}>
                                <div style={{ color: 'white', marginBottom: '3px' }}>THEN</div>
                                <Input
                                  style={{ width: 170 }}
                                  value={ifCond.Value}
                                  placeholder="Input return value"
                                  onChange={(e) =>
                                    this.onSelectChange(e.target.value, rowKey, ifIndex, 'Value')
                                  }
                                />
                              </div>
                            );
                          }
                        })}
                      {ifConditionList.length - 1 === ifIndex ? (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            style={{
                              background: '#2d4f71',
                              borderColor: '#2d4f71',
                              color: 'white'
                            }}
                            onClick={this.addNewIfCodition}
                          >
                            {'Add another if condition'}
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  );
                })}

              <Button onClick={this.saveCondition}>Save</Button>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </StyledDashboard>
    );
  }
}
