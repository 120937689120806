import React from 'react';
import { Route } from 'react-router';

import PartsMasters from '../pages/EMM/EquipmentMastersComponents/PartsMasters';
import SupplierDetails from '../pages/EMM/EquipmentMastersComponents/SupplierDetails';
import StatusMaster from '../pages/EMM/EquipmentMastersComponents/StatusMaster';
import Specifications from '../pages/EMM/EquipmentMastersComponents/Specifications';
import EquipmentHierarchy from '../pages/EMM/EquipmentSelection/CreateSpecifications';
import EquipmentParts from '../pages/EMM/EquipmentSelection/EquipmentParts';
import EquipmentEvents from '../pages/EMM/EquipmentSelection/EventsManagement';
import EquipmentOverview from '../pages/EMM/EquipmentSelection/EquipmentOverview';
import FileUpload from '../pages/EMM/EquipmentMastersComponents/UploadFile';
export default class EquipmentMasterRoute extends React.Component {
  render() {
    return (
      <div>
        <Route path="/rubus/EquipmentMaster/Parts" component={PartsMasters} />
        <Route path="/rubus/EquipmentMaster/SupplierDetails" component={SupplierDetails} />
        <Route path="/rubus/EquipmentMaster/StatusMaster" component={StatusMaster} />
        <Route path="/rubus/EquipmentMaster/Specifications" component={Specifications} />
        <Route path="/rubus/EquipmentMaster/FileUpload" component={FileUpload} />

        <Route path="/rubus/EMM/Specifications" component={EquipmentHierarchy} />
        <Route path="/rubus/EMM/Parts" component={EquipmentParts} />
        <Route path="/rubus/EMM/Events" component={EquipmentEvents} />
        <Route path="/rubus/EMM/EquipmentOverview" component={EquipmentOverview} />
      </div>
    );
  }
}
