import React, { Component } from 'react';
import { Form, Button, Select, Table, Input, Upload, message } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { getTranslationCache } from '../../../../../selectors/language';

import ReactEcharts from '../Echarts/Echarts';

const { TabPane } = Tabs;

class Measurement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: {
        xAxis: {
          type: 'category',
          data: [0.0, 0.15, 0.3, 0.45, 0.6, 0.75, 1.0]
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [2000, 3000, 4000, 5000, 6000, 7000],
            type: 'line'
          }
        ]
      },
      selectedTab: 'CM',
      translationCache: props.translationCache || []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;

    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  onChange = () => {};

  render() {
    const { selectedTab } = this.state;
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    };
    const buttonItemLayout = {
      wrapperCol: {
        span: 14,
        offset: 4
      }
    };
    const columns = [
      {
        title: this.translation('No'),
        dataIndex: 'No',
        key: 'No'
      },
      {
        title: this.translation('Sample Name'),
        dataIndex: 'SampleName',
        key: 'SampleName'
      },
      {
        title: this.translation('Date'),
        dataIndex: 'Date',
        key: 'Date'
      },
      {
        title: this.translation('CH'),
        dataIndex: 'ch',
        key: 'ch'
      },
      {
        title: this.translation('Channel Name'),
        dataIndex: 'Channel Name',
        key: 'Channel Name'
      },
      {
        title: this.translation('Measured Value'),
        dataIndex: 'DV',
        key: 'DV'
      }
    ];
    const dataSource = [
      {
        key: '1',
        name: this.translation('Mike'),
        age: 32,
        address: this.translation('10 Downing Street')
      },
      {
        key: '2',
        name: this.translation('John'),
        age: 42,
        address: this.translation('10 Downing Street')
      }
    ];
    let UploadProps = {
      name: 'file',
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      headers: {
        authorization: 'authorization-text'
      },
      onChange(info) {
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    };
    return (
      <div style={{}}>
        <Tabs
          defaultActiveKey={selectedTab}
          onChange={(value) => this.setState({ selectedTab: value })}
          type="card"
        >
          <TabPane tab={this.translation('Continuous Measurement')} key={'CM'}>
            <Form
              {...formItemLayout}
              layout="horizontal"
              name={this.translation('CM')}
              style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
            >
              <Form.Item label={this.translation('HD')}>
                <Select style={{ width: 220 }}>
                  <Select.Option value="01">01</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label={this.translation('CH')}>
                <Select style={{ width: 220 }}>
                  <Select.Option value="01">01</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item {...buttonItemLayout}>
                <Button type="primary" style={{ margin: '10px' }}>
                  {this.translation('Cut Off')}
                </Button>
              </Form.Item>

              <Form.Item label={this.translation('Recording Cycle')}>
                <Select style={{ width: 220 }}>
                  <Select.Option value="10">10</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label={this.translation('Automatic file')}>
                <Select style={{ width: 220 }}>
                  <Select.Option value="1">1</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label={this.translation('Interval statistics')}>
                <Select style={{ width: 220 }}>
                  <Select.Option value="1">1</Select.Option>
                </Select>
              </Form.Item>
            </Form>

            <div>
              <Button style={{ margin: '19px' }} type="primary">
                {this.translation('Indication of the result of measurement file')}
              </Button>
              <Button style={{ margin: '19px' }} type="primary">
                {this.translation('Setting of the result of measurement file')}
              </Button>
            </div>

            <ReactEcharts
              option={this.state.option}
              notMerge={true}
              lazyUpdate={true}
              style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
              className="react_for_echarts"
            />
          </TabPane>
          <TabPane tab={this.translation('Single Measurement')} key={'SM'}>
            <div style={{}}>
              <Form
                {...formItemLayout}
                layout="horizontal"
                name={this.translation('SM')}
                style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
              >
                <Form.Item label={this.translation('HD')}>
                  <Select style={{ width: 220 }}>
                    <Select.Option value="01">01</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label={this.translation('CH')}>
                  <Select style={{ width: 220 }}>
                    <Select.Option value="01">01</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label={this.translation('CutChip')}>
                  <div>24.1</div>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                  <Button type="primary">{this.translation('Measurement')}</Button>
                </Form.Item>
              </Form>
              <div
                style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
              >
                <label style={{ fontSize: '18px', fontWeight: 'bold' }}>
                  {this.translation('Measurement Log')}
                </label>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  // scroll={{ x: 2000, y: 500 }}
                  bordered
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab={this.translation('Batch Measurement')} key={'BM'}>
            <div style={{}}>
              <Form
                {...formItemLayout}
                layout="horizontal"
                name="BM"
                style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
              >
                <Form.Item label={this.translation('HD')}>
                  <Select style={{ width: 220 }}>
                    <Select.Option value="01">01</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label={this.translation('CH')}>
                  <Select style={{ width: 220 }}>
                    <Select.Option value="01">01</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item label={this.translation('Recording')}>
                  <Select style={{ width: 220 }}>
                    <Select.Option value="1">1</Select.Option>
                  </Select>
                  {this.translation('Sec')}
                </Form.Item>
                <Form.Item label={this.translation('Recording Time')}>
                  <Input style={{ width: 220 }}></Input>
                  {this.translation('Minute')}
                </Form.Item>
                <Form.Item label={this.translation('Folder Settings')}>
                  <Upload {...UploadProps}>
                    <Button icon={<UploadOutlined />}>{this.translation('Click to Upload')}</Button>
                  </Upload>
                </Form.Item>
                <Form.Item label={this.translation('Recording file')}>
                  <Button type="dashed">{this.translation('Click to record')}</Button>
                </Form.Item>
              </Form>

              <div
                style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
              >
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  // scroll={{ x: 2000, y: 500 }}
                  bordered
                />

                <ReactEcharts
                  option={this.state.option}
                  notMerge={true}
                  lazyUpdate={true}
                  className="react_for_echarts"
                />
                <ReactEcharts
                  option={this.state.option}
                  notMerge={true}
                  lazyUpdate={true}
                  className="react_for_echarts"
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab={this.translation('Channel Select')} key={'CS'}>
            <Form
              {...formItemLayout}
              layout="horizontal"
              name={this.translation('HeaderNumber_current')}
              style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
            >
              <Form.Item label="HD">
                <Select style={{ width: 220 }}>
                  <Select.Option value="01">01</Select.Option>
                </Select>
              </Form.Item>
            </Form>
            <Form
              {...formItemLayout}
              layout="horizontal"
              name={this.translation('HeaderNumber_change')}
              style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
            >
              <label style={{ fontSize: '18px', fontWeight: 'bolder' }}>
                {this.translation('Current Measurement Channel')}
              </label>
              <Form.Item label="CH">
                <Select style={{ width: 220 }}>
                  <Select.Option value="01">01</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label={this.translation('Channel Name')}>
                <div>{this.translation('CutChip')}</div>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(Measurement);
