import React, { Component } from 'react';
import { Form, Select, Button, Tabs, Row, Col, Table, Radio } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import ReactEcharts from '../Echarts/Echarts';
import { getTranslationCache } from '../../../../../selectors/language';
const { TabPane } = Tabs;

class Correction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      translationCache: props.translationCache || [],
      option: {
        xAxis: {
          type: 'category',
          data: [0.0, 0.15, 0.3, 0.45, 0.6, 0.75, 1.0]
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [2000, 3000, 4000, 5000, 6000, 7000],
            type: 'line'
          }
        ]
      }
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  translation = (keyword) => {
    let { translationCache } = this.props;

    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    };
    const columns = [
      {
        title: this.translation('Sample Name'),
        dataIndex: 'SampleName',
        key: 'SampleName'
      },
      {
        title: this.translation('Date'),
        dataIndex: 'Date',
        key: 'Date'
      },
      {
        title: this.translation('CH'),
        dataIndex: 'ch',
        key: 'ch'
      },
      {
        title: this.translation('Target Value'),
        dataIndex: 'Target Value',
        key: 'Target Value'
      },
      {
        title: this.translation('Disp .Value'),
        dataIndex: 'DV',
        key: 'DV'
      }
    ];
    const dataSource = [
      {
        key: '1',
        name: this.translation('Mike'),
        age: 32,
        address: this.translation('10 Downing Street')
      },
      {
        key: '2',
        name: this.translation('John'),
        age: 42,
        address: this.translation('10 Downing Street')
      }
    ];

    return (
      <div>
        <Form
          {...formItemLayout}
          layout="horizontal"
          name={this.translation('HeaderNumber_current')}
          style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
        >
          <Form.Item label={this.translation('HD')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="01">01</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={this.translation('CH')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="01">01</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={this.translation('Current Offset')}>
            <div>0.000000</div>
          </Form.Item>
          <Form.Item label={this.translation('Current Slope')}>
            <div>1.000000</div>
          </Form.Item>

          <Tabs defaultActiveKey="1" type="card">
            <TabPane tab={this.translation('Correction Data')} key={'CD'}>
              <div style={{ background: 'rgb(253, 253, 253)', padding: '30px' }}>
                <Button type="primary" style={{ margin: '10px' }}>
                  {this.translation('Start Measurement')}
                </Button>
                <Button type="primary" style={{ margin: '10px' }}>
                  {' '}
                  {this.translation('Calculation')}
                </Button>
                <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                  <label>{this.translation('Correction Method')}</label>
                  <Radio.Group>
                    <Radio value={'Offset'}>{this.translation('Offset')}</Radio>
                    <Radio value={'Offset+Slope'}>{this.translation('Offset+Slope')}</Radio>
                  </Radio.Group>
                </div>
                <Row>
                  <Col flex="auto">
                    <Table
                      dataSource={dataSource}
                      columns={columns}
                      pagination={false}
                      // scroll={{ x: 2000, y: 500 }}
                      bordered
                    />
                  </Col>
                </Row>
                <Button type="primary" style={{ margin: '10px' }}>
                  {this.translation('Open the correction data')}
                </Button>
                <Button type="primary" style={{ margin: '10px' }}>
                  {' '}
                  {this.translation('Save a correction data file')}
                </Button>
              </div>
            </TabPane>
            <TabPane tab={this.translation('Correction Result')} key={'CR'}>
              <Row>
                <Col flex={3}>
                  <Form
                    {...formItemLayout}
                    layout="horizontal"
                    name={this.translation('form1')}
                    style={{
                      backgroundColor: 'rgb(230, 247, 255)',
                      margin: '19px',
                      padding: '30px'
                    }}
                    className="formComponent"
                  >
                    <Form.Item label={this.translation('Corre. Method')}>
                      <div>R -</div>
                    </Form.Item>
                    <Form.Item label={this.translation('Number of data')}>
                      <div>SEC -</div>
                    </Form.Item>
                    <Form.Item label={this.translation('Offset')}>
                      <div>N -</div>
                    </Form.Item>
                    <Form.Item label={this.translation('Slope')}>
                      <div>GN -</div>
                    </Form.Item>
                  </Form>
                </Col>
                <Col flex={3}>
                  <Form
                    {...formItemLayout}
                    layout="horizontal"
                    name={this.translation('form2')}
                    style={{
                      backgroundColor: 'rgb(230, 247, 255)',
                      margin: '19px',
                      padding: '30px'
                    }}
                    className="formComponent"
                  >
                    <Form.Item label={this.translation('No')}>
                      <Select style={{ width: 220 }}>
                        <Select.Option value="01">01</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label={this.translation('Correction target')}></Form.Item>
                    <Form.Item label={this.translation('Display Value')}></Form.Item>
                    <Form.Item label={this.translation('Residual Error')}></Form.Item>
                  </Form>
                </Col>
              </Row>
              <ReactEcharts
                option={this.state.option}
                notMerge={true}
                lazyUpdate={true}
                style={{ backgroundColor: 'rgb(230, 247, 255)', margin: '19px', padding: '30px' }}
                className="react_for_echarts"
              />
              <Button type="primary" style={{ margin: '10px' }}>
                {this.translation('Write By KB-30')}
              </Button>
            </TabPane>
          </Tabs>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(Correction);
