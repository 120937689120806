import React, { Component } from 'react';
import { InputNumber } from 'antd';

class InputNumberComponent extends Component {
  render() {
    return (
      <InputNumber
        maxLength={300}
        min={0}
        {...{ ...this.props }}
        onChange={(e) =>
          this.props.onChange(typeof this.props.onChange === 'function' ? e : e.target.value)
        }
      />
    );
  }
}

export default InputNumberComponent;
