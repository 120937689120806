import React from 'react';
import { Form, Input, message, Button, Table, Drawer, Tooltip, Popconfirm } from 'antd';
import { StyledComponent } from '../../CSS/style';
import { StyledButton, DrawerFooter } from '../../../../commons/styles/layout';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import axios from 'axios';
import localStorage from '../../../../utils/localStorage';
import themeSettings from '../../Utils/themeSettings.json';
import { UpdateMasters, CreateMasters, deleteMasters } from '../../APICalls';
import { API_RUBUSMASTERS } from '../../../../commons/api';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../selectors/language';
import { connect } from 'react-redux';
// import { tableTranslation } from '../../Adapter/TranslationAdapter';
const ApiList = {
  CreateDynamic: `${API_RUBUSMASTERS.DynamicHierarchyCREATE}`,
  UpdateDynamic: `${API_RUBUSMASTERS.DynamicHierarchyUPDATE}`,
  DeleteDynamic: `${API_RUBUSMASTERS.DynamicHierarchyDELETE}`
};
class DynamicHierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      list: [],
      visibleDynamic: false
      // translationCache
    };
  }
  hForm = React.createRef();

  componentDidMount() {
    this._getList();
    // this.getFileDownloadName();
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  _getList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let url = '';
    url = `/api/rubusMaster/dynamicHierarchy/get?SiteId=${siteId}`;

    let orgHeaders = {
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ list: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  _editRecord = (record) => {
    let textValue = JSON.stringify(record.Actions);
    let formObject = {
      ...record,
      Actions: textValue,
      Id: record.Id
    };
    this.hForm.current && this.hForm.current.setFieldsValue(formObject);
    this.setState({
      visibleDynamic: true,
      formObject,
      // textValue,
      visible: true
    });
  };

  deleteRecord = async (id) => {
    let deleteResponse = await deleteMasters(ApiList.DeleteDynamic, id);
    message.success(deleteResponse && deleteResponse.message);
    this._getList();
  };

  onFinish = async () => {
    let { formObject } = this.state;
    this.hForm.current &&
      this.hForm.current
        .validateFields()
        .then(async (values) => {
          let payload = {
            ...values
          };

          if (formObject && formObject.Id) {
            let UpdateResponse = await UpdateMasters(ApiList.UpdateDynamic, payload, formObject.Id);
            message.success(UpdateResponse && UpdateResponse.message);
          } else {
            let createResponse = await CreateMasters(ApiList.CreateDynamic, payload);
            message.success(createResponse && createResponse.message);
          }
          this._getList();
          this.hForm.current &&
            this.hForm.current.setFieldsValue({
              Level: null,
              Name: null,
              ModuleCode: null,
              LevelType: null,
              Type: null,
              Actions: null
            });
          this.setState({ formObject: {}, visibleDynamic: false });
        })
        .catch((error) => {
          console.log(error);
        });
  };

  OpenDrawer = () => {
    this.hForm.current &&
      this.hForm.current.setFieldsValue({
        Level: null,
        Name: null,
        ModuleCode: null,
        LevelType: null,
        Type: null,
        Actions: null
      });
    let formObject = {
      Level: null,
      Name: null,
      ModuleCode: null,
      LevelType: null,
      Type: null,
      Actions: null
    };
    this.setState({ visibleDynamic: true, formObject });
  };
  onClose = () => {
    this.hForm.current &&
      this.hForm.current.setFieldsValue({
        Level: null,
        Name: null,
        ModuleCode: null,
        LevelType: null,
        Type: null,
        Actions: null
      });
    this.setState({ visibleDynamic: false, formObject: {} });
  };
  handleChange = (e, key) => {
    let { formObject } = this.state;
    // let formObject = this.hForm.current && this.hForm.current.getFieldsValue();
    if (key === 'Actions') {
      let data = JSON.parse(e.target.value);
      formObject = {
        ...formObject,
        Actions: data
      };
      this.setState({ formObject });
    }
  };

  render() {
    let { list, formObject, visibleDynamic } = this.state;
    const columns = [
      // {
      //   title: 'S.No',
      //   dataIndex: 'S No',
      //   key: 'S No'
      // },
      {
        title: 'Module Code',
        dataIndex: 'ModuleCode',
        key: 'ModuleCode'
      },
      {
        title: 'Name',
        dataIndex: 'Name',
        key: 'Name'
      },
      {
        title: 'Action',
        dataIndex: 'Action',
        render: (text, record) => (
          <>
            <Button className="ant-btn" onClick={() => this._editRecord(record)} size="middle">
              <Tooltip title="Edit">
                {' '}
                <EditOutlined />
              </Tooltip>
            </Button>
            <Popconfirm
              title={'Are You Sure That You Want To Delete'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this.deleteRecord(record.Id)}
              okText={'yes'}
              cancelText={'No'}
            >
              <Button className="ant-btn" size="middle">
                <span style={{ color: '#ff4d4f' }}>
                  {' '}
                  <Tooltip title={'Delete'}>
                    <DeleteOutlined />
                  </Tooltip>
                </span>
              </Button>
            </Popconfirm>
          </>
        )
      }
    ];
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '0px 30px', minHeight: window.innerHeight - 143 }}
      >
        <div style={{ display: 'flex', justifyContent: 'right', padding: '20px' }}>
          <StyledButton onClick={this.OpenDrawer}>
            <PlusOutlined /> {this.translation('Add DynamicHierarchy')}
          </StyledButton>
        </div>
        <Table
          className="basictable"
          columns={columns}
          dataSource={list}
          pagination={
            list.length > 10
              ? {
                  pageSize: '10'
                }
              : false
          }
        />
        <Drawer
          title={
            formObject && formObject.Id
              ? this.translation('Update DynamicHierarchy')
              : this.translation('Add DynamicHierarchy')
          }
          placement="right"
          width={600}
          closable
          onClose={this.onClose}
          visible={visibleDynamic}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            // theme={themeSettings}
            layout="horizontal"
            ref={this.hForm}
            name="Site_form"
            initialValues={formObject}
            onFinish={this.onFinish}
          >
            <Form.Item label={this.translation('Name')} name="Name">
              <Input placeholder={this.translation('Please enter Name')} />
            </Form.Item>
            <Form.Item
              label={this.translation('ModuleCode')}
              name="ModuleCode"
              rules={[{ required: true, message: this.translation('Please select ModuleCode') }]}
            >
              <Input placeholder={this.translation('Please enter ModuleCode')} />
            </Form.Item>
            <Form.Item
              label={this.translation('Level')}
              name="Level"
              rules={[{ required: true, message: this.translation('Please enter Level !') }]}
            >
              <Input placeholder={this.translation('Please enter Level')} />
            </Form.Item>

            <Form.Item label={this.translation('LevelType')} name="LevelType">
              <Input
                // disabled={formObject && formObject.Id ? true : false}
                placeholder={this.translation('Please enter LevelType')}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Type')}
              name="Type"
              rules={[{ required: true, message: this.translation('Please enter Type !') }]}
            >
              <Input placeholder={this.translation('Please enter Type')} />
            </Form.Item>
            <Form.Item
              label={this.translation('Actions')}
              name="Actions"
              rules={[{ required: true, message: this.translation('Please select Actions') }]}
            >
              <Input.TextArea
                style={{ width: '70%' }}
                value={formObject && formObject.Id ? formObject && formObject.Actions : null}
                placeholder={this.translation('Please enter Actions')}
                onChange={(e) => this.handleChange(e, 'Actions')}
              />
            </Form.Item>
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            <Button type="primary" onClick={this.onFinish}>
              {this.translation('Submit')}
            </Button>
          </DrawerFooter>
        </Drawer>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(DynamicHierarchy);
