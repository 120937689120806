/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Form, message, Col, Select, Tabs, Input, TimePicker, Button } from 'antd';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import { decode as base64_decode } from 'base-64';

import FileBase64 from '../../../Utils/FileUpload';
// import HierarchyModal from '../../../Widgets/HierarchyModal';
import TableSelect from '../../../Widgets/TableSelect';
import localStorage from '../../../../../utils/localStorage';
import {
  getSitesList,
  submitTask,
  getStatusListTask,
  getHierarchyList,
  getTaskById,
  _getUOMList,
  getUsersList,
  getSkillTypeList
} from '../../../Utils/FormCalls';
import Highlighter from 'react-highlight-words';
import history from '../../../../../commons/history';
import {
  StyledButton,
  StyledComponent,
  StyledForm,
  StyledSubForm,
  StyledTabs,
  StyledModal,
  StyledSubHeading
} from '../../../CSS/style';
import { constant } from '../../../Utils/constants';
import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import { ItemForm, ToolsForm, LaborForm, MaterialForm } from './TaskRequirements';
import { getTranslationCache } from '../../../../../selectors/language';
import InputComponent from '../../../Widgets/InputComponent';
import { API_DEVICE, API_HIERARCHYMODULE } from '../../../../../commons/api';

const { Option } = Select;
const { TabPane } = Tabs;
const fileData = new FormData();
const { TextArea } = Input;
// const printLog = (arg1) => {
//   printLog(arg1);
// };

class TaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      sitesList: [],
      statusList: [],
      usersList: [],
      uomList: [],
      visible: false,
      tableData: [],
      dropdownSelection: {
        deviceId: ''
      },
      SkillTypeList: [],
      translationCache: props.translationCache || {},
      formObject: {},
      hierarchyList: [],
      viewObservationType: false,
      viewfieldDataType: false
    };
  }

  formHeader = React.createRef();

  componentDidMount = async () => {
    // let { formObject } = this.state;
    this._getDataById();
    try {
      let hierarchyList = await getHierarchyList();
      this.setState({ hierarchyList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let sitesList = await getSitesList();
      let formObject = this.formHeader.current ? this.formHeader.current.getFieldsValue() : {};
      formObject = {
        ...formObject,
        SiteId: localStorage.get('currentSite') ? parseInt(localStorage.get('currentSite')) : ''
      };

      if (this.formHeader.current) this.formHeader.current.setFieldsValue(formObject);
      this.setState({ sitesList, formObject });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    let SkillTypeList = await getSkillTypeList();
    this.setState({ SkillTypeList: SkillTypeList });
    try {
      let statusList = await getStatusListTask();
      this.setState({ statusList: statusList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let usersList = await getUsersList();
      this.setState({ usersList: usersList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
    try {
      let uomList = await _getUOMList();
      this.setState({ uomList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.history.location !== prevProps.history.location) {
      this.onReset();
      this._getDataById();
    }
  }

  _getDataById = async (TaskId) => {
    let { formObject } = this.state;
    let path = history.location.pathname.split('/rubus/AMM/TasksDetails/');
    let formId = TaskId ? TaskId : base64_decode(path[1]);
    if (formId !== 'new') {
      formObject = await getTaskById(formId, 'withId');
      if (formObject && formObject.Type === 'observationType') {
        this.setState({ viewObservationType: true });
      } else if (formObject && formObject.Type === 'fieldDataType') {
        this.setState({ viewfieldDataType: true });
      } else {
        this.setState({ viewfieldDataType: false, viewObservationType: false });
      }
      _.cloneDeepWith(formObject, (value) => {
        return value && value !== null
          ? {
              ...value,
              ...(value['EstimatedDuration'] = value.EstimatedDuration
                ? moment(value.EstimatedDuration, 'YYYY-MM-DD HH:mm:ss')
                : undefined)
            }
          : _.noop();
      });
    }
    this.setState({
      formObject
    });
    if (formObject) {
      this.formHeader.current.setFieldsValue(formObject);
    }
  };

  handleSelect = (value, subindex, keyName, selectedObject) => {
    let { SkillTypeList } = this.state;

    let data = this.formHeader.current.getFieldValue();
    if (keyName === 'Labor') {
      let skill = SkillTypeList.filter((item) => item.Id === selectedObject[0]['SkillTypeId']);
      if (data && data[keyName] && data[keyName][subindex] && data[keyName][subindex]) {
        data[keyName][subindex]['SkillTypeName'] =
          skill && skill[0] && Array.isArray(skill) && skill[0].Type ? skill[0].Type : null;
      }
      if (data && data[keyName] && data[keyName][subindex] && data[keyName][subindex]) {
        data[keyName][subindex]['Description'] = selectedObject[0]['Description'];
      }

      if (data && data[keyName] && data[keyName][subindex] && data[keyName][subindex]) {
        data[keyName][subindex]['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
      }
    } else {
      if (data && data[keyName] && data[keyName][subindex] && data[keyName][subindex]) {
        data[keyName][subindex]['Description'] = selectedObject[0]['Description'];
      }

      if (data && data[keyName] && data[keyName][subindex] && data[keyName][subindex]) {
        data[keyName][subindex]['AvailableQuantity'] = selectedObject[0]['AvailableQuantity'];
      }
    }

    this.formHeader.current.setFieldsValue(data);
    this.setState({ formObject: data });
  };
  fileupload = async (values, key, fileDataObject) => {
    let formObject = this.formHeader.current.getFieldsValue();
    if (formObject && formObject[key]) {
      formObject[key].push(...values);
    } else {
      formObject[key] = values;
    }
    //removing duplicate file names//
    formObject[key] = formObject[key].reduce((acc, current) => {
      const x = acc.find((item) => item.Name === current.Name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    formObject = {
      ...formObject,
      [key]: formObject[key]
    };
    fileData.append('files', fileDataObject);
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({ formObject, fileData });
  };
  onFinish = async (values) => {
    let { formObject, fileData } = this.state;
    let payload = {
      ...formObject,
      ...values,
      TaskStatusId: values.TaskStatusId,
      EstimatedDuration: values.EstimatedDuration
        ? moment(values.EstimatedDuration).format('YYYY-MM-DD HH:mm:ss')
        : undefined
    };
    let statusResponse = await submitTask(payload, fileData);
    if (statusResponse && statusResponse.message) {
      message.success('Task submitted successfully');
      this._getDataById(statusResponse.Id);
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  onReset = () => {
    this.formHeader && this.formHeader.current && this.formHeader.current.resetFields();
  };
  // onFinishFailed = () => {
  //   message.error('Please Provide mandotary fields');
  // };

  handleDropdownChange = (value) => {
    let { dropdownSelection } = this.state;
    dropdownSelection = {
      ...dropdownSelection,
      ...value
    };
    this.setState({
      dropdownSelection
    });
  };

  hierarchyDropdownChange = (value) => {
    let formObject = this.formHeader.current.getFieldsValue();
    formObject = {
      ...formObject,
      Hierarachym: value.ShortDescription,
      Location: value.LongDescription
    };
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({
      visible: false
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };
  getHierarchyLevels = () => {
    let { hierarchyList } = this.state;
    const siteId = localStorage.get('currentSite');
    let payload = {
      HierarchyCode:
        hierarchyList &&
        Array.isArray(hierarchyList) &&
        hierarchyList[0] &&
        hierarchyList[0].HierarchyCode
          ? hierarchyList[0].HierarchyCode
          : null,
      Type: 'Device'
    };
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${API_HIERARCHYMODULE.GetHierarchyLevels}?SiteId=${siteId}&HierarchyCode=${payload.HierarchyCode}&Type=${payload.Type}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({
            treeData: data,
            visible: true,
            formObject: this.formHeader.current.getFieldsValue()
          });
        }
      })
      .catch(function (error) {
        message.error(error);
      });
  };
  hierarchyDropdownChange = (value) => {
    let formObject = this.formHeader.current.getFieldsValue();
    formObject = {
      ...formObject,
      Equipment: value.Code,
      EquipmentDescription: value.Name
    };
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({
      visible: false,
      formObject
    });
  };
  onDrawerCancel = () => {
    this.setState({
      visible: false
    });
  };
  handleChange = (value, key) => {
    let formObject = this.formHeader.current.getFieldsValue();
    if (key === 'EstimatedDuration') {
      formObject = {
        ...formObject,
        [key]: moment(value, 'HH:mm')
      };
    }
    if (this.formHeader.current) {
      this.formHeader.current.setFieldsValue(formObject);
    }
    this.setState({ formObject });
  };
  getValueFromList = (list, compareKey, compareValue, resultKey) => {
    let filterObject = [];
    if (list && Array.isArray(list)) {
      filterObject = list.filter((listObject) => listObject[compareKey] === compareValue);
    }
    return filterObject && filterObject[0] && filterObject[0][resultKey]
      ? filterObject[0][resultKey]
      : '';
  };
  onTaskSelection = (val) => {
    if (val === 'observationType') {
      this.setState({
        viewObservationType: true,
        viewfieldDataType: false
      });
    } else {
      this.setState({
        viewfieldDataType: true,
        viewObservationType: false
      });
    }
  };
  ObservationCancel = () => {
    this.setState({
      viewObservationType: false
    });
  };
  ObservationoK = () => {
    this.setState({
      viewObservationType: false
    });
  };
  datepickerchange = (date, dateString, key) => {
    // this.formHeader.current.setFieldsValue(key, moment(dateString).format("YYYY-MM-DD HH:mm:ss"))
    let formObject = this.formHeader.current.getFieldsValue();
    let EstimatedDuration = null;
    if (
      key &&
      key === 'EndTime' &&
      this.formHeader.current.getFieldValue() &&
      this.formHeader.current.getFieldValue()['StartTime']
    ) {
      let startTime =
        this.formHeader.current.getFieldValue() &&
        this.formHeader.current.getFieldValue()['StartTime'];
      let endTime = date;
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    } else if (
      key &&
      key === 'StartTime' &&
      this.formHeader.current.getFieldValue() &&
      this.formHeader.current.getFieldValue()['EndTime']
    ) {
      let endTime =
        this.formHeader.current.getFieldValue() &&
        this.formHeader.current.getFieldValue()['EndTime'];
      let startTime = date;
      let ms = moment(endTime, 'YYYY-MM-DD HH:mm').diff(moment(startTime, 'YYYY-MM-DD HH:mm'));
      let d = moment.duration(ms);
      EstimatedDuration = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
    }
    formObject['EstimatedDuration'] = EstimatedDuration;
    this.formHeader.current.setFieldsValue(formObject);
  };
  getDisabledHours = (e, key) => {
    let hours = [];
    let minutes = [];
    let StartDateTime = '';
    let EndDateTime = '';
    let StartDate = '';
    let EndDate = '';

    if (key === 'StartTime') {
      StartDateTime = moment(e).format('YYYY-MM-DD HH:mm');
      EndDateTime = moment
        .utc(
          this.formHeader.current.getFieldValue() &&
            this.formHeader.current.getFieldValue()['EndTime'],
          'YYYY-MM-DD HH:mm'
        )
        .local();

      StartDate = moment(e).format('YYYY-MM-DD');
      EndDate = moment
        .utc(
          this.formHeader.current.getFieldValue() &&
            this.formHeader.current.getFieldValue()['EndTime']
        )
        .local()
        .format('YYYY-MM-DD');
    } else if (key === 'EndTime') {
      StartDateTime = moment
        .utc(
          this.formHeader.current.getFieldValue() &&
            this.formHeader.current.getFieldValue()['StartTime'],
          'YYYY-MM-DD HH:mm'
        )
        .local();
      EndDateTime = moment(e).format('YYYY-MM-DD HH:mm');

      StartDate = moment
        .utc(
          this.formHeader.current.getFieldValue() &&
            this.formHeader.current.getFieldValue()['StartTime']
        )
        .local()
        .format('YYYY-MM-DD');
      EndDate = moment(e).format('YYYY-MM-DD');
    }
    if (
      key === 'StartTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (var i = 0; i <= 24; i++) {
        if (i > moment(EndDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var j = 0; j <= 60; j++) {
          if (j > moment(EndDateTime).minutes() - 1) {
            minutes.push(j);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    } else if (
      key === 'EndTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (var k = 0; k < moment(StartDateTime).hours(); k++) {
        if (k < 25) {
          hours.push(k);
        }
      }

      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var J = 0; J < moment(StartDateTime).minutes() + 1; J++) {
          if (J < 61) {
            minutes.push(J);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    }
  };
  getDeviceData = (key) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${API_DEVICE.LIST}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({
          tableData: data,
          [key]: true,
          formObject: this.formHeader.current.getFieldsValue()
        });
      })
      .catch(function () {});
  };
  TableDropdownChange = (value, modal) => {
    let formObject = this.formHeader.current.getFieldsValue();
    formObject = {
      ...formObject,
      Equipment: value.AliasCode,
      EquipmentDescription: value.Name,
      DeviceCode: value.DeviceCode,
      DisplayCode: value.DisplayCode
    };
    this.formHeader.current.setFieldsValue(formObject);
    this.setState({
      formObject,
      [modal]: false
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={translation(this.props.translationCache, `Search`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translation(this.props.translationCache, `Search`)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translation(this.props.translationCache, `Reset`)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });
  render() {
    let {
      formObject,
      statusList,
      // hierarchyList,
      sitesList,
      viewObservationType,
      viewfieldDataType,
      usersList,
      uomList
    } = this.state;
    let { translationCache } = this.props;
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!'
      },
      number: {
        range: '${label} must be between ${min} and ${max}'
      }
      // pattern: {
      //   mismatch: '${label} does not match pattern'
      // }
    };
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '0px 15px', minHeight: window.innerHeight - 143 }}
        >
          <StyledForm
            name="TaskData"
            theme={themeSettings}
            ref={this.formHeader}
            initialValues={formObject}
            onFinish={this.onFinish}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            //wrapperCol={{ flex: 1 }}
            validateMessages={validateMessages}
          >
            {/* {formObject && formObject.Id ? (
              <div
                style={{
                  color: 'white',
                  margin: '19px 37px',
                  fontSize: '17px',
                  fontWeight: 'bold',
                  marginBottom: '35px'
                }}
              >
                {formObject && formObject.SiteId ? (
                  <span>
                    <span style={{ color: '#ffb327' }}>
                      {this.getValueFromList(
                        sitesList,
                        'Id',
                        this.formHeader.current
                          ? this.formHeader.current.getFieldValue('SiteId')
                          : '',
                        'Name'
                      )}{' '}
                    </span>{' '}
                    (Site)
                    <span style={{ margin: '10px', color: 'white', fontSize: '17px' }}>
                      {' '}
                      /{' '}
                    </span>{' '}
                  </span>
                ) : null}
                {formObject && formObject.Location ? (
                  <span>
                    <span style={{ color: '#ffb327' }}>
                      {' '}
                      {this.formHeader.current
                        ? this.formHeader.current.getFieldValue('Location')
                        : null}
                    </span>{' '}
                    (Location)
                    <span style={{ margin: '10px', color: 'white' }}> / </span>
                  </span>
                ) : null}

                {formObject && formObject.Equipment ? (
                  <span>
                    <span style={{ color: '#ffb327' }}>
                      {' '}
                      {this.formHeader.current
                        ? this.formHeader.current.getFieldValue('Equipment')
                        : null}
                    </span>{' '}
                    ({' '}
                    {this.formHeader.current
                      ? this.formHeader.current.getFieldValue('EquipmentDescription')
                      : null}
                    )
                  </span>
                ) : null}
              </div>
            ) : null} */}
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Form.Item>
                <StyledButton theme={themeSettings} htmlType="submit">
                  {translation(translationCache, constant.submit)}
                </StyledButton>
              </Form.Item>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                label={translation(translationCache, constant.taskName)}
                name={'TaskName'}
                style={{
                  width: '100%',
                  flex: '50%'
                }}
                rules={[
                  { required: true },
                  {
                    // pattern: new RegExp(/^[A-Za-z0-9 ]*$/)
                    // pattern: new RegExp(
                    //   /^[一-龯ぁ-んァ-ンぁ-んァ-ン\wぁ-んァ-ンｧ-ﾝﾞﾟぁ-ゞ0-9A-Za-zァ-ヶｦ-ﾟ-_&一 ]*$/
                    // )
                    // message: 'field does not accept special characters'
                  }
                ]}
              >
                <InputComponent
                  placeholder={translation(translationCache, 'Enter Name')}
                  value={formObject && formObject.TaskName ? formObject.TaskName : ''}
                  style={{ width: '70%' }}
                  //maxLength={30}
                  disabled={formObject && formObject.Id ? true : false}
                />
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.taskDescription)}
                name={'TaskDescription'}
                style={{ width: '100%', flex: '50%' }}
                rules={[{ required: true }]}
              >
                <InputComponent
                  placeholder={translation(translationCache, 'Enter Task Description')}
                  value={formObject && formObject.TaskDescription ? formObject.TaskDescription : ''}
                  style={{ width: '70%' }}
                  //maxLength={150}
                />
              </Form.Item>

              <Form.Item name="Equipment"></Form.Item>
              <Form.Item
                label={translation(translationCache, constant.equipment)}
                name="DisplayCode"
                style={{
                  width: '100%',
                  flex: '50%'
                  // display: formObject && formObject.Id ? 'none' : true
                }}
                rules={[{ required: true }]}
              >
                <Input
                  style={{ width: '70%' }}
                  // value={formObject.Location}
                  placeholder={translation(translationCache, 'Select Equipment')}
                  disabled={true}
                  suffix={
                    <DownOutlined
                      style={{
                        // color: '#1a3b5c',
                        color: formObject && formObject.Id ? null : '#1a3b5c',
                        cursor: formObject && formObject.Id ? 'not-allowed' : null,
                        opacity: formObject && formObject.Id ? '0.8' : null
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (formObject && !formObject.Id) {
                          this.getDeviceData('visible');
                        }
                      }}
                    />
                  }
                />
                {/* <Select
                  value={formObject.Equipment}
                  placeholder={translation(translationCache,"Select Equipment")}
                  disabled={true}
                  style={{ width: '70%' }}
                  suffixIcon={
                    <ApartmentOutlined
                      className="apartmentOutlined"
                      style={{
                        color: '#1a3b5c',
                        opacity: formObject && formObject.Id ? 0.5 : null
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.getHierarchyLevels(hierarchyList);
                      }}
                      // style={{ color: 'red !important' }}
                    />
                  }
                /> */}
              </Form.Item>

              <Form.Item
                style={{
                  width: '100%',
                  flex: '50%'
                  // display: formObject && formObject.Id ? 'none' : true
                }}
                label={translation(translationCache, constant.equipmentDescription)}
                name="EquipmentDescription"
                rules={[{ required: true }]}
              >
                <InputComponent
                  disabled={true}
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Autopopulate Basis To The Equipment')}
                  //maxLength={150}
                />
              </Form.Item>

              <Form.Item
                label={translation(translationCache, constant.site)}
                name="SiteId"
                style={{
                  width: '100%',
                  flex: '50%'
                  // display: formObject && formObject.Id ? 'none' : true
                }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={translation(translationCache, 'Select Site')}
                  style={{ width: '70%' }}
                  disabled={formObject && formObject.Id ? true : false}
                >
                  {sitesList &&
                    Array.isArray(sitesList) &&
                    sitesList.map((site, index) => {
                      return (
                        <Option key={index} value={site.Id}>
                          {' '}
                          {site.Name}{' '}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label={translation(translationCache, constant.status)}
                name="TaskStatusId"
                style={{ width: '100%', flex: '50%' }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={translation(translationCache, 'Select Status')}
                  style={{ width: '70%' }}
                >
                  {statusList &&
                    Array.isArray(statusList) &&
                    statusList.map((status, index) => {
                      return (
                        <Option key={index} value={status.Value}>
                          {translation(translationCache, status.Key)}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                style={{ width: '100%', flex: '50%' }}
                label={translation(translationCache, constant.TaskType)}
                name="Type"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: '70%' }}
                  placeholder={translation(translationCache, 'Select Task')}
                  multiple
                  // defaultValue={selectedTaskADD
                  disabled={formObject && formObject.Id ? true : false}
                  onChange={(value) => this.onTaskSelection(value)}
                  notFoundContent={'Please Select Equipment For Tasks'}
                >
                  <option value="observationType">
                    {translation(translationCache, constant.observationType)}
                  </option>
                  <option value="fieldDataType">
                    {translation(translationCache, constant.fieldDataType)}
                  </option>
                </Select>
              </Form.Item>
              <Form.Item style={{ width: '100%', flex: '50%' }} name="TaskName">
                <InputComponent
                  //maxLength={20}
                  style={{ display: 'none' }}
                />
              </Form.Item>

              {viewObservationType === true || this.state.Type === 'observationType' ? (
                <>
                  <StyledSubHeading theme={themeSettings} style={{ marginLeft: '40%' }}>
                    {translation(translationCache, 'Observation Type Details')}
                  </StyledSubHeading>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.taskItem)}
                      name="Name"
                      rules={[{ required: true }]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache, 'Enter Name')}
                        value={formObject.Name}
                        style={{ width: '70%' }}
                        //maxLength={30}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.taskDescription)}
                      name="Description"
                      rules={[{ required: true }]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache, 'Enter Description')}
                        value={formObject.Description}
                        style={{ width: '70%' }}
                        //maxLength={150}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.criterion)}
                      name="Criteria"
                      rules={[{ required: false }]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache, 'Enter Criteria')}
                        value={formObject.Criteria}
                        style={{ width: '70%' }}
                        //maxLength={30}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.method)}
                      name="Method"
                      rules={[{ required: false }]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache, 'Enter Method')}
                        value={formObject.Method}
                        style={{ width: '70%' }}
                        //maxLength={30}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.observation)}
                      name="Observation"
                      rules={[{ required: false }]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache, 'Enter Observation')}
                        value={formObject.Observation}
                        style={{ width: '70%' }}
                        //maxLength={30}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.estimatedDuration)}
                      name="EstimatedDuration"
                    >
                      <TimePicker
                        style={{ width: '70%' }}
                        format={'HH:mm'}
                        allowClear={false}
                        onChange={(value) => this.handleChange(value, 'EstimatedDuration')}
                      />
                      {/* <InputComponent
                        disabled
                        placeholder={translation(translationCache,"Autopopulate EstimatedDuration  Basis To The Start & End Time"
                        style={{ width: '70%' }}
                        //maxLength={10}
                      /> */}
                    </Form.Item>

                    {/* <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.startTime)}
                      name="StartTime"
                      rules={[{ required: false }]}
                    >
                      <DatePicker
                        // inputReadOnly={true}
                        style={{ width: '70%' }}
                        format="YYYY-MM-DD HH:mm:ss"
                        // inputReadOnly={false}
                        placeholder={translation(translationCache,"Select Start Date Time")}
                        value={moment().format('YYYY-MM-DD HH:mm:ss')}
                        // disabledDate={this.disabledDate}

                        onChange={(date, dateString) => {
                          this.datepickerchange(date, dateString, 'StartTime');
                        }}
                        showTime={{
                          defaultValue: moment('00:00:00', 'HH:mm:ss')
                        }}
                        disabledDate={(d) =>
                          //     this.formHeader.current.getFieldValue("EndTime")
                          //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                          //         ))
                          !d ||
                          d.isAfter(
                            this.formHeader.current.getFieldValue() &&
                              this.formHeader.current.getFieldValue()['EndTime']
                              ? moment(this.formHeader.current.getFieldValue()['EndTime'])
                                  .add(1, 'day')
                                  .format('YYYY-MM-DD')
                              : ''
                          ) ||
                          d.isBefore(moment().format('YYYY-MM-DD'))
                        }
                        disabledTime={(e) =>
                          this.getDisabledHours(moment(e, 'YYYY-MM-DD HH:mm:ss'), 'StartTime')
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.endTime)}
                      name="EndTime"
                      rules={[{ required: false }]}
                    >
                      <DatePicker
                        inputReadOnly={true}
                        style={{ width: '70%' }}
                        placeholder={translation(translationCache,"Select End Date Time")}
                        format="YYYY-MM-DD HH:mm:ss"
                        value={moment().format('YYYY-MM-DD HH:mm:ss')}
                        onChange={(date, dateString) => {
                          this.datepickerchange(date, dateString, 'EndTime');
                        }}
                        // inputReadOnly={false}
                        disabledDate={(d) =>
                          //     this.formHeader.current.getFieldValue("EndTime")
                          //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                          //         ))
                          !d ||
                          d.isBefore(
                            this.formHeader.current &&
                              this.formHeader.current !== null &&
                              this.formHeader.current.getFieldValue() &&
                              this.formHeader.current.getFieldValue()['StartTime']
                              ? moment(
                                  this.formHeader.current.getFieldValue() &&
                                    this.formHeader.current.getFieldValue()['StartTime']
                                ).format('YYYY-MM-DD')
                              : ''
                          ) ||
                          d.isBefore(moment().format('YYYY-MM-DD'))
                        }
                        disabledTime={(e) =>
                          this.getDisabledHours(moment(e, 'YYYY-MM-DD HH:mm:ss'), 'EndTime')
                        }
                        showTime={{
                          defaultValue: moment('00:00:00', 'HH:mm:ss')
                        }}
                      />
                    </Form.Item> */}
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.remarks)}
                      name="Remarks"
                      rules={[{ required: false }]}
                    >
                      <TextArea
                        rows={4}
                        placeholder={translation(translationCache, 'Enter Remarks')}
                        value={formObject.Remarks}
                        style={{ width: '70%', hieght: '70%' }}
                        //maxLength={100}
                      />
                    </Form.Item>
                    {/* <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.UOM)}
                      name="UOM"
                      rules={[{ required: false }]}
                    >
                      <Select placeholder={translation(translationCache,"Select UOM")} style={{ width: '70%' }}>
                        {uomList.map((uom, index) => {
                          return (
                            <Option key={index} value={uom.Key}>
                              {' '}
                              {uom.Key}{' '}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item> */}
                    <Form.Item
                      label={translation(translationCache, constant.attachments)}
                      name="Attachments"
                      style={{ width: '100%', flex: '50%' }}
                    >
                      <FileBase64
                        style={{ width: '70%' }}
                        downloadClassName={'formdownloadInput'}
                        Attachments={formObject.Attachments}
                        fileUpload={this.fileupload}
                      ></FileBase64>
                    </Form.Item>
                    <Form.Item style={{ width: '100%', flex: '50%' }} name="test">
                      <InputComponent
                        //maxLength={20}
                        style={{ display: 'none' }}
                      />
                    </Form.Item>

                    <Form.Item name="Equipment"></Form.Item>
                  </div>
                </>
              ) : (
                ''
              )}
              {viewfieldDataType === true ? (
                <>
                  <StyledSubHeading theme={themeSettings} style={{ marginLeft: '40%' }}>
                    {translation(translationCache, 'FieldData Type Details')}
                  </StyledSubHeading>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.taskItem)}
                      name="Name"
                      rules={[{ required: true }]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache, 'Enter Task')}
                        value={formObject.Task}
                        style={{ width: '70%' }}
                        //maxLength={30}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.taskDescription)}
                      name="Description"
                      rules={[{ required: true }]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache, 'Enter Description')}
                        value={formObject.Description}
                        style={{ width: '70%' }}
                        //maxLength={100}
                      />
                    </Form.Item>
                    {/* <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.measurementDate)}
                      name="MeasurementDate"
                      rules={[{ required: false }]}
                    >
                      <DatePicker
                        style={{ width: '70%' }}
                        disabledDate={(d) => d.isBefore(moment().format('YYYY-MM-DD'))}
                        placeholder={translation(translationCache,"Select Actual End Date Time")}
                        format="YYYY-MM-DD"
                      />
                    </Form.Item> */}
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.measurementValue)}
                      name="MeasurementValue"
                      rules={[
                        {
                          // pattern: new RegExp(/^[0-9]*$/)
                          // message: 'field accept only postive values'
                        }
                      ]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache, 'Enter Measurement Value')}
                        value={formObject.MeasurementValue}
                        style={{ width: '70%' }}
                        //maxLength={30}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.UOM)}
                      name="UOM"
                      rules={[{ required: false }]}
                    >
                      <Select
                        placeholder={translation(translationCache, 'Select UOM')}
                        style={{ width: '70%' }}
                      >
                        {uomList.map((uom, index) => {
                          return (
                            <Option key={index} value={uom.UOM}>
                              {' '}
                              {uom.UOM}{' '}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.inspectionMethod)}
                      name="Method"
                      rules={[{ required: false }]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache, 'Enter Inspection Method')}
                        value={formObject.InspectionMethod}
                        style={{ width: '70%' }}
                        //maxLength={30}
                      />
                    </Form.Item>

                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.criterion)}
                      name="Criteria"
                      rules={[{ required: false }]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache, 'Enter Criteria')}
                        value={formObject.Criteria}
                        style={{ width: '70%' }}
                        //maxLength={30}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.observation)}
                      name="Observation"
                      rules={[{ required: false }]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache, 'Enter Observation')}
                        value={formObject.Observation}
                        style={{ width: '70%' }}
                        //maxLength={30}
                      />
                    </Form.Item>
                    {/* <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.estimatedDuration)}
                      name="EstimatedDuration"
                    >
                      <InputComponent
                        disabled
                        placeholder={translation(translationCache,"Autopopulate EstimatedDuration  Basis To The Start & End Time")}
                        style={{ width: '70%' }}
                        //maxLength={10}
                      />
                    </Form.Item> */}
                    <Form.Item
                      label={translation(translationCache, constant.attachments)}
                      name="Attachments"
                      style={{ width: '100%', flex: '50%' }}
                    >
                      <FileBase64
                        style={{ width: '70%' }}
                        downloadClassName={'formdownloadInput'}
                        Attachments={formObject.Attachments}
                        fileUpload={this.fileupload}
                      ></FileBase64>
                    </Form.Item>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.recordedBy)}
                      name="RecordBy"
                      rules={[{ required: false }]}
                    >
                      <Select
                        placeholder={translation(translationCache, 'Select RecordedBy')}
                        style={{ width: '70%' }}
                      >
                        {usersList &&
                          Array.isArray(usersList) &&
                          usersList.length > 0 &&
                          usersList.map((item, index) => {
                            return (
                              <Option key={index} value={item.Id}>
                                {' '}
                                {item.FirstName}{' '}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>

                    {/* <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.actualStartTime)}
                      name="StartTime"
                      rules={[{ required: false }]}
                    >
                      <DatePicker
                        inputReadOnly={true}
                        style={{ width: '70%' }}
                        format="YYYY-MM-DD HH:mm:ss"
                        // inputReadOnly={false}
                        placeholder={translation(translationCache,"Select Actual Start Date Time")}
                        onChange={(date, dateString) => {
                          this.datepickerchange(date, dateString, 'StartTime');
                        }}
                        showTime={{
                          defaultValue: moment('00:00:00', 'HH:mm:ss')
                        }}
                        disabledDate={(d) =>
                          //     this.formHeader.current.getFieldValue("EndTime")
                          //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                          //         ))
                          !d ||
                          d.isAfter(
                            this.formHeader.current.getFieldValue() &&
                              this.formHeader.current.getFieldValue()['EndTime']
                              ? moment(this.formHeader.current.getFieldValue()['EndTime'])
                                .add(1, 'day')
                                .format('YYYY-MM-DD')
                              : ''
                          ) ||
                          d.isBefore(moment().format('YYYY-MM-DD'))
                        }
                        disabledTime={(e) =>
                          this.getDisabledHours(moment(e, 'YYYY-MM-DD HH:mm:ss'), 'StartTime')
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.actualEndTime)}
                      name="EndTime"
                      rules={[{ required: false }]}
                    >
                      <DatePicker
                        inputReadOnly={true}
                        style={{ width: '70%' }}
                        placeholder={translation(translationCache,"Select Actual End Date Time")}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={(date, dateString) => {
                          this.datepickerchange(date, dateString, 'EndTime');
                        }}
                        // inputReadOnly={false}
                        disabledDate={(d) =>
                          //     this.formHeader.current.getFieldValue("EndTime")
                          //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                          //         ))
                          !d ||
                          d.isBefore(
                            this.formHeader.current &&
                              this.formHeader.current !== null &&
                              this.formHeader.current.getFieldValue() &&
                              this.formHeader.current.getFieldValue()['StartTime']
                              ? moment(
                                this.formHeader.current.getFieldValue() &&
                                this.formHeader.current.getFieldValue()['StartTime']
                              ).format('YYYY-MM-DD')
                              : ''
                          ) ||
                          d.isBefore(moment().format('YYYY-MM-DD'))
                        }
                        disabledTime={(e) =>
                          this.getDisabledHours(moment(e, 'YYYY-MM-DD HH:mm:ss'), 'EndTime')
                        }
                        showTime={{
                          defaultValue: moment('00:00:00', 'HH:mm:ss')
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ width: '100%', flex: '50%' }}
                      label={translation(translationCache, constant.observation)}
                      name="Observation"
                      rules={[{ required: false }]}
                    >
                      <InputComponent
                        placeholder={translation(translationCache,"Enter Observation")}
                        value={formObject.Observation}
                        style={{ width: '70%' }}
                        //maxLength={30}
                      />
                    </Form.Item> */}

                    <Form.Item style={{ width: '100%', flex: '50%' }} name="test">
                      <InputComponent
                        //maxLength={20}
                        style={{ display: 'none' }}
                      />
                    </Form.Item>
                  </div>
                </>
              ) : (
                ''
              )}
              <Form.Item name="Id"></Form.Item>
              <Form.Item name="Labor"></Form.Item>
              <Form.Item name="Materials"></Form.Item>
              <Form.Item name="Items"></Form.Item>
            </div>

            <StyledSubForm theme={themeSettings}>
              <StyledTabs theme={themeSettings} type="card" size={'middle'}>
                <TabPane tab={translation(translationCache, 'Tools')} key="1">
                  <Form.Item name="Tools">
                    <ToolsForm
                      quantity={'4'}
                      formObject={
                        this.formHeader &&
                        this.formHeader.current &&
                        this.formHeader.current !== null
                          ? this.formHeader.current.getFieldsValue()
                          : {}
                      }
                      onToolChange={this.handleSelect}
                      formHeader={this.formHeader}
                    />
                  </Form.Item>
                </TabPane>
                <TabPane tab={translation(translationCache, 'ManPower')} key="2">
                  <Col span={24}>
                    <Form.Item name="Labor">
                      <LaborForm
                        quantity={'4'}
                        formObject={
                          this.formHeader && this.formHeader.current !== null
                            ? this.formHeader.current.getFieldsValue()
                            : {}
                        }
                        onLaborChange={this.handleSelect}
                        formHeader={this.formHeader}
                      />
                    </Form.Item>
                  </Col>
                </TabPane>
                <TabPane tab={translation(translationCache, 'Material')} key="3">
                  <Col span={24}>
                    <Form.Item name="Materials">
                      <MaterialForm
                        quantity={'4'}
                        formObject={
                          this.formHeader &&
                          this.formHeader.current &&
                          this.formHeader.current !== null
                            ? this.formHeader.current.getFieldsValue()
                            : {}
                        }
                        onMaterialChange={this.handleSelect}
                        formHeader={this.formHeader}
                      />
                    </Form.Item>
                  </Col>
                </TabPane>
                <TabPane tab={translation(translationCache, 'Items')} key="4">
                  <Col span={24}>
                    <Form.Item name="Items">
                      <ItemForm
                        quantity={'4'}
                        formObject={
                          this.formHeader && this.formHeader.current !== null
                            ? this.formHeader.current.getFieldsValue()
                            : {}
                        }
                        onItemChange={this.handleSelect}
                        formHeader={this.formHeader}
                      />
                    </Form.Item>
                  </Col>
                </TabPane>
              </StyledTabs>
            </StyledSubForm>
          </StyledForm>

          <StyledModal
            open={this.state.visible}
            width={1000}
            closable
            title={translation(translationCache, constant.selectEquipment)}
            onCancel={this.onDrawerCancel}
            onOk={this.onDrawerCancel}
          >
            <TableSelect
              data={this.state.tableData}
              translationCache={this.props.translationCache}
              key="Equipment"
              columns={[
                {
                  title: translation(translationCache, 'Equipment'),
                  dataIndex: 'DisplayCode',
                  key: 'DisplayCode',
                  ...this.getColumnSearchProps('DisplayCode')
                },
                {
                  title: translation(translationCache, 'Equipment Description'),
                  dataIndex: 'Name',
                  key: 'Name',
                  ...this.getColumnSearchProps('Name')
                }
              ]}
              handleDropdownChange={(e) => this.TableDropdownChange(e, 'visible')}
            />
            {/* <HierarchyModal
              value={this.state.deviceId}
              treeData={this.state.treeData}
              translationCache={this.props.translationCache}
              language={this.props.language}
              handleDropdownChange={this.hierarchyDropdownChange}
              deviceList={this.state.deviceList}
            /> */}
          </StyledModal>
        </StyledComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(TaskForm);
