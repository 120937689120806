import React, { Component } from 'react';
import { Table, Button, Form, Select } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../../../selectors/language';

class SingleMeasurement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: '',
      translationCache: props.translationCache || []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }
  translation = (keyword) => {
    let { translationCache } = this.props;

    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const columns = [
      {
        title: this.translation('No'),
        dataIndex: 'No',
        key: 'No'
      },
      {
        title: this.translation('Sample Name'),
        dataIndex: 'SampleName',
        key: 'SampleName'
      },
      {
        title: this.translation('Date'),
        dataIndex: 'Date',
        key: 'Date'
      },
      {
        title: this.translation('CH'),
        dataIndex: 'ch',
        key: 'ch'
      },
      {
        title: this.translation('Channel Name'),
        dataIndex: 'Channel Name',
        key: 'Channel Name'
      },
      {
        title: this.translation('Measured Value'),
        dataIndex: 'DV',
        key: 'DV'
      }
    ];
    const dataSource = [
      {
        key: '1',
        name: this.translation('Mike'),
        age: 32,
        address: this.translation('10 Downing Street')
      },
      {
        key: '2',
        name: this.translation('John'),
        age: 42,
        address: this.translation('10 Downing Street')
      }
    ];
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    };
    const buttonItemLayout = {
      wrapperCol: {
        span: 14,
        offset: 4
      }
    };
    return (
      <div style={{}}>
        <Form
          {...formItemLayout}
          layout="horizontal"
          name={this.translation('HeaderNumber_current')}
          style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}
        >
          <Form.Item label={this.translation('HD')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="01">01</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={this.translation('CH')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="01">01</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={this.translation('CutChip')}>
            <div>24.1</div>
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button type="primary">{this.translation('Measurement')}</Button>
          </Form.Item>
        </Form>
        <div style={{ backgroundColor: 'rgb(215, 231, 240)', margin: '19px', padding: '30px' }}>
          <label style={{ fontSize: '18px', fontWeight: 'bold' }}>
            {this.translation('Measurement Log')}
          </label>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            // scroll={{ x: 2000, y: 500 }}
            bordered
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(SingleMeasurement);
