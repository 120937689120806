import React, { Component } from 'react';
import {
  Form,
  Input,
  message,
  Button,
  Select,
  Popconfirm,
  Tabs,
  Upload,
  Tooltip,
  Badge,
  Table,
  Drawer,
  Radio,
  DatePicker,
  Divider,
  Card,
  Space
} from 'antd';
import {
  PlusOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
  UploadOutlined,
  CommentOutlined,
  UserOutlined,
  FieldTimeOutlined,
  DownloadOutlined,
  EditOutlined,
  CalendarOutlined,
  AppstoreOutlined
} from '@ant-design/icons';
import { SubmitForm, getUserList, getRelatedTags, getStatusList } from '../APICalls/FormCalls';
import { createStructuredSelector } from 'reselect';
import { getUserListData } from '../APICalls/FormCalls';
import { getUserProfile } from '../../../selectors/layout';
import moment from 'moment';
import { connect } from 'react-redux';
import CommentsForm from './CommentsForm';
import localStorage from '../../../utils/localStorage';
import { StyledTable } from '../CSS/style';
import themeSettings from '../../Analytics_Predictions/Utils/themeSettings.json';
import '../CSS/style.css';
import { ANALYTICS } from '../../../commons/api';
import { StyledModal } from '../CSS/style';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

const { RangePicker } = DatePicker;

class PredictionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: props.formObject && props.formObject ? props.formObject : {},
      useCaseModalVisible: false,
      PredictionTaskModalVisible: false,
      remarksModalVisible: false,
      AnalysisTaskModalVisible: false,
      userProfile: props && props.userProfile ? props.userProfile : {},
      showdownloadModal: false,
      showusecasedownloadModal: false,
      commentsData: {},
      resultsCommentsData: {},
      AnalysisfileModalVisible: false,
      PredictionfileModalVisible: false,
      AnalysisResultsFileModalVisisble: false,
      PredictionResultsFileModalVisible: false,
      AnalysisTasksdataSource: [],
      PredictiondataSource: [],
      AnalysisResultsdataSource: [],
      PredictionResultsdataSource: [],
      selectedTask: props && props.selectedTask ? props.selectedTask : 'Analysis Tasks',
      StatusList: [],
      activeKey: '1',
      viewType: 'card',
      predictionViewType: 'card',
      predictionActivekey: '1',
      myId: '',
      myIndex: '',
      visible: true,
      AnalysisTasks: [],
      addanalyticData: false,
      addPredictionData: false,
      isAanalyticResultVisible: false,
      PredictionIndex: '',
      PredictionId: '',
      isPredictionResultVisible: false,
      ResultIndex: '',
      ResultModalVisible: false,
      PredictionResult: false,
      cardResultIndex: '',
      addNewResult: false,
      PredictionResultInd: '',
      addNewPredictionResult: false,
      PredictionTasks: [],
      AnalyTicTaskId: 0,
      PredtictionTaskId: 0,
      anaLyticTableEdit: false,
      AnalyticTableId: '',
      PredictionTableId: '',
      filterKey: null,
      searchField: '',
      userList: [],
      myData: this.props.myData
    };
  }
  formHeader = React.createRef();
  myRef = React.createRef();
  analysisRef = React.createRef();
  analysisresultRef = React.createRef();
  predictionRef = React.createRef();
  predictionResultRef = React.createRef();

  componentDidMount = async () => {
    let list = await getUserList();
    this.setState({ users: list });
    let relatedTags = await getRelatedTags();
    this.setState({ relatedTags });
    let StatusList = await getStatusList();
    this.setState({ StatusList: StatusList });
    let userList = await getUserListData();
    this.setState({ userList: userList });
  };

  componentDidUpdate(prevprops) {
    if (
      this.props.userProfile !== prevprops.userProfile ||
      this.props.formObject !== prevprops.formObject ||
      this.props.selectedTask !== prevprops.selectedTask
    ) {
      this.formHeader.current.setFieldsValue(this.props.formObject);
      this.setState({
        userProfile: this.props.userProfile,
        formObject: this.props.formObject,
        selectedTask: this.props.selectedTask
      });
    }

    this.formHeader.current.setFieldsValue({
      CreatedBy:
        this.props &&
        this.props.userProfile &&
        this.props.userProfile.user &&
        this.props.userProfile.user.Id,
      CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss')
    });
  }

  onReset = () => {
    this.formHeader.current.resetFields();
  };
  onFinish = async () => {
    let data = this.formHeader.current.getFieldsValue();
    let { formObject } = this.state;
    this.formHeader.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          ...formObject,
          Title: data.Title,
          RelatedTags: data.RelatedTags,
          Usecase: data.Usecase,
          functionalDescription: data.functionalDescription,
          ...(values && !values.AnalysisTasks && !values.PredictionTasks),
          CreatedBy:
            this.props &&
            this.props.userProfile &&
            this.props.userProfile.user &&
            this.props.userProfile.user.Id,
          CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss')
        };
        // this.formHeader.current.setFieldsValue(payload);
        let statusResponse = {};
        let urlList = {
          insert: `${ANALYTICS.CREATE}`,
          update: `${ANALYTICS.UPDATE}`
        };
        if (formObject && formObject.Id && formObject.Id !== undefined) {
          statusResponse = await SubmitForm(payload, urlList.update);
        } else {
          statusResponse = await SubmitForm(payload, urlList.insert);
        }
        if (statusResponse && statusResponse.message) {
          this.props._getAdhocDataSource();
          message.success(statusResponse.message);

          this.onReset();
        } else if (statusResponse && statusResponse.Error) {
          message.error(statusResponse.Error);
        } else {
          message.error('Error');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onFinishFailed = () => {
    message.error('Please Provide mandatory fields');
  };
  // onhandleChange = (e) => {
  //   const {  userList,formObject ,myData } = this.state;
  //   this.setState({ filterKey: e });
  //   if (e === 'Assigne') {
  //     const newArray = myData.AnalysisTasks.map((obj1) => {
  //       const obj2 = userList.find((obj2) => obj2.Id === obj1.Assigne);
  //       return {
  //         ...obj1,
  //         FirstName: obj2.FirstName
  //       };
  //     });
  //     this.setState({ formObject:{...formObject,AnalysisTasks:newArray} });
  //   }
  // };
  // filterByValue = (value) => {
  //   let { formObject, filterKey,myData } = this.state;
  //   let SearchedDate = null;
  //   const formats = ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'];
  //   if (filterKey === 'Assigne') {
  //     return myData.AnalysisTasks.filter((o) => {
  //       return o.FirstName.toString().toLowerCase().includes(value.toString().toLowerCase());
  //     });
  //   }else if (filterKey === 'CreatedDate') {
  //     for (const format of formats) {
  //       const momentObj = moment(value, format, true);
  //       if (momentObj.isValid()) {
  //         SearchedDate = momentObj.toDate();
  //         break;
  //       }
  //     }
  //     if (SearchedDate !== null) {
  //       const cretaeddate = myData.AnalysisTasks.filter((o) => {
  //         const date = new Date(o.CreatedDate);
  //         return (
  //           date.getDate() === SearchedDate.getDate() &&
  //           date.getMonth() === SearchedDate.getMonth() &&
  //           date.getFullYear() === SearchedDate.getFullYear()
  //         );
  //      });
  //       return cretaeddate;
  //     }
  //   } else {
  //     return myData.AnalysisTasks.filter((o) => {
  //       return o[filterKey].toString().toLowerCase().includes(value.toString().toLowerCase());
  //     });
  //   }
  // };
  // onSearch = () => {
  //   const {searchField,formObject} = this.state;
  //   let dataSource = this.filterByValue(searchField);
  //   this.setState({formObject:{...formObject,AnalysisTasks:dataSource}  });
  // };

  useCaseModalClose = () => {
    this.setState({ useCaseModalVisible: false });
  };
  AnalysisTaskModalClose = () => {
    this.setState({ AnalysisTaskModalVisible: false });
  };
  PredictionTaskModalClose = () => {
    this.setState({ PredictionTaskModalVisible: false });
  };
  resultsModalClose = () => {
    this.setState({ remarksModalVisible: false });
  };
  SubmitComments = (value, key, index, mainKey, mainIndex) => {
    //  let formObject = this.formHeader.current.getFieldValue();
    const { formObject } = this.state;
    if (
      formObject &&
      formObject[mainKey] &&
      formObject[mainKey][mainIndex] &&
      formObject[mainKey][mainIndex] &&
      formObject[mainKey][mainIndex][key] &&
      formObject[mainKey][mainIndex][key][index]
    ) {
      if (formObject[mainKey][mainIndex][key][index]['Comments'] !== undefined) {
        formObject[mainKey][mainIndex][key][index]['Comments'].push(
          ...value.filter((item) => {
            return item.Id === undefined;
          })
        );
        formObject[mainKey][mainIndex][key][index]['Comments'] = formObject[mainKey][mainIndex][
          key
        ][index]['Comments'].filter((obj, Index) => {
          return (
            Index ===
            formObject[mainKey][mainIndex][key][index]['Comments'].findIndex(
              (o) => obj.Comment === o.Comment
            )
          );
        });
      } else {
        formObject[mainKey][mainIndex][key][index]['Comments'] = value;
      }
    } else if (formObject && formObject[key] && formObject[key][index]) {
      if (
        formObject[key][index]['Comments'] !== undefined &&
        Array.isArray(formObject[key][index]['Comments'])
      ) {
        formObject[key][index]['Comments'].push(
          ...value.filter((item) => {
            return item.Id === undefined;
          })
        );
        formObject[key][index]['Comments'] = formObject[key][index]['Comments'].filter(
          (obj, Index) => {
            return (
              Index ===
              formObject[key][index]['Comments'].findIndex((o) => obj.Comment === o.Comment)
            );
          }
        );
      } else {
        formObject[key][index]['Comments'] = value;
      }
    } else {
      if (formObject['Comments'] !== undefined) {
        formObject['Comments'].push(
          ...value.filter((item) => {
            return item.Id === undefined;
          })
        );
        formObject['Comments'] = formObject['Comments'].filter((obj, Index) => {
          return Index === formObject['Comments'].findIndex((o) => obj.Comment === o.Comment);
        });
      } else {
        formObject['Comments'] = value;
      }
    }
    this.setState({
      formObject,
      useCaseModalVisible: false,
      AnalysisTaskModalVisible: false,
      PredictionTaskModalVisible: false,
      remarksModalVisible: false
    });
    this.formHeader.current.setFieldsValue(formObject);
  };
  onViewTypeChange = (type, viewType) => {
    if (type === 'Analysis Tasks') {
      this.setState({ viewType });
    } else if (type === 'Prediction Tasks') {
      this.setState({ predictionViewType: viewType });
    }
  };
  fileUpload = (info, key, index, mainKey, mainIndex) => {
    let { formObject } = this.state;
    // let formObject = this.formHeader.current.getFieldsValue();
    if (info.file.status === 'done') {
      let id =
        info && info.file && info.file.response && info.file.response && info.file.response.Id;
      let Name =
        info && info.file && info.file.response && info.file.response && info.file.response.Name;
      if (
        formObject &&
        formObject[mainKey] &&
        formObject[mainKey][mainIndex] &&
        formObject[mainKey][mainIndex] &&
        formObject[mainKey][mainIndex][key] &&
        formObject[mainKey][mainIndex][key][index]
      ) {
        if (formObject[mainKey][mainIndex][key][index]['Attachments']) {
          formObject[mainKey][mainIndex][key][index]['Attachments'] =
            this.state.formObject &&
            this.state.formObject[mainKey] &&
            this.state.formObject[mainKey][mainIndex] &&
            this.state.formObject[mainKey][mainIndex][key] &&
            this.state.formObject[mainKey][mainIndex][key][index] &&
            this.state.formObject[mainKey][mainIndex][key][index]['Attachments']
              ? this.state.formObject[mainKey][mainIndex][key][index]['Attachments']
              : [];
          formObject[mainKey][mainIndex][key][index]['Attachments'].push({
            Id: id,
            file: Name
          });
        } else {
          formObject[mainKey][mainIndex][key][index]['Attachments'] = [];
          formObject[mainKey][mainIndex][key][index]['Attachments'].push({
            Id: id,
            file: Name
          });
        }
      } else if (formObject && formObject[key] && formObject[key][index]) {
        if (formObject[key][index]['Attachments']) {
          formObject[key][index]['Attachments'] =
            this.state.formObject &&
            this.state.formObject[key] &&
            this.state.formObject[key][index] &&
            this.state.formObject[key][index]['Attachments']
              ? this.state.formObject[key][index]['Attachments']
              : [];

          formObject[key][index]['Attachments'].push({ Id: id, file: Name });
        } else {
          formObject[key][index]['Attachments'] = [];
          formObject[key][index]['Attachments'].push({ Id: id, file: Name });
        }
      } else {
        if (formObject['Attachments']) {
          formObject['Attachments'] =
            this.state.formObject && this.state.formObject['Attachments']
              ? this.state.formObject['Attachments']
              : [];
          formObject['Attachments'].push({ Id: id, file: Name });
        } else {
          formObject['Attachments'] = [];
          formObject['Attachments'].push({ Id: id, file: Name });
        }
      }
      this.setState({ formObject });
      this.formHeader.current.setFieldsValue(formObject);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  handleClick = (e) => {
    if (e === 'Add New Attachments') {
      this.myRef.current.click();
    } else {
      this.setState({ showusecasedownloadModal: true });
    }
  };
  handleAnalysisTasksClick = (e, key) => {
    let { formObject } = this.state;
    if (e === 'Add New Attachments') {
      this.analysisRef.current.click();
    } else {
      this.setState({
        AnalysisfileModalVisible: true,
        AnalysisTasksdataSource:
          formObject &&
          formObject['AnalysisTasks'] &&
          formObject['AnalysisTasks'][key] &&
          formObject['AnalysisTasks'][key].Attachments &&
          Array.isArray(formObject['AnalysisTasks'][key].Attachments) &&
          formObject['AnalysisTasks'][key].Attachments
            ? [...formObject['AnalysisTasks'][key].Attachments]
            : []
      });
    }
  };
  handlePredictionTasksClick = (e, key) => {
    let { formObject } = this.state;
    if (e === 'Add New Attachments') {
      this.predictionRef.current.click();
    } else {
      this.setState({
        PredictionfileModalVisible: true,
        PredictiondataSource:
          formObject &&
          formObject['PredictionTasks'] &&
          formObject['PredictionTasks'][key] &&
          formObject['PredictionTasks'][key].Attachments &&
          Array.isArray(formObject['PredictionTasks'][key].Attachments) &&
          formObject['PredictionTasks'][key].Attachments
            ? [...formObject['PredictionTasks'][key].Attachments]
            : []
      });
    }
  };
  handleAnalysisResultsClick = (e, key, index2) => {
    let { formObject } = this.state;
    if (e === 'Add New Attachments') {
      this.analysisresultRef.current.click();
    } else {
      this.setState({
        AnalysisResultsFileModalVisisble: true,
        AnalysisResultsdataSource:
          formObject &&
          formObject['AnalysisTasks'] &&
          formObject['AnalysisTasks'][key] &&
          formObject['AnalysisTasks'][key] &&
          formObject['AnalysisTasks'][key]['Results'] &&
          formObject['AnalysisTasks'][key]['Results'][index2] &&
          formObject['AnalysisTasks'][key]['Results'][index2]['Attachments'] &&
          formObject['AnalysisTasks'][key]['Results'][index2]['Attachments']
            ? [...formObject['AnalysisTasks'][key]['Results'][index2]['Attachments']]
            : []
      });
    }
  };
  handlePredictionResultsClick = (e, key, index2) => {
    let { formObject } = this.state;
    if (e === 'Add New Attachments') {
      this.predictionResultRef.current.click();
    } else {
      this.setState({
        PredictionResultsFileModalVisible: true,
        PredictionResultsdataSource:
          formObject &&
          formObject['PredictionTasks'] &&
          formObject['PredictionTasks'][key] &&
          formObject['PredictionTasks'][key] &&
          formObject['PredictionTasks'][key]['Results'] &&
          formObject['PredictionTasks'][key]['Results'][index2] &&
          formObject['PredictionTasks'][key]['Results'][index2]['Attachments'] &&
          formObject['PredictionTasks'][key]['Results'][index2]['Attachments']
            ? [...formObject['PredictionTasks'][key]['Results'][index2]['Attachments']]
            : []
      });
    }
  };
  add3Dots = (text, limit) => {
    let dots = '...';
    if (text.length > limit) {
      text = text.substring(0, limit) + dots;
    }
    return text;
  };
  onChange = (value) => {
    this.setState({
      selectedDate: {
        startDate: moment(value[0]).format('YYYY-MM-DD HH:mm'),
        endDate: moment(value[1]).format('YYYY-MM-DD HH:mm')
      }
    });
  };
  editAnalyticTaskData = (data, id, i) => {
    this.setState({
      activeKey: '2',
      myId: id,
      myIndex: i,
      visible: false,
      addanalyticData: false,
      ResultModalVisible: true
    });
  };
  editPredictionData = (data, id, i) => {
    this.setState({
      predictionActivekey: '2',
      PredictionId: id,
      PredictionIndex: i,
      addPredictionData: false,
      PredictionResult: true
    });
  };
  deletePredictionTasks = (index, id) => {
    let { formObject } = this.state;
    let { isEditable } = this.props;
    if (isEditable !== 'new') {
      let array = formObject.PredictionTasks;
      array.splice(id, 1); // Mutates the original array
      this.setState({
        formObject: { ...formObject, PredictionTasks: array }
      });
    } else if (isEditable === 'new') {
      let newarray = formObject.PredictionTasks.filter((item, i) => i !== index);
      delete newarray[id];
      this.setState({
        formObject: { ...formObject, PredictionTasks: newarray }
      });
    }
  };

  deleteAnalyticTable = (id) => {
    const { formObject } = this.state;
    let arr = formObject.AnalysisTasks;
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    arr.splice(objWithIdIndex, 1);
    this.setState({
      formObject: { ...formObject, AnalysisTasks: arr }
    });
  };
  deletePredictionTable = (id) => {
    const { formObject } = this.state;
    let arr = formObject.PredictionTasks;
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    arr.splice(objWithIdIndex, 1);

    this.setState({
      formObject: { ...formObject, PredictionTasks: arr }
    });
  };

  deleteAnalyticTask = (index, id) => {
    const { formObject } = this.state;
    const { isEditable } = this.props;
    if (isEditable !== 'new') {
      let array = formObject.AnalysisTasks;
      array.splice(id, 1); // Mutates the original array
      this.setState({
        formObject: { ...formObject, AnalysisTasks: array }
      });
    } else if (isEditable === 'new') {
      let newarray = formObject.AnalysisTasks.filter((item, i) => i !== index);
      delete newarray[id];
      this.setState({ formObject: { ...formObject, AnalysisTasks: newarray } });
    }
  };

  deleteResultTasks = (mainKey, mainIndex, key, index) => {
    const { formObject } = this.state;
    if (mainKey === 'AnalysisTasks') {
      const newArray = formObject[mainKey][mainIndex].Results.filter((item, i) => i !== index);
      this.setState({
        formObject: {
          ...formObject,
          AnalysisTasks: [{ ...formObject, Results: newArray }]
        }
      });
    } else if (mainKey === 'PredictionTasks') {
      const newArray = formObject[mainKey][mainIndex].Results.filter((item, i) => i !== index);
      this.setState({
        formObject: {
          ...formObject,
          PredictionTasks: [{ ...formObject, Results: newArray }]
        }
      });
    }
  };

  editAnalyticTable = (id) => {
    const { formObject } = this.state;
    this.setState({
      activeKey: '2',
      formObject,
      anaLyticTableEdit: true,
      AnalyticTableId: id
    });
  };
  editPredictionable = (id) => {
    const { formObject } = this.state;
    this.setState({
      predictionActivekey: '2',
      formObject,
      PredictionTableId: id
    });
  };
  editAnalyticResult = (item, itemId, resInd, key) => {
    if (key === 'PredictionTasks') {
      this.setState({
        isPredictionResultVisible: true,
        PredictionResultInd: resInd
      });
    } else if (key === 'AnalysisTasks') {
      this.setState({
        isAanalyticResultVisible: true,
        ResultIndex: resInd
      });
    }
  };
  handleChange = (value, index, key) => {
    const { formObject } = this.state;
    formObject.AnalysisTasks.map((item, colIndex) => {
      if (colIndex === index) {
        item[key] = value;
      }
      return {};
    });

    // formObject.AnalysisTasks[index][key] = value;
    this.setState({ formObject });
  };
  addPredictions = () => {
    const { isEditable } = this.props;
    const { PredictionTasks, PredtictionTaskId } = this.state;

    let obj = {
      Title: '',
      functionalDescription: '',
      RelatedTags: '',
      CreatedBy:
        this.props &&
        this.props.userProfile &&
        this.props.userProfile.user &&
        this.props.userProfile.user.Id,
      CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      Assigne: '',
      Comments: [],
      Attachments: [],
      Results: []
    };
    if (isEditable !== 'new') {
      let { formObject } = this.state;
      formObject && formObject.PredictionTasks && formObject.PredictionTasks.push(obj);
      this.setState({
        formObject,
        predictionActivekey: '2',
        addPredictionData: true,
        prediction: false,
        PredtictionTaskId: PredtictionTaskId + 1
      });
    } else if (isEditable === 'new') {
      let { formObject } = this.formHeader.current.getFieldsValue();
      // this.state;
      formObject['PredictionTasks'] = [];
      this.setState({ formObject });
      PredictionTasks.push(obj);
      formObject = {
        ...formObject,
        PredictionTasks: PredictionTasks
      };
      this.setState({
        formObject,
        predictionActivekey: '2',
        addPredictionData: true,
        prediction: false
      });
    }
  };

  addAnalytics = () => {
    let { isEditable } = this.props;
    const { AnalysisTasks } = this.state;
    let obj = {
      Title: '',
      functionalDescription: '',
      RelatedTags: '',
      CreatedBy:
        this.props &&
        this.props.userProfile &&
        this.props.userProfile.user &&
        this.props.userProfile.user.Id,
      CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      Assigne: '',
      Comments: [],
      Attachments: [],
      Results: []
    };
    if (isEditable !== 'new') {
      let { formObject } = this.state;
      formObject && formObject.AnalysisTasks && formObject.AnalysisTasks.push(obj);
      this.setState({
        formObject: { ...formObject },
        activeKey: '2',
        addanalyticData: true
      });
    } else if (isEditable === 'new') {
      let formObject = this.formHeader.current.getFieldsValue();
      formObject['AnalysisTasks'] = [];
      this.setState({ formObject });
      AnalysisTasks.push(obj);
      formObject = {
        ...formObject,
        AnalysisTasks: AnalysisTasks
      };
      this.setState({
        formObject,
        activeKey: '2',
        addanalyticData: true,
        AnalysisTasks
      });
    }
  };
  addResultAnalytics = (analyticIndex) => {
    const { formObject } = this.state;
    if (
      formObject.AnalysisTasks[analyticIndex] &&
      !formObject.AnalysisTasks[analyticIndex].Results
    ) {
      formObject.AnalysisTasks[analyticIndex]['Results'] = [];
    }
    let resultObject = {
      Description: '',
      Comments: [],
      Attachments: [],
      CreatedBy:
        this.props &&
        this.props.userProfile &&
        this.props.userProfile.user &&
        this.props.userProfile.user.Id,
      CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss')
    };
    formObject &&
      formObject.AnalysisTasks &&
      formObject.AnalysisTasks[analyticIndex] &&
      formObject.AnalysisTasks[analyticIndex].Results &&
      formObject.AnalysisTasks[analyticIndex].Results.push(resultObject);
    this.setState({
      formObject: { ...formObject },
      isAanalyticResultVisible: true,
      ResultModalVisible: false,
      addNewResult: true
    });
  };
  addPredictionResults = (predictionId) => {
    const { formObject } = this.state;
    if (
      formObject.PredictionTasks[predictionId] &&
      !formObject.PredictionTasks[predictionId].Results
    ) {
      formObject.PredictionTasks[predictionId]['Results'] = [];
    }
    let resultObject = {
      Description: '',
      CreatedBy:
        this.props &&
        this.props.userProfile &&
        this.props.userProfile.user &&
        this.props.userProfile.user.Id,
      CreatedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      Comments: [],
      Attachments: []
    };
    formObject &&
      formObject.PredictionTasks &&
      formObject.PredictionTasks[predictionId] &&
      formObject.PredictionTasks[predictionId].Results &&
      formObject.PredictionTasks[predictionId].Results.push(resultObject);

    this.setState({
      formObject: { ...formObject },
      isPredictionResultVisible: true,
      PredictionResult: false,
      addNewPredictionResult: true
    });
  };
  handleOk = () => {
    let { formObject } = this.state;
    this.setState({
      isAanalyticResultVisible: false,
      ResultModalVisible: true,
      addNewResult: false,
      formObject
    });
  };
  handleOkPrediction = () => {
    this.setState({
      isPredictionResultVisible: false,
      PredictionResult: true,
      addNewPredictionResult: false
    });
  };
  handleCancelPrediction = () => {
    this.setState({
      isPredictionResultVisible: false,
      addNewPredictionResult: true
    });
  };

  handleCancel = () => {
    this.setState({ isAanalyticResultVisible: false, addNewResult: true });
  };

  onPrediction = (e) => {
    const { formObject } = this.state;
    this.setState({ selectedTask: e.target.value, formObject });
  };
  onResultTaskHandleChange = (value, mainKey, mainIndex, resultKey, resultIndex, key) => {
    const { formObject } = this.state;
    formObject[mainKey][mainIndex][resultKey][resultIndex][key] = value;
    this.setState({ formObject });
  };
  onHandleChangeAnalysis = (value, TaskKey, index, key) => {
    const { formObject } = this.state;
    formObject[TaskKey][index][key] = value;
    this.setState({ formObject });
  };
  disabledDate = (current) => {
    return current > moment().startOf('day').add(1, 'day');
  };

  onHandleChangePrediction = (value, TaskKey, index, key) => {
    const { formObject } = this.state;
    formObject[TaskKey][index][key] = value;
    if (key === 'Date') {
      this.setState({
        formObject: {
          ...formObject,
          TaskKey: {
            ...formObject[TaskKey],
            Date: [
              moment(value[0]).format('YYYY-MM-DD HH:mm'),
              moment(value[1]).format('YYYY-MM-DD HH:mm')
            ]
          }
        }
      });
    }

    this.setState({ formObject });
  };
  render() {
    let {
      formObject,
      useCaseModalVisible,
      PredictionTaskModalVisible,
      AnalysisTaskModalVisible,
      users,

      remarksModalVisible,
      relatedTags,
      showusecasedownloadModal,
      commentsData,
      AnalysisfileModalVisible,
      PredictionfileModalVisible,
      AnalysisResultsFileModalVisisble,
      PredictionResultsFileModalVisible,
      AnalysisTasksdataSource,
      PredictiondataSource,
      AnalysisResultsdataSource,
      PredictionResultsdataSource,
      selectedTask,
      StatusList,
      activeKey,
      predictionActivekey,
      viewType,
      predictionViewType,
      isAanalyticResultVisible,
      isPredictionResultVisible,
      ResultIndex,
      addNewResult,
      addNewPredictionResult,
      PredictionResultInd,
      addPredictionData,

      PredictionIndex,

      AnalyticTableId,
      PredictionTableId
    } = this.state;
    const IconText = ({ icon, text }) => (
      <Space>
        {React.createElement(icon)}
        {text}
      </Space>
    );
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    let userName =
      this.props &&
      this.props.userProfile &&
      this.props.userProfile.user &&
      this.props.userProfile.user.Id;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    let columns = [
      {
        title: 'File',
        dataIndex: 'file',
        key: 'file',
        align: 'center'
      },
      {
        title: 'Action',
        width: '600',
        align: 'center',
        render: (text, record) => (
          <>
            <Tooltip placement="top" key="download" title="Download">
              <div style={{ marginLeft: '-25px' }}>
                <a href={`http://192.168.95.23:3001/api/IOTAAttachments/${record.file}`}>
                  {' '}
                  <DownloadOutlined style={{ color: '#1a91ff' }} />
                </a>
              </div>
            </Tooltip>
          </>
        )
      }
    ];
    let AnalyticsColumns = [
      {
        title: 'Title',
        dataIndex: 'Title',
        key: 'Title',
        align: 'center',
        width: '20%'
      },
      {
        title: 'Functional Description',
        dataIndex: 'functionalDescription',
        key: 'functionalDescription',
        align: 'center',
        width: '60%'
      },
      {
        title: 'Action',
        align: 'center',
        width: '20%',
        render: (text, record) => (
          <>
            <Button
              className="ant-btn"
              onClick={() => this.editAnalyticTable(record.Id, record)}
              size="middle"
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Are you sure that you want to delete the Task"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this.deleteAnalyticTable(record.Id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="ant-btn" size="middle" style={{ marginLeft: '20px' }}>
                <span style={{ color: '#ff4d4f' }}>
                  {' '}
                  <DeleteOutlined />
                </span>
              </Button>
            </Popconfirm>
          </>
        )
      }
    ];
    let PredictionColumns = [
      {
        title: 'Title',
        dataIndex: 'Title',
        key: 'Title',
        align: 'center',
        width: '20%'
      },
      {
        title: 'Functional Description',
        dataIndex: 'functionalDescription',
        key: 'functionalDescription',
        align: 'center',
        width: '60%'
      },
      {
        title: 'Action',
        align: 'center',
        width: '20%',
        render: (text, record) => (
          <>
            <Button
              className="ant-btn"
              onClick={() => this.editPredictionable(record.Id, record)}
              size="middle"
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Are you sure that you want to delete the Task"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this.deletePredictionTable(record.Id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="ant-btn" size="middle" style={{ marginLeft: '20px' }}>
                <span style={{ color: '#ff4d4f' }}>
                  {' '}
                  <DeleteOutlined />
                </span>
              </Button>
            </Popconfirm>
          </>
        )
      }
    ];
    return (
      // <StyledComponent theme={themeSettings} style={{ padding: '0px 15px' }}>
      <>
        <div
          className="PredictionForm"
          style={{
            minHeight: '700px',
            // background: 'rgb(26, 59, 92)',
            marginTop: '30px',
            marginBottom: '30px',
            color: 'black',
            fontWeight: 'bold'
          }}
        >
          <>
            <Form
              name="TaskData"
              className="collapse"
              ref={this.formHeader}
              initialValues={formObject}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              layout="horizontal"
              labelCol={{ flex: '130px' }}
              labelAlign="right"
              labelWrap
              wrapperCol={{ flex: 1 }}
            >
              <Form.Item name={'Id'} style={{ display: 'none' }}></Form.Item>

              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  label="Title"
                  name={'Title'}
                  style={{ width: '100%', flex: '50%' }}
                  rules={[{ required: true, message: 'Please Provide Title' }]}
                >
                  <Input
                    style={{ width: '70%' }}
                    maxLength={10000}
                    size="large"
                    placeholder="Input Title"
                  />
                </Form.Item>

                <Form.Item
                  label="Related Tags"
                  name="RelatedTags"
                  style={{ width: '100%', flex: '50%' }}
                >
                  <Select mode="multiple" placeholder="Select Tags" style={{ width: '70%' }}>
                    {relatedTags &&
                      Array.isArray(relatedTags) &&
                      relatedTags.map((item, index) => {
                        return (
                          <Option key={index} value={item.Value}>
                            {item.Key}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label="Created By"
                  name={'CreatedBy'}
                >
                  <Select
                    disabled
                    suffix={<UserOutlined />}
                    size="large"
                    placeholder="Select CreatedBy"
                    style={{ width: '70%' }}
                  >
                    {users &&
                      Array.isArray(users) &&
                      users.map((item, index) => {
                        return (
                          <option key={index} value={item.Id}>
                            {item.FirstName}
                          </option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label="Created Date"
                  name={'CreatedDate'}
                >
                  <Input
                    disabled
                    size="large"
                    style={{ width: '70%' }}
                    placeholder="CreatedDate"
                    suffix={<FieldTimeOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  label="Usecase"
                  name={'Usecase'}
                  style={{ width: '100%' }}
                  rules={[{ required: true, message: 'Please Provide Usecase' }]}
                >
                  <TextArea
                    style={{
                      height: 100,
                      width: '86%'
                    }}
                    maxLength={10000}
                    placeholder="Input Usecase"
                  />
                </Form.Item>
                <Form.Item
                  label="Functional Description"
                  name={'functionalDescription'}
                  style={{ width: '100%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please Provide FunctionalDescription'
                    }
                  ]}
                >
                  <TextArea
                    maxLength={10000}
                    style={{
                      height: 100,
                      width: '86%'
                    }}
                    placeholder="Input FunctionalDescription"
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  name="Attachments"
                  label="Attachments"
                >
                  <Select
                    size="large"
                    style={{ width: '70%' }}
                    placeholder={'Add/View Attachments'}
                    onChange={(e) => this.handleClick(e)}
                  >
                    <Option key={'View Attachments'} value={'View Attachments'}>
                      View Attachments
                    </Option>
                    <Option key={'Add New Attachments'} value={'Add New Attachments'}>
                      Add New Attachments
                    </Option>
                  </Select>
                  <Upload
                    multiple={true}
                    // ref={this.myRef}
                    onChange={(info) => this.fileUpload(info)}
                    action={`${ANALYTICS.FileUpload}?SiteId=${siteId}&Type=${'UseCase'}`}
                    headers={{
                      Authorization: Authorization
                    }}
                    showUploadList={false}
                  >
                    <Button style={{ display: 'none' }} ref={this.myRef} className="uploadFile">
                      <UploadOutlined /> Upload
                    </Button>
                  </Upload>
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label="Comments"
                  name={'Comments'}
                >
                  <Tooltip
                    placement="topLeft"
                    color={'red'}
                    title="Click Here To Add Your Comments"
                  >
                    <Badge
                      count={formObject && formObject['Comments'] && formObject['Comments'].length}
                    >
                      <Button
                        style={{ background: '#eabe6f', color: 'black' }}
                        onClick={() => this.setState({ useCaseModalVisible: true })}
                      >
                        <CommentOutlined />
                        Add/View Comments
                      </Button>
                    </Badge>
                  </Tooltip>
                </Form.Item>

                {useCaseModalVisible && useCaseModalVisible === true ? (
                  <CommentsForm
                    Comments={
                      formObject && formObject['Comments'] && formObject['Comments'] !== undefined
                        ? [...formObject['Comments']]
                        : []
                    }
                    Title={'UseCase Comments'}
                    visible={useCaseModalVisible}
                    modalClose={this.useCaseModalClose}
                    user={userName}
                    submitComments={this.SubmitComments}
                  />
                ) : null}
              </div>

              <Drawer
                title="UseCase Uploaded Files"
                open={showusecasedownloadModal}
                width={600}
                onOk={() => this.setState({ showusecasedownloadModal: false })}
                onClose={() => this.setState({ showusecasedownloadModal: false })}
              >
                <StyledTable theme={themeSettings}>
                  <Table
                    className="UseCaseUploadFilesTables"
                    columns={columns}
                    dataSource={
                      formObject &&
                      formObject.Attachments &&
                      Array.isArray(formObject.Attachments) &&
                      formObject.Attachments
                        ? [...formObject.Attachments]
                        : []
                    }
                  />
                </StyledTable>
              </Drawer>

              <Radio.Group
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  color: 'white !important'
                }}
                value={selectedTask}
                buttonStyle="solid"
                onChange={(e) =>
                  this.setState({
                    selectedTask: e.target.value,
                    formObject: formObject
                  })
                }
              >
                <Radio style={{ color: 'white' }} value="Analysis Tasks">
                  Analysis Tasks
                </Radio>
                <Radio
                  style={{ color: 'white' }}
                  value="Prediction Tasks"
                  // onChange={(e) => this.onPrediction(e)}
                >
                  Prediction Tasks
                </Radio>
              </Radio.Group>

              {selectedTask && selectedTask === 'Analysis Tasks' ? (
                <div style={{ background: 'rgb(26, 59, 92)', padding: '20px' }}>
                  {AnalysisTaskModalVisible && AnalysisTaskModalVisible === true ? (
                    <CommentsForm
                      visible={AnalysisTaskModalVisible}
                      modalClose={this.AnalysisTaskModalClose}
                      keyName={'AnalysisTasks'}
                      Title={'AnalysisTask Comments'}
                      {...{ ...commentsData }}
                      user={userName}
                      formObject={
                        this.formHeader.current && this.formHeader.current.getFieldsValue()
                          ? this.formHeader.current.getFieldsValue()
                          : {}
                      }
                      submitComments={this.SubmitComments}
                    />
                  ) : null}
                  <div style={{ float: 'right' }}>
                    {activeKey === '1' ? (
                      <PlusOutlined
                        onClick={() => this.addAnalytics()}
                        style={{
                          fontSize: '25px',
                          color: viewType === 'table' ? 'rgb(255, 108, 0)' : '#dfa230',
                          marginLeft: '10px'
                        }}
                      />
                    ) : null}

                    <UnorderedListOutlined
                      onClick={() => this.onViewTypeChange(selectedTask, 'table')}
                      style={{
                        fontSize: '25px',
                        color: viewType === 'table' ? 'rgb(255, 108, 0)' : '#dfa230',
                        marginLeft: '10px'
                      }}
                    />
                    <AppstoreOutlined
                      onClick={() => this.onViewTypeChange(selectedTask, 'card')}
                      style={{
                        fontSize: '25px',
                        color: viewType === 'card' ? 'rgb(255, 108, 0)' : '#dfa230',
                        marginLeft: '10px'
                      }}
                    />
                  </div>
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    activeKey={activeKey}
                    style={{ color: 'black' }}
                    onChange={async (activeKey) => {
                      if (activeKey === '2') {
                        this.setState({ activeKey });
                      }
                      if (activeKey === '1') {
                        this.setState({ activeKey });
                      }
                      this.setState({ activeKey });
                    }}
                  >
                    <TabPane tab="List Of Analytics" key="1">
                      {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={'selectFilter'} style={{ padding: '5px' }}>
                    <Select
                className={'selectFilter'}
                showSearch
                placeholder="Select Attribute"
                allowClear
                value={this.state.filterKey}
                style={{
                  background: '#1a3652',
                  borderRight: 'none',
                  borderTop: 'none',
                  borderLeft: 'none',
                  width: '20%',
                  padding: '5px'
                }}
                onChange={(e) => this.onhandleChange(e)}
              >
                 <Select.Option key="Task">Task</Select.Option>
                <Select.Option key="Title">Title</Select.Option>
                <Select.Option key="UserId">CreatedBy</Select.Option>
                <Select.Option key="CreatedDate">CreatedDate</Select.Option>
                <Select.Option key="Assigne">Assigne</Select.Option>
              </Select>
              <Input
                type="text"
                style={{
                  background: '#1a3652',
                  borderRight: 'none',
                  borderTop: 'none',
                  color: 'white',
                  borderLeft: 'none',
                  width: '20%',
                  padding: '5px'
                }}
                id="searchText"
                value={this.state.searchField}
                onChange={(e) => {
                  this.setState({ searchField: e.target.value });
                }}
                // onKeyPress={(e) => {
                //   if (e.key === 'Enter') {
                //     this.onSearch(this.state.searchField);
                //   }
                // }}
                placeholder="Search"
              />
              <Button onClick={(e) =>this.onSearch(e)}>Search </Button>
              </div>
              </div> */}
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {activeKey && activeKey === '1' && viewType === 'card' ? (
                          formObject && formObject.AnalysisTasks ? (
                            formObject &&
                            formObject.AnalysisTasks.map((item, i) => {
                              return (
                                <Card
                                  key={item.title}
                                  style={{
                                    width: '410px',
                                    padding: '5px',
                                    margin: '5px'
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column'
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: '0px 0px 6px 0px',
                                        fontSize: '18px',
                                        fontWeight: 'bolder',
                                        color: '#d98e04'
                                      }}
                                    >
                                      {this.add3Dots(item.functionalDescription, 35)}
                                    </div>

                                    <div style={{ margin: '0px 0px 7px 0px' }}>
                                      <IconText
                                        icon={UserOutlined}
                                        text={
                                          this.props &&
                                          this.props.userProfile &&
                                          this.props.userProfile.user &&
                                          this.props.userProfile.user.FirstName
                                        }
                                        key="list-vertical-star-o"
                                      />
                                    </div>
                                    <div style={{ margin: '0px 0px 7px 0px' }}>
                                      <IconText
                                        icon={CalendarOutlined}
                                        text={moment(item.CreatedDate).format(
                                          'DD-MM-YYYY HH:mm:ss'
                                        )}
                                        key="list-vertical-like-o"
                                      />
                                    </div>
                                  </div>
                                  <Divider
                                    className="customDividerHor"
                                    style={{
                                      borderTop: '1px solid #000',
                                      height: '0px'
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      margin: '0px 0px',
                                      fontSize: '18px'
                                    }}
                                  >
                                    <Tooltip placement="top" key="Edit" title="Edit">
                                      <EditOutlined
                                        style={{
                                          color: '#d98e04',
                                          marginRight: '8px'
                                        }}
                                        onClick={() => this.editAnalyticTaskData(item, item.Id, i)}
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Popconfirm
                                      key="1"
                                      title="Are you sure that you want to delete the Task"
                                      icon={<QuestionCircleOutlined style={{ color: '#d98e04' }} />}
                                      onConfirm={() => this.deleteAnalyticTask(item.Id, i)}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Tooltip placement="top" key="Delete" title="Delete">
                                        <DeleteOutlined
                                          style={{
                                            color: '#d98e04',
                                            marginRight: '8px'
                                          }}
                                        />
                                      </Tooltip>
                                    </Popconfirm>
                                  </div>
                                </Card>
                              );
                            })
                          ) : null
                        ) : (
                          //  ) : null
                          <StyledTable theme={themeSettings}>
                            <Table
                              dataSource={
                                formObject &&
                                formObject.AnalysisTasks &&
                                Array.isArray(formObject.AnalysisTasks)
                                  ? formObject.AnalysisTasks
                                  : []
                              }
                              columns={AnalyticsColumns}
                            />
                          </StyledTable>
                        )}
                      </div>
                    </TabPane>
                    <TabPane key="2" tab="Details Of Analytics">
                      {formObject && formObject.AnalysisTasks !== undefined
                        ? formObject.AnalysisTasks.map((item, index) => (
                            <>
                              {this.state.myIndex === index ||
                              (formObject.AnalysisTasks[formObject.AnalysisTasks.length - 1] &&
                                this.state.addanalyticData === true) ||
                              (this.state.anaLyticTableEdit === true &&
                                AnalyticTableId === formObject.AnalysisTasks[index].Id) ? (
                                <div
                                  className="DetailsOfAnalyticsForm"
                                  style={{ display: 'flex', flexWrap: 'wrap', color: 'black' }}
                                  key={index}
                                >
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    label="Title"
                                    name={['AnalysisTasks', index, 'Title']}
                                    style={{ flex: '100%' }}
                                    onChange={(e) =>
                                      this.onHandleChangeAnalysis(
                                        e.target.value,
                                        'AnalysisTasks',
                                        index,
                                        'Title'
                                      )
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Provide Title'
                                      }
                                    ]}
                                  >
                                    <Input
                                      maxLength={10000}
                                      size="large"
                                      style={{ width: '50%' }}
                                      placeholder="Input Title"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    name={['AnalysisTasks', index, 'functionalDescription']}
                                    onChange={(e) =>
                                      this.onHandleChangeAnalysis(
                                        e.target.value,
                                        'AnalysisTasks',
                                        index,
                                        'functionalDescription'
                                      )
                                    }
                                    label="Functional Description"
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Provide FunctionalDescription'
                                      }
                                    ]}
                                  >
                                    <TextArea
                                      maxLength={10000}
                                      style={{
                                        height: 100,
                                        width: '50%'
                                      }}
                                      placeholder="Input FunctionalDescription"
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    name={['AnalysisTasks', index, 'RelatedTags']}
                                    labelCol={{ flex: '130px' }}
                                    style={{ flex: '100%' }}
                                    label="RelatedTags"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'RelatedTags is required'
                                      }
                                    ]}
                                  >
                                    <Select
                                      style={{ width: '50%' }}
                                      size="large"
                                      mode="multiple"
                                      onChange={(e) =>
                                        this.onHandleChangeAnalysis(
                                          e,
                                          'AnalysisTasks',
                                          index,
                                          'RelatedTags'
                                        )
                                      }
                                      placeholder="Select Tags"
                                    >
                                      {relatedTags &&
                                        Array.isArray(relatedTags) &&
                                        relatedTags.map((item, index) => {
                                          return (
                                            <Option key={index} value={item.Value}>
                                              {item.Key}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    style={{ flex: '100%' }}
                                    //  name={['AnalysisTasks', index, 'CreatedBy']}

                                    label="CreatedBy"
                                    rules={[
                                      {
                                        required: false,
                                        message: 'CreatedBy is required'
                                      }
                                    ]}
                                  >
                                    <Select
                                      disabled
                                      size="large"
                                      placeholder="Select CreatedBy"
                                      value={
                                        this.props &&
                                        this.props.userProfile &&
                                        this.props.userProfile.user &&
                                        this.props.userProfile.user.Id
                                      }
                                      style={{ width: '50%' }}
                                      onChange={(e) =>
                                        this.onHandleChangeAnalysis(
                                          e,
                                          'AnalysisTasks',
                                          index,
                                          'CreatedBy'
                                        )
                                      }
                                    >
                                      {users &&
                                        Array.isArray(users) &&
                                        users.map((item) => {
                                          return (
                                            <option key={item.Id} value={item.Id}>
                                              {item.FirstName}
                                            </option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    //  name={['AnalysisTasks', index, 'CreatedDate']}
                                    onChange={(e) =>
                                      this.onHandleChangeAnalysis(
                                        e.target.value,
                                        'AnalysisTasks',
                                        index,
                                        'CreatedDate'
                                      )
                                    }
                                    label="CreatedDate"
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Date is required'
                                      }
                                    ]}
                                  >
                                    <Input
                                      disabled
                                      value={moment().format('YYYY-MM-DD HH:mm:ss')}
                                      size="large"
                                      style={{ width: '50%' }}
                                      placeholder="CreatedDate"
                                      suffix={<FieldTimeOutlined />}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    label="Assigne"
                                    name={['AnalysisTasks', index, 'Assigne']}
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Select Assigne!'
                                      }
                                    ]}
                                  >
                                    <Select
                                      size="large"
                                      placeholder="Select Assigne"
                                      onChange={(e) =>
                                        this.onHandleChangeAnalysis(
                                          e,
                                          'AnalysisTasks',
                                          index,
                                          'Assigne'
                                        )
                                      }
                                      style={{ width: '50%' }}
                                    >
                                      {users &&
                                        Array.isArray(users) &&
                                        users.map((item) => {
                                          return (
                                            <option key={item.Id} value={item.Id}>
                                              {item.FirstName}
                                            </option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    style={{ flex: '100%' }}
                                    label="Status"
                                    name={['AnalysisTasks', index, 'Status']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Select Status!'
                                      }
                                    ]}
                                  >
                                    <Select
                                      size="large"
                                      style={{ width: '50%' }}
                                      onChange={(e) =>
                                        this.onHandleChangeAnalysis(
                                          e,
                                          'AnalysisTasks',
                                          index,
                                          'Status'
                                        )
                                      }
                                      placeholder="Select Status"
                                    >
                                      {StatusList &&
                                        Array.isArray(StatusList) &&
                                        StatusList.map((item, index) => {
                                          return (
                                            <option key={index} value={item.Value}>
                                              {item.Key}
                                            </option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    name={['AnalysisTasks', index, 'Date']}
                                    label="From And To Date"
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Provide Date'
                                      }
                                    ]}
                                  >
                                    <RangePicker
                                      value={
                                        formObject &&
                                        formObject.AnalysisTasks &&
                                        formObject.AnalysisTasks[index] &&
                                        formObject.AnalysisTasks[index].Date
                                          ? [
                                              moment(
                                                formObject.AnalysisTasks[index].Date[0],
                                                dateFormat
                                              ),
                                              moment(
                                                formObject.AnalysisTasks[index].Date[1],
                                                dateFormat
                                              )
                                            ]
                                          : ''
                                      }
                                      onChange={(e) =>
                                        this.onHandleChangePrediction(
                                          e,
                                          'AnalysisTasks',
                                          index,
                                          'Date'
                                        )
                                      }
                                      disabledDate={(e) => this.disabledDate(e)}
                                      style={{ width: '50%' }}
                                      showTime={{
                                        format: 'HH:mm'
                                      }}
                                      format="YYYY-MM-DD HH:mm"
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    label="Approach"
                                    labelCol={{ flex: '130px' }}
                                    style={{ flex: '100%' }}
                                    name={['AnalysisTasks', index, 'Approach']}
                                    onChange={(e) =>
                                      this.onHandleChangeAnalysis(
                                        e.target.value,
                                        'AnalysisTasks',
                                        index,
                                        'Approach'
                                      )
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Provide Approach'
                                      }
                                    ]}
                                  >
                                    <Input
                                      //   showCount
                                      maxLength={10000}
                                      size="large"
                                      style={{ width: '50%' }}
                                      placeholder="Input Approach"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    label="Algorithms"
                                    name={['AnalysisTasks', index, 'Algorithms']}
                                    onChange={(e) =>
                                      this.onHandleChangeAnalysis(
                                        e.target.value,
                                        'AnalysisTasks',
                                        index,
                                        'Algorithms'
                                      )
                                    }
                                    labelCol={{ flex: '130px' }}
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Provide Algorithms'
                                      }
                                    ]}
                                  >
                                    <Input
                                      //   showCount
                                      maxLength={10000}
                                      size="large"
                                      style={{ width: '50%' }}
                                      placeholder="Input Algorithms"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    style={{ flex: '100%' }}
                                    name={['AnalysisTasks', index, 'Attachments']}
                                    label="Attachments"
                                  >
                                    <Select
                                      size="large"
                                      style={{ width: '50%' }}
                                      placeholder={'Add/View Attachments'}
                                      onChange={(e) => this.handleAnalysisTasksClick(e, index)}
                                    >
                                      <Option key={'View Attachments'} value={'View Attachments'}>
                                        View Attachments
                                      </Option>
                                      <Option
                                        key={'Add New Attachments'}
                                        value={'Add New Attachments'}
                                      >
                                        Add New Attachments
                                      </Option>
                                    </Select>
                                    <Upload
                                      multiple={true}
                                      onChange={(info) =>
                                        this.fileUpload(info, 'AnalysisTasks', index)
                                      }
                                      action={`${
                                        ANALYTICS.FileUpload
                                      }?SiteId=${siteId}&Type=${'Task'}`}
                                      headers={{
                                        Authorization: Authorization
                                      }}
                                      showUploadList={false}
                                    >
                                      <Button
                                        style={{ display: 'none' }}
                                        ref={this.analysisRef}
                                        className="uploadFile"
                                      >
                                        <UploadOutlined /> Upload
                                      </Button>
                                    </Upload>
                                    <Drawer
                                      title="AnalysisTask Uploaded Files"
                                      open={AnalysisfileModalVisible}
                                      width={600}
                                      onOk={() =>
                                        this.setState({
                                          AnalysisfileModalVisible: false
                                        })
                                      }
                                      onClose={() =>
                                        this.setState({
                                          AnalysisfileModalVisible: false
                                        })
                                      }
                                    >
                                      <StyledTable
                                        theme={themeSettings}
                                        className="AnalysisTaskUploadedFiles"
                                      >
                                        <Table
                                          columns={columns}
                                          dataSource={AnalysisTasksdataSource}
                                        />
                                      </StyledTable>
                                    </Drawer>
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    style={{ flex: '100%' }}
                                    label="Comments"
                                    name={['AnalysisTasks', index, 'Comments']}
                                    onChange={(e) =>
                                      this.onHandleChangeAnalysis(
                                        e.target.value,
                                        'AnalysisTasks',
                                        index,
                                        'Comments'
                                      )
                                    }
                                    // name={'Comments'}
                                  >
                                    <Tooltip
                                      placement="topLeft"
                                      color={'red'}
                                      title="Click Here To Add Your Comments"
                                    >
                                      <Badge
                                        count={
                                          formObject['AnalysisTasks'] &&
                                          formObject['AnalysisTasks'][index] &&
                                          formObject['AnalysisTasks'][index]['Comments'] &&
                                          formObject['AnalysisTasks'][index]['Comments'].length
                                        }
                                      >
                                        <Button
                                          style={{
                                            background: '#eabe6f',
                                            color: 'black'
                                          }}
                                          onClick={() => {
                                            let commentsData = {
                                              Comments:
                                                formObject &&
                                                formObject['AnalysisTasks'] &&
                                                formObject['AnalysisTasks'][index] &&
                                                formObject['AnalysisTasks'][index]['Comments']
                                                  ? [
                                                      ...formObject['AnalysisTasks'][index][
                                                        'Comments'
                                                      ]
                                                    ]
                                                  : [],
                                              index: index
                                            };
                                            this.setState({
                                              AnalysisTaskModalVisible: true,
                                              commentsData
                                            });
                                          }}
                                        >
                                          <CommentOutlined />
                                          Add/View Comments
                                        </Button>
                                      </Badge>
                                    </Tooltip>
                                  </Form.Item>
                                  <Button
                                    style={{
                                      background: '#29415a',
                                      color: '#fff'
                                    }}
                                    onClick={() => this.addResultAnalytics(index)}
                                  >
                                    <PlusOutlined /> Add Results
                                  </Button>
                                  <div style={{ marginTop: '60px' }}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap'
                                      }}
                                    >
                                      {formObject &&
                                      formObject.AnalysisTasks &&
                                      formObject.AnalysisTasks[index] &&
                                      formObject.AnalysisTasks[index].Results &&
                                      this.state.ResultModalVisible === true
                                        ? formObject.AnalysisTasks[index].Results.map(
                                            (item, resultIndex) => {
                                              return (
                                                <Card
                                                  key={item.title}
                                                  style={{
                                                    width: '410px',
                                                    padding: '5px',
                                                    margin: '5px'
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'column'
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        padding: '0px 0px 6px 0px',
                                                        fontSize: '18px',
                                                        fontWeight: 'bolder',
                                                        color: '#d98e04'
                                                      }}
                                                    >
                                                      {this.add3Dots(item.Description, 35)}
                                                    </div>

                                                    <div
                                                      style={{
                                                        margin: '0px 0px 7px 0px'
                                                      }}
                                                    >
                                                      <IconText
                                                        icon={UserOutlined}
                                                        text={
                                                          this.props &&
                                                          this.props.userProfile &&
                                                          this.props.userProfile.user &&
                                                          this.props.userProfile.user.FirstName
                                                        }
                                                        key="list-vertical-star-o"
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        margin: '0px 0px 7px 0px'
                                                      }}
                                                    >
                                                      <IconText
                                                        icon={CalendarOutlined}
                                                        text={moment(item.CreatedDate).format(
                                                          'DD-MM-YYYY HH:mm:ss'
                                                        )}
                                                        key="list-vertical-like-o"
                                                      />
                                                    </div>
                                                  </div>
                                                  <Divider
                                                    className="customDividerHor"
                                                    style={{
                                                      borderTop: '1px solid #000',
                                                      height: '0px'
                                                    }}
                                                  />
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'row',
                                                      margin: '0px 0px',
                                                      fontSize: '18px'
                                                    }}
                                                  >
                                                    <Tooltip
                                                      placement="top"
                                                      key="Edit"
                                                      title="Edit"
                                                    >
                                                      <EditOutlined
                                                        style={{
                                                          color: '#d98e04',
                                                          marginRight: '8px'
                                                        }}
                                                        onClick={() =>
                                                          this.editAnalyticResult(
                                                            item,
                                                            item.Id,
                                                            resultIndex,
                                                            'AnalysisTasks'
                                                          )
                                                        }
                                                      />
                                                    </Tooltip>
                                                    <Divider type="vertical" />
                                                    <Popconfirm
                                                      key="1"
                                                      title="Are you sure that you want to delete the Result"
                                                      icon={
                                                        <QuestionCircleOutlined
                                                          style={{
                                                            color: '#d98e04'
                                                          }}
                                                        />
                                                      }
                                                      onConfirm={() =>
                                                        this.deleteResultTasks(
                                                          'AnalysisTasks',
                                                          index,
                                                          'Results',
                                                          resultIndex
                                                        )
                                                      }
                                                      okText="Yes"
                                                      cancelText="No"
                                                    >
                                                      <Tooltip
                                                        placement="top"
                                                        key="Delete"
                                                        title="Delete"
                                                      >
                                                        <DeleteOutlined
                                                          style={{
                                                            color: '#d98e04',
                                                            marginRight: '8px'
                                                          }}
                                                        />
                                                      </Tooltip>
                                                    </Popconfirm>
                                                  </div>
                                                </Card>
                                              );
                                            }
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  {formObject &&
                                    formObject.AnalysisTasks &&
                                    formObject.AnalysisTasks[index] &&
                                    formObject.AnalysisTasks[index].Results !== undefined &&
                                    formObject.AnalysisTasks[index].Results.map(
                                      (resultKey, resultIndex) => (
                                        <>
                                          {ResultIndex === resultIndex || addNewResult === true ? (
                                            <StyledModal
                                              title="Add Result"
                                              className="AddResultDrawer"
                                              open={isAanalyticResultVisible}
                                              onOk={this.handleOk}
                                              onCancel={this.handleCancel}
                                            >
                                              <CommentsForm
                                                visible={remarksModalVisible}
                                                modalClose={this.resultsModalClose}
                                                mainkey={'AnalysisTasks'}
                                                mainindex={index}
                                                index={resultIndex}
                                                keyName={'Results'}
                                                Title={'AnalysisTaskResult Comments'}
                                                user={userName}
                                                Comments={
                                                  formObject &&
                                                  formObject['AnalysisTasks'] &&
                                                  formObject['AnalysisTasks'][index] &&
                                                  formObject['AnalysisTasks'][index] &&
                                                  formObject['AnalysisTasks'][index]['Results'] &&
                                                  formObject['AnalysisTasks'][index]['Results'][
                                                    resultIndex
                                                  ] &&
                                                  formObject['AnalysisTasks'][index]['Results'][
                                                    resultIndex
                                                  ]['Comments'] &&
                                                  formObject['AnalysisTasks'][index]['Results'][
                                                    resultIndex
                                                  ]['Comments'] !== undefined
                                                    ? formObject['AnalysisTasks'][index]['Results'][
                                                        resultIndex
                                                      ]['Comments']
                                                    : []
                                                }
                                                submitComments={this.SubmitComments}
                                              />
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                label="Description"
                                                name={[
                                                  'AnalysisTasks',
                                                  index,
                                                  'Results',
                                                  resultIndex,
                                                  'Description'
                                                ]}
                                                style={{ flex: '100%' }}
                                                onChange={(e) =>
                                                  this.onResultTaskHandleChange(
                                                    e.target.value,
                                                    'AnalysisTasks',
                                                    index,
                                                    'Results',
                                                    resultIndex,
                                                    'Description'
                                                  )
                                                }
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please Provide Description'
                                                  }
                                                ]}
                                              >
                                                <TextArea
                                                  maxLength={10000}
                                                  style={{
                                                    height: 100,
                                                    width: '70%'
                                                  }}
                                                  placeholder="Input Description"
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                label="observations"
                                                name={[
                                                  'AnalysisTasks',
                                                  index,
                                                  'Results',
                                                  resultIndex,
                                                  'observations'
                                                ]}
                                                style={{ flex: '100%' }}
                                                onChange={(e) =>
                                                  this.onResultTaskHandleChange(
                                                    e.target.value,
                                                    'AnalysisTasks',
                                                    index,
                                                    'Results',
                                                    resultIndex,
                                                    'observations'
                                                  )
                                                }
                                                rules={[
                                                  {
                                                    required: false,
                                                    message: 'Please Provide observations'
                                                  }
                                                ]}
                                              >
                                                <TextArea
                                                  maxLength={10000}
                                                  style={{
                                                    height: 100,
                                                    width: '70%'
                                                  }}
                                                  placeholder="Input observations"
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                style={{ flex: '100%' }}
                                                label="Created By"
                                                //  name={["AnalysisTasks",index,"Results", resultIndex, 'CreatedBy']}
                                                key={resultIndex}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Value Missing'
                                                  }
                                                ]}
                                              >
                                                <Select
                                                  disabled
                                                  suffix={<UserOutlined />}
                                                  size="large"
                                                  value={
                                                    this.props &&
                                                    this.props.userProfile &&
                                                    this.props.userProfile.user &&
                                                    this.props.userProfile.user.Id
                                                  }
                                                  onChange={(e) =>
                                                    this.onResultTaskHandleChange(
                                                      e,
                                                      'AnalysisTasks',
                                                      index,
                                                      'Results',
                                                      resultIndex,
                                                      'CreatedBy'
                                                    )
                                                  }
                                                  placeholder="Select CreatedBy"
                                                  style={{ width: '70%' }}
                                                >
                                                  {users &&
                                                    Array.isArray(users) &&
                                                    users.map((item, index) => {
                                                      return (
                                                        <option key={index} value={item.Id}>
                                                          {item.FirstName}
                                                        </option>
                                                      );
                                                    })}
                                                </Select>
                                              </Form.Item>

                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                style={{ flex: '100%' }}
                                                label="Created Date"
                                                //  name={["AnalysisTasks",index,"Results", resultIndex, 'CreatedDate']}
                                                key={resultIndex}
                                                onChange={(e) =>
                                                  this.onResultTaskHandleChange(
                                                    e.target.value,
                                                    'AnalysisTasks',
                                                    index,
                                                    'Results',
                                                    resultIndex,
                                                    'CreatedDate'
                                                  )
                                                }
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Value Missing'
                                                  }
                                                ]}
                                              >
                                                <Input
                                                  value={moment().format('YYYY-MM-DD HH:mm:ss')}
                                                  disabled
                                                  size="large"
                                                  style={{ width: '70%' }}
                                                  placeholder="CreatedDate"
                                                  suffix={<FieldTimeOutlined />}
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                style={{ flex: '100%' }}
                                                label="Evaluation Metric"
                                                onChange={(e) =>
                                                  this.onResultTaskHandleChange(
                                                    e.target.value,
                                                    'AnalysisTasks',
                                                    index,
                                                    'Results',
                                                    resultIndex,
                                                    'EvaluationMetric'
                                                  )
                                                }
                                                name={[
                                                  'AnalysisTasks',
                                                  index,
                                                  'Results',
                                                  resultIndex,
                                                  'EvaluationMetric'
                                                ]}
                                                key={resultIndex}
                                                rules={[
                                                  {
                                                    required: false,
                                                    message: 'Value Missing'
                                                  }
                                                ]}
                                              >
                                                <Input
                                                  style={{
                                                    width: '70%'
                                                  }}
                                                  placeholder="Input Evaluation Metric"
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                style={{ flex: '100%' }}
                                                label="Metric Value"
                                                name={[
                                                  'AnalysisTasks',
                                                  index,
                                                  'Results',
                                                  resultIndex,
                                                  'MetricValue'
                                                ]}
                                                key={resultIndex}
                                                onChange={(e) =>
                                                  this.onResultTaskHandleChange(
                                                    e.target.value,
                                                    'AnalysisTasks',
                                                    index,
                                                    'Results',
                                                    resultIndex,
                                                    'MetricValue'
                                                  )
                                                }
                                                rules={[
                                                  {
                                                    required: false,
                                                    message: 'Value Missing'
                                                  }
                                                ]}
                                              >
                                                <Input
                                                  style={{
                                                    width: '70%'
                                                  }}
                                                  placeholder="Input Metric Value"
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                style={{ flex: '100%' }}
                                                name={[
                                                  'AnalysisTasks',
                                                  index,
                                                  'Results',
                                                  resultIndex,
                                                  'Attachments'
                                                ]}
                                                label="Attachments"
                                              >
                                                <Select
                                                  size="large"
                                                  style={{ width: '70%' }}
                                                  placeholder={'Add/View Attachments'}
                                                  onChange={(e) =>
                                                    this.handleAnalysisResultsClick(
                                                      e,
                                                      index,
                                                      resultIndex
                                                    )
                                                  }
                                                >
                                                  <Option
                                                    key={'View Attachments'}
                                                    value={'View Attachments'}
                                                  >
                                                    View Attachments
                                                  </Option>
                                                  <Option
                                                    key={'Add New Attachments'}
                                                    value={'Add New Attachments'}
                                                  >
                                                    Add New Attachments
                                                  </Option>
                                                </Select>
                                                <Upload
                                                  style={{
                                                    width: '90%',
                                                    marginRight: '5px'
                                                  }}
                                                  multiple={true}
                                                  onChange={(info) =>
                                                    this.fileUpload(
                                                      info,
                                                      'Results',
                                                      resultIndex,
                                                      'AnalysisTasks',
                                                      index
                                                    )
                                                  }
                                                  action={`${
                                                    ANALYTICS.FileUpload
                                                  }?SiteId=${siteId}&Type=${'Results'}`}
                                                  headers={{
                                                    Authorization: Authorization
                                                  }}
                                                  showUploadList={false}
                                                >
                                                  <Button
                                                    style={{
                                                      display: 'none'
                                                    }}
                                                    ref={this.analysisresultRef}
                                                    className="uploadFile"
                                                  >
                                                    <UploadOutlined /> Upload
                                                  </Button>
                                                </Upload>
                                                <Drawer
                                                  title="AnalysisTask Results Uploaded Files"
                                                  open={AnalysisResultsFileModalVisisble}
                                                  width={600}
                                                  onOk={() =>
                                                    this.setState({
                                                      AnalysisResultsFileModalVisisble: false
                                                    })
                                                  }
                                                  onClose={() =>
                                                    this.setState({
                                                      AnalysisResultsFileModalVisisble: false
                                                    })
                                                  }
                                                >
                                                  <StyledTable
                                                    theme={themeSettings}
                                                    className="AnalysisTaskResultsUploadedFiles"
                                                  >
                                                    <Table
                                                      columns={columns}
                                                      dataSource={AnalysisResultsdataSource}
                                                    />
                                                  </StyledTable>
                                                </Drawer>
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                style={{ flex: '100%' }}
                                                label="Comments"
                                                name={'Comments'}
                                              >
                                                <Tooltip
                                                  placement="topLeft"
                                                  color={'red'}
                                                  title="Click Here To Add Your Comments"
                                                >
                                                  <Badge
                                                    count={
                                                      formObject &&
                                                      formObject['AnalysisTasks'] &&
                                                      formObject['AnalysisTasks'][index] &&
                                                      formObject['AnalysisTasks'][index][
                                                        'Results'
                                                      ] &&
                                                      formObject['AnalysisTasks'][index]['Results'][
                                                        resultIndex
                                                      ] &&
                                                      formObject['AnalysisTasks'][index]['Results'][
                                                        resultIndex
                                                      ]['Comments'] &&
                                                      formObject['AnalysisTasks'][index]['Results'][
                                                        resultIndex
                                                      ]['Comments'].length
                                                    }
                                                  >
                                                    <Button
                                                      style={{
                                                        background: '#eabe6f',
                                                        color: 'black'
                                                      }}
                                                      onClick={() => {
                                                        let resultsCommentsData = {
                                                          Comments:
                                                            formObject &&
                                                            formObject['AnalysisTasks'] &&
                                                            formObject['AnalysisTasks'][index] &&
                                                            formObject['AnalysisTasks'][index] &&
                                                            formObject['AnalysisTasks'][index][
                                                              'Results'
                                                            ] &&
                                                            formObject['AnalysisTasks'][index][
                                                              'Results'
                                                            ][resultIndex] &&
                                                            formObject['AnalysisTasks'][index][
                                                              'Results'
                                                            ][resultIndex]['Comments'] &&
                                                            formObject['AnalysisTasks'][index][
                                                              'Results'
                                                            ][resultIndex]['Comments'] !== undefined
                                                              ? formObject['AnalysisTasks'][index][
                                                                  'Results'
                                                                ][resultIndex]['Comments']
                                                              : [],
                                                          index: resultIndex,
                                                          mainindex: index
                                                        };
                                                        this.setState({
                                                          remarksModalVisible: true,
                                                          resultsCommentsData
                                                        });
                                                      }}
                                                    >
                                                      <CommentOutlined />
                                                      Add/View Comments
                                                    </Button>
                                                  </Badge>
                                                </Tooltip>
                                              </Form.Item>
                                            </StyledModal>
                                          ) : null}
                                        </>
                                      )
                                    )}
                                </div>
                              ) : null}
                            </>
                          ))
                        : null}
                    </TabPane>
                  </Tabs>
                </div>
              ) : (
                <div style={{ background: 'rgb(26, 59, 92)', padding: '20px' }}>
                  {PredictionTaskModalVisible && PredictionTaskModalVisible === true ? (
                    <CommentsForm
                      visible={PredictionTaskModalVisible}
                      modalClose={this.PredictionTaskModalClose}
                      keyName={'PredictionTasks'}
                      Title={'PredictionTask Comments'}
                      {...{ ...commentsData }}
                      user={userName}
                      formObject={
                        this.formHeader.current && this.formHeader.current.getFieldsValue()
                          ? this.formHeader.current.getFieldsValue()
                          : {}
                      }
                      submitComments={this.SubmitComments}
                    />
                  ) : null}

                  <div style={{ float: 'right' }}>
                    {predictionActivekey === '1' ? (
                      <PlusOutlined
                        onClick={() => this.addPredictions()}
                        style={{
                          fontSize: '25px',
                          color: viewType === 'table' ? 'rgb(255, 108, 0)' : '#dfa230',
                          marginLeft: '10px'
                        }}
                      />
                    ) : null}
                    <UnorderedListOutlined
                      onClick={() => this.onViewTypeChange(selectedTask, 'table')}
                      style={{
                        fontSize: '25px',
                        color: predictionViewType === 'table' ? 'rgb(255, 108, 0)' : '#dfa230',
                        marginLeft: '10px'
                      }}
                    />
                    <AppstoreOutlined
                      onClick={() => this.onViewTypeChange(selectedTask, 'card')}
                      style={{
                        fontSize: '25px',
                        color: predictionViewType === 'card' ? 'rgb(255, 108, 0)' : '#dfa230',
                        marginLeft: '10px'
                      }}
                    />
                  </div>
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    style={{ color: 'black' }}
                    activeKey={predictionActivekey}
                    onChange={async (predictionActivekey) => {
                      if (predictionActivekey === '2') {
                        this.setState({ predictionActivekey });
                      }
                      if (predictionActivekey === '1') {
                        this.setState({ predictionActivekey });
                      }
                      this.setState({ predictionActivekey });
                    }}
                  >
                    <TabPane tab="List Of Prediction" key="1">
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {predictionActivekey &&
                        predictionActivekey === '1' &&
                        predictionViewType === 'card' ? (
                          formObject && formObject.Id ? (
                            formObject && formObject.PredictionTasks ? (
                              formObject &&
                              formObject.PredictionTasks.map((item, i) => {
                                return (
                                  <Card
                                    key={item.title}
                                    style={{
                                      width: '410px',
                                      padding: '5px',
                                      margin: '5px'
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                      }}
                                    >
                                      <div
                                        style={{
                                          padding: '0px 0px 6px 0px',
                                          fontSize: '18px',
                                          fontWeight: 'bolder',
                                          color: '#d98e04'
                                        }}
                                      >
                                        {this.add3Dots(item.functionalDescription, 35)}
                                      </div>

                                      <div style={{ margin: '0px 0px 7px 0px' }}>
                                        <IconText
                                          icon={UserOutlined}
                                          text={
                                            this.props &&
                                            this.props.userProfile &&
                                            this.props.userProfile.user &&
                                            this.props.userProfile.user.FirstName
                                          }
                                          key="list-vertical-star-o"
                                        />
                                      </div>
                                      <div style={{ margin: '0px 0px 7px 0px' }}>
                                        <IconText
                                          icon={CalendarOutlined}
                                          text={moment(item.CreatedDate).format(
                                            'DD-MM-YYYY HH:mm:ss'
                                          )}
                                          key="list-vertical-like-o"
                                        />
                                      </div>
                                    </div>
                                    <Divider
                                      className="customDividerHor"
                                      style={{
                                        borderTop: '1px solid #000',
                                        height: '0px'
                                      }}
                                    />
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        margin: '0px 0px',
                                        fontSize: '18px'
                                      }}
                                    >
                                      <Tooltip placement="top" key="Edit" title="Edit">
                                        <EditOutlined
                                          style={{
                                            color: '#d98e04',
                                            marginRight: '8px'
                                          }}
                                          onClick={() => this.editPredictionData(item, item.Id, i)}
                                        />
                                      </Tooltip>
                                      <Divider type="vertical" />
                                      <Popconfirm
                                        key="1"
                                        title="Are you sure that you want to delete the Task"
                                        icon={
                                          <QuestionCircleOutlined style={{ color: '#d98e04' }} />
                                        }
                                        onConfirm={() => this.deletePredictionTasks(item.Id, i)}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Tooltip placement="top" key="Delete" title="Delete">
                                          <DeleteOutlined
                                            style={{
                                              color: '#d98e04',
                                              marginRight: '8px'
                                            }}
                                          />
                                        </Tooltip>
                                      </Popconfirm>
                                    </div>
                                  </Card>
                                );
                              })
                            ) : null
                          ) : null
                        ) : (
                          <StyledTable theme={themeSettings}>
                            <Table
                              dataSource={
                                formObject &&
                                formObject.PredictionTasks &&
                                Array.isArray(formObject.PredictionTasks)
                                  ? formObject.PredictionTasks
                                  : []
                              }
                              columns={PredictionColumns}
                            />
                          </StyledTable>
                        )}
                      </div>
                    </TabPane>
                    <TabPane key="2" tab="Details Of Prediction">
                      {formObject &&
                      formObject.PredictionTasks &&
                      formObject.PredictionTasks !== undefined &&
                      formObject.PredictionTasks.length !== 0
                        ? formObject.PredictionTasks.map((item, Index) =>
                            PredictionIndex === Index ||
                            (formObject.PredictionTasks[formObject.PredictionTasks.length - 1] &&
                              addPredictionData === true) ||
                            PredictionTableId === formObject.PredictionTasks[Index].Id ? (
                              <>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    // {...restField}
                                    label="Title"
                                    name={['PredictionTasks', Index, 'Title']}
                                    // name={[name, 'Title']}
                                    onChange={(e) =>
                                      this.onHandleChangePrediction(
                                        e.target.value,
                                        'PredictionTasks',
                                        Index,
                                        'Title'
                                      )
                                    }
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Provide Title'
                                      }
                                    ]}
                                  >
                                    <Input
                                      maxLength={10000}
                                      size="large"
                                      style={{ width: '50%' }}
                                      placeholder="Input Title"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    // {...restField}
                                    name={['PredictionTasks', Index, 'functionalDescription']}
                                    // name={[name, 'functionalDescription']}
                                    onChange={(e) =>
                                      this.onHandleChangePrediction(
                                        e.target.value,
                                        'PredictionTasks',
                                        Index,
                                        'functionalDescription'
                                      )
                                    }
                                    label="Functional Description"
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Provide FunctionalDescription'
                                      }
                                    ]}
                                  >
                                    <TextArea
                                      maxLength={10000}
                                      style={{
                                        height: 100,
                                        width: '50%'
                                      }}
                                      placeholder="Input FunctionalDescription"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    // {...restField}
                                    name={['PredictionTasks', Index, 'RelatedTags']}
                                    // name={[name, 'RelatedTags']}
                                    label="Related Tags"
                                    style={{ flex: '100%' }}
                                  >
                                    <Select
                                      style={{ width: '50%' }}
                                      size="large"
                                      mode="multiple"
                                      onChange={(e) =>
                                        this.onHandleChangePrediction(
                                          e,
                                          'PredictionTasks',
                                          Index,
                                          'RelatedTags'
                                        )
                                      }
                                      placeholder="Select Tags"
                                    >
                                      {relatedTags &&
                                        Array.isArray(relatedTags) &&
                                        relatedTags.map((item, index) => {
                                          return (
                                            <Option key={index} value={item.Value}>
                                              {item.Key}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    // {...restField}
                                    label="CreatedBy"
                                    //  name={['PredictionTasks', Index, 'CreatedBy']}

                                    // name={[name, 'CreatedBy']}
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Select CreatedBy!'
                                      }
                                    ]}
                                  >
                                    <Select
                                      disabled
                                      suffix={<UserOutlined />}
                                      value={
                                        this.props &&
                                        this.props.userProfile &&
                                        this.props.userProfile.user &&
                                        this.props.userProfile.user.Id
                                      }
                                      size="large"
                                      onChange={(e) =>
                                        this.onHandleChangePrediction(
                                          e,
                                          'PredictionTasks',
                                          Index,
                                          'CreatedBy'
                                        )
                                      }
                                      placeholder="Select CreatedBy"
                                      style={{ width: '50%' }}
                                    >
                                      {users &&
                                        Array.isArray(users) &&
                                        users.map((item, index) => {
                                          return (
                                            <option key={index} value={item.Id}>
                                              {item.FirstName}
                                            </option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    //  name={['PredictionTasks', Index, 'CreatedDate']}
                                    onChange={(e) =>
                                      this.onHandleChangePrediction(
                                        e.target.value,
                                        'PredictionTasks',
                                        Index,
                                        'CreatedDate'
                                      )
                                    }
                                    label="CreatedDate"
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'CreatedDate is required'
                                      }
                                    ]}
                                  >
                                    <Input
                                      value={moment().format('YYYY-MM-DD HH:mm:ss')}
                                      disabled
                                      size="large"
                                      style={{ width: '50%' }}
                                      placeholder="CreatedDate"
                                      suffix={<FieldTimeOutlined />}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    // {...restField}
                                    label="Assigne"
                                    name={['PredictionTasks', Index, 'Assigne']}
                                    // name={[name, 'Assigne']}
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Select Assigne!'
                                      }
                                    ]}
                                  >
                                    <Select
                                      size="large"
                                      placeholder="Select Assigne"
                                      onChange={(e) =>
                                        this.onHandleChangePrediction(
                                          e,
                                          'PredictionTasks',
                                          Index,
                                          'Assigne'
                                        )
                                      }
                                      style={{ width: '50%' }}
                                    >
                                      {users &&
                                        Array.isArray(users) &&
                                        users.map((item, index) => {
                                          return (
                                            <option key={index} value={item.Id}>
                                              {item.UserName}
                                            </option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    // {...restField}
                                    name={['PredictionTasks', Index, 'Status']}
                                    // name={[name, 'Status']}
                                    label="Status"
                                    style={{ flex: '100%' }}
                                  >
                                    <Select
                                      size="large"
                                      style={{ width: '50%' }}
                                      onChange={(e) =>
                                        this.onHandleChangePrediction(
                                          e,
                                          'PredictionTasks',
                                          Index,
                                          'Status'
                                        )
                                      }
                                      placeholder="Select Status"
                                    >
                                      {StatusList &&
                                        Array.isArray(StatusList) &&
                                        StatusList.map((item, index) => {
                                          return (
                                            <option key={index} value={item.Value}>
                                              {item.Key}
                                            </option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    // {...restField}
                                    // name={[name, 'Date']}
                                    name={['PredictionTasks', Index, 'Date']}
                                    label="From And To Date"
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Provide Date'
                                      }
                                    ]}
                                  >
                                    <RangePicker
                                      value={
                                        formObject &&
                                        formObject.PredictionTasks &&
                                        formObject.PredictionTasks[Index] &&
                                        formObject.PredictionTasks[Index].Date
                                          ? [
                                              moment(
                                                formObject.PredictionTasks[Index].Date[0],
                                                'YYYY-MM-DD HH:mm'
                                              ),
                                              moment(
                                                formObject.PredictionTasks[Index].Date[1],
                                                'YYYY-MM-DD HH:mm'
                                              )
                                            ]
                                          : ''
                                      }
                                      onChange={(e) =>
                                        this.onHandleChangePrediction(
                                          e,
                                          'PredictionTasks',
                                          Index,
                                          'Date'
                                        )
                                      }
                                      disabledDate={(e) => this.disabledDate(e)}
                                      style={{ width: '50%' }}
                                      showTime={{
                                        format: 'HH:mm'
                                      }}
                                      format="YYYY-MM-DD HH:mm"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    // {...restField}
                                    // name={[name, 'Approach']}
                                    name={['PredictionTasks', Index, 'Approach']}
                                    label="Approach"
                                    onChange={(e) =>
                                      this.onHandleChangePrediction(
                                        e.target.value,
                                        'PredictionTasks',
                                        Index,
                                        'Approach'
                                      )
                                    }
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Provide Approach'
                                      }
                                    ]}
                                  >
                                    <Input
                                      //   showCount
                                      maxLength={10000}
                                      size="large"
                                      style={{ width: '50%' }}
                                      placeholder="Input Approach"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    // {...restField}
                                    // name={[name, 'Algorithms']}

                                    name={['PredictionTasks', Index, 'Algorithms']}
                                    onChange={(e) =>
                                      this.onHandleChangePrediction(
                                        e.target.value,
                                        'PredictionTasks',
                                        Index,
                                        'Algorithms'
                                      )
                                    }
                                    label="Algorithms"
                                    style={{ flex: '100%' }}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please Provide Algorithms'
                                      }
                                    ]}
                                  >
                                    <Input
                                      //   showCount
                                      maxLength={10000}
                                      size="large"
                                      style={{ width: '50%' }}
                                      placeholder="Input Algorithms"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    style={{ flex: '100%' }}
                                    // name={[name, 'Attachments']}
                                    name={['PredictionTasks', Index, 'Attachments']}
                                    label="Attachments"
                                  >
                                    <Select
                                      size="large"
                                      style={{ width: '50%' }}
                                      placeholder={'Add/View Attachments'}
                                      onChange={(e) => this.handlePredictionTasksClick(e, Index)}
                                    >
                                      <Option key={'View Attachments'} value={'View Attachments'}>
                                        View Attachments
                                      </Option>
                                      <Option
                                        key={'Add New Attachments'}
                                        value={'Add New Attachments'}
                                      >
                                        Add New Attachments
                                      </Option>
                                    </Select>
                                    <Upload
                                      multiple={true}
                                      onChange={(info) =>
                                        this.fileUpload(info, 'PredictionTasks', Index)
                                      }
                                      action={`${
                                        ANALYTICS.FileUpload
                                      }?SiteId=${siteId}&Type=${'Task'}`}
                                      headers={{
                                        Authorization: Authorization
                                      }}
                                      showUploadList={false}
                                    >
                                      <Button
                                        style={{ display: 'none' }}
                                        ref={this.predictionRef}
                                        className="uploadFile"
                                      >
                                        <UploadOutlined /> Upload
                                      </Button>
                                    </Upload>
                                    <Drawer
                                      title="PredictionTask Uploaded Files"
                                      className="PredictionTaskUploadedFiles"
                                      open={PredictionfileModalVisible}
                                      width={600}
                                      onOk={() =>
                                        this.setState({
                                          PredictionfileModalVisible: false
                                        })
                                      }
                                      onClose={() =>
                                        this.setState({
                                          PredictionfileModalVisible: false
                                        })
                                      }
                                    >
                                      <StyledTable theme={themeSettings}>
                                        <Table
                                          columns={columns}
                                          dataSource={PredictiondataSource}
                                        />
                                      </StyledTable>
                                    </Drawer>
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ flex: '130px' }}
                                    style={{ flex: '100%' }}
                                    label="Comments"
                                    name={['PredictionTasks', Index, 'Comments']}
                                    // name={'Comments'}
                                  >
                                    <Tooltip
                                      placement="topLeft"
                                      color={'red'}
                                      title="Click Here To Add Your Comments"
                                    >
                                      <Badge
                                        count={
                                          formObject['PredictionTasks'] &&
                                          formObject['PredictionTasks'][Index] &&
                                          formObject['PredictionTasks'][Index]['Comments'] &&
                                          formObject['PredictionTasks'][Index]['Comments'].length
                                        }
                                      >
                                        <Button
                                          style={{
                                            background: '#eabe6f',
                                            color: 'black'
                                          }}
                                          onClick={() => {
                                            let commentsData = {
                                              Comments:
                                                formObject &&
                                                formObject['PredictionTasks'] &&
                                                formObject['PredictionTasks'][Index] &&
                                                formObject['PredictionTasks'][Index]['Comments']
                                                  ? [
                                                      ...formObject['PredictionTasks'][Index][
                                                        'Comments'
                                                      ]
                                                    ]
                                                  : [],
                                              index: Index
                                            };
                                            this.setState({
                                              PredictionTaskModalVisible: true,
                                              commentsData
                                            });
                                          }}
                                        >
                                          <CommentOutlined />
                                          Add/View Comments
                                        </Button>
                                      </Badge>
                                    </Tooltip>
                                  </Form.Item>
                                  <Button
                                    style={{
                                      background: '#29415a',
                                      color: '#fff'
                                    }}
                                    onClick={() => this.addPredictionResults(Index)}
                                  >
                                    <PlusOutlined /> Add Results
                                  </Button>
                                  <div style={{ marginTop: '60px' }}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap'
                                      }}
                                    >
                                      {formObject &&
                                      formObject.PredictionTasks &&
                                      formObject.PredictionTasks[Index] &&
                                      formObject.PredictionTasks[Index].Results &&
                                      this.state.PredictionResult === true
                                        ? formObject.PredictionTasks[Index].Results.map(
                                            (predicResultKey, ind) => {
                                              return (
                                                <Card
                                                  key={predicResultKey.title}
                                                  style={{
                                                    width: '410px',
                                                    padding: '5px',
                                                    margin: '5px'
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'column'
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        padding: '0px 0px 6px 0px',
                                                        fontSize: '18px',
                                                        fontWeight: 'bolder',
                                                        color: '#d98e04'
                                                      }}
                                                    >
                                                      {this.add3Dots(
                                                        predicResultKey.Description,
                                                        35
                                                      )}
                                                    </div>

                                                    <div
                                                      style={{
                                                        margin: '0px 0px 7px 0px'
                                                      }}
                                                    >
                                                      <IconText
                                                        icon={UserOutlined}
                                                        text={
                                                          this.props &&
                                                          this.props.userProfile &&
                                                          this.props.userProfile.user &&
                                                          this.props.userProfile.user.FirstName
                                                        }
                                                        key="list-vertical-star-o"
                                                      />
                                                    </div>
                                                    <div
                                                      style={{
                                                        margin: '0px 0px 7px 0px'
                                                      }}
                                                    >
                                                      <IconText
                                                        icon={CalendarOutlined}
                                                        text={moment(
                                                          predicResultKey.CreatedDate
                                                        ).format('DD-MM-YYYY HH:mm:ss')}
                                                        key="list-vertical-like-o"
                                                      />
                                                    </div>
                                                  </div>
                                                  <Divider
                                                    className="customDividerHor"
                                                    style={{
                                                      borderTop: '1px solid #000',
                                                      height: '0px'
                                                    }}
                                                  />
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      flexDirection: 'row',
                                                      margin: '0px 0px',
                                                      fontSize: '18px'
                                                    }}
                                                  >
                                                    <Tooltip
                                                      placement="top"
                                                      key="Edit"
                                                      title="Edit"
                                                    >
                                                      <EditOutlined
                                                        style={{
                                                          color: '#d98e04',
                                                          marginRight: '8px'
                                                        }}
                                                        onClick={() =>
                                                          this.editAnalyticResult(
                                                            predicResultKey,
                                                            predicResultKey.Id,
                                                            ind,
                                                            'PredictionTasks'
                                                          )
                                                        }
                                                      />
                                                    </Tooltip>
                                                    <Divider type="vertical" />
                                                    <Popconfirm
                                                      key="1"
                                                      title="Are you sure that you want to delete the Result"
                                                      icon={
                                                        <QuestionCircleOutlined
                                                          style={{
                                                            color: '#d98e04'
                                                          }}
                                                        />
                                                      }
                                                      onConfirm={() =>
                                                        this.deleteResultTasks(
                                                          'PredictionTasks',
                                                          Index,
                                                          'Results',
                                                          ind
                                                        )
                                                      }
                                                      okText="Yes"
                                                      cancelText="No"
                                                    >
                                                      <Tooltip
                                                        placement="top"
                                                        key="Delete"
                                                        title="Delete"
                                                      >
                                                        <DeleteOutlined
                                                          style={{
                                                            color: '#d98e04',
                                                            marginRight: '8px'
                                                          }}
                                                        />
                                                      </Tooltip>
                                                    </Popconfirm>
                                                  </div>
                                                </Card>
                                              );
                                            }
                                          )
                                        : null}
                                    </div>
                                  </div>
                                  {formObject &&
                                    formObject.PredictionTasks &&
                                    formObject.PredictionTasks[Index] &&
                                    formObject.PredictionTasks[Index].Results &&
                                    formObject.PredictionTasks[Index].Results.length !== 0 &&
                                    formObject.PredictionTasks[Index].Results !== undefined &&
                                    formObject.PredictionTasks[Index].Results.map(
                                      (PrectictionKe, resultIndex1) => (
                                        <>
                                          {PredictionResultInd === resultIndex1 ||
                                          addNewPredictionResult === true ? (
                                            <StyledModal
                                              title="Add Result"
                                              className="AddResultDrawer"
                                              open={isPredictionResultVisible}
                                              onOk={this.handleOkPrediction}
                                              onCancel={this.handleCancelPrediction}
                                            >
                                              <CommentsForm
                                                visible={remarksModalVisible}
                                                modalClose={this.resultsModalClose}
                                                mainkey={'PredictionTasks'}
                                                Title={'PredictionTaskResult Comments'}
                                                mainindex={Index}
                                                index={resultIndex1}
                                                keyName={'Results'}
                                                user={userName}
                                                Comments={
                                                  formObject &&
                                                  formObject['PredictionTasks'] &&
                                                  formObject['PredictionTasks'][Index] &&
                                                  formObject['PredictionTasks'][Index] &&
                                                  formObject['PredictionTasks'][Index]['Results'] &&
                                                  formObject['PredictionTasks'][Index]['Results'][
                                                    resultIndex1
                                                  ] &&
                                                  formObject['PredictionTasks'][Index]['Results'][
                                                    resultIndex1
                                                  ]['Comments'] &&
                                                  formObject['PredictionTasks'][Index]['Results'][
                                                    resultIndex1
                                                  ]['Comments'] !== undefined
                                                    ? formObject['PredictionTasks'][Index][
                                                        'Results'
                                                      ][resultIndex1]['Comments']
                                                    : []
                                                }
                                                submitComments={this.SubmitComments}
                                              />

                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                label="Description"
                                                name={[
                                                  'PredictionTasks',
                                                  Index,
                                                  'Results',
                                                  resultIndex1,
                                                  'Description'
                                                ]}
                                                style={{ flex: '100%' }}
                                                onChange={(e) =>
                                                  this.onResultTaskHandleChange(
                                                    e.target.value,
                                                    'PredictionTasks',
                                                    Index,
                                                    'Results',
                                                    resultIndex1,
                                                    'Description'
                                                  )
                                                }
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please Provide Description'
                                                  }
                                                ]}
                                              >
                                                <TextArea
                                                  maxLength={10000}
                                                  style={{
                                                    width: '70%',
                                                    height: 100
                                                  }}
                                                  placeholder="Input Description"
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                label="observations"
                                                name={[
                                                  'PredictionTasks',
                                                  Index,
                                                  'Results',
                                                  resultIndex1,
                                                  'observations'
                                                ]}
                                                style={{ flex: '100%' }}
                                                onChange={(e) =>
                                                  this.onResultTaskHandleChange(
                                                    e.target.value,
                                                    'PredictionTasks',
                                                    Index,
                                                    'Results',
                                                    resultIndex1,
                                                    'observations'
                                                  )
                                                }
                                                rules={[
                                                  {
                                                    required: false,
                                                    message: 'Please Provide observations'
                                                  }
                                                ]}
                                              >
                                                <TextArea
                                                  maxLength={10000}
                                                  style={{
                                                    width: '70%',
                                                    height: 100
                                                  }}
                                                  placeholder="Input observations"
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                style={{ flex: '70%' }}
                                                label="CreatedBy"
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Value Missing'
                                                  }
                                                ]}
                                              >
                                                <Select
                                                  disabled
                                                  size="large"
                                                  placeholder="Select CreatedBy"
                                                  value={
                                                    this.props &&
                                                    this.props.userProfile &&
                                                    this.props.userProfile.user &&
                                                    this.props.userProfile.user.Id
                                                  }
                                                  onChange={(e) =>
                                                    this.onResultTaskHandleChange(
                                                      e,
                                                      'PredictionTasks',
                                                      Index,
                                                      'Results',
                                                      resultIndex1,
                                                      'CreatedBy'
                                                    )
                                                  }
                                                  style={{ width: '70%' }}
                                                >
                                                  {users &&
                                                    Array.isArray(users) &&
                                                    users.map((item) => {
                                                      return (
                                                        <option key={item.Id} value={item.Id}>
                                                          {item.FirstName}
                                                        </option>
                                                      );
                                                    })}
                                                </Select>
                                              </Form.Item>

                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                style={{ flex: '70%' }}
                                                label="CreatedDate"
                                                //  name={["PredictionTasks",Index,"Results", resultIndex1, 'CreatedDate']}
                                                onChange={(e) =>
                                                  this.onResultTaskHandleChange(
                                                    e.target.value,
                                                    'PredictionTasks',
                                                    Index,
                                                    'Results',
                                                    resultIndex1,
                                                    'CreatedDate'
                                                  )
                                                }
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Value Missing'
                                                  }
                                                ]}
                                              >
                                                <Input
                                                  disabled
                                                  value={moment().format('YYYY-MM-DD HH:mm:ss')}
                                                  size="large"
                                                  style={{ width: '70%' }}
                                                  placeholder="CreatedDate"
                                                  suffix={<FieldTimeOutlined />}
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                style={{ flex: '70%' }}
                                                label="Evaluation Metric"
                                                name={[
                                                  'PredictionTasks',
                                                  Index,
                                                  'Results',
                                                  resultIndex1,
                                                  'EvaluationMetric'
                                                ]}
                                                onChange={(e) =>
                                                  this.onResultTaskHandleChange(
                                                    e.target.value,
                                                    'PredictionTasks',
                                                    Index,
                                                    'Results',
                                                    resultIndex1,
                                                    'EvaluationMetric'
                                                  )
                                                }
                                                rules={[
                                                  {
                                                    required: false,
                                                    message: 'Value Missing'
                                                  }
                                                ]}
                                              >
                                                <Input
                                                  style={{
                                                    width: '70%'
                                                  }}
                                                  placeholder="Input Evaluation Metric"
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                style={{ flex: '70%' }}
                                                name={[
                                                  'PredictionTasks',
                                                  Index,
                                                  'Results',
                                                  resultIndex1,
                                                  'MetricValue'
                                                ]}
                                                label="Metric Value"
                                                onChange={(e) =>
                                                  this.onResultTaskHandleChange(
                                                    e.target.value,
                                                    'PredictionTasks',
                                                    Index,
                                                    'Results',
                                                    resultIndex1,
                                                    'MetricValue'
                                                  )
                                                }
                                                rules={[
                                                  {
                                                    required: false,
                                                    message: 'Value Missing'
                                                  }
                                                ]}
                                              >
                                                <Input
                                                  style={{
                                                    width: '70%'
                                                  }}
                                                  placeholder="Input Metric Value"
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                style={{ flex: '70%' }}
                                                name={[
                                                  'PredictionTasks',
                                                  Index,
                                                  'Results',
                                                  resultIndex1,
                                                  'Attachments'
                                                ]}
                                                label="Attachments"
                                              >
                                                <Select
                                                  size="large"
                                                  style={{ width: '70%' }}
                                                  placeholder={'Add/View Attachments'}
                                                  onChange={(e) =>
                                                    this.handlePredictionResultsClick(
                                                      e,
                                                      Index,
                                                      resultIndex1
                                                    )
                                                  }
                                                >
                                                  <Option
                                                    key={'View Attachments'}
                                                    value={'View Attachments'}
                                                  >
                                                    View Attachments
                                                  </Option>
                                                  <Option
                                                    key={'Add New Attachments'}
                                                    value={'Add New Attachments'}
                                                  >
                                                    Add New Attachments
                                                  </Option>
                                                </Select>
                                                <Upload
                                                  style={{
                                                    width: '90%',
                                                    marginRight: '5px'
                                                  }}
                                                  multiple={true}
                                                  onChange={(info) =>
                                                    this.fileUpload(
                                                      info,
                                                      'Results',
                                                      resultIndex1,
                                                      'PredictionTasks',
                                                      Index
                                                    )
                                                  }
                                                  action={`${
                                                    ANALYTICS.FileUpload
                                                  }?SiteId=${siteId}&Type=${'Results'}`}
                                                  headers={{
                                                    Authorization: Authorization
                                                  }}
                                                  showUploadList={false}
                                                >
                                                  <Button
                                                    style={{
                                                      display: 'none'
                                                    }}
                                                    ref={this.predictionResultRef}
                                                    className="uploadFile"
                                                  >
                                                    <UploadOutlined /> Upload
                                                  </Button>
                                                </Upload>
                                                <Drawer
                                                  title="PredictionTask Results Uploaded Files"
                                                  className="PredictionTaskResultsUploadedFiles"
                                                  open={PredictionResultsFileModalVisible}
                                                  width={600}
                                                  onOk={() =>
                                                    this.setState({
                                                      PredictionResultsFileModalVisible: false
                                                    })
                                                  }
                                                  onClose={() =>
                                                    this.setState({
                                                      PredictionResultsFileModalVisible: false
                                                    })
                                                  }
                                                >
                                                  <StyledTable theme={themeSettings}>
                                                    <Table
                                                      columns={columns}
                                                      dataSource={PredictionResultsdataSource}
                                                    />
                                                  </StyledTable>
                                                </Drawer>
                                              </Form.Item>
                                              <Form.Item
                                                labelCol={{ flex: '130px' }}
                                                style={{ flex: '70%' }}
                                                label="Comments"
                                                name={'Comments'}
                                              >
                                                <Tooltip
                                                  placement="topLeft"
                                                  color={'red'}
                                                  title="Click Here To Add Your Comments"
                                                >
                                                  <Badge
                                                    count={
                                                      formObject &&
                                                      formObject['PredictionTasks'] &&
                                                      formObject['PredictionTasks'][Index] &&
                                                      formObject['PredictionTasks'][Index][
                                                        'Results'
                                                      ] &&
                                                      formObject['PredictionTasks'][Index][
                                                        'Results'
                                                      ][resultIndex1] &&
                                                      formObject['PredictionTasks'][Index][
                                                        'Results'
                                                      ][resultIndex1]['Comments'] &&
                                                      formObject['PredictionTasks'][Index][
                                                        'Results'
                                                      ][resultIndex1]['Comments'].length
                                                    }
                                                  >
                                                    <Button
                                                      style={{
                                                        background: '#eabe6f',
                                                        color: 'black'
                                                      }}
                                                      onClick={() => {
                                                        let resultsCommentsData = {
                                                          Comments:
                                                            formObject &&
                                                            formObject['PredictionTasks'] &&
                                                            formObject['PredictionTasks'][Index] &&
                                                            formObject['PredictionTasks'][Index] &&
                                                            formObject['PredictionTasks'][Index][
                                                              'Results'
                                                            ] &&
                                                            formObject['PredictionTasks'][Index][
                                                              'Results'
                                                            ][resultIndex1] &&
                                                            formObject['PredictionTasks'][Index][
                                                              'Results'
                                                            ][resultIndex1]['Comments'] &&
                                                            formObject['PredictionTasks'][Index][
                                                              'Results'
                                                            ][resultIndex1]['Comments'] !==
                                                              undefined
                                                              ? formObject['PredictionTasks'][
                                                                  Index
                                                                ]['Results'][resultIndex1][
                                                                  'Comments'
                                                                ]
                                                              : [],
                                                          index: resultIndex1,
                                                          mainindex: Index
                                                        };
                                                        this.setState({
                                                          remarksModalVisible: true,
                                                          resultsCommentsData
                                                        });
                                                      }}
                                                    >
                                                      <CommentOutlined />
                                                      Add/View Comments
                                                    </Button>
                                                  </Badge>
                                                </Tooltip>
                                              </Form.Item>
                                            </StyledModal>
                                          ) : null}
                                        </>
                                      )
                                    )}
                                </div>
                              </>
                            ) : null
                          )
                        : null}
                    </TabPane>
                  </Tabs>
                </div>
              )}

              <Form.Item>
                <Button type="primary" className="SubmitButton" htmlType="submit">
                  {formObject && formObject.Id ? 'Update' : 'Submit'}
                </Button>
              </Form.Item>
            </Form>
          </>
        </div>
      </>
      // </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
});
export default connect(mapStateToProps)(PredictionForm);
