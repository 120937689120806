import React, { Component } from 'react';
import { Space, Radio, Button, Form, Select } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../../../selectors/language';

class Memory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLayout: '',
      translationCache: props.translationCache || []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }
  translation = (keyword) => {
    let { translationCache } = this.props;

    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const formItemLayout = {
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      }
    };
    const buttonItemLayout = {
      wrapperCol: {
        span: 14,
        offset: 4
      }
    };
    return (
      <div style={{}}>
        <Form
          {...formItemLayout}
          layout="horizontal"
          name={this.translation('Processing')}
          className="formComponent"
        >
          <label style={{ fontSize: '18px', fontWeight: 'bolder' }}>
            {this.translation('Processing')}
          </label>
          <Form.Item {...buttonItemLayout}>
            <Radio.Group>
              <Radio value={'Initialization'}>{this.translation('Initialization')}</Radio>
              <Radio value={'Error Release'}>{this.translation('Error Release')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>

        <Form
          {...formItemLayout}
          layout="horizontal"
          name={this.translation('Target')}
          className="formComponent"
          style={{ marginTop: '10px' }}
        >
          <label style={{ fontSize: '18px', fontWeight: 'bolder' }}>
            {this.translation('Target')}
          </label>
          <Form.Item label={this.translation('HD')}>
            <Select style={{ width: 220 }}>
              <Select.Option value="01">01</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={'System Parameter'}>{this.translation('System Parameter')}</Radio>
                <Radio value={'Channel Release'}>{this.translation('Channel Parameter')}</Radio>
                <Radio value={'All Data'}>{this.translation('All Data')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item {...buttonItemLayout}>
            <Button type="primary">{this.translation('Execute')}</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(Memory);
