// import { useState } from "react";
// import { connect } from "react-redux";
// import { getTranslationCache } from "../../../../../selectors/language";
// import { createStructuredSelector } from "reselect";
// const getList = (props) => {
//   const [translationCache] = useState(props.translationCache || {});

//   return translationCache && translationCache[props.keyName]
//     ? translationCache[props.keyName]
//     : props.keyName;
// };
// const mapStateToProps = createStructuredSelector({
//   translationCache: getTranslationCache(),
// });
// const Translator = connect(mapStateToProps)(getList);

// export { Translator };

export default function getList(translationCache, keyword) {
  return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
}
