import React from 'react';

import moment from 'moment';
import { getTranslationCache } from '../../../selectors/language';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import themeSettings from '../Utils/themeSettings.json';
import { StyledComponent, StyledTable } from '../CSS/style';
import { getList } from '../Utils/FormCalls';
// import { constant } from '../Utils/Constants';
import { Button, Tooltip } from 'antd';
import { encode as base64_encode } from 'base-64';
// import translation from '../Utils/translation';
import history from '../../../commons/history';
import { MODELCOMPARSION } from '../../../commons/api';

const APIList = {
  getList: `${MODELCOMPARSION.GET}`
};
class BookmarksList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // columns:[
      //   {
      //     title: this.translation('Model 1'),
      //     dataIndex: 'model 1',
      //     key: 'model 1',
      //     children: [
      //       {
      //         title: this.translation('Name'),
      //         dataIndex: 'modelName_1',
      //         key: 'modelName_1'
      //       },
      //       {
      //         title: this.translation('From Time'),
      //         dataIndex: 'model_1_FromTime',
      //         key: 'model_1_FromTime',
      //         render: (text) => {
      //           return {
      //             children:
      //               text !== undefined && text !== null ? (
      //                 <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //               ) : null
      //           };
      //         }
      //       },
      //       {
      //         title: this.translation('To Time'),
      //         dataIndex: 'model_1_ToTime',
      //         key: 'model_1_ToTime',
      //         render: (text) => {
      //           return {
      //             children:
      //               text !== undefined && text !== null ? (
      //                 <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //               ) : null
      //           };
      //         }
      //       },
      //       {
      //         title: this.translation('Status'),
      //         dataIndex: 'model_1_Status',
      //         key: 'model_1_Status'
      //       }
      //     ]
      //   },
      //   {
      //     title: this.translation('Model 2'),
      //     dataIndex: 'model 2',
      //     key: 'model 2',
      //     children: [
      //       {
      //         title: this.translation('Name'),
      //         dataIndex: 'modelName_2',
      //         key: 'modelName_2'
      //       },
      //       {
      //         title: this.translation('From Time'),
      //         dataIndex: 'model_2_FromTime',
      //         key: 'model_2_FromTime',
      //         render: (text) => {
      //           return {
      //             children:
      //               text !== undefined && text !== null ? (
      //                 <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //               ) : null
      //           };
      //         }
      //       },
      //       {
      //         title: this.translation('To Time'),
      //         dataIndex: 'model_2_ToTime',
      //         key: 'model_2_ToTime',
      //         render: (text) => {
      //           return {
      //             children:
      //               text !== undefined && text !== null ? (
      //                 <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //               ) : null
      //           };
      //         }
      //       },
      //       {
      //         title: this.translation('Status'),
      //         dataIndex: 'model_2_Status',
      //         key: 'model_2_Status'
      //       }
      //     ]
      //   },

      //   {
      //     title: this.translation('Created By'),
      //     dataIndex: 'modelCreatedName',
      //     key: 'modelCreatedName'
      //   },
      //   {
      //     title: this.translation('Created Date&Time'),
      //     dataIndex: 'model_1_CreatedTime',
      //     key: 'model_1_CreatedTime',
      //     render: (text) => {
      //       return {
      //         children:
      //           text !== undefined && text !== null ? (
      //             <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //           ) : null
      //       };
      //     }
      //   },

      //   {
      //     title: this.translation('Action'),
      //     render: (text, record) => (
      //       <>
      //         <Tooltip
      //           title={
      //             record.model_1_Status === 'Completed' && record.model_2_Status === 'Completed'
      //               ? this.translation('View Results')
      //               : this.translation('View Results')
      //           }
      //         >
      //           <a
      //             style={{ marginRight: '5px' }}
      //             onClick={() =>
      //               history.push(`/rubus/RbsAnalytics/ModelComparision/${base64_encode(record.Id)}`)
      //             }
      //           >
      //             {/* {record.Status === "Completed" ||
      //             record.Status === "Registered"
      //               ? translation(props.translationCache, constant.details)
      //               : translation(props.translationCache, constant.register)} */}
      //             {record.model_1_Status === 'Completed' && record.model_2_Status === 'Completed'
      //               ? this.translation('View Results')
      //               : this.translation('View Results')}
      //           </a>
      //         </Tooltip>
      //       </>
      //     )
      //   }
      // ],
      dataSource: [],
      translationCache: props.translationCache || {}
    };
  }
  componentDidMount = async () => {
    let response = await getList(APIList.getList);
    this.setState({
      dataSource: response && Array.isArray(response) ? [...response] : []
    });
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { dataSource } = this.state;
    const columns = [
      {
        title: this.translation('Model 1'),
        dataIndex: 'model 1',
        key: 'model 1',
        children: [
          {
            title: this.translation('Name'),
            dataIndex: 'modelName_1',
            key: 'modelName_1'
          },
          {
            title: this.translation('From Time'),
            dataIndex: 'model_1_FromTime',
            key: 'model_1_FromTime',
            render: (text) => {
              return {
                children:
                  text !== undefined && text !== null ? (
                    <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
                  ) : null
              };
            }
          },
          {
            title: this.translation('To Time'),
            dataIndex: 'model_1_ToTime',
            key: 'model_1_ToTime',
            render: (text) => {
              return {
                children:
                  text !== undefined && text !== null ? (
                    <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
                  ) : null
              };
            }
          },
          {
            title: this.translation('Status'),
            dataIndex: 'model_1_Status',
            key: 'model_1_Status'
          }
        ]
      },
      {
        title: this.translation('Model 2'),
        dataIndex: 'model 2',
        key: 'model 2',
        children: [
          {
            title: this.translation('Name'),
            dataIndex: 'modelName_2',
            key: 'modelName_2'
          },
          {
            title: this.translation('From Time'),
            dataIndex: 'model_2_FromTime',
            key: 'model_2_FromTime',
            render: (text) => {
              return {
                children:
                  text !== undefined && text !== null ? (
                    <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
                  ) : null
              };
            }
          },
          {
            title: this.translation('To Time'),
            dataIndex: 'model_2_ToTime',
            key: 'model_2_ToTime',
            render: (text) => {
              return {
                children:
                  text !== undefined && text !== null ? (
                    <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
                  ) : null
              };
            }
          },
          {
            title: this.translation('Status'),
            dataIndex: 'model_2_Status',
            key: 'model_2_Status'
          }
        ]
      },

      {
        title: this.translation('Created By'),
        dataIndex: 'modelCreatedName',
        key: 'modelCreatedName'
      },
      {
        title: this.translation('Created Date&Time'),
        dataIndex: 'model_1_CreatedTime',
        key: 'model_1_CreatedTime',
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },

      {
        title: this.translation('Action'),
        render: (text, record) => (
          <>
            <Tooltip
              title={
                record.model_1_Status === 'Completed' && record.model_2_Status === 'Completed'
                  ? this.translation('View Results')
                  : this.translation('View Results')
              }
            >
              <a
                style={{ marginRight: '5px' }}
                onClick={() =>
                  history.push(`/rubus/RbsAnalytics/ModelComparision/${base64_encode(record.Id)}`)
                }
              >
                {/* {record.Status === "Completed" ||
                record.Status === "Registered"
                  ? translation(props.translationCache, constant.details)
                  : translation(props.translationCache, constant.register)} */}
                {record.model_1_Status === 'Completed' && record.model_2_Status === 'Completed'
                  ? this.translation('View Results')
                  : this.translation('View Results')}
              </a>
            </Tooltip>
          </>
        )
      }
    ];
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '50px 30px', minHeight: window.innerHeight - 143 }}
        >
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Tooltip title={this.translation('Click here to register a new model')}>
              <Button
                onClick={() => history.push('/rubus/RbsAnalytics/ModelComparision')}
                type="primary"
                style={{
                  marginBottom: 8
                }}
                size="middle"
              >
                {this.translation('New')}
              </Button>
            </Tooltip>
          </div>
          <StyledTable
            data-testid="workbenchList"
            theme={themeSettings}
            dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
            columns={columns}
          ></StyledTable>
        </StyledComponent>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(BookmarksList);
