export const constant = {
  New_Chat: 'New Chat',
  Clear_Messages: 'Clear Messages',
  DISABLED: 'DISABLED',
  Enable_Debug_Mode: 'Enable Debug Mode',
  Disable_Debug_Mode: 'Disable Debug Mode',
  Type_A_Message: 'Type a message...',
  Open: 'Open',
  Close: 'Close',
  Hi_there_Welcome_to_BM_GPT_How_can_i_help_you:
    'Hi there! 👋 ,Welcome to Rubus GPT. How can i help you?'
};
