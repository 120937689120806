import { combineReducers } from 'redux-immutable';

import login from './login';
import asset from './asset';
import device from './device';
import deviceType from './deviceType';
import layout from './layout';
import menu from './menu';
import translation from './translation';
import theme from './theme';

export default function createReducer(asyncReducers) {
  return combineReducers({
    login,
    asset,
    device,
    deviceType,
    layout,
    menu,
    translation,
    theme,
    ...asyncReducers
  });
}
