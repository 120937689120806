import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import SlideMenu from './SlideMenu';
import { StyledHeaderLayout } from './styles';
import { getNavigationMode } from '../../../../selectors/theme';
import './styles.css';

class HeaderLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <span>
        <StyledHeaderLayout
          style={{
            display: this.props.hideLayout === true ? 'none' : 'block',
            padding: 0
          }}
        >
          <SlideMenu
            name="test"
            history={this.props.history}
            nativProps={this.props}
            fullScreen={this.props.fullScreen}
          />
        </StyledHeaderLayout>
      </span>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  navigationMode: getNavigationMode()
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLayout);
