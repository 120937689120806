import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Input, Radio, Drawer, Form } from 'antd';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import localStorage from '../../../../../utils/localStorage';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { getTranslationCache } from '../../../../../selectors/language';

import '../../CSS/Table.css';
import { getCurrentTimezone } from '../../../../../selectors/layout';
import { API_DASHBOARD } from '../../../../../commons/api';

class AnomalyFeedbackTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: 'yes',
      feedbackText: '',
      openModal: false,
      notoficationId: '',
      notoficationValue: '',
      notoficationParamters: '',
      Notofication: '',
      translationCache: props.translationCache || [],
      feedbackForm: props.feedbackForm
    };
  }

  componentDidMount() {
    this.setState({
      feedbackForm: this.props.notification ? this.props.notification : {},
      openModal: this.props.openModal
    });
  }

  componentDidUpdate(PreProps) {
    if (
      PreProps.notification !== this.props.notification ||
      PreProps.openModal !== this.props.openModal
    ) {
      this.setState({
        feedbackForm: this.props.notification ? this.props.notification : {},
        openModal: this.props.openModal
      });
    }
  }

  openModal = () => {
    this.setState({
      openModal: true
    });
  };

  handleOk = () => {
    const { feedbackForm } = this.props;
    let payload = {
      ...feedbackForm,
      Timestamp: moment(feedbackForm.Timestamp, 'YYYY-MM-DD 00:00:00')
        .tz(this.props.currentTimeZone)
        .format('YYYY-MM-DD HH:mm:ss'),
      Action: this.state.action,
      Comment: this.state.Comment
    };
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'POST',
      url: `${API_DASHBOARD.Feedback}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    return axios(deviceTypeObject)
      .then(() => {
        this.setState({
          openModal: false
        });
        this.props.refereshData('refresh');
      })
      .catch(function () {
        return 'error';
      });
  };

  handleCancel = () => {
    this.setState({
      openModal: false
    });
  };

  render() {
    let { action, openModal } = this.state;
    let { feedbackForm } = this.props;
    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 10 }
    };
    return (
      <div style={{ marginLeft: '9px' }}>
        {/* <Button type="primary" onClick={this.openModal}>
                    {translationCache &&
                      translationCache['Feedback'] &&
                      translationCache['Feedback'] ?
                      translationCache['Feedback'] :
                      'Feedback'}
        </Button> */}

        <Drawer
          visible={openModal}
          width={1000}
          title="Prediction Feedback"
          onOk={this.handleOk}
          onClose={this.handleCancel}
        >
          <Form {...formItemLayout}>
            <Form.Item label="NotificationId">
              <Input value={feedbackForm.NotificationId} readOnly></Input>
            </Form.Item>
            <Form.Item label="Timestamp">
              <Input value={feedbackForm.Timestamp} readOnly></Input>
            </Form.Item>
            <Form.Item label="TagName">
              <Input value={feedbackForm.TagName} readOnly></Input>
            </Form.Item>
            <Form.Item label="Tag Description">
              <Input value={feedbackForm.TagDescription} readOnly></Input>
            </Form.Item>

            <Form.Item label="Action">
              <Radio.Group
                value={action}
                onChange={(e) => this.setState({ action: e.target.value })}
              >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Comment"
              onChange={(e) => {
                this.setState({
                  Comment: e.target.value
                });
              }}
            >
              <Input.TextArea value={this.state.Comment} />
            </Form.Item>
          </Form>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing(),
  currentTimeZone: getCurrentTimezone()
});

export default connect(mapStateToProps)(AnomalyFeedbackTable);
