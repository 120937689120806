import React from 'react';
import moment from 'moment';
import axios from 'axios';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../../selectors/language';
import localStorage from '../../../utils/localStorage';
import { getCurrentTimezone } from '../../../selectors/layout';
import ReactEcharts from '../../Dashboard/Utils/Echarts/Echarts';
import { getGraphData } from '../apiCalls';
import { Radio, Select, DatePicker, Modal, Button, Form, Input, message } from 'antd';
import { constant, constants } from '../Utils/constants';
import { CreateObservation, getObservationList, getUsersList } from '../ChartCalls';
import Table from '../Widget/Table';
import { API_FILE, IPAddress, REPORTS } from '../../../commons/api';
const { RangePicker } = DatePicker;
const { Option } = Select;
const color = [
  'orange',
  'tomato',
  'steelblue',
  'green',
  'pink',
  'voilet',
  'yellow',
  'red',
  'blue',
  'gray'
];
class AllGraphView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedInterval:
        this.props && this.props.payload && this.props.payload.Interval
          ? this.props.payload.Interval
          : '',
      operatorList: this.props && this.props.OperatorList ? this.props.OperatorList : [],
      frequencyList: constants && constants.frequencyList ? constants.frequencyList : [],
      selectedOperators:
        this.props && this.props.payload && this.props.payload.Operator
          ? this.props.payload.Operator
          : '',
      payload: this.props && this.props.payload ? this.props.payload : {},
      translationCache: props.translationCache || [],
      GraphType: 'line',
      FilterData: [],
      AllFilterData: [],
      option: {},
      ObservationsName: '',
      ObservationsFeedback: '',
      celd: {},
      UsersList: {},
      selectedShareType: ''
    };
  }
  observeRef = React.createRef();
  componentDidMount = async () => {
    this.generateData(this.props.payload);
    let UsersList = await getUsersList();
    let ObservationList = await getObservationList();
    this.setState({ UsersList, ObservationList });
  };
  componentDidUpdate(prevprops) {
    if (prevprops.payload !== this.props.payload) {
      this.setState({ payload: this.props.payload });
      this.generateData(this.props.payload);
    }
    if (
      prevprops.translationCache !== this.props.translationCache ||
      prevprops.userProfile !== this.props.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  }

  generateData = async (payload) => {
    const graph = this;
    // let { payload } = this.state;
    // let payload1 = test ? test : payload;
    let GraphType = payload.GraphType;
    let json = await getGraphData(payload);
    this.prepareData(json, payload);
    const legends = [...new Set(json.map((item) => item.Description))];
    const xAxis = [...new Set(json.map((item) => item.DateTime))];

    let series =
      legends &&
      Array.isArray(legends) &&
      legends.map((xAxisName) => {
        let data = json
          .filter((e) => e.Name === xAxisName)
          .map((jsonData) => {
            return jsonData.Value;
          });
        return {
          name: xAxisName,
          type: GraphType,
          data
        };
      });

    let option = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        // data: legends
        // icon:'roundRect'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          myTool1: {
            show: true,
            title: 'custom extension method 1',
            icon: 'path://M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM514.1 580.1l-61.8-102.4c-2.2-3.6-6.1-5.8-10.3-5.8h-38.4c-2.3 0-4.5.6-6.4 1.9-5.6 3.5-7.3 10.9-3.7 16.6l82.3 130.4-83.4 132.8a12.04 12.04 0 0 0 10.2 18.4h34.5c4.2 0 8-2.2 10.2-5.7L510 664.8l62.3 101.4c2.2 3.6 6.1 5.7 10.2 5.7H620c2.3 0 4.5-.7 6.5-1.9 5.6-3.6 7.2-11 3.6-16.6l-84-130.4 85.3-132.5a12.04 12.04 0 0 0-10.1-18.5h-35.7c-4.2 0-8.1 2.2-10.3 5.8l-61.2 102.3z',

            onclick: function () {
              graph.downLoadExcel();
            }
          },
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxis
      },
      yAxis: {
        type: 'value'
      },
      series: series
    };
    this.setState({
      option
    });
  };
  prepareData = async (json, trendData) => {
    let { GraphType } = this.state;
    let option = this.getOption();
    let xaxis = [];
    let series = [];
    let reportData =
      json &&
      Array.isArray(json) &&
      json.map((data) => {
        return {
          ...data,
          DateTime: moment
            .utc(data.DateTime, 'YYYY-MM-DD HH:mm:ss')
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm')
        };
      });
    let { fromDate, toDate, fromDate1, toDate1, fromDate2, toDate2 } = {};
    let reportName = await this.getReportNametoDownload(
      reportData,
      trendData.Type,
      { fromDate, toDate },
      { fromDate1, toDate1 },
      { fromDate2, toDate2 }
    );
    this.setState({
      reportData,
      reportName: reportName && reportName.file ? reportName.file : ''
    });
    json &&
      json &&
      Array.isArray(json) &&
      json.map((data) => {
        data = {
          ...data,
          DateTime: moment
            .utc(data.DateTime, 'YYYY-MM-DD HH:mm:ss')
            .tz(this.props.currentTimeZone)
            .format('YYYY-MM-DD HH:mm')
        };
        if (!xaxis.includes(data.DateTime)) {
          xaxis.push(data.DateTime);
        }
        if (series.map((data) => data.name).includes(data.Name)) {
          let index = series.map((data) => data.name).indexOf(data.Name);
          series[index].data.push([
            data.DateTime,
            data.Value,
            data.Description,
            data.ParameterName,
            data.DeviceName
          ]);
        } else {
          let obj = {
            name: data.Name,
            data: [
              [data.DateTime, data.Value, data.Description, data.ParameterName, data.DeviceName]
            ],
            type: GraphType && GraphType !== 'Area' ? GraphType : 'line',
            ...(GraphType && GraphType === 'Area' ? { areaStyle: {} } : {})
          };
          series.push(obj);
        }
        return {};
      });
    option.xAxis.data = xaxis;
    option.series = series;
    this.setState({
      option
    });
  };
  getOption = () => {
    const graph = this;
    // let { graphType } = this.state;
    let option = {
      legend: {
        icon: 'roundRect'
      },
      tooltip: {
        trigger: 'axis'
      },
      toolbox: {
        showTitle: false,
        orient: 'horizontal',
        itemSize: 15,
        feature: {
          myTool1: {
            show: true,
            title: 'custom extension method 1',
            icon: 'path://M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494zM514.1 580.1l-61.8-102.4c-2.2-3.6-6.1-5.8-10.3-5.8h-38.4c-2.3 0-4.5.6-6.4 1.9-5.6 3.5-7.3 10.9-3.7 16.6l82.3 130.4-83.4 132.8a12.04 12.04 0 0 0 10.2 18.4h34.5c4.2 0 8-2.2 10.2-5.7L510 664.8l62.3 101.4c2.2 3.6 6.1 5.7 10.2 5.7H620c2.3 0 4.5-.7 6.5-1.9 5.6-3.6 7.2-11 3.6-16.6l-84-130.4 85.3-132.5a12.04 12.04 0 0 0-10.1-18.5h-35.7c-4.2 0-8.1 2.2-10.3 5.8l-61.2 102.3z',

            onclick: function () {
              graph.downLoadExcel();
            }
          },
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        data: []
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: [],
          type: 'line'
        }
      ]
    };
    return option;
  };
  downLoadExcel = () => {
    const { reportName } = this.state;
    const link = document.createElement('a');
    link.href = `${IPAddress.LOCAL}${REPORTS.Reports}/${reportName}`;
    link.setAttribute('download', reportName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
  getReportNametoDownload(excelData, Type, SelectedDate) {
    let payload = {
      PortName: 'Rubus',
      Name: Type && Type ? Type : 'Trend',
      Consumption: 'Trend',
      Data: excelData || [],
      ReportType: 'singleHierarchy',
      GeneratedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      SelectedDate:
        moment
          .utc(SelectedDate.fromDate)
          .tz(this.props.currentTimeZone)
          .format('YYYY-MM-DD HH:mm') +
        '~' +
        moment.utc(SelectedDate.toDate).tz(this.props.currentTimeZone).format('YYYY-MM-DD HH:mm'),
      SelectionType: 'dateRange'
    };
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    let options = {};
    options = {
      method: 'POST',
      url: `${API_FILE.GenerateExcel}?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: JSON.stringify(payload)
    };
    return axios(options)
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        return '';
      });
  }
  onChangeFromDate_ToDate1 = (selectedFromDate_ToDate1) => {
    let { payload } = this.state;
    payload = {
      ...payload,
      FromDate: moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'), //conversion to utc
      ToDate: moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    // FromDate: moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'), //conversion to utc
    // ToDate: moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss')
    this.generateData(payload);
    this.setState({ payload, selectedFromDate_ToDate1 });
  };
  onIntervalChange = async (Interval) => {
    let { payload } = this.state;
    if (payload && payload.GraphType !== 'Table') {
      payload = {
        ...payload,
        Interval
      };
      this.generateData(payload);
      this.setState({ payload, SelectedInterval: Interval });
    } else {
      payload = {
        ...payload,
        Interval
      };
      let json = await getGraphData(payload);
      this.setState({ json: json, SelectedInterval: Interval, payload });
    }
  };
  onCheckboxChange = async (Operator) => {
    let { payload } = this.state;
    if (payload && payload.GraphType !== 'Table') {
      payload = {
        ...payload,
        Operator
      };
      this.generateData(payload);
      this.setState({ payload, selectedOperators: Operator });
    } else {
      payload = {
        ...payload,
        Operator
      };
      let json = await getGraphData(payload);
      this.setState({ payload, json, selectedOperators: Operator });
    }
    // let { payload } = this.state;
    // payload = {
    //   ...payload,
    //   Operator//   // let { selectedOperators } = this.state;
    //   let { payload } = this.state;
    //   payload = {
    //     ...payload,
    //     Operator
    //   };
    //   this.generateData(payload);
    //   this.setState({ payload, selectedOperators: Operator });
    //   // if (e.target.checked === true) {
    //   //   this.setState({ selectedOperators: e.target.value });
    //   //   let { payload } = this.state;
    //   //   payload = {
    //   //     ...payload,
    //   //     Operator: e.target.value
    //   //   };
    //   //   this.setState({ payload });
    //   //   this.generateData(payload);
    //   // }
    //   // else if(e.target.checked === false)
    //   // {
    //   //   // let data = checkedArray.filter((val) => val !== e.target.value);
    //   // //   this.setState({ checkedArray: data });
    //   // //   this.props.handleDropdownChange(data);
    //   // }

    //   // if (e.target.checked === true) {
    //   //   checkedArray.push(e.target.value);
    //   //   this.setState({ checkedArray });
    //   //   this.props.handleDropdownChange(checkedArray);
    //   // } else if (e.target.checked === false) {
    //   //   let data = checkedArray.filter((val) => val !== e.target.value);
    //   //   this.setState({ checkedArray: data });
    //   //   this.props.handleDropdownChange(data);
    //   // }
    // };
    // };
    // this.generateData(payload);
    // this.setState({ payload, selectedOperator: Operator });
  };

  radioOnChange = async (e) => {
    let GraphType = e.target.value;
    let { payload } = this.state;
    if (GraphType !== 'Table') {
      payload = {
        ...payload,
        GraphType
      };
      this.generateData(payload);
      this.setState({ payload, GraphType });
    } else {
      payload = {
        ...payload,
        GraphType
      };
      let json = await getGraphData(payload);
      this.setState({ json: json, payload, GraphType });
    }
  };
  onShareTypeChange = (value) => {
    this.setState({ selectedShareType: value });
  };
  onObservationSave = async () => {
    let { celd } = this.state;
    // e.preventDefault();
    this.observeRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          ...values,
          DateTime: celd && celd.data[0],
          // Value: celd && celd.data[1],
          // Description: celd && celd.data[2],
          Parameter: celd && celd.data[3],
          Device: celd && celd.data[4],
          Operator: 'AVG'
          // ShareType: "Group",
          // ShareUsers: "user3,user4"
        };
        let response = await CreateObservation(payload);
        if (response && response.message) {
          message.success(this.translation(response.message));
          this.observeRef && this.observeRef.current && this.observeRef.current.resetFields();
          let ObservationList = await getObservationList();
          this.setState({
            visible: false,
            ObservationList,
            selectedShareType: ''
          });
          // let ObservationList = await getObservationList()
          this.props.ObservationList(ObservationList);
        } else if (response && response.Error) {
          message.error(this.translation(response.Error));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onObservationCancel = () => {
    this.observeRef && this.observeRef.current && this.observeRef.current.resetFields();
    this.setState({ visible: false, selectedShareType: '' });
  };

  onChartClick = (cellData) => {
    this.setState({
      celd: cellData,
      visible: true
    });
    // this.props.(cellData);
  };
  disabledDate = (current) => {
    return current > moment().startOf('day').add(1, 'day');
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    let {
      selectedOperators,
      GraphType,
      payload,
      frequencyList,
      operatorList,
      json,
      visible,
      UsersList,
      selectedShareType
    } = this.state;
    let onEvents = {
      click: (cellData) => this.onChartClick(cellData)
    };
    return (
      <>
        {/* <div style={{ margin: '5px 0px', display: 'flex', justifyContent: 'flex-end' }}>
          {constants &&
            Array.isArray(constants.operatorList) &&
            constants.operatorList &&
            constants.operatorList.map((operator, index) => {
              return (
                <Checkbox
                  key={operator.label}
                  // value={operator}
                  defaultValue={selectedOperators}
                  checked={operator.value === selectedOperators}
                  onChange={(e) => this.onCheckboxChange(e, index)}
                >
                  {operator.label}
                </Checkbox>
              );
            })}
        </div> */}
        <div>
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            style={{ width: '330px' }}
            placeholder={[this.translation('Start DateTime'), this.translation('End DateTime')]}
            value={[moment.utc(payload.FromDate).local(), moment.utc(payload.ToDate).local()]}
            onChange={this.onChangeFromDate_ToDate1}
            onOk={this.onOk}
            disabledDate={this.disabledDate}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '5px 0px' }}>
          <Radio.Group onChange={(e) => this.radioOnChange(e)} value={GraphType}>
            <Radio.Button value={'line'}>{this.translation('Line')}</Radio.Button>
            <Radio.Button value={'bar'}>{this.translation('Bar')}</Radio.Button>
            <Radio.Button value={'Area'}>{this.translation('Area')}</Radio.Button>
            <Radio.Button value={'Table'}>{this.translation('Table')}</Radio.Button>
          </Radio.Group>
          {payload && payload.Type === 'historic' ? (
            <>
              <Select
                showSearch
                style={{ width: 100 }}
                value={payload.Interval}
                defaultValue={payload.Interval}
                placeholder={this.translation('Select Interval')}
                onChange={this.onIntervalChange}
                options={frequencyList}
              />
              <Select
                showSearch
                style={{ width: 100 }}
                value={selectedOperators}
                defaultValue={selectedOperators}
                placeholder={this.translation('Select Operator')}
                onChange={this.onCheckboxChange}
                // options={operatorList}
              >
                {operatorList &&
                  Array.isArray(operatorList) &&
                  operatorList.map((Operator, index) => {
                    return (
                      <Option key={index} value={Operator.Key}>
                        {Operator.Value}
                      </Option>
                    );
                  })}
              </Select>
            </>
          ) : (
            ''
          )}
          {/* <Select
            // showSearch
            defaultValue={SelectedInterval}
            placeholder="Select interval"
            onChange={this.onIntervalChange}
            // onSearch={onSearch}
            // filterOption={filterOption}
            options={[
              {
                value: '1m',
                label: '1m'
              },
              {
                value: '1h',
                label: '1h'
              }
            ]}
          /> */}
        </div>
        {GraphType === 'Table' ? (
          <Table
            panelData={{
              graph: 'trends',
              trendData: json,
              colors: color,
              update: this.state.update,
              GraphType
            }}
            currentTimeZone={this.props.currentTimeZone}
            className="trendTable"
            dataSource={this.state.json}
          />
        ) : (
          <ReactEcharts
            option={this.state.option}
            notMerge={true}
            lazyUpdate={true}
            onEvents={payload.Type === 'historic' ? onEvents : null}
            style={{ height: '80%', width: '100%' }}
            className="react_for_echarts"
            theme="dark"
          />
        )}

        <Modal
          title={this.translation('Observation List')}
          open={visible}
          onOk={this.onObservationSave}
          onCancel={this.onObservationCancel}
          style={{ width: '100px' }}
          footer={[
            <Button key="back" onClick={this.onObservationCancel}>
              {this.translation('Cancel')}
            </Button>,
            <Button key="submit" type="primary" onClick={this.onObservationSave}>
              {this.translation('Save')}
            </Button>
          ]}
        >
          <Form ref={this.observeRef} layout="vertical">
            <Form.Item
              name={'Name'}
              label={this.translation(constant.ObservationName)}
              rules={[{ required: true, message: this.translation('Input Observation!') }]}
            >
              <Input
                placeholder={this.translation('Please Input Observation Name')}
                onChange={(e) => this.setState({ ObservationsName: e.target.value })}
              ></Input>
            </Form.Item>
            <Form.Item
              name={'Comments'}
              label={this.translation(constant.ObservationFeedback)}
              rules={[{ required: true, message: this.translation('Input Observation Feedback!') }]}
            >
              <Input
                placeholder={this.translation('Please Input Observation Feedback')}
                onChange={(e) => this.setState({ ObservationsFeedback: e.target.value })}
              ></Input>
            </Form.Item>
            <Form.Item
              name={'ShareType'}
              label={this.translation(constant.ShareType)}
              rules={[{ required: true, message: this.translation('Please select ShareType!') }]}
            >
              <Select
                placeholder={this.translation('Please select ShareType')}
                onChange={this.onShareTypeChange}
                // disabled={true}
              >
                <Option value="Public">{this.translation('Public')}</Option>
                <Option value="Private">{this.translation('Private')}</Option>
                <Option value="Group">{this.translation('Group')}</Option>
              </Select>
            </Form.Item>
            {selectedShareType === 'Group' ? (
              <Form.Item
                name={'ShareUsers'}
                label={this.translation(constant.ShareUsers)}
                rules={[{ required: true, message: this.translation('Please select ShareUser!') }]}
              >
                <Select
                  placeholder={this.translation('Please select ShareUser')}
                  // disabled={true}
                >
                  {UsersList &&
                    Array.isArray(UsersList) &&
                    UsersList.map((user, index) => {
                      return (
                        <Option key={index} value={user.UserName}>
                          {user.UserName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            ) : null}
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentTimeZone: getCurrentTimezone(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(AllGraphView);
