import React, { Component } from 'react';
import axios from 'axios';
import localStorage from '../../../utils/localStorage';

class TextComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.text !== this.props.text) {
      this.setState({
        text: this.props.text
      });
      this._getOptionList(this.props.column);
    }
  }

  componentDidMount() {
    this._getOptionList(this.props.column);
  }

  _getOptionList = () => {
    let { column } = this.props;
    const accessToken = localStorage.get('accessToken');
    let siteId = localStorage.get('currentSite');
    let objectHeader = {};
    if (
      column &&
      column.table &&
      column.table.url &&
      column.table.method
      //  && column.table.payload
      // && column.table.method === 'POST'
    ) {
      objectHeader = {
        method: column.table.method,
        url: `${column.table.url}?SiteId=${siteId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          ...column.table.payload
        }
      };
    }
    return axios(objectHeader)
      .then((response) => {
        if (Array.isArray(response.data)) {
          let optionList = response.data;
          this.setState({
            optionList
          });
        }
      })
      .catch(() => {
        return [];
      });
  };

  render() {
    let { text } = this.props;
    let { optionList } = this.state;
    let filter =
      optionList &&
      Array.isArray(optionList) &&
      optionList.filter((option) => option.Value === text);
    return <div>{filter && filter[0] && filter[0].Key ? filter[0].Key : text}</div>;
  }
}
export default TextComponent;
