import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Avatar } from 'antd';

import localStorage from '../../../../../utils/localStorage';
import history from '../../../../../commons/history';
import { setMenuSelection } from '../../../../../modules/menu/menu.duck';

import './landingpage.css';
import { createStructuredSelector } from 'reselect';
import { LANDINGPAGE, MASTERIMAGES } from '../../../../../commons/api';

const colStyle = {
  color: 'white',
  //border: '2px solid #333333',
  margin: '5px',
  padding: '5px',
  borderRadius: '10px',
  boxShadow: '5px 5px 30px #333333'
};
const titleStyle = {
  color: 'black',
  fontSize: '1.2em',
  fontWeight: 'bold',
  textAlign: 'center',
  // padding:'5px',
  borderBottom: '2px dotted black',
  marginBottom: '10px'
};
const avatarStyle = {
  height: '50px',
  width: '50px'
};
const statusStyle = {
  fontSize: '1em',
  paddingLeft: '5px',
  color: 'black',
  fontWeight: 'bold'
};
class EquipmentOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: {},
      activeCrane: []
    };
  }
  timeTicket = null;
  // signal = axios.CancelToken.source();
  requestDeviceCheck = true;
  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this._getdeviceList();

    this.timeTicket = setInterval(() => {
      if (this.requestDeviceCheck) {
        // Request Pending status check for Device Stauts
        this._getdeviceList();
      }
    }, 1 * 60000);
  }

  componentWillUnmount() {
    // this.signal.cancel('Api is being canceled');
    clearInterval(this.timeTicket);
  }

  _getdeviceList = () => {
    this.requestDeviceCheck = false;
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    if (!siteId) {
      siteId = 'test';
    }
    const headers = {
      method: 'POST',
      url: `${LANDINGPAGE.LandingPageCount}?SiteId=${siteId}`,

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        Type: 'Crane'
      }
      // cancelToken: this.signal.token,
    };
    axios(headers)
      .then((response) => {
        if (response.data) {
          this.setState({ deviceList: response.data });
          this.requestDeviceCheck = true;
        }
      })
      .catch(() => {
        return {};
      });
  };

  onCraneImageClick = (craneObject, moduleName) => {
    if (moduleName && moduleName === 'MHC') {
      history.push(`${craneObject}`);
      let mhcPath = ['Modules', '1218', '1220'];
      this.props.actions.setMenuSelection(mhcPath);
    } else if (moduleName && moduleName === 'QC SVG') {
      let QC = ['Modules', '1136', '1138'];
      this.props.actions.setMenuSelection(QC);
      history.push(`${craneObject}`);
    } else if (moduleName && moduleName === 'RTG') {
      let RTG = ['Modules', '1144', '1146'];
      this.props.actions.setMenuSelection(RTG);
      history.push(`${craneObject}`);
    } else {
      history.push(`${craneObject}`);
    }
  };

  render() {
    let { deviceList } = this.state;
    // let colorStatus = {
    //   'No Data': '#808080',
    //   'Main Line': '#099c0c',
    //   Depot: '#3397da',
    //   Maintenance: '#ffc300'
    // };
    return (
      <div style={{ backgroundColor: '#243a4f', minHeight: window.innerHeight - 67 }}>
        <div>
          <Row
            style={{
              padding: '10px',
              marginBottom: '10px',
              backgroundColor: 'rgb(195, 200, 206)'
            }}
          >
            <Col span={3} idleset={2}></Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`${MASTERIMAGES.MHC_RUNNING_V1_PNG}`}
                style={avatarStyle}
              />
              <span style={statusStyle}>RUNNING</span>
            </Col>
            <Col span={5}>
              <Avatar shape="square" src={`${MASTERIMAGES.MHC_IDLE_V1_PNG}`} style={avatarStyle} />
              <span style={statusStyle}>IDLE</span>
            </Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`${MASTERIMAGES.Moblie_Harbour_Crane_V3_PNG}`}
                style={avatarStyle}
              />
              <span style={statusStyle}>STOPPED</span>
            </Col>
            <Col span={5}>
              <Avatar shape="square" src={`${MASTERIMAGES.MHC_Alert_V1_PNG}`} style={avatarStyle} />
              <span style={statusStyle}>DAMAGED/ALERT</span>
            </Col>
          </Row>
          <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#b3b3b3' }}>
            {deviceList &&
              deviceList &&
              Array.isArray(deviceList) &&
              deviceList[0].ListOfDevices.map((device, index) => {
                return (
                  <Col
                    key={index}
                    className="colRunning"
                    style={{
                      ...colStyle,
                      backgroundColor: device && device.color ? device.color : '',
                      width: '9%'
                    }}
                    onClick={() => {
                      if (device && device.deviceCode && device.deviceCode === 'MHC-1') {
                        this.onCraneImageClick(
                          `/rubus/HMI/distinct/MHC/${device.deviceCode}`,
                          'MHC'
                        );
                      } else if (device && device.deviceCode && device.deviceCode === 'MHC2') {
                        this.onCraneImageClick(
                          `/rubus/HMI/distinct/MHC2/${device.deviceCode}`,
                          'MHC'
                        );
                      } else if (device && device.deviceCode && device.deviceCode === 'MHC3') {
                        this.onCraneImageClick(
                          `/rubus/HMI/distinct/MHC3/${device.deviceCode}`,
                          'MHC'
                        );
                      }
                    }}
                    width={'7%'}
                  >
                    <div style={titleStyle}>{device.deviceCode} </div>
                    <div
                      className={'stop123'}
                      style={{ backgroundImage: device && device.url ? `url(${device.url})` : '' }}
                    ></div>
                  </Col>
                );
              })}
          </div>
        </div>
        <div>
          <Row
            style={{
              padding: '10px',
              marginBottom: '10px',
              backgroundColor: 'rgb(195, 200, 206)'
            }}
          >
            <Col span={3} idleset={2}></Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`${MASTERIMAGES.QC_RUNNING_V3_PNG}`}
                style={avatarStyle}
              />
              <span style={statusStyle}>RUNNING</span>
            </Col>
            <Col span={5}>
              <Avatar shape="square" src={`${MASTERIMAGES.QC_IDLE_V3_PNG}`} style={avatarStyle} />
              <span style={statusStyle}>IDLE</span>
            </Col>
            <Col span={5}>
              <Avatar shape="square" src={`${MASTERIMAGES.QC1_V3_PNG}`} style={avatarStyle} />
              <span style={statusStyle}>STOPPED</span>
            </Col>
            <Col span={5}>
              <Avatar shape="square" src={`${MASTERIMAGES.QC1_V3_PNG}`} style={avatarStyle} />
              <span style={statusStyle}>DAMAGED/ALERT</span>
            </Col>
          </Row>
          <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#b3b3b3' }}>
            {deviceList &&
              deviceList &&
              Array.isArray(deviceList) &&
              deviceList[1].ListOfDevices.map((key, index) => {
                return (
                  <Col
                    key={index}
                    className="colRunning"
                    style={{
                      ...colStyle,
                      backgroundColor: key && key.color ? key.color : '',
                      width: '9%'
                    }}
                    onClick={() => this.onCraneImageClick('/rubus/HMI/static/QC SVG', 'QC SVG')}
                    width={'7%'}
                  >
                    <div style={titleStyle}>{key.deviceCode} </div>
                    <div
                      className={'stop123'}
                      style={{ backgroundImage: key && key.url ? `url(${key.url})` : '' }}
                    ></div>
                  </Col>
                );
              })}
          </div>
        </div>

        <div>
          <Row
            style={{
              padding: '10px',
              marginBottom: '10px',
              backgroundColor: 'rgb(195, 200, 206)'
            }}
          >
            <Col span={3} idleset={2}></Col>
            <Col span={5}>
              <Avatar
                shape="square"
                src={`${MASTERIMAGES.RTG_RUNNING_V3_PNG}`}
                style={avatarStyle}
              />
              <span style={statusStyle}>RUNNING</span>
            </Col>
            <Col span={5}>
              <Avatar shape="square" src={`${MASTERIMAGES.RTG_Idle_V3_PNG}`} style={avatarStyle} />
              <span style={statusStyle}>IDLE</span>
            </Col>
            <Col span={5}>
              <Avatar shape="square" src={`${MASTERIMAGES.RTG_CRANE_V3_PNG}`} style={avatarStyle} />
              <span style={statusStyle}>STOPPED</span>
            </Col>
            <Col span={5}>
              <Avatar shape="square" src={`${MASTERIMAGES.RTG_Alert_V3_PNG}`} style={avatarStyle} />
              <span style={statusStyle}>DAMAGED/ALERT</span>
            </Col>
          </Row>
          <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#b3b3b3' }}>
            {deviceList &&
              deviceList &&
              Array.isArray(deviceList) &&
              deviceList[2] &&
              deviceList[2].ListOfDevices.map((key, index) => {
                return (
                  <Col
                    key={index}
                    className="colRunning"
                    style={{
                      ...colStyle,
                      backgroundColor: key && key.color ? key.color : '',
                      width: '9%'
                    }}
                    onClick={() => this.onCraneImageClick('/rubus/HMI/static/RTG', 'RTG')}
                    width={'7%'}
                  >
                    <div style={titleStyle}>{key.deviceCode} </div>
                    <div
                      className={'stop123'}
                      style={{ backgroundImage: key && key.url ? `url(${key.url})` : '' }}
                    ></div>
                  </Col>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setMenuSelection
      },
      dispatch
    )
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentOverview);
