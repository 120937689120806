import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';

import Preferences from './Preferences';
import { changeTheme, changeThemeing, setNavigationMode } from '../../../modules/theme/theme.duck';
import { makeSelectTheme, makeSelectThemeing } from '../../../selectors/theme';

const mapStateToProps = createStructuredSelector({
  selectedTheme: makeSelectTheme(),
  selectedThemeing: makeSelectThemeing()
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        changeTheme,
        changeThemeing,
        setNavigationMode
      },
      dispatch
    )
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
