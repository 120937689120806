export const adapterArrToObj = (Arr) => {
  var obj = {};
  Arr &&
    Array.isArray(Arr) &&
    Arr.map((a) => {
      obj[a.key] = a.maniObj;
      if (a.children !== undefined) {
        obj[a.key]['children'] = adapterArrToObj(a.children);
      }
      return {};
    });
  return obj;
};

export const adapter = (data) => {
  let finalData = [];

  for (let [key, value] of Object.entries(data)) {
    if (typeof value === 'object') {
      let obj = {};
      obj.key = key;
      obj.title = value.name;
      obj.maniObj = value;
      if (value.children !== undefined) {
        obj.children = adapter(value.children);
      }
      finalData.push(obj);
    }
  }
  return finalData;
};

export const data = {
  _key: '2134935',
  _id: 'menus/2134935',
  _rev: '_aNbtxIq--_',
  name: 'Rubus',
  modules: {
    name: 'Modules',
    key: 'modules',
    children: {
      equipmentOverview: {
        name: 'Equipment Overview',
        path: '/rubus/EquipmentOverview',
        key: 'equipmentOverview'
      },
      trendAnalysis: {
        name: 'Trend Analysis',
        path: '/rubus/Trends',
        key: 'trendAnalysis'
      },
      rtDashboard: {
        name: 'RT Dashboard',
        path: '/rubus/Trends',
        key: 'rtDashboard'
      },
      dataWatch: {
        name: 'Data Watch',
        path: '/rubus/DataWatch/Live Data Watch',
        key: 'dataWatch'
      },
      reports: {
        name: 'Reports',
        path: '/rubus/DataWatch/MHC Performance Report',
        key: 'reports'
      },
      predictions: {
        name: 'Predictions',
        path: '/rubus/dashboard/Predictions',
        key: 'predictions'
      }
    }
  },
  configuration: {
    name: 'Configuration',
    key: 'configuration',
    children: {
      asset_config: {
        name: 'Asset Configuration',
        path: '/rubus/assetConfiguration',
        key: 'asset_config',
        children: {
          asset_config: {
            name: 'Configuration',
            path: '/rubus/assetConfiguration',
            key: 'asset_config'
          },
          device: {
            name: 'Devices',
            path: '/rubus/devices',
            key: 'device'
          },
          deviceType: {
            name: 'Equipment Type',
            path: '/rubus/device-type',
            key: 'deviceType'
          },
          assetSettings: {
            name: 'Settings',
            path: '/rubus/configurationSettings/configurationUpload',
            key: 'assetSettings'
          }
        }
      },
      report_config: {
        name: 'Report Configuration',
        path: '/rubus/reportConfiguration',
        key: 'report_config'
      },
      dashboard_config: {
        name: 'Dashboard Configuration',
        path: '/rubus/dashboard',
        key: 'dashboard_config'
      }
    }
  }
};
