import React, { useState, useEffect } from 'react';
import { Form, Button, Collapse, Popconfirm, Select, Divider, Space, message, Tooltip } from 'antd';

import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { constant } from '../../../Utils/constants';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import { getRemedyCodes, insertModalData } from '../../../Utils/FormCalls';
import { StyledForm, StyledCollapse, StyledButton, StyledModal } from '../../../CSS/style';
import translation from '../../../Utils/translation';
import themeSettings from '../../../Utils/themeSettings.json';
import InputComponent from '../../../Widgets/InputComponent';
const { Panel } = Collapse;
const { Option } = Select;
const RemedyMaster = 'Remedy_Codes';
const ActionType = 'create';

const RemediesForm = (props) => {
  const [RemediesList, setRemedies] = useState([]);
  const [Visible, setVisible] = useState([false]);
  // eslint-disable-next-line no-unused-vars
  const [translationCache, setTranslationCache] = useState(props.translationCache || {});
  useEffect(() => {
    (async () => {
      try {
        let response = await getRemedyCodes();
        setRemedies(response);
      } catch (e) {
        return {};
      }
    })();
  }, []);

  const RemedyModalRef = React.createRef();

  function onRemedyModalOpen() {
    setVisible(true);
  }
  function onCancel(e) {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      setVisible(false);
    }
  }
  const onRemedyModalSubmit = async (e) => {
    e.preventDefault();
    // let data = RemedyModalRef.current.getFieldValue();
    // let payloadCause = data;
    // let statusModal = await insertModalData(RemedyMaster, ActionType, payloadCause);
    // setVisible(false);
    // if (statusModal && statusModal.message) {
    //   message.success(statusModal.message);
    //   if (RemedyModalRef.current) RemedyModalRef.current.resetFields();
    //   let list = await getRemedyCodes();
    //   setRemedies(list);
    // } else if (statusModal && statusModal.Error) {
    //   message.error(statusModal.Error);
    // }
    RemedyModalRef.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          ...values
        };
        let response = await insertModalData(RemedyMaster, ActionType, payload);
        setVisible(false);
        if (response && response.message) {
          message.success(response.message);
          if (RemedyModalRef.current) RemedyModalRef.current.resetFields();
          let list = await getRemedyCodes();
          setRemedies(list);
        } else if (response && response.Error) {
          message.error(response.Error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!'
    },
    number: {
      range: '${label} must be between ${min} and ${max}'
    }
    // pattern: {
    //   mismatch: '${label} does not match pattern'
    // }
  };
  return (
    <>
      <div>
        <Form.List name={[props.causeIndex, 'Remedies']}>
          {(Remedies, { add, remove }) => (
            <>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <Form.Item>
                  <StyledButton
                    theme={themeSettings}
                    // type="dashed"
                    // size="middle"
                    // style={{ background: '#29415a', color: '#fff', marginBottom: '20px' }}
                    onClick={() => add()}
                    // icon={<PlusOutlined />}
                  >
                    {translation(translationCache, constant.addRemedie)}
                  </StyledButton>
                </Form.Item>
              </div>
              {Remedies && Array.isArray(Remedies) && Remedies.length > 0 ? (
                <StyledCollapse theme={themeSettings} accordion defaultActiveKey={[]}>
                  {Remedies.map((remedies, index2) => (
                    <Panel
                      key={index2}
                      header={`${translation(translationCache, 'Remedy')} ${index2 + 1}`}
                      extra={
                        <Popconfirm
                          title={translation(
                            translationCache,
                            'Are you sure that you want to delete the remedy'
                          )}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={() => remove(remedies.name)}
                          okText={translation(translationCache, constant.yes)}
                          cancelText={translation(translationCache, constant.no)}
                        >
                          <Tooltip
                            title={translation(translationCache, 'Delete')}
                            placement="bottom"
                          >
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>
                      }
                    >
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Form.Item
                          style={{ width: '100%', flex: '50%' }}
                          {...remedies}
                          label={translation(translationCache, constant.remedieCode)}
                          name={[remedies.name, 'RemedyId']}
                          fieldKey={[remedies.fieldKey, 'Remedie Code']}
                          key={index2}
                          rules={[
                            { required: true }
                            // {
                            // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                            // pattern: new RegExp(/^[^%!^ ]*$/)
                            // }
                          ]}
                        >
                          <Select
                            style={{ width: '70%' }}
                            onChange={(value) =>
                              props.onRemedieChange(
                                value,
                                'Problems',
                                props.problemIndex,
                                'Causes',
                                props.causeIndex,
                                'Remedies',
                                remedies.name,
                                RemediesList.filter((remedie) => remedie.Id === value)
                              )
                            }
                            placeholder={translation(translationCache, 'Select Remedy')}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider
                                  style={{
                                    margin: '8px 0'
                                  }}
                                />
                                <Space
                                  style={{
                                    padding: '0 8px 4px'
                                  }}
                                >
                                  <Button
                                    type="dashed"
                                    // icon={<PlusOutlined />}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      onRemedyModalOpen();
                                    }}
                                  >
                                    {translation(translationCache, 'Add Remedy')}
                                  </Button>
                                </Space>
                              </>
                            )}
                          >
                            {RemediesList.map((remedies, index) => {
                              return (
                                <Option key={index} value={remedies.Id}>
                                  {' '}
                                  {translation(translationCache, remedies.RemedyCode)}{' '}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...remedies}
                          style={{ width: '100%', flex: '50%' }}
                          label={translation(translationCache, constant.description)}
                          //style={{ marginLeft: '20px' }}
                          name={[remedies.name, 'Description']}
                          fieldKey={[remedies.fieldKey, 'Description']}
                          key={index2}
                          rules={[{ required: false }]}
                        >
                          <InputComponent
                            disabled={true}
                            placeholder={translation(
                              translationCache,
                              'Autopopulate based on Remedy'
                            )}
                            // maxLength={150}
                            style={{ width: '70%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...remedies}
                          label={translation(translationCache, constant.remarks)}
                          //style={{ marginLeft: '20px' }}
                          style={{ width: '100%', flex: '50%', marginLeft: '50px' }}
                          name={[remedies.name, 'Remarks']}
                          fieldKey={[remedies.fieldKey, 'Remarks']}
                          key={index2}
                        >
                          <InputComponent
                            placeholder={translation(translationCache, 'Input Remarks')}
                            //  maxLength={150}
                            style={{ width: '32%' }}
                          />
                        </Form.Item>
                        {Visible === true ? (
                          <StyledModal
                            width={800}
                            closable
                            title={translation(translationCache, 'New Remedy')}
                            visible={Visible}
                            onCancel={onCancel}
                            footer={[
                              <Button key={'2'} onClick={onCancel}>
                                {translation(translationCache, constant.cancel)}
                              </Button>,
                              <Button
                                key={'1'}
                                type="primary"
                                onClick={(e) => onRemedyModalSubmit(e)}
                              >
                                {translation(translationCache, constant.submit)}
                              </Button>
                            ]}
                          >
                            {/* <StyledForm
                                                    ref={RemedyModalRef}
                                                    // onFinish={onRemedyModalSubmit}
                                                    // onFinishFailed={this.onFinishFailed}
                                                    // initialValues={formObject}
                                                    layout="vertical"
                                                > */}
                            <StyledForm
                              name="Item"
                              theme={themeSettings}
                              ref={RemedyModalRef}
                              // initialValues={formObject}
                              // onFinish={this.onFinish}
                              layout="vertical"
                              // labelCol={{ flex: '130px' }}
                              labelAlign="right"
                              labelWrap
                              //wrapperCol={{ flex: 1 }}
                              validateMessages={validateMessages}
                            >
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.newRemedyCode)}
                                name="RemedyCode"
                                rules={[
                                  { required: true },
                                  {
                                    // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                                    // pattern: new RegExp(/^[^%!^ ]*$/)
                                  }
                                ]}
                              >
                                <InputComponent
                                  // maxLength={50}
                                  placeholder={translation(translationCache, 'Input NewRemedyCode')}
                                  style={{ width: '70%' }}
                                />
                              </Form.Item>
                              <Form.Item
                                style={{ width: '100%', flex: '50%' }}
                                label={translation(translationCache, constant.description)}
                                name="Description"
                                rules={[
                                  { required: true },
                                  {
                                    // pattern: new RegExp(/^[A-Za-z0-9-_& ]*$/)
                                    // pattern: new RegExp(/^[^%!^ ]*$/)
                                  }
                                ]}
                              >
                                <InputComponent
                                  placeholder={translation(translationCache, 'Input Description')}
                                  style={{ width: '70%' }}
                                  // maxLength={150}
                                />
                              </Form.Item>
                            </StyledForm>
                          </StyledModal>
                        ) : (
                          ''
                        )}
                      </div>
                    </Panel>
                  ))}
                </StyledCollapse>
              ) : null}
            </>
          )}
        </Form.List>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(RemediesForm);
