import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { Link } from 'react-router-dom';

export const StyledMain = styled.div`
  background: #e3e3e3;
`;
export const StyledTrends = styled.div`
  background: #1a3652;
  margin: 5px;
  padding: 30px;
`;
export const DrawerFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
`;
export const StyledAddButton = styled(Button)`
  margin-left: 30px;
`;
export const AddTableButton = styled(Button)`
  margin-bottom: 20px;
`;
export const StyledIFrame = styled.iframe`
  width: 402%;
  height: 400%;
  border: 0;
  position: absolute;
  top: 0;
  left: -0.5%;
  background: #191a1d;
  -webkit-transform: scale(0.5);
  transform: scale(0.25);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  margin: 2px auto;
`;

export const LinkOverlay = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  :hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
  }
`;

export const StyledApplication = styled.div`
  background: white;
  margin: 20px;
  padding: 30px;
  min-height: 750px;
`;

export const DatePickerSlider = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.85) !important;
`;
// export const DatePickerIcon = styled()`
//   font-size: 33px;
//   cursor: pointer;
//   color: white;
// `;
export const darkTheme = {
  text: 'rgba(0,0,0,.85)',
  even: '#595959',
  odd: '#8c8c8c',
  hover: '#f5f5f5'
};

export const lightTheme = {
  text: 'black',
  even: '#7a8793',
  odd: '#bdc0c2',
  hover: '#dedede'
};

export const StyledTable = styled.table`
  caption-side: top;


  color: ${(props) => props.theme.text} !important;

  width: 100%;
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */

  caption-side: bottom;
  /* empty-cell: show | hide;  */
  /* empty-cell is a property of table or the cells themselves */

  /* vertical-align: baseline | sub | super | text-top | 
                text-bottom | middle | top | bottom | 
                <percentage> | <length> */

  /* tbody {
    vertical-align: top;
  }              */
  td,
  th {
    border: none;
  }
  /* td,
  th {
      color:${(props) => props.theme.text} !important;
    border: 1px solid red;
   
  } */

  td {
    padding: 11px 10px;
  }
  .ant-table-thead > tr > th{
    font-weight:bold !important
    font-size: 1em !important;
    color:white !important;
    background: #214972 ;
  }
  text[style] {
    // font-weight:bold !important
    font-size: 1em !important;
    color: ${(props) => props.theme.text} !important;
 }
  tbody tr {
    :nth-of-type(odd) {
      background-color:${(props) => props.theme.odd} !important;
    }
    :hover {
      background-color:${(props) => props.theme.hover} !important; 
    }
  }
  tbody tr {
    :nth-of-type(even) {
      background-color:${(props) => props.theme.even} !important;
    }
    :hover {
      background-color:${(props) => props.theme.hover} !important; 
    }
  }
  thead > tr {
    color: ${(props) => props.theme.text} !important;
    background-color: #262626 !important;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

export const StyledModal = styled(Modal)`
  width: 55% !important;

  .ant-modal-content {
    border-radius: 20px;
    background: #1a3652;
  }

  .ant-modal-header {
    background: #1a3652;
  }
  .ant-modal-title {
    color: #ffffff !important;
  }
  .ant-form-item-required {
    color: #ffffff !important;
  }

  .ant-modal-close {
    color: white !important;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
  }
`;
