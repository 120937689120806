import React, { Component } from 'react';
import axios from 'axios';
import { Select } from 'antd';

import localStorage from '../../../utils/localStorage';
import { MASTER } from '../../../commons/api';
const { Option } = Select;

class SelectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      conditionList: [],
      column: this.props.column,
      text: this.props.text
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.text !== this.props.text) {
      this.setState({
        text: this.props.text,
        column: this.props.column
      });
    }

    if (prevprops.column !== this.props.column) {
      let { column, datasource } = this.props;
      if (column && column.table && column.table.payload && column.table.payload.conditionList) {
        //Condition based filter option
        let conditionList = column.table.payload.conditionList;
        let dataSourceKeys = Object.keys(datasource);
        conditionList &&
          Array.isArray(conditionList) &&
          conditionList.map((condition, key) => {
            if (condition.referenceKey && dataSourceKeys.indexOf(condition.referenceKey) > -1) {
              conditionList[key].value = datasource[condition.referenceKey];
            }
            return {};
          });
        this.setState({
          conditionList
        });
        this._getOptionList(this.props.column, conditionList);
      }
      this._getOptionList(this.props.column);
    }
  }

  componentDidMount() {
    let { column, datasource } = this.props;
    if (
      column &&
      datasource &&
      column.table &&
      column.table.payload &&
      column.table.payload.conditionList
    ) {
      //Condition based filter option
      let conditionList = column.table.payload.conditionList;
      let dataSourceKeys = Object.keys(datasource);
      conditionList &&
        Array.isArray(conditionList) &&
        conditionList.map((condition, key) => {
          if (condition.referenceKey && dataSourceKeys.indexOf(condition.referenceKey) > -1) {
            conditionList[key].value = datasource[condition.referenceKey];
          }

          return {};
        });
      this.setState({
        conditionList
      });
      this._getOptionList(this.props.column, conditionList);
    } else {
      this._getOptionList(this.props.column);
    }
  }

  _getOptionList = (tableName) => {
    // let { conditionList } = this.state
    const siteId = localStorage.get('currentSite');

    const accessToken = localStorage.get('accessToken');
    let objectHeader = {};
    if (tableName && tableName.table && Array.isArray(tableName.table)) {
      //Direct OPtions Array
      this.setState({
        optionList: tableName.table
      });
      return tableName.table;
    }
    if (tableName && tableName.table && tableName.table.url && !tableName.table.method) {
      objectHeader = {
        method: 'GET',
        url: `/api/${tableName.table.url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
    } else if (
      tableName &&
      tableName.table &&
      tableName.table.url &&
      tableName.table.method &&
      tableName.table.payload &&
      tableName.table.method === 'POST'
    ) {
      objectHeader = {
        //URL,POST
        method: tableName.table.method,
        url: `${tableName.table.url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          ...tableName.table.payload,
          SiteId: siteId
          // conditionList: conditionList ? conditionList : []
        }
      };
    } else {
      //Table Name
      objectHeader = {
        method: 'POST',
        url: `${MASTER.DropDown}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          tableName: tableName.table,
          SiteId: siteId
        }
      };
    }
    return axios(objectHeader)
      .then((response) => {
        if (Array.isArray(response.data)) {
          let optionList = response.data;
          this.setState({
            optionList
          });
        }
      })
      .catch(() => {
        return [];
      });
  };

  // onSearch = (val) => {
  //   }

  render() {
    let { column, record, data } = this.props;
    let { optionList } = this.state;
    let filterListed = [];
    if (
      column &&
      column.validation &&
      column.validation.duplicate &&
      column.validation.duplicate === true &&
      !column.validation.conditionFilter
    ) {
      if (data && data !== undefined && Array.isArray(data)) {
        // If datasource is Array
        filterListed = data.map((da) => {
          return da[column.key];
        });
      } else if (data && data !== undefined && Object.is(data, data)) {
        // If datasource is object
        filterListed = Object.keys(data).map((da) => {
          return data[da][column.key];
        });
      }
    } else {
      if (column.validation && column.validation.conditionFilter && this.props.equipment) {
        // Based on colundition filter true or false
        // "validation": {   // format
        //     "duplicate": true,
        //     "conditionFilter": {
        //         "MTD": false,
        //         "MTC": true
        //     }
        // }
        let split = this.props.equipment.split(/[-]/);
        if (column.validation.conditionFilter[split[0]]) {
          //filter true

          if (data && data !== undefined && Array.isArray(data)) {
            // If datasource is Array when filter true
            filterListed = data.map((da) => {
              return da[column.key];
            });
          } else if (data && data !== undefined && Object.is(data, data)) {
            // If datasource is object  when filter true
            filterListed = Object.keys(data).map((da) => {
              return data[da][column.key];
            });
          }
        }
      }
    }

    return (
      <Select
        showSearch
        value={this.state.text}
        style={{ width: 300 }}
        onChange={(e) => this.props.handleFieldChange(e, column.key, record.key)}
        // onKeyPress={e => this.props.handleKeyPress(e, record.key)}
        placeholder={column.placeholder}
        // onSearch={this.onSearch}
        filterOption={(input, option) => {
          if (option.props && option.props.children && typeof option.props.children === 'string') {
            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          } else {
            return true;
            // return option.props && option.props.children &&  option.props.children.indexOf(input) >= 0
          }
        }}
      >
        {optionList &&
          optionList
            .filter((col) => {
              if (
                column &&
                column.validation &&
                column.validation.duplicate &&
                column.validation.duplicate === true
              ) {
                // optionsList is an Array of Objects
                if (col && col.key && col.value) {
                  if (!(filterListed.indexOf(col.value) >= 0)) {
                    return col;
                  }
                } else {
                  if (!(filterListed.indexOf(col) >= 0)) {
                    return col;
                  }
                }
              } else {
                // optionsList is an Array of strings
                if (!(filterListed.indexOf(col) >= 0)) {
                  return col;
                } else {
                  return col;
                }
              }

              return {};
            })
            .map((data, index) => {
              if (typeof data === 'object' && data !== null) {
                return (
                  <Option key={index} value={data.Equipment}>
                    {data.Equipment}
                  </Option>
                );
              } else {
                return (
                  <Option key={index} value={data}>
                    {data}
                  </Option>
                );
              }
            })}
      </Select>
    );
  }
}

export default SelectComponent;
