import React, { Component } from 'react';
import { Menu } from 'antd';
import {
  SettingOutlined,
  GlobalOutlined,
  MonitorOutlined,
  UserOutlined,
  DatabaseOutlined
} from '@ant-design/icons';

import {
  Settings,
  SystemParameter,
  ZeroAdjust,
  Memory,
  Calibration,
  Correction,
  ChannelParameter,
  ContinuousMeasurement,
  SingleMeasurement,
  Measurement
} from '../MoistureSensor/form';
import styles from '../../../../commons/styles';
import './form/style.css';
import { constant } from '../../../Settings/Constants';
import history from '../../../../commons/history';
import ConfigurationRouter from '../../../../routes/configurationRouter';
const { SubMenu } = Menu;
const { StyledWrapper, StyledWrapperLeft, StyledWrapperRight } = styles.layout;

class MoistureSensor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formName: '',
      collapsed: true
    };
  }
  componentDidMount() {
    let formName = this.props.match.params.formName;
    let deviceId = this.props.match.params.deviceId;
    this.setState({ formName, deviceId });
  }

  componentDidUpdate(prevprops) {
    if (
      this.props.match.params.formName !== prevprops.match.params.formName ||
      this.props.match.params.deviceId !== prevprops.match.params.deviceId
    ) {
      this.setState({
        formName: this.props.match.params.formName,
        deviceId: this.props.match.params.deviceId
      });
    }
  }

  renderForm = (key) => {
    switch (key) {
      case 'Settings':
        return <Settings />;
      case 'SystemParameter':
        return <SystemParameter />;
      case 'ZeroAdjust':
        return <ZeroAdjust />;
      case 'Memory':
        return <Memory />;
      case 'Calibration':
        return <Calibration />;
      case 'Correction':
        return <Correction />;
      case 'ChannelParameter':
        return <ChannelParameter />;
      case 'Measurement':
        return <Measurement />;
      case 'ContinuousMeasurement':
        return <ContinuousMeasurement />;
      case 'SingleMeasurement':
        return <SingleMeasurement />;
      default:
        break;
    }
  };

  getNavMenuItems = (menusData) => {
    if (!menusData) {
      return [];
    }
    if (Array.isArray(menusData)) {
      return menusData
        .filter((item) => item && item.name && !item.hideInMenu)
        .map((item) => {
          return this.getSubMenuOrItem(item);
        })
        .filter((item) => item);
    }
  };

  getSubMenuOrItem = (item) => {
    const { translationCache } = this.state;
    if (item && item.children) {
      if (!item.children) {
        return [];
      }
      if (item.children) {
        return (
          <SubMenu
            key="settings"
            title={
              <span>
                <UserOutlined />
                <span>
                  {' '}
                  {translationCache && translationCache[`${constant.User_Management}`]
                    ? translationCache[`${constant.User_Management}`]
                    : `${constant.User_Management}`}
                </span>
              </span>
            }
          >
            {item.children
              ? Object.keys(item.children)
                  .map((itemName) => item['children'][itemName])
                  .filter((item) => item && item.name && !item.hideInMenu)
                  .map((item) => {
                    return this.getSubMenuOrItem(item);
                  })
                  .filter((item) => item)
              : null}
          </SubMenu>
        );
      }
    }
    document.title = 'Configuration Menu';
    return (
      <Menu.Item
        key={item.key}
        className="customclass"
        onClick={() => {
          history.push(item.path);
        }}
      >
        {/* {item.icon ? <Icon type={item.icon} /> : null} */}
        <span className="nav-text">
          {translationCache && translationCache[item.name] && translationCache[item.name]
            ? translationCache[item.name]
            : item.name}
        </span>
      </Menu.Item>
    );
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  redirectPage = (path) => {
    return `/rubus/form/Moisture Sensor1/${path}`;
  };

  render() {
    let { collapsed } = this.state;
    function getItem(label, key, icon, children, type) {
      return {
        key,
        icon,
        children,
        label,
        type
      };
    }
    const items = [
      getItem(
        this.translation('Measurement'),
        this.redirectPage('Measurement'),
        <GlobalOutlined />
      ),
      getItem(
        this.translation('Calibration Curve'),
        this.redirectPage('CalibrationCurve'),
        <MonitorOutlined />,
        [
          getItem(this.translation('Calibration'), this.redirectPage('Calibration')),
          getItem(this.translation('Correction'), this.redirectPage('Correction')),
          getItem(this.translation('Channel Parameter'), this.redirectPage('ChannelParameter'))
        ]
      ),
      getItem(
        this.translation('Maintenance'),
        this.redirectPage('Maintenance'),
        <DatabaseOutlined />,
        [
          getItem(this.translation('System Parameter'), this.redirectPage('SystemParameter')),
          getItem(this.translation('Memory'), this.redirectPage('Memory')),
          getItem(this.translation('Zero Adjust'), this.redirectPage('ZeroAdjust'))
        ]
      ),
      getItem(this.translation('Settings'), this.redirectPage('Settings'), <SettingOutlined />)
    ];
    return (
      <StyledWrapper style={{ minHeight: window.innerHeight - 140 }}>
        <StyledWrapperLeft>
          <Menu
            mode="inline"
            defaultSelectedKeys={[collapsed]}
            style={{ height: '100%' }}
            items={items}
            onClick={(e) => {
              history.push(e.key);
            }}
          />
        </StyledWrapperLeft>
        <StyledWrapperRight style={{ background: 'white', margin: '1px 15px 13px 15px' }}>
          <ConfigurationRouter />
        </StyledWrapperRight>
      </StyledWrapper>
    );
  }
}

export default MoistureSensor;
