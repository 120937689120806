import React from 'react';
import PropTypes from 'prop-types';

import { Menu } from 'antd';
import Icon from '@ant-design/icons';
import MenuComponent from './MenuComponents';

const { SubMenu } = Menu;

const SubmenuComponents = (props, handeRoutingclick, AddPingItem) => {
  return props.routes !== undefined ? (
    <SubMenu
      key={props.uid}
      title={
        props.icon ? (
          <span>
            <Icon type={props.icon} />
            <span>{props.name}</span>
          </span>
        ) : (
          props.name
        )
      }
    >
      {props.routes !== null &&
      props.routes !== undefined &&
      props.routes !== Array.isArray(props.routes)
        ? MenuComponent(props.routes, handeRoutingclick, AddPingItem)
        : null}
    </SubMenu>
  ) : (
    MenuComponent([props], handeRoutingclick, AddPingItem)
  );
};

SubmenuComponents.prototype = {
  props: PropTypes.object.isRequired
};

export default SubmenuComponents;
