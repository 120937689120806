import React, { Fragment } from 'react';
import { Form, message, Upload, Tabs } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import { StyledForm } from '../CSS/style';
import { constant } from '../Utils/constants';
import themeSettings from '../Utils/themeSettings.json';
import translation from '../Utils/translation';
import localStorage from '../../../utils/localStorage';
import { getAssetFileDownloadName, getCarandDeviceFileDownloadName } from '../APICalls';
import { IPAddress } from '../../../commons/api';
const { TabPane } = Tabs;
class UploadAssets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {}
    };
    this.hRef = React.createRef();
  }
  componentDidMount() {
    this.setData(this.props.ShowForm);
  }
  componentDidUpdate(prevprops) {
    if (
      prevprops.ShowForm !== this.props.ShowForm ||
      prevprops.selectedRow !== this.props.selectedRow
    ) {
      this.setData(this.props.ShowForm);
    }
  }

  setData = async (ShowForm) => {
    if (ShowForm === 'UPLOAD_ASSETS') {
      let { selectedRow, parent } = this.props;
      this.getfileAssetDownloadName();
      if (selectedRow && selectedRow.Type === 'Asset' && parent.Type === 'Module')
        this.getfileAssetDownloadNameforCarandDevice();
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  getfileAssetDownloadName = async () => {
    let { selectedRow } = this.props;
    let parentAsset = selectedRow && selectedRow.Type === 'Module' ? null : selectedRow.Code;
    let fileAssetDownloadName = await getAssetFileDownloadName(selectedRow.Module, parentAsset);
    this.setState({
      fileAssetDownloadName:
        fileAssetDownloadName && fileAssetDownloadName.file ? fileAssetDownloadName.file : ''
    });
  };

  getfileAssetDownloadNameforCarandDevice = async () => {
    let { selectedRow } = this.props;
    let parentAsset = selectedRow && selectedRow.Type === 'Module' ? null : selectedRow.Code;
    let carAndAssetDownloadName = await getCarandDeviceFileDownloadName(
      selectedRow.Module,
      parentAsset
    );
    this.setState({
      carAndAssetDownloadName:
        carAndAssetDownloadName && carAndAssetDownloadName.file ? carAndAssetDownloadName.file : ''
    });
  };
  render() {
    let { fileAssetDownloadName, carAndAssetDownloadName } = this.state;
    let { selectedRow, translationCache, parent } = this.props;
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    let parentAsset = selectedRow && selectedRow.Type === 'Module' ? null : selectedRow.Code;
    return (
      <Fragment>
        <div
          style={{
            color: 'white',
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '24px'
          }}
        >
          {`Bulk upload for ${selectedRow.Name}`}
        </div>

        <StyledForm
          name="Item"
          ref={this.hRef}
          theme={themeSettings}
          layout="horizontal"
          labelCol={{ flex: '130px', marginTop: '20px' }}
          labelAlign="right"
          labelWrap
        >
          <Tabs
            tabPosition="top"
            centered
            style={{ marginTop: '20px', marginBottom: '30px' }}
            theme={themeSettings}
          >
            <TabPane tab="Bulk Upload" key="2">
              <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                <Form.Item
                  name="Download Assets"
                  style={{ width: '50%' }}
                  label={translation(translationCache, constant.DownloadAssets)}
                >
                  <a href={`${IPAddress.LOCAL}${fileAssetDownloadName}`}>
                    <DownloadOutlined
                      style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                    />
                    <span style={{ color: 'white' }}>Download</span>
                  </a>
                </Form.Item>

                <Form.Item
                  name="Upload Assets"
                  style={{ width: '50%' }}
                  label={translation(translationCache, constant.UploadAssets)}
                >
                  <Upload
                    multiple={false}
                    style={{ marginRight: '10px', color: 'white' }}
                    onChange={(info) => {
                      if (info.file.status === 'done') {
                        message.success(info.file.response.message);
                        this.getfileAssetDownloadName();
                        this.props.GetHierarchyLevelsUpdated();
                      } else if (info.file.status === 'error') {
                        message.error(info.file.response.message);
                      }
                    }}
                    action={`/api/client/upload/asset?SiteId=${siteId}&AssetType=${`asset`}&ModuleCode=${
                      this.props.Module
                    }&ParentAsset=${parentAsset}`}
                    headers={{
                      Authorization: Authorization
                    }}
                    showUploadList={false}
                  >
                    <UploadOutlined
                      style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                    />
                    <span style={{ color: 'white', marginRight: '10px' }}> Upload</span>
                  </Upload>
                </Form.Item>
              </div>
            </TabPane>
            {selectedRow && selectedRow.Type === 'Asset' && parent.Type === 'Module' ? (
              <TabPane tab="Bulk upload along devices" key="bulkUpload">
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                  <Form.Item
                    name="Download"
                    style={{ width: '50%' }}
                    label={translation(translationCache, constant.Download)}
                  >
                    <a href={`${IPAddress.LOCAL}${carAndAssetDownloadName}`}>
                      <DownloadOutlined
                        style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                      />
                      <span style={{ color: 'white' }}>Download</span>
                    </a>
                  </Form.Item>

                  <Form.Item
                    name="Upload"
                    style={{ width: '50%' }}
                    label={translation(translationCache, constant.Upload)}
                  >
                    <Upload
                      multiple={false}
                      style={{ marginRight: '10px', color: 'white' }}
                      onChange={(info) => {
                        if (info.file.status === 'done') {
                          message.success(info.file.response.message);
                          this.getfileAssetDownloadNameforCarandDevice();
                          this.props.GetHierarchyLevelsUpdated();
                        } else if (info.file.status === 'error') {
                          message.error(info.file.response.message);
                        }
                      }}
                      action={`/api/client/upload/assetBulk?SiteId=${siteId}&AssetType=${`asset`}&ModuleCode=${
                        this.props.Module
                      }&ParentAsset=${parentAsset}`}
                      headers={{
                        Authorization: Authorization
                      }}
                      showUploadList={false}
                    >
                      <UploadOutlined
                        style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                      />
                      <span style={{ color: 'white', marginRight: '10px' }}> Upload</span>
                    </Upload>
                  </Form.Item>
                </div>
              </TabPane>
            ) : null}
          </Tabs>
        </StyledForm>
      </Fragment>
    );
  }
}
export default UploadAssets;
