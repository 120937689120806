import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import {
  Form,
  Input,
  InputNumber,
  TimePicker,
  message,
  Button,
  Upload,
  Tabs,
  ConfigProvider,
  DatePicker
} from 'antd';
import ja_JP from 'antd/lib/locale/ja_JP';
import FileBase64 from 'react-file-base64';
import { LOGBOOK } from '../../../../commons/api';
import localStorage from '../../../../utils/localStorage';
import SelectComponent from '../Widgets/SelectComponent';
import SelectNestedComponennt from '../Widgets/SelectNestedComponennt';
import TableView from '../Widgets/TableView';
import TableViewExpandView from '../Widgets/TableViewExpandView';
import 'antd/lib/date-picker/style/css';
import { dailyShift, morningShift, nightShift, defaultRange } from '../../Constants/index.js';
import { UploadOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { TabPane } = Tabs;
const newLocal = true;
class FormWithMultipleTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: cloneDeep(props.formObject) || {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: '',
      shift: 'daily'
    };
  }

  componentDidMount() {
    let { formObject } = this.state;
    formObject = cloneDeep(this.props.formObject);
    if (this.props.action === 'new') {
      this.getDetails();
    }
    this.setState({
      columns:
        this.props.formObject && this.props.formObject.columns ? this.props.formObject.columns : [],
      data:
        this.props.formObject && this.props.formObject.datasource
          ? this.props.formObject.datasource
          : [],
      formObject: {
        ...formObject
      }
    });
    this.props.downTimeData(formObject, false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.action !== this.props.action && this.props.action === 'new') {
      this.getDetails();
    }
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        formObject: cloneDeep(this.props.formObject),
        columns:
          this.props.formObject && this.props.formObject.columns
            ? this.props.formObject.columns
            : [],
        data:
          this.props.formObject && this.props.formObject.datasource
            ? this.props.formObject.datasource
            : [],
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog
      });
    }
  }

  getDetails = () => {
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'POST',
      url: `${LOGBOOK.GetId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        TableName: 'eLogBook_JSONData',
        ColumnName: 'EWRId'
      }
    };
    axios(Object)
      .then((response) => {
        this.setData(response.data);
      })
      .catch(() => {
        return [];
      });
  };

  setData = (data) => {
    let { formObject } = this.props;
    formObject = {
      ...formObject,
      form: {
        ...formObject['form'],
        mainHeader: {
          ...(formObject && formObject['form'] && formObject['form']['mainHeader']
            ? formObject['form']['mainHeader']
            : {}),
          dataSource: {
            ...(formObject &&
            formObject['form'] &&
            formObject['form'].mainHeader &&
            formObject['form'].mainHeader['dataSource']
              ? formObject['form'].mainHeader['dataSource']
              : {}),
            Id: data && data.recordId ? data.recordId : '',
            RecordDate: moment().utc().format('YYYY-MM-DD HH:mm:ss')
          }
        }
      }
    };
    this.setState({ formObject });
    this.props.getMainSaveId(data && data.recordId ? data.recordId : '');
    this.props.downTimeData(formObject, false);
  };

  onInputChange = (columnValue, columnKey, name, columns) => {
    let { formObject } = this.state;
    let autoPopulateValues = {};
    if (
      columns &&
      columns.autoPopulate &&
      columns.autoPopulateKeys &&
      Array.isArray(columns.autoPopulateKeys) &&
      columns.autoPopulateKeys.length > 0
    ) {
      let autoPopulateKeys = columns.autoPopulateKeys;
      autoPopulateKeys.map((autoKey) => {
        if (columnValue && columnValue[autoKey]) {
          autoPopulateValues[autoKey] = columnValue[autoKey];
        }
        return {};
      });
    }
    formObject = {
      ...formObject,
      form: {
        ...formObject['form'],
        mainHeader: {
          ...formObject['form'].mainHeader,
          dataSource: {
            ...formObject['form']['mainHeader'].dataSource,
            [columnKey]: columnValue && columnValue.value ? columnValue.value : columnValue,
            ...(columns && columns.autoPopulate ? autoPopulateValues : {})
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  handleKeyPress = () => {};

  onOk = (date, columnKey, name) => {
    let { formObject } = this.state;
    let startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
    let endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
    if (date[0] !== null && date[1] !== null) {
      if (startDate2 === endDate2) {
        message.error('StartDateTime and EndDateTime should not be same');
        formObject = cloneDeep(this.props.formObject);
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            dataSource: {
              ...formObject[name].dataSource,
              [columnKey]: {}
            }
          }
        };
      } else if (startDate2 !== endDate2) {
        startDate2 = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
        endDate2 = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
        formObject = cloneDeep(this.props.formObject);
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            dataSource: {
              ...formObject[name].dataSource,
              [columnKey]: {
                startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
              }
            }
          }
        };
      }
    } else {
      message.error('Please Input Start & End Times');
    }

    this.props.downTimeData(formObject, false);
  };

  onChange = (date, columnKey, name) => {
    let { formObject } = this.state;
    // let startDate2 = moment(date[0]).format("YYYY-MM-DD HH:mm:ss");
    // let endDate2 = moment(date[1]).format("YYYY-MM-DD HH:mm:ss");
    formObject = cloneDeep(this.props.formObject);
    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...formObject[name].datasource,
          [columnKey]: {
            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  disabledDateRange = (formObject, current) => {
    if (
      formObject &&
      formObject['form'] &&
      formObject['form'].datasource &&
      formObject['form'].datasource['Date']
    ) {
      let selectedDate = moment(formObject['form'].datasource['Date'], 'YYYY-MM-DD HH:mm:ss');
      return (
        current > moment(selectedDate).add(1, 'day').endOf('day') ||
        current < moment(selectedDate).startOf('day')
      );
    }
    return current || current > moment().endOf('day') || current < moment().startOf('day');
  };

  onHeaderChange = (date, columnKey, name) => {
    let { formObject } = this.state;
    if (columnKey === 'Date') {
      let columns = [];
      if (formObject[name].columns) {
        columns = formObject[name].columns.map((formCol) => {
          return formCol.key;
        });
      }
      if (
        columns &&
        Array.isArray(columns) &&
        columns.length > 0 &&
        columns.includes('TimeSelection')
      ) {
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            mainHeader: {
              ...formObject[name]['mainHeader'],
              dataSource: {
                ...(formObject &&
                formObject[name] &&
                formObject[name]['mainHeader'] &&
                formObject[name]['mainHeader'].dataSource
                  ? formObject[name]['mainHeader'].dataSource
                  : {}),
                [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss'),
                TimeSelection: {}
              }
            }
          }
        };
        this.props.downTimeData(formObject, false);
      } else {
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            mainHeader: {
              ...formObject[name]['mainHeader'],
              dataSource: {
                ...(formObject &&
                formObject[name]['mainHeader'] &&
                formObject[name]['mainHeader'].dataSource
                  ? formObject[name]['mainHeader'].dataSource
                  : {}),
                [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
              }
            }
          }
        };
        this.props.downTimeData(formObject, false);
      }
    } else {
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          mainHeader: {
            ...formObject[name]['mainHeader'],
            dataSource: {
              ...(formObject &&
              formObject[name]['mainHeader'] &&
              formObject[name]['mainHeader'].dataSource
                ? formObject[name]['mainHeader'].dataSource
                : {}),
              [columnKey]: moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    }
  };

  renderSwitch(formObject, columns, name) {
    const { shift } = this.state;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name] &&
              formObject[name]['mainHeader'] &&
              formObject[name]['mainHeader'].dataSource &&
              formObject[name]['mainHeader'].dataSource[columns.key]
                ? formObject[name]['mainHeader'].dataSource[columns.key]
                : ''
            }
            maxLength={columns && columns.maxLength ? columns.maxLength : 100}
            disabled={columns.disable ? columns.disable : false}
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name, columns)}
          />
        );
      case 'select':
        return (
          <SelectComponent
            column={columns}
            record={{ key: columns }}
            text={
              formObject[name]['mainHeader'] &&
              formObject[name]['mainHeader'].dataSource &&
              formObject[name]['mainHeader'].dataSource[columns.key]
                ? formObject[name]['mainHeader'].dataSource[columns.key]
                : undefined
            }
            datasource={
              formObject[name]['mainHeader'] && formObject[name]['mainHeader'].dataSource
                ? formObject[name]['mainHeader'].dataSource
                : []
            }
            handleFieldChange={(e) => this.onInputChange(e, columns.key, name, columns)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300 }}
            value={
              formObject[name]['mainHeader'] &&
              formObject[name]['mainHeader'].dataSource &&
              (formObject[name]['mainHeader'].dataSource[columns.key]['mainHeader'] ||
                formObject[name].dataSource[columns.key] === 0)
                ? formObject[name]['mainHeader'].dataSource[columns.key]
                : ''
            }
            disabled={columns.disable ? columns.disable : false}
            placeholder={columns.placeHolder}
            min={columns.min ? columns.min : 0}
            max={columns.max ? columns.max : 1000000}
            // onChange={(e) => this.onInputChange(e, columns.key, name, columns)}
            onChange={(e) =>
              this.inputHeaderNumberChange(columns.min, columns.max, e, columns.key, name, columns)
            }
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            value={
              formObject['form'] &&
              formObject['form']['mainHeader'] &&
              formObject['form']['mainHeader'].dataSource &&
              formObject['form']['mainHeader'].dataSource[columns.key]
                ? moment
                    .utc(
                      formObject['form']['mainHeader'].dataSource[columns.key],
                      'YYYY-MM-DD HH:mm:ss'
                    )
                    .tz(this.props.currentTimeZone)
                : undefined
            }
            format={'YYYY-MM-DD HH:mm'}
            allowClear={false}
            showNow={false}
            showTime={columns.showTime ? columns.showTime : false}
            disabledTime={(e) => this.disabledRangeTime(e)}
            disabled={columns.disable ? columns.disable : false}
            disabledDate={(current) => this.disabledDate(current, columns)}
            onChange={(e) => this.onHeaderChange(e, columns.key, name, columns)}
            style={{ width: 300 }}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300 }}
            allowClear={false}
            showTime={{
              hideDisabledOptions: false,
              defaultValue: this.dateRanges(shift)
            }}
            value={
              formObject[name]['mainHeader'] &&
              formObject[name]['mainHeader'].dataSource &&
              formObject[name]['mainHeader'].dataSource[columns.key] &&
              formObject[name]['mainHeader'].dataSource[columns.key]['startDate'] &&
              formObject[name]['mainHeader'].dataSource[columns.key]['endDate']
                ? [
                    moment(
                      formObject[name]['mainHeader'].dataSource[columns.key]['startDate'],
                      dateFormat
                    ),
                    moment(
                      formObject[name]['mainHeader'].dataSource[columns.key]['endDate'],
                      dateFormat
                    )
                  ]
                : null
            }
            disabledTime={(e) => this.disabledRangeTime(e)}
            format="YYYY-MM-DD HH:mm"
            disabledDate={(e) =>
              this.disabledDateRange(
                {
                  ...(formObject && formObject ? formObject : {})
                },
                e
              )
            }
            onOk={(e) => this.onOk(e, columns.key, name, columns)}
          />
        );
      case 'timePicker':
        return (
          <TimePicker
            use12Hours
            format="h:mm:ss A"
            value={
              formObject[name]['mainHeader'] &&
              formObject[name]['mainHeader'].dataSource &&
              (formObject[name]['mainHeader'].dataSource[columns.key] ||
                formObject[name]['mainHeader'].dataSource[columns.key] === 0)
                ? moment(formObject[name]['mainHeader'].dataSource[columns.key], 'h:mm:ss A')
                : ''
            }
            style={{ width: 300 }}
            onChange={(e, string) => this.onInputChange(string, columns.key, name, columns)}
          />
        );
      case 'date-time picker':
        return (
          <DatePicker
            style={{ width: 300 }}
            allowClear={false}
            showTime={columns.showTime ? columns.showTime : false}
            onChange={(e) => this.onInputChange(e, columns.key, columns)}
            value={
              formObject['form'] &&
              formObject['form']['mainHeader'] &&
              formObject['form']['mainHeader'].dataSource &&
              formObject['form']['mainHeader'].dataSource[columns.key]
                ? moment
                    .utc(
                      formObject['form']['mainHeader'].dataSource[columns.key],
                      'YYYY-MM-DD HH:mm:ss'
                    )
                    .tz(this.props.currentTimeZone)
                : undefined
            }
            disabledDate={(e) => this.disabledDateTime(e, columns, formObject)}
            disabledTime={(e) =>
              this.getDisabledHours(
                moment.utc(e, 'YYYY-MM-DD HH:mm:ss'),
                columns.key,
                formObject['form']['mainHeader'].dataSource
              )
            }
            format={columns.showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
            onOk={(e) => this.onHeaderChange(e, columns.key, name, columns)}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject[name]['mainHeader'] &&
              formObject[name]['mainHeader'].dataSource &&
              formObject[name]['mainHeader'].dataSource[columns.key]
                ? formObject[name]['mainHeader'].dataSource[columns.key]
                : ''
            }
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name, columns)}
          />
        );
    }
  }

  disabledDateTime = (value, column, formObject) => {
    if (column && column.key === 'ActualStart') {
      let endDate =
        formObject['form'] &&
        formObject['form']['mainHeader'] &&
        formObject['form']['mainHeader'].dataSource &&
        formObject['form']['mainHeader'].dataSource['ActualFinish']
          ? formObject['form']['mainHeader'].dataSource['ActualFinish']
          : '';
      if (!value || !endDate) {
        return false;
      }
      return value.valueOf() > moment(endDate).valueOf() || value > moment().endOf('day');
    } else if (column && column.key === 'ActualFinish') {
      let startDate =
        formObject['form'] &&
        formObject['form']['mainHeader'] &&
        formObject['form']['mainHeader'].dataSource &&
        formObject['form']['mainHeader'].dataSource['ActualStart']
          ? formObject['form']['mainHeader'].dataSource['ActualStart']
          : '';
      if (!value || !startDate) {
        return false;
      }
      return value.valueOf() <= moment(startDate).valueOf() || value > moment().endOf('day');
    }
  };

  getDisabledHours = (e, key, datasource) => {
    let editObject = datasource;
    let hours = [];
    let minutes = [];
    let ActualStartDateTime = '';
    let ActualFinishDateTime = '';

    let ActualStartDate = '';
    let ActualFinishDate = '';

    if (key === 'ActualStart') {
      ActualStartDateTime = moment(e).format('YYYY-MM-DD HH:mm');
      ActualFinishDateTime = moment
        .utc(editObject && editObject.ActualFinish, 'YYYY-MM-DD HH:mm')
        .tz(this.props.currentTimeZone);

      ActualStartDate = moment(e).format('YYYY-MM-DD');
      ActualFinishDate = moment
        .utc(editObject && editObject.ActualFinish)
        .tz(this.props.currentTimeZone)
        .format('YYYY-MM-DD');
    } else if (key === 'ActualFinish') {
      ActualStartDateTime = moment
        .utc(editObject && editObject.ActualStart, 'YYYY-MM-DD HH:mm')
        .tz(this.props.currentTimeZone);
      ActualFinishDateTime = moment(e).format('YYYY-MM-DD HH:mm');

      ActualStartDate = moment
        .utc(editObject && editObject.ActualStart)
        .tz(this.props.currentTimeZone)
        .format('YYYY-MM-DD');
      ActualFinishDate = moment(e).format('YYYY-MM-DD');
    }
    if (
      key === 'ActualStart' &&
      moment(ActualStartDate).isSame(
        moment(ActualFinishDate) ||
          moment(ActualStartDateTime).hours() === moment(ActualFinishDateTime).hours()
      )
    ) {
      for (var i = 0; i <= 24; i++) {
        if (i > moment(ActualFinishDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(ActualStartDate).isSame(moment(ActualFinishDate))) {
        for (let i = 0; i <= 60; i++) {
          if (i > moment(ActualFinishDateTime).minutes() - 1) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    } else if (
      key === 'ActualFinish' &&
      moment(ActualStartDate).isSame(
        moment(ActualFinishDate) ||
          moment(ActualStartDateTime).hours() === moment(ActualFinishDateTime).hours()
      )
    ) {
      for (let i = 0; i < moment(ActualStartDateTime).hours(); i++) {
        if (i < 25) {
          hours.push(i);
        }
      }
      if (moment(ActualStartDate).isSame(moment(ActualFinishDate))) {
        for (let i = 0; i < moment(ActualStartDateTime).minutes(); i++) {
          if (i < 61) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    }
  };

  disabledRangeTime = (current) => {
    if (current !== null) {
      let { shift, formObject } = this.state;
      if (
        formObject &&
        formObject['form'] &&
        formObject['form'].datasource &&
        formObject['form'].datasource['Date']
      ) {
        let selectedDate = moment(formObject['form'].datasource['Date']).format('YYYY-MM-DD');
        let currentDate = moment(current).format('YYYY-MM-DD');
        let range = (start, end) => {
          const result = [];
          for (let i = start; i < end; i++) {
            result.push(i);
          }
          return result;
        };
        if (shift === 'Morning Shift') {
          return {
            // disabledHours: () => range(18, 18),
            disabledHours: () => [0, 1, 2, 3, 4, 5, 18, 19, 20, 21, 22, 23],
            disabledMinutes: () => range(60, 60)
            // disabledSeconds: () => [55, 56],
          };
        } else if (shift === 'Night Shift') {
          return {
            disabledHours: () => range(0, 24).splice(6, 12),
            disabledMinutes: () => range(60, 60)
          };
        } else if (selectedDate === currentDate) {
          return {
            disabledHours: () => [0, 1, 2, 3, 4, 5],
            disabledMinutes: () => range(60, 60)
          };
        } else if (selectedDate !== currentDate) {
          return {
            disabledHours: () => [
              6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
            ]
          };
        }
      }
    }
  };

  disabledDate = (current, columns) => {
    if (columns.showTime) {
      const { formObject } = this.props;
      if (formObject && formObject['mainHeaderValue'] && formObject['mainHeaderValue']['Date']) {
        if (formObject.mainHeaderValue.Date !== undefined) {
          let currentDate = moment(current).format('YYYY-MM-DD');
          let Date = moment(formObject.mainHeaderValue.Date).format('YYYY-MM-DD');
          let addedDate = moment(formObject.mainHeaderValue.Date)
            .add(1, 'day')
            .format('YYYY-MM-DD');
          if (moment(currentDate).isSame(Date)) {
            return false;
          } else if (moment(currentDate).isSame(addedDate)) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      } else if (
        formObject &&
        formObject['form'] &&
        formObject['form']['datasource'] &&
        formObject['form']['datasource']['Date']
      ) {
        if (formObject.form.datasource.Date !== undefined) {
          let currentDate = moment(current).format('YYYY-MM-DD');
          let Date = moment(formObject.form.datasource.Date).format('YYYY-MM-DD');
          let addedDate = moment(formObject.form.datasource.Date)
            .add(1, 'day')
            .format('YYYY-MM-DD');
          if (moment(currentDate).isSame(Date)) {
            return false;
          } else if (moment(currentDate).isSame(addedDate)) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      }
    }
    if (!columns.showTime) {
      return current > moment().endOf('day');
    }
    return current || current > moment().endOf('day') || current < moment().startOf('day');
  };

  fetchData = (formObject) => {
    let payload = {
      date:
        formObject &&
        formObject['form'] &&
        formObject['form']['datasource'] &&
        formObject['form']['datasource']['Date']
          ? moment(
              formObject &&
                formObject['form'] &&
                formObject['form']['datasource'] &&
                formObject['form']['datasource']['Date']
            ).format('YYYY-MM-DD')
          : {},
      EquipmentName:
        formObject &&
        formObject['form'] &&
        formObject['form']['datasource'] &&
        formObject['form']['datasource']['Date']
          ? formObject &&
            formObject['form'] &&
            formObject['form']['datasource'] &&
            formObject['form']['datasource']['EquipmentName']
          : ''
    };
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    let headers = {
      method: 'POST',
      url: `${LOGBOOK.Copy}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization
      },
      data: payload
    };
    axios(headers)
      .then((response) => {
        if (response && response.data && Array.isArray(response.data) && response.data[0]) {
          let fetchData = response.data[0];
          formObject = {
            ...formObject,
            form: {
              datasource: {
                ...formObject['form'].datasource,
                ...fetchData
              }
            }
          };
          this.props.downTimeData(formObject, false);
        } else if (response && response.data && response.data.Error) {
          if (
            formObject &&
            formObject['form'] &&
            formObject['form'] &&
            formObject['form'].datasource
          ) {
            let newObj = {
              Date:
                formObject &&
                formObject['form'] &&
                formObject['form'] &&
                formObject['form'].datasource &&
                formObject['form'].datasource['Date'],
              EquipmentName:
                formObject &&
                formObject['form'] &&
                formObject['form'] &&
                formObject['form'].datasource &&
                formObject['form'].datasource['EquipmentName']
            };
            formObject = {
              ...formObject,
              form: {
                ...formObject['form'],
                datasource: {
                  ...newObj
                }
              }
            };
            this.props.downTimeData(formObject, false);
          }
          message.error(response.data.Error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  dateRanges = (shift) => {
    switch (shift) {
      case 'daily': {
        return dailyShift;
      }
      case 'Morning Shift': {
        return morningShift;
      }
      case 'Night Shift': {
        return nightShift;
      }
      default:
        return defaultRange;
    }
  };

  inputHeaderNumberChange = (minValue, maxValue, value, key, name, columns) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      maxValue !== undefined
    ) {
      this.onInputChange(value, key, name, columns);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputChange(value, key, name, columns);
    } else {
      this.onInputChange('', key, name, columns);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };

  onInputTabFormChange = (columnValue, columnKey, tabName, columns, children) => {
    let { formObject } = this.state;
    let { selectionType } = columns;
    let columnsData = [];
    // if(columns.parameterMaxKey === columnKey ){
    columnsData =
      formObject &&
      formObject['form'] &&
      formObject['form']['Tabs'] &&
      formObject['form']['Tabs'][tabName] &&
      formObject['form']['Tabs'][tabName].form &&
      formObject['form']['Tabs'][tabName].form.columns
        ? formObject['form']['Tabs'][tabName].form.columns.map((column) => {
            if (column['key'] === columns.parameterMaxKey) {
              return {
                ...column,
                max: children.parameterMaxValue ? children.parameterMaxValue : 0
              };
            } else {
              return column;
            }
          })
        : {};
    formObject = {
      //parameterMaxKey
      ...formObject,
      form: {
        ...formObject['form'],
        Tabs: {
          ...(formObject && formObject['form'] && formObject['form']['Tabs']
            ? formObject['form']['Tabs']
            : {}),
          [tabName]: {
            ...(formObject &&
            formObject['form'] &&
            formObject['form']['Tabs'] &&
            formObject['form']['Tabs'][tabName]
              ? formObject['form']['Tabs'][tabName]
              : {}),
            ...(selectionType && selectionType === 'children'
              ? {
                  table: {
                    ...(formObject &&
                    formObject['form'] &&
                    formObject['form']['Tabs'] &&
                    formObject['form']['Tabs'][tabName] &&
                    formObject['form']['Tabs'][tabName].table
                      ? formObject['form']['Tabs'][tabName].table
                      : {}),
                    datasource: children
                  }
                }
              : {}),
            form: {
              ...(formObject &&
              formObject['form'] &&
              formObject['form']['Tabs'] &&
              formObject['form']['Tabs'][tabName] &&
              formObject['form']['Tabs'][tabName].form
                ? formObject['form']['Tabs'][tabName].form
                : {}),
              columns:
                columnsData && Array.isArray(columnsData) && columnsData.length > 0
                  ? columnsData
                  : formObject &&
                    formObject['form'] &&
                    formObject['form']['Tabs'] &&
                    formObject['form']['Tabs'][tabName] &&
                    formObject['form']['Tabs'][tabName].form &&
                    formObject['form']['Tabs'][tabName].form.columns &&
                    Array.isArray(formObject['form']['Tabs'][tabName].form.columns)
                  ? formObject['form']['Tabs'][tabName].form.columns
                  : [],

              dataSource: {
                ...(formObject &&
                formObject['form'] &&
                formObject['form']['Tabs'] &&
                formObject['form']['Tabs'][tabName] &&
                formObject['form']['Tabs'][tabName].form &&
                formObject['form']['Tabs'][tabName].form['dataSource']
                  ? formObject['form']['Tabs'][tabName].form['dataSource']
                  : {}),
                [columnKey]: columnValue && columnValue.value ? columnValue.value : columnValue
              }
            }
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  NestedSelectOnChange = (columnValue, columnKey, tabName, columns) => {
    let { formObject } = this.state;
    let { selectionType } = columns;
    formObject = {
      ...formObject,
      form: {
        ...formObject['form'],
        Tabs: {
          ...(formObject && formObject['form'] && formObject['form']['Tabs']
            ? formObject['form']['Tabs']
            : {}),
          [tabName]: {
            ...(formObject &&
            formObject['form'] &&
            formObject['form']['Tabs'] &&
            formObject['form']['Tabs'][tabName]
              ? formObject['form']['Tabs'][tabName]
              : {}),
            ...(selectionType && selectionType === 'children'
              ? {
                  table: {
                    ...(formObject &&
                    formObject['form'] &&
                    formObject['form']['Tabs'] &&
                    formObject['form']['Tabs'][tabName] &&
                    formObject['form']['Tabs'][tabName].table
                      ? formObject['form']['Tabs'][tabName].table
                      : {}),
                    datasource: [columnValue[1], columnValue[2], columnValue[3]]
                  }
                }
              : {}),
            form: {
              ...(formObject &&
              formObject['form'] &&
              formObject['form']['Tabs'] &&
              formObject['form']['Tabs'][tabName] &&
              formObject['form']['Tabs'][tabName].form
                ? formObject['form']['Tabs'][tabName].form
                : {}),
              dataSource: {
                ...(formObject &&
                formObject['form'] &&
                formObject['form']['Tabs'] &&
                formObject['form']['Tabs'][tabName] &&
                formObject['form']['Tabs'][tabName].form &&
                formObject['form']['Tabs'][tabName].form['dataSource']
                  ? formObject['form']['Tabs'][tabName].form['dataSource']
                  : {}),
                [columnKey]:
                  columnValue && columnValue[0] && columnValue[0].value
                    ? columnValue[0].value
                    : columnValue
              }
            }
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  inputTabsFormNumberChange = (minValue, maxValue, value, key, tabName, columns) => {
    if (
      value >= minValue &&
      value <= maxValue &&
      minValue !== undefined &&
      maxValue !== undefined
    ) {
      this.onInputTabFormChange(value, key, tabName, columns);
    } else if (minValue === undefined && maxValue === undefined) {
      this.onInputTabFormChange(value, key, tabName, columns);
    } else {
      this.onInputTabFormChange('', key, tabName, columns);
      message.error(`The input range must between ${minValue} and ${maxValue}`);
    }
  };

  onDateHeaderMainChange = (date, columnKey) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      form: {
        ...formObject['form'],
        mainHeader: {
          ...(formObject && formObject['form'] && formObject['form'].mainHeader
            ? formObject['form'].mainHeader
            : {}),
          dataSource: {
            ...(formObject &&
            formObject['form'] &&
            formObject['form'].mainHeader &&
            formObject['form'].mainHeader['dataSource']
              ? formObject['form'].mainHeader['dataSource']
              : {}),
            [columnKey]: moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onInputTabFormAtatchment = (columnValue, columnKey, tabName) => {
    let { formObject } = this.state;
    columnValue =
      columnValue &&
      Array.isArray(columnValue) &&
      columnValue.filter((column) => {
        return column.type !== 'image/svg+xml';
      });
    formObject = {
      ...formObject,
      form: {
        ...formObject['form'],
        Tabs: {
          ...(formObject && formObject['form'] && formObject['form']['Tabs']
            ? formObject['form']['Tabs']
            : {}),
          [tabName]: {
            ...(formObject &&
            formObject['form'] &&
            formObject['form']['Tabs'] &&
            formObject['form']['Tabs'][tabName]
              ? formObject['form']['Tabs'][tabName]
              : {}),

            table: {
              ...(formObject &&
              formObject['form'] &&
              formObject['form']['Tabs'] &&
              formObject['form']['Tabs'][tabName] &&
              formObject['form']['Tabs'][tabName].table
                ? formObject['form']['Tabs'][tabName].table
                : {}),
              datasource: [
                ...(formObject &&
                formObject['form'] &&
                formObject['form']['Tabs'] &&
                formObject['form']['Tabs'][tabName] &&
                formObject['form']['Tabs'][tabName].table &&
                formObject['form']['Tabs'][tabName].table.datasource
                  ? formObject['form']['Tabs'][tabName].table.datasource
                  : {}),
                ...columnValue
              ]
            }
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  renderTabsFormSwitch(formObject, columns, tabName) {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject['form'] &&
              formObject['form']['Tabs'] &&
              formObject['form']['Tabs'][tabName] &&
              formObject['form']['Tabs'][tabName].form &&
              formObject['form']['Tabs'][tabName].form.dataSource &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key] &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key] !== 'null'
                ? formObject['form']['Tabs'][tabName].form.dataSource[columns.key]
                : ''
            }
            maxLength={columns && columns.maxLength ? columns.maxLength : 100}
            disabled={columns.disable ? columns.disable : false}
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onInputTabFormChange(e.target.value, columns.key, tabName, columns)
            }
          />
        );
      case 'inputNumber':
        return (
          <InputNumber
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject['form'] &&
              formObject['form']['Tabs'] &&
              formObject['form']['Tabs'][tabName] &&
              formObject['form']['Tabs'][tabName].form &&
              formObject['form']['Tabs'][tabName].form.dataSource &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key] &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key] !== 'null'
                ? formObject['form']['Tabs'][tabName].form.dataSource[columns.key]
                : ''
            }
            min={columns.min ? columns.min : 0}
            max={columns.max ? columns.max : 1000000000}
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.inputTabsFormNumberChange(
                columns.min,
                columns.max,
                e,
                columns.key,
                tabName,
                columns
              )
            }
            // onChange={(e) => this.onInputHeaderMainChange(e, columns.key)}
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            value={
              formObject['form'] &&
              formObject['form']['Tabs'] &&
              formObject['form']['Tabs'][tabName] &&
              formObject['form']['Tabs'][tabName].form &&
              formObject['form']['Tabs'][tabName].form.dataSource &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key]
                ? moment
                    .utc(
                      formObject['form']['Tabs'][tabName].form.dataSource[columns.key],
                      'YYYY-MM-DD HH:mm:ss'
                    )
                    .tz(this.props.currentTimeZone)
                : undefined
            }
            showNow={false}
            format={'YYYY-MM-DD HH:mm:ss'}
            disabledDate={this.disabledDate}
            onChange={(e) => this.onDateHeaderMainChange(e, columns.key, tabName)}
            style={{ width: 300, fontWeight: 'bold' }}
          />
        );
      case 'select':
        return (
          <SelectComponent
            column={columns}
            record={{ key: columns }}
            text={
              formObject['form'] &&
              formObject['form']['Tabs'] &&
              formObject['form']['Tabs'][tabName] &&
              formObject['form']['Tabs'][tabName].form &&
              formObject['form']['Tabs'][tabName].form.dataSource &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key]
                ? formObject['form']['Tabs'][tabName].form.dataSource[columns.key]
                : ''
            }
            handleFieldChange={(value, key, tabName1, children) => {
              this.onInputTabFormChange(value, key, tabName, columns, children);
            }}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'selectNested':
        return (
          <SelectNestedComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject['form'] &&
              formObject['form']['Tabs'] &&
              formObject['form']['Tabs'][tabName] &&
              formObject['form']['Tabs'][tabName].form &&
              formObject['form']['Tabs'][tabName].form.dataSource &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key]
                ? formObject['form']['Tabs'][tabName].form.dataSource[columns.key]
                : ''
            }
            NestedSelectOnChange={(e) =>
              this.NestedSelectOnChange(e, columns.key, tabName, columns, e.child)
            }
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300, fontWeight: 'bold' }}
            showTime
            allowClear={false}
            format="YYYY-MM-DD HH:mm"
            disabledTime={(e) => this.disabledRangeTime(e)}
            disabledDate={(e) =>
              this.disabledDateRange(
                {
                  ...(formObject ? formObject : {})
                },
                e
              )
            }
            value={
              formObject['form'] &&
              formObject['form']['Tabs'] &&
              formObject['form']['Tabs'][tabName] &&
              formObject['form']['Tabs'][tabName].form &&
              formObject['form']['Tabs'][tabName].form.dataSource &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key] &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key]['StartDate'] &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key]['EndDate']
                ? [
                    moment(
                      formObject['form']['Tabs'][tabName].form.dataSource[columns.key]['StartDate'],
                      dateFormat
                    ),
                    moment(
                      formObject['form']['Tabs'][tabName].form.dataSource[columns.key]['EndDate'],
                      dateFormat
                    )
                  ]
                : null
            }
            onOk={(e) => this.onMainOk(e, columns.key)}
            //  onChange={(e) => this.onChange(e, columns.key)}
          />
        );
      case 'upload':
        return (
          <Upload
          // {...renderProps}
          // onChange={(info) => this.onFileChange(info, column, text, record)}
          >
            <Button>
              <UploadOutlined /> Upload
            </Button>
          </Upload>
        );
      case 'textArea':
        return (
          <TextArea
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject['form'] &&
              formObject['form']['Tabs'] &&
              formObject['form']['Tabs'][tabName] &&
              formObject['form']['Tabs'][tabName].form &&
              formObject['form']['Tabs'][tabName].form.dataSource &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key] &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key] !== 'null'
                ? formObject['form']['Tabs'][tabName].form.dataSource[columns.key]
                : ''
            }
            rows={4}
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onInputTabFormChange(e.target.value, columns.key, tabName, columns)
            }
          />
        );
      case 'imageUploadBase64':
        return (
          <span>
            <FileBase64
              type="file"
              multiple={true}
              onDone={(file) => {
                this.onInputTabFormAtatchment(file, columns.key, tabName, columns);
              }}
            />
          </span>
        );

      default:
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject['form'] &&
              formObject['form']['Tabs'] &&
              formObject['form']['Tabs'][tabName] &&
              formObject['form']['Tabs'][tabName].form &&
              formObject['form']['Tabs'][tabName].form.dataSource &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key] &&
              formObject['form']['Tabs'][tabName].form.dataSource[columns.key] !== 'null'
                ? formObject['form']['Tabs'][tabName].form.dataSource[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) =>
              this.onInputTabFormChange(e.target.value, columns.key, tabName, columns)
            }
          />
        );
    }
  }

  saveTableViewData = (name, datasource) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      Form: {
        ...(formObject && formObject.form ? formObject.form : {}),
        Tabs: {
          ...(formObject && formObject.form && formObject.form['Tabs']
            ? formObject.form['Tabs']
            : {}),
          [name]: {
            ...(formObject &&
            formObject.form &&
            formObject.form['Tabs'] &&
            formObject.form['Tabs'][name]
              ? formObject.form['Tabs'][name]
              : {}),
            table: {
              ...(formObject &&
              formObject.form &&
              formObject.form['Tabs'] &&
              formObject.form['Tabs'][name] &&
              formObject.form['Tabs'][name].table
                ? formObject.form['Tabs'][name].table
                : {}),
              datasource
            }
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  saveTableViewDataCustom = (name, value, key) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      form: {
        ...(formObject && formObject.form ? formObject.form : {}),
        Tabs: {
          ...(formObject && formObject.form && formObject.form['Tabs']
            ? formObject.form['Tabs']
            : {}),
          [name]: {
            ...(formObject &&
            formObject.form &&
            formObject.form['Tabs'] &&
            formObject.form['Tabs'][name]
              ? formObject.form['Tabs'][name]
              : {}),
            table: {
              ...(formObject &&
              formObject.form &&
              formObject.form['Tabs'] &&
              formObject.form['Tabs'][name] &&
              formObject.form['Tabs'][name].table
                ? formObject.form['Tabs'][name].table
                : {}),
              [key]: value
            }
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  render() {
    let { formObject, translationCache } = this.props;
    return (
      <ConfigProvider locale={ja_JP}>
        <div className="gasLogForm">
          <Form className="batchUpdateForm1">
            <div className="mainHeaderFormBody">
              {formObject &&
                formObject['form'] &&
                formObject['form']['mainHeader'] &&
                formObject['form']['mainHeader'].layout &&
                formObject['form']['mainHeader'].layout.map((layoutId, layoutIndex) => {
                  let columnsList = formObject['form']['mainHeader'].layout[layoutIndex];
                  return (
                    <div key={layoutIndex} className="mainHeaderFormBody">
                      {columnsList && Array.isArray(columnsList)
                        ? columnsList.map((columnsId, subIndex) => {
                            let columns = formObject['form']['mainHeader'].columns[columnsId];
                            return (
                              <div key={subIndex} className="subSectionFormBody">
                                <Form.Item
                                  required={false}
                                  label={
                                    <span style={{ marginLeft: columns.marginLeft }}>
                                      {translationCache &&
                                      translationCache[`${columns.displayName}`] &&
                                      translationCache[`${columns.displayName}`]
                                        ? translationCache[`${columns.displayName}`]
                                        : `${columns.displayName}`}
                                    </span>
                                  }
                                >
                                  <div>{this.renderSwitch(formObject, columns, 'form')}</div>
                                </Form.Item>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  );
                })}
            </div>
          </Form>
        </div>
        <div className="cardContainer" style={{ marginTop: '30px', marginBottom: '30px' }}>
          <Tabs defaultActiveKey="0" type="card">
            {formObject &&
              formObject['form'] &&
              formObject['form'].Tabs &&
              Object.keys(formObject['form'].Tabs).map((tabItem, index) => {
                return (
                  <TabPane
                    tab={
                      translationCache && translationCache[tabItem] && translationCache[tabItem]
                        ? translationCache[tabItem]
                        : tabItem
                    }
                    key={index}
                  >
                    <div className="mainHeaderFormBody">
                      {formObject['form'] &&
                      formObject['form'].Tabs &&
                      formObject['form'].Tabs[tabItem] &&
                      formObject['form'].Tabs[tabItem].form &&
                      formObject['form'].Tabs[tabItem].form['columns']
                        ? formObject['form'].Tabs[tabItem].form['columns'].map((columns, index) => {
                            return (
                              <div
                                key={index}
                                className="subSectionFormBody"
                                style={{
                                  ...(this.props.status === 'Completed'
                                    ? {
                                        opacity: newLocal ? 0.75 : 1,
                                        pointerEvents: newLocal ? 'none' : 'initial'
                                      }
                                    : {})
                                }}
                              >
                                <Form.Item
                                  required={false}
                                  label={
                                    <span style={{ marginLeft: columns.marginLeft }}>
                                      {translationCache &&
                                      translationCache[columns.displayName] &&
                                      translationCache[columns.displayName]
                                        ? translationCache[columns.displayName]
                                        : columns.displayName}
                                    </span>
                                  }
                                >
                                  <div>
                                    {this.renderTabsFormSwitch(formObject, columns, tabItem)}
                                  </div>
                                </Form.Item>
                              </div>
                            );
                          })
                        : null}
                    </div>

                    {formObject['form'] &&
                    formObject['form'].Tabs &&
                    formObject['form'].Tabs[tabItem] &&
                    formObject['form'].Tabs[tabItem].table &&
                    formObject['form'].Tabs[tabItem].table['columns'] ? (
                      <div>
                        {formObject['form'].Tabs[tabItem].table.tableType ===
                        'TableViewExpandView' ? (
                          <TableViewExpandView
                            form={formObject['form'].Tabs[tabItem].table}
                            recordType={'FormSubSectionTable'}
                            saveTableViewDataCustom={(e, e1) =>
                              this.saveTableViewDataCustom(tabItem, e, e1)
                            }
                            translationCache={this.props.translationCache}
                          />
                        ) : (
                          <TableView
                            form={formObject['form'].Tabs[tabItem].table}
                            recordType={'FormSubSectionTable'}
                            saveTableViewData={(e) => this.saveTableViewData(tabItem, e)}
                            translationCache={this.props.translationCache}
                            status={this.props.status}
                          />
                        )}
                      </div>
                    ) : null}
                  </TabPane>
                );
              })}
          </Tabs>
        </div>
      </ConfigProvider>
    );
  }
}

export default FormWithMultipleTabs;
