/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import { Form, Input, message, Select } from 'antd';
import {
  getDeviceList,
  getOrganisationsList,
  getSitesList,
  submitJobPlan,
  getJobPlanById,
  getStatus
} from '../../../Utils/FormCalls';
import translation from '../../../Utils/translation';
import themeSettings from '../../../Utils/themeSettings.json';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../../../selectors/language';
import { constant } from '../../../Utils/constants';
import InputComponent from '../../../Widgets/InputComponent';
import { StyledButton } from '../../../CSS/style';
const { Option } = Select;

class FollowUpWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      organisationsList: [],
      sitesList: [],
      statusList: [],
      recordData: props.formObject && props.formObject.TaskData ? props.formObject.TaskData : [],
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      translationCache: props.translationCache || {}
    };
  }
  taskRef = React.createRef();
  schedulingWorkOrderRef = React.createRef();

  componentDidMount = async () => {
    try {
      let deviceList = await getDeviceList();
      this.setState({ deviceList: deviceList && deviceList.data ? deviceList.data : [] });
      let organisationsList = await getOrganisationsList();
      this.setState({ organisationsList: organisationsList.data });
      let sitesList = await getSitesList();
      this.setState({ sitesList: sitesList.data });
      let statusList = await getStatus();
      this.setState({ statusList });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.formObject &&
      Object.keys(this.props.formObject).length === 0 &&
      Object.keys(this.state.formObject).length === 0
    ) {
      this.onReset();
    }
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.formObject && Object.keys(this.props.formObject).length === 0) {
      this.schedulingWorkOrderRef.current.setFieldsValue(this.props.formObject);
      this.taskRef.current.setFieldsValue(this.props.formObject.TaskData);
    }

    if (prevProps.formObject !== this.props.formObject) {
      this.setState({
        formObject: this.props.formObject,
        recordData:
          this.props.formObject && this.props.formObject.TaskData
            ? this.props.formObject.TaskData
            : []
      });
    }
  }

  handleChange = (value, key) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      [key]: value
    };
    this.schedulingWorkOrderRef.current.setFieldsValue(formObject);
    this.setState({ [key]: value, formObject });
  };

  onFinish = async (values) => {
    let { formObject } = this.state;
    let payload = {
      ...formObject,
      ...values
    };
    let statusResponse = await submitJobPlan(payload);
    if (statusResponse && statusResponse.message) {
      // history.goBack();
      message.success(statusResponse.message);
      this.onReset();
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  onReset = () => {
    this.taskRef.current.resetFields();
    this.schedulingWorkOrderRef.current.resetFields();
  };
  jobPlanOnChange = async (value) => {
    let formObject = await getJobPlanById(value);
    let recordData = formObject.TaskData;
    // this.schedulingWorkOrderRef.current.setFieldsValue(formObject)
    // this.taskRef.current.setFieldsValue(formObject)
    this.taskRef.current.getFieldValue('TaskData');
    this.setState({ formObject, recordData, selectedJobPaln: value });
  };
  render() {
    let { formObject, statusList, deviceList, translationCache } = this.state;
    return (
      <>
        <Form
          onFinish={this.props.onFinish}
          name={'FollowUpWork'}
          ref={this.schedulingWorkOrderRef}
          className="jobplan"
          initialValues={formObject}
          layout="horizontal"
          labelCol={{ flex: '130px' }}
          labelAlign="right"
          labelWrap
          //wrapperCol={{ flex: 1 }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item
              label={translation(translationCache, constant.workOrder)}
              name="WorkOrderId"
              onChange={(value) => this.handleChange(value, 'WorkOrderId')}
              rules={[{ required: false, message: 'Please Input WorkOrderId!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <InputComponent
                placeholder={translation(translationCache, 'Input WorkOrder')}
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.description)}
              name="Description"
              onChange={(value) => this.handleChange(value, 'Description')}
              rules={[{ required: false, message: 'Please Input Description!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <InputComponent
                placeholder={translation(translationCache, 'Input Description')}
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.asset)}
              name="Asset"
              onChange={(value) => this.handleChange(value, 'Asset')}
              rules={[{ required: false, message: 'Please Input Asset!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <Select
                style={{ width: '70%' }}
                mode={'multiple'}
                placeholder={translation(translationCache, 'Select Device')}
              >
                {deviceList.map((device, index) => {
                  return (
                    <Option key={index} value={device.Id}>
                      {' '}
                      {device.Name}{' '}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.location)}
              name="Location"
              onChange={(value) => this.handleChange(value, 'Location')}
              rules={[{ required: false, message: 'Please Input Location!' }]}
              style={{ width: '100%', flex: '50%' }}
            >
              <Select
                style={{ width: '70%' }}
                mode={'multiple'}
                placeholder={translation(translationCache, 'Select Location')}
              >
                {deviceList.map((device, index) => {
                  return (
                    <Option key={index} value={device.Id}>
                      {' '}
                      {device.Name}{' '}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={translation(translationCache, constant.status)}
              name="StatusId"
              style={{ width: '100%', flex: '50%' }}
            >
              <Select
                style={{ width: '70%' }}
                placeholder={translation(translationCache, 'Select Status')}
                onChange={(value) => this.handleChange(value, 'StatusId')}
              >
                {statusList.map((status, index) => {
                  return (
                    <Option key={index} value={status.Id}>
                      {' '}
                      {status.Status}{' '}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item style={{ width: '100%', flex: '50%' }}>
              <Input style={{ display: 'none' }} />
            </Form.Item>
          </div>

          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              <StyledButton theme={themeSettings} htmlType="submit">
                {translation(translationCache, constant.save)}
              </StyledButton>
            </Form.Item>
          </div>
        </Form>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(FollowUpWork);
