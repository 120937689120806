import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ImgCrop from 'antd-img-crop';
import {
  Table,
  Input,
  Drawer,
  Button,
  Card,
  message,
  Select,
  Popconfirm,
  Divider,
  Form,
  Tooltip,
  Upload
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  RetweetOutlined,
  LoadingOutlined,
  SearchOutlined
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import localStorage from '../../../../utils/localStorage';
import SiteRoleComponents from './SiteRoleComponent';
import constants from '../../../../constants/config.json';
import { StyledTable, lightTheme } from '../../../../commons/styles/table';
import { DrawerFooter } from '../style';
import { getTranslationCache } from '../../../../selectors/language';
import '../style.css';
import { getUserProfile } from '../../../../selectors/layout';
import {
  API_AUTH,
  API_ROLE,
  API_SITE,
  DEPARTMENT,
  GETUSERLIST,
  MEMBER
} from '../../../../commons/api';
const { Option } = Select;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      datasource: [],
      editObj: {},
      roleEdge: [],
      departmentList: [],
      siteList: [],
      roleList: [],
      translationCache: props.translationCache || [],
      width: 250,
      height: 250
    };
  }

  userRef = React.createRef();
  componentDidMount() {
    this._getUserList();
    this._getSiteList();
    this._getDepartmentList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  }

  _getSiteList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${API_SITE.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ siteList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _getDepartmentList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${DEPARTMENT.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        this.setState({ departmentList: data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _getRolesList = (siteId) => {
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${API_ROLE.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ roleList: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _getUserList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${GETUSERLIST.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({
          datasource: response.data
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _getUserListById = (Id) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgHeaders = {
      method: 'GET',
      url: `${GETUSERLIST.GET}?SiteId=${siteId}&Id=${Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(orgHeaders)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _createUser = (e) => {
    e.preventDefault();
    let { roleEdge } = this.state;
    this.userRef.current
      .validateFields()
      .then(async (values) => {
        const siteId = localStorage.get('currentSite');
        if (
          roleEdge &&
          roleEdge[0] &&
          roleEdge[0].SiteId &&
          roleEdge[0].RoleId &&
          Array.isArray(roleEdge[0].RoleId) &&
          roleEdge[0].RoleId.length > 0 &&
          roleEdge[0].SiteId !== null
        ) {
          let saveObject = {
            ...values,
            Password: constants.defaultPassword,
            ConfirmPassword: constants.defaultPassword,
            SiteId: siteId
          };
          delete saveObject['RoleId'];
          let saveObjects = await this._createUserAPI(saveObject);
          if (saveObjects) {
            for (var i = 0; i < roleEdge.length; i++) {
              let newObj = {
                ...roleEdge[i],
                UserId: saveObjects.id
              };
              this._linkSiteRole(newObj, 'create');
            }
          }
        } else {
          message.error('Please add site and role before submitting');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _updateUser = (e) => {
    e.preventDefault();
    let { roleEdge, editObj } = this.state;
    this.userRef.current
      .validateFields()
      .then(async (values) => {
        values['Id'] = editObj.Id;
        delete values['RoleId'];
        delete values['SiteId'];
        delete values['upload'];
        if (
          roleEdge &&
          roleEdge[0] &&
          roleEdge[0].SiteId &&
          roleEdge[0].RoleId &&
          Array.isArray(roleEdge[0].RoleId) &&
          roleEdge[0].RoleId.length > 0 &&
          roleEdge[0].SiteId !== null
        ) {
          let updateObjects = await this._updateUserAPI(values);
          if (updateObjects) {
            for (var i = 0; i < roleEdge.length; i++) {
              let newObj = {
                ...roleEdge[i],
                UserId: updateObjects.id
              };
              this._linkSiteRole(
                newObj,
                roleEdge && roleEdge[i] && roleEdge[i].Id ? 'update' : 'create'
              );
            }
          }
        } else {
          message.error('Please add site and role before updating user');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _linkSiteRole = (payload, operation) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgCreateHeaders = {
      method: operation === 'create' ? 'POST' : 'PATCH',
      url:
        operation === 'create'
          ? `${MEMBER.CREATE}?SiteId=${siteId}`
          : `${MEMBER.Update}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(orgCreateHeaders)
      .then(async () => {
        await this._getUserList();
        // message.success('RoleSite Linked', response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _updateUserAPI = (updateObject) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgUpdateHeaders = {
      method: 'PATCH',
      url: `${GETUSERLIST.UPDATE}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: updateObject
    };
    return axios(orgUpdateHeaders)
      .then((response) => {
        message.success(
          response && response.data && response.data.message ? response.data.message : ''
        );
        this._getUserList();
        this._cancel();
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status === 400 || error.response.status === 500) {
          message.info(error.response.data.message);
        }
      });
  };

  _createUserAPI = (createObject) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgCreateHeaders = {
      method: 'POST',
      url: `${GETUSERLIST.CREATE}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: createObject
    };
    return axios(orgCreateHeaders)
      .then((response) => {
        message.success(
          response && response.data && response.data.message ? response.data.message : ''
        );
        this._getUserList();
        this.setState({ visible: false });
        if (this.userRef.current) {
          this.userRef.current.resetFields();
        }
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status === 400 || error.response.status === 500) {
          message.info(error.response.data.message);
        }
      });
  };

  openModal = () => {
    if (this.userRef.current) {
      this.userRef.current.resetFields();
    }
    this.setState({
      visible: true,
      roleEdge: [],
      editObj: {}
    });
  };

  _cancel = () => {
    if (this.userRef.current) {
      this.userRef.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {}
    });
  };

  editUser = async (editObj) => {
    let roleEdge = await this.getSiteRole(editObj.Id);
    if (this.userRef.current) {
      this.userRef.current.setFieldsValue(editObj);
    }
    this.setState({
      editObj,
      roleEdge: roleEdge ? [roleEdge] : [],
      visible: true,
      imageUrl: editObj.ImageURL
    });
  };

  getSiteRole = (UserId) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${MEMBER.Get}?SiteId=${siteId}&UserId=${UserId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(headers)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.status === 400 || error.response.status === 500) {
          message.info(error.response.data.message);
        }
      });
  };

  _deleteUser = (Id) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgDeleteHeaders = {
      method: 'DELETE',
      url: `${GETUSERLIST.Delete}?SiteId=${siteId}&Id=${Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgDeleteHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          message.success(
            response && response.data && response.data.message ? response.data.message : ''
          );
          this._getUserList();
          this.setState({ visible: false });
        }
      })
      .catch(function (error) {
        if (
          error.response.status === 400 ||
          error.response.status === 500 ||
          error.response.status === 500
        ) {
          message.info(error.response.data.message);
        }
      });
  };

  add = () => {
    let { roleEdge } = this.state;
    if (roleEdge && Array.isArray(roleEdge)) {
      const newObj = {
        SiteId: '',
        RoleId: [],
        UserId: ''
      };
      roleEdge.push(newObj);
    } else {
      roleEdge = [];
      const newObj = {
        SiteId: '',
        RoleId: [],
        UserId: ''
      };
      roleEdge.push(newObj);
    }
    this.setState({ roleEdge });
  };

  handleFieldChange = (e, parameter, index) => {
    const { roleEdge } = this.state;
    const arrayUpdated = this.updateArray(roleEdge, index, (item) => ({ ...item, [parameter]: e }));
    if (parameter === 'SiteId') {
      this._getRolesList(e);
    }
    this.setState({ roleEdge: arrayUpdated });
  };

  updateArray = (array, index, updateFn) => {
    return [...array.slice(0, index), updateFn(array[index]), ...array.slice(index + 1)];
  };

  remove = (k, deleteObject) => {
    const { roleEdge } = this.state;
    if (!deleteObject._key) {
      var filtered = roleEdge.filter(function (value, index) {
        return index !== k;
      });
      if (deleteObject && deleteObject.Id) {
        this._unlinkSite(deleteObject.Id);
      }
      this.setState({ roleEdge: filtered });
    }
  };

  _unlinkSite = (Id) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let orgCreateHeaders = {
      method: 'DELETE',
      url: `${MEMBER.Delete}?SiteId=${siteId}&Id=${Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(orgCreateHeaders)
      .then(() => {
        message.success('Site Unlinked');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getData = (list, text, type) => {
    var result = list.filter((obj) => {
      return obj.Id === text;
    });
    if (result && Array.isArray(result) && result.length > 0 && type === 'department') {
      return result[0].Name;
    }
    return '';
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  onHandleChangeNumeric = (e) => {
    let valu = e.target.value;
    if (!Number(valu)) {
      return;
    }
    this.setState({ [e.target.name]: valu });
  };
  renderFormSwitch(renderFormItems) {
    let { editObj } = this.state;
    let { disableOnUpdate, Widget, Placeholder, Key, Options, OptionsSettings } = renderFormItems;
    switch (Widget) {
      case 'Select':
        return (
          <Select
            style={{ width: 300 }}
            placeholder={this.translation(`Select ${Placeholder}`)}
            disabled={
              editObj && editObj.Id && disableOnUpdate && disableOnUpdate === true ? true : false
            }
            onChange={(e) => this.userRef.current.setFieldsValue({ [Key]: e })}
          >
            {Options &&
              Array.isArray(Options) &&
              Options.map((option, index) => {
                if (OptionsSettings && OptionsSettings.key && OptionsSettings.value) {
                  return (
                    <Option key={index} value={option[OptionsSettings.value]}>
                      {option[OptionsSettings.key]}
                    </Option>
                  );
                }
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
          </Select>
        );
      case 'InputNumber':
        return (
          <Input
            disabled={
              editObj && editObj.Id && disableOnUpdate && disableOnUpdate === true ? true : false
            }
            placeholder={this.translation(`Input ${Placeholder}`)}
            style={{ width: 300 }}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
                message.error('Please Use Numeric values');
              }
            }}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300 }}
            placeholder={this.translation(`Input ${Placeholder}`)}
            disabled={
              editObj && editObj.Id && disableOnUpdate && disableOnUpdate === true ? true : false
            }
          />
        );
    }
  }
  onFinishFailed = () => {
    message.error('please enter required fields');
  };

  resetpassword = (user) => {
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    let data = {
      identifier: user.UserName,
      password: constants.defaultPassword,
      role:
        this.props.userProfile &&
        this.props.userProfile.belongsTo &&
        this.props.userProfile.belongsTo.sites &&
        this.props.userProfile.belongsTo.sites[siteId] &&
        this.props.userProfile.belongsTo.sites[siteId].role &&
        this.props.userProfile.belongsTo.sites[siteId].role.Id
          ? this.props.userProfile.belongsTo.sites[siteId].role.Id
          : ''
    };
    let orgHeaders = {
      method: 'POST',
      url: `${API_AUTH.ResetPassword}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    axios(orgHeaders)
      .then((response) => {
        message.success(response.data.message);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  handleChange = async (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      // return;
    }
    if (info.file.status === 'done') {
      let { editObj } = this.state;
      let editObject = await this._getUserListById(editObj.Id);
      editObj = editObject && editObject[0] ? editObject[0] : {};
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, () =>
        this.setState({
          loading: false,
          editObj,
          imageUrl: editObj.ImageURL
        })
      );
    }
  };
  beforeUpload = (file) => {
    let siteId = localStorage.get('currentSite');
    if (siteId) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPG) {
        message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJPG && isLt2M;
    } else {
      message.warn('Please select picture');
    }
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation(`Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    const { datasource, roleEdge, visible, editObj, departmentList, imageUrl, width, height } =
      this.state;
    let UserName = editObj.UserName;
    let userId =
      this.props.userProfile && this.props.userProfile.user && this.props.userProfile.user.Id
        ? this.props.userProfile.user.Id
        : '';
    const siteId = localStorage.get('currentSite');

    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">{this.translation('Upload')}</div>
      </div>
    );
    const formItems =
      roleEdge &&
      Array.isArray(roleEdge) &&
      roleEdge.map((site, index) =>
        site !== null ? (
          <SiteRoleComponents
            key={index}
            site={site}
            index={index}
            handleFieldChange={this.handleFieldChange}
            remove={this.remove}
            userId={editObj.Id}
          />
        ) : null
      );

    let columns = [
      {
        title: this.translation('First Name'),
        dataIndex: 'FirstName',
        key: 'FirstName',
        ...this.getColumnSearchProps('FirstName')
      },
      {
        title: this.translation('Last Name'),
        dataIndex: 'LastName',
        key: 'LastName',
        ...this.getColumnSearchProps('LastName')
      },
      {
        title: this.translation('User Name'),
        dataIndex: 'UserName',
        key: 'UserName',
        ...this.getColumnSearchProps('UserName')
      },
      {
        title: this.translation('Email'),
        dataIndex: 'Email',
        key: 'Email',
        ...this.getColumnSearchProps('Email')
      },
      {
        title: this.translation('Action'),
        dataIndex: '',
        key: '',
        render: (text, record) => {
          if (record && record.Default !== true) {
            return (
              <div>
                <Tooltip title={this.translation('Edit')}>
                  <button
                    type="button"
                    onClick={() => this.editUser(record)}
                    style={{ marginRight: '10px' }}
                    className="ant-btn"
                  >
                    <EditOutlined />
                  </button>
                </Tooltip>
                <Popconfirm
                  title={this.translation('Are you sure delete User ?')}
                  onConfirm={(e) => {
                    e.stopPropagation();
                    this._deleteUser(record.Id);
                  }}
                  okText={this.translation('Yes')}
                  cancelText={this.translation('No')}
                >
                  <Tooltip title={this.translation('Delete')}>
                    <button type="button" className="ant-btn">
                      <DeleteOutlined />
                    </button>
                  </Tooltip>
                </Popconfirm>

                <Button
                  onClick={() => {
                    this.resetpassword(record);
                  }}
                  style={{ marginRight: '10px' }}
                >
                  <Tooltip title={this.translation('Reset Password')}>
                    <RetweetOutlined />
                  </Tooltip>
                </Button>
              </div>
            );
          }
        }
      }
    ];
    let formStructure = [
      {
        DisplayName: 'Salutation',
        Key: 'Salutation',
        Widget: 'Select',
        Options: ['Ms', 'Mr', 'Mrs', 'Jr', 'Sr'],
        Placeholder: 'Salutation',
        Required: true
      },
      {
        DisplayName: 'FirstName',
        Key: 'FirstName',
        Widget: 'Input',
        Placeholder: 'FirstName',
        Required: true
      },
      {
        DisplayName: 'LastName',
        Key: 'LastName',
        Widget: 'Input',
        Placeholder: 'LastName',
        Required: true
      },
      {
        DisplayName: 'UserName',
        Key: 'UserName',
        Widget: 'Input',
        disableOnUpdate: true,
        Placeholder: 'UserName',
        Required: true
      },
      {
        DisplayName: 'Email',
        Key: 'Email',
        Widget: 'Input',
        disableOnUpdate: true,
        Placeholder: 'Email',
        Required: true
      },
      {
        DisplayName: 'Mobile Number',
        Key: 'MobileNumber',
        Widget: 'InputNumber',
        disableOnUpdate: true,
        Placeholder: 'MobileNumber',
        Required: true
      },
      {
        DisplayName: 'Department',
        Key: 'DepartmentId',
        Widget: 'Select',
        Placeholder: 'Department',
        Options: departmentList,
        Required: true,
        OptionsSettings: { key: 'Name', value: 'Id' }
      }
    ];
    return (
      <Card bordered={false}>
        <Drawer
          title={!editObj.Id ? this.translation('Create User') : this.translation('Update User')}
          visible={visible}
          width={600}
          closable
          onClose={this._cancel}
        >
          <Form
            layout="vertical"
            ref={this.userRef}
            name="user_form"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            initialValues={editObj || {}}
            style={{ marginBottom: '40px' }}
            onFinishFailed={this.onFinishFailed}
          >
            {editObj.Id === userId ? (
              <Form.Item
                label={this.translation('Upload')}
                name="upload"
                rules={[{ required: true, message: 'upload!' }]}
              >
                <span>
                  <ImgCrop width={width} height={height}>
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={`${GETUSERLIST.LogoUpload}?SiteId=${siteId}&UserName=${UserName}`}
                      headers={{
                        Authorization: Authorization
                      }}
                      beforeUpload={this.beforeUpload}
                      onChange={this.handleChange}
                    >
                      {imageUrl ? (
                        <img src={`/api/${imageUrl}`} height="100px" alt="avatar" />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </ImgCrop>
                </span>
              </Form.Item>
            ) : null}

            {formStructure &&
              Array.isArray(formStructure) &&
              formStructure.map((formItem, index) => {
                return (
                  <Form.Item
                    key={index}
                    name={formItem.Key}
                    label={this.translation(formItem.DisplayName)}
                    rules={[
                      {
                        required: formItem.Required,
                        message: this.translation(`${formItem.DisplayName} is required`)
                      }
                    ]}
                  >
                    {this.renderFormSwitch(formItem)}
                  </Form.Item>
                );
              })}

            <Divider>{this.translation('SiteRole')}</Divider>
            <Form.Item>
              <Button type="dashed" onClick={this.add}>
                <PlusOutlined />
                {this.translation('Add Item')}
              </Button>
            </Form.Item>
            {formItems}
          </Form>

          <DrawerFooter>
            <Button onClick={this._cancel} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            {!editObj.Id ? (
              <Button type="primary" onClick={this._createUser}>
                {this.translation('Create')}
              </Button>
            ) : (
              <Button type="primary" onClick={this._updateUser}>
                {this.translation('Update')}
              </Button>
            )}
          </DrawerFooter>
        </Drawer>
        <Button
          type="primary"
          size={'large'}
          style={{
            marginBottom: '10px',
            backgroundColor: '#214972',
            border: 'white'
          }}
          onClick={this.openModal}
        >
          <PlusOutlined /> {this.translation('Add')}
        </Button>
        <StyledTable theme={lightTheme}>
          <Table
            columns={columns}
            dataSource={datasource}
            pagination={
              datasource.length > 10
                ? {
                    pageSize: '10'
                  }
                : false
            }
          />
        </StyledTable>
      </Card>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  userProfile: getUserProfile()
});

export default connect(mapStateToProps)(User);
