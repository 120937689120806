import React from 'react';
import axios from 'axios';
import {
  Table,
  Form,
  Col,
  Input,
  Drawer,
  Button,
  Card,
  Row,
  message,
  Popconfirm,
  Tree,
  Select,
  Checkbox
} from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

// import { constant } from '../../Settings/Constants';
import history from '../../../commons/history';
import localStorage from '../../../utils/localStorage';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getTranslationCache } from '../.././../selectors/language';
import { DrawerFooter, StyledTable, lightTheme, StyledDashboard } from './style';
import './style.css';
import { API_HIERARCHYMODULE } from '../../../commons/api';

const { TreeNode } = Tree;
const { Option } = Select;

class Hierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      datasource: [],
      editObj: {},
      treeData: [],
      modalVisible: false,
      translationCache: props.translationCache || [],
      checkedKeys: []
    };
    this.hRef = React.createRef();
  }

  componentDidMount() {
    this._getHierarchyList();
  }

  _getHierarchyList = () => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let headers = {
      method: 'GET',
      url: `${API_HIERARCHYMODULE.MainHierarchyList}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.setState({ datasource: data });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  openModal = () => {
    if (this.hRef.current) {
      this.hRef.current.resetFields();
    }
    this.setState({
      visible: true,
      editObj: {}
    });
  };

  _cancel = () => {
    if (this.hRef.current) {
      this.hRef.current.resetFields();
    }
    this.setState({
      visible: false,
      editObj: {},
      checked: false,
      checkedKeys: [],
      treeObject: []
    });
  };

  editHierarchy = (editObj) => {
    if (this.hRef.current) {
      this.hRef.current.setFieldsValue(editObj);
    }
    this.setState({
      editObj,
      visible: true
    });
  };

  _createHierarchy = () => {
    let { checkedKeys } = this.state;
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        if (this.state.checked === true && checkedKeys.length !== 0) {
          let payload = {
            ...values,
            HierarchyIdNodes: this.state.checkedKeys
          };
          this._createHierarchyFromHierarchyAPI(payload);
        } else {
          this._createHierarchyAPI(values);
        }
      })
      .catch(() => {});
  };

  _createHierarchyFromHierarchyAPI = (createObject) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let orgCreateHeaders = {
      method: 'POST',
      url: `${API_HIERARCHYMODULE.CreateVirtualHierarchies}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { ...createObject, SiteId: siteId }
    };
    axios(orgCreateHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          message.success('Hierarchy Saved');
          this._getHierarchyList();
          this.setState({ visible: false });
        }
      })
      .catch(function (err) {
        if (err.response.status === 400 || err.response.status === 500) {
          message.error(err.response.data.message);
        }
      });
  };

  _updateHierarchy = () => {
    let { editObj } = this.state;
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        let updateObject = {
          ...values,
          Id: editObj.Id
        };
        this._updateHierarchyAPI(updateObject);
      })
      .catch(() => {});
  };

  _createHierarchyAPI = (createObject) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let orgCreateHeaders = {
      method: 'POST',
      url: `${API_HIERARCHYMODULE.MainHierarchyCreate}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { ...createObject, SiteId: siteId }
    };
    axios(orgCreateHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          message.success('Hierarchy Saved');
          this._getHierarchyList();
          this.setState({ visible: false });
        }
      })
      .catch(function (err) {
        if (err.response.status === 400 || err.response.status === 500) {
          message.error(err.response.data.message);
        }
      });
  };

  _updateHierarchyAPI = (updateObject) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let orgUpdateHeaders = {
      method: 'PATCH',
      url: `${API_HIERARCHYMODULE.MainHierarchyUpdate}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: updateObject
    };
    axios(orgUpdateHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          message.success('Hierarchy Updated');
          this._getHierarchyList();
          this.setState({ visible: false, editObj: {} });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  _deleteHierarchy = (payload) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let orgDeleteHeaders = {
      method: 'POST',
      url: `${API_HIERARCHYMODULE.MainHierarchyDelete}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(orgDeleteHeaders)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          message.success('Hierarchy Deleted');
          this._getHierarchyList();
          this.setState({ visible: false });
        }
      })
      .catch(function (err) {
        if (err.response.status === 400 || err.response.status === 500) {
          message.error(err.response.data.message);
        }
      });
  };

  openHierarchyForm = (record) => {
    history.push(`/rubus/settingsPage/Hierarchy/${record.Id}`);
  };

  onViewHierarchy = async (record) => {
    let treeData = await this._getHierarchyLevels(record.HierarchyCode);
    this.setState({ treeData, modalVisible: true });
  };

  onGetHierarchy = async (record) => {
    let treeObject = await this._getHierarchyLevels(record);
    this.setState({ treeObject });
  };

  _getHierarchyLevels = (record) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    let headers = {
      method: 'GET',
      url: `${API_HIERARCHYMODULE.GetHierarchyLevels}?HierarchyCode=${record}&SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(headers)
      .then(({ data }) => {
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  renderTreeNodes = (data) =>
    data &&
    Array.isArray(data) &&
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.Name} key={item.Id} autoExpandParent={true} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.Id} title={item.Name} {...item} />;
    });

  onClose = () => {
    if (this.hRef.current) {
      this.hRef.current.resetFields();
    }
    this.setState({ modalVisible: false, editObj: {} });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  onFinishFailed = () => {
    message.error('please enter required fields');
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation(`Search ${dataIndex}`)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    let { datasource, visible, editObj, modalVisible, treeData, treeObject, checkedKeys } =
      this.state;
    const columns = [
      {
        title: this.translation('Hierarchy Name'),
        dataIndex: 'HierarchyName',
        ...this.getColumnSearchProps('HierarchyName')
      },
      {
        title: this.translation('Hierarchy Code'),
        dataIndex: 'HierarchyCode',
        ...this.getColumnSearchProps('HierarchyCode')
      },
      {
        title: this.translation('Action'),
        render: (text, record) => (
          <div>
            <button
              type="button"
              onClick={() => this.editHierarchy(record)}
              style={{ marginRight: '10px' }}
              className="ant-btn"
            >
              <EditOutlined />
            </button>

            {record && record.Default && record.Default !== true ? (
              <Popconfirm
                title={this.translation('Are you sure delete Hierarchy?')}
                onConfirm={(e) => {
                  e.stopPropagation();
                  this._deleteHierarchy(record);
                }}
                okText={this.translation('Yes')}
                cancelText={this.translation('No')}
              >
                <button
                  type="button"
                  className="ant-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <DeleteOutlined />
                </button>
              </Popconfirm>
            ) : null}

            <button
              type="button"
              onClick={() => this.openHierarchyForm(record)}
              style={{ marginLeft: '10px' }}
              className="ant-btn"
            >
              {this.translation('Click to redirect')}
            </button>
            <button
              type="primary"
              className="ant-btn"
              style={{ marginLeft: '10px' }}
              onClick={() => this.onViewHierarchy(record)}
            >
              {this.translation('View')}
            </button>
          </div>
        )
      }
    ];
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 173 }}>
        <Drawer visible={modalVisible} closable onClose={this.onClose} width={600}>
          <Tree defaultExpandAll={true} showLine>
            treeData && Array.isArray(treeData) && treeData.length != 0 ?
            {this.renderTreeNodes(treeData)}: null
          </Tree>
        </Drawer>
        <Card bordered={false}>
          <Drawer
            title={
              !editObj.Id
                ? this.translation('Create Hierarchy')
                : this.translation('Update Hierarchy')
            }
            visible={visible}
            width={600}
            closable
            onClose={this._cancel}
          >
            <Form
              layout="vertical"
              name={'HierarchyForm'}
              ref={this.hRef}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              initialValues={editObj || {}}
              onFinishFailed={this.onFinishFailed}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={this.translation('Hierarchy Name')} name="HierarchyName">
                    <Input placeholder={this.translation('Please enter Hierarchy name')} />
                  </Form.Item>

                  <Form.Item label={this.translation('Hierarchy Code')} name="HierarchyCode">
                    <Input placeholder={this.translation('Please enter Hierarchy Code')} />
                  </Form.Item>
                  {!editObj.Id ? (
                    <>
                      <Checkbox
                        style={{ marginBottom: '20px' }}
                        checked={this.state.checked}
                        onChange={(e) => this.setState({ checked: e.target.checked })}
                      >
                        {this.translation('Click to choose hierarchy from existing tree structure')}
                      </Checkbox>
                      {this.state.checked === true ? (
                        <>
                          <Form.Item
                            label={this.translation('Replicate Hierarchy')}
                            name="ReplicateHierarchyCode"
                          >
                            <Select
                              onChange={(e) => this.onGetHierarchy(e)}
                              placeholder={this.translation('Replicate Hierarchy')}
                            >
                              {datasource && Array.isArray(datasource)
                                ? datasource.map((hierarchy, index) => (
                                    <Option key={index} value={hierarchy.HierarchyCode}>
                                      {this.translation(hierarchy.HierarchyName)}
                                    </Option>
                                  ))
                                : null}
                            </Select>
                          </Form.Item>

                          <Form.Item label={'Select Tree'}>
                            <Tree
                              defaultExpandAll={true}
                              checkable
                              onCheck={(e) => this.setState({ checkedKeys: e })}
                              checkedKeys={checkedKeys}
                            >
                              treeObject && Array.isArray(treeObject) ?{' '}
                              {this.renderTreeNodes(treeObject)} : null
                            </Tree>
                          </Form.Item>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Col>
              </Row>
            </Form>
            <DrawerFooter>
              <Button onClick={this._cancel} style={{ marginRight: 8 }}>
                {this.translation('Cancel')}
              </Button>
              {!editObj.Id ? (
                <Button type="primary" onClick={() => this._createHierarchy()}>
                  {this.translation('Create')}
                </Button>
              ) : (
                <Button type="primary" onClick={() => this._updateHierarchy()}>
                  {this.translation('Update')}
                </Button>
              )}
            </DrawerFooter>
          </Drawer>
          <Button
            type="primary"
            size={'large'}
            icon={<PlusOutlined />}
            style={{ marginBottom: '10px' }}
            onClick={this.openModal}
          >
            {this.translation('Add')}
          </Button>

          <StyledTable theme={lightTheme}>
            <Table
              className="basictable"
              columns={columns}
              dataSource={datasource}
              pagination={
                datasource.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : false
              }
            />
          </StyledTable>
        </Card>
      </StyledDashboard>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(Hierarchy);
