import React, { Fragment } from 'react';
import { Input, Select, Popover, Col, Row, TimePicker, message, Button } from 'antd';
import moment from 'moment';

import HierarchyModal from '../utils/HierarchyModal';
import {
  Boxdiv,
  StyledAppdiv,
  StyledModal,
  RuleTypeSelectionDiv,
  BlockedLabelBlack,
  ConditionSelect,
  ActionSelectionDiv,
  // AddParagraph,
  LogicAddButton,
  BlankDiv,
  DeviceSelect,
  ParameterSelect,
  TagNameSelect,
  OperaterSelect,
  ValueInput,
  ButtonBoxDiv,
  // PickyStyling,
  MarginRightButton,
  OnchangeCheckbox,
  ActionCheckbox,
  TextParagraph
  // AddParagraph
  // BlockedParaBlack
} from '../style';
import { actionList } from '../utils/const';
import { getCommunicationList, getRuleType, getUserprofiles, recipientListAPI } from '../Apicalls';
import constants from '../constants';
import localStorage from '../../../utils/localStorage';
import 'antd/lib/date-picker/style/css';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  ApartmentOutlined,
  UpSquareOutlined
} from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;
const Operator = [
  {
    key: 'EqualTo (==)',
    value: '=='
  },
  {
    key: 'Not EqualTo (!=)',
    value: '!='
  },
  {
    key: 'Greater Than (>)',
    value: '>'
  },
  {
    key: 'Less Than (<)',
    value: '<'
  },
  {
    key: 'Greater Than Or EqualTo (>=)',
    value: '>='
  },
  {
    key: 'Less Than Or EqualTo (<=)',
    value: '<='
  }
];

// export default function DeviceRuleView(props) {
export default class DeviceRule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sitesList: [],
      ItemdataSource: [],
      treeVisible: false,
      communicationType: [],
      value: [],
      data: [],
      translationAction: [],
      checkedValue: [],
      ruleTypeList: [],
      selectHierachyLevel: [],
      userProfile: props.userProfile || {},
      DeviceData: [],
      enable: props.enable || '',
      treeData: this.props && this.props.treeData ? this.props.treeData : [],
      selectedHierarchy: [],
      edit: this.props && this.props.edit ? this.props.edit : false
    };
  }

  async componentDidMount() {
    let { communicationType } = this.state;
    const { handleSelectHierarchy } = this.props.Methods;
    let commlist = await getCommunicationList();
    let ruleTypeList = await getRuleType();
    communicationType =
      commlist &&
      commlist.data &&
      Array.isArray(commlist.data) &&
      commlist.data.map((item) => {
        let val = item.Code;
        let t1 = communicationType.push(val);
        return t1;
        // this.setState({ communicationType });
      });
    let filteredActionList = this.getList();
    let recipientData = await recipientListAPI();
    let userProfile = await getUserprofiles();

    let HierachyLevels =
      userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['hierarchy'];
    // userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['defaultHierarchy'];

    // let hierarchyDefault =
    //   HierachyLevels && HierachyLevels.HierarchyCode ? HierachyLevels.HierarchyCode : undefined;
    // handleSelectHierarchy(hierarchyDefault, 0, 'inputList', 'initail');
    let hierarchyDefault =
      HierachyLevels && HierachyLevels[0] && HierachyLevels[0].HierarchyCode
        ? HierachyLevels[0].HierarchyCode
        : undefined;
    handleSelectHierarchy(hierarchyDefault, 0, 'inputList', 'initail');

    this.setState({
      enable: true,
      data: filteredActionList,
      communicationType,
      ruleTypeList,
      selectedHierarchy: HierachyLevels,
      recipientList: recipientData && recipientData.data ? recipientData.data : [],
      hierarchyDefault
    });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.userProfile !== this.props.userProfile || prevProps.edit !== this.props.edit) {
      this.setState({
        edit: this.props.edit,
        userProfile: this.props.userProfile
      });
    }
  }
  getList = () => {
    let { communicationType } = this.state;
    let filteredArray = [];
    filteredArray = communicationType.map((comm) => {
      return actionList.filter((action) => action.value === comm);
    });
    let data = [];
    filteredArray.map((item) => {
      return item.map((val) => {
        return data.push(val);
      });
    });
    return data;
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  hierarchyDropdownChange = (value) => {
    const { Methods } = this.props;
    const { handledeviceSelect } = Methods;
    let { indexDevice } = this.props;
    handledeviceSelect(value.Code, 'inputList', indexDevice);
    this.setState({
      treeVisible: false
    });
  };
  onModalOk = () => {
    this.setState({
      treeVisible: false
    });
  };
  onModalCancel = () => {
    this.setState({
      treeVisible: false
    });
  };
  hierarchyDropdownChanged = (value) => {
    this.setState({ checkedValue: value });
  };

  onModalsOk = (e) => {
    const { Methods } = this.props;
    const { handlePickyChange } = Methods;
    let { checkedValue } = this.state;
    e.preventDefault();
    handlePickyChange(checkedValue);
    this.setState({
      visibleTree: false
      // test: this.props.config.replicateDevices
    });
  };
  onModalsCancel = () => {
    this.setState({
      visibleTree: false
    });
  };

  onTree = (arr) => {
    if (arr && Array.isArray(arr)) {
      arr.map((obj) => {
        return obj && Array.isArray(obj.children)
          ? { ...obj, children: this.onTree(obj.children) }
          : { ...obj, checkable: true };
      });
    }
    return arr;
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { config, Methods, treeData, translationCache } = this.props;
    const {
      inputList,
      conif,
      conelseif,
      conelse,
      elsifLength,
      elseList,
      ruleType,
      mailRecipients,
      rulebody,
      url,
      deviceList,
      batchPriority,
      downtimeinputList,
      onChange,
      onContinous,
      previsible,
      rootCause,
      recommandationStatus,
      elseprevisible,
      elseroot,
      elserecommandation,
      replicateDevices,
      parametersList,
      parameterChangeList,
      disabledParamTypeDevice,
      selectedDevices
    } = config;
    const {
      handledeviceSelect,
      handleParameterSelect,
      handleSelectHierarchy,
      // handleelseif,
      // handleelse,
      handleDelay,
      handleFrequency,
      checkonChange,
      handleTagSelect,
      handleInputChange,
      handleAddClick,
      handleRuleType,
      handleRecipientList,
      handleRulebody,
      handleurl,
      // handlePickyChange,
      handleRadioButton,
      handleContinous,
      handleRemoveClick,
      handleVisibleChange,
      hanblePopoverOnclick,
      handleTextAreaChange,
      handleConditionchange
      // handleparameterTypeSelect
    } = Methods;
    let { ruleTypeList, selectedHierarchy, recipientList, hierarchyDefault, enable } = this.state;
    return (
      <StyledAppdiv>
        <RuleTypeSelectionDiv>
          <div style={{ marginRight: '12%', marginLeft: '10px' }}>
            <BlockedLabelBlack>{this.translation(constants.type)}</BlockedLabelBlack>
            <Select
              placeholder="ruleType"
              style={{ width: '120px', display: 'inline-block' }}
              value={ruleType}
              onChange={(value) => handleRuleType(value, 'ruleType')}
            >
              {ruleTypeList &&
                Array.isArray(ruleTypeList) &&
                ruleTypeList.map((ruleType, index) => {
                  return (
                    <Option
                      key={index}
                      value={ruleType.Code}
                      title={this.translation(ruleType.Description)}
                    >
                      {this.translation(ruleType.Name)}
                    </Option>
                  );
                })}
            </Select>
          </div>
          {ruleType === 'batch' ? (
            <div style={{ marginRight: '12%', marginTop: '5px' }}>
              <label style={{ display: 'block', color: 'white' }}>
                {this.translation(constants.priority)}
              </label>
              <Select
                placeholder={this.translation(constants.priority)}
                style={{ width: '120px', display: 'inline-block' }}
                value={batchPriority === '' ? undefined : batchPriority}
                onChange={(value) => handleRuleType(value, 'batchPriority')}
              >
                {config.priorityTypes.map((type, index) => (
                  <Option key={index} value={type.PriorityType}>
                    {type.PriorityType}
                  </Option>
                ))}
              </Select>
            </div>
          ) : null}
          {ruleType === 'downTime' ? (
            <>
              <div style={{ marginRight: '12%', marginTop: '5px' }}>
                <label style={{ display: 'block', color: 'white' }}>
                  {this.translation(constants.device)}
                </label>
                <DeviceSelect
                  placeholder={this.translation(constants.device)}
                  size="default"
                  value={downtimeinputList.device === '' ? undefined : downtimeinputList.device}
                  onChange={(value) => handledeviceSelect(value, 'downtimeinputList')}
                >
                  {deviceList &&
                    Array.isArray(deviceList) &&
                    deviceList.map((device, i) => {
                      return (
                        <Option key={`ifdeviceList${i}`} value={device.AliasCode}>
                          {device.Name}
                        </Option>
                      );
                    })}
                </DeviceSelect>
              </div>
              <div>
                <ActionSelectionDiv>
                  <BlockedLabelBlack>
                    {this.translation(constants.action)}
                    <span style={{ color: 'red' }}>*</span>
                  </BlockedLabelBlack>
                  <ActionCheckbox
                    options={this.state.data}
                    value={downtimeinputList['action']}
                    onChange={(checkedValues) => {
                      checkonChange(checkedValues, 'downtimeinputList');
                    }}
                  />
                  <Popover placement="top" title={'Delay'}>
                    <TimePicker
                      style={{ margin: '10px' }}
                      defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                      value={
                        downtimeinputList &&
                        downtimeinputList &&
                        downtimeinputList['delay'] === undefined
                          ? moment('00:00:00', 'HH:mm:ss')
                          : moment(
                              downtimeinputList &&
                                downtimeinputList &&
                                downtimeinputList['delay'][1],
                              'HH:mm:ss'
                            )
                      }
                      onChange={(e) => handleDelay(e, 'downtimeinputList')}
                      placeholder={this.translation(constants.selectDelay)}
                      allowClear={false}
                      size="small"
                    />
                  </Popover>
                  <Popover placement="top" title={'Frequency'}>
                    <TimePicker
                      disabled={onContinous === true ? false : true}
                      style={{ margin: '10px' }}
                      defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                      value={
                        downtimeinputList &&
                        downtimeinputList &&
                        downtimeinputList['frequency'] === undefined
                          ? moment('00:00:00', 'HH:mm:ss')
                          : moment(
                              downtimeinputList &&
                                downtimeinputList &&
                                downtimeinputList['frequency'][1],
                              'HH:mm:ss'
                            )
                      }
                      onChange={(e) => handleFrequency(e, 'downtimeinputList')}
                      placeholder={this.translation(constants.selectFrequency)}
                      allowClear={false}
                      size="small"
                    />
                  </Popover>
                  {config &&
                  config.inputList &&
                  config.inputList[config.inputList.length - 1].action.indexOf('RestAPI') > -1 ? (
                    <Input
                      style={{ width: '55%', marginTop: '2%' }}
                      placeholder="Enter Url"
                      name="value"
                      value={url}
                      onChange={(e) => handleurl(e, 'url')}
                    />
                  ) : null}
                </ActionSelectionDiv>
              </div>
            </>
          ) : null}
          {ruleType !== 'downTime' ? (
            <div
              style={{
                // marginRight: '12%',
                width: '350px',
                display: 'flex',
                justifyContent: 'flex-start',
                flexFlow: 'row wrap'
              }}
            >
              <BlockedLabelBlack>{this.translation(constants.replicate)}</BlockedLabelBlack>
              <span
                style={{
                  // marginRight: '12%',
                  width: '350px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexFlow: 'row wrap'
                }}
              >
                <Select
                  value={replicateDevices}
                  // //  onChange={this.OnDeviceChange}
                  size="default"
                  disabled={true}
                  mode="multiple"
                  style={{ width: '70%', color: 'black' }}
                  placeholder="Select Item..."
                  maxTagCount="responsive"
                >
                  {deviceList &&
                    deviceList.map((device, i) => {
                      return (
                        <Option key={`ifdeviceList${i}`} value={device.AliasCode}>
                          {device.Name}
                        </Option>
                      );
                    })}
                </Select>
                <Button
                  type="primary"
                  style={{ width: '15%' }}
                  onClick={() => {
                    if (
                      (selectedDevices && selectedDevices.length > 0) ||
                      this.state.edit === true
                    ) {
                      this.setState({ visibleTree: true });
                    } else {
                      this.setState({ visibleTree: false });
                      message.error('Please Select Equipment');

                      // this.setState({ visibleReplicateModal: false });
                      // message.error(this.translation('Please Select Equipment'));
                    }
                  }}
                >
                  <UpSquareOutlined />
                </Button>
              </span>
            </div>
          ) : null}
          {ruleType !== 'downTime' ? (
            <OnchangeCheckbox checked={onChange} onChange={(value) => handleRadioButton(value)}>
              {translationCache && translationCache[`${constants.onchange}`]
                ? translationCache[`${constants.onchange}`]
                : `${constants.onchange}`}
            </OnchangeCheckbox>
          ) : null}
        </RuleTypeSelectionDiv>
        {ruleType !== 'downTime' ? (
          <>
            {conif
              ? inputList.map((x, i) => {
                  return (
                    <Boxdiv key={`if${i}`}>
                      {i === 0 ? (
                        <div style={{ display: 'block' }}>
                          <BlockedLabelBlack>{`if`}</BlockedLabelBlack>
                        </div>
                      ) : null}
                      {i > 0 ? (
                        <div
                          style={{
                            marginRight: '10px 0px'
                          }}
                        >
                          <ConditionSelect
                            name="operater"
                            style={{
                              marginBottom: '25px'
                            }}
                            placeholder={
                              translationCache && translationCache[`${constants.selectCondition}`]
                                ? translationCache[`${constants.selectCondition}`]
                                : `${constants.selectCondition}`
                            }
                            size="default"
                            value={x['54765'] === '' ? undefined : x['54765']}
                            onChange={(e) => handleConditionchange(e, i, 'inputList')}
                          >
                            <Option value="&&">AND</Option>
                            <Option value="||">OR</Option>
                          </ConditionSelect>
                        </div>
                      ) : null}

                      <ParameterSelect
                        disabled={disabledParamTypeDevice}
                        name="parameterName"
                        placeholder={
                          translationCache && translationCache[`${constants.ParameterType}`]
                            ? translationCache[`${constants.ParameterType}`]
                            : `${constants.ParameterType}`
                        }
                        size="default"
                        value={x.parameterName === '' ? undefined : x.parameterName}
                        onChange={(value) => handleParameterSelect(value, i, 'inputList')}
                      >
                        {parameterChangeList &&
                          Array.isArray(parameterChangeList) &&
                          parameterChangeList.map((parameter, i) => {
                            return (
                              <Option
                                key={`ifdeviceList${i}`}
                                value={parameter.ParameterType}
                                // disabled={parameter.Key === 'Operational' ? false : true}
                              >
                                {parameter.ParameterType}
                              </Option>
                            );
                          })}
                      </ParameterSelect>

                      <ParameterSelect
                        name="selectedHierarchy"
                        onChange={(value) => handleSelectHierarchy(value, i, 'inputList')}
                        placeholder={this.translation('Please select hierarchy')}
                        value={x && x.HierarchyId === undefined ? hierarchyDefault : x.HierarchyId}
                      >
                        {selectedHierarchy &&
                          Array.isArray(selectedHierarchy) &&
                          selectedHierarchy.map((item, i) => {
                            return (
                              <Option key={i} value={item.HierarchyCode}>
                                {item.HierarchyName}
                              </Option>
                            );
                          })}
                      </ParameterSelect>
                      <DeviceSelect
                        placeholder={
                          translationCache && translationCache[`${constants.device}`]
                            ? translationCache[`${constants.device}`]
                            : `${constants.device}`
                        }
                        size="default"
                        value={x.device === '' ? undefined : x.device}
                        disabled={true}
                        // onChange={(value) => handledeviceSelect(value, 'inputList', i)}
                        suffixIcon={
                          <ApartmentOutlined
                            style={{
                              color: '#1a3b5c',
                              cursor:
                                disabledParamTypeDevice && disabledParamTypeDevice === true
                                  ? 'not-allowed'
                                  : null,
                              opacity:
                                disabledParamTypeDevice && disabledParamTypeDevice === true
                                  ? '0.3'
                                  : null
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (enable && enable === true ? true : false) {
                                this.setState({
                                  treeVisible: true
                                });
                              } else {
                                if (inputList && inputList[i] && !inputList[i].parameterName) {
                                  this.setState({ treeVisible: false });
                                  message.error(this.translation('Please Select ParameterType'));
                                } else {
                                  this.setState({ treeVisible: false });
                                }
                              }
                            }}
                          />
                        }
                      >
                        {deviceList &&
                          deviceList.map((device, i) => {
                            return (
                              <Option key={`ifdeviceList${i}`} value={device.AliasCode}>
                                {device.Name}
                              </Option>
                            );
                          })}
                      </DeviceSelect>
                      <TagNameSelect
                        showSearch
                        name="tagName"
                        size="default"
                        placeholder={
                          translationCache && translationCache[`${constants.selectparameters}`]
                            ? translationCache[`${constants.selectparameters}`]
                            : `${constants.selectparameters}`
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={x && x.device && x.tagName ? `${x.device}@@${x.tagName}` : undefined}
                        onChange={(e) => handleTagSelect(e, i, 'inputList')}
                      >
                        {parametersList &&
                          Array.isArray(parametersList) &&
                          parametersList.map((param, j) => {
                            return (
                              <Option key={`inputTagname${i}${j}`} value={param.ParameterCode}>
                                {param.ParameterName}
                              </Option>
                            );
                          })}
                      </TagNameSelect>
                      <OperaterSelect
                        name="operater"
                        size="default"
                        placeholder={
                          translationCache &&
                          translationCache[`${constants.selectoperator}`] &&
                          translationCache[`${constants.selectoperator}`]
                            ? translationCache[`${constants.selectoperator}`]
                            : `${constants.selectoperator}`
                        }
                        value={x.operater === '' ? undefined : x.operater}
                        onChange={(e) => handleInputChange(e, i, 'select', 'inputList')}
                      >
                        {Operator.map((item, index) => {
                          return (
                            <>
                              {config.parameter === 'Operational' ? (
                                <Option key={index} value={item.value}>
                                  {item.key}
                                </Option>
                              ) : null}
                            </>
                          );
                        })}
                        {config.parameter !== 'Operational' ? (
                          <Option value="==" title="EqualTo">
                            {`EqualTo (==)`}
                          </Option>
                        ) : null}
                      </OperaterSelect>
                      <ValueInput
                        size="default"
                        name="value"
                        maxLength={6}
                        placeholder={
                          translationCache && translationCache[`${constants.value}`]
                            ? translationCache[`${constants.value}`]
                            : `${constants.value}`
                        }
                        value={x.value}
                        onChange={(e) => handleInputChange(e, i, 'input', 'inputList')}
                      />

                      {inputList.length !== 1 && (
                        <MarginRightButton
                          size="default"
                          onClick={() => handleRemoveClick(i, 'inputList')}
                        >
                          <DeleteOutlined />
                        </MarginRightButton>
                      )}
                      {inputList.length - 1 === i && (
                        <LogicAddButton
                          type={'primary'}
                          size="default"
                          onClick={() => handleAddClick('inputList', this.state.ruleType)}
                        >
                          {translationCache && translationCache[`${constants.add}`]
                            ? translationCache[`${constants.add}`]
                            : `${constants.add}`}
                        </LogicAddButton>
                      )}

                      <ButtonBoxDiv>
                        {inputList.length - 1 === i && (
                          <Fragment>
                            <Popover
                              content={
                                <div>
                                  <TextArea
                                    value={
                                      inputList[i] && inputList[i]['preventive']
                                        ? inputList[i]['preventive']
                                        : ''
                                    }
                                    rows={5}
                                    maxLength="150"
                                    onChange={(e) =>
                                      handleTextAreaChange(e, i, 'inputList', 'preventive')
                                    }
                                  />

                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        'inputList',
                                        'close',
                                        'preventive',
                                        'previsible'
                                      );
                                    }}
                                  >
                                    {this.translation('Cancel')}
                                  </span>
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(i, 'inputList', 'ok', 'previsible');
                                    }}
                                  >
                                    {this.translation('Ok')}
                                  </span>
                                </div>
                              }
                              open={previsible}
                              onOpenChange={(value) => handleVisibleChange(value, 'previsible')}
                              title={
                                translationCache &&
                                translationCache[`${constants.preventivemeasure}`]
                                  ? translationCache[`${constants.preventivemeasure}`]
                                  : `${constants.preventivemeasure}`
                              }
                              trigger="click"
                            >
                              <TextParagraph>
                                {translationCache &&
                                translationCache[`${constants.preventivemeasure}`]
                                  ? translationCache[`${constants.preventivemeasure}`]
                                  : `${constants.preventivemeasure}`}
                              </TextParagraph>
                              {inputList[i]['preventive'] &&
                              inputList[i]['preventive'].length > 0 ? (
                                <span style={{ color: '#60c40e' }}>
                                  <CheckCircleOutlined />
                                </span>
                              ) : null}
                            </Popover>
                            <Popover
                              content={
                                <div>
                                  <TextArea
                                    value={inputList[i]['rootcause']}
                                    rows={5}
                                    maxLength="150"
                                    onChange={(e) =>
                                      handleTextAreaChange(e, i, 'inputList', 'rootcause')
                                    }
                                  />
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        'inputList',
                                        'close',
                                        'rootcause',
                                        'rootCause'
                                      );
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Cancel')}{' '}
                                  </span>
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(i, 'inputList', 'ok', 'rootCause');
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Ok')}{' '}
                                  </span>
                                </div>
                              }
                              open={rootCause}
                              onOpenChange={(value) => handleVisibleChange(value, 'rootCause')}
                              title={
                                translationCache &&
                                translationCache[`${constants.rootcauseanalysis}`] &&
                                translationCache[`${constants.rootcauseanalysis}`]
                                  ? translationCache[`${constants.rootcauseanalysis}`]
                                  : `${constants.rootcauseanalysis}`
                              }
                              trigger="click"
                            >
                              <TextParagraph>
                                {translationCache &&
                                translationCache[`${constants.rootcauseanalysis}`]
                                  ? translationCache[`${constants.rootcauseanalysis}`]
                                  : `${constants.rootcauseanalysis}`}
                              </TextParagraph>
                              {inputList[i]['rootcause'] && inputList[i]['rootcause'].length > 0 ? (
                                <span style={{ color: '#60c40e' }}>
                                  <CheckCircleOutlined />
                                </span>
                              ) : null}
                            </Popover>
                            <Popover
                              content={
                                <div>
                                  <TextArea
                                    value={inputList[i]['recommandation']}
                                    rows={5}
                                    maxLength="150"
                                    onChange={(e) =>
                                      handleTextAreaChange(e, i, 'inputList', 'recommandation')
                                    }
                                  />
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        'inputList',
                                        'close',
                                        'recommandation',
                                        'recommandationStatus'
                                      );
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Cancel')}{' '}
                                  </span>
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        'inputList',
                                        'ok',
                                        'recommandationStatus'
                                      );
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Ok')}{' '}
                                  </span>
                                </div>
                              }
                              open={recommandationStatus}
                              onOpenChange={(value) =>
                                handleVisibleChange(value, 'recommandationStatus')
                              }
                              title={
                                translationCache && translationCache[`${constants.recomandations}`]
                                  ? translationCache[`${constants.recomandations}`]
                                  : `${constants.recomandations}`
                              }
                              trigger="click"
                            >
                              <TextParagraph>
                                {translationCache && translationCache[`${constants.recomandations}`]
                                  ? translationCache[`${constants.recomandations}`]
                                  : `${constants.recomandations}`}
                                {inputList[i]['recommandation'] &&
                                inputList[i]['recommandation'].length > 0 ? (
                                  <span style={{ color: '#60c40e' }}>
                                    <CheckCircleOutlined />
                                  </span>
                                ) : null}
                              </TextParagraph>
                            </Popover>
                          </Fragment>
                        )}
                      </ButtonBoxDiv>

                      {i === inputList.length - 1 ? (
                        <div>
                          <Row>
                            <Col span={10}>
                              <ActionSelectionDiv>
                                <BlockedLabelBlack>
                                  {this.translation(constants.action)}
                                  <span style={{ color: 'red' }}>*</span>
                                </BlockedLabelBlack>
                                <ActionCheckbox
                                  options={this.state.data}
                                  value={config.inputList[i]['action']}
                                  onChange={(checkedValues) => {
                                    checkonChange(checkedValues, 'inputList', i);
                                  }}
                                />
                                <Popover placement="top" title={'Delay'}>
                                  <TimePicker
                                    style={{ margin: '10px' }}
                                    defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                    value={
                                      config.inputList &&
                                      config.inputList[i] &&
                                      config.inputList[i]['delay'] === undefined
                                        ? moment('00:00:00', 'HH:mm:ss')
                                        : moment(
                                            config.inputList &&
                                              config.inputList[i] &&
                                              config.inputList[i]['delay'][1],
                                            'HH:mm:ss'
                                          )
                                    }
                                    onChange={(e) => handleDelay(e, 'inputList', i)}
                                    placeholder={this.translation(constants.selectDelay)}
                                    allowClear={false}
                                    size="small"
                                  />
                                </Popover>

                                {config &&
                                config.inputList &&
                                config.inputList[config.inputList.length - 1] &&
                                config.inputList[config.inputList.length - 1].cation &&
                                config.inputList[config.inputList.length - 1].action.indexOf(
                                  'RestAPI'
                                ) > -1 ? (
                                  <Input
                                    style={{ width: '55%', marginTop: '2%' }}
                                    placeholder="Enter Url"
                                    name="value"
                                    value={url}
                                    onChange={(e) => handleurl(e, 'url')}
                                  />
                                ) : null}
                              </ActionSelectionDiv>
                            </Col>
                          </Row>
                        </div>
                      ) : null}

                      {i === inputList.length - 1 ? (
                        <div>
                          <Row>
                            <Col span={10}>
                              <ActionSelectionDiv>
                                <BlockedLabelBlack>
                                  {translationCache &&
                                  translationCache[`${constants.NotificationType}`]
                                    ? translationCache[`${constants.NotificationType}`]
                                    : `${constants.NotificationType}`}
                                  {/* <span style={{ color: 'red' }}>*</span> */}
                                </BlockedLabelBlack>
                                {ruleType !== 'downTime' ? (
                                  <OnchangeCheckbox
                                    checked={onContinous}
                                    onChange={(value) => handleContinous(value)}
                                  >
                                    {translationCache &&
                                    translationCache[`${constants.onContinous}`]
                                      ? translationCache[`${constants.onContinous}`]
                                      : `${constants.onContinous}`}
                                  </OnchangeCheckbox>
                                ) : null}
                                <Popover placement="top" title={'Frequency'}>
                                  <TimePicker
                                    disabled={!onContinous}
                                    style={{ margin: '10px' }}
                                    defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                    value={
                                      config.inputList &&
                                      config.inputList[i] &&
                                      config.inputList[i]['frequency'] === undefined
                                        ? moment('00:00:00', 'HH:mm:ss')
                                        : moment(
                                            config.inputList &&
                                              config.inputList[i] &&
                                              config.inputList[i]['frequency'][1],
                                            'HH:mm:ss'
                                          )
                                    }
                                    onChange={(e) => handleFrequency(e, 'inputList', i)}
                                    placeholder={
                                      translationCache &&
                                      translationCache[`${constants.selectFrequency}`]
                                        ? translationCache[`${constants.selectFrequency}`]
                                        : `${constants.selectFrequency}`
                                    }
                                    allowClear={false}
                                    size="small"
                                  />
                                </Popover>

                                {config &&
                                config.inputList &&
                                config.inputList[config.inputList.length - 1] &&
                                config.inputList[config.inputList.length - 1].cation &&
                                config.inputList[config.inputList.length - 1].action.indexOf(
                                  'RestAPI'
                                ) > -1 ? (
                                  <Input
                                    style={{ width: '55%', marginTop: '2%' }}
                                    placeholder="Enter Url"
                                    name="value"
                                    value={url}
                                    onChange={(e) => handleurl(e, 'url')}
                                  />
                                ) : null}
                              </ActionSelectionDiv>
                            </Col>
                          </Row>
                        </div>
                      ) : null}

                      {i === inputList.length - 1 ? (
                        <div>
                          <Row>
                            <Col span={7}>
                              <BlockedLabelBlack>
                                {translationCache &&
                                translationCache[`${constants.members}`] &&
                                translationCache[`${constants.members}`]
                                  ? translationCache[`${constants.members}`]
                                  : `${constants.members}`}
                              </BlockedLabelBlack>
                              <Select
                                placeholder={this.translation('RecipientList')}
                                mode="multiple"
                                value={mailRecipients}
                                style={{ width: '100%', display: 'inline-block' }}
                                onChange={(value) => handleRecipientList(value, 'mailRecipients')}
                              >
                                {recipientList &&
                                  Array.isArray(recipientList) &&
                                  recipientList.map((item, index) => {
                                    return (
                                      <Option key={index} value={item.Email}>
                                        {item.FirstName} {item.LastName}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                    </Boxdiv>
                  );
                })
              : null}
            {/* -------------------- Else if */}
            {conelseif
              ? elsifLength.map((elsepropName, index) => {
                  return config[elsepropName].map((x, i) => {
                    return (
                      <Boxdiv key={`${index}${i}`}>
                        {i === 0 ? <BlockedLabelBlack>else if</BlockedLabelBlack> : null}
                        {i > 0 ? (
                          <ConditionSelect
                            size="default"
                            name="operater"
                            value={x['54765'] === '' ? undefined : x['54765']}
                            placeholder={
                              translationCache && translationCache[`${constants.selectCondition}`]
                                ? translationCache[`${constants.selectCondition}`]
                                : `${constants.selectCondition}`
                            }
                            onChange={(e) => handleConditionchange(e, i, elsepropName)}
                          >
                            <Option value="&&">AND</Option>
                            <Option value="||">OR</Option>
                          </ConditionSelect>
                        ) : null}

                        <ParameterSelect
                          disabled={disabledParamTypeDevice}
                          name="parameterName"
                          placeholder={this.translation(constants.ParameterType)}
                          size="default"
                          value={x.parameterName === '' ? undefined : x.parameterName}
                          onChange={(value) => handleParameterSelect(value, i, 'elsepropName')}
                        >
                          {parameterChangeList &&
                            Array.isArray(parameterChangeList) &&
                            parameterChangeList.map((parameter, i) => {
                              return (
                                <Option
                                  key={`elseifdeviceList${i}`}
                                  value={parameter.ParameterType}
                                  disabled={
                                    parameter.ParameterType === 'Operational' ? false : true
                                  }
                                >
                                  {parameter.ParameterType}
                                </Option>
                              );
                            })}
                        </ParameterSelect>
                        <ParameterSelect
                          name="selectedHierarchy"
                          onChange={(value) => handleSelectHierarchy(value, i, 'elsepropName')}
                          // onChange={this.handleSelectHierarchy}
                          placeholder={this.translation('Please select hierarchy')}
                          value={
                            x && x.HierarchyId === undefined ? hierarchyDefault : x.HierarchyId
                          }
                        >
                          {selectedHierarchy &&
                            Array.isArray(selectedHierarchy) &&
                            selectedHierarchy.map((item, i) => {
                              return (
                                <Option key={i} value={item.HierarchyCode}>
                                  {item.HierarchyName}
                                </Option>
                              );
                            })}
                        </ParameterSelect>
                        <DeviceSelect
                          placeholder={
                            translationCache && translationCache[`${constants.device}`]
                              ? translationCache[`${constants.device}`]
                              : `${constants.device}`
                          }
                          size="default"
                          value={x.device === '' ? undefined : x.device}
                          disabled={true}
                          // onChange={(value) => handledeviceSelect(value, 'inputList', i)}
                          suffixIcon={
                            <ApartmentOutlined
                              style={{
                                color: '#1a3b5c',
                                cursor:
                                  disabledParamTypeDevice && disabledParamTypeDevice === true
                                    ? 'not-allowed'
                                    : null,
                                opacity:
                                  disabledParamTypeDevice && disabledParamTypeDevice === true
                                    ? '0.3'
                                    : null
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                if (enable && enable === true ? true : false) {
                                  this.setState({
                                    treeVisible: true
                                  });
                                } else {
                                  if (inputList && inputList[i] && !inputList[i].parameterName) {
                                    this.setState({ treeVisible: false });
                                    message.error(this.translation('Please Select ParameterType'));
                                  } else {
                                    this.setState({ treeVisible: false });
                                  }
                                }
                              }}
                            />
                          }
                        >
                          {deviceList &&
                            deviceList.map((device, i) => {
                              return (
                                <Option key={`ifdeviceList${i}`} value={device.AliasCode}>
                                  {device.Name}
                                </Option>
                              );
                            })}
                        </DeviceSelect>
                        <TagNameSelect
                          showSearch
                          name="tagName"
                          size="default"
                          placeholder={
                            translationCache &&
                            translationCache[`${constants.selectparameters}`] &&
                            translationCache[`${constants.selectparameters}`]
                              ? translationCache[`${constants.selectparameters}`]
                              : `${constants.selectparameters}`
                          }
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          value={
                            x && x.device && x.tagName ? `${x.device}@@${x.tagName}` : undefined
                          }
                          onChange={(e) => handleTagSelect(e, i, 'elsepropName')}
                        >
                          {parametersList &&
                            Array.isArray(parametersList) &&
                            parametersList.map((param, j) => {
                              return (
                                <Option key={`inputTagname${i}${j}`} value={param.ParameterCode}>
                                  {param.ParameterName}
                                </Option>
                              );
                            })}
                        </TagNameSelect>
                        <OperaterSelect
                          name="operater"
                          size="default"
                          placeholder={
                            translationCache &&
                            translationCache[`${constants.selectoperator}`] &&
                            translationCache[`${constants.selectoperator}`]
                              ? translationCache[`${constants.selectoperator}`]
                              : `${constants.selectoperator}`
                          }
                          value={x.operater === '' ? undefined : x.operater}
                          onChange={(e) => handleInputChange(e, i, 'select', 'elsepropName')}
                        >
                          {Operator.map((item, index) => {
                            return (
                              <>
                                {config.parameter === 'Operational' ? (
                                  <Option key={index} value={item.value}>
                                    {item.key}
                                  </Option>
                                ) : null}
                              </>
                            );
                          })}
                          {config.parameter !== 'Operational' ? (
                            <Option value="==" title="EqualTo">
                              {`EqualTo (==)`}
                            </Option>
                          ) : null}
                        </OperaterSelect>
                        <ValueInput
                          size="default"
                          name="value"
                          maxLength={6}
                          placeholder={
                            translationCache && translationCache[`${constants.value}`]
                              ? translationCache[`${constants.value}`]
                              : `${constants.value}`
                          }
                          value={x.value}
                          onChange={(e) => handleInputChange(e, i, 'input', 'elsepropName')}
                        />
                        {/* <TagNameSelect
                        showSearch
                        name="tagName"
                        size="default"
                        placeholder={
                          translationCache && translationCache[`${constants.selectparameters}`]
                            ? translationCache[`${constants.selectparameters}`]
                            : `${constants.selectparameters}`
                        }
                        value={x.tagName === '' ? undefined : x.tagName}
                        onChange={(e) => handleTagSelect(e, i, 'elsepropName')}
                      >
                        {parametersList && Array.isArray(parametersList) && parametersList
                          // .filter((param) => param.DeviceId === x.device)
                          .map((param, j) => {
                            return (
                              <Option
                                key={`${elsepropName}Tagname${i}${j}`}
                                value={param.ParameterCode}
                              >
                                {`${param.TagID}:${param.ParameterCode}`}
                              </Option>
                            );
                          })}
                      </TagNameSelect>
                      <OperaterSelect
                        size="default"
                        value={x.operater === '' ? undefined : x.operater}
                        name="operater"
                        placeholder={
                          translationCache &&
                            translationCache[`${constants.selectoperator}`] &&
                            translationCache[`${constants.selectoperator}`]
                            ? translationCache[`${constants.selectoperator}`]
                            : `${constants.selectoperator}`
                        }
                        onChange={(e) => handleInputChange(e, i, 'select', elsepropName)}
                      >
                        <Option value="==" title="EqualTo">
                          ==
                        </Option>
                        <Option value="!=" title="Not EqualTo">
                          !=
                        </Option>
                        <Option value=">" title="Greater Than">
                          {'>'}
                        </Option>
                        <Option value="<" title="Less Than">
                          {'<'}
                        </Option>
                        <Option value=">=" title="Greater Than Or EqualTo">
                          {'>='}
                        </Option>
                        <Option value="<=" title="Less Than Or Equal To">
                          {'<='}
                        </Option>
                      </OperaterSelect>
                      <ValueInput
                        name="value"
                        size="default"
                        maxLength={6}
                        placeholder={
                          translationCache &&
                            translationCache[`${constants.value}`] &&
                            translationCache[`${constants.value}`]
                            ? translationCache[`${constants.value}`]
                            : `${constants.value}`
                        }
                        value={x.value}
                        onChange={(e) => handleInputChange(e, i, 'input', elsepropName)}
                      /> */}

                        <ButtonBoxDiv>
                          {config[elsepropName].length !== 1 && (
                            <MarginRightButton
                              size="default"
                              onClick={() => handleRemoveClick(i, elsepropName)}
                            >
                              <DeleteOutlined />
                            </MarginRightButton>
                          )}
                          {config[elsepropName].length - 1 === i && (
                            <Fragment>
                              <LogicAddButton
                                type={'primary'}
                                size="default"
                                onClick={() => handleAddClick(elsepropName, this.state.ruleType)}
                              >
                                {this.translation(constants.add)}
                              </LogicAddButton>
                              <Popover
                                content={
                                  <div>
                                    <TextArea
                                      value={config[elsepropName][i]['preventive']}
                                      rows={5}
                                      maxLength="150"
                                      onChange={(e) =>
                                        handleTextAreaChange(e, i, elsepropName, 'preventive')
                                      }
                                    />
                                    <span
                                      style={{ color: '#1890ff', cursor: 'pointer' }}
                                      onClick={() => {
                                        hanblePopoverOnclick(
                                          i,
                                          elsepropName,
                                          'close',
                                          'preventive',
                                          `previsible${elsepropName}`
                                        );
                                      }}
                                    >
                                      {' '}
                                      {this.translation('Cancel')}{' '}
                                    </span>
                                    <span
                                      style={{
                                        color: '#1890ff',
                                        cursor: 'pointer',
                                        float: 'right'
                                      }}
                                      onClick={() => {
                                        hanblePopoverOnclick(
                                          i,
                                          elsepropName,
                                          'ok',
                                          `previsible${elsepropName}`
                                        );
                                      }}
                                    >
                                      {' '}
                                      {this.translation('Ok')}{' '}
                                    </span>
                                  </div>
                                }
                                open={config[`previsible${elsepropName}`]}
                                onOpenChange={(value) =>
                                  handleVisibleChange(value, `previsible${elsepropName}`)
                                }
                                title={this.translation(constants.preventivemeasure)}
                                trigger="click"
                              >
                                <TextParagraph>
                                  {this.translation(constants.preventivemeasure)}
                                </TextParagraph>
                                {config[elsepropName][i]['preventive'] &&
                                config[elsepropName][i]['preventive'].length > 0 ? (
                                  <span style={{ color: '#60c40e' }}>
                                    <CheckCircleOutlined />
                                  </span>
                                ) : null}
                              </Popover>

                              {/* <Divider   style={{ background: "black" }} type="vertical" /> */}
                              <Popover
                                content={
                                  <div>
                                    <TextArea
                                      value={config[elsepropName][i]['rootcause']}
                                      rows={5}
                                      maxLength="150"
                                      onChange={(e) =>
                                        handleTextAreaChange(e, i, elsepropName, 'rootcause')
                                      }
                                    />
                                    <span
                                      style={{ color: '#1890ff', cursor: 'pointer' }}
                                      onClick={() => {
                                        hanblePopoverOnclick(
                                          i,
                                          elsepropName,
                                          'close',
                                          'rootcause',
                                          `elseifroot${elsepropName}`
                                        );
                                      }}
                                    >
                                      {' '}
                                      {this.translation('Cancel')}{' '}
                                    </span>
                                    <span
                                      style={{
                                        color: '#1890ff',
                                        cursor: 'pointer',
                                        float: 'right'
                                      }}
                                      onClick={() => {
                                        hanblePopoverOnclick(
                                          i,
                                          elsepropName,
                                          'ok',
                                          `elseifroot${elsepropName}`
                                        );
                                      }}
                                    >
                                      {' '}
                                      {this.translation('Ok')}{' '}
                                    </span>
                                  </div>
                                }
                                open={config[`elseifroot${elsepropName}`]}
                                onOpenChange={(value) =>
                                  handleVisibleChange(value, `elseifroot${elsepropName}`)
                                }
                                title={this.translation(constants.preventivemeasure)}
                                trigger="click"
                              >
                                <TextParagraph>
                                  {this.translation(constants.rootcauseanalysis)}
                                </TextParagraph>
                                {config[elsepropName][i]['rootcause'] &&
                                config[elsepropName][i]['rootcause'].length > 0 ? (
                                  <span style={{ color: '#60c40e' }}>
                                    <CheckCircleOutlined />
                                  </span>
                                ) : null}
                              </Popover>
                              {/* <Divider   style={{ background: "black" }} type="vertical" /> */}
                              <Popover
                                content={
                                  <div>
                                    <TextArea
                                      value={config[elsepropName][i]['recommandation']}
                                      rows={5}
                                      maxLength="150"
                                      onChange={(e) =>
                                        handleTextAreaChange(e, i, elsepropName, 'recommandation')
                                      }
                                    />
                                    <span
                                      style={{ color: '#1890ff', cursor: 'pointer' }}
                                      onClick={() => {
                                        hanblePopoverOnclick(
                                          i,
                                          elsepropName,
                                          'close',
                                          'recommandation',
                                          `elseifrootr${elsepropName}`
                                        );
                                      }}
                                    >
                                      {' '}
                                      {this.translation('Cancel')}{' '}
                                    </span>
                                    <span
                                      style={{
                                        color: '#1890ff',
                                        cursor: 'pointer',
                                        float: 'right'
                                      }}
                                      onClick={() => {
                                        hanblePopoverOnclick(
                                          i,
                                          elsepropName,
                                          'ok',
                                          `elseifrecom${elsepropName}`
                                        );
                                      }}
                                    >
                                      {' '}
                                      {this.translation('Ok')}{' '}
                                    </span>
                                  </div>
                                }
                                open={config[`elseifrecom${elsepropName}`]}
                                onOpenChange={(value) =>
                                  handleVisibleChange(value, `elseifrecom${elsepropName}`)
                                }
                                title={this.translation(constants.recomandations)}
                                trigger="click"
                              >
                                <TextParagraph>
                                  {this.translation(constants.recomandations)}
                                </TextParagraph>
                              </Popover>
                              {config[elsepropName][i]['recommandation'] &&
                              config[elsepropName][i]['recommandation'].length > 0 ? (
                                <span style={{ color: '#60c40e' }}>
                                  <CheckCircleOutlined />
                                </span>
                              ) : null}
                            </Fragment>
                          )}
                        </ButtonBoxDiv>
                        {config[elsepropName].length - 1 === i && (
                          <ActionSelectionDiv>
                            <BlockedLabelBlack>
                              {this.translation(constants.action)}
                              <span style={{ color: 'red' }}>*</span>
                            </BlockedLabelBlack>
                            <ActionCheckbox
                              options={this.state.data}
                              value={config[elsepropName][i]['action']}
                              onChange={(checkedValues) => {
                                checkonChange(checkedValues, elsepropName, i);
                              }}
                            />
                            <Popover placement="top" title={'Delay'}>
                              <TimePicker
                                style={{ margin: '10px' }}
                                defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                value={
                                  config[elsepropName][i]['delay'] === undefined
                                    ? undefined
                                    : moment(config[elsepropName][i]['delay'][1], 'HH:mm:ss')
                                }
                                onChange={(e) => handleDelay(e, elsepropName, i)}
                                placeholder={this.translation(constants.selectDelay)}
                                size="small"
                                allowClear={false}
                              />
                            </Popover>
                            <Popover placement="top" title={'Frequency'}>
                              <TimePicker
                                disabled={onContinous === true ? false : true}
                                style={{ margin: '10px' }}
                                defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                value={
                                  config[elsepropName][i]['frequency'] === undefined
                                    ? undefined
                                    : moment(config[elsepropName][i]['frequency'][1], 'HH:mm:ss')
                                }
                                onChange={(e) => handleFrequency(e, elsepropName, i)}
                                placeholder={this.translation(constants.selectFrequency)}
                                size="small"
                                allowClear={false}
                              />
                            </Popover>
                            {config &&
                            config[elsepropName][i] &&
                            config[elsepropName][i].action.indexOf('RestAPI') > -1 ? (
                              <Input
                                style={{ width: '55%', marginTop: '2%' }}
                                placeholder="Enter Url"
                                name="value"
                                value={url}
                                onChange={(e) => handleurl(e, 'url')}
                              />
                            ) : null}
                          </ActionSelectionDiv>
                        )}
                        {i === config[elsepropName].length - 1 ? (
                          <div>
                            <Row>
                              <Col span={7}>
                                <BlockedLabelBlack>
                                  {this.translation(constants.members)}
                                </BlockedLabelBlack>
                                <Select
                                  placeholder={this.translation('RecipientList')}
                                  mode="multiple"
                                  value={mailRecipients}
                                  style={{ width: '90%', display: 'inline-block' }}
                                  onChange={(value) => handleRecipientList(value, 'mailRecipients')}
                                >
                                  {recipientList &&
                                    Array.isArray(recipientList) &&
                                    recipientList.map((item, index) => {
                                      return (
                                        <Option key={index} value={item.Email}>
                                          {item.FirstName} {item.LastName}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              </Col>
                              <Col span={6}>
                                <BlockedLabelBlack>
                                  {translationCache &&
                                  translationCache[`${constants.bodymessage}`] &&
                                  translationCache[`${constants.bodymessage}`]
                                    ? translationCache[`${constants.bodymessage}`]
                                    : `${constants.bodymessage}`}
                                </BlockedLabelBlack>
                                <TextArea
                                  size="default"
                                  name="value"
                                  rows={1}
                                  value={rulebody}
                                  placeholder={this.translation(constants.text)}
                                  onChange={(e) => handleRulebody(e, 'rulebody')}
                                />
                              </Col>
                            </Row>
                          </div>
                        ) : null}
                      </Boxdiv>
                    );
                  });
                })
              : null}
            {/* -------------------- Else */}
            {conelse
              ? elseList.map((x, i) => {
                  return (
                    <Boxdiv key={`else${i}`}>
                      {i === 0 ? <BlockedLabelBlack>else</BlockedLabelBlack> : null}
                      {i > 0 ? (
                        <ConditionSelect
                          name="operater"
                          size="default"
                          value={x['54765'] === '' ? undefined : x['54765']}
                          placeholder={
                            translationCache &&
                            translationCache[`${constants.selectCondition}`] &&
                            translationCache[`${constants.selectCondition}`]
                              ? translationCache[`${constants.selectCondition}`]
                              : `${constants.selectCondition}`
                          }
                          onChange={(e) => handleConditionchange(e, i, 'elseList')}
                        >
                          <Option value="&&">AND</Option>
                          <Option value="||">OR</Option>
                        </ConditionSelect>
                      ) : null}

                      <ParameterSelect
                        disabled={disabledParamTypeDevice}
                        name="parameterName"
                        placeholder={
                          translationCache && translationCache[`${constants.ParameterType}`]
                            ? translationCache[`${constants.ParameterType}`]
                            : `${constants.ParameterType}`
                        }
                        size="default"
                        value={x.parameterName === '' ? undefined : x.parameterName}
                        onChange={(value) => handleParameterSelect(value, i, 'elsepropName')}
                      >
                        {parameterChangeList &&
                          Array.isArray(parameterChangeList) &&
                          parameterChangeList.map((parameter, i) => {
                            return (
                              <Option
                                key={`elseifdeviceList${i}`}
                                value={parameter.ParameterType}
                                disabled={parameter.ParameterType === 'Operational' ? false : true}
                              >
                                {parameter.ParameterType}
                              </Option>
                            );
                          })}
                      </ParameterSelect>
                      <ParameterSelect
                        name="selectedHierarchy"
                        onChange={(value) => handleSelectHierarchy(value, i, 'elsepropName')}
                        // onChange={this.handleSelectHierarchy}
                        placeholder={this.translation('Please select hierarchy')}
                        value={x && x.HierarchyId === undefined ? hierarchyDefault : x.HierarchyId}
                      >
                        {selectedHierarchy &&
                          Array.isArray(selectedHierarchy) &&
                          selectedHierarchy.map((item, i) => {
                            return (
                              <Option key={i} value={item.HierarchyCode}>
                                {item.HierarchyName}
                              </Option>
                            );
                          })}
                      </ParameterSelect>
                      <DeviceSelect
                        placeholder={
                          translationCache && translationCache[`${constants.device}`]
                            ? translationCache[`${constants.device}`]
                            : `${constants.device}`
                        }
                        size="default"
                        value={x.device === '' ? undefined : x.device}
                        disabled={true}
                        // onChange={(value) => handledeviceSelect(value, 'inputList', i)}
                        suffixIcon={
                          <ApartmentOutlined
                            style={{
                              color: '#1a3b5c',
                              cursor:
                                disabledParamTypeDevice && disabledParamTypeDevice === true
                                  ? 'not-allowed'
                                  : null,
                              opacity:
                                disabledParamTypeDevice && disabledParamTypeDevice === true
                                  ? '0.3'
                                  : null
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (enable && enable === true ? true : false) {
                                this.setState({
                                  treeVisible: true
                                });
                              } else {
                                if (inputList && inputList[i] && !inputList[i].parameterName) {
                                  this.setState({ treeVisible: false });
                                  message.error(this.translation('Please Select ParameterType'));
                                } else {
                                  this.setState({ treeVisible: false });
                                }
                              }
                            }}
                          />
                        }
                      >
                        {deviceList &&
                          deviceList.map((device, i) => {
                            return (
                              <Option key={`ifdeviceList${i}`} value={device.AliasCode}>
                                {device.Name}
                              </Option>
                            );
                          })}
                      </DeviceSelect>
                      <TagNameSelect
                        showSearch
                        name="tagName"
                        size="default"
                        placeholder={this.translation(constants.selectoperator)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        value={x && x.device && x.tagName ? `${x.device}@@${x.tagName}` : undefined}
                        onChange={(e) => handleTagSelect(e, i, 'elsepropName')}
                      >
                        {parametersList &&
                          Array.isArray(parametersList) &&
                          parametersList.map((param, j) => {
                            return (
                              <Option key={`inputTagname${i}${j}`} value={param.ParameterCode}>
                                {param.ParameterName}
                              </Option>
                            );
                          })}
                      </TagNameSelect>
                      <OperaterSelect
                        name="operater"
                        size="default"
                        placeholder={this.translation(constants.selectoperator)}
                        value={x.operater === '' ? undefined : x.operater}
                        onChange={(e) => handleInputChange(e, i, 'select', 'elsepropName')}
                      >
                        {Operator.map((item, index) => {
                          return (
                            <>
                              {config.parameter === 'Operational' ? (
                                <Option key={index} value={item.value}>
                                  {item.key}
                                </Option>
                              ) : null}
                            </>
                          );
                        })}
                        {config.parameter !== 'Operational' ? (
                          <Option value="==" title="EqualTo">
                            {`EqualTo (==)`}
                          </Option>
                        ) : null}
                      </OperaterSelect>
                      <ValueInput
                        size="default"
                        name="value"
                        maxLength={6}
                        placeholder={
                          translationCache && translationCache[`${constants.value}`]
                            ? translationCache[`${constants.value}`]
                            : `${constants.value}`
                        }
                        value={x.value}
                        onChange={(e) => handleInputChange(e, i, 'input', 'elsepropName')}
                      />
                      {/* <DeviceSelect
                      placeholder={
                        translationCache &&
                          translationCache[`${constants.device}`] &&
                          translationCache[`${constants.device}`]
                          ? translationCache[`${constants.device}`]
                          : `${constants.device}`
                      }
                      size="default"
                      value={x && x.device ? x.device : undefined}
                      onChange={(value) => handledeviceSelect(value, 'elseList', i)}
                    >
                      {deviceList.map((device, i) => {
                        return (
                          <Option key={`elsedeviceList${i}`} value={device.AliasCode}>
                            {device.Name}
                          </Option>
                        );
                      })}
                    </DeviceSelect>
                    <TagNameSelect
                      showSearch
                      name="tagName"
                      size="default"
                      placeholder={
                        translationCache &&
                          translationCache[`${constants.selectparameters}`] &&
                          translationCache[`${constants.selectparameters}`]
                          ? translationCache[`${constants.selectparameters}`]
                          : `${constants.selectparameters}`
                      }
                      value={x.tagName === '' ? undefined : x.tagName}
                      onChange={(e) => handleTagSelect(e, i, 'elseList')}
                    >
                      {parametersList && Array.isArray(parametersList) &&  parametersList.map((param, j) => {
                        return (
                          <Option key={`elseListTagname${i}${j}`} value={param.ParameterCode}>
                            {`${param.TagID}:${param.ParameterCode}`}
                          </Option>
                        );
                      })}

                    </TagNameSelect>
                    <OperaterSelect
                      name="operater"
                      size="default"
                      placeholder={
                        translationCache &&
                          translationCache[`${constants.selectoperator}`] &&
                          translationCache[`${constants.selectoperator}`]
                          ? translationCache[`${constants.selectoperator}`]
                          : `${constants.selectoperator}`
                      }
                      value={x.operater === '' ? undefined : x.operater}
                      onChange={(e) => handleInputChange(e, i, 'select', 'elseList')}
                    >
                      <Option value="==" title="EqualTo">
                        ==
                      </Option>
                      <Option value="!=" title="Not EqualTo">
                        !=
                      </Option>
                      <Option value=">" title="Greater Than">
                        {'>'}
                      </Option>
                      <Option value="<" title="Less Than">
                        {'<'}
                      </Option>
                      <Option value=">=" title="Greater Than Or EqualTo">
                        {'>='}
                      </Option>
                      <Option value="<=" title="Less Than Or Equal To">
                        {'<='}
                      </Option>
                    </OperaterSelect>
                    <ValueInput
                      size="default"
                      name="value"
                      maxLength={6}
                      placeholder={
                        translationCache &&
                          translationCache[`${constants.value}`] &&
                          translationCache[`${constants.value}`]
                          ? translationCache[`${constants.value}`]
                          : `${constants.value}`
                      }
                      value={x.value}
                      onChange={(e) => handleInputChange(e, i, 'input', 'elseList')}
                    />  */}
                      <ButtonBoxDiv>
                        {elseList.length !== 1 && (
                          <MarginRightButton
                            size="default"
                            onClick={() => handleRemoveClick(i, 'elseList')}
                            icon={<DeleteOutlined />}
                          >
                            <DeleteOutlined />
                          </MarginRightButton>
                        )}
                        {elseList.length - 1 === i && (
                          <Fragment>
                            <LogicAddButton
                              type={'primary'}
                              size="default"
                              onClick={() => handleAddClick('elseList', this.state.handleAddClick)}
                            >
                              {translationCache && translationCache[`${constants.add}`]
                                ? translationCache[`${constants.add}`]
                                : `${constants.add}`}
                            </LogicAddButton>
                            <Popover
                              content={
                                <div>
                                  <TextArea
                                    value={elseList[i]['preventive']}
                                    rows={5}
                                    maxLength="150"
                                    onChange={(e) =>
                                      handleTextAreaChange(e, i, 'elseList', 'preventive')
                                    }
                                  />
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        'elseList',
                                        'close',
                                        'preventive',
                                        `elseprevisible`
                                      );
                                    }}
                                  >
                                    {this.translation('Cancel')}
                                  </span>
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(i, 'elseList', 'ok', `elseprevisible`);
                                    }}
                                  >
                                    {this.translation('Ok')}
                                  </span>
                                </div>
                              }
                              open={elseprevisible}
                              onOpenChange={(value) => handleVisibleChange(value, `elseprevisible`)}
                              title={
                                translationCache &&
                                translationCache[`${constants.preventivemeasure}`]
                                  ? translationCache[`${constants.preventivemeasure}`]
                                  : `${constants.preventivemeasure}`
                              }
                              trigger="click"
                            >
                              <TextParagraph>
                                {translationCache &&
                                translationCache[`${constants.preventivemeasure}`]
                                  ? translationCache[`${constants.preventivemeasure}`]
                                  : `${constants.preventivemeasure}`}
                              </TextParagraph>
                              {elseList[i]['preventive'] && elseList[i]['preventive'].length > 0 ? (
                                <span style={{ color: '#60c40e' }}>
                                  <CheckCircleOutlined />
                                </span>
                              ) : null}
                            </Popover>
                            {/* <Divider   style={{ background: "black" }}type="vertical" /> */}
                            <Popover
                              content={
                                <div>
                                  <TextArea
                                    value={elseList[i]['rootcause']}
                                    rows={4}
                                    maxLength="150"
                                    onChange={(e) =>
                                      handleTextAreaChange(e, i, 'elseList', 'rootcause')
                                    }
                                  />
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        'elseList',
                                        'close',
                                        'rootcause',
                                        `elseroot`
                                      );
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Cancel')}{' '}
                                  </span>
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(i, 'elseList', 'ok', `elseroot`);
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Ok')}{' '}
                                  </span>
                                </div>
                              }
                              open={elseroot}
                              onOpenChange={(value) => handleVisibleChange(value, `elseroot`)}
                              title={
                                translationCache &&
                                translationCache[`${constants.rootcauseanalysis}`]
                                  ? translationCache[`${constants.rootcauseanalysis}`]
                                  : `${constants.rootcauseanalysis}`
                              }
                              trigger="click"
                            >
                              <TextParagraph>
                                {translationCache &&
                                translationCache[`${constants.rootcauseanalysis}`]
                                  ? translationCache[`${constants.rootcauseanalysis}`]
                                  : `${constants.rootcauseanalysis}`}
                              </TextParagraph>
                              {elseList[i]['rootcause'] && elseList[i]['rootcause'].length > 0 ? (
                                <span style={{ color: '#60c40e' }}>
                                  <CheckCircleOutlined />
                                </span>
                              ) : null}
                            </Popover>
                            {/* <Divider  style={{ background: "black" }}type="vertical" /> */}
                            <Popover
                              content={
                                <div>
                                  <TextArea
                                    value={elseList[i]['recommandation']}
                                    rows={4}
                                    maxLength="150"
                                    onChange={(e) =>
                                      handleTextAreaChange(e, i, 'elseList', 'recommandation')
                                    }
                                  />
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        'elseList',
                                        'close',
                                        'recommandation',
                                        `elserecommandation`
                                      );
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Cancel')}{' '}
                                  </span>
                                  <span
                                    style={{ color: '#1890ff', cursor: 'pointer', float: 'right' }}
                                    onClick={() => {
                                      hanblePopoverOnclick(
                                        i,
                                        'elseList',
                                        'ok',
                                        `elserecommandation`
                                      );
                                    }}
                                  >
                                    {' '}
                                    {this.translation('Ok')}{' '}
                                  </span>
                                </div>
                              }
                              open={elserecommandation}
                              onOpenChange={(value) =>
                                handleVisibleChange(value, `elserecommandation`)
                              }
                              title={
                                translationCache && translationCache[`${constants.recomandations}`]
                                  ? translationCache[`${constants.recomandations}`]
                                  : `${constants.recomandations}`
                              }
                              trigger="click"
                            >
                              <TextParagraph>
                                {translationCache && translationCache[`${constants.recomandations}`]
                                  ? translationCache[`${constants.recomandations}`]
                                  : `${constants.recomandations}`}
                              </TextParagraph>
                            </Popover>
                            {elseList[i]['recommandation'] &&
                            elseList[i]['recommandation'].length > 0 ? (
                              <span style={{ color: '#60c40e' }}>
                                <CheckCircleOutlined />
                              </span>
                            ) : null}
                          </Fragment>
                        )}
                      </ButtonBoxDiv>
                      {elseList.length - 1 === i && (
                        <ActionSelectionDiv>
                          <BlockedLabelBlack>
                            {translationCache && translationCache[`${constants.action}`]
                              ? translationCache[`${constants.action}`]
                              : `${constants.action}`}
                            <span style={{ color: 'red' }}>*</span>
                          </BlockedLabelBlack>
                          <ActionCheckbox
                            options={this.state.data}
                            value={config.elseList[i]['action'].map((covert) => {
                              return translationCache[covert] !== undefined
                                ? translationCache[covert]
                                : covert;
                            })}
                            onChange={(checkedValues) => {
                              checkonChange(checkedValues, 'elseList', i);
                            }}
                          />
                          <Popover placement="top" title={'Delay'}>
                            <TimePicker
                              style={{ margin: '10px' }}
                              defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                              value={
                                config.elseList[i]['delay'] === undefined
                                  ? undefined
                                  : moment(config.elseList[i]['delay'][1], 'HH:mm:ss')
                              }
                              onChange={(e) => handleDelay(e, 'elseList', i)}
                              placeholder={
                                translationCache && translationCache[`${constants.selectDelay}`]
                                  ? translationCache[`${constants.selectDelay}`]
                                  : `${constants.selectDelay}`
                              }
                              size="small"
                              allowClear={false}
                            />
                          </Popover>
                          <Popover placement="top" title={'Frequency'}>
                            <TimePicker
                              disabled={onContinous === true ? false : true}
                              style={{ margin: '10px' }}
                              defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                              value={
                                config.elseList[i]['frequency'] === undefined
                                  ? undefined
                                  : moment(config.elseList[i]['frequency'][1], 'HH:mm:ss')
                              }
                              onChange={(e) => handleFrequency(e, 'elseList', i)}
                              placeholder={
                                translationCache && translationCache[`${constants.selectFrequency}`]
                                  ? translationCache[`${constants.selectFrequency}`]
                                  : `${constants.selectFrequency}`
                              }
                              size="small"
                              allowClear={false}
                            />
                          </Popover>
                          {config &&
                          config.elseList[i] &&
                          config.elseList[i].action.indexOf('RestAPI') > -1 ? (
                            <Input
                              style={{ width: '55%', marginTop: '2%' }}
                              placeholder="Enter Url"
                              name="value"
                              value={url}
                              onChange={(e) => handleurl(e, 'url')}
                            />
                          ) : null}
                        </ActionSelectionDiv>
                      )}
                      {i === elseList.length - 1 ? (
                        <div>
                          <Row>
                            <Col span={7}>
                              <BlockedLabelBlack>
                                {translationCache && translationCache[`${constants.members}`]
                                  ? translationCache[`${constants.members}`]
                                  : `${constants.members}`}
                              </BlockedLabelBlack>
                              <Select
                                placeholder={this.translation('RecipientList')}
                                value={mailRecipients}
                                mode="multiple"
                                style={{ width: '90%', display: 'inline-block' }}
                                onChange={(value) => handleRecipientList(value, 'mailRecipients')}
                              >
                                {recipientList &&
                                  Array.isArray(recipientList) &&
                                  recipientList.map((item, index) => {
                                    return (
                                      <Option key={index} value={item.Email}>
                                        {item.FirstName} {item.LastName}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Col>
                            <Col span={5}>
                              <BlockedLabelBlack>
                                {translationCache && translationCache[`${constants.bodymessage}`]
                                  ? translationCache[`${constants.bodymessage}`]
                                  : `${constants.bodymessage}`}
                              </BlockedLabelBlack>
                              <TextArea
                                size="default"
                                name="value"
                                rows={1}
                                value={rulebody}
                                placeholder={
                                  translationCache && translationCache[`${constants.text}`]
                                    ? translationCache[`${constants.text}`]
                                    : `${constants.text}`
                                }
                                onChange={(e) => handleRulebody(e, 'rulebody')}
                              />
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                    </Boxdiv>
                  );
                })
              : null}
            {/* 
            <AddParagraph
              onClick={handleelse}
              style={{
                pointerEvents: 'none',
                opacity: '0.4'
              }}
            >
              {translationCache && translationCache[`${constants.addelse}`]
                ? translationCache[`${constants.addelse}`]
                : `${constants.addelse}`}
            </AddParagraph>
            <AddParagraph
              onClick={handleelseif}
              style={{
                pointerEvents: 'none',
                opacity: '0.4'
              }}
            >
              {translationCache && translationCache[`${constants.addelseif}`]
                ? translationCache[`${constants.addelseif}`]
                : `${constants.addelseif}`}
            </AddParagraph> */}
            <BlankDiv></BlankDiv>
          </>
        ) : null}

        <StyledModal
          open={this.state.visibleTree}
          width={800}
          closable
          title={'Select Equipment for replication'}
          okText={'Add'}
          onOk={this.onModalsOk}
          onCancel={this.onModalsCancel}
        >
          <HierarchyModal
            // value={this.state.deviceId}
            replicateDevicesList={replicateDevices}
            treeData={this.onTree(treeData)}
            translationCache={this.props.translationCache}
            Key="Replicate"
            handleDropdownChange={this.hierarchyDropdownChanged}
            // handleParentNode={this.parentNode}
          />
        </StyledModal>

        <StyledModal
          open={this.state.treeVisible}
          width={800}
          closable
          title={'Select Equipment'}
          onOk={this.onModalOk}
          onCancel={this.onModalCancel}
        >
          <HierarchyModal
            replicateDevicesList={replicateDevices}
            value={this.state.deviceId}
            treeData={treeData}
            translationCache={this.props.translationCache}
            Key="Rule"
            handleDropdownChange={this.hierarchyDropdownChange}
            // handleParentNode={this.parentNode}
          />
        </StyledModal>
      </StyledAppdiv>
    );
  }
}
