import React from 'react';
import { Menu } from 'antd';

import SubmenuComponents from './SubmenuComponents';
import './index.css';

const MenuComponent = (menuList, handeRoutingclick, AddPingItem) => {
  const FormMenu = menuList.map((menu) => {
    if (menu.routes !== undefined) {
      return SubmenuComponents(menu, handeRoutingclick, AddPingItem);
    } else {
      let { uid } = menu;
      return (
        <Menu.Item
          key={uid}
          onClick={() => {
            handeRoutingclick(menu);
          }}
        >
          {menu.name}
        </Menu.Item>
      );
    }
  });
  return FormMenu;
};

export default MenuComponent;
