import { Tree } from 'antd';
import styled from 'styled-components';

export const StyledApplication = styled.div`
  background: white;
  margin: 0px;
  padding: 30px;
  min-height: 750px;
`;

export const StyledTree = styled(Tree)`
  // background: #1a3652 !important;
  // color: white !important;
`;
