import React from 'react';
import { Tabs } from 'antd';

import { StyledDashboard, StyledTabs } from '../../Settings/Masters/styles';
import themeSettings from '../Utils/themeSettings.json';
// import { API_DEVICETYPE } from '../../../commons/api';
// import Highlighter from 'react-highlight-words';
import Attributes from '../Masters/Attributes';
import Parameter from '../Masters/Parameter';
import SubEquipments from '../Masters/SubEquipments';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import '../CSS/index.css';
import { getTranslationCache } from '../../../selectors/language';

const { TabPane } = Tabs;
class DeviceTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      presentActiveKey: 'SubEquipments'
    };
  }
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    let { presentActiveKey } = this.state;
    return (
      <StyledDashboard style={{ minHeight: window.innerHeight - 69 }}>
        <StyledTabs
          tabPosition="top"
          type="card"
          style={{ marginBottom: '30px' }}
          theme={themeSettings}
          defaultActiveKey="Parameter"
          activeKey={presentActiveKey}
          onChange={(activeKey) => {
            if (activeKey) {
              this.setState({ presentActiveKey: activeKey });
            } else {
              this.setState({ presentActiveKey: 'SubEquipments' });
            }
          }}
          className="Modules"
        >
          <TabPane tab={this.translation('SubEquipments')} key="SubEquipments">
            <SubEquipments />
          </TabPane>
          <TabPane tab={this.translation('Parameter')} key="Parameter">
            <Parameter />
          </TabPane>
          <TabPane tab={this.translation('Attributes')} key="Attributes">
            <Attributes />
          </TabPane>
        </StyledTabs>
      </StyledDashboard>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(DeviceTypes);
