import React from 'react';
import { StyledModal } from '../style';
import axios from 'axios';
import { Form, Select, Button, Tabs, message } from 'antd';
import themeSettings from '../Utils/themeSettings.json';
import localStorage from '../../../../utils/localStorage';
import HierarchyModal from '../Utils/HierarchyModal';
import { EditOutlined, UpSquareOutlined } from '@ant-design/icons';
import { MAXIMOASSET, MAXIMO } from '../../../../commons/api';
import { getList, submit } from '../Utils/FormCalls';
import { StyledTabs, StyledTable, StyledForm } from '../../../AMM/CSS/style';
import { StyledSubForm } from '../../../Analytics_Predictions/CSS/style';
import { getTranslationCache } from '../../../../selectors/language';
import { getUserProfile } from '../../../../selectors/layout';
import history from '../../../../commons/history';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
// import { List } from 'immutable';
const { TabPane } = Tabs;
const { Option } = Select;

const ApiList = {
  getDeviceHierarchyList: `${MAXIMOASSET.DeviceHierarchy}`,
  getParameterByDevice: `${MAXIMOASSET.ParameterByDevice}`,
  getInterval: `${MAXIMOASSET.IntervalList}`,
  getUpdateIntervals: `${MAXIMOASSET.UpdateIntervals}`,
  getList: `${MAXIMO.List}`,
  getEditDetails: `${MAXIMOASSET.Edit}`
};
class IntervalMasterDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      deviceTypeList: [],
      deviceList: [],
      treeVisible: false,
      selectedDevice: [],
      selectedDeviceObject: [],
      checkedValue: [],
      userProfile: props && props.userProfile ? props.userProfile : {},
      expandedKey: null,
      expanded: false,
      record:
        this.props.location && this.props.location.state ? this.props.location.state.record : {},
      dataSource: [],
      presentActiveKey: 'Master List',
      Edit: 'false'
    };
  }
  hRef = React.createRef();

  componentDidMount = async () => {
    let path = history.location.pathname.split('/rubus/Maximo/');
    let Module = path[1].split('/') && path[1].split('/')[0];
    let deviceList = await getList(ApiList.getDeviceHierarchyList, Module);
    let IntervalList = await getList(ApiList.getInterval, Module);
    let list = await getList(ApiList.getList, Module);

    this.setState({
      deviceList,
      IntervalList,
      list
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.userProfile !== prevProps.userProfile ||
      this.props.translationCache !== prevProps.translationCache
    ) {
      this.setState({
        userProfile: this.props.userProfile,
        translationCache: this.props.translationCache
      });
    }
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onFormsubmit = async () => {
    let { selectedInterval, selectedParameter, selectedDevice } = this.state;
    let path = history.location.pathname.split('/rubus/Maximo/');
    let Module = path[1].split('/') && path[1].split('/')[0];
    if (selectedParameter !== null) {
      let payload = {
        Device: selectedDevice,
        Parameter: selectedParameter,
        Interval: selectedInterval
      };
      let response = await submit(ApiList.getUpdateIntervals, payload);
      let list = await getList(ApiList.getList, Module);
      message.success(response.message);
      this.setState({ presentActiveKey: 'Master List', list });
    } else {
      alert('please select parameter');
    }
  };
  onModalsOk = async (e) => {
    let { checkedValue } = this.state;
    e.preventDefault();
    let parameterList = await this.getParameterListByDevice(checkedValue);
    this.setState({
      parameterList,
      selectedDevice: checkedValue,
      treeVisible: false
    });
  };

  OnIntervalTypeChange = (value) => {
    this.setState({ selectedInterval: value });
  };

  OnParameterTypeChange = (value) => {
    this.setState({ selectedParameter: value });
  };

  handleEdit = async (record) => {
    let editData = [];
    editData = await this.editList(record.Id);
    // let val=(editData && editData[0] &&editData[0]['DeviceAlias']);
    let parameterList = {};
    // if (editData && editData[0] && editData[0]['DeviceAlias']) {
    //   parameterList = await this.getParameterListByDevice([val]);
    // }
    this.hRef.current &&
      this.hRef.current.setFieldsValue({
        ParameterCode: editData && editData[0]['ParameterAlias'],
        Interval: editData && editData[0] && editData[0]['CronTimeInterval']
      });
    let data = {
      selectedDevice: editData && editData[0] && editData[0]['DeviceAlias'],
      ParameterCode: editData && editData[0]['ParameterAlias'],
      Interval: editData && editData[0] && editData[0]['CronTimeInterval']
    };

    this.setState({
      selectedParameter: editData && editData[0] && editData[0]['ParameterAlias'],
      selectedDevice: editData && editData[0] && editData[0]['DeviceAlias'],
      checkedValue: editData && editData[0] && editData[0]['DeviceAlias'],
      formObject: data,
      presentActiveKey: 'Master Details',
      parameterList,
      Edit: 'true'
    });
  };
  onModalCancel = () => {
    this.setState({
      treeVisible: false,
      presentActiveKey: 'Master Details'
    });
  };
  getParameterListByDevice = (AliasCode) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const ParameterTypeObject = {
      method: 'POST',
      url: `${ApiList.getParameterByDevice}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        AliasCode: AliasCode
      }
    };
    return axios(ParameterTypeObject)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  };
  editList = (Id) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const ParameterTypeObject = {
      method: 'GET',
      url: `${ApiList.getEditDetails}?SiteId=${siteId}&Id=${Id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(ParameterTypeObject)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  };

  hierarchyDropdownChange = (value) => {
    this.setState({ checkedValue: value });
  };

  render() {
    const {
      formObject,
      deviceList,
      pagination,
      list,
      parameterList,
      IntervalList,
      selectedDevice,
      checkedValue,
      presentActiveKey,
      Edit
    } = this.state;
    const columns = [
      {
        title: this.translation('Asset Device hierarchy'),
        dataIndex: 'Device',
        key: 'Device'
      },
      {
        title: this.translation('Parameter'),
        dataIndex: 'ParameterCode',
        key: 'ParameterCode'
      },
      {
        title: this.translation('Interval'),
        dataIndex: 'Interval',
        key: 'Interval'
      },
      {
        title: 'Actions',
        dataIndex: 'Actions',
        key: 'Actions',
        render: (_text, record) => (
          <Button
            className="ant-btn"
            onClick={() => this.handleEdit(record)}
            // onClick={() => this.editUsecase(record.Id)}
            size="middle"
          >
            <EditOutlined />
          </Button>
        )
      }
    ];
    return (
      <StyledSubForm theme={themeSettings} style={{ height: '1500px' }}>
        <StyledTabs
          theme={themeSettings}
          style={{ color: 'black' }}
          defaultActiveKey="Master List"
          type="card"
          activeKey={presentActiveKey}
          onChange={(activeKey) => {
            if (activeKey === 'Master List') {
              this.setState({ presentActiveKey: activeKey });
            } else {
              this.setState({
                presentActiveKey: 'Master Details',
                Edit: 'false',
                selectedDevice: [],
                checkedValue: [],
                Device: [],
                ParameterCode: [],
                formObject: {}
              });
              this.hRef.current &&
                this.hRef.current.setFieldsValue({
                  selectedDevice: [],
                  checkedValue: [],
                  Device: [],
                  ParameterCode: [],
                  Interval: '',
                  formObject: {}
                });
            }
          }}
        >
          <TabPane tab={this.translation('Master List')} key="Master List">
            <StyledTable
              pagination={pagination}
              onChange={this.handleTableChange}
              theme={themeSettings}
              style={{
                paddingTop: '20px'
              }}
              dataSource={list}
              columns={columns}
            ></StyledTable>
          </TabPane>

          <TabPane tab={this.translation('Master Details')} key="Master Details">
            <StyledForm
              name="Equipment Master"
              theme={themeSettings}
              ref={this.hRef}
              onFinish={this.onFormsubmit}
              // onFinishFailed={this.onFinishFailed}
              initialValues={formObject || {}}
              layout="horizontal"
              labelCol={{ flex: '200px' }}
              labelAlign="right"
              labelWrap
              // wrapperCol={{ flex: 1 }}
              // validateMessages={validateMessages}
            >
              <div style={{ display: 'flex', justifyContent: 'right', paddingTop: '20px' }}>
                <Form.Item>
                  <Button theme={themeSettings} type="primary" htmlType="submit">
                    {Edit && Edit === 'true' ? 'Update' : 'Submit'}
                  </Button>
                </Form.Item>
              </div>
              <Form.Item
                name={'selectedDevice'}
                label={'Asset Device hierarchy'}
                style={{
                  width: '100%',
                  flex: '100%'
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                  }}
                >
                  <Select
                    style={{
                      width: '75%',
                      opacity: 1,
                      pointerEvents: 'none'
                    }}
                    value={selectedDevice}
                    mode="multiple"
                    placeholder={'Select Asset Device hierachy'}
                    size="default"
                  >
                    {deviceList &&
                      Array.isArray(Object.keys(deviceList)) &&
                      Object.keys(deviceList).map((device, index) => {
                        return (
                          <Option key={index} value={deviceList[device]}>
                            {deviceList[device].Name}
                          </Option>
                        );
                      })}
                  </Select>
                  <Button
                    style={{ width: '5%' }}
                    type="primary"
                    onClick={() => {
                      this.setState({ treeVisible: true });
                    }}
                    disabled={Edit && Edit === 'true' ? true : false}
                  >
                    <UpSquareOutlined />
                  </Button>
                </span>
              </Form.Item>
              <Form.Item
                label="Select Parameter"
                name="ParameterCode"
                rules={[{ required: false }]}
                style={{
                  width: '100%',
                  flex: '100%',
                  paddingTop: '10px'
                }}
              >
                <Select
                  placeholder="Please Select Parameter"
                  style={{ width: '80%' }}
                  onChange={this.OnParameterTypeChange}
                  mode={'multiple'}
                  disabled={Edit && Edit === 'true' ? true : false}
                >
                  {parameterList &&
                    Array.isArray(parameterList) &&
                    parameterList.map((item, index) => {
                      return (
                        <Option key={index} value={item.ParameterAlias}>
                          {item.ParameterAlias}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Interval"
                name="Interval"
                rules={[{ required: true, message: 'Please Select Interval!' }]}
                style={{
                  width: '100%',
                  flex: '100%',
                  paddingTop: '10px'
                }}
              >
                <Select
                  placeholder="Please Select Interval "
                  style={{ width: '80%' }}
                  onChange={this.OnIntervalTypeChange}
                >
                  {IntervalList &&
                    Array.isArray(IntervalList) &&
                    IntervalList.map((item, index) => {
                      return (
                        <Option key={index} value={item.Id}>
                          {item.TimeInterval}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <StyledModal
                open={this.state.treeVisible}
                width={800}
                closable
                title={'Select Asset Device hierachy'}
                okText={'Add'}
                onOk={this.onModalsOk}
                onCancel={this.onModalCancel}
              >
                <HierarchyModal
                  Edit={this.state.Edit}
                  checkedArray={checkedValue ? checkedValue : []}
                  treeData={deviceList}
                  Key="Interval"
                  handleDropdownChange={this.hierarchyDropdownChange}
                />
              </StyledModal>
            </StyledForm>
          </TabPane>
        </StyledTabs>
      </StyledSubForm>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});

export default connect(mapStateToProps)(IntervalMasterDetails);
