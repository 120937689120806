import localStorage from '../../../utils/localStorage';
import axios from 'axios';
import { message } from 'antd';
import { GETUSERLIST, API_MASTERCONFIGURATION, REALTIMEPREDICTION } from '../../../commons/api';

export async function getMetaData(api, param, Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${api}?${param}=${Id}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
export async function getList(api) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
export async function getDeployHistory(api, Parameter, Id) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${api}?SiteId=${siteId}&${Parameter}=${Id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
export async function getById(api, id, key) {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'GET',
    url: `${api}?${key}=${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
export async function RegisterModel(api, payload) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
export async function DeployModel(api, payload) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.data && err.response.data.message) {
        message.info(err.response.data.message);
      }
    });
}
export async function createPredictionRequests(api1, payload1, payload2) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${api1}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload1
  };
  return axios(Object)
    .then((response1) => {
      if (response1.data && response1.data.PredReqId) {
        const Object = {
          method: 'POST',
          url: `${api1}?SiteId=${siteId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: payload2
        };
        return axios(Object)
          .then((response2) => {
            return {
              response1: response1 && response1.data ? response1.data : '',
              response2: response2 && response2.data ? response2.data : ''
            };
          })
          .catch((err) => {
            message.info(err && err.response && err.response.data && err.response.data.message);
          });
      }
    })
    .catch((err) => {
      if (err && err.response && err.response.data && err.response.data.message) {
        message.info(err.response.data.message);
      }
    });
}
export async function deleteById(api, id, key) {
  const accessToken = localStorage.get('accessToken');
  const Object = {
    method: 'DELETE',
    url: `${api}?${key}=${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}

export async function getSubTypeList(api, payload) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}

export async function getTypeList(api) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err.response.data.message);
    });
}

export async function submitDataSource(api, payload) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${api}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.data && err.response.data.message) {
        message.info(err.response.data.message);
      }
    });
}
/**this function purpose is to get recipient or user list for mail ID purpose */
export async function recipientList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const recipientListApi = {
    method: 'GET',
    url: `${GETUSERLIST.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(recipientListApi)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}

export async function getCommunicationList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const deciceListApi = {
    method: 'POST',
    url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      MasterCode: 'Notification_Types',
      ActionType: 'read',
      Type: 'WithSiteId',
      Conditions: [
        {
          key: 'SiteId',
          value: siteId
        },
        {
          key: 'Module',
          value: 'Analytics'
        }
      ]
    }
  };
  return axios(deciceListApi)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
export async function RealTimeGraphData(payload) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'POST',
    url: `${REALTIMEPREDICTION.RealTimeGraph}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: payload
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      message.info(err && err.response && err.response.data && err.response.data.message);
    });
}
export async function DeleteMasters(api, Id) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const parameterGroup = {
    method: 'DELETE',
    url: `${api}?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: Authorization
    }
  };

  return axios(parameterGroup)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return {
        data: {}
      };
    });
}
