import React from 'react';
import { withRouter } from 'react-router';

import EquipmentMasterRoute from '../../../routes/equipmentMasterRoute';

import styles from '../../../commons/styles';

const { StyledWrapper, StyledWrapperRight } = styles.layout;
class EquipmentManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      tabPosition: 'left'
    };
  }
  render() {
    return (
      <StyledWrapper style={{ minHeight: window.innerHeight - 140 }}>
        {/* <StyledWrapperLeft >
            <Menu
          onClick={this.handleClick}
          mode="inline"
        >
          {/* <Menu.Item key='specs' onClick={() => { history.push('/rubus/EquipmentManagement/Specifications') }}> 
          <Icon type='audit' />Equipment Specifications </Menu.Item>
          <Menu.Item key='parts' onClick={() => { history.push('/rubus/EquipmentManagement/Parts') }}> 
          <Icon type='reconciliation' /> Parts Management</Menu.Item>
          <Menu.Item key='events' onClick={() => { history.push('/rubus/EquipmentManagement/Events') }}> 
          <Icon type='schedule' /> Events Management</Menu.Item>
         <Menu.Item key='overview'  onClick={() => { history.push('/rubus/EquipmentManagement/EquipmentOverview') }} > 
         <Icon type='cluster' /> Equipment Overview</Menu.Item> */}
        {/* <Menu.Item key='details' > <Icon type='profile' /> Equipment Details</Menu.Item> */}
        {/* <Menu.Item key='status' onClick={() => { history.push('/rubus/EquipmentManagement/StatusMaster') }}> <Icon type='profile' /> Status Master </Menu.Item> */}
        {/* <Menu.Item key='' onClick={() => { history.push('/rubus/EquipmentManagement/Specifications') }}> <Icon type='profile' /> Specifications </Menu.Item> */}
        {/* </Menu>
        </StyledWrapperLeft > */}
        <StyledWrapperRight style={{ background: '#214972', margin: '1px 15px 13px 15px' }}>
          <EquipmentMasterRoute />
        </StyledWrapperRight>
      </StyledWrapper>
    );
  }
}
export default withRouter(EquipmentManagement);
