import React from 'react';
import { Form, Input, message, Button, Table, Tabs, Drawer, Upload, Select, Modal } from 'antd';
import { StyledComponent } from '../../CustomerAssetDeviceConfiguration/CSS/style';
import { StyledButton, StyledTabs, DrawerFooter } from '../../../commons/styles/layout';
import {
  SearchOutlined,
  EditOutlined,
  UploadOutlined,
  DownloadOutlined,
  // DeleteOutlined,
  // QuestionCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import axios from 'axios';
import themeSettings from '../Utils/themeSettings.json';
import localStorage from '../../../utils/localStorage';
import history from '../../../commons/history';
import {
  UpdateMasters,
  CreateMasters,
  getModulesFileDownloadName,
  getDeviceTypeFileDownloadName
} from '../APICalls';
import { API_MODULES, IPAddress, API_RUBUSMASTERS } from '../../../commons/api';
import Highlighter from 'react-highlight-words';
import { createStructuredSelector } from 'reselect';
import { getTranslationCache } from '../../../selectors/language';
import { connect } from 'react-redux';
import '../CSS/index.css';
// import { tableTranslation } from '../../Adapter/TranslationAdapter';
const ApiList = {
  CreateModule: `${API_MODULES.ModulesCreate}`,
  UpdateModule: `${API_MODULES.ModulesUpdate}`
};
const { TabPane } = Tabs;
const { Option } = Select;
class Mod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      formData: {},
      list: [],
      form: 'modules',
      presentActiveKey: 'List',
      visibleParent: false,
      ParameterList: [],
      modalVisible: false,
      visiblePrameter: false
      // translationCache
    };
  }
  hForm = React.createRef();
  pForm = React.createRef();

  componentDidMount() {
    this._getList();
    this.DeviceTypeApi();
    this.getAllDeviceTypeFileDownloadName();
    this.getFileDownloadName();
  }

  getAllDeviceTypeFileDownloadName = async () => {
    let fileDTDownloadName = await getDeviceTypeFileDownloadName();
    this.setState({
      fileDTDownloadName:
        fileDTDownloadName && fileDTDownloadName.file ? fileDTDownloadName.file : ''
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  _getList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let url = '';
    url = `/api/rubusMaster/modules/get?Status=Active&SiteId=${siteId}`;

    let orgHeaders = {
      method: 'GET',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        this.setState({ list: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  DeviceTypeApi = () => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    const type = {
      method: '',
      url: `${API_RUBUSMASTERS.DT_GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(type)
      .then((response) => {
        this.setState({
          ModuleCodes: response.data
        });
      })
      .catch(() => {});
  };
  _editRecord = (record) => {
    // let { presentActiveKey } = this.state;
    this.hForm.current && this.hForm.current.setFieldsValue(record);
    this.setState({
      visibleParent: true,
      formObject: record,
      visible: true
    });
  };
  editRecord = (record) => {
    // let { presentActiveKey } = this.state;
    this.pForm.current && this.pForm.current.setFieldsValue(record);
    this.setState({
      visiblePrameter: true,
      formData: record
      // visible: true
    });
  };

  OnNextClick = (record) => {
    history.push(`/rubus/CustomerAssetDevice/DeviceTypes/${record.Code}`);
  };
  // deleteRecord = async (id) => {
  //   // await deleteModules(id);
  //   this._getList(this.props && this.props.match && this.props.match.params.module);
  // };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          //maxLength={30}
        />

        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  onFinish = async () => {
    let { formObject } = this.state;
    this.hForm.current &&
      this.hForm.current
        .validateFields()
        .then(async (values) => {
          let payload = {
            ...values
          };

          if (formObject && formObject.Id) {
            let UpdateResponse = await UpdateMasters(ApiList.UpdateModule, payload, formObject.Id);
            message.success(UpdateResponse && UpdateResponse.message);
          } else {
            let createResponse = await CreateMasters(ApiList.CreateModule, payload);
            message.success(createResponse && createResponse.message);
          }
          this._getList();
          this.getFileDownloadName();
          this.hForm.current &&
            this.hForm.current.setFieldsValue({
              Code: null,
              Name: null,
              Status: 'Active'
            });
          this.setState({ formObject: {}, visibleParent: false });
        })
        .catch((error) => {
          console.log(error);
        });
  };
  OpenDrawer = () => {
    this.hForm.current &&
      this.hForm.current.setFieldsValue({
        Code: null,
        Name: null,
        Status: 'Active'
      });
    let formObject = {
      Code: null,
      Name: null,
      Status: 'Active'
    };
    this.setState({ visibleParent: true, formObject });
  };
  onClose = () => {
    this.hForm.current &&
      this.hForm.current.setFieldsValue({
        Code: null,
        Name: null,
        Status: 'Active'
      });
    this.setState({ visibleParent: false, formObject: {} });
  };

  getFileDownloadName = async () => {
    let fileDownloadName = await getModulesFileDownloadName();
    this.setState({
      fileDownloadName: fileDownloadName && fileDownloadName.file ? fileDownloadName.file : ''
    });
  };

  handleChange = async (value, key) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      [key]: value
    };
    if (this.workOrderRef && this.workOrderRef.current) {
      this.workOrderRef.current.setFieldsValue(formObject);
    }
    this.setState({ [key]: value, formObject });
  };
  bulkupload = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let url = '';
    url = `/api/rubusMaster/upload/mastersBulkUpload?SiteId=${siteId}`;

    let orgHeaders = {
      method: 'Post',
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(orgHeaders)
      .then((response) => {
        message.success(response.data.message);
      })
      .catch(function (error) {
        console.log(error);
      });
    this.setState({ modalVisible: true });
  };
  handleModalConfirm = () => {
    this.setState({ modalVisible: false });
  };
  handleModalCancel = () => {
    this.setState({ modalVisible: false });
  };
  // beforeUpload = (file) => {
  //   this.setState({modalVisible: true,file})
  //   const isLt2M = file.size / 1024 / 1024 < 10;
  //   const isCSV = file.type === 'text/csv';
  //   if (!isCSV) {
  //     message.error('You can only upload CSV file!');
  //   }
  //   if (!isLt2M) {
  //     message.error('CSV file must be smaller than 10MB!');
  //   }
  //   return isLt2M && isCSV;

  // }
  render() {
    let { list, formObject, visibleParent, fileDownloadName, fileDTDownloadName } = this.state;
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    let colum = [
      { key: 'S No', value: 'S No' },
      { key: 'Module Code', value: 'Code' },
      { key: 'Module Name', value: 'Name' },
      { key: 'Status', value: 'Status' }
    ];

    let columns =
      colum && Array.isArray(colum)
        ? colum

            // .filter((col) => col !== 'Id' && col !== 'SiteId' && col !== 'AliasCode')
            .map((field) => {
              return {
                title: field.key,
                dataIndex: field.value,
                key: field.value,
                ...this.getColumnSearchProps(field.value)
              };
            })
        : [];

    // let translationTable = tableTranslation(
    //   columns,
    //   this.props.translationCache,
    //   this.props.language
    // );

    let finalColumns = [
      ...columns,
      {
        title: this.translation('Actions'),
        dataIndex: 'Actions',
        key: 'Actions',
        render: (_text, record) => (
          <span>
            <Button
              className="ant-btn"
              icon={<EditOutlined />}
              onClick={() => this._editRecord(record)}
              size="middle"
            />
            {/* <Popconfirm
              title="Are you sure that you want to delete"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this.deleteRecord(record.Id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="ant-btn" size="middle" style={{ marginLeft: '20px' }}>
                <span>
                  {' '}
                  <DeleteOutlined />
                </span>
              </Button>
            </Popconfirm> */}
            <Button
              className="ant-btn"
              onClick={() => this.OnNextClick(record)}
              size="middle"
              type="primary"
              style={{ marginLeft: '10px' }}
            >
              {this.translation('Next')}
            </Button>
          </span>
        )
      }
    ];
    return (
      <StyledComponent
        theme={themeSettings}
        style={{ padding: '0px 30px', minHeight: window.innerHeight - 114 }}
      >
        <Modal
          className="ServerRegistrationModal"
          closable={false}
          title={null}
          visible={this.state.modalVisible}
          onOk={this.handleModalConfirm}
          cancelText={this.translation('No')}
          okText={this.translation('Yes')}
          onCancel={this.handleModalCancel}
        >
          Are you sure that you want to upload all masters
        </Modal>
        <StyledTabs
          tabPosition="top"
          type="card"
          style={{ marginBottom: '30px' }}
          theme={themeSettings}
          className="Modules"
        >
          <TabPane tab={this.translation('Modules')} key="Modules">
            <div style={{ display: 'flex', justifyContent: 'right', padding: '20px' }}>
              <StyledButton>
                <Upload
                  multiple={false}
                  style={{ marginRight: '10px', color: 'white' }}
                  onChange={(info) => {
                    if (info.file.status === 'done') {
                      message.success(info.file.response.message);
                      this.getAllDeviceTypeFileDownloadName();
                    } else if (info.file.status === 'error') {
                      message.error(info.file.response.message);
                    }
                  }}
                  action={`/api/rubusMaster/upload/deviceTypes?SiteId=${siteId}`}
                  headers={{
                    Authorization: Authorization
                  }}
                  showUploadList={false}
                >
                  <UploadOutlined
                    style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                  />
                  <span style={{ color: 'white', marginRight: '10px' }}>
                    {' '}
                    {this.translation('Upload devicetypes for all modules')}
                  </span>
                </Upload>
              </StyledButton>
              <StyledButton>
                <a href={`${IPAddress.LOCAL}${fileDTDownloadName}`}>
                  <DownloadOutlined
                    style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                  />
                  <span style={{ color: 'white', marginRight: '10px' }}>
                    {this.translation('Download devicetypes for all modules')}
                  </span>
                </a>
              </StyledButton>
              <StyledButton>
                <a href={`${IPAddress.LOCAL}${fileDownloadName}`}>
                  <DownloadOutlined
                    style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                  />
                  <span style={{ color: 'white', marginRight: '10px' }}>
                    {this.translation('Download')}
                  </span>
                </a>
              </StyledButton>
              <StyledButton>
                <Upload
                  multiple={false}
                  style={{ marginRight: '10px', color: 'white' }}
                  onChange={(info) => {
                    if (info.file.status === 'done') {
                      message.success(info.file.response.message);
                      // this.getParameterList();
                      this.getFileDownloadName();
                      this._getList();
                    } else if (info.file.status === 'error') {
                      message.error(info.file.response.message);
                    }
                  }}
                  action={`/api/rubusMaster/upload/modules?SiteId=${siteId}`}
                  headers={{
                    Authorization: Authorization
                  }}
                  showUploadList={false}
                >
                  <UploadOutlined
                    style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                  />
                  <span style={{ color: 'white', marginRight: '10px' }}>
                    {' '}
                    {this.translation('Upload')}
                  </span>
                </Upload>
              </StyledButton>
              <StyledButton onClick={this.OpenDrawer}>
                <PlusOutlined /> {this.translation('Add Modules')}
              </StyledButton>
              {list && Array.isArray(list) && list.length === 0 ? (
                <StyledButton>
                  <Upload
                    multiple={false}
                    style={{ marginLeft: '10px', color: 'white' }}
                    onChange={(info) => {
                      if (info.file.status === 'done') {
                        message.success(info.file.response.message);
                        // this.getParameterList();
                        this.getFileDownloadName();
                        this._getList();
                      } else if (info.file.status === 'error') {
                        message.error(info.file.response.message);
                      }
                    }}
                    action={`/api/rubusMaster/upload/mastersBulkUpload?SiteId=${siteId}`}
                    headers={{
                      Authorization: Authorization
                    }}
                    showUploadList={false}
                  >
                    <UploadOutlined
                      style={{ color: 'white', fontSize: '16px', marginRight: '1px' }}
                    />
                    <span style={{ color: 'white', marginRight: '10px' }}>
                      {' '}
                      {this.translation('Upload All Masters')}
                    </span>
                  </Upload>
                </StyledButton>
              ) : null}
            </div>
            <Table
              className="basictable"
              columns={finalColumns}
              dataSource={list}
              pagination={
                list.length > 10
                  ? {
                      pageSize: '10'
                    }
                  : false
              }
            />
          </TabPane>
        </StyledTabs>

        <Drawer
          title={
            formObject && formObject.Id
              ? this.translation('Update Modules')
              : this.translation('Add Modules')
          }
          placement="right"
          width={600}
          closable
          onClose={this.onClose}
          visible={visibleParent}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            // theme={themeSettings}
            layout="horizontal"
            ref={this.hForm}
            name="Site_form"
            initialValues={formObject}
            onFinish={this.onFinish}
          >
            {/* <Form.Item name="Id"></Form.Item> */}
            <Form.Item
              label={this.translation('Code')}
              name="Code"
              rules={[{ required: true, message: this.translation('Please enter Code !') }]}
            >
              <Input
                disabled={formObject && formObject.Id ? true : false}
                placeholder={this.translation('Please enter Code')}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Name')}
              name="Name"
              rules={[{ required: true, message: this.translation('Please enter Name !') }]}
            >
              <Input placeholder={this.translation('Please enter Name')} />
            </Form.Item>
            <Form.Item
              label={this.translation('Status')}
              name="Status"
              rules={[{ required: true, message: this.translation('Please select Status') }]}
            >
              <Select disabled={true}>
                <Option value="Active">{'Active'}</Option>
              </Select>
            </Form.Item>
            {/* <Form.Item
            label="AliasCode"
            name="AliasCode"
            rules={[{ required: true, message: 'Please enter AliasCode' }]}
          >
            <Input />
          </Form.Item> */}
          </Form>
          <DrawerFooter>
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              {this.translation('Cancel')}
            </Button>
            <Button type="primary" onClick={this.onFinish}>
              {this.translation('Submit')}
            </Button>
          </DrawerFooter>
        </Drawer>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(Mod);
