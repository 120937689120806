import React, { Fragment } from 'react';
import _ from 'lodash';
import { flattenDeep } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import axios from 'axios';
import FileBase64 from './Utils/FileUpload';
import { Button, Drawer, Form, Tree, Input, message, Tabs, Table, Tooltip, Select } from 'antd';
import { DownOutlined, EditOutlined, PaperClipOutlined, SearchOutlined } from '@ant-design/icons';

import { constant } from '../../../Utils/constants';
import translation from '../../../Utils/translation';
import hierarchy from './Utils/hierarchy.json';
import localStorage from '../../../../../utils/localStorage';
import {
  AddItemDiv,
  DrawerFooter,
  HomeSubMenu,
  StyledComponent,
  StyledForm,
  StyledTabs
} from './CSS/style';
import themeSettings from './Utils/themeSettings.json';
import { StyledTree } from '../../../CSS/style';
import InputComponent from '../../../Widgets/InputComponent';
import { getTranslationCache } from '../../../../../selectors/language';
import {
  _getDeviceTypesList,
  _saveAssetData,
  _saveDeviceData,
  _updateAssetData,
  _updateDeviceData,
  getSitesList
} from './APICalls';
import Highlighter from 'react-highlight-words';
import { getDefaultHierarchy } from '../../../../../selectors/layout';
import { API_ASSET, API_HIERARCHYMODULE } from '../../../../../commons/api';
const { TabPane } = Tabs;
const { Option } = Select;
const fileDataParent = new FormData();
const fileDataChild = new FormData();
class AssetDeviceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editPObject: {},
      treeData: {},
      hierarchy: hierarchy || {},
      expandedKeys: [],

      visibleAsset: false,
      assetEditObj: {},

      visibleDevice: false,
      deviceEditObj: {},
      SearchValue: ''
    };
  }

  assetPRef = React.createRef();
  assetRef = React.createRef();
  deviceRef = React.createRef();
  componentDidMount = async () => {
    let siteList = await getSitesList();
    let deviceTypeList = await _getDeviceTypesList();
    this.getHierarchyLevels();
    this.setState({ siteList, deviceTypeList });
  };

  getHierarchyLevels = () => {
    let { defaultHiearchy } = this.props;
    let payload = {
      HierarchyCode:
        defaultHiearchy && defaultHiearchy.HierarchyCode ? defaultHiearchy.HierarchyCode : '',
      Type: 'Asset',
      HierarchyType: 'AMM'
    };
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${API_HIERARCHYMODULE.GetHierarchyLevels}?SiteId=${siteId}&HierarchyCode=${payload.HierarchyCode}&Type=${payload.Type}&HierarchyType=${payload.HierarchyType}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          this.expandAll(data);
          this.setState({
            treeData: data
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  expandAll = async (treeData) => {
    let expandedKeys = await this.getAllKeys(treeData);
    this.setState({
      expandedKeys
    });
  };

  getAllKeys = (data) => {
    // This function makes an array of keys, this is specific for this example, you would have to adopt for your case. If your list is dynamic, also make sure that you call this function everytime data changes.
    const nestedKeys = data.map((node) => {
      let childKeys = [];
      if (node.children) {
        childKeys = this.getAllKeys(node.children);
      }
      return [childKeys, node.key];
    });
    return flattenDeep(nestedKeys);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          //maxLength={30}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {this.translation(constant.search)}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation(`Reset`)}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };

  getData = (Id, Type) => {
    const SiteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    let headers = {
      method: 'GET',
      url: `${API_ASSET.GETDEVICEANDSUBLOCATIONBYId}?Id=${Id}&Type=${Type}&SiteId=${SiteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then(({ data, message }) => {
        let formObject = data;
        if (formObject) {
          this.setState({
            formObject
          });
        } else {
          message.error(message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  getParent = (Name, ParentId, treeData) => {
    let firstLevelParent = {};
    let children = [];
    let secondLevelParent = [];
    for (let i = 0; i < treeData.length; i++) {
      const node = treeData[i];
      if (node.children) {
        if (node.children.some((item) => item.Id === ParentId)) {
          firstLevelParent = node;
          children = node.children;
          if (children) {
            for (let i = 0; i < children.length; i++) {
              const childNode = children[i];
              if (childNode.children && childNode.children.length > 0) {
                if (childNode.Id === ParentId) {
                  secondLevelParent = childNode;
                }
              }
            }
          }
          if (this.props.handleParentNode && this.props.handleParentNode !== undefined) {
            this.props.handleParentNode(firstLevelParent, secondLevelParent);
          }
        } else if (this.getParent(Name, ParentId, node.children)) {
          this.getParent(Name, ParentId, node.children);
        }
      }
    }
    return firstLevelParent;
  };

  renderTreeNodes = (data) => {
    let { SearchValue } = this.state;

    return (
      data &&
      Array.isArray(data) &&
      data.map((item) => {
        const stringTitle =
          `${item.DisplayCode ? this.translation(item.DisplayCode) : ''}` +
          `(${item.Name ? this.translation(item.Name) : ''})`;

        const index = stringTitle.indexOf(SearchValue);
        const beforeString = stringTitle.substring(0, index);
        const afterString = stringTitle.slice(index + SearchValue.length);

        if (item.children && item.children.length > 0) {
          return (
            <Tree.TreeNode
              // title={
              //   <div>
              //     {`
              //     ${item.key ? ` ${this.translation(item.key)}` : ''}
              //     ${item.Name ? ` - (${this.translation(item.Name)})` : ''}
              //  `}
              //   </div>
              // }
              title={
                index > -1 ? (
                  <span>
                    {beforeString}
                    <span style={{ color: '#ffb039' }}>{SearchValue}</span>
                    {/* <span style={{ color: '#ffb039' }}>{(SearchValue)=>this.get(SearchValue)}</span> */}
                    {afterString}
                  </span>
                ) : (
                  <span>{stringTitle}</span>
                )
              }
              key={item.Id}
              dataRef={item}
            >
              {this.renderTreeNodes(item.children, item)}
            </Tree.TreeNode>
          );
        }
        return (
          <Tree.TreeNode
            key={item.key}
            title={
              <div>
                {index > -1 ? (
                  <span>
                    {beforeString}
                    <span style={{ color: '#ffb039' }}>{SearchValue}</span>

                    {afterString}
                  </span>
                ) : (
                  <span>{stringTitle}</span>
                )}
              </div>
            }
            dataRef={item}
          />
        );
      })
    );
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      SearchValue: value
    });
  };

  openPDrawer = () => {
    this.setState({
      editPObject: {},
      visibleParent: true
    });
  };
  onClose = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      if (this.assetPRef.current) this.assetPRef.current.resetFields();
      this.setState({ visibleParent: false, editPObject: {} });
    }
  };
  onCloseAsset = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      if (this.assetRef.current) this.assetRef.current.resetFields();
      this.setState({ visibleAsset: false, assetEditObj: {} });
    }
  };
  onCloseDevice = (e) => {
    if (e.target.tagName === 'DIV') {
      e.preventDefault();
      message.error('Please Click On Cancel');
    } else {
      if (this.deviceRef.current) this.deviceRef.current.resetFields();
      this.setState({ visibleDevice: false, deviceEditObj: {} });
    }
  };
  savePAsset = (e) => {
    e.preventDefault();
    let { fileDataParent, editPObject } = this.state;
    const siteId = localStorage.get('currentSite');
    this.assetPRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...values,
          AssetType: 'location',
          ParentAsset: null,
          SiteId: siteId,
          ...(editPObject && editPObject['Attachments']
            ? { Attachments: editPObject['Attachments'] }
            : {})
        };
        let saveResponse = await _saveAssetData(values, fileDataParent);
        if (saveResponse && saveResponse.message) {
          message.success(`Parent Location Created successfully`);
          if (this.assetPRef.current) this.assetPRef.current.resetFields();
          this.setState({ visibleParent: false, editPObject: {} });
          this.getHierarchyLevels();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fileuploadParent = async (values, key, fileDataObject) => {
    let editPObject = this.assetPRef.current.getFieldsValue();
    if (editPObject[key]) {
      editPObject[key].push(...values);
    } else {
      editPObject[key] = values;
    }
    editPObject[key] =
      editPObject[key] &&
      editPObject[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    editPObject = {
      ...editPObject,
      [key]: editPObject[key]
    };
    fileDataParent.append('files', fileDataObject);
    if (this.assetPRef.current) this.assetPRef.current.setFieldsValue(editPObject);
    this.setState({ editPObject, fileDataParent });
  };

  fileuploadChild = async (values, key, fileDataObject) => {
    let assetEditObj = this.assetRef.current.getFieldsValue();
    if (assetEditObj[key]) {
      assetEditObj[key].push(...values);
    } else {
      assetEditObj[key] = values;
    }
    assetEditObj[key] =
      assetEditObj[key] &&
      assetEditObj[key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    assetEditObj = {
      ...assetEditObj,
      [key]: assetEditObj[key]
    };
    fileDataChild.append('files', fileDataObject);
    if (this.assetRef.current) this.assetRef.current.setFieldsValue(assetEditObj);
    this.setState({ assetEditObj, fileDataChild });
  };

  saveAsset = (e) => {
    e.preventDefault();
    let { fileDataChild, formObject } = this.state;
    const SiteId = localStorage.get('currentSite');
    this.assetRef.current
      .validateFields()
      .then(async (values) => {
        delete values.Id;
        values = {
          ...values,
          AssetType: 'subLocation',
          ParentAsset: formObject && formObject.AssetCode ? formObject.AssetCode : '',
          SiteId
        };
        let saveResponse = await _saveAssetData(values, fileDataChild);
        if (saveResponse && saveResponse.message) {
          message.success(saveResponse.message);
          this.setState({ visibleAsset: false, assetEditObj: {} });
          if (this.assetRef.current) this.assetRef.current.resetFields();
          this.getData(formObject.Id, formObject.Type);
          this.getHierarchyLevels();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateAsset = (e) => {
    e.preventDefault();
    let { formObject, fileDataChild, assetEditObj } = this.state;
    const SiteId = localStorage.get('currentSite');
    this.assetRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...values,
          AssetType: 'subLocation',
          ParentAsset: formObject && formObject.AssetCode ? formObject.AssetCode : '',
          SiteId,
          AssetCode: assetEditObj.AssetCode
        };
        let updateResponse = await _updateAssetData(values, fileDataChild);
        if (updateResponse && updateResponse.message) {
          message.success(updateResponse.message);
          this.setState({ visibleAsset: false, assetEditObj: {} });
          if (this.assetRef.current) this.assetRef.current.resetFields();
          this.getData(formObject.Id, formObject.Type);
          this.getHierarchyLevels();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  saveDevice = (e) => {
    e.preventDefault();
    let { formObject } = this.state;
    const SiteId = localStorage.get('currentSite');
    this.deviceRef.current &&
      this.deviceRef.current
        .validateFields()
        .then(async (values) => {
          delete values.Id;
          values = {
            ...values,
            EquipmentType: 'Parent',
            TimeSeries: false,
            Asset: formObject && formObject.AssetCode ? formObject.AssetCode : '',
            SiteId
          };
          let saveResponse = await _saveDeviceData(values);
          if (saveResponse && saveResponse.message) {
            message.success(saveResponse.message);
            this.setState({ visibleDevice: false, deviceEditObj: {} });
            if (this.deviceRef.current) this.deviceRef.current.resetFields();
            this.getData(formObject.Id, formObject.Type);
            this.getHierarchyLevels();
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  updateDevice = (e) => {
    e.preventDefault();
    let { formObject, deviceEditObj } = this.state;
    const SiteId = localStorage.get('currentSite');
    this.deviceRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...values,
          //   ParentEquipment: data.parentDeviceId,
          TimeSeries: false,
          Asset: formObject && formObject.AssetCode ? formObject.AssetCode : '',
          SiteId,
          DeviceCode: deviceEditObj.DeviceCode
        };
        let updateResponse = await _updateDeviceData(values);

        if (updateResponse && updateResponse.Id) {
          message.success('Successfully Updated');
          this.setState({ visibleDevice: false, deviceEditObj: {} });
          if (this.deviceRef.current) this.deviceRef.current.resetFields();
          this.getData(formObject.Id, formObject.Type);
          this.getHierarchyLevels();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys
    });
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  render() {
    let {
      treeData,
      expandedKeys,
      editPObject,
      deviceTypeList,
      visibleParent,
      formObject,
      visibleAsset,
      assetEditObj,
      deviceEditObj,
      visibleDevice
    } = this.state;

    // const validateMessages = {
    //     required: '${label} is required!',
    //     types: {
    //         email: '${label} is not a valid email!',
    //         number: '${label} is not a valid number!'
    //     },
    //     number: {
    //         range: "${label} must be between ${min} and ${max}"
    //     },
    //     pattern: {
    //       mismatch: '${label} does not match pattern'
    //     }
    // };
    let { translationCache } = this.props;
    const onSelect = (selectedKeys, info) => {
      this.getData(
        info &&
          info['selectedNodes'] &&
          info['selectedNodes'][0] &&
          info['selectedNodes'][0]['dataRef'] &&
          info['selectedNodes'][0]['dataRef']['Id']
          ? info['selectedNodes'][0]['dataRef']['Id']
          : '',
        info &&
          info['selectedNodes'] &&
          info['selectedNodes'][0] &&
          info['selectedNodes'][0]['dataRef'] &&
          info['selectedNodes'][0]['dataRef']['Type']
          ? info['selectedNodes'][0]['dataRef']['Type']
          : ''
      );
      this.setState({ selectedKeys });
    };
    return (
      <Fragment>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '50px 30px', minHeight: window.innerHeight - 250 }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div
              style={{
                borderRight: '1px dashed white',
                width: '100%',
                flex: '25%',
                overflowY: 'auto',
                height: window.innerHeight - 215
              }}
            >
              <Input
                style={{ width: '50%', flex: '50%', marginBottom: '2%' }}
                placeholder="Search here"
                prefix={<SearchOutlined />}
                onChange={(e) => this.onChange(e)}
              />

              <StyledTree
                className="treeTheme"
                onSelect={onSelect}
                onExpand={this.onExpand}
                expandedKeys={expandedKeys}
                showLine
                defaultExpandAll={true}
                switcherIcon={<DownOutlined />}
              >
                {this.renderTreeNodes(treeData)}
              </StyledTree>
            </div>

            <div
              style={{
                flex: '65%',
                paddingLeft: '17px'
              }}
            >
              <AddItemDiv>
                <div></div>
                <div>
                  <Button type="primary" onClick={this.openPDrawer}>
                    {this.translation(`Add Parent Location`)}
                  </Button>
                </div>
              </AddItemDiv>

              <Drawer
                title={this.translation(`Add Parent Location`)}
                placement="right"
                width={400}
                closable
                onClose={this.onClose}
                visible={visibleParent}
              >
                <Form
                  layout="vertical"
                  ref={this.assetPRef}
                  name="user_form1"
                  initialValues={editPObject || {}}
                >
                  <Form.Item
                    label={this.translation(`Location Name`)}
                    name={'AssetCode'}
                    style={{ width: 300 }}
                    rules={[
                      {
                        required: true,
                        message: this.translation(`Input Location Name`)
                      },
                      {
                        message: 'field does not accept special characters'
                      }
                    ]}
                  >
                    <Input placeholder={this.translation(`Input Location Name`)} />
                  </Form.Item>
                  <Form.Item
                    label={this.translation(`Location Description`)}
                    name={'Name'}
                    style={{ width: 300 }}
                    rules={[
                      {
                        required: true,
                        message: this.translation(`Input Location Description`)
                      },
                      {
                        message: 'field does not accept special characters'
                      }
                    ]}
                  >
                    <Input placeholder={this.translation(`Input Location Description`)} />
                  </Form.Item>

                  <Form.Item
                    style={{ flex: '50%' }}
                    name={'Attachments'}
                    label={
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                          {this.translation(constant.attachments)}
                          <PaperClipOutlined className="plusCircleOutlined" />
                        </span>
                      </div>
                    }
                  >
                    <FileBase64
                      downloadClassName={'formdownloadInput'}
                      style={{ width: 300 }}
                      Attachments={editPObject.Attachments}
                      fileUpload={this.fileuploadParent}
                    ></FileBase64>
                  </Form.Item>
                </Form>

                <DrawerFooter>
                  <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                    {this.translation('Cancel')}
                  </Button>
                  <Button type="primary" onClick={this.savePAsset}>
                    {this.translation(`Create`)}
                  </Button>
                </DrawerFooter>
              </Drawer>

              <StyledForm
                name="Item"
                theme={themeSettings}
                initialValues={formObject}
                layout="horizontal"
                labelCol={{ flex: '130px', marginTop: '20px' }}
                labelAlign="right"
                labelWrap
                // validateMessages={validateMessages}
              >
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Form.Item
                    style={{ width: '50%', flex: '50%' }}
                    label={translation(translationCache, constant.Location)}
                  >
                    <InputComponent
                      style={{ width: '90%' }}
                      value={
                        formObject ? translation(translationCache, formObject.DisplayCode) : ''
                      }
                      disabled
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ width: '50%', flex: '50%' }}
                    label={translation(translationCache, constant.LocationDescription)}
                  >
                    <InputComponent
                      value={formObject ? translation(translationCache, formObject.Name) : ''}
                      style={{ width: '90%' }}
                      disabled
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ width: '50%', flex: '50%' }}
                    label={translation(translationCache, constant.ParentLocation)}
                  >
                    <InputComponent
                      style={{ width: '90%' }}
                      value={
                        formObject && formObject.ParentDisplayCode
                          ? translation(translationCache, formObject.ParentDisplayCode)
                          : ''
                      }
                      disabled
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ width: '50%', flex: '50%' }}
                    label={translation(translationCache, constant.ParentLocationDescription)}
                  >
                    <InputComponent
                      value={
                        formObject && formObject.ParentLocationDescription
                          ? translation(translationCache, formObject.ParentLocationDescription)
                          : ''
                      }
                      style={{ width: '90%' }}
                      disabled
                    />
                  </Form.Item>
                </div>
              </StyledForm>

              <StyledTabs
                tabPosition="top"
                type="card"
                style={{
                  marginTop: '20px',
                  color: 'white',
                  background: '#1a3652'
                }}
                theme={themeSettings}
              >
                <TabPane
                  tab={
                    <HomeSubMenu>{translation(translationCache, 'Child Locations')}</HomeSubMenu>
                  }
                  key={'childLocation'}
                >
                  <AddItemDiv>
                    <div></div>
                    <div>
                      <Button
                        type="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            assetEditObj: {},
                            visibleAsset: true
                          });
                          if (this.assetRef.current) this.assetRef.current.resetFields();
                        }}
                      >
                        {this.translation('Add Child Location')}
                      </Button>
                    </div>
                  </AddItemDiv>
                  <div>
                    <Table
                      dataSource={
                        formObject &&
                        formObject.ChildrenLocations &&
                        Array.isArray(formObject.ChildrenLocations)
                          ? formObject.ChildrenLocations
                          : []
                      }
                      size="large"
                      pagination={
                        formObject &&
                        formObject.ChildrenLocations &&
                        Array.isArray(formObject.ChildrenLocations) &&
                        formObject.ChildrenLocations.length > 10
                          ? {
                              pageSize: '10'
                            }
                          : false
                      }
                      columns={[
                        {
                          title: this.translation(`${_.startCase(_.toLower('Location'))} Name`),
                          dataIndex: 'DisplayCode',
                          key: 'DisplayCode',
                          ...this.getColumnSearchProps(
                            'DisplayCode',
                            this.translation(`${_.startCase(_.toLower('Location'))} Name`)
                          )
                        },
                        {
                          title: this.translation(
                            `${_.startCase(_.toLower('Location'))} Description`
                          ),
                          dataIndex: 'Name',
                          key: 'Name',
                          ...this.getColumnSearchProps(
                            'Name',
                            this.translation(`${_.startCase(_.toLower('Location'))} Description`)
                          )
                        },
                        {
                          title: this.translation('Action'),
                          dataIndex: 'Action',
                          key: 'Action',
                          render: (text, row) => {
                            return {
                              children: (
                                <div>
                                  <Tooltip title={this.translation('Edit')}>
                                    <EditOutlined
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          assetEditObj: row,
                                          visibleAsset: true
                                        });

                                        if (this.assetRef.current)
                                          this.assetRef.current.setFieldsValue(row);
                                      }}
                                    />
                                  </Tooltip>

                                  {/* <Divider type="vertical" />
                                  <Popconfirm
                                    title={this.translation('Are you sure delete Location?')}
                                    onConfirm={(e) => {
                                      e.stopPropagation();
                                      this._deleteAssetData(row);
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Tooltip title={this.translation('Delete')}>
                                      <DeleteOutlined
                                        theme="twoTone"
                                        twoToneColor="red"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      />
                                    </Tooltip>
                                  </Popconfirm> */}
                                </div>
                              )
                            };
                          }
                        }
                      ]}
                    />
                    <Drawer
                      title={
                        !assetEditObj.Id
                          ? this.translation(`Create ${_.startCase(_.toLower('Location'))}`)
                          : this.translation(`Update ${_.startCase(_.toLower('Location'))}`)
                      }
                      placement="right"
                      width={400}
                      closable
                      onClose={this.onCloseAsset}
                      visible={visibleAsset}
                    >
                      <Form
                        layout="vertical"
                        ref={this.assetRef}
                        name="user_form"
                        initialValues={assetEditObj || {}}
                      >
                        <Form.Item
                          label={this.translation(`Location Name`)}
                          name={'DisplayCode'}
                          style={{ width: 300 }}
                          rules={[
                            {
                              required: true,
                              message: this.translation(`Input Location Name`)
                            },
                            {
                              message: 'field does not accept special characters'
                            }
                          ]}
                        >
                          <Input
                            placeholder={this.translation(`Input Location Name`)}
                            disabled={assetEditObj && assetEditObj.Id ? true : false}
                          />
                        </Form.Item>
                        <Form.Item
                          label={this.translation(`Location Description`)}
                          name={'Name'}
                          style={{ width: 300 }}
                          rules={[
                            {
                              required: true,
                              message: this.translation(`Input Location Description`)
                            },
                            {
                              message: 'field does not accept special characters'
                            }
                          ]}
                        >
                          <Input placeholder={this.translation(`Input Location Description`)} />
                        </Form.Item>
                        <Form.Item
                          style={{ flex: '50%' }}
                          name="Attachments"
                          label={
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <span>
                                {this.translation(constant.attachments)}
                                <PaperClipOutlined className="plusCircleOutlined" />
                              </span>
                            </div>
                          }
                        >
                          <FileBase64
                            downloadClassName={'formdownloadInput'}
                            style={{ width: 300 }}
                            Attachments={assetEditObj.Attachments}
                            fileUpload={this.fileuploadChild}
                          ></FileBase64>
                        </Form.Item>
                        <Form.Item
                          label="Id"
                          display="none"
                          name="Id"
                          hidden={true}
                          initialValue={assetEditObj.Id || ''}
                        >
                          <Input style={{ display: 'none' }} />
                        </Form.Item>
                        <Form.Item name="AliasCode"></Form.Item>
                      </Form>

                      <DrawerFooter>
                        <Button onClick={this.onCloseAsset} style={{ marginRight: 8 }}>
                          {this.translation('Cancel')}
                        </Button>
                        {!assetEditObj.Id ? (
                          <Button type="primary" onClick={this.saveAsset}>
                            {this.translation(`Create Location`)}
                          </Button>
                        ) : (
                          <Button type="primary" onClick={this.updateAsset}>
                            {this.translation(`Update Location`)}
                          </Button>
                        )}
                      </DrawerFooter>
                    </Drawer>
                  </div>
                </TabPane>
                <TabPane
                  tab={<HomeSubMenu>{translation(translationCache, 'Equipments')}</HomeSubMenu>}
                  key={'Equipments'}
                >
                  <div>
                    <AddItemDiv>
                      <div></div>
                      <Button
                        type="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            deviceEditObj: {},
                            visibleDevice: true
                          });
                          if (this.deviceRef.current) this.deviceRef.current.resetFields();
                        }}
                      >
                        {translation(translationCache, 'Add Equipment')}
                      </Button>
                    </AddItemDiv>
                  </div>
                  <div>
                    <Table
                      columns={[
                        {
                          title: translation(
                            translationCache,
                            `${_.startCase(_.toLower('Equipment'))} Name`
                          ),
                          dataIndex: 'DisplayCode',
                          key: 'DisplayCode',
                          ...this.getColumnSearchProps(
                            'DisplayCode',
                            translation(
                              translationCache,
                              `${_.startCase(_.toLower('Equipment'))} Name`
                            )
                          )
                        },
                        {
                          title: translation(
                            translationCache,
                            `${_.startCase(_.toLower('Equipment'))} Description`
                          ),
                          dataIndex: 'Name',
                          key: 'Name',
                          ...this.getColumnSearchProps(
                            'Name',
                            translation(
                              translationCache,
                              `${_.startCase(_.toLower('Equipment'))} Description`
                            )
                          )
                        },
                        {
                          title: translation(translationCache, 'Action'),
                          dataIndex: 'Action',
                          key: 'Action',
                          render: (text, row) => {
                            return {
                              children: (
                                <div>
                                  <Tooltip title="Edit">
                                    <EditOutlined
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          deviceEditObj: row,
                                          visibleDevice: true
                                        });

                                        if (this.deviceRef.current)
                                          this.deviceRef.current.setFieldsValue(row);
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              )
                            };
                          }
                        }
                      ]}
                      dataSource={
                        formObject && formObject.Equipments && Array.isArray(formObject.Equipments)
                          ? formObject.Equipments
                          : []
                      }
                      size="large"
                      pagination={
                        formObject &&
                        formObject.Equipments &&
                        Array.isArray(formObject.Equipments) &&
                        formObject.Equipments.length > 10
                          ? {
                              pageSize: '10'
                            }
                          : false
                      }
                    />
                    <Drawer
                      title={
                        !deviceEditObj.Id
                          ? translation(translationCache, `Create Equipment`)
                          : translation(translationCache, `Update Equipment`)
                      }
                      placement="right"
                      width={400}
                      closable
                      onClose={this.onCloseDevice}
                      visible={visibleDevice}
                    >
                      <Form
                        layout="vertical"
                        ref={this.deviceRef}
                        name="user_form"
                        initialValues={deviceEditObj || {}}
                      >
                        <Form.Item
                          label={this.translation(`Equipment Name`)}
                          name={'DisplayCode'}
                          style={{ width: 300 }}
                          rules={[
                            {
                              required: true,
                              message: this.translation(`Input Equipment Name`)
                            },
                            {
                              message: 'field does not accept special characters'
                            }
                          ]}
                        >
                          <Input
                            placeholder={this.translation(`Input Equipment Name`)}
                            disabled={deviceEditObj && deviceEditObj.Id ? true : false}
                          />
                        </Form.Item>
                        <Form.Item
                          label={this.translation(`Equipment Description`)}
                          name={'Name'}
                          style={{ width: 300 }}
                          rules={[
                            {
                              required: true,
                              message: this.translation(`Input Equipment Description`)
                            },
                            {
                              message: 'field does not accept special characters'
                            }
                          ]}
                        >
                          <Input placeholder={this.translation(`Input Equipment Description`)} />
                        </Form.Item>
                        <Form.Item
                          label={this.translation(`Equipment Category`)}
                          name={'DeviceType'}
                          style={{ width: 300 }}
                          placeholder={this.translation(`Equipment Category`)}
                          rules={[
                            {
                              required: true,
                              message: this.translation(`Equipment Category`)
                            }
                          ]}
                        >
                          <Select
                            placeholder={this.translation('Select Equipment Category')}
                            defaultValue={deviceEditObj ? deviceEditObj.DeviceType : ''}
                            style={{ width: 300 }}
                            onChange={this.handleChange}
                          >
                            {deviceTypeList
                              ? deviceTypeList.map((deviceType, index) => (
                                  <Option key={index} value={deviceType.AliasCode}>
                                    {deviceType.Name}
                                  </Option>
                                ))
                              : null}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{
                            width: '100%',
                            flex: '50%'
                          }}
                          label={translation(translationCache, constant.EquipmentDisplayCode)}
                          name="DisplayCode"
                          rules={[{ required: true }]}
                        >
                          <InputComponent
                            style={{ width: 300 }}
                            placeholder={translation(
                              this.props.translationCache,
                              constant.InputDisplayCode
                            )}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Id"
                          display="none"
                          name="Id"
                          hidden={true}
                          initialValue={deviceEditObj.Id || ''}
                        >
                          <Input style={{ display: 'none' }} />
                        </Form.Item>
                        <Form.Item name="EquipmentType"></Form.Item>
                      </Form>

                      <DrawerFooter>
                        <Button onClick={this.onCloseDevice} style={{ marginRight: 8 }}>
                          {this.translation(`Cancel`)}
                        </Button>
                        {!deviceEditObj.Id ? (
                          <Button type="primary" onClick={this.saveDevice}>
                            {this.translation(`Create Equipment`)}
                          </Button>
                        ) : (
                          <Button type="primary" onClick={this.updateDevice}>
                            {translation(translationCache, `Update Equipment`)}
                          </Button>
                        )}
                      </DrawerFooter>
                    </Drawer>
                  </div>
                </TabPane>
              </StyledTabs>
            </div>
          </div>
        </StyledComponent>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  defaultHiearchy: getDefaultHierarchy()
});

export default connect(mapStateToProps)(AssetDeviceComponent);
