import styled from 'styled-components';
export const DrawerFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
`;
export const StyledDashboard = styled.div`
  background: white;
  // margin: 20px;
  padding: 30px;
`;
