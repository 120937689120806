import React from 'react';
import axios from 'axios';
import { Row, Col, Badge } from 'antd';

import localStorage from '../../../../../utils/localStorage';
import history from '../../../../../commons/history';

import './landingpage.css';
import { LANDINGPAGE } from '../../../../../commons/api';

const colStyle = {
  color: 'white',
  margin: '5px',
  padding: '5px',
  float: 'left',
  display: 'block',
  borderRadius: '10px',
  width: '6%',
  boxShadow: '5px 5px 30px #333333'
};

const titleStyle = {
  color: 'black',
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
  // padding:'5px',
  borderBottom: '2px dotted black',
  marginBottom: '10px'
};

class TrainStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: {},
      activeCrane: [],
      legendsList: {
        'No Communication': '#a6acaf',
        'Depot Aux Off': '#3397da',
        'Depot Aux On': '#ffc300',
        'Main Line': '#099c0c'
      },
      reportName: ''
    };
  }

  timeTicket = null;
  // signal = axios.CancelToken.source();
  requestDeviceCheck = true;
  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this._getdeviceList('RS');

    this.timeTicket = setInterval(() => {
      if (this.requestDeviceCheck) {
        // Request Pending status check for Device Stauts
        this._getdeviceList('RS');
      }
    }, 1 * 10000);
  }

  componentWillUnmount() {
    // this.signal.cancel('Api is being canceled');
    clearInterval(this.timeTicket);
  }

  _getdeviceList = (module) => {
    this.requestDeviceCheck = false;
    let siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    if (!siteId) {
      siteId = 'test';
    }
    const headers = {
      method: 'POST',
      url: `${LANDINGPAGE.LandingPageDeviceStatus}?SiteId=${siteId}&ModuleCode=${module}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    axios(headers)
      .then((response) => {
        if (response.data) {
          this.setState({ deviceList: response.data });
          this.requestDeviceCheck = true;
        }
      })
      .catch(() => {
        return {};
      });
  };

  onCraneImageClick = (craneObject) => {
    history.push(`/rubus/HMI/distinctByParent/RS/${craneObject.deviceAlias}`);
  };

  render() {
    let { deviceList, legendsList } = this.state;
    return (
      <div style={{ backgroundColor: '#243a4f', minHeight: window.innerHeight - 57 }}>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          {legendsList &&
            Object.entries(legendsList).map(([key, value]) => {
              return (
                <div key={value}>
                  <Badge color={value} />
                  <span style={{ marginRight: '10px', color: 'white', fontSize: '16px' }}>
                    {key}
                  </span>
                </div>
              );
            })}
        </div>
        <div style={{ marginBottom: '5px', marginLeft: '4%' }}></div>

        {deviceList &&
          Array.isArray(deviceList) &&
          deviceList.map(({ Zone, ZoneColor, ListOfDevices, index }) => {
            return (
              <Row key={index} className={Zone}>
                <Col span={24}>
                  <h3
                    style={{
                      marginTop: '10px',
                      color: 'white',
                      textAlign: 'center',
                      fontSize: '18px',
                      fontWeight: 'bold'
                    }}
                  >
                    {Zone}
                  </h3>
                  <div
                    style={{
                      height: '10px',
                      margin: '10px 22px',
                      // width: '100%',
                      background: ZoneColor
                    }}
                  ></div>
                  <div style={{ marginLeft: '40px' }}>
                    {ListOfDevices &&
                      Array.isArray(ListOfDevices) &&
                      ListOfDevices.map((key, index) => {
                        return (
                          <Col
                            key={index}
                            className="colRunning"
                            style={{
                              ...colStyle,
                              backgroundColor: key && key.color ? key.color : ''
                            }}
                            onClick={() => this.onCraneImageClick(key)}
                            width={'7%'}
                          >
                            <div style={titleStyle}>{key.deviceName} </div>
                            <div className={'stop123'}></div>
                          </Col>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            );
          })}
      </div>
    );
  }
}

export default TrainStatus;
