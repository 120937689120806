import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getCurrentTimezone, getUserProfile } from '../../selectors/layout';

import { StyledMain } from './Utils/CSS/styles';
import FilterComponent from './FilterComponent';
import DataComponent from './DataComponent';

class Trends extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: [],
      allFilterData: [],
      OperatorList: [],
      ObservationList: []
    };
  }
  getOperatorList = (OperatorList) => {
    this.setState({ OperatorList });
  };
  getFilterationData = (filterData, allFilterData) => {
    this.setState({
      filterData,
      allFilterData
    });
  };
  getObservationList = (ObservationList) => {
    this.setState({ ObservationList });
    // this.props.ObservationList(ObservationList)
  };

  render() {
    let { filterData, allFilterData, OperatorList, ObservationList } = this.state;
    console.log('filterData', filterData);
    return (
      <StyledMain style={{ minHeight: window.innerHeight - 73 }}>
        <FilterComponent
          generateData={this.getFilterationData}
          OperatorList={this.getOperatorList}
          ObservationList={this.getObservationList}
          obList={ObservationList}
        />
        <DataComponent
          FilterData={filterData}
          AllFilterData={allFilterData}
          OperatorList={OperatorList}
          ObservationList={this.getObservationList}
        />
      </StyledMain>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(Trends);
