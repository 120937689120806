/* eslint-disable array-callback-return */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Highlighter from 'react-highlight-words';
import ReactEcharts from '../../Utils/Echarts/Echarts';
// import {DatePicker} from 'antdv2'
import {
  Button,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Switch,
  Tabs,
  Tree,
  message,
  DatePicker,
  Space,
  Image,
  Tooltip,
  Col,
  Row,
  Card,
  Select,
  Typography,
  InputNumber,
  Drawer
  // Popover,
  // TimePicker
} from 'antd';
import {
  DownOutlined,
  LineChartOutlined,
  // QuestionCircleOutlined,
  SearchOutlined,
  // FormOutlined,
  SwapOutlined
} from '@ant-design/icons';
import {
  StyledComponent,
  // StyledDrawer,
  StyledForm,
  StyledModal,
  StyledSubForm,
  StyledTable,
  StyledTabs,
  StyledBox,
  StyledBoxLabel,
  StyleModal
  // DatePickerSlider
} from '../../CSS/style';
import {
  getMetaData,
  RegisterModel,
  DeployModel,
  getDeployHistory,
  getList,
  recipientList,
  getCommunicationList,
  RealTimeGraphData
} from '../../Utils/FormCalls';
import themeSettings from '../../Utils/themeSettings.json';
import { getUserProfile } from '../../../../selectors/layout';
// import { constant } from '../../Utils/Constants';
import {
  RBSPREDMODEL,
  RBSLOOKUPMaster,
  API_TRENDS,
  GETUSERLIST,
  REALTIMEPREDICTION,
  MASTERDATASOURCE,
  IPAddress
} from '../../../../commons/api';
import history from '../../../../commons/history';
// import translation from '../../Utils/translation';
import '../../CSS/index.css';
import { getTranslationCache } from '../../../../selectors/language';
import TrendSlider from './TrendSlider';
import FunctionalSummary from './FunctionalSummary';
import { actionList } from '../../../RuleEngine/utils/const';
import { ActionCheckbox } from '../../CSS/style';
import RealTimeGraph from './RealTimeGraph';
// import { faListSquares } from '@fortawesome/free-solid-svg-icons';
const { TabPane } = Tabs;
const { TreeNode } = Tree;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title } = Typography;
const color = [
  'orange',
  'tomato',
  'steelblue',
  'green',
  'pink',
  'violet',
  'yellow',
  'red',
  'blue',
  'grey',
  'orange',
  'tomato',
  'steelblue',
  'green',
  'pink',
  'violet',
  'yellow',
  'red',
  'blue',
  'grey'
];
const ApiList = {
  getData: `${RBSPREDMODEL.ReadMetadata}`,
  insert: `${RBSPREDMODEL.RegisterModel}`,
  deployModel: `${IPAddress.PREDICTIONIP}/dep_reqs/create_request`,
  getDeployHistory: `${RBSPREDMODEL.DeployemtReqlist}`,
  getMetrics: `${RBSLOOKUPMaster.LookUpItemsGetById}`,
  updateDefaultStatus: `${IPAddress.PREDICTIONIP}/dep_reqs/update_default`,
  predictionRequest: `${IPAddress.PREDICTIONIP}/pred_reqs/create_request`,
  getPredictionHistory: `${RBSPREDMODEL.PredictionReqlist}`,
  getMetricResults: `${RBSPREDMODEL.MetricpRedList}`,
  getRealTimeMetricResults: `${IPAddress.PREDICTIONIP}/realtime/metric_calc`,
  getPredictionResults: `${RBSPREDMODEL.PredictionResList}`,
  getTagsByModelId: `${RBSPREDMODEL.RelatedTagsValuesList}`,
  getParameterByDevice: `${RBSPREDMODEL.DeviceParameters}`,
  generateTrend: `${API_TRENDS.LIST}`,
  getImageList: `${RBSPREDMODEL.PredictImages}`,
  getUsersList: `${GETUSERLIST.GET}`,
  functionalSummaryForMonthly: `${RBSPREDMODEL.FunctionalSummaryWeek}`,
  functionalSummaryForDaily: `${RBSPREDMODEL.FunctionalSummaryDayWise}`,
  realTimeFunctionalSummaryForMonthly: `${REALTIMEPREDICTION.RealTimeFunctionalSummaryWeek}`,
  realTimeFunctionalSummaryForDaily: `${REALTIMEPREDICTION.RealTimeFunctionalSummaryDayWise}`,
  getDataSourceList: `${MASTERDATASOURCE.DatasourceList}`,
  realTimeCreate: `${IPAddress.PREDICTIONIP}/realtime/create_request`,
  realTimeStop: `${IPAddress.PREDICTIONIP}/pm2/stop_pm2`,
  getRealTimePredList: `${REALTIMEPREDICTION.RealTimePredictionList}`,
  getRealTimePredictionResults: `${REALTIMEPREDICTION.RealTimePredictionResList}`,
  realTimeConditionsCreate: `${REALTIMEPREDICTION.Create}`,
  getAnalyticsActionsLog: `${REALTIMEPREDICTION.GetAnalyticsActionLogs}`,
  actionsGetById: `${REALTIMEPREDICTION.Get}`,
  getQuickrunResults: `${REALTIMEPREDICTION.GetQuickRunResult}`
};
const IntervalData = [
  {
    key: '1Min',
    value: '1m'
  },
  {
    key: '15Min',
    value: '15m'
  },
  {
    key: '30Min',
    value: '30m'
  },
  {
    key: '60Min',
    value: '1h'
  },
  {
    key: 'Day',
    value: '1d'
  }
];
class ModelDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      modelVisible: false,
      metricResultVisible: false,
      disableGraph: false,

      // AddConditionFormDisable: true,
      addCondition: false,
      Status: 'Inactive',
      usersList: [],
      ModelDeployHistoryData: [],
      deployModelVisible: false,
      modelDeploymentInfo: {},
      userProfile: props && props.userProfile ? props.userProfile : {},
      ModelPredHistoryData: [],
      momentRange: [
        moment(moment().subtract(60, 'minute').format('YYYY-MM-DD HH:mm:ss')),
        moment(moment().add(15, 'minute').format('YYYY-MM-DD HH:mm:ss'))
      ],
      selectionDateRange: {
        startDate: moment().subtract(60, 'minute').format('YYYY-MM-DD HH:mm'),
        endDate: moment().add(15, 'minute').format('YYYY-MM-DD HH:mm'),
        timeBucket: '1m'
      },
      // functionalSummaryFrom:moment().subtract(60, 'minute').format('YYYY-MM-DD HH:mm'),
      // functionalSummaryTo:moment().add(15, 'minute').format('YYYY-MM-DD HH:mm'),
      // OnValueChangeDisable: true,
      predictionCLFResultColumns: [
        {
          title: this.translation('Current Time'),
          dataIndex: 'CurrentTime',
          hide: true,
          key: 'CurrentTime',
          ...this.getColumnSearchProps('CurrentTime'),
          sorter: (a, b) =>
            a.CurrentTime && b.CurrentTime && a.CurrentTime.localeCompare(b.CurrentTime),
          render: (text) => {
            return {
              children:
                text !== undefined ? (
                  <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
                ) : null
            };
          }
        },
        {
          title: this.translation('Predicted From'),
          dataIndex: 'predTimeFrom',
          key: 'predTimeFrom',
          ...this.getColumnSearchProps('predTimeFrom'),
          sorter: (a, b) =>
            a.predTimeFrom && b.predTimeFrom && a.predTimeFrom.localeCompare(b.predTimeFrom),
          render: (text) => {
            return {
              children:
                text !== undefined ? (
                  <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
                ) : null
            };
          }
        },
        {
          title: this.translation('Predicted To'),
          dataIndex: 'predTimeTo',
          key: 'predTimeTo',
          ...this.getColumnSearchProps('predTimeTo'),
          sorter: (a, b) =>
            a.predTimeTo && b.predTimeTo && a.predTimeTo.localeCompare(b.predTimeTo),
          render: (text) => {
            return {
              children:
                text !== undefined ? (
                  <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
                ) : null
            };
          }
        },

        {
          title: this.translation('Predicted Status'),
          dataIndex: 'PredictedStatus',
          key: 'PredictedStatus',
          filters: [
            {
              text: this.translation('NORMAL'),
              value: 'NORMAL'
            },
            {
              text: this.translation('ABNORMAL'),
              value: 'ABNORMAL'
            }
          ],
          onFilter: (value, record) => record.PredictedStatus === value
        },
        {
          title: this.translation('Actual Status'),
          dataIndex: 'ActualStatus',
          key: 'ActualStatus',
          filters: [
            {
              text: this.translation('NORMAL'),
              value: 'NORMAL'
            },
            {
              text: this.translation('ABNORMAL'),
              value: 'ABNORMAL'
            }
          ],
          onFilter: (value, record) => record.ActualStatus === value
        },
        {
          title: this.translation('View Trends'),
          render: (record) => (
            <Tooltip title={this.translation('Trends')}>
              <Button
                data-testid={'lift-hold-button'}
                style={{ marginRight: '5px' }}
                className="ant-btn"
                onClick={() => {
                  let trendObj = this.trendRef.current && this.trendRef.current.getFieldsValue();
                  let CurrentTime = moment.utc(record.CurrentTime, 'YYYY-MM-DD HH:mm:ss');
                  let fromDate = moment(CurrentTime)
                    .subtract(1, 'hours')
                    .format('YYYY-MM-DD HH:mm:ss');
                  let toDate = moment(CurrentTime).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
                  let defaultParameter = [];
                  this.state.ParameterList &&
                    this.state.ParameterList !== undefined &&
                    Array.isArray(this.state.ParameterList) &&
                    this.state.ParameterList.map((item, index) => {
                      if (index < 5) {
                        defaultParameter.push(item.ParameterCode);
                      }
                    });
                  trendObj = {
                    ...trendObj,
                    DeviceCode:
                      this.state.DeviceList &&
                      this.state.DeviceList[0] &&
                      this.state.DeviceList[0]['Device']
                        ? [this.state.DeviceList[0]['Device']]
                        : [],
                    ParameterCode: defaultParameter,
                    FromDate: moment.utc(fromDate, 'YYYY-MM-DD HH:mm:ss').local(),
                    ToDate: moment.utc(toDate, 'YYYY-MM-DD HH:mm:ss').local(),
                    CurrentTime: moment.utc(CurrentTime, 'YYYY-MM-DD HH:mm:ss').local(),
                    PredictionTargetFrom: moment(CurrentTime)
                      .utc()
                      .local()
                      .add(
                        this.state.formObject && this.state.formObject.FuturePredRangeFrom,
                        'minutes'
                      )
                      .format('DD-MM-YYYY HH:mm:ss'),
                    PredictionTargetTo: moment(CurrentTime)
                      .utc()
                      .local()
                      .add(
                        this.state.formObject && this.state.formObject.FuturePredRangeTo,
                        'minutes'
                      )
                      .format('DD-MM-YYYY HH:mm:ss'),
                    Interval:
                      IntervalData && IntervalData[0] && IntervalData[0]['value']
                        ? IntervalData[0]['value']
                        : ''
                  };
                  this.onParameterChange(defaultParameter);
                  this.trendRef.current && this.trendRef.current.setFieldsValue(trendObj);
                  this.setState({ trendObj, predictionActiveKey: 'Trends' });
                }}
                size="middle"
              >
                <span style={{ color: '#1890ff' }}>
                  <LineChartOutlined />
                </span>
              </Button>
            </Tooltip>
          )
        }
      ],
      predictionREGResultColumns: [
        {
          title: this.translation('Current Time'),
          dataIndex: 'CurrentTime',
          hide: true,
          key: 'CurrentTime',
          ...this.getColumnSearchProps('CurrentTime'),
          sorter: (a, b) =>
            a.CurrentTime && b.CurrentTime && a.CurrentTime.localeCompare(b.CurrentTime),
          render: (text) => {
            return {
              children:
                text !== undefined ? (
                  <div>{moment(text).utc().format('YYYY-MM-DD HH:mm:ss')}</div>
                ) : null
            };
          }
        },
        {
          title: this.translation('Predicted From'),
          dataIndex: 'predTimeFrom',
          key: 'predTimeFrom',
          ...this.getColumnSearchProps('predTimeFrom'),
          sorter: (a, b) =>
            a.predTimeFrom && b.predTimeFrom && a.predTimeFrom.localeCompare(b.predTimeFrom),
          render: (text) => {
            return {
              children:
                text !== undefined ? (
                  <div>{moment(text).utc().format('YYYY-MM-DD HH:mm:ss')}</div>
                ) : null
            };
          }
        },
        {
          title: this.translation('Predicted To'),
          dataIndex: 'predTimeTo',
          key: 'predTimeTo',
          ...this.getColumnSearchProps('predTimeTo'),
          sorter: (a, b) =>
            a.predTimeTo && b.predTimeTo && a.predTimeTo.localeCompare(b.predTimeTo),
          render: (text) => {
            return {
              children:
                text !== undefined ? (
                  <div>{moment(text).utc().format('YYYY-MM-DD HH:mm:ss')}</div>
                ) : null
            };
          }
        },
        {
          title: this.translation('Predicted Value'),
          dataIndex: 'PredictedValue',
          key: 'PredictedValue'
        },
        {
          title: this.translation('Actual Value'),
          dataIndex: 'ActualValue',
          key: 'ActualValue'
        },
        {
          title: this.translation('View Trends'),
          render: () => (
            <Tooltip title={this.translation('Trends')}>
              <Button
                data-testid={'lift-hold-button'}
                style={{ marginRight: '5px' }}
                className="ant-btn"
                size="middle"
              >
                <span style={{ color: '#1890ff' }}>
                  <LineChartOutlined />
                </span>
              </Button>
            </Tooltip>
          )
        }
      ],
      predictionResultsData: [],
      realPredictionResultsData: [],
      predResInfo: {},
      metricResultData: [],
      predictionModel: false,
      RealTimeModel: false,
      QuickRunModal: false,
      ActionsModel: false,
      resultsDrawerVisible: false,
      realTimeResultsDrawerVisible: false,
      realTimeQuickRunResultDrawer: false,
      realTimePredResInfo: {},
      metricsData: [],
      predictionActiveKey: 'Metric values',
      translationCache: props.translationCache || {},
      predictionRequestInfo: {},
      realTimePredictionRequestInfo: {},
      RealTimeInfo: {},
      ActionInfo: {},
      pagination: {
        current: 1,
        pageSize: 10
      },
      ParameterList: [],
      selectedRowKeys: [],
      selectedRows: [],
      RealTimeVal: false,
      ButtonKey: null,
      DataSourceList: [],
      confMatrixVisible: false,
      ROCCurveVisible: false,
      RealTimeMetricResultVisible: false,
      communicationType: [],
      ModelPredRealtimeHistoryData: [],
      option: {},
      RocCurveOption: {},
      graphType: props.panelData && props.panelData.type ? props.panelData.type : 'heatmap'
    };
    this.modelRef = React.createRef();
    this.modelDeployRef = React.createRef();
    this.predictionRequestRef = React.createRef();
    this.buttonRef = React.createRef();
    this.predictionButtonRef = React.createRef();
    this.predResRef = React.createRef();
    this.trendRef = React.createRef();
    this.realTimeTrendRef = React.createRef();
    this.RealTimeModelRef = React.createRef();
    this.realTimePredResRef = React.createRef();
    this.realTimePredictionRequestRef = React.createRef();
    this.ActionsModalRef = React.createRef();
    this.QuickRunModalRef = React.createRef();
    this.QuickRunResultsRef = React.createRef();
  }
  componentDidMount = async () => {
    let { communicationType, RealTimeInfo } = this.state;
    let pathname = history.location.pathname;
    let metaData = {};
    let DeviceList = [];
    if (pathname.includes('RegisterModel')) {
      let path = history.location.pathname.split('/rubus/RbsAnalytics/RegisterModel/');
      metaData = await getMetaData(ApiList.getData, 'ModelName', path[1]);
    } else {
      let path1 = history.location.pathname.split('/rubus/RbsAnalytics/ModelDetails/');
      metaData = await getMetaData(ApiList.getData, 'ModelId', path1[1]);
      _.cloneDeepWith(metaData, (value) => {
        return value !== null && value !== undefined
          ? {
              ...value,
              ...(value['TrainPeriodFrom']
                ? (value['TrainPeriodFrom'] = moment(value.TrainPeriodFrom)
                    .utc()
                    .format('YYYY-MM-DD HH:mm:ss'))
                : null),
              ...(value['TrainPeriodTo']
                ? (value['TrainPeriodTo'] = moment(value.TrainPeriodTo)
                    .utc()
                    .format('YYYY-MM-DD HH:mm:ss'))
                : null),
              ...(value['EvalPeriodFrom']
                ? (value['EvalPeriodFrom'] = moment(value.EvalPeriodFrom)
                    .utc()
                    .format('YYYY-MM-DD HH:mm:ss'))
                : null),
              ...(value['EvalPeriodTo']
                ? (value['EvalPeriodTo'] = moment(value.EvalPeriodTo)
                    .utc()
                    .format('YYYY-MM-DD HH:mm:ss'))
                : null),
              ...(value['CreatedTime']
                ? (value['CreatedTime'] = moment(value.CreatedTime)
                    .utc()
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss'))
                : null)
            }
          : _.noop();
      });
      this.getDeployHistory();
      this.getPredictionHistory();

      let modeltype =
        metaData && metaData.ModelType === 'Classification'
          ? 'RBS_MODEL_CLF_METRICS'
          : this.modelRef.current &&
            this.modelRef.current.getFieldValue('ModelType') === 'Regression'
          ? 'RBS_MODEL_REG_METRICS'
          : null;
      var metricsData = await getDeployHistory(ApiList.getMetrics, 'LookupName', modeltype);
      let modelId = metaData && metaData.ModelId ? metaData.ModelId : '';

      DeviceList = await getDeployHistory(ApiList.getTagsByModelId, 'ModelId', modelId);
      let Device =
        DeviceList && DeviceList[0] && DeviceList[0]['Device'] ? [DeviceList[0]['Device']] : [];

      let payload = {
        DeviceCode: Device,
        ModelId: metaData && metaData.ModelId ? metaData.ModelId : ''
      };
      let response = await RegisterModel(ApiList.getParameterByDevice, payload);
      this.setState({
        ParameterList:
          response && response.data && response.data.Operational ? response.data.Operational : []
      });
    }

    this.modelRef.current && this.modelRef.current.setFieldsValue(metaData);
    let recipientData = await recipientList();
    let commlist = await getCommunicationList();
    // {
    //   data: [
    //     {
    //       Id: 1,
    //       SiteId: 1,
    //       Name: 'Email',
    //       Code: 'Email',
    //       Status: true
    //     },
    //     {
    //       Id: 2,
    //       SiteId: 1,
    //       Name: 'Slack',
    //       Code: 'Slack',
    //       Status: true
    //     }
    //   ]
    // };

    communicationType =
      commlist &&
      commlist.data &&
      Array.isArray(commlist.data) &&
      commlist.data.map((item) => {
        let val = item.Code;
        // let t1 = communicationType.push(val);

        return val;
      });
    this.setState({ communicationType });

    const startDate = this.state.momentRange && this.state.momentRange[0];
    const timeEnd = this.state.momentRange && this.state.momentRange[1];
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    let differenceHours = diffDuration.days();
    // let differenceMinutes = diffDuration.minutes();

    let difMin = timeEnd - startDate;
    difMin = Math.round(difMin / 1000 / 60);
    this.setState({ differenceHours, difMin });
    let filteredActionList = this.getList();
    let DataSourceList = await getList(ApiList.getDataSourceList);
    let ModelPredRealtimeHistoryData = [];
    if (!pathname.includes('RegisterModel')) {
      let path1 = history.location.pathname.split('/rubus/RbsAnalytics/ModelDetails/');
      ModelPredRealtimeHistoryData = await getDeployHistory(
        ApiList.getRealTimePredList,
        'ModelId',
        path1[1]
      );
    }
    // this.getRealTimeHistory();
    let data =
      ModelPredRealtimeHistoryData &&
      Array.isArray(ModelPredRealtimeHistoryData) &&
      ModelPredRealtimeHistoryData.filter(
        (item) =>
          item.Status === 'Scheduled' || item.Status === 'Initiated' || item.Status === 'Running'
      ).length === 0
        ? false
        : true;
    this.setState({
      recipientList: recipientData && recipientData.data ? recipientData.data : [],
      ModelType: metaData && metaData.ModelType,
      ModelName: metaData && metaData.ModelName,
      formObject: metaData,
      metricsData,
      DeviceList,
      RealTimeVal: data,
      DataSourceList,
      ModelPredRealtimeHistoryData,
      data: filteredActionList,
      RealTimeInfo
    });
  };

  getList = () => {
    let { communicationType } = this.state;
    let filteredArray = [];
    filteredArray =
      communicationType &&
      Array.isArray(communicationType) &&
      communicationType !== undefined &&
      communicationType.map((comm) => {
        return actionList.filter((action) => action.value === comm);
      });
    let data = [];

    filteredArray &&
      Array.isArray(filteredArray) &&
      filteredArray !== undefined &&
      filteredArray.map((item) => {
        return item.map((val) => {
          return data.push(val);
        });
      });
    return data;
  };

  RealTimeEdit = async (record, key) => {
    let { RealTimeInfo } = this.state;
    let RecordData = await getMetaData(ApiList.actionsGetById, 'PredReqId', record.PredReqId);
    if (RecordData && RecordData[0] && RecordData[0] !== null) {
      RealTimeInfo = {
        ...RealTimeInfo,
        FromTime: moment(RecordData[0].FromTime),
        ToTime: moment(RecordData[0].ToTime),
        DataSourceID: RecordData[0] && RecordData[0].DataSourceID,
        TargetServer: RecordData[0] && RecordData[0].TargetServer,
        Frequency: record && record.Frequency,
        Comments: RecordData[0].Comments,
        ModelName: RecordData[0].ModelName,
        ModelType: RecordData[0].ModelType,
        Operator: RecordData[0].Operator,
        Value: RecordData[0].Value,
        CommunicationTypes: RecordData[0].CommunicationTypes,
        MailRecipient: RecordData[0].MailRecipient,
        Status: RecordData[0].Status
      };
      this.RealTimeModelRef.current &&
        this.RealTimeModelRef.current.setFieldsValue({
          FromTime: moment(RecordData[0].FromTime),
          ToTime: moment(RecordData[0].ToTime),
          DataSourceID: RecordData[0] && RecordData[0].DataSourceID,
          TargetServer: RecordData[0] && RecordData[0].TargetServer,
          Frequency: record && record.Frequency,
          Comments: RecordData[0].Comments,
          ModelName: RecordData[0].ModelName,
          ModelType: RecordData[0].ModelType,
          Operator: RecordData[0].Operator,
          Value: RecordData[0].Value,
          CommunicationTypes: RecordData[0].CommunicationTypes,
          MailRecipient: RecordData[0].MailRecipient,
          Status: RecordData[0].Status
        });
      this.getRealTimeRefreshHistory();
      this.setState({
        RealTimeModel: true,
        ButtonKey: key,
        RealTimeInfo,
        SelectedCommunication: RealTimeInfo.CommunicationTypes,
        MailRecipient: RealTimeInfo.MailRecipient,
        SelectedCondition: RecordData[0].Operator,
        Status: RecordData[0].Status,
        ActionRecordPredReqId: RecordData[0].PredReqId,
        RecordId: RecordData[0].Id
      });
    }
  };

  RealTimeStopJob = async (record, key) => {
    let { RealTimeInfo } = this.state;
    let RecordData = await getMetaData(ApiList.actionsGetById, 'PredReqId', record.PredReqId);
    if (RecordData && RecordData[0] && RecordData[0] !== null) {
      RealTimeInfo = {
        ...RealTimeInfo,
        FromTime: moment(RecordData[0].FromTime),
        ToTime: moment(RecordData[0].ToTime),
        DataSourceID: record && record.DataSourceID,
        TargetServer: RecordData && RecordData[0] && RecordData[0].TargetServer,
        Frequency: RecordData && RecordData[0] && RecordData[0].Frequency,
        Comments: RecordData[0].Comments,
        ModelName: RecordData[0].ModelName,
        ModelType: RecordData[0].ModelType,
        Operator: RecordData[0].Operator,
        Value: RecordData[0].Value,
        CommunicationTypes: RecordData[0].CommunicationTypes,
        MailRecipient: RecordData[0].MailRecipient,
        Status: RecordData[0].Status,
        PredReqId: RecordData[0].PredReqId
      };
      this.RealTimeModelRef.current &&
        this.RealTimeModelRef.current.setFieldsValue({
          FromTime: moment(RecordData[0].FromTime),
          ToTime: moment(RecordData[0].ToTime),
          DataSourceID: record && record.DataSourceID,
          TargetServer: RecordData && RecordData[0] && RecordData[0].TargetServer,
          Frequency: RecordData && RecordData[0] && RecordData[0].Frequency,
          Comments: RecordData[0].Comments,
          ModelName: RecordData[0].ModelName,
          ModelType: RecordData[0].ModelType,
          Operator: RecordData[0].Operator,
          Value: RecordData[0].Value,
          CommunicationTypes: RecordData[0].CommunicationTypes,
          MailRecipient: RecordData[0].MailRecipient,
          Status: RecordData[0].Status
        });

      this.setState({
        RealTimeModel: true,
        ButtonKey: key,
        RealTimeInfo,
        SelectedCommunication: RealTimeInfo.CommunicationTypes,
        MailRecipient: RealTimeInfo.MailRecipient,
        SelectedCondition: RecordData[0].Operator,
        Status: RecordData[0].Status
      });
    }
  };

  onRealTimeModelStopJob = async () => {
    let { RealTimeInfo } = this.state;
    let payload = {
      PredReqId: RealTimeInfo.PredReqId
    };
    let response = await DeployModel(ApiList.realTimeStop, payload);
    message.success(response && response.message && this.translation(response.message));
    this.RealTimeModelRef.current &&
      this.RealTimeModelRef.current.setFieldsValue({
        FromTime: moment(),
        ToTime: null,
        DataSourceID: null,
        TargetServer: null,
        Frequency: null,
        Comments: null,
        ModelType: this.state.ModelType,
        ModelName: this.state.ModelName,
        Operator: null,
        Value: null,
        CommunicationTypes: [],
        MailRecipient: [],
        Status: 'Inactive'
      });
    this.getRealTimeRefreshHistory();
    this.setState({
      RealTimeModel: false,
      RealTimeInfo: {},
      SelectedCommunication: [],
      MailRecipient: [],
      SelectedCondition: null,
      Status: 'Inactive'
    });
  };

  onRealTimeModelReqCancel = () => {
    this.RealTimeModelRef.current &&
      this.RealTimeModelRef.current.setFieldsValue({
        FromTime: null,
        ToTime: null,
        DataSourceID: '',
        Comments: ''
      });
    this.setState({ RealTimeModel: false, RealTimeInfo: {} });
  };

  getDailyFunctionalSummary = async (functionalSummaryDailyFrom, functionalSummaryDailyTo) => {
    let { predResInfo } = this.state;
    let payload = {
      PredReqId: predResInfo.PredReqId,
      siteId: '1',
      startDate: moment(functionalSummaryDailyFrom).utc().format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(functionalSummaryDailyTo).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    let response = await DeployModel(ApiList.functionalSummaryForDaily, payload);
    if (response && Array.isArray(response) && response.length === 0) {
      message.info(this.translation('No Data'));
    }
    this.setState({
      dailyFunctionalSummary: response && response !== undefined && response,
      functionalSummaryDailyFrom,
      functionalSummaryDailyTo
    });
  };

  getRealTimeDailyFunctionalSummary = async (
    functionalSummaryDailyFrom1,
    functionalSummaryDailyTo1
  ) => {
    let { realTimePredResInfo } = this.state;
    let payload = {
      PredReqId: realTimePredResInfo.PredReqId,
      // siteId: '1',
      startDate: moment(functionalSummaryDailyFrom1).utc().format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(functionalSummaryDailyTo1).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    let response = await DeployModel(ApiList.realTimeFunctionalSummaryForDaily, payload);
    if (response && Array.isArray(response) && response.length === 0) {
      message.info(this.translation('No Data'));
    }
    this.setState({
      dailyFunctionalSummary1: response && response !== undefined && response,
      functionalSummaryDailyFrom1,
      functionalSummaryDailyTo1
    });
  };

  getRealTimeweeklyFunctionalSummary = async (
    functionalSummaryWeeklyTo1,
    functionalSummaryWeekCount1
  ) => {
    let { realTimePredResInfo } = this.state;
    let payload = {
      PredReqId: realTimePredResInfo.PredReqId,
      // siteId: '1',
      numberOfWeeks: functionalSummaryWeekCount1,
      Date: moment(functionalSummaryWeeklyTo1).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    let response = await DeployModel(ApiList.realTimeFunctionalSummaryForMonthly, payload);
    if (response && Array.isArray(response) && response.length === 0) {
      message.info(this.translation('No Data'));
    }
    this.setState({
      weeklyFunctionalSummary1: response && response !== undefined && response,
      functionalSummaryWeekCount1,
      functionalSummaryWeeklyTo1
    });
  };

  getweeklyFunctionalSummary = async (functionalSummaryWeeklyTo, functionalSummaryWeekCount) => {
    let { predResInfo } = this.state;
    let payload = {
      PredReqId: predResInfo.PredReqId,
      siteId: '1',
      numberOfWeeks: functionalSummaryWeekCount,
      Date: moment(functionalSummaryWeeklyTo).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    let response = await DeployModel(ApiList.functionalSummaryForMonthly, payload);
    if (response && Array.isArray(response) && response.length === 0) {
      message.info(this.translation('No Data'));
    }
    this.setState({
      weeklyFunctionalSummary: response && response !== undefined && response,
      functionalSummaryWeekCount,
      functionalSummaryWeeklyTo
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.userProfile !== prevProps.userProfile ||
      this.props.translationCache !== prevProps.translationCache
    ) {
      this.setState({
        userProfile: this.props.userProfile,
        translationCache: this.props.translationCache
      });
    }
  };

  getUsersList = async () => {
    let usersList = await getList(ApiList.getUsersList);
    this.setState({ usersList });
  };

  renderTree = (data) => {
    let Array = [];
    data &&
      Object.keys(data).map((i) => {
        let newData = {
          key: i,
          children: data[i].map((j) => {
            return { key: j };
          })
        };
        Array.push(newData);
      });
    return Array;
  };

  renderTreeNodes = (data) => {
    return (
      data &&
      data !== undefined &&
      Array.isArray(data) &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <TreeNode title={item.key} key={item.key} dataRef={item.key}>
              {this.renderTreeNodes(item.children, item)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.value} title={item.key} dataRef={item.value} />;
      })
    );
  };

  onFormSubmit = () => {
    let { formObject } = this.state;
    this.modelRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...this.modelRef.current.getFieldsValue()
        };
        let response = await RegisterModel(ApiList.insert, values);
        if (response && response.status === 200) {
          const Id = response.data && response.data['ModelId'] ? response.data['ModelId'] : null;
          let metaData = await getMetaData(ApiList.getData, 'ModelId', Id);
          this.modelRef.current && this.modelRef.current.setFieldsValue(metaData);
          this.setState({ formObject: metaData });
          response &&
            response.data &&
            response.data['ModelId'] &&
            message.success(`${formObject['ModelName']} Updated successfully`);
          history.push('/rubus/RbsAnalytics/ModelsWorkbench');
        }
      })
      .catch(() => {});
  };

  onDeploySubmit = () => {
    let { formObject, userProfile } = this.state;
    this.modelDeployRef.current.validateFields().then(async (values) => {
      values = {
        ...this.modelDeployRef.current.getFieldsValue(),
        ModelId: formObject && formObject.ModelId ? formObject.ModelId : '',
        UserId: userProfile && userProfile.user && userProfile.user.Id ? userProfile.user.Id : ''
      };
      let response = await DeployModel(ApiList.deployModel, values);
      // if (response && response.status === 200) {
      message.success(response && response.message && this.translation(response.message));
      let path1 = history.location.pathname.split('/rubus/RbsAnalytics/ModelDetails/');
      let ModelDeployHistoryData = await getDeployHistory(
        ApiList.getDeployHistory,
        'ModelId',
        path1[1]
      );
      this.modelDeployRef.current.setFieldsValue({
        // Virtualenv: '',
        // Default: false,
        SourceServer: '',
        TargetServer: ''
      });
      this.setState({
        deployModelVisible: false,
        ModelDeployHistoryData,
        modelDeploymentInfo: {}
      });
      // }
    });
  };

  getDeployHistory = async () => {
    let path1 = history.location.pathname.split('/rubus/RbsAnalytics/ModelDetails/');
    let ModelDeployHistoryData = await getDeployHistory(
      ApiList.getDeployHistory,
      'ModelId',
      path1[1]
    );
    this.setState({ ModelDeployHistoryData });
  };

  getRealTimeRefreshHistory = async () => {
    let path1 = history.location.pathname.split('/rubus/RbsAnalytics/ModelDetails/');
    let ModelPredRealtimeHistoryData = await getDeployHistory(
      ApiList.getRealTimePredList,
      'ModelId',
      path1[1]
    );
    let data =
      ModelPredRealtimeHistoryData &&
      Array.isArray(ModelPredRealtimeHistoryData) &&
      ModelPredRealtimeHistoryData.filter(
        (item) =>
          item.Status === 'Scheduled' || item.Status === 'Initiated' || item.Status === 'Running'
      ).length === 0
        ? false
        : true;
    this.setState({ ModelPredRealtimeHistoryData, RealTimeVal: data });
  };

  getMetricResults = async (record) => {
    let metricResultData = await getDeployHistory(
      ApiList.getMetricResults,
      'PredReqId',
      record.PredReqId
    );
    this.setState({
      resultsDrawerVisible: true,
      MetricValuesData:
        metricResultData && metricResultData !== undefined && metricResultData.TableList
          ? metricResultData.TableList
          : null,
      MetricImagesData:
        metricResultData && metricResultData !== undefined && metricResultData.ImageList
          ? metricResultData.ImageList
          : null
    });
  };

  getRealTimeMetricResults = async (values) => {
    let metricResultData = values;
    // Dividing the whole response data into Two array of arrays for getting TableList and ImageList seperately
    let MetricValuesData1 =
      metricResultData && metricResultData !== undefined && metricResultData.TableList
        ? metricResultData.TableList
        : null;

    let MetricImagesData1 =
      metricResultData &&
      metricResultData !== undefined &&
      metricResultData.ImageList &&
      metricResultData.ImageList
        ? metricResultData.ImageList
        : null;

    //  This is used for getting only ROC Curve Object from all GraphList
    let ROCCurveData =
      MetricImagesData1 &&
      Array.isArray(MetricImagesData1) &&
      MetricImagesData1 !== undefined &&
      MetricImagesData1.filter((metric) => metric.MetricCode === 'ROC Curve');
    let ROCCurveDataOptions =
      ROCCurveData && ROCCurveData !== null && ROCCurveData[0] && ROCCurveData[0].MetricValue;

    // This is used for getting only AUC Object from all GraphList
    let AUC =
      MetricImagesData1 &&
      Array.isArray(MetricImagesData1) &&
      MetricImagesData1 !== undefined &&
      MetricImagesData1.filter((metric) => metric.MetricCode === 'AUC');
    let AUCData = AUC && AUC !== null && AUC[0] && AUC[0].MetricValue;

    // This is used for getting Confusion Matrix Object from all GraphList
    let ConfusionMatrixObj = {};
    MetricValuesData1 &&
      Array.isArray(MetricValuesData1) &&
      MetricValuesData1 !== undefined &&
      MetricValuesData1.forEach((ele) => {
        ConfusionMatrixObj[ele.MetricCode] = ele.MetricValue;
      });

    // This is wriiten to get Last index from an Roc Curve array
    let LastIndexValue =
      ROCCurveDataOptions &&
      ROCCurveDataOptions !== null &&
      ROCCurveDataOptions[ROCCurveDataOptions.length - 1];

    // This method is used for get all Graphs List Metric Code in an array to write graph visible and disable
    let GraphListVal =
      MetricImagesData1 &&
      Array.isArray(MetricImagesData1) &&
      MetricImagesData1 !== undefined &&
      MetricImagesData1.map((metric) => {
        return metric.MetricCode;
      });
    let confMatrixVisible =
      GraphListVal &&
      Array.isArray(GraphListVal) &&
      GraphListVal !== null &&
      GraphListVal.includes('Confusion Matrix');
    let ROCCurveVisible =
      GraphListVal &&
      Array.isArray(GraphListVal) &&
      GraphListVal !== null &&
      GraphListVal.includes('ROC Curve');

    // Options to show Confusion Matrix Graph
    let option = {
      tooltip: {
        position: 'top'
      },

      // animation: false,
      grid: {
        height: '50%',
        top: '10%'
      },
      xAxis: {
        type: 'category',
        nameLocation: 'middle',
        name: this.translation('Predicted Label'),
        data: [0, 1],
        splitArea: {
          show: true
        }
      },
      yAxis: {
        type: 'category',
        name: this.translation('True Label'),
        nameLocation: 'middle',
        data: [1, 0],
        splitArea: {
          show: true
        }
      },
      visualMap: {
        min: 0,
        max: 100,
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '15%',
        show: false,
        inRange: {
          color: ['green', 'yellow']
        }
      },
      series: [
        {
          name: this.translation('Confusion Matrix'),
          type: 'heatmap',

          label: {
            show: true,
            position: 'inside'
          },
          data: [
            [0, 0, ConfusionMatrixObj['True negatives'] ? ConfusionMatrixObj['True negatives'] : 0],
            [
              1,
              0,
              ConfusionMatrixObj['False positives'] ? ConfusionMatrixObj['False positives'] : 0
            ],
            [
              0,
              1,
              ConfusionMatrixObj['False negatives'] ? ConfusionMatrixObj['False negatives'] : 0
            ],
            [1, 1, ConfusionMatrixObj['True positives'] ? ConfusionMatrixObj['True positives'] : 0]
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0,0,0,0.5)',
              borderColor: 'rgb(230, 226, 23)'
            }
          }
        }
      ]
    };

    // Options to show ROC Curve Graph
    let RocCurveOption = {
      xAxis: {
        name: 'False Positive Rate',
        nameLocation: 'middle',
        nameGap: 30
      },
      yAxis: {
        name: 'True Positive Rate',
        nameLocation: 'middle',
        nameGap: 30
      },
      graphic: [
        {
          type: 'text',
          z: 10,
          left: 'right',
          top: 'bottom',
          style: {
            fill: '#333',
            width: 220,
            // overflow: 'break',
            text: `AUC =${AUCData}`,
            font: '14px Microsoft YaHei'
          }
        }
      ],
      series: [
        {
          data: ROCCurveDataOptions && ROCCurveDataOptions !== undefined ? ROCCurveDataOptions : [],
          type: 'line'
        },
        {
          data: [
            ROCCurveDataOptions && ROCCurveDataOptions !== null && ROCCurveDataOptions[0],
            LastIndexValue
          ],
          type: 'line',
          lineStyle: {
            color: 'red',
            width: 2,
            type: 'dashed'
          }
        }
      ]
    };
    this.setState({
      realTimeResultsDrawerVisible: true,
      MetricValuesData1,
      MetricImagesData1,
      ROCCurveData,
      option,
      RocCurveOption,
      confMatrixVisible,
      ROCCurveVisible
    });
  };

  realTimeDateRangePicker = (e, key) => {
    let realTimePredResInfo =
      this.realTimePredResRef.current && this.realTimePredResRef.current.getFieldsValue();
    realTimePredResInfo = {
      ...realTimePredResInfo,
      [key]: e
    };
    this.getRealTimePredictionResultsList(realTimePredResInfo, e, true);
    this.realTimePredResRef.current.setFieldsValue(realTimePredResInfo);
    this.setState({ realTimePredResInfo });
  };

  getRealTimePredictionResultsList = async (record, date, onChange) => {
    // let { selectionDateRange } = this.state;
    let payload = {
      PredReqId: record && record.PredReqId ? record.PredReqId : '',
      predictionFromTime:
        date !== undefined ? moment(date[0]).utc().format('YYYY-MM-DD HH:mm:ss') : null,
      predictionToTime:
        date !== undefined ? moment(date[1]).utc().format('YYYY-MM-DD HH:mm:ss') : null
    };
    let realPredictionResultsData = await DeployModel(
      ApiList.getRealTimePredictionResults,
      payload
    );
    // if (onChange === undefined) {
    //   _.cloneDeepWith(record, (value) => {
    //     return value !== null && value !== undefined
    //       ? {
    //         ...value,
    //         ...(value["FromTime"]
    //           ? (value["FromTime"] = moment(value.FromTime)
    //             .utc()
    //             .format("YYYY-MM-DD HH:mm:ss"))
    //           : null),
    //         ...(value["ToTime"]
    //           ? (value["ToTime"] = moment(value.ToTime)
    //             .utc()
    //             .format("YYYY-MM-DD HH:mm:ss"))
    //           : null),
    //       }
    //       : _.noop();
    //   });
    // }
    let data = {
      ...record,
      Date: [
        realPredictionResultsData &&
        realPredictionResultsData[0] &&
        realPredictionResultsData[0]['CurrentTime']
          ? moment.utc(realPredictionResultsData[0]['CurrentTime'], 'YYYY-MM-DD HH:mm:ss').local()
          : '',
        realPredictionResultsData &&
        realPredictionResultsData[realPredictionResultsData.length - 1] &&
        realPredictionResultsData[realPredictionResultsData.length - 1]['CurrentTime']
          ? moment
              .utc(
                realPredictionResultsData[realPredictionResultsData.length - 1]['CurrentTime'],
                'YYYY-MM-DD HH:mm:ss'
              )
              .local()
          : ''
      ],
      FromTime:
        onChange === undefined
          ? moment(record.FromTime).utc().local().format('YYYY-MM-DD HH:mm:ss')
          : record.FromTime,
      ToTime:
        onChange === undefined
          ? moment(record.ToTime).utc().local().format('YYYY-MM-DD HH:mm:ss')
          : record.ToTime
    };
    this.setState({
      // realTimeResultsDrawerVisible: true,
      realPredictionResultsData,
      predictionFromTime: data.FromTime,
      predictionToTime: data.ToTime,
      realTimePredResInfo: data,
      functionalSummaryDailyFrom1:
        data.FromTime > moment(data.ToTime).subtract(6, 'day').format('YYYY-MM-DD ')
          ? moment(data.FromTime).format('YYYY-MM-DD 00:00:00')
          : moment(data.ToTime).subtract(6, 'day').format('YYYY-MM-DD 00:00:00'),
      functionalSummaryDailyTo1: moment(data.ToTime).format('YYYY-MM-DD 23:59:59'),
      functionalSummaryWeeklyFrom:
        data.FromTime > moment(data.ToTime).subtract(27, 'day').format('YYYY-MM-DD')
          ? moment(data.FromTime).format('YYYY-MM-DD 00:00:00')
          : moment(data.ToTime).subtract(27, 'day').format('YYYY-MM-DD 00:00:00'),
      functionalSummaryWeeklyTo1: moment(data.ToTime).format('YYYY-MM-DD 23:59:59'),

      functionalSummaryFrom: moment().subtract(60, 'minute').format('YYYY-MM-DD HH:mm'),
      // data.FromTime > moment(data.ToTime).subtract(6, 'day').format('YYYY-MM-DD')
      //   ? moment(data.FromTime).format('YYYY-MM-DD HH:mm:ss')
      //   : moment(data.ToTime).subtract(27, 'day').format('YYYY-MM-DD HH:mm:ss'),
      functionalSummaryTo: moment().add(15, 'minute').format('YYYY-MM-DD HH:mm'),
      // moment(data.ToTime).format('YYYY-MM-DD HH:mm:ss'),
      functionalSummaryWeekCount1: 4
    });
    this.realTimePredResRef.current && this.realTimePredResRef.current.setFieldsValue(data);
  };

  dateRangePicker = (e, key) => {
    let predResInfo = this.predResRef.current && this.predResRef.current.getFieldsValue();
    predResInfo = {
      ...predResInfo,
      [key]: e
    };
    this.getPredictionResultsList(predResInfo, e, true);
    this.predResRef.current.setFieldsValue(predResInfo);
    this.setState({ predResInfo });
  };

  getPredictionResultsList = async (record, date, onChange) => {
    let payload = {
      PredReqId: record && record.PredReqId ? record.PredReqId : '',
      predictionFromTime:
        date !== undefined ? moment(date[0]).utc().format('YYYY-MM-DD HH:mm:ss') : null,
      predictionToTime:
        date !== undefined ? moment(date[1]).utc().format('YYYY-MM-DD HH:mm:ss') : null
    };
    let predictionResultsData = await DeployModel(ApiList.getPredictionResults, payload);
    // if (onChange === undefined) {
    //   _.cloneDeepWith(record, (value) => {
    //     return value !== null && value !== undefined
    //       ? {
    //         ...value,
    //         ...(value["FromTime"]
    //           ? (value["FromTime"] = moment(value.FromTime)
    //             .utc()
    //             .format("YYYY-MM-DD HH:mm:ss"))
    //           : null),
    //         ...(value["ToTime"]
    //           ? (value["ToTime"] = moment(value.ToTime)
    //             .utc()
    //             .format("YYYY-MM-DD HH:mm:ss"))
    //           : null),
    //       }
    //       : _.noop();
    //   });
    // }

    let data = {
      ...record,
      Date: [
        predictionResultsData && predictionResultsData[0] && predictionResultsData[0]['CurrentTime']
          ? moment.utc(predictionResultsData[0]['CurrentTime'], 'YYYY-MM-DD HH:mm:ss').local()
          : '',
        predictionResultsData &&
        predictionResultsData[predictionResultsData.length - 1] &&
        predictionResultsData[predictionResultsData.length - 1]['CurrentTime']
          ? moment
              .utc(
                predictionResultsData[predictionResultsData.length - 1]['CurrentTime'],
                'YYYY-MM-DD HH:mm:ss'
              )
              .local()
          : ''
      ],
      FromTime:
        onChange === undefined
          ? moment(record.FromTime).utc().local().format('YYYY-MM-DD HH:mm:ss')
          : record.FromTime,
      ToTime:
        onChange === undefined
          ? moment(record.ToTime).utc().local().format('YYYY-MM-DD HH:mm:ss')
          : record.ToTime
    };
    this.setState({
      // resultsDrawerVisible: true,
      predictionResultsData,
      predictionFromTime: data.FromTime,
      predictionToTime: data.ToTime,
      predResInfo: data,
      functionalSummaryDailyFrom:
        data.FromTime > moment(data.ToTime).subtract(6, 'day').format('YYYY-MM-DD ')
          ? moment(data.FromTime).format('YYYY-MM-DD 00:00:00')
          : moment(data.ToTime).subtract(6, 'day').format('YYYY-MM-DD 00:00:00'),
      functionalSummaryDailyTo: moment(data.ToTime).format('YYYY-MM-DD 23:59:59'),
      functionalSummaryWeeklyFrom:
        data.FromTime > moment(data.ToTime).subtract(27, 'day').format('YYYY-MM-DD')
          ? moment(data.FromTime).format('YYYY-MM-DD 00:00:00')
          : moment(data.ToTime).subtract(27, 'day').format('YYYY-MM-DD 00:00:00'),
      functionalSummaryWeeklyTo: moment(data.ToTime).format('YYYY-MM-DD 23:59:59'),
      functionalSummaryWeekCount: 4
    });
    this.predResRef.current && this.predResRef.current.setFieldsValue(data);
  };

  getPredictionHistory = async () => {
    let path1 = history.location.pathname.split('/rubus/RbsAnalytics/ModelDetails/');
    let ModelPredHistoryData = await getDeployHistory(
      ApiList.getPredictionHistory,
      'ModelId',
      path1[1]
    );
    this.setState({ ModelPredHistoryData });
  };

  getRealTimeHistory = async () => {
    let path1 = history.location.pathname.split('/rubus/RbsAnalytics/ModelDetails/');
    let ModelPredRealtimeHistoryData = await getDeployHistory(
      ApiList.getRealTimePredList,
      'ModelId',
      path1[1]
    );
    this.setState({ ModelPredRealtimeHistoryData });
  };

  onPredictionRequestSubmit = () => {
    let { formObject, userProfile, ModelDeployHistoryData } = this.state;
    this.predictionRequestRef.current.validateFields().then(async (values) => {
      values = {
        // ...values,
        FromTime: moment(this.predictionRequestRef.current.getFieldValue('FromTime'))
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        ToTime: moment(this.predictionRequestRef.current.getFieldValue('ToTime'))
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        Metrics: this.predictionRequestRef.current.getFieldValue('Metrics'),
        ModelId: formObject && formObject.ModelId ? formObject.ModelId : '',
        DepReqId:
          ModelDeployHistoryData &&
          Array.isArray(ModelDeployHistoryData) &&
          ModelDeployHistoryData.filter((item) => item.Default === true) &&
          ModelDeployHistoryData.filter((item) => item.Default === true)[0] &&
          ModelDeployHistoryData.filter((item) => item.Default === true)[0]['ReqId']
            ? ModelDeployHistoryData.filter((item) => item.Default === true)[0]['ReqId']
            : null,
        UserId: userProfile && userProfile.user && userProfile.user.Id ? userProfile.user.Id : ''
      };
      let response = await DeployModel(ApiList.predictionRequest, values);
      // if (response && response.status === 200) {
      message.success(response && response && this.translation(response.message));
      this.predictionRequestRef.current.setFieldsValue({
        FromTime: '',
        ToTime: ''
      });
      this.setState({ predictionModel: false, predictionRequestInfo: {} });
      this.getPredictionHistory();
      // }
    });
  };

  onRealTimePredictionRequestSubmit = () => {
    let { formObject, realTimePredictionReqInfo, userProfile } = this.state;
    this.realTimePredictionRequestRef.current.validateFields().then(async (values) => {
      values = {
        // ...values,
        FromTime: moment(this.realTimePredictionRequestRef.current.getFieldValue('FromTime'))
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        ToTime: moment(this.realTimePredictionRequestRef.current.getFieldValue('ToTime'))
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        Metrics: this.realTimePredictionRequestRef.current.getFieldValue('Metrics'),
        ModelId: formObject && formObject.ModelId ? formObject.ModelId : '',
        PredReqId:
          realTimePredictionReqInfo && realTimePredictionReqInfo.PredReqId
            ? realTimePredictionReqInfo.PredReqId
            : null,
        UserId: userProfile && userProfile.user && userProfile.user.Id ? userProfile.user.Id : ''
      };
      let response = await DeployModel(ApiList.getRealTimeMetricResults, values);
      if (response && response.message) {
        message.success(response && response && this.translation(response.message));
      }

      let context = {};
      if (response && Array.isArray(response) && response !== undefined) {
        //reduce is used for converting the response into two arrays to get table data and Graph data in individaual arrays
        context = response.reduce(
          (Metric, el) => {
            if (
              el.MetricCode !== 'Confusion Matrix' &&
              el.MetricCode !== 'ROC Curve' &&
              el.MetricCode !== 'AUC'
            ) {
              Metric.TableList.push(el);
            } else {
              Metric.ImageList.push(el);
            }
            return Metric;
          },
          { TableList: [], ImageList: [] }
        );
      }

      this.setState({
        metricCalResponse: context && context.ImageList,
        RealTimeMetricResultVisible: true,
        // realTimePredictionRequestInfo: {},
        metricResultVisible: true
      });
      this.getRealTimeMetricResults(context);
    });
  };

  onRealTimeModelSubmit = () => {
    let {
      userProfile,
      formObject,
      ModelDeployHistoryData,
      value,
      value1,
      value2,
      MailRecipient,
      ModelType,
      ModelName,
      SelectedCommunication,
      Status
    } = this.state;
    this.RealTimeModelRef.current
      .validateFields()
      .then(async (values) => {
        _.cloneDeepWith(values, (value) => {
          return value !== null && value !== undefined
            ? {
                // ...value,
                ...(value['FromTime']
                  ? (value['FromTime'] = moment(value.FromTime).utc().format('YYYY-MM-DD HH:mm:ss'))
                  : null),
                ...(value['ToTime']
                  ? (value['ToTime'] = moment(value.ToTime).utc().format('YYYY-MM-DD HH:mm:ss'))
                  : null)
              }
            : null;
        });
        values = {
          // ...values,
          FromTime: values.FromTime,
          ToTime: values.ToTime,
          Comments:
            this.RealTimeModelRef.current &&
            this.RealTimeModelRef.current.getFieldValue('Comments'),
          UserId: userProfile && userProfile.user && userProfile.user.Id ? userProfile.user.Id : '',
          ModelId: formObject && formObject.ModelId ? formObject.ModelId : '',
          Type: 'Realtime',
          DepReqId:
            ModelDeployHistoryData &&
            Array.isArray(ModelDeployHistoryData) &&
            ModelDeployHistoryData.filter((item) => item.Default === true) &&
            ModelDeployHistoryData.filter((item) => item.Default === true)[0] &&
            ModelDeployHistoryData.filter((item) => item.Default === true)[0]['ReqId']
              ? ModelDeployHistoryData.filter((item) => item.Default === true)[0]['ReqId']
              : null,
          DataSourceID:
            this.RealTimeModelRef.current &&
            this.RealTimeModelRef.current.getFieldValue('DataSourceID'),
          TargetServer:
            this.RealTimeModelRef.current &&
            this.RealTimeModelRef.current.getFieldValue('TargetServer'),
          Frequency:
            this.RealTimeModelRef.current &&
            this.RealTimeModelRef.current.getFieldValue('Frequency')
          // isCondition: addCondition
        };
        let response = await DeployModel(ApiList.realTimeCreate, values);
        message.success(response && response && this.translation(response.message));

        let payload = {};
        payload = {
          PredReqId: response && response.PredReqId,
          ModelType,
          ModelName,
          Status,
          Operator:
            this.RealTimeModelRef.current &&
            this.RealTimeModelRef.current.getFieldValue('Operator'),
          MailRecipient: MailRecipient,
          CommunicationTypes: SelectedCommunication
        };
        if (payload && payload.Operator && payload.Operator === 'Between') {
          payload.Value = [value1, value2];
        } else if (
          (payload && payload.Operator && payload.Operator === '<') ||
          payload.Operator === '>' ||
          payload.Operator === '>=' ||
          payload.Operator === '<='
        ) {
          payload.Value = [value];
        } else if (
          (payload && payload.Operator && payload.Operator === '==') ||
          payload.Operator === '!='
        ) {
          payload.Value =
            this.RealTimeModelRef.current && this.RealTimeModelRef.current.getFieldValue('Value');
        }
        if (response && response.PredReqId) {
          let ConditionResponse = await DeployModel(ApiList.realTimeConditionsCreate, payload);
          if (ConditionResponse && ConditionResponse.message) {
            message.success(
              ConditionResponse && ConditionResponse && this.translation(ConditionResponse.message)
            );
          }
        }
        this.getRealTimeRefreshHistory();
        this.RealTimeModelRef.current &&
          this.RealTimeModelRef.current.setFieldsValue({
            FromTime: moment(),
            ToTime: null,
            DataSourceID: null,
            TargetServer: null,
            Frequency: null,
            Comments: null,
            ModelName: this.state.ModelName,
            ModelType: this.state.ModelType,
            Operator: null,
            Value: null,
            CommunicationTypes: [],
            MailRecipient: []
          });
        this.setState({
          RealTimeModel: false,
          RealTimeInfo: {},
          SelectedCommunication: [],
          MailRecipient: [],
          addCondition: false,
          Status: 'Inactive'
        });
      })
      .catch(() => {});
  };

  onRealTimeModelUpdate = async () => {
    let {
      ModelType,
      ModelName,
      SelectedCondition,
      MailRecipient,
      SelectedCommunication,
      Status,
      ActionRecordPredReqId,
      RecordId,
      value,
      value1,
      value2
    } = this.state;
    let payload = {
      ModelType,
      ModelName,
      Operator: SelectedCondition,
      MailRecipient: MailRecipient,
      CommunicationTypes: SelectedCommunication,
      Status: Status,
      PredReqId: ActionRecordPredReqId,
      Id: RecordId
    };
    if (payload && payload.Operator && payload.Operator === 'Between') {
      payload.Value = [value1, value2];
    } else if (
      (payload && payload.Operator && payload.Operator === '<') ||
      payload.Operator === '>' ||
      payload.Operator === '>=' ||
      payload.Operator === '<='
    ) {
      payload.Value = [value];
    } else if (
      (payload && payload.Operator && payload.Operator === '==') ||
      payload.Operator === '!='
    ) {
      payload.Value =
        this.RealTimeModelRef.current && this.RealTimeModelRef.current.getFieldValue('Value');
    }

    let ConditionResponse = await DeployModel(ApiList.realTimeConditionsCreate, payload);
    if (ConditionResponse && ConditionResponse.message) {
      message.success(
        ConditionResponse && ConditionResponse && this.translation(ConditionResponse.message)
      );
    }
    this.setState({
      RealTimeModel: false,
      RealTimeInfo: {},
      addCondition: false,
      ModelType: this.state.ModelType,
      ModelName: this.state.ModelName,
      SelectedCondition: null,
      MailRecipient: [],
      SelectedCommunication: [],
      value: null,
      value1: null,
      value2: null,
      Status: 'Inactive'
    });
    this.getRealTimeRefreshHistory();
  };

  onChange = async (value, dateString) => {
    let { realTimePredResInfo } = this.state;
    this.setState({
      momentRange: [moment(dateString[0]), moment(dateString[1])],
      disableGraph: false
    });
    let startDate = moment(dateString[0]);
    let timeEnd = moment(dateString[1]);
    let diff = timeEnd.diff(startDate);
    let diffDuration = moment.duration(diff);
    let differenceHours = diffDuration.days();
    // let differenceMinutes = diffDuration.minutes();

    let difMin = timeEnd - startDate;
    difMin = Math.round(difMin / 1000 / 60);
    if (differenceHours === 0 && difMin > 0) {
      if (dateString && dateString[0] && dateString[1]) {
        let payload = {
          PredReqId: realTimePredResInfo.PredReqId,
          startDate: moment(dateString[0]).utc().format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(dateString[1]).utc().format('YYYY-MM-DD HH:mm:ss')
        };
        let response = await RealTimeGraphData(payload);
        if (response && Array.isArray(response) && response.length === 0) {
          message.info(this.translation('No Data'));
        } else {
          this.setState({
            RealTimeFunctionalSummary: response && response !== undefined && response,
            range: [dateString[0], dateString[1]],
            differenceHours,
            difMin
          });
        }
      }
    } else {
      message.warn('Please Select validate range!');
      this.setState({ differenceHours, difMin, RealTimeFunctionalSummary: [] });
    }
  };
  quickRun = async () => {
    let { userProfile, formObject, ModelDeployHistoryData, DataSourceId } = this.state;
    let CurrentDay = new Date();
    let StartDate = CurrentDay.setMinutes(CurrentDay.getMinutes() - 1);

    let payload = {
      FromTime: moment(StartDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      ToTime: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      Type: 'QuickRun',
      UserId: userProfile && userProfile.user && userProfile.user.Id ? userProfile.user.Id : '',
      DepReqId:
        ModelDeployHistoryData &&
        Array.isArray(ModelDeployHistoryData) &&
        ModelDeployHistoryData.filter((item) => item.Default === true) &&
        ModelDeployHistoryData.filter((item) => item.Default === true)[0] &&
        ModelDeployHistoryData.filter((item) => item.Default === true)[0]['ReqId']
          ? ModelDeployHistoryData.filter((item) => item.Default === true)[0]['ReqId']
          : null,
      ModelId: formObject && formObject.ModelId ? formObject.ModelId : '',
      DataSourceID: DataSourceId
    };
    let response = await DeployModel(ApiList.realTimeCreate, payload);
    message.success(response && response.message && this.translation(response.message));

    this.QuickRunModalRef.current &&
      this.QuickRunModalRef.current.setFieldsValue({
        DataSourceID: null
      });
    this.setState({ QuickRunModal: false, DataSourceId: null });
    this.getRealTimeRefreshHistory();
  };

  onFinishFailed = () => {
    message.error(this.translation('Please Enter Mandatory Fields'));
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  defaultDeployConfirm = async (record) => {
    let { formObject, userProfile } = this.state;
    let values = {
      ModelId: formObject && formObject.ModelId ? formObject.ModelId : '',
      UserId: userProfile && userProfile.user && userProfile.user.Id ? userProfile.user.Id : '',
      DepReqId: record.ReqId
    };
    let response = await DeployModel(ApiList.updateDefaultStatus, values);
    // if (response && response.status === 200) {
    this.getDeployHistory();
    message.success(response && response.message && this.translation(response.message));

    // }
  };

  cancel = () => {
    message.error(this.translation('Click on No'));
  };

  onCheck = (key, value) => {
    let predictionRequestInfo =
      this.predictionRequestRef.current && this.predictionRequestRef.current.getFieldsValue();
    predictionRequestInfo = {
      ...predictionRequestInfo,
      [key]: value
    };
    this.predictionRequestRef.current.setFieldsValue(predictionRequestInfo);
    this.setState({ predictionRequestInfo });
  };

  onMetricCheck = (key, value) => {
    let realTimePredictionRequestInfo =
      this.realTimePredictionRequestRef.current &&
      this.realTimePredictionRequestRef.current.getFieldsValue();
    realTimePredictionRequestInfo = {
      ...realTimePredictionRequestInfo,
      [key]: value
    };
    this.realTimePredictionRequestRef.current.setFieldsValue(realTimePredictionRequestInfo);
    this.setState({ realTimePredictionRequestInfo });
  };

  reset = () => {};

  getDisabledTrendHours = (e, current, type) => {
    let hours = [];
    let minutes = [];
    let seconds = [];
    let StartDateTime = '';
    let EndDateTime = '';
    let StartDate = '';
    let EndDate = '';
    StartDateTime = moment(current).format('YYYY-MM-DD HH:mm:ss');
    EndDateTime = moment(current).format('YYYY-MM-DD HH:mm:ss');
    StartDate = moment(current).format('YYYY-MM-DD');
    EndDate = moment(current).format('YYYY-MM-DD');
    let currentDate = moment(e).format('YYYY-MM-DD');
    let currentDateTime = moment(e).format('YYYY-MM-DD HH:mm:ss');

    if (
      moment(currentDate).isSame(
        moment(StartDate) ||
          moment(currentDateTime).hours() === moment(StartDateTime).hours() ||
          moment(currentDateTime).minutes() === moment(StartDateTime).minutes()
      ) &&
      type === 'start'
    ) {
      for (var i = 0; i <= 24; i++) {
        if (i > moment(StartDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(currentDate).isSame(moment(StartDate))) {
        for (var j = 0; j <= 60; j++) {
          if (j > moment(StartDateTime).minutes()) {
            minutes.push(j);
          }
        }
      }
      if (moment(currentDate).isSame(moment(StartDate))) {
        for (var l = 0; l <= 60; l++) {
          if (l < moment(StartDateTime).seconds()) {
            seconds.push(l);
          }
        }
      }

      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes,
        disabledSeconds: () => seconds
      };
    } else if (
      moment(currentDate).isSame(
        moment(EndDate) ||
          moment(currentDateTime).hours() === moment(EndDateTime).hours() ||
          moment(currentDateTime).minutes() === moment(EndDateTime).minutes()
      ) &&
      type === 'end'
    ) {
      for (var k = 0; k <= 24; k++) {
        if (k < moment(EndDateTime).hours()) {
          hours.push(k);
        }
      }
      if (moment(currentDate).isSame(moment(EndDate))) {
        for (var J = 0; J <= 60; J++) {
          if (J < moment(EndDateTime).minutes()) {
            minutes.push(J);
          }
        }
      }
      if (moment(currentDate).isSame(moment(EndDate))) {
        for (var K = 0; K <= 60; K++) {
          if (K < moment(EndDateTime).seconds()) {
            seconds.push(K);
          }
        }
      }
      // for (var k = 0; k < moment(EndDateTime).hours(); k++) {
      //   if (k < 25) {
      //     hours.push(k);
      //   }
      // }

      // if (moment(currentDate).isSame(moment(EndDate))) {
      //   for (var J = 0; J < moment(EndDateTime).minutes() + 1; J++) {
      //     if (J < 61) {
      //       minutes.push(J);
      //     }
      //   }
      // }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes,
        disabledSeconds: () => seconds
      };
    }
  };

  getDisabledHours = (e, from, to) => {
    let hours = [];
    let minutes = [];
    let seconds = [];
    let StartDateTime = '';
    let EndDateTime = '';
    let StartDate = '';
    let EndDate = '';
    StartDateTime = moment(from).format('YYYY-MM-DD HH:mm:ss');
    EndDateTime = moment(to).format('YYYY-MM-DD HH:mm:ss');
    StartDate = moment(from).format('YYYY-MM-DD');
    EndDate = moment(to).format('YYYY-MM-DD');
    let currentDate = moment(e).format('YYYY-MM-DD');
    let currentDateTime = moment(e).format('YYYY-MM-DD HH:mm:ss');
    if (
      moment(currentDate).isSame(
        moment(StartDate) ||
          moment(currentDateTime).hours() === moment(StartDateTime).hours() ||
          moment(currentDateTime).minutes() === moment(StartDateTime).minutes()
      )
    ) {
      for (var i = 0; i <= 24; i++) {
        if (i < moment(StartDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(currentDate).isSame(moment(StartDate))) {
        for (var j = 0; j <= 60; j++) {
          if (j < moment(StartDateTime).minutes()) {
            minutes.push(j);
          }
        }
      }
      if (moment(currentDate).isSame(moment(StartDate))) {
        for (var l = 0; l <= 60; l++) {
          if (l < moment(StartDateTime).seconds()) {
            seconds.push(l);
          }
        }
      }

      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes,
        disabledSeconds: () => seconds
      };
    } else if (
      moment(currentDate).isSame(
        moment(EndDate) ||
          moment(currentDateTime).hours() === moment(EndDateTime).hours() ||
          moment(currentDateTime).minutes() === moment(EndDateTime).minutes()
      )
    ) {
      for (var k = 0; k <= 24; k++) {
        if (k > moment(EndDateTime).hours()) {
          hours.push(k);
        }
      }
      if (moment(currentDate).isSame(moment(EndDate))) {
        for (var J = 0; J <= 60; J++) {
          if (J > moment(EndDateTime).minutes()) {
            minutes.push(J);
          }
        }
      }
      if (moment(currentDate).isSame(moment(EndDate))) {
        for (var K = 0; K <= 60; K++) {
          if (K > moment(EndDateTime).seconds()) {
            seconds.push(K);
          }
        }
      }
      // for (var k = 0; k < moment(EndDateTime).hours(); k++) {
      //   if (k < 25) {
      //     hours.push(k);
      //   }
      // }

      // if (moment(currentDate).isSame(moment(EndDate))) {
      //   for (var J = 0; J < moment(EndDateTime).minutes() + 1; J++) {
      //     if (J < 61) {
      //       minutes.push(J);
      //     }
      //   }
      // }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes,
        disabledSeconds: () => seconds
      };
    }
  };

  getDisabledHoursTask = (e, key) => {
    let hours = [];
    let minutes = [];
    let StartDateTime = '';
    let EndDateTime = '';
    let StartDate = '';
    let EndDate = '';

    if (key === 'StartTime') {
      StartDateTime = moment(e).format('YYYY-MM-DD HH:mm');
      EndDateTime = moment
        .utc(
          this.TaskRef.current.getFieldValue() && this.TaskRef.current.getFieldValue('EndTime'),
          'YYYY-MM-DD HH:mm'
        )
        .local();

      StartDate = moment(e).format('YYYY-MM-DD');
      EndDate = moment
        .utc(this.TaskRef.current.getFieldValue() && this.TaskRef.current.getFieldValue('EndTime'))
        .local()
        .format('YYYY-MM-DD');
    } else if (key === 'EndTime') {
      StartDateTime = moment
        .utc(
          this.TaskRef.current.getFieldValue() && this.TaskRef.current.getFieldValue('StartTime'),
          'YYYY-MM-DD HH:mm'
        )
        .local();
      EndDateTime = moment(e).format('YYYY-MM-DD HH:mm');

      StartDate = moment
        .utc(
          this.TaskRef.current.getFieldValue() && this.TaskRef.current.getFieldValue('StartTime')
        )
        .local()
        .format('YYYY-MM-DD');
      EndDate = moment(e).format('YYYY-MM-DD');
    }
    if (
      key === 'StartTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (let i = 0; i <= 24; i++) {
        if (i > moment(EndDateTime).hours()) {
          hours.push(i);
        }
      }
      if (moment(StartDate).isSame(moment(EndDate))) {
        for (var i = 0; i <= 60; i++) {
          if (i > moment(EndDateTime).minutes() - 1) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    } else if (
      key === 'EndTime' &&
      moment(StartDate).isSame(
        moment(EndDate) || moment(StartDateTime).hours() === moment(EndDateTime).hours()
      )
    ) {
      for (let i = 0; i < moment(StartDateTime).hours(); i++) {
        if (i < 25) {
          hours.push(i);
        }
      }

      if (moment(StartDate).isSame(moment(EndDate))) {
        for (let i = 0; i < moment(StartDateTime).minutes() + 1; i++) {
          if (i < 61) {
            minutes.push(i);
          }
        }
      }
      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    }
  };

  deviceOnchange = async (selectedDeviceList, formObject) => {
    let payload = {
      DeviceCode: selectedDeviceList,
      ModelId: formObject && formObject.ModelId ? formObject.ModelId : ''
    };
    let response = await RegisterModel(ApiList.getParameterByDevice, payload);
    this.setState({
      ParameterList:
        response && response.data && response.data.Operational ? response.data.Operational : []
    });
  };

  onParameterChange = (e) => {
    let { ParameterList } = this.state;
    let selectedDeviceParameter = [];
    ParameterList &&
      ParameterList !== undefined &&
      Array.isArray(ParameterList) &&
      ParameterList.filter((el) => {
        e.find((element) => {
          if (el.ParameterCode === element) {
            selectedDeviceParameter.push({
              DeviceCode: el.Device,
              ParameterCode: el.ParameterCode
            });
          }
        });
      });
    this.setState({ selectedDeviceParameter });
  };

  onTrendSubmit = async () => {
    let { selectedDeviceParameter } = this.state;
    let payload = {
      Type: 'historic',
      ViewType: 'Multiple',
      GraphType: 'Line',
      Operator: 'LAST',
      ParameterList: selectedDeviceParameter,
      FromDate:
        this.trendRef.current && this.trendRef.current.getFieldValue('FromDate')
          ? moment(this.trendRef.current.getFieldValue('FromDate'))
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
          : '',
      ToDate:
        this.trendRef.current && this.trendRef.current.getFieldValue('ToDate')
          ? moment(this.trendRef.current.getFieldValue('ToDate'))
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
          : '',
      Interval:
        this.trendRef.current && this.trendRef.current.getFieldValue('Interval')
          ? this.trendRef.current.getFieldValue('Interval')
          : ''
    };
    let response = await DeployModel(ApiList.generateTrend, payload);
    // if (response && response.status === 200) {
    this.setState({ trendData: response });
    // }
  };

  onTrendSubmitRealTime = async () => {
    let { selectedDeviceParameter } = this.state;
    let payload = {
      Type: 'historic',
      ViewType: 'Multiple',
      GraphType: 'Line',
      Operator: 'LAST',
      ParameterList: selectedDeviceParameter,
      FromDate:
        this.realTimeTrendRef.current && this.realTimeTrendRef.current.getFieldValue('FromDate')
          ? moment(this.realTimeTrendRef.current.getFieldValue('FromDate'))
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
          : '',
      ToDate:
        this.realTimeTrendRef.current && this.realTimeTrendRef.current.getFieldValue('ToDate')
          ? moment(this.realTimeTrendRef.current.getFieldValue('ToDate'))
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
          : '',
      Interval:
        this.realTimeTrendRef.current && this.realTimeTrendRef.current.getFieldValue('Interval')
          ? this.realTimeTrendRef.current.getFieldValue('Interval')
          : ''
    };
    let response = await DeployModel(ApiList.generateTrend, payload);
    // if (response && response.status === 200) {
    this.setState({ trendData: response });
    // }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={this.translation('Search') + this.translation(dataIndex)}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: '5px'
          }}
        >
          {this.translation('Search')}
        </Button>
        <Button
          onClick={() => clearFilters && this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {this.translation('Reset')}
        </Button>
        {/* <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            this.setState({
              searchText: selectedKeys[0],
              searchedColumn: dataIndex,
            });
          }}
        >
          Filter
        </Button> */}
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: '' });
  };

  handleDateInputChange = (dateString) => {
    let data =
      this.predictionRequestRef.current && this.predictionRequestRef.current.getFieldValue();
    let TrianedFrom = data.TrainPeriodFrom;
    let TrianedTo = data.TrainPeriodTo;
    let date = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
    if (date >= TrianedFrom && date <= TrianedTo) {
      message.error(
        'This date range overlaps with model training period. The prediction results may not reflect the accuracy of the unseen data. Do you want to continue?'
      );
    } else {
      return null;
    }
  };

  handleMetricDateInputChange = (dateString) => {
    let data =
      this.realTimePredictionRequestRef.current &&
      this.realTimePredictionRequestRef.current.getFieldValue();
    let FromTime = data.FromTime;
    let ToTime = data.ToTime;
    let date = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
    if (date >= FromTime && date <= ToTime) {
      message.error(
        'This date range overlaps with model training period. The prediction results may not reflect the accuracy of the unseen data. Do you want to continue?'
      );
    } else {
      return null;
    }
  };

  handleRealTimeDateInputChange = (dateString) => {
    let data = this.RealTimeModelRef.current && this.RealTimeModelRef.current.getFieldValue();
    let FromTime = data && data.FromTime;
    let ToTime = data && data.ToTime;

    let date = moment(dateString).format('YYYY-MM-DD HH:mm:ss');

    if (date >= FromTime && date <= ToTime) {
      message.error(
        'This date range overlaps with model training period. The prediction results may not reflect the accuracy of the unseen data. Do you want to continue?'
      );
    } else {
      return null;
    }
  };

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
  };

  handleRealtimeTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
  };

  onValueChange = (e) => {
    let value = e.target.value;
    this.setState({ value: value });
  };

  onValueChange1 = (e) => {
    let value = e.target.value;
    this.setState({ value1: value });
  };

  onValueChange2 = (e) => {
    let value = e.target.value;
    this.setState({ value2: value });
  };

  onConditionChage = (value) => {
    if (value && value !== undefined) {
      this.setState({ SelectedCondition: value });
    }
  };

  handleRecipientList = (value) => {
    if (value && Array.isArray(value) && value.length > 5) {
      message.error('Max 5 Users are allowed');
    } else {
      this.setState({ MailRecipient: value });
    }
  };

  handleContinous = (e) => {
    this.setState({ onContinous: e.target.checked });
  };

  handleFrequency = (value) => {
    let time = value._d.toTimeString().split(' ')[0];
    let splitTime = time.split(':');
    let frequencyTime =
      parseInt(splitTime[0] * 3600) + parseInt(splitTime[1] * 60) + parseInt(splitTime[2]);

    let frequency = {};
    // if (index === undefined) {
    // typeList = { ...this.state[type] };
    frequency = [frequencyTime, time];
    // } else {
    //   typeList = [...this.state[type]];
    //   typeList[index]['frequency'] = [frequencyTime, time];
    // }
    this.setState({ frequency });
  };

  checkonChange = (values) => {
    this.setState({ SelectedCommunication: values });
  };

  handleDelay = (value) => {
    let time = value._d.toTimeString().split(' ')[0];
    let splitTime = time.split(':');
    let delayTime =
      parseInt(splitTime[0] * 3600) + parseInt(splitTime[1] * 60) + parseInt(splitTime[2]);

    let delay = {};
    delay = [delayTime, time];
    this.setState({ delay });
  };

  onStatusCheck = (e) => {
    if (e.target.checked === true) {
      this.setState({
        Status: 'Active'
      });
    } else {
      this.setState({
        Status: 'Inactive'
      });
    }
  };
  QuickRunStopJob = async (record) => {
    let payload = {
      PredReqId: record.PredReqId
    };
    let response = await DeployModel(ApiList.realTimeStop, payload);
    message.success(response && response.message && this.translation(response.message));
    this.getRealTimeRefreshHistory();
  };
  getAnalyticsActionsLog = async (record) => {
    let payload = {
      PredReqId: record && record.PredReqId ? record.PredReqId : ''
    };
    // let payload = {
    //   "PredReqId": 27
    // };
    let ActoinsLogResponse = await DeployModel(ApiList.getAnalyticsActionsLog, payload);
    this.setState({ ActoinsLogDataSource: ActoinsLogResponse });
  };

  onStartClick = () => {
    let { RealTimeInfo } = this.state;
    this.realTimePredictionRequestRef.current &&
      this.realTimePredictionRequestRef.current.setFieldsValue({
        FromTime: moment()
      });
    RealTimeInfo = {
      ...RealTimeInfo,
      FromTime: moment()
    };
    this.setState({
      RealTimeInfo,
      RealTimeModel: true,
      ButtonKey: 'start'
    });
  };

  QuickRunResults = async (record) => {
    let QuickRunResults = await getMetaData(
      ApiList.getQuickrunResults,
      'PredReqId',
      record.PredReqId
    );

    this.QuickRunResultsRef.current &&
      this.QuickRunResultsRef.current.setFieldsValue({
        ActualValue: QuickRunResults && QuickRunResults[0] && QuickRunResults[0].ActualValue,
        PredictedValue: QuickRunResults && QuickRunResults[0] && QuickRunResults[0].PredictedValue
      });
    this.setState({
      QuickRunInfo: QuickRunResults && QuickRunResults[0] ? QuickRunResults[0] : {}
    });
  };

  onDataSourceChange = (value) => {
    this.setState({ DataSourceId: value });
  };

  getRealTimeFunctionalSummary = async (functionalSummaryFrom, functionalSummaryTo) => {
    let { realTimePredResInfo } = this.state;
    let payload = {
      PredReqId: realTimePredResInfo.PredReqId,
      startDate: moment(functionalSummaryFrom).utc().format('YYYY-MM-DD HH:mm'),
      endDate: moment(functionalSummaryTo).utc().format('YYYY-MM-DD HH:mm')
    };
    let response = await RealTimeGraphData(payload);
    if (response && Array.isArray(response) && response.length === 0) {
      message.info(this.translation('No Data'));
    }
    let selectionDateRange = {
      startDate: functionalSummaryFrom,
      endDate: functionalSummaryTo
    };
    this.setState({
      RealTimeFunctionalSummary: response && response !== undefined && response,
      momentRange: [functionalSummaryFrom, functionalSummaryTo],
      functionalSummaryFrom,
      functionalSummaryTo,
      selectionDateRange
      // differenceHours
    });
  };
  disabledDates = (current) => {
    return current > moment().endOf('day');
  };

  render() {
    const {
      formObject,
      modelVisible,
      SelectedCondition,
      DataSourceId,
      ModelDeployHistoryData,
      deployModelVisible,
      modelDeploymentInfo,
      ModelPredHistoryData,
      ModelPredRealtimeHistoryData,
      predictionModel,
      predictionRequestInfo,
      realTimePredictionRequestInfo,
      RealTimeInfo,
      resultsDrawerVisible,
      realTimeResultsDrawerVisible,
      realTimeQuickRunResultDrawer,
      metricsData,
      predictionREGResultColumns,
      predictionResultsData,
      realPredictionResultsData,
      predictionActiveKey,
      QuickRunInfo,
      predictionFromTime,
      predictionToTime,
      predResInfo,
      DeviceList,
      trendObj,
      ParameterList,
      trendData,
      MetricImagesData,
      MetricValuesData,
      MetricValuesData1,
      pagination,
      dailyFunctionalSummary,
      dailyFunctionalSummary1,
      weeklyFunctionalSummary,
      weeklyFunctionalSummary1,
      functionalSummaryDailyFrom,
      functionalSummaryDailyFrom1,
      functionalSummaryDailyTo,
      functionalSummaryDailyTo1,
      functionalSummaryWeeklyTo,
      functionalSummaryWeeklyTo1,
      functionalSummaryWeekCount,
      functionalSummaryWeekCount1,
      selectedRowKeys,
      selectedRows,
      RealTimeModel,
      RealTimeVal,
      ButtonKey,
      DataSourceList,
      realTimePredResInfo,
      RealTimeMetricResultVisible,
      QuickRunModal,
      recipientList,
      SelectedCommunication,
      Status,
      confMatrixVisible,
      ROCCurveVisible,
      ActoinsLogDataSource,
      realTimePredictionReqInfo,
      RealTimeFunctionalSummary,
      // functionalSummaryFrom,
      functionalSummaryTo,
      selectionDateRange
    } = this.state;
    const ModelDeployHistoryColumns = [
      {
        title: this.translation('Inference Server'),
        dataIndex: 'InferenceServer',
        key: 'InferenceServer',
        ...this.getColumnSearchProps('InferenceServer')
      },
      // {
      //   title: this.translation('Start Time'),
      //   dataIndex: 'DepStartTime',
      //   key: 'DepStartTime',
      //   ...this.getColumnSearchProps('DepStartTime'),
      //   sorter: (a, b) =>
      //     a.DepStartTime && b.DepStartTime && a.DepStartTime.localeCompare(b.DepStartTime),
      //   render: (text) => {
      //     return {
      //       children:
      //         text !== undefined && text !== null ? (
      //           <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //         ) : null
      //     };
      //   }
      // },
      // {
      //   title: this.translation('End Time'),
      //   dataIndex: 'DepEndTime',
      //   key: 'DepEndTime',
      //   ...this.getColumnSearchProps('DepEndTime'),
      //   sorter: (a, b) => a.DepEndTime && b.DepEndTime && a.DepEndTime.localeCompare(b.DepEndTime),
      //   render: (text) => {
      //     return {
      //       children:
      //         text !== undefined && text !== null ? (
      //           <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
      //         ) : null
      //     };
      //   }
      // },
      {
        title: this.translation('Deployed By'),
        dataIndex: 'CreatedName',
        key: 'CreatedName'
      },
      {
        title: this.translation('Deployed Date'),
        dataIndex: 'CreatedTime',
        key: 'CreatedTime',
        ...this.getColumnSearchProps('CreatedTime'),
        sorter: (a, b) =>
          a.CreatedTime && b.CreatedTime && a.CreatedTime.localeCompare(b.CreatedTime),
        render: (text) => {
          return {
            children:
              text !== undefined ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Status'),
        dataIndex: 'Status',
        key: 'Status',
        filters: [
          {
            text: this.translation('Initiated'),
            value: 'Initiated'
          },
          {
            text: this.translation('In-Progress'),
            value: 'In-Progress'
          },
          {
            text: this.translation('Completed Error'),
            value: 'Completed Error'
          },
          {
            text: this.translation('Completed'),
            value: 'Completed'
          }
        ],
        sorter: (a, b) => a.Status.length - b.Status.length,
        onFilter: (value, record) => record.Status === value
      }
      // {
      //   title: this.translation('Default'),
      //   dataIndex: 'Default',
      //   key: 'Default',
      //   filters: [
      //     {
      //       text: this.translation('True'),
      //       value: true
      //     },
      //     {
      //       text: this.translation('False'),
      //       value: false
      //     }
      //   ],
      //   filterResetToDefaultFilteredValue: false,
      //   onFilter: (value, record) => record.Default === value,
      //   // ...this.getColumnSearchProps("Default"),
      //   render: (text, record) =>
      //     record.Status === 'Completed' ? (
      //       <Popconfirm
      //         title={this.translation('Do you want to make this deployment as default?')}
      //         icon={<QuestionCircleOutlined />}
      //         onConfirm={() => this.defaultDeployConfirm(record)}
      //         onCancel={() => this.cancel()}
      //         okText={this.translation('Yes')}
      //         cancelText={this.translation('No')}
      //       >
      //         <Checkbox checked={text} />
      //       </Popconfirm>
      //     ) : (
      //       <Checkbox disabled checked={text} />
      //     )
      // }
    ];

    const ModelPredHistoryColumns = [
      {
        title: this.translation('Predicted Time From'),
        dataIndex: 'FromTime',
        key: 'FromTime',
        ...this.getColumnSearchProps('FromTime'),
        sorter: (a, b) => a.FromTime && b.FromTime && a.FromTime.localeCompare(b.FromTime),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Predicted Time To'),
        dataIndex: 'ToTime',
        key: 'ToTime',
        ...this.getColumnSearchProps('ToTime'),
        sorter: (a, b) => a.ToTime && b.ToTime && a.ToTime.localeCompare(b.ToTime),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Created By'),
        dataIndex: 'CreatedName',
        key: 'CreatedName',
        // filters: ,
        ...this.getColumnSearchProps('CreatedName'),
        sorter: (a, b) => a.CreatedName.length - b.CreatedName.length
        // onFilter: (value, record) => record.CreatedName === value,
      },
      {
        title: this.translation('Created Time'),
        dataIndex: 'CreatedTime',
        key: 'CreatedTime',
        ...this.getColumnSearchProps('CreatedTime'),
        sorter: (a, b) =>
          a.CreatedTime && b.CreatedTime && a.CreatedTime.localeCompare(b.CreatedTime),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Status'),
        dataIndex: 'Status',
        key: 'Status',
        filters: [
          {
            text: this.translation('Initiated'),
            value: 'Initiated'
          },
          {
            text: this.translation('In-Progress'),
            value: 'In-Progress'
          },
          {
            text: this.translation('Completed Error'),
            value: 'Completed Error'
          },
          {
            text: this.translation('Completed'),
            value: 'Completed'
          }
        ],
        onFilter: (value, record) => record.Status === value
      },
      {
        title: this.translation('Action'),
        render: (text, record) => (
          <>
            <a
              style={{ marginRight: '10px' }}
              onClick={() => {
                let ModelDetails = this.modelRef.current.getFieldsValue();
                _.cloneDeepWith(record, (value) => {
                  return value !== undefined && value !== null
                    ? {
                        ...value,
                        ...(value['FromTime']
                          ? (value['FromTime'] = moment.utc(value.FromTime, 'YYYY-MM-DD HH:mm:ss'))
                          : null),
                        ...(value['ToTime'] && value['ToTime'] !== null
                          ? (value['ToTime'] = moment.utc(value.ToTime, 'YYYY-MM-DD HH:mm:ss'))
                          : null)
                      }
                    : _.noop();
                });

                let deploymentData =
                  this.state.ModelDeployHistoryData &&
                  Array.isArray(this.state.ModelDeployHistoryData) &&
                  this.state.ModelDeployHistoryData.filter((item) => item.Default === true) &&
                  this.state.ModelDeployHistoryData.filter((item) => item.Default === true)[0];

                deploymentData = {
                  CreatedTime: moment(deploymentData.CreatedTime)
                    .utc()
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss'),
                  Virtualenv: deploymentData.Virtualenv
                };
                let date1 = new Date(record.FromTime);
                let date2 = new Date(record.ToTime);
                let data = {
                  ...record,
                  FromTime: moment(date1).utc().local(),
                  ToTime: moment(date2).utc().local()
                };
                ModelDetails = {
                  ...data,
                  ...ModelDetails,
                  ...deploymentData
                };
                this.predictionRequestRef.current &&
                  this.predictionRequestRef.current.setFieldsValue(ModelDetails);
                this.setState({
                  predictionModel: true,
                  predictionRequestInfo: ModelDetails
                });
              }}
            >
              {this.translation('Details')}
            </a>
            <a
              onClick={() => {
                if (record.Status === 'Completed') {
                  this.getMetricResults(record);
                  this.getPredictionResultsList(record);
                  this.setState({ predictionReqInfo: record });
                }
              }}
              disabled={record.Status === 'Completed' ? false : true}
            >
              {this.translation('Results')}
            </a>
          </>
        )
      }
    ];

    const ModelPredRealtimeColumns = [
      {
        title: this.translation('Start Time'),
        dataIndex: 'FromTime',
        key: 'FromTime',
        ...this.getColumnSearchProps('FromTime'),
        sorter: (a, b) => a.FromTime && b.FromTime && a.FromTime.localeCompare(b.FromTime),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('End Time'),
        dataIndex: 'ToTime',
        key: 'ToTime',
        ...this.getColumnSearchProps('ToTime'),
        sorter: (a, b) => a.ToTime && b.ToTime && a.ToTime.localeCompare(b.ToTime),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Type'),
        dataIndex: 'Type',
        key: 'Type',
        // filters: ,
        ...this.getColumnSearchProps('Type'),
        sorter: (a, b) => (a.Type && a.Type.length) - (b.Type && b.Type.length)
        // onFilter: (value, record) => record.CreatedName === value,
      },
      {
        title: this.translation('Status'),
        dataIndex: 'Status',
        key: 'Status',
        filters: [
          {
            text: this.translation('Initiated'),
            value: 'Initiated'
          },
          {
            text: this.translation('Scheduled'),
            value: 'Scheduled'
          },
          {
            text: this.translation('Running'),
            value: 'Running'
          },
          {
            text: this.translation('Stopped'),
            value: 'Stopped'
          },
          {
            text: this.translation('Completed'),
            value: 'Completed'
          }
        ],
        onFilter: (value, record) => record.Status === value
      },
      {
        title: this.translation('Created By'),
        dataIndex: 'CreatedName',
        key: 'CreatedName',
        ...this.getColumnSearchProps('CreatedName'),
        sorter: (a, b) =>
          a.CreatedName && b.CreatedName && a.CreatedName.localeCompare(b.CreatedName)
      },
      {
        title: this.translation('Created Time'),
        dataIndex: 'CreatedTime',
        key: 'CreatedTime',
        ...this.getColumnSearchProps('CreatedTime'),
        sorter: (a, b) =>
          a.CreatedTime && b.CreatedTime && a.CreatedTime.localeCompare(b.CreatedTime),
        render: (text) => {
          return {
            children:
              text !== undefined && text !== null ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },

      {
        title: this.translation('Action'),
        render: (text, record) => (
          <>
            {record.Status === 'Running' && record.Type !== 'QuickRun' ? (
              <Button
                key="edit"
                style={{ marginRight: 8 }}
                onClick={() => this.RealTimeEdit(record, 'edit')}
              >
                {this.translation('Edit')}
              </Button>
            ) : null}
            {record.Status === 'Running' && record.Type !== 'QuickRun' ? (
              <Button
                style={{ marginRight: 8 }}
                onClick={() => this.RealTimeStopJob(record, 'stop')}
              >
                {this.translation('Stop')}
              </Button>
            ) : null}

            {record.Status === 'Running' && record.Type === 'QuickRun' ? (
              <Button
                style={{ marginRight: 8 }}
                onClick={() => this.QuickRunStopJob(record, 'stop')}
              >
                {this.translation('Stop')}
              </Button>
            ) : null}

            {(record.Status === 'Running' ||
              record.Status === 'Stopped' ||
              record.Status === 'Completed') &&
            record.Type !== 'QuickRun' ? (
              <a
                onClick={() => {
                  this.setState({ realTimeResultsDrawerVisible: true });
                  let realTimePredictionRequestInfo = {
                    FromTime: moment(record.FromTime),
                    ToTime: moment(record.ToTime),
                    Metrics: record.Metrics
                  };
                  this.realTimePredictionRequestRef.current &&
                    this.realTimePredictionRequestRef.current.setFieldsValue({
                      FromTime: moment(record.FromTime),
                      ToTime: moment(record.ToTime),
                      Metrics: record.Metrics
                    });
                  this.setState({ realTimePredictionRequestInfo });
                  if (
                    (record.Status === 'Running' ||
                      record.Status === 'Stopped' ||
                      record.Status === 'Completed') &&
                    record.Type !== 'QuickRun'
                  ) {
                    // this.getRealTimeMetricResults(record);
                    this.getRealTimePredictionResultsList(record);
                    this.getAnalyticsActionsLog(record);
                    this.setState({ realTimePredictionReqInfo: record });
                  }
                }}
                // disabled={record.Status === "Completed" ? false : true}
              >
                {this.translation('Results')}
              </a>
            ) : null}
            {(record.Status === 'Running' ||
              record.Status === 'Stopped' ||
              record.Status === 'Completed') &&
            record.Type === 'QuickRun' ? (
              <a
                onClick={() => {
                  this.QuickRunResults(record);
                  this.setState({ realTimeQuickRunResultDrawer: true });
                }}
              >
                {this.translation('Results')}
              </a>
            ) : null}
          </>
        )
      }
    ];

    const predictionCLFResultColumns = [
      {
        title: this.translation('Current Time'),
        dataIndex: 'CurrentTime',
        hide: true,
        key: 'CurrentTime',
        ...this.getColumnSearchProps('CurrentTime'),
        sorter: (a, b) =>
          a.CurrentTime && b.CurrentTime && a.CurrentTime.localeCompare(b.CurrentTime),
        render: (text) => {
          return {
            children:
              text !== undefined ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Predicted From'),
        dataIndex: 'predTimeFrom',
        key: 'predTimeFrom',
        ...this.getColumnSearchProps('predTimeFrom'),
        sorter: (a, b) =>
          a.predTimeFrom && b.predTimeFrom && a.predTimeFrom.localeCompare(b.predTimeFrom),
        render: (text) => {
          return {
            children:
              text !== undefined ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Predicted To'),
        dataIndex: 'predTimeTo',
        key: 'predTimeTo',
        ...this.getColumnSearchProps('predTimeTo'),
        sorter: (a, b) => a.predTimeTo && b.predTimeTo && a.predTimeTo.localeCompare(b.predTimeTo),
        render: (text) => {
          return {
            children:
              text !== undefined ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },

      {
        title: this.translation('Predicted Status'),
        dataIndex: 'PredictedStatus',
        key: 'PredictedStatus',
        filters: [
          {
            text: this.translation('NORMAL'),
            value: 'NORMAL'
          },
          {
            text: this.translation('ABNORMAL'),
            value: 'ABNORMAL'
          }
        ],
        onFilter: (value, record) => record.PredictedStatus === value
      },
      {
        title: this.translation('Actual Status'),
        dataIndex: 'ActualStatus',
        key: 'ActualStatus',
        filters: [
          {
            text: this.translation('NORMAL'),
            value: 'NORMAL'
          },
          {
            text: this.translation('ABNORMAL'),
            value: 'ABNORMAL'
          }
        ],
        onFilter: (value, record) => record.ActualStatus === value
      },
      {
        title: this.translation('View Trends'),
        render: (record) => (
          <Tooltip title={this.translation('Trends')}>
            <Button
              data-testid={'lift-hold-button'}
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => {
                let trendObj = this.trendRef.current && this.trendRef.current.getFieldsValue();
                let CurrentTime = moment.utc(record.CurrentTime, 'YYYY-MM-DD HH:mm:ss');
                let fromDate = moment(CurrentTime)
                  .subtract(1, 'hours')
                  .format('YYYY-MM-DD HH:mm:ss');
                let toDate = moment(CurrentTime).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
                let defaultParameter = [];
                this.state.ParameterList &&
                  this.state.ParameterList !== undefined &&
                  Array.isArray(this.state.ParameterList) &&
                  this.state.ParameterList.map((item, index) => {
                    if (index < 5) {
                      defaultParameter.push(item.ParameterCode);
                    }
                  });
                trendObj = {
                  ...trendObj,
                  DeviceCode:
                    this.state.DeviceList &&
                    this.state.DeviceList[0] &&
                    this.state.DeviceList[0]['Device']
                      ? [this.state.DeviceList[0]['Device']]
                      : [],
                  ParameterCode: defaultParameter,
                  FromDate: moment.utc(fromDate, 'YYYY-MM-DD HH:mm:ss').local(),
                  ToDate: moment.utc(toDate, 'YYYY-MM-DD HH:mm:ss').local(),
                  CurrentTime: moment.utc(CurrentTime, 'YYYY-MM-DD HH:mm:ss').local(),
                  PredictionTargetFrom: moment(CurrentTime)
                    .utc()
                    .local()
                    .add(
                      this.state.formObject && this.state.formObject.FuturePredRangeFrom,
                      'minutes'
                    )
                    .format('DD-MM-YYYY HH:mm:ss'),
                  PredictionTargetTo: moment(CurrentTime)
                    .utc()
                    .local()
                    .add(
                      this.state.formObject && this.state.formObject.FuturePredRangeTo,
                      'minutes'
                    )
                    .format('DD-MM-YYYY HH:mm:ss'),
                  Interval:
                    IntervalData && IntervalData[0] && IntervalData[0]['value']
                      ? IntervalData[0]['value']
                      : ''
                };
                this.onParameterChange(defaultParameter);
                this.trendRef.current && this.trendRef.current.setFieldsValue(trendObj);
                this.setState({ trendObj, predictionActiveKey: 'Trends' });
              }}
              size="middle"
            >
              <span style={{ color: '#1890ff' }}>
                <LineChartOutlined />
              </span>
            </Button>
          </Tooltip>
        )
      }
    ];

    const realTimePredictionCLFResultColumns = [
      {
        title: this.translation('Current Time'),
        dataIndex: 'CurrentTime',
        hide: true,
        key: 'CurrentTime',
        ...this.getColumnSearchProps('CurrentTime'),
        sorter: (a, b) =>
          a.CurrentTime && b.CurrentTime && a.CurrentTime.localeCompare(b.CurrentTime),
        render: (text) => {
          return {
            children:
              text !== undefined ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Predicted From'),
        dataIndex: 'predTimeFrom',
        key: 'predTimeFrom',
        ...this.getColumnSearchProps('predTimeFrom'),
        sorter: (a, b) =>
          a.predTimeFrom && b.predTimeFrom && a.predTimeFrom.localeCompare(b.predTimeFrom),
        render: (text) => {
          return {
            children:
              text !== undefined ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },
      {
        title: this.translation('Predicted To'),
        dataIndex: 'predTimeTo',
        key: 'predTimeTo',
        ...this.getColumnSearchProps('predTimeTo'),
        sorter: (a, b) => a.predTimeTo && b.predTimeTo && a.predTimeTo.localeCompare(b.predTimeTo),
        render: (text) => {
          return {
            children:
              text !== undefined ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },

      {
        title: this.translation('Predicted Status'),
        dataIndex: 'PredictedStatus',
        key: 'PredictedStatus',
        filters: [
          {
            text: this.translation('NORMAL'),
            value: 'NORMAL'
          },
          {
            text: this.translation('ABNORMAL'),
            value: 'ABNORMAL'
          }
        ],
        onFilter: (value, record) => record.PredictedStatus === value
      },
      {
        title: this.translation('Actual Status'),
        dataIndex: 'ActualStatus',
        key: 'ActualStatus',
        filters: [
          {
            text: this.translation('NORMAL'),
            value: 'NORMAL'
          },
          {
            text: this.translation('ABNORMAL'),
            value: 'ABNORMAL'
          }
        ],
        onFilter: (value, record) => record.ActualStatus === value
      },
      {
        title: this.translation('View Trends'),
        render: (record) => (
          <Tooltip title={this.translation('Trends')}>
            <Button
              data-testid={'lift-hold-button'}
              style={{ marginRight: '5px' }}
              className="ant-btn"
              onClick={() => {
                let trendObj =
                  this.realTimeTrendRef.current && this.realTimeTrendRef.current.getFieldsValue();
                let CurrentTime = moment.utc(record.CurrentTime, 'YYYY-MM-DD HH:mm:ss');
                let fromDate = moment(CurrentTime)
                  .subtract(1, 'hours')
                  .format('YYYY-MM-DD HH:mm:ss');
                let toDate = moment(CurrentTime).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss');
                let defaultParameter = [];
                this.state.ParameterList &&
                  this.state.ParameterList !== undefined &&
                  Array.isArray(this.state.ParameterList) &&
                  this.state.ParameterList.map((item, index) => {
                    if (index < 5) {
                      defaultParameter.push(item.ParameterCode);
                    }
                  });
                trendObj = {
                  ...trendObj,
                  DeviceCode:
                    this.state.DeviceList &&
                    this.state.DeviceList[0] &&
                    this.state.DeviceList[0]['Device']
                      ? [this.state.DeviceList[0]['Device']]
                      : [],
                  ParameterCode: defaultParameter,
                  FromDate: moment.utc(fromDate, 'YYYY-MM-DD HH:mm:ss').local(),
                  ToDate: moment.utc(toDate, 'YYYY-MM-DD HH:mm:ss').local(),
                  CurrentTime: moment.utc(CurrentTime, 'YYYY-MM-DD HH:mm:ss').local(),
                  PredictionTargetFrom: moment(CurrentTime)
                    .utc()
                    .local()
                    .add(
                      this.state.formObject && this.state.formObject.FuturePredRangeFrom,
                      'minutes'
                    )
                    .format('DD-MM-YYYY HH:mm:ss'),
                  PredictionTargetTo: moment(CurrentTime)
                    .utc()
                    .local()
                    .add(
                      this.state.formObject && this.state.formObject.FuturePredRangeTo,
                      'minutes'
                    )
                    .format('DD-MM-YYYY HH:mm:ss'),
                  Interval:
                    IntervalData && IntervalData[0] && IntervalData[0]['value']
                      ? IntervalData[0]['value']
                      : ''
                };
                this.onParameterChange(defaultParameter);
                this.realTimeTrendRef.current &&
                  this.realTimeTrendRef.current.setFieldsValue(trendObj);
                this.setState({ trendObj, predictionActiveKey: 'Trends' });
              }}
              size="middle"
            >
              <span style={{ color: '#1890ff' }}>
                <LineChartOutlined />
              </span>
            </Button>
          </Tooltip>
        )
      }
    ];

    const ActionsHistoryColumns = [
      {
        title: this.translation('Status'),
        dataIndex: 'Status',
        hide: true,
        key: 'Status',
        filters: [
          {
            text: this.translation('Active'),
            value: 'Active'
          },
          {
            text: this.translation('Inactive'),
            value: 'Inactive'
          }
        ],
        onFilter: (value, record) => record.Status === value
      },
      {
        title: this.translation('Recipients'),
        dataIndex: 'Recipients',
        key: 'Recipients'
        // ...this.getColumnSearchProps('Recipients'),
      },
      {
        title: this.translation('Notification Time'),
        dataIndex: 'NotificationTime',
        key: 'NotificationTime',
        ...this.getColumnSearchProps('NotificationTime'),
        sorter: (a, b) =>
          a.NotificationTime &&
          b.NotificationTime &&
          a.NotificationTime.localeCompare(b.NotificationTime),
        render: (text) => {
          return {
            children:
              text !== undefined ? (
                <div>{moment(text).utc().local().format('YYYY-MM-DD HH:mm:ss')}</div>
              ) : null
          };
        }
      },

      {
        title: this.translation('Channel'),
        dataIndex: 'Type',
        key: 'Type'
      }
    ];

    const MetricResultColumns = [
      {
        title: this.translation('Metrics'),
        dataIndex: 'MetricCode',
        key: 'MetricCode'
      },
      {
        title: this.translation('Value'),
        dataIndex: 'MetricValue',
        key: 'MetricValue'
      }
    ];

    const RealTimeMetricResultColumns = [
      {
        title: this.translation('Metrics'),
        dataIndex: 'MetricCode',
        key: 'MetricCode'
      },
      {
        title: this.translation('Value'),
        dataIndex: 'MetricValue',
        key: 'MetricValue'
      }
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        if (
          selectedRows &&
          selectedRows[0] &&
          selectedRows[0]['Status'] &&
          selectedRows[0]['Status'] === 'Completed'
        ) {
          this.setState({ selectedRowKeys, selectedRows });
        } else {
          message.error(this.translation('Please Select Completed Prediction To Compare'));
          this.setState({ selectedRowKeys: [], selectedRows: [] });
        }
      },
      getCheckboxProps: (record) => ({
        //   disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.PredReqId
      })
    };
    const rowSelectionRealTime = {
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        if (
          selectedRows &&
          selectedRows[0] &&
          selectedRows[0]['Status'] &&
          selectedRows[0]['Status'] === 'Completed'
        ) {
          this.setState({ selectedRowKeys, selectedRows });
        } else {
          message.error(this.translation('Please Select Completed Prediction To Compare'));
          this.setState({ selectedRowKeys: [], selectedRows: [] });
        }
      },
      getCheckboxProps: (record) => ({
        //   disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.PredReqId
      })
    };

    const validateMessages = {
      required: this.translation('${label}') + this.translation('is required!')
    };
    const formItemLayout = { width: '400px', flex: '50%' };
    let pathname = history.location.pathname;
    return (
      <StyledComponent
        theme={themeSettings}
        style={{
          padding: '50px 30px',
          minHeight: window.innerHeight - 143
        }}
      >
        {/* =========== Main Form of Model Details ============ */}
        <StyledForm
          name="ModelDetails"
          ref={this.modelRef}
          autoComplete="off"
          initialValues={formObject}
          theme={themeSettings}
          layout="horizontal"
          labelCol={{ flex: '200px' }}
          labelAlign="right"
          onFinish={this.onFormSubmit}
          onFinishFailed={this.onFinishFailed}
          labelWrap
          validateMessages={validateMessages}
          wrapperCol={{ flex: 1 }}
        >
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              <Popconfirm
                placement="topLeft"
                title={this.translation('Sure to cancel?')}
                onConfirm={() => {
                  history.goBack();
                }}
                okText={this.translation('Yes')}
                cancelText={this.translation('No')}
              >
                <Button style={{ marginRight: 8 }} theme={themeSettings} size="middle">
                  {this.translation('Cancel')}
                </Button>
              </Popconfirm>
            </Form.Item>
            <Form.Item>
              <Button
                theme={themeSettings}
                type="primary"
                htmlType="submit"
                data-testid="submitButton"
              >
                {pathname.includes('RegisterModel')
                  ? this.translation('Register')
                  : this.translation('Submit')}
              </Button>
            </Form.Item>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item
              label={this.translation('Model Name')}
              name="ModelName"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input style={{ width: '70%' }} disabled type="text" />
            </Form.Item>
            <Form.Item
              label={this.translation('Description')}
              name="ModelDescription"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input style={{ width: '70%' }} disabled />
            </Form.Item>
            <Form.Item
              label={this.translation('Model Group')}
              name="ModelGroup"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input style={{ width: '70%' }} disabled />
            </Form.Item>
            <Form.Item
              label={this.translation('Model Type')}
              name="ModelType"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input style={{ width: '70%' }} disabled />
            </Form.Item>
            <Form.Item
              label={this.translation('Target Parameter')}
              name="TargetParameter"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input style={{ width: '70%' }} disabled />
            </Form.Item>
            <Form.Item
              label={this.translation('Target Parameter Type')}
              name="TargetParameterType"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input style={{ width: '70%' }} disabled />
            </Form.Item>
            <Form.Item
              label={this.translation('Staging Source')}
              name="StagingAreaSourceId"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input style={{ width: '70%' }} disabled />
            </Form.Item>
            <Form.Item
              label={this.translation('Staging Source Path')}
              name="StagingAreaSourcePath"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input style={{ width: '70%' }} disabled />
            </Form.Item>
            <Form.Item
              label={this.translation('Registered By')}
              name="CreatedName"
              rules={[{ required: pathname.includes('RegisterModel') ? false : true }]}
              style={formItemLayout}
            >
              <Input style={{ width: '70%' }} disabled />
            </Form.Item>
            <Form.Item
              label={this.translation('Registered Date')}
              name="CreatedTime"
              rules={[{ required: pathname.includes('RegisterModel') ? false : true }]}
              style={formItemLayout}
            >
              <Input style={{ width: '70%' }} disabled />
            </Form.Item>
            {/* <Form.Item label="Virtual Environment" name="virtual_environment" rules={[{ required: true }]} style={formItemLayout}>
                            <Input style={{ width: "70%" }} />
                        </Form.Item> */}
            <Form.Item
              label={this.translation('Status')}
              name="Status"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input style={{ width: '70%' }} disabled />
            </Form.Item>
            <Form.Item
              label={this.translation('Enabled')}
              name="Enabled"
              valuePropName="checked"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Switch />
            </Form.Item>
            <Form.Item label={this.translation('Comments')} name="comments" style={formItemLayout}>
              <TextArea style={{ width: '70%' }} />
            </Form.Item>
            <Form.Item style={formItemLayout} label={this.translation('Drift')}>
              <Button
                onClick={() =>
                  this.props.history.push({
                    pathname: '/rubus/RbsAnalytics/MonitorDrift',
                    // search: '?name=sudheer',
                    state: {
                      ModelName:
                        formObject && formObject['ModelName'] ? formObject['ModelName'] : '',
                      relatedTags: formObject.RelatedTagsList ? formObject.RelatedTagsList : [],
                      FromTime:
                        formObject && formObject.TrainPeriodFrom ? formObject.TrainPeriodFrom : '',
                      ToTime: formObject && formObject.TrainPeriodTo ? formObject.TrainPeriodTo : ''
                    }
                  })
                }
              >
                {this.translation('Calculate Drift')}
              </Button>
            </Form.Item>
            <Form.Item style={formItemLayout} name="ModelId"></Form.Item>
          </div>

          {/* ======== (Details,Deployment History,Batch Prediction,RealTime Tabs)======== */}
          <StyledSubForm theme={themeSettings}>
            <StyledTabs
              theme={themeSettings}
              type="card"
              onChange={() => {
                const pager = { ...this.state.pagination };
                pager.current = 1;
                this.setState({ pagination: pager });
              }}
              className="TabsText"
            >
              <TabPane tab={this.translation('Details')} key="Details">
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '24px' }}>
                  <Form.Item
                    label={this.translation('Train Period')}
                    name="TrainPeriodFrom"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Input.Group compact>
                      <Form.Item name="TrainPeriodFrom" rules={[{ required: true }]}>
                        <Input style={{ textAlign: 'center', width: 190 }} disabled />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 50,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('To')}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item name="TrainPeriodTo" rules={[{ required: true }]}>
                        <Input
                          style={{
                            textAlign: 'center',
                            borderLeft: 0,
                            width: 190
                          }}
                          disabled
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Evaluation Period')}
                    name="EvalPeriodFrom"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Input.Group compact>
                      <Form.Item name="EvalPeriodFrom">
                        <Input style={{ textAlign: 'center', width: 190 }} disabled />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 50,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('To')}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item name="EvalPeriodTo">
                        <Input
                          style={{
                            textAlign: 'center',
                            borderLeft: 0,
                            width: 190
                          }}
                          disabled
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Future Prediction')}
                    name="FuturePredRangeFrom"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Input.Group compact>
                      <Form.Item name="FuturePredRangeFrom">
                        <Input style={{ textAlign: 'center', width: 85 }} disabled />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 50,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('To')}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item name="FuturePredRangeTo">
                        <Input
                          style={{
                            textAlign: 'center',
                            borderLeft: 0,
                            width: 85
                          }}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 100,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('UOM')}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item name="FuturePredRangeUom">
                        <Input
                          style={{
                            textAlign: 'center',
                            borderLeft: 0,
                            width: 110
                          }}
                          disabled
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Look back period')}
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Input.Group compact>
                      <Form.Item name="LookBackPeriod">
                        <Input style={{ textAlign: 'center', width: 200 }} disabled />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 100,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('UOM')}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item name="LookBackPeriodUom">
                        <Input
                          style={{
                            textAlign: 'center',
                            borderLeft: 0,
                            width: 130
                          }}
                          disabled
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Realated Tags')}
                    name="RelatedTagsList"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Button type="dashed" onClick={() => this.setState({ modelVisible: true })}>
                      {'...'}
                    </Button>
                  </Form.Item>
                </div>
                <StyledModal
                  open={modelVisible}
                  theme={themeSettings}
                  width={800}
                  closable
                  title={this.translation('Realated Tags')}
                  onCancel={() => this.setState({ modelVisible: false })}
                  footer={[
                    <Button key="back" onClick={() => this.setState({ modelVisible: false })}>
                      {this.translation('Cancel')}
                    </Button>
                  ]}
                >
                  <div style={{ height: '70%', marginTop: '10px' }}>
                    <Tree showLine defaultExpandAll={true} switcherIcon={<DownOutlined />}>
                      {this.renderTreeNodes(
                        this.renderTree(
                          this.modelRef.current &&
                            this.modelRef.current.getFieldValue('RelatedTagsList')
                        )
                      )}
                    </Tree>
                  </div>
                </StyledModal>
              </TabPane>
              <TabPane tab={this.translation('Deployment History')} key="Deployment History">
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: '24px' }}>
                  <Form.Item>
                    <Button
                      onClick={() => this.getDeployHistory()}
                      style={{ marginRight: 8 }}
                      theme={themeSettings}
                      size="middle"
                    >
                      {this.translation('Refresh')}
                    </Button>
                  </Form.Item>
                  {formObject &&
                  formObject.Status &&
                  formObject.Status === 'Registered' &&
                  formObject.Enabled &&
                  formObject.Enabled === true ? (
                    <Form.Item>
                      <Button
                        theme={themeSettings}
                        size="middle"
                        style={{ marginRight: 8 }}
                        type="primary"
                        onClick={() => {
                          this.setState({ deployModelVisible: true });
                        }}
                      >
                        {this.translation('Deploy')}
                      </Button>
                    </Form.Item>
                  ) : null}
                </div>
                <StyledTable
                  pagination={pagination}
                  onChange={this.handleTableChange}
                  theme={themeSettings}
                  dataSource={
                    ModelDeployHistoryData && Array.isArray(ModelDeployHistoryData)
                      ? ModelDeployHistoryData
                      : []
                  }
                  columns={ModelDeployHistoryColumns}
                ></StyledTable>
              </TabPane>
              <TabPane tab={this.translation('Batch Inference')} key="Back test predictions">
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: '24px' }}>
                  <Form.Item>
                    <Button
                      onClick={() => this.getPredictionHistory()}
                      style={{ marginRight: 8 }}
                      theme={themeSettings}
                      size="middle"
                    >
                      {this.translation('Refresh')}
                    </Button>
                  </Form.Item>

                  {formObject &&
                  formObject.Status &&
                  formObject.Status === 'Registered' &&
                  formObject.Enabled &&
                  formObject.Enabled === true ? (
                    <Form.Item>
                      <Button
                        theme={themeSettings}
                        size="middle"
                        style={{ marginRight: 8 }}
                        type="primary"
                        // disabled={
                        //   ModelDeployHistoryData &&
                        //   ModelDeployHistoryData.filter(
                        //     (item) =>
                        //       item.Default === true &&
                        //       item.Status === "Completed"
                        //   ).length === 0
                        //     ? true
                        //     : false
                        // }
                        onClick={() => {
                          let ModelDetails =
                            this.modelRef.current && this.modelRef.current.getFieldsValue();
                          let deploymentData =
                            ModelDeployHistoryData &&
                            Array.isArray(ModelDeployHistoryData) &&
                            ModelDeployHistoryData.filter((item) => item.Default === true) &&
                            ModelDeployHistoryData.filter((item) => item.Default === true)[0];
                          this.predictionRequestRef.current &&
                            this.predictionRequestRef.current.setFieldsValue({});
                          deploymentData = {
                            CreatedTime: moment(deploymentData && deploymentData.CreatedTime)
                              .utc()
                              .local()
                              .format('YYYY-MM-DD HH:mm:ss'),
                            Virtualenv: deploymentData && deploymentData.Virtualenv
                          };
                          ModelDetails = {
                            ...ModelDetails,
                            ...deploymentData
                          };
                          this.predictionRequestRef.current &&
                            this.predictionRequestRef.current.setFieldsValue(ModelDetails);
                          this.setState({
                            predictionModel: true,
                            predictionRequestInfo: ModelDetails
                          });
                        }}
                      >
                        {this.translation('New Request')}
                      </Button>
                    </Form.Item>
                  ) : null}
                  {selectedRowKeys &&
                  Array.isArray(selectedRowKeys) &&
                  selectedRowKeys.length !== 0 ? (
                    <>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() =>
                            this.props.history.push({
                              pathname: '/rubus/RbsAnalytics/ModelComparision',
                              // search: '?name=sudheer',
                              state: {
                                ModelName_1:
                                  formObject && formObject['ModelName']
                                    ? formObject['ModelName']
                                    : '',
                                PredReqId_1:
                                  selectedRowKeys &&
                                  Array.isArray(selectedRowKeys) &&
                                  selectedRowKeys[0]
                                    ? selectedRowKeys[0]
                                    : '',
                                FromTime:
                                  selectedRows && selectedRows[0] && selectedRows[0].FromTime
                                    ? selectedRows[0].FromTime
                                    : '',
                                ToTime:
                                  selectedRows && selectedRows[0] && selectedRows[0].ToTime
                                    ? selectedRows[0].ToTime
                                    : ''
                              }
                            })
                          }
                          style={{ marginRight: 8 }}
                          theme={themeSettings}
                          size="middle"
                        >
                          {this.translation('Compare Models')}
                        </Button>
                      </Form.Item>
                    </>
                  ) : null}
                </div>
                <StyledTable
                  rowSelection={{
                    type: 'radio',
                    ...rowSelection
                  }}
                  pagination={pagination}
                  onChange={this.handleTableChange}
                  autoResetPage={false}
                  theme={themeSettings}
                  dataSource={
                    ModelPredHistoryData && Array.isArray(ModelPredHistoryData)
                      ? ModelPredHistoryData
                      : []
                  }
                  columns={ModelPredHistoryColumns}
                ></StyledTable>
              </TabPane>
              <TabPane tab={this.translation('Real Time Inference')} key="Realtime Inference">
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: '10px' }}>
                  <Form.Item>
                    <Button
                      onClick={() => this.getRealTimeRefreshHistory()}
                      style={{ marginRight: 8 }}
                      theme={themeSettings}
                      size="middle"
                    >
                      {this.translation('Refresh')}
                    </Button>
                  </Form.Item>
                  <>
                    <Form.Item>
                      <Button
                        theme={themeSettings}
                        size="middle"
                        style={{ marginRight: 8 }}
                        type="primary"
                        disabled={RealTimeVal}
                        onClick={this.onStartClick}
                      >
                        {this.translation('Start')}
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        theme={themeSettings}
                        size="middle"
                        // style={{ marginRight: 8 }}
                        type="primary"
                        onClick={() => {
                          this.setState({ QuickRunModal: true });
                        }}
                      >
                        {this.translation('Quick Run')}
                      </Button>
                    </Form.Item>
                  </>
                  {selectedRowKeys &&
                  Array.isArray(selectedRowKeys) &&
                  selectedRowKeys.length !== 0 ? (
                    <>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() =>
                            this.props.history.push({
                              pathname: '/rubus/RbsAnalytics/ModelComparision',
                              // search: '?name=sudheer',
                              state: {
                                ModelName_1:
                                  formObject && formObject['ModelName']
                                    ? formObject['ModelName']
                                    : '',
                                PredReqId_1:
                                  selectedRowKeys &&
                                  Array.isArray(selectedRowKeys) &&
                                  selectedRowKeys[0]
                                    ? selectedRowKeys[0]
                                    : '',
                                FromTime:
                                  selectedRows && selectedRows[0] && selectedRows[0].FromTime
                                    ? selectedRows[0].FromTime
                                    : '',
                                ToTime:
                                  selectedRows && selectedRows[0] && selectedRows[0].ToTime
                                    ? selectedRows[0].ToTime
                                    : ''
                              }
                            })
                          }
                          style={{ marginLeft: 8 }}
                          theme={themeSettings}
                          size="middle"
                        >
                          {this.translation('Compare Models')}
                        </Button>
                      </Form.Item>
                    </>
                  ) : null}
                </div>
                <StyledTable
                  rowSelection={{
                    type: 'radio',
                    ...rowSelectionRealTime
                  }}
                  pagination={pagination}
                  onChange={this.handleRealtimeTableChange}
                  autoResetPage={false}
                  theme={themeSettings}
                  dataSource={
                    ModelPredRealtimeHistoryData && Array.isArray(ModelPredRealtimeHistoryData)
                      ? ModelPredRealtimeHistoryData
                      : []
                  }
                  columns={ModelPredRealtimeColumns}
                ></StyledTable>
              </TabPane>
            </StyledTabs>
          </StyledSubForm>
        </StyledForm>

        {/* ================== DeployMent History Deploy Form =========== */}
        <StyledModal
          width="700px"
          title={this.translation('Deploy Model')}
          open={deployModelVisible}
          onCancel={() => this.setState({ deployModelVisible: false })}
          closable
          theme={themeSettings}
          footer={[
            <>
              <Button
                key="back"
                onClick={() => {
                  this.modelDeployRef.current &&
                    this.modelDeployRef.current.setFieldsValue({
                      // Virtualenv: '',
                      // Default: false,
                      SourceServer: '',
                      TargetServer: ''
                    });
                  this.setState({
                    deployModelVisible: false,
                    modelDeploymentInfo: {}
                  });
                }}
              >
                {' '}
                {this.translation('Cancel')}{' '}
              </Button>
              <Button key="submit" type="primary" onClick={() => this.buttonRef.current.click()}>
                {' '}
                {this.translation('Submit')}{' '}
              </Button>
            </>
          ]}
        >
          {/* <div style={{ display: "flex", flexWrap: "wrap" }}> */}
          <StyledForm
            name="ModelDeployment"
            ref={this.modelDeployRef}
            autoComplete="off"
            initialValues={modelDeploymentInfo}
            theme={themeSettings}
            layout="horizontal"
            labelCol={{ flex: '200px' }}
            labelAlign="right"
            onFinish={this.onDeploySubmit}
            onFinishFailed={this.onFinishFailed}
            labelWrap
            validateMessages={validateMessages}
            wrapperCol={{ flex: 1 }}
          >
            {/* <Form.Item
              // label={this.translation('Virtual Environment')}
              name="Virtualenv"
              rules={[{ required: true }]}
              style={{ width: '600px', marginLeft: '100px', marginTop: '24px' }}
            >
              <Input placeholder={this.translation('Input Virtual Environment')} />
            </Form.Item>
            <Form.Item label={this.translation('Default')} name="Default" valuePropName="checked">
              <Switch />
            </Form.Item> */}
            <Form.Item
              label={this.translation('Source Server')}
              name="SourceServer"
              rules={[{ required: true }]}
            >
              <Input
                placeholder={this.translation('Input Source Server')}
                style={{ width: '50%' }}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Target Server')}
              name="TargetServer"
              rules={[{ required: true }]}
            >
              <Input
                placeholder={this.translation('Input Target Server')}
                style={{ width: '50%' }}
              />
              {/* <Select placeholder={this.translation('Select Target Server')} style={{width:'50%'}} >
              </Select> */}
            </Form.Item>
            <Form.Item>
              <Button ref={this.buttonRef} htmlType="submit" style={{ display: 'none' }}></Button>
            </Form.Item>
          </StyledForm>
          {/* </div> */}
        </StyledModal>

        {/* ================ Batch Prediction NewRequest Form in Modal=========== */}
        <StyleModal
          // width="1500px"
          title={this.translation('Prediction Request')}
          open={predictionModel}
          onCancel={() => {
            this.setState({
              predictionRequestInfo: {},
              predictionModel: false
            });
            this.predictionRequestRef.current &&
              this.predictionRequestRef.current.setFieldsValue({
                FromTime: null,
                ToTime: null
              });
          }}
          closable
          theme={themeSettings}
          footer={[
            <>
              <Button
                key="back"
                onClick={() => {
                  this.setState({
                    predictionRequestInfo: {},
                    predictionModel: false
                  });
                  this.predictionRequestRef.current &&
                    this.predictionRequestRef.current.setFieldsValue({
                      FromTime: null,
                      ToTime: null
                    });
                }}
              >
                {' '}
                {predictionRequestInfo && predictionRequestInfo.PredReqId
                  ? this.translation('Close')
                  : this.translation('Cancel')}
              </Button>
              {predictionRequestInfo && predictionRequestInfo.PredReqId ? null : (
                <Button
                  key="submit"
                  type="primary"
                  onClick={() =>
                    this.predictionButtonRef.current && this.predictionButtonRef.current.click()
                  }
                >
                  {' '}
                  {this.translation('Submit')}{' '}
                </Button>
              )}
            </>
          ]}
        >
          <div style={{ width: '1300px' }}>
            <StyledForm
              name="Prediction Request"
              ref={this.predictionRequestRef}
              autoComplete="off"
              initialValues={predictionRequestInfo || {}}
              theme={themeSettings}
              layout="horizontal"
              labelCol={{ flex: '200px' }}
              labelAlign="right"
              onFinish={this.onPredictionRequestSubmit}
              onFinishFailed={this.onFinishFailed}
              labelWrap
              validateMessages={validateMessages}
              wrapperCol={{ flex: 1 }}
            >
              <StyledBox>
                <StyledBoxLabel>{this.translation('Deployment Details')}</StyledBoxLabel>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '50px' }}>
                  <Form.Item
                    label={this.translation('Deployed Date')}
                    name="CreatedTime"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Input disabled style={{ width: '70%' }}></Input>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Virtual Environment')}
                    name="Virtualenv"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Input disabled style={{ width: '70%' }}></Input>
                  </Form.Item>
                </div>
              </StyledBox>
              <StyledBox>
                <StyledBoxLabel>{this.translation('Prediction Details')}</StyledBoxLabel>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '50px' }}>
                  <Form.Item
                    label={this.translation('Train Period')}
                    name="TrainPeriodFrom"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Input.Group compact>
                      <Form.Item name="TrainPeriodFrom" rules={[{ required: true }]}>
                        <Input style={{ textAlign: 'center', width: 190 }} disabled />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 50,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('To')}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item name="TrainPeriodTo" rules={[{ required: true }]}>
                        <Input
                          style={{
                            textAlign: 'center',
                            borderLeft: 0,
                            width: 190
                          }}
                          disabled
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Evaluation Period')}
                    name="EvalPeriodFrom"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Input.Group compact>
                      <Form.Item name="EvalPeriodFrom">
                        <Input style={{ textAlign: 'center', width: 190 }} disabled />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 50,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('To')}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item name="EvalPeriodTo">
                        <Input
                          style={{
                            textAlign: 'center',
                            borderLeft: 0,
                            width: 190
                          }}
                          disabled
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Future Prediction')}
                    name="FuturePredRangeFrom"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Input.Group compact>
                      <Form.Item name="FuturePredRangeFrom">
                        <Input style={{ textAlign: 'center', width: 85 }} disabled />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 50,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('To')}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item name="FuturePredRangeTo">
                        <Input
                          style={{
                            textAlign: 'center',
                            borderLeft: 0,
                            width: 85
                          }}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 100,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('UOM')}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item name="FuturePredRangeUom">
                        <Input
                          style={{
                            textAlign: 'center',
                            borderLeft: 0,
                            width: 110
                          }}
                          disabled
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Look back period')}
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Input.Group compact>
                      <Form.Item name="LookBackPeriod">
                        <Input style={{ textAlign: 'center', width: 200 }} disabled />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 100,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('UOM')}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item name="LookBackPeriodUom">
                        <Input
                          style={{
                            textAlign: 'center',
                            borderLeft: 0,
                            width: 130
                          }}
                          disabled
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    name="FromTime"
                    label={this.translation('From Time')}
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <DatePicker
                      allowClear={false}
                      showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                      format="YYYY-MM-DD HH:mm:ss"
                      onOk={(date) => this.handleDateInputChange(date)}
                      disabledDate={(d) =>
                        //     this.formHeader.current.getFieldValue("EndTime")
                        //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                        //         ))
                        !d ||
                        d.isAfter(
                          this.predictionRequestRef.current &&
                            this.predictionRequestRef.current !== null &&
                            this.predictionRequestRef.current.getFieldValue() &&
                            this.predictionRequestRef.current.getFieldValue('ToTime')
                            ? moment(
                                this.predictionRequestRef.current.getFieldValue() &&
                                  this.predictionRequestRef.current.getFieldValue('ToTime')
                              ).format('YYYY-MM-DD HH:mm:ss')
                            : ''
                        )
                      }
                      disabledTime={(e) =>
                        this.getDisabledHoursTask(moment(e, 'YYYY-MM-DD HH:mm:ss'), 'ToTime')
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="ToTime"
                    label={this.translation('To Time')}
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <DatePicker
                      allowClear={false}
                      showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                      format="YYYY-MM-DD HH:mm:ss"
                      onOk={(date) => this.handleDateInputChange(date)}
                      disabledDate={(d) =>
                        //     this.formHeader.current.getFieldValue("EndTime")
                        //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                        //         ))
                        !d ||
                        d.isBefore(
                          this.predictionRequestRef.current &&
                            this.predictionRequestRef.current !== null &&
                            this.predictionRequestRef.current.getFieldValue() &&
                            this.predictionRequestRef.current.getFieldValue('FromTime')
                            ? moment(
                                this.predictionRequestRef.current.getFieldValue() &&
                                  this.predictionRequestRef.current.getFieldValue('FromTime')
                              ).format('YYYY-MM-DD HH:mm:ss')
                            : ''
                        )
                      }
                      disabledTime={(e) =>
                        this.getDisabledHoursTask(moment(e, 'YYYY-MM-DD HH:mm:ss'), 'ToTime')
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="Metrics"
                    label={this.translation('Metrics')}
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Tree
                      checkable
                      onCheck={(e) => this.onCheck('Metrics', e)}
                      switcherIcon={<DownOutlined />}
                      checkedKeys={
                        predictionRequestInfo && predictionRequestInfo['Metrics']
                          ? predictionRequestInfo['Metrics']
                          : ''
                      }
                    >
                      {this.renderTreeNodes(metricsData)}
                    </Tree>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      ref={this.predictionButtonRef}
                      htmlType="submit"
                      style={{ display: 'none' }}
                    ></Button>
                  </Form.Item>
                </div>
              </StyledBox>
            </StyledForm>
          </div>
        </StyleModal>

        {/* ============ Real Time Prediction Create Request Form =============== */}
        <StyleModal
          // width="1500px"
          title={this.translation('Real Time Inference')}
          open={RealTimeModel}
          onCancel={() => {
            this.setState({
              RealTimeInfo: {},
              RealTimeModel: false,
              ButtonKey: null,
              SelectedCommunication: [],
              MailRecipient: [],
              addCondition: false,
              Status: 'Inactive'
              // AddConditionFormDisable: true,
              // OnValueChangeDisable: true
            });
            this.RealTimeModelRef.current &&
              this.RealTimeModelRef.current.setFieldsValue({
                FromTime: moment(),
                ToTime: null,
                DataSourceID: null,
                TargetServer: null,
                Frequency: null,
                Comments: null,
                // ModelType:'',
                Operator: null,
                Value: [],
                CommunicationTypes: [],
                MailRecipient: []
              });
          }}
          closable
          theme={themeSettings}
          footer={[
            <>
              <Button
                key="back"
                onClick={() => {
                  this.setState({
                    RealTimeInfo: {},
                    RealTimeModel: false,
                    ButtonKey: null,
                    SelectedCommunication: [],
                    MailRecipient: [],
                    addCondition: false,
                    Status: 'Inactive'
                    // AddConditionFormDisable: true,
                    // OnValueChangeDisable: true
                  });
                  this.RealTimeModelRef.current &&
                    this.RealTimeModelRef.current.setFieldsValue({
                      FromTime: moment(),
                      ToTime: null,
                      DataSourceID: null,
                      TargetServer: null,
                      Frequency: null,
                      Comments: null,
                      Operator: null,
                      Value: [],
                      CommunicationTypes: [],
                      MailRecipient: []
                    });
                }}
              >
                {this.translation('Cancel')}
              </Button>
              {ButtonKey && ButtonKey === 'start' ? (
                <Button key="Submit" type="primary" onClick={this.onRealTimeModelSubmit}>
                  {' '}
                  {this.translation('Submit')}{' '}
                </Button>
              ) : null}
              {ButtonKey && ButtonKey === 'edit' ? (
                <Button key="Submit" type="primary" onClick={this.onRealTimeModelUpdate}>
                  {' '}
                  {this.translation('Submit')}{' '}
                </Button>
              ) : null}
              {/* {ButtonKey && ButtonKey === 'edit' ? (
                <Button key="CancelRequest" type="primary" onClick={this.onRealTimeModelReqCancel}>
                  {' '}
                  {this.translation('Cancel Request')}{' '}
                </Button>
              ) : null} */}
              {ButtonKey && ButtonKey === 'stop' ? (
                <Button key="Submit" type="primary" onClick={this.onRealTimeModelStopJob}>
                  {' '}
                  {this.translation('Submit')}{' '}
                </Button>
              ) : null}
            </>
          ]}
        >
          <StyledForm
            className="RealTimeStartForm"
            name="Real Time Inference"
            ref={this.RealTimeModelRef}
            autoComplete="off"
            initialValues={RealTimeInfo || {}}
            theme={themeSettings}
            layout="horizontal"
            labelCol={{ flex: '200px' }}
            labelAlign="right"
            onFinish={this.onRealTimeModelSubmit}
            onFinishFailed={this.onFinishFailed}
            labelWrap
            validateMessages={validateMessages}
            wrapperCol={{ flex: 1 }}
          >
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '1300px' }}>
              <Form.Item
                name="FromTime"
                label={this.translation('Start Time')}
                rules={[{ required: true }]}
                style={formItemLayout}
              >
                <DatePicker
                  style={{ width: '70%' }}
                  allowClear={false}
                  showTime={{ format: 'HH:mm:ss' }}
                  // defaultValue={moment()}
                  format="YYYY-MM-DD HH:mm:ss"
                  onOk={(date) => this.handleRealTimeDateInputChange(date)}
                  disabled={ButtonKey === 'stop' || ButtonKey === 'edit' ? true : false}
                  // disabledDate={(current) => {
                  //   return moment().add(-1, 'days') >= current;
                  // }}
                  disabledDate={(d) =>
                    !d || d.isBefore(moment() ? moment().format('YYYY-MM-DD HH:mm:ss') : '')
                  }
                />
              </Form.Item>
              <Form.Item name="ToTime" label={this.translation('End Time')} style={formItemLayout}>
                <DatePicker
                  style={{ width: '70%' }}
                  allowClear={false}
                  showTime={{ format: 'HH:mm:ss' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  minDate={moment().toDate()}
                  disabled={ButtonKey === 'stop' ? true : false}
                  onOk={(date) => this.handleRealTimeDateInputChange(date)}
                  disabledDate={(d) =>
                    !d ||
                    d.isBefore(
                      this.RealTimeModelRef.current &&
                        this.RealTimeModelRef.current !== null &&
                        this.RealTimeModelRef.current.getFieldValue() &&
                        this.RealTimeModelRef.current.getFieldValue('FromTime')
                        ? moment(
                            this.RealTimeModelRef.current.getFieldValue() &&
                              this.RealTimeModelRef.current.getFieldValue('FromTime')
                          ).format('YYYY-MM-DD HH:mm:ss')
                        : ''
                    ) ||
                    d.isBefore(moment().format('YYYY-MM-DD HH:mm:ss'))
                  }
                />
              </Form.Item>
              <Form.Item
                label={this.translation('DataSource')}
                name="DataSourceID"
                style={formItemLayout}
              >
                <Select
                  disabled={ButtonKey === 'edit' || ButtonKey === 'stop' ? true : false}
                  style={{ width: '70%' }}
                  placeholder={this.translation('Please Select DataSource')}
                >
                  {DataSourceList &&
                    Array.isArray(DataSourceList) &&
                    DataSourceList.map((item, index) => {
                      return (
                        <Option key={index} value={item.Id}>
                          {item.Name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label={this.translation('Target Server')}
                name="TargetServer"
                style={formItemLayout}
              >
                <Select
                  disabled={ButtonKey === 'edit' || ButtonKey === 'stop' ? true : false}
                  style={{ width: '70%' }}
                  placeholder={this.translation('Please Select Target Server')}
                >
                  {/* {DataSourceList &&
                    Array.isArray(DataSourceList) &&
                    DataSourceList.map((item, index) => {
                      return (
                        <Option key={index} value={item.Id}>
                          {item.Name}
                        </Option>
                      );
                    })} */}
                </Select>
              </Form.Item>
              <Form.Item
                label={this.translation('Frequency')}
                name="Frequency"
                style={formItemLayout}
              >
                <Select
                  disabled={ButtonKey === 'edit' || ButtonKey === 'stop' ? true : false}
                  style={{ width: '70%' }}
                  placeholder={this.translation('Please Select Frequency')}
                >
                  {/* {DataSourceList &&
                    Array.isArray(DataSourceList) &&
                    DataSourceList.map((item, index) => {
                      return (
                        <Option key={index} value={item.Id}>
                          {item.Name}
                        </Option>
                      );
                    })} */}
                </Select>
              </Form.Item>
              <Form.Item
                label={this.translation('Comments')}
                name="Comments"
                style={formItemLayout}
              >
                <TextArea
                  disabled={ButtonKey === 'edit' || ButtonKey === 'stop' ? true : false}
                  style={{ width: '70%' }}
                  placeholder={this.translation('Add Comments')}
                ></TextArea>
              </Form.Item>

              <StyledSubForm
                theme={themeSettings}
                style={{ background: 'rgb(8, 24, 70)', padding: '5px' }}
              >
                <StyledTabs
                  theme={themeSettings}
                  type="card"
                  className="ActionsTab"
                  // activeKey={predictionActiveKey}
                  // onChange={(value) => {
                  // }}
                >
                  <TabPane tab={this.translation('Actions')} key="Actions">
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                      {/* <Form.Item
               label={this.translation('Add Condition')}
               name="Add Condition"
               style={formItemLayout}
             >
               <Checkbox
                 onChange={this.onAddConditionCheck}
                 checked={addCondition}
                 style={{ width: '70%' }}
                 placeholder={this.translation('Add Condition')}
               ></Checkbox>
             </Form.Item> */}
                      <Form.Item
                        label={this.translation('Model Type')}
                        name="ModelType"
                        style={formItemLayout}
                      >
                        <Input
                          defaultValue={this.state.ModelType}
                          style={{ width: '70%' }}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item
                        label={this.translation('Model Name')}
                        name="ModelName"
                        style={formItemLayout}
                      >
                        <Input
                          defaultValue={this.state.ModelName}
                          style={{ width: '70%' }}
                          disabled
                        />
                      </Form.Item>
                      {formObject &&
                      formObject.ModelType &&
                      formObject.ModelType === 'Classification' ? (
                        <>
                          <Form.Item
                            label={this.translation('Condition')}
                            name="Operator"
                            style={formItemLayout}
                          >
                            <Select
                              disabled={ButtonKey === 'stop' ? true : false}
                              onChange={this.onConditionChage}
                              style={{ width: '70%' }}
                              placeholder={this.translation('Please Select Condition')}
                              //  disabled={AddConditionFormDisable}
                            >
                              <Option value="==">{'EqualTo (==)'}</Option>
                              <Option value="!=">{'Not EqualTo (!=)'}</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            label={this.translation('Value')}
                            name="Value"
                            style={formItemLayout}
                          >
                            <Select
                              style={{ width: '70%' }}
                              placeholder={this.translation('Please Select Value')}
                              disabled={
                                // SelectedCondition && SelectedCondition === undefined ||
                                ButtonKey === 'stop' ? true : false
                              }
                            >
                              <Option value="NORMAL">NORMAL</Option>
                              <Option value="ABNORMAL">ABNORMAL</Option>
                            </Select>
                          </Form.Item>
                        </>
                      ) : null}
                      {formObject &&
                      formObject.ModelType &&
                      formObject.ModelType === 'Regression' ? (
                        <>
                          <Form.Item
                            label={this.translation('Condition')}
                            name="Operator"
                            style={formItemLayout}
                          >
                            <Select
                              placeholder={this.translation('Select Condition')}
                              style={{ width: '70%' }}
                              onChange={this.onConditionChage}
                              disabled={ButtonKey === 'stop' ? true : false}
                              //  disabled={AddConditionFormDisable}
                            >
                              <Option value="<">{'Less Than (<)'}</Option>
                              <Option value=">">{'Greater Than (>)'}</Option>
                              <Option value=">=">{'Greater Than Or EqualTo (>=)'}</Option>
                              <Option value="<=">{'Less Than Or EqualTo (<=)'}</Option>
                              <Option value="Between">Between</Option>
                            </Select>
                          </Form.Item>

                          {SelectedCondition && SelectedCondition === 'Between' ? (
                            <>
                              <Form.Item
                                label={this.translation('Value')}
                                name="Value"
                                style={{ width: '100px', flex: '50%' }}
                              >
                                <Input.Group compact>
                                  <Form.Item>
                                    <Input
                                      placeholder={this.translation('Min Value')}
                                      step="0.1"
                                      min="0"
                                      max="100"
                                      type="number"
                                      name="value1"
                                      style={{ width: 100 }}
                                      onChange={this.onValueChange1}
                                      disabled={ButtonKey === 'stop' ? true : false}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    style={{
                                      width: 20
                                    }}
                                    disabled
                                  >
                                    <SwapOutlined />
                                  </Form.Item>
                                  <Form.Item>
                                    <Input
                                      placeholder={this.translation('Max Value')}
                                      step="0.1"
                                      min="0"
                                      max="100"
                                      style={{ width: 100 }}
                                      type="number"
                                      name="value2"
                                      onChange={this.onValueChange2}
                                      disabled={ButtonKey === 'stop' ? true : false}
                                    />
                                  </Form.Item>
                                </Input.Group>
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <Form.Item
                                label={this.translation('Value')}
                                name="Value"
                                style={formItemLayout}
                              >
                                <Input
                                  placeholder={this.translation('Please Select Value')}
                                  step="0.1"
                                  min="0"
                                  max="100"
                                  name="value"
                                  type="number"
                                  onChange={this.onValueChange}
                                  style={{ width: '70%' }}
                                  disabled={
                                    // (SelectedCondition && SelectedCondition === undefined) ||
                                    ButtonKey === 'stop' ? true : false
                                  }
                                />
                              </Form.Item>
                            </>
                          )}
                        </>
                      ) : null}
                      <Form.Item
                        label={this.translation('Communication Type')}
                        name="CommunicationTypes"
                        style={formItemLayout}
                      >
                        <ActionCheckbox
                          disabled={ButtonKey === 'stop' ? true : false}
                          options={this.state.data}
                          value={SelectedCommunication}
                          onChange={(checkedValues) => {
                            this.checkonChange(checkedValues, 'CommunicationTypes');
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label={this.translation('Mail Recipient')}
                        name="MailRecipient"
                        style={formItemLayout}
                      >
                        <Select
                          disabled={ButtonKey === 'stop' ? true : false}
                          placeholder={this.translation('RecipientList')}
                          mode="multiple"
                          value={this.state.MailRecipient}
                          style={{ width: '70%', display: 'inline-block' }}
                          onChange={(value) => this.handleRecipientList(value, 'mailRecipients')}
                        >
                          {recipientList &&
                            Array.isArray(recipientList) &&
                            recipientList.map((item, index) => {
                              return (
                                <Option key={index} value={item.Email}>
                                  {item.FirstName} {item.LastName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      {/* </div> */}

                      <Form.Item
                        label={this.translation('Status')}
                        name="Status"
                        style={formItemLayout}
                      >
                        <Checkbox
                          disabled={ButtonKey === 'stop' ? true : false}
                          onChange={this.onStatusCheck}
                          checked={Status && Status === 'Active' ? true : false}
                          style={{ width: '70%' }}
                        ></Checkbox>
                      </Form.Item>
                    </div>
                  </TabPane>
                </StyledTabs>
              </StyledSubForm>
            </div>
          </StyledForm>
        </StyleModal>

        {/* =============== Batch Prediction Results Drawer =========== */}
        <Drawer
          title={this.translation('Inference Results')}
          placement={'right'}
          width="90%"
          onClose={() => {
            // this.trendRef.current &&
            // this.trendRef.current.resetFields();
            const pager = { ...this.state.pagination };
            pager.current = 1;
            this.setState({
              resultsDrawerVisible: false,
              predictionActiveKey: 'Metric values',
              pagination: pager
            });
          }}
          open={resultsDrawerVisible}
          extra={
            <Space>
              <Button
                onClick={() => {
                  // this.trendRef.current &&
                  // this.trendRef.current.resetFields();
                  const pager = { ...this.state.pagination };
                  pager.current = 1;
                  this.setState({
                    resultsDrawerVisible: false,
                    predictionActiveKey: 'Metric values',
                    pagination: pager
                  });
                }}
              >
                {' '}
                {this.translation('Cancel')}{' '}
              </Button>
              {/* <Button type="primary">OK</Button> */}
            </Space>
          }
        >
          {/* <StyledSubForm theme={themeSettings}> */}
          <StyledTabs
            theme={themeSettings}
            type="card"
            activeKey={predictionActiveKey}
            onChange={(value) => {
              let { predictionReqInfo } = this.state;
              if (value === 'Functional summary') {
                this.getDailyFunctionalSummary(
                  functionalSummaryDailyFrom,
                  functionalSummaryDailyTo
                );
                this.getweeklyFunctionalSummary(
                  functionalSummaryWeeklyTo,
                  functionalSummaryWeekCount
                );
              }
              let trendObj = this.trendRef.current && this.trendRef.current.getFieldsValue();
              let toDate = moment(predictionReqInfo.FromTime)
                .utc()
                .add(1, 'hours')
                .format('YYYY-MM-DD HH:mm:ss');
              let defaultParameter = [];
              ParameterList &&
                ParameterList !== undefined &&
                Array.isArray(ParameterList) &&
                ParameterList.map((item, index) => {
                  if (index < 5) {
                    defaultParameter.push(item.ParameterCode);
                  }
                });
              trendObj = {
                // ...trendObj,
                DeviceCode:
                  DeviceList && DeviceList[0] && DeviceList[0]['Device']
                    ? [DeviceList[0]['Device']]
                    : [],
                ParameterCode: defaultParameter,
                FromDate: moment.utc(predictionReqInfo.FromTime, 'YYYY-MM-DD HH:mm:ss').local(),
                ToDate: moment.utc(toDate, 'YYYY-MM-DD HH:mm:ss').local(),
                Interval:
                  IntervalData && IntervalData[0] && IntervalData[0]['value']
                    ? IntervalData[0]['value']
                    : ''
              };
              this.onParameterChange(defaultParameter);
              this.trendRef.current && this.trendRef.current.setFieldsValue(trendObj);
              this.setState({
                predictionActiveKey: value,
                trendObj,
                trendData: []
              });
            }}
            className="TabsText"
          >
            <TabPane tab={this.translation('Metric Values')} key="Metric values">
              <div className="site-card-wrapper" style={{ marginTop: '24px' }}>
                <Row gutter={16}>
                  <Col span={8}>
                    <Card title={this.translation('Metric Values')} bordered={false}>
                      <StyledTable
                        pagination={{
                          defaultPageSize: 10,
                          hideOnSinglePage: true
                        }}
                        theme={themeSettings}
                        dataSource={
                          MetricValuesData && Array.isArray(MetricValuesData)
                            ? MetricValuesData
                            : []
                        }
                        columns={MetricResultColumns}
                      ></StyledTable>
                    </Card>
                  </Col>
                  {MetricImagesData && Array.isArray(MetricImagesData)
                    ? MetricImagesData.map((item, index) => {
                        return (
                          <Col key={index} span={8}>
                            <Card title={this.translation(item.MetricCode)} bordered={false}>
                              <Image
                                width={500}
                                src={`http://192.168.95.23:3001/api/analyticsAttachments/${
                                  item.MetricValue.split('/')[0]
                                }/${item.MetricValue.split('/')[1]}`}
                              />
                            </Card>
                          </Col>
                        );
                      })
                    : null}
                </Row>
              </div>
            </TabPane>

            <TabPane tab={this.translation('Inference Results')} key="Prediction results">
              <Form ref={this.predResRef} initialValues={predResInfo}>
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: '24px' }}>
                  <Form.Item name="FromTime" label={this.translation('Prediction From')}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item name="ToTime" label={this.translation('Prediction To')}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item name="Date" label={this.translation('Select Range')}>
                    <RangePicker
                      format="YYYY-MM-DD HH:mm:ss"
                      showTime={{
                        defaultValue: moment('00:00:00', 'HH:mm:ss')
                      }}
                      onChange={(e) => {
                        this.dateRangePicker(e, 'Date');
                      }}
                      disabledDate={(d) =>
                        //     this.formHeader.current.getFieldValue("EndTime")
                        //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                        //         ))
                        !d ||
                        d.isAfter(
                          predictionToTime
                            ? moment(predictionToTime).add(1, 'day').format('YYYY-MM-DD')
                            : ''
                        ) ||
                        d.isBefore(
                          predictionFromTime ? moment(predictionFromTime).format('YYYY-MM-DD') : ''
                        )
                      }
                      disabledTime={(e) =>
                        this.getDisabledHours(
                          moment(e, 'YYYY-MM-DD HH:mm:ss'),
                          predictionFromTime,
                          predictionToTime
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item name="PredReqId"></Form.Item>
                </div>
              </Form>
              <StyledTable
                theme={themeSettings}
                // scrollToFirstRowOnChange={true}
                showSizeChanger={false}
                pagination={pagination}
                onChange={this.handleTableChange}
                dataSource={
                  predictionResultsData && Array.isArray(predictionResultsData)
                    ? predictionResultsData
                    : []
                }
                columns={
                  this.modelRef.current &&
                  this.modelRef.current.getFieldsValue() &&
                  this.modelRef.current.getFieldsValue().ModelType === 'Classification'
                    ? predictionCLFResultColumns
                    : predictionREGResultColumns
                }
              ></StyledTable>
            </TabPane>
            <TabPane tab={this.translation('Trends')} key="Trends">
              <StyledForm
                // theme={themeSettings}
                className="TrendsForm"
                name="ModelDetails"
                ref={this.trendRef}
                autoComplete="off"
                initialValues={trendObj}
                theme={themeSettings}
                layout="horizontal"
                labelCol={{ flex: '200px' }}
                labelAlign="right"
                onFinish={this.onTrendSubmit}
                onFinishFailed={this.onFinishFailed}
                labelWrap
                validateMessages={validateMessages}
                wrapperCol={{ flex: 1 }}
              >
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '24px' }}>
                  <Form.Item
                    name="FromDate"
                    label={this.translation('Data From')}
                    style={formItemLayout}
                  >
                    <DatePicker
                      allowClear={false}
                      style={{ width: '70%' }}
                      format="YYYY-MM-DD HH:mm:ss"
                      showTime={{
                        defaultValue: moment('00:00:00', 'HH:mm:ss')
                      }}
                      disabledDate={(d) =>
                        !d ||
                        d.isAfter(
                          trendObj.CurrentTime
                            ? moment(trendObj.CurrentTime).add(1, 'day').format('YYYY-MM-DD')
                            : ''
                        )
                      }
                      disabledTime={(e) =>
                        this.getDisabledTrendHours(
                          moment(e, 'YYYY-MM-DD HH:mm:ss'),
                          trendObj.CurrentTime,
                          'start'
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="ToDate"
                    label={this.translation('Data To')}
                    style={formItemLayout}
                  >
                    <DatePicker
                      allowClear={false}
                      style={{ width: '70%' }}
                      format="YYYY-MM-DD HH:mm:ss"
                      showTime={{
                        defaultValue: moment('00:00:00', 'HH:mm:ss')
                      }}
                      disabledDate={(d) =>
                        !d ||
                        d.isBefore(
                          trendObj.CurrentTime
                            ? moment(trendObj.CurrentTime).add(1, 'day').format('YYYY-MM-DD')
                            : ''
                        )
                      }
                      disabledTime={(e) =>
                        this.getDisabledTrendHours(
                          moment(e, 'YYYY-MM-DD HH:mm:ss'),
                          trendObj.CurrentTime,
                          'end'
                        )
                      }
                    />
                  </Form.Item>
                  {trendObj && trendObj.CurrentTime && trendObj.CurrentTime !== undefined ? (
                    <>
                      <Form.Item
                        name="PredictionTargetFrom"
                        label={this.translation('Prediction Target From')}
                        style={formItemLayout}
                      >
                        <Input
                          disabled
                          style={{ width: '70%' }}
                          // format="YYYY-MM-DD HH:mm:ss"
                          // showTime={{
                          //   defaultValue: moment("00:00:00", "HH:mm:ss"),
                          // }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="PredictionTargetTo"
                        label={this.translation('Prediction Target To')}
                        style={formItemLayout}
                      >
                        <Input
                          disabled
                          style={{ width: '70%' }}
                          // format="YYYY-MM-DD HH:mm:ss"
                          // showTime={{
                          //   defaultValue: moment("00:00:00", "HH:mm:ss"),
                          // }}
                        />
                      </Form.Item>
                    </>
                  ) : null}
                  {trendObj && trendObj.CurrentTime && trendObj.CurrentTime !== undefined ? (
                    <Form.Item
                      name="CurrentTime"
                      label={this.translation('Predicted At')}
                      style={formItemLayout}
                    >
                      <DatePicker
                        style={{ width: '70%' }}
                        format="YYYY-MM-DD HH:mm:ss"
                        disabled
                        showTime={{
                          defaultValue: moment('00:00:00', 'HH:mm:ss')
                        }}
                        allowClear={false}
                      />
                    </Form.Item>
                  ) : null}
                  <Form.Item
                    style={formItemLayout}
                    name={'Interval'}
                    label={this.translation('Interval')}
                    rules={[{ required: true }]}
                  >
                    <Select style={{ width: '70%' }} disabled>
                      {IntervalData &&
                        Array.isArray(IntervalData) &&
                        IntervalData.map((item, index) => {
                          return (
                            <Option key={index} value={item.value}>
                              {item.key}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Device')}
                    name="DeviceCode"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Select
                      mode="multiple"
                      style={{ width: '70%' }}
                      onChange={(e) => {
                        this.deviceOnchange(e, formObject);
                      }}
                      disabled
                    >
                      {DeviceList &&
                        DeviceList &&
                        Array.isArray(DeviceList) &&
                        DeviceList.map((item) => {
                          return (
                            <Option key={item.Device} value={item.Device}>
                              {' '}
                              {item.Device}{' '}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Parameters')}
                    name="ParameterCode"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Select
                      mode="multiple"
                      style={{ width: '480px' }}
                      onChange={(e) => this.onParameterChange(e)}
                    >
                      {ParameterList &&
                        ParameterList &&
                        Array.isArray(ParameterList) &&
                        ParameterList.map((item) => {
                          return (
                            <Option key={item.Device} value={item.ParameterCode}>
                              {' '}
                              {item.Device}@{item.ParameterName}{' '}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>

                  {/* <Form.Item style={formItemLayout}></Form.Item> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <Button theme={themeSettings} type="primary" htmlType="submit">
                    {' '}
                    {this.translation('Generate Trend')}{' '}
                  </Button>
                </div>
              </StyledForm>
              <div
                style={
                  {
                    // display: "flex",
                    // justifyContent: "space-around",
                    // marginTop: '40px',
                    // flexWrap: "wrap",
                  }
                }
              >
                {trendData &&
                  Array.isArray(trendData) &&
                  trendData.map((graphData, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          width: '48%',
                          height: '48vh',
                          marginTop: '1%'
                        }}
                      >
                        {graphData && Array.isArray(graphData) && graphData.length > 0 ? (
                          <TrendSlider
                            panelData={{
                              graph: 'trends',
                              trendData: graphData,
                              colors: [color[i]],
                              linetype: '',
                              update: this.state.update,
                              type: 'line',
                              trendObj: trendObj,
                              formObject: formObject
                            }}
                          />
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            </TabPane>
            <TabPane tab={this.translation('Functional Summary')} key="Functional summary">
              {/* <div className="App">
                  <ViewDashboard name="Prediction" />
                </div> */}
              <Row>
                <Col span={10} style={{ fontSize: '10px' }}>
                  {' '}
                  <Title
                    level={4}
                    className="TitleText"
                    style={{ fontSize: '18px', width: '800px' }}
                  >
                    {'Main steam temperature(>428) daily Anomaly count- Predicted Vs Actual(%)'}
                  </Title>
                </Col>
                <Col span={6}></Col>
                <Col span={8}>
                  {' '}
                  <Form.Item
                    style={{ marginRight: '0px' }}
                    label={this.translation('Select Date Range')}
                  >
                    <RangePicker
                      allowClear={false}
                      disabledDate={(d) =>
                        //     this.formHeader.current.getFieldValue("EndTime")
                        //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                        //         ))
                        !d ||
                        d.isAfter(
                          predictionToTime ? moment(predictionToTime).format('YYYY-MM-DD') : ''
                        ) ||
                        d.isBefore(
                          predictionFromTime ? moment(predictionFromTime).format('YYYY-MM-DD') : ''
                        )
                      }
                      value={[
                        moment(functionalSummaryDailyFrom, 'YYYY-MM-DD 00:00:00'),
                        moment(functionalSummaryDailyTo, 'YYYY-MM-DD 23:59:59')
                      ]}
                      onChange={(date) =>
                        this.getDailyFunctionalSummary(
                          moment(date[0]).utc().local().format('YYYY-MM-DD 00:00:00'),
                          moment(date[1]).utc().local().format('YYYY-MM-DD 23:59:59'),
                          'date'
                        )
                      }
                    ></RangePicker>
                  </Form.Item>
                </Col>
              </Row>
              <div
                key={0}
                style={{
                  width: '48%',
                  height: '48vh',
                  marginTop: '1%'
                }}
              >
                {dailyFunctionalSummary &&
                Array.isArray(dailyFunctionalSummary) &&
                dailyFunctionalSummary.length > 0 ? (
                  <FunctionalSummary
                    panelData={{
                      graph: 'trends',
                      trendData: dailyFunctionalSummary,
                      linetype: '',
                      update: this.state.update,
                      type: 'bar',
                      formObject: formObject
                    }}
                  />
                ) : null}
              </div>
              <Row>
                <Col span={11}>
                  <Title
                    level={4}
                    className="TitleText"
                    style={{ fontSize: '18px', width: '800px' }}
                  >
                    {'Main stream temperature(>428) weekly Anomaly count- Predicted Vs Actual(%)'}
                  </Title>
                </Col>

                <Col span={4}></Col>
                <Col span={9}>
                  <Form.Item label={this.translation('As On Date')}>
                    <Input.Group compact>
                      <DatePicker
                        disabledDate={(d) =>
                          //     this.formHeader.current.getFieldValue("EndTime")
                          //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                          //         ))
                          !d ||
                          d.isAfter(
                            predictionToTime
                              ? moment(predictionToTime).add(1, 'day').format('YYYY-MM-DD')
                              : ''
                          ) ||
                          d.isBefore(
                            predictionFromTime
                              ? moment(predictionFromTime).format('YYYY-MM-DD')
                              : ''
                          )
                        }
                        value={moment(functionalSummaryWeeklyTo, 'YYYY-MM-DD HH:mm:ss')}
                        onChange={(date) =>
                          this.getweeklyFunctionalSummary(
                            moment(date).utc().local().format('YYYY-MM-DD 23:59:59'),
                            functionalSummaryWeekCount
                          )
                        }
                        allowClear={false}
                      ></DatePicker>

                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 100,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('Last')}
                          disabled
                        />
                      </Form.Item>

                      <InputNumber
                        onChange={(value) =>
                          this.getweeklyFunctionalSummary(functionalSummaryWeeklyTo, value)
                        }
                        value={functionalSummaryWeekCount}
                      />

                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 80,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('Weeks')}
                          disabled
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>
              <div
                key={1}
                style={{
                  width: '48%',
                  height: '48vh',
                  marginTop: '1%'
                }}
              >
                {weeklyFunctionalSummary &&
                Array.isArray(weeklyFunctionalSummary) &&
                weeklyFunctionalSummary.length > 0 ? (
                  <FunctionalSummary
                    panelData={{
                      graph: 'trends',
                      trendData: weeklyFunctionalSummary,
                      linetype: '',
                      update: this.state.update,
                      type: 'bar',
                      formObject: formObject
                    }}
                  />
                ) : null}
              </div>
            </TabPane>
          </StyledTabs>
          {/* </StyledSubForm> */}
        </Drawer>

        {/*============ QuickRun Results Drawer=========== */}
        <StyledModal
          className="quickrunresulsDiv"
          // width="1500px"
          title={this.translation('Quick Run Results')}
          open={realTimeQuickRunResultDrawer}
          onCancel={() => {
            this.QuickRunResultsRef.current &&
              this.QuickRunResultsRef.current.setFieldsValue({
                ActualValue: null,
                PredictedValue: null
              });
            this.setState({
              realTimeQuickRunResultDrawer: false,
              QuickRunInfo: {}
            });
          }}
          closable
          theme={themeSettings}
          footer={[
            <>
              <Button
                onClick={() => {
                  this.QuickRunResultsRef.current &&
                    this.QuickRunResultsRef.current.setFieldsValue({
                      ActualValue: null,
                      PredictedValue: null
                    });
                  this.setState({
                    QuickRunInfo: {},
                    realTimeQuickRunResultDrawer: false
                  });
                }}
              >
                {' '}
                {this.translation('Cancel')}{' '}
              </Button>
            </>
          ]}
        >
          <StyledForm
            name="QuickRunForm"
            autoComplete="off"
            initialValues={QuickRunInfo}
            ref={this.QuickRunResultsRef}
            theme={themeSettings}
          >
            <div>
              <Form.Item
                name="ActualValue"
                label={this.translation('Actual Value')}
                style={{ padding: '24px' }}
              >
                <Input
                  style={{
                    width: '250px',
                    pointerEvents: 'none'
                  }}
                  disabled
                />
              </Form.Item>

              <Form.Item name="PredictedValue" label={this.translation('Prediction Value')}>
                <Input
                  style={{
                    width: '250px',
                    pointerEvents: 'none'
                  }}
                  disabled
                />
              </Form.Item>
            </div>
          </StyledForm>
        </StyledModal>

        {/* ==============QuickRun DataSource selection Form ============ */}
        <StyledModal
          width="50%"
          title={this.translation('Quick Run')}
          open={QuickRunModal}
          onCancel={() => {
            this.setState({ QuickRunModal: false });
          }}
          closable
          theme={themeSettings}
          footer={[
            <>
              <Button
                key="back"
                onClick={() => {
                  this.QuickRunModalRef.current &&
                    this.QuickRunModalRef.current.setFieldsValue({
                      DataSourceID: null
                    });
                  this.setState({ QuickRunModal: false, DataSourceId: null });
                }}
              >
                {this.translation('Cancel')}
              </Button>
              <Button key="Submit" type="primary" onClick={this.quickRun}>
                {' '}
                {this.translation('Submit')}{' '}
              </Button>
            </>
          ]}
        >
          <StyledForm
            className="QuickRunForm"
            name="QuickRun"
            autoComplete="off"
            ref={this.QuickRunModalRef}
            theme={themeSettings}
            layout="horizontal"
            labelCol={{ flex: '200px' }}
            labelAlign="right"
            onFinish={this.quickRun}
            onFinishFailed={this.onFinishFailed}
            labelWrap
            validateMessages={validateMessages}
            wrapperCol={{ flex: 1 }}
          >
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                label={this.translation('DataSource')}
                name="DataSourceID"
                style={formItemLayout}
              >
                <Select
                  value={DataSourceId}
                  style={{ width: '70%' }}
                  placeholder={this.translation('Please Select DataSource')}
                  onChange={this.onDataSourceChange}
                >
                  {DataSourceList &&
                    Array.isArray(DataSourceList) &&
                    DataSourceList.map((item, index) => {
                      return (
                        <Option key={index} value={item.Id}>
                          {item.Name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
          </StyledForm>
        </StyledModal>

        {/* ============== Real Time Results Drawer ========== */}
        <Drawer
          title={this.translation('Real Time Inference Results')}
          placement={'right'}
          width="90%"
          onClose={() => {
            const pager = { ...this.state.pagination };
            pager.current = 1;
            this.realTimePredictionRequestRef.current &&
              this.realTimePredictionRequestRef.current.setFieldsValue({
                FromTime: null,
                ToTime: null,
                Metrics: null
              });
            this.setState({
              realTimeResultsDrawerVisible: false,
              predictionActiveKey: 'Metric values',
              pagination: pager,
              realTimePredictionRequestInfo: {},
              option: {},
              RocCurveOption: {},
              ROCCurveData: [],
              confMatrixVisible: false,
              ROCCurveVisible: false,
              MetricValuesData1: [],
              RealTimeMetricResultVisible: false
            });
          }}
          open={realTimeResultsDrawerVisible}
          extra={
            <Space>
              <Button
                onClick={() => {
                  const pager = { ...this.state.pagination };
                  pager.current = 1;
                  this.realTimePredictionRequestRef.current &&
                    this.realTimePredictionRequestRef.current.setFieldsValue({
                      FromTime: null,
                      ToTime: null,
                      Metrics: null
                    });
                  this.setState({
                    realTimeResultsDrawerVisible: false,
                    predictionActiveKey: 'Metric values',
                    pagination: pager,
                    realTimePredictionRequestInfo: {},
                    metricResultVisible: false,
                    RealTimeMetricResultVisible: false,
                    option: {},
                    RocCurveOption: {},
                    ROCCurveData: [],
                    confMatrixVisible: false,
                    ROCCurveVisible: false,
                    MetricValuesData1: []
                  });
                }}
              >
                {' '}
                {this.translation('Cancel')}{' '}
              </Button>
            </Space>
          }
        >
          {/* <StyledSubForm theme={themeSettings}> */}
          <StyledTabs
            theme={themeSettings}
            type="card"
            activeKey={predictionActiveKey}
            onChange={(value) => {
              let { realTimePredictionReqInfo } = this.state;
              if (value === 'Functional summary') {
                this.getRealTimeDailyFunctionalSummary(
                  functionalSummaryDailyFrom1,
                  functionalSummaryDailyTo1
                );
                this.getRealTimeweeklyFunctionalSummary(
                  functionalSummaryWeeklyTo1,
                  functionalSummaryWeekCount1
                );
                this.getRealTimeFunctionalSummary(
                  this.state.momentRange[0],
                  this.state.momentRange[1]
                );
              }
              let trendObj =
                this.realTimeTrendRef.current && this.realTimeTrendRef.current.getFieldsValue();
              let toDate = moment(realTimePredictionReqInfo.FromTime)
                .utc()
                .add(1, 'hours')
                .format('YYYY-MM-DD HH:mm:ss');
              let defaultParameter = [];
              this.state.ParameterList &&
                this.state.ParameterList !== undefined &&
                Array.isArray(this.state.ParameterList) &&
                this.state.ParameterList.map((item, index) => {
                  if (index < 5) {
                    defaultParameter.push(item.ParameterCode);
                  }
                });
              trendObj = {
                // ...trendObj,
                DeviceCode:
                  DeviceList && DeviceList[0] && DeviceList[0]['Device']
                    ? [DeviceList[0]['Device']]
                    : [],
                ParameterCode: defaultParameter,
                FromDate: moment
                  .utc(realTimePredictionReqInfo.FromTime, 'YYYY-MM-DD HH:mm:ss')
                  .local(),
                ToDate: moment.utc(toDate, 'YYYY-MM-DD HH:mm:ss').local(),
                Interval:
                  IntervalData && IntervalData[0] && IntervalData[0]['value']
                    ? IntervalData[0]['value']
                    : ''
              };
              this.onParameterChange(defaultParameter);
              this.realTimeTrendRef.current &&
                this.realTimeTrendRef.current.setFieldsValue(trendObj);
              this.setState({
                predictionActiveKey: value,
                trendObj,
                trendData: []
              });
            }}
            className="TabsText"
          >
            <TabPane tab={this.translation('Metric Values')} key="Metric values">
              <div className="site-card-wrapper">
                <Row gutter={16}>
                  <StyledForm
                    name=" RealTime Prediction Request"
                    ref={this.realTimePredictionRequestRef}
                    autoComplete="off"
                    initialValues={realTimePredictionRequestInfo || {}}
                    theme={themeSettings}
                    layout="horizontal"
                    labelCol={{ flex: '200px' }}
                    labelAlign="right"
                    onFinish={this.onRealTimePredictionRequestSubmit}
                    onFinishFailed={this.onFinishFailed}
                    labelWrap
                    validateMessages={validateMessages}
                    wrapperCol={{ flex: 1 }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: '50px',
                        color: 'black'
                      }}
                    >
                      <Form.Item
                        name="FromTime"
                        label={this.translation('Start Time')}
                        rules={[{ required: true }]}
                        style={formItemLayout}
                      >
                        <DatePicker
                          allowClear={false}
                          showTime={{
                            defaultValue: moment('00:00:00', 'HH:mm:ss')
                          }}
                          value={realTimePredictionRequestInfo.FromTime}
                          format="YYYY-MM-DD HH:mm:ss"
                          onOk={(date) => this.handleMetricDateInputChange(date)}
                          disabledDate={(d) =>
                            //     this.formHeader.current.getFieldValue("EndTime")
                            //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                            //         ))
                            !d ||
                            d.isAfter(
                              this.realTimePredictionRequestRef.current &&
                                this.realTimePredictionRequestRef.current !== null &&
                                this.realTimePredictionRequestRef.current.getFieldValue() &&
                                this.realTimePredictionRequestRef.current.getFieldValue('ToTime')
                                ? moment(
                                    this.realTimePredictionRequestRef.current.getFieldValue() &&
                                      this.realTimePredictionRequestRef.current.getFieldValue(
                                        'ToTime'
                                      )
                                  ).format('YYYY-MM-DD HH:mm:ss')
                                : ''
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="ToTime"
                        label={this.translation('End Time')}
                        rules={[{ required: true }]}
                        style={formItemLayout}
                      >
                        <DatePicker
                          allowClear={false}
                          showTime={{
                            defaultValue: moment('00:00:00', 'HH:mm:ss')
                          }}
                          format="YYYY-MM-DD HH:mm:ss"
                          onOk={(date) => this.handleMetricDateInputChange(date)}
                          disabledDate={(d) =>
                            !d ||
                            d.isBefore(
                              this.realTimePredictionRequestRef.current &&
                                this.realTimePredictionRequestRef.current !== null &&
                                this.realTimePredictionRequestRef.current.getFieldValue() &&
                                this.realTimePredictionRequestRef.current.getFieldValue('FromTime')
                                ? moment(
                                    this.realTimePredictionRequestRef.current.getFieldValue() &&
                                      this.realTimePredictionRequestRef.current.getFieldValue(
                                        'FromTime'
                                      )
                                  ).format('YYYY-MM-DD HH:mm:ss')
                                : ''
                            )
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        name="Metrics"
                        label={this.translation('Metrics')}
                        rules={[{ required: true }]}
                        style={formItemLayout}
                      >
                        <Tree
                          checkable
                          onCheck={(e) => this.onMetricCheck('Metrics', e)}
                          switcherIcon={<DownOutlined />}
                          checkedKeys={
                            realTimePredictionRequestInfo &&
                            realTimePredictionRequestInfo['Metrics']
                              ? realTimePredictionRequestInfo['Metrics']
                              : ''
                          }
                        >
                          {this.renderTreeNodes(metricsData)}
                        </Tree>
                      </Form.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'right',
                          marginTop: '200px'
                        }}
                      >
                        <Form.Item>
                          <Button ref={this.predictionButtonRef} htmlType="submit" type="primary">
                            {this.translation('Submit')}
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </StyledForm>
                  {RealTimeMetricResultVisible && RealTimeMetricResultVisible === true ? (
                    <>
                      {MetricValuesData1 &&
                      Array.isArray(MetricValuesData1) &&
                      MetricValuesData1 !== undefined ? (
                        <>
                          <Col span={8}>
                            <Card title={this.translation('Metric Values')} bordered={false}>
                              <StyledTable
                                pagination={{
                                  defaultPageSize: 10,
                                  hideOnSinglePage: true
                                }}
                                theme={themeSettings}
                                dataSource={
                                  MetricValuesData1 && Array.isArray(MetricValuesData1)
                                    ? this.translation(MetricValuesData1)
                                    : []
                                }
                                columns={RealTimeMetricResultColumns}
                              ></StyledTable>
                            </Card>
                          </Col>
                        </>
                      ) : null}

                      {confMatrixVisible && confMatrixVisible === true ? (
                        <>
                          <Col span={8}>
                            <Card title={this.translation('Confusion Matrix')} bordered={false}>
                              <ReactEcharts
                                // id={Math.ceil(Math.random(100, 100000) * 1000)}
                                option={this.state.option}
                                style={{
                                  height: '450%',
                                  width: '100%'
                                }}
                                className="react_for_echarts"
                              />
                            </Card>
                          </Col>
                        </>
                      ) : null}
                      {ROCCurveVisible && ROCCurveVisible === true ? (
                        <>
                          <Col span={8}>
                            <Card title={this.translation('ROC Curve')} bordered={false}>
                              <ReactEcharts
                                // id={Math.ceil(Math.random(100, 100000) * 1000)}
                                option={this.state.RocCurveOption}
                                style={{
                                  height: '450%',
                                  width: '100%'
                                }}
                                className="react_for_echarts"
                              />
                            </Card>
                          </Col>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Row>
              </div>
            </TabPane>

            <TabPane
              tab={this.translation('Real Time Inference Results')}
              key="RealTimeInferenceResults"
            >
              <Form ref={this.realTimePredResRef} initialValues={realTimePredResInfo}>
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: '24px' }}>
                  <Form.Item name="FromTime" label={this.translation('Prediction From')}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item name="ToTime" label={this.translation('Prediction To')}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item name="Date" label={this.translation('Select Range')}>
                    <RangePicker
                      format="YYYY-MM-DD HH:mm:ss"
                      showTime={{
                        defaultValue: moment('00:00:00', 'HH:mm:ss')
                      }}
                      onChange={(e) => {
                        this.realTimeDateRangePicker(e, 'Date');
                      }}
                      disabledDate={(d) =>
                        //     this.formHeader.current.getFieldValue("EndTime")
                        //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                        //         ))
                        !d ||
                        d.isAfter(
                          predictionToTime
                            ? moment(predictionToTime).add(1, 'day').format('YYYY-MM-DD')
                            : ''
                        ) ||
                        d.isBefore(
                          predictionFromTime ? moment(predictionFromTime).format('YYYY-MM-DD') : ''
                        )
                      }
                      disabledTime={(e) =>
                        this.getDisabledHours(
                          moment(e, 'YYYY-MM-DD HH:mm:ss'),
                          predictionFromTime,
                          predictionToTime
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item name="PredReqId"></Form.Item>
                </div>
              </Form>
              <StyledTable
                theme={themeSettings}
                // scrollToFirstRowOnChange={true}
                showSizeChanger={false}
                pagination={pagination}
                onChange={this.handleTableChange}
                dataSource={
                  // dataSource
                  realPredictionResultsData && Array.isArray(realPredictionResultsData)
                    ? realPredictionResultsData
                    : []
                }
                columns={
                  this.modelRef.current &&
                  this.modelRef.current.getFieldsValue() &&
                  this.modelRef.current.getFieldsValue().ModelType === 'Classification'
                    ? realTimePredictionCLFResultColumns
                    : predictionREGResultColumns
                }
              ></StyledTable>
            </TabPane>

            <TabPane tab={this.translation('Trends')} key="Trends">
              <StyledForm
                name="ModelDetails"
                ref={this.realTimeTrendRef}
                autoComplete="off"
                initialValues={trendObj}
                theme={themeSettings}
                layout="horizontal"
                labelCol={{ flex: '200px' }}
                labelAlign="right"
                onFinish={this.onTrendSubmitRealTime}
                onFinishFailed={this.onFinishFailed}
                labelWrap
                validateMessages={validateMessages}
                wrapperCol={{ flex: 1 }}
              >
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '24px' }}>
                  <Form.Item
                    name="FromDate"
                    label={this.translation('Data From')}
                    style={formItemLayout}
                  >
                    <DatePicker
                      allowClear={false}
                      style={{ width: '70%' }}
                      format="YYYY-MM-DD HH:mm:ss"
                      showTime={{
                        defaultValue: moment('00:00:00', 'HH:mm:ss')
                      }}
                      disabledDate={(d) =>
                        !d ||
                        d.isAfter(
                          trendObj.CurrentTime
                            ? moment(trendObj.CurrentTime).add(1, 'day').format('YYYY-MM-DD')
                            : ''
                        )
                      }
                      disabledTime={(e) =>
                        this.getDisabledTrendHours(
                          moment(e, 'YYYY-MM-DD HH:mm:ss'),
                          trendObj.CurrentTime,
                          'start'
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="ToDate"
                    label={this.translation('Data To')}
                    style={formItemLayout}
                  >
                    <DatePicker
                      allowClear={false}
                      style={{ width: '70%' }}
                      format="YYYY-MM-DD HH:mm:ss"
                      showTime={{
                        defaultValue: moment('00:00:00', 'HH:mm:ss')
                      }}
                      disabledDate={(d) =>
                        !d ||
                        d.isBefore(
                          trendObj.CurrentTime
                            ? moment(trendObj.CurrentTime).add(1, 'day').format('YYYY-MM-DD')
                            : ''
                        )
                      }
                      disabledTime={(e) =>
                        this.getDisabledTrendHours(
                          moment(e, 'YYYY-MM-DD HH:mm:ss'),
                          trendObj.CurrentTime,
                          'end'
                        )
                      }
                    />
                  </Form.Item>
                  {trendObj && trendObj.CurrentTime && trendObj.CurrentTime !== undefined ? (
                    <>
                      <Form.Item
                        name="PredictionTargetFrom"
                        label={this.translation('Prediction Target From')}
                        style={formItemLayout}
                      >
                        <Input
                          disabled
                          style={{ width: '70%' }}
                          // format="YYYY-MM-DD HH:mm:ss"
                          // showTime={{
                          //   defaultValue: moment("00:00:00", "HH:mm:ss"),
                          // }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="PredictionTargetTo"
                        label={this.translation('Prediction Target To')}
                        style={formItemLayout}
                      >
                        <Input
                          disabled
                          style={{ width: '70%' }}
                          // format="YYYY-MM-DD HH:mm:ss"
                          // showTime={{
                          //   defaultValue: moment("00:00:00", "HH:mm:ss"),
                          // }}
                        />
                      </Form.Item>
                    </>
                  ) : null}
                  {trendObj && trendObj.CurrentTime && trendObj.CurrentTime !== undefined ? (
                    <Form.Item
                      name="CurrentTime"
                      label={this.translation('Predicted At')}
                      style={formItemLayout}
                    >
                      <DatePicker
                        style={{ width: '70%' }}
                        format="YYYY-MM-DD HH:mm:ss"
                        disabled
                        showTime={{
                          defaultValue: moment('00:00:00', 'HH:mm:ss')
                        }}
                        allowClear={false}
                      />
                    </Form.Item>
                  ) : null}
                  <Form.Item
                    style={formItemLayout}
                    name={'Interval'}
                    label={this.translation('Interval')}
                    rules={[{ required: true }]}
                  >
                    <Select style={{ width: '70%' }} disabled>
                      {IntervalData &&
                        Array.isArray(IntervalData) &&
                        IntervalData.map((item, index) => {
                          return (
                            <Option key={index} value={item.value}>
                              {item.key}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Device')}
                    name="DeviceCode"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Select
                      mode="multiple"
                      style={{ width: '70%' }}
                      onChange={(e) => {
                        this.deviceOnchange(e, formObject);
                      }}
                      disabled
                    >
                      {DeviceList &&
                        DeviceList &&
                        Array.isArray(DeviceList) &&
                        DeviceList.map((item) => {
                          return (
                            <Option key={item.Device} value={item.Device}>
                              {' '}
                              {item.Device}{' '}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={this.translation('Parameters')}
                    name="ParameterCode"
                    rules={[{ required: true }]}
                    style={formItemLayout}
                  >
                    <Select
                      mode="multiple"
                      style={{ width: '480px' }}
                      onChange={(e) => this.onParameterChange(e)}
                    >
                      {ParameterList &&
                        ParameterList &&
                        Array.isArray(ParameterList) &&
                        ParameterList.map((item) => {
                          return (
                            <Option key={item.Device} value={item.ParameterCode}>
                              {' '}
                              {item.Device}@{item.ParameterName}{' '}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>

                  {/* <Form.Item style={formItemLayout}></Form.Item> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <Button theme={themeSettings} type="primary" htmlType="submit">
                    {' '}
                    {this.translation('Generate Trend')}{' '}
                  </Button>
                </div>
              </StyledForm>
              <div
                style={
                  {
                    // display: "flex",
                    // justifyContent: "space-around",
                    // marginTop: '40px',
                    // flexWrap: "wrap",
                  }
                }
              >
                {trendData &&
                  Array.isArray(trendData) &&
                  trendData.map((graphData, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          width: '48%',
                          height: '48vh',
                          marginTop: '1%'
                        }}
                      >
                        {graphData && Array.isArray(graphData) && graphData.length > 0 ? (
                          <TrendSlider
                            panelData={{
                              graph: 'trends',
                              trendData: graphData,
                              colors: [color[i]],
                              linetype: '',
                              update: this.state.update,
                              type: 'line',
                              trendObj: trendObj,
                              formObject: formObject
                            }}
                          />
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            </TabPane>
            <TabPane tab={this.translation('Functional Summary')} key="Functional summary">
              {/* <div className="App">
                  <ViewDashboard name="Prediction" />
                </div> */}
              <Row>
                <Col span={10}>
                  {' '}
                  <Title
                    className="TitleText"
                    level={4}
                    style={{ fontSize: '18px', width: '800px' }}
                  >
                    {'Main steam temperature(>428) daily Anomaly count- Predicted Vs Actual(%)'}
                  </Title>
                </Col>
                <Col span={6}></Col>
                <Col span={8}>
                  {' '}
                  <Form.Item
                    style={{ marginRight: '0px' }}
                    label={this.translation('Select Date Range')}
                  >
                    <RangePicker
                      allowClear={false}
                      disabledDate={(d) =>
                        //     this.formHeader.current.getFieldValue("EndTime")
                        //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                        //         ))
                        !d ||
                        d.isAfter(
                          predictionToTime ? moment(predictionToTime).format('YYYY-MM-DD') : ''
                        ) ||
                        d.isBefore(
                          predictionFromTime ? moment(predictionFromTime).format('YYYY-MM-DD') : ''
                        )
                      }
                      value={[
                        moment(functionalSummaryDailyFrom1, 'YYYY-MM-DD 00:00:00'),
                        moment(functionalSummaryDailyTo1, 'YYYY-MM-DD 23:59:59')
                      ]}
                      onChange={(date) =>
                        this.getRealTimeDailyFunctionalSummary(
                          moment(date[0]).utc().local().format('YYYY-MM-DD 00:00:00'),
                          moment(date[1]).utc().local().format('YYYY-MM-DD 23:59:59'),
                          'date'
                        )
                      }
                    ></RangePicker>
                  </Form.Item>
                </Col>
              </Row>
              <div
                key={0}
                style={{
                  width: '48%',
                  height: '48vh',
                  marginTop: '1%'
                }}
              >
                {dailyFunctionalSummary1 &&
                Array.isArray(dailyFunctionalSummary1) &&
                dailyFunctionalSummary1.length > 0 ? (
                  <FunctionalSummary
                    panelData={{
                      graph: 'trends',
                      trendData: dailyFunctionalSummary1,
                      linetype: '',
                      update: this.state.update,
                      type: 'bar',
                      formObject: formObject
                    }}
                  />
                ) : null}
              </div>
              <Row>
                <Col span={11}>
                  <Title
                    level={4}
                    className="TitleText"
                    style={{ fontSize: '18px', width: '800px' }}
                  >
                    {'Main stream temperature(>428) weekly Anomaly count- Predicted Vs Actual(%)'}
                  </Title>
                </Col>

                <Col span={4}></Col>
                <Col span={9}>
                  <Form.Item label={this.translation('As On Date')}>
                    <Input.Group compact>
                      <DatePicker
                        disabledDate={(d) =>
                          !d ||
                          d.isAfter(
                            predictionToTime
                              ? moment(predictionToTime).add(1, 'day').format('YYYY-MM-DD')
                              : ''
                          ) ||
                          d.isBefore(
                            predictionFromTime
                              ? moment(predictionFromTime).format('YYYY-MM-DD')
                              : ''
                          )
                        }
                        value={moment(functionalSummaryWeeklyTo1, 'YYYY-MM-DD HH:mm:ss')}
                        onChange={(date) =>
                          this.getRealTimeweeklyFunctionalSummary(
                            moment(date).utc().local().format('YYYY-MM-DD 23:59:59'),
                            functionalSummaryWeekCount1
                          )
                        }
                        allowClear={false}
                      ></DatePicker>

                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 80,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('Last')}
                          disabled
                        />
                      </Form.Item>

                      <InputNumber
                        onChange={(value) =>
                          this.getRealTimeweeklyFunctionalSummary(functionalSummaryWeeklyTo1, value)
                        }
                        value={functionalSummaryWeekCount1}
                      />

                      <Form.Item rules={[{ required: true }]}>
                        <Input
                          style={{
                            width: 80,
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none'
                          }}
                          placeholder={this.translation('Weeks')}
                          disabled
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>
              <div
                key={1}
                style={{
                  width: '48%',
                  height: '48vh',
                  marginTop: '1%'
                }}
              >
                {weeklyFunctionalSummary1 &&
                Array.isArray(weeklyFunctionalSummary1) &&
                weeklyFunctionalSummary1.length > 0 ? (
                  <FunctionalSummary
                    panelData={{
                      graph: 'trends',
                      trendData: weeklyFunctionalSummary1,
                      linetype: '',
                      update: this.state.update,
                      type: 'bar',
                      formObject: formObject
                    }}
                  />
                ) : null}
              </div>
              <Row>
                <Col span={10}>
                  {' '}
                  <Title
                    className="TitleText"
                    level={4}
                    style={{ fontSize: '18px', width: '800px' }}
                  >
                    {'Main steam temperature(>428) Real Time Anomaly count- Predicted Vs Actual(%)'}
                  </Title>
                </Col>
                <Col span={6}></Col>
                <Col span={8}>
                  {' '}
                  {/* <Form.Item
                      style={{ marginRight: '0px' }}
                      label={this.translation('Select Date Range')}
                    > */}
                  <Form.Item>
                    <RangePicker
                      format="YYYY-MM-DD HH:mm:ss"
                      showTime={{ format: 'HH:mm:ss' }}
                      placeholder={['Start Date', 'End Date']}
                      onChange={this.onChange}
                      ranges={{
                        Today: [moment().startOf('day'), moment()],
                        'This Month': [moment().startOf('month'), moment()],
                        'Last Month': [
                          moment().subtract(1, 'month').startOf('month'),
                          moment().subtract(1, 'month').endOf('month')
                        ]
                      }}
                      value={this.state.momentRange}
                      disabledDate={
                        (d) =>
                          //     this.formHeader.current.getFieldValue("EndTime")
                          //                 ? moment(this.formHeader.current.getFieldValue("EndTime")).add(1, "day").format("YYYY-MM-DD") : ""
                          //         ))
                          !d ||
                          d.isAfter(
                            functionalSummaryTo
                              ? moment(functionalSummaryTo).format('YYYY-MM-DD HH:mm:ss')
                              : ''
                          )
                        //  ||
                        // d.isBefore(
                        //   predictionFromTime
                        //     ? moment(predictionFromTime).format('YYYY-MM-DD HH:mm:ss')
                        //     : ''
                        // )
                      }
                      // onOk={this.onOkfun}
                      style={{
                        width: '400px',
                        cursor: 'pointer'
                      }}
                    />
                  </Form.Item>
                  {/* </Form.Item> */}
                </Col>
              </Row>
              <div
                key={0}
                style={{
                  width: '48%',
                  height: '48vh',
                  marginTop: '1%'
                }}
              >
                {RealTimeFunctionalSummary &&
                Array.isArray(RealTimeFunctionalSummary) &&
                RealTimeFunctionalSummary.length > 0 ? (
                  <RealTimeGraph
                    difMin={this.state.difMin}
                    differenceHours={this.state.differenceHours}
                    disableGraph={this.state.disableGraph}
                    panelData={{
                      GraphData: RealTimeFunctionalSummary,
                      selectionDateRange: selectionDateRange,
                      PredReqId: realTimePredictionReqInfo.PredReqId,
                      FromTime: realTimePredictionReqInfo.FromTime,
                      ToTime: realTimePredictionReqInfo.ToTime
                    }}
                  />
                ) : null}
              </div>
            </TabPane>

            <TabPane tab={this.translation('Actions History')} key="Actions History">
              <StyledTable
                pagination={pagination}
                onChange={this.handleTableChange}
                theme={themeSettings}
                dataSource={
                  ActoinsLogDataSource && Array.isArray(ActoinsLogDataSource)
                    ? ActoinsLogDataSource
                    : []
                }
                columns={ActionsHistoryColumns}
                style={{ marginTop: '24px' }}
              ></StyledTable>
            </TabPane>
          </StyledTabs>
          {/* </StyledSubForm> */}
        </Drawer>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(ModelDetails);
