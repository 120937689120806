import React from 'react';
import { Drawer, Tabs, Input, Form, Button, Select, Space } from 'antd';

const { TabPane } = Tabs;
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  }
};
class DashboardLevelConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dropdownObject: {}
    };
  }

  dashRef = React.createRef();
  handleSubmit = (e) => {
    e.preventDefault();
    this.dashRef.current
      .validateFields()
      .then(async (values) => {
        let { dropdownObject } = this.state;
        dropdownObject[values.key] = {
          ...values
        };
        this.setState({ dropdownObject });
      })
      .catch(() => {});
  };

  hasErrors = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  render() {
    let { dropdownObject } = this.state;
    return (
      <Drawer
        width={700}
        title="Dashboard Header Configuration"
        open={this.state.visible}
        closable
        extra={
          <Space>
            <Button type="primary" onClick={this.handleSubmit}>
              OK
            </Button>
          </Space>
        }
      >
        <Tabs style={{ color: 'white' }}>
          <TabPane tab="Multi Dropdown" key="1">
            <Form ref={this.dashRef} layout="vertical" onSubmit={this.handleSubmit}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Please input your Name!' }]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item name="key" rules={[{ required: true, message: 'Please input your key!' }]}>
                <Input placeholder="Key" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Add
                </Button>
              </Form.Item>
            </Form>
            {dropdownObject &&
              Object.keys(dropdownObject).length > 0 &&
              Object.keys(dropdownObject).map((dropdownName, index) => {
                return (
                  <div key={index}>
                    {dropdownObject[dropdownName].name}
                    <Form {...formItemLayout}>
                      <Form.Item label={'Show Dropdown'}>
                        <Input placeholder="dropdown" width="300" />
                      </Form.Item>
                      <Form.Item label={'Place holder'}>
                        <Input placeholder="Placeholder" width="300" />
                      </Form.Item>
                      <Form.Item label={'Request Input'}>
                        <Select placeholder="Select Input Type" style={{ width: '300' }}>
                          <Option value="array">Array</Option>
                          <Option value="object">Object</Option>
                          <Option value="string">String</Option>
                        </Select>
                      </Form.Item>
                    </Form>
                  </div>
                );
              })}
          </TabPane>
        </Tabs>
      </Drawer>
    );
  }
}

export default DashboardLevelConfig;
