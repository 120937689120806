import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// import models from "./config/model";
import MenuPrepare from './shared/MenuList';
import { getMenuHierarchy } from '../../../../../selectors/layout';
import { sideMenuFormat } from '../Adapter/sideMenuAdapter';

// export const flattenJson = (json) => {
//   if (json !== undefined && json !== null && Array.isArray(json)) {
//     let result = [],
//       parentname = "",
//       menuKeys = "";

//     const recurse = (data, nest) => {
//       data.map((element) => {
//         if (element.routes !== undefined) {
//           element.routes.map((subelement) => {
//             //checking  if object contains nested routes
//             if (subelement.routes !== undefined) {
//               //differentiating between calling recurse from inside and outside
//               if (nest !== "NEST") {
//                 parentname = "";
//                 menuKeys = "";
//               }
//               /* Adding parentname and key when nested array is present if it is not present */
//               if (!parentname.includes(`${element.name}`)) {
//                 parentname += element.name;
//                 menuKeys += element.uid;
//               }
//               /* Adding a argument NEST to the recurse to differentiating between inside calling and ouside calling  */
//               recurse([subelement], "NEST");
//             } else {
//               // differentiating between calling recurse from inside and outside
//               if (nest === undefined) {
//                 parentname = "";
//                 menuKeys = "";
//               }
//               //Adding Last parentname and parentkey in the object.
//               if (!parentname.includes(`${element.name}`)) {
//                 parentname += `/${element.name}`;
//                 menuKeys += `/${element.uid}`;
//               }
//               /*Adding parentname and Keys to the object And pushing to the result array.*/
//               subelement.parentKeys = menuKeys;
//               subelement.parentname = parentname;
//               result.push(subelement);
//             }
//           });
//         } else {
//           //Pushing to result array if not nested routes are present.
//           result.push(element);
//         }
//       });
//     };
//     recurse(json);
//     return result;
//   }
// };

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      router: this.props.menuList,
      filtersearch: []
    };
    // const { router } = this.state;
    //   let temp = flattenJson(adapter());
    // let temp = flattenJson(router);

    // let filtereddata = [...temp, ...models];
    // this.state.filtersearch = filtereddata;
  }

  render() {
    let { filtersearch } = this.state;
    return (
      <Fragment>
        {this.props.menuHierarchy.hasOwnProperty('modules') ? (
          <MenuPrepare
            id="menuTag"
            dataRouter={sideMenuFormat(this.props.menuHierarchy)}
            unFilteredData={filtersearch}
          />
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  menuHierarchy: getMenuHierarchy()
});

export default connect(mapStateToProps, {})(App);
