import axios from 'axios';
import { message } from 'antd';
import localStorage from '../../../utils/localStorage';
import { API_ASSET, API_ROLE, LOGBOOK, MASTER } from '../../../commons/api';
import { Table_Master } from '../../../commons/table';

export async function getDatasourceData() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = await fetch(`${LOGBOOK.GET}?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
  if (data && Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function _updateSubmitBatch(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let siteId = localStorage.get('currentSite');
  let data = await fetch(`${LOGBOOK.LogBookSubmit}?SiteId=${siteId}  `, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      ...payload,
      actualTimeSelection: {
        startDate: '2020-10-20 00:00:00',
        endDate: '2020-10-20 16:00:00'
      },
      status: 'submitted',
      logId: '51'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function deleteFileUpload(fileName) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`${MASTER.DeleteFile}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: {
      fileName
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function getConfigByElogName(logBookName) {
  const token = localStorage.get('accessToken');
  let Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  const getDeviceList = {
    method: 'GET',
    url: `${LOGBOOK.LogBookConfigurationByLogBookId}?SiteId=${siteId}&LogBookId=${logBookName}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(getDeviceList)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getELogAdhocJSONData(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = await fetch(`${LOGBOOK.EditList}?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.message) {
        message.warning(response.message);
      } else if (response) {
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}
export async function _getEquipmentList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`${LOGBOOK.EquimentList}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({ ProcessSelection: 'Milling' })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
  return data;
}

export async function _saveELogEdit(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = await fetch(`${LOGBOOK.Save}?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function updateStatus(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;

  let data = await fetch(`${MASTER.UpdateStatus}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  return data;
}

export async function updateBatchStatus(payload, elogName) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  let url = '';
  if (elogName && elogName === 'Power Sales') {
    url = `${LOGBOOK.SalesLogBookSubmit}`;
  } else {
    url = `${LOGBOOK.Submit}`;
  }

  let siteId = localStorage.get('currentSite');
  let data = await fetch(`${url}?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
  return data;
}

export async function _getRolesBySite() {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  let data = await fetch(`${API_ROLE.GET}?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });
  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getRecurrencePatternData() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = await fetch(`${LOGBOOK.Recurrence}?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch(() => {
      return [];
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getFormTypeData() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = await fetch(`${LOGBOOK.DropDown}?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      TableName: Table_Master.MasterFormTypes,
      columnName: 'Type'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getLocationData() {
  let siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;

  let data = await fetch(`${API_ASSET.GET}?SiteId=${siteId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getELogTypeData() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');

  let data = await fetch(`${LOGBOOK.DropDown}?SiteId=${siteId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    body: JSON.stringify({
      tableName: Table_Master.MasterFormTypes,
      columnName: 'Type'
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getTableMasters() {
  const token = localStorage.get('accessToken');
  const Authorization = 'Bearer ' + token;

  let data = await fetch(`${LOGBOOK.TableList}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.log(error);
    });

  if (Array.isArray(data)) {
    return data;
  } else {
    return [];
  }
}

export async function getList(logbookName) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  const Object = {
    method: 'GET',
    url: `${LOGBOOK.GET}?SiteId=${siteId}&LogBookId=${logbookName}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(Object)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      }
    });
}
