import React, { Fragment } from 'react';

import { Form, Select, Row, Col, message, TreeSelect, Tabs } from 'antd';

// import { getSpecsList,getSupplierList } from "../EquipmentMastersComponents/ApiCall";
import { getDeviceType, getHierarchyLevel, getOverview } from './ApiCalls';
import { EquipmentDetails } from './EquipmentDetails';
import { StyledApplication1 } from '../style';
import './style.css';
const { Option } = Select;
const { TabPane } = Tabs;
const { TreeNode } = TreeSelect;

class EquipmentOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceTypeList: [],
      deviceType: undefined,
      deviceList: [],
      deviceCode: undefined,
      hierarchy: [],
      Specifications: [],

      DeviceId: undefined,
      recordData: {}
    };
  }
  formRef = React.createRef();
  componentDidlMount = async () => {
    try {
      let deviceType = await getDeviceType();

      this.setState({
        deviceTypeList: deviceType.data
      });
    } catch (error) {
      message.warn(`${error.message}`);
    }
  };
  handelDeviceType = async (value) => {
    this.setState({ deviceType: value });
    try {
      let deviceList = await getHierarchyLevel(value);
      this.setState({
        deviceList: deviceList.data,
        DeviceId: undefined,
        deviceCode: undefined,
        recordData: {}
      });
    } catch (err) {
      return {};
    }
  };
  handleDevice = async (DeviceId) => {
    this.setState({ DeviceId });
    try {
      let record = await getOverview(DeviceId);
      let recordDat = record.data && record.data;
      this.setState({ recordData: recordDat });
    } catch (err) {
      message.warn(`${err.message}`);
    }
    this.setState({ deviceCode: DeviceId });
  };

  onTreeSelect = async (DeviceId) => {
    this.setState({ DeviceId });
    try {
      let record = await getOverview(DeviceId);
      let recordDat = record.data && record.data;
      this.setState({ recordData: recordDat });
    } catch (err) {
      message.warn(`${err.message}`);
    }
  };
  renderTreeNodes = (data) =>
    data &&
    Array.isArray(data) &&
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode value={item.DeviceId} title={item.Name} key={item.Id} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode value={item.DeviceId} key={item.Id} title={item.Name} {...item} />;
    });

  handleChange = () => {};
  render() {
    let { deviceType, deviceTypeList, deviceList, DeviceId, recordData } = this.state;
    //  let fields= recordData &&  Array.isArray(recordData)
    // let overview= recordData && Object.keys(recordData).length

    return (
      <StyledApplication1 style={{ minHeight: window.innerHeight - 170, background: '#eff1f3' }}>
        <Fragment>
          <Form className="headerDiv" layout="vertical">
            <Row>
              <Col span={5} offset={1}>
                <Form.Item label="Select Equipment Category">
                  <Select
                    placeholder="Select Equipment Category"
                    style={{ width: '300px' }}
                    value={deviceType}
                    onChange={this.handelDeviceType}
                  >
                    {deviceTypeList.map((param, index) => {
                      return (
                        <Option key={index} value={param.Id}>
                          {' '}
                          {param.Name}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5} offset={1}>
                <Form.Item label="Select  Equipment">
                  <Select
                    placeholder="Select Equipment"
                    style={{ width: '300px' }}
                    value={DeviceId}
                    onChange={this.handleDevice}
                  >
                    {deviceList.map((param, index) => {
                      return (
                        <Option key={index} value={param.DeviceId}>
                          {' '}
                          {param.Name}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          {DeviceId !== undefined ? (
            <div className="card-container">
              <Tabs type="card">
                <TabPane tab="Equipment Details" key="1">
                  {Object.keys(recordData).length !== 0 /*  */ ? (
                    <EquipmentDetails data={recordData} />
                  ) : null}
                </TabPane>
                {/* <TabPane tab="Equipment Events" key="2">
       <EquipmentEvent data={data}/>
      </TabPane> */}
                <TabPane tab="Equipment History" key="3">
                  {/* <EquipmentEvent/> */}
                </TabPane>
              </Tabs>
            </div>
          ) : null}
        </Fragment>
      </StyledApplication1>
    );
  }
}

//   const mapStateToProps = createStructuredSelector({
//     translationCache: getTranslationCache(),
//   });connect(mapStateToProps)

export default EquipmentOverview;
