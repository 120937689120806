import React, { Component } from 'react';
import axios from 'axios';
import { LOGBOOK } from '../../../../commons/api';
import localStorage from '../../../../utils/localStorage';

class KeyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: [],
      conditionList: []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.text !== this.props.text) {
      this.setState({
        text: this.props.text
      });
    }
    if (prevprops.datasource !== this.props.datasource) {
      let { column, datasource } = this.props;
      if (column && column.table && column.table.payload && column.table.payload.conditionList) {
        //Condition based filter option
        let conditionList = column.table.payload.conditionList;
        let dataSourceKeys = Object.keys(datasource);
        conditionList &&
          Array.isArray(conditionList) &&
          conditionList.map((condition, key) => {
            if (condition.referenceKey && dataSourceKeys.indexOf(condition.referenceKey) > -1) {
              conditionList[key].value = datasource[condition.referenceKey];
            }
            return {};
          });
        this.setState({
          conditionList
        });
        this._getOptionList(this.props.column, conditionList);
      }
    }
  }

  componentDidMount() {
    let { column, datasource } = this.props;

    if (
      column &&
      datasource &&
      column.table &&
      column.table.payload &&
      column.table.payload.conditionList
    ) {
      //Condition based filter option
      let conditionList = column.table.payload.conditionList;
      let dataSourceKeys = Object.keys(datasource);
      conditionList &&
        Array.isArray(conditionList) &&
        conditionList.map((condition, key) => {
          if (condition.referenceKey && dataSourceKeys.indexOf(condition.referenceKey) > -1) {
            conditionList[key].value = datasource[condition.referenceKey];
          }
          return {};
        });
      this.setState({
        conditionList
      });
      this._getOptionList(this.props.column, conditionList);
    } else {
      this._getOptionList(this.props.column);
    }
  }

  _getOptionList = (tableName, conditionList) => {
    // let { conditionList } = this.state
    const accessToken = localStorage.get('accessToken');
    let objectHeader = {};
    if (tableName && tableName.table && Array.isArray(tableName.table)) {
      //Direct OPtions Array
      this.setState({
        optionList: tableName.table
      });
      return tableName.table;
    }
    if (tableName && tableName.table && tableName.table.url && !tableName.table.method) {
      objectHeader = {
        //URL,GET
        method: 'GET',
        url: `${tableName.table.url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
    } else if (
      tableName &&
      tableName.table &&
      tableName.table.url &&
      tableName.table.method &&
      tableName.table.payload &&
      tableName.table.method === 'POST'
    ) {
      objectHeader = {
        //URL,POST
        method: tableName.table.method,
        url: `${tableName.table.url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          ...tableName.table.payload,
          conditionList: conditionList ? conditionList : []
        }
      };
    } else {
      //Table Name
      objectHeader = {
        method: 'POST',
        url: `${LOGBOOK.DropDown}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          tableName: `master."${tableName.table}"`
        }
      };
    }
    return axios(objectHeader)
      .then((response) => {
        if (Array.isArray(response.data)) {
          let optionList = response.data;
          this.setState({
            optionList
          });
          let data =
            optionList && Array.isArray(optionList) && optionList.length > 0 ? optionList[0] : '';
          this.props.handleDataChange(data, tableName.key);
        }
      })
      .catch(() => {
        return [];
      });
  };

  // onSearch = (val) => {
  //   }

  render() {
    let { optionList } = this.state;
    return (
      <div>
        {optionList && Array.isArray(optionList) && optionList.length > 0 ? optionList[0] : ''}
      </div>
    );
  }
}

export default KeyComponent;
