import React, { Fragment } from 'react';

import Report from '../../../../Reports';

class ReportComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      translationCache: props.translationCache || []
    };
  }
  componentDidMount() {
    this.getData(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.AssetCode !== this.props.AssetCode
    ) {
      this.getData(this.props);
    }
  }

  getData = (props) => {
    let { graphprops, AssetCode } = props;
    this.setState({
      AssetCode,
      report:
        graphprops && graphprops[graphprops.graph] && graphprops[graphprops.graph].ReportName
          ? graphprops[graphprops.graph].ReportName
          : '',
      reportType: 'Report'
    });
  };

  render() {
    let { AssetCode, report, reportType } = this.state;
    return (
      <Fragment>
        <Report
          match={{
            params: {
              report,
              reportType
            }
          }}
          location={{
            search: `?AssetCode=${AssetCode}&report=true`
          }}
        />
      </Fragment>
    );
  }
}

export default ReportComponent;
