import React, { Component } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { Form, Input, Tabs, DatePicker } from 'antd';

import SelectComponennt from '../Widgets/SelectComponent';
import TableView from '../Widgets/TableView';
import { dailyShift, morningShift, nightShift, defaultRange } from '../../Constants/index.js';

import '../eLogCalls';
import 'antd/lib/date-picker/style/css';

const { TabPane } = Tabs;

class FormSubSectionTableMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: cloneDeep(props.formObject) || {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: '',
      shift: 'daily'
    };
  }

  componentDidMount() {
    this.setState({
      formObject: this.props.formObject,
      columns:
        this.props.formObject && this.props.formObject.columns ? this.props.formObject.columns : [],
      data:
        this.props.formObject && this.props.formObject.datasource
          ? this.props.formObject.datasource
          : []
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        formObject: cloneDeep(this.props.formObject),
        columns:
          this.props.formObject && this.props.formObject.columns
            ? this.props.formObject.columns
            : [],
        data:
          this.props.formObject && this.props.formObject.datasource
            ? this.props.formObject.datasource
            : [],
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog
      });
    }
  }

  addDownTime = () => {
    let { formObject } = this.state;
    if (
      formObject &&
      formObject['subSection'] &&
      formObject['subSection']['headerValue'] &&
      formObject['subSection']['headerValue'].Equipment
    ) {
      let equipmentKey = formObject['subSection']['headerValue'].Equipment;
      formObject = {
        ...formObject,
        [equipmentKey]: {
          columns: formObject['subSection'].columns,
          datasource: {}
        }
      };
      this.props.downTimeData(formObject, false);
    }
  };

  onHeaderInputChange = (columnValue, columnKey) => {
    let formObject = cloneDeep(this.props.formObject);

    formObject = {
      ...formObject,
      mainHeaderValue: {
        ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
        [columnKey]: columnValue
      }
    };
    this.props.downTimeData(formObject, false);
  };

  handleKeyPress = () => {};

  onDateHeaderMainChange = (date, columnKey) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      mainHeaderValue: {
        ...(formObject && formObject.mainHeaderValue ? formObject.mainHeaderValue : {}),
        [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
      }
    };

    this.props.downTimeData(formObject, false);
  };

  renderHeaderSwitch(formObject, columns) {
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onHeaderInputChange(e.target.value, columns.key)}
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            value={
              formObject &&
              formObject['mainHeaderValue'] &&
              formObject['mainHeaderValue'][columns.key]
                ? moment(formObject['mainHeaderValue'][columns.key], 'YYYY-MM-DD HH:mm:ss')
                : undefined
            }
            allowClear={false}
            disabledDate={this.disabledDate}
            onChange={(e) => this.onDateHeaderMainChange(e, columns.key)}
            style={{ width: 300 }}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : undefined
            }
            handleFieldChange={(e) => this.onHeaderInputChange(e, columns.key)}
            handleKeyPress={this.handleKeyPress}
          />
        );

      default:
        return (
          <Input
            style={{ width: 300 }}
            value={
              formObject && formObject.mainHeaderValue && formObject.mainHeaderValue[columns.key]
                ? formObject.mainHeaderValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onHeaderInputChange(e.target.value, columns.key)}
          />
        );
    }
  }

  disabledDate = (current) => {
    return current > moment().endOf('day');
  };

  saveTableViewData = (name, data) => {
    let { formObject } = this.props;
    formObject = {
      ...formObject,
      subSectionTable: {
        ...(formObject['subSectionTable'] && formObject['subSectionTable']
          ? formObject['subSectionTable']
          : {}),
        [name]: {
          ...(formObject['subSectionTable'] &&
          formObject['subSectionTable'] &&
          formObject['subSectionTable'][name]
            ? formObject['subSectionTable'][name]
            : {}),
          datasource: data
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };
  dateRanges = (shift) => {
    switch (shift) {
      case 'daily': {
        return dailyShift;
      }
      case 'Morning Shift': {
        return morningShift;
      }
      case 'Night Shift': {
        return nightShift;
      }
      default:
        return defaultRange;
    }
  };

  render() {
    let { getFieldDecorator } = this.props && this.props.form ? this.props.form : {};
    let { formObject } = this.props;
    return (
      <div
        style={{
          fontWeight: 'bold',
          margin: '20px 0'
        }}
      >
        <Form className="batchUpdateForm1">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              boxSizing: 'border-box'
            }}
          >
            {formObject && formObject.mainHeader
              ? formObject.mainHeader.map((header, index) => {
                  return (
                    <div key={index} style={{ paddingRight: '2em' }}>
                      <Form.Item required={false} label={header.displayName}>
                        {getFieldDecorator(
                          header.key,
                          {}
                        )(<div>{this.renderHeaderSwitch(formObject, header)}</div>)}
                      </Form.Item>
                    </div>
                  );
                })
              : null}
          </div>
          <div style={{ border: '1px solid white', padding: '20px', marginTop: '20px' }}>
            <Tabs>
              {formObject.subSectionTable &&
                Object.keys(formObject.subSectionTable).map((subSection) => {
                  return (
                    <TabPane tab={subSection} key={subSection}>
                      <TableView
                        form={formObject.subSectionTable[subSection]}
                        recordType={'FormSubSectionTable'}
                        saveTableViewData={(e) => this.saveTableViewData(subSection, e)}
                      />
                    </TabPane>
                  );
                })}
            </Tabs>
          </div>
        </Form>
      </div>
    );
  }
}

export default FormSubSectionTableMain;
