import React from 'react';
import moment from 'moment';
import L from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { Table } from 'antd';

import 'leaflet/dist/leaflet.css';
import './css/map.css';
import './css/Table.css';
import history from '../../../../../commons/history';
import { _getMarkerList, _getTableData, getDataForPopup } from './apicalls';
// import turbineRunning from './images/turbine.gif';
// import turbineStop from './images/turbine.png';
import LeftSidePanel from './LeftSidePanel';
import { LeftSquareFilled } from '@ant-design/icons';
import localStorage from '../../../../../utils/localStorage';
import Tooltip from 'antd/es/tooltip';

class MapWithClustering extends React.Component {
  constructor() {
    super();
    this.state = {
      currentCoordinates: { lat: 17.4263, lng: 78.4305, zoom: 5 },
      defaultCenter: [17.4263, 78.4305],
      defaultZoom: 5,
      markerList: [],
      lastUpdatedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      selectedMarker: {},
      viewType: 'maps',
      selectedTreeValue: { Type: 'Site', Code: 'RenewableEnergy', Name: 'RenewableEnergy' },
      dataSource: [],
      columns: []
    };
  }

  timeTicket = null;

  componentDidMount() {
    let { selectedTreeValue } = this.state;
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.onTreeSelect(
      selectedTreeValue.Type,
      selectedTreeValue.Code,
      selectedTreeValue.Name,
      this.state.viewType
    );
    // this.timeTicket = setInterval(() => {
    //   this.onTreeSelect(selectedTreeValue.Type, selectedTreeValue.Code,selectedTreeValue.Name, this.state.viewType);
    // }, 60000);
  }
  componentWillUnmount() {
    clearInterval(this.timeTicket);
  }

  addMarkers = async (markerList) => {
    let currentCoordinates = {};
    let markers =
      markerList &&
      Array.isArray(markerList) &&
      markerList.map((mark) => {
        return {
          title: mark.Name,
          value: mark.Code,
          AliasCode: mark.AliasCode,
          Type: mark.Type,
          ImageURL: mark.ImageURL,
          position: {
            lat: mark.Latitude,
            lng: mark.Longitude
          }
        };
      });

    if (
      markerList &&
      Array.isArray(markerList) &&
      markerList[0] &&
      markerList[0].ParentLatitude &&
      markerList[0].ParentLongitude &&
      markerList[0].ZoomUnit
    ) {
      currentCoordinates = {
        lat: markerList[0].ParentLatitude,
        lng: markerList[0].ParentLongitude,
        zoom: markerList[0].ZoomUnit
      };
    } else {
      currentCoordinates = { lat: 17.4263, lng: 78.4305, zoom: 5 };
    }
    this.setState({
      currentCoordinates,
      markers,
      lastUpdatedTime: moment().format('YYYY-MM-DD HH:mm:ss')
    });
  };

  onTreeSelect = async (Type, Code, Name, viewType) => {
    let selectedTreeValue = { Type, Code, Name };
    if (viewType === 'maps') {
      let markerLists = await _getMarkerList(selectedTreeValue);
      this.addMarkers(markerLists, Type);
    } else {
      let dataSource = await _getTableData(selectedTreeValue);
      if (dataSource && dataSource[0] && Object.keys(dataSource[0])) {
        let eliminateKeys = ['Type', 'Code', 'Status', 'AliasCode', 'Rated Capacity'];
        let columns = Object.keys(dataSource[0])
          .filter((e) => !eliminateKeys.includes(e))
          .map((col) => {
            return {
              title: col,
              dataIndex: col,
              key: col,
              width: 150,
              render: (text, record) => {
                return (
                  <>
                    {(() => {
                      if (col === 'Name' && record.Type === 'Device') {
                        return (
                          <Tooltip placement="top" title={'Redirect to dashboard'}>
                            <div
                              style={{ fontWeight: 'bolder', color: '#045c6a', cursor: 'pointer' }}
                              onClick={() => {
                                if (record && record.Type === 'Device' && record.Code) {
                                  const siteId = localStorage.get('currentSite');
                                  history.push({
                                    pathname: `/rubus/dashboard/Renewable Energy`,
                                    state: {
                                      DeviceCode: `${siteId}_${record.Code}`,
                                      DeviceName: record.Name
                                    }
                                  });
                                }
                              }}
                            >
                              {/* <img
                              src={record.Status === 'Running' ? turbineRunning : turbineStop}
                              alt="logo"
                              style={{ width: '20px' }}
                            /> */}
                              {text}
                            </div>
                          </Tooltip>
                        );
                      } else if (col === 'Name') {
                        return (
                          <Tooltip placement="top" title={'Open Turbines'}>
                            <div
                              style={{ fontWeight: 'bolder', color: '#045c6a', cursor: 'pointer' }}
                              onClick={() => {
                                if (record && record.Type === 'Asset') {
                                  this.onTreeSelect(record.Type, record.Code, record.Name, 'table');
                                }
                              }}
                            >
                              {text}
                            </div>
                          </Tooltip>
                        );
                      }
                      return text;
                    })()}
                  </>
                );
              }
            };
          });
        this.setState({ columns, dataSource });
      }
    }
    this.setState({ selectedTreeValue });
  };

  onMarkerClick = async (mark) => {
    let payload = {
      Code: mark.value,
      Type: mark.Type
    };
    let selectedMarker = await getDataForPopup(payload);
    this.setState({ selectedMarker });
  };
  generateTitle = (mark) => {
    let { selectedTreeValue } = this.state;
    if (selectedTreeValue && selectedTreeValue.Type && selectedTreeValue.Type === 'Site') {
      return (
        <div>
          {' '}
          <span style={{ fontWeight: 'bold' }}>Site :</span> {mark.Name}
        </div>
      );
    } else if (mark && mark.Type && mark.Type === 'Asset') {
      return (
        <div>
          {' '}
          <span style={{ fontWeight: 'bold' }}>Location :</span> {mark.Name}
        </div>
      );
    }
  };

  render() {
    const {
      markers,
      viewType,
      selectedMarker,
      selectedTreeValue,
      lastUpdatedTime,
      dataSource,
      columns,
      currentCoordinates
    } = this.state;
    let eliminateKeysPopup = ['Name', 'Rated Capacity', 'TotalTurbines'];
    return (
      <div>
        <div
          style={{
            color: 'black',
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: '#aad3df'
          }}
        >
          Last Updated Time:
          <div style={{ marginRight: '10px' }}> {lastUpdatedTime}</div>
        </div>
        <div className="App">
          <div className="sidebarLeft">
            <LeftSidePanel
              onTreeSelect={(Type, Code, Name) => {
                this.onTreeSelect(Type, Code, Name, viewType);
              }}
            />
          </div>
          <div style={{ backgroundColor: '#aad3df' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: '#aad3df'
              }}
            >
              <div style={{ color: 'black', margin: '0px 10px' }}>
                {this.generateTitle(selectedTreeValue)}
              </div>
              <div>
                <LeftSquareFilled style={{ margin: '5px', color: 'black' }} />
                {viewType === 'maps' ? (
                  <a
                    style={{ marginRight: '10px', color: 'black' }}
                    onClick={() => {
                      this.setState({ viewType: 'table' });
                      this.onTreeSelect(
                        selectedTreeValue.Type,
                        selectedTreeValue.Code,
                        selectedTreeValue.Name,
                        'table'
                      );
                    }}
                  >
                    Show Table
                  </a>
                ) : (
                  <a
                    style={{ marginRight: '10px', color: 'black' }}
                    onClick={() => {
                      this.setState({ viewType: 'maps' });
                      this.onTreeSelect(
                        selectedTreeValue.Type,
                        selectedTreeValue.Code,
                        selectedTreeValue.Name,
                        'maps'
                      );
                    }}
                  >
                    Show GIS
                  </a>
                )}
              </div>
            </div>
            {viewType === 'maps' ? (
              <Map
                center={[currentCoordinates.lat, currentCoordinates.lng]}
                zoom={currentCoordinates.zoom}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                />
                {markers &&
                  Array.isArray(markers) &&
                  markers.map((mark, index) => {
                    return (
                      <Marker
                        key={index}
                        icon={
                          new L.Icon({
                            iconUrl: `${mark.ImageURL}`,
                            iconRetinaUrl: `${mark.ImageURL}`,
                            popupAnchor: [-0, -0],
                            iconSize: [50, 60]
                          })
                        }
                        onclick={() => this.onMarkerClick(mark)}
                        ondblclick={() => {
                          if (mark.Type === 'Device') {
                            history.push({
                              pathname: `/rubus/dashboard/Renewable Energy`,
                              state: { DeviceCode: mark.AliasCode, DeviceName: mark.title }
                            });
                          } else if (mark.Type === 'Asset') {
                            this.onTreeSelect(mark.Type, mark.value, mark.Name, 'maps');
                          }
                        }}
                        position={[mark.position.lat, mark.position.lng]}
                      >
                        <Popup>
                          <div>
                            <div style={{ fontWeight: 'bold' }}>
                              {selectedMarker && selectedMarker.Name ? selectedMarker.Name : ''}
                            </div>
                            {selectedMarker &&
                            Object.keys(selectedMarker) &&
                            Object.keys(selectedMarker).length > 0
                              ? Object.keys(selectedMarker)
                                  .filter((e) => !eliminateKeysPopup.includes(e))
                                  .map((sMark) => {
                                    return (
                                      <div key={index}>
                                        {sMark} : {selectedMarker[sMark]}
                                      </div>
                                    );
                                  })
                              : null}
                          </div>
                        </Popup>
                      </Marker>
                    );
                  })}
              </Map>
            ) : (
              <div className="sidebarRight">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={false}
                  scroll={{ y: 800 }}
                  style={{ margin: '5px', marginTop: 10 }}
                  onRow={(record) => {
                    return {
                      onDoubleClick: async () => {
                        if (record && record.Type === 'Asset') {
                          this.onTreeSelect(record.Type, record.Code, record.Name, 'table');
                        }
                        if (record && record.Type === 'Device' && record.AliasCode) {
                          history.push({
                            pathname: `/rubus/dashboard/Renewable Energy`,
                            state: { DeviceCode: record.AliasCode, DeviceName: record.Name }
                          });
                        }
                      }
                    };
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MapWithClustering;
