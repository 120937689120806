import indigoTheme from './indigo.json';
import lightIndigoTheme from './lightIndigo.json';
import blueTheme from './blue.json';
import lightBlueTheme from './lightBlue.json';
import greenTheme from './green.json';
import lightGreenTheme from './lightGreen.json';
import redTheme from './red.json';
import lightRedTheme from './lightRed.json';
import darkTheme from './dark.json';
import lightTheme from './light.json';

export default {
  indigoTheme,
  lightIndigoTheme,
  blueTheme,
  lightBlueTheme,
  greenTheme,
  lightGreenTheme,
  redTheme,
  lightRedTheme,
  darkTheme,
  lightTheme
};
