import styled from 'styled-components';
import { Button } from 'antd';

export const StyledDevices = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
export const AnchorButton = styled(Button)`
  background-color: #eaeaea;
  border: none;
  color: #4da7fa;
  box-shadow: none;
`;
export const DrawerFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
`;

export const StyledApplication = styled.div`
  background: #173049;
  margin: 20px;
  padding: 30px;
  min-height: 750px;
`;

export const AddTableButton = styled(Button)`
  margin-bottom: 20px;
`;
