import React from 'react';
import axios from 'axios';
import themeSettings from '../Utils/themeSettings.json';
// import translation from '../Utils/translation';
import ReactEcharts from '../Utils/Echarts/Echarts';
import moment from 'moment';
import _ from 'lodash';
import {
  StyledComponent,
  StyledForm,
  StyledModal,
  StyledSubHeading,
  StyledTable,
  StyledTabs
} from '../CSS/style';
import {
  getList,
  RegisterModel,
  getDeployHistory,
  DeployModel,
  getMetaData
} from '../Utils/FormCalls';
import localStorage from '../../../utils/localStorage';
import history from '../../../commons/history';
import { decode as base64_decode } from 'base-64';
import { getTranslationCache } from '../../../selectors/language';
// import { constant } from '../Utils/Constants';
import { Button, DatePicker, Form, Input, Select, Tabs, Tree, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import { getUserProfile } from '../../../selectors/layout';
import { connect } from 'react-redux';
import '../CSS/index.css';
import { DRIFT, IPAddress, RBSLOOKUPMaster } from '../../../commons/api';
const { TextArea } = Input;
const { TabPane } = Tabs;
const { TreeNode } = Tree;
const { Option } = Select;
const APIList = {
  getDeviceList: `${DRIFT.GetDevice}`,
  getParameterList: `${DRIFT.DriftParameters}`,
  getMetrics: `${RBSLOOKUPMaster.LookUpItemsGetById}`,
  driftCreate: `${IPAddress.PREDICTIONIP}/pred_reqs/datadrift`,
  driftSave: `${DRIFT.CREATE}`,
  driftGraphResults: `${DRIFT.DataDrift}`,
  getById: `${DRIFT.GET}`
};
class MonitorDrift extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      translationCache: props.translationCache || {},
      userProfile: props && props.userProfile ? props.userProfile : {},
      // columns: [
      //   {
      //     title: this.translation('Feature'),
      //     dataIndex: 'Tags',
      //     key: 'Tags'
      //   },
      //   {
      //     title: this.translation('Description'),
      //     dataIndex: 'Description',
      //     key: 'Description'
      //   },
      //   {
      //     title: this.translation('Drift Metric'),
      //     dataIndex: 'Methods',
      //     key: 'Methods'
      //   },
      //   {
      //     title: this.translation('Drift Detected'),
      //     dataIndex: 'Drift_Detected',
      //     key: 'Drift_Detected',
      //     render: (text) =>
      //       text === true
      //         ? this.translation('Yes')
      //         : this.translation('No')
      //   },
      //   {
      //     title: this.translation('Drift Score'),
      //     dataIndex: 'Drift_Score',
      //     key: 'Drift_Score'
      //   }
      // ],
      dataSource: [],
      deviceList: [],
      parameterList: [],
      metricsData: [],
      tableResults: true,
      graphResults: false,
      loading: false,
      expandedKey: null,
      expanded: false
    };
    this.hRef = React.createRef();
  }
  componentDidMount = async () => {
    let response = await getList(APIList.getDeviceList);
    var metricsData = await getDeployHistory(APIList.getMetrics, 'LookupName', 'DRIFTMETRICS');
    this.setState({
      deviceList: response && Array.isArray(response) ? response : [],
      metricsData
    });
    if (
      this.props &&
      this.props.location &&
      this.props.location &&
      this.props.location.state === undefined
    ) {
      let path = history.location.pathname.split('/rubus/RbsAnalytics/MonitorDrift/');
      let Id = path && path[1] && base64_decode(path[1]) ? base64_decode(path[1]) : null;
      if (Id && Id !== null) {
        let data = await getMetaData(APIList.getById, 'Id', Id);

        let formObject = data && data ? data : {};
        _.cloneDeepWith(formObject, (value) => {
          return value !== null && value !== undefined
            ? {
                ...value,
                ...(value['CurrentFromDate']
                  ? (value['CurrentFromDate'] = moment
                      .utc(value.CurrentFromDate, 'YYYY-MM-DD HH:mm:ss')
                      .local())
                  : null),
                ...(value['CurrentToDate']
                  ? (value['CurrentToDate'] = moment
                      .utc(value.CurrentToDate, 'YYYY-MM-DD HH:mm:ss')
                      .local())
                  : null),
                ...(value['RefFromDate']
                  ? (value['RefFromDate'] = moment
                      .utc(value.RefFromDate, 'YYYY-MM-DD HH:mm:ss')
                      .local())
                  : null),
                ...(value['RefToDate']
                  ? (value['RefToDate'] = moment
                      .utc(value.RefToDate, 'YYYY-MM-DD HH:mm:ss')
                      .local())
                  : null)
              }
            : _.noop();
        });

        if (this.hRef.current) {
          this.hRef.current.setFieldsValue({ ...formObject });
        }
        let parameterList = {
          DeviceCode: formObject.Devices
        };
        let response = await RegisterModel(APIList.getParameterList, parameterList);
        this.setState({
          formObject,
          parameterList:
            response &&
            response.data &&
            response.data.Operational &&
            Array.isArray(response.data.Operational)
              ? response.data.Operational
              : [],
          tableResults: true,
          dataSource: data && data.Result ? data.Result : []
        });
      }
    } else if (
      (this.props &&
        this.props.location &&
        this.props.location !== undefined &&
        this.props.location.state !== undefined &&
        this.props.location.state.ModelName,
      this.props.location.state.relatedTags)
    ) {
      let parameterList = {
        DeviceCode: [Object.keys(this.props.location.state.relatedTags)[0]]
      };
      let response = await RegisterModel(APIList.getParameterList, parameterList);
      let formObject = {
        ...this.state.formObject,
        ModelName: this.props.location.state.ModelName,
        Tags:
          Object.keys(this.props.location.state.relatedTags) &&
          this.props.location.state.relatedTags[
            Object.keys(this.props.location.state.relatedTags)[0]
          ]
            ? this.props.location.state.relatedTags[
                Object.keys(this.props.location.state.relatedTags)[0]
              ]
            : [],
        Devices: [Object.keys(this.props.location.state.relatedTags)[0]],
        RefFromDate: this.props.location.state.FromTime
          ? moment.utc(this.props.location.state.FromTime, 'YYYY-MM-DD HH:mm:ss')
          : null,
        RefToDate: this.props.location.state.ToTime
          ? moment.utc(this.props.location.state.ToTime, 'YYYY-MM-DD HH:mm:ss')
          : null
      };
      if (this.hRef.current) {
        this.hRef.current.setFieldsValue({ ...formObject });
      }
      this.setState({
        formObject,
        parameterList:
          response &&
          response.data &&
          response.data.Operational &&
          Array.isArray(response.data.Operational)
            ? response.data.Operational
            : []
      });
    }
  };
  componentDidUpdate = async (prevProps) => {
    if (
      this.props.userProfile !== prevProps.userProfile ||
      this.props.translationCache !== prevProps.translationCache
    ) {
      this.setState({
        userProfile: this.props.userProfile,
        translationCache: this.props.translationCache
      });
    }
  };
  renderTreeNodes = (data) => {
    return (
      data &&
      data !== undefined &&
      Array.isArray(data) &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <TreeNode title={item.key} key={item.key} dataRef={item.key}>
              {this.renderTreeNodes(item.children, item)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.value} title={item.key} dataRef={item.value} />;
      })
    );
  };
  onCheck = async (key, value) => {
    let formObject = this.hRef.current.getFieldsValue();
    formObject = {
      ...formObject,
      [key]: value
    };
    let parameterList = {
      DeviceCode: value
    };
    let response = await RegisterModel(APIList.getParameterList, parameterList);
    this.hRef.current.setFieldsValue(formObject);
    this.setState({
      formObject,
      parameterList:
        response &&
        response.data &&
        response.data.Operational &&
        Array.isArray(response.data.Operational)
          ? response.data.Operational
          : []
    });
  };
  onFormSubmit = () => {
    let { userProfile } = this.state;
    this.setState({ loading: true });
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        _.cloneDeepWith(values, (value) => {
          return value !== null && value !== undefined
            ? {
                ...value,
                ...(value['RefFromDate']
                  ? (value['RefFromDate'] = moment(value.RefFromDate)
                      .utc()
                      .format('YYYY-MM-DD HH:mm:ss'))
                  : null),
                ...(value['RefToDate']
                  ? (value['RefToDate'] = moment(value.RefToDate)
                      .utc()
                      .format('YYYY-MM-DD HH:mm:ss'))
                  : null),
                ...(value['CurrentFromDate']
                  ? (value['CurrentFromDate'] = moment(value.CurrentFromDate)
                      .utc()
                      .format('YYYY-MM-DD HH:mm:ss'))
                  : null),
                ...(value['CurrentToDate']
                  ? (value['CurrentToDate'] = moment(value.CurrentToDate)
                      .utc()
                      .format('YYYY-MM-DD HH:mm:ss'))
                  : null)
              }
            : null;
        });
        values = {
          ...values,
          UserId: userProfile && userProfile.user && userProfile.user.Id ? userProfile.user.Id : ''
        };
        let response = await DeployModel(APIList.driftCreate, values);
        if (response) {
          this.setState({ loading: false });
        }
        this.setState({ tableResults: true, dataSource: response });
      })
      .catch(() => {});
  };

  saveDriftFunc = (payload) => {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    const Object = {
      method: 'POST',
      url: `${DRIFT.SaveDriftResults}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    return axios(Object)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err && err.response && err.response.data && err.response.data.message) {
          message.info(this.translation(err.response.data.message));
        }
      });
  };

  getDriftResults = async (expanded, record) => {
    // let { translationCache } = this.state;
    let formObject = this.hRef.current.getFieldsValue();
    const siteId = localStorage.get('currentSite');
    let payload = {
      siteId,
      tag: record && record.Tags ? record.Tags : '',
      CurrentFromDate: formObject.CurrentFromDate
        ? moment(formObject.CurrentFromDate).utc().format('YYYY-MM-DD HH:mm:ss')
        : '',
      CurrentToDate: formObject.CurrentToDate
        ? moment(formObject.CurrentToDate).utc().format('YYYY-MM-DD HH:mm:ss')
        : ''
    };
    let response = await DeployModel(APIList.driftGraphResults, payload);
    if (expanded === false) {
      this.setState({ expandedKey: null });
    } else {
      this.setState({ expandedKey: record.key });
    }
    console.log(record.Ref_Mean, record.Ref_LowerLimit, record.Ref_UpperLimit, 'dataaaadriftt');
    let dataDrift = {
      title: {
        text: this.translation('Drift Calculation')
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '12%',
        bottom: '3%',
        containLabel: true
      },
      yAxis: {
        name: this.translation('Proportion'),
        nameLocation: 'middle',
        nameGap: 40,
        type: 'value'
        // boundaryGap: [0, 0.01]
      },
      xAxis: {
        name: this.translation('Value'),
        nameLocation: 'middle',
        nameGap: 20,
        type: 'category',
        data:
          response && response[0] && response[0]['Createdtime'] ? response[0]['Createdtime'] : []
      },
      series: [
        {
          name: this.translation('Current'),
          type: 'scatter',
          symbolSize: 5,
          emphasis: {
            focus: 'series'
          },
          data: response && response[0] && response[0]['values'] ? response[0]['values'] : []
        },
        {
          name: this.translation('Reference'),
          type: 'line',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          markArea: {
            // itemStyle: {
            //   color: 'rgba(255, 173, 177, 0.4)'
            // },
            data: [
              [
                {
                  name: this.translation('Peak'),
                  yAxis:
                    record && record.Ref_LowerLimit && record.Ref_LowerLimit
                      ? record.Ref_LowerLimit
                      : []
                },
                {
                  yAxis:
                    record && record.Ref_UpperLimit && record.Ref_UpperLimit
                      ? record.Ref_UpperLimit
                      : []
                }
              ]
            ]
          },
          markLine: {
            symbol: ['none', 'none'],
            label: { show: true },
            data: [
              {
                lineStyle: {
                  type: 'solid',
                  color: 'green'
                },
                label: {
                  formatter: this.translation('Mean') + '=' + record.Ref_Mean
                },
                name: 'ref_mean',
                yAxis: record && record.Ref_Mean ? record.Ref_Mean : []
              },
              {
                label: {
                  formatter: this.translation('Min') + '=' + record.Ref_LowerLimit
                },
                name: 'Ref_LowerLimit',
                yAxis: record && record.Ref_LowerLimit ? record.Ref_LowerLimit : []
              },
              {
                label: {
                  formatter: this.translation('Max') + '=' + record.Ref_UpperLimit
                },
                name: 'Ref_UpperLimit',
                yAxis: record && record.Ref_UpperLimit ? record.Ref_UpperLimit : []
              }
            ]
          }
        }
      ]
    };
    let dataDistribution = {
      title: {
        text: this.translation('Drift Calculation')
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      yAxis: {
        name: this.translation('Proportion'),
        nameLocation: 'middle',
        nameGap: 50,
        type: 'value'
        // boundaryGap: [0, 0.01]
      },
      xAxis: {
        name: this.translation('Value'),
        nameLocation: 'middle',
        nameGap: 20,
        type: 'category',
        data: record && record.X_values ? record.X_values : []
      },
      series: [
        {
          name: this.translation('Current'),
          type: 'bar',
          data: record && record.Y_current ? record.Y_current : []
        },
        {
          name: this.translation('Reference'),
          type: 'bar',
          data: record && record.Y_reference ? record.Y_reference : []
        }
      ]
    };
    let reference = {
      title: {
        text: this.translation('Drift Calculation')
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      yAxis: {
        name: this.translation('Proportion'),
        nameLocation: 'middle',
        nameGap: 50,
        type: 'value'
        // boundaryGap: [0, 0.01]
      },
      xAxis: {
        name: this.translation('Value'),
        nameLocation: 'middle',
        nameGap: 20,
        type: 'category',
        data: record && record.X_values ? record.X_values : []
      },
      series: [
        {
          name: this.translation('Reference'),
          type: 'bar',
          color: '#91cc75',
          data: record && record.Y_reference ? record.Y_reference : []
        }
      ]
    };
    let current = {
      title: {
        text: this.translation('Drift Calculation')
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      yAxis: {
        name: this.translation('Proportion'),
        nameLocation: 'middle',
        nameGap: 50,
        type: 'value'
        // boundaryGap: [0, 0.01]
      },
      xAxis: {
        name: this.translation('Value'),
        nameLocation: 'middle',
        nameGap: 20,
        type: 'category',
        data: record && record.X_values ? record.X_values : []
      },
      series: [
        {
          name: this.translation('Current'),
          type: 'bar',
          data: record && record.Y_current ? record.Y_current : []
        }
      ]
    };
    this.setState({
      graphResults: true,
      dataDrift,
      dataDistribution,
      reference,
      current,
      expanded
    });
  };
  saveDrift = async () => {
    let { userProfile } = this.state;
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        _.cloneDeepWith(values, (value) => {
          return value !== null && value !== undefined
            ? {
                ...value,

                ...(value['RefFromDate']
                  ? (value['RefFromDate'] = moment(value.RefFromDate)
                      .utc()
                      .format('YYYY-MM-DD HH:mm:ss'))
                  : null),
                ...(value['RefToDate']
                  ? (value['RefToDate'] = moment(value.RefToDate)
                      .utc()
                      .format('YYYY-MM-DD HH:mm:ss'))
                  : null),
                ...(value['CurrentFromDate']
                  ? (value['CurrentFromDate'] = moment(value.CurrentFromDate)
                      .utc()
                      .format('YYYY-MM-DD HH:mm:ss'))
                  : null),
                ...(value['CurrentToDate']
                  ? (value['CurrentToDate'] = moment(value.CurrentToDate)
                      .utc()
                      .format('YYYY-MM-DD HH:mm:ss'))
                  : null)
              }
            : null;
        });
        const accessToken = localStorage.get('accessToken');
        const siteId = localStorage.get('currentSite');
        let payload = {
          method: 'POST',
          url: `${APIList.driftCreate}?SiteId=${siteId}`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            ...values,
            UserId:
              userProfile && userProfile.user && userProfile.user.Id ? userProfile.user.Id : ''
          }
        };

        let response = await RegisterModel(APIList.driftSave, payload);
        message.success(
          response && response.data && response.data.message
            ? this.translation(response.data.message)
            : ''
        );
      })
      .catch(() => {});

    // let formObject = this.hRef.current.getFieldsValue();
    // formObject = {
    //   ...formObject,
    //   CurrentFromDate: moment(formObject.CurrentFromDate)
    //     .utc()
    //     .format("YYYY-MM-DD HH:mm:ss"),
    //   CurrentToDate: moment(formObject.CurrentFromDate)
    //     .utc()
    //     .format("YYYY-MM-DD HH:mm:ss"),
    //   RefFromDate: moment(formObject.RefFromDate)
    //     .utc()
    //     .format("YYYY-MM-DD HH:mm:ss"),
    //   RefToDate: moment(formObject.RefToDate)
    //     .utc()
    //     .format("YYYY-MM-DD HH:mm:ss"),
    // };
    // let response = await RegisterModel(APIList.driftSave, formObject);
    // message.success(
    //   response && response.data && response.data.message
    //     ? response.data.message
    //     : ""
    // );
  };
  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const {
      formObject,
      dataSource,
      // columns,
      deviceListModal,
      deviceList,
      parameterList,
      tableResults,
      graphResults,
      metricsData,
      dataDrift,
      dataDistribution,
      reference,
      current,
      expandedKey,
      expanded
    } = this.state;
    const columns = [
      {
        title: this.translation('Feature'),
        dataIndex: 'Tags',
        key: 'Tags'
      },
      {
        title: this.translation('Description'),
        dataIndex: 'Description',
        key: 'Description'
      },
      {
        title: this.translation('Drift Metric'),
        dataIndex: 'Methods',
        key: 'Methods'
      },
      {
        title: this.translation('Drift Detected'),
        dataIndex: 'Drift_Detected',
        key: 'Drift_Detected',
        render: (text) => (text === true ? this.translation('Yes') : this.translation('No'))
      },
      {
        title: this.translation('Drift Score'),
        dataIndex: 'Drift_Score',
        key: 'Drift_Score'
      }
    ];
    const validateMessages = {
      required: this.translation('${label}') + this.translation('is required!')
    };
    const formItemLayout = { width: '100%', flex: '50%' };

    return (
      <StyledComponent
        theme={themeSettings}
        style={{
          padding: '50px 30px',
          minHeight: window.innerHeight - 143
        }}
      >
        <StyledSubHeading theme={themeSettings}>
          {this.translation('Monitor Drift')}
        </StyledSubHeading>
        <StyledForm
          name="ModelDetails"
          ref={this.hRef}
          autoComplete="off"
          initialValues={formObject}
          theme={themeSettings}
          layout="horizontal"
          labelCol={{ flex: '200px' }}
          labelAlign="right"
          onFinish={this.onFormSubmit}
          onFinishFailed={this.onFinishFailed}
          labelWrap
          validateMessages={validateMessages}
          wrapperCol={{ flex: 1 }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item name="Id"></Form.Item>

            <Form.Item
              label={this.translation('Description')}
              name="Name"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input
                style={{ width: '70%' }}
                placeholder={this.translation('Please Enter Description')}
              ></Input>
            </Form.Item>

            <Form.Item
              label={this.translation('Device')}
              name="Devices"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Select
                mode="multiple"
                placeholder={this.translation('Please Select Device')}
                style={{ width: '70%' }}
                onChange={(value) => this.onCheck('Devices', value)}
              >
                {deviceList &&
                  Array.isArray(deviceList) &&
                  deviceList.map((tag) => {
                    return (
                      <Option value={tag.value} key={tag.key}>
                        {this.translation(tag.key)}
                      </Option>
                    );
                  })}{' '}
              </Select>
            </Form.Item>
            <Form.Item
              label={this.translation('Tags')}
              name="Tags"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Select
                maxTagCount="responsive"
                mode="multiple"
                style={{ width: '70%' }}
                placeholder={this.translation('Please Select Tags')}
              >
                {parameterList &&
                  Array.isArray(parameterList) &&
                  parameterList.map((tag) => {
                    return (
                      <Option value={tag.value} key={tag.key}>
                        {this.translation(tag.value)}-{this.translation(tag.Description)}
                      </Option>
                    );
                  })}{' '}
              </Select>
            </Form.Item>
            <Form.Item
              label={this.translation('Drift Metrics')}
              name="Methods"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Select
                mode="multiple"
                style={{ width: '70%' }}
                placeholder={this.translation('Please Select Drift Metric')}
              >
                {metricsData &&
                  Array.isArray(metricsData) &&
                  metricsData.map((tag) => {
                    return (
                      <Option value={tag.key} key={tag.key}>
                        {this.translation(tag.key)}
                      </Option>
                    );
                  })}{' '}
              </Select>
            </Form.Item>
            <Form.Item
              label={this.translation('Ref From Date')}
              name="RefFromDate"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <DatePicker
                placeholder={this.translation('Please Select Ref From Date')}
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Ref To Date')}
              name="RefToDate"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <DatePicker
                placeholder={this.translation('Please Select Ref To Date')}
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Current From Date')}
              name="CurrentFromDate"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <DatePicker
                placeholder={this.translation('Please Select Current From Date')}
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ width: '70%' }}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Current To Date')}
              name="CurrentToDate"
              rules={[{ required: true }]}
              style={formItemLayout}
            >
              <DatePicker
                placeholder={this.translation('Please Select Current To Date')}
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ width: '70%' }}
              />
            </Form.Item>

            <Form.Item label={this.translation('Comments')} name="Comments" style={formItemLayout}>
              <TextArea
                style={{ width: '70%' }}
                placeholder={this.translation('Add Comments')}
              ></TextArea>
            </Form.Item>
            <Form.Item
              label={this.translation('Model Name')}
              name="ModelName"
              // rules={[{ required: true }]}
              style={formItemLayout}
            >
              <Input
                style={{ width: '70%' }}
                placeholder={this.translation('Please Enter ModelName')}
              ></Input>
            </Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              <Button
                style={{ marginRight: 8 }}
                theme={themeSettings}
                type="primary"
                htmlType="submit"
                data-testid="submitButton"
              >
                {this.translation('Calculate')}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ marginRight: 8 }}
                theme={themeSettings}
                type="primary"
                data-testid="submitButton"
                onClick={() => this.saveDrift()}
              >
                {this.translation('Save')}
              </Button>
            </Form.Item>
          </div>
        </StyledForm>
        {tableResults && tableResults === true ? (
          <StyledTable
            data-testid="workbenchList"
            loading={this.state.loading}
            theme={themeSettings}
            dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
            columns={columns}
            onExpand={(expanded, record) => {
              this.getDriftResults(expanded, record);
            }}
            expandedRowKeys={[expandedKey]}
            expandable={true}
            expandedRowRender={() =>
              expanded === true && graphResults && graphResults === true ? (
                <p
                  style={{
                    margin: 0
                  }}
                >
                  <StyledTabs
                    theme={themeSettings}
                    type="card"
                    defaultActiveKey="1"
                    className="TabsText"
                  >
                    <TabPane tab={this.translation('Data Drift')} key="1">
                      <ReactEcharts
                        option={dataDrift}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{
                          height: '300%',
                          width: '100%',
                          marginBottom: '40px'
                        }}
                        className="react_for_echarts"
                      />
                    </TabPane>
                    <TabPane tab={this.translation('Data Distribution')} key="2">
                      <ReactEcharts
                        option={dataDistribution}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{
                          height: '300%',
                          width: '100%',
                          marginBottom: '40px'
                        }}
                        className="react_for_echarts"
                      />
                    </TabPane>
                    <TabPane tab={this.translation('Reference/Current Data')} key="3">
                      <ReactEcharts
                        option={reference}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{
                          height: '300%',
                          width: '100%',
                          marginBottom: '40px'
                        }}
                        className="react_for_echarts"
                      />
                      <ReactEcharts
                        option={current}
                        notMerge={true}
                        lazyUpdate={true}
                        style={{
                          height: '300%',
                          width: '100%',
                          marginBottom: '40px'
                        }}
                        className="react_for_echarts"
                      />
                    </TabPane>
                  </StyledTabs>
                </p>
              ) : null
            }
            rowExpandable={(record) => record.Feature !== 'Not Expandable'}
          ></StyledTable>
        ) : null}
        <StyledModal
          width="1500px"
          title=""
          open={deviceListModal}
          onCancel={() => {
            this.setState({
              deviceListModal: false
            });
          }}
          closable
          theme={themeSettings}
          footer={[
            <>
              <Button
                key="back"
                onClick={() => {
                  this.setState({
                    deviceListModal: false
                  });
                }}
              >
                {this.translation('Close')}
              </Button>

              <Button
                key="submit"
                type="primary"
                onClick={() =>
                  this.setState({
                    deviceListModal: false
                  })
                }
              >
                {this.translation('Save')}
              </Button>
            </>
          ]}
        >
          <Tree
            checkable
            onCheck={(e) => this.onCheck('devices', e)}
            switcherIcon={<DownOutlined />}
            // checkedKeys={
            //   predictionRequestInfo && predictionRequestInfo["Metrics"]
            //     ? predictionRequestInfo["Metrics"]
            //     : ""
            // }
          >
            {this.renderTreeNodes(deviceList)}
          </Tree>
        </StyledModal>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(MonitorDrift);
