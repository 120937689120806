import axios from 'axios';
import { message } from 'antd';

import localStorage from '../../../utils/localStorage';
import { API_LANGUAGE } from '../../../commons/api';

export async function _getTranslationMasterData() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  let changePassword = {
    method: 'GET',
    url: `${API_LANGUAGE.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(changePassword)
    .then((response) => {
      return response.data;
    })
    .catch(function () {});
}

export async function _saveTranslation(saveObject, apiURL, method) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let payload = {
    method: method,
    url: `${API_LANGUAGE.Language}/${apiURL}?SiteId=${siteId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: saveObject
  };
  return axios(payload)
    .then((response) => {
      if (response && response.data) {
        return response.data;
      }
    })
    .catch(function (error) {
      message.error(error);
    });
}
export async function _getCurrentLanguages() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let changePassword = {
    method: 'GET',
    url: `${API_LANGUAGE.LanguageList}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(changePassword)
    .then((response) => {
      return response.data;
    })
    .catch(function () {});
}
