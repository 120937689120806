import { message } from 'antd';
import axios from 'axios';
import localStorage from '../../../utils/localStorage';
import {
  API_PARAMETER,
  API_HIERARCHYMODULE,
  API_RUBUSMASTERS,
  TROUBLEREPORT,
  GETUSERLIST
} from '../../../commons/api';

export async function createStep(data, StepDirection) {
  let url = '';
  if (StepDirection === 'PreviousStep') {
    url = '/api/ruleGenerator/update';
  } else {
    url = '/api/ruleGenerator/create';
  }
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const header = {
    method: StepDirection === 'PreviousStep' ? 'PATCH' : 'POST',
    url: `${url}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...data,
      SiteId: siteId
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}

export async function getStepsDetailsById(Id) {
  //  return [
  // 	{
  // 		"Id": 23,
  // 		"Step": "ProblemStatement",
  // 		"Value": "Problem Statement3"
  // 	},
  // 	{
  // 		"Id": 64,
  // 		"Step": "Scenario",
  // 		"Options": [
  // 			{
  // 				"Option": "This is Scenario1",
  // 				"FileName": "GuidelinesforupdatinginformationinFactoHR.pdf",
  // 				"FilePath": "/api/aiRuleEngine/uploads/GuidelinesforupdatinginformationinFactoHR.pdf",
  // 				"Page": 2
  // 			},
  // 			{
  // 				"Option": "This is Scenario2",
  // 				"FileName": "Scenario2.pdf",
  // 				"FilePath": "/api/aiRuleEngine/uploads/Scenario2.pdf",
  // 				"Page": 4
  // 			}
  // 		],
  // 		"Value": "This is Scenario1",
  // 		"Type": null
  // 	},
  // 	{
  // 		"Id": 115,
  // 		"Step": "EquipmentType",
  // 		"Options": [
  // 			{
  // 				"Option": "RS1_ATC"
  // 			},
  // 			{
  // 				"Option": "RS1_APS"
  // 			},
  //       {
  // 				"Option": "RS1_ATC1"
  // 			},
  // 			{
  // 				"Option": "RS1_APS1"
  // 			},
  //       {
  // 				"Option": "RS1_ATC2"
  // 			},
  // 			{
  // 				"Option": "RS1_APS2"
  // 			},
  //       {
  // 				"Option": "RS1_ATC3"
  // 			},
  // 			{
  // 				"Option": "RS1_APS3"
  // 			}
  // 		],
  // 		"Value": "",
  // 		"Type": null
  // 	}
  // ]
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const header = {
    method: 'GET',
    url: `/api/ruleGenerator/getById?SiteId=${siteId}&Id=${Id}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}

export async function getStepsList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const header = {
    method: 'GET',
    url: `/api/ruleGenerator/getSteps?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}

export async function getRules() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const header = {
    method: 'GET',
    url: `/api/ruleGenerator/get?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}

export async function _getHierarchyLevels(DeviceType) {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let headers = {
    method: 'GET',
    url: `${API_HIERARCHYMODULE.GetDeviceTypeHierarchy}?DeviceType=${DeviceType}&SiteId=${siteId}`,
    // url: `${API_HIERARCHYMODULE.GetHierarchyLevels}?HierarchyCode=${DeviceType}&SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(headers)
    .then(({ data }) => {
      return data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function _getEquipmentType() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let headers = {
    method: 'GET',
    url: `${API_RUBUSMASTERS.DT_GET}?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(headers)
    .then(({ data }) => {
      return data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function getFileData() {
  const accessToken = localStorage.get('accessToken');
  const siteId = localStorage.get('currentSite');
  let headers = {
    method: 'GET',
    url: `/api/ruleGenerator/getUploadList?SiteId=${siteId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(headers)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}
export async function saveAIRule(param) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const saveRule = {
    method: 'POST',
    url: `/api/ruleGenerator/create?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: { ...param, SiteId: siteId }
  };
  return axios(saveRule)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 400 || err.response.status === 500) {
        message.info(err.response.data.message);
      } else if (err.response.status === 500) {
        message.info(err.response.data.message);
      }
      return err;
    });
}
export async function getBackTestResultsData(payload) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const header = {
    method: 'POST',
    url: `/api/ruleGenerator/backTest?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    },
    data: {
      ...payload,
      SiteId: siteId
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}

export async function getCustomHierarchy(type, DeviceType) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let url = '';
  if (type === 'EquipmentType') {
    url = '/api/ruleGenerator/getCustomHierarchy';
  } else if (type === 'Tag') {
    url = '/api/deviceParameter/getParamByDeviceType';
  }
  let Authorization = 'Bearer ' + token;
  const header = {
    method: 'GET',
    url: `${url}?SiteId=${siteId}${DeviceType ? `&DeviceType=${DeviceType}` : ''}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}

export async function getOptionsList(type, DeviceType) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');
  let url = '';
  if (type === 'EquipmentType') {
    url = '/api/deviceType/get';
  } else if (type === 'Tag') {
    url = '/api/deviceParameter/getParamByDeviceType';
  }

  let Authorization = 'Bearer ' + token;
  const header = {
    method: 'GET',
    url: `${url}?SiteId=${siteId}${DeviceType ? `&DeviceType=${DeviceType}` : ''}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}

export async function getRootLevelData(DeviceType) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const header = {
    method: 'GET',
    url: `/api/ruleGenerator/getRootLevels?DeviceType=${DeviceType}&SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}

export async function getDeviceTypeByCode(AliasCode) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const header = {
    method: 'GET',
    url: `/api/deviceType/get?AliasCode=${AliasCode}&SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}

export async function getRootLevelDataByDT(Tag, DeviceType) {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const header = {
    method: 'GET',
    url: `/api/ruleGenerator/getRootLevels?DeviceType=${DeviceType}&Parameter=${Tag}&SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}

export async function getParameterList() {
  const siteId = localStorage.get('currentSite');
  const token = localStorage.get('accessToken');

  let Authorization = 'Bearer ' + token;
  const header = {
    method: 'GET',
    url: `${API_PARAMETER.GET}?SiteId=${siteId}`,
    headers: {
      Authorization: Authorization
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.response && (err.response.status === 400 || err.response.status === 500)) {
        message.info(err.response.data.message);
      }
    });
}

export async function checkFileAlreadyExistsOrNot(payload) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/chat/checkFile?SiteId=${siteId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data: payload
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function _getAttachmentList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${TROUBLEREPORT.GET}?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function getEditObject(Id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let headersPayload = {
    url: `${TROUBLEREPORT.GET}?Id=${Id}&SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(headersPayload)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}

export async function getUsersList() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `${GETUSERLIST.GET}?SiteId=${siteId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}

export async function _saveTroubleReport(data, Id) {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let headersPayload = {
    url: `/api/troublereport/${Id ? 'update' : 'create'}?SiteId=${siteId}${Id ? `&Id=${Id}` : ''}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    },
    data
  };
  return axios(headersPayload)
    .then((response) => {
      return response.data;
    })
    .catch(() => {});
}
export async function getFileData1() {
  const token = localStorage.get('accessToken');
  var Authorization = 'Bearer ' + token;
  const siteId = localStorage.get('currentSite');
  let data = {
    url: `/api/chat/getDocuments?SiteId=${siteId}&Module=${'RuleEngine'}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: Authorization
    }
  };
  return axios(data)
    .then((response) => {
      return response.data;
    })

    .catch(() => {});
}
