import React from 'react';
import {
  Select,
  Button,
  DatePicker,
  message,
  Modal,
  Input,
  Table,
  Divider,
  Dropdown,
  Typography,
  Space
} from 'antd';
import { Form } from 'antd';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { getTranslationCache } from '../../selectors/language';
import { getUserProfile } from '../../selectors/layout';
import localStorage from '../../utils/localStorage';
// import { constant } from './Utils/constants';
import history from '../../commons/history';
import {
  getParameterList,
  // getDeviceData,
  getUserHeirarchyData,
  getHierarchyTreeData,
  CreateTrend,
  getBookmarkList,
  UpdateTrends,
  getOperationList,
  DeleteTrend,
  getDeviceList
  // getDefaultSiteName
} from './ChartCalls';
import './CSS/trends.css';
import { StyledModal } from './CSS/styles';
import HierarchyModal from './Utils/HierarchyModal';
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  UpSquareOutlined
} from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;
const IntervalData = [
  {
    key: '1Min',
    value: '1m'
  },
  {
    key: '15Min',
    value: '15m'
  },
  {
    key: '30Min',
    value: '30m'
  },
  {
    key: '60Min',
    value: '1h'
  },
  {
    key: 'Day',
    value: '1d'
  }
];

class FilterComponent extends React.Component {
  timeTicket = null;
  requestCheck = true;

  constructor(props) {
    super(props);
    this.state = {
      Module: '',

      EquipmentList: [],
      ParameterList: {},
      OperationalList: {},
      ParameterListByType: [],

      trendData: [],
      tunedData: {},

      loading: false,
      CurrentTab: 'day',
      GraphType: props.GraphType || 'line',
      ViewType: 'Single',
      userProfile: props.userProfile,
      translationCache: props.translationCache || [],

      type: 'live',
      selectedDevice: [],
      checkedValue: [],
      selectedParameter: [],
      selectedOperator: undefined,
      selectedParameterType: [],
      selectedInterval: undefined,
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: [],

      isModalSaveBookmark: false,
      isModalViewBookmark: false,
      fieldVisible: true,
      treeVisible: false,
      HierarchyArray: [],
      checkedData: [],
      devicesList: []
    };
  }
  formHeader = React.createRef();
  bookMarkRef = React.createRef();
  abortController = new window.AbortController();

  async componentDidMount() {
    let Module =
      this.props && this.props.match && this.props.match.params && this.props.match.params.module;
    // this._getEquipmentData(Module);

    let BookmarkList = await getBookmarkList(Module);
    let devicesList = await getDeviceList();

    let HierarchyData = await getUserHeirarchyData();
    let permissionsArray =
      HierarchyData &&
      HierarchyData['belongsTo'] &&
      HierarchyData['belongsTo']['sites'] &&
      HierarchyData['belongsTo']['sites'][localStorage.get('currentSite')] &&
      HierarchyData['belongsTo']['sites'][localStorage.get('currentSite')]['hierarchy'];
    // this.onHierarchySelect(permissionsArray[0].Id);
    this.setState({
      Module,
      devicesList,
      BookmarkList,
      HierarchyArray: permissionsArray
    });
  }

  // _getEquipmentData = async () => {
  //   // let getSiteName = await getDefaultSiteName();
  //   // let HierarchyCode = getSiteName && getSiteName[0] ? getSiteName[0].HierarchyCode : '';
  //   // let EquipmentList = await getDeviceData();
  //   // this.setState({ EquipmentList: [EquipmentList] });
  // };

  componentDidUpdate(prevProps) {
    if (
      prevProps.translationCache !== this.props.translationCache ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        translationCache: this.props.translationCache,
        userProfile: this.props.userProfile
      });
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  onChangeFromDate_ToDate = (selectedFromDate_ToDate) => {
    this.setState({
      selectedFromDate_ToDate
    });
  };

  onChangeFromDate_ToDate1 = (selectedFromDate_ToDate1) => {
    this.setState({
      selectedFromDate_ToDate1
    });
  };

  onChangeFromDate1_ToDate1 = (selectedDateRangeComparision) => {
    this.setState({
      selectedDateRangeComparision
    });
  };

  OnResetFields = () => {
    this.formHeader.current &&
      this.formHeader.current.setFieldsValue({
        type: 'live',
        EquipmentList: [],
        selectedDevice: [],
        trendData: [],
        checkedValue: [],
        selectedParameter: [],
        selectedOperator: undefined,
        selectedParameterType: [],
        selectedInterval: undefined,
        selectedFromDate_ToDate: [],
        selectedFromDate_ToDate1: [],
        selectedDateRangeComparision: [],
        ParameterList: [],
        ParameterListByType: [],
        ViewType: 'Single',
        fieldVisible: true,
        GraphType: 'line',
        selectedHierarchy: ''
      });

    this.setState({
      type: 'live',
      EquipmentList: [],
      selectedDevice: [],
      checkedValue: [],
      selectedParameter: [],
      selectedOperator: undefined,
      selectedParameterType: [],
      selectedInterval: undefined,
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: [],
      BookmarkName: undefined,
      Id: undefined,
      ViewType: 'Single',
      fieldVisible: true,
      GraphType: 'line',
      selectedHierarchy: '',
      ParameterList: [],
      ParameterListByType: []
    });
    this.props.OnGenerateTrendData({}, {}, 'Trends');
  };

  OnGenerateTrendData = async () => {
    let {
      type,
      selectedFromDate_ToDate,
      selectedFromDate_ToDate1,
      selectedDateRangeComparision,
      selectedInterval,
      ViewType,
      CurrentTab,
      selectedParameter,
      selectedOperator,
      selectedParameterType
    } = this.state;
    let filterObject = {
      type,
      selectedFromDate_ToDate,
      selectedFromDate_ToDate1,
      selectedDateRangeComparision,
      selectedInterval,
      ViewType,
      CurrentTab,
      selectedParameter,
      selectedOperator,
      selectedParameterType
    };
    this.formHeader.current &&
      this.formHeader.current
        .validateFields()
        .then(async () => {
          const tunedData = this.trendAdapter();
          this.props.OnGenerateTrendData(tunedData, filterObject, 'Trends');
          this.setState({
            tunedData
          });
        })
        .catch((error) => {
          console.log(error);
        });
  };

  trendAdapter() {
    const {
      type,
      selectedFromDate_ToDate,
      selectedFromDate_ToDate1,
      selectedDateRangeComparision,
      selectedInterval,
      ViewType,
      CurrentTab,
      selectedParameter,
      selectedOperator,
      selectedParameterType
    } = this.state;
    try {
      let data = selectedParameter.map((deviceParameter) => {
        let spliteData = deviceParameter.split('@@');
        return {
          DeviceCode: spliteData[0],
          ParameterCode: spliteData[1]
        };
      });
      let payload = {
        Type: type,
        ViewType,
        ParameterList: data
      };
      if (selectedParameterType === 'Operational') {
        payload.GraphType = 'line';
        if (type !== 'live') {
          payload.Operator = selectedOperator;
        }
      }
      if (selectedParameterType === 'Status') {
        payload.GraphType = 'Table';
        if (type !== 'live' && (selectedOperator === 'FIRST' || 'LAST' || 'RAW')) {
          payload.Operator = selectedOperator;
        }
      }
      if (type === 'historic') {
        payload.Interval = selectedInterval;
        payload.FromDate = moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
        payload.ToDate = moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
      } else if (type === 'timeRange') {
        payload.FromDate1 = moment(selectedFromDate_ToDate[0]).utc().format('YYYY-MM-DD HH:mm:ss');
        payload.ToDate1 = moment(selectedFromDate_ToDate[1]).utc().format('YYYY-MM-DD HH:mm:ss');
        payload.FromDate2 = moment(selectedDateRangeComparision[0])
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
        payload.ToDate2 = moment(selectedDateRangeComparision[1])
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
        payload.Interval = selectedInterval;
      } else if (type === 'timeWithSelectionType') {
        payload.FromDate = moment(selectedFromDate_ToDate1[0]).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
        payload.ToDate = moment(selectedFromDate_ToDate1[1]).utc().format('YYYY-MM-DD HH:mm:ss'); //conversion to utc
        payload.Interval = selectedInterval;
        payload.SelectionType = CurrentTab;
      }

      return payload;
    } catch (error) {
      return {};
    }
  }

  hierarchyDropdownChange = (value) => {
    this.setState({ checkedValue: value });
  };

  onModalsOk = (e) => {
    let { checkedValue } = this.state;
    e.preventDefault();
    this.OnDeviceChange(checkedValue);
    this.setState({
      treeVisible: false
    });
  };

  onModalCancel = () => {
    this.setState({
      treeVisible: false
    });
  };
  OnDeviceChange = async (value) => {
    let val = [];
    val.push(value);
    let ParameterList = {};
    if (value.length === 6) {
      value.shift();
      ParameterList = await getParameterList(val);
    } else {
      ParameterList = await getParameterList(val);
    }
    this.setState({
      selectedDevice: value,
      ParameterList,
      update: false,
      ParameterListByType: [],
      selectedParameterType: undefined,
      selectedParameter: [],
      selectedOperator: undefined
    });
    this.formHeader.current &&
      this.formHeader.current.setFieldsValue({
        selectedDevice: value,
        ParameterList,
        update: false,
        ParameterListByType: [],
        selectedParameterType: undefined,
        selectedParameter: [],
        selectedOperator: undefined
      });
  };

  OnParameterChange = (value) => {
    if (this.state.type === 'timeRange' && value.length === 2) {
      value.shift();
      this.setState({ selectedParameter: value, update: false });
    } else if (this.state.type === 'timeWithSelectionType' && value.length === 2) {
      value.shift();
      this.setState({ selectedParameter: value, update: false });
    } else if (value.length > 5) {
      value.shift();
      this.setState({ selectedParameter: value, update: false });
    } else {
      this.setState({ selectedParameter: value, update: false });
    }
  };

  OnOperatorChange = (value) => {
    this.setState({ selectedOperator: value });
  };

  OnParameterTypeChange = async (selectedParameterType) => {
    let { ParameterList, OperationalList, selectedOperator, type, GraphType } = this.state;
    // let { GraphType } = this.props;
    if (selectedParameterType === 'Status' && type !== 'live') {
      this.setState({ GraphType: 'Table' });
      GraphType = 'Table';
      OperationalList = await getOperationList(selectedParameterType, GraphType);
      this.setState({
        fieldVisible: true,
        OperationalList
      });
    }
    if (
      selectedParameterType !== 'Operational' ||
      (selectedParameterType === 'Status' && type === 'live')
    ) {
      this.setState({
        fieldVisible: false,
        selectedOperator: ''
      });
    } else if (selectedParameterType === 'Operational' && type !== 'live') {
      OperationalList = await getOperationList(selectedParameterType, GraphType);
      this.setState({
        fieldVisible: true,
        OperationalList,
        selectedOperator
      });
    }

    this.setState({
      ParameterListByType: ParameterList[selectedParameterType],
      selectedParameterType,
      selectedParameter: [],
      selectedOperator: undefined
    });
    this.formHeader.current &&
      this.formHeader.current.setFieldsValue({
        selectedParameterType,
        selectedParameter: [],
        selectedOperator: undefined
      });
  };

  disabledDate = (current) => {
    return current > moment().startOf('day').add(1, 'day');
  };

  OnTypeChange = (value) => {
    let ViewType =
      value && (value === 'timeRange' || value === 'timeWithSelectionType') ? 'Multiple' : 'Single';
    this.setState({
      type: value,
      trendData: [],
      tunedData: {},
      loading: false,
      currentSelectionTab: 'day',
      GraphType: 'line',
      ViewType,
      selectedDevice: [],
      checkedValue: [],
      selectedParameter: [],
      selectedParameterType: [],
      selectedInterval: '',
      selectedFromDate_ToDate: [],
      selectedFromDate_ToDate1: [],
      selectedDateRangeComparision: [],
      selectedHierarchy: ''
    });
    this.formHeader.current &&
      this.formHeader.current.setFieldsValue({
        type: value,
        ViewType,
        selectedDevice: [],
        checkedValue: [],
        selectedParameter: [],
        selectedParameterType: [],
        selectedInterval: '',
        selectedFromDate_ToDate: [],
        selectedFromDate_ToDate1: [],
        selectedDateRangeComparision: [],
        selectedHierarchy: ''
      });
  };

  onHierarchySelect = async (value) => {
    let EquipmentList = await getHierarchyTreeData(value);
    this.setState({
      selectedHierarchy: value,
      EquipmentList,
      selectedDevice: [],
      selectedParameter: [],
      selectedParameterType: [],
      selectedOperator: [],
      checkedValue: []
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  onBookmarkSave = async () => {
    const siteId = localStorage.get('currentSite');
    let {
      type,
      tunedData,
      // selectedDevice,
      ViewType,
      selectedOperator,
      selectedParameter,
      selectedParameterType,
      selectedInterval,
      Module,
      bookMark,
      selectedHierarchy,
      checkedValue
    } = this.state;
    let val = [];
    val.push(checkedValue);
    if (bookMark && bookMark.length > 0) {
      let payload = {
        Devices: checkedValue,
        Interval: selectedInterval,
        Module: Module,
        Name: bookMark,
        Parameters: selectedParameter,
        ParameterType: selectedParameterType,
        Payload: tunedData ? tunedData : [],
        SiteId: siteId,
        Type: type,
        ViewType: ViewType,
        Operator: selectedOperator,
        HierarchyCode: selectedHierarchy
      };
      let saveBookmarkResponse = await CreateTrend(payload);
      if (saveBookmarkResponse && saveBookmarkResponse.message) {
        message.success(saveBookmarkResponse.message);
        this.setState({ BookmarkName: bookMark });
        let BookmarkList = await getBookmarkList(Module);
        this.setState({
          isModalSaveBookmark: false,
          BookmarkList
        });
      }
    } else {
      message.warn('Please input Bookmark name');
    }
  };
  updateBookmark = async () => {
    const siteId = localStorage.get('currentSite');
    let {
      type,
      tunedData,
      selectedDevice,
      ViewType,
      selectedParameter,
      selectedParameterType,
      selectedInterval,
      Module,
      BookmarkName,
      Id,
      selectedOperator,
      selectedHierarchy
    } = this.state;
    let payload = {
      Devices: selectedDevice,
      Interval: selectedInterval,
      Module: Module,
      Name: BookmarkName,
      Parameters: selectedParameter,
      ParameterType: selectedParameterType,
      Payload: tunedData ? tunedData : [],
      SiteId: siteId,
      Type: type,
      ViewType: ViewType,
      Id: Id,
      Operator: selectedOperator,
      HierarchyCode: selectedHierarchy
    };
    let updateBookmarkResponse = await UpdateTrends(payload);
    if (updateBookmarkResponse && updateBookmarkResponse.message) {
      message.success(updateBookmarkResponse.message);
      let BookmarkList = await getBookmarkList(Module);
      this.setState({ BookmarkList });
    }
  };
  onBookmarkModalOpen = () => {
    this.bookMarkRef.current &&
      this.bookMarkRef.current.setFieldsValue({
        BookmarkName: ''
      });
    this.setState({
      isModalSaveBookmark: true,
      bookMark: ''
    });
  };
  onBookmarkListOpen = () => {
    this.setState({
      isModalViewBookmark: true
    });
  };
  deleteBookmark = async (record) => {
    let { Module } = this.state;
    let deleteResponse = await DeleteTrend(record.Id);
    if (deleteResponse && deleteResponse.message) {
      message.success(deleteResponse.message);
      let BookmarkList = await getBookmarkList(Module);
      this.setState({ BookmarkList });
    }
  };

  editBookmark = async (editObject) => {
    let { GraphType } = this.props;
    let EquipmentList = await getHierarchyTreeData(editObject && editObject.HierarchyCode);
    let val = [];
    val.push(editObject.Devices);
    let ParameterList = {};
    let OperationalList = {};
    let ParameterListByType = [];
    if (editObject.Devices) {
      ParameterList = await getParameterList(val);
      ParameterListByType = ParameterList[editObject.ParameterType];
      if (editObject.ParameterType === 'Status' && editObject.Type !== 'live') {
        OperationalList = await getOperationList(editObject.ParameterType, 'Table');
      } else if (editObject.ParameterType === 'Operational' && editObject.Type !== 'live') {
        OperationalList = await getOperationList(editObject.ParameterType, GraphType);
      }
    }
    this.setState({
      selectedHierarchy: editObject && editObject.HierarchyCode,
      EquipmentList,
      type: editObject && editObject.Type,
      selectedDevice: editObject && editObject.Devices,
      checkedValue: editObject && editObject.Devices,
      selectedParameter: editObject && editObject.Parameters,
      selectedParameterType: editObject && editObject.ParameterType,
      selectedInterval: editObject && editObject.Interval,
      selectedDateRangeComparision: [
        moment.utc(editObject.Payload.FromDate2, 'YYYY-MM-DD HH:mm:ss').local(),
        moment.utc(editObject.Payload.ToDate2, 'YYYY-MM-DD HH:mm:ss').local()
      ],
      isModalViewBookmark: false,
      ViewType: editObject && editObject.ViewType,
      selectedOperator: editObject && editObject.Operator,
      Id: editObject && editObject.Id,
      BookmarkName: editObject && editObject.Name,
      selectedFromDate_ToDate: [
        moment.utc(editObject.Payload.FromDate1, 'YYYY-MM-DD HH:mm:ss').local(),
        moment.utc(editObject.Payload.ToDate1, 'YYYY-MM-DD HH:mm:ss').local()
      ],
      selectedFromDate_ToDate1: [
        moment.utc(editObject.Payload.FromDate, 'YYYY-MM-DD HH:mm:ss').local(),
        moment.utc(editObject.Payload.ToDate, 'YYYY-MM-DD HH:mm:ss').local()
      ],
      ParameterList,
      ParameterListByType,
      OperationalList
    });
    this.formHeader.current &&
      this.formHeader.current.setFieldsValue({
        selectedHierarchy: editObject.HierarchyCode,
        type: editObject && editObject.Type,
        selectedDevice: editObject && editObject.Devices,
        checkedValue: editObject && editObject.Devices,
        selectedParameter: editObject && editObject.Parameters,
        selectedParameterType: editObject && editObject.ParameterType,
        selectedInterval: editObject && editObject.Interval,
        selectedDateRangeComparision: [
          moment.utc(editObject.Payload.FromDate2, 'YYYY-MM-DD HH:mm:ss').local(),
          moment.utc(editObject.Payload.ToDate2, 'YYYY-MM-DD HH:mm:ss').local()
        ],
        ViewType: editObject && editObject.ViewType,
        selectedOperator: editObject && editObject.Operator,
        selectedFromDate_ToDate: [
          moment.utc(editObject.Payload.FromDate1, 'YYYY-MM-DD HH:mm:ss').local(),
          moment.utc(editObject.Payload.ToDate1, 'YYYY-MM-DD HH:mm:ss').local()
        ],
        selectedFromDate_ToDate1: [
          moment.utc(editObject.Payload.FromDate, 'YYYY-MM-DD HH:mm:ss').local(),
          moment.utc(editObject.Payload.ToDate, 'YYYY-MM-DD HH:mm:ss').local()
        ]
      });
  };

  render() {
    const {
      type,
      EquipmentList,
      selectedDevice,
      ViewType,
      selectedParameter,
      selectedOperator,
      selectedInterval,
      ParameterList,
      ParameterListByType,
      userProfile,
      selectedParameterType,
      BookmarkList,
      BookmarkName,
      fieldVisible,
      OperationalList,
      checkedValue,
      HierarchyArray,
      GraphType,
      devicesList
    } = this.state;
    const columns = [
      {
        title: this.translation('Bookmark Name'),
        dataIndex: 'Name',
        key: 'Name',
        width: 150
      },
      {
        title: this.translation('Type'),
        dataIndex: 'Type',
        key: 'Type',
        width: 150
      },
      {
        title: this.translation('Devices'),
        dataIndex: 'Devices',
        key: 'Devices',
        width: 150
      },
      {
        title: this.translation('Parameters'),
        dataIndex: 'Parameters',
        key: 'Parameters',
        width: 150
      },
      {
        title: this.translation('Parameter Type'),
        dataIndex: 'ParameterType',
        key: 'ParameterType',
        width: 150
      },
      {
        title: this.translation('View Type'),
        dataIndex: 'ViewType',
        key: 'ViewType',
        width: 150
      },
      {
        title: this.translation('Actions'),
        dataIndex: '',
        key: '',
        width: 150,
        render: (text, record) => (
          <span>
            <a target="_blank" rel="noopener noreferrer">
              <EyeOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/rubus/ViewTrends/${record.Id}`);
                  this.editBookmark(record);
                }}
              />
            </a>
            <Divider type="vertical" />
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation();
                this.editBookmark(record);
              }}
            />
            <Divider type="vertical" />
            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation();
                this.deleteBookmark(record);
              }}
            />
          </span>
        )
      }
    ];
    const items = [
      {
        key: this.state.Id ? this.translation('update') : this.translation('save'),
        label: this.state.Id
          ? this.translation('Update bookmark')
          : this.translation('Save bookmark')
      },
      {
        key: 'list',
        label: 'View bookmarks'
      }
    ];

    let permissionsArray = [];
    userProfile &&
      userProfile['belongsTo'] &&
      userProfile['belongsTo']['sites'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role'] &&
      userProfile['belongsTo']['sites'][localStorage.get('currentSite')]['role']['Permission']
        .filter((item) => item.resource === 'trends')
        .map((name) => permissionsArray.push(name.action));

    return (
      <>
        {BookmarkName ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '13px',
              fontSize: '16px'
            }}
          >
            {this.translation('Bookmark')}: {BookmarkName}
          </div>
        ) : null}
        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: '10px' }}>
          <Dropdown
            menu={{
              items,
              selectable: true,
              onClick: (e) => {
                if (e.key === 'list') {
                  this.onBookmarkListOpen();
                } else if (e.key === 'save') {
                  this.setState({ bookMark: '' });
                  this.formHeader.current &&
                    this.formHeader.current
                      .validateFields()
                      .then(async () => {
                        this.onBookmarkModalOpen();
                      })
                      .catch(() => {
                        message.warn(
                          this.translation('Please input mandatory fields to save bookmark')
                        );
                      });
                } else if (e.key === 'update') {
                  this.formHeader.current &&
                    this.formHeader.current
                      .validateFields()
                      .then(async () => {
                        this.updateBookmark();
                      })
                      .catch(() => {
                        message.warn(
                          this.translation('Please input mandatory fields to update bookmark')
                        );
                      });
                }
              }
            }}
          >
            <Typography.Link>
              <Space>
                {this.translation('Bookmark Options')}
                <DownOutlined />
              </Space>
            </Typography.Link>
          </Dropdown>
        </div>

        <Form ref={this.formHeader} initialValues={this.state} layout="vertical">
          <Form.Item
            name={'type'}
            label={this.translation('Select Trend Type')}
            rules={[{ required: true, message: this.translation('Please Select TrendType!') }]}
          >
            <Select
              placeholder={this.translation('Select Trend Type')}
              onChange={this.OnTypeChange}
            >
              <Option value="live">{this.translation('Live Trend')}</Option>
              <Option value="historic">{this.translation('Historical Trend')}</Option>
              <Option value="timeRange">
                {this.translation('Trend between two different TimeRanges')}
              </Option>
              <Option value="timeWithSelectionType">
                {this.translation('Trend based Day Week Month Year Comparision')}
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            name={'selectedHierarchy'}
            label={this.translation('Select Hierarchy')}
            rules={[{ required: true, message: this.translation('Please Select Hierarchy!') }]}
          >
            <Select
              onChange={this.onHierarchySelect}
              placeholder={this.translation('Select Hierarchy')}
              size="default"
            >
              {HierarchyArray &&
                Array.isArray(Object.keys(HierarchyArray)) &&
                Object.keys(HierarchyArray).map((device, index) => {
                  return (
                    <Option key={index} value={HierarchyArray[device].HierarchyCode}>
                      {HierarchyArray[device].HierarchyName}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            name={'selectedDevice'}
            label={this.translation('Select Equipment')}
            rules={[{ required: true, message: this.translation('Please Select Equipment!') }]}
          >
            <span
              style={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <Select
                style={{
                  width: '90%'
                }}
                value={selectedDevice}
                mode="multiple"
                placeholder={this.translation('Select Equipment')}
                size="default"
                disabled={true}
              >
                {devicesList &&
                  Array.isArray(devicesList) &&
                  devicesList.map((device, index) => {
                    return (
                      <Option key={index} value={device.AliasCode}>
                        {device.DisplayCode}
                      </Option>
                    );
                  })}
              </Select>
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ treeVisible: true });
                }}
              >
                <UpSquareOutlined />
              </Button>
            </span>
          </Form.Item>

          <Form.Item
            name={'selectedParameterType'}
            label={this.translation('Select Parameter Type')}
            rules={[{ required: true, message: this.translation('Please Select Parameter Type!') }]}
          >
            <Select
              value={selectedParameterType}
              placeholder={this.translation('Select Parameter Type')}
              onChange={this.OnParameterTypeChange}
            >
              {ParameterList &&
                Array.isArray(Object.keys(ParameterList)) &&
                Object.keys(ParameterList).map((parameterType) => {
                  return <Option key={parameterType}>{parameterType}</Option>;
                })}
            </Select>
          </Form.Item>

          <Form.Item
            name={'selectedParameter'}
            label={this.translation('Select Parameter')}
            rules={[{ required: true, message: this.translation('Please Select Parameter!') }]}
          >
            <Select
              showSearch
              mode="multiple"
              value={selectedParameter}
              onChange={this.OnParameterChange}
              placeholder={this.translation('Select Parameter')}
            >
              {ParameterListByType &&
                Array.isArray(ParameterListByType) &&
                ParameterListByType.map((param, index) => {
                  return (
                    <Option key={index} value={`${param.Device}@@${param.ParameterCode}`}>
                      {`${param.DeviceName}=>${param.ParameterDescription}`}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          {(type !== 'live' && fieldVisible === true) ||
          (selectedParameterType === 'Status' && GraphType === 'Table' && type !== 'live') ? (
            <Form.Item
              name={'selectedOperator'}
              label={this.translation('Select Operator')}
              rules={[{ required: true, message: this.translation('Please Select Operator !') }]}
            >
              <Select
                // showSearch
                value={selectedOperator}
                onChange={this.OnOperatorChange}
                placeholder={this.translation('Select Operator')}
              >
                {OperationalList &&
                  Array.isArray(OperationalList) &&
                  OperationalList.map((operator, index) => {
                    return (
                      <Option key={index} value={operator.Key}>
                        {operator.Value}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          ) : null}

          {type === 'historic' ? (
            <>
              <Form.Item
                name={'selectedFromDate_ToDate1'}
                label={this.translation('Select Range')}
                rules={[
                  {
                    required: true,
                    message: this.translation('Please Select DateRanges!')
                  }
                ]}
              >
                <RangePicker
                  showTime={{ format: 'HH:mm:ss' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder={[
                    this.translation('Start DateTime'),
                    this.translation('End DateTime')
                  ]}
                  value={this.state.selectedFromDate_ToDate1}
                  onChange={this.onChangeFromDate_ToDate1}
                  onOk={this.onOk}
                  disabledDate={this.disabledDate}
                />
              </Form.Item>
              <Form.Item
                name={'selectedInterval'}
                label={this.translation('Select Interval')}
                rules={[{ required: true, message: this.translation('Please Select Interval!') }]}
              >
                <Select
                  placeholder={this.translation('Select Interval')}
                  value={selectedInterval}
                  onChange={(selectedInterval) => {
                    this.setState({ selectedInterval });
                  }}
                >
                  {IntervalData.map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {item.key}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          ) : null}
          {type === 'timeRange' ? (
            <>
              <Form.Item
                name={'selectedFromDate_ToDate'}
                label={this.translation('Select Range')}
                rules={[
                  {
                    required: true,
                    message: this.translation('Please Select DateRanges!')
                  }
                ]}
              >
                <RangePicker
                  showTime={{ format: 'HH:mm:ss' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder={[
                    this.translation('Start DateTime'),
                    this.translation('End DateTime')
                  ]}
                  value={this.state.selectedFromDate_ToDate}
                  onChange={this.onChangeFromDate_ToDate}
                  onOk={this.onOk}
                  disabledDate={this.disabledDate}
                />
              </Form.Item>
              <Form.Item
                name={'selectedDateRangeComparision'}
                label={this.translation('Select Range Comparison')}
                rules={[
                  {
                    required: true,
                    message: this.translation('Please Select DateRanges!')
                  }
                ]}
              >
                <RangePicker
                  showTime={{ format: 'HH:mm:ss' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder={[
                    this.translation('Start DateTime'),
                    this.translation('End DateTime')
                  ]}
                  value={this.state.selectedDateRangeComparision}
                  onChange={this.onChangeFromDate1_ToDate1}
                  onOk={this.onOk}
                  disabledDate={this.disabledDate}
                />
              </Form.Item>
              <Form.Item
                name={'selectedInterval'}
                label={this.translation('Select Interval')}
                rules={[{ required: true, message: this.translation('Please Select Interval!') }]}
              >
                <Select
                  placeholder={this.translation('Select Interval')}
                  value={selectedInterval}
                  onChange={(value) => {
                    this.setState({ selectedInterval: value });
                  }}
                >
                  {IntervalData.map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {item.key}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          ) : null}
          {type === 'timeWithSelectionType' ? (
            <>
              <Form.Item
                name={'selectedFromDate_ToDate1'}
                label={this.translation('Select Range')}
                rules={[
                  {
                    required: true,
                    message: this.translation('Please Select DateRanges!')
                  }
                ]}
              >
                <RangePicker
                  showTime={{ format: 'HH:mm:ss' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder={[
                    this.translation('Start DateTime'),
                    this.translation('End DateTime')
                  ]}
                  value={this.state.selectedFromDate_ToDate1}
                  onChange={this.onChangeFromDate_ToDate1}
                  onOk={this.onOk}
                  disabledDate={this.disabledDate}
                />
              </Form.Item>
              <Form.Item
                name={'selectedInterval'}
                label={this.translation('Select Interval')}
                rules={[{ required: true, message: this.translation('Please Select Interval!') }]}
              >
                <Select
                  placeholder={this.translation('Select Interval')}
                  value={selectedInterval}
                  onChange={(value) => {
                    this.setState({ selectedInterval: value });
                  }}
                >
                  {IntervalData.map((item, index) => {
                    return (
                      <Option key={index} value={item.value}>
                        {item.key}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          ) : null}
          {type === 'timeWithSelectionType' || type === 'timeRange' ? (
            <>
              <Form.Item
                name={'ViewType'}
                label={this.translation('View Type')}
                rules={[{ required: true, message: this.translation('Please Select ViewType!') }]}
              >
                <Select
                  placeholder={this.translation('View Type')}
                  value={ViewType}
                  onChange={(ViewType) => {
                    this.setState({ ViewType });
                  }}
                >
                  {/* <Option key="Single" value="Single">
                    {this.translation('Single')}
                  </Option> */}
                  <Option key="Multiple" value="Multiple">
                    {this.translation('Multiple')}
                  </Option>
                </Select>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                name={'ViewType'}
                label={this.translation('View Type')}
                rules={[{ required: true, message: this.translation('Please Select ViewType!') }]}
              >
                <Select
                  placeholder={this.translation('View Type')}
                  value={ViewType}
                  onChange={(ViewType) => {
                    this.setState({ ViewType });
                  }}
                >
                  <Option key="Single" value="Single">
                    {this.translation('Single')}
                  </Option>
                  <Option key="Multiple" value="Multiple">
                    {this.translation('Multiple')}
                  </Option>
                </Select>
              </Form.Item>
            </>
          )}
          <div>
            <Button
              htmlType="submit"
              type="primary"
              disabled={!permissionsArray.includes('read') ? true : false}
              onClick={() => {
                this.OnGenerateTrendData();
              }}
            >
              {this.translation('Generate Trend')}
            </Button>
            <Button
              type="dashed"
              style={{ marginLeft: '5px' }}
              onClick={() => {
                this.OnResetFields();
              }}
            >
              {this.translation('Reset')}
            </Button>
          </div>
        </Form>
        <Modal
          title={this.translation('Save Bookmark')}
          open={this.state.isModalSaveBookmark}
          onOk={this.onBookmarkSave}
          onCancel={() => this.setState({ isModalSaveBookmark: false })}
          footer={[
            <Button
              key="back"
              onClick={() => this.setState({ isModalSaveBookmark: false, bookMark: '' })}
            >
              {this.translation('Cancel')}
            </Button>,
            <Button key="submit" type="primary" onClick={this.onBookmarkSave}>
              {this.translation('Save Bookmark')}
            </Button>
          ]}
        >
          <Form layout="vertical" ref={this.bookMarkRef}>
            <Form.Item
              name={'BookmarkName'}
              label={this.translation('Bookmark Name')}
              rules={[{ required: true, message: this.translation('Input Bookmark!') }]}
            >
              <Input onChange={(e) => this.setState({ bookMark: e.target.value })}></Input>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          width={'900'}
          title="View Bookmarks"
          open={this.state.isModalViewBookmark}
          onOk={() => this.setState({ isModalViewBookmark: false })}
          onCancel={() => this.setState({ isModalViewBookmark: false })}
          footer={[
            <Button key="back" onClick={() => this.setState({ isModalViewBookmark: false })}>
              {this.translation('Cancel')}
            </Button>
          ]}
        >
          <Table dataSource={BookmarkList} columns={columns} scroll={{ x: 120, y: 550 }} />
        </Modal>
        <StyledModal
          open={this.state.treeVisible}
          width={800}
          closable
          title={'Equipment'}
          okText={'Add'}
          onOk={this.onModalsOk}
          onCancel={this.onModalCancel}
        >
          <HierarchyModal
            checkedArray={checkedValue ? checkedValue : []}
            checkedData={this.state.checkedData ? this.state.checkedData : []}
            treeData={Object.values(EquipmentList)}
            Key="Trends"
            handleDropdownChange={this.hierarchyDropdownChange}
          />
        </StyledModal>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(FilterComponent);
