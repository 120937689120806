import React, { Fragment } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';

import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Tag, Input, Radio, Table, Spin, Button, Drawer } from 'antd';
import { Form } from 'antd';

import { createStructuredSelector } from 'reselect';

import localStorage from '../../../../../utils/localStorage';
import { getChartDataWithoutSelection, getChartDataWithSelection } from '../../ChartCalls';
import { tableTranslation } from '../../../Utils/Adapter/translationAdapter';
import { getTranslationCache } from '../../../../../selectors/language';
import { makeSelectThemeing } from '../../../../../selectors/theme';
import { defaultRefreshTime } from '../../constant.json';
import { ErrorComponent, NoRecordComponent } from '../../Utils';

import '../../CSS/Table.css';

class FeedbackTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      tabledata: [],
      loading: false,
      openModal: false,
      modalObj: {},
      translationCache: props.translationCache || [],
      refreshDateTime: moment(),
      searchText: '',
      errorInAPI: false,
      noDataInDB: false
    };
  }

  columns = [];
  tabledata = [];
  config = {
    redirectTo: 'counter'
  };

  timeTicket = null;
  requestCheck = true;
  abortController = new window.AbortController();
  rowUpdateInterval = null;
  feedbackRef = React.createRef();

  async componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.getData(this.props);

    let refreshTime = '';
    if (this.props.graphprops.refreshTime) {
      refreshTime = this.props.graphprops.refreshTime * 60000;
    } else {
      refreshTime = defaultRefreshTime;
    }

    this.timeTicket = setInterval(() => {
      if (this.requestCheck) {
        this.getData(this.props, this.props.selectedThemeing);
      }
    }, refreshTime);
  }

  componentWillUnmount() {
    this.abortController.abort();
    clearInterval(this.timeTicket);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphprops !== this.props.graphprops ||
      prevProps.selectionDate !== this.props.selectionDate ||
      prevProps.selectionDateLive !== this.props.selectionDateLive ||
      prevProps.selectionMonth !== this.props.selectionMonth ||
      prevProps.selectionWeek !== this.props.selectionWeek ||
      prevProps.DeviceCode !== this.props.DeviceCode ||
      prevProps.shift !== this.props.shift ||
      prevProps.selectionDateRange !== this.props.selectionDateRange ||
      prevProps.dayMonthYear !== this.props.dayMonthYear ||
      prevProps.dropdownSelection !== this.props.dropdownSelection ||
      prevProps.dashboardPayload !== this.props.dashboardPayload ||
      prevProps.AssetCode !== this.props.AssetCode ||
      prevProps.translationCache !== this.props.translationCache
    ) {
      this.getData();
    }
  }

  openModal = (e, record) => {
    let { openModal } = this.state;
    this.setState({
      openModal: !openModal,
      modalObj: record
    });
  };

  getActionColumn = (text) => {
    return (
      <div onClick={(e) => this.openModal(e, text)} style={{ color: '#fff' }}>
        Click to feedback
      </div>
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: 'white', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  updateData = async (data) => {
    let theme = this.props.selectedThemeing;
    try {
      this.columns = [];
      this.tabledata = [];
      const columnNames = Object.keys(data[0]);

      columnNames.map((value) => {
        if (
          value.length > 0 &&
          value !== 'color' &&
          value !== 'noData' &&
          value !== 'Action' &&
          value !== 'Comment' &&
          value !== 'Timestamp'
        ) {
          //ignoring columns
          this.columns.push({
            title: value,
            dataIndex: value,
            key: value,
            ...this.getColumnSearchProps(value),
            render(text, record) {
              return {
                props: {
                  style: { color: record && record.color ? record.color : '#000' }
                },
                children: (
                  <text style={{ color: record && record.color ? record.color : '#8c8c8c' }}>
                    {text}
                  </text>
                )
              };
            }
          });
        } else if (value && value === 'Action') {
          this.columns.push({
            title: value,
            dataIndex: value,
            key: value,
            render: (text, row) => {
              return {
                children: this.getActionColumn(row)
              };
            }
          });
        } else if (value && value === 'Timestamp') {
          this.columns.push({
            title: value,
            dataIndex: value,
            key: value,
            render: (text, record) => {
              return {
                style: { color: theme === 'lightTheme' ? 'black' : 'white' },
                children: (
                  <text style={{ color: record && record.color ? record.color : '#8c8c8c' }}>
                    {moment(text).format('YYYY-MM-DD HH:mm:ss')}
                  </text>
                )
              };
            }
          });
        }
        return {};
      });

      if (data && data[0] && data[0]['noData']) {
        return {};
      } else {
        data.map((dataval, dataindex) => {
          dataval.key = dataindex + 1;
          this.tabledata.push(dataval);
          return {};
        });
      }
      let translationTable = await tableTranslation(this.columns, this.props.translationCache);
      this.setState({
        columns: translationTable,
        tabledata: this.tabledata
      });
    } catch (e) {
      this.setState({
        columns: []
      });
    }
  };

  getData = async () => {
    this.requestCheck = false;
    let { errorInAPI, noDataInDB } = this.state;
    errorInAPI = false;
    noDataInDB = false;
    let json = [];
    let { graphprops } = this.props;

    // this.setState({ loading: true });
    if (
      (graphprops &&
        (graphprops.DashboardLevelSelection === 'datePicker' ||
          graphprops.GraphLevelSelection === 'datePicker') &&
        this.props.selectionDate) ||
      (graphprops &&
        (graphprops.DashboardLevelSelection === 'monthPicker' ||
          graphprops.GraphLevelSelection === 'monthPicker') &&
        this.props.selectionMonth) ||
      (graphprops &&
        (graphprops.DashboardLevelSelection === 'weakPicker' ||
          graphprops.GraphLevelSelection === 'weakPicker') &&
        this.props.selectionWeek) ||
      (graphprops &&
        (graphprops.DashboardLevelSelection === 'shiftPicker' ||
          graphprops.GraphLevelSelection === 'shiftPicker') &&
        this.props.shift) ||
      (graphprops &&
        (graphprops.DashboardLevelSelection === 'dateRangePicker' ||
          graphprops.GraphLevelSelection === 'dateRangePicker') &&
        this.props.selectionDateRange) ||
      (graphprops &&
        (graphprops.DashboardLevelSelection === 'dateMonthYearPicker' ||
          graphprops.GraphLevelSelection === 'dateMonthYearPicker') &&
        this.props.dayMonthYear) ||
      (graphprops && graphprops.dashboardType === 'dynamic') ||
      (graphprops.dashboardType === 'dropdownHide' && this.props.DeviceCode) ||
      (graphprops && graphprops.dashboardType === 'hierarchy' && this.props.dropdownSelection) ||
      (graphprops && this.props.dashboardPayload) ||
      (graphprops && graphprops.treeExists === 'true' && this.props.AssetCode)
    ) {
      json = await getChartDataWithSelection({
        graphprops,
        DeviceCode: this.props.DeviceCode,
        selectionDate: this.props.selectionDate,
        selectionMonth: this.props.selectionMonth,
        selectionWeek: this.props.selectionWeek,
        shift: this.props.shift,
        selectionDateRange: this.props.selectionDateRange,
        dayMonthYear: this.props.dayMonthYear,
        abortController: this.abortController,
        dropdownSelection: this.props.dropdownSelection,
        dashboardPayload: this.props.dashboardPayload,
        AssetCode: this.props.AssetCode,
        selectionDateLive: this.props.selectionDateLive,
        currentTimeZone: this.props.currentTimeZone
      });
      this.requestCheck = true;
    } else {
      json = await getChartDataWithoutSelection({
        graphprops,
        abortController: this.abortController,
        dropdownSelection: this.props.dropdownSelection,
        selectionDateLive: this.props.selectionDateLive,
        DeviceCode: this.props.DeviceCode,
        timeZone: this.props.currentTimeZone
      });
      this.requestCheck = true;
      if (
        (json && json.message) ||
        (Array.isArray(json) && json.length === 0 && graphprops.enableMock !== true)
      ) {
        if (json.message) {
          errorInAPI = true;
        } else if (json.length === 0) {
          noDataInDB = true;
        }
      } else {
        this.setState({
          loading: false,
          errorInAPI,
          noDataInDB
        });
      }
      this.updateData(json);
    }
  };

  handleOk = () => {
    this.feedbackRef.current.validateFields().then(async () => {
      //Feedback
      // let response = await this.submitFeedbackAPI(values)
      this.getData(this.props);
      this.setState({
        openModal: false
      });
    });
  };

  submitFeedbackAPI = (payload) => {
    //API
    const accessToken = localStorage.get('accessToken');
    const deviceTypeObject = {
      method: 'POST',
      url: `/predictions/update`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    return axios(deviceTypeObject)
      .then(() => {
        return 'success';
      })
      .catch(function () {
        return 'error';
      });
  };

  handleCancel = () => {
    this.setState({
      openModal: false
    });
  };
  render() {
    let theme = this.props.selectedThemeing;
    let { loading, columns, openModal, modalObj, errorInAPI, noDataInDB, refreshDateTime } =
      this.state;
    let { graphprops } = this.props;
    return (
      <Fragment>
        {graphprops && graphprops.checked === true ? (
          <Tag className="graphTag" color="blue">
            {refreshDateTime ? moment(refreshDateTime).format('YYYY-MM-DD HH:mm:ss') : ''}
          </Tag>
        ) : null}
        {noDataInDB === true || errorInAPI === true ? (
          noDataInDB === true ? (
            <NoRecordComponent />
          ) : (
            <ErrorComponent />
          )
        ) : (
          <span>
            <Drawer
              visible={openModal}
              title="Prediction feedback"
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              closable
              footer={[
                <Button key="back" onClick={this.handleCancel}>
                  Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={this.handleOk}>
                  Submit
                </Button>
              ]}
            >
              <Form ref={this.feedbackRef} name="user_form" initialValues={modalObj || {}}>
                <Form.Item
                  label=" Is this prediction useful?"
                  name="Action"
                  rules={[
                    {
                      required: true,
                      message: 'Yes/No ? '
                    }
                  ]}
                >
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="Comment"
                  name="Comment"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Comment'
                    }
                  ]}
                >
                  <Input.TextArea min={'5'} />
                </Form.Item>
              </Form>
            </Drawer>

            {loading === false && columns && columns.length > 0 ? (
              <Table
                bordered
                scroll={{ x: this.tabledata.length > 5 ? true : false }}
                className="TableWidget"
                style={{ padding: '0px 10px', marginTop: '12px', fontSize: '13px !important' }}
                columns={this.state.columns}
                dataSource={this.state.tabledata}
                pagination={{ pageSize: '5' }}
              />
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  color: theme === 'lightTheme' ? 'black' : 'white',
                  marginTop: '10%'
                }}
              >
                <h2 style={{ color: theme === 'lightTheme' ? 'black' : 'white' }}>
                  <Spin spinning={loading} tip="Loading..." size="large"></Spin>
                </h2>
              </div>
            )}
          </span>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache(),
  selectedThemeing: makeSelectThemeing()
});

export default connect(mapStateToProps)(FeedbackTable);
