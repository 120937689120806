import styled from 'styled-components';
import { Modal, Form } from 'antd';
export const StyledModal = styled(Modal)`
  width: 55% !important;

  .ant-modal-content {
    border-radius: 20px;
    background: #1a3652;
  }

  .ant-modal-header {
    background: #1a3652;
  }
  .ant-modal-title {
    color: #ffffff !important;
  }
  .ant-form-item-required {
    color: #ffffff !important;
  }

  .ant-modal-close {
    color: white !important;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
  }
`;
export const StyledForm = styled(Form)`
  .ant-divider-horizontal.ant-divider-with-text {
    display: flex;
    margin: 16px 0;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-top: 0;
  }

  .ant-form-item-label > label {
    color: ${(props) => props.theme.form.formLabel} !important;
    font-weight: revert;
    font-size: ${(props) => props.theme.form.formLabelSize};
    font-weight: bold;
  }

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.66);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }

  .ant-tabs-content-holder {
    border-radius: 0 5px 5px 5px;
  }
`;
