import { API_DEVICE, API_DEVICETYPE, API_MASTERCONFIGURATION } from '../../commons/api';
import localStorage from '../../utils/localStorage';
import axios from 'axios';

export default class device {
  static getDeviceList() {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`${API_DEVICE.GET}?SiteId=${siteId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }
  static getList() {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const ParameterTypeObject = {
      method: 'POST',
      url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        MasterCode: 'Parameter_References',
        ActionType: 'read',
        Type: 'WithoutSiteId'
      }
    };
    return axios(ParameterTypeObject)
      .then((response) => {
        return response.data;
      })
      .catch(function () {});
  }

  static getDeviceCMList() {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`${API_DEVICETYPE.CMDevices}?SiteId=${siteId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static createDevice(payload) {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`${API_DEVICE.CREATE}?SiteId=${siteId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload)
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static updateDevice(payload) {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`${API_DEVICE.UPDATE}/${payload._key}?SiteId=${siteId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }

  static deleteDeviceSaga(payload) {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    return fetch(`${API_DEVICE.DELETE}/${payload}?SiteId=${siteId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response;
    }
  }
}
