import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { createStructuredSelector } from 'reselect';
import {
  Button,
  Input,
  // Popconfirm,
  Tooltip,
  Tag
} from 'antd';
import {
  SearchOutlined,
  EditOutlined
  // DeleteOutlined,
  // QuestionCircleOutlined
} from '@ant-design/icons';
import { encode as base64_encode } from 'base-64';
import history from '../../../../../commons/history';
import ListView from '../../../Utils/ListView';
import { constant } from '../../../Utils/constants';
import { getUserProfile } from '../../../../../selectors/layout';
import { getPMList, deleteById, getUsersList } from '../../../Utils/FormCalls';
import { StyledComponent } from '../../../CSS/style';
import themeSettings from '../../../Utils/themeSettings.json';
import translation from '../../../Utils/translation';
import { getTranslationCache } from '../../../../../selectors/language';
import localStorage from '../../../../../utils/localStorage';
import { API_PM } from '../../../../../commons/api';
class PMList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      userProfile: props && props.userProfile ? props.userProfile : {},
      userList: [],
      config: {
        viewTypes: ['add', 'card', 'table'],
        defaultViewType: 'table',

        filterType: 'ByAttributeValue',
        attributeList: ['Name'],

        firstTitle: 'Name',
        secondTitle: 'CreatedBy',
        thirdTitle: 'CreatedTime',

        actions: ['edit'],
        edit: {
          byAttribute: 'Id',
          redirectURL: '/rubus/AMM/PreventiveMaintenanceDetails'
        },
        deletee: {
          byAttribute: 'Id'
        }
      }
    };
  }

  componentDidMount = async () => {
    this._getDataSource();
  };

  componentDidUpdate(prevprops) {
    if (this.props.userProfile !== prevprops.userProfile) {
      this.setState({ userProfile: this.props.userProfile });
    }
  }
  editPM = async (record) => {
    let { config } = this.state;
    history.push({ pathname: `${config.edit.redirectURL}/${base64_encode(record.Id)}` });
  };

  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };

  _getDataSource = async () => {
    let dataSource = await getPMList();
    this.setState({
      dataSource
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          //maxLength={30}
        />
        <SearchOutlined
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8, fontSize: '130%' }}
        >
          Search
        </SearchOutlined>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, fontSize: '130%' }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };
  handleReset = (clearFilters, confirm) => {
    clearFilters();
    this.handleSearch([], confirm);
  };
  // editJobPlan = async (tableRowData) => {
  //   let { form } = this.state;
  //   let data = await getJobPlanById(tableRowData.Id, 'withId');
  //   let record = data && data !== undefined ? data : {};
  //   _.cloneDeepWith(data, (value) => {
  //     return value !== null && value.StartTime
  //       ? {
  //         ...value,
  //         ...(value['StartTime'] = moment.utc(value.StartTime, 'YYYY-MM-DD HH:mm:ss')),
  //         ...(value['EndTime'] = moment.utc(value.EndTime, 'YYYY-MM-DD HH:mm:ss'))
  //       }
  //       : _.noop();
  //   });
  //   form = {
  //     ...data
  //   };
  //   this.setState({
  //     activeKey: '2',
  //     status: record && record.Status && record.Status === 'COMP' ? 'Completed' : this.state.status,
  //     form
  //   });
  // };

  _deleteById = async (id) => {
    const siteId = localStorage.get('currentSite');
    let payload = {
      url: `${API_PM.DELETE}?Id=${id}&SiteId=${siteId}&Type=${'ESchedule'}`,

      method: 'POST'
    };
    await deleteById(payload);
    let dataSource = await getPMList();
    this.setState({
      dataSource
    });
    // this.taskRef.current.resetFields();
  };

  render() {
    const { dataSource, userList } = this.state;
    let { translationCache } = this.props;
    let columns = [
      {
        title: translation(translationCache, constant.name),
        dataIndex: 'Name',
        key: 'Name',
        filter: true,
        ...this.getColumnSearchProps('Name')
      },
      {
        title: translation(translationCache, constant.description),
        dataIndex: 'Description',
        key: 'Description',
        ...this.getColumnSearchProps('Description')
      },
      {
        title: translation(translationCache, constant.site),
        dataIndex: 'SiteId',
        key: 'SiteId',
        filter: true
      },
      {
        title: translation(translationCache, constant.status),
        dataIndex: 'Status',
        key: 'Status',
        render: (text) => (
          <>
            {/* <Tooltip title={`Click To ${text === 'Active' ? 'Inactive' : 'Active'}`}>
              <span onClick={() => this.changeStatus(record.Id)}> */}
            <Tag
              color={
                text && text === 'Active' ? '#87d068' : text === 'Inactive' ? '#ff4d4f' : 'gold'
              }
              key={text}
            >
              {text}
            </Tag>
            {/* </span>
            </Tooltip> */}
          </>
        )
      },
      {
        title: translation(translationCache, constant.action),
        width: '600',
        render: (text, record) => (
          <>
            <Tooltip placement="top" key="Edit" title={translation(translationCache, 'Edit')}>
              <Button
                disabled={record.Status === 'Inactive' ? true : false}
                style={{ marginRight: '5px' }}
                className="ant-btn"
                onClick={() => this.editPM(record)}
                size="middle"
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            {/* <Popconfirm
              title={translation(translationCache, constant.deletePM)}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => this._deleteById(record.Id)}
              okText={translation(translationCache, constant.yes)}
              cancelText={translation(translationCache, constant.no)}
            >
              <Tooltip placement="top" key="Delete" title={translation(translationCache, 'Delete')}>
                <Button className="ant-btn" size="middle">
                  <span style={{ color: '#ff4d4f' }}>
                    {' '}
                    <DeleteOutlined />
                  </span>
                </Button>
              </Tooltip>
            </Popconfirm> */}
          </>
        )
      }
    ];
    let config = {
      title: translation(translationCache, 'PreventiveMaintenance'),
      viewTypes: ['add', 'card', 'table'],
      defaultViewType: 'table',

      filterType: 'ByAttributeValue',
      attributeList: ['Name'],

      firstTitle: 'Name',
      secondTitle: 'CreatedBy',
      thirdTitle: 'CreatedDate',

      actions: ['edit'],
      edit: {
        byAttribute: 'Id',
        redirectURL: '/rubus/AMM/PreventiveMaintenanceDetails'
      },
      deletee: {
        byAttribute: 'Id'
      }
    };
    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 143 }}>
        <ListView
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(PMList);
