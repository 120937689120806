import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';

import { StyledHomeRouter } from './styles';
import {
  DefaultLandingPage,
  XMLConfiguration,
  MaximoEdgeConfiguration,
  FileUploadAndDownload,
  AssetMapping,
  ParameterMapping,
  MaximoNotification,
  SettingsPage,
  GenericPageNotFound,
  MasterView,
  AssetDeviceList,
  Device,
  DeviceType,
  ApplicationSettings,
  AssetHierarchyView,
  Trends,
  ViewTrends,
  HMI,
  HmiConfiguration,
  DashboardConfiguration,
  ViewDashboard,
  EditDashboard,
  ViewDashboardGroup,
  Report,
  ReportConfiguration,
  ReportTypeConfiguration,
  DataWatch,
  ISOReports,
  ELogBookConfiguration,
  ELogBookView,
  DownTimeLogs,
  RuleEngine,
  RulesList,
  RulesFeedback,
  RuleEngineRootCause,
  DeviceTypeRuleExclusion,
  EquipmentMasters,
  EMM,
  AMM,
  Analytics,
  AnalyticsDetails,
  QueryBuilder,
  SmartControl,
  SmartControlTags,
  MoistureSensor,
  QrDetails,
  QrList,
  CraneStatus,
  FormGenerator,
  FormGeneratorLink,
  AllModulesList,
  EquipmentOverview,
  XMLConfigurationLists,
  SRHaltTime,
  SRWaitInterval,
  StatusScreen,
  IntervalMasterDetails,
  // IntervalMasterList,
  InHouseRegistration,
  ChatApplication,
  CustomerAssetDeviceComponent
  // AssetAttributeList
  // Parameter
} from '../pages';
import ChatUpload from './../pages/ChatApplication/ChatUpload';
import ChatWidgets from './../pages/ChatApplication/ChatWidgets';

import MapCoordinates from '../pages/Dashboard/ChartComponents/Widgets/Unused/GeoMap/MapWithClusterExample';
import SiteOverview from '../pages/Modules/Common/DefaultLandingPage/CompleteTagStatus';
import RealTimeAssetTracking from '../pages/Dashboard/ChartComponents/Widgets/Unused/GeoMap/MapWithSidePanel';
import TurbineOverview from '../pages/Modules/Common/DefaultLandingPage/TurbineHomePage';
import TrendsAnalysis from '../pages/TrendAnalysis';
import CmFeedback from '../pages/AMM/Modules/WorkPlanning/ConditionalMonitoring/CmFeedback';
import Analytics_V1 from '../pages/Analytics_Predictions/Version_1.0/index';
import LookupMasters from '../pages/Settings/Masters/LookupMasters/index';
import LookupMasterItems from '../pages/Settings/Masters/LookupMasters/LookupMasterItems';
// // import Modules from '../pages/CustomerAssetDeviceConfiguration/Masters/Modules';
import Mod from '../pages/CustomerAssetDeviceConfiguration/Masters/Mod';
import DeviceTypes from '../pages/CustomerAssetDeviceConfiguration/Masters/DeviceTypes';
import SubEquipments from '../pages/CustomerAssetDeviceConfiguration/Masters/SubEquipments';
import StatusConfiguration from '../pages/CustomerAssetDeviceConfiguration/StatusConfiguration';

import Fileupload from '../pages/ChatApplication/ChatUpload/FileUpload';
import Parameter from '../pages/CustomerAssetDeviceConfiguration/Masters/Parameter';
// import Attributes from '../pages/CustomerAssetDeviceConfiguration/Masters/Attributes';
import DeviceTypesChildren from '../pages/CustomerAssetDeviceConfiguration/Masters/DeviceTypesChildren';
import RubusAnalytics from '../pages/Analytics/Analytics_V2';
import ModuleWiseSVG from '../pages/HMI/Custom';
import ModuleWiseDashboard from '../pages/Dashboard/Custom';
import TrainStatus from '../pages/Modules/Common/DefaultLandingPage/TrainStatus';
// import Home from '../pages/Analytics/Analytics_V2/Home';
import RuleEngineMaintainance from '../pages/RuleEngine/RuleEngineMaintainance';
import AssetAttributes from '../pages/CustomerAssetDeviceConfiguration/Masters/RubusMaster/AssetAttributes';
import AssetTypes from '../pages/CustomerAssetDeviceConfiguration/Masters/RubusMaster/AssetTypes';
import DynamicHierarchy from '../pages/CustomerAssetDeviceConfiguration/Masters/RubusMaster/DynamicHierarchy';
import AssetAttributeList from '../pages/CustomerAssetDeviceConfiguration/AssetAttributes/AssetAttributeList';
// import AssetAttributeDetails from '../pages/CustomerAssetDeviceConfiguration/AssetAttributes/AssetAttributeDetails';
import CMRLFaults from '../pages/Modules/Common/DefaultLandingPage/CMRL_Faults';
import AIRulesDetails from '../pages/RuleEngine/AIGenerator';
import AIFileUpload from '../pages/RuleEngine/AIGenerator/FileUploads/FileUpload';
import AIList from '../pages/RuleEngine/AIGenerator/AIList';
import AssetParameter from '../pages/CustomerAssetDeviceConfiguration/Masters/AssetParameter';
import MultiLevelDashboard from '../pages/Dashboard/Custom/MultiLevelDashboard';
import TaskScheduler from '../pages/Modules/DataBackup/TaskScheduler';
import TaskSchedulerDetails from '../pages/Modules/DataBackup/TaskSchedulerDetails';
import PurgeList from '../pages/Modules/DataBackup/Purge';
import DailyPowerGenerationReport from '../pages/Reports/Custom/DailyPowerGenerationReport';
export class HomePageRouter extends Component {
  dynamicRouteRender = (routes) => {
    return (
      routes &&
      Array.isArray(routes) &&
      routes.map((route, index) => {
        return <Route key={index} {...route} />;
      })
    );
  };

  render() {
    document.title = 'Rubus-Industrial IoT Platform';

    let routes = [
      { path: '/rubus/LandingPage', component: DefaultLandingPage },
      { path: '/rubus/CMRLFaults', component: CMRLFaults },
      { path: '/rubus/EquipmentOverview', component: EquipmentOverview },
      { path: '/rubus/TrainStatus', component: TrainStatus },
      { path: '/rubus/settingsPage', component: SettingsPage },
      { path: '/rubus/CentralDashboard', component: AllModulesList },
      { path: '/rubus/CraneStatus', component: CraneStatus },
      { path: '/rubus/MaximoXMLConfigurationLists', component: XMLConfigurationLists },
      { path: '/rubus/MaximoXMLConfiguration', component: XMLConfiguration },

      { path: '/rubus/MaximoEdgeConfiguration', component: MaximoEdgeConfiguration },
      { path: '/rubus/MeterUploadAndDownload', component: FileUploadAndDownload },
      { path: '/rubus/MaximoNotification', component: MaximoNotification },
      { path: '/rubus/FaultsUploadAndDownload', component: AssetMapping },
      { path: '/rubus/ParameterUploadAndDownload', component: ParameterMapping },
      { path: '/rubus/master/:mastername', component: MasterView },

      { path: '/rubus/assetConfiguration', component: AssetDeviceList },
      { path: '/rubus/assetConfiguration/:location/:device', component: AssetDeviceList },
      { path: '/rubus/equipment', component: Device },
      { path: '/rubus/equipment-type', component: DeviceType },
      { path: '/rubus/configurationSettings', component: ApplicationSettings },
      { path: '/rubus/AssetHierarchyView', component: AssetHierarchyView },

      { path: '/rubus/Trends', component: Trends, exact: true },
      { path: '/rubus/Trends/:module', component: Trends, exact: true },
      { path: '/rubus/ViewTrends/:id', component: ViewTrends, exact: true },
      { path: '/rubus/TrendsAnalysis', component: TrendsAnalysis, exact: true },

      { path: '/rubus/smartcontrol/:formName', component: SmartControl, exact: true },
      { path: '/rubus/smartcontrol', component: SmartControl, exact: true },
      { path: '/rubus/SmartControlTag', component: SmartControlTags, exact: true },

      { path: '/rubus/QrList', component: QrList, exact: true },
      { path: '/rubus/QrDetails/:id', component: QrDetails, exact: true },

      { path: '/rubus/hmiConfiguration', component: HmiConfiguration },
      { path: '/rubus/HMI/:type/:hmiName', component: HMI, exact: true },
      { path: '/rubus/HMI/:type/:hmiName/:dynamicName', component: HMI, exact: true },
      { path: '/rubus/ModuleWiseSVG/:module', component: ModuleWiseSVG, exact: true },
      { path: '/rubus/ModuleWiseDashboard/:module', component: ModuleWiseDashboard, exact: true },

      { path: '/rubus/dashboard', component: DashboardConfiguration, exact: true },
      { path: '/rubus/multileveldashboard', component: MultiLevelDashboard },
      { path: '/rubus/dashboard/:name', component: ViewDashboard },
      { path: '/rubus/dashboard/:name/:deviceCode', component: ViewDashboard },
      { path: '/rubus/editDashboard', component: EditDashboard },
      { path: '/rubus/dashboards/:name', component: ViewDashboardGroup },

      { path: '/rubus/reportConfiguration', component: ReportConfiguration },
      { path: '/rubus/report/:reportType/:report', component: Report },
      { path: '/rubus/ReportTypeConfiguration', component: ReportTypeConfiguration },
      { path: '/rubus/DataWatch/:datawatch/:craneName/:groupname', component: DataWatch },
      { path: '/rubus/DataWatch/:datawatch/:craneName', component: DataWatch },
      { path: '/rubus/DataWatch/:datawatch', component: DataWatch },
      { path: '/rubus/ISOReports', component: ISOReports },

      { path: '/rubus/FFT', component: FormGenerator },
      { path: '/rubus/FFTLink', component: FormGeneratorLink },

      { path: '/rubus/Configuration/ELogBook', component: ELogBookConfiguration },
      { path: '/rubus/LogBook/:name', component: ELogBookView },

      { path: '/rubus/DownTime', component: DownTimeLogs },

      { path: '/rubus/RuleEngine', component: RuleEngine, exact: true },
      { path: '/rubus/RulesList', component: RulesList, exact: true },
      { path: '/rubus/RuleEngine/:id', component: RuleEngine, exact: true },
      { path: '/rubus/RulesFeedback', component: RulesFeedback, exact: true },
      { path: '/rubus/Rule/RootCause', component: RuleEngineRootCause, exact: true },
      { path: '/rubus/Rule/Maintainance', component: RuleEngineMaintainance, exact: true },
      { path: '/rubus/DeviceTypeRuleExclusion', component: DeviceTypeRuleExclusion, exact: true },

      { path: '/rubus/EMM', component: EMM },
      { path: '/rubus/EquipmentMaster', component: EquipmentMasters },

      { path: '/rubus/AMM', component: AMM },
      { path: '/rubus/CmFeedback', component: CmFeedback, exact: true },

      { path: '/rubus/RbsAnalytics/UseCase', component: Analytics },
      { path: '/rubus/RbsAnalytics/UseCaseDetails/:Id', component: AnalyticsDetails },
      { path: '/rubus/RbsAnalytics', component: Analytics_V1 },
      { path: '/rubus/LookupMasters', component: LookupMasters },
      { path: '/rubus/RubusAnalytics', component: RubusAnalytics },

      {
        path: '/rubus/LookupMasterItemDetails/:Id',
        component: LookupMasterItems
      },

      { path: '/rubus/QueryBuilder', component: QueryBuilder },

      { path: `/rubus/form/:deviceId/:formName`, component: MoistureSensor },
      { path: `/rubus/map/getCoordinates`, component: MapCoordinates },
      { path: `/rubus/map/RealTimeAssetTracking`, component: RealTimeAssetTracking },
      { path: `/rubus/map/TurbineOverview`, component: TurbineOverview },
      { path: `/rubus/SiteOverview`, component: SiteOverview },

      { path: '/rubus/Maximo/:BMS/SRHaltTime', component: SRHaltTime, exact: true },
      { path: '/rubus/Maximo/:BMS/SRWaitInterval', component: SRWaitInterval, exact: true },
      { path: '/rubus/Maximo/:BMS/StatusScreen', component: StatusScreen, exact: true },

      { path: '/rubus/InHouseRegistration', component: InHouseRegistration, exact: true },
      { path: '/rubus/ChatApplication', component: ChatApplication, exact: true },
      { path: '/rubus/ChatApplication/upload', component: ChatUpload, exact: true },
      { path: '/rubus/ChatApplication/fileupload', component: Fileupload, exact: true },
      { path: '/rubus/ChatApplication/graph', component: ChatWidgets, exact: true },
      {
        path: '/rubus/Maximo/IntervalMasterDetails',
        component: IntervalMasterDetails,
        exact: true
      },
      {
        path: '/rubus/CustomerAssetDeviceConfiguration/:module',
        component: CustomerAssetDeviceComponent
      },
      // {
      //   path: '/rubus/CustomerAssetDevice/:module',
      //   component: Modules
      // },
      {
        path: '/rubus/CustomerAssetDevice/Modules',
        component: Mod
      },
      {
        path: '/rubus/CustomerAssetDevice/DeviceTypes',
        component: DeviceTypes
      },
      {
        path: '/rubus/CustomerAssetDevice/SubEquipments',
        component: SubEquipments
      },
      {
        path: '/rubus/CustomerAssetDevice/Parameter',
        component: Parameter
      },
      {
        path: '/rubus/CustomerAssetDevice/AssetParameter',
        component: AssetParameter
      },
      {
        path: '/rubus/CustomerAssetDevice/DeviceTypesChildren',
        component: DeviceTypesChildren
      },
      {
        path: '/rubus/CustomerAssetDevice/StatusConfiguration',
        component: StatusConfiguration
      },
      {
        path: '/rubus/CustomerAssetDevice/AssetTypes',
        component: AssetTypes
      },
      {
        path: '/rubus/CustomerAssetDevice/DynamicHierarchy',
        component: DynamicHierarchy
      },
      // {
      //   path: '/rubus/CustomerAssetDevice/AssetAttributesList',
      //   component: Attributes
      // },
      {
        path: '/rubus/CustomerAssetDevice/CommonAttributes',
        component: AssetAttributes
      },
      {
        path: '/rubus/CustomerAssetDevice/UnCommonAttributes',
        component: AssetAttributeList
      },
      // { path: '/rubus/CustomerAssetDevice/AssetAttributeDetails/:id', component: AssetAttributeDetails },
      // {
      //   path: '/rubus/RubusAnalytics/Home',
      //   component: Home
      // },
      {
        path: '/rubus/AIRulesDetails',
        component: AIRulesDetails
      },

      {
        path: '/rubus/AIRulesList',
        component: AIList
      },

      {
        path: '/rubus/AI/fileupload',
        component: AIFileUpload
      },
      {
        path: '/rubus/TaskScheduler',
        component: TaskScheduler
      },
      {
        path: '/rubus/TaskSchedulerDetails',
        component: TaskSchedulerDetails
      },
      {
        path: '/rubus/PurgeList',
        component: PurgeList
      },
      {
        path: '/rubus/DailyPowerGenerationReport',
        component: DailyPowerGenerationReport
      },

      { path: '*', component: GenericPageNotFound }
    ];

    return (
      <StyledHomeRouter>
        <Switch>{this.dynamicRouteRender(routes)}</Switch>
      </StyledHomeRouter>
    );
  }
}
export default withRouter(HomePageRouter);
