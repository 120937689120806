import styled from 'styled-components';
import { Card } from 'antd';

export const darkTheme = {
  text: 'rgba(0,0,0,.85)',
  even: '#595959',
  odd: '#8c8c8c',
  hover: '#f5f5f5'
};

export const lightTheme = {
  text: 'black',
  even: '#7a8793',
  odd: '#bdc0c2',
  hover: '#dedede'
};

export const StyledTable = styled.table`
  caption-side: top;


  color: ${(props) => props.theme.text} !important;

  width: 100%;
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */

  caption-side: bottom;
  /* empty-cell: show | hide;  */
  /* empty-cell is a property of table or the cells themselves */

  /* vertical-align: baseline | sub | super | text-top | 
                text-bottom | middle | top | bottom | 
                <percentage> | <length> */

  /* tbody {
    vertical-align: top;
  }              */
  td,
  th {
    border: none;
  }
  /* td,
  th {
      color:${(props) => props.theme.text} !important;
    border: 1px solid red;
   
  } */

  td {
    padding: 11px 10px;
  }
  .ant-table-thead > tr > th{
    font-weight:bold !important
    font-size: 1em !important;
    color:white !important;
    background: #214972 ;
  }
  text[style] {
    // font-weight:bold !important
    font-size: 1em !important;
    color: ${(props) => props.theme.text} !important;
 }
  tbody tr {
    :nth-of-type(odd) {
      background-color:${(props) => props.theme.odd} !important;
    }
    :hover {
      background-color:${(props) => props.theme.hover} !important; 
    }
  }
  tbody tr {
    :nth-of-type(even) {
      background-color:${(props) => props.theme.even} !important;
    }
    :hover {
      background-color:${(props) => props.theme.hover} !important; 
    }
  }
  thead > tr {
    color: ${(props) => props.theme.text} !important;
    background-color: #262626 !important;
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;

// export const StyledTab = styled(Tabs)`
//   .ant-tabs-nav-container {
//     height: 50px !important;
//   }
//   .ant-tabs-nav {
//     // border-radius: 10px;
//   }
//   .ant-tabs-tab {
//     background: #1a3652 !important;
//     border-radius: 8px;
//     color: white !important;
//     font-weight: 800;
//     border-radius: 10px !important;
//     border:1px solid #fff !important
//   }

//   .ant-tabs-extra-content {
//     margin-right: 6%;
//     margin-top: 0.6%;
//   }
//    .ant-tabs-tab-active{
//     background: #fff !important;
//     border-radius: 8px;
//     color: black !important;
//     font-weight: 800;
//     border:1px solid #1a3652 !important
//     border-radius: 8px !important;

//   }

// `;

export const StyledCard = styled(Card)`
border: 1px solid #D3D3D3;
border-radius:20px;
margin-bottom: 10px;


box-shadow: 0 8px 6px -6px grey;

.ant-card-head {
    color:#000000 !important;
    border-bottom: 2px solid #d2caca;
    height: 65px;
}
.ant-card-body {
    color:#000000 !important;
    font-size:16px
    padding-left:0px
}`;

export const StyledElogbook = styled.div`
  margin: 45px 0px;
`;
