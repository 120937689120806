import { API_AUTH } from '../../commons/api';
import localStorage from '../../utils/localStorage';

export default class layout {
  static getUserprofile() {
    const accessToken = localStorage.get('accessToken');
    const siteId = localStorage.get('currentSite');
    return fetch(`${API_AUTH.ME}?SiteId=${siteId}&menuType=Web`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(responseToken);
    function responseToken(response) {
      return response.json();
    }
  }
}
