import React from 'react';
import axios from 'axios';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import history from '../../commons/history';
import localStorage from '../../utils/localStorage';
import Text from './Text';
import { getTranslationCache } from '../../selectors/language';
// import SelectComponent from './SelectComponent';
import moment from 'moment';
import { API_DEVICE, API_MASTERCONFIGURATION, API_HMI, MASTERIMAGES } from '../../commons/api';

class SinglePlot extends React.Component {
  timeTicket = null;
  constructor(props) {
    super(props);
    this.state = {
      triggerPoint: null,
      config: {},
      annotations: [],
      shapesannotations: [],
      staticannotations: [],
      animations: [],
      sldName: '',
      hmiName: '',
      hmiType: '',
      x: 0,
      y: 0,
      loading: false,
      payload: 0,
      initialRender: true,
      deviceList: []
    };
  }

  componentDidMount() {
    try {
      this.getHMITypeAPIs();
      if (this.props.match.params.hmiName && this.props.match.params.type) {
        this.setState({
          hmiName: this.props.match.params.hmiName,
          hmiType: this.props.match.params.type || 'dynamic',
          deviceCode: this.props.match.params.dynamicName
        });
        let hmiObj = {
          hmiName: this.props.match.params.hmiName,
          hmiType: this.props.match.params.type || 'dynamic',
          deviceCode: this.props.match.params.dynamicName
        };
        this._getDeviceList();
        this.hmiMapping(hmiObj);
      } else if (this.props.match.params.hmiName && !this.props.match.params.dynamicName) {
        this.setState({
          hmiName: this.props.match.params.hmiName,
          hmiType: 'static',
          name: this.props.match.params.hmiName
        });
        let hmiObj = {
          hmiName: this.props.match.params.hmiName,
          hmiType: 'static'
        };
        this.setState({ name: this.props.match.params.hmiName });
        this.hmiMapping(hmiObj);
      }
    } catch (e) {
      return {};
    }
  }

  componentDidUpdate(prevprops) {
    let { initialRender } = this.state;
    const siteId = localStorage.get('currentSite');
    try {
      if (
        (this.props.match.params.hmiName &&
          this.props.match.params.type &&
          (this.props.match.params.hmiName !== prevprops.match.params.hmiName ||
            this.props.match.params.dynamicName !== prevprops.match.params.dynamicName)) ||
        (initialRender && this.props.match.params.hmiName && this.props.match.params.type)
      ) {
        this.setState({
          hmiName: this.props.match.params.hmiName,
          hmiType: this.props.match.params.type,
          deviceCode: this.props.match.params.dynamicName
        });
        let hmiObj = {
          hmiName: this.props.match.params.hmiName,
          hmiType: this.props.match.params.type,
          deviceCode: this.props.match.params.dynamicName
        };
        this.hmiMapping(hmiObj);
        this.setState({ initialRender: false });
      } else if (
        this.props.match.params.hmiName !== prevprops.match.params.hmiName ||
        (initialRender && this.props.match.params.hmiName && !this.props.match.params.dynamicName)
      ) {
        this.setState({
          hmiName: this.props.match.params.hmiName,
          hmiType: this.props.match.params.type
        });
        let hmiObj = {
          hmiName: this.props.match.params.hmiName,
          hmiType: this.props.match.params.type,
          SiteId: siteId
        };
        this.setState({ initialRender: false });
        this.hmiMapping(hmiObj);
      }
    } catch (e) {
      return {};
    }
  }

  getHMITypeAPIs = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const hmiMappingObject = {
      method: 'POST',
      url: `${API_MASTERCONFIGURATION.Master}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { MasterCode: 'HMI_Types', ActionType: 'read', Type: 'WithoutSiteId' }
    };
    this.setState({ loading: true });
    axios(hmiMappingObject)
      .then((response) => {
        this.setState({
          HMITypeList: response.data || {}
        });
      })
      .catch(function () {});
  };

  _getDeviceList = () => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const devicePayload = {
      method: 'GET',
      url: `${API_DEVICE.GET}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    this.setState({ loading: true });
    axios(devicePayload)
      .then((response) => {
        this.setState({
          deviceList: response.data || []
        });
      })
      .catch(function () {});
  };

  hmiMapping = (payload) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const hmiMappingObject = {
      method: 'GET',
      url: `${API_HMI.GET}?SiteId=${siteId}&Name=${payload.hmiName}&Type=${payload.hmiType}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    this.setState({ loading: true });
    axios(hmiMappingObject)
      .then(async (response) => {
        if (response.data && Array.isArray(response.data) && response.data.length > 0) {
          let HMIObject = response.data;
          let data = HMIObject && HMIObject[0] && HMIObject[0].JSON ? HMIObject[0].JSON : {};
          if (this.timeTicket) {
            clearInterval(this.timeTicket);
          }
          let { HMITypeList } = this.state;

          let currentHMI =
            HMITypeList &&
            Array.isArray(HMITypeList) &&
            HMITypeList.filter((hmi) => hmi.HMIType === payload.hmiType);
          let URL =
            currentHMI && Array.isArray(currentHMI) && currentHMI[0] && currentHMI[0].URL
              ? currentHMI[0].URL
              : '';
          this._updatehmiMapping(payload, URL);
          this.timeTicket = setInterval(
            () => {
              this._updatehmiMapping(payload, URL);
            },
            data && data.frequency ? data.frequency * 60000 : 1 * 60000
          ); // min frequency

          this.setState({
            loading: false,
            config: data
          });
        }
      })
      .catch(function () {});
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  _updatehmiMapping = (payload, URL) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const hmiMappingObject = {
      method: 'POST',
      url: `${URL}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: payload
    };
    axios(hmiMappingObject)
      .then((response) => {
        this.setState({
          lastUpdatedTimeStamp: moment().format('YYYY-MM-DD HH:mm:ss'),
          annotations: response.data.annotations,
          shapesannotations: response.data.shapesannotations,
          animations: response.data.animations,
          staticannotations: response.data.staticannotations
        });
      })
      .catch(function () {});
  };

  componentWillUnmount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
  }

  render() {
    const { config, loading, animations } = this.state;
    console.log(config.Url);

    return (
      <div>
        {loading === false ? (
          <div>
            {config &&
            config.showGoBack &&
            config.showGoBack === true &&
            history.location.pathname.split('/rubus/HMI') &&
            history.location.pathname.split('/rubus/HMI').length > 1 ? (
              <Button
                type="primary"
                shape="round"
                style={{ marginLeft: '10px' }}
                onClick={() => history.goBack()}
              >
                <LeftOutlined />
                Go back
              </Button>
            ) : null}
            {/* {hmiType &&
            (hmiType === 'dynamic' || hmiType === 'distinct') &&
            this.props.triggerPoint !== 'dashboard' ? (
              <SelectComponent
                hmiName={hmiName}
                hmiType={hmiType}
                deviceCode={deviceCode}
                deviceList={deviceList}
                api={config && config.api ? config.api : {}}
              />
            ) : null} */}
            <svg viewBox={config.viewbox}>
              <image href={`${config.Url}`} width={config.width} height={config.height} />
              {this.state.shapesannotations && this.state.shapesannotations.length > 0
                ? this.state.shapesannotations
                    .filter((ann) => ann.ignore !== true)
                    .map((annotations) => {
                      switch (annotations.type) {
                        case 'rect':
                          return (
                            <rect
                              x={annotations.x}
                              y={annotations.y}
                              rx={annotations.rx}
                              ry={annotations.ry}
                              width={annotations.width}
                              height={annotations.height}
                              fill={annotations.fill}
                              className={annotations.className}
                              fillOpacity={annotations.opacity}
                              onClick={
                                annotations.route === true
                                  ? () => {
                                      history.push(annotations.path);
                                    }
                                  : undefined
                              }
                            />
                          );
                        case 'circle':
                          return (
                            <circle
                              cx={annotations.cx}
                              cy={annotations.cy}
                              r={annotations.r}
                              fill={annotations.fill}
                              className={annotations.className}
                              fillOpacity={annotations.opacity}
                            />
                          );
                        case 'ellipse':
                          return (
                            <ellipse
                              cx={annotations.cx}
                              cy={annotations.cy}
                              rx={annotations.rx}
                              ry={annotations.ry}
                              fill={annotations.fill}
                              className={annotations.className}
                              fillOpacity={annotations.opacity}
                            />
                          );
                        case 'recthref':
                          return (
                            <Link to={annotations.link}>
                              <rect
                                x={annotations.x}
                                y={annotations.y}
                                width={annotations.width}
                                height={annotations.height}
                                fill={'transparent'}
                                fill-opacity={annotations.opacity}
                              />
                            </Link>
                          );
                        // rectwithtext is to align text at the center of rectangle
                        case 'rectwithtext':
                          return (
                            <svg>
                              <text
                                x="37.3"
                                y="7"
                                alignment-baseline="middle"
                                text-anchor="middle"
                                fontSize="1px"
                                fontWeight="bold"
                              >
                                {this.translation('MAGOME FACTORY')}
                              </text>
                            </svg>
                          );
                        // string is used to translate a text to other languages
                        case 'string':
                          return (
                            <Text
                              type={annotations.type}
                              limits={annotations.limits}
                              x={annotations.x}
                              y={annotations.y}
                              fontFamily={config.fontFamily}
                              fontSize={config.fontSize}
                              fill={annotations.colorCode}
                              checkStatus={config.checkStatus}
                              displayName={annotations.displayName}
                              device={annotations.device}
                              conditions={annotations.conditions}
                              payload={annotations.payload}
                              fontWeight={annotations.fontWeight}
                              hmiName={this.state.hmiName}
                              hmiType={this.state.hmiType}
                            />
                          );
                        default:
                          return null;
                      }
                    })
                : ''}

              {this.state.annotations && this.state.annotations.length > 0
                ? this.state.annotations
                    .filter((ann) => ann.ignore !== true)
                    .map((annotation, index) => {
                      return (
                        <Text
                          key={index}
                          limits={annotation.limits}
                          x={annotation.x}
                          y={annotation.y}
                          fontFamily={
                            annotation.fontFamily && annotation.fontFamily !== undefined
                              ? annotation.fontFamily
                              : config.fontFamily
                          }
                          fontSize={
                            annotation.fontSize && annotation.fontSize !== undefined
                              ? annotation.fontSize
                              : config.fontSize
                          }
                          fill={
                            annotation.fill && annotation.fill !== undefined
                              ? annotation.fill
                              : config.fill
                          }
                          type={
                            annotation.type && annotation.type !== undefined
                              ? annotation.type
                              : 'number'
                          }
                          className={annotation.className}
                          checkStatus={config.checkStatus}
                          displayParameters={config.displayParameters}
                          displayName={annotation.displayName}
                          device={annotation.device}
                          conditions={annotation.conditions}
                          payload={annotation.payload}
                          fontWeight={annotation.fontWeight}
                          alignment={annotation.alignment}
                          hmiName={this.state.hmiName}
                          hmiType={this.state.hmiType}
                          annotation={annotation}
                        />
                      );
                    })
                : ''}

              {animations && animations.length > 0
                ? animations
                    .filter((ann) => ann.ignore !== true)
                    .map((animation) => {
                      switch (animation.type) {
                        case 'fan':
                          return (
                            <image
                              x={animation.x}
                              y={animation.y}
                              xlinkHref={`${MASTERIMAGES.MasterImages}/${animation.imagePrefix}${animation.payload}${animation.imageFormat}`}
                              className={`rotate_${animation.payload}`}
                              width={
                                animation.width && animation.width !== undefined
                                  ? animation.width
                                  : '4px'
                              }
                              height={
                                animation.height && animation.height !== undefined
                                  ? animation.height
                                  : '4px'
                              }
                            />
                          );
                        case 'pointer':
                          return (
                            <image
                              x={animation.x}
                              y={animation.y}
                              xlinkHref={`${MASTERIMAGES.MasterImages}/${animation.imagePrefix}${animation.payload}${animation.imageFormat}`}
                              className={`rotate_${animation.payload}`}
                              width={
                                animation.width && animation.width !== undefined
                                  ? animation.width
                                  : '4px'
                              }
                              height={
                                animation.height && animation.height !== undefined
                                  ? animation.height
                                  : '4px'
                              }
                            />
                          );
                        case 'arrow':
                          return (
                            <image
                              x={animation.x}
                              y={animation.y}
                              xlinkHref={`${MASTERIMAGES.MasterImages}/${animation.imagePrefix}${animation.payload}${animation.imageFormat}`}
                              className={`arrow_${animation.payload}`}
                              width={
                                animation.width && animation.width !== undefined
                                  ? animation.width
                                  : '4px'
                              }
                              height={
                                animation.height && animation.height !== undefined
                                  ? animation.height
                                  : '4px'
                              }
                            />
                          );
                        case 'foreignLR':
                          return (
                            <foreignObject
                              className="node"
                              x={animation.x}
                              y={animation.y}
                              width={
                                animation.width && animation.width !== undefined
                                  ? animation.width
                                  : '18'
                              }
                              height={
                                animation.height && animation.height !== undefined
                                  ? animation.height
                                  : '4'
                              }
                            >
                              <div
                                style={{ width: '20px', height: '10px' }}
                                className={` sideLR_${animation.payload} normal`}
                              >
                                {' '}
                              </div>
                            </foreignObject>
                          );
                        case 'foreignTB':
                          return (
                            <foreignObject
                              className="node"
                              x={animation.x}
                              y={animation.y}
                              width={
                                animation.width && animation.width !== undefined
                                  ? animation.width
                                  : '1'
                              }
                              height={
                                animation.height && animation.height !== undefined
                                  ? animation.height
                                  : '11'
                              }
                            >
                              <div
                                style={{ width: '4px', height: '7px' }}
                                className={` topTB_${animation.payload} normal`}
                              >
                                {' '}
                              </div>
                            </foreignObject>
                          );
                        case 'foreignRL':
                          return (
                            <foreignObject
                              className="node"
                              x={animation.x}
                              y={animation.y}
                              width={
                                animation.width && animation.width !== undefined
                                  ? animation.width
                                  : '18'
                              }
                              height={
                                animation.height && animation.height !== undefined
                                  ? animation.height
                                  : '4'
                              }
                            >
                              <div
                                style={{ width: '20px', height: '10px' }}
                                className={` sideRL_${animation.payload} normal`}
                              >
                                {' '}
                              </div>
                            </foreignObject>
                          );
                        case 'foreignBT':
                          return (
                            <foreignObject
                              className="node"
                              x={animation.x}
                              y={animation.y}
                              width={
                                animation.width && animation.width !== undefined
                                  ? animation.width
                                  : '1'
                              }
                              height={
                                animation.height && animation.height !== undefined
                                  ? animation.height
                                  : '11'
                              }
                            >
                              <div
                                style={{ width: '4px', height: '7px' }}
                                className={` topBT_${animation.payload} normal`}
                              >
                                {' '}
                              </div>
                            </foreignObject>
                          );
                        case 'image':
                          return (
                            <image
                              x={animation.x}
                              y={animation.y}
                              onError={(event) => (event.target.style.display = 'none')}
                              xlinkHref={`${MASTERIMAGES.MasterImages}/${animation.imagePrefix}${animation.payload}${animation.imageFormat}`}
                              className={animation.className}
                              width={
                                animation.width && animation.width !== undefined
                                  ? animation.width
                                  : '4px'
                              }
                              height={
                                animation.height && animation.height !== undefined
                                  ? animation.height
                                  : '4px'
                              }
                            />
                          );
                        default:
                          return {};
                      }
                    })
                : ''}

              {this.state.staticannotations && this.state.staticannotations.length > 0
                ? this.state.staticannotations
                    .filter((ann) => ann.ignore !== true)
                    .map((annotation, index) => {
                      return (
                        <Text
                          key={index}
                          limits={annotation.limits}
                          x={annotation.x}
                          y={annotation.y}
                          fontFamily={
                            annotation.fontFamily && annotation.fontFamily !== undefined
                              ? annotation.fontFamily
                              : config.fontFamily
                          }
                          fontSize={
                            annotation.fontSize && annotation.fontSize !== undefined
                              ? annotation.fontSize
                              : config.fontSize
                          }
                          fill={
                            annotation.fill && annotation.fill !== undefined
                              ? annotation.fill
                              : config.fill
                          }
                          type={
                            annotation.type && annotation.type !== undefined
                              ? annotation.type
                              : 'number'
                          }
                          className={annotation.className}
                          checkStatus={config.checkStatus}
                          displayParameters={config.displayParameters}
                          displayName={annotation.displayName}
                          device={annotation.device}
                          conditions={annotation.conditions}
                          payload={this.state[annotation.mappingKey]}
                          fontWeight={annotation.fontWeight}
                          alignment={annotation.alignment}
                          hmiName={this.state.hmiName}
                          hmiType={this.state.hmiType}
                          annotation={annotation}
                        />
                      );
                    })
                : ''}
            </svg>
          </div>
        ) : (
          <div
            style={{
              textAlign: 'center',
              marginTop: '20%'
            }}
          >
            <Spin spinning={loading} tip="Loading..." size="large"></Spin>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(SinglePlot);
