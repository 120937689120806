import React, { Component } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { Col, Form, Input, DatePicker, message } from 'antd';

import SelectComponennt from '../Widgets/SelectComponent';
import localStorage from '../../../../utils/localStorage';
import { LOGBOOK } from '../../../../commons/api';
const { RangePicker } = DatePicker;
let id = 0;

class AttributeDynamic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: cloneDeep(props.formObject) || {},
      columns: props.formObject && props.formObject.columns ? props.formObject.columns : [],
      data: props.formObject && props.formObject.datasource ? props.formObject.datasource : [],
      activeKey: [],
      headerValue: {},
      recordType: props.recordType || '',
      remarks: ''
    };
  }

  componentDidMount() {
    this.setState({
      formObject: this.props.formObject,
      columns:
        this.props.formObject && this.props.formObject.columns ? this.props.formObject.columns : [],
      data:
        this.props.formObject && this.props.formObject.datasource
          ? this.props.formObject.datasource
          : []
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formObject !== this.props.formObject ||
      prevProps.headerValue !== this.props.headerValue ||
      prevProps.header !== this.props.header ||
      prevProps.elog !== this.props.elog ||
      prevProps.userProfile !== this.props.userProfile
    ) {
      this.setState({
        formObject: cloneDeep(this.props.formObject),
        columns:
          this.props.formObject && this.props.formObject.columns
            ? this.props.formObject.columns
            : [],
        data:
          this.props.formObject && this.props.formObject.datasource
            ? this.props.formObject.datasource
            : [],
        headerValue: this.props.headerValue,
        header: this.state.header,
        userProfile: this.props.userProfile,
        elog: this.props.elog
      });
    }
  }

  addDownTime = () => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      [id]: {
        columns: formObject['batchAttribute'].columns,
        datasource: {}
      }
    };
    id = id + 1;
    this.props.downTimeData(formObject, false);
  };

  onCause = (name) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...(formObject && formObject[name] && formObject[name].datasource
            ? formObject[name].datasource
            : {})
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onInputChange = (columnValue, columnKey, name) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...formObject[name].datasource,
          [columnKey]: columnValue
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onHeaderInputChange = async (columnValue, columnKey, name) => {
    let columnsAttribute = [];
    let formObject = cloneDeep(this.props.formObject);
    if (columnKey === 'Equipment') {
      let attributeLists = await this._getAttributeList(columnValue);
      columnsAttribute =
        attributeLists &&
        Array.isArray(attributeLists) &&
        attributeLists.map((attributeName) => {
          return {
            displayName: attributeName,
            key: attributeName,
            placeHolder: 'Please input ' + attributeName,
            required: true,
            widget: 'input',
            width: '20%'
          };
        });
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          columns: columnsAttribute,
          headerValue: {
            ...(formObject && formObject[name] && formObject[name].headerValue
              ? formObject[name].headerValue
              : {}),
            [columnKey]: columnValue
          }
        }
      };
    } else {
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          headerValue: {
            ...(formObject && formObject[name] && formObject[name].headerValue
              ? formObject[name].headerValue
              : {}),
            [columnKey]: columnValue
          }
        }
      };
    }

    this.props.downTimeData(formObject, false);
  };

  _getAttributeList = async (equipment) => {
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    let data = await fetch(`${LOGBOOK.AttributesList}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization
      },
      body: JSON.stringify({
        Equipment:
          equipment && equipment.split('-') && equipment.split('-').length > 0
            ? equipment.split('-')[0]
            : ''
      })
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  };

  handleKeyPress = () => {};

  onChange = (date, columnKey, name) => {
    let { formObject } = this.state;

    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        datasource: {
          ...formObject[name].datasource,
          [columnKey]: {
            startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      }
    };
    this.props.downTimeData(formObject, false);
  };

  onDateHeaderMainChange = (date, columnKey, name) => {
    let { formObject } = this.state;
    formObject = {
      ...formObject,
      [name]: {
        ...formObject[name],
        headerValue: {
          ...(formObject && formObject[name] && formObject[name].headerValue
            ? formObject[name].headerValue
            : {}),
          [columnKey]: moment(date).format('YYYY-MM-DD HH:mm:ss')
        }
      }
    };

    this.props.downTimeData(formObject, false);
  };

  onHeaderChange = (date, columnKey, name, validation) => {
    let { formObject } = this.state;
    if (validation && validation === '1h') {
      let dayDifference = moment(date[1]).diff(moment(date[0]), 'days');
      var hourDiff = moment(date[1]).diff(moment(date[0]), 'hour');
      if (dayDifference === 0 && hourDiff <= 1) {
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            headerValue: {
              ...formObject[name].headerValue,
              [columnKey]: {
                startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
              }
            }
          }
        };
        this.props.downTimeData(formObject, false);
      } else {
        message.error('Dates should be less than 1 hour');
        formObject = {
          ...formObject,
          [name]: {
            ...formObject[name],
            headerValue: {
              ...formObject[name].headerValue,
              [columnKey]: {
                startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment(date[0]).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss')
              }
            }
          }
        };
        this.props.downTimeData(formObject, false);
      }
    } else {
      formObject = {
        ...formObject,
        [name]: {
          ...formObject[name],
          headerValue: {
            ...formObject[name].headerValue,
            [columnKey]: {
              startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
              endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss')
            }
          }
        }
      };
      this.props.downTimeData(formObject, false);
    }
  };

  renderSwitch(formObject, columns, name) {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? formObject[name].datasource[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name)}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? formObject[name].datasource[columns.key]
                : undefined
            }
            handleFieldChange={(e) => this.onInputChange(e, columns.key, name)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300, fontWeight: 'bold' }}
            ranges={{
              Today: [moment(), moment()],
              'This Montha': [moment().startOf('month'), moment().endOf('month')]
            }}
            showTime
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? [
                    moment(formObject[name].datasource[columns.key]['startDate'], dateFormat),
                    moment(formObject[name].datasource[columns.key]['endDate'], dateFormat)
                  ]
                : undefined
            }
            format="YYYY-MM-DD HH:mm"
            onChange={(e) => this.onChange(e, columns.key, name)}
          />
        );
      default:
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject[name] &&
              formObject[name].datasource &&
              formObject[name].datasource[columns.key]
                ? formObject[name].datasource[columns.key]
                : ''
            }
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name)}
          />
        );
    }
  }

  renderHeaderSwitch(formObject, columns, name) {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    switch (columns.widget) {
      case 'input':
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : ''
            }
            placeholder={columns.placeHolder}
            onChange={(e) => this.onHeaderInputChange(e.target.value, columns.key, name)}
          />
        );
      case 'select':
        return (
          <SelectComponennt
            column={columns}
            record={{ key: columns }}
            text={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : undefined
            }
            handleFieldChange={(e) => this.onHeaderInputChange(e, columns.key, name)}
            handleKeyPress={this.handleKeyPress}
          />
        );
      case 'date-picker':
        return (
          <DatePicker
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? moment(formObject[name].headerValue[columns.key], 'YYYY-MM-DD HH:mm:ss')
                : undefined
            }
            onChange={(e) => this.onDateHeaderMainChange(e, columns.key, name)}
            style={{ width: 300, fontWeight: 'bold' }}
          />
        );
      case 'dateTimeRange':
        return (
          <RangePicker
            style={{ width: 300, fontWeight: 'bold' }}
            ranges={{
              Today: [moment(), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')]
            }}
            showTime
            format="YYYY-MM-DD HH:mm"
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? [
                    moment(formObject[name].headerValue[columns.key]['startDate'], dateFormat),
                    moment(formObject[name].headerValue[columns.key]['endDate'], dateFormat)
                  ]
                : undefined
            }
            onChange={(e) => this.onHeaderChange(e, columns.key, name, columns.validation)}
          />
        );

      default:
        return (
          <Input
            style={{ width: 300, fontWeight: 'bold' }}
            value={
              formObject[name] &&
              formObject[name].headerValue &&
              formObject[name].headerValue[columns.key]
                ? formObject[name].headerValue[columns.key]
                : ''
            }
            onChange={(e) => this.onInputChange(e.target.value, columns.key, name)}
          />
        );
    }
  }

  render() {
    // let { getFieldDecorator } = this.props.form;
    let { formObject } = this.props;
    return (
      <div
        style={{
          fontWeight: 'bold',
          margin: '20px 0'
        }}
      >
        <Form className="batchUpdateForm1">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              boxSizing: 'border-box'
            }}
          >
            {formObject && formObject['batchAttribute'] && formObject['batchAttribute'].header
              ? formObject['batchAttribute'].header.map((header, index) => {
                  return (
                    <div key={index} style={{ paddingRight: '2em' }}>
                      <Col span={6}>
                        <Form.Item
                          required={false}
                          label={
                            <span style={{ marginLeft: header.marginLeft }}>{header.name}</span>
                          }
                        >
                          {/* {getFieldDecorator(
                            header.key,
                            {}
                          )( */}
                          <div>{this.renderHeaderSwitch(formObject, header, 'batchAttribute')}</div>
                          {/* )} */}
                        </Form.Item>
                      </Col>
                    </div>
                  );
                })
              : null}

            {formObject &&
              Object.keys(formObject).map((name, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box'
                    }}
                  >
                    {formObject && formObject[name] && formObject[name].columns
                      ? formObject[name].columns.map((columns, subindex) => {
                          return (
                            <div key={subindex} style={{ paddingRight: '2em' }}>
                              <Form.Item
                                required={false}
                                label={
                                  <span style={{ marginLeft: columns.marginLeft }}>
                                    {columns.displayName}
                                  </span>
                                }
                              >
                                <div>{this.renderSwitch(formObject, columns, name)}</div>
                              </Form.Item>
                            </div>
                          );
                        })
                      : null}
                  </div>
                );
              })}
          </div>
        </Form>
      </div>
    );
  }
}

export default AttributeDynamic;
