import axios from 'axios';

import localStorage from '../../../../utils/localStorage';
import { API_DEVICE, DATAWATCH } from '../../../../commons/api';

export function _getTopicList() {
  const accessToken = localStorage.get('accessToken');
  const header = {
    url: `${DATAWATCH.TOPLICLIST}`,
    mehod: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        message: 'Error in API'
      };
    });
}
export function _getDeviceList() {
  const siteId = localStorage.get('currentSite');
  const accessToken = localStorage.get('accessToken');
  const header = {
    url: `${API_DEVICE.GET}?SiteId=${siteId}`,
    mehod: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        message: 'Error in API'
      };
    });
}

export function _getGroupList(data) {
  const accessToken = localStorage.get('accessToken');
  const header = {
    url: `${DATAWATCH.GROUPLIST}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        message: 'Error in API'
      };
    });
}

export function _generate(data) {
  const accessToken = localStorage.get('accessToken');
  const header = {
    url: `${DATAWATCH.GETPARAMETR}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data
  };
  return axios(header)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return {
        message: 'Error in API'
      };
    });
}
