import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Select } from 'antd';
import FullscreenOutlined from '@ant-design/icons';

export const Button = styled.button`
  color: ${(props) => props.theme.fg};
  border: 2px solid ${(props) => props.theme.fg};
  background: ${(props) => props.theme.bg};
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border-radius: 3px;
`;
export const darkTheme = {
  fg: '#bfbfbf',
  hg: '#262626',
  hbg: '#8c8c8c',
  bc: '#1a3652',
  bg: '#1a3652',
  shadow: '#000000'
};

export const lightTheme = {
  fg: 'rgba(0,0,0,.85)',
  bc: '#1a3652',
  bg: '#1a3652',
  hg: '#fff',
  hbg: '#d9d9d9',
  shadow: '#8c8c8c'
};
export const FullScreenIcon = styled(FullscreenOutlined)`
  float: right;
  color: ${(props) => props.theme.fg};
  margin-right: 10px;
  margin-top: -30px;
  cursor: pointer;
  font-size: 20px;
`;
export const DashboardDiv = styled.div`
  margin-top: -3px;
  min-height: 93.5vh;
`;
export const Title = styled.h3`
  color: ${(props) => props.theme.fg};
  background: ${(props) => props.theme.hg};
  font-family: Roboto-Medium;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  padding: 0.7em 0em;
  margin: 0px;
`;
export const PanelDiv = styled.div`
  background-color: ${(props) => props.theme.hg} !important;
  box-shadow: 3px 3px 7px ${(props) => props.theme.shadow};
  -moz-box-shadow: 0px 2px 7px ${(props) => props.theme.shadow};
  -webkit-box-shadow: 0px 2px 7px ${(props) => props.theme.shadow};
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
`;

export const DashboardHeaderDiv = styled.div`
  height: 40px;
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
`;
export const StyledDashboard = styled.div`
  background: white;
  margin: 20px;
  padding: 30px;
`;
export const ModalDiv = styled.div`
  background-color: ${(props) => props.theme.hg};

  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
`;
export const DateRangePickerDiv = styled.div`
  margin-left: -20%;
  display: ${(props) => (props.showDateRange === false ? 'none' : 'block')};
`;

export const NoPanelmessage = styled.div`
  text-align: center;
  color: white;
  margin-top: 25%;
  font-size: 20px;
`;

export const StyledIFrame = styled.iframe`
  width: 402%;
  height: 400%;
  border: 0;
  position: absolute;
  top: 0;
  left: -0.5%;
  background: #191a1d;
  -webkit-transform: scale(0.5);
  transform: scale(0.25);
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  margin: 2px auto;
`;

export const LinkOverlay = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  :hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.3);
  }
`;

export const SelectDropDown = styled(Select)`
  width: 100%;
`;
export const CommonDiv = styled.div`
  margin-top: 5px;
`;

export const DateSelection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  margin-right: 20px;
  margintop: '40px';
  color: rgba(0, 0, 0, 0.85) !important;
`;

export const DrawerFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
`;

export const StyledTable = styled.table`
     caption-side: top;
   
   
     color: ${(props) => props.theme.text} !important;
   
     width: 100%;
     /* border-collapse: separate; */
     /* border-spacing: 5px 10px; */
   
     caption-side: bottom;
     /* empty-cell: show | hide;  */
     /* empty-cell is a property of table or the cells themselves */
   
     /* vertical-align: baseline | sub | super | text-top | 
                   text-bottom | middle | top | bottom | 
                   <percentage> | <length> */
   
     /* tbody {
       vertical-align: top;
     }              */
     th{
         background:#13c2c2 !important;
         border: 1px solid #bfbfbf !important;
         text-align:center !important;
         color:#fff !important;
     }
     td
      {lightTheme
         border: 1px solid #bfbfbf !important;
         text-align:center !important;
       color: ${(props) => props.theme.text} !important;
     }
     
   
     td {
       padding: 11px 10px;
       font-size: 1.2em !important;
   
     }
     .ant-table-thead > tr > th{
       font-weight:bold !important
       font-size: 1.2em !important;
       
        // color:red !important
     }
     text[style] {
       font-weight:bold !important
       font-size: 2em !important;
       color: ${(props) => props.theme.text} !important;
    }
     tbody tr {
       :nth-of-type(odd) {
         background-color:${(props) => props.theme.odd} !important;
       }
       :hover {
         background-color:${(props) => props.theme.hover} !important; 
       }
     }
     tbody tr {
       :nth-of-type(even) {
         background-color:${(props) => props.theme.even} !important;
       }
       :hover {
         background-color:${(props) => props.theme.hover} !important; 
       }
     }
     thead > tr {
       color: ${(props) => props.theme.text} !important;
       background-color: #262626 !important;
     }
     caption {
       font-size: 0.9em;
       padding: 5px;
       font-weight: bold;
     }
   `;
