import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Input, Button, message, Select, Modal, Form, Tooltip, Checkbox, Space } from 'antd';

import history from '../../../commons/history';
import localstorage from '../../../utils/localStorage';
import ConfigurationModalContent from './ConfigurationModalContent';
import DashboardLevelConfig from './DashboardLevelConfig';
import { getDashboardByName } from './DashboardCalls';
import { getUserProfile } from '../../../selectors/layout';
import { insertDashboard, updateDashboard } from './DashboardCalls';
import {
  Title,
  DashboardTheme,
  AddPanelBtn,
  PanelDiv,
  DeletePanelDiv,
  EditDashboardDiv,
  AddConfigurationDiv
} from '../CSS/style';

import './CSS/NewDashboard.css';
import 'react-grid-layout/css/styles.css';
import {
  DownloadOutlined,
  PlusCircleOutlined,
  SaveOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';

const Option = Select.Option;

const ResponsiveReactGridLayout = WidthProvider(Responsive);
class NewDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panels: [],
      visible: false,
      editData: [],
      dashboardName: '',
      id: '',
      mode: 'insert',
      dashboardType: 'static',
      DashboardLevelSelection: 'none',
      TimeBucket: 'none',
      treeExists: 'false',
      apiPayload: {},
      treePayload: {},
      userProfile: props && props.userProfile ? props.userProfile : {},
      hierarchyLevels: [],
      GroupName: '',
      isModalOpen: false,
      timeBucketList: [],
      DashboardLevelSelectionPoint: 'PreviousDay',
      inputFields: {
        Day: '',
        Week: '',
        Month: '',
        Year: ''
      },
      TimeBucketKey: true,
      checkedList: false,
      apiPayloadData: {},
      apiPayloadCount: {},
      apiPayloadDeviceSelection: {}
    };
    this.counter = 0;
  }
  componentDidUpdate = (prevProps) => {
    let siteId = localstorage.get('currentSite');
    if (prevProps.userProfile !== this.props.userProfile) {
      this.setState({
        userProfile: this.props.userProfile,
        hierarchyLevels:
          this.props.userProfile && this.props.userProfile.belongsTo.sites[siteId]
            ? this.props.userProfile.belongsTo.sites[siteId].hierarchy
            : []
      });
    }
  };

  componentDidMount = () => {
    let dashboardName = this.props.history.location;
    if (dashboardName && dashboardName.state && dashboardName.state !== 'new') {
      this.getDashboardDataName(dashboardName.state);
    }
  };

  getDashboardDataName = async (dashboardName) => {
    let dashboardObject = await getDashboardByName(dashboardName);
    let dashboardData = dashboardObject && dashboardObject[0] ? dashboardObject[0] : {};
    let headerJSON = dashboardData && dashboardData.headerJSON ? dashboardData.headerJSON : {};

    this.setState({
      mode: 'update',
      dashboardData,
      dashboardName: dashboardData && dashboardData.Name ? dashboardData.Name : '',
      dashboardId: dashboardData && dashboardData.Id ? dashboardData.Id : '',
      dashboardType: headerJSON && headerJSON.dashboardType ? headerJSON.dashboardType : 'static',
      hierarchyViewType:
        headerJSON && headerJSON.hierarchyViewType ? headerJSON.hierarchyViewType : '',
      isMultiLevelDashboard:
        headerJSON && headerJSON.isMultiLevelDashboard ? headerJSON.isMultiLevelDashboard : false,
      multiLevelSettings:
        headerJSON && headerJSON.multiLevelSettings ? headerJSON.multiLevelSettings : [],
      panels: dashboardData && dashboardData.json ? dashboardData.json : [],
      GroupName: dashboardData && dashboardData.GroupName ? dashboardData.GroupName : '',
      DashboardLevelSelection:
        // dashboardData &&
        //   dashboardData.json &&
        //   dashboardData.json[0] &&
        //   dashboardData.json[0].DashboardLevelSelection
        //   ? dashboardData.json[0].DashboardLevelSelection
        headerJSON && headerJSON.DashboardLevelSelection
          ? headerJSON.DashboardLevelSelection
          : 'none',
      TimeBucket:
        // dashboardData &&
        //   dashboardData.json &&
        //   dashboardData.json[0] &&
        //   dashboardData.json[0].TimeBucket
        //   ? dashboardData.json[0].TimeBucket
        headerJSON && headerJSON.TimeBucket ? headerJSON.TimeBucket : 'none',
      DashboardLevelSelectionPoint:
        // dashboardData &&
        //   dashboardData.json &&
        //   dashboardData.json[0] &&
        //   dashboardData.json[0].DashboardLevelSelectionPoint
        //   ? dashboardData.json[0].DashboardLevelSelectionPoint
        headerJSON && headerJSON.DashboardLevelSelectionPoint
          ? headerJSON.DashboardLevelSelectionPoint
          : 'PreviousDay',
      inputFields:
        // dashboardData &&
        //   dashboardData.json &&
        //   dashboardData.json[0] &&
        //   dashboardData.json[0].TimeBucket &&
        //   dashboardData.json[0].DashboardLevelSelection === 'dateMonthYearPicker'
        //   ? dashboardData.json[0].TimeBucket
        headerJSON &&
        headerJSON.TimeBucket &&
        headerJSON.DashboardLevelSelection === 'dateMonthYearPicker'
          ? headerJSON.TimeBucket
          : {},
      treeExists:
        // dashboardData &&
        //   dashboardData.json &&
        //   dashboardData.json[0] &&
        //   dashboardData.json[0].treeExists
        //   ? dashboardData.json[0].treeExists
        headerJSON && headerJSON.treeExists ? headerJSON.treeExists : 'false',

      apiPayload:
        // dashboardData &&
        //   dashboardData.json &&
        //   dashboardData.json[0] &&
        //   dashboardData.json[0].apiPayload
        //   ? dashboardData.json[0].apiPayload
        headerJSON && headerJSON.apiPayload ? headerJSON.apiPayload : {},
      treePayload:
        // dashboardData &&
        //   dashboardData.json &&
        //   dashboardData.json[0] &&
        //   dashboardData.json[0].treePayload
        //   ? dashboardData.json[0].treePayload
        headerJSON && headerJSON.treePayload ? headerJSON.treePayload : {},
      checkedList:
        // dashboardData &&
        //   dashboardData.json &&
        //   dashboardData.json[0] &&
        //   dashboardData.json[0].checkedList
        //   ? dashboardData.json[0].checkedList
        headerJSON && headerJSON.checkedList ? headerJSON.checkedList : false,
      apiPayloadData:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].apiPayloadData
          ? dashboardData.json[0].apiPayloadData
          : {},
      apiPayloadCount:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].apiPayloadCount
          ? dashboardData.json[0].apiPayloadCount
          : {},
      apiPayloadDeviceSelection:
        dashboardData &&
        dashboardData.json &&
        dashboardData.json[0] &&
        dashboardData.json[0].apiPayloadDeviceSelection
          ? dashboardData.json[0].apiPayloadDeviceSelection
          : {}
    });
  };

  showModals = () => {
    this.setState({ isModalOpen: true });
  };
  handleOks = () => {
    this.setState({ isModalOpen: false });
  };
  handleCancels = () => {
    this.setState({
      isModalOpen: false
      // dashboardType: 'static',
      // DashboardLevelSelection: 'none',
      // TimeBucket: 'none',
      // DashboardLevelSelectionPoint: 'PreviousDay',
      // treeExists: 'false'
    });
  };
  enterURL = (e) => {
    let { apiPayload } = this.state;
    this.setState({
      apiPayload: {
        ...apiPayload,
        url: e.target.value,
        method: apiPayload && apiPayload.method ? apiPayload.method : '',
        ...(apiPayload && apiPayload.method === 'POST' ? { payload: apiPayload.payload } : {})
      }
    });
  };

  selectMethod = (value) => {
    let { apiPayload } = this.state;
    this.setState({
      apiPayload: {
        ...apiPayload,
        method: value,
        ...(apiPayload && apiPayload.method === 'POST' ? { payload: apiPayload.payload } : {})
      }
    });
  };

  selectPayload = (e) => {
    let { apiPayload } = this.state;
    this.setState({
      apiPayload: {
        ...apiPayload,
        payload: JSON.parse(e.target.value)
      }
    });
  };

  selectGETParams = (e) => {
    let { apiPayload } = this.state;
    this.setState({
      apiPayload: {
        ...apiPayload,
        GETParams: e.target.value
      }
    });
  };

  enterTreeURL = (e) => {
    let { treePayload } = this.state;
    this.setState({
      treePayload: {
        url: e.target.value,
        method: treePayload && treePayload.method ? treePayload.method : '',
        ...(treePayload && treePayload.method === 'POST' ? { payload: treePayload.payload } : {})
      }
    });
  };

  selectTreeMethod = (value) => {
    let { treePayload } = this.state;
    this.setState({
      treePayload: {
        url: treePayload && treePayload.url ? treePayload.url : '',
        method: value,
        ...(treePayload && treePayload.method === 'POST' ? { payload: treePayload.payload } : {})
      }
    });
  };

  selectTreePayload = (e) => {
    let { treePayload } = this.state;
    this.setState({
      treePayload: {
        url: treePayload && treePayload.url ? treePayload.url : '',
        method: treePayload && treePayload.method ? treePayload.method : 'GET',
        payload: JSON.parse(e.target.value)
      }
    });
  };

  handleRemoveFields = (keyName) => {
    const { inputFields } = this.state;
    delete inputFields[keyName];
    // values.splice(index, 1);
    this.setState(inputFields);
  };
  selectTreeGETPayload = (e) => {
    let { treePayload } = this.state;
    this.setState({
      treePayload: {
        ...treePayload,
        GETParams: e.target.value
      }
    });
  };
  handleInputChange = (event, item) => {
    const { inputFields } = this.state;
    inputFields[item] = event.target.value;
    this.setState({ inputFields });
  };

  resetTimeBucket = () => {
    let { inputFields } = this.state;
    let TimeBucket = {
      Day: '',
      Week: '',
      Month: '',
      Year: '',
      ...inputFields
    };
    this.setState({ inputFields: TimeBucket });
  };
  render() {
    let {
      dashboardName,
      dashboardType,
      panels,
      apiPayload,
      DashboardLevelSelection,
      TimeBucket,
      DashboardLevelSelectionPoint,
      treePayload,
      treeExists,
      GroupName,
      hierarchyViewType,
      isMultiLevelDashboard,
      multiLevelSettings,
      inputFields,
      TimeBucketKey
    } = this.state;
    return (
      <EditDashboardDiv>
        <div style={{ padding: '10px', marginTop: '64px' }}>
          <Button
            style={{ float: 'right' }}
            type="danger"
            shape="round"
            onClick={this.saveDashboard}
          >
            <SaveOutlined />
            Save Dashboard
          </Button>
          <Input
            style={{ width: '150px', float: 'right' }}
            type="text"
            value={dashboardName}
            placeholder="Enter Dashboard Name"
            onChange={(e) => {
              this.setState({ dashboardName: e.target.value });
            }}
          />
          <Input
            style={{ width: '150px', float: 'right' }}
            type="text"
            value={GroupName}
            placeholder="Enter Group Name"
            onChange={(e) => {
              this.setState({ GroupName: e.target.value });
            }}
          />
          <AddPanelBtn type="danger" shape="round" onClick={this.addPanel}>
            <PlusCircleOutlined />
            Add Panel
          </AddPanelBtn>

          <div style={{ marginLeft: 20, float: 'left' }}>
            <Button type="danger" shape="round" onClick={this.showModals}>
              <PlusCircleOutlined />
              Headers
            </Button>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ color: 'white' }}>New dashboard creation</h3>
          </div>
        </div>
        <Modal
          title="Header Selection"
          bodyStyle={{ height: 700 }}
          width={1000}
          open={this.state.isModalOpen}
          okText="Update"
          cancelText="Close"
          onOk={this.handleOks}
          onCancel={this.handleCancels}
        >
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 8 }}>
            <Form.Item label="DashboardType">
              <DashboardTheme
                onChange={(e) => {
                  this.setState({
                    dashboardType: e,
                    apiPayload: e && e === 'static' ? {} : apiPayload
                  });
                }}
                value={dashboardType}
              >
                <Option value="static">Static</Option>
                <Option value="dynamic">Dynamic</Option>
                <Option value="hierarchy">Hierarchy</Option>
              </DashboardTheme>
              {this.state.dashboardType === 'hierarchy' ? (
                <Select
                  style={{ width: '200px' }}
                  value={hierarchyViewType ? hierarchyViewType : ''}
                  placeholder={'Select Hierarchy ViewType'}
                  onChange={(e) => {
                    this.setState({ hierarchyViewType: e });
                  }}
                >
                  <Option value="Tree">Tree</Option>
                  <Option value="Select">Select</Option>
                </Select>
              ) : null}
              {this.state.dashboardType === 'dynamic' ||
              this.state.dashboardType === 'hierarchy' ? (
                <div>
                  <Select
                    style={{ width: '200px' }}
                    value={apiPayload && apiPayload.method ? apiPayload.method : undefined}
                    placeholder={'Select Method'}
                    onChange={this.selectMethod}
                  >
                    <Option value="GET">GET</Option>
                    <Option value="POST">POST</Option>
                  </Select>
                  <Input
                    style={{ width: '300px' }}
                    value={apiPayload && apiPayload.url ? apiPayload.url : ''}
                    placeholder="Enter URL"
                    onChange={this.enterURL}
                  />
                  {apiPayload && apiPayload.method && apiPayload.method === 'POST' ? (
                    <Input.TextArea
                      style={{ width: '300px' }}
                      defaultValue={
                        apiPayload &&
                        apiPayload.payload &&
                        Object.keys(apiPayload.payload) &&
                        Object.keys(apiPayload.payload).length > 0 &&
                        JSON.stringify(apiPayload.payload)
                          ? JSON.stringify(apiPayload.payload)
                          : ''
                      }
                      placeholder="Enter payload"
                      onBlur={this.selectPayload}
                    />
                  ) : null}
                  {apiPayload && apiPayload.method && apiPayload.method === 'GET' ? (
                    <Input.TextArea
                      style={{ width: '300px' }}
                      defaultValue={apiPayload && apiPayload.GETParams ? apiPayload.GETParams : ''}
                      placeholder="Enter GETParams"
                      onBlur={this.selectGETParams}
                    />
                  ) : null}
                </div>
              ) : null}
              {this.state.dashboardType === 'hierarchy' ? (
                <Select
                  style={{ width: '200px' }}
                  value={isMultiLevelDashboard ? isMultiLevelDashboard : ''}
                  placeholder={'Select Is MultiLevel Dashboard ?'}
                  onChange={(e) => {
                    this.setState({ isMultiLevelDashboard: e });
                  }}
                >
                  <Option value={'true'}>true</Option>
                  <Option value={'false'}>false</Option>
                </Select>
              ) : null}
              {isMultiLevelDashboard === 'true' ? (
                <>
                  <Form
                    name="dynamic_form_nest_item"
                    initialValues={{
                      multiLevelSettings: multiLevelSettings
                    }}
                    onFinish={(values) => {
                      this.setState({ multiLevelSettings: values.multiLevelSettings });
                    }}
                    autoComplete="off"
                  >
                    <Form.List name="multiLevelSettings">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{ display: 'flex', marginBottom: 0 }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, 'LevelType']}
                                rules={[{ required: true, message: 'Missing Level Type' }]}
                              >
                                <Input placeholder="Level Type" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, 'DashboardName']}
                                rules={[{ required: true, message: 'Missing Dashboard Name' }]}
                              >
                                <Input placeholder="Dashboard Name" />
                              </Form.Item>
                              <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add field
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Update configuration
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              ) : null}
            </Form.Item>

            {this.state.dashboardType === 'dynamic' && apiPayload && apiPayload.url ? (
              <Form.Item label="Enable All Options">
                <Checkbox
                  checked={this.state.checkedList}
                  onChange={(e) => {
                    this.setState({ checkedList: e.target.checked });
                  }}
                >
                  {this.state.checkedList ? 'True' : 'False'}
                </Checkbox>
              </Form.Item>
            ) : null}

            <Form.Item label="Tree Selection">
              <DashboardTheme
                onChange={(e) => {
                  this.setState({ treeExists: e, treePayload: {} });
                }}
                value={treeExists}
              >
                <Option value="true">true</Option>
                <Option value="false">false</Option>
              </DashboardTheme>
              {treeExists === 'true' ? (
                <div>
                  <Select
                    style={{ width: '200px' }}
                    value={treePayload && treePayload.method ? treePayload.method : undefined}
                    placeholder={'Select Method'}
                    onChange={this.selectTreeMethod}
                  >
                    <Option value="GET">GET</Option>
                    <Option value="POST">POST</Option>
                  </Select>
                  <Input
                    style={{ width: '300px' }}
                    value={treePayload && treePayload.url ? treePayload.url : ''}
                    placeholder="Enter URL"
                    onChange={this.enterTreeURL}
                  />
                  {treePayload && treePayload.method && treePayload.method === 'POST' ? (
                    <Input.TextArea
                      style={{ width: '300px' }}
                      defaultValue={
                        treePayload &&
                        treePayload.payload &&
                        Object.keys(treePayload.payload) &&
                        Object.keys(treePayload.payload).length > 0 &&
                        JSON.stringify(treePayload.payload)
                          ? JSON.stringify(treePayload.payload)
                          : ''
                      }
                      placeholder="Enter payload"
                      onBlur={this.selectTreePayload}
                    />
                  ) : null}

                  {treePayload && treePayload.method && treePayload.method === 'GET' ? (
                    <Input.TextArea
                      style={{ width: '300px' }}
                      defaultValue={
                        treePayload && treePayload.GETParams ? treePayload.GETParams : ''
                      }
                      placeholder="Enter GETParams"
                      onBlur={this.selectTreeGETPayload}
                    />
                  ) : null}
                </div>
              ) : null}
            </Form.Item>

            <Form.Item label="Selection Type">
              <DashboardTheme
                onChange={(value) => {
                  let TimeBucket = 'none';
                  if (value === 'datePicker' || value === 'dateRangePicker') {
                    TimeBucket = '1h';
                    this.setState({ TimeBucketKey: false });
                  } else if (
                    value === 'monthPicker' ||
                    value === 'weakPicker' ||
                    value === 'shiftPicker'
                  ) {
                    TimeBucket = '1d';
                    this.setState({ TimeBucketKey: false });
                  } else if (value === 'none') {
                    this.setState({ TimeBucketKey: true });
                    TimeBucket = '';
                  } else {
                    TimeBucket = '';
                    this.setState({ TimeBucketKey: true });
                  }
                  if (value === 'dateMonthYearPicker') {
                    let inputFields = {
                      Day: '',
                      Week: '',
                      Month: '',
                      Year: ''
                    };
                    this.setState({
                      inputFields,
                      DashboardLevelSelection: value,
                      TimeBucket: inputFields
                    });
                  } else {
                    this.setState({ DashboardLevelSelection: value, TimeBucket });
                  }
                }}
                value={DashboardLevelSelection}
              >
                <Option value="datePicker">Date Selection</Option>
                <Option value="dateRangePicker">DateRange Selection</Option>
                <Option value="monthPicker">Month Selection</Option>
                <Option value="weakPicker">Weak Selection</Option>
                <Option value="dateMonthYearPicker">DayMonthYear Selection</Option>
                <Option value="shiftPicker">Shift Selection</Option>
                <Option value="none">None</Option>
              </DashboardTheme>
            </Form.Item>

            {(DashboardLevelSelection && DashboardLevelSelection === 'none') ||
            DashboardLevelSelection !== 'dateMonthYearPicker' ? (
              <Form.Item label="Time Bucket">
                <DashboardTheme
                  onChange={(e) => {
                    this.setState({ TimeBucket: e });
                  }}
                  value={TimeBucket}
                  disabled={TimeBucketKey}
                >
                  <option value="1m">1 Minute (1m)</option>
                  <option value="1h">1 Hour (1h)</option>
                  <option value="1d">1 Day (1d)</option>
                  <option value="1week">1 Week (1week)</option>
                  <option value="1month">1 Month (1month)</option>
                  <option value="1y">1 Year (1y)</option>
                  <option value="none">None</option>
                </DashboardTheme>
              </Form.Item>
            ) : null}

            {DashboardLevelSelection && DashboardLevelSelection === 'dateMonthYearPicker' ? (
              <div>
                {inputFields &&
                  Object.keys(inputFields) &&
                  Object.keys(inputFields).length > 0 &&
                  Object.keys(inputFields).map((item, index) => (
                    <div key={item}>
                      <Form.Item label={item}>
                        <input
                          style={{ width: '150px' }}
                          type="text"
                          id="Value"
                          value={inputFields[item]}
                          onChange={(e) => this.handleInputChange(e, item)}
                        />
                        <MinusCircleOutlined
                          style={{ color: 'red', marginLeft: '20px' }}
                          onClick={() => this.handleRemoveFields(item)}
                        />
                        {index === 0 ? (
                          <Tooltip title={'Add Other Selections'}>
                            <PlusOutlined
                              style={{ color: 'green', marginLeft: '20px' }}
                              onClick={this.resetTimeBucket}
                            />
                          </Tooltip>
                        ) : null}
                      </Form.Item>
                    </div>
                  ))}
              </div>
            ) : null}

            {DashboardLevelSelection && DashboardLevelSelection !== 'none' ? (
              <Form.Item label="Selection EndPoint">
                <DashboardTheme
                  onChange={(e) => {
                    this.setState({ DashboardLevelSelectionPoint: e });
                  }}
                  value={DashboardLevelSelectionPoint}
                >
                  <option value="PreviousDay">Previous Day</option>
                  <option value="CurrentDay">Current Day</option>
                </DashboardTheme>
              </Form.Item>
            ) : null}
          </Form>
        </Modal>

        <div>{panels && Array.isArray(panels) && panels.length > 0 ? this.createPanels() : ''}</div>
        <ConfigurationModalContent
          DeviceData={this.props.DeviceData}
          AssetData={this.props.AssetData}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          editData={this.state.editData}
        />
        <DashboardLevelConfig />
      </EditDashboardDiv>
    );
  }

  handleChangeFile = (e) => {
    let context = this;
    var reader = new FileReader();
    reader.readAsText(e[0]);
    reader.onloadend = function () {
      let fileData = JSON.parse(reader.result);
      context.setState({ panels: fileData.json });
    };
  };

  setTitle = (e) => {
    this.setState({ title: e.target.value, id: this.state.id });
  };

  redirect() {
    let { dashboardName } = this.state;
    this.props.history.push(
      dashboardName.length === 0 ? '/dashboard/' : '/dashboard/' + dashboardName
    );
  }

  addPanel = () => {
    this.counter = this.state.panels.length + 1;
    this.state.panels.push({
      id: this.counter,
      title: 'new panel',
      graph: 'guage',
      source: 'mqtt',
      query: '',
      query1: '',
      query2: '',
      position: { x: 0, y: 0, w: 4, h: 6 }
    });

    this.setState({ panels: [...this.state.panels] });
  };

  createPanels = () => {
    return (
      <ResponsiveReactGridLayout
        className="layout"
        draggableHandle=".headerdrag"
        //key={new Date().getTime()}
        onResizeStop={(data) => this.updatePosition(data)}
        onDragStop={(data) => {
          this.updatePosition(data);
        }}
        rowHeight={30}
      >
        {this.getPanels()}
      </ResponsiveReactGridLayout>
    );
  };

  updatePosition = (data) => {
    this.state.panels.map((v, i) => {
      data[i]['id'] = v.id;
      return {};
    });
    data.map((value1) => {
      this.state.panels.map((value2) => {
        if (value1.id === value2.id) {
          value2.position.x = value1.x;
          value2.position.y = value1.y;
          value2.position.w = value1.w;
          value2.position.h = value1.h;
        }
        return {};
      });
      return {};
    });
    this.setState({ panels: [...this.state.panels] });
  };

  getPanels = () => {
    let { panels } = this.state;
    let data =
      panels &&
      Array.isArray(panels) &&
      panels.map((value, index) => {
        const { x, y, w, h } = value && value.position;
        return (
          <PanelDiv
            key={index}
            data-grid={{
              x,
              y,
              w,
              h,
              isResizable: true
            }}
          >
            <div className="headerdrag">
              <Title>{value.title}</Title>
            </div>

            <AddConfigurationDiv>
              <Button type="primary" shape="round" onClick={() => this.editConfiguration(value)}>
                <PlusCircleOutlined />
                Edit Configuration
              </Button>
            </AddConfigurationDiv>
            <DeletePanelDiv>
              <Button type="danger" shape="round" onClick={() => this.deletePanel(value.id)}>
                <DownloadOutlined />
                Delete Panel
              </Button>
            </DeletePanelDiv>
          </PanelDiv>
        );
      });

    return data;
  };

  deletePanel = (id) => {
    this.state.panels.map((value, index) => {
      if (id === value.id) {
        this.state.panels.splice(index, 1);
      }
      return {};
    });
    let panels = this.state.panels.map((value, index) => {
      value.id = index + 1;
      return value;
    });

    this.setState({
      panels: [...panels],
      id: ''
    });
  };

  editConfiguration(value) {
    let { panels } = this.state;
    let editdata =
      panels &&
      Array.isArray(panels) &&
      panels.filter((filterData) => {
        return value.id === filterData.id;
      });
    this.setState({ id: value.id, panels });
    this.showModal(editdata);
  }

  showModal = (value) => {
    this.setState({
      visible: true,
      editData: [...value]
    });
  };

  handleOk = (config) => {
    let { panels, id, apiPayloadCount, apiPayloadData, apiPayloadDeviceSelection } = this.state;
    apiPayloadCount = {
      method: config && config.apiPayloadCount ? config.apiPayloadCount.method : '',
      url: config && config.apiPayloadCount ? config.apiPayloadCount.url : '',
      payload: config && config.apiPayloadCount ? config.apiPayloadCount.payload : {},
      GETParams: config && config.apiPayloadCount ? config.apiPayloadCount.GETParams : ''
    };
    apiPayloadData = {
      method: config && config.apiPayloadData ? config.apiPayloadData.method : '',
      url: config && config.apiPayloadData ? config.apiPayloadData.url : '',
      payload: config && config.apiPayloadData ? config.apiPayloadData.payload : {},
      GETParams: config && config.apiPayloadData ? config.apiPayloadData.GETParams : ''
    };
    apiPayloadDeviceSelection = {
      method:
        config && config.apiPayloadDeviceSelection ? config.apiPayloadDeviceSelection.method : '',
      url: config && config.apiPayloadDeviceSelection ? config.apiPayloadDeviceSelection.url : '',
      payload:
        config && config.apiPayloadDeviceSelection ? config.apiPayloadDeviceSelection.payload : {},
      GETParams:
        config && config.apiPayloadDeviceSelection ? config.apiPayloadDeviceSelection.GETParams : ''
    };
    try {
      let panelObject = {};
      let panelData = panels.map((value) => {
        if (value.id === id) {
          panelObject = {
            ...value,
            ...config,
            GraphLevelTimeBucket:
              value.GraphLevelSelection === 'dateMonthYearPicker'
                ? value.inputGraphFields
                : config.GraphLevelTimeBucket,
            ...apiPayloadCount,
            ...apiPayloadData,
            ...apiPayloadDeviceSelection
          };
          return panelObject;
        } else {
          return value;
        }
      });
      this.setState({
        visible: false,
        panels: panelData,
        id: '',
        apiPayloadCount,
        apiPayloadData,
        apiPayloadDeviceSelection
      });
    } catch (e) {
      message.error('Configuration Error!');
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  saveDashboard = async () => {
    let {
      dashboardName,
      dashboardType,
      treeExists,
      panels,
      apiPayload,
      treePayload,
      GroupName,
      hierarchyViewType,
      isMultiLevelDashboard,
      multiLevelSettings,
      DashboardLevelSelection,
      TimeBucket,
      inputFields,
      DashboardLevelSelectionPoint,
      checkedList,
      apiPayloadCount,
      apiPayloadData,
      apiPayloadDeviceSelection
    } = this.state;
    if (dashboardName.trim().length !== 0) {
      panels.map((value) => {
        if (value.queryData && Array.isArray(value.queryData) && value.queryData.length === 0) {
          delete value.query1;
        }
        value.dashboardName = dashboardName;

        value.apiPayloadData = apiPayloadData;
        value.apiPayloadCount = apiPayloadCount;
        value.apiPayloadDeviceSelection = apiPayloadDeviceSelection;
        return {};
      });
      let headerJSON = {
        treeExists,
        DashboardLevelSelection,
        DashboardLevelSelectionPoint,
        TimeBucket:
          DashboardLevelSelection && DashboardLevelSelection === 'dateMonthYearPicker'
            ? inputFields
            : TimeBucket,
        dashboardType,
        hierarchyViewType,
        isMultiLevelDashboard,
        multiLevelSettings,
        apiPayload,
        treePayload,
        checkedList
        // multiLevelSettings: [{
        //   LevelType: "Module",
        //   DashboardName: "Global",
        //   DashboardType: "dynamic"
        // },
        // {
        //   LevelType: "Asset",
        //   DashboardName: "Solar Overview",
        //   DashboardType: "dynamic"
        // }
        // ]
      };
      if (this.state.mode === 'insert') {
        let msg = await insertDashboard(
          panels,
          dashboardName,
          this.state.dashboardId,
          GroupName,
          headerJSON
        );
        message.info(msg);
        history.push('/rubus/dashboard');
        this.setState({
          panels: [],
          visible: false,
          editData: [],
          dashboardName: '',
          mode: this.state.mode,
          id: '',
          apiPayload: {},
          hierarchyViewType: '',
          isMultiLevelDashboard: false,
          multiLevelSettings: [],
          treePayload: {},
          apiPayloadData: {},
          apiPayloadCount: {},
          headerJSON: {},
          apiPayloadDeviceSelection: {}
        });
      } else {
        let msg = await updateDashboard(
          panels,
          dashboardName,
          this.state.dashboardId,
          GroupName,
          headerJSON
        );
        message.success(msg);
        history.push('/rubus/dashboard');
        this.setState({
          panels: [],
          visible: false,
          // editData: [],
          dashboardName: '',
          mode: this.state.mode,
          id: '',
          apiPayload: {},
          hierarchyViewType: '',
          isMultiLevelDashboard: false,
          multiLevelSettings: [],
          treePayload: {},
          apiPayloadData: {},
          apiPayloadCount: {},
          headerJSON: {},
          apiPayloadDeviceSelection: {}
        });
      }
    } else {
      alert('Enter Dashboard Name');
    }
  };
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile()
});

export default connect(mapStateToProps)(NewDashboard);
