import { fromJS } from 'immutable';

import { types } from './types';
import localStorage from '../../utils/localStorage';

export const userLoginAuth = (credentials) => {
  return {
    type: types.USERLOGIN_AUTH,
    credentials
  };
};

export const signUpAuth = (payload) => {
  return {
    type: types.USERSIGNUP_AUTH,
    payload
  };
};

export const userLoginAuthSuccess = (response) => {
  return {
    type: types.USERLOGIN_AUTH_SUCCESS,
    response
  };
};

export const userCurrentMenu = (menuCode) => {
  return {
    type: types.USER_CURRENT_MENU,
    menuCode
  };
};

const initialState = fromJS({
  status: false,
  token: '',
  userProfile: {},
  userCurrentMenu: ''
});

const login = (state = initialState, action) => {
  switch (action.type) {
    case types.USERLOGIN_AUTH_SUCCESS: {
      localStorage.set('expiresIn', action.response.ExpiresIn);
      localStorage.set('accessToken', action.response.AccessToken);
      localStorage.set('currentSite', action.response.SiteId);
      return state.set('accessToken', action.response.AccessToken);
    }
    case types.USER_CURRENT_MENU: {
      return state.set('userCurrentMenu', action.menuCode);
    }
    default:
      return state;
  }
};

export default login;
