import React from 'react';
import axios from 'axios';
import { Row, Col, Avatar, Select, Form, Button, Tooltip, Divider, Typography } from 'antd';
import localStorage from '../../../../../utils/localStorage';
import history from '../../../Common/DefaultLandingPage/CentralDashboard';
import damaged from './images/crane2.png';
import idle from './images/crane1.png';
import stopped from './images/crane3.png';
import running from './images/crane4.png';

import './landingpage.css';

const colStyle = {
    color: 'white',
    //border: '2px solid #333333',
    margin: '5px',
    padding: '5px',
    float: 'left',
    display: 'block',
    borderRadius: '10px',
    width: '6%',
    boxShadow: '5px 5px 30px #333333',
};

const titleStyle = {
    color: 'black',
    fontSize: '1em',
    fontWeight: 'bold',
    textAlign: 'center',
    // padding:'5px',
    borderBottom: '2px dotted black',
    marginBottom: '10px',
};
const avatarStyle = {
    height: '50px',
    width: '50px',
};
const statusStyle = {
    fontSize: '1em',
    paddingLeft: '5px',
    color: 'black',
    fontWeight: 'bold',
};

class CentralDashboardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            allCategory: [],
            crane: {},
            deviceType: 'dynamic',
            deviceList: {},
            deviceTPH: {},
            deviceArray: [],
            berthMaster: [],
            berthStatusMaster: [],
            berth: '',
            listTph: [],
            sumArray: [],
            activeCrane: [],
            landingPage: '',
            activeData: [],
            formObject: {},
            selectedData:'',
        };
    }
    formRef = React.createRef();
    componentWillMount() {
        // this._getdeviceList();
        // this._getTphList();
        // this._getBerthMasterList();
        let landingPage = localStorage.get('landingPage');
        this.setState({
            landingPage: landingPage,
        });
    }

    timeTicket = null;
    signal = axios.CancelToken.source();
    requestDeviceCheck = true
    requestTPHCheck = true

    componentDidMount() {
        if (this.timeTicket) {
            clearInterval(this.timeTicket);
        }

        this._getBerthMasterList();
        this._getdeviceList();
        this._getTphList();

        this.timeTicket = setInterval(() => {
            if (this.requestDeviceCheck) { // Request Pending status check for Device Stauts
                this._getdeviceList();
            }
            if (this.requestTPHCheck) { // Request Pending status check for TPH
                this._getTphList();
            }

        }, 1 * 10000);

    }

    componentWillUnmount() {
        this.signal.cancel('Api is being canceled');
        clearInterval(this.timeTicket);
    }

    saveTPH = () => {    
        let { berthStatusMaster, deviceTPH,crane } = this.state;
        let formObject = this.formRef.current.getFieldsValue()
            if (formObject) {
                let total = 0;
                formObject.crane.map((selectedDeviceList) => {
                    if (deviceTPH[selectedDeviceList] && deviceTPH[selectedDeviceList]) {
                        total = total + deviceTPH[selectedDeviceList];
                    }
                    return total;
                });
                berthStatusMaster = {
                    ...berthStatusMaster,
                    [formObject.Berth]: {
                        berthName:formObject.Berth,
                        devices: crane.Devices,
                        tphValueSum: total.toFixed(2),
                    },
                };
                this.setState({
                    berthStatusMaster
                });
                if (this.formRef.current) this.formRef.current.resetFields();
            }    
    };

    clearBerth = () => {
        let { berthStatusMaster } = this.state;
        berthStatusMaster && Object.keys(berthStatusMaster).map((berthCode) => {
            berthStatusMaster[berthCode] = {
              ...berthStatusMaster[berthCode],
              devices: [],
              tphValueSum: 0
            }
            return {}
          })      
       
        this.setState({
            berthStatusMaster
        })
        if (this.formRef.current) this.formRef.current.resetFields();
    }

    _getdeviceList = () => {
        let data = [{
            "Gottwald-MHC": [
                {
                    "deviceName": "MHC-16",
                    "deviceCode": "MHC-16",
                    "status": "stop"
                },
                {
                    "deviceName": "MHC-17",
                    "deviceCode": "MHC-17",
                    "status": "stop"
                },
                {
                    "deviceName": "MHC-20",
                    "deviceCode": "MHC-20",
                    "status": "running"
                },
                {
                    "deviceName": "MHC-21",
                    "deviceCode": "MHC-21",
                    "status": "idle"
                },
                {
                    "deviceName": "MHC-22",
                    "deviceCode": "MHC-22",
                    "status": "stop"
                },
                {
                    "deviceName": "MHC-23",
                    "deviceCode": "MHC-23",
                    "status": "stop"
                },
                {
                    "deviceName": "MHC-24",
                    "deviceCode": "MHC-24",
                    "status": "stop"
                },
                {
                    "deviceName": "MHC-25",
                    "deviceCode": "MHC-25",
                    "status": "stop"
                },
                {
                    "deviceName": "MHC-26",
                    "deviceCode": "MHC-26",
                    "status": "stop"
                },
                {
                    "deviceName": "MHC-27",
                    "deviceCode": "MHC-27",
                    "status": "stop"
                },
                {
                    "deviceName": "MHC-28",
                    "deviceCode": "MHC-28",
                    "status": "idle"
                },
                {
                    "deviceName": "MHC-29",
                    "deviceCode": "MHC-29",
                    "status": "running"
                },
                {
                    "deviceName": "MHC-30",
                    "deviceCode": "MHC-30",
                    "status": "idle"
                },
                {
                    "deviceName": "MHC-31",
                    "deviceCode": "MHC-31",
                    "status": "stop"
                },
                {
                    "deviceName": "MHC-32",
                    "deviceCode": "MHC-32",
                    "status": "running"
                },
                {
                    "deviceName": "MHC-33",
                    "deviceCode": "MHC-33",
                    "status": "running"
                },
                {
                    "deviceName": "MHC-34",
                    "deviceCode": "MHC-34",
                    "status": "stop"
                },
                {
                    "deviceName": "MHC-35",
                    "deviceCode": "MHC-35",
                    "status": "idle"
                },
                {
                    "deviceName": "MHC-36",
                    "deviceCode": "MHC-36",
                    "status": "running"
                },
                {
                    "deviceName": "MHC-37",
                    "deviceCode": "MHC-37",
                    "status": "running"
                },
                {
                    "deviceName": "MHC-38",
                    "deviceCode": "MHC-38",
                    "status": "running"
                },
                {
                    "deviceName": "MHC-39",
                    "deviceCode": "MHC-39",
                    "status": "idle"
                }
            ]
        }];
        data.map((crane) => {
            if (crane && Array.isArray(crane['Gottwald-MHC'])) {
                let craneList = crane['Gottwald-MHC'];
                let activeCrane = craneList.map((crane) => {
                    if (crane && (crane.status === 'running' || crane.status === 'idle')) {
                        return crane.deviceCode;
                    }
                    return crane;
                });
                activeCrane = activeCrane.filter(function (element) {
                    return element !== undefined;
                });
                this.setState({ deviceList: [...craneList], activeCrane });
                this.requestDeviceCheck = true
            } else {
                this.setState({ deviceList: [] });
                this.requestDeviceCheck = true
            }
            return data;
        })

    }

    _getBerthMasterList = () => {
        let data1 = {
            "Berth 5": "b5",
            "Berth 6": "b6",
            "Berth 7": "b7",
            "Berth 8": "b8",
            "Berth 9": "b9",
            "Berth 10": "b10",
            "Berth 11": "b11",
            "Berth 12": "b12",
            "Berth 14": "b14",
            "Berth 15": "b15",
            "Berth 16": "b16"
        };
        let berthStatusMaster = {};
        Object.keys(data1).map((berth) => {
            berthStatusMaster[berth] = {
                berthName: berth,
                devices: [],
                tphValueSum: 0,
            };
            return berthStatusMaster;
        });
        this.setState({ berthMaster: data1, berthStatusMaster });
    };

    _getTphList = () => {
        let data2 = {
            "MHC-16": 0,
            "MHC-17": 0,
            "MHC-20": 25.7,
            "MHC-21": 19,
            "MHC-22": 0,
            "MHC-23": 0,
            "MHC-24": 0,
            "MHC-25": 0,
            "MHC-26": 0,
            "MHC-27": 0,
            "MHC-28": 36.2,
            "MHC-29": 26.9,
            "MHC-30": 33.3,
            "MHC-31": 0,
            "MHC-32": 27,
            "MHC-33": 28.9,
            "MHC-34": 0,
            "MHC-35": 0,
            "MHC-36": 34.2,
            "MHC-37": 18.5,
            "MHC-38": 26.9,
            "MHC-39": 29.6
        };

        this.setState({ deviceTPH: data2 })
        // this.updateBerthCalculation(this.state.berthStatusMaster, data2)
    };

    onCraneImageClick = craneObject => {
        history.push(`/rubus/singleHMI/dynamic/rakCrane/${craneObject.deviceCode}`);
    };

    onCraneSelection = value => {
        history.push(
            `/rubus/singleHMI/dynamic/rakCrane/${this.state.deviceType}/${value.key}`
        );
    };

    updateBerthCalculation = (berthStatusMaster, deviceTPH) => {
        Object.keys(berthStatusMaster).map((berthName) => {
            if (berthStatusMaster
                && berthStatusMaster[berthName]
                && berthStatusMaster[berthName].devices
                && Array.isArray(berthStatusMaster[berthName].devices)
                && berthStatusMaster[berthName].devices.length > 0
            ) {
                let total = 0;
                berthStatusMaster[berthName].devices.map(selectedDeviceList => {
                    if (deviceTPH[selectedDeviceList]) {
                        total = total + deviceTPH[selectedDeviceList];
                    }
                    return total;
                });
                berthStatusMaster = {
                    ...berthStatusMaster,
                    [berthName]: {
                        berthName,
                        devices: berthStatusMaster[berthName].devices,
                        tphValueSum: total.toFixed(2),
                    },
                };

            }
            return {};
        })

        this.setState({
            berthStatusMaster,
        });
    }


    render() {
        let {
            deviceList,
            deviceTPH,
            berthMaster,
            berthStatusMaster,
            activeCrane
        }
            = this.state;
        const { Option } = Select;
        return (
            <div>
                <div style={{ marginBottom: '5px', marginLeft: '4%' }}></div>
                <Row
                    style={{
                        padding: '10px',
                        marginBottom: '10px',
                        backgroundColor: 'rgb(195, 200, 206)',
                    }}
                >
                    <Col span={3} idleset={2}></Col>
                    <Col span={5}>
                        <Avatar shape="square" src={running} style={avatarStyle} />
                        <span style={statusStyle}>RUNNING</span>
                    </Col>
                    <Col span={5}>
                        <Avatar shape="square" src={idle} style={avatarStyle} />
                        <span style={statusStyle}>IDLE</span>
                    </Col>
                    <Col span={5}>
                        <Avatar shape="square" src={stopped} style={avatarStyle} />
                        <span style={statusStyle}>STOPPED</span>
                    </Col>
                    <Col span={5}>
                        <Avatar shape="square" src={damaged} style={avatarStyle} />
                        <span style={statusStyle}>ALERTS</span>
                    </Col>
                </Row>
                <div>
                    <Row
                        style={{ marginBottom: '10px', backgroundColor: '#b3b3b3' }}
                    >
                        <Typography.Title level={3} >
                            MHC {''}
                        </Typography.Title>
                        {deviceList &&
                            Array.isArray(deviceList) &&
                            deviceList.map((key, index) => {
                                return (
                                    <Col
                                        style={colStyle}
                                        className="colRunning"
                                        onClick={() => this.onCraneImageClick(key)}
                                        //span={1}
                                        width={"7%"}
                                    >
                                        <div style={titleStyle}>{key.deviceName} </div>
                                        <div className={key.status}></div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
                <div>
                    <Row
                        style={{
                            padding: '10px',
                            marginBottom: '10px',
                            backgroundColor: '#bfbfbf',
                        }}
                    >
                        <Typography.Title level={3}>Live TPC Analysis</Typography.Title>
                        {Object.entries(deviceTPH).map(([key, value]) => {
                            if (activeCrane.indexOf(key) > -1) {
                                return (
                                    <Col className="tphBox" span={1}>
                                        <div className="tphhead">{key} </div>
                                        <div>{value}</div>
                                    </Col>
                                );
                            } else {
                                return (
                                    <Col className="tphBox" span={1}>
                                        <div className="tphhead">{key} </div>
                                        <div>-</div>
                                    </Col>
                                );
                            }
                        })}

                        <Col offset={3} span={15} style={{ marginTop: '10px' }}>
                            <Divider />
                            <Form layout="inline"
                            ref={this.formRef}
                            onFinish={this.saveTPH}
                            >
                                <Form.Item name='Berth' label="Select Berth :" style={{ marginLeft: "9px" }}
                                    rules={[{ required: true, message: 'Please select Berth!' }]}
                                >
                                    {

                                        <Select style={{ width: 200 }}
                                            placeholder="Select Berth"
                                        >
                                            {Object.keys(berthMaster).map((berth) => {
                                                return <Option value={berth}>{berth}</Option>;
                                            })}
                                        </Select>
                                    }
                                </Form.Item>
                                <Form.Item name='crane' label="Select Cranes :"
                                    rules={[{ required: true, message: 'Please select devices !'}]}
                                >
                                    {<Select
                                        style={{ width: 300 }}
                                        mode="multiple"
                                        placeholder="Select Cranes"
                                    >
                                        {activeCrane.map((Item) => {
                                        if(Item.status !== 'stop'){
                                            return <Option value={Item}>{Item}</Option>
                                        }else{
                                            return ''
                                        }
                                        })}
                                    </Select>
                                    }
                                </Form.Item>
                                <Button type="primary" htmlType='submit'>
                                    Submit
                                </Button>
                                <Button style={{ marginLeft: "5px" }} onClick={this.clearBerth} type="primary">
                                    Clear
                                </Button>
                            </Form>
                        </Col>

                    </Row>
                    <Divider />
                    <Row>
                        {Object.keys(berthStatusMaster).map((berth) => {
                            return (
                                <Col span={2}>
                                    <div className="gutter-box">
                                        <Tooltip
                                            placement="topLeft"
                                            title={berthStatusMaster[berth]}
                                        >
                                            <span className="gutterTitle">
                                                {berthStatusMaster[berth].berthName}
                                            </span>
                                            <div>{berthStatusMaster[berth].tphValueSum}</div>
                                        </Tooltip>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </div>
        );
    }
}

export default CentralDashboardComponent;

