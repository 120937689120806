import React from 'react';
import moment from 'moment';
import { flattenDeep } from 'lodash';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import { Modal, Radio, Table, Tree } from 'antd';
import MarkerCluster from './MarkerCluster';
import 'leaflet/dist/leaflet.css';
import './map.css';
import './Table.css';
import {
  getLiveCraneCountsList,
  getLiveCranesList,
  getCraneDetailsByCode,
  getTreeData
} from './apicalls';

// import { DownOutlined } from '@ant-design/icons';
import ReactEcharts from '../../../../Echarts/Echarts';
import LeftSidePanel from './LeftSidePanel';

const { TreeNode } = Tree;
class MapWithClustering extends React.Component {
  constructor() {
    super();
    this.state = {
      defaultCenter: [25.360933, 55.375195],
      defaultZoom: 2,
      markerList: [],
      selectedRow: {},
      showMarkModal: false,
      lastUpdatedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      selectedAsset: 'All',
      selectedMode: 'All',
      assetList: ['ITV', 'RS', 'ECH', 'MHCS1', 'QCS1', 'QCS2', 'RTGS1', 'RTGS2', 'All'],
      statusList: ['Running', 'StandBy', 'Idle', 'All'],
      treeData: [],
      selectedArea: ['0-0'],
      option: {
        series: [
          {
            type: 'gauge',
            axisLine: {
              lineStyle: {
                width: 30,
                color: [
                  [0.3, 'green'],
                  [0.7, 'steelblue'],
                  [1, '#fd666d']
                ]
              }
            },
            pointer: {
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              distance: -30,
              length: 8,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            splitLine: {
              distance: -30,
              length: 30,
              lineStyle: {
                color: '#fff',
                width: 4
              }
            },
            axisLabel: {
              color: 'inherit',
              distance: 40,
              fontSize: 10
            },
            detail: {
              valueAnimation: true,
              fontSize: 10,
              formatter: '{value} km/h',
              color: 'inherit'
            },
            data: [
              {
                value: 0,
                name: 'Vehicle Speed'
              }
            ]
          }
        ]
      }
    };
  }

  timeTicket = null;

  componentDidMount() {
    let { selectedAsset, selectedMode, selectedArea } = this.state;
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.addMarkers(selectedAsset, selectedMode, selectedArea);
    this.assetHierarchy();
    this.timeTicket = setInterval(() => {
      this.addMarkers(selectedAsset, selectedMode, selectedArea);
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.timeTicket);
  }

  addMarkers = async (selectedAsset, selectedMode, selectedArea) => {
    let markers = [];
    let markerList = await getLiveCranesList(selectedAsset, selectedMode, selectedArea);
    let getCountsList = await getLiveCraneCountsList();
    markers = markerList.map((mark) => {
      return {
        title: mark.DeviceName,
        value: mark.DeviceAlias,
        status: mark.Operating_Stage,
        position: {
          lat: mark.Latitude1 || '',
          lng: mark.Longitude1 || ''
        },
        asset: mark.Asset
      };
    });
    this.setState({
      markers,
      getCountsList,
      lastUpdatedTime: moment().format('YYYY-MM-DD HH:mm:ss')
    });
  };

  assetHierarchy = async () => {
    let treeData = await getTreeData();
    let expandKey = await this.getAllKeys(treeData);
    this.setState({ treeData, expandKey });
  };

  onCraneClick = async (selectedMarker) => {
    let { option } = this.state;
    let modalDetails = await getCraneDetailsByCode(selectedMarker.value);
    option.series[0].data[0].value = modalDetails['Vehicle Speed'];
    this.setState({ selectedMarker, showMarkModal: true, modalDetails, option });
  };

  renderTreeNodes = (data) => {
    return data.map((item) => {
      if (item.children && item.children.length > 0) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} title={item.title} dataRef={item} />;
    });
  };
  onTreeSelect = (selectedArea) => {
    let { selectedAsset, selectedMode } = this.state;
    this.setState({ selectedArea });
    this.addMarkers(selectedAsset, selectedMode, selectedArea);
  };
  getAllKeys = (data) => {
    const nestedKeys = data.map((node) => {
      let childKeys = [];
      if (node.children) {
        childKeys = this.getAllKeys(node.children);
      }
      return [childKeys, node.key];
    });
    return flattenDeep(nestedKeys);
  };

  render() {
    const {
      markers,
      defaultCenter,
      defaultZoom,
      getCountsList,
      selectedMarker,
      showMarkModal,
      modalDetails,
      lastUpdatedTime,
      selectedAsset,
      selectedMode,
      assetList,
      statusList,
      // treeData,
      selectedArea,
      option
      // expandKey
    } = this.state;
    const columns = [
      {
        title: 'Asset',
        dataIndex: 'Asset',
        key: 'Asset'
      },
      {
        title: 'Total',
        dataIndex: 'Total',
        key: 'Total'
      },
      {
        title: 'Running',
        dataIndex: 'Running',
        key: 'Running'
      },
      {
        title: 'Idle',
        dataIndex: 'Idle',
        key: 'Idle'
      },
      {
        title: 'StandBy',
        dataIndex: 'StandBy',
        key: 'StandBy'
      }
    ];
    return (
      <div className="App">
        <div className="sidebarLeft">
          <LeftSidePanel />
        </div>

        <LeafletMap style={{ width: '100vw' }} center={defaultCenter} zoom={defaultZoom}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
          <MarkerCluster
            markers={markers}
            addMarkers={this.addMarkers}
            parentCallback={this.onCraneClick}
          />
        </LeafletMap>
        {/* <div className="sidebarRight">
          <Tree
            style={{ background: '#aad3df', borderRight: '1px dashed black' }}
            showLine
            defaultExpandAll={true}
            switcherIcon={<DownOutlined />}
            selectedKeys={selectedArea}
            onSelect={this.onTreeSelect}
            expandedKeys={expandKey}
          >
            {this.renderTreeNodes(treeData)}
          </Tree>
        </div> */}
        <div className="sidebar">
          <div style={{ marginBottom: '10px' }}>Last Updated Time: {lastUpdatedTime}</div>
          <div>
            <span>Select Asset: </span>
            <Radio.Group
              onChange={(e) => {
                this.setState({ selectedAsset: e.target.value });
                this.addMarkers(e.target.value, selectedMode, selectedArea);
              }}
              value={selectedAsset}
              style={{ marginTop: 16 }}
            >
              {assetList &&
                Array.isArray(assetList) &&
                assetList.map((asset, index) => {
                  return (
                    <Radio.Button key={index} value={asset}>
                      {asset}
                    </Radio.Button>
                  );
                })}
            </Radio.Group>
          </div>
          <div style={{}}>
            <span>Select Status: </span>
            <Radio.Group
              onChange={(e) => {
                this.setState({ selectedMode: e.target.value });
                this.addMarkers(selectedAsset, e.target.value, selectedArea);
              }}
              value={selectedMode}
              style={{ marginTop: 16 }}
            >
              {statusList &&
                Array.isArray(statusList) &&
                statusList.map((status, index) => {
                  return (
                    <Radio.Button key={index} value={status}>
                      {status}
                    </Radio.Button>
                  );
                })}
            </Radio.Group>
          </div>

          <Table
            columns={columns}
            dataSource={getCountsList}
            pagination={false}
            scroll={{ y: 800 }}
            style={{ margin: '5px', marginTop: 16 }}
            onRow={(record) => {
              return {
                onClick: async () => {
                  this.setState({
                    selectedRow: record,
                    defaultCenter: record.defaultLatLng,
                    defaultZoom: record.defaultZoom
                  });
                }
              };
            }}
          />
          {selectedMarker &&
          Object.keys(selectedMarker) &&
          Object.keys(selectedMarker).length > 0 ? (
            <div>
              {selectedMarker && selectedMarker.title ? selectedMarker.title : ''}
              <div>
                <ReactEcharts id={Math.ceil(Math.random(100, 100000) * 1000)} option={option} />
              </div>
              <div>
                Internal Terminal Vehicle
                <div>
                  {[
                    {
                      key: 'Engine On Time',
                      value:
                        modalDetails && modalDetails['Engine ON time']
                          ? modalDetails['Engine ON time']
                          : ''
                    },
                    {
                      key: 'Engine OFF Time',
                      value:
                        modalDetails && modalDetails['Engine OFF time']
                          ? modalDetails['Engine OFF time']
                          : ''
                    }
                  ].map((data, index) => {
                    return (
                      <div key={index} style={{ margin: '5px', fontSize: '17px' }}>
                        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>{data.key}:</span>
                        <span>{data.value}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <Modal
          title={`Asset: ${
            selectedMarker && selectedMarker.title ? selectedMarker.title : 'Selected Crane'
          }`}
          open={showMarkModal}
          footer={null}
          onCancel={() => this.setState({ showMarkModal: false })}
        >
          {modalDetails && Object.keys(modalDetails) && Object.keys(modalDetails).length > 0
            ? Object.keys(modalDetails).map((modal, index) => {
                return (
                  <div key={index} style={{ margin: '5px', fontSize: '17px' }}>
                    <span style={{ fontWeight: 'bold', marginRight: '10px' }}>{modal}:</span>
                    <span>{modalDetails[modal]}</span>
                  </div>
                );
              })
            : null}
        </Modal>
      </div>
    );
  }
}

export default MapWithClustering;
