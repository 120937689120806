import React from 'react';
import themeSettings from '../../Utils/themeSettings.json';
// import translation from '../Utils/translation';
import {
  StyledComponent,
  StyledForm,
  StyledSubHeading,
  // StyledTable,
  StyledModal
  // StyledFormType
} from '../../CSS/style';
import { getMetaData, submitDataSource, getDeployHistory } from '../../Utils/FormCalls';
import history from '../../../../commons/history';
import { getTranslationCache } from '../../../../selectors/language';
// import { constant } from '../Utils/Constants';
import { MASTERALGORITHM, RBSLOOKUPMaster } from '../../../../commons/api';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  message,
  Space
  // Popconfirm,
  //  Tooltip
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import { getUserProfile } from '../../../../selectors/layout';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
const { Option } = Select;
const { TextArea } = Input;
const APIList = {
  getById: `${MASTERALGORITHM.GET}`,
  getMetrics: `${RBSLOOKUPMaster.LookUpItemsGetById}`,
  createAlgorithm: `${MASTERALGORITHM.CREATE}`
};
class AlgorithmMastersDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObject: {},
      translationCache: props.translationCache || {},
      userProfile: props && props.userProfile ? props.userProfile : {},
      typeList: {},
      subTypeList: {},
      checkedVal: 'true',
      modelVisible: false,
      newRecord: false,
      ParameterModal: false,
      AddParametrModal: false,
      ApplicableMetricsList: [],
      dataSource: [],
      SelecetedModelType: ''
    };
    this.hRef = React.createRef();
    this.dynamicFormRef = React.createRef();
    // this.formRef = React.createRef();
  }
  componentDidMount = async () => {
    let path = history.location.pathname.split('/rubus/RbsAnalytics/AlgorithmMasterDetails/');
    let Id = path && path[1] ? path[1] : null;
    if (Id && Id !== null) {
      let data = await getMetaData(APIList.getById, 'Id', Id);
      let formObject = data && data[0] ? data[0] : {};
      this.setState({
        parameters: { users: formObject.HyperParameters },
        HyperParametersData: formObject.HyperParameters,
        SelecetedModelType: formObject.ModelType
      });
      this.dynamicFormRef.current &&
        this.dynamicFormRef.current.setFieldsValue({ users: formObject.HyperParameters });
      let ApplicableMetricsList = [];
      if (formObject && formObject.ModelType && formObject.ModelType === 'Regression') {
        ApplicableMetricsList = await getDeployHistory(
          APIList.getMetrics,
          'LookupName',
          'RBS_MODEL_REG_METRICS'
        );
      } else {
        ApplicableMetricsList = await getDeployHistory(
          APIList.getMetrics,
          'LookupName',
          'RBS_MODEL_CLF_METRICS'
        );
      }
      this.setState({ ApplicableMetricsList, SelecetedModelType: formObject.ModelType });
      if (formObject.GPUFlag === 'true') {
        this.setState({ checkedVal: 'true' });
      } else {
        this.setState({ checkedVal: 'false' });
      }

      if (this.hRef.current) {
        this.hRef.current.setFieldsValue({ ...formObject });
      }

      this.setState({
        formObject,
        // subTypeList: subType,
        selectedType: formObject.Type
      });
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (
      this.props.userProfile !== prevProps.userProfile ||
      this.props.translationCache !== prevProps.translationCache
    ) {
      this.setState({
        userProfile: this.props.userProfile,
        translationCache: this.props.translationCache
      });
    }
  };

  handleSave = (dataSource) => {
    this.setState({ dataSource, disableSubmit: false, newRecord: false });
  };

  handleAdd = () => {
    const { dataSource } = this.state;
    let newData = {
      key: uuidv4(),
      Name: '',
      DefaultValue: '',
      Enabled: true
    };
    this.setState({ dataSource: [...dataSource, newData], newRecord: true });
  };
  disableSubmit = (disableSubmit) => {
    this.setState({ disableSubmit, newRecord: false });
  };

  onFormSubmit = () => {
    let {
      checkedVal,
      // checkedParameters,
      HyperParametersData
    } = this.state;
    this.hRef.current
      .validateFields()
      .then(async (values) => {
        values = {
          ...this.hRef.current.getFieldsValue(),
          GPUFlag: checkedVal,
          HyperParameters: HyperParametersData
        };
        let response = await submitDataSource(APIList.createAlgorithm, values);

        this.hRef.current && this.hRef.current.resetFields();
        // this.setState({ formObject: metaData });
        message.success(response && response.message ? this.translation(response.message) : '');
        history.push('/rubus/RbsAnalytics/AlgorithmMasterList');
      })
      .catch(() => {});
  };

  resetFields = () => {
    this.hRef.current &&
      this.hRef.current.setFieldsValue({
        ModelType: null,
        AlgorithmName: '',
        ShortDescription: '',
        DetailedDescription: '',
        DependentLibraries: '',
        TemplateClass: null,
        ApplicableMetrics: []
      });
    this.setState({
      formObject: {},
      HyperParametersData: [],
      checkedVal: 'true',
      SelecetedModelType: ''
    });
  };
  onModelTypeChange = async (value) => {
    let ApplicableMetricsList = [];
    if (value && value === 'Regression') {
      ApplicableMetricsList = await getDeployHistory(
        APIList.getMetrics,
        'LookupName',
        'RBS_MODEL_REG_METRICS'
      );
    } else {
      ApplicableMetricsList = await getDeployHistory(
        APIList.getMetrics,
        'LookupName',
        'RBS_MODEL_CLF_METRICS'
      );
    }
    this.setState({ ApplicableMetricsList, SelecetedModelType: value });
  };

  onCheckboxChange = (e) => {
    if (e.target.checked === true) {
      this.setState({ checkedVal: 'true' });
    } else if (e.target.checked === false) {
      this.setState({ checkedVal: 'false' });
    }
  };

  onSubmit = (values) => {
    this.setState({
      HyperParametersData: values && values.users,
      ParameterModal: false,
      parameters: { users: values.users }
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  render() {
    const { formObject, edit, checkedVal, ApplicableMetricsList, ParameterModal } = this.state;
    const validateMessages = {
      required: this.translation('${label}') + this.translation('is required!')
    };

    return (
      <StyledComponent
        theme={themeSettings}
        style={{
          padding: '50px 50px',
          minHeight: window.innerHeight - 143
        }}
      >
        <StyledSubHeading theme={themeSettings}>
          {this.translation('Algorithm Master')}
        </StyledSubHeading>
        <StyledForm
          name="AlgorithmMaster"
          ref={this.hRef}
          autoComplete="off"
          initialValues={formObject}
          theme={themeSettings}
          layout="horizontal"
          labelCol={{ flex: '200px' }}
          labelAlign="right"
          onFinish={this.onFormSubmit}
          onFinishFailed={this.onFinishFailed}
          // disabled={view === true ? true : false}
          labelWrap
          validateMessages={validateMessages}
          wrapperCol={{ flex: 1 }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item name="Id"></Form.Item>
            <Form.Item
              label={this.translation('Model Type')}
              name="ModelType"
              rules={[{ required: true }]}
              style={{ width: '50%' }}
            >
              <Select
                // disabled={formObject && formObject.ModelType ? true : false}
                onChange={this.onModelTypeChange}
                placeholder={this.translation('Please Select Model Type')}
              >
                <Option value={'Regression'} key={'Regression'}>
                  Regression
                </Option>
                <Option value={'Classification'} key={'Classification'}>
                  Classification
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={this.translation('Algorithm Name')}
              name="AlgorithmName"
              rules={[{ required: true }]}
              style={{ width: '50%' }}
            >
              <Input
                // disabled={formObject && formObject.AlgorithmName ? true : false}
                placeholder={this.translation('Please Enter Algorithm Name')}
              ></Input>
            </Form.Item>
            <Form.Item
              label={this.translation('Short Description')}
              name="ShortDescription"
              // rules={[{ required: true }]}
              style={{ width: '50%' }}
            >
              <TextArea placeholder={this.translation('Add ShortDescription')}></TextArea>
            </Form.Item>
            <Form.Item
              label={this.translation('Detailed Description')}
              name="DetailedDescription"
              // rules={[{ required: true }]}
              style={{ width: '50%' }}
            >
              <TextArea placeholder={this.translation('Add Detailed Description')}></TextArea>
            </Form.Item>
            <Form.Item
              label={this.translation('Dependent libraries with versions')}
              name="DependentLibraries"
              // rules={[{ required: true }]}
              style={{ width: '50%' }}
            >
              <TextArea placeholder={this.translation('Add Dependent Libraries')}></TextArea>
            </Form.Item>
            <Form.Item
              label={this.translation('Template class')}
              name="TemplateClass"
              // rules={[{ required: true }]}
              style={{ width: '50%' }}
            >
              <TextArea placeholder={this.translation('Add Template class')}></TextArea>
            </Form.Item>
            <Form.Item
              label={this.translation('Best with GPU flag')}
              name="GPUFlag"
              style={{ width: '50%' }}
            >
              <Checkbox
                checked={checkedVal === 'true' ? true : false}
                onClick={(e) => this.onCheckboxChange(e)}
              />
            </Form.Item>
            <Form.Item
              label={this.translation('Applicable metrics')}
              name="ApplicableMetrics"
              rules={[{ required: true }]}
              style={{ width: '50%' }}
            >
              <Select
                mode="multiple"
                disabled={this.state.SelecetedModelType ? false : true}
                placeholder={this.translation('Please Select Applicable Metrics')}
              >
                {ApplicableMetricsList &&
                  Array.isArray(ApplicableMetricsList) &&
                  ApplicableMetricsList.map((metrics) => {
                    return (
                      <Option value={metrics.value} key={metrics.key}>
                        {metrics.key}
                      </Option>
                    );
                  })}{' '}
              </Select>
            </Form.Item>
            <Form.Item
              label={this.translation('Hyper Parameters')}
              name="HyperParameters"
              style={{ width: '50%' }}
            >
              <Button
                type="dashed"
                onClick={() => {
                  // parameters: { users: values.users }}
                  this.setState({ parameters: { users: this.state.HyperParametersData } });
                  this.dynamicFormRef.current &&
                    this.dynamicFormRef.current.setFieldsValue({
                      users: this.state.HyperParametersData
                    });
                  this.setState({ ParameterModal: true });
                }}
              >
                {'...'}
              </Button>
            </Form.Item>

            <StyledModal
              open={ParameterModal}
              theme={themeSettings}
              width={800}
              closable
              title={
                formObject && formObject.HyperParameters && formObject.HyperParameters !== null
                  ? 'Update Hyper Parameters'
                  : 'Add Hyper Parameters'
              }
              onCancel={() => this.setState({ ParameterModal: false })}
              footer={null}
            >
              <Form
                name="dynamic_form_nest_item"
                onFinish={this.onSubmit}
                autoComplete="off"
                ref={this.dynamicFormRef}
                initialValues={this.state.parameters}
                style={{ paddingLeft: '10%' }}
              >
                <Form.List name="users">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'Name']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing Name'
                              }
                            ]}
                          >
                            <Input placeholder=" Name" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'DefaultValue']}
                            rules={[
                              {
                                required: true,
                                message: 'Missing Default Value'
                              }
                            ]}
                          >
                            <Input placeholder="Default Value" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          style={{ width: '70%' }}
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Parameter
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <div style={{ display: 'flex', justifyContent: 'right', paddingLeft: '10px' }}>
                  <Form.Item>
                    <Button type="primary" style={{ marginRight: 8 }} htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      key={'back'}
                      style={{ marginRight: 8 }}
                      onClick={() => this.setState({ ParameterModal: false })}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </StyledModal>
          </div>

          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Form.Item>
              <Button
                style={{ marginRight: 8 }}
                theme={themeSettings}
                type="primary"
                htmlType="submit"
                data-testid="submitButton"
              >
                {edit ? this.translation('Update') : this.translation('Submit')}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ marginRight: 8 }}
                theme={themeSettings}
                type="primary"
                onClick={() => this.resetFields()}
              >
                {this.translation('Reset')}
              </Button>
            </Form.Item>
          </div>
        </StyledForm>
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(AlgorithmMastersDetails);
