import React, { Component } from 'react';
import axios from 'axios';
import { Select } from 'antd';

import localStorage from '../../../utils/localStorage';

const { Option } = Select;

class SelectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionList: []
    };
  }

  componentDidUpdate(prevprops) {
    if (prevprops.column !== this.props.column) {
      this.setState({
        column: this.props.column
      });
    }
  }

  componentDidMount() {
    this._getOptionList(this.props.column);
  }

  _getOptionList = (column) => {
    let payloadHeader = {};
    const accessToken = localStorage.get('accessToken');

    if (column && column.url && column.method && column.method === 'GET') {
      payloadHeader = {
        method: column.method,
        url: `${column.url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      };
    } else if (column && column.url && column.method === 'POST') {
      payloadHeader = {
        method: column.method,
        url: `${column.url}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: JSON.parse(column.payload)
      };
    }
    return axios(payloadHeader)
      .then((response) => {
        if (Array.isArray(response.data)) {
          let optionList = response.data;
          this.setState({
            optionList
          });
        }
      })
      .catch(() => {
        return [];
      });
  };

  render() {
    let { column, value } = this.props;
    let { optionList } = this.state;
    return (
      <div>
        <Select
          value={value}
          style={{ width: 251 }}
          onChange={(e) => this.props.handleFieldChange(e, column.key)}
          placeholder={column.placeHolder || column.displayName}
        >
          {optionList &&
            optionList.map((data, index) => {
              return (
                <Option key={index} value={data.value}>
                  {data.key}
                </Option>
              );
            })}
        </Select>
      </div>
    );
  }
}

export default SelectComponent;
