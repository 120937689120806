export const columns = [
  {
    title: 'Id',
    dataIndex: 'Id'
  },
  {
    title: 'Organisation Name',
    dataIndex: 'Name'
  },
  {
    title: 'Short Name',
    dataIndex: 'ShortName'
  },
  {
    title: 'Description',
    dataIndex: 'Description'
  },
  {
    title: 'Tag Line',
    dataIndex: 'TagLine'
  },
  {
    title: 'URL',
    dataIndex: 'URL'
  }
];
