import React, { Component } from 'react';
import { Button, Input, Space, Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import { getMasterList, getUsersList } from './Utils/FormCalls';
// import { encode as base64_encode } from 'base-64';
import { SearchOutlined } from '@ant-design/icons';
import history from '../../../commons/history';
import { connect } from 'react-redux';
// import { constant } from '../AMM/Utils/constants';
import { createStructuredSelector } from 'reselect';

// import translation from '../AMM/Utils/translation';
import { getCurrentLanguage, getTranslationCache } from '../../../selectors/language';
import themeSettings from './Utils/themeSettings.json';
import ListView from '../../AMM/Utils/ListView';
import { StyledComponent } from '../../AMM/CSS/style';
import { MAXIMOASSET } from '../../../commons/api';
const urlList = {
  list: `${MAXIMOASSET.GetAFCList}`
};

class StatusScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      SpecificationData: [],
      activeKey: '1',
      form: {},
      editingKey: false,
      translationCache: props.translationCache || [],
      columns: [
        {
          title: 'S.No',
          dataIndex: 'S.No',
          key: 'S.No'
        },
        {
          title: 'Station',
          dataIndex: 'Station',
          key: 'Station',
          filter: true
        },
        {
          title: 'DeviceType',
          dataIndex: 'deviceTypeName',
          key: 'deviceTypeName'
        },
        {
          title: 'Device ',
          dataIndex: 'DeviceName',
          key: 'DeviceName',
          filter: true
        },
        {
          title: 'Fault Code',
          dataIndex: 'FaultName',
          key: 'FaultName'
        },
        {
          title: 'Halt Status',
          render: (record) => (
            <Switch
              key={record.Id}
              style={{ cursor: 'not-allowed', opacity: 1 }}
              defaultChecked={record.HaltStatus ? true : false}
              disabled
            />
          )
        },
        {
          title: 'Interval Status',
          render: (record) => (
            <Switch
              key={record.Id}
              style={{ cursor: 'not-allowed', opacity: 1 }}
              defaultChecked={record.IntervalStatus ? true : false}
              disabled
            />
          )
        },
        {
          title: 'Fault Interval',
          dataIndex: 'Interval',
          key: 'Interval',
          editable: true
        },
        {
          title: 'Edit',
          dataIndex: 'Edit',
          key: 'Edit',
          render: (text, record) => (
            <Space>
              <Button className="ant-btn" onClick={() => this.editHalt(record)} size="middle">
                Halt Edit
              </Button>
              <Button className="ant-btn" onClick={() => this.editInterval(record)} size="middle">
                Interval Edit
              </Button>
            </Space>
          )
        }
      ],

      config: {
        title: 'Status Screen',

        viewTypes: ['table'],
        defaultViewType: 'table',

        firstTitle: 'Status Screen',
        secondTitle: 'CreatedBy',
        thirdTitle: 'CreatedTime',

        actions: ['edit'],
        edit: {
          byAttribute: 'Id',
          redirectURL: ''
        },
        deletee: {
          byAttribute: 'Id'
        }
      },
      userProfile: props && props.userProfile ? props.userProfile : {},
      userList: []
    };
  }
  formRef = React.createRef();
  componentDidUpdate(prevprops) {
    if (prevprops.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (this.props.match.params.Masters !== prevprops.match.params.Masters) {
      let mastername = this.props.match.params.Masters;
      this._getAdhocDataSource();
      this.setState({ mastername });
    }
    if (this.props.userProfile !== prevprops.userProfile) {
      this.setState({ userProfile: this.props.userProfile });
    }
  }
  componentDidMount = async () => {
    this._getAdhocDataSource();
  };

  _getUserList = async () => {
    let userList = await getUsersList();
    this.setState({ userList });
  };
  _getAdhocDataSource = async () => {
    let path = history.location.pathname.split('/rubus/Maximo/');
    let Module = path[1].split('/') && path[1].split('/')[0];
    let dataSource = await getMasterList(urlList.list, Module);
    this.setState({
      dataSource
    });
  };

  downTimeData = (form) => {
    this.setState({
      form
    });
  };
  editHalt = (record) => {
    let path = history.location.pathname.split('/rubus/Maximo/');
    let Module = path[1].split('/') && path[1].split('/')[0];
    history.push({
      pathname: `/rubus/Maximo/${Module}/SRHaltTime`,
      state: {
        record: record
      }
    });
  };
  editInterval = (record) => {
    let path = history.location.pathname.split('/rubus/Maximo/');
    let Module = path[1].split('/') && path[1].split('/')[0];
    history.push({
      pathname: `/rubus/Maximo/${Module}/SRWaitInterval`,
      state: {
        record: record
      }
    });
  };

  // onChangeInterval = async (value, key, record) => {
  //   let { dataSource } = this.state;
  //   dataSource.map((item) => {
  //     if (item.key === record.key) {
  //       return (record.SRInterval = value);
  //     }
  //   });
  //   this.setState({ dataSource });
  // };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  // handleHaltToggle = (e) => {
  //   let { dataSource } = this.state;
  //   dataSource.map((data) => {
  //     if (e === true) {
  //       return (data.HaltStatus = true);
  //     } else {
  //       return (data.HaltStatus = false);
  //     }
  //   });
  //   this.setState({ dataSource });
  // };

  // handleIntervalToggle = (e) => {
  //   let { dataSource } = this.state;
  //   dataSource.map((data) => {
  //     if (e === true) {
  //       return (data.IntervalStatus = true);
  //     } else {
  //       return (data.IntervalStatus = false);
  //     }
  //   });
  //   this.setState({ dataSource });
  // };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record &&
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text && text.toString()}
      />
    )
  });

  render() {
    const { dataSource, userList, columns, config } = this.state;

    return (
      <StyledComponent theme={themeSettings} style={{ minHeight: window.innerHeight - 143 }}>
        <ListView
          ref={this.formRef}
          columns={columns}
          dataSource={dataSource}
          userList={userList}
          config={config}
          delete={this._deleteById}
        />
      </StyledComponent>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  language: getCurrentLanguage(),
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(StatusScreen);
