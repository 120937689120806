import React from 'react';
import Highlighter from 'react-highlight-words';
import { Button, Input, Table, Select, Switch, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { createStructuredSelector } from 'reselect';
import { _getDeviceList, _getTopicList, _getGroupList, _generate } from './apiCalls';
import { StyledApplication } from './styles';
import './style.css';
import { connect } from 'react-redux';
import { getTranslationCache } from '../../../../selectors/language';
const { Option } = Select;

class BridgeConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DataSource: props.deviceList || [],
      topicList: [],
      deviceList: [],
      groupList: []
    };
  }

  bridgeConfig = React.createRef();
  componentDidMount() {
    this._SendAPICall('TopicList');
    this._SendAPICall('DeviceList');
  }

  _SendAPICall = async (listName, payload) => {
    if (listName === 'TopicList') {
      let topicList = await _getTopicList();
      this.setState({
        topicList
      });
    } else if (listName === 'DeviceList') {
      let deviceList = await _getDeviceList();
      this.setState({
        deviceList
      });
    } else if (listName === 'GroupList') {
      let groupList = await _getGroupList(payload);
      this.setState({
        groupList
      });
    }
  };

  renderFormSwitch(renderFormItems) {
    let { disableOnUpdate } = renderFormItems;
    switch (renderFormItems.Widget) {
      case 'Select':
        return (
          <Select
            style={{ width: 300 }}
            placeholder={this.translation(`Select ${renderFormItems.DisplayName}`)}
            onChange={(e) => {
              this.bridgeConfig.current.setFieldsValue({ [renderFormItems.Key]: e });
              let formData = this.bridgeConfig.current.getFieldsValue();
              if (renderFormItems.onChange && formData) {
                this._SendAPICall(renderFormItems.onChange, formData);
              }
            }}
          >
            {renderFormItems.Options &&
              Array.isArray(renderFormItems.Options) &&
              renderFormItems.Options.map((option, index) => {
                if (
                  renderFormItems.OptionsSettings &&
                  renderFormItems.OptionsSettings.key &&
                  renderFormItems.OptionsSettings.value
                ) {
                  return (
                    <Option key={index} value={option[renderFormItems.OptionsSettings.value]}>
                      {option[renderFormItems.OptionsSettings.key]}
                    </Option>
                  );
                }
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                );
              })}
          </Select>
        );
      case 'Switch':
        return <Switch disabled={disableOnUpdate && disableOnUpdate === true ? true : false} />;

      default:
        return (
          <Input
            style={{ width: 300 }}
            placeholder={`Enter ${renderFormItems.Key}`}
            disabled={disableOnUpdate && disableOnUpdate === true ? true : false}
          />
        );
    }
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <SearchOutlined
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </SearchOutlined>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text && text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  generateTagList = (e) => {
    e.preventDefault();
    this.bridgeConfig.current
      .validateFields()
      .then(async (values) => {
        let payload = {
          ...values,
          ParameterValue: false
        };
        let dataSource = await _generate(payload);
        this.setState({
          dataSource
        });
      })
      .catch(() => {});
  };

  render() {
    const { dataSource, topicList, deviceList, groupList } = this.state;
    let columns =
      dataSource && Array.isArray(dataSource) && dataSource[0]
        ? Object.keys(dataSource[0]).map((columns) => {
            return {
              title: columns,
              dataIndex: columns,
              key: columns,
              ...this.getColumnSearchProps(columns)
            };
          })
        : [];

    let formStructure = [
      {
        DisplayName: 'Topic Name',
        Key: 'TopicName',
        Widget: 'Select',
        Options: topicList
      },
      {
        DisplayName: 'Device',
        Key: 'Device',
        Widget: 'Select',
        Options: deviceList,
        OptionsSettings: { key: 'Name', value: 'DeviceCode' },
        onChange: 'GroupList'
      },
      {
        DisplayName: 'Group Name',
        Key: 'GroupName',
        Widget: 'Select',
        Options: groupList
      }
    ];

    return (
      <StyledApplication style={{ minHeight: window.innerHeight - 226 }}>
        <Form
          layout="inline"
          ref={this.bridgeConfig}
          name="user_form"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          initialValues={{}}
          onFinishFailed={this.onFinishFailed}
        >
          {formStructure &&
            Array.isArray(formStructure) &&
            formStructure.map((formItem, index) => {
              return (
                <Form.Item
                  key={index}
                  name={formItem.Key}
                  // label={this.translation(formItem.DisplayName)}
                >
                  {this.renderFormSwitch(formItem)}
                </Form.Item>
              );
            })}
          <Form.Item>
            <Button type="primary" onClick={this.generateTagList}>
              {this.translation('Generate')}
            </Button>
          </Form.Item>
        </Form>
        {columns && Array.isArray(columns) && columns.length > 0 ? (
          <Table
            className="basictable"
            style={{ marginTop: '33px' }}
            size="large"
            columns={columns}
            dataSource={dataSource && Array.isArray(dataSource) ? dataSource : []}
            locale="No Data"
          />
        ) : null}
      </StyledApplication>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(BridgeConfiguration);
