import { Button, Drawer, Modal, Skeleton, Space, Tree } from 'antd';
import React, { useState, useEffect } from 'react';
import { getCustomHierarchy, getOptionsList, getRootLevelData } from './APICalls';
import { UpCircleOutlined } from '@ant-design/icons';

const DynamicGrid = (props) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [customModalOpen, setCustomModalOpen] = useState(false);
  const [customValue, setCustomValue] = useState(props.customValue || '');
  const [optionList, setOptionList] = useState([]);
  const [customHierarchy, setCustomHierarchy] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        let optionListData = await getOptionsList(props.Step, props.EquipmentType);
        let customHierarchy = await getCustomHierarchy(props.Step, props.EquipmentType);
        setOptionList(optionListData);
        setCustomHierarchy(customHierarchy);
      } catch (e) {
        return {};
      }
    })();
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (props.stepWise && props.stepWise.Answer) {
        setItems(
          props.stepWise.Answer.map((option, i) => ({
            id: i + 1,
            Option: option.Option,
            Value: option.Value,
            highlighted: option.Value === props.value ? true : false
          }))
        );
      }
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  });

  const handleClick = (Value) => {
    console.log('Value', Value);

    props.onSelectChange(Value);
  };

  const filterListByKeyValue = (key, value) => {
    let list = optionList.filter((e) => e[key] === value);
    return list && list[0] && list[0]['Name'] ? list[0]['Name'] : value;
  };

  const add3Dots = (text, limit) => {
    let dots = '...';

    if (text.length > limit) {
      text = text.substring(0, limit) + dots;
    }
    return text;
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
          fontSize: '24px'
        }}
      >
        <Skeleton />
      </div>
    );
  }
  return (
    <>
      <div className="containerDynamicGrid">
        {items &&
          Array.isArray(items) &&
          items.map((item, index) => (
            <>
              <div
                style={{
                  flexBasis: 'calc(33% - 8px)',
                  backgroundColor: props.value === item.Value ? 'rgb(242, 176, 54)' : '#2f4459',
                  display: 'flex',
                  borderRadius: '15px'
                }}
              >
                <div
                  key={index + item}
                  style={{
                    width: '90%',
                    padding: '31px 18px',
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease, transform 0.3s ease',
                    animation: `fadeIn 0.5s ease-out ${index * 0.1}s both`,
                    color: props.value === item.Value ? 'rgb(11, 38, 64)' : '#eee',
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                  onClick={() => handleClick(item.Value)}
                >
                  {add3Dots(item.Option, 25)}
                </div>
                <div
                  key={index + 'icon'}
                  style={{
                    width: '10%',
                    padding: '25px 18px',
                    borderRadius: '8px',
                    boxSizing: 'border-box',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease, transform 0.3s ease',
                    animation: `fadeIn 0.5s ease-out ${index * 0.1}s both`,
                    color: props.value === item.Value ? 'rgb(11, 38, 64)' : '#eee',
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                  onClick={async () => {
                    let list = await getRootLevelData(item.Value);
                    Modal.info({
                      title: 'Devicetype at different levels',
                      content: (
                        <div>
                          {list && Array.isArray(list)
                            ? list.map((data, index) => {
                                return <div key={index}>{data.MatchedNode}</div>;
                              })
                            : ''}
                        </div>
                      )
                    });
                  }}
                >
                  <UpCircleOutlined style={{ color: 'white' }} />
                </div>
              </div>
            </>
          ))}
        <div
          key={'custom'}
          style={{
            flexBasis: 'calc(30% - 8px)',
            padding: '31px 18px',
            backgroundColor: props.value === 'custom' ? 'rgb(242, 176, 54)' : '#2f4459',
            borderRadius: '8px',
            boxSizing: 'border-box',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease, transform 0.3s ease',
            animation: `fadeIn 0.5s ease-out ${(items && items.length - 1) * 0.1}s both`,
            color: props.value === 'custom' ? 'rgb(11, 38, 64)' : '#eee',
            fontWeight: 'bold',
            fontSize: '16px',
            border: '1px dashed'
          }}
          onClick={() => {
            setCustomModalOpen(true);
          }}
        >
          {!customValue ? `Custom...` : ''}
          {customValue ? add3Dots(`${filterListByKeyValue('AliasCode', customValue)}`, 25) : ''}
        </div>
      </div>
      <Drawer
        title={'Select Equipment Type'}
        open={customModalOpen}
        closable
        extra={
          <Space>
            <Button onClick={() => setCustomModalOpen(false)}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => {
                props.onCustomSelectChange(customValue);
                setCustomModalOpen(false);
              }}
            >
              Submit
            </Button>
          </Space>
        }
        onClose={() => setCustomModalOpen(false)}
        width={600}
      >
        <Tree
          defaultExpandAll={true}
          showLine
          treeData={customHierarchy}
          onSelect={(value) => {
            setCustomValue(value[0]);
          }}
          selectedKeys={[customValue]}
        />
      </Drawer>
    </>
  );
};

const App = (props) => {
  return (
    <div>
      <DynamicGrid {...props} />
    </div>
  );
};

export default App;
